import axios from 'axios'
import { useToast } from 'services/helpers/useToast'
import {
  imageDownloadFilename,
  IMAGE_DEFAULT_URL,
  SAFARI_DOWNLOAD_INFO,
  USUAL_DOWNLOAD_SUCCESS_TEXT,
} from '_features/editor'

export const downloadImageOnClickRequest = async (
  imageUrl?: string,
  isBrowserSafari?: boolean | null,
) => {
  /* =========================================================
  This is giving a CORS error, so we are using axios instead.

  const httpClient = new AxiosService()
  const imageResponse = await httpClient.downloadImage(imageUrl)  
  ========================================================== */

  const toast = useToast()

  if (isBrowserSafari) toast.info(SAFARI_DOWNLOAD_INFO)
  else toast.successBlack(USUAL_DOWNLOAD_SUCCESS_TEXT)

  const imageBlob = await axios.get(imageUrl || IMAGE_DEFAULT_URL, {
    responseType: 'blob',
  })

  const fileReader = new FileReader()
  fileReader.readAsDataURL(imageBlob.data)
  fileReader.onload = () => {
    /* We are going to fake a download behaviour using faked elements. */
    /* Helper elements will be removed after used for their purpose. */

    const anchorForDownloading = document.createElement('a')
    anchorForDownloading.download = imageDownloadFilename(Date.now())
    anchorForDownloading.style.display = 'none'
    anchorForDownloading.href = fileReader.result as string
    anchorForDownloading.click()
    anchorForDownloading.remove()
  }
}
