import styled from 'styled-components'
import { ReactComponent as ChevronRightIcon } from 'assets/images/chevron-right-black.svg'
import { ChromePicker } from 'react-color'
import { ReactComponent as CloseIcon } from 'assets/images/close.svg'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

export type ChevronRightIconRotatingProps = {
  turn?: boolean
  hide?: boolean
}

export const PaletteWrapper = styled.div<{ isSelected?: boolean }>`
  height: auto;
  width: 100%;
  border-radius: 7px;
  border: ${(props) =>
    props.isSelected ? '1px solid var(--primary-color)' : '1px solid var(--secondary-text)'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const PaletteOverview = styled.div`
  display: flex;
  justify-content: space-between;
  height: 46px;
  width: 100%;
`

export const PaletteNameWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  gap: 6px;
`

export const PaletteColorsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  gap: 6px;
  position: relative;
`

export const PaletteColor = styled.div<{ color: string; size?: string; isColorWheel?: boolean }>`
  height: ${(props) => (props.size ? props.size : '30px')};
  width: ${(props) => (props.size ? props.size : '30px')};
  border-radius: 4px;
  cursor: pointer;
  background-color: ${(props) => `${props.color}`};
  color: var(--primary-text);
  border: solid 0.5px var(--gray3);
  background: ${(props) =>
    props.isColorWheel &&
    `linear-gradient(
    45deg,
    hsl(0, 100%, 50%),
    hsl(30, 100%, 50%),
    hsl(60, 100%, 50%),
    hsl(90, 100%, 50%),
    hsl(120, 100%, 50%),
    hsl(150, 100%, 50%),
    hsl(180, 100%, 50%),
    hsl(210, 100%, 50%),
    hsl(240, 100%, 50%),
    hsl(270, 100%, 50%),
    hsl(300, 100%, 50%),
    hsl(330, 100%, 50%),
    hsl(360, 100%, 50%)
  );`};
`

export const PaletteNumber = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DetailedColors = styled.div``

export const ColorDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0;
  padding: 0 8px 0 0;
  color: var(--primary-text);
`

export const MoreOptionsWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  width: 132px;
  margin: 4px 8px;
`

export const CustomDropdownWrapper = styled(RadixDropdown)`
  display: flex;
  padding: 8px 12px;
  justify-content: flex-start !important;
  width: 132px;
`
