import { StyleTypes, StickyNoteTypes, TextBlockTypes } from '_entities/block'

export const generateBlockDefaultStyle = {
  [TextBlockTypes.TITLE]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '48px' },
    { type: StyleTypes.FONT_WEIGHT, value: '700' },
  ],
  [TextBlockTypes.XLARGE]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '38px' },
    { type: StyleTypes.FONT_WEIGHT, value: '700' },
  ],
  [TextBlockTypes.LARGE]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '28px' },
    { type: StyleTypes.FONT_WEIGHT, value: '700' },
  ],
  [TextBlockTypes.MEDIUM]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '21px' },
    { type: StyleTypes.FONT_WEIGHT, value: '700' },
  ],
  [TextBlockTypes.SMALL]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '17px' },
    { type: StyleTypes.FONT_WEIGHT, value: '700' },
  ],
  [TextBlockTypes.SUBHEADER]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '36px' },
    { type: StyleTypes.FONT_WEIGHT, value: '100' },
  ],
  [TextBlockTypes.TEXT]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '18px' },
    { type: StyleTypes.FONT_WEIGHT, value: '400' },
  ],
  [StickyNoteTypes.STICKY_NOTE]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '18px' },
    { type: StyleTypes.FONT_WEIGHT, value: '400' },
  ],
  [TextBlockTypes.LIST]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '18px' },
    { type: StyleTypes.FONT_WEIGHT, value: '400' },
  ],
  [TextBlockTypes.CHECKLIST]: [
    { type: StyleTypes.COLOR, value: '' },
    { type: StyleTypes.BACKGROUND_COLOR, value: '' },
    { type: StyleTypes.BOLD, value: '' },
    { type: StyleTypes.UNDERLINE, value: '' },
    { type: StyleTypes.STRIKE_THROUGH, value: '' },
    { type: StyleTypes.ITALIC, value: '' },
    { type: StyleTypes.FONT_FAMILY, value: '' },
    { type: StyleTypes.FONT_SIZE, value: '18px' },
    { type: StyleTypes.FONT_WEIGHT, value: '400' },
  ],
}
