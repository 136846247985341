import { AxiosService } from 'services/axiosService/axiosService'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ChangeEvent, useEffect, useState } from 'react'
import { useToast } from 'services/helpers/useToast'
import { setSettings } from 'redux/reducers/globalReducer'
import { useUpload } from '_features/upload'
import { Setting } from '_entities/settings'
import { extractNameFromLink, getById } from 'shared/lib'

export const useAdminImageBackground = () => {
  // ** State
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [backgroundImage, setBackgroundImage] = useState('')
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>(undefined)
  const [backgroundOpacity, setBackgroundOpacity] = useState<number | undefined>(undefined)

  // ** Hooks
  const axiosService = new AxiosService()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const _upload = useUpload()

  // ** Redux state
  const settings = useAppSelector((state) => state.global.settings)
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const page = useAppSelector((state) => state.page.selectedPage)

  useEffect(() => {
    const backgroundImageSetting = settings?.find(
      (setting) => setting.key === Setting.BACKGROUND_IMAGE,
    )?.value

    if (!backgroundImageSetting) return

    const background = JSON.parse(backgroundImageSetting)

    if (background.backgroundImage) {
      setName(extractNameFromLink(background.backgroundImage))
      setBackgroundImage(background.backgroundImage)
      setBackgroundColor(undefined)
    } else {
      setName('')
      setBackgroundImage('')
    }

    if (background.backgroundColor) {
      setBackgroundColor(background?.backgroundColor)
      setBackgroundImage('')
      setName('')
    }
  }, [settings])

  const updateSettings = async (data: string) => {
    const backgroundImageSettingId = settings?.find(
      (setting) => setting.key === Setting.BACKGROUND_IMAGE,
    )?.id

    if (!backgroundImageSettingId) return

    await axiosService.updateSetting(backgroundImageSettingId.toString(), data)

    const documentBackground = getById('document-background')

    const backgroundImage = JSON.parse(data).backgroundImage

    if (documentBackground) {
      documentBackground.style.background = `url(${backgroundImage})`
    }

    dispatch(
      setSettings(
        settings.map((setting) => {
          if (setting.key === Setting.BACKGROUND_IMAGE) {
            return { ...setting, value: data }
          }
          return setting
        }),
      ),
    )
  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const files = e.target.files

    if (files && files[0].size > 5000000000) {
      toast.error('The maximum size per file is 5GB')
      return
    }

    if (files === null) return

    await _upload.handleFileUpload(files[0], async (name, _, link) => {
      const data = {
        backgroundImage: link,
        backgroundColor: undefined,
        backgroundOpacity: undefined,
      }

      await updateSettings(JSON.stringify(data))
    })

    setLoading(false)
  }

  const removeImage = async () => {
    setLoading(true)
    await updateSettings('{}')
    dispatch(
      setSettings(
        settings?.map((setting) =>
          setting.key === Setting.BACKGROUND_IMAGE
            ? {
                ...setting,
                value: '{}',
              }
            : setting,
        ),
      ),
    )
    setName('')
    setBackgroundImage('')
    setLoading(false)
  }

  return {
    updateSettings,
    handleFileUpload,
    loading,
    name,
    removeImage,
    projectFile,
    page,
    settings,
    backgroundImage,
    backgroundColor,
    setBackgroundColor,
    backgroundOpacity,
    setBackgroundOpacity,
  }
}
