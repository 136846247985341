import Quill, { DeltaStatic, QuillOptionsStatic, RangeStatic } from 'quill'
import {
  type block,
  IBlockTypes,
  EDITOR_DEBOUNCE_TIME,
  INDENTATION,
  ENTER_PRESS_DELAY_TO_GET_REAL_TIME_VALUE,
  getBlockPageId,
  isNotAnyKindOfList,
  isSomeOfLists,
  isBlockNumberedList,
  getIndex,
  getPreviousBlock,
  getNextBlock,
} from '_entities/block'
import { useCreateBlock, useUpdateBlock } from '_features/block'
import { SourceKeys } from 'interfaces/editor'
import { Doc as JsonDoc } from 'ot-json1'
import { useEffect, useMemo, useRef, useState } from 'react'
import { debounce, isArray } from 'lodash'
import { useJson1Text } from 'shared/shareDb/useJson1Text'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useUpdatePage } from '_features/page'
import useNumberedList from './useNumberedList'
import { convertFromArabicToRoman, convertRomanToArabic } from 'utils/convertNumerics'
import { setFontFamily, setFontSize, setIsEditing } from 'redux/reducers/pageReducer'
import { useEditorEdit, useEditorFocus } from '_features/editor'
import { getFontSizes } from '_entities/text'
import { replaceTextWithEmoji } from '_features/emojis'
import { checkIfTheCharacterIsNumber, useTextEdit } from '_features/text'
import { IPermissions } from '_entities/user'
import { getBlocks } from 'shared/shareDb'
import { removeSelectedNodesAttrs } from '_widgets/Document'
import { checkIsEditorEmpty, getEditor, getEditorElement } from 'shared/lib'

export interface TextChangeArgs {
  delta: DeltaStatic
  oldDelta: DeltaStatic
  block: block
}

interface Props {
  block: block
  userRole?: IPermissions
  openActionMenu?: () => void
  isWhiteboard?: boolean
  shouldFocusOnRender?: boolean
  onTextChange?: (args: TextChangeArgs) => void
  isPreview?: boolean
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'indent',
  'link',
  'image',
  'align',
  'color',
  'background',
  'font',
  'size',
]

export const getSlashMenuCondition = (editor: Quill) => {
  return editor.getText().trim().startsWith('/')
}

export const getSlashCtaMenuCondition = (editor: Quill) => {
  return editor.getText().trim().toLowerCase().startsWith('/cta')
}

export const useQuill = (props: Props) => {
  // ** State
  const [menuOpen, setMenuOpen] = useState(false)
  const [ctaMenuOpen, setCtaMenuOpen] = useState(false)
  const [isFocused, setFocused] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')

  // ** Redux
  const user = useAppSelector((state) => state.global.user)

  // ** Refs
  const blockRef = useRef(props.block)
  const menuOpenRef = useRef(menuOpen)
  const focusedRef = useRef(isFocused)

  // ** Hooks
  const _json1 = useJson1()
  const _json1Text = useJson1Text()
  const _submit = useSubmit()
  const _updatePage = useUpdatePage()
  const _createBlock = useCreateBlock()
  const _updateBlock = useUpdateBlock()
  const _editorFocus = useEditorFocus()
  const _editorEdit = useEditorEdit()
  const _numberedList = useNumberedList()
  const _textEdit = useTextEdit()
  const dispatch = useAppDispatch()

  useEffect(() => {
    menuOpenRef.current = menuOpen
  }, [menuOpen])

  useEffect(() => {
    focusedRef.current = isFocused
  }, [isFocused])

  useEffect(() => {
    blockRef.current = props.block
  }, [props.block])

  const submitDeltaOps = () => {
    const oldRichText = blockRef.current.data.richText
    if (oldRichText || oldRichText === '') {
      const quillForOldContent = new Quill(document.createElement('div'))
      if (typeof oldRichText !== 'string' && oldRichText.ops) {
        quillForOldContent.setContents(oldRichText)
      } else if (typeof oldRichText === 'string') {
        quillForOldContent.setText(oldRichText)
      }
      const oldContents = quillForOldContent.getContents()
      const editor = getEditor(blockRef.current._id)
      const newContents = editor?.getContents()
      if (oldContents && newContents) {
        const diff = oldContents.diff(newContents)
        if (diff && diff.ops && user) {
          const index = getIndex(props.block)
          if (index === -1) return
          const pageId = getBlockPageId(blockRef.current)
          const blocks = getBlocks(pageId)
          if (
            blockRef.current.data.tag === IBlockTypes.TITLE &&
            blockRef.current._id ===
              blocks?.find((block) => block.data.tag === IBlockTypes.TITLE)?._id
          ) {
            const title = editor?.getText()
            title && editor && editor.getLength() > 1 && _updatePage.updateTitle(title, pageId)
          }
          const finalOp = _json1Text.getEditTextOperation(index, diff.ops)
          _submit.submit(pageId, finalOp, SourceKeys.UPDATE_BLOCK)
        }
      }
    }
  }

  const debounceChange = useMemo(
    () =>
      debounce(() => {
        submitDeltaOps()
      }, EDITOR_DEBOUNCE_TIME),
    [],
  )

  const checkEmptyAndNotList = () => {
    const isEmpty = checkIsEditorEmpty(blockRef.current)
    const previousBlock = getPreviousBlock(blockRef.current)
    const nextBlock = getNextBlock(blockRef.current)
    const isPreviousBlockList = previousBlock && isSomeOfLists(previousBlock)
    const isNextBlockList = nextBlock && isSomeOfLists(nextBlock)

    if (isEmpty && !isSomeOfLists(blockRef.current)) {
      const index = getIndex(props.block)
      if (index > -1) {
        _editorFocus.focusPreviousBlock(blockRef.current)
        const deleteOp = _json1.deleteBlock(index)
        const pageId = getBlockPageId(blockRef.current)
        _submit.submit(pageId, deleteOp, SourceKeys.UPDATE_BLOCK)
        setTimeout(() => {
          if (isPreviousBlockList && isNextBlockList) {
            _numberedList.updateList(nextBlock)
          }
        })
        return
      }
    }
  }

  const checkCaretAtBeginningAndNotList = () => {
    const isEmpty = checkIsEditorEmpty(blockRef.current)
    const editor = getEditor(blockRef.current._id)
    const selection = editor?.getSelection()
    if (
      selection &&
      selection.index === 0 &&
      selection.length === 0 &&
      !isSomeOfLists(props.block) &&
      !isEmpty
    ) {
      const index = getIndex(props.block)
      if (index) {
        _editorEdit.concatContentsWithBlockBefore(blockRef.current, props.block)
        const deleteOp = _json1.deleteBlock(index)
        const pageId = getBlockPageId(blockRef.current)
        _submit.submit(pageId, deleteOp, SourceKeys.UPDATE_BLOCK)

        setTimeout(() => {
          _numberedList.updateList(blockRef.current)
        })

        return
      }
    }
  }

  const checkPreviousBlockIsNumberedList = () => {
    const previousBlock = getPreviousBlock(props.block)

    if (typeof previousBlock?.data.numberInList === 'number') {
      return 'number'
    } else {
      const isRoman = !Number.isNaN(
        convertRomanToArabic(blockRef.current.data.numberInList as string),
      )

      if (isRoman) return 'roman'

      return 'string'
    }
  }

  const handleSpaceForBullets = (character: string) => {
    const previousBlock = getPreviousBlock(props.block)
    let newTag: IBlockTypes | undefined
    if (character === '-' || character === '*') {
      newTag = IBlockTypes.LIST
      _updateBlock.handleSwitchToList(props.block, blockRef.current, newTag)
    } else if (character === '+') {
      newTag = IBlockTypes.CHECKLIST
      _updateBlock.handleSwitchToList(props.block, blockRef.current, newTag)
    } else if (checkIfTheCharacterIsNumber(character) && !isBlockNumberedList(blockRef.current)) {
      newTag = IBlockTypes.NUMBERED_LIST
      if (previousBlock?.data.tag === IBlockTypes.NUMBERED_LIST) {
        if (
          checkPreviousBlockIsNumberedList() === 'roman' ||
          checkPreviousBlockIsNumberedList() === 'string'
        ) {
          const previousNumberBlock = _numberedList.findPreviousNumberBlockInDocument(
            blockRef.current,
          )
          if (!previousNumberBlock?.data.numberInList) return

          _updateBlock.handleSwitchToList(
            props.block,
            blockRef.current,
            newTag,
            (previousNumberBlock.data.numberInList as number) + 1,
            previousNumberBlock?.data.numberInList as number,
          )
          setTimeout(() => {
            _numberedList.updateList(blockRef.current)
          }, 100)
        } else {
          _updateBlock.handleSwitchToList(
            props.block,
            blockRef.current,
            newTag,
            (_numberedList.addNumber(blockRef.current.data.numberInList || 1) as number) || 1,
          )
          setTimeout(() => {
            _numberedList.updateList(blockRef.current)
          }, 100)
        }
      } else {
        _updateBlock.handleSwitchToList(
          props.block,
          blockRef.current,
          newTag,
          checkIfTheCharacterIsNumber(character),
        )
        setTimeout(() => {
          _numberedList.updateList(blockRef.current)
        }, 100)
      }
    }
  }

  const handleTabOnNumberedList = () => {
    let newValue: JsonDoc = 'a'
    const isRoman = !Number.isNaN(
      convertRomanToArabic(blockRef.current.data.numberInList as string),
    )
    const isNumber = typeof blockRef.current.data.numberInList === 'number'
    const isString =
      typeof blockRef.current.data.numberInList === 'string' &&
      Number.isNaN(convertRomanToArabic(blockRef.current.data.numberInList as string))

    if (isNumber && !isString && !isRoman) {
      newValue = String.fromCharCode(97)
    } else if (isString && !isNumber && !isRoman) {
      newValue = convertFromArabicToRoman(1) as string
    } else if (!isString && !isNumber && isRoman) {
      newValue = 1
    }

    const listBeforeTab = _numberedList.getListBefore(blockRef.current)

    const list =
      blockRef.current.data.indent !== undefined &&
      _numberedList.findListWithTheSameIndent(blockRef.current)

    if (listBeforeTab && listBeforeTab.length > 0) {
      _numberedList.splitNumberedList(blockRef.current)
    }

    _numberedList.updateListItem(blockRef.current, newValue)

    if (!list) return

    setTimeout(() => {
      _numberedList.submitUpdatedList(list)
      setTimeout(() => {
        _numberedList.updateList(blockRef.current)
      })
    }, 100)
  }

  const handleShiftTabOnNumberedList = () => {
    let newValue: JsonDoc | undefined = undefined
    const isRoman = !Number.isNaN(
      convertRomanToArabic(blockRef.current.data.numberInList as string),
    )
    const isNumber = typeof blockRef.current.data.numberInList === 'number'
    const isString = typeof blockRef.current.data.numberInList === 'string'

    if (
      isNumber &&
      !isString &&
      !isRoman &&
      props.block.data.indent &&
      props.block.data.indent > 0
    ) {
      const previousRomanBlock = _numberedList.findPreviousNumberBlockInDocument(blockRef.current)
      newValue = convertFromArabicToRoman(
        (previousRomanBlock?.data.numberInList as number) + 1,
      ) as string
    } else if (isString && !isNumber && !isRoman) {
      const previousNumberedBlock = _numberedList.findPreviousNumberBlockInDocument(
        blockRef.current,
      )
      newValue = (previousNumberedBlock?.data.numberInList as number) + 1
    } else if (!isNumber && isRoman) {
      const previousCharBlock = _numberedList.findPreviousCharacterBlockInDocument(blockRef.current)
      newValue = String.fromCharCode(
        (previousCharBlock?.data.numberInList as string)?.charCodeAt(0) + 1,
      )
    }

    const list =
      blockRef.current.data.indent !== undefined &&
      _numberedList.findListWithTheSameIndent(blockRef.current)

    if (!list) return

    if (blockRef.current.data.indent && blockRef.current.data.indent - INDENTATION === 0) {
      newValue = 1
    }

    const listAfterTab = _numberedList.getListAfter(blockRef.current)

    _numberedList.updateListItem(blockRef.current, newValue)

    setTimeout(() => {
      _numberedList.submitUpdatedList(list)
      setTimeout(() => {
        _numberedList.updateList(blockRef.current)
        setTimeout(() => {
          if (!listAfterTab) return
          _numberedList.submitUpdatedList(listAfterTab)
        }, 100)
      }, 100)
    }, 100)
  }

  const transferToTextAndSplitList = () => {
    if (blockRef.current.data.indent && blockRef.current.data.indent > 0) {
      setTimeout(() => {
        blockRef.current.data.indent !== undefined &&
          _numberedList.updateIndent(blockRef.current, blockRef.current.data.indent - INDENTATION)
        setTimeout(() => {
          const previousBlock =
            blockRef.current.data.indent !== undefined
              ? _numberedList.findBlockWithSameIndentInPreviousBlocks(
                  blockRef.current,
                  blockRef.current.data.indent,
                )
              : undefined

          previousBlock && _numberedList.updateList(previousBlock)
        }, 100)
      })
    } else {
      _updateBlock.changeToTextBlock(blockRef.current)
      _numberedList.splitNumberedList(blockRef.current)
    }
  }

  const handleCheckListOnEnter = (textContentLength: number) => {
    !textContentLength
      ? _updateBlock.changeToTextBlock(blockRef.current)
      : _createBlock.createDocumentBlock(blockRef.current, IBlockTypes.CHECKLIST)

    const blockElement = getEditorElement(blockRef.current._id)
    if (blockElement) {
      const editor: Quill = Quill.find(blockElement)
      const content = editor.getContents()
      if (content.ops && content.ops[0].insert[0] === '/') {
        editor.deleteText(0, 1, 'user')
      }
    }
  }

  const handleNumberedListOnEnter = (textContentLength: number) => {
    if (!textContentLength) {
      transferToTextAndSplitList()
    } else {
      _createBlock.createDocumentBlock(blockRef.current, IBlockTypes.NUMBERED_LIST)

      setTimeout(() => {
        _numberedList.updateList(blockRef.current)
      })
    }
  }

  const handleListOnEnter = (textContentLength: number) => {
    !textContentLength
      ? _updateBlock.changeToTextBlock(blockRef.current)
      : _createBlock.createDocumentBlock(blockRef.current, IBlockTypes.LIST)
  }

  const handleEnterKeyDown = () => {
    const editor = getEditor(props.block._id)
    if (!editor) return
    const selection = editor.getSelection()
    let textContentLength = 0
    textContentLength = editor.getLength() - 1

    setTimeout(() => {
      if (selection?.index === 0 && textContentLength && isSomeOfLists(props.block)) {
        _createBlock.createDocumentBlock(blockRef.current, blockRef.current.data.tag as IBlockTypes)
        setTimeout(() => {
          _numberedList.updateList(blockRef.current)
        })
        return
      } else if (blockRef.current.data.tag === IBlockTypes.LIST) {
        handleListOnEnter(textContentLength)
        return
      } else if (blockRef.current.data.tag === IBlockTypes.CHECKLIST) {
        handleCheckListOnEnter(textContentLength)
        return
      } else if (blockRef.current.data.tag === IBlockTypes.NUMBERED_LIST) {
        handleNumberedListOnEnter(textContentLength)
        return
      } else if (selection?.index === 0 && textContentLength && isNotAnyKindOfList(props.block)) {
        _createBlock.createDocumentBlock(blockRef.current, IBlockTypes.TEXT)
        return
      } else {
        _createBlock.createDocumentBlock(blockRef.current, IBlockTypes.TEXT)
      }
      removeSelectedNodesAttrs(getBlockPageId(blockRef.current))
    }, ENTER_PRESS_DELAY_TO_GET_REAL_TIME_VALUE)
  }
  const options: QuillOptionsStatic = {
    modules: {
      clipboard: {
        matchVisual: true,
      },
      toolbar: {
        container: null,
      },
      keyboard: {
        bindings: {
          enter: {
            key: 13,
            shiftKey: false,
            handler: () => {
              if (props.isWhiteboard) return true
              handleEnterKeyDown()
            },
          },
          'header enter': {
            key: 13,
            shiftKey: false,
            handler: () => {
              if (props.isWhiteboard) return true
              handleEnterKeyDown()
            },
          },
          tab: {
            key: 9,
            shiftKey: false,
            handler: () => {
              if (blockRef.current.data.tag !== IBlockTypes.NUMBERED_LIST) return true
              handleTabOnNumberedList()
            },
          },
          'tab+shift': {
            key: 9,
            shiftKey: true,
            handler: () => {
              if (blockRef.current.data.tag !== IBlockTypes.NUMBERED_LIST) return true
              handleShiftTabOnNumberedList()
            },
          },
          backspace: {
            key: 8,
            handler: () => {
              if (!props.isWhiteboard) {
                checkEmptyAndNotList()
                _editorEdit.handleEmptyList(blockRef.current)
                checkCaretAtBeginningAndNotList()
                _editorEdit.checkCaretAtBeginningAndList(blockRef.current)
              }
              return true
            },
          },
          space: {
            key: 32,
            handler: (range: RangeStatic, context: { prefix: string }) => {
              const isList = isSomeOfLists(blockRef.current)

              if (
                (((context.prefix === '-' || context.prefix === '*' || context.prefix === '+') &&
                  range.index === 1) ||
                  (checkIfTheCharacterIsNumber(context.prefix) &&
                    !isBlockNumberedList(blockRef.current) &&
                    range.index === 2)) &&
                !isList
              ) {
                handleSpaceForBullets(context.prefix)
                return false
              } else return true
            },
          },
          arrowUp: {
            key: 38,
            handler: () => {
              if (props.isWhiteboard) return true
              if (!menuOpenRef.current) {
                const blocks = getBlocks(getBlockPageId(blockRef.current))
                blocks && _editorFocus.handleArrowUp(blockRef.current)
              } else {
                return
              }
            },
          },
          arrowDown: {
            key: 40,
            handler: () => {
              if (props.isWhiteboard) return true
              if (!menuOpenRef.current) {
                _editorFocus.handleArrowDown(blockRef.current)
              } else {
                setFocused(true)
                return
              }
            },
          },
        },
      },
    },

    readOnly:
      blockRef.current.meta.relationshipType === 'mirror' ||
      props.userRole === IPermissions.CAN_VIEW ||
      props.userRole === IPermissions.CAN_COMMENT ||
      props.isPreview,
    theme: 'snow',
    formats,
  }

  const handleBlur = () => {
    setMenuOpen(false)
    setFocused(false)
  }

  const initiateQuill = () => {
    const Font = Quill.import('formats/font')
    const Size = Quill.import('formats/size')
    Font.whitelist = ['inconsolata', 'roboto', 'montserrat', 'arial', 'pacifico', 'mirza', 'ptsans']
    Size.whitelist = getFontSizes()
    Quill.register(Size, true)
    Quill.register(Font, true)
    const editorElement = getEditorElement(props.block._id)
    if (editorElement) {
      const elements = document.querySelectorAll(`#quill-editor-${props.block._id}`)

      const editors: Quill[] = []
      elements.forEach((element) => {
        const editor = new Quill(element, options)
        editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) =>
          _textEdit.removeHighlight(node, delta, editor),
        )
        editors.push(editor)
      })

      editors?.map((editor) => {
        handleInitialContents(editor)
        handleEditorListeners(editor)
        handleInitialFocus(editor)
        handleInitialHeaderFormatting(editor)
        handleLinkTooltip()
      })
    }
  }

  const handleInitialContents = (editor: Quill) => {
    if (props.block.data.richText && typeof props.block.data.richText !== 'string')
      editor.setContents(props.block.data.richText)
    else if (props.block.data.richText && typeof props.block.data.richText === 'string')
      editor.setText(props.block.data.richText)
  }

  const handleSlashMenuOnTextChange = (editor: Quill) => {
    if (getSlashCtaMenuCondition(editor)) {
      setMenuOpen(false)
      setCtaMenuOpen(true)
    } else if (getSlashMenuCondition(editor)) {
      setCtaMenuOpen(false)
      setMenuOpen(true)
    } else {
      setCtaMenuOpen(false)
      setMenuOpen(false)
      setFocused(false)
    }
  }

  const handleSearchTextOnTextChange = (editor: Quill) => {
    setSearchText(editor.getText().replaceAll('/', ''))
  }

  const handleEditorListeners = (editor: Quill) => {
    editor.on('text-change', (delta, oldDelta, source) => {
      if (source !== 'user') return

      // Prevent Quill from deleting format when deleting text from the entire line
      _textEdit.handlePreventFormatDelete(editor)

      replaceTextWithEmoji(editor)

      props.onTextChange && props.onTextChange({ delta, oldDelta, block: blockRef.current })
      handleSlashMenuOnTextChange(editor)
      handleSearchTextOnTextChange(editor)
      debounceChange()
    })

    editor.on('selection-change', (range) => {
      // Handle focusout
      if (range === null) {
        handleBlur()
      }

      if (range?.length === 0) {
        const sizeFormat = editor.getFormat(range.index, 1)?.size
        const fontFormat = editor.getFormat(range.index, 1)?.font
        if (fontFormat) {
          dispatch(setFontFamily(fontFormat))
        }

        if (sizeFormat) {
          dispatch(setFontSize(parseInt(sizeFormat.replace('px', ''))))
        }
      }

      // Handle selection of text
      if (range && range.length !== 0) {
        props.openActionMenu && props.openActionMenu()
        dispatch(setIsEditing(true))

        const sizeFormat = editor.getFormat(range.index, range.length)?.size
        const fontFormat = editor.getFormat(range.index, range.length)?.font

        if (sizeFormat) {
          if (isArray(sizeFormat)) {
            dispatch(setFontSize(undefined))
          } else {
            dispatch(setFontSize(parseInt(sizeFormat.replace('px', ''))))
          }
        }

        if (fontFormat) {
          if (isArray(fontFormat)) {
            dispatch(setFontFamily(undefined))
          } else {
            dispatch(setFontFamily(editor.getFormat(range.index, range.length).font))
          }
        }
      }
    })
  }

  const handleInitialHeaderFormatting = (editor: Quill) => {
    const blocks = getBlocks(getBlockPageId(props.block))
    if (!props.block.data.richText && blocks?.length === 1) {
      editor.format('header', 1, 'user')
    }
  }

  const handleInitialFocus = (editor: Quill) => {
    const blocks = getBlocks(getBlockPageId(props.block))
    !props.isWhiteboard && blocks?.length === 1 && editor.focus()
    if (props.shouldFocusOnRender) {
      editor.focus()
    }
  }

  const handleLinkTooltip = () => {
    const editorElement = getEditorElement(props.block._id)
    const linkTooltip = editorElement?.querySelector('.ql-tooltip')
    if (linkTooltip) {
      if (props.isWhiteboard) {
        handleLinkTooltipInWhiteboard(linkTooltip)
      } else {
        handleLinkTooltipInEditor(linkTooltip)
      }
    }
  }

  const handleLinkTooltipInWhiteboard = (linkTooltip: Element | null | undefined) => {
    // We prevent mousedown event to prevent whiteboard mousedown on stage which would deselect our block
    linkTooltip?.addEventListener('mousedown', (event) => {
      event.stopPropagation()
    })
  }

  const handleLinkTooltipInEditor = (linkTooltip: Element | null | undefined) => {
    // We stop propagation of paste event to prevent the global pasting handler on th block from triggering
    linkTooltip?.addEventListener('paste', (event) => {
      event.stopPropagation()
    })
  }

  return {
    ctaMenuOpen,
    menuOpen,
    isFocused,
    searchText,
    initiateQuill,
    setFocused,
  }
}
