import Dots from 'components/atoms/Dots/Dots'
import { CustomDropdown } from 'components/molecules/CustomDropdown/CustomDropdown'
import {
  ChevronRightIconRotating,
  SidebarListItemContainer,
  SidebarListItemInput,
  SidebarListItemText,
  Divider,
  SidebarListItemIcon as SidebarListItemIconBase,
} from 'components/molecules/SidebarList/styles'
import { setFreshlyCreatedPageId } from 'redux/reducers/projectFileReducer'
import { getColor } from 'shared/colors'
import { Spinner } from 'react-bootstrap'
import {
  SidebarListItemIcon,
  StyledPageWrapper,
} from '_widgets/LeftSidebar/ui/PagesNavigation/styles'
import { getPageReferenceId, usePageReference } from '_entities/page/model/usePageReference'
import { PageReferenceProps } from '_entities/page'
import { useIcons, Icon } from 'shared/icons'

const PageReference = (props: PageReferenceProps) => {
  const _draggablePage = usePageReference({
    handleMoveModalOpen: props.handleMoveModalOpen,
    node: props.node,
    isViewOnly: props.isViewOnly,
    setEmojiNodeElement: props.setEmojiNodeElement,
  })
  const _icons = useIcons()

  return (
    <StyledPageWrapper
      depth={props.depth}
      id={getPageReferenceId(props.node.id)}
      isDropTarget={props.isDropTarget}
      ref={_draggablePage.ref}
      onDrop={props.onDrop}
      active={
        props.node &&
        _draggablePage.selectedPage &&
        props.node.id === _draggablePage.selectedPage.id
      }
      onMouseEnter={() => _draggablePage.setIsHovered(true)}
      onMouseLeave={() => _draggablePage.setIsHovered(false)}
    >
      <ChevronRightIconRotating
        turn={props.isOpen}
        onClick={(e) => {
          e.stopPropagation()
          props.onToggle()
        }}
        hide={!props.hasChild}
        id={props.node.id}
      />

      <SidebarListItemContainer>
        <SidebarListItemIconBase
          hoverGray
          onClick={_draggablePage.handleOnIconClick}
          id={props.node.id}
        >
          <Icon Icon={_icons.getIcon({ pageNode: props.node })} />
        </SidebarListItemIconBase>
        {(!props.isViewOnly && _draggablePage.renameInputPosition > 0) ||
        _draggablePage.freshlyCreatedPageId === props.node.id ? (
          <SidebarListItemInput
            id={props.node.id}
            active={
              props.node &&
              _draggablePage.selectedPage &&
              props.node.id === _draggablePage.selectedPage.id
            }
            type='text'
            autoFocus
            defaultValue={props.node.text}
            ref={_draggablePage.inputRef}
            onKeyDown={(event) => _draggablePage.handleRenamePage(event)}
            onBlur={() => {
              _draggablePage.setRenameInputPosition(0)
              _draggablePage.dispatch(setFreshlyCreatedPageId(undefined))
            }}
            onFocus={() => {
              setTimeout(() => {
                _draggablePage.inputRef.current?.select()
              }, 100)
            }}
            onChange={(event) => _draggablePage.handlePageNameChange(event)}
          />
        ) : (
          <SidebarListItemText onClick={_draggablePage.handlePageClick} id={props.node.id}>
            <Divider id={props.node.id}>{_draggablePage.pageName || props.node.text}</Divider>
          </SidebarListItemText>
        )}
      </SidebarListItemContainer>
      <SidebarListItemIcon>
        {!_draggablePage.isSummaryLoading && _draggablePage.hovered && (
          <CustomDropdown
            onToggle={() => _draggablePage.setRenameInputPosition(0)}
            positionDropdown='initial'
            toggleButton={<Dots color={getColor('--secondary-text')} />}
            dropdownOptions={_draggablePage.dropdownOptions}
            transitionDropdownMenu='none'
            menuPadding='0'
          />
        )}

        {_draggablePage.isSummaryLoading && <Spinner size='sm' />}
      </SidebarListItemIcon>
    </StyledPageWrapper>
  )
}

export default PageReference
