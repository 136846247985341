import { type block } from '_entities/block'
import { DeltaOperation } from 'quill'
import { TextFontSizes } from './types'
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html'
import { getEditor } from 'shared/lib'

export const QuillToHtmlConfig = {
  paragraphTag: '',
  encodeHtml: true,
}

export const formattedTextFontSize: any = {
  [TextFontSizes.H1]: 48,
  [TextFontSizes.H2]: 38,
  [TextFontSizes.H3]: 36,
  [TextFontSizes.H4]: 28,
  [TextFontSizes.H5]: 21,
  [TextFontSizes.H6]: 17,
  [TextFontSizes.P]: 18,
}

export const useText = () => {
  const getHeaderFormat = (block: block) => {
    let headerFormat = 0
    const richText = block.data.richText
    if (richText && typeof richText !== 'string') {
      const ops = richText.ops
      ops?.forEach((op: DeltaOperation) => {
        headerFormat = op.attributes?.header
      })
      return headerFormat
    }
  }

  const hasText = (block: block) => {
    const editor = getEditor(block._id)
    const textLength = editor?.getLength()
    const shapeHasText = textLength && textLength > 1
    return !!shapeHasText
  }

  const convertDeltaToHtml = (delta: DeltaOperation[]) => {
    const converter = new QuillDeltaToHtmlConverter(delta, QuillToHtmlConfig)
    return converter.convert()
  }

  const isDeleting = (delta: DeltaOperation[]) => {
    let isDeleting = false
    delta.forEach((op: DeltaOperation) => {
      if ('delete' in op) {
        isDeleting = true
      }
    })
    return isDeleting
  }

  const getText = (block: block) => {
    const editor = getEditor(block._id)
    return editor?.getText()
  }

  return {
    getHeaderFormat,
    hasText,
    convertDeltaToHtml,
    isDeleting,
    getText,
  }
}
