import * as React from 'react'
import { RenderGoToPageProps } from '@react-pdf-viewer/page-navigation'
import { ToolbarSlot } from '@react-pdf-viewer/toolbar'
import {
  RenderCurrentScaleProps,
  RenderZoomInProps,
  RenderZoomOutProps,
} from '@react-pdf-viewer/zoom'

import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/toolbar/lib/styles/index.css'
import { StyledPdfToolbar, StyledPdfToolbarWrapper } from './styles'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { icons } from 'shared/icons'
import { VerticalLine } from 'shared/ui/VerticalLine'
import { Plugin } from '@react-pdf-viewer/core'
import { ToolbarProps } from '@react-pdf-viewer/default-layout'

interface CustomToolbarExampleProps {
  fileUrl: string
  downloadFile: (url: string, name: string) => void
  name: string
  toolbarPluginInstance: ToolbarPlugin
}

interface ToolbarPlugin extends Plugin {
  Toolbar: React.FC<ToolbarProps>
}

export const PdfToolbar: React.FC<CustomToolbarExampleProps> = (
  props: CustomToolbarExampleProps,
) => {
  const { Toolbar } = props.toolbarPluginInstance
  const { downloadFile, fileUrl, name } = props

  return (
    <StyledPdfToolbar>
      <Toolbar>
        {(props: ToolbarSlot) => {
          return (
            <StyledPdfToolbarWrapper>
              <Wrapper>
                <Wrapper cursor='pointer' onClick={() => downloadFile(fileUrl, name)}>
                  <icons.download />
                </Wrapper>
              </Wrapper>
              <VerticalLine margin={'12px'} />
              <Wrapper>
                <props.ZoomOut>
                  {(props: RenderZoomOutProps) => (
                    <Wrapper cursor='pointer' onClick={props.onClick}>
                      <icons.minus />
                    </Wrapper>
                  )}
                </props.ZoomOut>
              </Wrapper>
              <Wrapper>
                <props.CurrentScale>
                  {(props: RenderCurrentScaleProps) => (
                    <span>{`${Math.round(props.scale * 100)}%`}</span>
                  )}
                </props.CurrentScale>
              </Wrapper>
              <Wrapper>
                <props.ZoomIn>
                  {(props: RenderZoomInProps) => (
                    <Wrapper cursor='pointer' onClick={props.onClick}>
                      <icons.plus />
                    </Wrapper>
                  )}
                </props.ZoomIn>
              </Wrapper>
              <VerticalLine margin={'12px'} />
              <Wrapper>
                <props.GoToPreviousPage>
                  {(props: RenderGoToPageProps) => (
                    <Wrapper cursor='pointer' onClick={props.onClick}>
                      <icons.arrowUp />
                    </Wrapper>
                  )}
                </props.GoToPreviousPage>
              </Wrapper>
              <Wrapper>
                <props.CurrentPageInput />
              </Wrapper>
              <Wrapper>
                / <props.NumberOfPages />
              </Wrapper>
              <Wrapper padding={'0 24px 0 0'}>
                <props.GoToNextPage>
                  {(props: RenderGoToPageProps) => (
                    <Wrapper cursor='pointer' onClick={props.onClick}>
                      <icons.arrowDown />
                    </Wrapper>
                  )}
                </props.GoToNextPage>
              </Wrapper>
            </StyledPdfToolbarWrapper>
          )
        }}
      </Toolbar>
    </StyledPdfToolbar>
  )
}
