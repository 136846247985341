import React from 'react'
import { useSpaceUserModalAccountTab } from 'services/space/useSpaceUserModalAccountTab'
import ChangeEmailModal from '../ChangeEmailModal/ChangeEmailModal'
import ChangeNameModal from '../ChangeNameModal/ChangeNameModal'
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal'
import {
  AccountTabUserModalStyled,
  EmptyDiv,
  FlexWrapper,
  ModalChangeButton,
  ModalDangerButton,
  ModalInfoLabel,
  ValueAndButtonContainer,
} from './styles'
import { useAppSelector } from 'redux/hooks'

type Props = {
  onHide?: () => void
}

const AccountTabUserModal: React.FC<Props> = ({ onHide }) => {
  const {
    showChangeEmailModal,
    setChangeEmailModal,
    showChangeNameModal,
    setChangeNameModal,
    showChangeNPasswordModal,
    setChangePasswordModal,
    setPassword,
    setNewPassword,
    setOldPassword,
    setConfirmNewPassword,
    newPassword,
    confirmNewPassword,
    setNewEmail,
    newEmail,
    name,
    setName,
    surname,
    setSurname,
    onCloseChangeEmailModal,
    saveChangeEmail,
    onShowChangeModal,
    onCloseChangePasswordModal,
    saveChangePassword,
    onCloseChangeNameModal,
    saveChangeName,
    openOtherQuestionForDeleteAccount,
    setOpenOtherQuestionForDeleteAccount,
    deleteAccount,
    logoutAccount,
  } = useSpaceUserModalAccountTab(onHide)
  const user = useAppSelector((state) => state.global.user)
  if (!user) return null
  return (
    <AccountTabUserModalStyled>
      <ModalInfoLabel>Full name</ModalInfoLabel>
      <ValueAndButtonContainer>
        <EmptyDiv>{user.firstName + ' ' + user.lastName}</EmptyDiv>
        <ModalChangeButton onClick={() => setChangeNameModal(true)}>Change name</ModalChangeButton>
        <ChangeNameModal
          onShow={onShowChangeModal}
          show={showChangeNameModal}
          handleClose={onCloseChangeNameModal}
          saveChanges={saveChangeName}
          setName={setName}
          name={name}
          user={user}
          setSurname={setSurname}
          surname={surname}
          placeholder='Enter a new firstname'
          additionalPlaceholder='Enter a new lastname'
        />
      </ValueAndButtonContainer>
      <ModalInfoLabel>Email</ModalInfoLabel>
      <ValueAndButtonContainer>
        <EmptyDiv>{user.email}</EmptyDiv>
        <ModalChangeButton onClick={() => setChangeEmailModal(true)}>
          Change email
        </ModalChangeButton>
        <ChangeEmailModal
          onShow={onShowChangeModal}
          show={showChangeEmailModal}
          handleClose={onCloseChangeEmailModal}
          saveChanges={saveChangeEmail}
          setPassword={setPassword}
          setNewEmail={setNewEmail}
          email={newEmail}
          user={user}
          placeholder='Enter email'
          additionalPlaceholder='Enter password'
        />
      </ValueAndButtonContainer>
      <ModalInfoLabel>Password</ModalInfoLabel>
      <ValueAndButtonContainer marginBottom='0.5rem'>
        <EmptyDiv>****</EmptyDiv>
        <ModalChangeButton onClick={() => setChangePasswordModal(true)}>
          Change password
        </ModalChangeButton>
        <ChangePasswordModal
          onShow={onShowChangeModal}
          show={showChangeNPasswordModal}
          handleClose={onCloseChangePasswordModal}
          saveChanges={saveChangePassword}
          setConfirmNewPassword={setConfirmNewPassword}
          setNewPassword={setNewPassword}
          setOldPassword={setOldPassword}
          newPassword={newPassword}
          confirmNewPassword={confirmNewPassword}
        />
      </ValueAndButtonContainer>
      <hr className='colorForHr' />
      <FlexWrapper>
        <ModalInfoLabel>Log out on all other devices</ModalInfoLabel>
        <ModalDangerButton onClick={logoutAccount}>Log out</ModalDangerButton>
      </FlexWrapper>
      <hr className='colorForHr' />
      <FlexWrapper>
        <ModalInfoLabel>Saying bye bye</ModalInfoLabel>
        <ModalDangerButton onClick={() => setOpenOtherQuestionForDeleteAccount((prev) => !prev)}>
          Delete my account
        </ModalDangerButton>
      </FlexWrapper>

      {openOtherQuestionForDeleteAccount && (
        <div>
          <ModalInfoLabel marginTop='1rem'>
            Are you sure you want to finish this action? You cannot get your account and data back
            if you confirm deleting.
          </ModalInfoLabel>
          <ModalInfoLabel
            colour='var(--everyday-red) !important'
            cursor='pointer'
            padding='0.25rem 0'
            paddingHover='0.25rem 0.5rem'
            backgroundHover='var(--hover-gray)'
            marginTop='0.5rem'
            onClick={deleteAccount}
          >
            Yes, delete my account permanently
          </ModalInfoLabel>
        </div>
      )}
    </AccountTabUserModalStyled>
  )
}

export default AccountTabUserModal
