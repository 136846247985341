import { useCallback } from 'react'
import Quill from 'quill'
import { getBlockPageId, getPreviousBlock, type block } from '_entities/block'
import { getBlocks } from 'shared/shareDb'
import {
  findEditorInNextBlocks,
  findEditorInPreviousBlocks,
  findFirstEmptyParagraph,
  getEditor,
} from 'shared/lib'

export const useEditorFocus = () => {
  const focusPreviousBlock = useCallback((block: block) => {
    const previousBlock = getPreviousBlock(block)
    if (previousBlock) {
      const previousEditor = getEditor(previousBlock._id)
      previousEditor?.setSelection(previousEditor.getLength() - 1, 0)
    }
  }, [])

  const handleArrowUp = useCallback((block: block) => {
    const editor: Quill | undefined = getEditor(block._id)
    if (editor) {
      const selection = editor.getSelection()?.index
      if (selection !== undefined) {
        const blocks = getBlocks(getBlockPageId(block))
        const index = blocks?.findIndex((b) => b._id === block._id)
        if (index === -1) return
        const previousEditor = findEditorInPreviousBlocks(block)
        if (previousEditor) {
          previousEditor.setSelection(selection, 0)
        }
      }
    }
  }, [])

  const handleArrowDown = useCallback((block: block) => {
    const editor: Quill | undefined = getEditor(block._id)
    if (editor) {
      const selection = editor.getSelection()?.index
      if (selection !== undefined) {
        const blocks = getBlocks(getBlockPageId(block))
        const index = blocks?.findIndex((b) => b._id === block._id)
        if (index === -1) return
        const nextEditor = findEditorInNextBlocks(block)
        if (nextEditor) {
          nextEditor.setSelection(selection, 0)
        }
      }
    }
  }, [])

  const focusFirstEmptyParagraph = (pageId: string) => {
    const firsParagraph = findFirstEmptyParagraph(pageId)

    if (firsParagraph) {
      firsParagraph.focus()
    }
  }

  return {
    focusPreviousBlock,
    handleArrowUp,
    handleArrowDown,
    focusFirstEmptyParagraph,
  }
}
