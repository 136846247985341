// ** Type Imports
import { ctaApi, TAGS } from 'shared/api'
import { CtaButton } from '_entities/cta'
import {
  CreateCtaButtonRequest,
  GetCtaButtonsRequest,
  UpdateCtaButtonRequest,
  UpdateCtaButtonRequestBody,
} from '../model'

export const ctaEndpoints = ctaApi.injectEndpoints({
  endpoints: (build) => ({
    createCta: build.mutation<CtaButton, CreateCtaButtonRequest>({
      query: ({ projectFileId, title, onClickAction, link }) => ({
        url: `${projectFileId}/widgets`,
        method: 'POST',
        body: {
          type: 'cta-button',
          title,
          onClickAction,
          link,
        },
      }),
      invalidatesTags: [TAGS.CTA],
    }),
    getCtaButtons: build.query<
      {
        data: CtaButton[]
        meta: {
          total: number
          page: number
          limit: number
          hasNextPage: boolean
        }
      },
      GetCtaButtonsRequest
    >({
      query: ({ projectFileId, page, limit }) => ({
        url: `${projectFileId}/widgets?page=${page}&limit=${limit}`,
        method: 'GET',
      }),
      providesTags: [TAGS.CTA],
    }),
    updateCta: build.mutation<CtaButton, UpdateCtaButtonRequest>({
      query: ({ projectFileId, id, title, onClickAction, link, styling }) => {
        const body: UpdateCtaButtonRequestBody = {
          title,
          type: 'cta-button',
          onClickAction,
          link,
        }

        if (styling) {
          body.styling = styling
        }

        return {
          url: `${projectFileId}/widgets/${id}`,
          method: 'PATCH',
          body,
        }
      },
      invalidatesTags: [TAGS.CTA],
    }),
    getCtaButtonById: build.query<CtaButton, { projectFileId: string; id: number }>({
      query: ({ projectFileId, id }) => ({
        url: `${projectFileId}/widgets/${id}`,
        method: 'GET',
      }),
      providesTags: [TAGS.CTA],
    }),
    deleteCtaButton: build.mutation<{ id: number }, { projectFileId: string; id: number }>({
      query: ({ projectFileId, id }) => ({
        url: `${projectFileId}/widgets/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS.CTA],
    }),
  }),
})
