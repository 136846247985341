import { useEffect } from 'react'
import { type block, checkIsFirstBlockFullBleed } from '_entities/block'
import Loader from 'components/atoms/Loader/Loader'
import { DocumentBlock, setIsBlockSelected } from '_widgets/DocumentBlock'
import BlocksDragPlaceholder from 'components/atoms/DragPlaceholder/BlocksDragPlaceholder/BlocksDragPlaceholder'
import { useShareDbSession } from '_entities/page'
import { useHash } from 'shared/lib'
import { DropzoneRootProps, useDropzone } from 'react-dropzone'
import useBlockUpload from '_entities/block/model/useBlockUpload'
import DraggableTree from 'shared/dragging/ui/DraggableTree'
import { TreeItem } from '_entities/tree/model/types'
import { ROOT_ID } from 'shared/dragging/model/useDragging'
import { useBlockDrag, useCreateBlock } from '_features/block'
import { EditorContainer } from './styles'
import { getDocumentContainerId } from '../lib/getters'
import { getBlocks, getRole } from 'shared/shareDb'
import { getSelectedBlockNodes } from '../model/selected-blocks'
import { useMultipleSelection } from '../model/useMultipleSelection'

interface Props {
  pageId: string
  isWhiteboard?: boolean
  isEmbed?: boolean
  isPreview?: boolean
}

export const DEFAULT_DOCUMENT_PADDING = '60px'

export const Document = (props: Props) => {
  // ** Hooks
  const { blocks } = useShareDbSession({
    pageId: props.pageId,
    isPreview: props.isPreview,
  })
  const _createBlock = useCreateBlock()
  const _blockUpload = useBlockUpload()
  const _hash = useHash()
  const _dropzone = useDropzone({
    onDrop: (acceptedFiles) => _blockUpload.handleFileOnDrop(props.pageId, acceptedFiles),
    noClick: true,
  })
  const _blockDrag = useBlockDrag()
  const _multipleSelection = useMultipleSelection()

  useEffect(() => {
    focusBlockFromHash(props.pageId)
  }, [blocks])

  const focusBlockFromHash = (pageId: string) => {
    const id = _hash.getBlockIdFromHash()
    if (!id) return
    const element = document.getElementById(id)
    const block = getBlocks(pageId)?.find((block) => block._id === id)
    if (element && block) {
      element.scrollIntoView({ behavior: 'smooth' })
      setIsBlockSelected(block)
      _hash.removeHash()
    }
  }

  const modifiedDataForTree: TreeItem[] | undefined = blocks?.map((block, i) => {
    return {
      id: block._id,
      parent: ROOT_ID,
      text: '',
      data: block,
      positionIndex: i + 1,
      type: 'block',
      depth: 0,
      isShown: true,
    }
  })

  if (blocks) {
    return (
      <>
        <EditorContainer
          data-blocks={JSON.stringify(blocks)}
          id={getDocumentContainerId(props.pageId)}
          tabIndex={0}
          className='PageBoundaryElementDetector'
          shadow='none'
          onMouseDown={() => _multipleSelection.removeSelection(props.pageId)}
          onKeyDown={_multipleSelection.pageMultipleSelectionHandler}
          {...(_dropzone.getRootProps() as <T extends DropzoneRootProps>(props?: T) => T)}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (!getSelectedBlockNodes(props.pageId)?.length)
              _createBlock.createEmptyBlockOnClick(props.pageId)
          }}
          isEmbed={props.isEmbed}
          paddingTop={checkIsFirstBlockFullBleed(blocks[0]) ? '0' : DEFAULT_DOCUMENT_PADDING}
        >
          <input {..._dropzone.getInputProps()} />
          <div id={props.pageId}>
            <DraggableTree
              flexContainer
              onDragStart={_blockDrag.onDragStartHandler}
              onDrop={(args) => _blockDrag.onDocumentDrop({ ...args, pageId: props.pageId })}
              items={modifiedDataForTree || []}
              placeholderRender={() => {
                return <BlocksDragPlaceholder />
              }}
              rootId={ROOT_ID}
              render={(node, { isDragging, handleRef }) => {
                const block = node.data as block
                return (
                  <DocumentBlock
                    block={block}
                    isPreview={props.isPreview}
                    userRole={getRole(props.pageId)}
                    isDropTarget={false}
                    isDragging={isDragging}
                    handleRef={handleRef}
                  />
                )
              }}
              preventDrag={props.isPreview}
            />
          </div>
        </EditorContainer>
      </>
    )
  } else return <Loader />
}
