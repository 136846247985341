import {
  PushIcon,
  StyledDropdown,
  StyledInstanceIcon,
  StyledInstanceIndicator,
  DetachIcon,
  RelationshipDropdownMenu,
} from '../styles'
import RenderBlockInstances from '../RenderBlockInstances'
import { IPages } from 'interfaces/page'
import { type block } from '_entities/block'
import { RelationshipType, useRelationships } from '_features/relationship'
import OriginalInstance from './OriginalInstance'
import { DropdownMenuItem } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import Divider from 'components/atoms/Divider/Divider'

interface IProps {
  hasOriginalObject: boolean
  block: block
  onPageClick: (pageId: string, blockId: string) => void
  pages: IPages[] | undefined
  isMirror: boolean
}

const InstanceIndicator = ({ hasOriginalObject, isMirror, block, onPageClick, pages }: IProps) => {
  const {
    handlePushUpdates,
    handlePullUpdates,
    handleDetachAllFromOriginal,
    handleDetachFromOriginal,
    handleDetachFromRelationship,
    handleSwitchRelationshipType,
  } = useRelationships({ block })
  return (
    <>
      {hasOriginalObject ? (
        <OriginalInstance
          block={block}
          onPageClick={onPageClick}
          pages={pages}
          isMirror={isMirror}
          handlePullUpdates={handlePullUpdates}
          handleDetachFromRelationship={handleDetachFromRelationship}
          handleSwitchRelationshipType={handleSwitchRelationshipType}
        />
      ) : (
        <StyledDropdown
          trigger={
            <StyledInstanceIndicator isMirror={isMirror}>
              <StyledInstanceIcon isScaled />
            </StyledInstanceIndicator>
          }
        >
          <RelationshipDropdownMenu>
            <RenderBlockInstances
              currentBlock={block}
              pages={pages}
              handleDetachFromOriginal={handleDetachFromOriginal}
            />
            <Divider margin={'0px'} width={'100%'} />
            <DropdownMenuItem onClick={(e) => handlePushUpdates(e, block)}>
              <PushIcon />
              Update all instances
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={(e) => handleDetachAllFromOriginal(e, block, RelationshipType.INSTANCE)}
            >
              <DetachIcon />
              Detach all
            </DropdownMenuItem>
          </RelationshipDropdownMenu>
        </StyledDropdown>
      )}
    </>
  )
}

export default InstanceIndicator
