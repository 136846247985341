import { getWhiteboardContainerId } from '_entities/whiteboard'
import { WhiteboardAttrs, WhiteboardAttrsData } from './types'
import { getElemAttr, removeElemAttr, setElemAttr } from 'shared/lib'

export const getCanvasElemAttr = (pageId: string, name: WhiteboardAttrs) => {
  return getElemAttr(getWhiteboardContainerId(pageId), name)
}
export const setCanvasElemAttr = (
  pageId: string,
  name: WhiteboardAttrs,
  value: WhiteboardAttrsData[WhiteboardAttrs] | undefined,
) => {
  setElemAttr(getWhiteboardContainerId(pageId), name, JSON.stringify(value))
}

export const removeCanvasElemAttr = (pageId: string, name: WhiteboardAttrs) => {
  removeElemAttr(getWhiteboardContainerId(pageId), name)
}
