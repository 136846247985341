import React, { useEffect, useState } from 'react'
import { Group, Rect } from 'react-konva'
import { Html } from 'react-konva-utils'
import { type block, WhiteboardBlockProps, getHtmlPortalId, getBlockPageId } from '_entities/block'
import { getColor } from 'shared/colors'
import { useAppSelector } from 'redux/hooks'
import { StyledTextWrapper } from './styles'
import { TextPositioningWrapper, QuillWrapper } from '../Text/style'
import { useQuill } from 'editor/EditableBlock/EditorTextBlock/helpers/useQuill'
import CustomP from 'components/atoms/CustomP/CustomP'
import { ITools } from 'interfaces/whiteboard'
import { constants, GroupNames, useKonvaNode } from '_entities/whiteboard'
import { useShape } from '_entities/shape'
import { useStage, useTransformer } from '_features/canvas'
import { useCreateBlock } from '_features/block'
import {
  getResizableQuillWrapperId,
  getTextPositioningWrapperId,
} from 'whiteboard/Text/ResizableTextBlock'
import { useOverflow } from '_features/text'
import { FocusEdgeLocations, enableEditor, focusEditor, getById } from 'shared/lib'

export const getHtmlWrapperId = (block: block) => {
  return `sticky-html-${block._id}`
}

const StickyNote = (props: WhiteboardBlockProps) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const [editMode, setEditMode] = useState<boolean>(false)
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const _konvaNode = useKonvaNode()
  const _shape = useShape()
  const _stage = useStage()
  const _transformer = useTransformer()
  const _createBlock = useCreateBlock()
  const _overflow = useOverflow()
  const _quill = useQuill({
    block: props.block,
    isWhiteboard: true,
    shouldFocusOnRender: _createBlock.getIsJustCreatedOnCanvas(props.block),
    onTextChange: () => _overflow.reduceOverflowingTextSizeOnChange(props.block),
  })

  const getIsShowCreatedBy = (block: block) => {
    return block.data.showCreatedBy
  }

  const wrapperKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') setTimeout(() => startEditing())
  }

  const startEditing = () => {
    setEditMode(true)
    focusEditor(props.block, FocusEdgeLocations.END)
  }

  const stopEditing = () => {
    setEditMode(false)
  }

  const onClick = () => {
    if (selectedBlock?._id !== props.block._id) {
      const wrapper = getById(getHtmlWrapperId(props.block))
      wrapper?.focus()
    } else startEditing()
  }

  useEffect(() => {
    if (
      (!selectedBlock || selectedBlock._id !== props.block._id) &&
      !_createBlock.getIsJustCreatedOnCanvas(props.block)
    ) {
      stopEditing()
    }
  }, [selectedBlock])

  const getHeight = () => {
    if (getIsShowCreatedBy(props.block)) return constants.STICKY_NOTE_DEFAULT_HEIGHT + 20
    return constants.STICKY_NOTE_DEFAULT_HEIGHT
  }

  const fill = _shape.getShapeFill(props.block)

  return (
    <Group
      id={props.block._id}
      name={GroupNames.BLOCK}
      x={props.block.data.x}
      y={props.block.data.y}
      scaleX={props.block.data.scaleX}
      scaleY={props.block.data.scaleY}
      draggable={!props.isPreview && tool !== ITools.HAND}
      blockType={props.block.data.tag}
      onClick={onClick}
    >
      <Html
        divProps={{
          id: getHtmlPortalId(props.block),
          style: {
            pointerEvents: !props.isPreview && editMode ? 'auto' : 'none',
            zIndex: 0,
          },
        }}
      >
        <StyledTextWrapper
          id={getHtmlWrapperId(props.block)}
          width={constants.STICKY_NOTE_DEFAULT_WIDTH}
          height={getHeight()}
          backgroundColor={fill ?? getColor('--everyday-yellow')}
          onKeyDown={wrapperKeydown}
          tabIndex={0}
          onClick={() => enableEditor(props.block)}
          onMouseDown={(e) => {
            const stage = _stage.getStage(props.block.meta.pageId)
            if (!stage) return
            stage.setPointersPositions(e)
            const group = _konvaNode.getGroupNode(props.block)
            group?.startDrag()
          }}
          onMouseUp={() => {
            const group = _konvaNode.getGroupNode(props.block)
            group?.stopDrag()
          }}
        >
          <QuillWrapper
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            onKeyDown={(e) => e.stopPropagation()}
            id={getResizableQuillWrapperId(props.block)}
          >
            <TextPositioningWrapper id={getTextPositioningWrapperId(props.block)}>
              <div
                ref={(node) => {
                  if (!node || loaded) return
                  _quill.initiateQuill()

                  if (_createBlock.getIsJustCreatedOnCanvas(props.block)) {
                    _transformer.setNodeFromBlockToTransformer(
                      getBlockPageId(props.block),
                      props.block,
                    )
                    focusEditor(props.block, FocusEdgeLocations.START)
                    _createBlock.removeIsJustCreatedOnCanvas(props.block)
                  }

                  setLoaded(true)
                }}
                id={`quill-editor-${props.block._id}`}
              />
            </TextPositioningWrapper>
          </QuillWrapper>
          {props.block.data.showCreatedBy && (
            <CustomP
              fontSize='8px'
              opacity='0.8'
              paddingLeft='8px'
              maxwidth={`${constants.STICKY_NOTE_DEFAULT_WIDTH}px`}
              whiteSpace='nowrap'
              overflow='auto'
            >
              {props.block.data.createdBy ?? 'No user'}
            </CustomP>
          )}
        </StyledTextWrapper>
      </Html>
      <Rect width={constants.STICKY_NOTE_DEFAULT_WIDTH} height={getHeight()} />
    </Group>
  )
}

export default StickyNote
