import { commonGradient, shineAnimation } from 'shared/ui/Shimmer'
import styled from 'styled-components'
import { ReactComponent as AlignIconStyled } from 'assets/images/alignSymbol.svg'
import { ReactComponent as AlignCentreIconStyled } from 'assets/images/alignCentre.svg'
import { ReactComponent as AlignLeftIconStyled } from 'assets/images/alignLeft.svg'
import { ReactComponent as AlignRightIconStyled } from 'assets/images/alignRight.svg'
import { ReactComponent as ResizeLineStyled } from 'assets/images/resizeLine.svg'
import { ReactComponent as DiagonalIconStyled } from 'assets/images/diagonalIcon.svg'
import { ReactComponent as DownloadIconStyled } from 'assets/images/DownloadIcon.svg'
import { ReactComponent as FloatLeftIconStyled } from 'assets/images/floatLeftIcon.svg'
import { ReactComponent as FloatRightIconStyled } from 'assets/images/floatRightIcon.svg'
import { ReactComponent as changeWidthIconStyled } from 'assets/images/wide-item.svg'
import { ReactComponent as changeTextIconStyled } from 'assets/images/text-recognition.svg'
import { ReactComponent as ReplaceImageIconStyled } from 'assets/images/image-replace.svg'

type EditorUploadDividerPropsType = {
  isSelected?: boolean
}

type ImageEditorWrapperPropsType = {
  isSelected?: boolean
  alignPosition?: string
}

type ResizeLinePropsType = {
  left?: string
  right?: string
}

type ImageBorderWrapperPropsType = {
  width?: number | string
}

export const Resizable = styled.div({
  position: 'relative',
})

export const StyledImage = styled.div<{ imageUrl?: string; isWhiteboard?: boolean }>`
  min-width: ${(props) => (props.isWhiteboard ? '624px' : '300px')};
  min-height: 300px;
  width: ${(props) => (props.isWhiteboard ? '624px' : '300px')};
  height: 300px;
  border-radius: 10px;
  background-size: ${(props) => (props.imageUrl || props.isWhiteboard ? 'cover' : '300px')};
  ${({ imageUrl }) =>
    imageUrl ? `background-image: url(${imageUrl});` : `${commonGradient}; ${shineAnimation}`};
`

export const ImageEditorWrapper = styled.div<ImageEditorWrapperPropsType>`
  border: ${({ isSelected }) => (isSelected ? '1px solid var(--primary-color)' : 'none')};
  width: 100% !important;
  overflow: hidden;
  position: relative;
  display: grid;
  place-items: ${({ alignPosition }) => (alignPosition ? alignPosition : 'center')};
  place-content: ${({ alignPosition }) => (alignPosition ? alignPosition : 'center')};
  margin: 0;
  padding: 0;
`
export const ResizeLine = styled(ResizeLineStyled)<ResizeLinePropsType>`
  position: absolute;
  left: ${({ left }) => left || ''};
  right: ${({ right }) => right || ''};
  top: 50%;
  cursor: ew-resize;
  filter: drop-shadow(0rem 0rem 2rem rgba(0, 0, 0, 0.15));
  display: none;
`

export const ImageBorderWrapper = styled.div<ImageBorderWrapperPropsType>`
  width: 100% !important;
  height: auto !important;
  position: relative;
  padding: 0;
  margin: 0;
  width: ${({ width }) => (width ? `${width}px` : '50%')};

  img {
    width: ${({ width }) => (width ? `${width}px` : '')};
    user-select: none;
  }

  ${ResizeLine} {
    display: none;
  }

  &:hover {
    ${ResizeLine} {
      display: block;
    }
  }
`

export const Image = styled.img`
  padding: 0 !important;
  margin: 0 !important;
  width: 100%;
  object-fit: cover;
`

export const EditorUploadDivider = styled.div<EditorUploadDividerPropsType>`
  position: relative;
  overflow: auto;
  padding-block: 0.5rem;
  flex: 1;
  background-color: ${(props) => (props.isSelected ? 'var(--hover)' : '')};

  :focus {
    background-color: var(--hover);
  }

  & * {
    touch-action: none;
  }
`

export const AlignIcon = styled(AlignIconStyled)``

export const ChangeWidthIcon = styled(changeWidthIconStyled)``

export const ChangeTextIcon = styled(changeTextIconStyled)``

export const DiagonalIcon = styled(DiagonalIconStyled)``

export const ReplaceImageIcon = styled(ReplaceImageIconStyled)``

export const DownloadIcon = styled(DownloadIconStyled)``

export const AlignCentreIcon = styled(AlignCentreIconStyled)`
  cursor: pointer;
`

export const AlignLeftIcon = styled(AlignLeftIconStyled)`
  cursor: pointer;
`

export const AlignRightIcon = styled(AlignRightIconStyled)`
  cursor: pointer;
`
export const FloatLeftIcon = styled(FloatLeftIconStyled)`
  cursor: pointer;
`

export const FloatRightIcon = styled(FloatRightIconStyled)`
  cursor: pointer;
`
