import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import FullWidthLogo from '../FullScreenLoader/FullScreenLoader'
import { AxiosService } from 'services/axiosService/axiosService'
import { useToast } from 'services/helpers/useToast'
import { LocalStorageKeys } from 'shared/lib/useLocalStorage'

const EmailVerificationScreen = () => {
  const [isDone, setIsDone] = useState<boolean>(false)
  const [isFail, setIsFail] = useState<boolean>(false)
  const toast = useToast()
  const AxiosInstance = new AxiosService()
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const navigate = useNavigate()

  const sendConfirmation = async () => {
    if (token) {
      const response = await AxiosInstance.confirmEmail(token)
      if (response) {
        toast.success('Email confirmed!')
        setIsDone(true)
        setTimeout(() => {
          navigate('/invitation-check')
        }, 2000)
      } else {
        setIsFail(true)
        setTimeout(() => {
          navigate('/signin')
        }, 2000)
      }
    }
  }
  useEffect(() => {
    sendConfirmation()
  }, [])

  return <FullWidthLogo isDone={isDone} isFail={isFail} />
}

export default EmailVerificationScreen
