import { useEffect, useState } from 'react'
import { type block } from '_entities/block'
import { IUserAttributes } from 'interfaces/auth/userData'
import { useAppSelector } from 'redux/hooks'

interface Props {
  block: block
}

const useCreatedBy = (props: Props) => {
  const { block } = props
  const [createdBy, setCreatedBy] = useState<IUserAttributes | null>(null)
  const spaceMembers = useAppSelector((state) => state.space.currentSpace)?.spaceMembers || []

  useEffect(() => {
    fetchCreatedBy()
  }, [block])

  const fetchCreatedBy = async () => {
    const createdById = block?.meta?.createdBy

    spaceMembers.map((member) => {
      if (member.user.uuid === createdById) {
        setCreatedBy(member.user)
      }
    })
  }

  return {
    createdBy,
  }
}

export default useCreatedBy
