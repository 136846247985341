import CustomButton from 'components/atoms/CustomButton/CustomButton'
import styled from 'styled-components'
import { ModalTitle } from 'react-bootstrap'

export const StyledButtonDanger = styled(CustomButton)`
  color: #ff4a55;
  border-radius: 8px;
  &:hover {
    background-color: #ff4a55;
  }
`

export const StyledButtonCancel = styled(CustomButton)`
  &:hover {
    background-color: transparent;
  }
`

export const StyledModalTitle = styled(ModalTitle)`
  font-size: 16px;
  font-weight: 600;
`
