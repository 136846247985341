import styled from 'styled-components'

export const ProjectMembersWrapper = styled.div`
  max-height: 145px;
  overflow-y: scroll;
  padding: 20px 10px 0 0;
  width: 100%;
`

export const StyledHr = styled.hr`
  width: 100%;
  margin: auto;
  color: #9c9c9c;
`
