import { useEffect, useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import { Setting } from '_entities/settings'
import { hexToRgba } from 'shared/colors'
import { BackgroundContainer, OverlayContainer } from '_entities/project'
import { IPage } from 'interfaces/page'

interface Props {
  page?: IPage
}

export const useBackground = (props: Props) => {
  const [backgroundImage, setBackgroundImage] = useState<string>('')
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const settings = useAppSelector((state) => state.global.settings)

  const blur = props.page && JSON.parse(props.page.backgroundImage || '{}').overlayBlur
  const overlayColor = props.page && JSON.parse(props.page.backgroundImage || '{}').overlayColor
  const overlayOpacity = props.page && JSON.parse(props.page.backgroundImage || '{}').overlayOpacity
  const background = overlayColor && overlayOpacity && hexToRgba(overlayColor, overlayOpacity)

  const getBackgroundNode = () => {
    return (
      <BackgroundContainer
        background={backgroundImage}
        id='document-background'
      ></BackgroundContainer>
    )
  }

  const getOverlayNode = () => {
    return <OverlayContainer background={background} blur={blur}></OverlayContainer>
  }

  useEffect(() => {
    const backgroundImageValue =
      JSON.parse(props.page?.backgroundImage || '{}')?.backgroundImage ||
      JSON.parse(projectFile?.backgroundImage || '{}')?.backgroundImage ||
      JSON.parse(
        settings?.find((setting) => setting.key === Setting.BACKGROUND_IMAGE)?.value || '{}',
      )?.backgroundImage

    const backgroundColorValue =
      JSON.parse(projectFile?.backgroundImage || '{}')?.backgroundColor ||
      JSON.parse(
        settings?.find((setting) => setting.key === Setting.BACKGROUND_IMAGE)?.value || '{}',
      )?.backgroundColor

    const backgroundOpacityValue =
      JSON.parse(projectFile?.backgroundImage || '{}')?.backgroundOpacity ||
      JSON.parse(
        settings?.find((setting) => setting.key === Setting.BACKGROUND_IMAGE)?.value || '{}',
      )?.backgroundOpacity

    if (backgroundColorValue) {
      setBackgroundImage(hexToRgba(backgroundColorValue, backgroundOpacityValue || 1))
      return
    } else if (backgroundImageValue) {
      setBackgroundImage(backgroundImageValue)
      return
    } else {
      setBackgroundImage('')
      return
    }
  }, [props.page])

  return { backgroundImage, getBackgroundNode, getOverlayNode, blur, background }
}
