import React from 'react'
import { Modal } from 'react-bootstrap'
import { ModalTitle } from 'components/molecules/ChangePasswordModal/styles'
import { Option } from 'interfaces/selectOptions'
import { Button } from 'components/molecules/ChangeNameModal/styles'
import { DefaultsType } from 'interfaces/settings'
import { IProjectFile, ISpace } from 'interfaces/space'
import { IPage } from 'interfaces/page'
import DefaultProjectWorkspace from './DefaultProjectWorkspace'
import DefaultFile from './DefaultFile'

interface Props {
  shownModal: DefaultsType | null
  handleCloseModal: () => void
  generateModalHeader: (item: string) => string
  options: ISpace[] | IProjectFile[] | IPage[]
  handleSelectedOption: (e: Option) => void
  selectedDefault: Option | null
  selectedOption: Option | null
  handleShowIcon: (item: DefaultsType | null) => JSX.Element
  handleSubmitDefault: (item: DefaultsType | null) => Promise<void>
  loadOptions: (search: string, loadedOptions: Option[], { page }: { page: number }) => Promise<any>
  loadSpaceOptions: (
    search: string,
    loadedOptions: Option[],
    { page }: { page: number },
  ) => Promise<any>
  loadProjectOptions: (
    search: string,
    loadedOptions: Option[],
    { page }: { page: number },
  ) => Promise<any>
}

const SelectDefaultModal = (props: Props) => {
  return (
    <Modal show={props.shownModal !== null} onHide={props.handleCloseModal}>
      <Modal.Header closeButton className='modalHeader border-0'>
        <ModalTitle>Default {props.generateModalHeader(props.shownModal as string)}</ModalTitle>
      </Modal.Header>
      <Modal.Body className='border-0'>
        {props.shownModal === 'workspace' || props.shownModal === 'project' ? (
          <DefaultProjectWorkspace
            shownModal={props.shownModal}
            options={props.options}
            handleSelectedOption={props.handleSelectedOption}
            selectedDefault={props.selectedDefault}
            selectedOption={props.selectedOption}
            handleShowIcon={props.handleShowIcon}
            loadSpaceOptions={props.loadSpaceOptions}
            loadProjectOptions={props.loadProjectOptions}
          />
        ) : (
          <DefaultFile
            shownModal={props.shownModal}
            options={props.options}
            handleSelectedOption={props.handleSelectedOption}
            selectedDefault={props.selectedDefault}
            selectedOption={props.selectedOption}
            handleShowIcon={props.handleShowIcon}
            loadOptions={props.loadOptions}
          />
        )}
      </Modal.Body>
      <Modal.Footer className='border-0 d-flex justify-content-end'>
        <Button
          onClick={() => props.handleSubmitDefault(props.shownModal)}
          disabled={props.selectedOption === null}
        >
          Set as default {props.shownModal}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default SelectDefaultModal
