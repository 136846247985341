import styled from 'styled-components'

export const StyledButton = styled.button<{ width?: string }>`
  background: transparent;
  border: none;
  display: flex;
  align-items: flex-start;
  width: ${(props) => props.width || '85%'};
  border-radius: 10px;
  padding: 4px;
  margin-left: -4px;
  height: 24px;

  &:hover {
    background: var(--hover);
  }
`
