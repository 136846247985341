import {
  type block,
  IBlockTypes,
  BLOCKS_COPY_KEY,
  getIndex,
  getBlockPageId,
  getBlockId,
} from '_entities/block'
import {
  checkIsFigma,
  checkIsImage,
  checkIsMiro,
  checkIsOfficeEmbedCode,
  checkIsGoogleDocLink,
  checkIsGoogleSlidesLink,
  checkIsGoogleSheetsLink,
} from '_entities/embed'
import { useEmbedCreation } from '_features/embed'
import { objectId } from 'utils/editor'
import { getDoc, useJson1 } from 'shared/shareDb'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { getBlockFromEvent } from './events'
import { getEditor } from 'shared/lib'

export const usePaste = () => {
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _embedCreation = useEmbedCreation()

  const paste = (pageId: string, clipboard: block[], index: number) => {
    /* The reverse is for inserting blocks on the same position */
    clipboard.reverse()
    const doc = getDoc(pageId)
    if (!doc) return
    const updatedClipboardBlocks = clipboard.map((block) => {
      return {
        ...block,
        _id: objectId(),
      }
    })
    updatedClipboardBlocks.forEach((block) => {
      _submit.submit(getBlockPageId(block), _json1.addBlock(block, index), SourceKeys.UPDATE_BLOCK)
    })
  }

  const onPaste = async (e: React.ClipboardEvent<HTMLDivElement>) => {
    const data = e.clipboardData.getData('text/html')
    const pasted: string = e.clipboardData.getData('text/plain')
    const block = getBlockFromEvent(e)
    if (!block) return
    const index = getIndex(block)
    const editor = getEditor(getBlockId(block))
    if (pasted && pasted.includes(BLOCKS_COPY_KEY)) {
      e.preventDefault()
      const parsed = JSON.parse(pasted)
      const blocksInClipboard: block[] | undefined = parsed[BLOCKS_COPY_KEY]
      if (blocksInClipboard) {
        paste(getBlockPageId(block), blocksInClipboard, index)
      }
    }
    if (pasted.startsWith('https://')) {
      if (editor) {
        editor.format('link', pasted, 'user')
        setTimeout(() => {
          editor.setSelection(editor.getLength(), 0)
        }, 100)
      }
    }

    if (checkIsFigma(pasted)) {
      if (editor && editor.getLength() > 2) {
        editor.format('link', pasted, 'user')
        setTimeout(() => {
          editor.setSelection(editor.getLength(), 0)
        }, 100)
        return
      }
      e.preventDefault()
      e.stopPropagation()
      _embedCreation.handleDocExternalLink(pasted, block)
      return
    }

    if (checkIsMiro(pasted)) {
      if (editor && editor.getLength() > 2) {
        editor.format('link', pasted, 'user')
        setTimeout(() => {
          editor.setSelection(editor.getLength(), 0)
        }, 100)
        return
      }
      e.preventDefault()
      e.stopPropagation()
      _embedCreation.handleDocExternalLink(pasted, block)
      return
    }

    if (
      checkIsGoogleDocLink(pasted) ||
      checkIsGoogleSlidesLink(pasted) ||
      checkIsGoogleSheetsLink(pasted)
    ) {
      if (editor && editor.getLength() > 2) {
        editor.format('link', pasted, 'user')
        setTimeout(() => {
          editor.setSelection(editor.getLength(), 0)
        }, 100)
        return
      }
      e.preventDefault()
      e.stopPropagation()
      _embedCreation.handleDocExternalLink(pasted, block)
      return
    }

    if (checkIsOfficeEmbedCode(pasted)) {
      if (editor && editor.getLength() > 2) {
        editor.format('link', pasted, 'user')
        setTimeout(() => {
          editor.setSelection(editor.getLength(), 0)
        }, 100)
        return
      }
      e.preventDefault()
      e.stopPropagation()

      const src = checkIsOfficeEmbedCode(pasted)?.[1]
      if (src) {
        _embedCreation.handleDocExternalLink(src.replace(/&amp;/g, '&'), block)
        return
      }
    }

    if (await checkIsImage(pasted)) {
      if (editor && editor.getLength() > 2) {
        editor.format('link', pasted, 'user')
        setTimeout(() => {
          editor.setSelection(editor.getLength(), 0)
        }, 100)
        return
      }

      const newBlock = {
        ...block,
        data: {
          ...block.data,
          imageUrl: pasted,
          tag: IBlockTypes.IMAGE,
          imageBorderWrapperWidth: 400,
        },
      }
      _submit.submit(
        getBlockPageId(block),
        _json1.replaceBlock(newBlock, index, block),
        SourceKeys.UPDATE_BLOCK,
      )
      return
    }

    if (data) {
      const el = document.createElement('html')
      el.innerHTML = data
      const imgTag = el.getElementsByTagName('img')
      const imgNode = imgTag[0]

      if (editor && editor.getLength() > 2) {
        return
      }

      if (imgNode) {
        e.preventDefault()
        const src = imgNode.src
        const newBlock = {
          ...block,
          data: {
            ...block.data,
            imageUrl: src,
            tag: IBlockTypes.IMAGE,
            imageBorderWrapperWidth: 400,
          },
        }
        _submit.submit(
          getBlockPageId(block),
          _json1.replaceBlock(newBlock, index, block),
          SourceKeys.UPDATE_BLOCK,
        )
        return
      }
    }
  }

  return {
    onPaste,
  }
}
