import React, { useState } from 'react'

export const useNotificationsTabUserModal = () => {
  const [inAppNotificationsOn, setInAppNotificationsOn] = useState<boolean>(false)
  const [emailAppNotificationsOn, setEmailAppNotificationsOn] = useState<boolean>(false)
  return {
    inAppNotificationsOn,
    setInAppNotificationsOn,
    emailAppNotificationsOn,
    setEmailAppNotificationsOn,
  }
}
