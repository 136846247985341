import React, { useState } from 'react'
import Card from 'components/organisms/Card/Card'
import LoadingAnim from 'components/atoms/LoadingAnim/LoadingAnim'
import AuthWrapper from 'components/templates/AuthWrapper/AuthWrapper'
import TextSuccess from 'components/atoms/TextSuccess/TextSuccess'
import TextDanger from 'components/atoms/TextDanger/TextDanger'
import TextWithSpanLink from 'components/atoms/TextWithSpanLink/TextWithSpanLink'
import DisplayCoverImage from 'components/templates/DisplayCoverImage/DisplayCoverImage'
import { AxiosService } from 'services/axiosService/axiosService'
import { MainWrapper } from 'views/styles'

type Props = {
  id?: string
  email?: string
}

const VerifyEmail: React.FC<Props> = ({ id, email }) => {
  const [statusMessage, setStatusMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [AxiosInstance] = useState<AxiosService>(new AxiosService())

  const resendMail = async () => {
    setLoading(true)
    const response = await AxiosInstance.resendEmail(id)
    if (response) setStatusMessage('Email sent, check your inbox.')
    else setStatusMessage('Something went wrong')
    setLoading(false)
  }

  return (
    <MainWrapper>
      <DisplayCoverImage />

      <AuthWrapper
        elements={
          <Card
            textBlack
            shadow
            radius
            alignLeft
            fontSize
            titleText='Verify your email'
            isFontSmaller
            isTitleLeft
            subtext=' '
            secondaryText={`We sent an email to your email address: ${email && email}${' '}`}
            cardElements={
              <>
                <TextWithSpanLink
                  // eslint-disable-next-line quotes
                  primaryText={"Didn't receive an email?"}
                  secondaryText={'Resend'}
                  callback={resendMail}
                />
                {statusMessage === 'Email sent, check your inbox.' ? (
                  <TextSuccess text={statusMessage} />
                ) : (
                  <TextDanger text={statusMessage} />
                )}
                {loading && (
                  <span className='d-flex justify-content-center align-items-center'>
                    <LoadingAnim className='loading-spinner mb-3' />
                  </span>
                )}
              </>
            }
          />
        }
      />
    </MainWrapper>
  )
}

export default VerifyEmail
