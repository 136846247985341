import { ExtendedEmoji } from '../model/types'
import { emojis } from '@nutrify/quill-emoji-mart-picker'
import { getEmojisData } from './getEmojisData'
import Quill from 'quill'

export const replaceTextWithEmoji = (editor: Quill) => {
  const content = editor.getContents()
  let text = content.ops?.map((op) => op.insert).join('')
  let matches = text?.match(/:\w+:/g)

  while (matches) {
    matches.forEach((match) => {
      let emoji: ExtendedEmoji | undefined = emojis.find(
        (emoji) => `:${emoji.shortName.replace('-', '_').trim()}:` === match.trim(),
      )

      if (emoji === undefined) {
        const matchStripped = match.replace(/:/g, '')
        emoji = getEmojisData().find((emoji) => {
          return emoji?.keywords?.includes(matchStripped) || emoji?.id === matchStripped
        })

        if (emoji && emoji.skins) {
          emoji = {
            ...emoji,
            native: emoji.skins[0]?.native,
          }
        }
      }

      if (emoji === undefined) return

      const position = text?.indexOf(match)
      if (position === undefined) return

      let insertValue: string | undefined

      if (emoji.native) {
        insertValue = emoji.native
      } else {
        insertValue = emoji.unified.includes('-')
          ? emoji.unified
              .split('-')
              .map((codePoint) => String.fromCodePoint(parseInt(codePoint, 16)))
              .join('')
          : String.fromCodePoint(parseInt(emoji.unified, 16))
      }

      if (insertValue === undefined) return

      editor.deleteText(position, match.length)
      editor.insertText(position, insertValue)

      const updatedContent = editor.getContents()
      text = updatedContent.ops?.map((op) => op.insert).join('')
      matches = text?.match(/:\w+:/g)
    })
  }
}
