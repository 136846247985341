import React from 'react'
import { CheckboxStyled, StyledFormCheck } from './styles'

type Props = {
  label?: string
  checked?: boolean
  id?: string
  disabled?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  fontSize?: string
  fontWeight?: string
  marginBlock?: string
  dimensions?: string
  borderRadius?: string
  borderColour?: string
  borderWidth?: string
  left?: string
  top?: string
  marginRight?: string
}

const Checkbox: React.FC<Props> = (props: Props) => {
  return (
    <CheckboxStyled
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      marginBlock={props.marginBlock}
      checked={props.checked}
    >
      <StyledFormCheck
        type={'checkbox'}
        checked={props.checked}
        id={props.id}
        label={props.label}
        disabled={props.disabled}
        onChange={props.onChange}
        dimensions={props.dimensions}
        borderRadius={props.borderRadius}
        borderColour={props.borderColour}
        borderWidth={props.borderWidth}
        top={props.top}
        left={props.left}
        marginRight={props.marginRight}
      />
    </CheckboxStyled>
  )
}

export default Checkbox
