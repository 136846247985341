import { Node, NodeConfig } from 'konva/lib/Node'
import { Image } from 'konva/lib/shapes/Image'
import { sizes } from './types'

export enum FrameAttributes {
  THUMBNAIL = 'data-thumbnail',
}

export interface FrameAttributesData {
  [FrameAttributes.THUMBNAIL]: string
}

export const getFrameSize = (node: Node<NodeConfig>) => {
  return node.attrs.frameType as sizes
}

export const getFrameThumbnail = (node: Image) => {
  return node.attrs[FrameAttributes.THUMBNAIL] as FrameAttributesData[FrameAttributes.THUMBNAIL]
}
