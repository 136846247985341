import React from 'react'
import { Link } from 'react-router-dom'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import Card from 'components/organisms/Card/Card'
import AuthWrapper from 'components/templates/AuthWrapper/AuthWrapper'
import CustomP from 'components/atoms/CustomP/CustomP'

const EmailVerified: React.FC = () => {
  return (
    <AuthWrapper
      elements={
        <Card
          textBlack
          shadow
          radius
          fontSize
          cardElements={
            <div className='card-body p-1 text-center mt-auto'>
              <h1 className='fs-2 mb-3'>Account created!</h1>
              <h2 className='fs-6 text-secondary mb-5'>
                Customize your space or get straight to your first Everyday project!
              </h2>
              <div>
                <Link className='text-primary' to='#'>
                  <CustomButton className='btn btn-outline-primary w-25 me-3'>
                    <CustomP className='mb-0'>Start working</CustomP>
                  </CustomButton>
                </Link>
                <Link className='text-primary' to='#'>
                  <CustomButton className='btn btn-primary w-25 ms-3' isDisabled={true}>
                    <CustomP className='mb-0'>Setup space</CustomP>
                  </CustomButton>
                </Link>
              </div>
            </div>
          }
        />
      }
    />
  )
}

export default EmailVerified
