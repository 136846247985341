import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setOpen, setSelectedObject } from 'redux/reducers/chatReducer'
import { setIsSliderOpen } from 'redux/reducers/taskManagerReducer'
import { setShareModalOpened } from 'redux/reducers/pageReducer'
import { setProfileOpen } from 'redux/reducers/profileReducer'

const useProjectFileRightSidebarTop = () => {
  // Redux state
  const selectedPage = useAppSelector((state) => state.page?.selectedPage)
  const authUser = useAppSelector((state) => state.global.user)

  // Hooks
  const dispatch = useAppDispatch()

  const handlePageModalOpen = () => {
    dispatch(setShareModalOpened(true))
  }

  const handleChatOpen = () => {
    dispatch(setOpen(true))
    dispatch(setSelectedObject(null))
    setTimeout(() => {
      const element = document.querySelector('.str-chat__list')
      if (element) {
        element.scrollTo(0, element.scrollHeight)
      }
    }, 1000)
  }

  const handleToggleSliderOpen = () => {
    dispatch(setIsSliderOpen(true))
  }

  const handleProfileOpen = () => {
    dispatch(setProfileOpen(true))
  }

  return {
    handlePageModalOpen,
    handleChatOpen,
    selectedPage,
    handleToggleSliderOpen,
    handleProfileOpen,
    authUser,
  }
}

export default useProjectFileRightSidebarTop
