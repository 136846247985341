import { useLocation, useNavigate } from 'react-router-dom'

export const useHash = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { search, pathname, hash } = location

  const getBlockIdFromHash = () => {
    if (hash) {
      return hash.slice(1)
    }
  }

  const removeHash = () => {
    navigate({
      pathname,
      search,
    })
  }

  return {
    getBlockIdFromHash,
    hash,
    removeHash,
  }
}
