import { TreeItem } from './types'
import { useAppSelector } from 'redux/hooks'
import { IPages } from 'interfaces/page'
import { InitialTreeStructure } from 'shared/dragging/model/types'

export const useTree = () => {
  const pages = useAppSelector((state) => state.projectFile.pages)

  const getParent = (item: TreeItem) => {
    return pages?.find((page) => page.id === item.id)?.parent
  }

  const getSubItems = (items: TreeItem[], itemId: string | number): TreeItem[] => {
    const children: TreeItem[] = []
    if (!items) return children
    for (const page of items) {
      if (page.parent === itemId) {
        const nestedChildren = getSubItems(items, page.id)
        children.push(page, ...nestedChildren)
      }
    }
    return children
  }

  const getSubItemsAndParentItem = (items: TreeItem[], itemId: string | number) => {
    const parentPage = items.find((page) => page.id === itemId)
    const subpages = getSubItems(items, itemId)
    if (!parentPage) return subpages
    return [parentPage, ...subpages]
  }

  const getFirstChildren = (items: TreeItem[], itemId: string | number) => {
    const children = getSubItems(items, itemId)
    return children.filter((child) => child.parent === itemId)
  }

  const getSiblings = (items: TreeItem[], item: TreeItem | IPages) => {
    return items.filter((orderedItem) => orderedItem.parent === item.parent)
  }

  const checkAreChildrenVisible = (items: TreeItem[], id: string) => {
    const children = getFirstChildren(items, id)

    if (children.length === 0) return

    return children.every((child) => child.isShown)
  }

  const getParentAndRelativeIndex = (id: string) => {
    const page = pages?.find((page) => page.id === id)
    const parentId = page?.parent
    const parent = parentId ? pages?.find((page) => page.id === parentId) : undefined
    const siblings = parentId ? getSiblings(pages as TreeItem[], page) : pages

    return {
      parent,
      relativeIndex: siblings?.findIndex((sibling) => sibling.id === id),
    }
  }

  const generateItemFromInitialTreeStructure = (treeStructure: InitialTreeStructure[]) => {
    return treeStructure.map((item: { id: string; isShown: boolean }) => {
      const parent = getParent(item as TreeItem)
      return {
        id: item.id,
        isShown: item.isShown,
        parent: (parent as string) || 0,
      }
    })
  }

  const generateMissingItems = (
    missingPages: TreeItem[] | IPages[],
    generatedItems: InitialTreeStructure[],
  ) => {
    return missingPages.map((item) => {
      const parent = getParent(item as TreeItem)
      return {
        id: item.id,
        isShown: checkAreChildrenVisible(generatedItems as TreeItem[], parent as string),
      }
    })
  }

  return {
    getSubItems,
    getSubItemsAndParentItem,
    getSiblings,
    getFirstChildren,
    checkAreChildrenVisible,
    getParentAndRelativeIndex,
    generateItemFromInitialTreeStructure,
    generateMissingItems,
  }
}
