import { MainContainer, Container } from './styles'
import ProjectFileRightSidebarTop from '../ProjectFileRightSidebarTop/ProjectFileRightSidebarTop'
import { IPage } from 'interfaces/page'
import { PageTypesEnum } from 'interfaces/projectFile'
import Sidebar from './ReusableSidebar/Sidebar'
import { useAppSelector } from 'redux/hooks'
import { useDispatch } from 'react-redux'

import SidebarTabs, {
  SidebarTabsBody,
  SidebarTabsBodyItem,
} from 'components/molecules/SidebarTabs/SidebarTabs'
import { setRightSidebarTabKey } from 'redux/reducers/projectFileReducer'
import { useEffect } from 'react'
import { setFontFamily, setFontSize } from 'redux/reducers/pageReducer'

interface Props {
  selectedPage: IPage
}

const ProjectFileRightSidebar = ({ selectedPage }: Props) => {
  const dispatch = useDispatch()
  const tabKey = useAppSelector((state) => state.projectFile.rightSidebarTabKey)
  const setTabKey = (tabKey: string) => dispatch?.(setRightSidebarTabKey(tabKey))

  const selectedBlock =
    selectedPage?.type === PageTypesEnum.WHITE_BOARD
      ? useAppSelector((state) => state.whiteboard.selectedBlock)
      : useAppSelector((state) => state.page.selectedBlock)

  useEffect(() => {
    !selectedBlock && dispatch(setFontFamily(undefined)) && dispatch(setFontSize(undefined))
  }, [selectedBlock])

  return (
    <MainContainer>
      <ProjectFileRightSidebarTop />
      <SidebarTabs
        defaultActiveKey='Edit'
        activeKey={tabKey || 'Edit'}
        onSelect={(k) => setTabKey(k || '')}
        fullHeight
      >
        <SidebarTabsBody>
          <SidebarTabsBodyItem eventKey='Edit' grow fullWidth padding='0px'>
            <Container>
              {(selectedPage?.type === PageTypesEnum.DOCUMENT ||
                selectedPage?.type === PageTypesEnum.WHITE_BOARD) && (
                <Sidebar block={selectedBlock} pageType={selectedPage.type} />
              )}
            </Container>
          </SidebarTabsBodyItem>
        </SidebarTabsBody>
      </SidebarTabs>
    </MainContainer>
  )
}

export default ProjectFileRightSidebar
