import styled from 'styled-components'
import { icons } from 'shared/icons'

export const StyledGoogleIcon = styled(icons.google)`
  path {
    stroke: transparent !important;
  }
`
export const SignInContainer = styled.div<{ width?: number; height?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ height }) => `${height}px` || '100%'};
  width: ${({ width }) => `${width}px` || '100%'};
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  background-color: var(--document-background);
`
