import { getById } from 'shared/lib'
import { LEFT_SIDEBAR_ID } from '../ui/LeftSidebar'
export const useLeftSidebar = () => {
  const getLeftSidebarElement = () => {
    return getById(LEFT_SIDEBAR_ID)
  }

  const getLeftSidebarRect = () => {
    return getLeftSidebarElement()?.getBoundingClientRect()
  }

  return {
    getLeftSidebarElement,
    getLeftSidebarRect,
  }
}
