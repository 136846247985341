import { property } from '../api'

export const useCreateProperty = () => {
  // ** Network
  const [handleCreateProperty] = property.endpoints.createProperty.useMutation()

  const createProperty = async (taskId: string, propertyId: number) => {
    // ** Create Property
    return await handleCreateProperty({ taskId, propertyId }).unwrap()
  }

  return {
    createProperty,
  }
}
