import styled from 'styled-components'

type ModalInfoLabelPropsType = {
  colour?: string
  cursor?: string
  marginTop?: string
  marginBottom?: string
  padding?: string
  paddingHover?: string
  backgroundHover?: string
}

type ValueAndButtonContainerProps = {
  marginBottom?: string
}

export const AccountTabUserModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  & .btn {
    width: fit-content;
  }
`

export const ValueAndButtonContainer = styled.div<ValueAndButtonContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '1.5rem')};
`

export const EmptyDiv = styled.div`
  font-size: 16px;
  color: var(--primary-text);
`

export const ModalInfoLabel = styled.label<ModalInfoLabelPropsType>`
  color: ${(props) => props.colour || 'var(--primary-text)'};
  cursor: ${(props) => props.cursor || ''};
  font-size: 14px;
  font-weight: 500;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};
  padding: ${(props) => props.padding || ''};
  border-radius: 10px;
  transition: all 0.25s ease-out;

  :hover {
    padding: ${(props) => props.paddingHover || ''};
    background-color: ${(props) => props.backgroundHover || ''};
  }
`
export const ModalChangeButton = styled.div`
  color: var(--primary-color);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
  border-radius: 10px;

  :hover {
    background-color: var(--hover);
  }
`
export const ModalDangerButton = styled.p`
  color: var(--everyday-red) !important;
  background: transparent !important;
  border-radius: 10px;
  transition: all 0.1s ease-out;
  font-size: 14px;
  font-weight: 600;

  :active {
    border-color: transparent !important;
  }

  cursor: pointer;
`

export const FlexWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`
