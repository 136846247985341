import { InternalEmbedSelection } from '_entities/embed'
import { useToolbar, ToolbarAttrs, ToolbarAttrsData } from '_features/toolbar'
export const useInternalEmbedSelection = () => {
  const _toolbar = useToolbar()
  const getSelectedEmbed = () => {
    const selectedEmbed = _toolbar.getAttr(ToolbarAttrs.INTERNAL_EMBED)
    if (!selectedEmbed) return
    return JSON.parse(selectedEmbed) as ToolbarAttrsData[ToolbarAttrs.INTERNAL_EMBED]
  }
  const setSelectedEmbed = (embed: InternalEmbedSelection) => {
    _toolbar.setAttr(ToolbarAttrs.INTERNAL_EMBED, embed)
  }
  const removeSelectedEmbed = () => {
    _toolbar.removeAttr(ToolbarAttrs.INTERNAL_EMBED)
  }

  return {
    getSelectedEmbed,
    setSelectedEmbed,
    removeSelectedEmbed,
  }
}
