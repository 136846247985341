import React from 'react'
import styled from 'styled-components'

type SpanPropsType = {
  colour?: string
}

const Paragraph = styled.p`
  cursor: pointer;
  color: var(--gray2) !important;
  text-align: start;
  margin-top: 1.5rem;
`

const Span = styled.span<SpanPropsType>`
  cursor: pointer;
  color: ${(props) => props.colour || 'var(--gray2) !important'};
`

type Props = {
  primaryText: string
  secondaryText: string
  callback: () => void
}

const TextWithSpanLink: React.FC<Props> = ({ primaryText, secondaryText, callback }) => {
  return (
    <Paragraph>
      {primaryText}&nbsp;
      <Span onClick={() => callback()} className='text-primary' colour='var(--primary-color)'>
        {secondaryText}
      </Span>
    </Paragraph>
  )
}

export default TextWithSpanLink
