import { Doc } from 'sharedb'
import sharedb, { LocalPresence, Presence } from 'sharedb/lib/client'
import * as json1 from 'ot-json1'
import { type block } from '_entities/block'
import { useAppSelector } from 'redux/hooks'
import { usePresence } from 'shared/shareDb/usePresence'
import { docSubscribe, getBlocks, getDoc } from 'shared/shareDb'

export const useShareDb = () => {
  // ** Hooks
  const _presence = usePresence()

  // ** State
  const currentUser = useAppSelector((state) => state.global.user)

  const registerJson1 = () => {
    sharedb.types.register(json1.type)
  }

  const subscribeToPresence = (pageId: string): Promise<Presence | Error> | undefined => {
    const presence = _presence.getPresence(pageId)
    if (!presence) return
    return new Promise((resolve, reject) => {
      presence.subscribe((error) => {
        if (error) {
          reject(error)
        }
        resolve(presence)
      })
    })
  }

  const submitLocalPresence = (pageId: string) => {
    let localPresence: LocalPresence | undefined = _presence.getLocalPresence(pageId)
    if (!localPresence) {
      localPresence = _presence.createLocalPresence(pageId)
    }
    localPresence?.submit({
      user: { ...currentUser, selectedPageId: pageId },
    })
    return localPresence
  }

  const getBlocksFromUnsubscribedDoc = async (pageId: string): Promise<block[] | undefined> => {
    let blocks: block[] | undefined
    const doc = getDoc(pageId)
    if (doc && doc.data) {
      blocks = getBlocks(pageId)
    } else {
      const subscribedDoc = await docSubscribe(pageId)
      if (!subscribedDoc || subscribedDoc instanceof Error) {
        throw new Error('Error subscribing')
      }
      blocks = getBlocks(pageId)
      const presence = subscribeToPresence(pageId)
      if (!presence || presence instanceof Error) {
        throw new Error('Error getting presence')
      }
      const localPresence = submitLocalPresence(pageId)
      subscribedDoc?.destroy()
      localPresence?.destroy()
    }
    return blocks
  }

  const handleUnsubscribedPage = async (pageId: string, callback: (doc: Doc) => void) => {
    const doc = getDoc(pageId)
    if (doc && doc.data) {
      callback(doc)
      return
    }
    const subscribedDoc = await docSubscribe(pageId)
    if (!subscribedDoc || subscribedDoc instanceof Error) return
    const subscribedPresence = await subscribeToPresence(pageId)
    if (!subscribedPresence || subscribedPresence instanceof Error) return
    submitLocalPresence(pageId)
    callback(subscribedDoc)
    subscribedDoc.destroy()
    _presence.destroyLocalPresence(pageId)
  }

  return {
    registerJson1,
    subscribeToPresence,
    submitLocalPresence,
    getBlocksFromUnsubscribedDoc,
    handleUnsubscribedPage,
  }
}
