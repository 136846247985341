import Embed from 'whiteboard/Embed/Embed'
import TextBlock from 'whiteboard/Text/TextBlock'
import StickyNote from 'whiteboard/StickyNote/StickyNote'
import Frame from 'whiteboard/Frame/Frame'
import InternalEmbed from 'whiteboard/Embed/InternalEmbed/InternalEmbed'
import ShapeBlock from 'whiteboard/ResizableShape/ShapeBlock'
import { ImageBlock, EmojiBlock } from '_entities/whiteboard'
import { useEffect } from 'react'
import { setSelectedBlock } from 'redux/reducers/whiteboardReducer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { IBlockTypes, WhiteboardBlockProps } from '../../model/types'

const WhiteboardBlock = (props: WhiteboardBlockProps) => {
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (selectedBlock?._id === props.block._id) {
      dispatch(setSelectedBlock(props.block))
    }
  }, [props.block])

  switch (props.block.data.tag) {
    case IBlockTypes.DOCUMENT:
    case IBlockTypes.WHITEBOARD:
    case IBlockTypes.EMBED:
    case IBlockTypes.SLIDESHOW: {
      return <InternalEmbed {...props} />
    }
    case IBlockTypes.EXTERNAL_EMBED: {
      return <Embed {...props} />
    }

    case IBlockTypes.TITLE:
    case IBlockTypes.XLARGE:
    case IBlockTypes.LARGE:
    case IBlockTypes.MEDIUM:
    case IBlockTypes.SMALL:
    case IBlockTypes.SUBHEADER:
    case IBlockTypes.TEXT:
    case IBlockTypes.LIST:
    case IBlockTypes.CHECKLIST:
    case IBlockTypes.NUMBERED_LIST: {
      return <TextBlock {...props} />
    }
    case IBlockTypes.STICKY_NOTE: {
      return <StickyNote {...props} />
    }
    case IBlockTypes.SHAPE: {
      if (props.block && props.block.data && props.block.data.shape) {
        return <ShapeBlock {...props} />
      } else return null
    }

    case IBlockTypes.IMAGE: {
      return <ImageBlock {...props} />
    }
    case IBlockTypes.EMOJI: {
      return <EmojiBlock {...props} />
    }
    case IBlockTypes.FRAME: {
      return <Frame {...props} />
    }
    default:
      return null
  }
}

export default WhiteboardBlock
