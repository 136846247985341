import styled from 'styled-components'
import { MessageText } from 'stream-chat-react'

export const MessageWrapper = styled.div`
  display: flex !important;
  padding: 0;
  & .str-chat__message-mention {
    color: var(--primary-color);
    background-color: var(--hover);
    padding: 4px;
    border-radius: 6px;
    font-weight: 400;
  }
`

export const MessageWrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

export const MessageHeader = styled.div`
  display: flex;
  align-items: center !important;
  gap: 8px;
  margin-bottom: 6px;
`

export const MessageHeaderName = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: var(--primary-text);
`

export const MessageHeaderTimestamp = styled.div`
  font-weight: 400;
  font-size: 12px;
  color: #a3a3a3;
`

export const SelectedObjectWrapper = styled.button`
  background-color: transparent;
  border: solid 1px #d6d6d6;
  border-radius: 8px;
  padding: 7px;
  margin-left: 30px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  width: 92.5%;
  text-align: left;
`

export const SelectedObjectImage = styled.img`
  height: auto;
  width: 100%;
  pointer-events: none;
`

export const SelectedObjectPreview = styled.p<{
  padding?: string
  height?: number
  backgroundColor?: string
}>`
  height: ${(props) => (props.height ? `${props.height}px` : 'auto')};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  margin-bottom: 4px;
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  color: var(--primary-text);
  padding: ${(props) => (props.padding ? props.padding : '5px')};
  border-radius: 8px;
`

export const EditContainer = styled.div`
  display: flex;
  gap: 8px;
  padding: 6px 0;
  margin-left: 30px;
`

export const EditInput = styled.input`
  border: solid 1px #ebebeb;
  padding: 4px;
  border-radius: 8px;
  min-width: 250px;
  font-size: 14px;
`

export const EditButton = styled.button`
  border: solid 1px #ebebeb;
  padding: 4px 6px;
  border-radius: 8px;
`

export const EditButtonSave = styled(EditButton)`
  background-color: #005fff;
  color: white;
`

export const EditButtonCancel = styled(EditButton)`
  background: transparent;
  color: red;
`

export const StyledThreadIndicator = styled.div`
  display: flex;
  margin-left: 30px;
  gap: 4px;
  align-items: center;
`

export const AvatarsWrapper = styled.div`
  display: flex;
  margin-left: 6px;
  gap: 4px;
`

export const MessageContent = styled.div`
  width: 100%;
`

export const CustomMessageText = styled(MessageText)`
  padding-left: 20px !important;
  font-size: 14px !important;
`
