import { getBlockPageId, getIndex, IBlockTypes, WhiteboardBlockProps } from '_entities/block'
import Checkbox from 'components/atoms/Checkbox/Checkbox'
import 'react-quill/dist/quill.snow.css'
import ResizableTextBlock from './ResizableTextBlock'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { JSONOp } from 'ot-json1'
import { useTextBlock } from '_entities/text'

const TextBlock = (props: WhiteboardBlockProps) => {
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _textBlock = useTextBlock()
  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = getIndex(props.block)
    let op: JSONOp
    if (_textBlock.getIsCheckedKey(props.block)) {
      op = _json1.getReplaceBlockDataKeyOp(index, ['isChecked'], e.target.checked)
    } else {
      op = _json1.getInsertKeyInDataKeyOp(index, ['isChecked'], e.target.checked)
    }
    _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
  }
  return (
    <ResizableTextBlock {...props}>
      {props.block.data.tag === IBlockTypes.LIST && <span>&#x2022;</span>}
      {props.block.data.tag === IBlockTypes.CHECKLIST && (
        <Checkbox
          id={`check_${props.block._id}`}
          onChange={handleCheck}
          checked={props.block.data.isChecked}
        />
      )}
    </ResizableTextBlock>
  )
}

export default TextBlock
