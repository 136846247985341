import { block } from '_entities/block'
import Cropper from 'react-cropper'
import { CancelOption, CropOption, CropperOptions } from './styles'
import { Spinner } from 'react-bootstrap'
import { useCrop } from '../hooks/useCrop'

export interface CropperProps {
  block: block
  onCropFinish: () => void
}

const ImageCropper = (props: CropperProps) => {
  const _crop = useCrop(props)
  return (
    <>
      <Cropper
        src={`${props.block.data.imageUrl}`}
        guides={false}
        ref={_crop.cropperRef}
        cropend={_crop.onCrop}
        autoCropArea={1}
        scalable={false}
        zoomable={false}
        zoomOnTouch={false}
        zoomOnWheel={false}
        minCanvasWidth={props.block.data.imageBorderWrapperWidth}
        minCropBoxWidth={props.block.data.imageBorderWrapperWidth}
      />

      {!_crop.cropLoading && (
        <CropperOptions>
          <CancelOption onClick={props.onCropFinish}>Cancel</CancelOption>
          <CropOption onClick={_crop.onEditorImageCrop}>Crop</CropOption>
        </CropperOptions>
      )}

      {_crop.cropLoading && (
        <CropperOptions>
          <Spinner size='sm' />
        </CropperOptions>
      )}
    </>
  )
}

export default ImageCropper
