import { type block } from '_entities/block'
import { DropdownMenuItem } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { useAppDispatch } from 'redux/hooks'
import { setFontFamily } from 'redux/reducers/pageReducer'
import { TextFontFamily } from '_entities/text'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getEditor } from 'shared/lib'

export type FontFamilyDropdownProps = {
  block: block
}

const FontFamilyDropdown = (props: FontFamilyDropdownProps) => {
  const dispatch = useAppDispatch()

  const fontFamilyArray = (): any => {
    return {
      [TextFontFamily.INCONSOLATA]: 'Inconsolata, monospace',
      [TextFontFamily.ROBOTO]: 'Roboto, sans-serif',
      [TextFontFamily.PACIFICO]: 'Pacifico, cursive',
      [TextFontFamily.PT_SANS]: 'PT Sans, sans-serif',
      [TextFontFamily.MONTSERRAT]: 'Montserrat, sans-serif',
      [TextFontFamily.ARIAL]: 'Arial, sans-serif',
      [TextFontFamily.MIRZA]: 'Mirza, cursive',
    }
  }

  const fontFamilyOptions = (block: block) => {
    return Object.keys(fontFamilyArray()).map((fontFamily) => {
      const label = fontFamily.charAt(0).toUpperCase() + fontFamily.slice(1)
      return {
        name: (
          <CustomP fontSize='12px' paddingLeft='6px' paddingRight='6px' fontWeight='500'>
            {label.charAt(0).toUpperCase() + label.slice(1)}
          </CustomP>
        ),
        label: label,
        onClick: () => {
          const editor = getEditor(block._id)
          if (!editor) return
          const selection = editor.getSelection()
          if (selection && selection.length > 0) {
            editor?.formatText(selection.index, selection.length, 'font', fontFamily, 'user')
          } else {
            editor?.formatText(0, editor.getLength(), 'font', fontFamily, 'user')
          }
        },
        isCheckmark: true,
      }
    })
  }

  return (
    <>
      {fontFamilyOptions(props.block).map((item, n) => (
        <DropdownMenuItem
          onClick={() => {
            item.onClick()
            dispatch(setFontFamily(item.label))
          }}
          key={n}
        >
          {item.label}
        </DropdownMenuItem>
      ))}
    </>
  )
}

export default FontFamilyDropdown
