import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  setIsEditing,
  setIsMessageThreadOpen,
  setMessagesToOpen,
  setMessagesWithBlock,
  setObjectChatToView,
  setPinnedMessages,
  setSelectedChannel,
  setSelectedMessages,
  setSelectedObject,
} from 'redux/reducers/chatReducer'
import { StreamMessage, useChannelActionContext, useChannelStateContext } from 'stream-chat-react'
import { useDefaultChannel } from '../Chat/useDefaultChannel'
import { useChatDrawer } from '_widgets'
import { MessageResponseWithBlock } from 'interfaces/chat'
import { type block, getBlockId, getBlockPageId, getIndex, IBlockTypes } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'
import useChat from '../Chat/useChat'
import { User } from 'stream-chat'
import { IPage } from 'interfaces/page'
import { useGetPage, getPage } from '_entities/page'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { getBlockById } from 'shared/shareDb'

const useWindow = () => {
  // State
  const [open, setOpen] = useState(false)
  const [allMessagesFromChat, setAllMessages] = useState<StreamMessage[]>([])
  const [messageToDelete, setMessageToDelete] = useState<StreamMessage | null>(null)
  const [isSingleMessageDelete, setIsSingleMessageDelete] = useState(true)
  const [referencedBlock, setReferencedBlock] = useState<block | null>(null)
  const [referencedPage, setReferencedPage] = useState<IPage | null>(null)
  const selectedPage = useAppSelector((state) => state.page.selectedPage)

  // Selected object to start a thread with
  const selectedObject = useAppSelector((state) => state.chat.selectedObject)
  const pinnedMessages = useAppSelector((state) => state.chat.pinnedMessages) || []
  const selectedMessages = useAppSelector((state) => state.chat.selectedMessages) || []
  const selectedChannel = useAppSelector((state) => state.chat.selectedChannel)
  const messagesToOpen = useAppSelector((state) => state.chat.messagesToOpen)
  const messagesWithBlock = useAppSelector((state) => state.chat.messagesWithBlock)
  const chatOpen = useAppSelector((state) => state.chat.open)
  const objectChatToView = useAppSelector((state) => state.chat.objectChatToView)
  const threadIsOpen = useAppSelector((state) => state.chat.isMessageThreadOpen)
  const selectedProjectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  // Hooks
  const dispatch = useAppDispatch()
  const { chatClient, channel } = useChat()
  const { DEFAULT_CHANNEL } = useDefaultChannel()
  const _project = useGetProjectFile()

  const { getReplaceBlockMetaKeyOp } = useJson1()

  const { submit } = useSubmit()
  const { openThread } = useChannelActionContext()
  const { messages } = useChannelStateContext()

  const { channelOptions } = useChatDrawer()

  const { getPageObjects } = useGetPage()

  const customActions = {
    Edit: (message: StreamMessage) => {
      handleSetIsEditing(message)
    },
    Pin: (message: StreamMessage) => {
      handlePinMessage(message)
    },
    'Delete Message': (message: StreamMessage) => {
      setOpen(true)
      fetchBlock(message as MessageResponseWithBlock)
      setMessageToDelete(message)
      setIsSingleMessageDelete(true)
    },
    'Delete Thread': (message: StreamMessage) => {
      setOpen(true)
      setMessageToDelete(message)
      setIsSingleMessageDelete(false)
    },
  }

  useEffect(() => {
    handleOpenMessage()
  }, [messagesToOpen, channel])

  useEffect(() => {
    if (messages) {
      setAllMessages([...messages])
    }
  }, [messages])

  const fetchBlock = async (message: MessageResponseWithBlock) => {
    if (message?.referencedBlock) {
      const block = await getPageObjects(
        `${message.referencedBlock.pageId}`,
        `${message.referencedBlock.blockId}`,
      )

      const page = await getPage(
        `${message.referencedBlock.projectFileId}`,
        `${message.referencedBlock.pageId}`,
      )

      if (page) {
        setReferencedPage(page)
      }

      if (block) {
        setReferencedBlock(block)
      }
    }
  }

  const handleOpenMessage = async () => {
    if (messagesToOpen) {
      const allMessages = messages as any[]
      const filteredMessages = allMessages?.filter(
        (message: MessageResponseWithBlock) =>
          message.referencedBlock?.blockId &&
          message.referencedBlock?.blockId === objectChatToView?._id,
      )

      if (!filteredMessages) return

      dispatch(setMessagesWithBlock([...filteredMessages]))
      dispatch(setMessagesToOpen(null))
    }
  }

  const handleCloseBlockChat = () => {
    dispatch(setMessagesWithBlock(null))
  }

  useEffect(() => {
    if (!chatOpen) {
      dispatch(setMessagesToOpen(null))
      dispatch(setSelectedMessages(null))
      dispatch(setSelectedChannel(DEFAULT_CHANNEL.cid))
      dispatch(setObjectChatToView(null))
    }
  }, [chatOpen])

  const handleDeleteMessage = async () => {
    chatClient?.deleteMessage(messageToDelete?.id || '')
    setOpen(false)
  }

  const handleDeleteThread = async () => {
    if (messageToDelete?.reply_count !== undefined) {
      await chatClient?.deleteMessage(messageToDelete.id)
    }
    if (messageToDelete?.reply_count !== undefined && messageToDelete?.reply_count > 0) {
      const response: any = (await channel?.getReplies(messageToDelete.id, { limit: 1000 }))
        ?.messages
      await response?.map((participant: any) => {
        chatClient?.deleteMessage(participant.id)
      })
    }
    setOpen(false)
  }

  const handleSetIsEditing = (message: StreamMessage) => {
    dispatch(setIsEditing(message))
  }

  useEffect(() => {
    if (!messages || !objectChatToView) return
    const allMessages = messages as any[]
    const filteredMessages = allMessages?.filter(
      (message: MessageResponseWithBlock) =>
        message.referencedBlock?.blockId &&
        message.referencedBlock?.blockId === objectChatToView?._id,
    )

    dispatch(setMessagesWithBlock([...filteredMessages]))
  }, [messages])

  useEffect(() => {
    if (selectedObject) {
      dispatch(setIsMessageThreadOpen(false))
      dispatch(setSelectedChannel(channelOptions[0].cid))
    }
  }, [selectedObject])

  const isBlockEmbed = (block: block) => {
    return (
      block.data.tag === IBlockTypes.WHITEBOARD ||
      block.data.tag === IBlockTypes.EMBED ||
      block.data.tag === IBlockTypes.DOCUMENT
    )
  }

  const handleSendMessage = async (message: any) => {
    if (chatClient) {
      if (selectedObject?._id) {
        const messageToSend: any = {
          ...message,
          mentioned_users: message?.mentioned_users.map((user: User) => user?.id), // eslint-disable-line
          referencedBlock: {
            tag: selectedObject?.data?.tag,
            blockId: selectedObject._id,
            pageId: !isBlockEmbed(selectedObject)
              ? selectedPage?.id
              : selectedObject.data.documentPage?.id,
            projectFileId: selectedPage?.projectFileId,
          },
        }

        allMessagesFromChat?.forEach((message: any) => {
          if (message?.referencedBlock?.blockId === selectedObject?._id) {
            messageToSend.parent_id = message?.id // eslint-disable-line
            delete messageToSend.referencedBlock
          }
        })

        const response = await channel?.sendMessage(messageToSend)

        const chatMessageIds =
          selectedObject.meta.chatMessageIds && response?.message.id
            ? [...selectedObject?.meta.chatMessageIds, response?.message.id] // eslint-disable-line
            : response?.message.id
            ? [response?.message.id]
            : []

        const index = getIndex(selectedObject)

        const op = getReplaceBlockMetaKeyOp(index, ['chatMessageIds'], chatMessageIds)

        submit(getBlockPageId(selectedObject), op, SourceKeys.UPDATE_CHAT_ID)

        allMessagesFromChat?.forEach((message: any) => {
          if (message?.referencedBlock?.blockId === selectedObject?._id) {
            openThread(message)
            dispatch(setIsMessageThreadOpen(true))
          }
        })
      } else {
        try {
          let text = message.text

          if (text.startsWith('@Everyday')) {
            text = text.replace('@Everyday', '/everyday')
          }

          if (
            text.startsWith('/everyday') &&
            text.toLowerCase().includes('create a new document named') &&
            selectedProjectFile
          ) {
            text = text + `;${selectedProjectFile.id}`
          }

          const messageToSend = {
            ...message,
            text: text,
            mentioned_users: message?.mentioned_users.map((user: User) => user?.id), // eslint-disable-line
          }

          setTimeout(() => {
            if (
              text.startsWith('/everyday') &&
              text.toLowerCase().includes('create a new document named') &&
              selectedProjectFile
            ) {
              _project.getAndDispatchProjectFilePages(selectedProjectFile?.id)
            }
          }, 1500)

          await channel?.sendMessage(messageToSend)
          dispatch(setSelectedChannel(channelOptions?.[0].cid))
        } catch (e) {
          console.error(e)
        }
      }
    }
    dispatch(setSelectedObject(null))
  }

  const handleSendMessageInThread = async (message: any) => {
    if (chatClient) {
      if (selectedObject) {
        if (selectedObject?._id === message?.parent.referencedBlock?.blockId) {
          const messageToSend = {
            ...message,
            mentioned_users: message?.mentioned_users.map((user: User) => user?.id), // eslint-disable-line
            parent_id: message?.parent.id, // eslint-disable-line
          }

          const response = await channel?.sendMessage(messageToSend)

          const chatMessageIds =
            selectedObject.meta.chatMessageIds && response?.message.id
              ? [...selectedObject?.meta.chatMessageIds, response?.message.id] // eslint-disable-line
              : response?.message.id
              ? [response?.message.id]
              : []

          const index = getIndex(selectedObject)

          const op = getReplaceBlockMetaKeyOp(index, ['chatMessageIds'], chatMessageIds)

          submit(getBlockPageId(selectedObject), op, SourceKeys.UPDATE_CHAT_ID)
          const allMessages = messages as any[]
          const filteredMessages = allMessages.filter(
            (message: MessageResponseWithBlock) =>
              message.referencedBlock?.blockId &&
              message.referencedBlock?.blockId === objectChatToView?._id,
          )

          dispatch(setMessagesWithBlock([...filteredMessages]))
        } else {
          handleSendMessage(message)
        }
      } else {
        const referencedBlock = message?.parent?.referencedBlock
        if (referencedBlock) {
          const response = await channel?.sendMessage({
            ...message,
            parent_id: message.parent.id, // eslint-disable-line
          })

          const blockToUpdate = getBlockById(
            getBlockPageId(referencedBlock),
            getBlockId(referencedBlock),
          )

          if (!blockToUpdate) return
          const chatMessageIds =
            blockToUpdate?.meta.chatMessageIds && response?.message.id
              ? [...blockToUpdate?.meta.chatMessageIds, response.message.id] // eslint-disable-line
              : response?.message.id
              ? [response?.message.id]
              : []

          const index = getIndex(blockToUpdate)

          const op = getReplaceBlockMetaKeyOp(index, ['chatMessageIds'], chatMessageIds)

          submit(getBlockPageId(blockToUpdate), op, SourceKeys.UPDATE_CHAT_ID)
        } else {
          await channel?.sendMessage({
            ...message,
            parent_id: message.parent.id, // eslint-disable-line
            mentioned_users: message?.mentioned_users.map((user: User) => user?.id), // eslint-disable-line
          })
        }
      }
    }
    dispatch(setSelectedObject(null))
  }

  const handlePinMessage = async (message: StreamMessage) => {
    const isPinned = message?.pinned
    const element = document.querySelector(
      `.str-chat__li[data-message-id="${message.id}"] .str-chat__message-actions-list-item:nth-child(2)`,
    ) as HTMLElement

    if (isPinned) {
      await chatClient?.unpinMessage(message)

      const pinnedMessagesCopy = pinnedMessages.filter(
        (pinnedMessage) => pinnedMessage.id !== message.id,
      )

      dispatch(setPinnedMessages([...pinnedMessagesCopy]))

      if (element) {
        element.innerHTML = 'Pin'
      }
    } else {
      await chatClient?.pinMessage(message)
      dispatch(setPinnedMessages([...pinnedMessages, message]))

      if (element) {
        element.innerHTML = 'Unpin'
      }
    }
  }

  const handleCloseModal = () => {
    setOpen(false)
  }

  return {
    chatClient,
    customActions,
    handleSendMessage,
    handleSendMessageInThread,
    open,
    messageToDelete,
    handleDeleteMessage,
    handleDeleteThread,
    handleCloseModal,
    isSingleMessageDelete,
    selectedMessages,
    selectedChannel,
    channelOptions,
    messagesWithBlock,
    handleCloseBlockChat,
    threadIsOpen,
    referencedPage,
    referencedBlock,
    messages,
  }
}

export default useWindow
