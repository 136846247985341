// ** RTK Query Imports
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// ** Tags
import tags from './tags'
import { LocalStorageKeys } from 'shared/lib/useLocalStorage'

const googleApi = createApi({
  reducerPath: 'googleApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: (headers) => {
      const token = localStorage.getItem(LocalStorageKeys.GOOGLE_TOKEN)
      headers.set('Authorization', `Bearer ${token}`)
      return headers
    },
  }),
  tagTypes: [tags.GOOGLE],
  endpoints: () => ({}),
})

export default googleApi
