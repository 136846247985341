import { SidebarItemNewPage } from '_features/page/addPage'
import { EmptyPageContainer, StyledDropdownButton } from 'components/templates/Page/styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { EditAddPlusIcon } from '_widgets/LeftSidebar/ui/styles'

const EmptyPage = () => {
  return (
    <EmptyPageContainer>
      <SidebarItemNewPage
        isScrollable
        toggleButton={
          <StyledDropdownButton>
            <CustomP fontSize={'22px'} alignItems={'center'} display={'flex'} gap={'8px'}>
              <EditAddPlusIcon />
              Add a new file to start collaborating
            </CustomP>
          </StyledDropdownButton>
        }
      />
    </EmptyPageContainer>
  )
}

export default EmptyPage
