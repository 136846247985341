import { ITools } from 'interfaces/whiteboard'
import { IconWrapper } from '_entities/whiteboard/ui/styles'
import useLocalStorage from 'shared/lib/useLocalStorage'
import { FunctionComponent, SVGProps, useRef } from 'react'
import ShapeSelectorDropdown from 'components/organisms/Dropdowns/ShapeSelectorDropdown/ShapeSelectorDropdown'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setTool } from 'redux/reducers/whiteboardReducer'
import { useShape } from '_entities/shape'
import { StyledComponent } from 'styled-components'

interface Props {
  isDisabled: boolean
}

export const ShapeSelector = (props: Props) => {
  const LAST_SELECTED_SHAPE = 'lastSelectedShape'
  const tool = useAppSelector((state) => state.whiteboard.tool)

  const { setItem, getParsedItem } = useLocalStorage()
  const _shape = useShape()
  const dispatch = useAppDispatch()
  const toggleButtonRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

  const lastSelectedShape: ITools = getParsedItem(LAST_SELECTED_SHAPE)

  const isSelected = _shape.ShapeOptions.includes(tool)

  const ShapeIcon:
    | StyledComponent<
        FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>,
        any,
        object,
        never
      >
    | undefined = _shape.iconRenderConfig[lastSelectedShape ?? ITools.CIRCLE]

  if (!isSelected) {
    return (
      <IconWrapper
        disabled={props.isDisabled}
        onClick={() => {
          if (props.isDisabled) return
          if (lastSelectedShape && _shape.ShapeOptions.includes(lastSelectedShape)) {
            dispatch(setTool(lastSelectedShape))
          } else {
            dispatch(setTool(ITools.CIRCLE))
          }
        }}
        ref={toggleButtonRef}
        selected={isSelected}
      >
        {ShapeIcon && <ShapeIcon />}
      </IconWrapper>
    )
  }

  return (
    <ShapeSelectorDropdown
      isDisabled={props.isDisabled}
      isSelected={isSelected}
      selectedShape={lastSelectedShape}
      toggleIcon={ShapeIcon && <ShapeIcon />}
      onSelect={(option) => {
        if (props.isDisabled) return
        dispatch(setTool(option))
        setItem(LAST_SELECTED_SHAPE, option)
      }}
    />
  )
}
