import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { TAGS } from 'shared/api'
import { envVars } from 'config/env'

export const managerApi = createApi({
  reducerPath: 'managerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envVars.api.https}api/managers/`,
    credentials: 'include',
  }),
  tagTypes: [TAGS.MANAGER],
  endpoints: () => ({}),
})
