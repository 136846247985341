import Embed from 'components/atoms/IFrameEmbed/IFrameEmbed'
import { Container } from './styles'
import { FIGMA_EMBED_IDENTIFIER, FIGMA_EMBED_REDIRECTER } from '_entities/embed'

type FigmaFullPageEmbedPropsType = {
  embedUrl?: string
}

export const FigmaFullPageEmbed = ({ embedUrl }: FigmaFullPageEmbedPropsType) => {
  return (
    <Container>
      <Embed
        width='100%'
        height='100%'
        src={`${FIGMA_EMBED_REDIRECTER}${FIGMA_EMBED_IDENTIFIER}${embedUrl}}`}
      />
    </Container>
  )
}
