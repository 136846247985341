import React from 'react'
import { StyledDropDownListContainer, StyledDropDownList } from './styles'
import CustomSelectOption from '../CustomSelectOptions/CustomSelectOptions'
import CheckMark from 'assets/images/check.svg'
import { IDropdownOption } from 'interfaces/invite'
import IconOption from '../IconOption/IconOption'
import { HrDivider } from 'components/atoms/HrDivider/HrDivider'

interface Props {
  options?: any[]
  fontsize?: string
  defaultSelect?: string
  isOpen?: boolean
  onClickFunction?: (value: any) => () => void
  selectedOption?: IDropdownOption
  dropdownWidth?: string
  dropdownBottom?: string
  dropdownLeft?: string
  dropdownIconOptionPadding?: string
  dropdownTop?: string
  dropdownRight?: string
  optionsInSelectPadding?: string
  fontWeightForOptions?: string
}

export const DropdownListContainer = ({
  options,
  fontsize,
  defaultSelect,
  isOpen,
  onClickFunction,
  selectedOption,
  dropdownWidth,
  dropdownBottom,
  dropdownLeft,
  dropdownIconOptionPadding,
  dropdownTop,
  dropdownRight,
  optionsInSelectPadding,
  fontWeightForOptions,
}: Props) => {
  return (
    <StyledDropDownListContainer
      isOpen={isOpen}
      fontsize={fontsize}
      width={dropdownWidth}
      bottom={dropdownBottom}
      left={dropdownLeft}
      top={dropdownTop}
      right={dropdownRight}
    >
      <StyledDropDownList>
        {options?.map((option, i) => (
          <div key={i + option.name}>
            {option.name === 'Remove access' || option.name === 'Remove member' ? (
              <div>
                <HrDivider bordertop={'1px solid #F2F2F2'} />
                <IconOption
                  key={option.name + i}
                  onClick={onClickFunction && onClickFunction(option.name)}
                  option={option.name}
                  color={option.alert && '#FA5A32'}
                  padding={dropdownIconOptionPadding}
                  src={option.src}
                  alt={option.alt}
                />
              </div>
            ) : (
              <CustomSelectOption
                onClick={onClickFunction && onClickFunction(option)}
                key={i}
                isSelected={
                  selectedOption
                    ? selectedOption.name === option.name
                    : defaultSelect === option.name
                }
                src={CheckMark}
                alt={'Checkmark'}
                option={option.name}
                optionsInSelectPadding={optionsInSelectPadding}
                fontWeightForOptions={fontWeightForOptions}
              />
            )}
          </div>
        ))}
      </StyledDropDownList>
    </StyledDropDownListContainer>
  )
}
