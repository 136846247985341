import { motion } from 'framer-motion'
import styled from 'styled-components'
import { ReactComponent as CheckBox } from 'assets/images/checktask.svg'
import { ReactComponent as ArrowLeftSvg } from 'assets/images/arrow_left_task_slider_close.svg'
import { ReactComponent as ArrowBarToRight } from 'assets/images/arrow-bar-to-right.svg'

export const ArrowLeft = styled(ArrowLeftSvg)`
  cursor: pointer;
  path {
    stroke: var(--primary-text);
  }
`

export const StyledCheckBoxIcon = styled(CheckBox)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledArrowBarToRight = styled(ArrowBarToRight)`
  path {
    stroke: var(--primary-text);
  }
`

export const TasksDrawerContainer = styled(motion.div)`
  height: 100vh;
  max-height: 100vh;
  background: var(--tasks-background) !important;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
`

export const TasksDrawerContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
  background: var(--sidebars);
`

export const TasksDrawerHead = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 20px;
  box-shadow: 0px 4px 16px 0px #00000026;
  background-color: var(--tasks-background);
`

export const TasksDrawerDropdownContainer = styled.div`
  padding: 10px 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

export const TasksDrawerDropdown = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const TasksDropdownTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: var(--primary-text);
`

export const TasksDrawerBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  background: var(--sidebars);
`

export const TasksDrawerGrow = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`
export const TasksDrawerDetailsWrapper = styled.div`
  padding: 12px;
  margin: 12px;
  height: 100%;
  .PageBoundaryElementDetector {
    border-radius: 8px;
    padding-left: 24px !important;
    padding-top: 42px !important;
  }
`
