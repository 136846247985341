import React, { useEffect, useState } from 'react'
import { SettingsOptionText, SettingsOptionWrapper, StyledDropdownTrigger } from '../styles'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { PaletteColor } from 'components/organisms/Palette/styles'
import { getCtaTextColor, CtaButtonProps, getCtaButtonId, getCtaBlocks } from '_entities/cta'
import { StyledColorPicker } from 'components/molecules/ColorPicker/styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { Color } from 'react-color'
import { getById, getElements } from 'shared/lib'

export const TextColourEdit = (props: CtaButtonProps) => {
  const [selectedColor, setSelectedColor] = useState<string | null>(null)

  useEffect(() => {
    setSelectedColor(getCtaTextColor(props.button))
  }, [props.button])

  const onColorChange = (color: { hex: string }) => {
    const buttons = getCtaBlocks(props.button)

    if (buttons) {
      buttons.forEach((button) => {
        ;(button as HTMLElement).style.color = color.hex
      })
    }
  }

  return (
    <SettingsOptionWrapper>
      <SettingsOptionText>Text Colour</SettingsOptionText>

      <RadixDropdown
        trigger={
          <StyledDropdownTrigger>
            <PaletteColor color={selectedColor || '#ffffff'} size='18px' />
            <CustomP fontWeight='500'>{selectedColor || '#ffffff'}</CustomP>
          </StyledDropdownTrigger>
        }
      >
        <StyledColorPicker
          onChange={(color: { hex: string }) => {
            setSelectedColor(color.hex)
            onColorChange(color)
          }}
          color={selectedColor as Color}
          onChangeComplete={(color: { hex: string }) => {
            props.updateCtaButton({
              id: props.button.id,
              styling: {
                ...props.button.styling,
                textColor: color.hex,
              },
            })
            const button = getById(getCtaButtonId(props.button.id))

            if (button) {
              button.style.color = color.hex
            }
          }}
        />
      </RadixDropdown>
    </SettingsOptionWrapper>
  )
}
