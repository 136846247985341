import { ITask, ITaskProperty } from 'interfaces/taskManager'

export const getAllProperties = (tasks: ITask[]): ITaskProperty[] => {
  let allProperties: ITaskProperty[] = []
  if (tasks) {
    tasks.forEach((task) => {
      if (task?.properties) allProperties = allProperties.concat(task?.properties)
    })
  }
  return allProperties
}
