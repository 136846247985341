import { block } from '_entities/block'
import { useAppDispatch } from 'redux/hooks'
import { setFontSize } from 'redux/reducers/pageReducer'
import { DEFAULT_SIZE } from '_entities/text'
import { getEditor } from 'shared/lib'

export const useFontSize = () => {
  const dispatch = useAppDispatch()

  const updateTextSizeAtCursor = (block: block, size: number) => {
    const editor = getEditor(block._id)
    dispatch(setFontSize(size))
    editor?.format('size', `${size}px`, 'user')
  }

  const updateFullTextSize = (block: block, size: number) => {
    const editor = getEditor(block._id)
    if (!editor) return
    editor?.formatText(0, editor.getLength(), 'size', `${size}px`, 'user')
  }

  const decreaseFontSize = (block: block, currentSize: number) => {
    const editor = getEditor(block._id)
    if (!editor) return
    const newSize = currentSize ? currentSize - 1 : DEFAULT_SIZE - 1
    updateFullTextSize(block, newSize)
  }

  const increaseFontSize = (block: block, currentSize: number) => {
    const editor = getEditor(block._id)
    if (!editor) return
    const newSize = currentSize ? currentSize + 1 : DEFAULT_SIZE
    updateFullTextSize(block, newSize)
  }

  const updateSelectionTextSize = (block: block, size: number) => {
    const editor = getEditor(block._id)
    if (!editor) return
    const selection = editor.getSelection()
    if (!selection) return
    editor.formatText(selection.index, selection.length, 'size', `${size}px`, 'user')
  }

  const setFontSizeOnFocusedEditor = (block: block, size: number) => {
    const editor = getEditor(block._id)
    if (!editor) return
    const selection = editor.getSelection()
    if (selection) {
      if (selection.length > 0) {
        updateSelectionTextSize(block, size)
      } else {
        updateTextSizeAtCursor(block, size)
      }
    }
  }

  return {
    updateSelectionTextSize,
    updateFullTextSize,
    setFontSizeOnFocusedEditor,
    decreaseFontSize,
    increaseFontSize,
  }
}
