import React, { useState } from 'react'
import { EmbedType, embedTypeDisplayName } from '_entities/embed'
import RadixDropdown, {
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { StyledPageLinkSelectorWrapper } from './styles'
import SingleSelect from 'components/organisms/SingleSelect/SingleSelect'
import { PageOption, pageOptions } from 'components/organisms/ProjectFileRightSideBar/constants'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import EmbedMenu from './EmbedMenu/EmbedMenu'
import {
  checkIsPdf,
  checkIsNotion,
  checkIsAsana,
  checkIsMiro,
  checkIsFigma,
  checkIsYoutube,
  checkIsVimeo,
  checkIsOfficeEmbedCode,
  checkIsGoogleDocLink,
  checkIsGoogleSheetsLink,
  checkIsGoogleSlidesLink,
  checkIsEmail,
} from '_entities/embed/ExternalEmbed/model/EmbedRegex'
import Link from 'components/molecules/Link/Link'
import { useEmbedCreation, useEmbedSelection, useInternalEmbedSelection } from '_features/embed'
import { PageTypesEnum } from 'interfaces/projectFile'
import { setTool } from 'redux/reducers/whiteboardReducer'
import { ITools } from 'interfaces/whiteboard'
import { IBlockTypes } from '_entities/block'
import { IconButtonSize } from '_features/toolbar'
import { icons } from 'shared/icons'

interface Props {
  isDisabled: boolean
}

const EmbedDropdown = (props: Props) => {
  const [open, setOpen] = useState<boolean | undefined>(false)
  const [pageLinkSelectorOpen, setPageLinkSelectorOpen] = useState(false)
  const [uploadSelectorOpen, setUploadSelectorOpen] = useState(false)
  const [embedType, setEmbedType] = useState<EmbedType | ''>('')
  const [link, setLink] = useState('')
  const [linkButtonDisabled, setLinkButtonDisabled] = useState(true)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const pagesWithoutTaskManager = pages?.filter((page) => page.type !== PageTypesEnum.TASK_MANAGER)
  const _embedCreation = useEmbedCreation()
  const _embedSelection = useEmbedSelection()
  const _internalEmbedSelection = useInternalEmbedSelection()
  const dispatch = useAppDispatch()

  const isOnlyLinkTypes = () =>
    embedType === EmbedType.VIMEO ||
    embedType === EmbedType.YOUTUBE ||
    embedType === EmbedType.NOTION ||
    embedType === EmbedType.ASANA ||
    embedType === EmbedType.GOOGLE_DOC ||
    embedType === EmbedType.GOOGLE_SHEETS ||
    embedType === EmbedType.GOOGLE_SLIDES ||
    embedType === EmbedType.GOOGLE_CALENDAR ||
    embedType === EmbedType.MIRO ||
    embedType === EmbedType.FIGMA ||
    embedType === EmbedType.GENERIC_LINK

  const onOpenChange = (open: boolean) => {
    if (open === false) {
      setPageLinkSelectorOpen(false)
      setEmbedType('')
      setLink('')
      setLinkButtonDisabled(true)
      setUploadSelectorOpen(false)
    }
    setOpen(open)
  }

  const linkConfig = {
    [EmbedType.FILE]: (pasted: string) => {
      if (pasted && pasted.length > 0) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.GENERIC_LINK]: (pasted: string) => {
      if (pasted && pasted.length > 0) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.IMAGE]: (pasted: string) => {
      if (pasted && pasted.length > 0) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.PDF]: async (pasted: string) => {
      const isPdf = await checkIsPdf(pasted)
      if (pasted && isPdf) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.NOTION]: (pasted: string) => {
      const isNotion = checkIsNotion(pasted)
      if (pasted && isNotion) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.ASANA]: (pasted: string) => {
      const isAsana = checkIsAsana(pasted)
      if (pasted && isAsana) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.VIDEO]: () => void 0,
    [EmbedType.VIMEO]: (pasted: string) => {
      const isVimeo = checkIsVimeo(pasted)
      if (pasted && isVimeo) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.YOUTUBE]: (pasted: string) => {
      const isYoutube = checkIsYoutube(pasted)
      if (pasted && isYoutube) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.GOOGLE_DOC]: (pasted: string) => {
      const isGoogleDoc = checkIsGoogleDocLink(pasted)
      if (pasted && isGoogleDoc) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.GOOGLE_SHEETS]: (pasted: string) => {
      const isGoogleSlides = checkIsGoogleSheetsLink(pasted)
      if (pasted && isGoogleSlides) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.GOOGLE_SLIDES]: (pasted: string) => {
      const isGoogleSheets = checkIsGoogleSlidesLink(pasted)
      if (pasted && isGoogleSheets) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.GOOGLE_CALENDAR]: (pasted: string) => {
      const isGoogleCalendar = checkIsEmail(pasted)
      if (pasted && isGoogleCalendar) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.MIRO]: (pasted: string) => {
      const isMiro = checkIsMiro(pasted)
      if (pasted && isMiro) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.FIGMA]: (pasted: string) => {
      const isFigma = checkIsFigma(pasted)
      if (pasted && isFigma) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.OFFICE]: (pasted: string) => {
      const isFigma = checkIsOfficeEmbedCode(pasted)
      if (pasted && isFigma) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.OFFICE_DOCUMENT]: () => void 0,
    [EmbedType.OFFICE_EXCEL]: () => void 0,
    [EmbedType.OFFICE_POWERPOINT]: () => void 0,
  }

  const onPaste = async (e: React.ClipboardEvent<HTMLDivElement>) => {
    const pasted = e.clipboardData.getData('Text')
    linkConfig[embedType as EmbedType](pasted)
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value)
    linkConfig[embedType as EmbedType](e.target.value)
  }

  return (
    <RadixDropdown
      disabled={props.isDisabled}
      open={open}
      onOpenChange={onOpenChange}
      trigger={
        <IconButton
          disabled={props.isDisabled}
          width={IconButtonSize + 'px'}
          height={IconButtonSize + 'px'}
        >
          <icons.filePlus />
        </IconButton>
      }
    >
      {!pageLinkSelectorOpen && !embedType && !uploadSelectorOpen && (
        <EmbedMenu
          onSelectDoc={() => {
            dispatch(setTool(ITools.INTERNAL_EMBED))
            _internalEmbedSelection.setSelectedEmbed({ type: IBlockTypes.DOCUMENT })
            onOpenChange(false)
          }}
          onSelectWb={() => {
            dispatch(setTool(ITools.INTERNAL_EMBED))
            _internalEmbedSelection.setSelectedEmbed({ type: IBlockTypes.WHITEBOARD })
            onOpenChange(false)
          }}
          setEmbedType={setEmbedType}
          setPageLinkSelectorOpen={setPageLinkSelectorOpen}
          setUploadSelectorOpen={setUploadSelectorOpen}
          handleFileUpload={(e) => {
            const files = e.target.files
            const firstFile = files && files[0]
            if (!firstFile) return
            dispatch(setTool(ITools.FILE))
            window.__FILE__ = firstFile
            onOpenChange(false)
          }}
        />
      )}
      {pageLinkSelectorOpen && (
        <StyledPageLinkSelectorWrapper>
          <SingleSelect
            autoFocus
            options={pageOptions(pagesWithoutTaskManager)}
            onChange={(item) => {
              dispatch(setTool(ITools.INTERNAL_EMBED))
              _internalEmbedSelection.setSelectedEmbed({ pageId: item?.value })
              onOpenChange(false)
            }}
            placeholder='Search pages'
            isClearable={false}
            menuIsOpen
            components={{
              IndicatorSeparator: () => null,
              DropdownIndicator: () => null,
              Option: PageOption as any,
            }}
          />
        </StyledPageLinkSelectorWrapper>
      )}
      {isOnlyLinkTypes() && (
        <Link
          loading={_embedCreation.loading}
          title={`Embed ${embedTypeDisplayName[embedType as EmbedType]}`}
          placeholder={
            embedType === EmbedType.GOOGLE_CALENDAR ? 'Paste email' : 'Paste link to embed here'
          }
          buttonText={'Embed'}
          link={link}
          onInputChange={onInputChange}
          addLink={() => {
            if (!embedType) return
            dispatch(setTool(ITools.EXTERNAL_EMBED))
            _embedSelection.setSelectedEmbed({
              type: embedType,
              url: link,
              name: embedType,
            })
            onOpenChange(false)
          }}
          disabled={link === '' || linkButtonDisabled}
          onPaste={onPaste}
        />
      )}
    </RadixDropdown>
  )
}

export default EmbedDropdown
