import { IPalette } from 'interfaces/settings'
import { ReactComponent as PlusIcon } from 'assets/images/Plus.svg'
import CustomP from 'components/atoms/CustomP/CustomP'
import { Spinner } from 'react-bootstrap'
import {
  ColorSettingsWrapper,
  CreateNewPalletWrapper,
  StyledSubtitle,
  StyledTitle,
} from '../ColorSettings/styles'
import useAdminColorSettings from './useAdminColorSettings'
import AdminPalette from '../AdminPalette/AdminPalette'

const AdminColorSettings = () => {
  const {
    selected,
    expanded,
    palettes,
    handleSelectPalette,
    handleExpandOnClick,
    handleCreateNewPalette,
    loading,
    fetchPalettes,
    setDefaultPalette,
    handleResetColorToDefault,
    sortedPalettes,
  } = useAdminColorSettings()

  return (
    <ColorSettingsWrapper>
      <StyledTitle>Color Settings</StyledTitle>
      <StyledSubtitle>Space Color Palettes</StyledSubtitle>
      {palettes &&
        sortedPalettes?.map((palette: IPalette) => (
          <AdminPalette
            key={palette.id}
            palette={palette}
            selected={selected}
            handleSelectPalette={handleSelectPalette}
            handleExpandOnClick={handleExpandOnClick}
            expanded={expanded}
            fetchPalettes={fetchPalettes}
            setDefaultPalette={setDefaultPalette}
            handleResetColorToDefault={handleResetColorToDefault}
          />
        ))}
      {loading === 'New' ? (
        <Spinner size='sm' />
      ) : (
        <CreateNewPalletWrapper onClick={handleCreateNewPalette}>
          <PlusIcon />
          <CustomP color='var(--secondary-text)' fontSize='14px' fontWeight='500'>
            Create New Palette
          </CustomP>
        </CreateNewPalletWrapper>
      )}
    </ColorSettingsWrapper>
  )
}

export default AdminColorSettings
