import { getBlockPageId, getIndex, type block } from '_entities/block'
import { getBorderColor, getBorderWidth, getFill, useFrame } from '_entities/whiteboard'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { SourceKeys } from 'interfaces/editor'
import { Rect } from 'konva/lib/shapes/Rect'

export const useFrameColor = () => {
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _frame = useFrame()

  const handleSelectColor = (block: block, color: string) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    if (index < 0) return
    const currentFill = getFill(block)
    if (currentFill) {
      _submit.submit(
        pageId,
        _json1.getReplaceBlockDataKeyOp(index, ['frame', 'fill'], color, currentFill),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      _submit.submit(
        pageId,
        _json1.getInsertKeyInDataKeyOp(index, ['frame', 'fill'], color),
        SourceKeys.UPDATE_BLOCK,
      )
  }

  const handleSelectBorderColor = (block: block, color: string) => {
    if (!block) return
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    if (index < 0) return
    const currentColor = getBorderColor(block)
    if (currentColor !== undefined) {
      _submit.submit(
        pageId,
        _json1.getReplaceBlockDataKeyOp(index, ['frame', 'borderColor'], color, currentColor),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      _submit.submit(
        pageId,
        _json1.getInsertKeyInDataKeyOp(index, ['frame', 'borderColor'], color),
        SourceKeys.UPDATE_BLOCK,
      )
  }

  const handleSelectBorderWidth = (block: block, width: number) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    if (index < 0) return
    const currentWidth = getBorderWidth(block)
    if (currentWidth !== undefined) {
      _submit.submit(
        pageId,
        _json1.getReplaceBlockDataKeyOp(index, ['frame', 'borderWeight'], width, currentWidth),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      _submit.submit(
        pageId,
        _json1.getInsertKeyInDataKeyOp(index, ['frame', 'borderWeight'], width),
        SourceKeys.UPDATE_BLOCK,
      )
  }

  const updateFrameRealTime = (block: block, rgba: string) => {
    const frameHtml = _frame.getFrameHtmlElement(block)
    const frameKonvaEl = _frame.getFrameKonvaElement(block)
    if (frameHtml) frameHtml.style.backgroundColor = rgba
    if (frameKonvaEl) (frameKonvaEl as Rect).fill(rgba)
  }

  return {
    handleSelectColor,
    handleSelectBorderColor,
    handleSelectBorderWidth,
    updateFrameRealTime,
  }
}
