import React, { useState } from 'react'
import CustomSelectDropdown from '../CustomSelectDropdown/CustomSelectDropdown'
import FlexContainer from '../FlexContainer/FlexContainer'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { IconHover } from './styles'
import { useAppSelector } from 'redux/hooks'

interface IconWithDropdown {
  iconLeft?: JSX.Element
  iconRight?: JSX.Element
  options?: any[]
  defaultSelect?: string
  onChange?: (value: any) => void
  marginIconRight?: string
  padding?: string
}

const IconWithDropdown = ({
  iconLeft,
  iconRight,
  options,
  defaultSelect,
  onChange,
  marginIconRight,
  padding,
}: IconWithDropdown) => {
  const [hover, setHover] = useState<boolean>(false)
  const currentProjectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  return (
    <FlexContainer justifyContent='flex-start' padding={padding}>
      {iconLeft}
      <CustomSelectDropdown
        options={options}
        defaultSelect={defaultSelect}
        margin={'0 10px 0 10px'}
        onChange={onChange}
      />
      <IconHover
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        margin={marginIconRight}
      >
        {iconRight}
        <Tooltip
          width={defaultSelect === `Everyone from ${currentProjectFile?.title}` ? '288px' : '274px'}
          height={defaultSelect === `Everyone from ${currentProjectFile?.title}` ? 'auto' : 'auto'}
          active={hover}
          message={
            defaultSelect === `Everyone from ${currentProjectFile?.title}`
              ? 'Project members will be able to access this page with permissions assigned on the project. You can invite other members to this page only'
              : 'Only members added to this page will have access to it. Page will not be visible to'
          }
          aligntext={'center'}
          padding={'8px'}
          className={'mb-0'}
          left={
            defaultSelect === `Everyone from ${currentProjectFile?.title}` ? '-130px' : '-120px'
          }
          top={'30px'}
        />
      </IconHover>
    </FlexContainer>
  )
}

export default IconWithDropdown
