import { type block } from '_entities/block'
import { ColorCircle, IconButtonRow, TextColorIcon, ColorPickerIcon } from '../styles'
import { Dropdown } from 'react-bootstrap'
import { IconButton } from 'components/atoms/Button/Button'
import { PageTypesEnum } from 'interfaces/projectFile'
import { icons } from 'shared/icons'
import { CaretDownIcon } from 'components/molecules/Dropdown/ClassicDropdown/ClassicDropdown'
import TextColorDropdownMenu from 'editor/EditableBlock/BlockToolbar/TextColorDropdownMenu/TextColorDropdownMenu'
import HighlightColorDropdownMenu from 'editor/EditableBlock/BlockToolbar/HighlightColorDropdownMenu/HighlightColorDropdownMenu'

export type TextAndBackgroundColorProps = {
  block: block
  BlockColor: string
  BlockBackground: string
  pageType: PageTypesEnum
}

const TextAndBackgroundColor = (props: TextAndBackgroundColorProps) => {
  const { block, BlockColor, BlockBackground, pageType } = props
  return (
    <IconButtonRow>
      {/* Text color */}
      <Dropdown
        // Prevent editor focus loss
        onMouseDown={(event) => event.preventDefault()}
      >
        <Dropdown.Toggle as={'div'}>
          <IconButton variant='sidebar' size='small'>
            <TextColorIcon fillColor={BlockColor} />
            <icons.caretDown />
          </IconButton>
        </Dropdown.Toggle>
        <TextColorDropdownMenu
          block={block}
          isWhiteboard={pageType === PageTypesEnum.WHITE_BOARD}
        />
      </Dropdown>

      {/* Background color */}
      <Dropdown onMouseDown={(event) => event.preventDefault()}>
        <Dropdown.Toggle as={'div'}>
          <IconButton variant='sidebar' size='small'>
            {!BlockBackground || BlockBackground === 'transparent' ? (
              <ColorPickerIcon />
            ) : (
              <ColorCircle fillColor={BlockBackground} />
            )}
            <CaretDownIcon />
          </IconButton>
        </Dropdown.Toggle>
        <HighlightColorDropdownMenu
          block={block}
          isWhiteboard={pageType === PageTypesEnum.WHITE_BOARD}
        />
      </Dropdown>
    </IconButtonRow>
  )
}

export default TextAndBackgroundColor
