import './checkmark.css'
import { FullWidthComponent } from './styles'
import Spinner from './Spinner'

interface Props {
  isDone?: boolean
  isFail?: boolean
}

const FullScreenLoader = ({ isDone, isFail }: Props) => {
  return (
    <FullWidthComponent>
      <Spinner {...{ isDone, isFail }} />
      {/*  <LoadingAnim className='loading-spinner-waiting-screen mb-3' /> */}
    </FullWidthComponent>
  )
}

export default FullScreenLoader
