import SharingAccess from 'components/molecules/SharingAccess/SharingAccess'
import { usePageInviteModal } from './usePageInviteModal'
import InviteByLink from 'components/templates/PageInviteModal/InviteByLink/InviteByLink'
import PageMembers from 'components/organisms/PageMembers/PageMembers'
import InviteByEmail from './InviteByEmail/InviteByEmail'
import Modal from 'components/molecules/Modal/Modal'

type Props = {
  show?: boolean
  setShow: (value: boolean) => void
}

const PageInviteModal = ({ show, setShow }: Props) => {
  const { selectedPage, userInvited, handleUserInvited, handleUserNotInvited } =
    usePageInviteModal()

  return (
    <Modal title={selectedPage?.title} open={show} setOpen={setShow}>
      <SharingAccess />
      <InviteByEmail handleUserInvited={handleUserInvited} />

      {selectedPage && (
        <PageMembers
          page={selectedPage}
          userInvited={userInvited}
          handleUserNotInvited={handleUserNotInvited}
        />
      )}
      <InviteByLink />
    </Modal>
  )
}

export default PageInviteModal
