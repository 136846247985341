import React from 'react'
import { ITask } from 'interfaces/taskManager'
import { PropertiesContainer, TaskTitleWrapper } from './styles'
import { usePropertyValueElement } from '_features/task'
import { DropdownOptionContainer } from 'components/atoms/PropertyContainer/PropertyContainer'
import { SidebarListItemInput } from '../TaskManagerBoard/styles'
import { useTaskManagerModal } from './useTaskManagerModal'
import Dots from 'components/atoms/Dots/Dots'
import { CustomDropdown } from 'components/molecules/CustomDropdown/CustomDropdown'
import TaskBlock from '../TaskBlock/TaskBlock'
import { getColor } from 'shared/colors'
import { useTask } from '_entities/task'

interface Props {
  selectedTask: ITask
}

const Details = (props: Props) => {
  const { renderPropertyValueElement } = usePropertyValueElement()

  const { statusProperty, assigneeProperty, dueDateProperty } = useTask({
    task: props.selectedTask,
  })

  const properties = [assigneeProperty, statusProperty, dueDateProperty]

  const { topDropdownOptions } = useTaskManagerModal({
    task: props.selectedTask,
  })

  const { handleRenameTask, setIsTaskTitleRename, block, page, inputRef, handleRedirectToBlock } =
    useTask({
      task: props.selectedTask,
      isSlider: false,
    })

  return (
    <>
      <TaskTitleWrapper>
        <SidebarListItemInput
          ref={inputRef}
          width='100%'
          type='text'
          defaultValue={props.selectedTask.title}
          key={props.selectedTask.title}
          onChange={handleRenameTask}
          onClick={setIsTaskTitleRename}
          placeholder='Task title'
        />
        <CustomDropdown
          menuPadding='0'
          toggleButton={<Dots color={getColor('--primary-text')} />}
          dropdownOptions={topDropdownOptions}
        />
      </TaskTitleWrapper>
      <PropertiesContainer>
        {props.selectedTask &&
          properties &&
          properties.map((prop, i) => {
            return (
              <DropdownOptionContainer
                padding='0'
                margin='0'
                key={`board-properties_${prop?.id}`}
                width='auto'
              >
                {prop && renderPropertyValueElement(prop)}
              </DropdownOptionContainer>
            )
          })}
      </PropertiesContainer>
      {block && (
        <TaskBlock
          block={block}
          page={page}
          margin={'0 0 24px 0'}
          handleRedirectToBlock={handleRedirectToBlock}
          isDisabled={false}
        />
      )}
    </>
  )
}

export default Details
