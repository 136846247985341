import * as json1 from 'ot-json1'
import {
  IBlockTypes,
  MAX_LIST_INDENTATION_LEVEL,
  INDENTATION,
  getBlockPageId,
  getIndex,
} from '_entities/block'
import { useCreateBlock, useDeleteBlock } from '_features/block'
import { SourceKeys } from 'interfaces/editor'
import { useAppSelector } from 'redux/hooks'
import { useCreatePage } from '_features/page'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { getNoBoldTags } from 'components/organisms/ProjectFileRightSideBar/useTextBlockOptions'
import { getBlockFromEvent } from './events'

interface Props {
  actionMenuOpen: boolean
  openActionMenu: () => void
}

export const useKeyHandler = (props: Props) => {
  const space = useAppSelector((state) => state.space.currentSpace)
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _createBlock = useCreateBlock()
  const _createPage = useCreatePage()
  const _deleteBlock = useDeleteBlock()

  const pages = useAppSelector((state) => state.projectFile.pages)

  const handleTabKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    let op: json1.JSONOp
    const block = getBlockFromEvent(e)
    if (!block) return
    const index = getIndex(block)
    if (e?.key === 'Tab' && !e?.shiftKey) {
      e?.preventDefault()
      if (block.data.indent || block.data.indent === 0) {
        if (
          (block.data.tag === IBlockTypes.LIST || block.data.tag === IBlockTypes.CHECKLIST) &&
          block.data.indent === MAX_LIST_INDENTATION_LEVEL * INDENTATION
        )
          return
        op = _json1.getReplaceBlockDataKeyOp(
          index,
          ['indent'],
          block.data.indent + INDENTATION,
          block.data.indent,
        )
      } else {
        op = _json1.getInsertKeyInDataKeyOp(index, ['indent'], INDENTATION)
      }

      _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
    }

    if (e?.key === 'Tab' && e?.shiftKey) {
      e?.preventDefault()
      if (block.data.indent && block.data.indent > 0) {
        op = _json1.getReplaceBlockDataKeyOp(
          index,
          ['indent'],
          block.data.indent - INDENTATION,
          block.data.indent,
        )
        _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
      } else if (block.data.indent === 0) {
        return
      } else {
        op = _json1.getInsertKeyInDataKeyOp(index, ['indent'], INDENTATION)
        _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
      }
    }
  }

  const handleNewDocKeyDown = async (e: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if ((e?.ctrlKey || e?.metaKey) && e?.key === 'd') {
      e.preventDefault()
      if (pages && project && space) {
        const block = getBlockFromEvent(e)
        if (!block) return
        const createdPage = await _createPage.createDocumentPage(
          pages,
          project.id,
          space.id,
          getBlockPageId(block),
        )

        if (createdPage) {
          _createBlock.createDocumentBlock(block, IBlockTypes.TEXT)
        }
      }
    }
  }

  const handleNewWhiteboardKeyDown = async (e: React.KeyboardEvent<HTMLDivElement> | undefined) => {
    if ((e?.ctrlKey || e?.metaKey) && e?.key === 'w') {
      e.preventDefault()
      if (pages && space && project) {
        const block = getBlockFromEvent(e)
        if (!block) return
        const createdPage = await _createPage.createWhiteboardPage(
          pages,
          project.id,
          space.id,
          getBlockPageId(block),
        )

        if (createdPage) {
          _createBlock.createDocumentBlock(block, IBlockTypes.TEXT)
        }
      }
    }
  }

  const handleSelectTextAndOpenActionMenu = (
    e: React.KeyboardEvent<HTMLDivElement> | undefined,
  ) => {
    if ((e?.ctrlKey || e?.metaKey) && e?.key === 'a') props.openActionMenu()
  }

  const handleBoldPrevention = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const block = getBlockFromEvent(e)
    if (!block) return
    if ((e?.ctrlKey || e?.metaKey) && e?.key === 'b' && getNoBoldTags(block)) e.preventDefault()
  }

  const handleDeleteImageOnBackspace = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const block = getBlockFromEvent(e)
    if (!block) return
    if (e.key === 'Backspace' && block?.data?.tag === IBlockTypes.IMAGE) {
      e.preventDefault()
      _deleteBlock.deleteBlockHandler(block)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    handleNewDocKeyDown(e)
    handleNewWhiteboardKeyDown(e)
    handleTabKeyDown(e)
    handleSelectTextAndOpenActionMenu(e)
    handleBoldPrevention(e)
    handleDeleteImageOnBackspace(e)
  }

  return {
    handleKeyDown,
  }
}
