import React from 'react'
import ChipContainer from 'components/molecules/ChipContainer/ChipContainer'
import { ReactComponent as EmptyCheckbox } from 'assets/images/Ellipse 1.svg'
import { ReactComponent as FullCheckbox } from 'assets/images/checkbox.svg'
import { PeriodButtonContainer, PeriodButtonStyled, AbsoluteContainer } from './styles'
import usePlanOverviewModal from './usePlanOverviewModal'
import { useDispatch } from 'react-redux'
import { setBillingInterval } from 'redux/reducers/subscriptionReducer'

type Props = {
  selectedPeriod: 'Monthly' | 'Yearly'
}

const MapPeriodButtons: React.FC<Props> = ({ selectedPeriod }) => {
  const { periodButtonArray } = usePlanOverviewModal()
  const dispatch = useDispatch()

  return (
    <PeriodButtonContainer>
      {periodButtonArray.map((buttonLabel, index) => {
        return (
          <PeriodButtonStyled
            isSelected={selectedPeriod === buttonLabel}
            key={index}
            onClick={() => dispatch(setBillingInterval(buttonLabel))}
          >
            {selectedPeriod === buttonLabel ? <FullCheckbox /> : <EmptyCheckbox />}
            {buttonLabel}
            {buttonLabel === 'Yearly' && (
              <AbsoluteContainer>
                <ChipContainer typeOfSubscription={'-25%'} />
              </AbsoluteContainer>
            )}
          </PeriodButtonStyled>
        )
      })}
    </PeriodButtonContainer>
  )
}

export default MapPeriodButtons
