import AccountTabUserModal from 'components/molecules/AccountTabUserModal/AccountTabUserModal'
import ConnectionTabUserModal from 'components/molecules/ConnectionTabUserModal/ConnectionTabUserModal'
import MyProfileTabUserModal from 'components/molecules/MyProfileTabUserModal/MyProfileTabUserModal'
import NotificationTabUserModal from 'components/molecules/NotificationsTabUserModal/NotificationTabUserModal'
import ReferralsTabUserModal from 'components/molecules/ReferralsTabUserModal/ReferralsTabUserModal'

export const SidebarLinks = [
  { name: 'Account', eventKey: 'first' },
  { name: 'My profile', eventKey: 'second' },
  { name: 'Referrals', eventKey: 'third' },
  { name: 'Notifications', eventKey: 'fourth' },
  { name: 'Connections', eventKey: 'fifth' },
]

export const SidebarItems = (
  userImage: string | undefined,
  setUserImage: React.Dispatch<React.SetStateAction<string | undefined>>,
  onHide?: () => void,
) => {
  return [
    {
      item: <AccountTabUserModal onHide={onHide} />,
      eventKey: 'first',
    },
    {
      item: <MyProfileTabUserModal userImage={userImage} setUserImage={setUserImage} />,
      eventKey: 'second',
    },
    { item: <ReferralsTabUserModal />, eventKey: 'third' },
    { item: <NotificationTabUserModal />, eventKey: 'fourth' },
    { item: <ConnectionTabUserModal />, eventKey: 'fifth' },
  ]
}
