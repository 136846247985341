import { IPalette, IPaletteColor } from 'interfaces/settings'
import { userPalette } from 'interfaces/auth/userData'
import convertHexToRGBA from './convertToRgba'
import { useAppSelector } from 'redux/hooks'

const useColorPalette = () => {
  const user = useAppSelector((state) => state.global.user)
  const handleSetPalette = (palette: IPalette) => {
    const colorsToChange: IPaletteColor[] = []
    if (!user?.palette) return

    const userPalette = user && [...user.palette.colors].reverse()

    for (let i = 0; i < user?.palette?.colors.length; i++) {
      if (palette.attributes.colors[i].color !== userPalette[i].color) {
        colorsToChange.push(palette.attributes.colors[i])
      }
    }

    colorsToChange.forEach((color: IPaletteColor) => {
      const rgbaColor = convertHexToRGBA(color.color, color.opacity)
      document.documentElement.style.setProperty(`--${color.type}`, `${rgbaColor}`)
    })
  }

  const handleSetPaletteAttributes = (palette: userPalette) => {
    palette?.colors?.forEach((color: IPaletteColor) => {
      const rgbaColor = convertHexToRGBA(color.color, color.opacity)
      document.documentElement.style.setProperty(`--${color.type}`, `${rgbaColor}`)
    })
  }

  return {
    handleSetPalette,
    handleSetPaletteAttributes,
  }
}

export default useColorPalette
