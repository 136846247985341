import styled from 'styled-components'
import { BasicCallout, BasicCalloutProps } from './BasicCallout'

export const StyledWrapper = styled.div<{
  border?: string
  background?: string
  width?: string
  height?: string
}>`
  display: inline-flex;
  justify-content: space-between;
  width: ${(props) => (props.width ? props.width : 'fit-content')};
  height: ${(props) => (props.height ? props.height : 'fit-content')};
  border: ${(props) => props.border || '1.4px solid var(--gray-disabled-stroke)'};
  border-radius: 10px;
  padding: 0 10px 0 0;
  background: ${(props) => props.background || 'var(--document-background)'};
  gap: 6px;
  svg path {
    color: var(--text);
    stroke: var(--text);
  }
`

export interface StyledCalloutProps extends BasicCalloutProps {
  id?: string
  border?: string
  background?: string
  width?: string
  height?: string
  children?: React.ReactNode
}

export const StyledCallout = (props: StyledCalloutProps) => {
  return (
    <StyledWrapper
      width={props.width}
      height={props.height}
      border={props.border}
      id={props.id}
      background={props.background}
    >
      <BasicCallout {...props} />
      {props.children}
    </StyledWrapper>
  )
}
