import { useAppSelector } from 'redux/hooks'
import { ILinkSharingOption, PageLinkSharingEnum, publicLinkNameMapper } from 'interfaces/invite'
import { useEffect, useState } from 'react'
import CustomP from 'components/atoms/CustomP/CustomP'
import { useUpdatePage } from '_features/page'

export const useInviteByLink = () => {
  const { updatePage } = useUpdatePage()
  const [defaultValue, setDefaultValue] = useState<string>()
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const getDefaultValue = () => {
    const linkSharing = selectedPage?.linkSharing as PageLinkSharingEnum
    if (linkSharing) {
      setDefaultValue(publicLinkNameMapper[linkSharing])
    }
  }

  const linkSharingOptions: ILinkSharingOption[] = [
    {
      name: (
        <CustomP paddingRight='10px' paddingLeft='5px'>
          No access via link
        </CustomP>
      ),
      label: 'No access via link',
      id: 0,
      value: 0,
      isCheckmark: true,
      linkSharing: PageLinkSharingEnum.NO_ACCESS,
      onClick: () => {
        selectedPage &&
          project &&
          updatePage(selectedPage.id, parseInt(project.id), PageLinkSharingEnum.NO_ACCESS)
        setDefaultValue(PageLinkSharingEnum.NO_ACCESS)
      },
    },
    {
      name: (
        <CustomP paddingRight='10px' paddingLeft='5px'>
          Can Edit
        </CustomP>
      ),
      label: 'Can Edit',
      id: 2,
      value: 2,
      linkSharing: PageLinkSharingEnum.CAN_EDIT,
      isCheckmark: true,
      onClick: () => {
        selectedPage &&
          project &&
          updatePage(selectedPage.id, parseInt(project.id), PageLinkSharingEnum.CAN_EDIT)
        setDefaultValue(PageLinkSharingEnum.CAN_EDIT)
      },
    },
    {
      name: (
        <CustomP paddingRight='10px' paddingLeft='5px'>
          Can Comment
        </CustomP>
      ),
      label: 'Can Comment',
      id: 3,
      value: 3,
      linkSharing: PageLinkSharingEnum.CAN_COMMENT,
      isCheckmark: true,
      onClick: () => {
        selectedPage &&
          project &&
          updatePage(selectedPage.id, parseInt(project.id), PageLinkSharingEnum.CAN_COMMENT)
        setDefaultValue(PageLinkSharingEnum.CAN_COMMENT)
      },
    },
    {
      name: (
        <CustomP paddingRight='10px' paddingLeft='5px'>
          Can View
        </CustomP>
      ),
      label: 'Can View',
      id: 4,
      value: 4,
      linkSharing: PageLinkSharingEnum.CAN_VIEW,
      isCheckmark: true,
      onClick: () => {
        selectedPage &&
          project &&
          updatePage(selectedPage.id, parseInt(project.id), PageLinkSharingEnum.CAN_VIEW)
        setDefaultValue(PageLinkSharingEnum.CAN_VIEW)
      },
    },
  ]

  useEffect(() => {
    getDefaultValue()
  }, [])

  return {
    defaultValue,
    selectedPage,
    linkSharingOptions,
  }
}
