import { block } from '_entities/block'
import { getElemAttr, removeElemAttr, setElemAttr, observeMutation, getById } from 'shared/lib'
import { getImageBlockId } from '../lib/getters'

export enum EditorImageBlockAttrs {
  CROP = 'data-crop',
}

export interface EditorImageBlockAttrsData {
  [EditorImageBlockAttrs.CROP]: boolean
}

export const getImageBlockElemAttr = (block: block, name: EditorImageBlockAttrs) => {
  return getElemAttr(getImageBlockId(block), name)
}

export const setImageBlockElemAttr = (
  block: block,
  name: EditorImageBlockAttrs,
  value: EditorImageBlockAttrsData[EditorImageBlockAttrs],
) => {
  setElemAttr(getImageBlockId(block), name, JSON.stringify(value))
}

export const removeImageBlockElemAttr = (block: block, name: EditorImageBlockAttrs) => {
  removeElemAttr(getImageBlockId(block), name)
}

export const observeMutationOnImageBlockElement = (
  block: block,
  attr: EditorImageBlockAttrs,
  callback: () => void,
) => {
  const element = getById(getImageBlockId(block))
  if (!element) return
  return observeMutation(element, (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes' && mutation.attributeName === attr) {
        callback()
      }
    }
  })
}
