import Spinner from 'components/templates/FullScreenLoader/Spinner'
import CustomP from 'components/atoms/CustomP/CustomP'
import IFrameEmbed from 'components/atoms/IFrameEmbed/IFrameEmbed'
import GoogleEmbedLoginButton from './GoogleEmbedLoginButton'
import { useGoogleEmbed } from '_entities/embed'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { getColor } from 'shared/colors'
import { SignInContainer } from './styles'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { envVars } from 'config/env'
import React from 'react'

interface Props {
  embedUrl: string
  id?: string
  width?: number
  height?: number
  iframeRef?: React.RefObject<HTMLIFrameElement>
}

export const GoogleEmbed = (props: Props) => {
  const _googleEmbed = useGoogleEmbed({ embedUrl: props.embedUrl })
  if (_googleEmbed.loading) {
    return (
      <SignInContainer>
        <Spinner />
      </SignInContainer>
    )
  }

  if (!_googleEmbed.hasAccess) {
    return (
      <SignInContainer width={props.width} height={props.height}>
        <CustomP paddingBottom={'14px'} fontWeight={'700'}>
          You don't have access to this file.
        </CustomP>
        <Wrapper padding={'0 0 26px 0'}>
          {' '}
          {_googleEmbed.user
            ? 'Contact the owner of the file and request access.'
            : 'Trying logging in with Google.'}
        </Wrapper>
        <Wrapper padding={'0 0 12px 0'} display={'flex'} gap={'6px'}>
          {_googleEmbed.user && (
            <>
              Logged in as:
              <Wrapper color={getColor('--everyday-blue')}>
                {(_googleEmbed.user as any)?.email}
              </Wrapper>
            </>
          )}
        </Wrapper>
        <GoogleOAuthProvider clientId={`${envVars.googleApi.clientId}`}>
          <GoogleEmbedLoginButton
            label={_googleEmbed.user ? 'Try another account' : 'Login with Google'}
          />
        </GoogleOAuthProvider>
      </SignInContainer>
    )
  }

  return (
    <IFrameEmbed
      iframeRef={props.iframeRef}
      id={props.id}
      width={props.width}
      height={props.height}
      src={`${props.embedUrl}&access_token=${_googleEmbed.token}`}
    />
  )
}
