import styled, { css } from 'styled-components'

export const StyledCustomP = css<{ invalidEmail?: boolean }>`
  font-size: 12px;
  ${(props) =>
    !props.invalidEmail &&
    `
    color: #4a80ff;
    cursor: pointer;
    line-height: 20px;   
    text-align: left;

    &:hover {
      text-decoration: underline;
      text-underline-offset: 3px;
    }
  `};
  text-align: ${(props) => props.invalidEmail && 'left'};
`

export const Form = styled.form`
  & input {
    width: 100%;
  }
`

export const HorizontalDivider = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 425px) {
    flex-direction: column;
  }
`

export const InputWrapperPassword = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const InputWrapperEmail = styled.div`
  position: relative;
`
