import { useEffect, useState } from 'react'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { type block, getBlockPageId, getIndex } from '_entities/block'
import { getBlockInternalEmbed, getBlockIsEmbedOverlayOn } from '_entities/embed'
import { SourceKeys } from 'interfaces/editor'
import { JSONOp } from 'ot-json1'

interface IProps {
  block: block
}

export const useEmbedOverlayToggle = (props: IProps) => {
  // ** State
  const [overlay, setOverlay] = useState(false)

  // ** Hooks
  const _submit = useSubmit()
  const _json1 = useJson1()

  useEffect(() => {
    setOverlay(!!getBlockIsEmbedOverlayOn(props.block))
  }, [props.block])

  const handleEditOverlayToggle = () => {
    setOverlay(!overlay)
    const index = getIndex(props.block)

    if (index === -1) return

    let op: JSONOp

    if (getBlockIsEmbedOverlayOn(props.block) !== undefined) {
      op = _json1.getReplaceBlockDataKeyOp(index, ['internalEmbed', 'isEmbedOverlayOn'], !overlay)
    } else {
      if (!getBlockInternalEmbed(props.block)) {
        op = _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed'], {
          isEmbedOverlayOn: !overlay,
        })
      } else {
        op = _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed', 'isEmbedOverlayOn'], !overlay)
      }
    }

    _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
  }

  return {
    overlay,
    handleEditOverlayToggle,
  }
}
