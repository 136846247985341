import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Button, ModalInnerContainer, ModalTitle, Input } from './styles'
import { ModalInfoLabel } from '../ChangeEmailModal/styles'
import { IUserAttributes } from 'interfaces/auth/userData'

type Props = {
  show: boolean
  isLastnameShown?: boolean
  handleClose: () => void
  saveChanges: () => void
  onShow: () => void
  setName?: React.Dispatch<React.SetStateAction<string>>
  setSurname?: React.Dispatch<React.SetStateAction<string>>
  name?: string
  surname?: string
  placeholder?: string
  additionalPlaceholder?: string
  maxLenght?: number
  user?: IUserAttributes
  label?: string
}

const ChangeNameModal: React.FC<Props> = ({
  show,
  handleClose,
  saveChanges,
  setName,
  setSurname,
  onShow,
  name,
  surname,
  placeholder,
  additionalPlaceholder,
  maxLenght,
  isLastnameShown,
  label,
}) => {
  return (
    <Modal
      show={show}
      onShow={onShow}
      onHide={handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='changeEmailModal'
    >
      <ModalInnerContainer placeholderColour='var(--gray3)'>
        <Modal.Header closeButton className='modalHeader border-0'>
          <ModalTitle>Change name</ModalTitle>
        </Modal.Header>
        <Modal.Body className='border-0'>
          <Form>
            <Form.Group className='mb-3'>
              <ModalInfoLabel>{label || 'Firstname'}</ModalInfoLabel>
              <Input
                value={name}
                type='text'
                placeholder={placeholder || 'Enter your new workspace name'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault()
                  setName && setName(e.target.value)
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    saveChanges()
                  }
                }}
                maxLength={maxLenght}
              />
            </Form.Group>

            {isLastnameShown && (
              <Form.Group className='mb-0'>
                <ModalInfoLabel>Lastname</ModalInfoLabel>
                <Input
                  value={surname}
                  type='text'
                  placeholder={additionalPlaceholder}
                  onChange={(e: React.ChangeEvent) =>
                    setSurname && setSurname((e.currentTarget as HTMLInputElement).value)
                  }
                  maxLength={maxLenght}
                />
              </Form.Group>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-end'>
          <Button onClick={saveChanges} type='submit'>
            Save name
          </Button>
        </Modal.Footer>
      </ModalInnerContainer>
    </Modal>
  )
}

export default ChangeNameModal

ChangeNameModal.defaultProps = {
  placeholder: 'name surname',
  isLastnameShown: true,
}
