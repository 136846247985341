import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { StreamMessage } from 'stream-chat-react'
import { type block } from '_entities/block'

export type ChannelOption = {
  label: string
  cid: string
}

interface ChatState {
  open: boolean
  fullScreen: boolean
  selectedChannel?: string | null
  selectedObject?: block | null
  isEditing?: StreamMessage | null
  pinnedMessages?: StreamMessage[] | []
  selectedMessages: any[] | null
  messagesToOpen: string[] | null
  messagesWithBlock?: StreamMessage[] | null
  isMessageThreadOpen: boolean
  objectChatToView: block | null
}

const initialState = {
  open: false,
  fullScreen: false,
} as ChatState

const chatSlice = createSlice({
  name: 'chatSlice',
  initialState,
  reducers: {
    setOpen: (state, action: PayloadAction<boolean>) => {
      state.open = action.payload
    },
    setFullScreen: (state, action: PayloadAction<boolean>) => {
      state.fullScreen = action.payload
    },
    toggleFullScreen: (state) => {
      state.fullScreen = !state.fullScreen
    },
    setSelectedChannel: (state, action: PayloadAction<string | null>) => {
      state.selectedChannel = action.payload
    },
    setSelectedObject: (state, action: PayloadAction<block | null>) => {
      state.selectedObject = action.payload
    },
    setIsEditing: (state, action: PayloadAction<StreamMessage | null>) => {
      state.isEditing = action.payload
    },
    setPinnedMessages: (state, action: PayloadAction<StreamMessage[]>) => {
      state.pinnedMessages = action.payload
    },
    setSelectedMessages: (state, action: PayloadAction<any[] | null>) => {
      state.selectedMessages = action.payload
    },
    setMessagesToOpen: (state, action: PayloadAction<string[] | null>) => {
      state.messagesToOpen = action.payload
    },
    setMessagesWithBlock: (state, action: PayloadAction<StreamMessage[] | null>) => {
      state.messagesWithBlock = action.payload
    },
    setIsMessageThreadOpen: (state, action: PayloadAction<boolean>) => {
      state.isMessageThreadOpen = action.payload
    },
    setObjectChatToView: (state, action: PayloadAction<block | null>) => {
      state.objectChatToView = action.payload
    },
  },
})

export const {
  setOpen,
  setFullScreen,
  toggleFullScreen,
  setSelectedChannel,
  setSelectedObject,
  setIsEditing,
  setPinnedMessages,
  setSelectedMessages,
  setMessagesToOpen,
  setMessagesWithBlock,
  setIsMessageThreadOpen,
  setObjectChatToView,
} = chatSlice.actions

export default chatSlice.reducer

// Selectors
