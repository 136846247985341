import { IExternalEmbed, InternalEmbedSelection } from '_entities/embed'
import { Emoji } from '_features/emojis'
import { getElemAttr, removeElemAttr, setElemAttr } from 'shared/lib'

export enum ToolbarAttrs {
  EMOJI = 'emoji',
  INTERNAL_EMBED = 'internal-embed',
  EXTERNAL_EMBED = 'external-embed',
  FILE = 'file',
}

export interface ToolbarAttrsData {
  [ToolbarAttrs.EMOJI]: Emoji
  [ToolbarAttrs.INTERNAL_EMBED]: InternalEmbedSelection
  [ToolbarAttrs.EXTERNAL_EMBED]: IExternalEmbed
  [ToolbarAttrs.FILE]: File
}

export const getToolbarId = () => 'floating-toolbar'
export const useToolbar = () => {
  const getAttr = (name: ToolbarAttrs) => {
    return getElemAttr(getToolbarId(), name)
  }
  const setAttr = (name: ToolbarAttrs, value: ToolbarAttrsData[ToolbarAttrs] | undefined) => {
    setElemAttr(getToolbarId(), name, JSON.stringify(value))
  }

  const removeAttr = (name: ToolbarAttrs) => {
    removeElemAttr(getToolbarId(), name)
  }

  return {
    getAttr,
    setAttr,
    removeAttr,
  }
}
