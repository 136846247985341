import { useAppSelector } from 'redux/hooks'

export const useSpaceRighSidebar = () => {
  const spaceMembers = useAppSelector((state) => state.space.spaceMembers)
  const user = useAppSelector((state) => state.global.user)

  const userName = user?.firstName + ' ' + user?.lastName
  const userEmail = user?.email
  const userColor = user?.userColor

  const membersWithoutOwner = spaceMembers.filter((member) => member.role !== 'Owner')
  const ownerMember = spaceMembers.filter((member) => member.role === 'Owner')

  // Owner should be a first member in the list
  const sortedMembers = [...ownerMember, ...membersWithoutOwner]

  const addDescriptionInSpaceRightbar = () => {
    // TODO — waiting for the implementation (backend side).
  }

  return {
    sortedMembers,
    userName,
    userEmail,
    userColor,
    addDescriptionInSpaceRightbar,
  }
}
