import { CustomDropdownWrapper, MoreOptionsWrapper } from 'components/organisms/Palette/styles'
import Dots from 'components/atoms/Dots/Dots'
import CustomP from 'components/atoms/CustomP/CustomP'
import { IPalette } from 'interfaces/settings'
import { DropdownMenuItem } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

interface IDropdownOption {
  id: number
  label: string
  onClick: () => void
}

interface IProps {
  palette: IPalette
  defaultDropdownOptions: IDropdownOption[]
  dropdownOptions: IDropdownOption[]
}

const ColorPaletteDropdown = ({ palette, defaultDropdownOptions, dropdownOptions }: IProps) => {
  return (
    <CustomDropdownWrapper
      trigger={
        <MoreOptionsWrapper>
          <Dots />
          <CustomP color='var(--primary-text)'>More options</CustomP>
        </MoreOptionsWrapper>
      }
    >
      {!palette.attributes.createdBy ? (
        <>
          {defaultDropdownOptions.map((option: IDropdownOption) => (
            <DropdownMenuItem onSelect={option.onClick} key={option.id}>
              {option.label}
            </DropdownMenuItem>
          ))}
        </>
      ) : (
        <>
          {dropdownOptions.map((option: IDropdownOption) => (
            <DropdownMenuItem onSelect={option.onClick} key={option.id}>
              {option.label}
            </DropdownMenuItem>
          ))}
        </>
      )}
    </CustomDropdownWrapper>
  )
}

export default ColorPaletteDropdown
