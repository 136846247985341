import './style.css'
import TableComponent from './TableComponent'
import { type block, type ITableReducer } from '_entities/block'
import { useTable } from './useTable'

interface Props {
  tableState?: ITableReducer
  block: block
  style: React.CSSProperties
  blockId: string
  actionMenuOpen?: boolean
  innerRef?: React.RefObject<HTMLDivElement> | ((node: HTMLDivElement) => void)
}

function index({ tableState, block, style, blockId, actionMenuOpen, innerRef }: Props) {
  const { state, dispatch } = useTable({ tableState, block })
  return (
    <>
      <TableComponent
        actionMenuOpen={actionMenuOpen}
        style={style}
        blockId={blockId}
        columns={state.columns}
        data={state.data}
        dispatch={dispatch}
        skipReset={state.skipReset}
      />
      <div id='popper-portal'></div>
    </>
  )
}

export default index
