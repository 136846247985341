import Konva from 'konva'
import { Position, Size } from 'interfaces/whiteboard'
import { Stage } from 'konva/lib/Stage'
import useLocalStorage, { LocalStorageKeys, StagesType } from 'shared/lib/useLocalStorage'
import { type block, getBlock } from '_entities/block'
import { Group } from 'konva/lib/Group'
import { getStageId } from '../lib/getters'
import { IResetArgs } from './types'
import { ZOOM_DURATION } from '_features/canvas'
import { GroupNames } from '_entities/whiteboard'

export enum StageAttrs {
  INITIAL_DRAWING_POSITION = 'initialDrawingPosition',
  IS_SHAPE_MOUSE_DOWN = 'isShapeMouseDown',
  SHAPE_CREATED_BY_DRAG = 'shapeCreatedByDrag',
  PARENT_PAGE_ID = 'parentPageId',
  PARENT_BLOCK = 'parentBlock',
  IS_EMBED = 'isEmbed',
  IS_SLIDESHOW = 'isSlideShow',
  DRAGGED_TO_LEFT_SIDEBAR = 'draggedToLeftSidebar',
  DRAGGED_TO_TASKS = 'draggedToTasks',
  DRAGGED_TO_CHAT = 'draggedToChat',
  FOCUSED_BLOCK = 'focusedBlock',
  LAST_CREATED_BLOCK = 'lastCreatedBlock',
}

export interface StageAttrsData {
  [StageAttrs.INITIAL_DRAWING_POSITION]: Position
  [StageAttrs.IS_SHAPE_MOUSE_DOWN]: boolean
  [StageAttrs.SHAPE_CREATED_BY_DRAG]: block | undefined
  [StageAttrs.IS_EMBED]: boolean
  [StageAttrs.PARENT_PAGE_ID]: string
  [StageAttrs.PARENT_BLOCK]: block | undefined
  [StageAttrs.IS_SLIDESHOW]: boolean
  [StageAttrs.DRAGGED_TO_LEFT_SIDEBAR]: boolean
  [StageAttrs.DRAGGED_TO_TASKS]: boolean
  [StageAttrs.DRAGGED_TO_CHAT]: boolean
  [StageAttrs.FOCUSED_BLOCK]: block | undefined
  [StageAttrs.LAST_CREATED_BLOCK]: block | undefined
}

export interface StageLocalStorageAttrs {
  x: number
  y: number
  scaleX: number
  scaleY: number
}

export const useStage = () => {
  const _localStorage = useLocalStorage()

  const unScale = (pageId: string, val: number) => {
    const stage = getStage(pageId)
    if (stage) return val / stage.scaleX()
    else return val
  }

  const unScaleY = (pageId: string, val: number) => {
    const stage = getStage(pageId)
    if (stage) return val / stage.scaleY()
    else return val
  }

  const unScaleSize = (pageId: string, size: Size) => {
    const stageScaleX = getStageScaleX(pageId)
    if (!stageScaleX) return
    return {
      width: size.width / stageScaleX,
      height: size.height / stageScaleX,
    }
  }

  const unScalePosition = (pageId: string, position: Position) => {
    const stage = getStage(pageId)
    const stageScaleX = stage?.scaleX()
    if (!stage || !stageScaleX) return
    return {
      x: position.x / stageScaleX - stage.x() / stageScaleX,
      y: position.y / stageScaleX - stage.y() / stageScaleX,
    }
  }

  const getRealPointerPosition = (pageId: string) => {
    return getStage(pageId)?.getPointerPosition()
  }

  const getScaledPointerPosition = (pageId: string): Position | undefined => {
    const stage = getStage(pageId)
    const stagePointerPosition = getRealPointerPosition(pageId)
    if (stage && stagePointerPosition) {
      return unScalePosition(pageId, stagePointerPosition)
    }
  }

  const getInversedStageScale = (pageId: string) => {
    const rescaled = unScaleSize(pageId, { width: 1, height: 1 })
    if (rescaled) {
      return {
        x: rescaled.width,
        y: rescaled.height,
      }
    } else
      return {
        x: 1,
        y: 1,
      }
  }

  const getDocIdFromStageId = (stageId: string) => {
    return stageId.split('-').slice(2).join('-').trim()
  }

  const getPageIdFromStage = (stage: Stage) => {
    return getDocIdFromStageId(stage.attrs.id)
  }

  const getStageElement = (pageId: string) => document.querySelector(`[id="${getStageId(pageId)}"]`)

  const getStage = (pageId: string): Stage | undefined => {
    const stageElement = getStageElement(pageId)
    return Konva.stages.find((s) => s.container() === stageElement)
  }

  const getStageSize = (pageId: string) => {
    const stage = getStage(pageId)
    if (!stage) return
    return {
      width: stage.width(),
      height: stage.height(),
    }
  }

  const getStageScaleX = (pageId: string) => {
    const stage = getStage(pageId)
    return stage?.scaleX()
  }

  const getStageScaleY = (pageId: string) => {
    const stage = getStage(pageId)
    return stage?.scaleY()
  }

  const resetStage = (args: IResetArgs) => {
    const stage = args.stageRef?.current || getStage(args.pageId)
    if (stage) {
      stage.to({
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
        duration: args.shouldAnimate ? ZOOM_DURATION : 0,
        easing: Konva.Easings.EaseInOut,
      })
    }
  }

  const getAllBlockNodes = (
    pageId: string,
    stageRef?: React.MutableRefObject<Stage | undefined>,
  ) => {
    const parentStage = stageRef?.current || getStage(pageId)
    return parentStage?.find(`.${GroupNames.BLOCK}`)
  }

  const getAllFrameNodes = (
    pageId: string,
    stageRef?: React.MutableRefObject<Stage | undefined>,
  ) => {
    const parentStage = stageRef?.current || getStage(pageId)
    return parentStage?.find(`.${GroupNames.FRAME}`)
  }

  const getAllNodes = (pageId: string, stageRef?: React.MutableRefObject<Stage | undefined>) => {
    const allBlocks = getAllBlockNodes(pageId, stageRef)
    if (allBlocks) {
      const allFrames = getAllFrameNodes(pageId, stageRef)
      if (allFrames) {
        const allObjects = allBlocks.concat(allFrames)
        return allObjects
      }
    }
  }

  const setStageAttr = (
    pageId: string,
    name: StageAttrs,
    value: StageAttrsData[StageAttrs] | undefined,
  ) => {
    const stage = getStage(pageId)
    stage?.setAttr(name, value)
  }

  const getStageAttr = (pageId: string, name: StageAttrs) => {
    const stage = getStage(pageId)
    if (!stage) return
    return stage.getAttr(name) as StageAttrsData[StageAttrs]
  }

  const removeStageAttr = (pageId: string, name: StageAttrs) => {
    const stage = getStage(pageId)
    stage?.setAttr(name, undefined)
  }

  const getNodeFromBlock = (
    pageId: string,
    blockId: string,
    stageRef?: React.MutableRefObject<Stage | undefined>,
  ): Group | undefined => {
    const nodes = getAllNodes(pageId, stageRef)
    return nodes?.find((node) => node.attrs.id === blockId) as Group
  }

  const getBlockFromNode = (pageId: string, node: Konva.Node) => {
    return getBlock(pageId, node.attrs.id)
  }

  const getIndexFromNodeArrayForBlock = (nodes: Konva.Node[], block: block) => {
    return nodes.findIndex((node) => node.attrs.id === block._id)
  }

  const getStagesFromLocalStorage = (): StagesType | undefined => {
    return _localStorage.getParsedItem(LocalStorageKeys.STAGES)
  }

  const constructNewLocalStorageStages = (pageId: string): StagesType => {
    return {
      [pageId]: {
        x: 0,
        y: 0,
        scaleX: 1,
        scaleY: 1,
      },
    }
  }

  return {
    unScale,
    unScaleY,
    unScaleSize,
    unScalePosition,
    getDocIdFromStageId,
    getPageIdFromStage,
    getRealPointerPosition,
    getScaledPointerPosition,
    getInversedStageScale,
    getStage,
    getStageSize,
    getStageScaleX,
    getStageScaleY,
    resetStage,
    getAllBlockNodes,
    getAllFrameNodes,
    getAllNodes,
    getStageAttr,
    setStageAttr,
    removeStageAttr,
    getNodeFromBlock,
    getBlockFromNode,
    getIndexFromNodeArrayForBlock,
    getStagesFromLocalStorage,
    constructNewLocalStorageStages,
  }
}
