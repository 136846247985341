import React from 'react'
import {
  MutedDescriptionText,
  PlanInfoContaier,
  SectionContainer,
  SidebarSubtitle,
  SidebarTitle,
  StyledRightSidebarCss,
  StyledRightSidebarTitleContainerCss,
  StyledRightSidebarTitleCss,
  UpgradeSpaceButton,
} from './styles'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import CustomP from 'components/atoms/CustomP/CustomP'
import { useSpaceRighSidebar } from './useSpaceRightSidebar'
import Avatar from 'components/atoms/Avatar/Avatar'
import ChipContainer from 'components/molecules/ChipContainer/ChipContainer'
import { SubscriptionTypes } from 'interfaces/space'
import AvatarPlaceholder from 'assets/images/avatar-placeholder.png'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { setPlanModalOpened } from 'redux/reducers/spaceReducer'

type Props = {
  userImage?: string
}

const SpaceRightSidebar: React.FC<Props> = ({ userImage }) => {
  const projectFiles = useAppSelector((state) => state.space.projectFiles)
  const { sortedMembers, userEmail, userName, userColor, addDescriptionInSpaceRightbar } =
    useSpaceRighSidebar()
  const user = useAppSelector((state) => state.global.user)
  const dispatch = useAppDispatch()
  return (
    <StyledRightSidebarCss xs={12} md={2} lg={2}>
      <StyledRightSidebarTitleContainerCss>
        <SidebarTitle>Space details</SidebarTitle>
        <MutedDescriptionText
          onClick={() => addDescriptionInSpaceRightbar() /* Waiting implementation... */}
        >
          Add a description
        </MutedDescriptionText>

        <SectionContainer className='bottom-light-border'>
          <SidebarSubtitle>Members</SidebarSubtitle>
          <StyledRightSidebarTitleCss>
            {sortedMembers.map((member, i) => (
              <Wrapper
                key={member + i}
                display={'flex'}
                justifyContent={'start'}
                alignItems={'center'}
                padding={'0 0 8px 0'}
              >
                {member.email === userEmail ? (
                  <Avatar
                    src={
                      !userImage?.includes('null') || userImage != null
                        ? userImage
                        : AvatarPlaceholder
                    }
                    /* S3 Bucket prefix will be appended in the upper components. */
                    userName={userName}
                    userColor={userColor}
                    width='24px'
                    height='24px'
                  />
                ) : (
                  <Avatar userName={member.name} width='24px' height='24px' />
                )}
                <CustomP className='mb-0' paddingLeft={'8px'} fontWeight={'500'} fontSize='14px'>
                  {member.email === userEmail
                    ? `${user?.firstName} ${user?.lastName}` + ' (You)'
                    : member.name}
                </CustomP>
              </Wrapper>
            ))}
          </StyledRightSidebarTitleCss>
        </SectionContainer>

        <SectionContainer className='position-section'>
          <SidebarSubtitle paddingBottom='0.5rem'>Workspace plan</SidebarSubtitle>
          <StyledRightSidebarTitleCss>
            <ChipContainer typeOfSubscription={SubscriptionTypes.FREE} />

            <PlanInfoContaier>{projectFiles.length} / 5 Project files used</PlanInfoContaier>
            <PlanInfoContaier className='position-plan-container'>
              {sortedMembers.length} / 10 Editor collaborators
            </PlanInfoContaier>
            <UpgradeSpaceButton
              onClick={() => {
                dispatch(setPlanModalOpened(true))
              }}
            >
              Upgrade workspace
            </UpgradeSpaceButton>
          </StyledRightSidebarTitleCss>
        </SectionContainer>
      </StyledRightSidebarTitleContainerCss>
    </StyledRightSidebarCss>
  )
}

export default SpaceRightSidebar
