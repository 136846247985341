import { CustomMessageInput } from 'components/organisms/ChatCustomMessageInput/CustomMessageInput'
import { Chat as GetStreamChat, Channel, Thread, LoadingIndicator } from 'stream-chat-react'

import 'stream-chat-react/dist/css/v2/index.css'

import WindowContainer from '../ChatWindow/Window'
import useChat from './useChat'
import CustomMessage from '../ChatCustomMessage/CustomMessage'
import useWindow from '../ChatWindow/useWindow'
import ChatHeader from '../ChatHeader/ChatHeader'
import ChatFilter from '../ChatFilter/ChatFilter'
import DeleteMessageModal from '../ChatWindow/DeleteMessageModal/DeleteMessageModal'
import { ReactComponent as EmptyChatIcon } from 'assets/images/empty-chat.svg'
import { StyledEmptyChatText, StyledEmptyChatWrapper } from './styles'

const Chat = () => {
  const { chatClient, channel } = useChat()
  const {
    customActions,
    handleSendMessageInThread,
    open,
    messageToDelete,
    handleDeleteMessage,
    handleDeleteThread,
    handleCloseModal,
    isSingleMessageDelete,
    referencedPage,
    referencedBlock,
  } = useWindow()

  const ThreadHead = () => {
    return <></>
  }

  const EmptyState = () => {
    return (
      <StyledEmptyChatWrapper>
        <EmptyChatIcon />
        <StyledEmptyChatText>No comments on this page yet</StyledEmptyChatText>
      </StyledEmptyChatWrapper>
    )
  }

  if (!chatClient && !channel) {
    return <LoadingIndicator />
  }

  if (chatClient && channel)
    return (
      <GetStreamChat client={chatClient} theme='str-chat__theme-light'>
        <Channel
          channel={channel}
          Input={CustomMessageInput}
          ThreadHeader={ThreadHead}
          EmptyStateIndicator={EmptyState}
        >
          <ChatHeader />
          <ChatFilter />
          <WindowContainer />
          <Thread
            Input={CustomMessageInput}
            Message={CustomMessage}
            messageActions={['reply', 'react']}
            additionalMessageListProps={{
              customMessageActions: customActions,
            }}
            additionalMessageInputProps={{
              overrideSubmitHandler: handleSendMessageInThread,
            }}
          />
        </Channel>

        <DeleteMessageModal
          open={open}
          messageToDelete={messageToDelete}
          handleDeleteMessage={handleDeleteMessage}
          handleDeleteThread={handleDeleteThread}
          handleCloseModal={handleCloseModal}
          isSingleMessageDelete={isSingleMessageDelete}
          referencedPage={referencedPage}
          referencedBlock={referencedBlock}
        />
      </GetStreamChat>
    )
  else return null
}

export default Chat
