import { getTaskWithValues } from '../../tasks'
import { setTasks } from 'redux/reducers/taskManagerReducer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useToast } from 'services/helpers/useToast'
import { useUpdateTask } from './useUpdateTask'

export const useMessageListener = () => {
  // ** Redux state
  const user = useAppSelector((state) => state.global.user)
  const propertyDefinitions = useAppSelector((state) => state.taskManager.propertyDefinitions)
  const tasks = useAppSelector((state) => state.taskManager.tasks)

  // ** Hooks
  const toast = useToast()
  const dispatch = useAppDispatch()
  const _updateTask = useUpdateTask()

  const messageListener = (e: MessageEvent<any>) => {
    if (user && e.data && typeof e.data === 'string' && window.__MANAGER_SOCKET__) {
      const parsedData = JSON.parse(e.data)
      const event = parsedData.event
      if (!tasks) return

      switch (event) {
        case 'task:create': {
          const task = parsedData.data.task
          const taskWithProperties = getTaskWithValues([...tasks, task], propertyDefinitions)
          dispatch(setTasks([...taskWithProperties]))
          toast.success('Task created successfully')
          break
        }
        case 'task:update': {
          const task = parsedData.data.task[0]
          const updatedTasks = tasks.map((t) => (t.id === task.id ? { ...t, ...task } : t))
          const taskWithProperties = getTaskWithValues([...updatedTasks], propertyDefinitions)
          dispatch(setTasks([...taskWithProperties]))
          break
        }
        case 'task:delete': {
          const taskId = parsedData.data.id
          const updatedTasks = tasks.filter((t) => t.id !== taskId)
          dispatch(setTasks(updatedTasks))
          toast.success('Task deleted successfully')
          break
        }
        case 'property:assign':
        case 'property:dismiss': {
          const taskId = parsedData.data.property.page.id
          const propertyDefinitionId = parsedData.data.property.propertyDefinitionId
          const newTasks = _updateTask.updateTaskProperties(
            taskId,
            propertyDefinitionId,
            parsedData.data.property,
          )
          dispatch(setTasks(newTasks))
          break
        }
      }
    }
  }

  return {
    messageListener,
  }
}
