import { IRoles } from 'interfaces/invite'
import React from 'react'

type Props = {
  acceptedRoles: IRoles[]
  myRole?: IRoles
  accessDeniedElement: JSX.Element
  children: JSX.Element
}

const PermissionWrapper: React.FC<Props> = ({
  myRole,
  acceptedRoles,
  accessDeniedElement,
  children,
}) => {
  return <>{myRole && acceptedRoles.includes(myRole) ? children : accessDeniedElement}</>
}

export default PermissionWrapper
