import AuthInput from 'components/atoms/AuthInput/AuthInput'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import styled from 'styled-components'
import { StyledCustomP, Form, InputWrapperEmail, InputWrapperPassword } from './styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { Spinner } from 'react-bootstrap'

import emailIcon from 'assets/images/emailIcon.svg'
import emailRed from 'assets/images/emailRed.svg'
import lockProtect from 'assets/images/lockProtect.svg'
import lockProtectRed from 'assets/images/lockRed.svg'
import { useSigninForm } from './useSigninForm'

const CustomP2 = styled.p<{ invalidEmail?: boolean }>`
  ${StyledCustomP}
`

type Props = {
  onSubmit: React.FormEventHandler<HTMLFormElement>
  validateEmail: (email: string) => boolean
  validatePassword: (password: string) => boolean
  email: string
  password: string
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setPassword: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  isInvalidResponse: boolean
}

const index: React.FC<Props> = ({
  onSubmit,
  validateEmail,
  email,
  password,
  setEmail,
  setPassword,
  isLoading,
  isInvalidResponse,
}) => {
  const { emailValidityChecker } = useSigninForm(email)

  return (
    <>
      <Form onSubmit={onSubmit}>
        <InputWrapperEmail>
          <AuthInput
            type='email'
            placeholder='Email address'
            id='email'
            className={emailValidityChecker}
            isRequired
            label={email ? 'Email address' : ''}
            onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value)
            }}
            iconLeft={emailIcon}
            iconLeftError={emailRed}
            moveIcon='mt-5'
            paddingLeft='2.5rem'
            valueEntered={email}
            inputInnerWidth='100%'
          />
        </InputWrapperEmail>

        <CustomP2 invalidEmail className='text-danger mb-4 mt-1'>
          {!validateEmail(email) && 'Please enter a valid email address.'}
        </CustomP2>

        <InputWrapperPassword>
          <AuthInput
            type='password'
            id='password'
            placeholder='Password'
            label={password ? 'Password' : ''}
            className='form-control form-control-md fontAwesome placeholderDarker'
            onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value)
            }}
            iconLeft={lockProtect}
            iconLeftError={lockProtectRed}
            moveIcon='mt-5'
            paddingLeft='2.5rem'
            eyeIconTop='45px'
            inputInnerWidth='100%'
          />
        </InputWrapperPassword>

        {isInvalidResponse && (
          <CustomP2 invalidEmail className='text-danger my-4'>
            We couldn’t log you in because of an incorrect email and/or password.
          </CustomP2>
        )}

        <div className='mt-4 mb-4'>
          <AuthInput
            className='form-check-input'
            type='checkbox'
            label='I agree to the <a href="https://www.google.com" style="color: var(--everyday-blue) !important; text-decoration: none !important;" target="blank" rel="noopener noreferrer">Terms and Conditions</a>'
            id='flexCheckDefault'
            isRequired
            labelClassName='form-check-label text-secondary'
            htmlFor='flexCheckDefault'
            textFontSize='16pt !important'
            isChecked={true}
            labelFontSize='14px'
            padding='0.65rem'
          />
        </div>

        <div className='d-grid gap-2 '>
          <CustomButton
            className='btn btn-primary d-flex justify-content-center align-items-center'
            type='submit'
            height='40px'
            borderradius='10px'
            background='var(--primary-color)'
            padding='8px 32px'
            margin='0.5rem 0px'
          >
            <CustomP
              className='mb-0 text-white'
              color='white'
              fontSize='16px'
              marginRight='0.5rem'
              fontWeight='600'
            >
              Log in
            </CustomP>
            {isLoading && (
              <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
            )}
          </CustomButton>
        </div>
      </Form>
    </>
  )
}

export default index
