import { CtaButton } from './types'
import { type block } from '_entities/block'
import { getElements } from 'shared/lib'
import { FONT_WEIGHTS_MAP, getCtaButtonId } from './constants'

export const getCtaStyling = (cta: CtaButton) => cta.styling

export const getCtaTitle = (cta: CtaButton) => cta.title

export const getCtaType = (cta: CtaButton) => cta.type

export const getCtaFont = (cta: CtaButton) => cta.styling.font

export const getCtaFontSize = (cta: CtaButton) => cta.styling.fontSize

export const getCtaTextColor = (cta: CtaButton) => cta.styling.textColor

export const getCtaButtonColor = (cta: CtaButton) => cta.styling.buttonColor

export const getCtaButtonHeight = (cta: CtaButton) => cta.styling.buttonSize

export const getCtaFontWeight = (cta: CtaButton) => cta.styling.fontWeight

export const getCtaFontWeightValue = (cta: CtaButton) => {
  const fontWeight = cta.styling.fontWeight
  return FONT_WEIGHTS_MAP.find((weight) => weight.label === fontWeight)?.value
}

export const getCtaBorderRadius = (cta: CtaButton) => cta.styling.borderRadius

export const getCtaBorderColor = (cta: CtaButton) => cta.styling.borderColor

export const getCtaOnClickAction = (cta: CtaButton) => cta.onClickAction

export const getCtaLink = (cta: CtaButton) => cta.link

export const getBlockCtaId = (block: block) => block.data.cta?.id

export const getCtaBlocks = (button: CtaButton) => {
  return Array.from(getElements(`#${getCtaButtonId(button.id)}`))
}

export const getCtaButtonAlignment = (block: block) => block.data.cta?.align
