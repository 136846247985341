import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import {
  StyledAccordion,
  DroppableWrapper,
  StyledDroppable,
  ArrowDownIcon,
  ColumnTitleContainer,
  ColumnTitle,
  ColumnTitleText,
  ColumnTitleNumber,
  ColumnTitleIcon,
} from '../TaskManagerView/styles'
import { StyledListAccordion } from './styles'
import { colors } from 'shared/colors'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import Accordion from 'react-bootstrap/Accordion'
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext'
import {
  setActiveKeysList,
  setActiveSubgroupKeysList,
  setSelectedTaskId,
  getSelectedOption,
  getSelectedTask,
  setSelectedTaskDocument,
} from 'redux/reducers/taskManagerReducer'
import { useParams } from 'react-router-dom'
import { AxiosService } from 'services/axiosService/axiosService'
import { IGroup, ISubgroup, ITask } from 'interfaces/taskManager'
import { IconButton } from 'components/atoms/Button/Button'
import { Task } from '_entities/task'
import { type block } from '_entities/block'
import BlocksDragPlaceholder from 'components/atoms/DragPlaceholder/BlocksDragPlaceholder/BlocksDragPlaceholder'
import { ColumnActionRow, ColumnActionRowText } from '../TaskManagerModal/styles'
import DraggableTree from 'shared/dragging/ui/DraggableTree'
import { TreeItem } from '_entities/tree/model/types'
import { useCreateTask } from '_features/task'
import { CommonProps } from '_features/tasks'

export const TASK_COLUMN = 'task-column'

const getColumnId = (subgroup: ISubgroup, group: IGroup) => {
  const status = group.property?.status?.find((status) => status.current)
  return `${status?.statusValue?.id}-${subgroup.name}`
}

const TaskManagerList = (props: CommonProps) => {
  const dispatch = useAppDispatch()
  const activeKeys = useAppSelector((state) => state.taskManager.activeKeysList)
  const activeSubgroupKeys = useAppSelector((state) => state.taskManager.activeSubgroupKeysList)
  const selectedBlock = useAppSelector((state) => state.page.selectedBlock)

  const selectedOption = useAppSelector(getSelectedOption)
  const subgroup = selectedOption?.subGroupBy
  const selectedShowProperties = selectedOption?.showProperties
  const selectedTask = useAppSelector(getSelectedTask)

  const params = useParams()
  const spaceId = params.spaceId
  const projectId = params.id
  const AxiosInstance = new AxiosService()

  const onTaskSelect = async (task: ITask) => {
    if (spaceId && projectId) {
      const response = await AxiosInstance.getPage(projectId, task.id)
      if (response) {
        dispatch(setSelectedTaskId(task.id))
        dispatch(setSelectedTaskDocument(task))
        setTimeout(() => {
          const openedDocument = document.querySelector(
            `[id="${task.id}"] ul p:empty`,
          ) as HTMLElement
          if (openedDocument) {
            openedDocument.focus()
          }
        }, 500)
      }
    }
  }

  const _createTask = useCreateTask()

  return (
    <StyledAccordion
      onSelect={(e: AccordionEventKey) => {
        dispatch(setActiveKeysList(e))
      }}
      isSubgroup={!!subgroup}
      alwaysOpen
      type={props.type}
      activeKey={activeKeys}
      isSlider={props.isSlider}
    >
      <DragDropContext onDragEnd={(result) => props.onDragEnd(result)}>
        {props.data.map((user, userIndex) => (
          <StyledAccordion.Item key={user.name} eventKey={userIndex.toString()}>
            <StyledAccordion.Header>
              <ArrowDownIcon
                shouldFlip={activeKeys?.includes(userIndex.toString())}
                stroke={colors.gray2}
              />
              &nbsp; {user.name}
            </StyledAccordion.Header>
            <StyledAccordion.Body className='outside'>
              {Object.entries(user.columns).map(([columnId, column]) => {
                return (
                  <StyledListAccordion
                    onSelect={(e: AccordionEventKey) => {
                      dispatch(setActiveSubgroupKeysList(e))
                    }}
                    alwaysOpen
                    key={columnId}
                    activeKey={props.isSlider ? columnId : activeSubgroupKeys}
                    id={getColumnId(user, column)}
                    name={TASK_COLUMN}
                  >
                    <Accordion.Item eventKey={columnId}>
                      <Accordion.Header className='inner' as='div'>
                        <ColumnTitleContainer>
                          {!props.isSlider && (
                            <ArrowDownIcon
                              shouldFlip={activeSubgroupKeys?.includes(columnId)}
                              stroke={colors.gray2}
                            />
                          )}

                          <ColumnTitle>
                            <ColumnTitleText>{column.name}</ColumnTitleText>
                            <ColumnTitleNumber>{column.tasks.length}</ColumnTitleNumber>
                          </ColumnTitle>
                          <IconButton
                            size='small'
                            variant='sidebar'
                            onClick={(event: MouseEvent) => {
                              event.preventDefault()
                              event.stopPropagation()
                              _createTask.createTask(column)
                            }}
                          >
                            <ColumnTitleIcon />
                          </IconButton>
                        </ColumnTitleContainer>
                      </Accordion.Header>
                      <Accordion.Body className='inside'>
                        <DroppableWrapper>
                          <Droppable droppableId={columnId} key={columnId}>
                            {(provided, snapshot) => {
                              const modifiedDataForTree: TreeItem[] = column.tasks?.map(
                                (task, i) => {
                                  return {
                                    id: task?.id,
                                    parent: columnId,
                                    text: '',
                                    data: task,
                                    positionIndex: task?.positionIndex,
                                    type: 'task',
                                    isShown: true,
                                  }
                                },
                              )

                              return (
                                <StyledDroppable
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  <DraggableTree
                                    items={modifiedDataForTree ? modifiedDataForTree : []}
                                    onDrop={({ droppedData, destinationIndex }) => {
                                      const status = column.property?.status?.find(
                                        (status) => status.current,
                                      )

                                      if (droppedData && destinationIndex !== undefined) {
                                        const block =
                                          selectedBlock?._id === droppedData.id
                                            ? selectedBlock
                                            : droppedData.data

                                        block &&
                                          _createTask.createReferencedTask(
                                            block as block,
                                            destinationIndex,
                                            status?.statusValue.id,
                                          )
                                      }
                                    }}
                                    render={(node, _) => {
                                      const task = node.data as ITask
                                      return (
                                        <div>
                                          {selectedShowProperties && node.data && (
                                            <Task
                                              key={task.id}
                                              type='horizontal'
                                              task={task}
                                              onTaskSelect={() => onTaskSelect(task)}
                                              index={task.positionIndex}
                                              selected={task.id === selectedTask?.id}
                                              isSlider={props.isSlider}
                                              isSimpleView={false}
                                            />
                                          )}
                                        </div>
                                      )
                                    }}
                                    placeholderRender={() => {
                                      return <BlocksDragPlaceholder width={'100%'} />
                                    }}
                                  />
                                  <ColumnActionRow onClick={() => _createTask.createTask(column)}>
                                    <ColumnTitleIcon />
                                    <ColumnActionRowText>New Task</ColumnActionRowText>
                                  </ColumnActionRow>
                                </StyledDroppable>
                              )
                            }}
                          </Droppable>
                        </DroppableWrapper>
                      </Accordion.Body>
                    </Accordion.Item>
                  </StyledListAccordion>
                )
              })}
            </StyledAccordion.Body>
          </StyledAccordion.Item>
        ))}
      </DragDropContext>
    </StyledAccordion>
  )
}

export default TaskManagerList
