import CustomP from 'components/atoms/CustomP/CustomP'
import { ITask } from 'interfaces/taskManager'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { useNavigate, useParams } from 'react-router-dom'
import {
  setIsSliderTaskOpen,
  setModalOpen,
  setSelectedTaskDocument,
  setSelectedTaskId,
} from 'redux/reducers/taskManagerReducer'
import { useMemo } from 'react'
import debounce from 'lodash.debounce'
import { getColor } from 'shared/colors'
import { useDeleteTask, useUpdateTask } from '_features/task'

interface Props {
  task: ITask
}

export const useTaskManagerModal = (props: Props) => {
  const { task } = props

  const userImage = useAppSelector((state) => state.global.userImage)
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const params = useParams()
  const spaceId = params.spaceId
  const projectId = params.id

  const navigate = useNavigate()
  const _deleteTask = useDeleteTask()
  const _updateTask = useUpdateTask()

  const updateTitleHandler = (title: string) => {
    if (task)
      _updateTask?.updateTask({
        taskId: task.id,
        title,
      })
  }

  const updateTitle = useMemo(() => debounce(updateTitleHandler, 300), [])

  const dispatch = useAppDispatch()
  const topDropdownOptions = [
    {
      element: (
        <CustomP
          hoverbgcolor='var(--hover)'
          className='px-3 py-2'
          marginBottom='0'
          borderradius='5.5px'
        >
          Copy task link
        </CustomP>
      ),
      id: 4632624627,
      onClick: () => void 0,
      eventKey: 52345235,
    },
    {
      element: (
        <CustomP
          hoverbgcolor={getColor('--everyday-red')}
          className='px-3 py-2'
          marginBottom='0'
          borderradius='5.5px'
        >
          Delete task
        </CustomP>
      ),
      id: 463734573457,
      onClick: () => {
        if (task) {
          _deleteTask.deleteTask(task)
          closeModalHandler()
          dispatch(setIsSliderTaskOpen(false))
        }
      },
      eventKey: 523452354352,
    },
  ]

  const closeModalHandler = () => {
    dispatch(setModalOpen(false))
    if (spaceId && projectId && selectedPage) {
      navigate(`/spaces/${spaceId}/project-file/${projectId}?pageId=${selectedPage.id}`)
    }
    setTimeout(() => {
      dispatch(setSelectedTaskId(undefined))
      dispatch(setSelectedTaskDocument(undefined))
    }, 500)
  }

  return {
    userImage,
    selectedPage,
    dispatch,
    topDropdownOptions,
    updateTitle,
    closeModalHandler,
  }
}
