import { getBlockPageId, getIndex } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'
import { useRef, useState } from 'react'
import { ReactCropperElement } from 'react-cropper'
import { AxiosService } from 'services/axiosService/axiosService'
import { useJson1 } from 'shared/shareDb'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { CropperProps } from '../ui/ImageCropper'

export const useCrop = (props: CropperProps) => {
  const [cropBlob, setCropBlob] = useState<Blob | null>(null)
  const [cropLoading, setCroppingLoading] = useState<boolean>(false)

  const axiosInstance = new AxiosService()
  const cropperRef = useRef<ReactCropperElement>(null)

  const _json1 = useJson1()
  const _submit = useSubmit()

  const onEditorImageCrop = async () => {
    if (!cropBlob) return

    setCroppingLoading(true)

    const formData = new FormData()
    const fileName = `${crypto.randomUUID()}.${cropBlob.type.split('/')[1]}`
    formData.append('file', cropBlob, fileName)

    const response = await axiosInstance.postImage(
      formData,
      {
        'Content-Type': 'multipart/form-data',
      },
      () => void 'Skip and proceed...',
      () => void 'Skip and proceed...',
    )

    if (response != null) {
      const updatedImage = await axiosInstance.getImage(response.id)

      const index = getIndex(props.block)

      if (index === -1) return

      if (updatedImage != null) {
        const op = _json1.getReplaceBlockDataKeyOp(
          index,
          ['imageUrl'],
          updatedImage.data,
          props.block.data.imageUrl as string,
        )
        _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
      }
    }

    setCroppingLoading(false)
    props.onCropFinish()
  }
  const onCrop = () => {
    const cropper = cropperRef?.current?.cropper
    cropper?.getCroppedCanvas().toBlob((blob) => setCropBlob(blob), 'image')
  }

  return {
    cropperRef,
    onCrop,
    onEditorImageCrop,
    cropLoading,
  }
}
