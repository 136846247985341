export const convertFromArabicToRoman = (num: number | string) => {
  if (typeof num === 'string') return
  const romanNumerals = [
    { value: 1000, symbol: 'M' },
    { value: 900, symbol: 'CM' },
    { value: 500, symbol: 'D' },
    { value: 400, symbol: 'CD' },
    { value: 100, symbol: 'C' },
    { value: 90, symbol: 'XC' },
    { value: 50, symbol: 'L' },
    { value: 40, symbol: 'XL' },
    { value: 10, symbol: 'X' },
    { value: 9, symbol: 'IX' },
    { value: 5, symbol: 'V' },
    { value: 4, symbol: 'IV' },
    { value: 1, symbol: 'I' },
  ]

  let result = ''

  for (const numeral of romanNumerals) {
    while (num >= numeral.value) {
      result += numeral.symbol
      num -= numeral.value
    }
  }

  return result
}

export const convertRomanToArabic = (roman: string): number => {
  const romanNumerals: { [key: string]: number } = {
    I: 1,
    V: 5,
    X: 10,
    L: 50,
    C: 100,
    D: 500,
    M: 1000,
  }

  let result = 0

  for (let i = 0; i < roman?.length; i++) {
    const currentSymbol = roman[i]
    const currentValue = romanNumerals[currentSymbol]
    const nextSymbol = roman[i + 1]
    const nextValue = romanNumerals[nextSymbol]

    if (nextValue && nextValue > currentValue) {
      result += nextValue - currentValue
      i++
    } else {
      result += currentValue
    }
  }

  return result
}
