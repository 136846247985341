import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { StyledExpandIcon, StyledMinimizeIcon } from 'components/molecules/TaskTitle/styles'

export interface ExpandIconProps {
  isExpanded?: boolean
  maximize?: () => void
  minimize?: () => void
}

export const Expand = (props: ExpandIconProps) => {
  return (
    <Wrapper display='flex' alignItems='center' gap='6px'>
      {!props.isExpanded ? (
        <StyledExpandIcon
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            props.maximize && props.maximize()
          }}
        />
      ) : (
        <StyledMinimizeIcon onClick={props.minimize} />
      )}
    </Wrapper>
  )
}
