import { SelectedObjectPreview, SelectedObjectWrapper } from '../styles'
import { PageNameWrapper } from '../../ChatCustomMessageInput/styles'
import { PageName } from '_entities/page'
import { type block } from '_entities/block'
import convertToPlain from 'utils/editor/convertToPlain'
import { IPage } from 'interfaces/page'
import { useTextBlock } from '_entities/text'
import { useShape } from '_entities/shape'
import { useFrame } from '_entities/whiteboard'
interface IProps {
  handleOnClickSubject?: () => void
  referencedBlock: block
  referencedPage: IPage | null
}

const SelectedTextBlock = ({ handleOnClickSubject, referencedBlock, referencedPage }: IProps) => {
  const _textBlock = useTextBlock()
  const _shape = useShape()
  const _frame = useFrame()
  return (
    <SelectedObjectWrapper onClick={handleOnClickSubject} className='selected-object-wrapper'>
      <SelectedObjectPreview backgroundColor={_shape.getShapeFill(referencedBlock)}>
        {_frame.isFrameBlock(referencedBlock) ? (
          'Frame'
        ) : convertToPlain(_textBlock.convertToHtml(referencedBlock as block)).length > 150 ? (
          <>
            {convertToPlain(_textBlock.convertToHtml(referencedBlock as block)).substring(0, 150)}
            ...
          </>
        ) : (
          convertToPlain(_textBlock.convertToHtml(referencedBlock as block))
        )}
      </SelectedObjectPreview>
      <PageNameWrapper>
        <PageName page={referencedPage} />
      </PageNameWrapper>
    </SelectedObjectWrapper>
  )
}

export default SelectedTextBlock
