import { Deserializer } from 'jsonapi-serializer'
import { HttpAdapter } from './httpAdapterService'
import { envVars } from 'config/env'
import { PageTypesEnum } from 'interfaces/projectFile'
import { PageLinkSharingEnum } from 'interfaces/invite'
import { useToast } from 'services/helpers/useToast'
import { type block } from '_entities/block'
import { ImageSizeEnum, SummarizationCategory } from 'services/ai/useAi'
import { EMPTY_PAGE_ID } from '_entities/page/model/constants'
import { IPermissions } from '_entities/user'

const toast = useToast()

export interface ICreatePageRequestArgs {
  type: PageTypesEnum
  projectId: string
  parentId?: string
  embedUrl?: string
  title?: string
  blocks?: block[]
  positionIndex?: number
  icon?: string
  isHidden?: boolean
}

export class AxiosService extends HttpAdapter {
  apiUrl?: string

  constructor() {
    super()
    this.apiUrl = envVars.api.https
  }

  getUrlQueries(params: object | undefined) {
    let toReturn = ''
    if (params) {
      toReturn = '?'
      Object.entries(params).forEach(([key, value]) => (toReturn += `${key}=${value}&`))
      toReturn = toReturn.slice(0, -1)
    }
    return toReturn
  }

  defineNullableArray(key: string | number | symbol, value: any): any {
    if (Array.isArray(value) && value.includes(null)) {
      return [key, value.filter((x) => !!x)]
    }

    return [key, value]
  }

  getAnyDeserializedData(e: any) {
    try {
      const data = new Deserializer({
        keyForAttribute: 'camelCase',
        pluralizeType: false,
      })
        .deserialize(e)
        .then((data) => {
          return data
        })
      return data
    } catch (e: any) {
      return null
    }
  }

  /* ================= Begin API methods ============== */

  /* Hotjar */

  async initializeHotjar(hjid: number, hjsv: number) {
    const url = `https://static.hotjar.com/c/hotjar-${hjid}.js?sv=${hjsv}`
    const response = await this.GET(url)
    if (response) return response
  }

  /* ========== Auth =========== */

  /* Forgot password */
  async resetEmail(email: string) {
    const response = await this.POST(`${this.apiUrl}api/auth/forgot-pass`, {
      data: {
        attributes: {
          email,
        },
        type: 'auths',
      },
    })
    if (response) return response

    toast.error('Could not send e-mail')
  }

  /* Reset password */
  async resetPassword(email: string, password: string) {
    const response = await this.POST(`${this.apiUrl}api/auth/reset-pass`, {
      data: {
        attributes: {
          email,
          password,
        },
        type: 'auths',
      },
    })
    if (response) return response
    else toast.error('Could not reset password')
  }

  /* Sign in */
  async signIn(email: string, password: string, inviteToken?: string | null) {
    let url: string
    if (inviteToken) url = `${this.apiUrl}api/auth/signin?inviteToken=${inviteToken}`
    else url = `${this.apiUrl}api/auth/signin`

    const response = await this.POST(
      url,
      {
        data: {
          attributes: {
            email,
            password,
          },
          type: 'auths',
        },
      },
      undefined,
      undefined,
      undefined,
      false,
      true,
    )
    if (response) {
      return response
    }
  }

  async signUp(
    firstName: string,
    lastName: string,
    email: string,
    password: string,
    inviteToken?: string | null,
  ) {
    let url: string
    if (inviteToken) url = `${this.apiUrl}api/auth/signup?inviteToken=${inviteToken}`
    else url = `${this.apiUrl}api/auth/signup`
    const response = await this.POST(
      url,
      {
        data: {
          attributes: {
            firstName,
            lastName,
            email,
            password,
          },
          type: 'users',
        },
      },
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    )
    if (response) return response
  }

  async resendEmail(id?: string) {
    const response = await this.GET(`${this.apiUrl}api/auth/resend-verify-email/` + id)
    if (response) return response
    toast.error('Could not resend your email')
  }

  async confirmEmail(token: string) {
    const response = await this.GET(
      `${this.apiUrl}api/auth/verify-email?token=${token}`,
      false,
      true,
      true,
    )

    if (response) return response
  }

  async logout() {
    const response = await this.GET(`${this.apiUrl}api/auth/logout`)
    if (response) return response
  }

  /* ================= Space =================== */

  async getMySpaces() {
    const response = await this.GET(`${this.apiUrl}api/spaces`)
    if (response) return await this.getAnyDeserializedData(response.data)
  }

  async getSpace(spaceId: string) {
    const response = await this.GET(`${this.apiUrl}api/spaces/${spaceId}`)
    if (response) return await this.getAnyDeserializedData(response.data)
  }

  async getSpaceProjectFiles(spaceId: string) {
    const response = await this.GET(`${this.apiUrl}api/spaces/${spaceId}/project-files`, true)
    if (response) return await this.getAnyDeserializedData(response.data)
  }

  async getSpaceRoles() {
    const response = await this.GET(`${this.apiUrl}api/space-roles`)
    if (response) return response
  }

  async getInviteLink(spaceId: string, expires: string, spaceRoleId = 3) {
    const response = await this.GET(`${this.apiUrl}api/spaces/${spaceId}/invites/public`)
    if (response) return response
  }

  async generateInviteLink(spaceId: string, expires: string, spaceRoleId = 3) {
    const response = await this.POST(`${this.apiUrl}api/spaces/${spaceId}/invites/public`, {
      data: {
        type: 'spaces',
        attributes: {
          expires,
        },
      },
    })
    if (response) return response
  }

  async sendSpaceInvite(spaceId: string, inviteEmails: string[], spaceRoleId = 3) {
    const response = await this.POST(
      `${this.apiUrl}api/spaces/${spaceId}/invites`,
      {
        data: {
          type: 'spaces',
          attributes: {
            emails: inviteEmails,
            spaceRoleId,
          },
        },
      },
      undefined,
      undefined,
      undefined,
      false,
      true,
    )
    if (response) return response
  }

  async createNewSpace(name: string, description: string) {
    const response = await this.POST(`${this.apiUrl}api/spaces`, {
      data: {
        type: 'spaces',
        attributes: {
          name,
          description,
        },
      },
    })
    if (response) return response
    else console.error(response)
  }

  async createProjectFileRequest(
    newProjectFileTitle: string,
    spaceId: string,
    isPrivate?: boolean,
    assignMembers?: string,
  ) {
    const response = await this.POST(`${this.apiUrl}api/spaces/${spaceId}/project-files`, {
      data: {
        type: 'projectFile',
        attributes: {
          title: newProjectFileTitle,
          spaceId: parseInt(spaceId),
          private: isPrivate,
          assignMembers,
        },
      },
    })
    if (response) return response
    toast.error('Unable to create project file')
  }

  async deleteProjectFileRequest(spaceId: string, projectFileId: number) {
    const response = await this.DELETE(
      `${this.apiUrl}api/spaces/${spaceId}/project-files/${projectFileId}`,
    )
    if (response) return response
    toast.error('Unable to delete project file')
  }

  async getSpaceMembers(spaceId: string, name?: string, projectFileId?: string) {
    let urlString: string
    if (!name && !projectFileId) {
      urlString = `${this.apiUrl}api/spaces/${spaceId}/members`
    } else
      urlString = `${this.apiUrl}api/spaces/${spaceId}/members?name=${name}&projectFileId=${projectFileId}`
    const response = await this.GET(urlString)
    if (response) {
      return await this.getAnyDeserializedData(response.data)
    }
    console.error('Unable to get members of space')
  }

  async confirmSpaceInviteToken(inviteToken: string) {
    return await this.GET(
      `${this.apiUrl}api/spaces/redirect?inviteToken=${inviteToken}`,
      false,
      false,
    )
  }

  async updateSpaceMemberTeamPermission(roleId: number, spaceId: number, memberId: string) {
    const response = await this.PATCH(`${this.apiUrl}api/spaces/${spaceId}/members/${memberId}`, {
      data: {
        type: 'spaceMembers',
        attributes: {
          roleId,
          spaceId,
        },
      },
    })
    if (response) return response
    toast.error('Failed to update space member team permission')
  }

  async deleteSpaceMember(memberId: string, spaceId: number) {
    const response = await this.DELETE(`${this.apiUrl}api/spaces/${spaceId}/members/${memberId}`)
    if (response) return response
    toast.error('Unable to delete space member')
  }

  async deleteSpace(spaceId: number) {
    const response = await this.DELETE(`${this.apiUrl}api/spaces/${spaceId}`)
    if (response) return response
  }

  async updateSpace(spaceId: string, name?: string, description?: string) {
    const response = await this.PATCH(`${this.apiUrl}api/spaces/${spaceId}`, {
      data: {
        type: 'spaces',
        attributes: {
          name,
          description,
        },
      },
    })
    if (response) return await this.getAnyDeserializedData(response.data)
  }

  /* ========== Project file =========== */

  async getProjectFile(spaceId: string, projectFileId: number) {
    const response = await this.GET(
      `${this.apiUrl}api/spaces/${spaceId}/project-files/${projectFileId}`,
      true,
      false,
      true,
    )
    if (response) return await this.getAnyDeserializedData(response.data)
  }

  async getProjectFilePages(projectFileId: string) {
    const response = await this.GET(`${this.apiUrl}api/project-files/${projectFileId}/pages`, true)
    if (response) return this.getAnyDeserializedData(response.data)
  }

  async addProjectFilePage(args: ICreatePageRequestArgs) {
    const createPageResponse = await this.POST(
      `${this.apiUrl}api/project-files/${args.projectId}/pages`,
      {
        data: {
          type: 'page',
          attributes: {
            title: args.title,
            access: 'project-members',
            type: args.type,
            parentId: args.parentId,
            embedUrl: args.embedUrl,
            blocks: args.blocks,
            positionIndex: args.positionIndex,
            icon: args.icon,
            isHidden: args.isHidden,
          },
        },
      },
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    )

    if (createPageResponse) return this.getAnyDeserializedData(createPageResponse.data)
    toast.error('Unable to create page')
  }

  async deleteProjectFilePage(spaceId: string, projectFileId: number, pageId: string) {
    const deletePageResponse = await this.DELETE(
      `${this.apiUrl}api/project-files/${projectFileId}/pages/${pageId}?id=${pageId}`,
    )
    if (deletePageResponse) {
      const response: any = await this.getProjectFile(spaceId, projectFileId)
      if (response) {
        return response
      } else toast.error('Unable to fetch new pages')
    } else toast.error('Error deleting page')
  }

  async updatePageTitle(title: string, projectFileId: number, pageId: string) {
    const response = await this.PATCH(
      `${this.apiUrl}api/project-files/${projectFileId}/pages/${pageId}`,
      {
        data: {
          type: 'page',
          attributes: {
            title,
          },
        },
      },
    )
    if (response) return response
    toast.error('Unable to update page title.')
  }

  async updatePageIcon(icon: string, projectFileId: number, pageId: string) {
    const response = await this.PATCH(
      `${this.apiUrl}api/project-files/${projectFileId}/pages/${pageId}`,
      {
        data: {
          type: 'page',
          attributes: {
            icon,
            projectFileId,
            softDeleted: false,
            activeView: 'document',
          },
        },
      },
    )
    if (response) return response
    toast.error('Unable to update page icon.')
  }

  async movePage(
    projectFileId: string,
    pageId: string,
    parentPageId: string | number | null,
    newIndex = 0,
  ) {
    if (parentPageId === 0) parentPageId = null
    const response = await this.PATCH(
      `${this.apiUrl}api/project-files/${projectFileId}/pages/${pageId}/move`,
      {
        data: {
          type: 'page',
          attributes: {
            parentId: parentPageId,
            newIndex,
          },
        },
      },
    )
    if (response) {
      return await this.getAnyDeserializedData(response.data)
    }
  }

  async getProjectFileRoles() {
    const response = await this.GET(`${this.apiUrl}api/project-file-roles`)
    if (response) return response
    toast.error('Unable to get project file roles')
  }

  async inviteUserToProject(projectFileId: number, emails: string[], roleId: number) {
    const response = await this.POST(
      `${this.apiUrl}api/project-files/${projectFileId}/invites`,
      {
        data: {
          type: 'projectFiles',
          attributes: {
            emails,
            projectFileRoleId: roleId,
          },
        },
      },
      undefined,
      undefined,
      undefined,
      false,
      true,
    )
    if (response) return response
  }

  async getProjectFilePublicLink(projectFileId: number) {
    const response = await this.GET(`${this.apiUrl}api/project-files/${projectFileId}/invites/link`)
    if (response) return response
    toast.error('Failed to generate shareable public project link')
  }

  async generateProjectFileInviteLink(projectFileId: number, roleId: number, expires: string) {
    const response = await this.POST(
      `${this.apiUrl}api/project-files/${projectFileId}/invites/link`,
      {
        data: {
          type: 'projectFiles',
          attributes: {
            projectFileRoleId: roleId,
            expires,
          },
        },
      },
    )
    if (response) return response
    toast.error('Failed to generate shareable public project link')
  }

  async updateProjectFile({
    spaceId,
    projectFileId,
    isPrivate,
    title,
    backgroundImage,
  }: {
    spaceId: string
    projectFileId: string
    isPrivate?: string
    title?: string
    backgroundImage?: string
  }) {
    const privacySetting = () => {
      if (isPrivate === 'true') {
        return true
      } else if (isPrivate === 'false') return false
    }

    const response = await this.PATCH(
      `${this.apiUrl}api/spaces/${spaceId}/project-files/${projectFileId}`,
      {
        data: {
          type: 'projectFile',
          attributes: {
            title,
            private: privacySetting(),
            backgroundImage,
          },
        },
      },
    )
    if (response) return response
    toast.error('Failed to update project file properties')
  }

  async getProjectFileMembers(projectFileId: number, pageId?: string, name?: string) {
    const urlString = () => {
      if (pageId && name)
        return `${this.apiUrl}api/project-files/${projectFileId}/members?pageId=${pageId}&name=${name}`
      else return `${this.apiUrl}api/project-files/${projectFileId}/members`
    }
    const response = await this.GET(urlString(), true)
    if (response) {
      return await this.getAnyDeserializedData(response.data)
    }
    toast.error('Failed to fetch project file members')
  }

  async getProjectPendingMembers(projectId: number) {
    const response = await this.GET(`${this.apiUrl}api/project-files/${projectId}/invites`)
    if (response) {
      return await this.getAnyDeserializedData(response.data)
    }
    toast.error('Failed to fetch project file pending members')
  }

  async confirmProjectFileInvite(projectFileId: string, inviteToken: string, spaceId: string) {
    const response = await this.GET(
      `${this.apiUrl}api/spaces/${spaceId}/project-files/${projectFileId}?inviteToken=${inviteToken}`,
      false,
      false,
    )
    if (response) return this.getAnyDeserializedData(response.data)
  }

  async updateProjectFileMember(projectFileId: string, memberId: string, roleId: number) {
    const response = await this.PATCH(
      `${this.apiUrl}api/project-files/${projectFileId}/members/${memberId}`,
      {
        data: {
          type: 'projectFileMembers',
          attributes: {
            roleId,
          },
        },
      },
    )
    if (response) return response
    toast.error('Failed to update project member role')
  }

  async deleteProjecFileMember(projectFileId: number, memberId: string) {
    const response = await this.DELETE(
      `${this.apiUrl}api/project-files/${projectFileId}/members/${memberId}`,
    )
    if (response) return response
    toast.error('Could not remove member')
  }

  async resendInvitationLink(projectFileId: number, inviteToken: string) {
    const response = await this.POST(
      `${this.apiUrl}api/project-files/${projectFileId}/invites/resend-link/${inviteToken}`,
      {},
    )
    if (response) return response
    toast.error('Could not resend invitation')
  }

  /* =========== Page ============ */

  async getPage(projectFileId: string, pageId: string, inviteToken?: string) {
    if (pageId === EMPTY_PAGE_ID) return
    const requestString = inviteToken
      ? `${this.apiUrl}api/project-files/${projectFileId}/pages/${pageId}?inviteToken=${inviteToken}`
      : `${this.apiUrl}api/project-files/${projectFileId}/pages/${pageId}`

    try {
      const response = await this.GET(requestString, true, false, true)
      if (response) return await this.getAnyDeserializedData(response.data)
    } catch (error) {
      throw new Error(error as string)
    }
  }

  async inviteUserToPage(
    pageId: string,
    emails: string[],
    pageRoleId: number,
    allowedView?: string,
  ) {
    const response = await this.POST(
      `${this.apiUrl}api/pages/${pageId}/invites`,
      {
        data: {
          type: 'pages',
          attributes: {
            emails,
            pageRoleId,
            allowedView,
          },
        },
      },
      undefined,
      undefined,
      undefined,
      false,
      true,
    )
    if (response) return response
  }

  async getPagePublicLink(pageId: string, pageRoleId: number) {
    const response = await this.POST(`${this.apiUrl}api/pages/${pageId}/invites/link`, {
      data: {
        type: 'invitationLink',
        attributes: {
          pageRoleId,
        },
      },
    })
    if (response) return response
    toast.error('Could not fetch link')
  }

  async updatePage({
    pageId,
    projectId,
    linkSharing,
    access,
    title,
    type,
    softDelete,
    positionIndex,
    backgroundImage,
  }: {
    pageId?: string
    projectId?: number
    linkSharing?: PageLinkSharingEnum
    access?: string
    title?: string
    type?: string
    softDelete?: boolean
    positionIndex?: number
    backgroundImage?: string
  }) {
    const response = await this.PATCH(
      `${this.apiUrl}api/project-files/${projectId}/pages/${pageId}`,
      {
        data: {
          type: 'page',
          attributes: {
            title,
            projectFileId: projectId,
            type,
            linkSharing,
            access,
            softDelete,
            positionIndex,
            backgroundImage,
          },
        },
      },
    )
    if (response) return response
    toast.error('Unable to update privacy setting')
  }

  async confirmPageInvitation(projectFileId: string, pageId: string, inviteToken: string) {
    const response = this.GET(
      `${this.apiUrl}api/project-files/${projectFileId}/pages/${pageId}?inviteToken=${inviteToken}`,
      false,
      false,
    )
    if (response) return response
    toast.error('Unable to confirm invitation to page')
  }

  async updatePageMember(memberId: string, pageId: string, roleId: number) {
    const response = this.PATCH(`${this.apiUrl}api/pages/${pageId}/members/${memberId}`, {
      data: {
        type: 'pageMembers',
        attributes: {
          roleId,
        },
      },
    })
    if (response) return response
    toast.error('Unable to update page member!')
  }

  async getPageRoles() {
    const response = await this.GET(`${this.apiUrl}api/page-roles`)
    if (response) return await this.getAnyDeserializedData(response.data)
  }

  async getPageMembers(pageId: string) {
    const response = await this.GET(`${this.apiUrl}api/pages/${pageId}/members`)
    if (response) return await this.getAnyDeserializedData(response.data)
  }

  async getPendingMembers(pageId: string) {
    const response = await this.GET(`${this.apiUrl}api/pages/${pageId}/invites`)
    if (response) return await this.getAnyDeserializedData(response.data)
  }

  async resendPageInvite(pageId: string, inviteToken: string) {
    const response = await this.GET(
      `${this.apiUrl}api/pages/${pageId}/invites/resend-link/${inviteToken}`,
    )
    if (response) return response
    toast.error('Something went wrong while resending invitation')
  }

  async removeFromPage(pageId: string, memberId: string) {
    const response = await this.DELETE(`${this.apiUrl}api/pages/${pageId}/members/${memberId}`)
    if (response) {
      toast.error('User removed from page')
      return true
    }
    toast.error('Something went wrong while removing member from page')
  }

  async removePendingUserFromPage(pageId: string, inviteId: string) {
    const response = await this.DELETE(`${this.apiUrl}api/pages/${pageId}/invites/${inviteId}`)
    if (response) {
      toast.error('Pending user successfully removed from page')
      return true
    }
    toast.error('Something went wrong while removing pending member from page')
  }

  /* ========== PAGE REQUEST ACCESS ===========*/

  async requestAccessToPage(pageId: string) {
    const response = await this.POST(
      `${this.apiUrl}api/pages/${pageId}/invites/request`,
      {
        roleType: IPermissions.CAN_EDIT,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    )
    if (response) return response
    toast.error('Unable to request access to page')
  }

  async confirmAccessToPage(pageId: string, inviteToken: string, accept: boolean) {
    const response = await this.PATCH(`${this.apiUrl}api/pages/${pageId}/invites/confirm`, {
      inviteToken,
      accept,
    })
    if (response) return response
    toast.error('Unable to confirm access to page')
  }

  async postImage(
    formData: FormData | Blob,
    headers: any,
    onUploadProgress: (progressEvent: any) => void,
    onDownloadProgress: (progressEvent: any) => void,
  ) {
    const response = await this.POST(
      `${this.apiUrl}api/resources`,
      formData,
      headers,
      onUploadProgress,
      onDownloadProgress,
    )
    if (response) return await this.getAnyDeserializedData(response.data)
    toast.error('Failed to upload image')
  }

  async getImage(id: string) {
    const response = await this.GET(`${this.apiUrl}api/resources/${id}/path`)
    if (response) return response
  }

  async getUserProfileImage(id: number) {
    const response = await this.GET(`${this.apiUrl}api/users/${id}/photo`)
    if (response) return response
  }

  async deleteImage(id: string) {
    const response = await this.DELETE(`${this.apiUrl}api/users/${id}/photo`)
    if (response) return response
  }

  /* ========== USER =========== */

  async updateUserInfo(updateData: {
    genderId?: number
    age?: number
    location?: string
    aboutMe?: string
    firstName?: string
    lastName?: string
    email?: string
    password?: string
    newPassword?: string
    colorPaletteId?: number
  }) {
    const response = this.PATCH(`${this.apiUrl}api/users/me`, {
      data: {
        type: 'user',
        attributes: updateData,
      },
    })

    if (response) return response
    toast.error('Unable to update user info!')
  }

  async deleteUserAccount() {
    const response = this.DELETE(`${this.apiUrl}api/users/me`)
    if (response) return response
    toast.error('Unable to delete account.')
  }

  async refreshChatToken() {
    return await this.GET(`${this.apiUrl}api/users/refresh-tokens`)
  }

  /* ========== Genders =========== */

  async getAllGenders() {
    const response = await this.GET(`${this.apiUrl}api/genders`, true)
    if (response) return this.getAnyDeserializedData(response.data)
  }

  async duplicatePage(projectFileId: string, pageId: string, title: string, parentId?: string) {
    const response = await this.POST(
      `${this.apiUrl}api/project-files/${projectFileId}/pages?duplicateId=${pageId}`,
      {
        data: {
          type: 'page',
          attributes: {
            title: `Duplicate of ${title}`,
            parentId,
          },
        },
      },
    )
    if (response) return response
  }

  /* ========== Global =========== */

  async getMe(isErrorCritial?: boolean) {
    const response = await this.GET(`${this.apiUrl}api/users/me`, isErrorCritial ? true : false)
    if (response) {
      const user = await this.getAnyDeserializedData(response.data)
      user.id = parseInt(user.id)
      return user
    }
  }

  /* ========== AI ==============  */

  async aiExpand(text: string) {
    const response = await this.POST(`${this.apiUrl}api/open-ai/extend`, {
      text,
    })
    return response?.data
  }

  async aiSummarize(text: string) {
    const response = await this.POST(`${this.apiUrl}api/open-ai/summarize`, {
      text,
    })
    return response?.data
  }

  async aiGenerate(text: string) {
    const response = await this.POST(`${this.apiUrl}api/open-ai/generate`, {
      text,
    })
    return response?.data
  }

  /* In most of the cases, you could get a CORS error. Additional changes in the configuration for this instance then should be applied. Because that can be a big job and to avoid possible leftovers and issues on other requests, in such cases you can even use the directly instantiated instance of axios in a specific file, depending on the case. */
  async downloadImage(imageUrl?: string) {
    const response = await this.GET(imageUrl || 'cover-case', false, false, true, 'blob')
    return response
  }

  async getMyFiles() {
    // Project file hardcoded because of the REST principles
    const response = await this.GET(`${this.apiUrl}api/spaces/2/pages`)
    if (response) return response.data
  }

  async getDefaultColorPalettes() {
    const response = await this.GET(`${this.apiUrl}api/system/color-palettes`)
    if (response) return response.data
  }

  async getUserColorPalette(id: number) {
    const response = await this.GET(`${this.apiUrl}api/users/${id}/color-palettes`)
    if (response) return response.data
  }

  async updateColorPalette(id: string, name: string, userId: number) {
    const response = await this.PATCH(`${this.apiUrl}api/users/${userId}/color-palettes/${id}`, {
      data: {
        type: 'colorPalette',
        attributes: {
          name,
        },
      },
    })
    if (response) return response.data
  }

  async createColorPalette(userId: number, name: string, duplicateId: number) {
    const response = await this.POST(`${this.apiUrl}api/users/${userId}/color-palettes`, {
      data: {
        type: 'colorPalette',
        attributes: {
          name,
          duplicateId,
        },
      },
    })
    if (response) return response.data
  }

  async createDefaultPalette(name: string, duplicateId: number) {
    const response = await this.POST(`${this.apiUrl}api/system/color-palettes`, {
      data: {
        type: 'colorPalette',
        attributes: {
          name,
          duplicateId,
        },
      },
    })
    if (response) return response.data
  }

  async deleteColorPalette(id: string, userId: number) {
    const response = await this.DELETE(`${this.apiUrl}api/users/${userId}/color-palettes/${id}`)
    if (response) return response.data
  }

  async deleteDefaultPalette(id: string) {
    const response = await this.DELETE(`${this.apiUrl}api/system/color-palettes/${id}`)
    if (response) return response
  }

  async updateColorPaletteColor(id: string, color: string, opacity: number, userId: number) {
    const response = await this.PATCH(`${this.apiUrl}api/users/${userId}/color-presets/${id}`, {
      data: {
        type: 'colorPreset',
        attributes: {
          color: color.replace('#', ''),
          opacity,
        },
      },
    })
    if (response) return response.data
  }

  async updateDefaultColorPalette(paletteId: string, name: string) {
    const response = await this.PATCH(`${this.apiUrl}api/system/color-palettes/${paletteId}`, {
      data: {
        type: 'colorPalette',
        attributes: {
          name,
        },
      },
    })
    if (response) return response.data
  }

  async updateDefaultColorPaletteColor(id: string, color: string, opacity: number) {
    const response = await this.PATCH(`${this.apiUrl}api/system/color-presets/${id}`, {
      data: {
        type: 'colorPreset',
        attributes: {
          color: color.replace('#', ''),
          opacity,
        },
      },
    })
    if (response) return response.data
  }

  async resetDefaultColorPalette(id: string) {
    const response = await this.POST(`${this.apiUrl}api/system/color-palettes/${id}/reset`, {})
    if (response) return response.data
  }

  async getPageObjects(pageId: string, objectId: string) {
    const response = await this.GET(`${this.apiUrl}api/pages/${pageId}/objects/${objectId}`)
    if (response) return response.data
  }

  async getUser(id: string) {
    const response = await this.GET(`${this.apiUrl}api/users/${id}`)
    if (response) return response.data
  }

  async setDefaultSpace(spaceId: string, isDefault = true) {
    const response = await this.PATCH(`${this.apiUrl}api/spaces/${spaceId}/default`, {
      isDefault: isDefault,
    })
    if (response) return response.data
  }

  async getDefaultSpace() {
    const response = await this.GET(`${this.apiUrl}api/spaces/default`)
    if (response) return response.data
  }

  // TAGS

  async createTag(name: string, projectId: string) {
    const response = await this.POST(`${this.apiUrl}api/project-files/${projectId}/tags`, {
      value: name,
    })

    if (response) return response.data
  }

  async getTags(projectId: string) {
    const response = await this.GET(`${this.apiUrl}api/project-files/${projectId}/tags`)
    if (response) return response.data
  }

  async deleteTag(projectId: string, tagId: string) {
    const response = await this.DELETE(`${this.apiUrl}api/project-files/${projectId}/tags/${tagId}`)
    if (response) return response.data
  }

  async updateTag(projectId: string, tagId: string, name: string) {
    const response = await this.PATCH(
      `${this.apiUrl}api/project-files/${projectId}/tags/${tagId}`,
      {
        value: name,
      },
    )
    if (response) return response.data
  }

  async getTag(projectId: string, tagId: string) {
    const response = await this.GET(`${this.apiUrl}api/project-files/${projectId}/tags/${tagId}`)
    if (response) return response.data
  }

  async setDefaultProject(projectId: string, isDefault = true) {
    const response = await this.PATCH(`${this.apiUrl}api/project-files/${projectId}/default`, {
      isDefault: isDefault,
    })
    if (response) return response.data
  }

  async getDefaultProject() {
    const response = await this.GET(`${this.apiUrl}api/project-files/default`)
    if (response) return response.data
  }

  async getProjectFilesBySpaceId(spaceId: string) {
    const response = await this.GET(`${this.apiUrl}api/spaces/${spaceId}/project-files`)
    if (response) return response.data
  }

  async getDefaultPage(type: string) {
    const response = await this.GET(`${this.apiUrl}api/pages/default?type=${type}`)
    if (response) return response.data
  }

  async setDefaultPage(pageId: string, isDefault = true) {
    const response = await this.PATCH(`${this.apiUrl}api/pages/${pageId}/default`, {
      isDefault: isDefault,
    })
    if (response) return response.data
  }

  async getAllPages({
    type,
    limit = 10,
    page = 1,
    search = '',
  }: {
    type: string
    limit?: number
    page?: number
    search?: string
  }) {
    const response = await this.GET(
      `${this.apiUrl}api/spaces/1/pages?type=${type}&limit=${limit}&page=${page}&search=${search}`,
    )
    if (response) return response.data
  }

  async copyPageToProjectFile(pageId: string, projectFileId: number, projectToCopyInto: number) {
    const response = await this.POST(
      `${this.apiUrl}api/project-files/${projectFileId}/pages/${pageId}/copy`,
      {
        projectId: projectToCopyInto,
      },
    )
    if (response) return response.data
  }

  async copyProjectToProjectSpace(
    spaceId: string,
    projectFileId: string,
    spaceIdToCopyInto: number,
  ) {
    const response = await this.POST(
      `${this.apiUrl}api/spaces/${spaceId}/project-files/${projectFileId}/copy`,
      {
        spaceId: spaceIdToCopyInto,
      },
    )
    if (response) return response.data
  }

  async getCalloutMetadata(url: string) {
    const response = await this.POST(`${this.apiUrl}api/callouts`, {
      url,
    })

    if (response) return response.data
  }

  async createPageSummary(projectId: string, pageId: string, category: string, text: string) {
    const response = await this.POST(
      `${this.apiUrl}api/project-files/${projectId}/pages/${pageId}/summary`,
      {
        category,
        text,
      },
    )
    if (response) return this.getAnyDeserializedData(response.data)
  }

  async uploadUrl(filename: string) {
    const response = await this.POST(`${this.apiUrl}api/resources/upload-url`, {
      filename,
    })
    if (response) return response.data
  }

  async uploadFileToUrl(url: string, file: any) {
    try {
      const headers = new Headers({
        'Content-Type': file.type,
      })

      const response = await fetch(url, {
        method: 'PUT',
        headers,
        body: file,
      })

      if (response) return response
    } catch (e) {
      console.error(e)
    }
  }

  async createResource(filePath: string, pageId?: string) {
    const response = await this.POST(`${this.apiUrl}api/resources/link`, {
      pageId,
      filePath,
    })
    if (response) return response.data
  }

  async generateAiImage(text: string, imageSize?: ImageSizeEnum) {
    const response = await this.POST(
      `${this.apiUrl}api/open-ai/generate-image`,
      {
        text,
        imageSize,
      },
      undefined,
      undefined,
      undefined,
      undefined,
      true,
    )

    if (response) return response.data
  }

  async createProjectSummary(projectId: string, category: SummarizationCategory, text: string) {
    const response = await this.POST(`${this.apiUrl}api/project-files/${projectId}/pages/summary`, {
      category,
      text,
    })
    if (response) return response.data
  }

  async getAllSettings() {
    const response = await this.GET(`${this.apiUrl}api/settings`)
    if (response) return response.data
  }

  async updateSetting(id: string, value: string) {
    const response = await this.PATCH(`${this.apiUrl}api/settings/${id}`, {
      value,
    })
    if (response) return response.data
  }

  async searchSpaces({ search, page, limit }: { search: string; page: number; limit: number }) {
    const response = await this.GET(
      `${this.apiUrl}api/spaces/search?search=${search}&page=${page}&limit=${limit}`,
    )
    if (response) return response.data
  }

  async searchProjects({ search, page, limit }: { search: string; page: number; limit: number }) {
    const response = await this.GET(
      `${this.apiUrl}api/spaces/3/project-files/search?search=${search}&page=${page}&limit=${limit}`,
    )
    if (response) return response.data
  }
}
