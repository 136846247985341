import { useNavigate } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'

const useOnPageClick = () => {
  const space = useAppSelector((state) => state.space.currentSpace)
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const navigate = useNavigate()

  return (pageId: string, blockId?: string, spaceId?: string, projectFileId?: string) => {
    navigate(
      {
        pathname: `/spaces/${spaceId || space?.id}/project-file/${
          projectFileId || projectFile?.id
        }`,
        search: `?pageId=${pageId && pageId}`,
        hash: blockId,
      },
      { replace: true },
    )
  }
}

export default useOnPageClick
