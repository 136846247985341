import styled from 'styled-components'
import { ReactComponent as CropIconStyled } from 'assets/images/cropIntersection.svg'

export const CropIcon = styled(CropIconStyled)``

export const CropperOptions = styled.div`
  position: absolute;
  right: 0.5rem;
  bottom: 1rem;
  background: var(--overlay-modal);
  border-radius: 7px;
  padding: 0.5rem 0.6rem;
  display: flex;
  align-items: baseline;
  gap: 1.5rem;
  filter: drop-shadow(0rem 0rem 2rem rgba(0, 0, 0, 0.15));
  z-index: 100;
  transform: scale(0.8);
  user-select: none;
`

export const CancelOption = styled.p`
  cursor: pointer;
  padding: 0;
  margin: 0;
`

export const CropOption = styled.p`
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: var(--primary-color);
`
