import React from 'react'

interface Props {
  id: string
}

const ExpandIcon = (props: Props) => {
  return (
    <svg width='14' height='13' viewBox='0 0 14 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.6665 11.3341H12.3332M1.6665 11.3341V8.66739L6.99984 3.33406M1.6665 11.3341L4.33317 11.334L9.6665 6.00072M6.99984 3.33406L8.91226 1.42163L8.91341 1.42049C9.17666 1.15724 9.30852 1.02538 9.46052 0.975988C9.59442 0.932483 9.73866 0.932483 9.87256 0.975988C10.0245 1.02534 10.1562 1.15705 10.419 1.41993L11.5789 2.5798C11.8429 2.84381 11.975 2.97588 12.0244 3.1281C12.0679 3.26199 12.0679 3.40622 12.0244 3.54012C11.975 3.69223 11.8431 3.82409 11.5795 4.08773L11.5789 4.08829L9.6665 6.00072M6.99984 3.33406L9.6665 6.00072'
        stroke={`url(#${props.id})`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <linearGradient
          id={props.id}
          x1='6.99984'
          y1='0.943359'
          x2='6.99984'
          y2='11.3341'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2C6BFF' />
          <stop offset='0.557292' stopColor='#9CACFF' />
          <stop offset='1' stopColor='#FC4AFF' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default ExpandIcon
