import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { setSelectedPage } from 'redux/reducers/pageReducer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useUpdatePage } from '../createPage'
import { useToast } from 'services/helpers/useToast'
import { useUpload } from '_features/upload'
import { extractNameFromLink } from 'shared/lib'

export const useUpdatePageBackground = () => {
  // ** State
  const [loading, setLoading] = useState(false)
  const [color, setColor] = useState<string | undefined>()
  const [opacity, setOpacity] = useState<number | undefined>(0)
  const [backgroundImage, setBackgroundImage] = useState<string | undefined>()
  const [name, setName] = useState<string>('')
  const [blur, setBlur] = useState<string>('')

  // ** Redux state
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const page = useAppSelector((state) => state.page.selectedPage)

  // ** Hooks
  const _updatePage = useUpdatePage()
  const _upload = useUpload()
  const toast = useToast()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const backgroundImageSetting = page?.backgroundImage

    if (!backgroundImageSetting) return

    const background = JSON.parse(backgroundImageSetting)

    const backgroundImage = background.backgroundImage

    if (backgroundImage) {
      setName(extractNameFromLink(backgroundImage))
      setBackgroundImage(backgroundImage)
      setColor(undefined)
    }

    const overlayColor = background.overlayColor
    const overlayOpacity = background.overlayOpacity
    const overlayBlur = background.overlayBlur

    if (overlayColor) {
      setColor(overlayColor)
    }

    if (overlayOpacity) {
      setOpacity(overlayOpacity)
    }

    if (overlayBlur) {
      setBlur(overlayBlur)
    }
  }, [page])

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const files = e.target.files

    if (files && files[0].size > 5000000000) {
      toast.error('The maximum size per file is 5GB')
      return
    }

    if (files === null) return

    await _upload.handleFileUpload(files[0], async (name, _, link) => {
      const data = {
        backgroundImage: link,
      }

      setBackgroundImage(link)

      await updatePageBackground(JSON.stringify(data))
    })

    setLoading(false)
  }

  const changeBlur = (value: string) => {
    const newData = JSON.stringify({
      overlayBlur: value,
    })

    updatePageBackground(newData)
  }

  const debounceBlurChange = useMemo(
    () =>
      debounce((args: { value: string }) => {
        changeBlur(args.value)
      }, 300),
    [],
  )

  const onBlurChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBlur(e.currentTarget.value)
    debounceBlurChange({ value: e.currentTarget.value })
  }

  const updatePageBackground = async (data: string) => {
    if (!page?.id || !projectFile?.id) return
    await _updatePage.updatePage(page.id, parseInt(projectFile.id), undefined, undefined, data)

    dispatch(
      setSelectedPage({
        ...page,
        backgroundImage: JSON.stringify({
          ...JSON.parse(page.backgroundImage || '{}'),
          ...JSON.parse(data),
        }),
      }),
    )
  }

  const removeBackgroundImage = async () => {
    setLoading(true)
    const data = JSON.stringify({
      backgroundImage: '',
    })

    await updatePageBackground(data)

    setName('')
    setBackgroundImage('')

    setLoading(false)
  }

  return {
    updatePageBackground,
    onBlurChange,
    changeBlur,
    handleFileUpload,
    page,
    setColor,
    color,
    loading,
    backgroundImage,
    name,
    removeBackgroundImage,
    opacity,
    setOpacity,
    blur,
    setBlur,
  }
}
