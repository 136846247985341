import { EditWrapper } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/BlockEdit/styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import { useUpload } from '_features/upload'
import { IBlockTypes } from '_entities/block'
import { SidebarEditProps } from './EmbedEdit'
import { getBlockDocumentPage, useWhiteboardEmbed } from '_entities/embed'
import { getCurrentFrame, usePresentation, WhiteboardAttrs, useCanvas } from '_features/canvas'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import { IFrameItem } from 'interfaces/whiteboard'
import { Spinner } from 'react-bootstrap'

export const StyledThumbnail = styled.img`
  width: auto;
  height: 50px;
  border-radius: 8px;
`

export const EmbedBackgroundImageEdit = (props: SidebarEditProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [currentFrame, setCurrentFrame] = useState<IFrameItem | undefined>()
  const _upload = useUpload()
  const _presentation = usePresentation()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _canvas = useCanvas()

  const onClick = () => {
    _upload.triggerUpload((file) => {
      setLoading(true)
      if (!file) return
      _upload.handleFileUpload(file, (fileName, fileType, link) => {
        if (!link) return
        if (fileType !== IBlockTypes.IMAGE) return
        const blockPage = getBlockDocumentPage(props.block)
        if (blockPage && _whiteboardEmbed.isDocEmbed(blockPage.id)) {
          _presentation.updateFrameBackgroundImage(blockPage.id, link)
          setCurrentFrame(getCurrentFrame(blockPage.id))
          setLoading(false)
        }
      })
    })
  }

  useEffect(() => {
    const blockDocumentPage = getBlockDocumentPage(props.block)
    if (!blockDocumentPage) return
    _canvas.observeMutationOnCanvasElement(
      blockDocumentPage.id,
      WhiteboardAttrs.CURRENT_FRAME,
      () => {
        setCurrentFrame(getCurrentFrame(blockDocumentPage.id))
      },
    )
  }, [])
  return (
    <EditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Background image
      </CustomP>
      {!loading && currentFrame?.backgroundImage && (
        <StyledThumbnail src={currentFrame.backgroundImage} />
      )}
      {!loading && !currentFrame?.backgroundImage && (
        <IconButton onClick={onClick} padding='0' width='18px' height='18px'>
          <icons.upload />
        </IconButton>
      )}
      {loading && <Spinner size='sm' animation='border' />}
    </EditWrapper>
  )
}
