import React, { ChangeEvent } from 'react'
import { StyledUploadOrLinkSelectorWrapper } from './style'
import Tabs from 'components/molecules/Tabs/Tabs'
import FileUpload from 'components/molecules/WhiteboardFileUpload/FileUpload'

interface Props {
  tabs: { text: string }[]
  selectedTab: { text: string }
  setSelectedTab: React.Dispatch<React.SetStateAction<{ text: string }>>
  handleFileUpload: (e: ChangeEvent<HTMLInputElement>) => Promise<void> | void
  loading: boolean
  link: string
  uploadButtonText: string
  embedButtonDisabled: boolean
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => Promise<void>
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  embedFileTitle: string
  embedFileButtonText: string
}

const UploadOrLinkSelector = (props: Props) => {
  return (
    <StyledUploadOrLinkSelectorWrapper>
      <Tabs
        tabFontSize='12px'
        tabs={props.tabs}
        selectedTab={props.selectedTab}
        onClick={(text) => props.setSelectedTab({ text })}
        padding='0 1rem'
        height='26px'
        borderBottom={'1px solid #D6D6D6'}
      />
      {props.selectedTab.text === props.tabs[0].text && (
        <FileUpload
          handleFileUpload={props.handleFileUpload}
          loading={props.loading}
          buttonText={props.uploadButtonText}
        />
      )}
    </StyledUploadOrLinkSelectorWrapper>
  )
}

export default UploadOrLinkSelector
