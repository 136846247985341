import * as json1 from 'ot-json1'
import * as richText from 'rich-text'
import { type block } from '_entities/block'
import { Doc, Path } from 'ot-json1'

export const useJson1 = () => {
  json1.type.registerSubtype(richText)

  const getBlockKey = (index: number) => ['blocks', index]

  const getJson1 = () => json1

  // Data key replacement

  const getReplaceBlockDataKeyOp = (index: number, key: Path, newValue: Doc, oldValue?: Doc) => {
    return json1.replaceOp([...getBlockKey(index), 'data', ...key], oldValue || true, newValue)
  }

  // Data key insertion

  const getInsertKeyInDataKeyOp = (index: number, key: Path, value: Doc) => {
    return json1.insertOp([...getBlockKey(index), 'data', ...key], value)
  }

  // Data key deletion
  const getDeleteKeyInDataKeyOp = (index: number, key: Path) => {
    return json1.removeOp([...getBlockKey(index), 'data', ...key])
  }

  // Meta key replacement

  const getReplaceBlockMetaKeyOp = (index: number, key: Path, newValue: Doc, oldValue?: Doc) => {
    return json1.replaceOp([...getBlockKey(index), 'meta', ...key], oldValue || true, newValue)
  }

  // Full block operations

  const addBlock = (block: block, index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return json1.insertOp(getBlockKey(index), block as any)
  }

  const replaceBlock = (block: block, index: number, oldBlock?: block) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return json1.replaceOp(getBlockKey(index), (oldBlock as any) || true, block as any)
  }

  const deleteBlock = (index: number) => {
    return json1.removeOp(getBlockKey(index))
  }

  const moveBlock = (indexFrom: number, indexTo: number) => {
    return json1.moveOp(getBlockKey(indexFrom), ['blocks', indexTo])
  }

  const combineOperations = (array: json1.JSONOp[]) => {
    return array.reduce(json1.type.compose, null)
  }

  const replaceEntireDoc = (oldDoc: block[], newDoc: block[]) => {
    return json1.replaceOp(['blocks'], oldDoc as any, newDoc as any)
  }

  return {
    getJson1,
    addBlock,
    getReplaceBlockDataKeyOp,
    getInsertKeyInDataKeyOp,
    getDeleteKeyInDataKeyOp,
    replaceBlock,
    deleteBlock,
    moveBlock,
    combineOperations,
    replaceEntireDoc,
    getReplaceBlockMetaKeyOp,
  }
}
