import React from 'react'
import Embed from 'components/atoms/IFrameEmbed/IFrameEmbed'
import { Container } from './styles'

type FullPageEmbedPropsType = {
  embedUrl: string
}

export const FullPageEmbed = ({ embedUrl }: FullPageEmbedPropsType) => {
  return (
    <Container>
      <Embed width='100%' height='100%' src={embedUrl} />
    </Container>
  )
}
