import styled from 'styled-components'

export const StyledButton = styled.button<{
  borderradius?: string
  background?: string
  border?: string
  position?: string
  left?: string
  right?: string
  textAling?: string
  width?: string
  margin?: string
  padding?: string
  height?: string
  fontSize?: string
  fontWeight?: string
  color?: string
  opacity?: string
}>`
  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderradius};
  background: ${(props) => props.background};
  position: ${(props) => props.position};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  text-align: ${(props) => props.textAling};
  width: ${(props) => props.width};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  height: ${(props) => props.height};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  opacity: ${(props) => props.opacity};

  &:hover {
    background-color: var(--hover);
  }
`
