import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { TAGS } from 'shared/api'
import { envVars } from 'config/env'

export const propertyApi = createApi({
  reducerPath: 'propertyApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envVars.api.https}api/tasks/0/properties`,
    credentials: 'include',
  }),
  tagTypes: [TAGS.PROPERTY],
  endpoints: () => ({}),
})
