import React from 'react'
import { CustomIconCss } from './styles'

type Props = {
  src?: any
  alt?: string
  height?: string
  width?: string
  hoverEffect?: boolean
  padding?: string
  margin?: string
  rotatez?: string
  isOpen?: boolean
  index?: number
  className?: string
  opacity?: string
}

const CustomIcon: React.FC<Props> = ({
  src,
  alt,
  height,
  width,
  padding,
  margin,
  rotatez,
  isOpen,
  className,
  opacity,
}) => {
  return (
    <CustomIconCss
      src={src}
      alt={alt}
      height={height}
      width={width}
      padding={padding}
      margin={margin}
      rotatez={rotatez}
      isOpen={isOpen}
      opacity={opacity}
      className={className}
    />
  )
}

CustomIcon.defaultProps = {
  src: '',
  alt: '',
  height: '',
  width: '',
  margin: '',
  padding: '',
  opacity: '',
}

export default CustomIcon
