import { AxiosService } from 'services/axiosService/axiosService'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setExpireLinkValue, setInviteEmailsInputValue } from 'redux/reducers/spaceReducer'
import { ISpace } from 'interfaces/space'
import { useEffect, useRef, useState } from 'react'
import { MultiValue } from 'react-select'
import { IDropdownOption, IMultiValueOption } from 'interfaces/invite'
import { useToast } from 'services/helpers/useToast'

export const useSpaceInviteModal = () => {
  const toast = useToast()
  const AxiosInstance = new AxiosService()

  const selectRef = useRef(null)
  const dispatch = useAppDispatch()
  const space = useAppSelector((state) => state.space.currentSpace)
  const inputValue = useAppSelector((state) => state.space.inviteEmailsInputValue)
  const realInputValue = useRef(inputValue)
  const expiresValue = useAppSelector((state) => state.space.expireLinkValue)
  const [inviteLink, setInviteLink] = useState<string>('')

  const [inviteEmails, setInviteEmails] = useState<MultiValue<IMultiValueOption>>([])

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const getInviteLink = async (spaceId: string, expires: string) => {
    const linkResponse = await AxiosInstance.getInviteLink(spaceId, expires)
    if (linkResponse) setInviteLink(linkResponse.data)
  }

  const generateInviteLink = async (spaceId: string, expires: string) => {
    const linkResponse = await AxiosInstance.generateInviteLink(spaceId, expires)
    if (linkResponse) {
      setInviteLink(linkResponse.data)
      toast.success('New link has been generated.')
    }
  }

  const setInviteEmailsState = (value: MultiValue<IMultiValueOption>) => {
    setInviteEmails(value)
  }

  const setInputValueState = (value: string) => {
    realInputValue.current = value
  }

  const setInviteEmailsInputValueState = (value: string) => {
    setInputValueState(value)
    dispatch(setInviteEmailsInputValue(value))
  }

  const sendInviteEmail = async () => {
    if (space && space.id) {
      const onlyEmails = inviteEmails.map((elem) => elem.value)
      const response = await AxiosInstance.sendSpaceInvite(space.id, onlyEmails)
      if (response) {
        selectRef && selectRef.current && (selectRef.current as any).clearValue()
        toast.success('Invite sent successfully!')
      }
    }
  }

  const onChangeLinkExpiration = (value: IDropdownOption) => {
    dispatch(setExpireLinkValue(value))
  }

  const onInvitationModalClick = (e: MouseEvent) => {
    if (!(e.target as HTMLElement).matches('#linkOptionsForInviteModalOnlySelector')) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    if (!space) return
    getInviteLink(space.id, 'Never')
  }, [])

  return {
    inviteLink,
    getInviteLink,
    generateInviteLink,
    inputValue,
    setInviteEmailsInputValueState,
    sendInviteEmail,
    expiresValue,
    setInviteEmailsState,
    inviteEmails,
    onChangeLinkExpiration,
    selectRef,
    onInvitationModalClick,
    isOpen,
    setIsOpen,
  }
}
