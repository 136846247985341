import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setRightSidebarOpen } from 'redux/reducers/pageReducer'
import { setLeftMinimize, setRightMinimize } from 'redux/reducers/projectFileReducer'

export const useSidebarSizes = () => {
  const leftMinimize = useAppSelector((state) => state.projectFile.leftMinimize)
  const rightMinimize = useAppSelector((state) => state.projectFile.rightMinimize)

  const dispatch = useAppDispatch()

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1280) {
        dispatch(setLeftMinimize(false))
        dispatch(setRightMinimize(false))
        dispatch(setRightSidebarOpen(false))
      } else if (window.innerWidth < 1280 && window.innerWidth > 1024) {
        dispatch(setLeftMinimize(true))
        dispatch(setRightMinimize(false))
        dispatch(setRightSidebarOpen(false))
      } else if (window.innerWidth <= 1024) {
        dispatch(setLeftMinimize(true))
        dispatch(setRightMinimize(true))
        dispatch(setRightSidebarOpen(true))
      }
    }

    window.addEventListener('resize', handleResize)

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return {
    leftMinimize,
    rightMinimize,
    setLeftMinimize,
    setRightMinimize,
  }
}
