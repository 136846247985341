import { DrawerDropIndicator, DrawerDropIndicatorContent, DrawerDropIndicatorIcon } from './styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import React from 'react'

interface IProps {
  text: string
  icon: React.ReactNode
}

const OnDropOverlay = ({ text, icon }: IProps) => (
  <DrawerDropIndicator>
    <DrawerDropIndicatorContent>
      <DrawerDropIndicatorIcon>{icon}</DrawerDropIndicatorIcon> <CustomP>{text}</CustomP>
    </DrawerDropIndicatorContent>
  </DrawerDropIndicator>
)

export default OnDropOverlay
