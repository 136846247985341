import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

const StyledDropdown = styled(Dropdown)`
  .dropdown-menu {
    border-radius: 10px;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  }

  .dropdown-item {
    * {
      color: var(--primary-text) !important;
      background-color: var(--overlay-modal);
    }
    &:hover {
      background: var(--hover) !important;
    }
  }

  svg path {
    stroke: var(--primary-text) !important;
  }
`

export default StyledDropdown
