import React from 'react'
import { EditWrapper } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/BlockEdit/styles'
import { getColor } from 'shared/colors'
import CustomP from 'components/atoms/CustomP/CustomP'
import { useEmbedOverlayToggle } from '_features/embed'
import Switch from 'components/atoms/Switch/Switch'
import { SidebarEditProps } from './EmbedEdit'

export const OverlayToggle = (props: SidebarEditProps) => {
  const _embedOverlayToggle = useEmbedOverlayToggle(props)

  return (
    <EditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Overlay
      </CustomP>
      <Switch
        checked={_embedOverlayToggle.overlay}
        onClick={_embedOverlayToggle.handleEditOverlayToggle}
        transform='translateX(3px)'
      />
    </EditWrapper>
  )
}
