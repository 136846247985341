import { type block, IBlockTypes } from '_entities/block'

export const isPageReference = (block: block): boolean => {
  return (
    (block?.data.tag === IBlockTypes.DOCUMENT ||
      block?.data.tag === IBlockTypes.WHITEBOARD ||
      block?.data.tag === IBlockTypes.EMBED) &&
    block?.data?.documentPage !== undefined
  )
}
