import styled from 'styled-components'

export const StyledEmbedMenu = styled.div`
  display: flex;
  height: fit-content;
`
export const StyledColumn = styled.div`
  padding: 12px;
  width: 156px;
`
