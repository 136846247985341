import { getBlockPageId, getIndex } from '_entities/block'
import RadixDropdown, {
  IconButton,
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { FrameSelectorMenu } from '_features/toolbar/ui/FrameSelector/styles'
import { SizeToggleButton } from './styles'
import { ReactComponent as PlayIcon } from 'assets/images/whiteboard/play.svg'
import ColorTool from 'components/organisms/ShapeToolbar/ColorTool/ColorTool'
import { IconButtonSize } from '_features/toolbar'
import { useAppSelector } from 'redux/hooks'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { FrameOptions, IFrameOption, useFrame, getFill } from '_entities/whiteboard'
import { SourceKeys } from 'interfaces/editor'
import { usePresentation, useTransformer, useFrameColor } from '_features/canvas'
import { ToolbarDivider } from 'editor/EditableBlock/BlockToolbar/styles'

const FrameToolbar = () => {
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _presentation = usePresentation()
  const _transformer = useTransformer()
  const _frame = useFrame()
  const _frameColor = useFrameColor()
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)

  const onSizeChange = (option: IFrameOption) => {
    if (!selectedBlock) return
    const pageId = getBlockPageId(selectedBlock)
    const index = getIndex(selectedBlock)
    if (index < 0) return
    const op = _json1.getReplaceBlockDataKeyOp(
      index,
      ['frame', 'name'],
      option.name,
      selectedBlock.data.frame?.name,
    )
    _submit.submit(pageId, op, SourceKeys.UPDATE_BLOCK)
    setTimeout(() => {
      _transformer.forceUpdateTransformer(pageId)
    })
  }

  const handleDisableColor = () => {
    if (!selectedBlock) return
    const pageId = getBlockPageId(selectedBlock)
    const index = getIndex(selectedBlock)
    const currentFill = getFill(selectedBlock)
    if (currentFill) {
      _submit.submit(
        pageId,
        _json1.getDeleteKeyInDataKeyOp(index, ['frame', 'fill']),
        SourceKeys.UPDATE_BLOCK,
      )
    }
  }

  const beginPresentation = () => {
    if (!selectedBlock) return
    _presentation.beginPresentation({
      pageId: getBlockPageId(selectedBlock),
      block: selectedBlock,
      shouldAnimate: true,
    })
  }

  if (!selectedBlock) return null

  return (
    <>
      {/* Frame size selector */}
      <RadixDropdown
        trigger={<SizeToggleButton>{selectedBlock.data.frame?.name}</SizeToggleButton>}
      >
        <FrameSelectorMenu>
          {FrameOptions().map((option) => (
            <DropdownMenuItem
              onSelect={() => {
                onSizeChange(option)
              }}
              key={'frame-selector-option'}
            >
              {option.child}
            </DropdownMenuItem>
          ))}
        </FrameSelectorMenu>
      </RadixDropdown>
      {/* Background color selector */}
      <ColorTool
        onSelect={(color) => selectedBlock && _frameColor.handleSelectColor(selectedBlock, color)}
        onSelectDisabled={handleDisableColor}
        color={getFill(selectedBlock) || _frame.NO_FILL}
      />

      <IconButton
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
        onClick={beginPresentation}
      >
        <PlayIcon />
      </IconButton>
      <ToolbarDivider />
    </>
  )
}

export default FrameToolbar
