import React from 'react'
import { StyledExpandIcon, StyledMinimizeIcon } from 'components/molecules/TaskTitle/styles'
import { PagePreviewActions } from './styles'

interface IProps {
  isEmbeddedDocumentExpanded: boolean
  handleEmbeddedMaximize: () => void
  handleEmbeddedMinimize: () => void
  isFigma?: boolean
}

export const PagePreviewAction = (props: IProps) => {
  return (
    <PagePreviewActions isFigma={props.isFigma}>
      {!props.isEmbeddedDocumentExpanded ? (
        <StyledExpandIcon
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            props.handleEmbeddedMaximize()
          }}
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
        />
      ) : (
        <StyledMinimizeIcon
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            props.handleEmbeddedMinimize()
          }}
          onMouseDown={(e) => {
            e.stopPropagation()
          }}
        />
      )}
    </PagePreviewActions>
  )
}
