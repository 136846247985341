import { css } from 'styled-components'
import { ReactComponent as Exclamation } from 'assets/images/exclamationWhite.svg'

/**
 * Add text truncation, defaults to word-break: break-all, but you can overwrite by specifying word-break after calling this
 *
 * @param {number} [numOfLines=1]
 */
export const textTrunc = (numOfLines = 1) => css`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  word-break: break-all;
  -webkit-line-clamp: ${numOfLines};
  -webkit-box-orient: vertical;
`
