import { IUserAttributes } from './auth/userData'
import { NodeModel } from '@minoru/react-dnd-treeview'

export enum SourceKeys {
  UPDATE_CHAT_ID = 'update-chat-id',
  PASTE_OP = 'paste-op',
  UPDATE_COLOUR = 'update-colour',
  UPDATE_BLOCK_INDEX = 'update-block-index',
  UPDATE_BLOCK = 'update-block',
  UPDATE_PROPERTY = 'update-property',
  AI_UPDATE_EXPAND = 'ai-update-expand',
  AI_UPDATE_GENERATE = 'ai-update-generate',
  AI_UPDATE_SUMARRY = 'ai-update-summary',
}

export interface SourceType extends IUserAttributes {
  sourceKey?: SourceKeys
}

export enum DragSourceTypes {
  WHITEBOARD = 'whiteboard',
  DOCUMENT = 'document',
  EMBED = 'embed',
  BLOCK = 'block',
}

export interface ExtendedNodeModel extends NodeModel<unknown> {
  positionIndex: number
  type: string
}

export enum FormatTypes {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  STRIKETHROUGH = 'strikethrough',
  COLOR = 'color',
  BACKGROUND_COLOR = 'background',
  BLOCK_TYPE = 'size',
}

export type focusHandlerOptions = {
  caretStart?: number | undefined
  caretEnd?: number | undefined
  hasToGoAtTheEndPosition?: boolean | undefined
}

export enum BootstrapDropdowns {
  TEXT_STYLES = 'textStyles',
  TEXT_COLOURS = 'textColours',
  TEXT_AI = 'textAI',
}

export enum TagTypes {
  CREATE_INPUT = 'create-input',
  CREATE = 'create',
}
