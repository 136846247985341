import { IBlockTypes, type block } from '_entities/block'
import {
  useGrid,
  useHtml,
  useFrame,
  useKonvaNode,
  getWhiteboardContainerId,
  useLayer,
} from '_entities/whiteboard'
import { useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setSelectedBlock } from 'redux/reducers/whiteboardReducer'
import {
  useTransformer,
  useStage,
  useCopyPaste,
  StageAttrs,
  IConnector,
  useConnectors,
  useIntersection,
  useMove,
} from '_features/canvas'
import { objectId } from 'utils/editor'
import { KonvaEventObject, Node, NodeConfig } from 'konva/lib/Node'
import { IArrowItem } from 'interfaces/whiteboard'
import { useEvent } from '_features/canvas/event'
import { SourceKeys } from 'interfaces/editor'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { JSONOp } from 'ot-json1'
import { Transformer } from 'konva/lib/shapes/Transformer'

import { getColor } from 'shared/colors'
import { useLeftSidebar } from '_widgets/LeftSidebar/model/useLeftSidebar'
import { RelationshipType, useRelationships } from '_features/relationship'
import { useComments } from '_features/comments'
import { IPages } from 'interfaces/page'
import Konva from 'konva'
import { getPageReferenceId } from '_entities/page/model/usePageReference'
import { useTasksDrawerElement, useChatDrawerElement } from '_widgets'
import { TASK_COLUMN } from 'components/organisms/TaskManagerList/TaskManagerList'
import { useDeleteBlock } from '_features/block'
import { useWhiteboardEmbed } from '_entities/embed'
import { useCreateTask } from '_features/task'
import { getById } from 'shared/lib'
import { getBlockById, getBlocks, getBlocksLength } from 'shared/shareDb'

interface Props {
  connectionPreviewArrow: IArrowItem | null
  setConnectionPreviewArrow: (arrow: IArrowItem | null) => void
  connectors: IConnector[]
  setConnectors: (connectors: IConnector[]) => void
}

export interface IBlockAndOp {
  block: block
  op: JSONOp
}

export const SHADOW_NAME = 'drag-shadow'
export const SHADOW_PREFIX = 'shadow-'

export const getShadowId = (blockId: string) => {
  return `${SHADOW_PREFIX}${blockId}`
}

export const useCanvasDrag = (props: Props) => {
  const [ghosts, setGhosts] = useState<block[] | null>()
  const _grid = useGrid()
  const dispatch = useAppDispatch()
  const _transformer = useTransformer()
  const _connectors = useConnectors(props)
  const _event = useEvent()
  const _stage = useStage()
  const _intersection = useIntersection()
  const _move = useMove()
  const _delete = useDeleteBlock()
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _konvaNode = useKonvaNode()
  const _layer = useLayer()
  const _copyPaste = useCopyPaste()
  const _leftSidebar = useLeftSidebar()
  const _tasksDrawerElement = useTasksDrawerElement()
  const _chatDrawerElement = useChatDrawerElement()
  const _relationships = useRelationships({})
  const _html = useHtml()
  const _createTask = useCreateTask()
  const _comments = useComments()
  const _frame = useFrame()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const pages = useAppSelector((state) => state.projectFile.pages)

  const handleGhosts = (pageId: string) => {
    createGhostPlaceholders(pageId)
  }

  const setGhostBlocks = (pageId: string) => {
    const draggingBlocks = _transformer.getBlocksFromTransformer(pageId)
    if (!draggingBlocks) return
    const updatedBlocks = draggingBlocks.map((block) => {
      return { ...block, _id: objectId() }
    })
    setGhosts(updatedBlocks)
  }

  const getAllDragShadowNodes = (pageId: string) => {
    const parentStage = _stage.getStage(pageId)
    return parentStage?.find(`.${SHADOW_NAME}`)
  }

  const destroyShadowNodes = (pageId: string) => {
    const shadowNodes = getAllDragShadowNodes(pageId)
    if (shadowNodes && shadowNodes.length) {
      shadowNodes.forEach((shadowNode) => {
        shadowNode?.destroy()
      })
    }
  }

  const createGhostPlaceholders = (pageId: string) => {
    const layer = _layer.getLayer(pageId)
    const draggingNodes = _transformer.getTransformerNodes(pageId)
    if (!layer || !draggingNodes || !draggingNodes.length) return
    const clonedNodes = draggingNodes.map((node) => node.clone())
    clonedNodes.forEach((clonedNode) => {
      const backgroundNode =
        _konvaNode.getImageNodeFromGroup(clonedNode) || _konvaNode.getRectFromGroup(clonedNode)
      if (!backgroundNode) return
      backgroundNode.fill(getColor('--primary-color'))
      backgroundNode.opacity(0.2)
      backgroundNode.id(getShadowId(clonedNode.attrs.id))
      clonedNode.name(SHADOW_NAME)
      layer.add(clonedNode)
    })
  }

  const handleBlockMove = (pageId: string, isEmbed?: boolean) => {
    const whiteboardEmbedConditions = getMoveBlockToEmbedConditions(pageId)
    if (
      !isEmbed &&
      whiteboardEmbedConditions.hasIntersectionWithWhiteboardEmbed &&
      whiteboardEmbedConditions.embedBlock
    ) {
      handleMoveBlocksToEmbed(
        pageId,
        whiteboardEmbedConditions.whiteboardEmbedToDropBlockInto,
        whiteboardEmbedConditions.embedBlock,
      )
    } else {
      _move.moveBlocksInTransformer(pageId)
    }
  }

  const handleMoveBlocksToEmbed = (pageId: string, wbEmbedPageId: string, wbEmbedBlock: block) => {
    const draggedBlocks = _transformer.getBlocksFromTransformer(pageId)
    if (draggedBlocks) {
      dispatch(setSelectedBlock(wbEmbedBlock))
      _delete.deleteMultipleBlocks(pageId)
      setTimeout(() => {
        const parentBlocks = getBlocks(wbEmbedPageId)
        const addOps = draggedBlocks.map((block) => {
          const newPosition = getNewPosition(wbEmbedPageId, block)
          const blockWithModifiedPosition = getBlockWithModifedPosition(
            block,
            newPosition?.x || 0,
            newPosition?.y || 0,
            wbEmbedPageId || block.meta.pageId,
          )
          return _json1.addBlock(blockWithModifiedPosition, parentBlocks?.length || 0)
        })
        _submit.submit(wbEmbedPageId, _json1.combineOperations(addOps), SourceKeys.UPDATE_BLOCK)
      }, 10)
    }
  }

  const getBlockWithModifedPosition = (
    block: block,
    x: number,
    y: number,
    parentPageId: string,
  ) => {
    return {
      ...block,
      _id: objectId(),
      data: {
        ...block.data,
        x,
        y,
      },
      meta: {
        ...block.meta,
        pageId: parentPageId,
      },
    }
  }

  const getNewPosition = (wbEmbedPageId: string, block: block) => {
    // Initially new position will be pointer position in embed
    const newPosition = _stage.getScaledPointerPosition(wbEmbedPageId)
    if (!newPosition) return
    // If block is arrow, then we need to subtract the difference
    // between pointer position and arrow coordinate with the lower x
    if (block.data.tag === IBlockTypes.ARROW && block.data.arrow) {
      const xStart = block.data.arrow.xStart
      const xEnd = block.data.arrow.xEnd
      const yStart = block.data.arrow.yStart
      const yEnd = block.data.arrow.yEnd
      const coordinateWithLowerX = xStart < xEnd ? { x: xStart, y: yStart } : { x: xEnd, y: yEnd }
      const differenceX = coordinateWithLowerX.x - newPosition.x
      const differenceY = coordinateWithLowerX.y - newPosition.y
      newPosition.x = -differenceX
      newPosition.y = -differenceY
    }
    if (block.data.tag === IBlockTypes.LINE && block.data.line) {
      const xStart = block.data.line.points[0]
      const yStart = block.data.line.points[1]
      const differenceX = newPosition.x - xStart
      const differenceY = newPosition.y - yStart
      newPosition.x = differenceX
      newPosition.y = differenceY
    }
    return newPosition
  }

  const getMoveBlockToEmbedConditions = (pageId: string) => {
    let hasIntersectionWithWhiteboardEmbed = false
    let whiteboardEmbedToDropBlockInto = ''
    let embedBlock: block | undefined
    const mousePosition = _stage.getRealPointerPosition(pageId)
    const whiteboardEmbedsWithoutDragged = filterDraggedEmbedFromAllEmbeds(pageId)
    whiteboardEmbedsWithoutDragged?.forEach((node) => {
      const rect = node.getClientRect()
      if (mousePosition) {
        const hasIntersection = _intersection.hasPointIntersection(mousePosition, rect)
        if (hasIntersection) {
          const wbEmbedBlock = findWhiteboardEmbedBlock(pageId, node)
          if (wbEmbedBlock && getCanBlockBeDroppedInEmbed(wbEmbedBlock)) {
            hasIntersectionWithWhiteboardEmbed = true
            embedBlock = wbEmbedBlock
            whiteboardEmbedToDropBlockInto = embedBlock.data.documentPage?.id || ''
          }
        }
      }
    })

    return { hasIntersectionWithWhiteboardEmbed, embedBlock, whiteboardEmbedToDropBlockInto }
  }

  const getCanBlockBeDroppedInEmbed = (wbEmbedBlock: block) => {
    return wbEmbedBlock.data.isEmbedExpanded
  }

  const findWhiteboardEmbedBlock = (pageId: string, node: Node<NodeConfig>) => {
    return getBlockById(pageId, node.attrs.id)
  }

  const filterDraggedEmbedFromAllEmbeds = (pageId: string) => {
    const transformerNodes = _transformer.getTransformerNodes(pageId)
    const whiteboardEmbeds = findAllWhiteboardEmbedNodes(pageId)
    if (whiteboardEmbeds) {
      return whiteboardEmbeds.filter((node) => {
        return !transformerNodes?.find((n) => n.attrs.id === node.attrs.id)
      })
    }
  }

  const findAllWhiteboardEmbedNodes = (pageId: string) => {
    return filterNodesByType(pageId, IBlockTypes.WHITEBOARD)
  }

  const filterNodesByType = (pageId: string, type: string) => {
    return _stage.getAllBlockNodes(pageId)?.filter((node) => node.attrs.blockType === type)
  }

  const checkIfMovedToEdgeOfWhiteboard = (
    evt: KonvaEventObject<MouseEvent | TouchEvent>,
    isEmbed?: boolean,
    parentPageId?: string,
  ) => {
    const pageId = _event.getPageIdFromEvent(evt)
    const whiteboardRect = getWhiteboardContainerRect(pageId)
    const mousePointerPosition = getMousePosition(evt)
    if (whiteboardRect && _intersection.isPointOutsideRect(mousePointerPosition, whiteboardRect)) {
      if (isEmbed && !_whiteboardEmbed.isDocEmbed(pageId)) moveBlocksOutOfEmbed(evt, parentPageId)
    }
  }

  const setHtmlNodesOpacity = (pageId: string, opacity: number) => {
    const blocks = _transformer.getBlocksFromTransformer(pageId)
    if (!blocks || blocks.length === 0) return
    blocks.forEach((block) => {
      const htmlNode = _html.getHtmlElement(block)
      if (!htmlNode) return
      htmlNode.style.opacity = opacity.toString()
    })
  }

  const setHtmlNodesZIndex = (pageId: string, zIndex: number) => {
    const blocks = _transformer.getBlocksFromTransformer(pageId)
    if (!blocks || blocks.length === 0) return
    blocks.forEach((block) => {
      const htmlNode = _html.getHtmlElement(block)
      if (!htmlNode) return

      if (_frame.isFrameBlock(block)) {
        htmlNode.style.zIndex = zIndex.toString()
      } else {
        htmlNode.style.zIndex = (zIndex + 1).toString()
      }
    })
  }

  enum SidebarDragSelectors {
    LEFT = 'left',
    TASKS = 'tasks',
    CHAT = 'chat',
  }

  const sidebarDragConfig = {
    [SidebarDragSelectors.LEFT]: {
      stageAttr: StageAttrs.DRAGGED_TO_LEFT_SIDEBAR,
      getRect: _leftSidebar.getLeftSidebarRect,
    },
    [SidebarDragSelectors.TASKS]: {
      stageAttr: StageAttrs.DRAGGED_TO_TASKS,
      getRect: _tasksDrawerElement.getTasksRect,
    },
    [SidebarDragSelectors.CHAT]: {
      stageAttr: StageAttrs.DRAGGED_TO_CHAT,
      getRect: _chatDrawerElement.getChatRect,
    },
  }

  const checkMoveToSidebar = (
    evt: KonvaEventObject<MouseEvent | TouchEvent>,
    selector: SidebarDragSelectors,
  ) => {
    const pageId = _event.getPageIdFromEvent(evt)
    let pointerPosition = _stage.getRealPointerPosition(pageId)
    const config = sidebarDragConfig[selector]
    if (!pointerPosition || !config) return
    const rect = config.getRect()
    if (!rect) return

    const canvasRect = getWhiteboardContainerRect(pageId)
    if (!canvasRect) return
    pointerPosition = {
      x: pointerPosition.x + canvasRect.x,
      y: pointerPosition.y + canvasRect.y,
    }
    const isIntersectedAttr = _stage.getStageAttr(pageId, config.stageAttr)
    if (_intersection.hasPointIntersection(pointerPosition, rect)) {
      // We are constanly setting opacity because of frame which is setting opacity
      // to 1 on drag since it needs to be html to go over left sidebar, and when it's
      // not being dragged the html opacity is 0 so only Konva node is rendered
      // to make the transformer visible if it goes over the frame
      setHtmlNodesOpacity(pageId, 0.2)
      if (!isIntersectedAttr) {
        _stage.setStageAttr(pageId, config.stageAttr, true)
      }
    } else {
      if (isIntersectedAttr) {
        _stage.setStageAttr(pageId, config.stageAttr, false)
        setHtmlNodesOpacity(pageId, 1)
      }
    }
  }

  const handleDragAltKeyDown = (pageId: string) => {
    if (ghosts) return
    setGhostBlocks(pageId)
  }

  const handleDragAltKeyUp = () => {
    setGhosts(null)
  }

  const handleDragEndAltKey = (pageId: string) => {
    if (ghosts) {
      const ops = ghosts.map((block) => {
        const newBlock = _copyPaste.generateNewPastedBlock(pageId, block, block._id)
        return _json1.addBlock(newBlock, getBlocksLength(pageId))
      })
      const pasteOps = _json1.combineOperations(ops)
      _submit.submit(pageId, pasteOps, SourceKeys.UPDATE_BLOCK)
    }
  }

  const moveBlocksOutOfEmbed = (
    evt: KonvaEventObject<MouseEvent | TouchEvent>,
    parentPageId?: string,
  ) => {
    const pageId = _event.getPageIdFromEvent(evt)
    const draggedBlocks = _transformer.getBlocksFromTransformer(pageId)
    if (draggedBlocks && draggedBlocks.length) {
      _delete.deleteMultipleBlocks(pageId)
      setTimeout(() => {
        sendDraggedBlocksToParent(draggedBlocks, parentPageId)
      })
    }
  }

  const sendDraggedBlocksToParent = (blocks: block[], parentPageId?: string) => {
    if (!parentPageId) return
    const newBlocksAndOps = getNewBlocksAndOpsToSendToParent(blocks, parentPageId)
    const ops = newBlocksAndOps.map((blockAndOp) => blockAndOp.op)
    _submit.submit(parentPageId, _json1.combineOperations(ops), SourceKeys.UPDATE_BLOCK)
    setTimeout(() => {
      continueDraggingNodesFromEmbed(newBlocksAndOps, parentPageId)
    }, 10)
  }

  const findNewlyAddedNodes = (newBlocksAndOps: IBlockAndOp[], parentPageId: string) => {
    return _stage.getAllNodes(parentPageId)?.filter((node: Node) => {
      return newBlocksAndOps.find((blockAndOp) => blockAndOp.block._id === node.attrs.id)
    })
  }

  const continueDraggingNodesFromEmbed = (newBlocksAndOps: IBlockAndOp[], parentPageId: string) => {
    if (parentPageId) {
      const parentStage = _stage.getStage(parentPageId)
      const parentTransformer = _transformer.getTransformer(parentPageId)
      if (parentStage && parentTransformer) {
        const newBlockNodes = findNewlyAddedNodes(newBlocksAndOps, parentPageId)
        if (newBlockNodes && newBlockNodes.length)
          (parentTransformer as Transformer).nodes(newBlockNodes)._nodes[0].startDrag()
      }
    }
  }

  const getNewBlocksAndOpsToSendToParent = (
    blocks: block[],
    parentPageId: string,
  ): IBlockAndOp[] => {
    if (!parentPageId) return []
    const parentBlocks = getBlocks(parentPageId)
    return blocks.map((block) => {
      const pointerPosition = parentPageId ? getNewPosition(parentPageId, block) : { x: 0, y: 0 }
      const blockWithModifiedPosition = getBlockWithModifedPosition(
        block,
        pointerPosition?.x || 0,
        pointerPosition?.y || 0,
        parentPageId || block.meta.pageId,
      )
      return {
        block: blockWithModifiedPosition,
        op: _json1.addBlock(blockWithModifiedPosition, parentBlocks?.length || 0),
      }
    })
  }

  const getMousePosition = (evt: KonvaEventObject<Event>) => {
    return {
      x: (evt.evt as MouseEvent).clientX,
      y: (evt.evt as MouseEvent).clientY,
    }
  }

  const getWhiteboardWrapper = (pageId: string) => {
    return getById(getWhiteboardContainerId(pageId))
  }

  const getWhiteboardContainerRect = (pageId: string) => {
    const whiteboardWrapper = getWhiteboardWrapper(pageId)
    if (whiteboardWrapper) return whiteboardWrapper.getBoundingClientRect()
  }

  const onTransformerDragStart = (pageId: string) => {
    handleGhosts(pageId)
    dispatch(setSelectedBlock(undefined))
    _grid.showShadow(pageId)
  }

  const onTransformerDrag = (
    evt: KonvaEventObject<MouseEvent | TouchEvent>,
    isEmbed?: boolean,
    parentPageId?: string,
  ) => {
    const pageId = _event.getPageIdFromEvent(evt)
    setHtmlNodesZIndex(pageId, 4)
    setHtmlNodesOpacity(pageId, 1)

    _grid.moveShadowWithTransformer(evt)
    _connectors.updateConnectors(pageId)
    _connectors.drawConnector(pageId)
    if (!_whiteboardEmbed.isDocEmbed(pageId)) {
      checkIfMovedToEdgeOfWhiteboard(evt, isEmbed, parentPageId)
      checkMoveToSidebar(evt, SidebarDragSelectors.LEFT)
      checkMoveToSidebar(evt, SidebarDragSelectors.TASKS)
      checkMoveToSidebar(evt, SidebarDragSelectors.CHAT)
    }
  }

  const onTransformerDragEnd = (e: KonvaEventObject<DragEvent>, isEmbed?: boolean) => {
    setGhosts(null)
    const pageId = _event.getPageIdFromEvent(e)
    setHtmlNodesZIndex(pageId, 0)
    setHtmlNodesOpacity(pageId, 1)
    checkMove(pageId, isEmbed)
    destroyShadowNodes(pageId)
    _connectors.addConnector(e)
    _grid.hideShadow(pageId)
  }

  const resetBlocksPositions = (pageId: string) => {
    const blocks = _transformer.getBlocksFromTransformer(pageId)
    if (!blocks || blocks.length === 0) return
    blocks.forEach((block) => {
      const group = _konvaNode.getGroupNode(block)
      group?.to({
        x: block.data.x || 0,
        y: block.data.y || 0,
        duration: 0.4,
        easing: Konva.Easings.StrongEaseOut,
      })
    })
  }

  const createRelationships = (fromPageId: string, page: IPages) => {
    const blocks = _transformer.getBlocksFromTransformer(fromPageId)
    if (!blocks || blocks.length === 0) return
    blocks.forEach((block) => {
      _relationships.createRelationship(RelationshipType.MIRROR, block, [
        _relationships.constructRelationshipPage({ ...page, id: page.id.toString() }),
      ])
    })
  }

  const getElementMouseIsOver = (pageId: string) => {
    const pointerPosition = _stage.getRealPointerPosition(pageId)
    if (!pointerPosition) return
    return document.elementFromPoint(pointerPosition.x, pointerPosition.y)
  }

  const checkDropIntoPage = (pageId: string) => {
    const elementMouseIsOver = getElementMouseIsOver(pageId)
    if (!elementMouseIsOver || !pages) return
    return pages.find((page) => {
      const pageElement = getById(getPageReferenceId(page.id.toString()))
      return pageElement?.contains(elementMouseIsOver)
    })
  }

  const checkDropIntoTasks = (pageId: string) => {
    const elementMouseIsOver = getElementMouseIsOver(pageId)
    if (!elementMouseIsOver) return
    const taskColumns = document.getElementsByName(TASK_COLUMN)
    return Array.from(taskColumns).find((column) => column.contains(elementMouseIsOver))
  }

  const checkDropIntoChat = (pageId: string) => {
    const elementMouseIsOver = getElementMouseIsOver(pageId)
    if (!elementMouseIsOver) return
    return _chatDrawerElement.getChatElement()?.contains(elementMouseIsOver)
  }

  const dropToPage = (pageId: string, pageToDropInto: IPages) => {
    resetBlocksPositions(pageId)
    createRelationships(pageId, pageToDropInto)
  }

  const dropToTasks = (pageId: string, taskCategoryElement: HTMLElement) => {
    const statusValueId = taskCategoryElement.id.split('-')[0]
    const blocks = _transformer.getBlocksFromTransformer(pageId)
    if (!blocks || blocks.length === 0) return
    blocks.forEach((block) => {
      _createTask.createReferencedTask(block, undefined, parseInt(statusValueId))
    })
    resetBlocksPositions(pageId)
  }

  const dropIntoChat = (pageId: string) => {
    const blocks = _transformer.getBlocksFromTransformer(pageId)
    if (!blocks || blocks.length === 0) return
    _comments.handleOnCommentClick(blocks[0])
    resetBlocksPositions(pageId)
  }

  const checkMove = (pageId: string, isEmbed?: boolean) => {
    const taskCategoryElement = checkDropIntoTasks(pageId)
    const pageToDropInto = checkDropIntoPage(pageId)
    const chatElement = checkDropIntoChat(pageId)
    if (!_whiteboardEmbed.isDocEmbed(pageId)) {
      if (pageToDropInto) {
        dropToPage(pageId, pageToDropInto)
      } else if (taskCategoryElement) {
        dropToTasks(pageId, taskCategoryElement)
      } else if (chatElement) {
        dropIntoChat(pageId)
      }
    }
    handleDragEndAltKey(pageId)
    handleBlockMove(pageId, isEmbed)
  }

  return {
    ghosts,
    onTransformerDragStart,
    onTransformerDrag,
    onTransformerDragEnd,
    handleDragAltKeyDown,
    handleDragAltKeyUp,
  }
}
