import { configureStore } from '@reduxjs/toolkit'
import projectFileReducer from './reducers/projectFileReducer'
import globalReducer from './reducers/globalReducer'
import spaceReducer from './reducers/spaceReducer'
import pageReducer from './reducers/pageReducer'
import taskManagerReducer from './reducers/taskManagerReducer'
import subscriptionReducer from './reducers/subscriptionReducer'
import whiteboardReducer from './reducers/whiteboardReducer'
import chatReducer from './reducers/chatReducer'
import profileReducer from './reducers/profileReducer'
import googleApi from 'shared/api/googleApi'
import { managerTasksApi } from 'shared/api/managerTasksApi'
import { managerApi } from 'shared/api/managerApi'
import { propertyApi } from 'shared/api/propertyApi'
import { ctaApi, pageApi } from 'shared/api'
import { landingPageApi } from 'shared/api/landingPageApi'

const store = configureStore({
  reducer: {
    global: globalReducer,
    projectFile: projectFileReducer,
    space: spaceReducer,
    page: pageReducer,
    taskManager: taskManagerReducer,
    subscription: subscriptionReducer,
    whiteboard: whiteboardReducer,
    chat: chatReducer,
    profile: profileReducer,
    [googleApi.reducerPath]: googleApi.reducer,
    [managerTasksApi.reducerPath]: managerTasksApi.reducer,
    [managerApi.reducerPath]: managerApi.reducer,
    [propertyApi.reducerPath]: propertyApi.reducer,
    [ctaApi.reducerPath]: ctaApi.reducer,
    [landingPageApi.reducerPath]: landingPageApi.reducer,
    [pageApi.reducerPath]: pageApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'projectFileReducer/setConnection',
          'taskManagerReducer/setSelectedFilters',
          'taskManagerReducer/setSelectedTask',
          'taskManagerReducer/setTasks',
          'taskManagerReducer/setProperties',
          'taskManagerReducer/setAllProperties',
          'taskManagerReducer/setGroups',
          'taskManagerReducer/setSubgroups',
          'taskManagerReducer/setSelectedShowProperties',
          'taskManagerReducer/setWebsocketConnection',
          'taskManagerReducer/setSelectedNewProperty',
          'taskManagerReducer/setSelectedFilterValues',
        ],
        ignoredPaths: [
          'projectFile.connection',
          'page.selectedBlocks',
          'taskManager.selectedFilters',
          'taskManager.selectedTask',
          'taskManager.tasks',
          'taskManager.properties',
          'taskManager.allProperties',
          'taskManager.groups',
          'taskManager.subgroups',
          'taskManager.selectedShowProperties',
          'taskManager.websocketConnection',
          'taskManager.selectedNewProperty',
          'taskManager.selectedFilterValues',
          'editor.embeddedWhiteboards',
        ],
      },
    }).concat([
      googleApi.middleware,
      managerTasksApi.middleware,
      managerApi.middleware,
      propertyApi.middleware,
      ctaApi.middleware,
      landingPageApi.middleware,
      pageApi.middleware,
    ]),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
