import React from 'react'
import {
  FlexRowContainerForInfo,
  InfoText,
  ModalInfoLabel,
  ReferralTabUserModalStyled,
  ModalCopyLinkButton,
  TitleText,
  HrAndOrContainer,
  InfoSpanText,
  Hr,
  ProgressBar,
  ProgressLine,
  InfoTextSmall,
} from './styles'
import { ReactComponent as FileCopySvg } from 'assets/images/copy.svg'
import Input from 'components/atoms/Input/Input'
import { useReferralsTabUserModal } from './useReferralsTabUserModal'
import ReferralsList from 'components/organisms/ReferralsList/ReferralsList'

const MyProfileTabUserModal = () => {
  const { referralLink, referralEmails, percentageForProgressBar } = useReferralsTabUserModal()

  return (
    <ReferralTabUserModalStyled>
      <TitleText>Invite others to join Everyday</TitleText>
      <ModalInfoLabel>Referral by link</ModalInfoLabel>
      <FlexRowContainerForInfo>
        <Input placeHolder='Invitation link' width='250px !important' fontweight='500' />
        <ModalCopyLinkButton disabled={referralLink !== ''}>
          <FileCopySvg /> Copy Link
        </ModalCopyLinkButton>
      </FlexRowContainerForInfo>

      <HrAndOrContainer>
        <InfoSpanText>or</InfoSpanText>
      </HrAndOrContainer>
      <ModalInfoLabel>Referral by email</ModalInfoLabel>
      <FlexRowContainerForInfo>
        <Input width='250px !important' placeHolder='@email or name' fontweight='500' />
        <ModalCopyLinkButton disabled={!referralEmails.length}>Send invite</ModalCopyLinkButton>
      </FlexRowContainerForInfo>
      <Hr />
      <TitleText margin='2.5rem 0px -1rem !important'>Referrals</TitleText>
      <InfoText>Invite 5 people to claim rewards</InfoText>
      <InfoTextSmall>2/5 people referred</InfoTextSmall>

      <ProgressBar className='progress'>
        <ProgressLine
          value={percentageForProgressBar || 40} /* Placeholder before networking is completed. */
          className='progress-bar'
        ></ProgressLine>
      </ProgressBar>

      <ReferralsList referralsList={[]} />
    </ReferralTabUserModalStyled>
  )
}

export default MyProfileTabUserModal
