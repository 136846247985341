import { SettingsOptionText, SettingsOptionWrapper, StyledDropdownTrigger } from '../styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { CtaButtonProps, FONT_WEIGHTS_MAP, getCtaFontWeight } from '_entities/cta'
import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'

export const FontWeightEdit = (props: CtaButtonProps) => {
  return (
    <SettingsOptionWrapper>
      <SettingsOptionText>Font Weight</SettingsOptionText>

      <RadixDropdown
        trigger={
          <StyledDropdownTrigger>
            <CustomP fontWeight='500'>
              {FONT_WEIGHTS_MAP.find((weight) => weight.label === getCtaFontWeight(props.button))
                ?.label || 400}
            </CustomP>
            <icons.arrowDown />
          </StyledDropdownTrigger>
        }
      >
        {[...FONT_WEIGHTS_MAP].map((option) => (
          <DropdownMenuItem
            key={option.value}
            onSelect={() =>
              props.updateCtaButton({
                id: props.button.id,
                styling: {
                  ...props.button.styling,
                  fontWeight: option.label,
                },
              })
            }
          >
            {option.label}
          </DropdownMenuItem>
        ))}
      </RadixDropdown>
    </SettingsOptionWrapper>
  )
}
