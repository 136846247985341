import { KonvaEventObject } from 'konva/lib/Node'
import { useSelection } from './useSelection'
import { useEvent } from '_features/canvas/event'
import { useResize } from '_features/canvas'
import { useGrid } from '_entities/whiteboard'

export const useTransform = () => {
  const _selection = useSelection()
  const _event = useEvent()
  const _resize = useResize()
  const _grid = useGrid()

  const onTransformStart = (pageId: string) => {
    _selection.removeSelectedBlock()
    _grid.showShadow(pageId)
  }

  const onTransformEnd = (e: KonvaEventObject<DragEvent>) => {
    const pageId = _event.getPageIdFromEvent(e)
    _resize.resizeBlocksInTransformer(pageId)
    _grid.hideShadow(pageId)
  }

  return {
    onTransformStart,
    onTransformEnd,
  }
}
