import { AxiosService } from 'services/axiosService/axiosService'
import { useToast } from 'services/helpers/useToast'

const toast = useToast()
const AxiosInstance = new AxiosService()
const IMAGE_DELETION_SUCCESS_TEXT = 'Successfully deleted file'

export const deleteImageOnServer = async (imageId?: string) => {
  // The imageUrl contains images/name.jpg, hence we do not need
  // to explicitly add the /images endpoint in the API url
  if (imageId) {
    const response = await AxiosInstance.deleteImage(imageId)
    if (response) toast.success(IMAGE_DELETION_SUCCESS_TEXT)
  }
}
