import { type block, getBlockPageId, getIndex, IBlockTypes } from '_entities/block'
import { useAppSelector } from 'redux/hooks'
import { useToast } from 'services/helpers/useToast'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { PageTypesEnum } from 'interfaces/projectFile'
import { useCreatePage } from '_features/page'
import { useCreateBlock } from '_features/block'
import { useState } from 'react'
import {
  EmbedType,
  FIGMA_INVALID_EMBED_NAME,
  getFigmaFileNameFromUrl,
  GOOGLE_DOCS_INVALID_EMBED_URL,
  GOOGLE_SHEET_INVALID_EMBED_URL,
  GOOGLE_SLIDES_INVALID_EMBED_URL,
  MIRO_INVALID_EMBED_URL,
  OFFICE_INVALID_EMBED_URL,
  useEmbed,
  useGoogle,
} from '_entities/embed'
import {
  checkIsAsana,
  checkIsEmail,
  checkIsFigma,
  checkIsGoogleDocLink,
  checkIsGoogleSheetsLink,
  checkIsGoogleSlidesLink,
  checkIsImageUrl,
  checkIsMiro,
  checkIsNotion,
  checkIsOfficeEmbedCode,
  checkIsOfficeLink,
  checkIsPdf,
  checkIsVimeo,
  checkIsYoutube,
} from '_entities/embed/ExternalEmbed/model/EmbedRegex'
import { Position } from 'interfaces/whiteboard'
import { setProgress, setInitial } from 'shared/lib'

export const DEFAULT_GENERIC_LINK_NAME = 'Link'

export const useEmbedCreation = () => {
  // ** State
  const [loading, setLoading] = useState<boolean>(false)

  // ** Redux state
  const space = useAppSelector((state) => state.space.currentSpace)
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const pages = useAppSelector((state) => state.projectFile.pages)

  // ** Hooks
  const _json1 = useJson1()
  const _submit = useSubmit()
  const toast = useToast()
  const _createBlock = useCreateBlock()
  const _embed = useEmbed()
  const _createPage = useCreatePage()
  const _google = useGoogle()

  const handleDocExternalLinkOp = async (
    block: block,
    link: string,
    name?: string,
    icon?: string,
  ) => {
    const index = getIndex(block)
    if (!space || !project || !pages) return
    const response = await _createPage.createEmbedPage(
      PageTypesEnum.EMBED,
      pages,
      project.id,
      space.id,
      block.meta.pageId,
      link,
      name || 'Untitled',
      icon,
    )
    const newBlock = {
      ...block,
      data: {
        ...block.data,
        tag: IBlockTypes.EXTERNAL_EMBED,
        embed: {
          type: _embed.getEmbedTypeFromLink(link) || EmbedType.GENERIC_LINK,
          name: name || '',
          url: link,
          pageId: response?.id,
          image: icon,
        },
      },
      meta: {
        ...block.meta,
        name: name || 'Untitled',
      },
    }

    const op = _json1.replaceBlock(newBlock, index, block)

    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  const handleDocExternalLink = async (link: string, block: block) => {
    setLoading(true)

    if (!pages) return
    const metadata = await _embed.fetchMetadata(link)
    let name = metadata?.title || ''
    const embedType = block.data.embed?.type

    switch (embedType) {
      case EmbedType.FIGMA:
        if (!checkIsFigma(link)) {
          toast.error(FIGMA_INVALID_EMBED_NAME)
          return
        }
        break

      case EmbedType.OFFICE:
        if (!checkIsOfficeEmbedCode(link)) {
          toast.error(OFFICE_INVALID_EMBED_URL)
          return
        }
        break

      case EmbedType.MIRO:
        if (!checkIsMiro(link)) {
          toast.error(MIRO_INVALID_EMBED_URL)
          return
        }
        break

      case EmbedType.GOOGLE_DOC:
        if (!checkIsGoogleDocLink(link)) {
          toast.error(GOOGLE_DOCS_INVALID_EMBED_URL)
          return
        }
        break

      case EmbedType.GOOGLE_SLIDES:
        if (!checkIsGoogleSlidesLink(link)) {
          toast.error(GOOGLE_SLIDES_INVALID_EMBED_URL)
          return
        }
        break

      case EmbedType.GOOGLE_SHEETS:
        if (!checkIsGoogleSheetsLink(link)) {
          toast.error(GOOGLE_SHEET_INVALID_EMBED_URL)
          return
        }
        break

      case EmbedType.YOUTUBE:
        if (!checkIsYoutube(link)) {
          toast.error('Invalid Youtube embed URL')
          return
        }
        break

      case EmbedType.VIMEO:
        if (!checkIsVimeo(link)) {
          toast.error('Invalid Vimeo embed URL')
          return
        }
        break

      case EmbedType.NOTION:
        if (!checkIsNotion(link)) {
          toast.error('Invalid Notion embed URL')
          return
        }
        break

      case EmbedType.ASANA:
        if (!checkIsAsana(link)) {
          toast.error('Invalid Asana embed URL')
          return
        }
        break

      case EmbedType.IMAGE:
        if (!checkIsImageUrl(link)) {
          toast.error('Invalid image embed URL')
          return
        }
        break

      case EmbedType.PDF:
        if (!checkIsPdf(link)) {
          toast.error('Invalid PDF embed URL')
          return
        }
        break

      default:
        break
    }
    if (checkIsFigma(link)) {
      name = getFigmaFileNameFromUrl(link) || 'Figma file'
    } else if (checkIsNotion(link)) {
      name = _embed.getNotionLinkName(link) || 'Notion file'
    } else if (checkIsMiro(link)) {
      name = name || 'Miro board'
    } else if (
      checkIsGoogleDocLink(link) ||
      checkIsGoogleSlidesLink(link) ||
      checkIsGoogleSheetsLink(link)
    ) {
      name = (await _google.fetchFileName(link)) || 'Google file'
    } else if (checkIsOfficeEmbedCode(link) || checkIsYoutube(link) || checkIsVimeo(link)) {
      name = name || 'Office 365'
    } else if (checkIsAsana(link)) {
      name = name || 'Asana'
    }

    await handleDocExternalLinkOp(block, link, name, metadata?.image)
    setLoading(false)
  }

  const handleWbExternalLink = async (
    link: string,
    embedType: EmbedType,
    pageId: string,
    onOpenChange?: (open: boolean) => void,
    position?: Position,
    doCreateEmbedPage = true,
  ) => {
    setLoading(true)
    setProgress()
    const metadata = await _embed.fetchMetadata(link)
    const name = metadata?.title
    const image = metadata?.image

    if (embedType !== EmbedType.GENERIC_LINK) {
      const isImage = checkIsImageUrl(link)
      const isPdf = await checkIsPdf(link)
      const isNotion = checkIsNotion(link)
      const isAsana = checkIsAsana(link)
      const isYoutube = checkIsYoutube(link)
      const isVimeo = checkIsVimeo(link)
      const isMiro = checkIsMiro(link)
      const isFigma = checkIsFigma(link)
      const isGoogleDoc = checkIsGoogleDocLink(link)
      const isGoogleSlides = checkIsGoogleSlidesLink(link)
      const isGoogleSheets = checkIsGoogleSheetsLink(link)
      const isGoogleCalendar = checkIsEmail(link)
      const isOffice = checkIsOfficeLink(link)

      if (isImage) {
        const page = pages?.find((p) => p.embedUrl === link)

        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.IMAGE,
          initialText: '',
          imageUrl: link,
          pageId,
          position,
          name: page?.text || 'Image',
        })
        return
      } else if (isPdf) {
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.EXTERNAL_EMBED,
          externalEmbed: {
            type: EmbedType.PDF,
            name: 'PDF',
            url: link,
          },
          pageId,
          position,
          name: 'PDF',
        })
        onOpenChange && onOpenChange(false)
      } else if (isNotion) {
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.EXTERNAL_EMBED,
          externalEmbed: {
            type: EmbedType.NOTION,
            name: _embed.getNotionLinkName(link),
            url: link,
          },
          pageId,
          position,
          name: _embed.getNotionLinkName(link),
        })
        onOpenChange && onOpenChange(false)
      } else if (isAsana) {
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.EXTERNAL_EMBED,
          externalEmbed: { type: EmbedType.ASANA, name: 'Asana', url: link },
          pageId,
          position,
          name: metadata?.title || 'Asana',
        })
        onOpenChange && onOpenChange(false)
      } else if (isFigma) {
        if (pages && space && project) {
          const response =
            doCreateEmbedPage &&
            (await _createPage.createEmbedPage(
              PageTypesEnum.EMBED,
              pages,
              project.id,
              space.id,
              pageId,
              link,
              getFigmaFileNameFromUrl(link),
            ))

          _createBlock.createWhiteboardBlock({
            type: IBlockTypes.EXTERNAL_EMBED,
            externalEmbed: {
              type: EmbedType.FIGMA,
              name: getFigmaFileNameFromUrl(link),
              url: link,
              pageId: response?.id,
            },
            pageId,
            position,
            name: getFigmaFileNameFromUrl(link),
          })
        }
      } else if (isYoutube) {
        const name = await _google.fetchFileName(link)

        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.EXTERNAL_EMBED,
          externalEmbed: {
            type: embedType,
            name: name || 'Youtube',
            url: link,
          },
          pageId,
          position,
          name: name || 'Youtube',
        })
      } else if (isVimeo) {
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.EXTERNAL_EMBED,
          externalEmbed: {
            type: embedType,
            name: 'Vimeo',
            url: link,
          },
          pageId,
          position,
          name: 'Vimeo',
        })
      } else if (isMiro) {
        if (pages && space && project) {
          _createBlock.createWhiteboardBlock({
            type: IBlockTypes.EXTERNAL_EMBED,
            externalEmbed: {
              type: EmbedType.MIRO,
              name: 'Miro board',
              url: link,
            },
            pageId,
            position,
            name: name || 'Miro board',
          })
        }
      } else if (isGoogleDoc || isGoogleSlides || isGoogleSheets) {
        const name =
          (await _google.fetchFileName(link)) || isGoogleDoc
            ? 'Google Doc'
            : isGoogleSlides
            ? 'Google Slides'
            : isGoogleSheets
            ? 'Google Sheets'
            : 'Google file'

        if (pages && space && project) {
          _createBlock.createWhiteboardBlock({
            type: IBlockTypes.EXTERNAL_EMBED,
            externalEmbed: {
              type: _embed.getGoogleEmbedType(link),
              name,
              url: link,
            },
            pageId,
            position,
            name: name || 'Google file',
          })
        }
      } else if (isGoogleCalendar) {
        const fullLink = `https://calendar.google.com/calendar/embed?src=${link}`
        const name = (await _google.fetchFileName(link)) || 'Google Calendar'

        if (pages && space && project) {
          _createBlock.createWhiteboardBlock({
            type: IBlockTypes.EXTERNAL_EMBED,
            externalEmbed: {
              type: _embed.getGoogleEmbedType(link),
              name: name,
              url: fullLink,
            },
            pageId,
            position,
            name: name || 'Google file',
          })
        }
      } else if (isOffice) {
        const page = pages?.find((p) => p.embedUrl === link)

        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.EXTERNAL_EMBED,
          externalEmbed: {
            type: embedType,
            name: page?.text || name || 'Office 365',
            url: link,
          },
          pageId,
          position,
          name: page?.text || name || 'Office 365',
        })
      }
    } else {
      if (!space || !project || !pages) return

      _createBlock.createWhiteboardBlock({
        type: IBlockTypes.EXTERNAL_EMBED,
        externalEmbed: {
          type: EmbedType.GENERIC_LINK,
          name: checkIsAsana(link) ? 'Asana' : name || DEFAULT_GENERIC_LINK_NAME,
          url: link,
          image,
        },
        pageId,
        position,
        name: name || DEFAULT_GENERIC_LINK_NAME,
      })
    }
    setLoading(false)
    setInitial()
  }

  return {
    handleDocExternalLink,
    handleWbExternalLink,
    loading,
  }
}
