import { createContext, useState, useEffect, useContext } from 'react'
import { DefaultGenerics, OwnUserResponse, StreamChat, UserResponse } from 'stream-chat'
import { useAppSelector } from 'redux/hooks'
import { StreamChatGenerics } from 'interfaces/getstream/streamChatGenerics'
import { envVars } from 'config/env'

const getStreamKey = envVars.getStream.key || ''

export interface GetStreamContextValue {
  chatClient: StreamChat<StreamChatGenerics> | null
  error: Error | null
}

export interface GetStreamContextProviderProps {
  children?: React.ReactNode
}

const GetStreamContext = createContext<GetStreamContextValue>({
  chatClient: null,
  error: null,
})

/**
 * Connects user to GetStream feed and chat apis.
 */
export const GetStreamContextProvider = ({ children }: GetStreamContextProviderProps) => {
  const currentUser = useAppSelector((state) => state.global.user)

  const [chatClient, setChatClient] = useState<StreamChat<StreamChatGenerics> | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const disconnectUser = async (): Promise<void> => {
    if (!chatClient) return

    chatClient
      .disconnectUser()
      .then(() => {
        setChatClient(null)
      })
      .catch((error) => {
        setError(new Error(`Failed to disconnect from chat service. ${error.message}`))
      })
  }

  const connectChatClient = async (
    user: OwnUserResponse<DefaultGenerics> | UserResponse<DefaultGenerics>,
    chatToken: string,
    didUserConnectInterrupt: boolean,
  ): Promise<void> => {
    const client = new StreamChat<StreamChatGenerics>(getStreamKey)

    const connect = client
      .connectUser(user, chatToken)
      .then(() => {
        if (!didUserConnectInterrupt) setChatClient(client)
      })
      .catch((error) => {
        setError(new Error(`Couldn't connect to chat service. ${error.message}`))
      })

    return connect
  }

  useEffect(() => {
    if (!currentUser) {
      if (chatClient) disconnectUser()
      return
    }

    if (!currentUser.uuid) {
      setError(
        new Error(
          'Missing user ID while connecting to our chat service, please contact support or try again later',
        ),
      )
    }

    if (!currentUser.chatToken) {
      setError(
        new Error(
          'Missing chat token while connecting to our chat service, please contact support or try again later',
        ),
      )
    }

    // Under some circumstances, a "connectUser" operation might be interrupted
    // (fast user switching, react strict-mode in dev). With this flag, we control
    // whether a "disconnectUser" operation has been requested before we
    // provide a new StreamChat instance to the consumers of this hook.
    let didUserConnectInterrupt = false

    const user: OwnUserResponse<DefaultGenerics> | UserResponse<DefaultGenerics> = {
      id: currentUser.uuid ?? '',
      name: `${currentUser.firstName} ${currentUser.lastName}`,
      image: `${envVars.api.s3Butcket}${currentUser.photo}`,
    }

    const chatClientConnection = connectChatClient(
      user,
      currentUser.chatToken || '',
      didUserConnectInterrupt,
    )

    return () => {
      didUserConnectInterrupt = true
      // There might be a pending "connectUser" operation, wait for it to finish
      // before executing the "disconnectUser" in order to prevent race-conditions.
      chatClientConnection.then(() => {
        disconnectUser()
        setChatClient(null)
      })
    }
  }, [currentUser?.uuid, currentUser?.chatToken])

  return (
    <GetStreamContext.Provider value={{ chatClient, error }}>{children}</GetStreamContext.Provider>
  )
}

export const useGetStreamContext = () => useContext(GetStreamContext)
