import styled from 'styled-components'

export const DrawerDropIndicator = styled.div`
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  position: absolute;
  z-index: 101 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primary-color);
`

export const DrawerDropIndicatorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & > p {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
  }
`

export const DrawerDropIndicatorIcon = styled.div`
  opacity: 0.3;
`
