import CustomP from 'components/atoms/CustomP/CustomP'
import { MessageText } from 'stream-chat-react'
import { ExtendedStreamMessage } from 'interfaces/chat'

interface IProps {
  message: ExtendedStreamMessage
}

const MessageContainer = ({ message }: IProps) => {
  return <MessageText message={message} />
}

export default MessageContainer
