import { useTransformer, useStage, useAnchors } from '_features/canvas'
import { KonvaEventObject } from 'konva/lib/Node'
import { useFullEmbed } from '_entities/embed'
import { useAppDispatch } from 'redux/hooks'
import { setSelectedBlock } from 'redux/reducers/whiteboardReducer'
import { setIsEditing } from 'redux/reducers/pageReducer'
import { GroupNames, useFrame } from '_entities/whiteboard'
import { useWhiteboardEmbed } from '_entities/embed/InternalEmbed'

export const useSelection = () => {
  const _stage = useStage()
  const _transformer = useTransformer()
  const _anchors = useAnchors()
  const _fullEmbed = useFullEmbed()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _frame = useFrame()

  const dispatch = useAppDispatch()
  const getResizeEnabled = (pageId: string) =>
    !_fullEmbed.findIfFullEmbedAmongstMutlipleNodes(pageId)

  const setResizeEnabled = (pageId: string) => {
    const transformer = _transformer.getTransformer(pageId)
    if (transformer) {
      transformer.resizeEnabled(getResizeEnabled(pageId))
    }
  }
  const handleSelection = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const stageId = e.target.getStage()?.attrs.id
    const docId = _stage.getDocIdFromStageId(stageId)
    const transformer = _transformer.getTransformer(docId)
    if (transformer) {
      if (
        !e.target.parent?.hasName(GroupNames.BLOCK) &&
        !e.target.parent?.hasName(GroupNames.FRAME)
      ) {
        return
      }
      if (e.target.parent.hasName(GroupNames.FRAME) && _whiteboardEmbed.isDocEmbed(docId)) return

      // do we pressed shift or ctrl?
      const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey
      const isSelected = transformer.nodes().indexOf(e.target.parent) >= 0

      if (!metaPressed && !isSelected) {
        if (
          e.target.parent &&
          e.target.parent.hasName(GroupNames.FRAME) &&
          !_whiteboardEmbed.isDocEmbed(docId)
        ) {
          // Check if there are any blocks intersecting with the frame and select them as well as the frame
          const nodes = _frame.getBlocksIntersectingWithFrame(docId, e.target.parent)
          transformer.nodes([e.target.parent, ...nodes])
        } else {
          transformer.nodes([e.target.parent])
        }
        setResizeEnabled(docId)
        _anchors.setAnchors(docId)
        checkRatio(docId)
      } else if (metaPressed && isSelected) {
        // if we pressed keys and node was selected
        // we need to remove it from selection:
        const nodes = transformer.nodes().slice() // use slice to have new copy of array
        // remove node from array
        nodes.splice(nodes.indexOf(e.target.parent), 1)
        transformer.nodes(nodes)
        setResizeEnabled(docId)
        _anchors.setAnchors(docId)
        checkRatio(docId)
      } else if (metaPressed && !isSelected) {
        // add the node into selection
        const nodes = transformer.nodes().concat([e.target.parent])
        transformer.nodes(nodes)
        setResizeEnabled(docId)
        _anchors.setAnchors(docId)
        checkRatio(docId)
      }
    }
  }

  const shouldKeepRatio = (pageId: string) => {
    if (_fullEmbed.findIfOnlyFullEmbedInTransformer(pageId)) return false
    else return true
  }

  const checkRatio = (pageId: string) => {
    const transformer = _transformer.getTransformer(pageId)
    if (!transformer) return
    transformer.keepRatio(shouldKeepRatio(pageId))
  }

  const removeSelectedBlock = () => {
    dispatch(setSelectedBlock(undefined))
    dispatch(setIsEditing(false))
  }

  return {
    getResizeEnabled,
    setResizeEnabled,
    handleSelection,
    checkRatio,
    removeSelectedBlock,
  }
}
