import { MessageInput, MessageList, Window } from 'stream-chat-react'
import CustomMessage from '../ChatCustomMessage/CustomMessage'
import DeleteMessageModal from './DeleteMessageModal/DeleteMessageModal'
import useWindow from './useWindow'

const WindowContainer = () => {
  const {
    customActions,
    handleSendMessage,
    open,
    messageToDelete,
    handleDeleteMessage,
    handleDeleteThread,
    handleCloseModal,
    isSingleMessageDelete,
    selectedMessages,
    selectedChannel,
    channelOptions,
    threadIsOpen,
    referencedBlock,
    referencedPage,
  } = useWindow()

  return (
    <Window>
      {selectedChannel === channelOptions?.[0].cid ? (
        <MessageList
          Message={CustomMessage}
          messageActions={['reply', 'react']}
          customMessageActions={customActions}
          closeReactionSelectorOnClick
          hideDeletedMessages
        />
      ) : (
        <MessageList
          Message={CustomMessage}
          messageActions={['reply', 'react']}
          customMessageActions={customActions}
          closeReactionSelectorOnClick
          messages={selectedMessages}
          hideDeletedMessages
        />
      )}
      {!threadIsOpen && <MessageInput overrideSubmitHandler={handleSendMessage} />}

      <DeleteMessageModal
        open={open}
        messageToDelete={messageToDelete}
        handleDeleteMessage={handleDeleteMessage}
        handleDeleteThread={handleDeleteThread}
        handleCloseModal={handleCloseModal}
        isSingleMessageDelete={isSingleMessageDelete}
        referencedPage={referencedPage}
        referencedBlock={referencedBlock}
      />
    </Window>
  )
}

export default WindowContainer
