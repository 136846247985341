import { RelationshipType } from '_features/relationship'
import { type block, IBlockTypes, GridBlockWidth } from './types'
import { getBlockGridWidth, getBlockPageId } from './getters'
import { getBlocks } from 'shared/shareDb'

export const isBlockInstance = (block: block) => {
  return (
    block?.meta?.relatedObjects?.some(
      (object) => object?.typeOfRelation === RelationshipType.INSTANCE,
    ) || block?.meta?.relationshipType === RelationshipType.INSTANCE
  )
}

export const isBlockMirror = (block: block) => {
  return (
    block?.meta?.relatedObjects?.some(
      (object) => object?.typeOfRelation === RelationshipType.MIRROR,
    ) || block?.meta?.relationshipType === RelationshipType.MIRROR
  )
}

export const isInstance = (block: block) =>
  (checkHasRelatedObjects(block) && isBlockInstance(block)) ||
  (isBlockInstance(block) && hasOriginalObject(block))

export const isMirror = (block: block) =>
  (checkHasRelatedObjects(block) && isBlockMirror(block)) ||
  (hasOriginalObject(block) && isBlockMirror(block))

export const isBlockNumberedList = (block: block) => block.data.tag === IBlockTypes.NUMBERED_LIST

export const checkHasRelatedObjects = (block: block) => {
  return block.meta?.relatedObjects && block.meta?.relatedObjects.length > 0
}

export const hasOriginalObject = (block: block) => {
  return !!block?.meta?.originalObject
}

export const isNotAnyKindOfList = (block: block) =>
  block.data.tag !== IBlockTypes.LIST &&
  block.data.tag !== IBlockTypes.CHECKLIST &&
  block.data.tag !== IBlockTypes.NUMBERED_LIST

export const isSomeOfLists = (block: block) =>
  block.data.tag === IBlockTypes.LIST ||
  block.data.tag === IBlockTypes.CHECKLIST ||
  block.data.tag === IBlockTypes.NUMBERED_LIST

export const checkIsBlockMedia = (block: block) => {
  return block?.data.tag === IBlockTypes.IMAGE || block?.data.tag === IBlockTypes.FRAME
}

export const isBlockGenericLink = (block: block) => block.data.tag === IBlockTypes.GENERIC_LINK

export const checkIsFirstBlockFullBleed = (block: block) => {
  const blocks = getBlocks(getBlockPageId(block))
  if (!blocks) return false
  return getBlockGridWidth(block) === GridBlockWidth.FULL_BLEED && blocks[0]._id === block._id
}
