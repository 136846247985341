import { Group, Image } from 'react-konva'
import { Html, useImage } from 'react-konva-utils'
import { type block, WhiteboardBlockProps, getHtmlPortalId, getBlockPageId } from '_entities/block'
import { useEmbed, IS_FULL_EMBED } from '_entities/embed'
import React, { useEffect, useRef } from 'react'
import { ReactReduxContext, Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { GroupNames, useKonvaNode } from '_entities/whiteboard'
import { useAppSelector } from 'redux/hooks'
import { ITools } from 'interfaces/whiteboard'
import { useTransformer, useStage } from '_features/canvas'
import { updateElementSize, updateElementZIndex } from 'shared/lib'

interface Props extends WhiteboardBlockProps {
  shouldEnable: boolean
  children?: React.ReactNode
  onClick?: () => void
  onDblClick?: () => void
  groupAttrs?: object
  htmlStyle?: React.CSSProperties
  isShadow?: boolean
  isCallout?: boolean
  strokeEnabled?: boolean
  stroke?: string
  strokeWidth?: number
  borderRadius?: number
}

export const getRectId = (block: block) => {
  return `wb-resizable-rect-${block._id}`
}

const Resizable = (props: Props) => {
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const blockRef = useRef<block>(props.block)
  const _konvaNode = useKonvaNode()
  const _embed = useEmbed()
  const _transformer = useTransformer()
  const _stage = useStage()

  useEffect(() => {
    blockRef.current = props.block
    // We need to set background image node manually here
    // because React will not update the image node size
    // if the new size is the same as the old size.
    // This happens when the block has been resized but the
    // snap position of one or both axis is the same as the old snap position.
    updateElementZIndex(getHtmlPortalId(props.block), 1)
    setImageNodeSizeFromBlock()
    setElementSize()
    _transformer.forceUpdateTransformer(getBlockPageId(blockRef.current))
  }, [props.block])

  const [image] = useImage('')

  const setElementSize = () => {
    const stage = _stage.getStage(getBlockPageId(blockRef.current))
    const group = _konvaNode.getGroupNode(blockRef.current)
    const image = getImageNode()
    if (!image || !group || !stage) return
    const newSize = { width: image.width(), height: image.height() }
    updateElementSize(getHtmlPortalId(props.block), newSize)
  }

  const resetGroupScale = () => {
    const group = _konvaNode.getGroupNode(blockRef.current)
    if (!group) return
    group.scaleX(1)
    group.scaleY(1)
  }

  const getImageNode = () => {
    return _konvaNode.findById(getBlockPageId(blockRef.current), getRectId(blockRef.current))
  }

  const setImageNodeSizeFromBlock = () => {
    const size = _embed.getSize(blockRef.current)
    const image = getImageNode()
    if (!image) return
    image.width(size.width)
    image.height(size.height)
  }

  const setImageNodeSizeOnTransform = () => {
    const image = getImageNode()
    const group = _konvaNode.getGroupNode(blockRef.current)
    if (!image || !group) return
    image.width(image.width() * group.scaleX())
    image.height(image.height() * group.scaleY())
  }

  const onTransformEnd = () => {
    setElementSize()
    resetGroupScale()
  }

  const onTransform = () => {
    setElementSize()
    setImageNodeSizeOnTransform()
    resetGroupScale()
  }

  return (
    <Group
      x={props.block.data.x}
      y={props.block.data.y}
      scaleX={props.block.data.scaleX}
      scaleY={props.block.data.scaleY}
      id={props.block._id}
      name={GroupNames.BLOCK}
      draggable={!props.isPreview && tool !== ITools.HAND}
      blockType={props.block.data.tag}
      {...{ [IS_FULL_EMBED]: true }}
      {...props.groupAttrs}
      onClick={props.onClick}
      onDblClick={props.onDblClick}
      onTransform={onTransform}
      onTransformEnd={onTransformEnd}
    >
      <ReactReduxContext.Consumer>
        {({ store }) => (
          <Html
            divProps={{
              id: getHtmlPortalId(props.block),
              style: {
                pointerEvents: props.shouldEnable ? 'auto' : 'none',
                zIndex: 1,
                ...props.htmlStyle,
              },
            }}
          >
            <Provider store={store}>
              <Router>{props.children}</Router>
            </Provider>
          </Html>
        )}
      </ReactReduxContext.Consumer>

      <Image
        image={image}
        id={getRectId(props.block)}
        strokeEnabled={props.strokeEnabled}
        stroke={props.stroke}
        strokeWidth={props.strokeWidth}
        cornerRadius={props.borderRadius}
      />
    </Group>
  )
}

export default Resizable
