import styled from 'styled-components'
import { Modal } from 'react-bootstrap'

export const ChangeSpaceLogoModalStyled = styled(Modal)`
  /* className='mx-3 py-2 border-0' */
  & .modal-header {
    margin-left: 0.7em !important;
    margin-right: 0.7em !important;
    padding-top: 0.7em !important;
    padding-bottom: 0.7em !important;
    border-bottom: none !important;
  }
  & .modal-body {
    text-align: center;
    display: flex;
    justify-content: center;
  }
  /* className='border-0 mx-3 d-flex justify-content-end' */
  & .modal-footer {
    border: none;
    display: flex;
    justify-content: flex-end;
    margin-left: 0.7em !important;
    margin-right: 0.7em !important;
  }
`
export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  padding: 35px 12px;
`
export const ModalInnerContainer = styled.div``
export const SubtitleText = styled.div`
  text-align: center;
  color: #aeb0b4;
  padding: 0px 24px 5px 24px;
  margin-left: 0.7em !important;
  margin-right: 0.7em !important;
  font-size: 14px;
`

export const Button = styled.button`
  color: white;
  background-color: var(--everyday-blue);
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  border: none;
  font-weight: 600;
  font-size: 14px;

  &:hover {
    opacity: 0.8;
  }
`
