import { customEmojis } from '../ui/customEmojis'

export const getCustomEmojiById = (id: string) => {
  for (const category of customEmojis) {
    for (const emoji of category.emojis) {
      if (emoji.id === id) {
        return emoji
      }
    }
  }
  return undefined // Emoji with the given id not found
}

export const getCustomEmojiSrc = (id: string) => {
  const emoji = getCustomEmojiById(id)
  return emoji?.skins?.[0].src
}
