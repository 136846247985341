import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import { SIDEBAR_TOGGLE_DURATION } from '_entities/project'

type Props = {
  mainContentWidth?: string
  leftMinimize?: boolean
  rightMinimize?: boolean
  chatIsOpen?: boolean
  taskSliderIsOpen?: boolean
  padding?: string
}

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  height: 100vh;
  margin: 0 !important;
  overflow: hidden;
  justify-content: space-between;
`
export const LeftSidebarContainer = styled.div<Props>`
  width: 312px;
  transition: all 0.3s ease-in-out;
  position: ${isMobile ? 'absolute' : 'relative'};
  margin-left: ${(props) => (props.leftMinimize ? '-312px' : '0')};
  left: 0;
`
export const MainContentContainer = styled.div<Props>`
  transition: all ${SIDEBAR_TOGGLE_DURATION / 1000}s ease-in-out;
  padding-right: ${(props) =>
    (props.chatIsOpen || props.taskSliderIsOpen) && !isMobile ? '200px' : '0'};
  overflow: hidden;
  flex: 1;
  width: 100%;
  height: 100%;
`

export const RightSidebarContainer = styled.div<Props>`
  width: 312px;
  margin-right: ${(props) => (props.rightMinimize ? '-312px' : '0')};
  transition: all ${SIDEBAR_TOGGLE_DURATION / 1000}s ease-in-out;
  position: ${isMobile ? 'absolute' : 'relative'};
  right: 0;
  display: flex;
`
