import React, { useEffect } from 'react'
import { type block } from '_entities/block'
import { RowDropdownTitle } from '../styles'
import FontFamilyDropdown from './Dropdowns/FontFamilyDropdown'
import { icons } from 'shared/icons'
import { EditWrapper } from './styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { useConversionMenu } from '_features/block'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setFontFamily, setFontSize } from 'redux/reducers/pageReducer'
import { isArray } from 'lodash'
import { StyledButton, FontSizeSelector } from '_features/text'
import { getColor } from 'shared/colors'
import { getEditor } from 'shared/lib'
import { LineHeightEdit } from '_features/text/ui/LineHeightEdit'

export type BlockTypeAndEditPencilProps = {
  block: block
}

const BlockEdit = (props: BlockTypeAndEditPencilProps) => {
  const fontFamily = useAppSelector((state) => state.page.fontFamily)
  const _conversionMenu = useConversionMenu()
  const dispatch = useAppDispatch()

  useEffect(() => {
    const editor = getEditor(props.block._id)
    const format = editor?.getFormat(0, editor.getLength())
    const sizeFormat = format?.size
    const fontFormat = format?.font

    if (isArray(sizeFormat)) {
      dispatch(setFontSize(undefined))
    } else {
      dispatch(setFontSize(sizeFormat ? parseInt(sizeFormat?.replace('px', '')) : 18))
    }

    if (isArray(fontFormat)) {
      dispatch(setFontFamily(undefined))
    } else {
      dispatch(setFontFamily(fontFormat))
    }
  }, [props.block])

  return (
    <>
      {props.block?.meta?.relationshipType !== 'mirror' && (
        <>
          {/* Block type selector */}
          <RadixDropdown
            trigger={
              <StyledButton>
                <Wrapper
                  display='flex'
                  alignItems='center'
                  gap='8px'
                  color={getColor('--primary-text')}
                >
                  {_conversionMenu.getCurrentIcon(props.block) || null}{' '}
                  {_conversionMenu.getCurrentLabel(props.block) || 'Text'}
                </Wrapper>
              </StyledButton>
            }
          >
            {_conversionMenu.textOptions.map((option) => (
              <DropdownMenuItem
                onSelect={() => {
                  _conversionMenu.textConverter(props.block, option.value)
                }}
                key={`text-conversion-option-${option.label}-block-id-${props.block._id}`}
              >
                {option.icon} {option.label}
              </DropdownMenuItem>
            ))}
          </RadixDropdown>

          {/* Font family selector */}
          <EditWrapper>
            <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
              Font family
            </CustomP>
            <RadixDropdown
              trigger={
                <StyledButton>
                  <RowDropdownTitle>
                    {fontFamily
                      ? `${fontFamily.charAt(0).toUpperCase()}${fontFamily.slice(1)}`
                      : 'Arial'}
                  </RowDropdownTitle>
                  <icons.caretDown />
                </StyledButton>
              }
            >
              <FontFamilyDropdown {...{ block: props.block }} />
            </RadixDropdown>
          </EditWrapper>

          {/* Font size selector */}
          <FontSizeSelector block={props.block} />

          {/* Line height selector */}
          <LineHeightEdit block={props.block} />
        </>
      )}
    </>
  )
}

export default BlockEdit
