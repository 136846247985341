import { StyledThreadHeader, StyledThreadHeaderText } from './styles'
import { ReactComponent as MenuCloseIcon } from 'assets/images/close.svg'

const CustomThreadHeader = (props: any) => {
  return (
    <StyledThreadHeader>
      <StyledThreadHeaderText>Thread</StyledThreadHeaderText>
      <MenuCloseIcon onClick={() => props.closeThread()} />
    </StyledThreadHeader>
  )
}

export default CustomThreadHeader
