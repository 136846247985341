interface Props {
  id?: string
  url?: string
}
export const WebView = (props: Props) => {
  return (
    <webview
      style={{ width: '100%', height: '100%', zIndex: 0, position: 'absolute' }}
      id={props.id}
      src={props.url}
    />
  )
}
