import Konva from 'konva'
import { IPaletteColor } from 'interfaces/settings'
import convertHexToRGBA from 'utils/convertToRgba'
import { getColor } from 'shared/colors'
import { useKonvaNode, useLayer, getWhiteboardContainer } from '_entities/whiteboard'
import { KonvaEventObject } from 'konva/lib/Node'
import { Position, Size } from 'interfaces/whiteboard'
import { useTransformer, useStage } from '_features/canvas'
import { useEvent } from '_features/canvas/event'
import { GAP_SIZE, FULL_SIZE, STEP_SIZE } from '../lib/getSizeFromGridLength'
import { useWhiteboardEmbed } from '_entities/embed'

export const STROKE_WIDTH = 1
export const DASH_SIZE = 12
export const DASH_OFFSET = GAP_SIZE + DASH_SIZE / 2
export const INTERSECTION_OFFSET = GAP_SIZE
export const DASH = [DASH_SIZE, FULL_SIZE - DASH_SIZE]

export const getGridColor = (color?: IPaletteColor) =>
  color ? convertHexToRGBA(color.color, color.opacity) : getColor('--whiteboard-dots')

export const getShadowId = (pageId: string) => `shadow-${pageId}`

export const useGrid = () => {
  const _stage = useStage()
  const _layer = useLayer()
  const _konvaNode = useKonvaNode()
  const _transformer = useTransformer()
  const _event = useEvent()
  const _whiteboardEmbed = useWhiteboardEmbed()

  const getSizeFromGridLength = (gridLength: number) => {
    return gridLength * FULL_SIZE - GAP_SIZE
  }

  const removeLines = (pageId: string) => {
    const gridLayer = _layer.getGridLayer(pageId)
    if (gridLayer) {
      gridLayer.clear()
      gridLayer.destroyChildren()
      gridLayer.clipWidth(0) // clear any clipping
    }
  }

  // =========== Grid layer ===========

  const getZoomStep = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (stage) {
      const oldScale = stage.scaleX()
      // 0.4 is the zoom out scale level where the
      // grid needs to recalculate with new width and stroke width
      if (oldScale > 0.2 && oldScale < 0.4) return 2
      if (oldScale < 0.2) return 4
      else return 1
    } else return 1
  }

  const getStageRect = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (!stage) return
    return {
      x1: 0,
      y1: 0,
      x2: stage.width(),
      y2: stage.height(),
      offset: {
        x: _stage.unScale(pageId, stage.x()),
        y: _stage.unScale(pageId, stage.y()),
      },
    }
  }

  const getViewRect = (pageId: string) => {
    const whiteboardContainer = getWhiteboardContainer(pageId)
    const stageRect = getStageRect(pageId)
    if (!stageRect) return
    return {
      x1: -stageRect.offset.x,
      y1: -stageRect.offset.y,
      x2: _stage.unScale(pageId, whiteboardContainer?.offsetWidth ?? 0) - stageRect.offset.x,
      y2: _stage.unScale(pageId, whiteboardContainer?.offsetHeight ?? 0) - stageRect.offset.y,
    }
  }

  const getFullOffset = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (!stage) return
    return {
      x: Math.ceil(_stage.unScale(pageId, stage.x()) / FULL_SIZE) * FULL_SIZE,
      y: Math.ceil(_stage.unScale(pageId, stage.y()) / FULL_SIZE) * FULL_SIZE,
    }
  }

  const getStepOffset = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (!stage) return
    return {
      x: Math.ceil(_stage.unScale(pageId, stage.x()) / STEP_SIZE) * STEP_SIZE,
      y: Math.ceil(_stage.unScale(pageId, stage.y()) / STEP_SIZE) * STEP_SIZE,
    }
  }

  const getFullRect = (pageId: string) => {
    const fullOffset = getFullOffset(pageId)
    const whiteboardContainer = getWhiteboardContainer(pageId)

    if (!fullOffset) return
    return {
      x1: -fullOffset.x,
      y1: -fullOffset.y,
      x2: _stage.unScale(pageId, whiteboardContainer?.offsetWidth ?? 0) - fullOffset.x + FULL_SIZE,
      y2: _stage.unScale(pageId, whiteboardContainer?.offsetHeight ?? 0) - fullOffset.y + FULL_SIZE,
    }
  }

  const getStepRect = (pageId: string) => {
    const stepOffset = getStepOffset(pageId)
    const whiteboardContainer = getWhiteboardContainer(pageId)
    if (!stepOffset) return
    return {
      x1: -stepOffset.x,
      y1: -stepOffset.y,
      x2: _stage.unScale(pageId, whiteboardContainer?.offsetWidth ?? 0) - stepOffset.x + STEP_SIZE,
      y2: _stage.unScale(pageId, whiteboardContainer?.offsetHeight ?? 0) - stepOffset.y + STEP_SIZE,
    }
  }

  const getFullTotal = (pageId: string) => {
    const stageRect = getStageRect(pageId)
    if (!stageRect) return
    const fullRect = getFullRect(pageId)
    if (!fullRect) return
    return {
      x1: Math.min(stageRect.x1, fullRect.x1),
      y1: Math.min(stageRect.y1, fullRect.y1),
      x2: Math.max(stageRect.x2, fullRect.x2),
      y2: Math.max(stageRect.y2, fullRect.y2),
    }
  }

  const getStepTotal = (pageId: string) => {
    const stageRect = getStageRect(pageId)
    if (!stageRect) return
    const stepRect = getStepRect(pageId)
    if (!stepRect) return
    return {
      x1: Math.min(stageRect.x1, stepRect.x1),
      y1: Math.min(stageRect.y1, stepRect.y1),
      x2: Math.max(stageRect.x2, stepRect.x2),
      y2: Math.max(stageRect.y2, stepRect.y2),
    }
  }

  const clipGridLayer = (pageId: string) => {
    const gridLayer = _layer.getGridLayer(pageId)
    if (!gridLayer) return
    const viewRect = getViewRect(pageId)
    if (!viewRect) return
    // set clip function to stop leaking lines into non-viewable space.
    gridLayer.clip({
      x: viewRect.x1,
      y: viewRect.y1,
      width: viewRect.x2 - viewRect.x1,
      height: viewRect.y2 - viewRect.y1,
    })
  }

  const getFullGridSize = (pageId: string) => {
    const fullTotal = getFullTotal(pageId)
    if (!fullTotal) return
    return {
      xFullSize: fullTotal.x2 - fullTotal.x1,
      yFullSize: fullTotal.y2 - fullTotal.y1,
    }
  }

  const getStepGridSize = (pageId: string) => {
    const stepTotal = getStepTotal(pageId)
    if (!stepTotal) return
    return {
      xStepSize: stepTotal.x2 - stepTotal.x1,
      yStepSize: stepTotal.y2 - stepTotal.y1,
    }
  }

  const drawFullX = (pageId: string, color?: IPaletteColor) => {
    const gridLayer = _layer.getGridLayer(pageId)
    if (!gridLayer) return
    const fullGridSize = getFullGridSize(pageId)
    if (!fullGridSize) return
    const xFull = Math.round(fullGridSize.xFullSize / FULL_SIZE)
    const fullTotal = getFullTotal(pageId)
    if (!fullTotal) return
    for (let i = 0; i <= xFull; i++) {
      gridLayer.add(
        new Konva.Line({
          x: fullTotal.x1 - INTERSECTION_OFFSET + i * FULL_SIZE,
          y: fullTotal.y1,
          points: [0, 0, 0, fullGridSize.yFullSize],
          stroke: getGridColor(color),
          strokeWidth: STROKE_WIDTH,
          dash: DASH,
          dashOffset: DASH_OFFSET,
          lineCap: 'round',
          lineJoin: 'round',
        }),
      )
    }
  }

  const drawFullY = (pageId: string, color?: IPaletteColor) => {
    const gridLayer = _layer.getGridLayer(pageId)
    if (!gridLayer) return
    const fullGridSize = getFullGridSize(pageId)
    if (!fullGridSize) return
    const yFull = Math.round(fullGridSize.yFullSize / FULL_SIZE)
    const fullTotal = getFullTotal(pageId)
    if (!fullTotal) return
    for (let i = 0; i <= yFull; i++) {
      gridLayer.add(
        new Konva.Line({
          x: fullTotal.x1,
          y: fullTotal.y1 - INTERSECTION_OFFSET + i * FULL_SIZE,
          points: [0, 0, fullGridSize.xFullSize, 0],
          stroke: getGridColor(color),
          strokeWidth: STROKE_WIDTH,
          dash: DASH,
          dashOffset: DASH_OFFSET,
          lineCap: 'round',
          lineJoin: 'round',
        }),
      )
    }
  }

  const drawStepX = (pageId: string, color?: IPaletteColor) => {
    const gridLayer = _layer.getGridLayer(pageId)
    if (!gridLayer) return
    const stepGridSize = getStepGridSize(pageId)
    if (!stepGridSize) return
    const xStep = Math.round(stepGridSize.xStepSize / STEP_SIZE)
    const fullTotal = getFullTotal(pageId)
    if (!fullTotal) return
    for (let i = 0; i <= xStep; i++) {
      gridLayer.add(
        new Konva.Line({
          x: fullTotal.x1 + INTERSECTION_OFFSET + i * FULL_SIZE - GAP_SIZE,
          y: fullTotal.y1,
          points: [0, 0, 0, stepGridSize.yStepSize],
          stroke: getGridColor(color),
          strokeWidth: STROKE_WIDTH,
          dash: DASH,
          lineCap: 'round',
          lineJoin: 'round',
        }),
      )
    }
  }
  const drawStepY = (pageId: string, color?: IPaletteColor) => {
    const gridLayer = _layer.getGridLayer(pageId)
    if (!gridLayer) return
    const stepGridSize = getStepGridSize(pageId)
    if (!stepGridSize) return
    const yStep = Math.round(stepGridSize.yStepSize / STEP_SIZE)
    const fullTotal = getFullTotal(pageId)
    if (!fullTotal) return
    for (let i = 0; i <= yStep; i++) {
      gridLayer.add(
        new Konva.Line({
          x: fullTotal.x1,
          y: fullTotal.y1 + INTERSECTION_OFFSET + i * FULL_SIZE - GAP_SIZE,
          points: [0, 0, stepGridSize.xStepSize, 0],
          stroke: getGridColor(color),
          strokeWidth: STROKE_WIDTH,
          dash: DASH,
          lineCap: 'round',
          lineJoin: 'round',
        }),
      )
    }
  }

  const drawLines = (pageId: string, color?: IPaletteColor) => {
    const gridLayer = _layer.getGridLayer(pageId)
    if (gridLayer) {
      clipGridLayer(pageId)
      removeLines(pageId)
      drawFullX(pageId, color)
      drawFullY(pageId, color)
      /* drawStepX(pageId, color)
      drawStepY(pageId, color) */
      gridLayer.batchDraw()
    }
  }

  const createShadow = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (!stage) return
    const shadow = new Konva.Rect({
      x: 0,
      y: 0,
      width: FULL_SIZE * 6,
      height: FULL_SIZE * 3,
      fill: 'transparent',
      opacity: 0.6,
      stroke: '#a3a3a3',
      strokeWidth: 1,
      dash: [20, 2],
      id: getShadowId(pageId),
      strokeEnabled: false,
    })
    shadow.hide()
    const layer = _layer.getLayer(pageId)
    layer?.add(shadow)
  }

  const getShadow = (pageId: string) => {
    return _konvaNode.findById(pageId, getShadowId(pageId))
  }

  const getShadowSize = (pageId: string) => {
    const shadow = getShadow(pageId)
    if (!shadow) return
    return {
      width: shadow.width(),
      height: shadow.height(),
    }
  }

  const showShadow = (pageId: string) => {
    const shadow = getShadow(pageId)
    const shadowSize = _transformer.getScaledTransformerSize(pageId)
    if (!shadow || !shadowSize) return
    shadow?.width(shadowSize.width)
    shadow?.height(shadowSize.height)
    shadow?.show()
    shadow?.moveToTop()
    shadow?.getLayer()?.batchDraw()
  }

  const hideShadow = (pageId: string) => {
    const shadow = _konvaNode.findById(pageId, getShadowId(pageId))
    shadow?.hide()
    shadow?.getLayer()?.batchDraw()
  }

  const calculateGridCoordinates = (position: Position) => {
    return {
      x: Math.round(position.x / FULL_SIZE) * FULL_SIZE - GAP_SIZE,
      y: Math.round(position.y / FULL_SIZE) * FULL_SIZE - GAP_SIZE,
    }
  }

  const snapShadowPosition = (pageId: string, x: number, y: number) => {
    const shadow = _konvaNode.findById(pageId, getShadowId(pageId))
    const { x: snapX, y: snapY } = calculateGridCoordinates({ x, y })
    shadow?.position({ x: snapX, y: snapY })
    shadow?.getLayer()?.batchDraw()
  }

  const getSnapSize = (size: Size) => {
    const calculatedSize = calculateGridCoordinates({
      x: size.width + GAP_SIZE * 2,
      y: size.height + GAP_SIZE * 2,
    })

    return {
      width: calculatedSize.x,
      height: calculatedSize.y,
    }
  }

  const getSizeFromTransformer = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    const transformerSize = _transformer.getTransformer(pageId)?.size()
    if (!stage || !transformerSize) return
    const size = _stage.unScaleSize(pageId, transformerSize)
    if (!size) return
    return getSnapSize(size)
  }

  const snapShadowSize = (pageId: string) => {
    const shadow = _konvaNode.findById(pageId, getShadowId(pageId))
    const snapSize = getSizeFromTransformer(pageId)
    if (!snapSize) return
    shadow?.size(snapSize)
    shadow?.getLayer()?.batchDraw()
  }

  const moveShadowWithTransformer = (evt: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const pageId = _event.getPageIdFromEvent(evt)
    const position = _event.getCurrentScaledTransformerPosition(evt)
    if (!position) return
    snapShadowSize(pageId)
    // Uncomment this line to enable snapping shadow position
    // snapShadowPosition(pageId, position.x, position.y)
  }

  return {
    getSizeFromGridLength,
    drawLines,
    removeLines,
    createShadow,
    getShadow,
    getShadowSize,
    showShadow,
    hideShadow,
    snapShadowPosition,
    calculateGridCoordinates,
    getSnapSize,
    moveShadowWithTransformer,
  }
}
