import styled from 'styled-components'

type ResizeArrowPropsType = {
  isDisabled?: boolean
}

export const ResizeArrows = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 4px;
`

export const ResizeArrow = styled.div<ResizeArrowPropsType>`
  line-height: 0;
  padding: 4px;
  cursor: pointer;
  border-radius: 1000px;
  opacity: ${(props) => (props.isDisabled ? 0.5 : 1)};

  &:hover {
    background-color: var(--hover);
  }
`
