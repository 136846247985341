import { tasksApi } from '../api'
import { ITask } from 'interfaces/taskManager'
import { useAppSelector } from 'redux/hooks'

export const useDeleteTask = () => {
  // ** Network
  const [handleDeleteTask] = tasksApi.endpoints.deleteTask.useMutation()

  // ** Redux state
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const deleteTask = async (task: ITask) => {
    return (
      projectFile?.managers &&
      (await handleDeleteTask({
        taskId: task.id,
        managerId: projectFile.managers[0].id,
      }).unwrap())
    )
  }

  return {
    deleteTask,
  }
}
