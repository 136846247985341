import { FilterDropdownItem } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/useSidebar'
import { useAppSelector } from 'redux/hooks'
import { type block } from '_entities/block'
import { useEffect, useRef, useState } from 'react'
import { useMember } from '_entities/member/model/useMember'
import useAssignee from '_entities/block/model/useAssignee'

interface Props {
  block: block
}

const useAssignees = (props: Props) => {
  const { block } = props
  const { onAssigneesChange } = useAssignee()
  const [assignees, setAssignees] = useState<FilterDropdownItem[] | null>(null)
  const spaceMembers = useAppSelector((state) => state.space.currentSpace)?.spaceMembers || []
  const blockRef = useRef(block)

  useEffect(() => {
    blockRef.current = block
    fetchAssignees()
  }, [block])

  const options = useMember()

  const fetchAssignees = async () => {
    const assignees = [] as FilterDropdownItem[]
    spaceMembers.map((member) => {
      block?.meta?.assignees?.map((assignee) => {
        if (member.user.uuid === assignee) {
          const user = {
            id: member.user.uuid,
            label: `${member.user.firstName} ${member.user.lastName}`,
            value: member.user.uuid || '',
            photo: member.user.photo,
          }
          assignees.push(user)
        }
      })
    })

    setAssignees(assignees)
  }

  return {
    assignees,
    options,
    onAssigneesChange,
  }
}

export default useAssignees
