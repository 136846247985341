import { FontEditWrapper, StyledButton } from './styles'
import { getColor } from 'shared/colors'
import CustomP from 'components/atoms/CustomP/CustomP'
import { type block, getBlockLineHeight, getBlockPageId, getIndex } from '_entities/block'
import { icons } from 'shared/icons'
import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { LINE_HEIGHTS } from '_entities/text/lineHeight/model/line-height'
import { useJson1 } from 'shared/shareDb'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { JSONOp } from 'ot-json1'
import { SourceKeys } from 'interfaces/editor'
import { RowDropdownTitle } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/styles'

interface IProps {
  block: block
}

export const LineHeightEdit = (props: IProps) => {
  const _json1 = useJson1()
  const _submit = useSubmit()

  const handleChangeLineHeight = (option: number) => {
    const index = getIndex(props.block)
    let op: JSONOp

    if (!props.block.data.lineHeight) {
      op = _json1.getInsertKeyInDataKeyOp(index, ['lineHeight'], option)
    } else {
      op = _json1.getReplaceBlockDataKeyOp(index, ['lineHeight'], option)
    }
    _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
  }

  return (
    <FontEditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Line height
      </CustomP>

      <RadixDropdown
        trigger={
          <StyledButton>
            <RowDropdownTitle>{getBlockLineHeight(props.block)}</RowDropdownTitle>
            <icons.caretDown />
          </StyledButton>
        }
      >
        {[...LINE_HEIGHTS].map((option) => {
          return (
            <DropdownMenuItem
              key={option}
              onSelect={() => {
                handleChangeLineHeight(option)
              }}
            >
              {option}
            </DropdownMenuItem>
          )
        })}
      </RadixDropdown>
    </FontEditWrapper>
  )
}
