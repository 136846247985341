import { EditButtonCancel, EditButtonSave, EditContainer, EditInput } from '../styles'

interface IProps {
  handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleEditClose: () => void
  handleOnEditSave: () => void
  editValue?: string
}

const EditMessage = ({ handleOnChange, handleEditClose, handleOnEditSave, editValue }: IProps) => {
  return (
    <EditContainer>
      <EditInput value={editValue} onChange={handleOnChange} />
      <EditButtonCancel onClick={handleEditClose}>Cancel</EditButtonCancel>
      <EditButtonSave onClick={handleOnEditSave}>Save</EditButtonSave>
    </EditContainer>
  )
}

export default EditMessage
