import { IProjectFile, ISpace } from 'interfaces/space'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setFilteredProjectFiles, setProjectFiles } from 'redux/reducers/spaceReducer'
import { AxiosService } from 'services/axiosService/axiosService'
import { ProjectFileOrganisation } from 'interfaces/projectFile'
import { useUser } from 'services/user/useUser'
import { useSpace } from '_entities/space'
import { useToast } from 'services/helpers/useToast'
import useTransferProject from '_entities/project/model/useTransferProject'
import useProject from '_entities/project/model/useProject'
import ProjectDuplicatedToast from './ProjectDuplicatedToast'

interface Props {
  space?: ISpace
}

export const useSpaceProjects = ({ space }: Props) => {
  const [hoveredProjectFileId, setHoveredProjectFileid] = useState<IProjectFile>()
  const toast = useToast()

  const dispatch = useAppDispatch()
  const params = useParams()
  const projectId = params.id
  const navigate = useNavigate()
  const AxiosInstance = new AxiosService()
  const projectFiles = useAppSelector((state) => state.space.projectFiles)
  const filteredProjectFiles = useAppSelector((state) => state.space.filteredProjectFiles)
  const currentUser = useAppSelector((state) => state.global.user)
  const { getWorkspaces } = useUser()
  const { handleCopyProjectToAnotherSpace } = useTransferProject()
  const { deleteProjectFile } = useProject()
  const { moveProjectFileToSpace } = useSpace()

  const [layout, setLayout] = useState<string>(ProjectFileOrganisation.LIST)
  const [areIconsShown, setAreIconsShown] = useState<boolean>(false)
  const [isDeleteButtonShown, setIsDeleteButtonShown] = useState<boolean>(false)
  const [isProjectFileFavourited, setIsProjectFileFavourited] = useState<boolean>(false)
  const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<any[]>([])
  const [selectedSpace, setSelectedSpace] = useState<any>(null)
  const [selectedProject, setSelectedProject] = useState<any>(null)
  const [isRenameModalShown, setIsRenameModalShown] = useState<string | null>(null)
  const [projectName, setProjectName] = useState<string>('')

  const [isUserTypingInInput, setIsUserTypingInInput] = useState<boolean>(false)

  const getAndDispatchSpaceProjectFiles = async (spaceId: string) => {
    const getProjectFilesResponse = await AxiosInstance.getSpaceProjectFiles(spaceId)
    if (getProjectFilesResponse) dispatch(setProjectFiles(getProjectFilesResponse))
  }

  const handleModalClose = () => {
    setMoveModalOpen(false)
    setSelectedSpace(null)
  }

  const handleModalShow = async (e: React.MouseEvent, project: IProjectFile) => {
    e.stopPropagation()
    const spaces = await getWorkspaces()
    setOptions([...spaces])
    setSelectedProject(project)
    setMoveModalOpen(true)
  }

  const handleSelectSpace = (e: any) => {
    setSelectedSpace(e)
  }

  const handleRenameModalOpen = (projectFile: IProjectFile) => {
    setIsRenameModalShown(projectFile.id.toString())
    setProjectName(projectFile.title)
  }

  const handleRenameModalClose = () => {
    setIsRenameModalShown(null)
  }

  const handleOnUndo = async (projectId: number, isSameSpace?: boolean, spaceId?: string) => {
    const response = await deleteProjectFile(projectId, spaceId || space?.id)

    if (response && space) {
      toast.success('Undo successful!')
      getAndDispatchSpaceProjectFiles(spaceId || space?.id)
    }
  }

  const handleRedirect = (spaceId: string, projectId: string) => {
    navigate(`/spaces/${spaceId}/project-file/${projectId}`)
  }

  const handleCopy = async () => {
    projectId &&
      handleCopyProjectToAnotherSpace(
        space?.id as string,
        projectId,
        selectedProject,
        selectedSpace,
        handleOnUndo,
        handleRedirect,
      )
    handleModalClose()
  }

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUserTypingInInput(!!e.target.value.length)

    dispatch(
      setFilteredProjectFiles(
        projectFiles.filter((projectFile) =>
          projectFile.title.toLowerCase().includes(e.target.value.toLowerCase()),
        ),
      ),
    )
  }

  const openProjectFile = async (projectFileId: number) => {
    space && navigate(`/spaces/${space.id}/project-file/${projectFileId}`)
  }

  const onSpaceProjectFileDotsClicked = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
    setIsDeleteButtonShown((prev) => !prev)
  }

  const onSpaceProjectFileStarClicked = (e: React.MouseEvent<HTMLImageElement>) => {
    e.stopPropagation()
  }

  const handleRenameProjectFile = async (projectFileId: string) => {
    const response =
      space?.id &&
      (await AxiosInstance.updateProjectFile({
        spaceId: space?.id,
        projectFileId,
        title: projectName,
      }))

    if (response) {
      handleRenameModalClose()
      if (space) {
        getAndDispatchSpaceProjectFiles(space.id)
      } else {
        toast.error('Could not rename project file, try again later!')
      }
    }
  }

  const handleCopyLink = (projectId: string) => {
    const link = window.location.href
    const splitLink = link.split('/')
    const root = splitLink[0] + '//' + splitLink[2]
    const generatedLink = `${root}/spaces/${space?.id}/project-file/${projectId}`
    navigator.clipboard.writeText(generatedLink)
    toast.success('Link copied to clipboard!')
  }

  const handleDeleteProject = async (projectFileId: number) => {
    await deleteProjectFile(projectFileId, space?.id as string)
    if (space) {
      getAndDispatchSpaceProjectFiles(space.id)
    } else {
      toast.error('Could not delete project file, try again later!')
    }
  }

  const handleDuplicateProject = async (projectFileId: number) => {
    toast.info('Duplicating project file...')
    const response =
      space?.id &&
      (await moveProjectFileToSpace(space.id, projectFileId.toString(), parseInt(space.id)))

    if (response) {
      const createdProjectId = response.data?.id

      const projectFileIdDuplicated = {
        projectFileId: createdProjectId,
        spaceId: space.id,
      }

      toast.customToast(
        () =>
          ProjectDuplicatedToast({
            onUndo: () =>
              handleOnUndo(
                parseInt(projectFileIdDuplicated.projectFileId),
                true,
                projectFileIdDuplicated.spaceId,
              ),
            onRedirect: () =>
              handleRedirect(
                projectFileIdDuplicated.spaceId,
                projectFileIdDuplicated.projectFileId,
              ),
          }),
        5000,
      )

      getAndDispatchSpaceProjectFiles(space.id)
    }
  }

  return {
    hoveredProjectFileId,
    setHoveredProjectFileid,
    handleFilter,
    openProjectFile,
    handleDeleteProject,
    projectFiles,
    filteredProjectFiles,
    layout,
    setLayout,
    areIconsShown,
    setAreIconsShown,
    isDeleteButtonShown,
    setIsDeleteButtonShown,
    isProjectFileFavourited,
    setIsProjectFileFavourited,
    isUserTypingInInput,
    currentUser,
    // Handlers
    onSpaceProjectFileDotsClicked,
    onSpaceProjectFileStarClicked,
    handleModalClose,
    handleModalShow,
    moveModalOpen,
    options,
    handleSelectSpace,
    selectedSpace,
    selectedProject,
    handleCopyProjectToAnotherSpace,
    handleRenameModalOpen,
    handleRenameModalClose,
    isRenameModalShown,
    handleRenameProjectFile,
    setProjectName,
    projectName,
    handleCopy,
    handleDuplicateProject,
    handleCopyLink,
  }
}
