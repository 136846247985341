import { block } from '_entities/block'
import {
  EditorImageBlockAttrs,
  EditorImageBlockAttrsData,
  getImageBlockElemAttr,
  removeImageBlockElemAttr,
  setImageBlockElemAttr,
} from '_features/editor'

export const getIsCropMode = (block: block) => {
  const isCropMode = getImageBlockElemAttr(block, EditorImageBlockAttrs.CROP)
  if (!isCropMode) return false
  return JSON.parse(isCropMode) as EditorImageBlockAttrsData[EditorImageBlockAttrs.CROP]
}

export const setIsCropMode = (block: block) => {
  setImageBlockElemAttr(block, EditorImageBlockAttrs.CROP, true)
}

export const removeIsCropMode = (block: block) => {
  removeImageBlockElemAttr(block, EditorImageBlockAttrs.CROP)
}
