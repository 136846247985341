import React from 'react'
import styled from 'styled-components'
import { SeparatorCss } from './styled'

const StyledSeparator = styled.div`
  ${SeparatorCss}
`

interface Props {
  children: React.ReactNode
}

const Separator = ({ children }: Props) => {
  return <StyledSeparator>{children}</StyledSeparator>
}

export default Separator
