import data from '@emoji-mart/data'
import { Emojis } from '../model/types'

export const getEmojisData = () => {
  return Object.keys((data as Emojis).emojis).map((key) => {
    return {
      ...(data as Emojis).emojis[key],
    }
  })
}
