import React, { useState } from 'react'
import {
  StyledMainContainer,
  BodyContainer,
  SidebarHead,
  SidebarBody,
  Sidebar,
  PagesBarWrapper,
  EditAddPlusIcon,
} from './styles'
import ProjectFileSidebarTopElement from 'components/organisms/ProjectFileSidebarTopElement/ProjectFileSidebarTopElement'
import SidebarItemTaskbarManager from 'components/molecules/SidebarItemTaskManager/SidebarItemTaskManager'
import { SidebarListDivider, SidebarListGrow } from 'components/molecules/SidebarList/styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { SidebarItemNewPage } from '_features/page/addPage'
import { Spinner } from 'react-bootstrap'
import PagesNavigation from '_widgets/LeftSidebar/ui/PagesNavigation/PagesNavigation'
import { icons } from 'shared/icons'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { FileSearch } from './FileSearch/FileSearch'

type Props = {
  myRole: string
  uploadLoading: boolean
}

export const LEFT_SIDEBAR_ID = 'left-sidebar'

export const LeftSidebar: React.FC<Props> = (props: Props) => {
  const [isSearchOpen, setIsSearchOpen] = useState(false)

  const handleSearchOpen = () => {
    setIsSearchOpen(true)
    setTimeout(() => {
      const inputElement = document.querySelector('.modal-content input') as HTMLInputElement

      if (inputElement) {
        inputElement.focus()
      }
    }, 100)
  }

  const handleSearchClose = () => {
    setIsSearchOpen(false)
  }

  return (
    <StyledMainContainer id={LEFT_SIDEBAR_ID}>
      <Sidebar>
        <SidebarHead>
          <ProjectFileSidebarTopElement myRole={props.myRole} />
        </SidebarHead>
        <SidebarBody>
          <BodyContainer>
            <SidebarListDivider margin='8px 0 0 0' />
            <SidebarItemTaskbarManager />
            <SidebarListDivider margin='0 0 12px 0' />
            <PagesBarWrapper>
              <Wrapper display='flex' gap='6px' alignItems='center'>
                <CustomP
                  marginLeft='14px'
                  fontWeight='500'
                  fontSize='14px'
                  color='var(--secondary-text)'
                >
                  Pages
                </CustomP>
                <Wrapper onClick={handleSearchOpen} cursor='pointer'>
                  <icons.magnifyingGlass />
                </Wrapper>
              </Wrapper>
              {props.uploadLoading ? (
                <Spinner size='sm' />
              ) : (
                <SidebarItemNewPage toggleButton={<EditAddPlusIcon />} />
              )}
            </PagesBarWrapper>
            <PagesNavigation />
            <SidebarListGrow />
          </BodyContainer>
        </SidebarBody>
      </Sidebar>
      <FileSearch show={isSearchOpen} handleCloseModal={handleSearchClose} />
    </StyledMainContainer>
  )
}
