import { RGBColor } from 'react-color'

export const colors = {
  primary: '#4a80ff',
  secondary: '#f5f5f5',
  gray: '#767f82',
  gray1: '#6E717D',
  gray2: '#aeb0b4',
  gray3: '#D8D8D8',
  gray4: '#F2F2F2',
  gray5: '#fafbfc',
  white: '#ffffff',
  'hover-blue': '#f4f7ff',
  'everyday-blue': '#4a80ff',
  'space-blue': '#00a6fb',
  'everyday-red': '#fa5a32',
  'everyday-green': '#00e094',
  'everyday-yellow': '#FDF4E0',
  orange: '#ccad47',
  'light-green': '#b3f6df',
  'main-black': '#403d3d',
}

export const getColor = (color: string): string => {
  const root = document.querySelector(':root')
  if (root) {
    const rs = getComputedStyle(root)
    return rs.getPropertyValue(color)
  } else return ''
}

export const getValuesFromRgba = (rgba: string) => {
  return rgba.match(/\d+/g)
}

export const getValuesWithoutAlphaFromRgba = (rgba: string) => {
  return rgba.match(/\d+/g)?.slice(0, 3)
}

export const getAlphaFromRgba = (rgba: string) => {
  return rgba.match(/\d+\.\d+/g)
}

export const getStringFromRGBColor = (rgb: RGBColor) => {
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${rgb.a})`
}

export const getRGBColorFromString = (rgba: string): RGBColor | undefined => {
  const values = getValuesFromRgba(rgba)
  if (!values) return
  let alpha
  if (values[4]) {
    alpha = parseFloat(values[4]) / 100
  }
  return {
    r: parseFloat(values[0]),
    g: parseFloat(values[1]),
    b: parseFloat(values[2]),
    a: alpha || 1,
  }
}

export const changeOpacity = (rgba: string, newOpacity: number): string => {
  // Extract the rgba components using a regular expression
  const rgbaRegex = /rgba?\((\d{1,3}),(\d{1,3}),(\d{1,3}),([01]?\.?\d*)\)/
  const matches = rgba.match(rgbaRegex)

  if (!matches) {
    return rgba
  }

  // Extract the individual components
  const r = matches[1]
  const g = matches[2]
  const b = matches[3]

  // Return the new rgba string with the updated opacity
  return `rgba(${r}, ${g}, ${b}, ${newOpacity})`
}

export const hexToRgba = (hex: string, opacity: number): string => {
  // Remove the hash at the start if it's there
  hex = hex.replace(/^#/, '')

  // Parse the hex string
  let bigint: number
  if (hex.length === 3) {
    bigint = parseInt(
      hex
        .split('')
        .map((hexChar) => hexChar + hexChar)
        .join(''),
      16,
    )
  } else if (hex.length === 6) {
    bigint = parseInt(hex, 16)
  } else {
    return ''
  }

  return `rgba(${(bigint >> 16) & 255}, ${(bigint >> 8) & 255}, ${bigint & 255}, ${opacity})`
}
