import { getColor } from 'shared/colors'
import { type block } from '_entities/block'
import { FontEditWrapper } from './styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { FontSizeChanger } from './FontSizeChanger'

interface Props {
  block: block
}

export const FontSizeSelector = (props: Props) => {
  return (
    <FontEditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Font size
      </CustomP>
      <FontSizeChanger block={props.block} />
    </FontEditWrapper>
  )
}
