import React from 'react'
import styled from 'styled-components'
import {
  StyledInputCss,
  StyledInputContainerCss,
  StyledLabelCss,
  EyeIcon,
  EyeClosedIcon,
  EyeIconRed,
  InputWrapper,
  CheckGreenIcon,
} from './styles'
import { useAuthInput } from 'services/customHooks/useAuthInput'
import Image from 'components/atoms/Image/Image'
import { isSafari } from 'shared/lib'

const StyledInputContainer = styled.div`
  ${StyledInputContainerCss}
`
const StyledInput = styled.input`
  ${StyledInputCss}
`

const StyledLabel = styled.label`
  ${StyledLabelCss}
`

type Props = {
  className?: string
  labelClassName?: string
  id?: string
  value?: string | number
  label?: string
  onFocus?: () => void
  onBlur?: () => void
  type?: string
  name?: string
  htmlFor?: string
  isRequired?: boolean
  placeholder?: string
  isChecked?: boolean
  min?: string | number
  max?: string | number
  step?: string | number
  size?: number
  onChangeFunction?: React.ChangeEventHandler<HTMLInputElement>
  onInvalidFunction?: React.FormEventHandler<HTMLInputElement>
  iconWidth?: string
  iconHeight?: string
  iconLeft?: string
  iconLeftError?: string
  moveIcon?: string
  paddingLeft?: string
  eyeIconTop?: string
  leftIconTop?: string
  isError?: boolean
  borderColour?: string
  placeholderFontSize?: string
  textFontSize?: string
  width?: string
  margin?: string
  padding?: string
  borderRadius?: string
  isFlexWrap?: boolean
  labelFontSize?: string
  valueEntered?: string
  focusBorder?: string
  inputInnerWidth?: string
}
const AuthInput: React.FC<Props> = ({
  className,
  id,
  value,
  label,
  onFocus,
  onBlur,
  type,
  name,
  onChangeFunction,
  isRequired,
  isChecked,
  placeholder,
  labelClassName,
  htmlFor,
  min,
  max,
  step,
  size,
  onInvalidFunction,
  iconHeight,
  iconWidth,
  iconLeft,
  iconLeftError,
  moveIcon,
  paddingLeft,
  eyeIconTop,
  leftIconTop,
  isError,
  borderColour,
  placeholderFontSize,
  textFontSize,
  width,
  margin,
  padding,
  borderRadius,
  labelFontSize,
  valueEntered,
  focusBorder,
  inputInnerWidth,
}) => {
  const { passwordShown, isPasswordFocused, setIsPasswordFocused, togglePassword } = useAuthInput()

  if (type !== 'password')
    return (
      <StyledInputContainer margin={margin} isFlexWrap={type !== 'checkbox'}>
        {label && type !== 'checkbox' && (
          <StyledLabel
            dangerouslySetInnerHTML={{ __html: label }}
            inputType={type}
            className={labelClassName}
            htmlFor={htmlFor}
            labelFontSize={labelFontSize}
          />
        )}

        <InputWrapper inputInnerWidth={inputInnerWidth}>
          {iconLeft && !isError && (
            <Image
              src={iconLeft}
              alt='Icon in Everyday input field'
              width={iconWidth ? iconWidth : '18px'}
              height={iconHeight ? iconHeight : '18px'}
              className='position-absolute'
              imageTop='12px'
            />
          )}

          {iconLeftError && (isError || className?.includes('is-invalid')) && (
            <Image
              src={iconLeftError}
              alt='Icon in Everyday input field'
              width={iconWidth ? iconWidth : '18px'}
              height={iconHeight ? iconHeight : '18px'}
              className={'position-absolute'}
              imageTop={'12px'}
            />
          )}

          {iconLeftError && !isError && !className?.includes('is-invalid') && valueEntered && (
            <CheckGreenIcon
              onClick={togglePassword}
              top={'20px'}
              right={
                isSafari() && isPasswordFocused
                  ? className?.includes('is-invalid')
                    ? '70px'
                    : '40px'
                  : className?.includes('is-invalid')
                  ? '40px'
                  : ''
              }
            />
          )}

          <StyledInput
            defaultChecked
            className={`fontAwesome ${
              type !== 'checkbox' && 'form-control form-control-md'
            } ${className}`}
            type={type}
            id={id}
            name={name}
            onFocus={onFocus}
            onBlur={onBlur}
            min={min}
            max={max}
            step={step}
            value={value}
            onChange={onChangeFunction}
            required={isRequired}
            placeholder={placeholder}
            size={size}
            paddingLeft={paddingLeft}
            borderColour={borderColour}
            placeholderFontSize={placeholderFontSize}
            textFontSize={textFontSize}
            width={width}
            padding={padding}
            borderRadius={borderRadius}
            focusBorder={focusBorder}
          />
        </InputWrapper>

        {label && type === 'checkbox' && (
          <StyledLabel
            dangerouslySetInnerHTML={{ __html: label }}
            inputType={type}
            className={labelClassName}
            htmlFor={htmlFor}
            labelFontSize={labelFontSize}
          />
        )}
      </StyledInputContainer>
    )
  else
    return (
      <div className='password-eye-wrapper'>
        <StyledInputContainer isFlexWrap>
          {label && (
            <StyledLabel
              dangerouslySetInnerHTML={{ __html: label }}
              inputType={type}
              className={labelClassName}
              htmlFor={htmlFor}
              labelFontSize={labelFontSize}
            />
          )}

          <InputWrapper inputInnerWidth={inputInnerWidth}>
            {iconLeft && !isError && !className?.includes('is-invalid') && (
              <Image
                src={iconLeft}
                alt='Icon in Everyday input field'
                width={iconWidth ? iconWidth : '18px'}
                height={iconHeight ? iconHeight : '18px'}
                className={`position-absolute ${moveIcon ? moveIcon : ''}`}
                imageTop='-37px'
              />
            )}

            {iconLeftError && (isError || className?.includes('is-invalid')) && (
              <Image
                src={iconLeftError}
                alt='Icon in Everyday input field'
                width={iconWidth ? iconWidth : '18px'}
                height={iconHeight ? iconHeight : '18px'}
                className='position-absolute'
                imageTop={'12px'}
              />
            )}

            <StyledInput
              defaultChecked /* Should always be in the checked state at the beggining for auth processes. */
              className={`fontAwesome ${'form-control form-control-md'} ${className}`}
              type={passwordShown ? 'text' : 'password'}
              id={id}
              name={name}
              onFocus={() => setIsPasswordFocused(true)}
              onBlur={() => setIsPasswordFocused(false)}
              min={min}
              max={max}
              step={step}
              value={value}
              onChange={onChangeFunction}
              required={isRequired}
              placeholder={placeholder}
              size={size}
              onInvalid={onInvalidFunction}
              paddingLeft={paddingLeft}
              focusBorder={focusBorder}
            />

            {isError || className?.includes('is-invalid') ? (
              passwordShown ? (
                <EyeIconRed
                  onClick={togglePassword}
                  top={'22px'}
                  right={
                    isSafari() && isPasswordFocused
                      ? className?.includes('is-invalid')
                        ? '70px'
                        : '40px'
                      : className?.includes('is-invalid')
                      ? '40px'
                      : ''
                  }
                />
              ) : (
                <EyeClosedIcon
                  onClick={togglePassword}
                  top={'22px'}
                  right={
                    isSafari() && isPasswordFocused
                      ? className?.includes('is-invalid')
                        ? '70px'
                        : '40px'
                      : className?.includes('is-invalid')
                      ? '40px'
                      : ''
                  }
                />
              )
            ) : passwordShown ? (
              <EyeIcon
                onClick={togglePassword}
                top={'22px'}
                right={
                  isSafari() && isPasswordFocused
                    ? className?.includes('is-invalid')
                      ? '70px'
                      : '40px'
                    : className?.includes('is-invalid')
                    ? '40px'
                    : ''
                }
              />
            ) : (
              <EyeClosedIcon
                onClick={togglePassword}
                top={'22px'}
                right={
                  isSafari() && isPasswordFocused
                    ? className?.includes('is-invalid')
                      ? '70px'
                      : '40px'
                    : className?.includes('is-invalid')
                    ? '40px'
                    : ''
                }
              />
            )}
          </InputWrapper>
        </StyledInputContainer>
      </div>
    )
}

export default AuthInput
