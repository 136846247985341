import { Tab } from 'react-bootstrap'
import { SidebarTabsContainer } from './styles'
import React, { ComponentProps } from 'react'

export type SidebarTabsProps = ComponentProps<typeof Tab.Container> & {
  fullWidth?: boolean
  fullHeight?: boolean
  grow?: boolean
}

const SidebarTabs = (props: SidebarTabsProps) => {
  const { children, fullWidth, fullHeight, grow, ...restProps } = props

  return (
    <Tab.Container {...restProps} defaultActiveKey='Files'>
      <SidebarTabsContainer {...{ fullWidth, fullHeight, grow }}>{children}</SidebarTabsContainer>
    </Tab.Container>
  )
}

export default SidebarTabs
export * from './styles'
