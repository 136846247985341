import React from 'react'
import { Link } from 'react-router-dom'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import Card from 'components/organisms/Card/Card'
import AuthWrapper from 'components/templates/AuthWrapper/AuthWrapper'
import CustomP from 'components/atoms/CustomP/CustomP'

const AllDone: React.FC = () => {
  return (
    <AuthWrapper
      text={'Sign up'}
      // eslint-disable-next-line
      question={'Not a member yet?'}
      elements={
        <Card
          titleText='All done!'
          secondaryText='You password has been reset.'
          isTitleLeft
          isFontSmaller
          subtext=' '
          shadow
          radius
          alignLeft
          cardElements={
            <Link className='text-primary' to='/signin'>
              <CustomButton
                className='btn w-50 d-inline-flex justify-content-center align-items-center'
                height='40px'
                fontWeight='600'
                background='transparent !important'
                borderradius='10px'
              >
                <CustomP className='mb-0'>Back to login</CustomP>
              </CustomButton>
            </Link>
          }
        />
      }
    />
  )
}

export default AllDone
