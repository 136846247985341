import { ReactNode } from 'react'
import { icons } from 'shared/icons'
import { OptionWrapper } from '_entities/profile/ui/Defaults/styles'
import { StyledIcon } from './styles'

interface Props {
  data: {
    id?: string | number | null | undefined
    value: string
    label: ReactNode
  }
  selectedOption: string | undefined
}

const LLMOption = (props: Props) => {
  return (
    <OptionWrapper>
      <StyledIcon>
        {(props.selectedOption === props.data.value ||
          props.selectedOption?.toString() === props.data.value) && <icons.check />}
      </StyledIcon>

      {props.data.label}
    </OptionWrapper>
  )
}

export default LLMOption
