import { StageLocalStorageAttrs } from '_features/canvas'

export enum LocalStorageKeys {
  INVITATION_LINK = 'invitationLink',
  STAGES = 'stages',
  TOKEN = 'token',
  GOOGLE_TOKEN = 'google_token',
  TIME_UNFOCUSED = 'time_unfocused',
}

export type StagesType = Record<string, StageLocalStorageAttrs>

export interface LocalStorageItems {
  [LocalStorageKeys.INVITATION_LINK]: string
  [LocalStorageKeys.STAGES]: StagesType
  [LocalStorageKeys.TOKEN]: string
}

const useLocalStorage = () => {
  const getItem = (key: string) => {
    return localStorage.getItem(key)
  }
  const setItem = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value))
  }

  const getParsedItem = (key: string) => {
    const itemString = getItem(key)
    if (itemString) return JSON.parse(itemString)
  }

  const removeItem = (key: string) => {
    localStorage.removeItem(key)
  }

  const clearLocalStorage = () => {
    localStorage.clear()
  }

  return {
    getItem,
    setItem,
    getParsedItem,
    removeItem,
    clearLocalStorage,
  }
}

export default useLocalStorage
