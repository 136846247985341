import { Group, Image } from 'react-konva'
import {
  useFrame,
  sizes,
  GroupNames,
  getFill,
  getName,
  gridSizes,
  getFrameHtmlId,
  getFrameKonvaRectId,
  getBackgroundImage,
  getThumbnail,
} from '_entities/whiteboard'
import { WhiteboardBlockProps, getBlockPageId, getHtmlPortalId } from '_entities/block'
import { Html, useImage } from 'react-konva-utils'
import { StyledFrame } from './styles'
import { useWhiteboardEmbed } from '_entities/embed'
import { useCallback, useState } from 'react'
import { useCreateBlock } from '_features/block'
import { getCrop, setFrameFit, usePresentation } from '_features/canvas'
import { Rect as RectType } from 'konva/lib/shapes/Rect'

const Frame = (props: WhiteboardBlockProps) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const _frame = useFrame()
  const _createBlock = useCreateBlock()
  const _presentation = usePresentation()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const [image] = useImage(getBackgroundImage(props.block) || '')
  const isDocEmbed = _whiteboardEmbed.isDocEmbed(getBlockPageId(props.block))
  const width = gridSizes[getName(props.block) || sizes.FRAME_ONE_ONE].width
  const height = gridSizes[getName(props.block) || sizes.FRAME_ONE_ONE].height

  const onRectRefLoad = useCallback((node: RectType | null) => {
    if (!node || loaded) return
    if (_createBlock.getIsJustCreatedOnCanvas(props.block) && isDocEmbed) {
      if (!node.parent) return
      setFrameFit(getBlockPageId(props.block), 'cover')
      _presentation.focusFrame({ node: node.parent })
    }
    _createBlock.removeIsJustCreatedOnCanvas(props.block)
    setLoaded(true)
  }, [])

  return (
    <Group
      x={props.block.data.x}
      y={props.block.data.y}
      scaleX={props.block.data.scaleX}
      scaleY={props.block.data.scaleY}
      draggable={!props.isPreview && !isDocEmbed}
      id={props.block._id}
      name={GroupNames.FRAME}
      blockType={props.block.data.tag}
      frameType={getName(props.block)}
    >
      <Html
        divProps={{
          id: getHtmlPortalId(props.block),
          style: {
            pointerEvents: 'none',
            zIndex: 0,
            opacity: 0,
          },
        }}
      >
        <StyledFrame
          id={getFrameHtmlId(props.block._id)}
          backgroundColor={getFill(props.block) || _frame.NO_FILL}
          width={width}
          height={height}
        />
      </Html>
      <Image
        data-thumbnail={getThumbnail(props.block)}
        image={image}
        crop={image ? getCrop(image, { width, height }) : undefined}
        id={getFrameKonvaRectId(props.block._id)}
        ref={onRectRefLoad}
        fill={getFill(props.block) || _frame.NO_FILL}
        width={width}
        height={height}
      />
    </Group>
  )
}

export default Frame
