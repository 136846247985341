import styled from 'styled-components'

import { ReactComponent as FacebookLogo } from 'assets/images/facebook.svg'
import { ReactComponent as GoogleLogo } from 'assets/images/mark_email_unread.svg'

export const ConnectionTabUserModalStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & hr {
    width: 90%;
    color: #d8d8d8 !important;
    border: 1px solid !important;
  }
`

export const StyledFacebookLogo = styled(FacebookLogo)`
  path {
    fill: var(--secondary-text);
  }
`

export const StyledGoogleLogo = styled(GoogleLogo)`
  path {
    fill: var(--secondary-text);
  }
`
