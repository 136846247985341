import Form from 'react-bootstrap/Form'
import styled from 'styled-components'

export const StyledLabel = styled(Form.Label)<{
  labelColor?: string
  fontSize?: string
  labelweight?: string
  labelMargin?: string
}>`
  color: ${(props) => props.labelColor};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.labelweight};
  margin: ${(props) => props.labelMargin};
`

export const StyledInput = styled(Form.Control)<{
  borderradius?: string
  backgroundColor?: string
  disabledBackgroundColor?: string
  width?: string
  bordercolor?: string
  fontSize?: string
  inputmargin?: string
  placeholdercolor?: string
  fontweight?: string
  color?: string
  height?: string
  maxwidth?: string
  linkexist?: boolean
  inputPadding?: string
}>`
  border-radius: ${(props) => props.borderradius} !important;
  border-color: ${(props) => props.bordercolor};
  font-size: ${(props) => props.fontSize};
  margin-top: ${(props) => props.inputmargintop};
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  margin: ${(props) => props.inputmargin};
  font-weight: ${(props) => props.fontweight};
  color: ${(props) => props.color};
  background: ${(props) => (props.backgroundColor ? props.backgroundColor : 'white')};
  max-width: ${(props) => props.maxwidth};
  overflow: hidden;
  text-overflow: ellipsis;
  border: ${(props) => (props.linkexist ? 'none' : '')};
  padding: ${(props) => props.inputPadding};
  outline: none;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${(props) => props.placeholdercolor};
  }

  :-ms-input-placeholder {
    color: ${(props) => props.placeholdercolor};
  }

  :disabled {
    background-color: ${(props) =>
      props.disabledBackgroundColor ? props.disabledBackgroundColor : 'white'};
    border: none;
  }

  :focus {
    box-shadow: none;
  }
`

export const StyledFormGroup = styled(Form.Group)<{
  width: string
  height?: string
  maxwidth?: string
}>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  background: white;
  max-width: ${(props) => props.maxwidth};
`
