import { useAppSelector } from 'redux/hooks'
import { usePage } from './usePage'
import { SummarizationCategory } from 'services/ai/useAi'
import { useState } from 'react'
import { useSummarization } from '_features/ai'
import { AxiosService } from 'services/axiosService/axiosService'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { useTransformer } from '_features/canvas'
import { useTextBlock } from '_entities/text'
import { useInternalEmbedBlock } from '_entities/embed'
import { usePagesTreeStructure } from '_features/page'

export const SummaryPageTitle = 'Analysis'

export const usePageSummarization = () => {
  const [isLoading, setIsLoading] = useState(false)
  const _page = usePage()
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const _summarization = useSummarization()
  const _getProjectFile = useGetProjectFile()
  const axiosInstance = new AxiosService()
  const _transformer = useTransformer()
  const _textBlock = useTextBlock()
  const _internalEmbedBlock = useInternalEmbedBlock()
  const _pagesTreeStructure = usePagesTreeStructure()

  const getPagesForSummarization = (pageId: string) => {
    if (!pages) return []
    const allPages = _page.getSubpagesAndParentPage(pages, pageId)
    return allPages.filter((page) => {
      return page.text !== SummaryPageTitle
    })
  }

  const getTextFromTransformer = async (pageId: string) => {
    if (!project) return
    const blocksInTransformer = _transformer.getBlocksFromTransformer(pageId)
    if (!blocksInTransformer) return
    const blocksContainingText = _textBlock.getBlocksContainingTextFromBlocks(blocksInTransformer)
    const internalEmbedBlocks =
      _internalEmbedBlock.getInternalEmbedBlocksFromBlocks(blocksInTransformer)
    const pageIdsFromInternalEmbedBlocks =
      _internalEmbedBlock.getPageIdsFromInternalEmbedBlocks(internalEmbedBlocks)
    const textFromInternalEmbedBlocks = await _summarization.getFullText(
      pageIdsFromInternalEmbedBlocks as string[],
    )
    const textFromBlocksContainingText = _textBlock.getTextFromBlocks(blocksContainingText)
    if (textFromInternalEmbedBlocks)
      return textFromInternalEmbedBlocks + textFromBlocksContainingText
    return textFromBlocksContainingText
  }

  const summarize = async (pageId: string, category: SummarizationCategory) => {
    setIsLoading(true)
    if (!project) {
      setIsLoading(false)
      return
    }
    const text =
      (await getTextFromTransformer(pageId)) ||
      (await _summarization.getFullText(
        getPagesForSummarization(pageId).map((page) => page.id.toString()),
      ))
    if (!text) {
      setIsLoading(false)
      return
    }
    sendRequest(pageId, text, category)
  }

  const sendRequest = async (pageId: string, text: string, category: SummarizationCategory) => {
    if (!project) {
      setIsLoading(false)
      return
    }
    const response = await axiosInstance.createPageSummary(project.id, pageId, category, text)
    if (response) {
      _pagesTreeStructure.setNewPageIntoTreeStructure(project.id, response.id)
      _getProjectFile.getAndDispatchProjectFilePages(project.id)
      setIsLoading(false)
    }
  }
  return {
    isLoading,
    getPagesForSummarization,
    summarize,
  }
}
