import React from 'react'
import { Dropdown } from 'react-bootstrap'
import { useBlockAi } from './useBlockAi'
import { IconButton } from 'components/atoms/Button/Button'
import { blockToolbar } from './blockToolbarTypes'
import { ClassicDropdown } from 'components/molecules/Dropdown/ClassicDropdown/ClassicDropdown'
import { RelationshipType } from '_features/relationship'
import { useComments } from '_features/comments'
import { type block } from '_entities/block'
import { isCapableToHaveSizeChangeArrowsDisplayedInMenu } from './isCapableToHaveSizeChangeArrowsDisplayedInMenu'
import { EditorToolbarFramer } from 'utils/framerConfig'
import { ReactComponent as Dots } from 'assets/images/block-toolbar/dots.svg'
import { ReactComponent as Mirror } from 'assets/images/block-toolbar/mirror.svg'
import { ReactComponent as CheckBox } from 'assets/images/checktask.svg'
import { ReactComponent as TextBIcon } from 'assets/images/TextB.svg'
import { ReactComponent as CommentIcon } from 'assets/images/block-toolbar/comment.svg'
import ExpandIcon from './ExpandIcon'
import GenerateIcon from './GenerateIcon'
import CustomP from 'components/atoms/CustomP/CustomP'
import TextResizer from './ResizeArrows/TextResizer'
import Relationship from './Relationships/Relationship'
import SummarizeIcon from './SummarizeIcon'
import { ConversionMenu, useConversionMenu, useDeleteBlock } from '_features/block'
import ColorDropdownMenu from './ColorDropdownMenu/ColorDropdownMenu'
import TextFormattingMenu from './TextFormattingMenu/TextFormattingMenu'
import { TextAUnderlineIcon } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/styles'
import { StyledBlockToolbar, ElementContainer, IconAndText, StyledOptionIcon } from './styles'
import LinkDropdown from 'components/organisms/Dropdowns/LinkDropdown/LinkDropdown'
import { icons, useIcons, Icon } from 'shared/icons'
import { FLOAT_RIGHT_ICON_SELECTOR, EditorImageBlockOptions } from '_features/editor'
import { useCreateTask } from '_features/task'

interface Props {
  block: block
  isEditor?: boolean
  isWhiteboard?: boolean
  innerRef: React.RefObject<HTMLDivElement>
  handleIsAnyTooltipSubmenuOpenState?: (state: boolean) => void
  handleOnCommentClick: (block: block) => void
  closeActionMenu?: () => void
  activeToolbarOption?: string | null
  setActiveToolbarOption: React.Dispatch<React.SetStateAction<string | null>>
}

const BlockToolbar = (props: Props) => {
  const _comments = useComments()
  const _conversionMenu = useConversionMenu()
  const _blockAi = useBlockAi({
    block: props.block,
    setActiveToolbarOption: props.setActiveToolbarOption,
  })
  const _icons = useIcons()
  const _deleteBlock = useDeleteBlock()
  const _createTask = useCreateTask()

  const icon = _icons.getIcon({
    block: props.block,
  })

  return (
    <StyledBlockToolbar
      {...EditorToolbarFramer}
      ref={props.innerRef}
      onMouseDown={(e: React.MouseEvent) => {
        e.preventDefault()
      }}
      isEditor={props.isEditor}
    >
      {props.block && (
        <>
          {/* Resize arrows */}
          {isCapableToHaveSizeChangeArrowsDisplayedInMenu(props.block) && (
            <TextResizer
              block={props.block}
              onClick={() =>
                props.setActiveToolbarOption &&
                props.setActiveToolbarOption(blockToolbar.TEXT_RESIZER)
              }
            />
          )}

          {/* Block conversion */}
          <Dropdown
            show={props.activeToolbarOption === blockToolbar.TEXT_RESIZER}
            onToggle={(e) => {
              props.handleIsAnyTooltipSubmenuOpenState &&
                props.handleIsAnyTooltipSubmenuOpenState(e)
            }}
          >
            <Dropdown.Toggle as={'div'}>
              <IconButton
                variant='transparent'
                size='small'
                onClick={() => {
                  props.activeToolbarOption !== blockToolbar.TEXT_RESIZER
                    ? props.setActiveToolbarOption(blockToolbar.TEXT_RESIZER)
                    : props.setActiveToolbarOption(null)
                }}
                border={
                  props.activeToolbarOption === blockToolbar.TEXT_RESIZER
                    ? '1px solid var(--primary-color)'
                    : '1px solid transparent'
                }
                width={'36px'}
              >
                <StyledOptionIcon>
                  {icon ? (
                    <Icon Icon={icon} />
                  ) : (
                    _conversionMenu.options.find((obj) => obj.tag === props.block?.data.tag)?.icon
                  )}
                </StyledOptionIcon>
              </IconButton>
            </Dropdown.Toggle>
            <ConversionMenu
              block={props.block}
              setActiveToolbarOption={props.setActiveToolbarOption}
            />
          </Dropdown>

          {/* Bold, italics, underline, strikethrough */}
          <Dropdown
            show={props.activeToolbarOption === blockToolbar.TEXT_STYLES}
            onToggle={(e) => {
              props.handleIsAnyTooltipSubmenuOpenState &&
                props.handleIsAnyTooltipSubmenuOpenState(e)
            }}
          >
            <Dropdown.Toggle as={'div'}>
              <IconButton
                variant='transparent'
                size='small'
                onClick={() => {
                  props.activeToolbarOption !== blockToolbar.TEXT_STYLES
                    ? props.setActiveToolbarOption(blockToolbar.TEXT_STYLES)
                    : props.setActiveToolbarOption(null)
                }}
                border={
                  props.activeToolbarOption == blockToolbar.TEXT_STYLES
                    ? '1px solid var(--primary-color)'
                    : ''
                }
              >
                <TextBIcon />
              </IconButton>
            </Dropdown.Toggle>
            <TextFormattingMenu block={props.block} />
          </Dropdown>

          {/* Text color and background */}
          <Dropdown
            show={props.activeToolbarOption === blockToolbar.TEXT_COLOURS}
            onToggle={(e) => {
              props.handleIsAnyTooltipSubmenuOpenState &&
                props.handleIsAnyTooltipSubmenuOpenState(e)
            }}
          >
            <Dropdown.Toggle as={'div'}>
              <IconButton
                variant='transparent'
                size='small'
                onClick={() => {
                  props.activeToolbarOption !== blockToolbar.TEXT_COLOURS
                    ? props.setActiveToolbarOption(blockToolbar.TEXT_COLOURS)
                    : props.setActiveToolbarOption(null)
                }}
                border={
                  props.activeToolbarOption === blockToolbar.TEXT_COLOURS
                    ? '1px solid var(--primary-color)'
                    : ''
                }
              >
                <TextAUnderlineIcon />
                <icons.caretDown />
              </IconButton>
            </Dropdown.Toggle>
            <ColorDropdownMenu
              isWhiteboard={props.isWhiteboard}
              block={props.block}
              setActiveToolbarOption={props.setActiveToolbarOption}
            />
          </Dropdown>

          {/* Link */}
          <LinkDropdown block={props.block} />

          {/* Generate image and text using AI */}
          <IconButton
            variant='transparent'
            size='small'
            onClick={(e: React.MouseEvent) => {
              props.activeToolbarOption !== EditorImageBlockOptions.FLOAT_RIGHT
                ? _blockAi.convertToImageAndText(e)
                : props.setActiveToolbarOption(null)
            }}
            border={
              props.activeToolbarOption === EditorImageBlockOptions.FLOAT_RIGHT
                ? '1px solid var(--primary-color)'
                : ''
            }
          >
            <icons.imageAi id={FLOAT_RIGHT_ICON_SELECTOR} />
          </IconButton>

          {/* AI */}
          <ClassicDropdown
            show={props.activeToolbarOption === blockToolbar.TEXT_AI}
            onToggle={(e: boolean) =>
              props.handleIsAnyTooltipSubmenuOpenState &&
              props.handleIsAnyTooltipSubmenuOpenState(e)
            }
            onClick={() =>
              props.setActiveToolbarOption && props.setActiveToolbarOption(blockToolbar.TEXT_AI)
            }
          >
            <ClassicDropdown.Toggle as={'div'}>
              <IconButton
                variant='transparent'
                size='small'
                background={
                  props.activeToolbarOption === blockToolbar.TEXT_AI ? 'var(--hover)' : ''
                }
              >
                <icons.ai />
                <icons.caretDown />
              </IconButton>
            </ClassicDropdown.Toggle>
            <ClassicDropdown.Menu>
              <ClassicDropdown.Item>
                <ElementContainer onClick={_blockAi.summarizeText}>
                  <IconAndText>
                    <SummarizeIcon id={`toolbar-${props.block._id}`} /> Summarize
                  </IconAndText>
                </ElementContainer>
              </ClassicDropdown.Item>
              <ClassicDropdown.Item>
                <ElementContainer onClick={_blockAi.expandText}>
                  <IconAndText>
                    <ExpandIcon id={`toolbar-${props.block._id}`} /> Complete&nbsp;sentence
                  </IconAndText>
                </ElementContainer>
              </ClassicDropdown.Item>
              <ClassicDropdown.Item>
                <ElementContainer onClick={_blockAi.generateText}>
                  <IconAndText>
                    <GenerateIcon id={`toolbar-${props.block._id}`} /> Expand upon
                  </IconAndText>
                </ElementContainer>
              </ClassicDropdown.Item>
            </ClassicDropdown.Menu>
          </ClassicDropdown>

          {/* Comment */}
          <IconButton
            variant='transparent'
            size='small'
            onClick={() => {
              props.setActiveToolbarOption &&
                props.setActiveToolbarOption(blockToolbar.TEXT_COMMENT)
              _comments.handleOnCommentClick(props.block)
            }}
            background={
              props.activeToolbarOption === blockToolbar.TEXT_COMMENT ? 'var(--hover)' : ''
            }
          >
            <CommentIcon />
          </IconButton>

          {/* Create task */}
          <IconButton
            variant='transparent'
            size='small'
            onMouseUp={() => {
              _createTask.createReferencedTask(props.block)
            }}
            onClick={() => {
              props.setActiveToolbarOption && props.setActiveToolbarOption(blockToolbar.TEXT_TASK)
            }}
            background={props.activeToolbarOption === blockToolbar.TEXT_TASK ? 'var(--hover)' : ''}
          >
            <CheckBox />
          </IconButton>

          {/* Mirror/Instance */}
          <Relationship
            block={props.block}
            closeActionMenu={props.closeActionMenu}
            relationshipType={RelationshipType.MIRROR}
            icon={<Mirror />}
            onClick={() => {
              props.setActiveToolbarOption && props.setActiveToolbarOption(blockToolbar.TEXT_MIRROR)
            }}
            onIconClick={() =>
              props.setActiveToolbarOption && props.setActiveToolbarOption(blockToolbar.TEXT_MIRROR)
            }
            background={
              props.activeToolbarOption === blockToolbar.TEXT_MIRROR ? 'var(--gray4)' : ''
            }
          />

          {/* Dots */}
          <ClassicDropdown
            onToggle={(e: boolean) =>
              props.handleIsAnyTooltipSubmenuOpenState &&
              props.handleIsAnyTooltipSubmenuOpenState(e)
            }
            onClick={() => {
              props.setActiveToolbarOption && props.setActiveToolbarOption(blockToolbar.TEXT_DOTS)
            }}
          >
            <ClassicDropdown.Toggle as={'div'}>
              <IconButton
                variant='transparent'
                size='small'
                background={
                  props.activeToolbarOption === blockToolbar.TEXT_DOTS ? 'var(--hover)' : ''
                }
              >
                <Dots />
              </IconButton>
            </ClassicDropdown.Toggle>
            <ClassicDropdown.Menu>
              <ClassicDropdown.Item>
                <ElementContainer onClick={() => _deleteBlock.deleteBlockHandler(props.block)}>
                  <CustomP color='var(--everyday-red)' whiteSpace='nowrap'>
                    Delete
                  </CustomP>
                </ElementContainer>
              </ClassicDropdown.Item>
            </ClassicDropdown.Menu>
          </ClassicDropdown>
        </>
      )}
    </StyledBlockToolbar>
  )
}

export default BlockToolbar
