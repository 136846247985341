import CustomP from 'components/atoms/CustomP/CustomP'
import Wrapper from 'components/molecules/Wrapper/Wrapper'

import trashIcon from 'assets/images/trashIcon.svg'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import { INewDropdownOption } from 'interfaces/dropdownOptions'

export const dropdownOptionsForListLayout = (
  projectFileId: number,
  deleteProjectFile: (projectFileId: number) => void,
): INewDropdownOption[] => [
  {
    element: (
      <Wrapper
        display='flex'
        padding='0px 0px 0px 12px'
        styles={
          '&:hover {background: var(--white) !important; & p { color: var(--everyday-red)}}; align-items: center;'
        }
        className='dark-style-hover'
        margin='0'
      >
        <CustomIcon src={trashIcon} width='12px !important' height='12px !important' />
        <CustomP
          marginBottom='-0.5rem'
          borderradius='5.5px'
          fontWeight='500'
          fontSize='16px'
          color='var(--everyday-red)'
        >
          Delete project
        </CustomP>
      </Wrapper>
    ),
    id: 9,
    onClick: () => {
      deleteProjectFile(projectFileId)
    },
    eventKey: 1,
  },
]
