import { useEffect, useMemo, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getSelectedOption } from 'redux/reducers/taskManagerReducer'
import { debounce } from 'lodash'
import { type block } from '_entities/block'
import { setSelectedBlock } from 'redux/reducers/pageReducer'
import { useGoogle } from '_entities/embed'
import { useUpdateBlock } from '_features/block'

interface IProps {
  block?: block
}

export type FilterDropdownItem = {
  value: string
  label: string
  photo?: string
}

export const useSidebar = (props: IProps) => {
  const [editClicked, setIsEditClicked] = useState<boolean>(false)
  const [shownTab, setShownTab] = useState<'edit' | 'info' | null>('edit')

  const rightSidebarOpen = useAppSelector((state) => state.page.rightSidebarOpen)
  const selectedOption = useAppSelector(getSelectedOption)

  const _google = useGoogle()
  const dispatch = useAppDispatch()
  const _blockUpdate = useUpdateBlock()

  const blockRef = useRef(props.block)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!rightSidebarOpen) {
      setShownTab(null)
      dispatch(setSelectedBlock(null))
    }
  }, [rightSidebarOpen])

  useEffect(() => {
    blockRef.current = props.block

    if (inputRef.current) {
      inputRef.current.value = props.block?.meta.name || ''
    }

    updateBlockName()
  }, [props.block])

  useEffect(() => {
    handleShownTab('edit')
  }, [])

  const handleShownTab = (tab: 'edit' | 'info' | null) => {
    setShownTab(tab)
  }

  const updateBlockName = async () => {
    const name =
      props.block &&
      props.block.data.embed &&
      (await _google.fetchFileName(props.block.data.embed.url))
    props.block && name && _blockUpdate.updateBlockName(props.block, name)
  }

  const renameBlock = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = (event.target as HTMLInputElement).value
    if (blockRef.current) {
      _blockUpdate.updateBlockName(blockRef.current, name)
    }
  }

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ event: e })
  }

  const debounceChange = useMemo(
    () =>
      debounce((args: { event: React.ChangeEvent<HTMLInputElement> }) => {
        renameBlock(args.event)
      }, 350),
    [],
  )

  return {
    setIsEditClicked,
    editClicked,
    handleShownTab,
    shownTab,
    selectedOption,
    handleNameChange,
    inputRef,
  }
}
