import { StyleTypes, type blockStyling } from '_entities/block'

const getBlockStyleValue = (styling: blockStyling[]) => {
  const BlockBackground = styling.find((b) => b.type === StyleTypes.BACKGROUND_COLOR)?.value ?? ''
  const BlockColor = styling.find((b) => b.type === StyleTypes.COLOR)?.value ?? ''
  const BlockBold = styling.find((b) => b.type === StyleTypes.BOLD)?.value ?? ''
  const BlockFontWeight = styling.find((b) => b.type === StyleTypes.FONT_WEIGHT)?.value ?? ''
  const BlockUnderline = styling.find((b) => b.type === StyleTypes.UNDERLINE)?.value ?? ''
  const BlockStrikeThrough = styling.find((b) => b.type === StyleTypes.STRIKE_THROUGH)?.value ?? ''
  const BlockItalic = styling.find((b) => b.type === StyleTypes.ITALIC)?.value ?? ''
  const BlockFontSize = styling.find((b) => b.type === StyleTypes.FONT_SIZE)?.value ?? ''
  const BlockFontFamily = styling.find((b) => b.type === StyleTypes.FONT_FAMILY)?.value ?? ''

  return {
    BlockBackground,
    BlockColor,
    BlockBold,
    BlockFontWeight,
    BlockUnderline,
    BlockStrikeThrough,
    BlockItalic,
    BlockFontSize,
    BlockFontFamily,
  }
}

export default getBlockStyleValue
