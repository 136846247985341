import { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import FullWidthLogo from '../FullScreenLoader/FullScreenLoader'
import { AxiosService } from 'services/axiosService/axiosService'
import { useInvites } from 'services/invites/useInvites'
import { useToast } from 'services/helpers/useToast'
import { useUser } from 'services/user/useUser'
import { useViewer } from '_entities/viewer'

const SpaceInvitationScreen = () => {
  const toast = useToast()
  const { dispatchInviteLink } = useInvites()
  const _viewer = useViewer()
  const { checkUser } = useUser()
  const AxiosInstance = new AxiosService()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [isDone, setIsDone] = useState<boolean>(false)
  const [isFail, setIsFail] = useState<boolean>(false)
  const inviteToken = searchParams.get('inviteToken')
  const spaceId = searchParams.get('spaceId')
  const projectFileId = searchParams.get('projectId')
  const pageId = searchParams.get('pageId')

  const getConfirmationPromise = () => {
    if (inviteToken) {
      if (spaceId && !projectFileId && !pageId)
        return AxiosInstance.confirmSpaceInviteToken(inviteToken)
      else if (spaceId && projectFileId && !pageId)
        return AxiosInstance.confirmProjectFileInvite(projectFileId, inviteToken, spaceId)
      else if (spaceId && projectFileId && pageId)
        return AxiosInstance.confirmPageInvitation(projectFileId, pageId, inviteToken)
    }
  }

  const getRedirectLink = () => {
    if (spaceId && !projectFileId && !pageId) return `/space/${spaceId}`
    else if (spaceId && projectFileId && !pageId)
      return `/spaces/${spaceId}/project-file/${projectFileId}`
    else if (spaceId && projectFileId && pageId)
      return `/spaces/${spaceId}/project-file/${projectFileId}?pageId=${pageId}`
  }

  const sendConfirmation = async () => {
    if (inviteToken) {
      const response = await getConfirmationPromise()
      if (response) {
        toast.success('Successfully accepted invitation!')
        setIsDone(true)
        _viewer.getAndSaveViewer()
        setTimeout(() => {
          navigate(getRedirectLink() ?? '')
        }, 2000)
      } else {
        dispatchInviteLink()
        checkUser(true)
      }
    }
  }
  useEffect(() => {
    sendConfirmation()
  }, [])

  return <FullWidthLogo isDone={isDone} isFail={isFail} />
}

export default SpaceInvitationScreen
