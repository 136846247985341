import { useState } from 'react'
import { useAppSelector } from 'redux/hooks'

const useProfileSettings = () => {
  const [isShown, setIsShown] = useState<string | null>(null)
  const userImage = useAppSelector((state) => state.global.userImage)

  return {
    userImage,
    isShown,
    setIsShown,
  }
}

export default useProfileSettings
