import { getBlockPageId, getIndex, type block } from '_entities/block'
import {
  getBlockDocumentPage,
  getExpandedPageBlockElement,
  getInternalEmbedBorderColor,
  useWhiteboardEmbed,
} from '_entities/embed'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { SourceKeys } from 'interfaces/editor'

export const useInternalCanvasBorderColor = () => {
  const _json1 = useJson1()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _submit = useSubmit()

  // ===== REAL TIME UPDATES =====

  const updateInternalCanvasBorderColorInRealTime = (block: block, color: string) => {
    const blockPage = getBlockDocumentPage(block)
    if (!blockPage) return
    updateInternalCanvasElementBorderColorInRealTime(blockPage.id, color)
  }
  const updateInternalCanvasElementBorderColorInRealTime = (pageId: string, rgba: string) => {
    const parentBlock = _whiteboardEmbed.getParentBlock(pageId)
    if (!parentBlock) return
    const element = getExpandedPageBlockElement(parentBlock)
    if (!element) return
    element.style.borderColor = rgba
  }

  // ===== SHAREDB UPDATES =====

  const updateInternalCanvasBorderColor = (block: block, color: string) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    if (index < 0) return
    const currentColor = getInternalEmbedBorderColor(block)
    if (currentColor) {
      _submit.submit(
        pageId,
        _json1.getReplaceBlockDataKeyOp(
          index,
          ['internalEmbed', 'borderColor'],
          color,
          currentColor,
        ),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      _submit.submit(
        pageId,
        _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed', 'borderColor'], color),
        SourceKeys.UPDATE_BLOCK,
      )
  }
  return {
    updateInternalCanvasBorderColorInRealTime,
    updateInternalCanvasElementBorderColorInRealTime,
    updateInternalCanvasBorderColor,
  }
}
