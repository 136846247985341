import { css } from 'styled-components'
import { textTrunc } from 'globalStyles'

type Props = {
  className?: string
  text?: string
  fontSize?: string
  children?: React.ReactNode
  letterSpacing?: string
  fontFamilySFPro?: boolean
  fontWeight?: string
  color?: string
  backgroundColor?: string
  translatey?: string
  paddingTop?: string
  paddingLeft?: string
  paddingRight?: string
  paddingBottom?: string
  marginBottom?: string
  marginLeft?: string
  marginRight?: string
  marginTop?: string
  cursorPointer?: boolean
  customCss?: string
  maxwidth?: string
  overflow?: string
  textoverflow?: string
  whiteSpace?: string
  borderradius?: string
  hoverbgcolor?: string
  position?: string
  top?: string
  left?: string
  userSelect?: string
  textTrunc?: number
  wordBreak?: string
  boxShadow?: string
  width?: string
  display?: string
  alignItems?: string
  justifyContent?: string
  gap?: string
  opacity?: string
  pointerEvents?: string
}

export const StyledPCss = css<Props>`
  &.text-bolded-600 {
    font-weight: 600;
  }

  &.whiten {
    color: var(--white) !important;
  }

  font-size: ${(props) => (!props.fontSize ? '14px' : `${props.fontSize}`)};
  letter-spacing: ${(props) => (!props.letterSpacing ? '' : `${props.letterSpacing}`)};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '')};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : '')};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => (props.color ? props.color : '')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : '')};
  transform: ${(props) => (props.translatey ? `translateY(${props.translatey})` : '')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '')};
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '')};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  cursor: ${(props) => props.cursorPointer && 'pointer'};
  overflow: ${(props) => props.overflow};
  text-overflow: ${(props) => props.textoverflow};
  white-space: ${(props) => props.whiteSpace};
  max-width: ${(props) => props.maxwidth};
  ${(props) => props.customCss};
  border-radius: ${(props) => props.borderradius};
  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  user-select: ${(props) => props.userSelect};
  box-shadow: ${(props) => props.boxShadow};
  width: ${(props) => props.width};
  display: ${(props) => props.display};
  align-items: ${(props) => props.alignItems};
  justify-content: ${(props) => props.justifyContent};
  gap: ${(props) => props.gap};
  opacity: ${(props) => props.opacity};
  pointer-events: ${(props) => props.pointerEvents};
  &:hover {
    background: ${(props) => props.hoverbgcolor};
  }

  ${(props) => (props.textTrunc ? textTrunc(props.textTrunc) : '')};

  word-break: ${(props) => props.wordBreak};
`
