import { ITools } from 'interfaces/whiteboard'
import { IconLine } from '_entities/whiteboard/ui/styles'
import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import CustomP from 'components/atoms/CustomP/CustomP'
import { StyledBlockToolbar } from 'editor/EditableBlock/BlockToolbar/styles'
import { getColor } from 'shared/colors'
import { IconButtonSize } from './Toolbar'
import { setTool } from 'redux/reducers/whiteboardReducer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { usePresentation } from '_features/canvas'
import { getToolbarId } from '../model/useToolbar'
import { WhiteboardToolbarFramer } from 'utils/framerConfig'
import { icons } from 'shared/icons'

interface Props {
  pageId: string
  isSlideShow?: boolean
  isPreview?: boolean
}

export const PresentationToolbar = (props: Props) => {
  const _presentation = usePresentation()
  const dispatch = useAppDispatch()
  const tool = useAppSelector((state) => state.whiteboard.tool)
  if (props.isPreview && !props.isSlideShow) return null
  return (
    <StyledBlockToolbar
      {...WhiteboardToolbarFramer()}
      id={getToolbarId()}
      left={'50%'}
      transform='translate(-50%, 0)'
      bottom='8px'
    >
      <IconButton
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
        onClick={() => {
          _presentation.previousSlide(props.pageId)
        }}
      >
        <icons.chevronLeft />
      </IconButton>
      <CustomP display='flex' alignItems='center' color={getColor('--primary-text')}>
        Presentation
      </CustomP>
      <IconButton
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
        onClick={() => {
          _presentation.nextSlide(props.pageId)
        }}
      >
        <icons.chevronRight />
      </IconButton>
      {!props.isSlideShow && (
        <>
          <IconButton
            width={IconButtonSize + 'px'}
            height={IconButtonSize + 'px'}
            isSelected={tool === ITools.STICKY}
            onClick={() => {
              _presentation.stopPresentation(props.pageId)
              dispatch(setTool(ITools.STICKY))
            }}
          >
            <icons.stickyNote />
          </IconButton>

          <IconLine />
          <IconButton
            background='var(--everyday-red)'
            hoverBackground='var(--hover-red)'
            width={IconButtonSize + 'px'}
            height={IconButtonSize + 'px'}
            onClick={() => _presentation.stopPresentation(props.pageId)}
          >
            <icons.stop />
          </IconButton>
        </>
      )}
    </StyledBlockToolbar>
  )
}
