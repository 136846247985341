import { type block, getBlockPageId, checkIsBlockMedia, getIndex } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useTransformer } from '_features/canvas'
import { deleteImageOnServer } from 'utils/editor/deleteImageOnServer'
import { Group } from 'konva/lib/Group'
import { getBlocks } from 'shared/shareDb'

export const useDeleteBlock = () => {
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _transformer = useTransformer()

  const removeBlock = (block: block) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    _submit.submit(pageId, _json1.deleteBlock(index), SourceKeys.UPDATE_BLOCK)
    _transformer.removeNodesFromTransformer(pageId)
  }

  const deleteBlockHandler = (currentBlock: block, isGroupFileNow?: boolean) => {
    const pageId = getBlockPageId(currentBlock)
    const index = getIndex(currentBlock)
    if (index === -1) return
    _submit.submit(pageId, _json1.deleteBlock(index), SourceKeys.UPDATE_BLOCK)
    // If the deleted block was an image block, we have to delete
    // the image file on the server
    if (checkIsBlockMedia(currentBlock) && currentBlock.data.imageUrl && !isGroupFileNow) {
      deleteImageOnServer(currentBlock.data.imageId)
    }
  }

  const deleteMultipleBlocks = (pageId: string) => {
    const selectedNodes = _transformer.getTransformerNodes(pageId)
    deleteNodes(pageId, selectedNodes)
    _transformer.removeNodesFromTransformer(pageId)
  }

  const deleteNodes = (pageId: string, nodes: Group[]) => {
    const blocks = getBlocks(pageId)
    if (nodes && blocks) {
      const updatedBlocks: block[] = [...blocks]
      nodes.forEach((node) => {
        const index = updatedBlocks.map((b) => b._id).indexOf(node.attrs.id)
        updatedBlocks.splice(index, 1)
        _submit.submit(pageId, _json1.deleteBlock(index), SourceKeys.UPDATE_BLOCK)
      })
    }
  }

  return { removeBlock, deleteMultipleBlocks, deleteBlockHandler, deleteNodes }
}
