import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AxiosService } from 'services/axiosService/axiosService'
import { useToast } from 'services/helpers/useToast'
import { displayToastAndCloseModals } from 'utils/displayToastAndCloseModals'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setUser } from 'redux/reducers/globalReducer'
import { IUserAttributes } from 'interfaces/auth/userData'
import { AxiosResponse } from 'axios'

export const useSpaceUserModalAccountTab = (onHide?: () => void) => {
  const AxiosInstance = new AxiosService()
  const navigate = useNavigate()
  const toast = useToast()
  const user = useAppSelector((state) => state.global.user)
  const [showChangeEmailModal, setChangeEmailModal] = useState<boolean>(false)
  const [showChangeNameModal, setChangeNameModal] = useState<boolean>(false)
  const [showChangeNPasswordModal, setChangePasswordModal] = useState<boolean>(false)
  const [password, setPassword] = useState<string>('')
  const [newPassword, setNewPassword] = useState<string>('')
  const [oldPassword, setOldPassword] = useState<string>('')
  const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')
  const [newEmail, setNewEmail] = useState<string>(user?.email || '')
  const [name, setName] = useState<string>(user?.firstName || '')
  const [surname, setSurname] = useState<string>(user?.lastName || '')
  const dispatch = useAppDispatch()

  const mainModal = document.getElementsByClassName('userModalMaxWidth')

  const [openOtherQuestionForDeleteAccount, setOpenOtherQuestionForDeleteAccount] =
    useState<boolean>(false)

  const onCloseChangeEmailModal = () => {
    setChangeEmailModal(false)
    mainModal[0]?.classList.remove('userModalHideForOtherModals')
  }

  const saveChangeEmail = async () => {
    const user = await AxiosInstance.updateUserInfo({
      firstName: name,
      lastName: surname,
      email: newEmail,
      password,
      newPassword: password,
    })

    if (user != null) {
      dispatch(setUser({ ...user, email: newEmail }))
    }

    displayToastAndCloseModals('Email', setChangeEmailModal, user, onHide)
  }

  const onShowChangeModal = () => {
    mainModal[0]?.classList.add('userModalHideForOtherModals')
  }

  const onCloseChangePasswordModal = () => {
    setChangePasswordModal(false)
    mainModal[0]?.classList.remove('userModalHideForOtherModals')
  }

  const saveChangePassword = async () => {
    const user = await AxiosInstance.updateUserInfo({
      firstName: name,
      lastName: surname,
      password: oldPassword,
      newPassword,
    })

    displayToastAndCloseModals('Password', setChangePasswordModal, user, onHide)
  }

  const onCloseChangeNameModal = () => {
    setChangeNameModal(false)
    mainModal[0]?.classList.remove('userModalHideForOtherModals')
  }

  const saveChangeName = async () => {
    const user: AxiosResponse<IUserAttributes> | undefined = await AxiosInstance.updateUserInfo({
      firstName: name,
      lastName: surname,
    })

    if (user) {
      const fetchedUser = await AxiosInstance.getMe()
      dispatch(setUser({ ...fetchedUser, firstName: name, lastName: surname }))
    }

    displayToastAndCloseModals('Name', setChangeNameModal, user, onHide)
  }

  const deleteAccount = () => {
    AxiosInstance.deleteUserAccount().then(() => {
      toast.success('Account deleted successfully.')
      navigate('/')
    })
  }

  const logoutAccount = () => {
    AxiosInstance.logout().then(() => {
      navigate('/signin')
    })
  }

  return {
    showChangeEmailModal,
    setChangeEmailModal,
    showChangeNameModal,
    setChangeNameModal,
    showChangeNPasswordModal,
    setChangePasswordModal,
    setPassword,
    setNewPassword,
    setOldPassword,
    setConfirmNewPassword,
    newPassword,
    confirmNewPassword,
    setNewEmail,
    newEmail,
    name,
    setName,
    surname,
    setSurname,
    onCloseChangeEmailModal,
    saveChangeEmail,
    onShowChangeModal,
    onCloseChangePasswordModal,
    saveChangePassword,
    onCloseChangeNameModal,
    saveChangeName,
    deleteAccount,
    logoutAccount,
    openOtherQuestionForDeleteAccount,
    setOpenOtherQuestionForDeleteAccount,
  }
}
