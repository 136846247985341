import { Form } from 'react-bootstrap'
import styled from 'styled-components'

export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
`
export const ModalInnerContainer = styled.div`
  padding: 30px 24px 16px 24px;

  & > p {
    color: red !important;
  }

  form {
    font-size: 14px;

    label:has(span) {
      margin-bottom: 1rem;
    }
  }
`

export const ModalSubtitle = styled.div`
  padding: 8px 16px;
  font-size: 14px;

  &.warning {
    padding: 0 16px;
  }

  & span {
    font-weight: 600;
    width: auto;
  }
`

type Props = {
  opacity?: number
}
export const Button = styled.button<Props>`
  width: fit-content;
  font-weight: 600;
  color: #fa5a32 !important;
  background: transparent !important;
  border-radius: 10px;
  padding-top: 5.5px !important;
  padding-bottom: 5.5px !important;
  padding-left: 0;
  transition: all 0.1s ease-out;
  :hover {
    background-color: var(--gray4) !important;
    padding-left: 1rem;
  }
  :active {
    border-color: transparent !important;
  }
  opacity: ${(props) => props.opacity} !important;

  cursor: pointer;
`

export const Span = styled.span`
  font-weight: 700;
`

export const Input = styled(Form.Control)`
  :focus {
    box-shadow: none;
    border: 2px solid var(--everyday-blue);
  }
`
