import { SelectedObjectWrapper } from '../TaskManagerBoard/styles'
import { SelectedObjectPreview } from '../ChatCustomMessage/styles'
import { PageName } from '_entities/page'
import { IPage } from 'interfaces/page'
import { isPageReference } from './isPageReference'
import { type block, IBlockTypes } from '_entities/block'
import {
  TaskBlockWrapper,
  TaskBlockContentWrapper,
  SelectedObjectImage,
  PageBlockWrapper,
  ErrorWrapper,
} from './styles'
import convertToPlain from 'utils/editor/convertToPlain'
import { ReactComponent as ExclamationCircle } from 'assets/images/exclamation-circle.svg'
import { icons } from 'shared/icons'
import { useTextBlock } from '_entities/text'
import { useFrame, getFill } from '_entities/whiteboard'
import { IFrameBlockPreview, useEmbed } from '_entities/embed'
import useOnPageClick from 'utils/useOnPageClick'
import { useShape } from '_entities/shape'

interface IProps {
  block: block
  page: IPage | null
  margin?: string
  handleRedirectToBlock: (block: block) => void
  isDisabled: boolean
}

const TaskBlock = ({ block, page, margin, handleRedirectToBlock, isDisabled }: IProps) => {
  const _textBlock = useTextBlock()
  const _embed = useEmbed()
  const _shape = useShape()
  const _frame = useFrame()
  const onPageClick = useOnPageClick()
  if (!block) return <></>

  if (block?.data.tag === IBlockTypes.IMAGE) {
    return (
      <TaskBlockWrapper
        margin={margin}
        onClick={() => handleRedirectToBlock(block)}
        isDisabled={isDisabled}
      >
        <SelectedObjectWrapper className='selected-object-wrapper'>
          <TaskBlockContentWrapper>
            <SelectedObjectImage backgroundImage={`${block?.data.imageUrl}`} />
          </TaskBlockContentWrapper>
          <PageName page={page as IPage} padding={'0 8px 6px 0'} />
        </SelectedObjectWrapper>
      </TaskBlockWrapper>
    )
  }

  if (isPageReference(block as block)) {
    return (
      <TaskBlockWrapper
        margin={margin}
        onClick={() => handleRedirectToBlock(block)}
        isDisabled={isDisabled}
      >
        <SelectedObjectWrapper className='selected-object-wrapper'>
          <TaskBlockContentWrapper>
            <PageBlockWrapper
              onClick={(e) => {
                e.stopPropagation()
                if (block.data.documentPage?.id) onPageClick(block.data.documentPage?.id)
              }}
            >
              {block.data.documentPage?.icon ? (
                block.data.documentPage?.icon
              ) : block.data.documentPage?.type === 'document' ? (
                <icons.document />
              ) : (
                <icons.whiteboard />
              )}{' '}
              {block.data.documentPage?.title}
            </PageBlockWrapper>
          </TaskBlockContentWrapper>
          <PageName page={page as IPage} padding={'0 8px 6px 0'} />
        </SelectedObjectWrapper>
      </TaskBlockWrapper>
    )
  }

  if (_embed.isEmbedBlock(block)) {
    return (
      <TaskBlockWrapper margin={margin} onClick={() => handleRedirectToBlock(block)}>
        <SelectedObjectWrapper className='selected-object-wrapper'>
          <TaskBlockContentWrapper>
            <SelectedObjectPreview padding='12px'>
              <IFrameBlockPreview referencedBlock={block} referencedPage={page} />
            </SelectedObjectPreview>
          </TaskBlockContentWrapper>
        </SelectedObjectWrapper>
      </TaskBlockWrapper>
    )
  }

  if (block.data.error) {
    return (
      <TaskBlockWrapper
        margin={margin}
        onClick={() => handleRedirectToBlock(block)}
        isDisabled={isDisabled}
      >
        <SelectedObjectWrapper className='selected-object-wrapper'>
          <TaskBlockContentWrapper>
            <ErrorWrapper>
              <ExclamationCircle />
              {convertToPlain(_textBlock.convertToHtml(block as block))}
            </ErrorWrapper>
          </TaskBlockContentWrapper>
          <PageName page={page as IPage} padding={'0 8px 6px 0'} />
        </SelectedObjectWrapper>
      </TaskBlockWrapper>
    )
  }

  return (
    <TaskBlockWrapper margin={margin} onClick={() => handleRedirectToBlock(block)}>
      <SelectedObjectWrapper className='selected-object-wrapper'>
        <TaskBlockContentWrapper>
          <SelectedObjectPreview
            backgroundColor={
              _frame.isFrameBlock(block) ? getFill(block) : _shape.getShapeFill(block)
            }
            padding='12px'
          >
            {_frame.isFrameBlock(block)
              ? 'Frame'
              : convertToPlain(_textBlock.convertToHtml(block as block))}
          </SelectedObjectPreview>
        </TaskBlockContentWrapper>
        <PageName page={page as IPage} padding={'0 8px 6px 0'} />
      </SelectedObjectWrapper>
    </TaskBlockWrapper>
  )
}

export default TaskBlock
