import { ReactComponent as AddEmbed } from 'assets/images/add-embed.svg'
import { ReactComponent as Ai } from 'assets/images/block-toolbar/ai.svg'
import { ReactComponent as ArrowDoubleForward } from 'assets/images/arrow-forward-up-double.svg'
import { ReactComponent as ArrowDown } from 'assets/images/block-toolbar/arrow-down.svg'
import { ReactComponent as ArrowRightUp } from 'assets/images/arrow-up-right.svg'
import { ReactComponent as ArrowUp } from 'assets/images/block-toolbar/arrow-up.svg'
import { ReactComponent as Asana } from 'assets/images/embeds/asana.svg'
import { ReactComponent as AsanaFull } from 'assets/images/embeds/asana-full.svg'
import { ReactComponent as CalendarPlus } from 'assets/images/calendar-plus.svg'
import { ReactComponent as CaretDownIcon } from 'assets/images/CaretDown.svg'
import { ReactComponent as ChatIcon } from 'assets/images/chat/message.svg'
import { ReactComponent as ChatIconBold } from 'assets/images/message_bold.svg'
import { ReactComponent as Check } from 'assets/images/check.svg'
import { ReactComponent as CheckBox } from 'assets/images/checktask.svg'
import { ReactComponent as CheckBoxBold } from 'assets/images/checkbox_bold.svg'
import { ReactComponent as CheckSquare } from 'assets/images/CheckSquare.svg'
import { ReactComponent as ChevronLeft } from 'assets/images/whiteboard/presentation-toolbar-icons/chevron-left.svg'
import { ReactComponent as ChevronRight } from 'assets/images/whiteboard/presentation-toolbar-icons/chevron-right.svg'
import { ReactComponent as CircleIcon } from 'assets/images/whiteboard/circle.svg'
import { ReactComponent as Close } from 'assets/images/close.svg'
import { ReactComponent as Copywriting } from 'assets/images/copywriting.svg'
import { ReactComponent as Crop } from 'assets/images/whiteboard/frame-sizes/crop.svg'
import { ReactComponent as CurvedRectangle } from 'assets/images/whiteboard/shape-types/curved-rectangle.svg'
import { ReactComponent as Trash } from 'assets/images/trash.svg'
import { ReactComponent as Detach } from 'assets/images/unlink.svg'
import { ReactComponent as Document } from 'assets/images/file-text-doc.svg'
import { ReactComponent as Dots } from 'assets/images/block-toolbar/dots.svg'
import { ReactComponent as Download } from 'assets/images/DownloadIcon.svg'
import { ReactComponent as Duplicate } from 'assets/images/duplicateIcon.svg'
import { ReactComponent as Edit } from 'assets/images/edit.svg'
import { ReactComponent as EmbedFilesIcon } from 'assets/images/window-maximize-embed.svg'
import { ReactComponent as EmbedLink } from 'assets/images/link-to-page.svg'
import { ReactComponent as Excel } from 'assets/images/embeds/excel.svg'
import { ReactComponent as EyeOff } from 'assets/images/eye-off.svg'
import { ReactComponent as Figma } from 'assets/images/embeds/figma.svg'
import { ReactComponent as FigmaFull } from 'assets/images/embeds/figma-full.svg'
import { ReactComponent as FileDots } from 'assets/images/file-dots.svg'
import { ReactComponent as FilePlus } from 'assets/images/whiteboard/file-plus.svg'
import { ReactComponent as FileText } from 'assets/images/file-text.svg'
import { ReactComponent as Finance } from 'assets/images/finance.svg'
import { ReactComponent as FloatLeftIcon } from 'assets/images/floatLeftIcon.svg'
import { ReactComponent as FrameOff } from 'assets/images/frame-off.svg'
import { ReactComponent as GoogleCalendar } from 'assets/images/embeds/google-calendar.svg'
import { ReactComponent as GoogleDoc } from 'assets/images/embeds/google-docs.svg'
import { ReactComponent as GoogleIcon } from 'assets/images/google.svg'
import { ReactComponent as GoogleSheets } from 'assets/images/embeds/google-sheets.svg'
import { ReactComponent as GoogleSlides } from 'assets/images/embeds/google-slides.svg'
import { ReactComponent as H1Icon } from 'assets/images/block-toolbar/text/h1.svg'
import { ReactComponent as H2Icon } from 'assets/images/block-toolbar/text/h2.svg'
import { ReactComponent as Hand } from 'assets/images/whiteboard/hand.svg'
import { ReactComponent as Hash } from 'assets/images/hash.svg'
import { ReactComponent as Hexagon } from 'assets/images/whiteboard/shape-types/hexagon.svg'
import { ReactComponent as Image } from 'assets/images/whiteboard/image-icon.svg'
import { ReactComponent as ImageAi } from 'assets/images/image-ai.svg'
import { ReactComponent as ImageAndText } from 'assets/images/text-recognition.svg'
import { ReactComponent as ImageSquare } from 'assets/images/ImageSquare.svg'
import { ReactComponent as InstanceIcon } from 'assets/images/instance-icon.svg'
import { ReactComponent as LIcon } from 'assets/images/block-toolbar/text/l.svg'
import { ReactComponent as Legal } from 'assets/images/legal.svg'
import { ReactComponent as LineDashedIcon } from 'assets/images/line-dashed.svg'
import { ReactComponent as LineDottedIcon } from 'assets/images/line-dotted.svg'
import { ReactComponent as LineStraightIcon } from 'assets/images/line-straight.svg'
import { ReactComponent as LineQIcon } from 'assets/images/whiteboard/line-q.svg'
import { ReactComponent as LinkIcon } from 'assets/images/LinkIcon.svg'
import { ReactComponent as LinkToPage } from 'assets/images/file-symlink.svg'
import { ReactComponent as ListBullets } from 'assets/images/ListBullets.svg'
import { ReactComponent as ListNumbers } from 'assets/images/numbered_list.svg'
import { ReactComponent as Lock } from 'assets/images/lock.svg'
import { ReactComponent as LockSmall } from 'assets/images/lock-small.svg'
import { ReactComponent as MIcon } from 'assets/images/block-toolbar/text/m.svg'
import { ReactComponent as MagnifyingGlass } from 'assets/images/MagnifyingGlass.svg'
import { ReactComponent as MinimizeIcon } from 'assets/images/minimize.svg'
import { ReactComponent as MinusIcon } from 'assets/images/minus.svg'
import { ReactComponent as Miro } from 'assets/images/embeds/miro.svg'
import { ReactComponent as MiroFull } from 'assets/images/embeds/miro-full.svg'
import { ReactComponent as MirrorIcon } from 'assets/images/mirror-icon.svg'
import { ReactComponent as Notion } from 'assets/images/embeds/notion.svg'
import { ReactComponent as NotionFull } from 'assets/images/embeds/notion-full.svg'
import { ReactComponent as Pdf } from 'assets/images/embeds/pdf.svg'
import { ReactComponent as PdfFull } from 'assets/images/embeds/pdf-full.svg'
import { ReactComponent as Pencil } from 'assets/images/whiteboard/pencil.svg'
import { ReactComponent as Pentagon } from 'assets/images/whiteboard/shape-types/pentagon.svg'
import { ReactComponent as People } from 'assets/images/people_alt.svg'
import { ReactComponent as PlayIcon } from 'assets/images/whiteboard/play.svg'
import { ReactComponent as PlusIcon } from 'assets/images/PlusIcon.svg'
import { ReactComponent as Powerpoint } from 'assets/images/embeds/powerpoint.svg'
import { ReactComponent as Product } from 'assets/images/product.svg'
import { ReactComponent as Project } from 'assets/images/folders.svg'
import { ReactComponent as RectangleIcon } from 'assets/images/whiteboard/rectangle.svg'
import { ReactComponent as Romb } from 'assets/images/whiteboard/shape-types/romb.svg'
import { ReactComponent as SIcon } from 'assets/images/block-toolbar/text/s.svg'
import { ReactComponent as SixteenNine } from 'assets/images/whiteboard/frame-sizes/16-9.svg'
import { ReactComponent as Slideshow } from 'assets/images/slideshow.svg'
import { ReactComponent as Smiley } from 'assets/images/whiteboard/smiley.svg'
import { ReactComponent as SquareFrame } from 'assets/images/whiteboard/frame-sizes/square-frame.svg'
import { ReactComponent as Star } from 'assets/images/star.svg'
import { ReactComponent as StickyNoteIcon } from 'assets/images/whiteboard/stickyNote.svg'
import { ReactComponent as Stop } from 'assets/images/whiteboard/presentation-toolbar-icons/stop.svg'
import { ReactComponent as Summarize } from 'assets/images/summarize.svg'
import { ReactComponent as TaskManager } from 'assets/images/TaskManager.svg'
import { ReactComponent as TIcon } from 'assets/images/block-toolbar/text/t.svg'
import { ReactComponent as ToastCheck } from 'assets/images/circle-check.svg'
import { ReactComponent as TriangleIcon } from 'assets/images/whiteboard/triangle.svg'
import { ReactComponent as UnassignedIcon } from 'assets/images/user-profile-unassgined.svg'
import { ReactComponent as Update } from 'assets/images/update-relationship.svg'
import { ReactComponent as Upload } from 'assets/images/upload-embed.svg'
import { ReactComponent as UserIcon } from 'assets/images/notes-user.svg'
import { ReactComponent as UserPlus } from 'assets/images/user-plus.svg'
import { ReactComponent as Video } from 'assets/images/embeds/video.svg'
import { ReactComponent as Vimeo } from 'assets/images/embeds/vimeo.svg'
import { ReactComponent as VimeoFull } from 'assets/images/embeds/vimeo-full.svg'
import { ReactComponent as Web } from 'assets/images/embeds/web.svg'
import { ReactComponent as Whiteboard } from 'assets/images/chalkboard-whiteboard.svg'
import { ReactComponent as Width } from 'assets/images/wide-item.svg'
import { ReactComponent as Word } from 'assets/images/embeds/word.svg'
import { ReactComponent as Workspace } from 'assets/images/workspace.svg'
import { ReactComponent as XLIcon } from 'assets/images/block-toolbar/text/xl.svg'
import { ReactComponent as Youtube } from 'assets/images/embeds/youtube.svg'
import { ReactComponent as YoutubeFull } from 'assets/images/embeds/youtube-full.svg'
import { ReactComponent as DisabledColor } from 'assets/images/color-picker/disabled.svg'
import { ReactComponent as DirectionIcon } from 'assets/images/direction.svg'
import { ReactComponent as DirectionOutIcon } from 'assets/images/direction-out.svg'
import { ReactComponent as AlignLeft } from 'assets/images/align-left.svg'

import RombShapePath, {
  ReactComponent as RombShape,
} from 'assets/images/whiteboard/shapes/romb.svg'
import PentagonShapePath, {
  ReactComponent as PentagonShape,
} from 'assets/images/whiteboard/shapes/pentagon.svg'
import SquareShapePath, {
  ReactComponent as SquareShape,
} from 'assets/images/whiteboard/shapes/square.svg'
import SquareCShapePath, {
  ReactComponent as SquareCShape,
} from 'assets/images/whiteboard/shapes/square-c.svg'
import TriangleShapePath, {
  ReactComponent as TriangleShape,
} from 'assets/images/whiteboard/shapes/triangle.svg'
import CircleShapePath, {
  ReactComponent as CircleShape,
} from 'assets/images/whiteboard/shapes/circle.svg'
import HexagonShapePath, {
  ReactComponent as HexagonShape,
} from 'assets/images/whiteboard/shapes/hexagon.svg'
import { IShapeTypes } from 'interfaces/whiteboard'
import { ReactComponent as StrokeMinIcon } from 'assets/images/stroke-min.svg'
import { ReactComponent as StrokeMaxIcon } from 'assets/images/stroke-max.svg'
import { ReactComponent as StrokeMidIcon } from 'assets/images/stroke-mid.svg'
import { ReactComponent as CTAIcon } from 'assets/images/section.svg'

export const paths = {
  [IShapeTypes.TRIANGLE]: TriangleShapePath,
  [IShapeTypes.RECTANGLE]: SquareShapePath,
  [IShapeTypes.PENTAGON]: PentagonShapePath,
  [IShapeTypes.ROMB]: RombShapePath,
  [IShapeTypes.CURVED_RECTANGLE]: SquareCShapePath,
  [IShapeTypes.HEXAGON]: HexagonShapePath,
  [IShapeTypes.CIRCLE]: CircleShapePath,
}

export const icons = {
  addEmbed: AddEmbed,
  ai: Ai,
  align: AlignLeft,
  arrowDoubleForward: ArrowDoubleForward,
  arrowDown: ArrowDown,
  arrowRightUp: ArrowRightUp,
  arrowUp: ArrowUp,
  asana: Asana,
  asanaFull: AsanaFull,
  calendarPlus: CalendarPlus,
  caretDown: CaretDownIcon,
  chat: ChatIcon,
  chatBold: ChatIconBold,
  check: Check,
  checkBox: CheckBox,
  checkBoxBold: CheckBoxBold,
  checkSquare: CheckSquare,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  circle: CircleIcon,
  circleShape: CircleShape,
  close: Close,
  copywriting: Copywriting,
  crop: Crop,
  curvedRectangle: CurvedRectangle,
  ctaIcon: CTAIcon,
  detach: Detach,
  disabled: DisabledColor,
  direction: DirectionIcon,
  directionOut: DirectionOutIcon,
  document: Document,
  dots: Dots,
  download: Download,
  duplicate: Duplicate,
  edit: Edit,
  embedFiles: EmbedFilesIcon,
  embedLink: EmbedLink,
  excel: Excel,
  eyeOff: EyeOff,
  figma: Figma,
  figmaFull: FigmaFull,
  fileDots: FileDots,
  filePlus: FilePlus,
  fileText: FileText,
  finance: Finance,
  floatTextLeft: FloatLeftIcon,
  folders: Project,
  frameOff: FrameOff,
  google: GoogleIcon,
  googleCalendar: GoogleCalendar,
  googleDoc: GoogleDoc,
  googleSheets: GoogleSheets,
  googleSlides: GoogleSlides,
  h1Icon: H1Icon,
  h2Icon: H2Icon,
  hand: Hand,
  hash: Hash,
  hexagon: Hexagon,
  hexagonShape: HexagonShape,
  image: Image,
  imageAi: ImageAi,
  imageAndText: ImageAndText,
  imageSquare: ImageSquare,
  instance: InstanceIcon,
  lIcon: LIcon,
  legal: Legal,
  lineDashed: LineDashedIcon,
  lineDotted: LineDottedIcon,
  lineStraight: LineStraightIcon,
  lineQ: LineQIcon,
  link: LinkIcon,
  linkToPage: LinkToPage,
  listBullets: ListBullets,
  listNumbers: ListNumbers,
  lock: Lock,
  lockSmall: LockSmall,
  mIcon: MIcon,
  magnifyingGlass: MagnifyingGlass,
  minimize: MinimizeIcon,
  minus: MinusIcon,
  miro: Miro,
  miroFull: MiroFull,
  mirror: MirrorIcon,
  notion: Notion,
  notionFull: NotionFull,
  office: Powerpoint,
  pdf: Pdf,
  pdfFull: PdfFull,
  pencil: Pencil,
  pentagon: Pentagon,
  pentagonShape: PentagonShape,
  people: People,
  play: PlayIcon,
  plus: PlusIcon,
  powerpoint: Powerpoint,
  product: Product,
  rectangle: RectangleIcon,
  romb: Romb,
  rombShape: RombShape,
  sIcon: SIcon,
  sixteenNine: SixteenNine,
  slideshow: Slideshow,
  smiley: Smiley,
  squareCShape: SquareCShape,
  squareFrame: SquareFrame,
  squareShape: SquareShape,
  star: Star,
  stickyNote: StickyNoteIcon,
  stop: Stop,
  strokeMin: StrokeMinIcon,
  strokeMid: StrokeMidIcon,
  strokeMax: StrokeMaxIcon,
  summarize: Summarize,
  taskManager: TaskManager,
  tIcon: TIcon,
  toastCheck: ToastCheck,
  trash: Trash,
  triangle: TriangleIcon,
  triangleShape: TriangleShape,
  unassigned: UnassignedIcon,
  update: Update,
  upload: Upload,
  user: UserIcon,
  userPlus: UserPlus,
  video: Video,
  vimeo: Vimeo,
  vimeoFull: VimeoFull,
  web: Web,
  whiteboard: Whiteboard,
  width: Width,
  word: Word,
  workspace: Workspace,
  xlIcon: XLIcon,
  youtube: Youtube,
  youtubeFull: YoutubeFull,
}
