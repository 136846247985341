import { useToast } from 'services/helpers/useToast'
import { croppingNoPossibleWarningText } from '_features/editor'

export const checkIfImageIsBigEnoughForCropping = (
  imageWidth: number,
  imageHeight: number,
  minimumSize: number,
) => {
  const toast = useToast()

  if (imageWidth < minimumSize || imageHeight < minimumSize) {
    const noCropWarning = croppingNoPossibleWarningText(minimumSize)
    toast.warning(noCropWarning)
    return false
  }

  return true
}
