import styled from 'styled-components'
export const StyledTextWrapper = styled.div<{
  width: number
  height: number
  backgroundColor: string
}>`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-color: ${(props) => props.backgroundColor};
  box-shadow: #0000004d 0px 4px 4px 0px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  outline: none;
`
