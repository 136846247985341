export const envVars = {
  nodeEnv: process.env.NODE_ENV || 'development',
  api: {
    https: process.env.REACT_APP_BACKEND_URL,
    wss: process.env.REACT_APP_BACKEND_SOCKET_URL,
    s3Butcket: process.env.REACT_APP_STORAGE_BUCKET_URL,
    proxy: process.env.REACT_APP_PROXY_URL,
  },
  googleApi: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    clientSecret: process.env.REACT_APP_GOOGLE_CLIENT_SECRET,
  },
  getStream: {
    id: process.env.REACT_APP_GETSTREAM_ID,
    key: process.env.REACT_APP_GETSTREAM_KEY,
  },
  googleAnalytics: {
    id: process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  },
  hotjar: {
    id: process.env.REACT_APP_HOTJAR_ID,
    sv: process.env.REACT_APP_HOTJAR_SNIPPET_VERSION,
  },
  retryDelay: process.env.REACT_APP_RETRY_DELAY,
  landingPageUrl: process.env.REACT_APP_LANDING_PAGE_URL,
  termly: {
    id: process.env.REACT_APP_TERMLY_ID,
  },
}
