import { useState } from 'react'
import { AxiosService } from 'services/axiosService/axiosService'
import { useNavigate } from 'react-router-dom'

interface Props {
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const useCreateSpaceModal = ({ setShow }: Props) => {
  const [newSpaceName, setNewSpaceName] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const AxiosInstance = new AxiosService()
  const navigate = useNavigate()

  const createNewSpace = async (name: string, description: string) => {
    const response = await AxiosInstance.createNewSpace(name, description)
    if (response) {
      setShow(false)
      const space = response.data
      navigate({
        pathname: `/space/${space.data.id}`,
      })
    } else console.error(response)
  }

  return {
    newSpaceName,
    setNewSpaceName,
    description,
    setDescription,
    createNewSpace,
  }
}
