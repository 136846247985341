import { useEffect, useState } from 'react'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import Checkbox from 'components/atoms/Checkbox/Checkbox'
import {
  type block,
  IBlockTypes,
  INDENTATION,
  getBlockId,
  getBlockPageId,
  getImageUrl,
  getIndex,
} from '_entities/block'
import { SlashMenu, useCreateBlock, SlashCtaMenu } from '_features/block'
import { IPermissions } from '_entities/user'
import { SourceKeys } from 'interfaces/editor'
import { NumberWrapper, StyledDropdown, StyledDropdownWrapper, StyledImage } from './styles'
import { displayBulletSign } from './helpers/displayBulletSign'
import { useQuill } from './helpers/useQuill'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { usePresence } from 'shared/shareDb'
import { setSelectedBlock } from 'redux/reducers/pageReducer'
import Placeholder from 'assets/images/placeholder.png'

export interface Props {
  block: block
  userRole?: IPermissions
  openActionMenu: () => void
  searchText?: string
  isPreview?: boolean
}

const EditorTextBlock = (props: Props) => {
  const selectedBlock = useAppSelector((state) => state.page.selectedBlock)
  const [loaded, setLoaded] = useState<boolean>(false)
  const [isElementAtBottom, setIsElementAtBottom] = useState(false)
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _createBlock = useCreateBlock()
  const _presence = usePresence()
  const dispatch = useAppDispatch()

  const _quill = useQuill({
    block: props.block,
    userRole: props.userRole,
    openActionMenu: props.openActionMenu,
    isPreview: props.isPreview,
    shouldFocusOnRender: _createBlock.isJustCreatedInDoc(props.block),
  })

  const handleCheck = (value: boolean) => {
    const oldValue = props.block.data.isChecked
    const index = getIndex(props.block)
    const op = _json1.getReplaceBlockDataKeyOp(index, ['isChecked'], value, oldValue)
    _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
  }

  const handleFocus = () => {
    if (props.userRole === IPermissions.CAN_VIEW) {
      return
    } else {
      _presence.updatePresence(props.block)
      dispatch(setSelectedBlock(props.block))
    }
  }

  const handleBlur = () => {
    _presence.removePresence(getBlockPageId(props.block))
  }

  useEffect(() => {
    if (!selectedBlock) return

    const documentWrapper = document.querySelector('#PageBoundaryElementDetector') as HTMLDivElement

    if (!documentWrapper) return

    const quillElement = document.querySelector(`#quill-editor-${selectedBlock._id}`)

    if (!quillElement) return

    const elementRect = quillElement.getBoundingClientRect()

    const elementBottom = elementRect.bottom + 150 + elementRect.height

    const viewportHeight = documentWrapper.offsetHeight || documentWrapper.clientHeight

    setIsElementAtBottom(elementBottom >= viewportHeight)
  }, [selectedBlock])

  return (
    <StyledDropdownWrapper isElementAtBottom={isElementAtBottom}>
      <StyledDropdown autoClose={false}>
        <Wrapper
          display='flex'
          alignItems='start'
          justifyContent='center'
          gap='0.35rem'
          width='100%'
        >
          {props.block.data.tag === IBlockTypes.LIST &&
            displayBulletSign(props.block.data.indent ? props.block.data.indent / INDENTATION : 0)}

          {props.block.data.tag === IBlockTypes.NUMBERED_LIST && (
            <NumberWrapper>{props.block.data.numberInList}.</NumberWrapper>
          )}

          {props.block.data.tag === IBlockTypes.CHECKLIST && (
            <Checkbox
              id={`check_${props.block._id}`}
              onChange={(e) => handleCheck(e.target.checked)}
              checked={props.block.data.isChecked}
              dimensions='1rem'
              borderRadius='0.1rem'
              borderColour='var(--gray2)'
              borderWidth='2px'
              top='0.35rem'
              left='0.15rem'
              marginRight='0.15rem'
            />
          )}
          {props.block.data.tag === IBlockTypes.IMAGE_TEXT && getImageUrl(props.block) !== '' && (
            <StyledImage
              src={getImageUrl(props.block) === null ? Placeholder : getImageUrl(props.block)}
              alt={`${getBlockId(props.block)}-block-image`}
            />
          )}

          <div
            ref={(node) => {
              if (!node || loaded) return
              _quill.initiateQuill()
              _presence.updatePresence(props.block)
              setLoaded(true)
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            id={`quill-editor-${props.block._id}`}
          />
        </Wrapper>

        <SlashCtaMenu
          block={props.block}
          searchText={_quill.searchText}
          open={_quill.ctaMenuOpen}
          isFocused={_quill.isFocused}
          setFocused={_quill.setFocused}
        />

        <SlashMenu
          block={props.block}
          searchText={_quill.searchText}
          open={_quill.menuOpen}
          isFocused={_quill.isFocused}
          setFocused={_quill.setFocused}
        />
      </StyledDropdown>
    </StyledDropdownWrapper>
  )
}

export default EditorTextBlock
