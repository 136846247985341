import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setFreshlyCreatedPageId, setPages } from 'redux/reducers/projectFileReducer'
import { AxiosService } from 'services/axiosService/axiosService'
import useLocalStorage from 'shared/lib/useLocalStorage'
import { useToast } from 'services/helpers/useToast'
import { type block, getBlockPageId, getIndex, IBlockTypes } from '_entities/block'
import { RelationshipType, useRelationships } from '_features/relationship'
import { IManager, IPage, IPages } from 'interfaces/page'
import useOnPageClick from 'utils/useOnPageClick'
import { useSpace } from '_entities/space'
import { usePage, usePageTransfer } from '_entities/page'
import { createPage, usePagesTreeStructure, useUpdatePage } from '_features/page'
import { PageTypesEnum } from 'interfaces/projectFile'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import objectId from 'utils/editor/objectId'
import { DragSourceTypes, SourceKeys } from 'interfaces/editor'
import { BlockMetaKeys } from '_entities/block/model/types'
import { IProjectFile } from 'interfaces/space'
import { useEmbed } from '_entities/embed'
import { useCanvas } from '_entities/whiteboard'
import { useInternalEmbed } from 'whiteboard/Embed/InternalEmbed/useInternalEmbed'
import useEmojiDropdown from './useEmojiDropdown'
import { TreeItem } from '_entities/tree/model/types'
import { LAST_PLACEHOLDER } from 'shared/dragging/ui/DraggableTree'
import { useTree } from '_entities/tree'
import { InitialTreeStructure } from 'shared/dragging/model/types'
import { Emoji, getEmojiCode } from '_features/emojis'

export interface ExtendedDropTarget {
  droppable?: boolean
  embedUrl?: string
  icon?: string
  id: string
  managers?: IManager[]
  parent: number | string
  positionIndex: number
  text: string
  type: string
}

export const usePagesNavigation = () => {
  // ** Redux state
  const allPages = useAppSelector((state) => state.projectFile.pages)
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  // ** State
  const [moveModalOpen, setMoveModalOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<any[]>([])
  const [selectedProject, setSelectedProject] = useState<IProjectFile | null>(null)
  const [pageToMove, setPageToMove] = useState<IPages | null>(null)
  const [pageStructure, setPageStructure] = useState<InitialTreeStructure[] | undefined>(undefined)

  // ** Custom hooks
  const _relationship = useRelationships({})
  const _localStorage = useLocalStorage()
  const _getProjectFile = useGetProjectFile()
  const _space = useSpace()
  const _pageTransfer = usePageTransfer()
  const onPageClick = useOnPageClick()
  const _page = usePage()
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _embed = useEmbed()
  const _canvas = useCanvas()
  const _emojiDropdown = useEmojiDropdown()
  const _updatePage = useUpdatePage()
  const _internalEmbed = useInternalEmbed()
  const _tree = useTree()
  const dispatch = useAppDispatch()
  const AxiosInstance = new AxiosService()
  const toast = useToast()
  const params = useParams()
  const _pagesTreeStructure = usePagesTreeStructure()

  useEffect(() => {
    if (!projectFile) return
    const treeStructure = _pagesTreeStructure.getPagesTreeStructure(projectFile.id)
    if (!treeStructure) return

    const generatedItems = _tree.generateItemFromInitialTreeStructure(treeStructure)

    const treeStructureIds = treeStructure.map((item: { id: string; isShown: boolean }) => item.id)

    const missingPages = allPages?.filter((page) => !treeStructureIds.includes(page.id))

    if (missingPages && missingPages.length > 0) {
      const missingPagesStructure = _tree.generateMissingItems(missingPages, generatedItems)
      setPageStructure([...treeStructure, ...missingPagesStructure])
      _pagesTreeStructure.setNewTreeStructure(projectFile.id, [
        ...treeStructure,
        ...missingPagesStructure,
      ])
    } else {
      setPageStructure(treeStructure)
    }
  }, [selectedPage])

  const handleMoveModalOpen = async (page: IPages) => {
    setMoveModalOpen(true)
    setPageToMove(page)

    const projects = await _space.getUsersProjects()
    setOptions(projects)
  }

  const handleMoveModalClose = () => {
    setMoveModalOpen(false)
    setSelectedProject(null)
  }

  const handleSelectProject = (project: IProjectFile) => {
    setSelectedProject(project)
  }

  const getTypeOfPage = (block: block) => {
    if (_canvas.isCanvasBlock(block)) {
      return PageTypesEnum.WHITE_BOARD
    } else if (
      block.data.tag === IBlockTypes.EXTERNAL_EMBED ||
      block.data.tag === IBlockTypes.WHITEBOARD ||
      block.data.tag === IBlockTypes.DOCUMENT
    ) {
      return PageTypesEnum.EMBED
    } else {
      return PageTypesEnum.DOCUMENT
    }
  }

  const handleCreateNewPageWithBlock = async (
    block: block,
    page: ExtendedDropTarget,
    destination?: number,
  ) => {
    if (!projectFile) return

    let embedLink
    let fileName = ''

    if (_embed.checkIsBlockInternalEmbed(block)) {
      const page = _internalEmbed.getEmbedBlockPage(block)
      embedLink = `pageId-${page?.id}`
      fileName = page?.title || ''
    }

    if (_embed.isEmbedBlock(block)) {
      embedLink = block.data.embed?.url
    }

    const generatedBlock = {
      ...block,
      _id: objectId(),
      meta: {
        ...block.meta,
        relationshipType: RelationshipType.MIRROR,
        pageId: '',
        originalObject: _relationship.generateOriginalObject(block),
      },
    }

    const addPageResponse = await createPage({
      type: getTypeOfPage(block),
      pages: allPages || [],
      projectId: projectFile.id,
      blocks: [generatedBlock],
      positionIndex: destination,
      parentId: page?.id,
      embedUrl: embedLink || '',
      title: fileName ? `Link to ${fileName}` : '',
    })

    if (addPageResponse) {
      await _getProjectFile.getAndDispatchProjectFilePages(projectFile.id)
      dispatch(setFreshlyCreatedPageId(addPageResponse.id))

      const relatedObject = _relationship.generateRelatedObject(
        generatedBlock,
        addPageResponse?.id,
        RelationshipType.MIRROR,
      )

      const relatedObjects = block.meta.relatedObjects
        ? [...block.meta.relatedObjects, relatedObject]
        : [relatedObject]

      const op = _json1.getReplaceBlockMetaKeyOp(
        getIndex(block),
        [BlockMetaKeys.RELATED_OBJECTS],
        relatedObjects,
      )

      await _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
    }
  }

  const onChangeOpen = (arrOfOpened: (number | string)[]) => {
    let treeStructures: Record<string, (string | number)[]> | undefined =
      _localStorage.getParsedItem('treeStructures')
    if (projectFile) {
      if (treeStructures) {
        treeStructures[projectFile.id] = arrOfOpened
      } else {
        treeStructures = {
          [projectFile.id]: arrOfOpened,
        }
      }
    }
    _localStorage.setItem('treeStructures', treeStructures)
  }

  const handleOnUndo = async (pageId: string) => {
    const response = await _page.deletePage(pageId, params.spaceId as string, params.id as string)

    if (response) {
      toast.success('Undo successful!')
    }
  }

  const handleRedirect = (pageId: string, page: IPage, spaceId: string) => {
    onPageClick(pageId, '', spaceId, page.projectFileId.toString())
  }

  const handleCopy = async () => {
    projectFile &&
      pageToMove &&
      (await _pageTransfer.handleCopyFileToAnotherProject(
        pageToMove,
        projectFile.id,
        selectedProject,
        handleRedirect,
        handleOnUndo,
      ))
    handleMoveModalClose()
  }

  const onDrop = async ({
    dropTarget,
    droppedData,
    relativeIndex,
  }: {
    dropTarget: TreeItem
    droppedData: TreeItem
    relativeIndex: number
  }) => {
    const page = allPages?.find((page) => page.id === dropTarget.id)
    const parent = page?.parent
    const parentPage = allPages?.find((page) => page.id === parent)

    if (droppedData.type === DragSourceTypes.BLOCK && dropTarget.isBetweenItems) {
      await handleCreateNewPageWithBlock(
        droppedData.data as block,
        parentPage as ExtendedDropTarget,
        relativeIndex,
      )

      return
    }

    if (droppedData.type === DragSourceTypes.BLOCK && !dropTarget.isBetweenItems) {
      droppedData.data &&
        page &&
        _relationship.createRelationship(RelationshipType.MIRROR, droppedData.data as block, [
          _relationship.constructRelationshipPage(page),
        ])

      return
    }

    if (projectFile) {
      const response = await AxiosInstance.movePage(
        projectFile.id,
        droppedData.id as string,
        dropTarget.isBetweenItems
          ? parentPage?.id || 0
          : (dropTarget?.id as string) === LAST_PLACEHOLDER
          ? 0
          : dropTarget.id,
        relativeIndex as number,
      )

      if (response) {
        toast.success('Successfully reordered pages!', 5000)
        dispatch(setPages(response))
      }
    }
  }

  const handleAddEmojiOnClick = (emoji: Emoji) => {
    if (projectFile && _emojiDropdown.emojiNodeElement) {
      const code = getEmojiCode(emoji)
      if (code) {
        _updatePage.updateIcon(
          code,
          projectFile.id,
          _emojiDropdown.emojiNodeElement.node.id.toString(),
        )
        _emojiDropdown.closeEmojiDropdown()
      }
    }
  }

  const handleRemoveEmojiOnClick = () => {
    if (projectFile && _emojiDropdown.emojiNodeElement)
      _updatePage.updateIcon('', projectFile.id, _emojiDropdown.emojiNodeElement.node.id.toString())
    _emojiDropdown.closeEmojiDropdown()
  }

  return {
    allPages,
    pagesWithoutTaskManager: allPages?.filter((page) => page.type !== PageTypesEnum.TASK_MANAGER),
    taskManagerPage: allPages?.find((page) => page.type === PageTypesEnum.TASK_MANAGER),
    onChangeOpen,
    selectedPage,
    handleMoveModalOpen,
    handleMoveModalClose,
    moveModalOpen,
    options,
    handleSelectProject,
    selectedProject,
    handleCopy,
    pageToMove,
    onDrop,
    handleRemoveEmojiOnClick,
    handleAddEmojiOnClick,
    _emojiDropdown,
    pageStructure,
  }
}
