import React from 'react'
import { Row } from 'react-bootstrap'
import Image from 'components/atoms/Image/Image'
import Header from 'components/organisms/Header/Header'
import FullScreen from 'components/templates/FullScreen/FullScreen'
import { StyledAvatarContainer, StyledArrow, NotificationBell } from './styles'
import everydayLogo from 'assets/images/everydayNavigationLogo.svg'
import keyboardArrowDownMuted from 'assets/images/keyboardArrowDownMuted.svg'
import Avatar from 'components/atoms/Avatar/Avatar'
import SpaceSidebar from 'components/organisms/SpaceSidebar/SpaceSidebar'
import SpaceMainContent from 'components/organisms/SpaceMainContent/SpaceMainContent'
import SpaceRightSidebar from 'components/organisms/SpaceRightSidebar/SpaceRightSidebar'
import FullWidthLogo from 'components/templates/FullScreenLoader/FullScreenLoader'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { useSpaceNavigation } from 'services/space/useSpaceNavigation'
import ModalWithSidebar from 'components/molecules/ModalWithSidebar/ModalWithSidebar'
import { CustomDropdown } from 'components/molecules/CustomDropdown/CustomDropdown'
import { useProfileDropdownOptions } from './userProfileDropdownOptions'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setProfileModalOpened } from 'redux/reducers/spaceReducer'
import { useSpaceInitializer } from 'services/space/useSpaceInitializer'

const Space: React.FC = () => {
  const { userImage, setUserImage } = useSpaceNavigation()
  const user = useAppSelector((state) => state.global.user)
  const profileOpened = useAppSelector((state) => state.space.profileModalOpened)
  const projectFiles = useAppSelector((state) => state.space.projectFiles)
  const dispatch = useAppDispatch()
  const { dropdownOptions } = useProfileDropdownOptions()
  useSpaceInitializer()

  return (
    <>
      <FullScreen>
        {!projectFiles && <FullWidthLogo />}
        <Wrapper display={!projectFiles ? 'none' : ''} height={'100%'}>
          <Header>
            <Image
              src={everydayLogo}
              alt='everyday company logo R'
              width='9rem'
              margin='0 24px 0 0'
            />

            <ModalWithSidebar
              setUserImage={setUserImage}
              onHide={() => {
                dispatch(setProfileModalOpened(false))
              }}
              show={profileOpened}
              userImage={userImage}
            />
            <StyledAvatarContainer>
              <NotificationBell />
              <Avatar
                src={userImage}
                userName={user?.firstName}
                userColor={user?.userColor}
                width='32px'
                height='32px'
              />
              <CustomDropdown
                dropdownOptions={dropdownOptions(user, userImage)}
                positionDropdown='initial'
                toggleButton={<StyledArrow src={keyboardArrowDownMuted} />}
                menuStylesOverrides={'transform: translate3d(0px, 34px, 0px) !important;'}
                transformMenu='translate3d(0px, 34px, 0px)'
                menuMargin='0.5rem 1rem 0 0'
                backgroundColor='var(--white)'
                color='var(--main-black)'
              />
            </StyledAvatarContainer>
          </Header>
          <Row className='m-0 h-100' id='row-borders'>
            <SpaceSidebar />
            <SpaceMainContent />
            <SpaceRightSidebar userImage={userImage} />
          </Row>
        </Wrapper>
      </FullScreen>
    </>
  )
}

export default Space
