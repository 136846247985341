import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { ReactComponent as ArrowUp } from 'assets/images/keyboard_arrow_up.svg'

export const StyledLinkSettingsPopup = styled.div<{ open?: boolean; padding?: string }>`
  align-items: baseline;
  width: 100%;
  opacity: ${(props) => (props.open ? '1' : '0')};
  max-height: ${(props) => (props.open ? '33px' : '0px')};
  transition: all 0.3s ease-in-out;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  padding: ${(props) => props.padding};
`

export const StyledContainer = styled(Container)`
  width: 109px;
  height: 36px;
  margin-left: 0;
  margin: ${(props) => props.margin};
`
export const Arrow = styled(ArrowUp)<{ translatez?: string }>`
  transition: transform 0.3s ease-in-out;
  transform: ${(props) => `rotateZ(${props.translatez})`};
`
