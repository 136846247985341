import styled, { css } from 'styled-components'
import EmojiPicker from '@emoji-mart/react'
import { CSSProperties } from 'react'
import { BaseMenuStyles } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

export const StyledEmojiPicker = styled(EmojiPicker)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const EmojiContainer = styled.div<{ size?: CSSProperties['fontSize'] }>`
  &,
  * {
    line-height: 1;
    width: 1em;
    height: 1em;
    ${(props) =>
      props.size &&
      css`
        font-size: ${props.size};
      `}
  }
`

export const EmojiPickerContainer = styled.div<{ shouldHide?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  border: 0;
  padding: 0;
  ${BaseMenuStyles};

  ${(props) =>
    props.shouldHide &&
    css`
      display: none;
    `}
`

export const EmojiPickerActionBar = styled.div`
  padding: 10px;
  display: flex;
`

export const EmojiPartPickerStyles = styled.div`
  /* Styling ref: https://github.com/missive/emoji-mart/blob/main/packages/emoji-mart-website/example-custom-styles.html */
  em-emoji-picker {
    --font-family: 'Inter';
    --font-size: 14px;
    --shadow: none;
  }
`
