import { IExternalEmbed } from '_entities/embed'
import { ToolbarAttrs, ToolbarAttrsData, useToolbar } from '_features/toolbar'
export const useEmbedSelection = () => {
  const _toolbar = useToolbar()
  const getSelectedEmbed = () => {
    const selectedEmbed = _toolbar.getAttr(ToolbarAttrs.EXTERNAL_EMBED)
    if (!selectedEmbed) return
    return JSON.parse(selectedEmbed) as ToolbarAttrsData[ToolbarAttrs.EXTERNAL_EMBED]
  }

  const setSelectedEmbed = (embed: IExternalEmbed) => {
    _toolbar.setAttr(ToolbarAttrs.EXTERNAL_EMBED, embed)
  }

  const removeSelectedEmbed = () => {
    _toolbar.removeAttr(ToolbarAttrs.EXTERNAL_EMBED)
  }

  return {
    getSelectedEmbed,
    setSelectedEmbed,
    removeSelectedEmbed,
  }
}
