import styled from 'styled-components'

export const ChangeUserProfileModalStyled = styled.div``
export const ModalTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  padding: 35px 12px;
`
export const ModalInnerContainer = styled.div``
export const SubtitleText = styled.div`
  text-align: center;
  color: #aeb0b4;
  padding: 0px 24px 5px 24px;
  font-size: 14px;
`

export const Button = styled.button`
  color: white;
  background-color: var(--everyday-blue);
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  border: none;
  font-weight: 600;
  font-weight: 14px;

  &:hover {
    opacity: 0.8;
  }
`
