import {
  block,
  getBlockGridWidth,
  getBlockPageId,
  getBlockSizes,
  getIndex,
  GridBlockWidth,
} from '_entities/block'
import { Doc, JSONOp } from 'ot-json1'
import { SourceKeys } from 'interfaces/editor'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { getExpandedPageBlockElement, getHeightFromSize } from '_entities/embed/InternalEmbed'
import { SidebarEditProps } from '_entities/embed/InternalEmbed/ui/EmbedEdit'
import { useToast } from 'services/helpers/useToast'
import { useAppSelector } from 'redux/hooks'
import { SIDEBAR_TOGGLE_DURATION } from '_entities/project'

export const useHeightEdit = (props: SidebarEditProps) => {
  // ** State
  const [height, setHeight] = useState<number>(0)

  // ** Redux state
  const leftMinimize = useAppSelector((state) => state.projectFile.leftMinimize)
  const rightMinimize = useAppSelector((state) => state.projectFile.rightMinimize)

  // ** Hooks
  const _submit = useSubmit()
  const _json1 = useJson1()
  const toast = useToast()

  useEffect(() => {
    const height = getHeightFromSize(props.block)

    if (!height) {
      setTimeout(() => {
        const resizableElement = getExpandedPageBlockElement(props.block)
        if (resizableElement) {
          setHeight(resizableElement.offsetHeight)
        }
      }, SIDEBAR_TOGGLE_DURATION + 10)
    } else {
      setHeight(height)
    }
  }, [props.block.data.gridWidth, leftMinimize, rightMinimize])

  useEffect(() => {
    const height = getHeightFromSize(props.block)

    if (!height) {
      const resizableElement = getExpandedPageBlockElement(props.block)
      if (resizableElement) {
        setHeight(resizableElement.offsetHeight)
      }
    } else {
      setHeight(height)
    }
  }, [props.block._id])

  const onHeightChange = (block: block, height: number) => {
    const index = getIndex(block)
    if (index === -1) return
    let op: JSONOp

    const blockWidth = getBlockGridWidth(block)

    if (getBlockSizes(block) !== undefined) {
      const newSizes =
        getBlockSizes(block)?.map((size) => {
          if (size.width === blockWidth) {
            return {
              ...size,
              height,
            }
          }
          return size
        }) || []

      if (getBlockSizes(block)?.find((size) => size.width === blockWidth) === undefined) {
        newSizes.push({
          width: blockWidth || GridBlockWidth.IN_GRID,
          height,
        })
      }

      op = _json1.getReplaceBlockDataKeyOp(index, ['sizes'], [...newSizes] as unknown as Doc)
    } else {
      const newSizes = [
        {
          width: blockWidth,
          height: height,
        },
      ]
      op = _json1.getInsertKeyInDataKeyOp(index, ['sizes'], newSizes as Doc)
    }

    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  const debounceChange = useMemo(
    () =>
      debounce((args: { block: block; height: number }) => {
        onHeightChange(args.block, args.height)
      }, 300),
    [],
  )

  const handleOnInputHeightChange = (block: block, height: number) => {
    setHeight(height)
    if (height < 200) {
      toast.info('Minimum height is 200px')
      return
    }
    if (height > 999) {
      toast.info('Maximum height is 1000px')
      return
    }
    const resizableElement = getExpandedPageBlockElement(props.block)

    if (resizableElement) {
      resizableElement.style.height = `${height}px`
    }

    debounceChange({ block, height })
  }

  return {
    onHeightChange,
    handleOnInputHeightChange,
    debounceChange,
    height,
  }
}
