// ** RTK Query Imports
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

// ** Tags
import tags from './tags'
import { envVars } from 'config/env'

export const ctaApi = createApi({
  reducerPath: 'ctaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${envVars.api.https}api/project-files`,
    credentials: 'include',
  }),
  tagTypes: [tags.CTA],
  endpoints: () => ({}),
})
