import { PageTypesEnum } from 'interfaces/projectFile'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setPageMembers, setSelectedPage } from 'redux/reducers/pageReducer'
import { createPage } from '_features/page/createPage'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { setFreshlyCreatedPageId, setIsProjectFileEmpty } from 'redux/reducers/projectFileReducer'

export const useAddPage = () => {
  const dispatch = useAppDispatch()
  const params = useParams()
  const navigate = useNavigate()
  const { getAndDispatchProjectFilePages } = useGetProjectFile()
  const pages = useAppSelector((state) => state.projectFile.pages)

  const addPage = async (type: PageTypesEnum, parentId?: string) => {
    if (pages && params.spaceId && params.id) {
      dispatch(setSelectedPage(undefined))
      dispatch(setPageMembers(undefined))

      const addPageResponse = await createPage({
        type,
        pages,
        projectId: params.id,
        parentId: parentId,
      })

      if (addPageResponse) {
        getAndDispatchProjectFilePages(params.id)
        if (type === PageTypesEnum.WHITE_BOARD) {
          dispatch(setFreshlyCreatedPageId(addPageResponse.id))
        }
        dispatch(setIsProjectFileEmpty(false))
        navigate({
          pathname: `/spaces/${params.spaceId}/project-file/${params.id}`,
          search: `?pageId=${addPageResponse.id && addPageResponse.id}`,
        })
      }
    }
  }

  return {
    addPage,
  }
}
