import styled, { css } from 'styled-components'
import { icons } from 'shared/icons'
import { CSSProperties } from 'react'
import { getColor } from 'shared/colors'

const iconStyles = `
  path {
    stroke: var(--primary-text);
    fill: transparent;
  }
`

export const H1Icon = styled(icons.h1Icon)`
  ${iconStyles}
`

export const H2Icon = styled(icons.h2Icon)`
  ${iconStyles}
`

export const LIcon = styled(icons.lIcon)`
  ${iconStyles}
`

export const MIcon = styled(icons.mIcon)`
  ${iconStyles}
`

export const SIcon = styled(icons.sIcon)`
  ${iconStyles}
`

export const TIcon = styled(icons.tIcon)`
  ${iconStyles}
`

export const XLIcon = styled(icons.xlIcon)`
  ${iconStyles}
`

export const ListBullets = styled(icons.listBullets)`
  ${iconStyles}
`

export const ListNumbers = styled(icons.listNumbers)`
  path {
    fill: var(--primary-text);
    stroke: transparent !important;
  }
`

export const CheckSquare = styled(icons.checkSquare)`
  ${iconStyles}
`

export const ImageSquare = styled(icons.imageSquare)`
  ${iconStyles}
`

export const FigmaEmbedIcon = styled(icons.figma)`
  rect {
    stroke: var(--primary-text);
    fill: transparent;
  }

  path {
    stroke: var(--primary-text);
  }
`

export const ImageAndText = styled(icons.imageAndText)`
  ${iconStyles}
`

export const LinkToPage = styled(icons.linkToPage)`
  ${iconStyles}
`

export const GoogleDoc = styled(icons.googleDoc)`
  path {
    stroke: transparent !important;
  }

  transform: scale(0.75);
`

export const GoogleSlides = styled(icons.googleSlides)`
  path {
    stroke: transparent !important;
  }

  transform: scale(0.75);
`

export const GoogleSheets = styled(icons.googleSheets)`
  path {
    stroke: transparent !important;
  }

  transform: scale(0.75);
`

export const SlideShow = styled(icons.slideshow)`
  ${iconStyles}
`

export const EmbedLink = styled(icons.embedLink)`
  ${iconStyles}
`

export const CTAIcon = styled(icons.ctaIcon)`
  ${iconStyles}
`

export const DropdownMenuItem = styled.div<{
  align?: string
  noHover?: boolean
  padding?: CSSProperties['padding']
  focused?: boolean
}>`
  color: var(--primary-text);
  cursor: pointer;
  padding: ${(props) =>
    props.padding
      ? props.padding
      : css`
                    var(--padding-8) var(--padding-12)
                    `};
  display: flex;
  align-items: ${(props) => props.align || 'center'};
  gap: 6px;
  font-size: 14px;
  z-index: 1;

  svg path {
    stroke: var(--primary-text);
  }

  ${(props) =>
    !props.noHover &&
    css`
      &:hover {
        background-color: ${getColor('--hover')} !important;
        opacity: 1;
        outline: none;
      }
    `}
  &:focus {
    outline: none;
  }

  background-color: ${(props) => (props.focused ? 'var(--primary-color)' : 'var(--overlay-modal)')};
`

export const DropdownMenu = styled.div<{ shown?: boolean; isCentered?: boolean; height?: string }>`
  display: ${(props) => (props.shown ? 'block' : 'none')};
  height: ${(props) => props.height || 'auto'};
  width: 200px;
  background-color: var(--overlay-modal);
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border: none;
  position: absolute;
  overflow-y: scroll;
  z-index: 1;

  ${(props) =>
    props.isCentered &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}
  &:focus {
    outline: none;
  }
`

export const EmptyState = styled.div`
  padding: var(--padding-12);
  color: var(--primary-text);
  font-size: 14px;
  text-align: center;
`
