import { getElements } from 'shared/lib'
import {
  CtaButton,
  getCtaBorderColor,
  getCtaBorderRadius,
  getCtaButtonColor,
  getCtaButtonHeight,
  getCtaButtonId,
  getCtaFont,
  getCtaFontSize,
  getCtaTextColor,
} from '_entities/cta'

export const updateButtonsStyling = (
  id: number,
  styling: {
    font?: string
    fontSize?: number
    textColor?: string
    buttonColor?: string
    buttonSize?: number
    borderRadius?: number
    borderColor?: string
  },
  data: CtaButton[],
) => {
  const ctaButton = data.find((button) => button.id === id)

  if (!ctaButton) return

  const buttons = Array.from(getElements(`#${getCtaButtonId(ctaButton.id)}`))

  if (buttons) {
    buttons.forEach((button) => {
      ;(button as HTMLElement).style.backgroundColor =
        styling.buttonColor || getCtaButtonColor(ctaButton)
      ;(button as HTMLElement).style.color = styling.textColor || getCtaTextColor(ctaButton)
      ;(button as HTMLElement).style.fontSize =
        (styling.fontSize || getCtaFontSize(ctaButton)) + 'px'
      ;(button as HTMLElement).style.fontFamily = styling.font || getCtaFont(ctaButton)
      ;(button as HTMLElement).style.height =
        (styling.buttonSize || getCtaButtonHeight(ctaButton)) + 'px'
      ;(button as HTMLElement).style.borderRadius =
        (styling.borderRadius || getCtaBorderRadius(ctaButton)) + 'px'
      ;(button as HTMLElement).style.borderColor =
        styling.borderColor || getCtaBorderColor(ctaButton)
    })
  }
}
