import { useEffect, useRef, useState } from 'react'
import useTags from 'services/tags/useTags'
import { useAppSelector } from 'redux/hooks'
import { getBlockPageId, getIndex, type block } from '_entities/block'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { SourceKeys, TagTypes } from 'interfaces/editor'
import { useOnClickOutside } from 'services/hooks/editor'

interface Props {
  block: block
}

export interface Tag {
  id: string
  label: string
  value: string
}

const useTag = (props: Props) => {
  const [projectTags, setProjectTags] = useState<Tag[] | null>(null)
  const [blockTags, setBlockTags] = useState<Tag[] | null>(null)
  const [isTagCreation, setIsTagCreation] = useState<boolean>(false)
  const [isTagRename, setIsTagRename] = useState<string | null>(null)
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const { submit } = useSubmit()
  const { getReplaceBlockMetaKeyOp } = useJson1()
  const inputRef = useRef<HTMLInputElement>(null)

  const _tags = useTags()

  useOnClickOutside(inputRef, () => {
    handleTagCreationClose()
    setIsTagRename(null)
  })

  useEffect(() => {
    getPageTags()
    getBlockTags()
  }, [props.block])

  const getPageTags = async () => {
    if (project) {
      const tags: Tag[] = await _tags.getTags(project.id.toString())

      tags?.map((tag) => {
        tag.label = tag.value
        tag.value = tag.id
      })

      tags.push({
        id: '0',
        label: 'New tag',
        value: TagTypes.CREATE,
      })

      setProjectTags(tags)
    }
  }

  const getBlockTags = async () => {
    if (project && props.block && props.block.meta.tags) {
      const blockTags: Tag[] = []
      await Promise.all(
        props.block.meta.tags.map(async (tag: string) => {
          const response = await _tags.getTag(project.id.toString(), tag)

          if (!response) return

          blockTags.push({
            id: response.id,
            label: response.value,
            value: response.id,
          })
        }),
      )

      setBlockTags(blockTags)
    }
  }

  const createTag = async (name: string, e: React.KeyboardEvent) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      if (project) {
        const tag = await _tags.createTag(project.id.toString(), name)
        tag.label = tag.value
        tag.value = tag.id
        blockTags && updateBlockTags([...blockTags, tag])
        getPageTags()
        setIsTagCreation(false)
      }
    }
  }

  const updateTagName = async (tagId: string, name: string, e: React.KeyboardEvent) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      if (project) {
        const tag = await _tags.updateTag(project.toString(), tagId, name)
        tag.label = tag.value
        tag.value = tag.id
        getPageTags()
        blockTags && updateBlockTags([...blockTags, tag], true)
        getBlockTags()
        setIsTagRename(null)
      }
    }
  }

  const deleteTag = async (tagId: string) => {
    if (project) {
      await _tags.deleteTag(project.id.toString(), tagId)

      getPageTags()
      getBlockTags()
      setIsTagCreation(false)
    }
  }

  const removeTag = (tagId: string) => {
    let tags = blockTags && blockTags.filter((tag) => tag.id !== tagId)
    if (tags) {
      tags = tags.filter((tag) => tag.value !== TagTypes.CREATE)
      tags = tags.filter((tag) => tag.value !== TagTypes.CREATE_INPUT)

      updateBlockTags(tags)
      setIsTagCreation(false)
    }
  }

  const handleTagCreationClose = () => {
    const tags = projectTags && [...projectTags]

    tags?.splice(-2, 1)

    setProjectTags(tags)

    setIsTagCreation(false)
  }

  const handleOnTagCreation = () => {
    const inputTag = {
      id: '0',
      label: 'New tag',
      value: TagTypes.CREATE_INPUT,
    }

    const tags = projectTags && [...projectTags]

    tags?.splice(-1, 0, inputTag)

    setProjectTags(tags)
    setIsTagCreation(true)

    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  }

  const updateBlockTags = async (tags: Tag[], isRename?: boolean) => {
    const blocksTags: string[] = [...tags.map((tag) => tag.id)]

    if (tags.some((tag) => tag.value === TagTypes.CREATE || tag.value === TagTypes.CREATE_INPUT))
      return

    const index = getIndex(props.block)

    if (index === -1) return

    const op = getReplaceBlockMetaKeyOp(index, ['tags'], [...blocksTags])

    !isRename && submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)

    setIsTagCreation(false)
  }

  const handleOnTagRenameStart = (tagId: string) => {
    setIsTagRename(tagId)

    setTimeout(() => {
      inputRef.current?.focus()
    }, 100)
  }

  return {
    projectTags,
    createTag,
    updateBlockTags,
    blockTags,
    handleOnTagCreation,
    inputRef,
    isTagCreation,
    deleteTag,
    removeTag,
    handleTagCreationClose,
    isTagRename,
    handleOnTagRenameStart,
    updateTagName,
  }
}

export default useTag

export type UseTagType = ReturnType<typeof useTag>
