import { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { type block } from '_entities/block'
import { BootstrapDropdowns } from 'interfaces/editor'
import { ReactComponent as TextBIcon } from 'assets/images/TextB.svg'
import { ReactComponent as TextColor } from 'assets/images/TextColor.svg'
import ColorDropdownMenu from 'editor/EditableBlock/BlockToolbar/ColorDropdownMenu/ColorDropdownMenu'
import TextFormattingMenu from 'editor/EditableBlock/BlockToolbar/TextFormattingMenu/TextFormattingMenu'
import LinkDropdown from '../Dropdowns/LinkDropdown/LinkDropdown'
import { IconButtonSize } from '_features/toolbar'
import AIDropdown from '../Dropdowns/AIDropdown/AIDropdown'
import { icons } from 'shared/icons'
import { useBlockAi } from 'editor/EditableBlock/BlockToolbar/useBlockAi'
import { FontSizeChanger } from '_features/text'
interface Props {
  isWhiteboard?: boolean
  block: block
}

const SharedTextToolbar = (props: Props) => {
  const [openedDropdown, setOpenedDropdown] = useState<string | null>(null)

  const _blockAi = useBlockAi({ block: props.block })

  return (
    <>
      {props.block && (
        <>
          {/* Font size */}
          <FontSizeChanger block={props.block} inputDisabled />
          {/* Bold, italics, underline, strikethrough*/}
          <Dropdown
            show={openedDropdown === BootstrapDropdowns.TEXT_STYLES}
            onToggle={(e) => {
              if (e) setOpenedDropdown(BootstrapDropdowns.TEXT_STYLES)
              else setOpenedDropdown(null)
            }}
          >
            <Dropdown.Toggle as={'div'}>
              <IconButton
                isSelected={openedDropdown === BootstrapDropdowns.TEXT_STYLES}
                width={IconButtonSize + 'px'}
                height={IconButtonSize + 'px'}
              >
                <TextBIcon />
              </IconButton>
            </Dropdown.Toggle>
            <TextFormattingMenu block={props.block} setOpenedDropdown={setOpenedDropdown} />
          </Dropdown>

          {/* Text color and background */}
          <Dropdown
            show={openedDropdown === BootstrapDropdowns.TEXT_COLOURS}
            onToggle={(e) => {
              if (e) setOpenedDropdown(BootstrapDropdowns.TEXT_COLOURS)
              else setOpenedDropdown(null)
            }}
          >
            <Dropdown.Toggle as={'div'}>
              <IconButton
                isSelected={openedDropdown === BootstrapDropdowns.TEXT_COLOURS}
                width={IconButtonSize + 'px'}
                height={IconButtonSize + 'px'}
              >
                <TextColor />
              </IconButton>
            </Dropdown.Toggle>
            <ColorDropdownMenu
              isWhiteboard={props.isWhiteboard}
              block={props.block}
              setActiveToolbarOption={setOpenedDropdown}
            />
          </Dropdown>

          {/* Link */}
          <LinkDropdown block={props.block} />

          {/* AI */}
          <AIDropdown block={props.block} />
          <IconButton
            onClick={(e) => _blockAi.convertToImageAndText(e, true)}
            width={IconButtonSize + 'px'}
            height={IconButtonSize + 'px'}
          >
            <icons.imageAi />
          </IconButton>
        </>
      )}
    </>
  )
}

export default SharedTextToolbar
