import { ctaEndpoints } from '../api'
import { useAppSelector } from 'redux/hooks'

export const useCreateCtaButton = () => {
  // ** Redux state
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  // ** Mutation methods
  const [handleCreateCtaButton] = ctaEndpoints.endpoints.createCta.useMutation()

  const createCtaButton = async () => {
    const projectFileId = projectFile?.id

    if (!projectFileId) return

    return await handleCreateCtaButton({
      projectFileId,
      title: 'Text',
      onClickAction: 'open-url',
      link: 'https://',
    }).unwrap()
  }

  return {
    createCtaButton,
  }
}
