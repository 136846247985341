import { SettingsOptionText, SettingsOptionWrapper } from '../styles'
import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import { getCtaOnClickAction, CTA_OPTIONS, CtaButtonProps } from '_entities/cta'

export const OnClickEdit = (props: CtaButtonProps) => {
  return (
    <SettingsOptionWrapper>
      <SettingsOptionText>OnClick</SettingsOptionText>

      <Wrapper display='flex' alignItems='center' gap='6px'>
        <CustomP fontWeight='500' color={getColor('--primary-text')}>
          {CTA_OPTIONS.find((option) => option.action === getCtaOnClickAction(props.button))?.label}
        </CustomP>
        <RadixDropdown trigger={<icons.arrowDown />}>
          {CTA_OPTIONS.map((option) => (
            <DropdownMenuItem
              key={option.action}
              onSelect={() =>
                props.updateCtaButton({
                  id: props.button.id,
                  onClickAction: option.action,
                })
              }
            >
              {option.label}
            </DropdownMenuItem>
          ))}
        </RadixDropdown>
      </Wrapper>
    </SettingsOptionWrapper>
  )
}
