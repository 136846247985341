import { type block } from '_entities/block'

export const getBlockInternalEmbed = (block: block) => block.data.internalEmbed

export const getBlockIsEmbedOverlayOn = (block: block) => block.data.internalEmbed?.isEmbedOverlayOn

export const getIsSlidesNavOn = (block: block) => block.data.internalEmbed?.isSlidesNavOn

export const getInternalEmbedBackgroundColor = (block: block) =>
  block.data.internalEmbed?.backgroundColor

export const getInternalEmbedBackgroundHistory = (block: block) =>
  block.data.internalEmbed?.backgroundColorHistory

export const getInternalEmbedBorderColor = (block: block) => block.data.internalEmbed?.borderColor

export const getInternalEmbedBorderWidth = (block: block) => block.data.internalEmbed?.borderWidth

export const getInternalEmbedBorderRadius = (block: block) => block.data.internalEmbed?.borderRadius

export const getBlockDocumentPage = (block: block) => block.data.documentPage
