import { type block } from '_entities/block'
import { textBlockOptions } from '../../useTextBlockOptions'
import { IconButtonRow } from '../styles'
import { IconButton } from 'components/atoms/Button/Button'

interface Props {
  block: block
}

const BoldItalicStrikethrough = (props: Props) => {
  return (
    <IconButtonRow>
      {textBlockOptions(props.block).map((item, n) => (
        <IconButton
          key={n}
          variant='sidebar'
          size='small'
          onClick={item.onClick}
          // Prevent editor focus loss
          onMouseDown={(event: React.MouseEvent<HTMLElement>) => event.preventDefault()}
        >
          {item.element}
        </IconButton>
      ))}
    </IconButtonRow>
  )
}

export default BoldItalicStrikethrough
