import React, { ComponentType, useEffect, useRef, useState } from 'react'
import { StyledSelect } from './styles'
import { colourStyles } from './stylesReactSelect'
import { DropdownIndicatorProps, GroupBase, OptionProps } from 'react-select'
import NewSelectOption from '../NewSelectOption/NewSelectOption'
import DropdownIndicator from '../DropdownIndicator/DropdownIndicator'

interface NewSelectProps {
  selectWidth?: string
  optionWidth?: string
  options?: any[]
  defaultValue?: any
  lastOptionTopBorder?: string
  containerPositionInitial?: boolean
  containerPositionRelative?: boolean
  controlMargin?: string
  controlTransform?: string
  menuListDisplay?: string
  menuTransform?: string
  menuWidth?: string
  menuHeight?: string
  menuPositionInitial?: boolean
  menuPadding?: string
  menuRight?: string
  menuTop?: string
  optionDisplay?: string
  optionPadding?: string
  optionHeight?: string
  optionJustifyContent?: string
  optionAlignContent?: string
  optionAlignItems?: string
  optionMargin?: string
  optionBorder?: boolean
  dropdownIndicatorPadding?: string
  dropdownIndicatorMargin?: string
  dropdownWidth?: string
  selectBackgroundoHover?: string
  menuIsOpen?: boolean
  value?: any
  otherIcon?: boolean
  position?: any
  placeholderColor?: string
}

export const NewSelect = ({
  selectWidth,
  optionWidth,
  options,
  defaultValue,
  containerPositionInitial,
  containerPositionRelative,
  controlMargin,
  controlTransform,
  menuListDisplay,
  menuTransform,
  menuWidth,
  menuHeight,
  menuPositionInitial,
  menuPadding,
  menuRight,
  menuTop,
  optionDisplay,
  optionPadding,
  optionHeight,
  optionJustifyContent,
  optionAlignContent,
  optionAlignItems,
  optionMargin,
  optionBorder,
  dropdownIndicatorPadding,
  dropdownIndicatorMargin,
  dropdownWidth,
  selectBackgroundoHover,
  menuIsOpen,
  otherIcon,
  value,
  lastOptionTopBorder,
  position,
  placeholderColor,
}: NewSelectProps) => {
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string | undefined | React.ReactNode | any>(
    defaultValue,
  )

  useEffect(() => {
    setSelectedValue(defaultValue || value)
  }, [value])

  const styles = colourStyles(
    selectWidth,
    optionWidth,
    isMenuOpened,
    containerPositionInitial,
    containerPositionRelative,
    controlMargin,
    menuListDisplay,
    menuTransform,
    menuWidth,
    menuHeight,
    menuPositionInitial,
    menuPadding,
    menuRight,
    menuTop,
    optionDisplay,
    optionPadding,
    optionHeight,
    optionJustifyContent,
    optionAlignContent,
    optionAlignItems,
    optionMargin,
    optionBorder,
    dropdownIndicatorPadding,
    dropdownIndicatorMargin,
    dropdownWidth,
    selectBackgroundoHover,
    lastOptionTopBorder,
  )

  return (
    <StyledSelect
      placeholder={selectedValue}
      value={selectedValue}
      components={
        otherIcon
          ? {
              Option: NewSelectOption as ComponentType<
                OptionProps<unknown, boolean, GroupBase<unknown>>
              >,
              DropdownIndicator: DropdownIndicator as ComponentType<
                DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>
              >,
            }
          : {
              Option: NewSelectOption as ComponentType<
                OptionProps<unknown, boolean, GroupBase<unknown>>
              >,
            }
      }
      styles={styles}
      closeMenuOnSelect
      onMenuOpen={() => setIsMenuOpened(true)}
      onMenuClose={() => setIsMenuOpened(false)}
      isSearchable={false}
      onChange={(choice: any) => {
        setSelectedValue(choice)
      }}
      options={options}
      menuIsOpen={menuIsOpen}
    />
  )
}
