import { getFontSizeCss } from '_entities/text'
import Dropdown from 'react-bootstrap/Dropdown'
import styled, { css } from 'styled-components'

export const FontFamilies = css`
  & .ql-font-roboto {
    font-family: 'Roboto', sans-serif !important;
  }

  & .ql-font-montserrat {
    font-family: 'Montserrat', sans-serif !important;
  }

  & .ql-font-inconsolata {
    font-family: 'Inconsolata', monospace !important;
  }

  & .ql-font-arial {
    font-family: 'Arial', sans-serif !important;
  }

  & .ql-font-pacifico {
    font-family: 'Pacifico', cursive !important;
  }

  & .ql-font-mirza {
    font-family: 'Mirza', cursive !important;
  }

  & .ql-font-ptsans {
    font-family: 'PT Sans', sans-serif !important;
  }
`

export const FontSizes = css`
  ${getFontSizeCss()}
`

export const TextPlaceholderStyles = css`
  h1:empty,
  p:empty {
    color: var(--gray-inactive);
  }

  h1:empty::after {
    color: var(--gray-inactive);
    content: 'Page title';
  }

  h2:empty::after {
    color: var(--gray-inactive);
    content: 'Write a heading';
  }

  h3:empty::after {
    color: var(--gray-inactive);
    content: 'Subheader about this page';
  }

  p:focus:empty::after {
    color: var(--gray-inactive);
    content: 'Press / for commands';
  }

  checklist:focus:empty::after {
    color: var(--gray-inactive);
    content: 'Type anything and press enter';
  }
`

export const QuillStyles = css`
  & .ql-container {
    width: 100%;
    border: none;
  }

  & .ql-toolbar {
    display: none;
  }

  & .ql-tooltip {
    border: none;
    border-radius: var(--border-radius-10);
    background-color: var(--overlay-modal);
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
    z-index: 100;

    & input {
      outline: none;
      border: 1px solid var(--gray-disabled-stroke);
      border-radius: 6px;
    }
  }

  & .ql-blank:focus::before {
    font-size: 18px;
    font-style: normal;
    color: var(--gray-inactive);
    content: 'Press / for commands';
  }

  & .ql-editor {
    padding: 0.3rem;
    user-select: text;
    color: var(--text);

    &::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    & a {
      color: var(--primary-color) !important;
      text-decoration: underline !important;
    }

    & img {
      max-width: 50%;
      float: right;
      padding-left: 1em;
    }

    ${FontFamilies}
    ${FontSizes}
  }
`

export const StyledDropdown = styled(Dropdown)<{ narrowLayout?: boolean }>`
  display: inline-block;
  width: 100%;
  border-radius: 4px;

  ${TextPlaceholderStyles}
  ${QuillStyles}
    .dropdown-menu::-webkit-scrollbar {
    display: none;
  }

  .dropdown-menu {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .dropdown-item * {
    color: var(--primary-text);
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: var(--hover) !important;
  }

  .dropdown-item.active {
    background-color: var(--primary-color) !important;
  }
`

export const BulletSpan = styled.span`
  position: relative;
  margin-right: 0.5rem;
  left: 0.3rem;
  top: 0.3rem;
`

export const NumberWrapper = styled.div`
  position: relative;
  top: 0.2rem;
  left: 0.3rem;
  font-size: 18px;
`

export const StyledDropdownWrapper = styled.div<{ isElementAtBottom?: boolean }>`
  width: 100%;

  .tag-selector-menu {
    top: ${(props) => (props.isElementAtBottom ? '-200px' : 'auto')};
  }
`

export const StyledImage = styled.img`
  width: 50%;
  margin-right: 1em;
`
