import { Container, CheckBox, StyledIconText, StyledProfileIcon, StyledShareButton } from './styles'
import Button, { IconButton } from 'components/atoms/Button/Button'
import { ReactComponent as MessageIcon } from 'assets/images/message.svg'
import useProjectFileRightSidebarTop from './useProjectFileRightSidebarTop'
import { StyledShareText } from 'components/templates/Page/styles'
import { IRoles } from 'interfaces/invite'

const ProjectFileRightSidebarTop = () => {
  const {
    handleChatOpen,
    handleToggleSliderOpen,
    handleProfileOpen,
    selectedPage,
    handlePageModalOpen,
    authUser,
  } = useProjectFileRightSidebarTop()

  return (
    <Container>
      <StyledShareButton
        variant='primary'
        size='small'
        onClick={handlePageModalOpen}
        opacity={selectedPage?.id ? '1' : '0.2'}
        width={authUser?.role?.name !== IRoles.SUPER_ADMIN ? '110px' : 'auto'}
      >
        <StyledShareText>Publish</StyledShareText>
      </StyledShareButton>

      <IconButton size='small' variant='sidebar' onClick={handleChatOpen} flexDirection='column'>
        <MessageIcon />
      </IconButton>

      <IconButton
        size='small'
        variant='sidebar'
        onClick={handleToggleSliderOpen}
        flexDirection='column'
      >
        <CheckBox />
      </IconButton>
      <IconButton size='small' variant='sidebar' onClick={handleProfileOpen} flexDirection='column'>
        <StyledProfileIcon />
      </IconButton>
    </Container>
  )
}

export default ProjectFileRightSidebarTop
