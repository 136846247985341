export const EmbedRegex = {
  // eslint-disable-next-line
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  // eslint-disable-next-line
  url: /^(http(s?):\/\/.)[-a-zA-Z0-9@:%._()\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_()\+.~#?&//=]*)$/,
  // eslint-disable-next-line
  image: /\.(jpg|jpeg|png|webp|avif|gif|svg)$/,
  // eslint-disable-next-line
  pdf: /^https:\/\/.*\.pdf$/,
  // eslint-disable-next-line
  notion: /https:\/\/www.notion.so[/a-zA-Z0-9-]*(\?pvs=\d+)?/,
  // eslint-disable-next-line
  asana: /^http(s?)\:\/\/app.asana.com\/0\/[0-9]+\/[0-9]?/,
  // eslint-disable-next-line
  miro: /^http(s?)\:\/\/miro.com\/app\/board\/[a-zA-Z0-9_=&-]+/,
  // eslint-disable-next-line
  figma: /https:\/\/([\w.-]+\.)?figma.com\/(file|proto|design)\/([0-9a-zA-Z]{22,128})(?:\/.*)?$/,
  youtube:
    // eslint-disable-next-line
    /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/,
  vimeo:
    // eslint-disable-next-line
    /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/,
  office:
    // eslint-disable-next-line
    /<iframe[^>]*src\s*=\s*["']([^"']*)["'][^>]*>/,
  officeLink:
    // eslint-disable-next-line
    /https:\/\/onedrive\.live\.com\/embed\?resid=[a-zA-Z0-9%]+&authkey=[^&]+&em=\d+/,
  embedOfficeLink:
    // eslint-disable-next-line
    /^(https:\/\/).*\.(docx|xlsx|pptx)$/,
  officeDocLink:
    // eslint-disable-next-line
    /^(https:\/\/).*\.docx$/,
  officeExcelLink:
    // eslint-disable-next-line
    /^(https:\/\/).*\.xlsx$/,
  officePowerPointLink:
    // eslint-disable-next-line
    /^(https:\/\/).*\.pptx$/,
  videoLink:
    // eslint-disable-next-line
    /^https:\/\/.*\.(mp4|avi|mkv|mov|wmv)$/i,
  googleDoc:
    // eslint-disable-next-line
    /\bhttps:\/\/docs\.google\.com\/document\/d\/[a-zA-Z0-9_-]+\/edit\b/,
  googleSlide:
    // eslint-disable-next-line
    /\bhttps:\/\/docs\.google\.com\/presentation\/d\/[a-zA-Z0-9_-]+\/edit\b/,
  googleSheets:
    // eslint-disable-next-line
    /\bhttps:\/\/docs\.google\.com\/spreadsheets\/d\/[a-zA-Z0-9_-]+\/edit\b/,
  // eslint-disable-next-line
  googleCalendar: /\bhttps:\/\/calendar\.google\.com\/calendar\/embed\?src=[a-zA-Z0-9@._-]+/,
}

export const toBlob = async (res?: Response) => {
  return await res?.blob()
}

export const checkIsEmail = (string: string) => {
  return EmbedRegex.email.test(string)
}

export const checkIsImage = async (url: string) => {
  try {
    const res = await fetch(url)
    const buff = await toBlob(res)
    return buff?.type.startsWith('image/') || buff?.type.startsWith('binary/')
  } catch (e) {
    return EmbedRegex.image.test(url)
  }
}

export const checkIsUrl = (url: string) => {
  return EmbedRegex.url.test(url)
}

export const checkIsPdf = async (url: string) => {
  try {
    const res = await fetch(url)
    const buff = await toBlob(res)
    return buff?.type.startsWith('application/pdf')
  } catch (e) {
    return EmbedRegex.pdf.test(url)
  }
}

export const checkIsNotion = (url: string) => {
  return EmbedRegex.notion.test(url)
}

export const checkIsAsana = (url: string) => {
  return EmbedRegex.asana.test(url)
}

export const checkIsMiro = (url: string) => {
  return EmbedRegex.miro.test(url)
}

export const checkIsFigma = (url: string) => {
  return EmbedRegex.figma.test(url)
}

export const checkIsYoutube = (url: string) => {
  return EmbedRegex.youtube.test(url)
}

export const checkIsVimeo = (url: string) => {
  return EmbedRegex.vimeo.test(url)
}

export const checkIsOfficeEmbedCode = (html: string) => {
  return html.match(EmbedRegex.office)
}

export const checkIsOfficeLink = (link: string) => {
  const isOfficeLink = link.match(EmbedRegex.officeLink)
  const isEmbedOfficeLink = link.match(EmbedRegex.embedOfficeLink)

  return isOfficeLink || isEmbedOfficeLink
}

export const checkIsPdfLink = (url: string) => {
  return url.match(EmbedRegex.pdf)
}

export const checkIfFile = (url: string) => {
  return url.includes('resources')
}

export const checkIsImageUrl = (url: string) => {
  return EmbedRegex.image.test(url)
}

export const checkIsOfficeDoc = (url: string) => {
  return EmbedRegex.officeDocLink.test(url)
}

export const checkIsOfficeExcel = (url: string) => {
  return EmbedRegex.officeExcelLink.test(url)
}

export const checkIsOfficePowerPoint = (url: string) => {
  return EmbedRegex.officePowerPointLink.test(url)
}

export const checkIsImageRegex = (url: string) => {
  return EmbedRegex.image.test(url)
}

export const checkIsVideoLink = (url: string): boolean => {
  return EmbedRegex.videoLink.test(url)
}

export const checkIsGoogleDocLink = (url: string) => {
  return EmbedRegex.googleDoc.test(url)
}

export const checkIsGoogleSlidesLink = (url: string) => {
  return EmbedRegex.googleSlide.test(url)
}

export const checkIsGoogleSheetsLink = (url: string) => {
  return EmbedRegex.googleSheets.test(url)
}

export const checkIsGoogleCalendarLink = (url: string) => {
  return EmbedRegex.googleCalendar.test(url)
}
