import { Size } from 'interfaces/whiteboard'

export const getAllElements = () => {
  return document.getElementsByTagName('*')
}

export const getById = (id: string) => {
  return document.getElementById(id)
}

export const getElemAttr = (id: string, name: string) => {
  const element = getById(id)
  return element?.getAttribute(name)
}

// HTLM DOM attributes can only be saved as string
export const setElemAttr = (id: string, name: string, value: string) => {
  const element = getById(id)
  element?.setAttribute(name, value)
}

export const removeElemAttr = (id: string, name: string) => {
  const element = getById(id)
  element?.removeAttribute(name)
}

export const observeResize = (el: Element, callback: () => void) => {
  const observer = new ResizeObserver(() => {
    callback()
  })
  observer.observe(el)
  return observer
}

export const observeMutation = (
  el: Element,
  callback: (mutationList: MutationRecord[], observer: MutationObserver) => void,
) => {
  const observer = new MutationObserver(callback)
  observer.observe(el, { attributes: true })
  return observer
}

export const updateElementWidth = (elemId: string, width: number | undefined) => {
  const element = getById(elemId)
  if (element) element.style.width = `${width}px`
}

export const updateElementHeight = (elemId: string, height: number | undefined) => {
  const element = getById(elemId)
  if (element) element.style.height = `${height}px`
}

export const updateElementSize = (elemId: string, size: Size) => {
  updateElementWidth(elemId, size.width as number)
  updateElementHeight(elemId, size.height)
}

export const updateElementZIndex = (elemId: string, zIndex: number) => {
  const element = getById(elemId)
  if (element) element.style.zIndex = zIndex.toString()
}

export const enablePointerEvents = (elemId: string) => {
  const element = getById(elemId)
  if (element) element.style.pointerEvents = 'auto'
}

export const disablePointerEvents = (elemId: string) => {
  const element = getById(elemId)
  if (element) element.style.pointerEvents = 'none'
}

export const getElements = (selector: string) => {
  return document.querySelectorAll(selector)
}
