import React from 'react'
import EmbedOption from '../Option/Option'
import { icons } from 'shared/icons'
import CustomP from 'components/atoms/CustomP/CustomP'
import IconList from './IconList'
import { EmbedType } from '_entities/embed'

interface Props {
  setEmbedType: React.Dispatch<React.SetStateAction<EmbedType | ''>>
}
const GenericLink = (props: Props) => {
  return (
    <>
      <EmbedOption
        icon={icons.link}
        onSelect={() => props.setEmbedType(EmbedType.GENERIC_LINK)}
        onClick={() => void 0}
      >
        Generic link
      </EmbedOption>
      <CustomP color='var(--gray-inactive)' fontWeight='300' marginBottom='8px'>
        Supported
      </CustomP>
      <IconList
        icons={[
          <icons.youtubeFull width={18} height={18} key={'upload-icon-youtube'} />,
          <icons.vimeoFull width={18} height={18} key={'upload-icon-vimeo'} />,
          <icons.web width={18} height={18} key={'upload-icon-web'} />,
        ]}
        title='Upload-icons'
      />
    </>
  )
}

export default GenericLink
