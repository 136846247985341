import { useState } from 'react'
import CustomP from 'components/atoms/CustomP/CustomP'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import DeleteRedIcon from 'assets/images/deleteRed.svg'
import { AxiosService } from 'services/axiosService/axiosService'
import { IRoles } from 'interfaces/invite'
import duplicateIcon from 'assets/images/duplicateIcon.svg'
import trashIcon from 'assets/images/trashIcon.svg'
import checkmark from 'assets/images/check.svg'
import { useToast } from 'services/helpers/useToast'
import { setFilteredSpaceMembers, setSpaceMembers } from 'redux/reducers/spaceReducer'
import { useDispatch } from 'react-redux'
import copy from 'copy-to-clipboard'
import { useAppSelector } from 'redux/hooks'

export const useSpaceMembersOptions = () => {
  const toast = useToast()
  const AxiosInstance = new AxiosService()
  const dispatch = useDispatch()
  const space = useAppSelector((state) => state.space.currentSpace)
  const spaceMembers = useAppSelector((state) => state.space.spaceMembers)
  const [isMenuHidden, setIsMenuHidden] = useState<boolean>(false)

  const updateTeamPermission = async (memberId: string, roleId: number) => {
    if (space) {
      const response = await AxiosInstance.updateSpaceMemberTeamPermission(
        roleId,
        parseInt(space.id),
        memberId,
      )
      if (response) {
        const updatedSpaceMembers = spaceMembers.map((member: any) => {
          if (member.id === memberId) {
            return {
              ...member,
              role: response.data.included[0].attributes.type,
            }
          }
          return member
        })

        const updatedFilteredSpaceMembers = spaceMembers.map((member: any) => {
          if (member.id === memberId) {
            return {
              ...member,
              role: response.data.included[0].attributes.type,
            }
          }
          return member
        })

        dispatch(setSpaceMembers(updatedSpaceMembers))
        dispatch(setFilteredSpaceMembers(updatedFilteredSpaceMembers))
        toast.success('Successfully updated team permission!')
      } else console.error('error')
    }
  }

  const removeMemberFromSpace = async (spaceId: number, memberId: string, roleId: number) => {
    setIsMenuHidden(true)
    if (roleId === 1) return toast.warning('Cannot remove space owner from this space')

    const response = await AxiosInstance.deleteSpaceMember(memberId, spaceId)
    if (response) {
      toast.success('Member removed from space')
    }
  }

  const copyEmailAddressInSpaceMembers = (email: string) => {
    if (email === 'No email adress detected' || email === 'No email')
      return toast.error('No email detected, nothing copied.')

    toast.success('Email address copied to the clipboard.')
    copy(email)
    setIsMenuHidden(true)
  }

  const rolesConfig: (
    memberId: string,
    memberRole: string,
  ) => {
    [key: string]: {
      name: JSX.Element
      label: string
      id: number
      icon?: JSX.Element
      alt?: string
      onClick?: () => void
    }[]
  } = (memberId: string, memberRole: string) => {
    return {
      Admin: [
        {
          name: (
            <CustomP className='mb-0' paddingRight='6px' paddingLeft='0px'>
              {IRoles.REMOVE_MEMBER}
            </CustomP>
          ),
          label: 'Remove member',
          id: 3,
          icon: <CustomIcon src={DeleteRedIcon} />,
          alt: 'Remove',
          onClick: () => removeMemberFromSpace(parseInt(memberId), '3', 0),
          topborder: true,
        },
      ],
      Owner: [
        {
          name: (
            <CustomP className={'mb-0'} paddingRight='6px' paddingLeft='0px'>
              {IRoles.ADMIN}
            </CustomP>
          ),
          label: 'Admin',
          icon: (
            <CustomIcon
              src={checkmark}
              padding='0 0.5rem 0 0'
              opacity={memberRole === IRoles.ADMIN ? '1' : '0'}
            />
          ),
          alt: 'Checkmark',
          id: 2,
          onClick: () => updateTeamPermission(memberId, 2),
        },
        {
          name: (
            <CustomP className={'mb-0'} paddingRight='6px' paddingLeft='0px'>
              {IRoles.MEMBER}
            </CustomP>
          ),
          label: 'Space member',
          icon: (
            <CustomIcon
              src={checkmark}
              padding='0 0.5rem 0 0'
              opacity={memberRole === IRoles.MEMBER || memberRole === IRoles.GUEST ? '1' : '0'}
            />
          ),
          alt: 'Checkmark',
          id: 3,
          onClick: () => updateTeamPermission(memberId, 3),
        },
        {
          name: (
            <CustomP
              className='mb-0'
              paddingRight='6px'
              paddingLeft='0px'
              color='var(--everyday-red)'
            >
              {IRoles.REMOVE_MEMBER}
            </CustomP>
          ),
          label: 'Remove member',
          id: 3,
          icon: <CustomIcon src={DeleteRedIcon} padding='0 0.5rem 0 0' />,
          alt: 'Remove',
          onClick: () => removeMemberFromSpace(parseInt(memberId), '3', 0),
          topborder: true,
        },
      ],
    }
  }

  const SpaceMemberDropdownOptions = (memberId: string, roleId: number, email?: string) => [
    {
      element: (
        <Wrapper
          display='flex'
          padding='0px 0px 0px 12px'
          styles={'&:hover {background: var(--hover)}'}
          className='dark-style-hover'
          margin='0.25rem 0 0 0'
        >
          <CustomIcon src={duplicateIcon} />
          <CustomP
            hoverbgcolor='&:hover {background:var(--hover)}'
            className='ps-2 py-2'
            marginBottom='0'
            borderradius='5.5px'
            fontWeight='500'
          >
            Copy email
          </CustomP>
        </Wrapper>
      ),
      id: 9,
      onClick: () => copyEmailAddressInSpaceMembers(email || 'No email adress detected'),
      eventKey: 1,
    },
    {
      element: (
        <Wrapper
          display='flex'
          padding='0px 0px 0px 12px'
          styles={'&:hover {background: var(--hover)}'}
          className='dark-style-hover'
          margin='0.25rem 0 0 0'
          bordertop='3px solid var(--gray4)'
        >
          <CustomIcon src={trashIcon} />
          <CustomP
            hoverbgcolor='&:hover {background:var(--hover)}'
            className='ps-2 py-2'
            marginBottom='0'
            borderradius='5.5px'
            fontWeight='500'
            color='var(--everyday-red) !important'
          >
            Remove member
          </CustomP>
        </Wrapper>
      ),
      id: 9,
      onClick: () => removeMemberFromSpace(parseInt(space?.id as string), memberId, roleId),
      eventKey: 2,
    },
  ]

  return {
    rolesConfig,
    SpaceMemberDropdownOptions,
    isMenuHidden,
    setIsMenuHidden,
  }
}
