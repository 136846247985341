import React from 'react'
import CustomP from '../CustomP/CustomP'

type Props = {
  text: string
}

const TextDanger: React.FC<Props> = ({ text }) => {
  return <CustomP className='text-danger' text={text} />
}

export default TextDanger
