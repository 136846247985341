export type QuillHeadings = 1 | 2 | 3 | 4 | 5 | 6

export enum TextFontFamily {
  INCONSOLATA = 'inconsolata',
  ROBOTO = 'roboto',
  PACIFICO = 'pacifico',
  PT_SANS = 'ptsans',
  MONTSERRAT = 'montserrat',
  ARIAL = 'arial',
  MIRZA = 'mirza',
}

export enum TextFontSizes {
  H1 = 1,
  H2 = 2,
  H3 = 3,
  H4 = 4,
  H5 = 5,
  H6 = 6,
  P = 'p',
}
