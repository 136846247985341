import { useStage, StageAttrs, StageAttrsData, useTransformer } from '_features/canvas'
import { type block, getHtmlPortalId, getIndex } from '_entities/block'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { useGrid, useKonvaNode } from '_entities/whiteboard'
import { KonvaEventObject } from 'konva/lib/Node'
import { Size } from 'interfaces/whiteboard'
import { updateElementSize } from 'shared/lib'

export const INITIAL_SCALE = 0.1
export const useShapeCreation = () => {
  const _stage = useStage()
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _grid = useGrid()
  const _konvaNode = useKonvaNode()
  const _transformer = useTransformer()

  const getIsShapeMouseDown = (pageId: string) => {
    return _stage.getStageAttr(pageId, StageAttrs.IS_SHAPE_MOUSE_DOWN)
  }

  const setIsShapeMouseDown = (pageId: string) => {
    _stage.setStageAttr(pageId, StageAttrs.IS_SHAPE_MOUSE_DOWN, true)
  }

  const removeIsShapeMouseDown = (pageId: string) => {
    _stage.setStageAttr(pageId, StageAttrs.IS_SHAPE_MOUSE_DOWN, false)
  }

  const getShapeCreationDragBlock = (pageId: string) => {
    return _stage.getStageAttr(
      pageId,
      StageAttrs.SHAPE_CREATED_BY_DRAG,
    ) as StageAttrsData[StageAttrs.SHAPE_CREATED_BY_DRAG]
  }

  const getShapeCreationDragNode = (pageId: string) => {
    const shapeCreationDragBlock = getShapeCreationDragBlock(pageId)
    if (!shapeCreationDragBlock) return
    return _stage.getNodeFromBlock(pageId, shapeCreationDragBlock._id)
  }

  const setShapeCreationDragBlock = (pageId: string, block: block) => {
    _stage.setStageAttr(pageId, StageAttrs.SHAPE_CREATED_BY_DRAG, block)
  }

  const removeShapeCreationDragBlock = (pageId: string) => {
    _stage.setStageAttr(pageId, StageAttrs.SHAPE_CREATED_BY_DRAG, undefined)
  }

  const getShapeCreationBlockPosition = (pageId: string) => {
    const shapeCreationDragBlock = getShapeCreationDragBlock(pageId)
    if (!shapeCreationDragBlock) return
    return {
      x: shapeCreationDragBlock.data.x,
      y: shapeCreationDragBlock.data.y,
    }
  }

  const getSize = (pageId: string): Size => {
    const realPointerPosition = _stage.getScaledPointerPosition(pageId)
    const position = getShapeCreationBlockPosition(pageId)
    if (!realPointerPosition || !position || !position.x || !position.y)
      return {
        width: 0,
        height: 0,
      }
    return {
      width: realPointerPosition.x - position.x,
      height: realPointerPosition.y - position.y,
    }
  }

  const setShapeBlockScale = (pageId: string) => {
    const pointer = _stage.getScaledPointerPosition(pageId)
    const draggedBlock = getShapeCreationDragBlock(pageId)
    const groupNode = getShapeCreationDragNode(pageId)
    const size = getSize(pageId)
    const absSize = {
      width: Math.abs(size.width as number),
      height: Math.abs(size.height),
    }
    if (!size || !draggedBlock || !groupNode) return
    const backgroundNode = _konvaNode.getImageNodeFromGroup(groupNode)
    updateElementSize(getHtmlPortalId(draggedBlock), absSize)
    backgroundNode.size(absSize)
    _transformer.forceUpdateTransformer(pageId)
    // If we are moving the shape to the left or up, we need to move the group node
    if (size.width < 0) {
      groupNode.x(pointer?.x || groupNode.x())
    }
    if (size.height < 0) {
      groupNode.y(pointer?.y || groupNode.y())
    }
  }

  const resizeShapeOnCreation = (
    pageId: string,
    evt: KonvaEventObject<MouseEvent | TouchEvent>,
  ) => {
    _grid.moveShadowWithTransformer(evt)
    setShapeBlockScale(pageId)
  }

  const saveNewShapeBlockAfterDrag = (pageId: string) => {
    const block = getShapeCreationDragBlock(pageId)
    if (!block) return
    const groupNode = _stage.getNodeFromBlock(pageId, block._id)
    if (!groupNode) return
    const backgroundNode = _konvaNode.getImageNodeFromGroup(groupNode)
    const index = getIndex(block)
    const snapPosition = _grid.calculateGridCoordinates({ x: groupNode.x(), y: groupNode.y() })
    const snapSize = _grid.getSnapSize({
      width: backgroundNode.width(),
      height: backgroundNode.height(),
    })
    const updatedBlock = {
      ...block,
      data: {
        ...block.data,
        x: snapPosition.x,
        y: snapPosition.y,
        width: snapSize?.width,
        height: snapSize?.height,
      },
    }
    const op = _json1.replaceBlock(updatedBlock, index, block)
    _submit.submit(pageId, op, SourceKeys.UPDATE_BLOCK)
  }

  return {
    getIsShapeMouseDown,
    setIsShapeMouseDown,
    removeIsShapeMouseDown,
    getShapeCreationDragBlock,
    getShapeCreationDragNode,
    setShapeCreationDragBlock,
    removeShapeCreationDragBlock,
    resizeShapeOnCreation,
    saveNewShapeBlockAfterDrag,
  }
}
