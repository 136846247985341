import { icons } from 'shared/icons'
import styled from 'styled-components'
import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

export const SourceContainer = styled.div`
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  font-size: 12px;
  padding: 12px 0 0 0;
  position: relative;
  cursor: pointer;

  &:hover {
    background: var(--hover);
    ${IconButton} {
      opacity: 1;
    }
  }

  ${IconButton} {
    position: absolute;
    right: 4px;
    top: 0;
    opacity: 0;

    &[data-state='open'] {
      opacity: 1 !important;
    }
  }
`

export const PageNameWrapper = styled.div`
  border-radius: 10px;
  border-top: 1px solid #d6d6d6;
  font-size: 12px;
  padding: 4px 8px;
  background: var(--hover);
  display: flex;
  gap: 6px;
  justify-content: right;
  position: relative;
`

export const StyledImage = styled.img`
  border-radius: 15px;
  width: 92.5%;
`

export const NumberInList = styled.div`
  margin-right: 2px;
`

export const StyledUpdateIcon = styled(icons.update)`
  position: absolute;
  left: 4px;
  cursor: pointer;
`
