interface Props {
  id: string
}

const GenerateIcon = (props: Props) => {
  return (
    <svg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.66663 2.66732H3.79997C3.05323 2.66732 2.67958 2.66732 2.39437 2.81264C2.14349 2.94047 1.93966 3.1443 1.81183 3.39518C1.6665 3.6804 1.6665 4.05404 1.6665 4.80078V11.2008C1.6665 11.9475 1.6665 12.3207 1.81183 12.6059C1.93966 12.8568 2.14349 13.061 2.39437 13.1888C2.67931 13.334 3.0525 13.334 3.79778 13.334H10.2019C10.9472 13.334 11.3198 13.334 11.6048 13.1888C11.8556 13.061 12.0602 12.8566 12.188 12.6057C12.3332 12.3208 12.3332 11.948 12.3332 11.2027V9.33398M9.6665 3.33398L5.6665 7.33398V9.33398H7.6665L11.6665 5.33398M9.6665 3.33398L11.6665 1.33398L13.6665 3.33398L11.6665 5.33398M9.6665 3.33398L11.6665 5.33398'
        stroke={`url(#${props.id})`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <linearGradient
          id={props.id}
          x1='7.6665'
          y1='1.33398'
          x2='7.6665'
          y2='13.334'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2C6BFF' />
          <stop offset='0.557292' stopColor='#9CACFF' />
          <stop offset='1' stopColor='#FC4AFF' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default GenerateIcon
