import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { AxiosService } from 'services/axiosService/axiosService'
import { setMembers, setMyProjectFileRole } from 'redux/reducers/projectFileReducer'
import { useToast } from 'services/helpers/useToast'
import { IProjectFileMember } from 'interfaces/projectFile'

export const useProjectMembersList = () => {
  const toast = useToast()

  const customDropdownWidth = '151px'
  const customDropdownRight = '140px'
  const customDropdownBottom = '20px'
  const pendingDropdownWidth = '151px'
  const pendingDropdownRight = '140px'
  const pendingDropdownBottom = '62px'
  const pendingDropdownOptionPadding = '8px 12px'
  const pendingDropdownRotateZ = '90deg'
  const dispatch = useAppDispatch()
  const AxiosInstance = new AxiosService()
  const allMembers = useAppSelector((state) => state.projectFile.allMembers)
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const currentUser = useAppSelector((state) => state.global.user)

  const getProjectRole = (projectMembers?: IProjectFileMember[], userId?: number) => {
    const selectedMember = projectMembers?.find((member) => member.user.id === userId)
    return selectedMember?.role.type
  }

  const myProjectFileRole = currentUser ? getProjectRole(allMembers, currentUser?.id) : null
  dispatch(setMyProjectFileRole(myProjectFileRole))

  const updateMemberRole = async (projectFileId: string, memberId: string, roleId: number) => {
    const response = await AxiosInstance.updateProjectFileMember(projectFileId, memberId, roleId)
    if (response) toast.success('Member role updated!')
  }
  const setProjectFileMembers = async () => {
    if (!project) return
    const projectMembers = await AxiosInstance.getProjectFileMembers(parseInt(project.id) || -1)

    dispatch(setMembers(projectMembers))
  }

  const deletePageMember = async (projectFileId: number, memberId: string) => {
    const response = await AxiosInstance.deleteProjecFileMember(projectFileId, memberId)

    if (response) {
      setProjectFileMembers()
      toast.success('Member removed!')
    }
  }

  const resendInvite = async (projectFileId: number, inviteToken: string) => {
    const response = await AxiosInstance.resendInvitationLink(projectFileId, inviteToken)
    if (response) toast.success('Link resended')
  }

  return {
    updateMemberRole,
    deletePageMember,
    resendInvite,
    customDropdownWidth,
    customDropdownRight,
    customDropdownBottom,
    pendingDropdownWidth,
    pendingDropdownRight,
    pendingDropdownBottom,
    pendingDropdownOptionPadding,
    pendingDropdownRotateZ,
    myProjectFileRole,
  }
}
