import styled, { css } from 'styled-components'

export const ShapeStrokeDropdownSection = styled.div<{ disabled?: boolean }>`
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: space-around;
  width: 100%;
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.3;
    `}

  padding: 8px 0;
`
