import styled from 'styled-components'

export const StyledChip = styled.div`
  width: auto;
  color: var(--secondary-text);
  background-color: var(--gray4);
  font-size: 14px;
  border-radius: 7px;
  padding: 4px 8px;
  display: flex;
  gap: 6px;
  align-items: center;
`
