import styled from 'styled-components'

type PlainInfoContainerType = {
  margin?: string
  colour?: string
}

export const StyledRightSidebarTitleCss = styled.h6`
  font-size: 18px;
  font-weight: bold;
`

export const UpgradeButton = styled.button`
  border-radius: 10px;
  border: none;
  display: flex;
  height: 28px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;

  text-align: center;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.02em;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  color: white;
  background: var(--everyday-blue);
  :hover {
    opacity: 0.8;
  }
`
export const PlanInfoContaier = styled.div<PlainInfoContainerType>`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: ${(props) => (props.colour ? props.colour : '#403d3d')};
  margin: ${(props) => (props.margin ? props.margin : '')};
  margin-bottom: 24px;
`

export const SpacePlansAndBillingStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-top: -12px;
  padding-top: 32px;
  border-top: 3px solid var(--gray4);

  & > * {
    font-style: normal;
  }

  font-style: normal;
  margin-left: 3.4rem;
`

export const SeparatorContainer = styled.div`
  width: 100%;
  margin-top: 0;

  & > * {
    align-items: center;
    padding: 0 24px 0 0 !important;
  }
`

export const SpaceInfoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  & > div {
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
  }
`
export const SpaceSettingsLabelStyled = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: var(--gray2);
`
export const BillingLabelStyled = styled.label`
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--gray2);
`
export const DeleteContainer = styled.label`
  display: flex;
  flex-direction: column;
  gap: 4px;
`
