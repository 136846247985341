import styled, { css } from 'styled-components'

export const StyledFirstInputWrapper = css`
  margin-right: 1rem;
`

export const StyledSecondInputWrapper = css`
  margin-left: 1rem;
`

export const Form = styled.form`
  & input {
    width: 100% !important;
  }
`

export const HorizontalDivider = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 425px) {
    flex-direction: column;
    gap: 0.25rem;
  }
`

export const InputWrapperPassword = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

export const InputWrapperEmail = styled.div``
