import axios, { AxiosRequestConfig, AxiosResponse, ResponseType } from 'axios'
import { useToast } from 'services/helpers/useToast'

const toast = useToast()

interface AxiosRequestConfigWithRetry extends AxiosRequestConfig {
  retry?: number
  retryDelay?: number
}

export class HttpAdapter {
  handleAPIError() {
    toast.error('Your request did not go through. Please try again later.')
  }

  handleNoInternet() {
    if (navigator.onLine) return false
    toast.warning('You dont have an internet connection.')
    return true
  }

  async axiosFetch(
    axiosConfig: AxiosRequestConfigWithRetry,
    shouldRedirect?: boolean,
    shouldShowToastMessage?: boolean,
    shouldReturnError?: boolean,
  ) {
    axios.interceptors.response.use(undefined, (err) => {
      const { config, message } = err
      if (!config || !config.retry) {
        return Promise.reject(err)
      }

      // retry while Network timeout or Network Error
      if (!(message.includes('timeout') || message.includes('Network Error'))) {
        return Promise.reject(err)
      }
      config.retry -= 1
      const delayRetryRequest = new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve()
        }, config.retryDelay || 1000)
      })
      return delayRetryRequest.then(() => axios(config))
    })

    try {
      const response = await axios(axiosConfig)
      if (response.status >= 200 && response.status < 300) return response

      if (response.status >= 300) this.handleAPIError()
    } catch (e: any) {
      // eslint-disable-next-line no-console
      if (e && e.response && e.response.data && e.response.data.errors && e.response.data.errors[0])
        if (shouldShowToastMessage) {
          toast.error(e.response.data.errors[0].message)
        }
      if (shouldRedirect) {
        if (e.response.status === 401) window.location.replace('/signin')

        if (e.response.status === 403) window.location.replace('/f')

        if (e.response.status === 500) window.location.replace('/i')

        if (e.response.status === 502) window.location.replace('/b')

        if (e.response.status === 503) window.location.replace('/s')
      }

      if (this.handleNoInternet()) console.error('Error when fetching', e)
      if (shouldReturnError) return e
    }
  }

  axiosConfig({
    method,
    url,
    data,
    headers,
    onUploadProgress,
    onDownloadProgress,
    responseType,
  }: AxiosRequestConfigWithRetry) {
    return {
      method: method,
      url: url,
      data: data,
      responseType,
      headers,
      onUploadProgress,
      onDownloadProgress,
      withCredentials: true,
      retry: 10,
      retryDelay: 1000,
    }
  }

  async fetchResponse(
    data: AxiosRequestConfig,
    shouldRedirect?: boolean,
    shouldShowToastMessage?: boolean,
    shouldReturnError?: boolean,
  ) {
    return this.axiosFetch(
      this.axiosConfig(data),
      shouldRedirect,
      shouldShowToastMessage,
      shouldReturnError,
    )
  }

  async GET(
    url: string,
    shouldRedirect?: boolean,
    shouldShowToastMessage?: boolean,
    shouldReturnError?: boolean,
    responseType?: ResponseType,
  ): Promise<AxiosResponse | undefined> {
    return await this.fetchResponse(
      { method: 'get', url: url, responseType },
      shouldRedirect,
      shouldShowToastMessage,
      shouldReturnError,
    )
  }

  async POST(
    url: string,
    data: any,
    headers?: any,
    onUploadProgress?: (progressEvent: any) => void,
    onDownloadProgress?: (progressEvent: any) => void,
    shouldRedirect?: boolean,
    shouldShowToastMessage?: boolean,
  ): Promise<AxiosResponse | undefined> {
    return await this.fetchResponse(
      {
        method: 'post',
        url: url,
        data: data,
        headers: headers,
        onUploadProgress: onUploadProgress,
        onDownloadProgress,
      },
      shouldRedirect,
      shouldShowToastMessage,
    )
  }

  async PATCH(url: string, data: any): Promise<AxiosResponse | undefined> {
    return await this.fetchResponse({ method: 'patch', url: url, data: data })
  }

  async DELETE(url: string): Promise<AxiosResponse | undefined> {
    return await this.fetchResponse({ method: 'delete', url: url })
  }
}
