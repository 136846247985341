import { useState } from 'react'
import { AxiosService } from 'services/axiosService/axiosService'

export const useAuth = () => {
  const AxiosInstance = new AxiosService()

  const [isInvalidResponse, setIsInvalidResponse] = useState<boolean>(false)

  const signIn = async (email: string, password: string) => {
    setIsInvalidResponse(false)
    const response = await AxiosInstance.signIn(email, password)
    if (!response) setIsInvalidResponse(true)
    return response
  }

  const signUp = async (firstName: string, lastName: string, email: string, password: string) => {
    const response = await AxiosInstance.signUp(firstName, lastName, email, password)
    return response
  }

  return {
    signIn,
    signUp,
    isInvalidResponse,
  }
}
