import { IGroup } from 'interfaces/taskManager'
import { setSelectedTaskDocument, setSelectedTaskId } from 'redux/reducers/taskManagerReducer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import convertToPlain from 'utils/editor/convertToPlain'
import { block } from '_entities/block/model/types'
import { useTextBlock } from '_entities/text'
import { tasksApi } from '../api'
import { getTaskManager } from '_widgets/TaskManager'

export const useCreateTask = () => {
  const [handleCreateTask] = tasksApi.endpoints.createTask.useMutation()

  // ** Redux
  const propertyDefinitions = useAppSelector((state) => state.taskManager.propertyDefinitions)
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  // ** Hooks
  const dispatch = useAppDispatch()
  const _textBlock = useTextBlock()

  const createTask = async (column?: IGroup) => {
    const manager = getTaskManager(projectFile)

    const managerId = manager?.id

    if (!managerId) return

    dispatch(setSelectedTaskId(undefined))
    dispatch(setSelectedTaskDocument(undefined))
    if (column) {
      const propertyDefinition = propertyDefinitions?.find(
        (propertyDefinition) => propertyDefinition.id === column.property?.propertyDefinition.id,
      )

      const statusValue = propertyDefinition?.statusValues.find(
        (statusValue) => statusValue.value === column.name,
      )

      await handleCreateTask({
        data: {
          title: 'Untitled',
          type: 'document',
          statusValueId: statusValue?.id,
        },
        managerId: managerId,
      }).unwrap()
    } else {
      await handleCreateTask({
        data: {
          title: 'Untitled',
          type: 'document',
        },
        managerId: managerId,
      }).unwrap()
    }
  }

  const createReferencedTask = async (
    block: block,
    positionIndex?: number,
    statusValueId?: number,
  ) => {
    const manager = getTaskManager(projectFile)

    const managerId = manager?.id

    if (!managerId) return

    const blockHtml = convertToPlain(_textBlock.convertToHtml(block))
    const taskName = blockHtml || `New task from ${block?.data.tag}`

    await handleCreateTask({
      data: {
        title: taskName,
        type: 'document',
        blockId: block._id,
        positionIndex,
        statusValueId,
        pageId: block.meta.pageId,
      },
      managerId,
    }).unwrap()
  }

  return {
    createTask,
    createReferencedTask,
  }
}
