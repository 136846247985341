import React from 'react'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import useFileUpload from '_entities/resources/model/useFileUpload'
import { PdfToolbar } from './PdfToolbar'

type FullPageEmbedPropsType = {
  embedUrl: string
  name: string
}

export const FullPagePdfEmbed = (props: FullPageEmbedPropsType) => {
  const toolbarPluginInstance = toolbarPlugin()
  const { downloadFile } = useFileUpload()

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.6.172/build/pdf.worker.min.js'>
      <PdfToolbar
        fileUrl={props.embedUrl}
        downloadFile={downloadFile}
        name={props.name}
        toolbarPluginInstance={toolbarPluginInstance}
      />
      <Viewer fileUrl={props.embedUrl} plugins={[toolbarPluginInstance]} />
    </Worker>
  )
}
