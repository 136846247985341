import { StyledDots } from './styles'

interface Props {
  onClick?: React.MouseEventHandler<HTMLElement> | undefined
  color?: string
}

const Dots = ({ onClick, color }: Props) => {
  return <StyledDots className='fa-solid fa-ellipsis' onClick={onClick} color={color} />
}

export default Dots
