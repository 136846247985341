const GOOGLE = 'google'
const CTA = 'cta'
const TASK = 'task'
const MANAGER = 'manager'
const PROPERTY = 'property'
const LANDING_PAGE = 'landing-page'
const PAGE = 'page'

export default {
  GOOGLE,
  TASK,
  MANAGER,
  PROPERTY,
  CTA,
  LANDING_PAGE,
  PAGE,
}
