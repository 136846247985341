import { ExpandedProps } from '../model/types'
import { NoAccess, PagePreviewOptions, getExpandedPageBlockId } from '_entities/embed'
import { Document } from '_widgets/Document'
import { StyledPageWrapper } from './styles'
import { usePermissions } from '_entities/user'

export const InternalDocument = (props: ExpandedProps) => {
  const _permissions = usePermissions()
  return (
    <StyledPageWrapper
      width={props.width}
      height={props.height}
      id={getExpandedPageBlockId(props.block)}
      onClick={props.onClick}
      isDocumentEmbed
    >
      {props.blockPage && (
        <Document
          pageId={props.blockPage.id}
          isWhiteboard={props.isWhiteboard}
          isPreview={props.isPreview}
          isEmbed
        />
      )}
      {/* {props.isEmbed && <NoViewableContent />} */}
      {props.blockPage && !_permissions.canViewPage(props.blockPage.id) && props.blockPage && (
        <NoAccess pageId={props.blockPage.id} />
      )}
      <PagePreviewOptions
        Icon={props.Icon}
        isEmbeddedDocumentExpanded={props.isExpanded}
        handleEmbeddedMaximize={props.maximize}
        handleEmbeddedMinimize={props.minimize}
        title={props.title}
        onTitleClick={props.onTitleClick}
      />
    </StyledPageWrapper>
  )
}
