import React from 'react'
import AuthInput from 'components/atoms/AuthInput/AuthInput'
import PasswordStrenghtBar from 'components/atoms/PasswordStrenghtBar/PasswordStrenghtBar'
import Image from 'components/atoms/Image/Image'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import { Link } from 'react-router-dom'
import loadingSpinner from 'assets/images/loadingSpinner.gif'
import CustomP from 'components/atoms/CustomP/CustomP'
import { Form } from './styles'

import emailIcon from 'assets/images/emailIcon.svg'
import emailRed from 'assets/images/emailRed.svg'
import lockProtect from 'assets/images/lockProtect.svg'
import lockProtectRed from 'assets/images/lockRed.svg'

type Props = {
  onSubmit: React.FormEventHandler<HTMLFormElement>
  validateEmail: () => boolean
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setPassword: React.Dispatch<React.SetStateAction<string>>
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>
  password: string
  passwordShown: boolean
  checkIfPasswordSafe: () => boolean
  togglePassword: () => void
  passwordMismatchMessage: string
  error: string
  successMessage: string
  loading: boolean
}

const index: React.FC<Props> = ({
  onSubmit,
  validateEmail,
  setEmail,
  password,
  setPassword,
  passwordShown,
  checkIfPasswordSafe,
  togglePassword,
  setConfirmPassword,
  passwordMismatchMessage,
  error,
  successMessage,
  loading,
}) => {
  return (
    <Form onSubmit={onSubmit} id='resetPasswordForm'>
      <>
        <AuthInput
          type='email'
          placeholder='Email'
          id='email'
          isRequired={true}
          className={
            !validateEmail() ? 'is-invalid mb-4 placeholderDarker' : 'mb-4 placeholderDarker'
          }
          onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
            setEmail(e.target.value)
          }}
          iconLeft={emailIcon}
          iconLeftError={emailRed}
          paddingLeft='2.5rem'
        />

        {!validateEmail() && (
          <p className='text-danger mb-4'>Please enter a valid E-mail address.</p>
        )}

        <AuthInput
          type={passwordShown ? 'text' : 'password'}
          id='password'
          placeholder='New Password'
          className={
            !checkIfPasswordSafe() ? 'is-invalid mb-4 placeholderDarker' : 'mb-4 placeholderDarker'
          }
          isRequired={true}
          onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
            setPassword(e.target.value)
          }}
          iconLeft={lockProtect}
          iconLeftError={lockProtectRed}
          paddingLeft='2.5rem'
        />

        <PasswordStrenghtBar password={password} />

        <AuthInput
          type={passwordShown ? 'text' : 'password'}
          id='confirmedPassword'
          placeholder='Confirm Password'
          className='mb-4 placeholderDarker'
          isRequired={true}
          onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
            setConfirmPassword(e.target.value)
          }}
          iconLeft={lockProtect}
          iconLeftError={lockProtect}
          paddingLeft='2.5rem'
        />

        {passwordMismatchMessage && (
          <span>
            <p className='text-danger mb-4'>{passwordMismatchMessage}</p>
          </span>
        )}
        {error && (
          <span>
            <p className='text-danger mb-4'>{error}</p>
          </span>
        )}
        {successMessage && (
          <span>
            <p className='text-success mb-4'>{successMessage}</p>
          </span>
        )}
        {loading && (
          <span className='d-flex justify-content-center align-items-center'>
            <Image src={loadingSpinner} alt='loading spinner' className='loading-spinner mb-4' />
          </span>
        )}
        <CustomButton
          className='btn w-75'
          type='submit'
          height='40px'
          background='var(--primary-color)'
          color='var(--white) !important'
        >
          <CustomP className='mb-0'>Create a new password</CustomP>
        </CustomButton>
        <p className='mb-0 mt-3 text-secondary'>
          <Link to='/signin'> Sign in</Link>
        </p>
      </>
    </Form>
  )
}

export default index
