import { WhiteboardBlockProps, getImageUrl } from '_entities/block'
import { useKonvaNode } from '_entities/whiteboard'
import Resizable from 'whiteboard/Resizable/Resizable'
import { useAppSelector } from 'redux/hooks'
import { Image } from './Image'
import TransparentImage from 'assets/images/transparent-image.png'
import { useStage } from '_features/canvas'
import { getImageElementId } from '../lib/getters'

export const ImageBlock = (props: WhiteboardBlockProps) => {
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const _konvaNode = useKonvaNode()
  const _stage = useStage()

  const imageUrl = getImageUrl(props.block)
  const shouldEnable = () => {
    return !props.isPreview && selectedBlock?._id === props.block._id
  }
  return (
    <Resizable {...props} shouldEnable={shouldEnable()}>
      <Image
        id={getImageElementId(props.block)}
        alt='whiteboard-image'
        src={imageUrl || TransparentImage}
        isAiGenerating={props.block.data.isAiGenerating}
        onMouseDown={(e) => {
          const stage = _stage.getStage(props.block.meta.pageId)
          if (!stage) return
          stage.setPointersPositions(e)
          const group = _konvaNode.getGroupNode(props.block)
          group?.startDrag()
        }}
        onMouseUp={() => {
          const group = _konvaNode.getGroupNode(props.block)
          group?.stopDrag()
        }}
      />
    </Resizable>
  )
}
