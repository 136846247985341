import React from 'react'
import { Form } from 'react-bootstrap'
import { RadioButtonStyled } from './styles'
type Props = {
  label: string
  id?: string
  disabled?: boolean
  name?: string
  fontSize?: string
  fontWeight?: string
  marginBlock?: string
}

const RadioButton: React.FC<Props> = ({
  label,
  id,
  disabled,
  name,
  fontSize,
  fontWeight,
  marginBlock,
}) => {
  return (
    <RadioButtonStyled fontSize={fontSize} fontWeight={fontWeight} marginBlock={marginBlock}>
      <Form.Check type={'radio'} id={id} label={label} disabled={disabled} name={name} />
    </RadioButtonStyled>
  )
}

export default RadioButton
