import { Accordion } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledDraggableListContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const StyledListTaskPropertiesContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  max-width: 50%;
  overflow-x: auto;
`

export const StyledListAccordion = styled(Accordion)`
  .accordion-header {
    button {
      background: transparent;
    }
  }
`
