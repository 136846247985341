import useOnPageClick from 'utils/useOnPageClick'
import { usePagesNavigation } from '_widgets/LeftSidebar/model/usePagesNavigation'
import { TasksButtonSidebarWrapper } from '../SidebarList/styles'

import { CheckBox } from 'components/organisms/ProjectFileRightSidebarTop/styles'
import React from 'react'
import { useAppSelector } from 'redux/hooks'

const SidebarItemTaskManager = () => {
  const _pagesNavigation = usePagesNavigation()
  const _onPageClick = useOnPageClick()
  const selectedPage = useAppSelector((state) => state.page.selectedPage)

  return (
    <>
      {_pagesNavigation.taskManagerPage?.id && (
        <TasksButtonSidebarWrapper
          key={_pagesNavigation.taskManagerPage.id}
          onClick={() =>
            _pagesNavigation.taskManagerPage &&
            _onPageClick(_pagesNavigation.taskManagerPage.id as string)
          }
          selected={selectedPage && selectedPage.id === _pagesNavigation.taskManagerPage.id}
        >
          <CheckBox width={20} />
          Tasks
        </TasksButtonSidebarWrapper>
      )}
    </>
  )
}

export default SidebarItemTaskManager
