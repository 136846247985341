import { PaletteOverview, PaletteWrapper } from './styles'
import { IPalette, IPaletteColor } from 'interfaces/settings'
import React from 'react'
import useAdminPalette from './useAdminPalette'
import useAdminDropdownOptions from '../AdminColorSettings/useDropdownOptions'
import PaletteName from 'components/molecules/PaletteName/PaletteName'
import PaletteColors from 'components/molecules/PaletteColors/PaletteColors'
import ColorPaletteDropdown from 'components/molecules/ColorPaletteDropdown/ColorPaletteDropdown'
import DetailedColors from 'components/molecules/DetailedColors/DetailedColors'

interface IProps {
  palette: IPalette
  selected: string
  handleSelectPalette: (palette: IPalette) => void
  handleExpandOnClick: (palette: IPalette) => void
  expanded: string
  fetchPalettes: () => Promise<IPalette[]>
  setDefaultPalette: () => void
  handleResetColorToDefault: (palette: IPalette) => void
}

const AdminPalette = ({
  palette,
  selected,
  handleSelectPalette,
  handleExpandOnClick,
  expanded,
  fetchPalettes,
  setDefaultPalette,
  handleResetColorToDefault,
}: IProps) => {
  const {
    colorToChange,
    pickedColor,
    handleSelectColorToChange,
    handleChange,
    closeColorPicker,
    handleDuplicatePalette,
    deletePalette,
    handleEditPalette,
    isEdit,
    renameInputPosition,
    setRenameInputPosition,
    loading,
    isOpen,
    convertToTitleCase,
    userPalette,
    handleRenameColorPalette,
  } = useAdminPalette({ palette, fetchPalettes, setDefaultPalette })

  const { defaultDropdownOptions } = useAdminDropdownOptions({
    palette,
    handleDuplicatePalette,
    deletePalette,
    handleEditPalette,
    handleResetColorToDefault,
  })

  const numberOfVisiblePalettes = 4

  if (!userPalette) {
    return <></>
  }

  return (
    <PaletteWrapper
      isSelected={selected === userPalette.id}
      onClick={() => handleSelectPalette(userPalette)}
    >
      <PaletteOverview>
        <PaletteName
          loading={loading}
          userPalette={userPalette}
          expanded={expanded}
          handleExpandOnClick={handleExpandOnClick}
          isEdit={isEdit}
          renameInputPosition={renameInputPosition}
          setRenameInputPosition={setRenameInputPosition}
          handleRenameColorPalette={handleRenameColorPalette}
        />
        <PaletteColors
          userPalette={userPalette}
          numberOfVisiblePalettes={numberOfVisiblePalettes}
          handleSelectColorToChange={handleSelectColorToChange}
          handleChange={handleChange}
          closeColorPicker={closeColorPicker}
          colorToChange={colorToChange}
          pickedColor={pickedColor}
          handleExpandOnClick={handleExpandOnClick}
          expanded={expanded}
          isOpen={isOpen}
          colorType={colorToChange ? convertToTitleCase(colorToChange?.type) : ''}
          isAdmin
          palette={palette}
        />
      </PaletteOverview>
      {expanded === userPalette.id && (
        <>
          <DetailedColors
            userPalette={userPalette}
            colorToChange={colorToChange}
            isOpen={isOpen}
            palette={palette}
            handleSelectColorToChange={handleSelectColorToChange}
            expanded={expanded}
            handleChange={handleChange}
            pickedColor={pickedColor}
            closeColorPicker={closeColorPicker}
            convertToTitleCase={convertToTitleCase}
          />
          <ColorPaletteDropdown
            palette={palette}
            defaultDropdownOptions={defaultDropdownOptions}
            dropdownOptions={defaultDropdownOptions}
          />
        </>
      )}
    </PaletteWrapper>
  )
}

export default AdminPalette
