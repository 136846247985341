import styled, { CSSProperties } from 'styled-components'

export const DropdownOptionContainer = styled.div<{
  backgroundColor?: CSSProperties['backgroundColor']
  isBorder?: boolean
  isHover?: boolean
  padding?: string
  margin?: string
  transform?: string
  height?: string
  width?: string
}>`
  transition: opacity 0.2s;
  background-color: ${(props) => props.backgroundColor};
  width: ${(props) => (props.width ? props.width : '100%')};
  height: ${(props) => props.height};
  transform: ${(props) => (props.transform ? props.transform : '')};
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 500;
  padding: ${(props) => (props.padding ? props.padding : '16px')};
  margin: ${(props) => props.margin};
  border-radius: 8px;
  cursor: pointer;
  ${(props) => (props.isBorder ? 'border-bottom: 1px solid var(--gray4)' : '')}

  &:hover {
    ${(props) => (props.isHover ? 'background-color: var(--hover)' : '')}
  }
`
