export const getStandardDateTimeFormat = (date: Date, isTimeShown: boolean) => {
  const fullDate = new Date(date)

  const day = fullDate.getDate()
  const month = fullDate.getMonth() + 1
  const year = fullDate.getFullYear()

  const hours = fullDate.getHours()
  const minutes = fullDate.getMinutes()

  return `${day}/${month}/${year} ${isTimeShown ? `${hours}:${minutes}` : ''}`
}
