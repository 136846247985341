import { useEffect, useState } from 'react'
import { type block } from '_entities/block'
import { useShareDb } from './useShareDb'
import { usePresence } from 'shared/shareDb/usePresence'
import { destroyDoc, docSubscribe, getBlocks, initializeDocListener } from 'shared/shareDb'
import { useGetSnapshotQuery } from '../api/snapshots'
interface Props {
  pageId: string
  isEmbed?: boolean
  isPreview?: boolean
}

export const useShareDbSession = (props: Props) => {
  // ** State
  const [blocks, setBlocks] = useState<block[] | undefined>(undefined)
  const { data } = useGetSnapshotQuery({ pageId: props.pageId })
  // ** Hooks
  const _shareDb = useShareDb()
  const _presence = usePresence()

  _shareDb.registerJson1()

  useEffect(() => {
    if (!data) return
    if (props.isPreview) {
      setBlocks(data.data.blocks)
    } else {
      handleConnect()
    }

    return () => {
      if (!props.isPreview) handleDestroy()
    }
  }, [data])

  const handleConnect = async () => {
    if (props.pageId) {
      const subscribedDoc = await docSubscribe(props.pageId)
      if (!subscribedDoc || subscribedDoc instanceof Error) return
      const blocks = getBlocks(props.pageId)
      setBlocks(blocks)
      if (!subscribedDoc) return
      initializeDocListener(props.pageId, () => {
        setBlocks(getBlocks(props.pageId))
      })
      const presence = _presence.getPresence(props.pageId)
      if (presence) {
        await _shareDb.subscribeToPresence(props.pageId)
        _presence.initializePresenceListener(props.pageId)
        _presence.createLocalPresence(props.pageId)
        _shareDb.submitLocalPresence(props.pageId)
      }
    }
  }

  const handleDestroy = () => {
    if (props.isEmbed || props.isPreview) return
    destroyDoc(props.pageId)
    _presence.destroyLocalPresence(props.pageId)
    setBlocks(undefined)
  }

  return {
    blocks,
    setBlocks,
  }
}
