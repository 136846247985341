import { transform } from 'lodash'
import { string } from 'prop-types'
import { StylesConfig, CSSObjectWithLabel, GroupBase, NonceProvider } from 'react-select'

export const colourStyles = (
  selectMinWidth?: string,
  optionWidth?: string,
  isMenuOpened?: boolean,
  containerPositionInitial?: boolean,
  containerPositionRelative?: boolean,
  controlMargin?: string,
  menuListDisplay?: string,
  menuTransform?: string,
  menuWidth?: string,
  menuHeight?: string,
  menuPositionInitial?: boolean,
  menuPadding?: string,
  menuRight?: string,
  menuTop?: string,
  optionDisplay?: string,
  optionPadding?: string,
  optionHeight?: string,
  optionJustifyContent?: string,
  optionAlignContent?: string,
  optionAlignItems?: string,
  optionMargin?: string,
  optionBorder?: boolean,
  dropdownIndicatorPadding?: string,
  dropdownIndicatorMargin?: string,
  dropdownWidth?: string,
  selectBackgroundoHover?: string,
  position?: any,
  placeholderColor?: string,
): StylesConfig<unknown, boolean, GroupBase<unknown>> => {
  return {
    container: (styles: CSSObjectWithLabel) => ({
      ...styles,
      width: optionWidth,
      color: 'black',
      fontSize: '14px',
      position: containerPositionInitial
        ? 'initial'
        : containerPositionRelative
        ? 'relative'
        : 'absolute',
      minHeight: '0px !important',
      cursor: 'pointer',
      borderRadius: '8px',
      display: 'flex',

      ':hover': {
        ...styles[':hover'],
        background: selectBackgroundoHover,
      },
    }),
    placeholder: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: 'black',
      opacity: 1,
      width: '100%',
      overflow: 'hidden',
      cursor: 'pointer',
    }),
    input: (styles: CSSObjectWithLabel) => ({
      ...styles,
      color: 'white',
      cursor: 'pointer',
      fontSize: '14px',
      width: '0px',
      height: '0px',
    }),
    indicatorSeparator: (styles: CSSObjectWithLabel) => ({ ...styles, display: 'none' }),
    indicatorsContainer: (styles: CSSObjectWithLabel) => ({
      ...styles,
      cursor: 'pointer',
    }),
    dropdownIndicator: (styles: CSSObjectWithLabel) => ({
      ...styles,
      padding: dropdownIndicatorPadding ? dropdownIndicatorPadding : '0',
      margin: dropdownIndicatorMargin,
      transform: isMenuOpened ? 'rotate(180deg) scale(0.8)' : 'rotate(0deg) scale(0.8)',
      transition: 'all 0.4s ease-in-out',
    }),
    menuList: (styles: CSSObjectWithLabel) => ({
      padding: '0 !important',
      display: menuListDisplay,
      zIndex: '10000 !important',
      width: menuWidth,
    }),
    control: (styles: { [x: string]: any }) => {
      return {
        ...styles,
        color: '',
        backgroundColor: '',
        borderColor: 'transparent',
        borderRadius: '0',
        border: 'none !important',
        borderWidth: '0 !important',
        width: 'fit-content',
        boxShadow: 'none !important',
        minHeight: '0px',
        margin: controlMargin ? controlMargin : '0px',

        ':hover': {
          ...styles[':hover'],
          border: 'none !important',
          boxShadow: 'none !important',
          outline: 'none !important',
        },
        ':visited': {
          ...styles[':visited'],
          border: 'none',
          boxShadow: 'none !important',
        },
        ':focus': {
          ...styles[':visited'],
          border: 'none !important',
          boxShadow: 'none !important',
        },
      }
    },

    menu: (styles: CSSObjectWithLabel) => ({
      ...styles,
      margin: '0 !important',
      width: `${dropdownWidth} !important` || '100% !important',
      borderRadius: '8px',
      transform: menuTransform,
      height: menuHeight,
      position: menuPositionInitial ? 'initial' : 'absolute',
      padding: menuPadding,
      right: menuRight,
      top: menuTop,
    }),
    valueContainer: (styles: CSSObjectWithLabel) => ({
      padding: '0px 0px 0 0',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer !important',
    }),
    option: (styles: { [x: string]: any }, { isDisabled, isFocused, isSelected }: any) => {
      const color = 'white'
      const textColor = 'var(--main-black)'
      const backgroundColor = 'var(--dark-grey)'
      const selectedColor = 'var(--hover)'
      return {
        ...styles,
        color: isFocused ? textColor : 'var(--main-black)',
        backgroundColor: isDisabled ? backgroundColor : isFocused ? selectedColor : backgroundColor,
        cursor: isDisabled ? 'unset' : 'pointer',
        textAlign: 'right',
        borderRadius: '8px',
        padding: optionPadding ? optionPadding : '8px 0 8px 0',
        WebkitUserSelect: 'none',
        MozUserSelect: 'none',
        msUserSelect: 'none',
        userSelect: 'none',
        height: optionHeight,
        display: optionDisplay,
        justifyContent: optionJustifyContent,
        alignContent: optionAlignContent,
        alignItems: optionAlignItems,
        margin: optionMargin,

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled ? color : backgroundColor,
        },
        ':last-child': {
          ...styles[':last-child'],
        },
      }
    },
    multiValue: (styles: CSSObjectWithLabel) => {
      const color = ''
      return {
        ...styles,
        backgroundColor: color,
        color: 'white',
        opacity: '1',
        borderRadius: '0',
      }
    },
    singleValue: (styles: CSSObjectWithLabel) => {
      return {
        ...styles,
        color: 'black',
        background: '',
        padding: '0px',
        opacity: '1',
        borderRadius: '6px',
        overflow: 'visible',
        textAlign: 'center',
      }
    },
    multiValueLabel: (styles: CSSObjectWithLabel, { data }: any) => ({
      ...styles,
      color: data.color,
    }),
    multiValueRemove: (styles: CSSObjectWithLabel, { data }: any) => ({
      ...styles,
      color: data.color,

      ':hover': {
        backgroundColor: '',
        color: '',
      },
    }),
    noOptionsMessage: (styles: CSSObjectWithLabel, { isDisabled, isFocused, isSelected }: any) => {
      const color = ''
      const textColor = ''
      const backgroundColor = ''
      return {
        ...styles,
        color: 'white',
        backgroundColor,
      }
    },
  }
}
