import styled from 'styled-components'
export const StyledFlexContainer = styled.div<{
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  height?: string
  gap?: string
  margin?: string
  padding?: string
  position?: string
}>`
  display: flex;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : 'row')};
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'center')};
  align-items: ${(props) => (props.alignItems ? props.alignItems : 'center')};
  height: ${(props) => props.height};
  gap: ${(props) => props.gap};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  position: ${(props) => props.position};
`
