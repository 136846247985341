import { ExtendedIPages, SlashMenuOptions } from '../../model/types'
import { SlashOption } from './SlashOption'
import { DropdownMenu, EmptyState } from './styles'
import { useMenuComponent } from '../../model/useMenuComponent'

interface Props {
  isCentered?: boolean
  open?: boolean
  options: (SlashMenuOptions | ExtendedIPages)[]
  onChange: ((newValue: unknown) => void) | undefined
  onClickOutside?: () => void
  isFocused?: boolean
  setFocused?: (value: React.SetStateAction<boolean>) => void
  isEmpty?: boolean
}

export const MenuComponent = (props: Props) => {
  const _menuComponent = useMenuComponent(props)

  return (
    <DropdownMenu
      shown={props.open}
      ref={_menuComponent.ref}
      tabIndex={props.isFocused ? 0 : -1}
      isCentered={props.isCentered}
      height={props.options.length > 5 && !props.isEmpty ? '200px' : 'auto'}
    >
      {props.isEmpty ? (
        <EmptyState>No results</EmptyState>
      ) : (
        <>
          {props.options.map((option, index) => {
            return (
              <SlashOption
                onChange={props.onChange}
                option={option}
                key={`slash-option-${index}`}
                focused={_menuComponent.focused}
                index={index}
              />
            )
          })}
        </>
      )}
    </DropdownMenu>
  )
}
