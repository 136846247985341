import {
  NoAccessText,
  TitleAndUpgradeContainer,
  UpgradeButton,
  StyledCenter,
  StyledSpaceTitle,
  StyledSpaceTitleContainer,
  StyledSpaceTopButton,
  StyledSpaceTopButtonContainer,
} from './styles'
import SpaceInviteModal from 'components/templates/SpaceInviteModal/SpaceInviteModal'
import { SpaceTabNames } from 'interfaces/space'
import NewProjectFileModal from '../NewProjectFileModal/NewProjectFileModal'
import {
  setSelectedTab,
  setPlanModalOpened,
  setInviteModalOpened,
} from 'redux/reducers/spaceReducer'
import Tabs from 'components/molecules/Tabs/Tabs'
import SpaceProjects from '../SpaceProjects/SpaceProjects'
import SpaceMembers from '../SpaceMembers/SpaceMembers'
import SpaceGroups from '../SpaceGroups/SpaceGroups'
import { IRoles } from 'interfaces/invite'
import SpacePlansAndBilling from '../SpacePlansAndBilling/SpacePlansAndBilling'
import PermissionWrapper from 'services/PermissionWrapper/PermissionWrapper'
import SpaceSettings from '../SpaceSettings/SpaceSettings'
import PlanOverviewModal from '../PlanOverviewModal/PlanOverviewModal'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useSpace } from '_entities/space'
import { useState } from 'react'

const SpaceMainContent = () => {
  const [newProjectFileModalshow, setNewProjectFileModalShow] = useState(false)
  const [newProjectFileTitle, setNewProjectFileTitle] = useState<string>('')
  const space = useAppSelector((state) => state.space.currentSpace)
  const selectedTab = useAppSelector((state) => state.space.selectedTab)
  const spaceMembers = useAppSelector((state) => state.space.spaceMembers)
  const dispatch = useAppDispatch()
  const _space = useSpace()

  const handleNewProjectTitleModalShow = () => setNewProjectFileModalShow(true)

  const tabs = [
    {
      text: 'Projects',
    },
    {
      text: 'Members',
    },
    {
      text: 'Settings',
    },
    {
      text: 'Plans and billing',
    },
  ]
  return (
    <StyledCenter className='p-0 pt-4' xs={12} md={8} lg={8}>
      <StyledSpaceTitleContainer>
        <PlanOverviewModal />
        <TitleAndUpgradeContainer>
          <StyledSpaceTitle>{space?.name}</StyledSpaceTitle>
          <UpgradeButton
            onClick={() => {
              dispatch(setPlanModalOpened(true))
            }}
          >
            Upgrade
          </UpgradeButton>
        </TitleAndUpgradeContainer>
        <StyledSpaceTopButtonContainer>
          <PermissionWrapper
            acceptedRoles={[IRoles.OWNER, IRoles.ADMIN]}
            accessDeniedElement={<></>}
            myRole={_space.getSpaceRole()}
          >
            <>
              <StyledSpaceTopButton
                onClick={() => {
                  dispatch(setInviteModalOpened(true))
                }}
                backgroundColour='var(--white)'
                border='2px solid var(--everyday-blue)'
                hoverTextColour='var(--everyday-blue)'
                hoverBackgroundColour='var(--hover-gray)'
              >
                Invite members
              </StyledSpaceTopButton>
              {space && <SpaceInviteModal />}
            </>
          </PermissionWrapper>

          <StyledSpaceTopButton
            onClick={handleNewProjectTitleModalShow}
            textColour='var(--white)'
            backgroundColour='var(--everyday-blue)'
            buttonWidth='fit-content !important'
            hoverOpacity='0.8'
            hoverTextColour='var(--white)'
          >
            New project
          </StyledSpaceTopButton>
          {space && (
            <NewProjectFileModal
              show={newProjectFileModalshow}
              setShow={setNewProjectFileModalShow}
              newProjectFileTitle={newProjectFileTitle}
              setNewProjectFileTitle={setNewProjectFileTitle}
              space={space}
            />
          )}
        </StyledSpaceTopButtonContainer>
      </StyledSpaceTitleContainer>

      <Tabs
        tabFontSize='14px'
        onClick={(text: string) => {
          if (text.includes('Members')) dispatch(setSelectedTab('Members'))
          else dispatch(setSelectedTab(text))
          dispatch(setSelectedTab(text))
        }}
        tabs={tabs}
        selectedTab={selectedTab}
        numberOfMembers={spaceMembers.length || 1}
      />

      {selectedTab.text === SpaceTabNames.PROJECTS && (
        <SpaceProjects
          space={space}
          handleNewProjectFileModalShow={handleNewProjectTitleModalShow}
        />
      )}
      {selectedTab.text === SpaceTabNames.MEMBERS && <SpaceMembers />}
      {selectedTab.text === SpaceTabNames.GROUPS && <SpaceGroups />}
      {selectedTab.text === SpaceTabNames.SETTINGS && (
        <PermissionWrapper
          acceptedRoles={[IRoles.OWNER, IRoles.ADMIN]}
          accessDeniedElement={
            <NoAccessText>You don't have authorization to see the space settings.</NoAccessText>
          }
          myRole={_space.getSpaceRole()}
        >
          <SpaceSettings />
        </PermissionWrapper>
      )}
      {selectedTab.text === SpaceTabNames.PLANSANDBILLING && (
        <PermissionWrapper
          acceptedRoles={[IRoles.OWNER, IRoles.ADMIN]}
          accessDeniedElement={
            <NoAccessText>You don't have authorization to see the space settings.</NoAccessText>
          }
          myRole={_space.getSpaceRole()}
        >
          <SpacePlansAndBilling />
        </PermissionWrapper>
      )}
    </StyledCenter>
  )
}

export default SpaceMainContent
