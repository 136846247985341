import { IPalette, IPaletteColor } from 'interfaces/settings'
import convertHexToRGBA from 'utils/convertToRgba'
import ColorPicker from '../ColorPicker/ColorPicker'
import CustomP from 'components/atoms/CustomP/CustomP'
import React from 'react'
import { PaletteColor, PaletteColorsWrapper, PaletteNumber } from './styles'
import { RGBColor } from 'react-color'
import { ColorPickerTypes } from 'components/organisms/Palette/Palette'

interface IProps {
  userPalette: IPalette
  numberOfVisiblePalettes: number
  handleSelectColorToChange: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    paletteColor: IPaletteColor,
  ) => void
  handleChange: (
    color: any,
    event: React.ChangeEvent<HTMLInputElement>,
    paletteColor: IPaletteColor,
  ) => void
  closeColorPicker: () => void
  colorToChange: IPaletteColor | null
  pickedColor?: RGBColor
  handleExpandOnClick: (palette: IPalette) => void
  expanded: string
  isOpen: boolean
  colorType: string
  isAdmin?: boolean
  palette: IPalette
  handleShowError?: (error: string, e: React.MouseEvent<HTMLElement>) => void
}

export const TOTAL_NUMBER_OF_COLORS = 11

const PaletteColors = ({
  userPalette,
  numberOfVisiblePalettes,
  handleSelectColorToChange,
  handleChange,
  closeColorPicker,
  colorToChange,
  pickedColor,
  handleExpandOnClick,
  expanded,
  isOpen,
  colorType,
  isAdmin,
  palette,
  handleShowError,
}: IProps) => {
  return (
    <PaletteColorsWrapper>
      {userPalette.attributes.colors
        .slice(0, numberOfVisiblePalettes)
        .map((paletteColor: IPaletteColor) => (
          <>
            <PaletteColor
              color={convertHexToRGBA(`#${paletteColor.color}`, paletteColor.opacity)}
              key={paletteColor.type}
              onClick={
                isAdmin
                  ? (e) => handleSelectColorToChange(e, paletteColor)
                  : palette.attributes.createdBy
                  ? (e) => handleSelectColorToChange(e, paletteColor)
                  : (e) => handleShowError && handleShowError('You cannot edit default palettes', e)
              }
            />
            {colorToChange?.id === paletteColor.id && isOpen && expanded !== userPalette.id && (
              <ColorPicker
                placeholder={colorType}
                handleChange={handleChange}
                closeColorPicker={closeColorPicker}
                paletteColor={paletteColor}
                pickedColor={pickedColor}
                type={ColorPickerTypes.PALETTE}
              />
            )}
          </>
        ))}
      <PaletteNumber
        onClick={(e) => {
          e.stopPropagation()
          handleExpandOnClick(userPalette)
        }}
      >
        <CustomP fontSize='14px' fontWeight='500' color='var(--primary-text)'>
          +{TOTAL_NUMBER_OF_COLORS}
        </CustomP>
      </PaletteNumber>
    </PaletteColorsWrapper>
  )
}

export default PaletteColors
