import { Option } from 'interfaces/selectOptions'
import { SelectStyles, StyledCaret, StyledWrapper } from './styles'
import Select, { OptionsOrGroups, SingleValue, GroupBase, PropsValue } from 'react-select'
import SelectType, { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select'
import { SelectComponents } from 'react-select/dist/declarations/src/components'
import { ReactNode } from 'react'
import { icons } from 'shared/icons'
import Wrapper from 'components/molecules/Wrapper/Wrapper'

interface Props {
  options?: OptionsOrGroups<Option, GroupBase<Option>>
  onChange: (items: SingleValue<Option>) => void
  autoFocus?: boolean
  hideControl?: boolean
  ref?: React.Ref<SelectType<Option, false, GroupBase<Option>>>
  menuIsOpen?: boolean
  isClearable?: boolean
  openMenuOnFocus?: boolean
  isSearchable?: boolean
  onClick?: (e: React.MouseEvent) => void
  components?: Partial<SelectComponents<Option, false, GroupBase<Option>>>
  placeholder?: string
  currentValue?: PropsValue<Option>
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
  ) => ReactNode
  hideSelectedOptions?: boolean
  controlShouldRenderValue?: boolean
  isDropdownIndicatorShown?: boolean
}

const SingleSelect = (props: Props) => {
  const { hideControl, isSearchable, isDropdownIndicatorShown } = props
  return (
    <Wrapper onClick={props.onClick}>
      <Select
        placeholder={props.placeholder}
        openMenuOnFocus={props.openMenuOnFocus}
        isSearchable={props.isSearchable}
        ref={props.ref}
        menuIsOpen={props.menuIsOpen}
        autoFocus={props.autoFocus}
        isClearable={props.isClearable}
        options={props.options}
        onChange={props.onChange}
        value={props.currentValue || undefined}
        hideSelectedOptions={props.hideSelectedOptions}
        onKeyDown={props.onClick as any}
        components={
          props.components
            ? props.components
            : {
                DropdownIndicator: () => {
                  return isDropdownIndicatorShown ? <icons.caretDown /> : null
                },
                IndicatorSeparator: () => null,
                Control: (props) => {
                  return (
                    <StyledWrapper hideControl={hideControl}>
                      {isSearchable ? <StyledCaret /> : null}
                      {props.children}
                    </StyledWrapper>
                  )
                },
              }
        }
        styles={SelectStyles(props.hideControl, props.isSearchable)}
        formatOptionLabel={props.formatOptionLabel}
        controlShouldRenderValue={props.controlShouldRenderValue}
      />
    </Wrapper>
  )
}

export default SingleSelect
