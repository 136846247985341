import { CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import styled from 'styled-components'

export const SelectStyles = (
  hideControl?: boolean,
  removePaddingOnOption?: number | null,
): StylesConfig<Option, true, GroupBase<Option>> | undefined => {
  return {
    container: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: '0 8px',
      backgroundColor: 'var(--overlay-modal)',
    }),
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      border: '1px solid var(--secondary-text) !important',
      borderRadius: '8px',
      boxShadow: 'none',
      fontSize: '14px',
      display: hideControl ? 'none' : 'block',
      backgroundColor: 'var(--overlay-modal)',
      color: 'var(--primary-text)',
    }),
    input: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      paddingBottom: '.4em',
      color: 'var(--primary-text)',
      gridTemplateColumns: 'none',
      input: {
        minWidth: '100% !important',
        width: 'initial !important',
      },
    }),
    menu: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      backgroundColor: 'var(--overlay-modal)',
      margin: 0,
      padding: 0,
      borderColor: 'transparent',
      border: 0,
      borderRadius: '8px',
      boxShadow: 'none',
      position: 'relative',
      left: 0,
      width: '100%',
      fontSize: '14px',
      color: 'var(--primary-text)',
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: OptionProps<Option, true, GroupBase<Option>>,
    ) => ({
      ...baseStyles,
      color: 'var(--primary-text)',
      backgroundColor: state.isFocused ? 'var(--hover)' : 'var(--overlay-modal)',
      borderRadius: '8px',
      [`:nth-child(${removePaddingOnOption})`]: {
        padding: '0 !important',
      },
    }),
    multiValue: (base: CSSObjectWithLabel) => ({
      ...base,
      backgroundColor: 'var(--hover)',
      borderRadius: '8px',
      maxWidth: '300px',
      overflowX: 'hidden',
    }),
    multiValueRemove: (base: CSSObjectWithLabel) => ({
      ...base,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: 'var(--hover)',
      },
      svg: {
        stroke: 'var(--primary-text)',
        fill: 'var(--primary-text)',
      },
    }),
    multiValueLabel: (base: CSSObjectWithLabel) => ({
      ...base,
      color: 'var(--primary-text)',
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      flexDirection: 'column',
      padding: '0',
    }),
  }
}

export const StyledWrapper = styled.div<{ hasValue: boolean }>`
    display: flex;
    height: 100%;
    cursor: pointer;
    font-size: 14px;
    border: 1px solid var(--primary-text);
    border-radius: 8px;
    box-shadow: none;
    width: 100%;
    align-items: center;
    gap: 4px;
    padding: ${(props) => (!props.hasValue ? '0px 6px' : '0')};,
`
