import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { SourceKeys } from 'interfaces/editor'
import { type block } from './types'
import { getDoc } from 'shared/shareDb'
import { getBlockPageId, getIndex } from './getters'

const useDate = () => {
  const { submit } = useSubmit()
  const { getReplaceBlockMetaKeyOp } = useJson1()

  const onDateChange = (date: Date | null, block: block) => {
    const index = getIndex(block)
    const dateString = date?.toISOString() || null
    if (index === -1) return
    const op = getReplaceBlockMetaKeyOp(index, ['dueDate'], dateString)
    submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  return {
    onDateChange,
  }
}

export default useDate
