import { EmojiPickerContainer, EmojiPickerActionBar } from './styles'
import Button from 'components/atoms/Button/Button'
import { EmojiPicker } from './EmojiPicker'
import { EmojiPickerFullProps } from '../model/types'

export const LeftSidebarEmojiPicker = (props: EmojiPickerFullProps) => {
  return (
    <EmojiPickerContainer
      ref={props.refHandler}
      style={props.style}
      {...props.componentProps}
      shouldHide={props.shouldHide}
    >
      <EmojiPicker onEmojiSelect={props.onEmojiSelect} />
      <EmojiPickerActionBar>
        <Button variant='transparent' size='small' onClick={props.onButtonClick}>
          Remove
        </Button>
      </EmojiPickerActionBar>
    </EmojiPickerContainer>
  )
}
