import { KonvaEventObject } from 'konva/lib/Node'
import { useMove, useZoom, usePresentation } from '_features/canvas'
import { useEvent } from '_features/canvas/event'

export const useWheel = () => {
  const _move = useMove()
  const _zoom = useZoom()
  const _event = useEvent()
  const _presentation = usePresentation()
  const handleWheel = (e: KonvaEventObject<WheelEvent>) => {
    const pageId = _event.getPageIdFromEvent(e)
    if (!_presentation.getIsPresentation(pageId)) {
      e.evt.preventDefault()
      if (e.evt.ctrlKey || e.evt.metaKey) _zoom.zoomHandler(e)
      else _move.scrollAndTouchpadMoveHandler(e)
    }
  }

  return {
    handleWheel,
  }
}
