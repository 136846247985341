import Embed from 'components/atoms/IFrameEmbed/IFrameEmbed'
import { Container, ResizeLine } from './styles'
import { block } from '_entities/block'
import React from 'react'
import useEditorEmbedFrame from '../model/useEditorEmbedFrame'
import { GoogleEmbed } from '_features/google-embed'
import {
  PagePreviewOptions,
  useInternalEmbedBlock,
  EmbedType,
  useEmbedExpansion,
  useEmbed,
  FIGMA_EMBED_IDENTIFIER,
  FIGMA_EMBED_REDIRECTER,
  FullEmbedWrapper,
  checkIsFigma,
  PagePreviewAction,
} from '_entities/embed'
import { ExpandableCallout } from 'shared/ui'
import { useIcons } from 'shared/icons'
import { isElectron } from 'shared/lib'
import { WebView } from '_features/embed'
import {
  RESIZE_BOTTOM_SELECTOR,
  RESIZE_LEFT_SELECTOR,
  RESIZE_RIGHT_SELECTOR,
} from '_features/editor'
import Wrapper from 'components/molecules/Wrapper/Wrapper'

type EditorFigmaEmbedPropsType = {
  block: block
}

export const EditorEmbedFrame = (props: EditorFigmaEmbedPropsType) => {
  const { block } = props
  const _editorEmbedFrame = useEditorEmbedFrame({ block })
  const _editorPageBlock = useInternalEmbedBlock()
  const _embedExpansion = useEmbedExpansion({ block })
  const _icons = useIcons()
  const _embed = useEmbed()

  const constructEmbedUrl = (src?: string) => {
    if (block.data.embed?.type === EmbedType.FIGMA) {
      return `${FIGMA_EMBED_REDIRECTER}${FIGMA_EMBED_IDENTIFIER}${src}}`
    } else if (block.data.embed?.type === EmbedType.OFFICE) {
      return `https://view.officeapps.live.com/op/embed.aspx?src=${src}`
    } else return `${src}?embed=true`
  }

  if (!_embedExpansion.isEmbeddedDocumentExpanded) {
    return (
      <ExpandableCallout
        id={props.block._id}
        block={block}
        title={
          _editorPageBlock.getBlockPageTitle(props.block) ||
          props.block.data.embed?.name ||
          _embed.getEmbedUrl(props.block) ||
          ''
        }
        Icon={_icons.getIcon({ block: props.block })}
        freshlyCreatedBlockPageId={_editorPageBlock.freshlyCreatedBlockPageId}
        inputOnKeydown={(e) => _editorPageBlock.handleOnKeyDown(e, props.block)}
        inputOnBlur={_editorPageBlock.handleOnBlur}
        isExpanded={_embedExpansion.isEmbeddedDocumentExpanded}
        maximize={_embedExpansion.handleEmbeddedDocumentMaximize}
        minimize={_embedExpansion.handleEmbeddedDocumentMinimize}
        shouldSliceText
      />
    )
  }

  return (
    <Wrapper margin='0 0 48px 0' position='relative' width='100%'>
      <Container
        width={_editorEmbedFrame.width || block.data.width}
        height={_editorEmbedFrame.height || block.data.height}
        isDraggingOver={_editorEmbedFrame.resizeDirection}
      >
        <React.Fragment>
          <ResizeLine
            id={RESIZE_LEFT_SELECTOR}
            left='-2rem'
            onMouseDown={_editorEmbedFrame.onLeftResizerMouseDown}
          />
          <ResizeLine
            id={RESIZE_RIGHT_SELECTOR}
            right='-2rem'
            onMouseDown={_editorEmbedFrame.onRightResizeMouseDown}
          />
          <ResizeLine
            id={RESIZE_BOTTOM_SELECTOR}
            bottom='-3.5rem'
            onMouseDown={_editorEmbedFrame.onBottomResizeMouseDown}
          />
        </React.Fragment>

        <FullEmbedWrapper>
          {block.data.embed?.type === EmbedType.GOOGLE_SLIDES ||
          block.data.embed?.type === EmbedType.GOOGLE_SHEETS ||
          block.data.embed?.type === EmbedType.GOOGLE_DOC ? (
            <GoogleEmbed
              embedUrl={constructEmbedUrl(block.data.embed?.url) as string}
              iframeRef={_editorEmbedFrame.iframeRef}
            />
          ) : isElectron() ? (
            <WebView url={_embed.getEmbedUrl(props.block)} />
          ) : (
            <Embed
              src={constructEmbedUrl(block.data.embed?.url)}
              iframeRef={_editorEmbedFrame.iframeRef}
            />
          )}
        </FullEmbedWrapper>

        {checkIsFigma(_embed.getUrl(props.block)) && (
          <PagePreviewAction
            isEmbeddedDocumentExpanded={_embedExpansion.isEmbeddedDocumentExpanded}
            handleEmbeddedMaximize={_embedExpansion.handleEmbeddedDocumentMaximize}
            handleEmbeddedMinimize={_embedExpansion.handleEmbeddedDocumentMinimize}
            isFigma
          />
        )}

        {!checkIsFigma(_embed.getUrl(props.block)) && (
          <PagePreviewOptions
            Icon={_icons.getIcon({ block: props.block })}
            isEmbeddedDocumentExpanded={_embedExpansion.isEmbeddedDocumentExpanded || false}
            handleEmbeddedMaximize={_embedExpansion.handleEmbeddedDocumentMaximize}
            handleEmbeddedMinimize={_embedExpansion.handleEmbeddedDocumentMinimize}
            title={_editorPageBlock.getBlockPageTitle(props.block) || props.block.data.embed?.name}
            isWhiteboardEmbed
          />
        )}
      </Container>
    </Wrapper>
  )
}
