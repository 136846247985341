import { IPages } from 'interfaces/page'
import { PageTypesEnum } from 'interfaces/projectFile'
import { createPage } from './createPage'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { useAppDispatch } from 'redux/hooks'
import { setFreshlyCreatedBlockPageId } from 'redux/reducers/projectFileReducer'
import { type block } from '_entities/block'

export const useCreatePage = () => {
  const { getAndDispatchProjectFile, getAndDispatchProjectFilePages } = useGetProjectFile()

  const dispatch = useAppDispatch()

  const createDocumentPage = async (
    pages: IPages[],
    projectId: string,
    spaceId: string,
    parentId?: string,
    title?: string,
    blocks?: block[],
  ) => {
    const createdPage = await createPage({
      type: PageTypesEnum.DOCUMENT,
      pages,
      projectId,
      parentId,
      title,
      blocks,
    })

    if (createdPage) {
      getAndDispatchProjectFile(parseInt(projectId), spaceId)
      getAndDispatchProjectFilePages(projectId)

      // This is used for initial page naming
      if (createdPage.id) dispatch(setFreshlyCreatedBlockPageId(createdPage.id))

      return createdPage
    }
  }

  const createWhiteboardPage = async (
    pages: IPages[],
    projectId: string,
    spaceId: string,
    parentId?: string,
    title?: string,
    isHidden?: boolean,
  ) => {
    const createdPage = await createPage({
      type: PageTypesEnum.WHITE_BOARD,
      pages,
      projectId,
      parentId,
      title,
      isHidden,
    })
    if (createdPage) {
      getAndDispatchProjectFile(parseInt(projectId), spaceId)
      getAndDispatchProjectFilePages(projectId)

      // This is used for initial page naming
      if (createdPage.id) dispatch(setFreshlyCreatedBlockPageId(createdPage.id))

      return createdPage
    }
  }

  const createEmbedPage = async (
    tag: PageTypesEnum,
    pages: IPages[],
    projectId: string,
    spaceId: string,
    parentId?: string,
    embedUrl?: string,
    name?: string,
    icon?: string,
  ) => {
    const createdPage = await createPage({
      type: tag || PageTypesEnum.EMBED,
      pages,
      projectId,
      parentId,
      embedUrl,
      title: name,
      icon,
    })

    if (createdPage) {
      getAndDispatchProjectFile(parseInt(projectId), spaceId)
      getAndDispatchProjectFilePages(projectId)

      return createdPage
    }
  }

  return {
    createDocumentPage,
    createWhiteboardPage,
    createEmbedPage,
  }
}
