import { type block } from '_entities/block'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import { EditWrapper } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/BlockEdit/styles'
import { StyledInput } from './styles'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { useHeightEdit } from '_features/block'

interface IProps {
  block: block
}

export const getEmbedHeightInputId = (blockId: string) => `embed-height-input-${blockId}`

export const EmbedHeightEdit = (props: IProps) => {
  const _embedHeight = useHeightEdit(props)

  return (
    <EditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Height
      </CustomP>
      <Wrapper display='flex' alignItems='center' gap='6px' color={getColor('--primary-text')}>
        <StyledInput
          value={_embedHeight.height}
          onChange={(e) => {
            _embedHeight.handleOnInputHeightChange(props.block, parseInt(e.target.value))
          }}
          id={`${getEmbedHeightInputId(props.block._id)}`}
          type='number'
        />
        px
      </Wrapper>
    </EditWrapper>
  )
}
