import { getBlockPageId, getIndex, type block } from '_entities/block'
import { getBackgroundImage, getThumbnail } from '_entities/whiteboard'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb'
import { SourceKeys } from 'interfaces/editor'

export const useFrameBackgroundImage = () => {
  const _submit = useSubmit()
  const _json1 = useJson1()

  const handleSetBackgroundImage = (block: block, image: string) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    if (index < 0) return
    const currentBackgroundImage = getBackgroundImage(block)
    if (currentBackgroundImage !== undefined) {
      _submit.submit(
        pageId,
        _json1.getReplaceBlockDataKeyOp(
          index,
          ['frame', 'backgroundImage'],
          image,
          currentBackgroundImage,
        ),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      _submit.submit(
        pageId,
        _json1.getInsertKeyInDataKeyOp(index, ['frame', 'backgroundImage'], image),
        SourceKeys.UPDATE_BLOCK,
      )
  }

  const handleSetThumbnailImage = (block: block, image: string) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    if (index < 0) return
    const currentThumbnailImage = getThumbnail(block)
    if (currentThumbnailImage !== undefined) {
      _submit.submit(
        pageId,
        _json1.getReplaceBlockDataKeyOp(
          index,
          ['frame', 'thumbnail'],
          image,
          currentThumbnailImage,
        ),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      _submit.submit(
        pageId,
        _json1.getInsertKeyInDataKeyOp(index, ['frame', 'thumbnail'], image),
        SourceKeys.UPDATE_BLOCK,
      )
  }

  return {
    handleSetBackgroundImage,
    handleSetThumbnailImage,
  }
}
