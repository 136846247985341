import styled from 'styled-components'

export const BackgroundContainer = styled.div<{
  background?: string
  transparency?: number
}>`
  top: 0;
  left: 0 !important;
  position: fixed;
  width: 100%;
  height: 100%;
  background: ${(props) =>
    props.background?.includes('https') ? `url('${props.background}')` : `${props.background}`};
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 0;
`
