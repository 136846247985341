import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import RadixDropdown, {
  IconButton,
  RowMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { strokeWidthOptions } from 'components/organisms/Dropdowns/ShapeStrokeDropdown/ShapeStrokeDropdown'
import { ShapeConfig } from 'konva/lib/Shape'
import React, { useEffect, useState } from 'react'
import { EditWrapper } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/BlockEdit/styles'
import { IconWrapper } from '_entities/whiteboard/ui/styles'
import {
  getBlockDocumentPage,
  getInternalEmbedBorderWidth,
  useWhiteboardEmbed,
  useInternalEmbedBlock,
} from '_entities/embed'
import { SidebarEditProps } from './EmbedEdit'
import { usePresentation, useCanvas, WhiteboardAttrs, getCurrentFrame } from '_features/canvas'
import { useInternalCanvasBorderWidth } from '_features/embed'

export const BorderWeightEdit = (props: SidebarEditProps) => {
  const [isFrames, setIsFrames] = useState<boolean>(false)
  const [frameBorderWidth, setFrameBorderWidth] = useState<number | undefined>(undefined)
  const _presentation = usePresentation()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _internalCanvasBorderWidth = useInternalCanvasBorderWidth()
  const _canvas = useCanvas()
  const _internalEmbedBlock = useInternalEmbedBlock()

  const blockPage = getBlockDocumentPage(props.block)
  useEffect(() => {
    const pageId = _internalEmbedBlock.getBlockPageId(props.block)
    if (!pageId) return
    const observer = _canvas.observeMutationOnCanvasElement(
      pageId,
      WhiteboardAttrs.CURRENT_FRAME,
      () => {
        const currentFrame = getCurrentFrame(pageId)
        if (currentFrame) {
          setIsFrames(true)
          const borderWidth = currentFrame.borderWeight
          setFrameBorderWidth(borderWidth)
        } else {
          setIsFrames(false)
        }
      },
    )
    return () => {
      observer?.disconnect()
    }
  }, [props.block])

  return (
    <EditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Border weight
      </CustomP>
      <RadixDropdown
        trigger={
          <IconButton padding='0' width='18px' height='18px' transform='translateX(1.5px)'>
            <icons.strokeMid />
          </IconButton>
        }
      >
        <Wrapper>
          <RowMenuItem
            onSelect={() => {
              if (blockPage && _whiteboardEmbed.isDocEmbed(blockPage.id)) {
                _presentation.updateFrameBorderWidth(blockPage.id, 0)
              }
              _internalCanvasBorderWidth.updateInternalCanvasBorderWidth(props.block, 0)
            }}
            key={`${props.block._id}-stroke-width-option-0`}
          >
            <IconWrapper
              height='36px'
              selected={
                isFrames
                  ? frameBorderWidth === 0
                  : getInternalEmbedBorderWidth(props.block) === 0 ||
                    getInternalEmbedBorderWidth(props.block) === undefined
              }
            >
              <icons.disabled />
            </IconWrapper>
          </RowMenuItem>
          {strokeWidthOptions().map(
            (option: { width: ShapeConfig['strokeWidth']; icon: React.ReactNode }, i) => (
              <RowMenuItem
                onSelect={() => {
                  if (blockPage && _whiteboardEmbed.isDocEmbed(blockPage.id)) {
                    _presentation.updateFrameBorderWidth(blockPage.id, option.width || 0)
                  }
                  _internalCanvasBorderWidth.updateInternalCanvasBorderWidth(
                    props.block,
                    option.width || 0,
                  )
                }}
                key={`${props.block._id}-stroke-width-option-${i}`}
              >
                <IconWrapper
                  height='36px'
                  selected={
                    isFrames
                      ? frameBorderWidth === option.width
                      : getInternalEmbedBorderWidth(props.block) === option.width
                  }
                >
                  {option.icon}
                </IconWrapper>
              </RowMenuItem>
            ),
          )}
        </Wrapper>
      </RadixDropdown>
    </EditWrapper>
  )
}
