import SidebarReference from 'components/molecules/SidebarReference/SidebarReference'
import SidebarNavigation from 'components/organisms/SidebarNavigation/SidebarNavigation'
import { StyledLeftSidebar, Separator, Line } from './styles'
import { useSpaceSidebar } from './useSpaceSidebar'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import CreateSpace from 'assets/images/createSpace.svg'
import CustomP from 'components/atoms/CustomP/CustomP'
import { ISpace } from 'interfaces/space'
import { CreateSpaceModal } from '../CreateSpaceModal/CreateSpaceModal'
import { useAppSelector } from 'redux/hooks'

const SpaceSidebar = () => {
  const {
    navigate,
    mySpaces,
    setCreateNewSpaceIsHovered,
    sharedSpacesIsHovered,
    setsharedSpacesIsHovered,
    sharedSpaces,
    show,
    setShow,
  } = useSpaceSidebar()
  const currentSpace = useAppSelector((state) => state.space.currentSpace)
  return (
    <StyledLeftSidebar padding='0rem' paddingTop='1.5rem' xs={12} md={2} lg={2}>
      <SidebarNavigation title={'My workspaces'} titleColor='var(--gray2)'>
        {mySpaces.map((mySpace: ISpace) => {
          return (
            <SidebarReference
              borderRadius={'10px'}
              hoveredBorderRadius={'10px'}
              levelOfNesting={1}
              key={mySpace?.id}
              onClick={() => {
                navigate(`/space/${mySpace?.id}`)
              }}
              isSelected={mySpace?.id === currentSpace?.id}
              padding='4px 14px 6px 48px'
              color={currentSpace?.id === mySpace?.id ? 'var(--main-black)' : 'var(--gray)'}
            >
              {mySpace?.name}
            </SidebarReference>
          )
        })}

        <SidebarReference
          isSelected={false}
          justifyContent={'flex-start'}
          color={'var(--gray)'}
          onMouseEnter={() => setCreateNewSpaceIsHovered(true)}
          onMouseLeave={() => setCreateNewSpaceIsHovered(false)}
          onClick={() => setShow(!show)}
        >
          <CustomIcon
            src={CreateSpace}
            alt='Create workspace'
            margin={'0 4px 0 8px'}
            width='20px'
          />
          Create workspace
        </SidebarReference>
        <CreateSpaceModal show={show} setShow={setShow} />
      </SidebarNavigation>

      <Separator>
        <Line />
      </Separator>
      <SidebarNavigation
        title={'Shared workspaces'}
        onMouseEnterReference={() => setsharedSpacesIsHovered(true)}
        onMouseLeaveReference={() => setsharedSpacesIsHovered(false)}
        titleColor={sharedSpacesIsHovered ? 'black' : 'var(--gray2)'}
      >
        {sharedSpaces.length ? (
          sharedSpaces.map((sharedSpace: ISpace, i: number) => (
            <SidebarReference
              borderRadius={'10px'}
              hoveredBorderRadius={'10px'}
              color={
                currentSpace?.id === sharedSpace?.id ? 'var(--main-black)' : 'var(--secondary-text)'
              }
              levelOfNesting={1}
              key={sharedSpace.name + i}
              isSelected={currentSpace?.id === sharedSpace.id}
              padding='4px 14px 6px 48px'
              onClick={() => {
                navigate(`/space/${sharedSpace.id}`)
              }}
            >
              {sharedSpace.name}
            </SidebarReference>
          ))
        ) : (
          <CustomP color={'var(--gray3) !important'} marginLeft={'54px'} marginBottom='1rem'>
            No spaces here yet
          </CustomP>
        )}
      </SidebarNavigation>
    </StyledLeftSidebar>
  )
}

export default SpaceSidebar
