import styled from 'styled-components'

export const ProjectSidebarMessageWrapper = styled.div`
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  padding: 8px;
  display: flex;
  font-size: 12px;
  gap: 8px;
  width: 100%;
  cursor: pointer;
`

export const StyledImage = styled.img`
  width: 100%;
  height: auto;
  background-size: cover;
  border-radius: 10px;
`

export const StyledVideo = styled.video`
  width: 100%;
  height: auto;
  background-size: cover;
  border-radius: 10px;
`

export const StyledMessageTextContainer = styled.div``

export const ShowMoreContainer = styled.div`
  cursor: pointer;
  color: var(--everyday-blue);
  margin-top: 4px;
`

export const ReplyCountText = styled.div`
  color: var(--secondary-text);
  opacity: 0.6;
  margin-top: 4px;
  font-size: 12px;
  cursor: pointer;
  flex: 1;
`

export const MessageContentWrapper = styled.div``

export const StyledPagePreview = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  gap: 12px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: var(--gray3);
  text-underline-offset: 3px;
  cursor: pointer;
  color: var(--primary-text);
`
