import React from 'react'

export default function Arrow() {
  return (
    <svg
      width='48'
      height='48'
      viewBox='0 0 24 24'
      strokeWidth='1.5'
      stroke='#2c3e50'
      fill='none'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <line x1='12' y1='5' x2='12' y2='19' />
      <line x1='18' y1='11' x2='12' y2='5' />
      <line x1='6' y1='11' x2='12' y2='5' />
    </svg>
  )
}
