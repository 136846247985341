import { type block, getBlockTag, IBlockTypes } from '_entities/block'
import { useImage } from '../image/model/useImage'

export const useCanvas = () => {
  const _canvasImage = useImage()
  const canvasBlockTypes = [
    IBlockTypes.ARROW,
    IBlockTypes.LINE,
    IBlockTypes.SHAPE,
    IBlockTypes.STICKY_NOTE,
    IBlockTypes.FRAME,
  ]
  const isCanvasBlock = (block: block) => {
    return canvasBlockTypes.includes(getBlockTag(block)) || _canvasImage.isCanvasImage(block)
  }

  return {
    isCanvasBlock,
  }
}
