import { ShapeConfig } from 'konva/lib/Shape'
import { sizes } from '_entities/whiteboard'
import { GridBlockWidth } from '_entities/block'
import { Stage } from 'konva/lib/Stage'

export enum IShapeTypes {
  RECTANGLE = 'rectangle',
  CURVED_RECTANGLE = 'curved-rectangle',
  CIRCLE = 'circle',
  TRIANGLE = 'triangle',
  ROMB = 'romb',
  HEXAGON = 'hexagon',
  PENTAGON = 'pentagon',
}

export enum ITools {
  CURSOR = 'cursor',
  HAND = 'hand',
  PEN = 'pen',
  ERASER = 'eraser',
  ARROW = 'arrow',
  // Shapes
  PENTAGON = 'pentagon',
  HEXAGON = 'hexagon',
  ROMB = 'romb',
  CIRCLE = 'circle',
  RECTANGLE = 'rectangle',
  CURVED_RECTANGLE = 'curved-rectangle',
  TRIANGLE = 'triangle',
  // Emojis
  EMOJI = 'emoji',
  // Embeds
  INTERNAL_EMBED = 'internal-embed',
  EXTERNAL_EMBED = 'external-embed',
  // Files
  FILE = 'file',
  // Frames
  FRAME_SIXTEEN_NINE = 'frame-sixteen-nine',
  FRAME_FOUR_THREE = 'frame-four-three',
  FRAME_ONE_ONE = 'frame-one-one',
  FRAME_CUS = 'frame-cus',
  STICKY = 'sticky',
  TEXT = 'text',
  FRAME = 'frame',
  CTA = 'cta',
}

export interface SelectionRectangle {
  fill: string
  visible: boolean
  cornerRadius: number
  stroke: string
  strokeWidth: number
  opacity: number
  x?: number
  y?: number
  width?: number
  height?: number
}

export interface AnchorPositions {
  top: Position
  right: Position
  bottom: Position
  left: Position
}

export interface Position {
  x: number
  y: number
}

export interface Size {
  width: number
  height: number
}

export interface Scale {
  scaleX: number
  scaleY: number
}

export interface Transposition {
  transposeX: number
  transposeY: number
}

export interface ILineItem {
  points: number[]
  x: number
  y: number
}

export interface IArrowItem {
  xStart: number
  yStart: number
  xEnd: number
  yEnd: number
}

export interface IArrowNode {
  x: number
  y: number
  fill: string
  width: number
  draggable: boolean
}

export interface IShapeItem {
  stroke?: ShapeConfig['stroke']
  strokeWidth?: ShapeConfig['strokeWidth']
  dash?: ShapeConfig['dash']
  fill?: string
  type: IShapeTypes | null
}

export interface IFrameItem {
  name?: sizes
  backgroundImage?: string
  thumbnail?: string
  fill?: string
  borderColor?: string
  borderWeight?: number
}

export interface IClientRect {
  width: number
  height: number
  x: number
  y: number
}

export enum TooltipElements {
  BACKGROUND_COLOR = 'background-color',
  TEXT_COLOR = 'text-color',
  DELETE = 'delete',
  CREATE_TASK = 'create-task',
}

export interface IFrameScalePosition {
  position: Position
  scale: number
}
