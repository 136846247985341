import React from 'react'
import Card from 'components/organisms/Card/Card'
import { Link } from 'react-router-dom'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import background from 'assets/images/errors/403.svg'
import Image from 'components/atoms/Image/Image'
import HeaderWrapper from 'components/templates/HeaderWrapper/HeaderWrapper'
import CustomP from 'components/atoms/CustomP/CustomP'

const Forbidden = () => {
  return (
    <HeaderWrapper>
      <Card
        cardElements={
          <div className='overflow-hidden'>
            <Image
              isDraggable={false}
              className='img-responsive'
              height={'500'}
              src={background}
              alt='bad gateway'
            ></Image>
            <h1 className='fs-2 mb-3 text-black'>403</h1>
            <h1 className='fs-2 text-black mb-5'>Forbidden access</h1>
            <div>
              <Link className='text-primary' to='/'>
                <CustomButton
                  className='btn btn-primary d-inline-flex justify-content-center align-items-center'
                  height='40px'
                  width='130px'
                >
                  <CustomP className='mb-0'>Back to home</CustomP>
                </CustomButton>
              </Link>
            </div>
          </div>
        }
      />
    </HeaderWrapper>
  )
}

export default Forbidden
