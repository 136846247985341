import React, { ComponentProps } from 'react'
import {
  ColorButton,
  ColorButtonColor,
  ColorButtonContainer,
  ColorDropdownMenuBody,
  ColorDropdownMenuContainer,
  ColorDropdownMenuTitle,
} from '../ColorDropdownMenu/styles'
import { textColorArray } from 'components/organisms/ProjectFileRightSideBar/constants'
import { type block } from '_entities/block'
import Quill from 'quill'

interface Props {
  isWhiteboard?: boolean
  block: block
  setActiveToolbarOption?: React.Dispatch<React.SetStateAction<string | null>>
}

const HighlightColorDropdownMenu = (props: Props) => {
  return (
    <ColorDropdownMenuContainer>
      <ColorDropdownMenuTitle>Highlight</ColorDropdownMenuTitle>
      <ColorDropdownMenuBody>
        {textColorArray().map((color, n) => (
          <ColorDropdownMenuItem
            key={n}
            color={color}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              const quillElement = document.querySelector(`#quill-editor-${props.block._id}`)
              if (quillElement) {
                const editor: Quill = Quill.find(quillElement)
                editor.format('background', color, 'user')
              }
              props.setActiveToolbarOption && props.setActiveToolbarOption(null)
            }}
          />
        ))}
      </ColorDropdownMenuBody>
    </ColorDropdownMenuContainer>
  )
}

type ColorDropdownMenuItemProps = ComponentProps<typeof ColorButtonContainer> & {
  color: string
  border?: boolean
  active?: boolean
}

const ColorDropdownMenuItem = (props: ColorDropdownMenuItemProps) => {
  const { color, active, ...restProps } = props

  const border = (color === 'white' && '1px solid #ccc') || undefined

  return (
    <ColorButtonContainer active={!!active} {...restProps}>
      <ColorButton>
        <ColorButtonColor backgroundColor={color} border={border} />
      </ColorButton>
    </ColorButtonContainer>
  )
}

export default HighlightColorDropdownMenu
