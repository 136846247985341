import React, { Fragment } from 'react'
import { PageTypesEnum } from 'interfaces/projectFile'
import NewPageDropdown from '../NewPageDropdown/NewPageDropdown'
import { useAddPage } from '../../model/useAddPage'
interface Props {
  toggleButton: React.ReactNode
  parentPageId?: string | number
  isScrollable?: boolean
}

export const SidebarItemNewPage = (props: Props) => {
  const { addPage } = useAddPage()

  return (
    <Fragment>
      <NewPageDropdown
        toggleButton={props.toggleButton}
        onSelectDoc={() => addPage(PageTypesEnum.DOCUMENT, props.parentPageId?.toString())}
        onSelectWb={() => addPage(PageTypesEnum.WHITE_BOARD, props.parentPageId?.toString())}
        parentPageId={props.parentPageId?.toString()}
        isScrollable={props.isScrollable}
      />
    </Fragment>
  )
}
