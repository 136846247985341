import React, { useEffect } from 'react'
import { Option } from 'interfaces/selectOptions'
import useLocalStorage from 'shared/lib/useLocalStorage'
import { SingleValue } from 'react-select'

const useLLMDropdown = () => {
  const [selected, setSelected] = React.useState<Option | null>(null)
  const [isShown, setIsShown] = React.useState(false)
  const { getParsedItem, setItem } = useLocalStorage()
  const options: Option[] = [
    {
      id: 1,
      label: 'GPT-3',
      value: 'chat-gpt',
    },
    {
      id: 2,
      label: 'Google Bard',
      value: 'google-bard',
    },
    {
      id: 3,
      label: 'LlaMA',
      value: 'LlaMA',
    },
    {
      id: 4,
      label: 'Amazon Redshift ML',
      value: 'amazon-redshift-ml',
    },
  ]

  useEffect(() => {
    const selectedLLM = getParsedItem('LLM')

    if (!selectedLLM) {
      setSelected(options[0])
      setItem('LLM', options[0])
    } else {
      setSelected(selectedLLM)
    }
  }, [])

  const handleSelect = (option: SingleValue<Option>) => {
    setSelected(option)
    setItem('LLM', option)
  }

  return {
    selected,
    setSelected,
    isShown,
    setIsShown,
    options,
    handleSelect,
  }
}

export default useLLMDropdown
