export const extractVimeoVideoId = (vimeoLink: string) => {
  const regex = /vimeo\.com\/(\d+)/
  const match = vimeoLink.match(regex)

  if (match && match[1]) {
    return match[1]
  } else {
    return null
  }
}
