import { useEffect, useState } from 'react'
import { useChatDrawer } from '_widgets'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  ChannelOption,
  setIsMessageThreadOpen,
  setMessagesWithBlock,
  setObjectChatToView,
  setSelectedChannel,
  setSelectedMessages,
} from 'redux/reducers/chatReducer'
import { useChannelStateContext } from 'stream-chat-react'
import useCustomMessage from '../ChatCustomMessage/useCustomMessage'
import { MessageResponseWithBlock } from 'interfaces/chat'
import { useGetPage } from '_entities/page'

const useChatFilter = () => {
  // Hooks
  const { channelOptions } = useChatDrawer()
  const { messages } = useChannelStateContext()
  const dispatch = useAppDispatch()
  const { handleCloseThread } = useCustomMessage()
  const { getPageObjects } = useGetPage()

  // State
  const [selectedFilter, setSelectedFilter] = useState(channelOptions?.[0]?.cid)
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const selectedChannel = useAppSelector((state) => state.chat.selectedChannel)
  const isMessageThreadOpen = useAppSelector((state) => state.chat.isMessageThreadOpen) || false
  const messagesWithBlock = useAppSelector((state) => state.chat.messagesWithBlock)

  const selectedMessages =
    channelOptions.length > 0 &&
    channelOptions?.find((channel) => channel?.cid?.replace('messaging:', '') === selectedPage?.id)

  const handleFilterSelection = (channelOption: ChannelOption) => {
    if (selectedFilter?.replace('messaging:', '') === selectedPage?.id) {
      setSelectedFilter(channelOptions?.[0]?.cid)
      dispatch(setSelectedChannel(channelOptions?.[0].cid))
      return
    }
    setSelectedFilter(channelOption?.cid)
    dispatch(setSelectedChannel(channelOption?.cid))
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(setMessagesWithBlock(null))
      if (selectedChannel && selectedChannel !== channelOptions[0].cid) {
        const allMessages = messages as MessageResponseWithBlock[]
        let filteredMessages = await Promise.all(
          allMessages.map(async (message: MessageResponseWithBlock) => {
            const referencedBlock = await fetchBlock(message as MessageResponseWithBlock)

            if (!referencedBlock) return undefined

            if (
              message?.referencedBlock?.blockId &&
              referencedBlock?.meta?.pageId === selectedChannel.replace('messaging:', '')
            ) {
              return message
            }
          }),
        )

        filteredMessages = filteredMessages?.filter((message) => message !== undefined)

        if (filteredMessages.length === 0) return

        dispatch(setSelectedMessages([...filteredMessages]))
      }

      if (selectedChannel && selectedChannel === channelOptions[0].cid) {
        dispatch(setSelectedMessages(null))
        setSelectedFilter(channelOptions[0]?.cid)
      }
    }

    fetchData()
  }, [selectedChannel])

  const fetchBlock = async (message: MessageResponseWithBlock) => {
    if (message?.referencedBlock) {
      return await getPageObjects(
        `${message.referencedBlock.pageId}`,
        `${message.referencedBlock.blockId}`,
      )
    } else {
      return null
    }
  }

  const handleCloseBlockChat = () => {
    dispatch(setMessagesWithBlock(null))
    dispatch(setIsMessageThreadOpen(false))
    dispatch(setObjectChatToView(null))
    handleCloseThread()
  }

  return {
    selectedFilter,
    channelOptions,
    handleFilterSelection,
    selectedPage,
    isMessageThreadOpen,
    handleCloseBlockChat,
    messagesWithBlock,
    selectedMessages,
  }
}

export default useChatFilter
