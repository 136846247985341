import { StyledTitle } from 'components/templates/Page/ProfileDrawer/styles'
import { CtaWrapper } from './styles'
import { CTAButtonSettings } from './CTAButtonSettings'

export const ProfileCtaSection = () => {
  return (
    <CtaWrapper>
      <StyledTitle>CTAs</StyledTitle>
      <CTAButtonSettings isDocument={false} />
    </CtaWrapper>
  )
}
