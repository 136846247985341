import { Form } from 'react-bootstrap'
import styled from 'styled-components'

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 120%;
`
export const ModalInnerContainer = styled.div`
  padding: 12px 24px;

  & *::placeholder {
    color: #d8d8d8;
  }

  & form * {
    font-size: 14px;
  }
`
export const ForgotPasswordLink = styled.div`
  color: var(--primary-color);
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  margin-block: 0 !important;
`
export const Button = styled.button`
  color: white;
  background-color: var(--primary-color);
  padding: 8px 16px 8px 16px;
  border-radius: 10px;
  border: none;
  font-weight: 600 !important;
  font-size: 14px;
  margin-block: 0 !important;

  :hover {
    opacity: 0.8;
  }
`
export const ModalSubtitle = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 50%;
`

export const ModalInfoLabel = styled.label`
  color: var(--gray2) !important;
  font-weight: 600;
  font-size: 13px;
  margin-block: 0.15rem !important;
`

export const InputControl = styled(Form.Control)`
  border-radius: 10px;

  :focus {
    box-shadow: none;
    border: 2px solid var(--everyday-blue);
  }
`
