import { IFrameItem } from 'interfaces/whiteboard'
import { NodeFit } from '../zoom'

export enum WhiteboardAttrs {
  IS_PRESENTATION = 'is-presentation',
  SHOULD_SHOW_PRESENTATION_CONTROLS = 'should-show-presentation-controls',
  PRESENTATION_CONTROLS_ENABLED = 'presentation-controls-enabled',
  PRESENTATION_FRAME_FIT = 'presentation-frame-fit',
  CURRENT_FRAME = 'current-frame',
}

export interface WhiteboardAttrsData {
  [WhiteboardAttrs.IS_PRESENTATION]: boolean
  [WhiteboardAttrs.SHOULD_SHOW_PRESENTATION_CONTROLS]: boolean
  [WhiteboardAttrs.PRESENTATION_CONTROLS_ENABLED]: boolean
  [WhiteboardAttrs.PRESENTATION_FRAME_FIT]: NodeFit
  [WhiteboardAttrs.CURRENT_FRAME]: IFrameItem
}
