import { PropertyTitle, PropertyWrapper } from '../styles'
import CustomDatePicker from 'components/molecules/CustomDatePicker/CustomDatePicker'
import { StyledChipWrapper, StyledDatePickerWrapper } from '../../../TaskManagerModal/styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { icons } from 'shared/icons'
import React from 'react'
import { type block } from '_entities/block'
import useDueDate from './useDueDate'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { StyledButton } from '_entities/assignee/ui/styles'

interface IProps {
  block: block
}

const DueDate = (props: IProps) => {
  const _dueDate = useDueDate({ block: props.block })

  return (
    <PropertyWrapper width={'50%'}>
      <PropertyTitle>Due date</PropertyTitle>
      <CustomDatePicker
        isClearable
        onChange={(date) => _dueDate.onDateChange(date, props.block)}
        customInput={
          <StyledButton width='100%'>
            <StyledChipWrapper>
              {props.block.meta.dueDate ? (
                <CustomP color='var(--primary-text)' paddingRight={'12px'}>
                  {new Date(props.block.meta.dueDate).toLocaleDateString('en-US')}
                </CustomP>
              ) : (
                <Wrapper padding={'0 0 0 0'}>
                  <icons.calendarPlus />
                </Wrapper>
              )}
            </StyledChipWrapper>
          </StyledButton>
        }
        selected={props.block.meta.dueDate ? new Date(props.block.meta.dueDate) : null}
      />
    </PropertyWrapper>
  )
}

export default DueDate
