import {
  ButtonWrapper,
  CtaSettingsWrapper,
  StyledDirectionIcon,
  DropdownWrapper,
  StyledDirectionOutIcon,
  StyledCloseIcon,
  PlusIcon,
  PlusIconWrapper,
} from './styles'
import { useCta } from '../hooks'
import {
  CTABtn,
  CtaButton,
  getCtaBorderColor,
  getCtaBorderRadius,
  getCtaButtonColor,
  getCtaButtonHeight,
  getCtaFont,
  getCtaFontSize,
  getCtaTextColor,
  getCtaTitle,
  DocumentBlockAttributes,
  getCtaButtonId,
  getCtaFontWeight,
  getCtaFontWeightValue,
} from '_entities/cta'
import Divider from 'components/atoms/Divider/Divider'
import { block } from '_entities/block'
import { ButtonsList, Settings } from './Settings'
import { ctaEndpoints } from '../api'
import { useAppSelector } from 'redux/hooks'
import { useObservationOnDocumentBlock } from '_features/block'
import { getById } from 'shared/lib'
import React from 'react'

export interface CTAButtonSettingsProps {
  isDocument?: boolean
  button?: CtaButton | null
  block?: block
}

export const CTAButtonSettings = (props: CTAButtonSettingsProps) => {
  // ** Hooks
  const _cta = useCta(props)
  const _observeDocBlock = useObservationOnDocumentBlock()

  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const [handleGetCtaButtonById] = ctaEndpoints.endpoints.getCtaButtonById.useLazyQuery()

  const fetchButton = async () => {
    if (!projectFile || !_cta.selectedButton) return

    const button = await handleGetCtaButtonById({
      projectFileId: projectFile.id,
      id: _cta.selectedButton.id,
    }).unwrap()

    if (button) {
      _cta.setButtonForEdit(button)
      _cta.setSelectedButton(button)
    }
  }

  return (
    <CtaSettingsWrapper isDocument={props.isDocument}>
      <ButtonWrapper>
        {_cta.selectedButton && (
          <CTABtn
            key={getCtaButtonId(_cta.selectedButton.id)}
            contentEditable
            fontFamily={getCtaFont(_cta.selectedButton)}
            fontSize={getCtaFontSize(_cta.selectedButton)}
            textColor={getCtaTextColor(_cta.selectedButton)}
            buttonBackgroundColor={getCtaButtonColor(_cta.selectedButton)}
            borderRadius={getCtaBorderRadius(_cta.selectedButton)}
            buttonSize={getCtaButtonHeight(_cta.selectedButton)}
            borderColor={getCtaBorderColor(_cta.selectedButton)}
            fontWeight={getCtaFontWeightValue(_cta.selectedButton)}
            onInput={(e) => _cta.selectedButton && _cta.onTitleChange(e, _cta.selectedButton)}
            id={getCtaButtonId(_cta.selectedButton.id)}
            ref={(node) => {
              if (!node) return

              _observeDocBlock.observeOnDocumentBlock(
                node.id,
                DocumentBlockAttributes.BUTTON_JUST_UPDATED,
                async () => {
                  const isJustUpdated =
                    node.getAttribute(DocumentBlockAttributes.BUTTON_JUST_UPDATED) === 'true'

                  if (isJustUpdated) {
                    // node.removeAttribute(DocumentBlockAttributes.BUTTON_JUST_UPDATED)
                    await fetchButton()
                  }
                },
              )
            }}
          >
            {getCtaTitle(_cta.selectedButton)}
          </CTABtn>
        )}
        {props.isDocument && (
          <StyledCloseIcon
            onClick={() => {
              const buttons = getById(getCtaButtonId(props.block?._id))

              if (buttons) {
                buttons.removeAttribute(DocumentBlockAttributes.BUTTON_IS_EDITING)
              }
            }}
          />
        )}
        {!props.isDocument && (
          <div>
            {_cta.isButtonsDropdownOpen ? (
              <StyledDirectionOutIcon onClick={_cta.toggleIsButtonsContainerOpen} />
            ) : (
              <StyledDirectionIcon onClick={_cta.toggleIsButtonsContainerOpen} />
            )}
          </div>
        )}
        <DropdownWrapper isButtonsDropdownOpen={_cta.isButtonsDropdownOpen}>
          <ButtonsList
            wrapperRef={_cta.wrapperRef}
            isDocument={props.isDocument}
            createCtaButton={_cta.createCtaButton}
            setButtonForEdit={_cta.setButtonForEdit}
            deleteCtaButton={_cta.deleteButton}
            loadedData={_cta.loadedData}
          />
          <PlusIconWrapper>
            <PlusIcon
              onClick={() => {
                _cta.createCtaButton()
              }}
            />
          </PlusIconWrapper>
        </DropdownWrapper>
      </ButtonWrapper>

      <Divider margin='auto' width='90%' />

      <Settings
        updateCtaButton={_cta.updateCtaButton}
        isButtonsDropdownOpen={_cta.isButtonsDropdownOpen}
        onChangeLink={_cta.onChangeLink}
        button={_cta.selectedButton}
        isDocument={props.isDocument}
      />
    </CtaSettingsWrapper>
  )
}
