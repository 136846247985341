import { useStage } from '_features/canvas'
import { Layer } from 'konva/lib/Layer'

export const getLayerId = (pageId: string) => `main-layer-${pageId}`

export const getGridLayerId = (pageId: string) => `grid-layer-${pageId}`

export const useLayer = () => {
  const { getStage } = useStage()
  const getLayer = (pageId: string): Layer | undefined => {
    const stage = getStage(pageId)
    return stage?.findOne(`#${getLayerId(pageId)}`)
  }

  const getGridLayer = (pageId: string): Layer | undefined => {
    const stage = getStage(pageId)
    return stage?.findOne(`#${getGridLayerId(pageId)}`)
  }

  return {
    getLayer,
    getGridLayer,
  }
}
