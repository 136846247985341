import { getWhiteboardContainer } from '_entities/whiteboard'
import { ITools } from 'interfaces/whiteboard'
import { useEffect } from 'react'
import { useAppSelector } from 'redux/hooks'
import { setCrosshair, setGrab, setInitial } from 'shared/lib'

interface Props {
  pageId: string
}

export const useCursor = (props: Props) => {
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const whiteboardContainer = getWhiteboardContainer(props.pageId)
  useEffect(() => {
    if (!whiteboardContainer) return
    if (tool === ITools.HAND) {
      setGrab(whiteboardContainer)
    } else if (
      tool === ITools.TEXT ||
      tool === ITools.PENTAGON ||
      tool === ITools.HEXAGON ||
      tool === ITools.ROMB ||
      tool === ITools.CIRCLE ||
      tool === ITools.RECTANGLE ||
      tool === ITools.CURVED_RECTANGLE ||
      tool === ITools.TRIANGLE ||
      tool === ITools.STICKY ||
      tool === ITools.EMOJI ||
      tool === ITools.INTERNAL_EMBED ||
      tool === ITools.EXTERNAL_EMBED ||
      tool === ITools.FILE ||
      tool === ITools.FRAME_SIXTEEN_NINE ||
      tool === ITools.FRAME_FOUR_THREE ||
      tool === ITools.FRAME_ONE_ONE ||
      tool === ITools.FRAME_CUS
    ) {
      setCrosshair(whiteboardContainer)
    } else setInitial(whiteboardContainer)
  }, [tool])
}
