import { SourceKeys } from 'interfaces/editor'
import { type block, IBlockTypes, getBlockPageId, getIndex } from '_entities/block'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useCallback } from 'react'
import objectId from 'utils/editor/objectId'
import { getBlocksLength } from 'shared/shareDb'
import { getEditor } from 'shared/lib'

export const useUpdateBlock = () => {
  // ** Hooks
  const _json1 = useJson1()
  const _submit = useSubmit()

  const updateBlockName = useCallback((block: block, name: string) => {
    if (!block) return

    const index = getIndex(block)

    if (index === -1) return

    const op = _json1.getReplaceBlockMetaKeyOp(index, ['name'], name)

    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }, [])

  const changeToTextBlock = useCallback((block: block) => {
    const index = getIndex(block)
    if (index === -1) return
    const updatedBlock = {
      ...block,
      data: {
        ...block.data,
        tag: IBlockTypes.TEXT,
        indent: 0,
      },
    }
    delete updatedBlock.data.numberInList
    const replaceOp = _json1.replaceBlock(updatedBlock, index, block)
    _submit.submit(getBlockPageId(block), replaceOp, SourceKeys.UPDATE_BLOCK)
  }, [])

  const handleSwitchToList = useCallback(
    (
      currentBlock: block,
      newBlock: block,
      type: IBlockTypes,
      numberInList?: number | null | string,
      indent?: number | null,
    ) => {
      const totalNumberOfBlocks = getBlocksLength(newBlock.meta.pageId)
      const blockIndex = getIndex(newBlock)
      const isTheLastBlock = blockIndex + 1 === totalNumberOfBlocks
      if (isTheLastBlock) void isTheLastBlock
      const updatedBlock = {
        ...currentBlock,
        data: {
          ...currentBlock.data,
          tag: type,
          numberInList,
          indent: indent ?? 0,
        },
      }
      const editor = getEditor(newBlock._id)
      if (type === IBlockTypes.NUMBERED_LIST) {
        // If the block is numbered list we need to delete number and dot
        editor?.deleteText(0, 2)
      } else {
        // If the block is not numbered list we need to delete only the character
        editor?.deleteText(0, 1)
      }
      const replaceOp = _json1.replaceBlock(updatedBlock, blockIndex, currentBlock)
      _submit.submit(getBlockPageId(currentBlock), replaceOp, SourceKeys.UPDATE_BLOCK)
    },
    [],
  )

  const copyBlockHandler = (block: block, destinationIndex?: number) => {
    const newBlock = {
      ...block,
      _id: objectId(),
      data: {
        ...block.data,
        positionIndex: destinationIndex,
      },
    }
    setTimeout(() => {
      _submit.submit(
        getBlockPageId(block),
        _json1.addBlock(newBlock, destinationIndex as number),
        SourceKeys.UPDATE_BLOCK,
      )
    })
  }

  return {
    updateBlockName,
    changeToTextBlock,
    handleSwitchToList,
    copyBlockHandler,
  }
}
