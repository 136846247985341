import styled from 'styled-components'
import { icons } from 'shared/icons'

export const StyledDefaultsWrapper = styled.div`
  padding: 12px 24px 12px 24px;
  height: auto;
  border-top: none;
`

export const ActionsWrapper = styled.div``
export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 0;
`

export const ActionTitle = styled.p`
  font-size: 14px;
  color: var(--primary-text);
`

export const AddButtonWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: var(--everyday-blue);
  cursor: pointer;
  padding: 8px 12px 8px 12px;
  border-radius: 10px;

  &:hover {
    background: var(--hover);
    color: #8aadff;
  }
`

export const OptionWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
`

export const SelectedDefaultWrapper = styled.div`
  margin-top: -14px;
  margin-left: 8px;
  display: flex;
  gap: 6px;
  align-items: center;
  position: relative;
  padding-bottom: 16px;
`

export const SelectedName = styled.div`
  font-size: 14px;
  font-weight: 600;
`

export const StyledTrashIcon = styled(icons.trash)`
  position: absolute;
  right: 0;
  cursor: pointer;
`

export const StyledEditIcon = styled(icons.pencil)`
  position: absolute;
  right: 32px;
  cursor: pointer;
`
