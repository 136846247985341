import { IUserAttributes } from 'interfaces/auth/userData'
import { useAppDispatch } from 'redux/hooks'
import { setUser, setUserImage } from 'redux/reducers/globalReducer'
import tinycolor from 'tinycolor2'
import { AxiosService } from 'services/axiosService/axiosService'

export const useViewer = () => {
  const dispatch = useAppDispatch()
  const AxiosInstance = new AxiosService()
  const getMe = async () => {
    return await AxiosInstance.getMe()
  }

  const getAndSaveViewer = async (): Promise<IUserAttributes | null> => {
    const user = await getMe()
    if (user) {
      user.userColor = tinycolor.random().toHexString()
      dispatch(setUser(user))
      const userImage = await getProfileImage(user.id)
      if (userImage) {
        dispatch(setUserImage(userImage.data))
      }
      return user
    } else return null
  }

  const getProfileImage = async (userId: number) => {
    const response = await AxiosInstance.getUserProfileImage(userId)
    if (response && response.data !== undefined) {
      dispatch(setUserImage(`${response.data}`))
      return response
    }
  }

  const deleteViewer = () => {
    dispatch(setUser(undefined))
  }
  return {
    getMe,
    getAndSaveViewer,
    getProfileImage,
    deleteViewer,
  }
}
