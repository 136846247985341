import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

interface Props {
  styles?: string
  padding?: string
  fontSize?: string
  borderRadius?: string
  borderColor?: string
  borderColourForFocus?: string
  margin?: string
  placeholderFontSize?: string
  position?: string
  left?: string
  background?: string
  color?: string
}

export const BasicInput = styled(Form.Control)<Props>`
  font-size: ${(props) => props.fontSize};
  border-color: ${(props) => props.borderColor};
  border-radius: ${(props) => props.borderRadius};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  position: ${(props) => props.position};
  left: ${(props) => props.left};
  background: ${(props) => props.background};
  color: ${(props) => props.color};

  &::placeholder {
    font-family: Helvetica, 'FontAwesome', sans-serif !important;
    color: var(--gray3);
    font-weight: 500;
    font-size: ${(props) => props.placeholderFontSize || '14px'};
  }
  ${(props) => props.styles}

  :focus {
    border-color: ${(props) => (props.borderColourForFocus ? props.borderColourForFocus : '')};
    box-shadow: none;
    background: ${(props) => props.background};
    color: ${(props) => props.color};
  }
`

export const BasicLabel = styled(Form.Label)``
