import React from 'react'
import { Tree, TreeProps, MultiBackend, getBackendOptions } from '@minoru/react-dnd-treeview'
import { NativeTypes } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import './styling.css'

type Props<T> = Pick<
  TreeProps<T>,
  | 'tree'
  | 'onDrop'
  | 'rootId'
  | 'render'
  | 'canDrag'
  | 'canDrop'
  | 'onDragStart'
  | 'onChangeOpen'
  | 'onDragEnd'
  | 'dragPreviewRender'
  | 'placeholderRender'
  | 'initialOpen'
  | 'classes'
>

export const TreeView: React.FC<Props<any>> = (props) => (
  <DndProvider backend={MultiBackend} options={getBackendOptions()}>
    <Tree
      tree={props.tree}
      initialOpen={props.initialOpen}
      onDrop={props.onDrop}
      onDragStart={props.onDragStart}
      onChangeOpen={props.onChangeOpen}
      onDragEnd={props.onDragEnd}
      rootId={props.rootId}
      sort={false}
      dropTargetOffset={0}
      insertDroppableFirst={false}
      canDrag={props.canDrag}
      canDrop={props.canDrop}
      classes={props.classes}
      extraAcceptTypes={[NativeTypes.TEXT]}
      render={props.render}
      dragPreviewRender={props.dragPreviewRender}
      placeholderRender={props.placeholderRender}
      enableAnimateExpand
    />
  </DndProvider>
)
