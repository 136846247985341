import React from 'react'
import { ArrowDownIcon, StyledDropdownMenu, StyledIconButton } from './styles'
import MultiSelect from 'components/organisms/MultiSelect/MultiSelect'
import { useFilterByDropdown } from './useFilterByDropdown'
import { IPropertyDefinition } from 'interfaces/taskManager'
import Button from 'components/atoms/Button/Button'
import { generateProperties } from 'services/taskManager/generateProperties'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

interface Props {
  propertyDefinition: IPropertyDefinition
}

const FilterByDropdown = (props: Props) => {
  const { propertyDefinition } = props
  const { name } = propertyDefinition

  const { onChange, options } = useFilterByDropdown({ propertyDefinition })

  const propertyIcon = generateProperties().find(
    (prop) => prop.type === propertyDefinition.type,
  )?.icon

  return (
    <RadixDropdown
      trigger={
        <StyledIconButton>
          <Button
            size='small'
            variant='transparent'
            leftIcon={propertyIcon}
            style={{ color: 'var(--primary-text)' }}
            rightIcon={<ArrowDownIcon stroke='var(--primary-text)' />}
          >
            {name}
          </Button>
        </StyledIconButton>
      }
    >
      <StyledDropdownMenu>
        <MultiSelect options={options} onChange={onChange} />
      </StyledDropdownMenu>
    </RadixDropdown>
  )
}

export default FilterByDropdown
