import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import {
  Button,
  Input,
  ModalInnerContainer,
  ModalTitle,
} from 'components/molecules/ChangeNameModal/styles'
import { ModalInfoLabel } from 'components/molecules/ChangeEmailModal/styles'

interface Props {
  show: string | null
  handleClose: () => void
  saveChanges: (projectId: string) => Promise<void>
  onShow: () => void
  setName?: React.Dispatch<React.SetStateAction<string>>
  name?: string
  placeholder?: string
  label?: string
}

const ChangeProjectNameModal = (props: Props) => {
  return (
    <Modal
      show={props.show !== null}
      onShow={props.onShow}
      onHide={props.handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='changeEmailModal'
    >
      <ModalInnerContainer placeholderColour='var(--gray3)'>
        <Modal.Header closeButton className='modalHeader border-0'>
          <ModalTitle>Change name</ModalTitle>
        </Modal.Header>
        <Modal.Body className='border-0'>
          <Form>
            <Form.Group className='mb-3'>
              <ModalInfoLabel>{props.label || 'Firstname'}</ModalInfoLabel>
              <Input
                value={props.name}
                type='text'
                placeholder={props.placeholder || 'Enter your new space name'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  e.preventDefault()
                  props.setName && props.setName((e.currentTarget as HTMLInputElement).value)
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    props.saveChanges(props.show || '')
                  }
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-end'>
          <Button onClick={() => props.saveChanges(props.show || '')} disabled={!props.name}>
            Save name
          </Button>
        </Modal.Footer>
      </ModalInnerContainer>
    </Modal>
  )
}

export default ChangeProjectNameModal
