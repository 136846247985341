import React from 'react'
import { passwordStrength } from 'check-password-strength'

type Props = {
  password: string
}
const PasswordStrenghtBar: React.FC<Props> = ({ password }) => {
  return (
    <>
      {passwordStrength(password).length > 0 &&
        {
          'Too weak': (
            <div className='progress mt-1 mb-4'>
              <div
                className='w-25 progress-bar bg-danger progress-bar-striped progress-bar-animated'
                role='progressbar'
                aria-valuenow={25}
                aria-valuemin={0}
                aria-valuemax={100}
              >
                Too Weak
              </div>
            </div>
          ),
          Weak: (
            <div className='progress mt-1 mb-4'>
              <div
                className='w-50 progress-bar bg-warning progress-bar-striped progress-bar-animated'
                role='progressbar'
                aria-valuenow={50}
                aria-valuemin={25}
                aria-valuemax={100}
              >
                Weak
              </div>
            </div>
          ),
          Medium: (
            <div className='progress mt-1 mb-4'>
              <div
                className='w-75 progress-bar progress-bar-striped progress-bar-animated'
                role='progressbar'
                aria-valuenow={75}
                aria-valuemin={50}
                aria-valuemax={100}
              >
                Medium
              </div>
            </div>
          ),
          Strong: (
            <div className='progress mt-1 mb-4'>
              <div
                className='w-100 progress-bar bg-success progress-bar-striped progress-bar-animated'
                role='progressbar'
                aria-valuenow={100}
                aria-valuemin={75}
                aria-valuemax={100}
              >
                Strong
              </div>
            </div>
          ),
        }[passwordStrength(password).value]}
    </>
  )
}

export default PasswordStrenghtBar
