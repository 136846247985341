import React from 'react'
import Image from 'components/atoms/Image/Image'
import CompanyInfoSection from 'components/molecules/CompanyInfoSection/CompanyInfoSection'
import everydayLogo from 'assets/images/everydayTextLogo.svg'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Row } from 'react-bootstrap'
import { StyledAuthWrapperComponent, StyledRow, StyledP } from './styles'

const AuthWrapperComponent = styled.div`
  ${StyledAuthWrapperComponent}
`

const RowComponent = styled(Row)`
  ${StyledRow}

  & > div:first-of-type {
    width: min(90%, 550px) !important;
    padding-inline: 2.5rem;

    @media (max-width: 768px) {
      width: 100% !important;
      padding-inline: 0;
    }
  }
`
const Paragraph = styled.p`
  ${StyledP}
`
type Props = {
  elements: React.ReactNode
  text?: React.ReactNode
  question?: React.ReactNode
  linkPassed?: string
}
const AuthWrapper: React.FC<Props> = ({ elements, text, question, linkPassed }) => {
  const link = !linkPassed ? (text === 'Sign up' ? '/' : '/signin') : linkPassed
  return (
    <AuthWrapperComponent className='d-flex justify-content-center align-items-center container pt-0'>
      <CompanyInfoSection />
      <Image
        src={everydayLogo}
        alt='Everyday logo'
        width={'min(100%, 250px)'}
        margin={'auto auto 36px'}
        className='pt-5'
      />
      <RowComponent>
        <div className='col-10 col-md-8 col-lg-6 col-xl-4 mb-3'>
          {elements}
          <Paragraph className='pt-4 text-center d-flex gap-3 ps-0 justify-content-center'>
            {question}
            <Link to={link} className='text-primary px-1 addEverydayBlue fw-bold'>
              {text}
            </Link>
          </Paragraph>
        </div>
      </RowComponent>
    </AuthWrapperComponent>
  )
}

export default AuthWrapper
