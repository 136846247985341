import styled from 'styled-components'

export const StyledDivider = styled.hr<{
  width?: string
  height?: string
  color?: string
  margin?: string
}>`
  width: ${(props) => props.width};
  border-top: ${(props) => props.color && props.height && `${props.height} solid ${props.color}`};
  margin: ${(props) => props.margin};
`
