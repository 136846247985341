import { useState } from 'react'
import { type block, getIndex, IBlockTypes, getBlockPageId } from '_entities/block'
import { useToast } from 'services/helpers/useToast'
import { AxiosService } from 'services/axiosService/axiosService'
import { constructFormData } from 'utils/constructFormData'
import { isCorrectFileType } from 'utils/isCorrectFileType'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { SourceKeys } from 'interfaces/editor'
import { getFileInputId, IMAGE_DEFAULT_WIDTH, IMAGE_DEFAULT_ZOOM_SIZE } from '_features/editor'
import { usePresence } from 'shared/shareDb'

export const useFilesHandler = () => {
  // ** State
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [uploadProgress, setUploadProgress] = useState<string>('')

  // ** Hooks
  const toast = useToast()
  const axiosInstance = new AxiosService()
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _presence = usePresence()

  const handleImageUpload = async (block: block, tag: IBlockTypes, inputEl?: HTMLInputElement) => {
    setIsLoading(true)
    const fileInput = document.getElementById(getFileInputId(block._id)) as HTMLInputElement
    const fileFromFileInput = fileInput && fileInput.files && fileInput.files[0]

    let imageFile: File | null = fileFromFileInput

    if (inputEl?.files) {
      imageFile = inputEl.files[0]
    }

    if (imageFile) {
      const formData = constructFormData(imageFile, getBlockPageId(block))
      const incorrectBlock = {
        ...block,
        data: {
          ...block.data,
          imageUrl: undefined,
        },
      }

      const index = getIndex(block)

      if (index === -1) return

      let op = _json1.replaceBlock(incorrectBlock, index, block)
      if (imageFile.size > 45000000) {
        _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)

        setUploadProgress('')
        return toast.error('Maximum file size is 45 MB')
      }
      if (!isCorrectFileType(block.data.tag ?? '', imageFile.name)) {
        setIsLoading(false)
        setUploadProgress('')
        _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
        return toast.error('Wrong file type.')
      }
      const response = await axiosInstance.postImage(
        formData,
        {
          'Content-Type': 'multipart/form-data',
        },
        (progressEvent) => {
          const progress = (progressEvent.loaded / progressEvent.total) * 100
          const shortProgress = progress.toString().substring(0, 5)
          setUploadProgress(shortProgress)
        },
        () => {
          void 0
        },
      )
      if (response) {
        setUploadProgress('')
        const image = await axiosInstance.getImage(response.id)
        if (image) {
          const newBlock = {
            ...block,
            data: {
              ...block.data,
              tag,
              imageUrl: image.data,
              imageId: block.data.imageId || response.id,
              sizeValue: block.data.sizeValue || IMAGE_DEFAULT_ZOOM_SIZE,
              imageBorderWrapperWidth: block.data.imageBorderWrapperWidth || IMAGE_DEFAULT_WIDTH,
            },
          }

          op = _json1.replaceBlock(newBlock, index, block)
          _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
          setIsLoading(false)
        }
      } else {
        setUploadProgress('')
        setIsLoading(false)
      }
    }
  }

  const onResizeStart = (block: block) => {
    _presence.updatePresence(block)
  }

  const onResizeStop = (block: block) => {
    _presence.removePresence(getBlockPageId(block))
  }
  return {
    isLoading,
    uploadProgress,
    handleImageUpload,
    onResizeStart,
    onResizeStop,
  }
}
