import { createSlice } from '@reduxjs/toolkit'
import { IUserAttributes } from 'interfaces/auth/userData'
import { IPalette, Setting } from 'interfaces/settings'

interface globalProps {
  user?: IUserAttributes
  userId: string
  userImage?: string
  colorPalette?: IPalette
  colorPalettes?: IPalette[]
  selectedColorPalette?: IPalette
  areErrorsShown?: boolean
  settings?: Setting[]
}

const initialState = { browser: '', userId: '', areErrorsShown: true } as globalProps

const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
    },
    setUserImage: (state, action) => {
      state.userImage = action.payload
    },
    setColorPalette: (state, action) => {
      state.colorPalette = action.payload
    },
    setColorPalettes: (state, action) => {
      state.colorPalettes = action.payload
    },
    setSelectedColorPalette: (state, action) => {
      state.selectedColorPalette = action.payload
    },
    setErrorShown: (state, action) => {
      state.areErrorsShown = action.payload
    },
    setSettings: (state, action) => {
      state.settings = action.payload
    },
  },
})

export const {
  setUser,
  setUserImage,
  setColorPalette,
  setColorPalettes,
  setSelectedColorPalette,
  setErrorShown,
  setSettings,
} = globalSlice.actions

export default globalSlice.reducer
