import { Transformer } from 'konva/lib/shapes/Transformer'
import { Node } from 'konva/lib/Node'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setSelectedBlock } from 'redux/reducers/whiteboardReducer'
import { ITools } from 'interfaces/whiteboard'
import {
  useStage,
  useTransformer,
  useAnchors,
  useIntersection,
  useSelection,
  useBoundingBox,
} from '_features/canvas'
import { KonvaMouseTouchEvent } from '_features/canvas/event'
import { useFrame } from '_entities/whiteboard'
import { getBlocks } from 'shared/shareDb'

export const useMouse = () => {
  const _stage = useStage()
  const _transformer = useTransformer()
  const dispatch = useAppDispatch()
  const _selection = useSelection()
  const _anchors = useAnchors()
  const _intersection = useIntersection()
  const _boundingBox = useBoundingBox()
  const _frame = useFrame()
  const tool = useAppSelector((state) => state.whiteboard.tool)

  const cursorMouseDown = (e: KonvaMouseTouchEvent) => {
    if (tool === ITools.CURSOR) {
      // We are setting selected block to undefined to remove toolbar while dragging
      // Setting selected block to undefined removes pointer events from embed blocks
      // not doing this in embed whiteboard ensures proper handling of embed canvas
      const isEmbed = e.target.getStage()?.attrs.isEmbed
      const parentPageId = e.target.getStage()?.attrs.parentPageId
      const stageId = e.target.getStage()?.attrs.id
      const docId = _stage.getDocIdFromStageId(stageId)
      // If the stage is mouse downed and it's not an embed,
      // we are dispatching undefined selected block
      if (!isEmbed) dispatch(setSelectedBlock(undefined))
      // If the stage is mouse downed and it's
      // inside an embed, we are dispatching embed
      // block in the parent page as selected block
      // so that is stays enabled for editing
      else {
        const blocks = getBlocks(parentPageId)
        const embedBlock = blocks?.find((block) => block.data.documentPage?.id === docId)
        if (!embedBlock) return
        dispatch(setSelectedBlock(embedBlock))
      }
      _boundingBox.setInitialDrawingPosition(docId)
      _boundingBox.createBoundingBox(docId)
    }
  }

  const selectMultipleNodes = (pageId: string) => {
    const allObjects = _stage.getAllNodes(pageId)
    const boundingBox = _boundingBox.getBoundingBox(pageId)
    if (allObjects && boundingBox) {
      const box = boundingBox.getClientRect()
      const selected = allObjects.filter((node: Node) => {
        if (_frame.isNodeFrameInsideCanvasInsideDoc(node)) return false
        return _intersection.haveRectsIntersection(box, node.getClientRect())
      })
      const transformer = _transformer.getTransformer(pageId) as Transformer
      if (transformer) {
        transformer.nodes(selected)
        _selection.setResizeEnabled(pageId)
        _anchors.setAnchors(pageId)
        _selection.checkRatio(pageId)
        transformer?.getLayer()?.batchDraw()
      }
    }
  }

  const handleMouseMove = (e: KonvaMouseTouchEvent) => {
    const stageId = e.target.getStage()?.attrs.id
    const docId = _stage.getDocIdFromStageId(stageId)
    _boundingBox.updateBoundingBox(docId)
  }

  const handleMouseUp = (e: KonvaMouseTouchEvent) => {
    const stageId = e.target.getStage()?.attrs.id
    const docId = _stage.getDocIdFromStageId(stageId)
    _boundingBox.removeBoundingBoxes(docId)
  }

  return {
    cursorMouseDown,
    handleMouseMove,
    handleMouseUp,
    selectMultipleNodes,
  }
}
