import styled, { css } from 'styled-components'
import { icons } from 'shared/icons'
import { motion } from 'framer-motion'

export const WhiteboardComponent = styled.div<{
  background?: string
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  background: ${(props) => props.background};
`

export const StyledToolbar = styled(motion.div)<{
  left?: string
  right?: string
  transform?: string
}>`
  padding: 4px;
  display: flex;
  position: absolute;
  height: fit-content;
  gap: 4px;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: 8px;
  transform: ${(props) => props.transform};
  z-index: 4;
  background-color: var(--overlay-modal);
  border-radius: 1000px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  transition: bottom 0.2s ease-in-out;

  svg path {
    stroke: var(--primary-text);
  }
`

export const IconWrapper = styled.div<{
  width?: string
  height?: string
  selected?: boolean
  padding?: string
  disabled?: boolean
}>`
  width: ${(props) => (props.width ? props.width : '36px')};
  ${(props) => props.height && `height: ${props.height}`};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10000px;
  background-color: ${(props) => (props.selected ? 'var(--hover)' : 'none')};
  border: 1.4px solid ${(props) => (props.selected ? 'var(--primary-color2)' : 'transparent')};
  padding: ${(props) => props.padding};
  user-select: none;

  color: var(--primary-text);
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: var(--hover);
        cursor: pointer;
      }
    `}
`

export const Icon = styled.div`
  object-fit: cover;
`

export const IconLine = styled.div`
  height: 36px;
  width: 1px;
  background-color: var(--whiteboard-background);
  margin: auto 0;
`

export const ChatWrapper = styled.div`
  position: absolute;
  left: 100vw;
  transform: translateX(-100%);
`

export const StyledChatIconReference = styled.div<{
  isMirror?: boolean
  isInstance?: boolean
  isBlockMedia?: boolean
}>`
  cursor: pointer;
  position: absolute;
  pointer-events: all;
  right: ${(props) =>
    props.isMirror && props.isInstance
      ? '-90%'
      : props.isMirror || props.isInstance
      ? '-65%'
      : '-35%'};
`

export const StyledHandIcon = styled(icons.hand)`
  path {
    fill: var(--primary-text);
    stroke: transparent !important;
  }
`

export const StyledPlusIcon = styled(icons.plus)`
  path {
    fill: var(--primary-text);
    stroke: transparent !important;
  }
`
