import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Style } from './styles'

const StyledDiv = styled.div`
  ${Style}
`

const CompanyInfoSection = () => {
  return (
    <StyledDiv className='col-12 col-lg-8 justify-content-center'>
      <Link to='/'>&copy; The Everyday Company</Link>
      <Link to='/'>Contact</Link>
      <Link to='/'>Privacy & terms</Link>
    </StyledDiv>
  )
}

export default CompanyInfoSection
