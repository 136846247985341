import { ProjectMembersWrapper } from './styles'
import { useProjectFileMembers } from './useProjectFileMembers'
import { ProjectMembersList } from 'components/molecules/ProjectMembersList.tsx/ProjectMembersList'
import { IProjectFileMember } from 'interfaces/projectFile'
import { useAppSelector } from 'redux/hooks'

const ProjectFileMembers = () => {
  const { pendingOptions, projectRolesOwner, projectRoles } = useProjectFileMembers()
  const projectMembers = useAppSelector((state) => state.projectFile.allMembers)

  return (
    <ProjectMembersWrapper className='scrollbar'>
      {projectMembers?.map((member: IProjectFileMember, i: number) => {
        return (
          <ProjectMembersList
            firstName={member?.user?.firstName}
            lastName={member?.user?.lastName}
            memberRoleType={member?.role?.type}
            optionsPending={pendingOptions}
            key={member?.id + i}
            email={member?.email}
            status={member?.status}
            memberId={parseInt(member?.id)}
            pendingMemberUserId={member?.id}
            inviteToken={member?.inviteToken?.id}
            projectRoles={projectRoles}
            projectRolesOwner={projectRolesOwner}
          />
        )
      })}
    </ProjectMembersWrapper>
  )
}

export default ProjectFileMembers
