export enum AccessType {
  PUBLIC = 'public',
  USER = 'user',
  LINK_OWNER = 'link_owner',
}

export enum EmbedType {
  IMAGE = 'image',
  PDF = 'pdf',
  VIDEO = 'video',
  VIMEO = 'vimeo',
  YOUTUBE = 'youtube',
  NOTION = 'notion',
  ASANA = 'asana',
  GOOGLE_DOC = 'googleDoc',
  GOOGLE_SLIDES = 'googleSlides',
  GOOGLE_SHEETS = 'googleSheets',
  GOOGLE_CALENDAR = 'googleCalendar',
  MIRO = 'miro',
  FIGMA = 'figma',
  OFFICE = 'office',
  GENERIC_LINK = 'generic',
  FILE = 'file',
  OFFICE_DOCUMENT = 'officeDocument',
  OFFICE_EXCEL = 'officeExcel',
  OFFICE_POWERPOINT = 'officePowerpoint',
}

export interface IExternalEmbed {
  type: EmbedType
  name: string
  url: string
  title?: string
  image?: string
  pageId?: string
  html?: string
}
