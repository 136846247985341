import React, { useState } from 'react'
import Card from 'components/organisms/Card/Card'
import AuthWrapper from 'components/templates/AuthWrapper/AuthWrapper'
import ForgotPassowordForm from 'components/organisms/ForgotPasswordForm/ForgotPasswordForm'
import { AxiosService } from 'services/axiosService/axiosService'
import DisplayCoverImage from 'components/templates/DisplayCoverImage/DisplayCoverImage'
import { MainWrapper } from 'views/styles'

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [AxiosInstance, setAxiosInstance] = useState<AxiosService>(new AxiosService())

  const validateEmail = () => {
    if (email.length !== 0) {
      const re = // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase()) ? true : false
    } else {
      return true
    }
  }

  const resetValuesBeforeReq = () => {
    setError('')
    setSuccessMessage('')
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    resetValuesBeforeReq()
    setLoading(true)
    const response = await AxiosInstance.resetEmail(email)
    if (response) setSuccessMessage('Email sent. Check your inbox.')
    else setError('Something went wrong')
    setLoading(false)
  }

  const BeforeP =
    'Enter the email address associated with your account and we’ll send you a link to reset your password.'
  const SuccessP = 'If you haven’t received an email in 5 minutes, check your spam or '

  return (
    <MainWrapper>
      <DisplayCoverImage />

      <AuthWrapper
        text={'Sign up'}
        question={'Don’t have an account?'}
        elements={
          <Card
            email={email}
            successMessage={successMessage}
            textBlack
            shadow
            radius
            alignLeft
            subtext=' '
            fontSize
            isTitleLeft
            isFontSmaller
            titleText={
              successMessage
                ? 'Thanks, check your email for instructions to reset your password'
                : 'Forgot your password?'
            }
            secondaryText={successMessage ? SuccessP : BeforeP}
            cardElements={
              <ForgotPassowordForm
                onSubmit={onSubmit}
                validateEmail={validateEmail}
                setEmail={setEmail}
                error={error}
                successMessage={successMessage}
                loading={loading}
              />
            }
          />
        }
      />
    </MainWrapper>
  )
}
export default ForgotPassword
