export const EditorToolbarFramer = {
  initial: {
    opacity: 0,
    scale: 0.8,
    top: '-1rem',
  },
  animate: {
    opacity: 1,
    scale: 1,
    top: '-3.5rem',
  },
  exit: {
    opacity: 1,
    scale: 1,
    top: '-3rem',
  },
}

export const RadixDropdownFramer = {
  initial: {
    opacity: 0,
    scale: 0.8,
    top: '-1rem',
  },
  animate: {
    opacity: 1,
    scale: 1,
    top: '-2rem',
  },
  exit: {
    opacity: 1,
    scale: 1,
    top: '-2rem',
  },
}

export const WhiteboardToolbarFramer = (opacity?: number) => {
  return {
    initial: {
      opacity: 0,
      scale: 0.8,
      bottom: '8px',
      left: '50%',
      translateX: '-50%',
    },
    animate: {
      opacity: opacity || 1,
      scale: 1,
      skewX: '0deg',
    },
    exit: {
      opacity: opacity || 1,
      scale: 1,
    },
  }
}
