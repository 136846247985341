export const useMessages = () => {
  const socket = window.__MANAGER_SOCKET__
  if (!socket) return
  const joinTaskManager = (id: number) => {
    socket.send(
      JSON.stringify({
        event: 'manager:join',
        data: {
          managerId: id,
        },
      }),
    )
  }

  const pingPong = () => {
    socket.send(
      JSON.stringify({
        event: 'ping',
      }),
    )
  }

  return {
    joinTaskManager,
    pingPong,
  }
}
