export const setInitial = (element?: HTMLElement) => {
  ;(element || document.body).style.cursor = 'initial'
}
export const setProgress = (element?: HTMLElement) => {
  ;(element || document.body).style.cursor = 'progress'
}

export const setCrosshair = (element?: HTMLElement) => {
  ;(element || document.body).style.cursor = 'crosshair'
}

export const setGrab = (element?: HTMLElement) => {
  ;(element || document.body).style.cursor = 'grab'
}

export const setGrabbing = (element?: HTMLElement) => {
  ;(element || document.body).style.cursor = 'grabbing'
}

export const setNSResize = (element?: HTMLElement) => {
  ;(element || document.body).style.cursor = 'ns-resize'
}
