import { block } from '_entities/block'
import {
  getBlockElemAttr,
  setBlockElemAttr,
  removeBlockElemAttr,
  EditorBlockAttrs,
  EditorBlockAttrsData,
} from '../ui/attributes'

export const getIsBlockSelected = (block: block) => {
  const isSelected = getBlockElemAttr(block, EditorBlockAttrs.IS_SELECTED)
  if (!isSelected) return false
  return JSON.parse(isSelected) as EditorBlockAttrsData[EditorBlockAttrs.IS_SELECTED]
}

export const setIsBlockSelected = (block: block) => {
  setBlockElemAttr(block, EditorBlockAttrs.IS_SELECTED, true)
}

export const removeIsBlockSelected = (block: block) => {
  removeBlockElemAttr(block, EditorBlockAttrs.IS_SELECTED)
}
