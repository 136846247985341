import { ModalInnerContainer } from 'components/molecules/ChangeNameModal/styles'
import { ModalSubtitle } from 'components/molecules/DeleteModal/styles'
import PinnedMessage from 'components/organisms/Chat/PinnedMessage/PinnedMessage'
import { Modal } from 'react-bootstrap'
import { StyledButtonCancel, StyledButtonDanger, StyledModalTitle } from './styles'
import { IPage } from 'interfaces/page'
import { type block } from '_entities/block'

interface DeleteMessageModalProps {
  open: boolean
  messageToDelete: any
  handleDeleteMessage: () => void
  handleDeleteThread: () => void
  handleCloseModal: () => void
  isSingleMessageDelete: boolean
  referencedPage: IPage | null
  referencedBlock: block | null
}

const DeleteMessageModal = ({
  open,
  messageToDelete,
  handleDeleteMessage,
  handleDeleteThread,
  handleCloseModal,
  isSingleMessageDelete,
  referencedPage,
  referencedBlock,
}: DeleteMessageModalProps) => {
  return (
    <Modal
      show={open}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='changeEmailModal'
      className='deleteMessageModal'
    >
      <ModalInnerContainer>
        <Modal.Header className='modalHeader border-0 pb-0'>
          <StyledModalTitle>Delete message?</StyledModalTitle>
        </Modal.Header>
        <ModalSubtitle className='modal-header border-0 m-0 pb-0'>
          {isSingleMessageDelete
            ? 'Are you sure you want to delete the following chat message?'
            : 'Are you sure you want to delete the following chat thread?'}
        </ModalSubtitle>
        <ModalSubtitle className='border-0 m-0 warning'>
          This action <b>can not be</b> undone.
        </ModalSubtitle>
        <Modal.Body className='border-0 p-0'>
          <PinnedMessage
            messageObject={messageToDelete}
            referencedBlock={referencedBlock}
            referencedPage={referencedPage}
          />
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex gap-12 pt-0'>
          <StyledButtonCancel
            onClickFunction={handleCloseModal}
            type='button'
            className='btn'
            width='74px'
            height='auto'
            margin='0 12px 0 0'
            fontWeight='600'
          >
            Cancel
          </StyledButtonCancel>
          <StyledButtonDanger
            onClickFunction={isSingleMessageDelete ? handleDeleteMessage : handleDeleteThread}
            className='btn btn-outline-danger'
            width='154px'
            height='auto'
            border='solid 1px #D6D6D6'
            fontWeight='600'
          >
            {isSingleMessageDelete ? 'Delete message' : 'Delete thread'}
          </StyledButtonDanger>
        </Modal.Footer>
      </ModalInnerContainer>
    </Modal>
  )
}

export default DeleteMessageModal
