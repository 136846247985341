import {
  SelectWithDropdownAndButtonWrapper,
  SelectWithDropdownWrapper,
} from 'components/templates/PageInviteModal/styles'
import { useProjectFileInviteByEmail } from '../useProjectFileInviteByEmail'
import SelectWithChips from 'components/organisms/SelectWithChipsInvites/SelectWithChipsInvites'
import CustomSelectDropdown from 'components/molecules/CustomSelectDropdown/CustomSelectDropdown'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import React from 'react'
import Spinner from 'react-bootstrap/Spinner'

const InviteByEmail = () => {
  const {
    userRoles,
    selectRef,
    updateEmailPermission,
    setInviteEmailsState,
    inviteToProject,
    suggestedUsersPromise,
    inviteLoading,
  } = useProjectFileInviteByEmail()
  return (
    <SelectWithDropdownAndButtonWrapper>
      <SelectWithDropdownWrapper>
        <SelectWithChips
          selectRef={selectRef}
          setInviteEmailsState={setInviteEmailsState}
          suggestedUsersPromise={suggestedUsersPromise}
          width={'100%'}
          borderradius='8px !important'
        />
        <CustomSelectDropdown
          margin='0 1rem'
          options={userRoles}
          defaultSelect={userRoles[0].name}
          dropdownWidth={'138px'}
          onChange={updateEmailPermission}
        />
      </SelectWithDropdownWrapper>

      <CustomButton
        margin={'5px'}
        padding={'8px 20px'}
        width={'fit-content'}
        height={'36px'}
        className='d-flex justify-content-center align-items-center gap-2 border-0 position-relative cdx-loader'
        borderradius='8px'
        background={getColor('--primary-color')}
        onClickFunction={inviteToProject}
      >
        {inviteLoading ? (
          <Spinner size='sm' variant='light' />
        ) : (
          <CustomP className='mb-0 text-white'>Send&nbsp;invite</CustomP>
        )}
      </CustomButton>
    </SelectWithDropdownAndButtonWrapper>
  )
}

export default InviteByEmail
