import textFormat from 'assets/images/text_format.svg'
import { type block } from '_entities/block'
import { useSidebar } from './useSidebar'
import getBlockStyleValue from 'utils/editor/getBlockStyleValue'
import {
  BlockTitle,
  ChevronRightIconRotating,
  DropdownContent,
  DropdownHeader,
  EmbedContainer,
  RowContainer,
  SidebarDivider,
  SidebarDropdown,
  StyledTitle,
} from './styles'
import { PageTypesEnum } from 'interfaces/projectFile'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import Editing from './Editing/Editing'
import CreatedBy from './CreatedBy/CreatedBy'
import CreatedAt from './CreatedAt/CreatedAt'
import Assignee from '_entities/assignee/ui/Assignee'
import DueDate from './DueDate/DueDate'
import Messages from './Messages/Messages'
import Tasks from './Tasks/Tasks'
import Source from './Source/Source'
import Backlinks from './Backlinks/Backlinks'
import Tags from '_entities/tags/ui/Tags'
import React from 'react'
import { PageBackgroundEdit } from '_features/page'

type Props = {
  block?: block
  pageType: PageTypesEnum
}

const Sidebar = (props: Props) => {
  const { block, pageType } = props
  const _sidebar = useSidebar({ block })

  const { BlockColor, BlockBackground } = getBlockStyleValue(block?.style?.styling ?? [])

  return (
    <>
      <PageBackgroundEdit />
      {block && (
        <RowContainer>
          <>
            <SidebarDivider />
            <Editing
              shownTab={_sidebar.shownTab}
              handleShownTab={_sidebar.handleShownTab}
              textFormat={textFormat}
              block={block}
              BlockColor={BlockColor}
              BlockBackground={BlockBackground}
              pageType={pageType}
            />

            <SidebarDivider />

            <SidebarDropdown>
              <DropdownHeader
                onClick={
                  _sidebar.shownTab !== 'info'
                    ? () => _sidebar.handleShownTab('info')
                    : () => _sidebar.handleShownTab(null)
                }
              >
                <StyledTitle>Object info</StyledTitle>
                {_sidebar.shownTab === 'info' ? (
                  <ChevronRightIconRotating turn />
                ) : (
                  <ChevronRightIconRotating />
                )}
              </DropdownHeader>
              <DropdownContent>
                <EmbedContainer display={_sidebar.shownTab !== 'info' ? 'none' : 'initial'} top={0}>
                  <BlockTitle
                    onChange={(e) => _sidebar.handleNameChange(e)}
                    key={block._id}
                    ref={_sidebar.inputRef}
                  />

                  <Wrapper display='flex' padding='6px' margin='8px 0 8px 0 '>
                    <CreatedBy block={block} />
                    <CreatedAt block={block} />
                  </Wrapper>

                  <Wrapper display='flex' padding='6px'>
                    <Assignee block={block} />
                    <DueDate block={block} />
                  </Wrapper>
                  {block.meta.originalObject && <Source block={block} />}
                  <Backlinks block={block} />
                  <Tasks block={block} />
                  <Messages block={block} />
                  <Tags block={block} />
                </EmbedContainer>
              </DropdownContent>
            </SidebarDropdown>
          </>
        </RowContainer>
      )}
    </>
  )
}

export default Sidebar
