import React from 'react'
import { PropertyTitle, PropertyWrapper, StyledWrapper } from '../styles'
import { type block } from '_entities/block/model/types'
import { SourceContainer } from './styles'
import useSource from './useSource'
import SourceDropdown from '_entities/source/ui/SourceDropdown'
import SourcePageName from '_entities/source/ui/SourcePageName'
import SourceEmbed from '_entities/source/ui/SourceEmbed'
import SourceImage from '_entities/source/ui/SourceImage'
import SourceText from '_entities/source/ui/SourceText'

interface IProps {
  block: block
}

const Source = (props: IProps) => {
  const _source = useSource({
    block: props.block,
  })

  return (
    <StyledWrapper>
      <PropertyWrapper width={'100%'}>
        <PropertyTitle>Source</PropertyTitle>
        <SourceContainer onClick={() => _source.handleOnSourceClick()}>
          <SourceText block={props.block} />

          <SourceImage block={props.block} />

          <SourceEmbed block={props.block} Icon={_source.Icon} />

          <SourcePageName
            block={props.block}
            page={_source.page}
            handlePullUpdates={_source.handlePullUpdates}
          />

          <SourceDropdown
            block={props.block}
            handleSwitchRelationshipType={_source.handleSwitchRelationshipType}
            handleDetachFromRelationship={_source.handleDetachFromRelationship}
            isChecked={_source.isChecked}
          />
        </SourceContainer>
      </PropertyWrapper>
    </StyledWrapper>
  )
}

export default Source
