import { ToolbarDivider } from 'editor/EditableBlock/BlockToolbar/styles'
import SharedTextToolbar from 'components/organisms/SharedTextToolbar/SharedTextToolbar'
import WhiteboardShapeTextConversionDropdown from 'components/organisms/Dropdowns/WhiteboardShapeTextConversionDropdown/WhiteboardShapeTextConversionDropdown'
import ColorTool from 'components/organisms/ShapeToolbar/ColorTool/ColorTool'
import ShapeSelectorTool from 'components/organisms/ShapeToolbar/ShapeSelectorTool/ShapeSelectorTool'
import CreatedByTool from './CreatedByTool'
import { useAppSelector } from 'redux/hooks'
import { useShapeColor } from '_features/canvas'
import { useShape } from '_entities/shape'

const NotesToolbar = () => {
  const _shape = useShape()
  const _shapeColor = useShapeColor()

  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)

  if (!selectedBlock) return null

  return (
    <>
      {/* Note color */}
      <ColorTool
        color={_shape.getShapeFill(selectedBlock) || ''}
        onSelect={(color) => _shapeColor.handleSelectColor(selectedBlock, color)}
        onSelectDisabled={() => _shapeColor.handleDisableColor(selectedBlock)}
      />

      {/* Note reporter */}
      <CreatedByTool block={selectedBlock} />

      {/* Shape replace */}
      <ShapeSelectorTool block={selectedBlock} showSticky />

      <ToolbarDivider />
      <WhiteboardShapeTextConversionDropdown block={selectedBlock} />
      <SharedTextToolbar isWhiteboard block={selectedBlock} />
      <ToolbarDivider />
    </>
  )
}

export default NotesToolbar
