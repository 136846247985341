import { useAppSelector } from 'redux/hooks'

export const usePermissions = () => {
  const pages = useAppSelector((state) => state.projectFile.pages)

  const findAllowedPage = (pageId: string) => {
    return pages?.find((page) => page.id === pageId)
  }

  const canViewPage = (pageId: string) => {
    return !!findAllowedPage(pageId)
  }

  return {
    findAllowedPage,
    canViewPage,
  }
}
