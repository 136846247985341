import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import {
  Button,
  ForgotPasswordLink,
  ModalInfoLabel,
  ModalInnerContainer,
  ModalTitle,
  InputControl,
} from './styles'

import { useToast } from 'services/helpers/useToast'

type Props = {
  show: boolean
  handleClose: () => void
  saveChanges: () => void
  onShow: () => void
  setOldPassword: React.Dispatch<React.SetStateAction<string>>
  setNewPassword: React.Dispatch<React.SetStateAction<string>>
  setConfirmNewPassword: React.Dispatch<React.SetStateAction<string>>
  newPassword?: string
  confirmNewPassword?: string
}

const ChangePasswordModal: React.FC<Props> = ({
  show,
  handleClose,
  saveChanges,
  setOldPassword,
  setNewPassword,
  setConfirmNewPassword,
  onShow,
  newPassword,
  confirmNewPassword,
}) => {
  const toast = useToast()

  return (
    <Modal
      show={show}
      onShow={onShow}
      onHide={handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='changeEmailModal'
    >
      <ModalInnerContainer>
        <Modal.Header closeButton className='modalHeader border-0'>
          <ModalTitle>Change password</ModalTitle>
        </Modal.Header>
        <Modal.Body className='border-0'>
          <Form>
            <Form.Group className='mb-3'>
              <ModalInfoLabel>Current password</ModalInfoLabel>
              <InputControl
                type='password'
                placeholder='Enter password'
                onChange={(e: React.ChangeEvent) =>
                  setOldPassword((e.currentTarget as HTMLInputElement).value)
                }
              />
            </Form.Group>
            <Form.Group className='mb-3'>
              <ModalInfoLabel>New password</ModalInfoLabel>
              <InputControl
                type='password'
                placeholder='Enter password'
                onChange={(e: React.ChangeEvent) =>
                  setNewPassword((e.currentTarget as HTMLInputElement).value)
                }
              />
            </Form.Group>
            <Form.Group className='mb-0'>
              <ModalInfoLabel>Confirm new password</ModalInfoLabel>
              <InputControl
                type='password'
                placeholder='Enter password'
                onChange={(e: React.ChangeEvent) =>
                  setConfirmNewPassword((e.currentTarget as HTMLInputElement).value)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between'>
          <ForgotPasswordLink onClick={handleClose}>Forgot password?</ForgotPasswordLink>
          <Button
            onClick={() => {
              if (newPassword !== confirmNewPassword) {
                toast.error('Entered passwords should match.')
                return
              }

              saveChanges()
            }}
          >
            Change password
          </Button>
        </Modal.Footer>
      </ModalInnerContainer>
    </Modal>
  )
}

export default ChangePasswordModal
