import ChipContainer from 'components/molecules/ChipContainer/ChipContainer'
import { SubscriptionTypes } from 'interfaces/space'
import { Line, Separator } from '../SpaceSidebar/styles'
import {
  SpacePlansAndBillingStyled,
  DeleteContainer,
  SeparatorContainer,
  SpaceInfoSection,
  SpaceSettingsLabelStyled,
  StyledRightSidebarTitleCss,
  PlanInfoContaier,
  UpgradeButton,
  BillingLabelStyled,
} from './styles'
import { useSpacePlansAndBilling } from './useSpacePlansAndBilling'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { setPlanModalOpened } from 'redux/reducers/spaceReducer'

const SpacePlansAndBilling = () => {
  const { spaceMembers } = useSpacePlansAndBilling()
  const space = useAppSelector((state) => state.space.currentSpace)
  const dispatch = useAppDispatch()
  return (
    <SpacePlansAndBillingStyled>
      <SpaceInfoSection>
        <SpaceSettingsLabelStyled>Workspace plan</SpaceSettingsLabelStyled>
        <StyledRightSidebarTitleCss>
          <ChipContainer typeOfSubscription={SubscriptionTypes.FREE} />
          <PlanInfoContaier margin='24px 0'>
            {space?.projectFiles?.length || '0'} / 5 Project files used
          </PlanInfoContaier>
          <PlanInfoContaier>{spaceMembers?.length} / 10 Editor collaborators</PlanInfoContaier>
          <UpgradeButton
            onClick={() => {
              dispatch(setPlanModalOpened(true))
            }}
          >
            Upgrade plan
          </UpgradeButton>
        </StyledRightSidebarTitleCss>
      </SpaceInfoSection>

      <SeparatorContainer>
        <Separator>
          <Line />
        </Separator>
      </SeparatorContainer>

      <DeleteContainer>
        <BillingLabelStyled>Billing</BillingLabelStyled>
        {/* TODO this information will come from the BE/Stripe */}
        <PlanInfoContaier colour='var(--gray3)'>No payments here yet</PlanInfoContaier>
      </DeleteContainer>
    </SpacePlansAndBillingStyled>
  )
}

export default SpacePlansAndBilling
