import { AxiosService } from 'services/axiosService/axiosService'
import { useUser } from 'services/user/useUser'
import useProject from '_entities/project/model/useProject'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { IRoles } from 'interfaces/invite'
import { IUserAttributes } from 'interfaces/auth/userData'
import { IProjectFile, ISpaceMember } from 'interfaces/space'
import { setSpaceMembers, setProjectFiles, setCurrentSpace } from 'redux/reducers/spaceReducer'
import { formatDateAndTime } from 'utils/formatDateAndTime'

export const useSpace = () => {
  const axiosService = new AxiosService()
  const dispatch = useAppDispatch()
  const { getSpaces } = useUser()
  const { getProjectFilesBySpaceId } = useProject()
  const user = useAppSelector((state) => state.global.user)
  const spaceMembers = useAppSelector((state) => state.space.spaceMembers)

  const getSpace = async (spaceId: string) => {
    const spaceResponse = await axiosService.getSpace(spaceId)
    if (spaceResponse) {
      dispatch(setCurrentSpace(spaceResponse))
    }
  }

  const getProjects = async (spaceId: string) => {
    const getProjectFilesResponse = await axiosService.getSpaceProjectFiles(spaceId)
    if (getProjectFilesResponse) dispatch(setProjectFiles(getProjectFilesResponse))
  }

  const getMembers = async (spaceId: string) => {
    const membersOfSpace: ISpaceMember[] = await axiosService.getSpaceMembers(spaceId)

    const spaceMembersToDispatch = membersOfSpace.map((member) => {
      return {
        name: member.user.firstName
          ? `${member.user.firstName} ${member.user.lastName}`
          : 'Anonymous member',
        email: member.user.email || 'No email',
        memberId: member.id,
        roleId: member.role.id,
        role: member.role.type,
        lastActive: member.user.lastActive
          ? formatDateAndTime(member.user.lastActive)
          : 'a few moments ago',
        id: member.user.id,
        photo: member.user.photo,
      }
    })
    dispatch(setSpaceMembers(spaceMembersToDispatch))
  }

  const setDefaultSpace = (spaceId: string, isDefaultSpace = true) => {
    return axiosService.setDefaultSpace(spaceId, isDefaultSpace)
  }

  const getDefaultSpace = () => {
    return axiosService.getDefaultSpace()
  }

  const moveProjectFileToSpace = (
    spaceId: string,
    projectFileId: string,
    spaceIdToCopyInto: number,
  ) => {
    return axiosService.copyProjectToProjectSpace(spaceId, projectFileId, spaceIdToCopyInto)
  }

  const getUsersProjects = async () => {
    const { mySpaces, sharedSpaces } = await getSpaces()

    const allSpaces = [...mySpaces, ...sharedSpaces]

    const allProjects = await Promise.all(
      allSpaces.map(async (space) => {
        const projects = await getProjectFilesBySpaceId(space.id)

        if (projects) {
          return projects?.data.map((project: IProjectFile) => {
            return {
              ...project,
              spaceId: space.id,
            }
          })
        }
        return null
      }),
    )

    const filteredProjects = [...allProjects.filter((project) => project !== null).flat()]

    return filteredProjects.map((project) => {
      return {
        ...project,
        label: project.attributes.title,
        value: project.id,
        id: project.id,
      }
    })
  }

  const getSpaceRole = (): IRoles | undefined => {
    if (!user) return
    const selectedMember = spaceMembers?.find((member: IUserAttributes) => member.id === user.id)
    return selectedMember?.role
  }

  return {
    getSpace,
    getProjects,
    getMembers,
    setDefaultSpace,
    getDefaultSpace,
    moveProjectFileToSpace,
    getUsersProjects,
    getSpaceRole,
  }
}
