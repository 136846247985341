import sharedb from 'sharedb/lib/client'
import { Socket } from 'sharedb/lib/sharedb'
import { getSocket } from './socket'

export const options = {
  maxReconnectionDelay: 200,
  minReconnectionDelay: 5,
  reconnectionDelayGrowFactor: 2,
}

export const TIME_UNFOCUSED = 180

export const setSocket = () => {
  const socket = getSocket('/', [], options) as Socket
  socket.onclose = () => {
    // This has not been to detected to be triggered
    // since after establishing shareDB connection
    // the connection is responsible for everything as
    // it extends the socket. Only connection.on('disconnected'),
    // ('connecting') and ('connected') are ever triggered
    delete window.__CONNECTION__
  }
  socket.onopen = () => {
    setConnection()
  }
  window.__SOCKET__ = socket
}

export const setConnection = () => {
  const socket = window.__SOCKET__
  if (!socket) return
  window.__CONNECTION__ = new sharedb.Connection(socket)
}

export const handleDisconnect = () => {
  window.__CONNECTION__?.close()
  delete window.__SOCKET__
  delete window.__CONNECTION__
}
