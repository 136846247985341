import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import {
  IGroups,
  IPropertyDefinition,
  IPropertyUI,
  ISubgroup,
  ITask,
  ITaskProperty,
  Options,
  IOption,
} from 'interfaces/taskManager'
import { Option } from 'interfaces/selectOptions'
import { MultiValue } from 'react-select'
import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext'
import { RootState } from 'redux/store'
import { Doc } from 'sharedb'
import { Connection, Presence, LocalPresence } from 'sharedb/lib/client'
import { type block } from '_entities/block'
import { UpdateViewResponseData } from '_features/tasks'

interface TaskManagerState {
  selectedFilterValues: Record<string, MultiValue<Option>>
  activeKeysBoard: AccordionEventKey
  activeKeysList: AccordionEventKey
  activeSubgroupKeysList: AccordionEventKey
  tasks?: ITask[]
  selectedTaskId?: string
  selectedTaskDocument?: ITask
  propertyDefinitions?: IPropertyDefinition[]
  allProperties: IPropertyUI[]
  groups?: IGroups
  subgroups?: ISubgroup[]
  isSelectNewProperty: boolean
  selectedProperty?: IPropertyDefinition
  newOption?: { value: string }
  propertyForNoPropertyTask?: ITaskProperty
  modalOpen: boolean

  options?: Options
  selectedOptionId?: IOption['id']
  isSliderOpen: boolean
  isFullscreen: boolean
  isSliderTaskOpen: boolean

  /* Task doc states */

  doc?: Doc
  connection?: Connection
  data?: block[]
  presence?: Presence
  localPresence?: LocalPresence
  selectedBlocks: block[]
  isBlockSelectEnabled?: boolean
}

const initialState = {
  selectedFilterValues: {},
  activeKeysBoard: ['0'],
  activeKeysList: ['0'],
  activeSubgroupKeysList: ['0'],
  allProperties: [],
  isSelectNewProperty: false,
  modalOpen: false,
  propertyDefinitions: [],
  isSliderOpen: false,
  isFullscreen: false,
  isSliderTaskOpen: false,
  selectedBlocks: [],
} as TaskManagerState

const taskManagerSlice = createSlice({
  name: 'taskManagerReducer',
  initialState,
  reducers: {
    setSelectedFilterValues: (state, action) => {
      state.selectedFilterValues = action.payload
    },
    setActiveKeysBoard: (state, action) => {
      state.activeKeysBoard = action.payload
    },
    setActiveKeysList: (state, action) => {
      state.activeKeysList = action.payload
    },
    setActiveSubgroupKeysList: (state, action) => {
      state.activeSubgroupKeysList = action.payload
    },
    setTasks: (state, action) => {
      state.tasks = action.payload
    },
    setSelectedTaskId: (state, action: PayloadAction<string | undefined>) => {
      state.selectedTaskId = action.payload
    },
    setSelectedTaskDocument: (state, action) => {
      state.selectedTaskDocument = action.payload
    },
    setGroups: (state, action) => {
      state.groups = action.payload
    },
    setSubgroups: (state, action) => {
      state.subgroups = action.payload
    },
    setPropertyForNoPropertyTask: (state, action) => {
      state.propertyForNoPropertyTask = action.payload
    },
    setModalOpen: (state, action) => {
      state.modalOpen = action.payload
    },
    setOptions: (state, action: PayloadAction<Options>) => {
      state.options = action.payload
    },
    pushOption: (state, action: PayloadAction<Options[number]>) => {
      state.options?.push(action.payload)
    },
    setSelectedOptionId: (state, action: PayloadAction<IOption['id'] | undefined>) => {
      state.selectedOptionId = action.payload
    },
    updateOption: (state, action: PayloadAction<UpdateViewResponseData>) => {
      const updatedOption = action.payload.view

      if (state.options) {
        const optionIndex = state.options?.findIndex((option) => option.id === updatedOption.id)

        if (optionIndex !== -1) {
          const option = state.options[optionIndex]

          state.options[optionIndex] = {
            ...updatedOption,
            // Backend does not return layout unless we update it so we patch
            layout: updatedOption.layout || option.layout,
          }

          state.selectedOptionId = option.id
        }
      }
    },
    setPropertyDefinitions: (state, action: PayloadAction<IPropertyDefinition[]>) => {
      state.propertyDefinitions = action.payload
    },
    toggleSliderOpen: (state) => {
      state.isSliderOpen = !state.isSliderOpen
    },
    setIsSliderOpen: (state, action) => {
      state.isSliderOpen = action.payload
    },
    setIsSliderTaskOpen: (state, action) => {
      state.isSliderTaskOpen = action.payload
    },
    setSelectedBlocks: (state, action) => {
      state.selectedBlocks = action.payload
    },
  },
})

export const {
  setSelectedFilterValues,
  setActiveKeysBoard,
  setActiveKeysList,
  setActiveSubgroupKeysList,
  setTasks,
  setSelectedTaskId,
  setSelectedTaskDocument,
  setGroups,
  setSubgroups,
  setPropertyForNoPropertyTask,
  setModalOpen,
  setOptions,
  pushOption,
  setSelectedOptionId,
  updateOption,
  setPropertyDefinitions,
  toggleSliderOpen,
  setSelectedBlocks,
  setIsSliderOpen,
  setIsSliderTaskOpen,
} = taskManagerSlice.actions

export default taskManagerSlice.reducer

// Selectors

export const getSelectedOption = (state: RootState) => {
  const { options, selectedOptionId } = state.taskManager

  return options?.find((option) => option.id === selectedOptionId)
}

export const getSelectedTask = (state: RootState) => {
  const { tasks, selectedTaskId } = state.taskManager

  return tasks?.find((task) => task?.id === selectedTaskId)
}

export const getShowProperties = (state: RootState) => {
  const selectedOption = getSelectedOption(state)

  return selectedOption?.showProperties
}
