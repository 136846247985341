import React from 'react'
import Chip from 'components/atoms/Chip/Chip'
import { ChipContainerStyled } from './styles'

type Props = {
  typeOfSubscription: string
}

const ChipContainer: React.FC<Props> = ({ typeOfSubscription }) => {
  return (
    <ChipContainerStyled>
      <Chip itemId='0' needClose={false} value={typeOfSubscription} />
    </ChipContainerStyled>
  )
}

export default ChipContainer
