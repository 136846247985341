import styled from 'styled-components'
import { ReactComponent as EditColumnsIcon } from 'assets/images/task-manager/EditColumns.svg'
import { ReactComponent as EditListUnorderedIcon } from 'assets/images/task-manager/EditListUnordered.svg'
import { ReactComponent as EditAddPlus } from 'assets/images/task-manager/EditAddPlus.svg'
import { Tab } from 'react-bootstrap'

import { StyledH1 } from 'components/atoms/CustomH1/StyledH1'

export const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  padding: 2%;
  margin-bottom: 3rem;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledCustomH1 = styled(StyledH1)`
  color: var(--primary-text);
`

export const StyledTab = styled(Tab)`
  margin: 0 !important;
`

export const StyledTabTogglerContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--primary-text);
`

export const StyledBoardIcon = styled(EditColumnsIcon)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledListIcon = styled(EditListUnorderedIcon)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledAddIcon = styled(EditAddPlus)`
  path {
    stroke: var(--primary-text);
  }
`
