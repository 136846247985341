import styled from 'styled-components'
import { Col, Row, Container } from 'react-bootstrap'
import { ReactComponent as InfoSvg } from 'assets/images/info.svg'

export const InfoSvgStyled = styled(InfoSvg)`
  & path {
    fill: #e1e1e1 !important;
  }
  height: 13px !important;
  width: 13px !important;
`
export const AbsoluteContainer = styled.div`
  position: absolute;
  top: 110%;
  left: auto;
  & > div * {
    color: var(--primary-color) !important;
    background: #f4f7ff !important;
  }
`
export const PeriodButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 27px;
`
type PeriodButtonProps = {
  isSelected: boolean
}

export const PeriodButtonStyled = styled.button<PeriodButtonProps>`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease-in-out;
  align-items: center;
  border: ${(props) => (props.isSelected ? '1px solid var(--primary-color)' : '1px solid #F2F2F2')};
  background: ${(props) => (props.isSelected ? '#F4F7FF' : 'white')};
  padding: 9px 12px;
  border-radius: 8px;
`
export const InfoSpan = styled.span`
  @media screen and (max-width: 1300px) {
    font-size: 0.8em !important;
  }
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 120%;
  color: #aeb0b4;
`
export const ContainerStyled = styled(Container)`
  min-width: 1100px;
  height: 100% !important;
  overflow: hidden;

  @media screen and (max-width: 1400px) {
    font-size: 0.9em !important;
    min-width: 900px;
  }
  @media screen and (max-width: 1200px) {
    font-size: 0.7em !important;
    min-width: 650px;
  }
  @media screen and (max-width: 900px) {
    font-size: 0.7em !important;
    overflow: auto;
    overflow-x: scroll;
  }
`
export const CommingSoonLabel = styled.div`
  background: #fdf4e0;
  padding: 4px 8px !important;
  border-radius: 12px;
`

export const UpgradeButton = styled.button`
  @media screen and (max-width: 1300px) {
    font-size: 1em !important;
  }
  @media screen and (max-width: 900px) {
    padding: 7px;
  }
  background: var(--everyday-blue);
  border-radius: 10px;
  border: none;
  padding: 8px 12px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  :hover {
    opacity: 0.8;
  }
`
export const CurrentPlanLabel = styled.div`
  background: #f4f6ff;
  padding: 4px 8px !important;
  border-radius: 12px;
  display: flex;
  gap: 10px;
  align-items: center;
`
export const StyledBootstrapRow = styled(Row)`
  width: 100% !important;
`
export const StyledBootstrapColumn = styled(Col)`
  @media screen and (max-width: 1500px) {
    font-size: 0.9em !important;
    padding: 7px 0px !important;
  }
  position: relative;
  border: 1px solid #f2f2f2;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  display: flex;
  padding: 11px 0px !important;
  color: #403d3d;
  align-items: center;
  gap: 10px;
  justify-content: center;

  @media screen and (max-width: 1200px) {
    padding: auto !important;
  }
`
export const StyledBootstrapColumnHead = styled(Col)`
  @media screen and (max-width: 1500px) {
    font-size: 0.8em !important;
    padding: 10px;
    gap: 20px;
  }
  @media screen and (max-width: 700px) {
    gap: 10px;
  }
  padding: 32px;
  border: 1px solid #f2f2f2;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
`
export const PlanNameStyled = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 120%;
  color: #403d3d;
`

export const ModalTitle = styled.div`
  @media screen and (max-width: 1500px) {
    font-size: 30px !important;
  }
  @media screen and (max-width: 1200px) {
    font-size: 27px !important;
  }
  @media screen and (max-width: 900px) {
    font-size: 25px !important;
  }
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 120%;
  color: #403d3d;
  margin-left: auto;
`
export const ModalInnerContainer = styled.div`
  padding: 30px 24px;
  border-radius: 8px !important;
  height: 100%;
  @media screen and (max-height: 900px) {
    overflow-y: auto;
  }
`

export const EmptyDiv = styled.div``
