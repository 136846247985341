import React from 'react'
import RadixDropdown, {
  IconButton,
  RowMenuWrapper,
  RowMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { IconWrapper } from '_entities/whiteboard/ui/styles'
import { ITools } from 'interfaces/whiteboard'
import { StyledComponent } from 'styled-components'
import { IconButtonSize } from '_features/toolbar'
import { useShape } from '_entities/shape'
interface Props {
  selectedShape: ITools
  toggleIcon: React.ReactNode
  toggleButtonRef?: React.MutableRefObject<HTMLButtonElement | null>
  onSelect: (tool: ITools) => void
  showSticky?: boolean
  isSelected?: boolean
  isDisabled?: boolean
}

const ShapeSelectorDropdown = (props: Props) => {
  const _shape = useShape()
  const StickyIcon = _shape.iconRenderConfig[ITools.STICKY]
  return (
    <RadixDropdown
      trigger={
        <IconButton
          disabled={props.isDisabled}
          isSelected={props.isSelected}
          width={IconButtonSize + 'px'}
          height={IconButtonSize + 'px'}
          ref={props.toggleButtonRef}
        >
          {props.toggleIcon}
        </IconButton>
      }
    >
      <RowMenuWrapper>
        {_shape.ShapeOptions.map((option) => {
          const ShapeIcon = _shape.iconRenderConfig[option] as StyledComponent<
            React.FunctionComponent<React.SVGProps<SVGSVGElement>>,
            any,
            any,
            never
          >
          return (
            <RowMenuItem onSelect={() => props.onSelect(option)} key={`shape-option-${option}`}>
              <IconWrapper height='34px' selected={props.selectedShape === option}>
                <ShapeIcon />
              </IconWrapper>
            </RowMenuItem>
          )
        })}
        {props.showSticky && (
          <RowMenuItem
            onSelect={() => props.onSelect(ITools.STICKY)}
            key={`shape-option-${ITools.STICKY}`}
          >
            <IconWrapper height='34px' selected={props.selectedShape === ITools.STICKY}>
              <StickyIcon />
            </IconWrapper>
          </RowMenuItem>
        )}
      </RowMenuWrapper>
    </RadixDropdown>
  )
}

export default ShapeSelectorDropdown
