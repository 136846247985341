import { CSSProperties, ComponentProps, ReactNode, forwardRef, Ref, RefObject } from 'react'
import styled, { css } from 'styled-components'
import { IconContainer } from './styles'

export type StyledButtonVariant =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'link'
  | 'sidebar'
  | 'transparent'
export type StyledButtonSize = 'large' | 'medium' | 'small'

export type StyledButtonProps = {
  variant: StyledButtonVariant
  size: StyledButtonSize
  // Additional props
  onlyIcon?: boolean
  opacity?: number | string
  gap?: CSSProperties['gap']
  elevated?: boolean
  grow?: boolean
  background?: string
  border?: string
  margin?: string
  width?: string
}

const StyledButton = styled.button<StyledButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  margin: ${(props) => props.margin || '0'};
  // Spacing between icon and text
  gap: ${(props) => props.gap || '12px'};
  width: ${(props) => props.width || 'auto'};

  ${(props) => props.grow && 'flex-grow: 1;'}

  border: ${(props) => props.border || '1px solid transparent'};

  ${(props) =>
    props.elevated &&
    css`
      box-shadow: 0px 1px 1px rgba(56, 56, 56, 0.2);
    `}

  ${(props) => {
    switch (props.size) {
      case 'large':
        return css`
          border-radius: 32px;
          padding: 12px 24px;

          ${() =>
            props.onlyIcon &&
            css`
              padding: 14px;
            `}

          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
        `
      case 'medium':
        return css`
          border-radius: 7px;
          padding: 10px 16px;

          ${() =>
            props.onlyIcon &&
            css`
              padding: 10px;
            `}

          /* All buttons — new design */
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
        `
      case 'small':
        return css`
          border-radius: 7px;
          padding: 6px 16px;

          ${() =>
            props.onlyIcon &&
            css`
              padding: 7px;
            `}

          /* All buttons — new design */
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
        `
    }
  }}

  &:hover {
    background: var(--hover);
  }

  ${(props) => {
    switch (props.variant) {
      case 'primary':
        return css`
          background: var(--primary-color);
          color: var(--white);

          &:hover {
            background: #8aadff;
          }

          & svg path {
            stroke: var(--primary-text);
          }

          &:disabled {
            background: #e1e1e1;
          }
        `
      case 'secondary':
        return css`
          background: #fff;
          color: #4a80ff;
          border: 1.5px solid #cfcfcf;

          &:hover {
            background: var(--hover);
          }

          & svg path {
            stroke: var(--primary-text);
          }

          &:disabled {
            background: #f6f6f6;
            color: #cfcfcf;
            border: none;
          }
        `
      case 'tertiary':
        return css`
          background: #fff;
          color: var(--primary-color);

          &:hover {
            background: var(--hover);

            & svg path {
              stroke: var(--primary-text);
            }
          }

          & svg path {
            stroke: var(--primary-text);
          }

          &:disabled {
            background: #f6f6f6;
            color: #cfcfcf;
          }
        `
      case 'sidebar':
        return css`
          background: var(--sidebars);

          & svg path {
            stroke: var(--primary-text);
          }

          &:hover {
            background: var(--hover);

            & svg path {
              stroke: var(--primary-text);
            }
          }
        `
      case 'transparent':
        return css`
          background: transparent;
          &:hover {
            background: var(--hover);
          }
        `
    }
  }}

  ${(props) =>
    props.opacity &&
    css`
      opacity: ${props.opacity};
    `}

    background: ${(props) => props.background || ''};
`

StyledButton.defaultProps = {
  variant: 'primary',
  size: 'medium',
}

export type ButtonProps = ComponentProps<typeof StyledButton> &
  StyledButtonProps & {
    leftIcon?: ReactNode
    rightIcon?: ReactNode
    children?: ReactNode
    icon?: never
    elevated?: boolean
    grow?: boolean
    flexDirection?: CSSProperties['flexDirection']
    ref?: RefObject<HTMLButtonElement>
  }

const Button = forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
  const { children, leftIcon, rightIcon, ...restProps } = props

  return (
    <StyledButton {...restProps} ref={ref}>
      {leftIcon && <IconContainer>{leftIcon}</IconContainer>}
      {children && <div>{children}</div>}
      {rightIcon && <IconContainer>{rightIcon}</IconContainer>}
    </StyledButton>
  )
})

Button.displayName = 'Button'

export type IconButtonProps = ComponentProps<typeof StyledButton> & StyledButtonProps

export const IconButton = (props: IconButtonProps) => {
  const { children, ...restProps } = props
  const { flexDirection } = props

  return (
    <StyledButton onlyIcon {...restProps}>
      <IconContainer flexDirection={flexDirection}>{children}</IconContainer>
    </StyledButton>
  )
}

export default Button
