import styled from 'styled-components'
import { Button } from 'react-bootstrap'

export const SharingButton = styled(Button)<{ show?: boolean }>`
  height: 52px;
  width: 112px;
  padding: 0px;
  background: transparent;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
  &:hover {
    background-color: transparent !important;
    color: var(--primary-color) !important;
  }
  display: ${(props) => (props.show ? 'block' : 'none')};
`
