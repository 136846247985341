import React from 'react'
import AuthInput from 'components/atoms/AuthInput/AuthInput'
import AvatarEditor from 'react-avatar-editor'
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone'
import styled from 'styled-components'
import { StyledImage, StyledUploadIcon, UploadText } from './styles'

const UploadIcon = styled.i`
  ${StyledUploadIcon}
`

type Props = {
  file?: File
  imageScale: number
  setImageScale: React.Dispatch<React.SetStateAction<number>> // eslint-disable-next-line
  editor: React.MutableRefObject<any>
  getRootProps: <T extends DropzoneRootProps>(props?: T | undefined) => T
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T
  isProfileModal?: boolean
}

const ProfilePhotoEditor: React.FC<Props> = ({
  file,
  getRootProps,
  getInputProps,
  imageScale,
  setImageScale,
  editor,
  isProfileModal,
}) => {
  return (
    <>
      {file ? (
        <>
          <StyledImage
            {...getRootProps({
              onClick: (event: React.MouseEvent<HTMLElement>) => event.stopPropagation(),
            })}
            className='d-flex justify-content-center align-items-center flex-column  p-1 mt-3'
          >
            <input {...getInputProps()} />
            <AvatarEditor
              ref={editor}
              image={file}
              scale={imageScale}
              disableBoundaryChecks={true}
            />
            <AuthInput
              type='range'
              onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) =>
                setImageScale(Number(e.target.value))
              }
              className='form-range mt-3 mb-2'
              value={imageScale}
              id='image-zoom-level'
              min={0.5}
              step={0.1}
              max={5}
              labelClassName='form-label'
              label={`${isProfileModal ? '' : 'Zoom level'}`}
              htmlFor='image-zoom-level'
            />
          </StyledImage>
        </>
      ) : (
        <>
          <StyledImage
            {...getRootProps()}
            className='bg-light d-flex justify-content-center align-items-center flex-column rounded-circle p-1'
          >
            <UploadIcon className='fa-solid fa-arrow-up-from-bracket'></UploadIcon>

            <UploadText className='text-secondary fs-6 p-3'>
              Drag &apos;n&apos; drop your image here or click to browse
            </UploadText>
          </StyledImage>
        </>
      )}
    </>
  )
}

export default ProfilePhotoEditor
