import RadixDropdown, {
  DropdownMenuItem,
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import Dots from 'components/atoms/Dots/Dots'
import { type block } from '_entities/block'
import { RelationshipType } from '_features/relationship'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import CustomP from 'components/atoms/CustomP/CustomP'
import Switch from 'components/atoms/Switch/Switch'
import { icons } from 'shared/icons'

interface IProps {
  block: block
  handleSwitchRelationshipType: (type: RelationshipType, e: any) => void
  handleDetachFromRelationship: (block: any) => void
  isChecked: boolean
}

const SourceDropdown = (props: IProps) => {
  return (
    <RadixDropdown
      trigger={
        <IconButton>
          <Dots />
        </IconButton>
      }
    >
      <DropdownMenuItem
        onSelect={(e) => {
          props.handleSwitchRelationshipType(
            !props.isChecked ? RelationshipType.MIRROR : RelationshipType.INSTANCE,
            e,
          )
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Wrapper display={'flex'} gap={'48px'} alignItems={'center'}>
          <CustomP fontSize={'14px'}>Mirror from source</CustomP>
          <Switch
            checked={
              props.block.meta.relationshipType === RelationshipType.MIRROR || props.isChecked
            }
          />
        </Wrapper>
      </DropdownMenuItem>
      <DropdownMenuItem
        onSelect={() => {
          props.handleDetachFromRelationship(props.block)
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <Wrapper display={'flex'} gap={'6px'}>
          <icons.detach />

          <CustomP fontSize={'14px'}>Detach</CustomP>
        </Wrapper>
      </DropdownMenuItem>
    </RadixDropdown>
  )
}

export default SourceDropdown
