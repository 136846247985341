import { Input } from 'components/molecules/ChangeNameModal/styles'
import React, { useRef, useEffect } from 'react'
import { CtaButton } from '_entities/cta'
import { SettingsOptionWrapper } from '_features/cta'

interface EditUrlProps {
  button: CtaButton
  onChangeLink: (e: React.ChangeEvent<HTMLInputElement>, button: CtaButton) => void
}

export const EditUrl = (props: EditUrlProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = props.button.link
    }
  }, [props.button.link])

  return (
    <SettingsOptionWrapper>
      <Input
        ref={inputRef}
        defaultValue={props.button.link}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          props.onChangeLink(e, props.button)
        }}
      />
    </SettingsOptionWrapper>
  )
}
