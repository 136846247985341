import RadixDropdown, {
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { ReactComponent as ShapeColorIcon } from 'assets/images/shape-color.svg'
import styled from 'styled-components'
import ColorDropdownMenu from 'components/organisms/Dropdowns/ColorDropdown/ColorDropdownMenu'
import { IconButtonSize } from '_features/toolbar'

export const ColorDropdownTriggerIcon = styled(ShapeColorIcon)<{ color: string }>`
  & path {
    fill: ${(props) => props.color};
    stroke: ${(props) => (props.color === '#ffffff' ? 'var(--gray-disabled-stroke)' : 'none')};
  }
`

interface Props {
  color: string
  onSelect: (color: string) => void
  onSelectDisabled: () => void
}

const ColorDropdown = (props: Props) => {
  return (
    <RadixDropdown
      trigger={
        <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
          <ColorDropdownTriggerIcon color={props.color ? props.color : 'transparent'} />
        </IconButton>
      }
    >
      <ColorDropdownMenu
        selectedColor={props.color}
        onSelect={props.onSelect}
        onSelectDisabled={props.onSelectDisabled}
      />
    </RadixDropdown>
  )
}

export default ColorDropdown
