import React, { useEffect, useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import { type block, getBlockPageId, getIndex, INDENTATION } from '_entities/block'
import { RelationshipType } from '_features/relationship'
import { useGetPage, getPage } from '_entities/page'
import { IPage } from 'interfaces/page'
import { useIcons } from 'shared/icons'
import { SourceKeys } from 'interfaces/editor'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { Doc as JsonDoc } from 'ot-json1/dist/types'
import { useToast } from 'services/helpers/useToast'
import useOnPageClick from 'utils/useOnPageClick'
import { useParams } from 'react-router-dom'

interface IProps {
  block: block
}

const useSource = (props: IProps) => {
  const [originalBlock, setOriginalBlock] = useState<block>()
  const [loading, setLoading] = useState<boolean>(false)
  const [isChecked, setIsChecked] = useState<boolean>(false)
  const [page, setPage] = useState<IPage>()
  const toast = useToast()
  const pages = useAppSelector((state) => state.projectFile.pages) || []
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const { getPageObjects } = useGetPage()
  const { getReplaceBlockMetaKeyOp, getReplaceBlockDataKeyOp } = useJson1()
  const { submit } = useSubmit()
  const onPageClick = useOnPageClick()
  const _icons = useIcons()
  const params = useParams()
  const spaceId = params.spaceId

  useEffect(() => {
    fetchAllBlockFrom()
    fetchPage()

    if (props.block.meta.relationshipType === RelationshipType.MIRROR) {
      setIsChecked(true)
    }
  }, [props.block])

  const Icon = originalBlock?.data?.documentPage
    ? _icons.PageTypeIcon[originalBlock?.data.documentPage.type as keyof typeof _icons.PageTypeIcon]
    : null

  const fetchAllBlockFrom = async () => {
    setLoading(true)
    const originalObjectPage = props.block.meta.originalObject?.pageOfOriginalBlock
    const originalObjectBlock = props.block.meta.originalObject?._id
    if (originalObjectPage && originalObjectBlock) {
      const block = await getPageObjects(originalObjectPage, originalObjectBlock)

      if (block) {
        setLoading(false)
        setOriginalBlock(block)
      }
    }
  }

  const fetchPage = async () => {
    const originalObjectPage = props.block.meta.originalObject?.pageOfOriginalBlock

    if (originalObjectPage && project) {
      const page = await getPage(project.id.toString(), originalObjectPage)
      setPage(page)
    }
  }

  const handleDetachFromRelationship = (block: block) => {
    const index = getIndex(block)

    if (index === -1) return

    const op = getReplaceBlockMetaKeyOp(index, ['originalObject'], null)

    submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  const handleSwitchRelationshipType = (type: RelationshipType, e: Event) => {
    e.preventDefault()
    e.stopPropagation()

    if (type === RelationshipType.MIRROR) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }

    const index = getIndex(props.block)

    if (index === -1) return

    const op = getReplaceBlockMetaKeyOp(
      index,
      ['relationshipType'],
      type,
      props.block?.meta.relationshipType,
    )

    submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
  }

  const handlePullUpdates = (e: React.MouseEvent, block: block) => {
    e.preventDefault()
    e.stopPropagation()

    const index = getIndex(block)

    if (index === -1) return

    const op = getReplaceBlockDataKeyOp(
      index,
      ['updateInstance'],
      !block.data.updateInstance as unknown as JsonDoc,
    )
    submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
    toast.success('Pulled updates from original')
  }

  const handleOnSourceClick = () => {
    if (props.block.meta.originalObject && project) {
      onPageClick(
        props.block.meta.originalObject?.pageOfOriginalBlock,
        props.block.meta.originalObject?._id,
        spaceId,
        project.id.toString(),
      )
    }
  }

  return {
    pages,
    originalBlock,
    page,
    Icon,
    handleSwitchRelationshipType,
    loading,
    INDENTATION,
    handlePullUpdates,
    handleDetachFromRelationship,
    isChecked,
    handleOnSourceClick,
  }
}

export default useSource
