import { Transformer } from 'konva/lib/shapes/Transformer'
import { type block } from '_entities/block'
import { useRef } from 'react'
import { Node } from 'konva/lib/Node'
import { Stage } from 'konva/lib/Stage'
import {
  LEFT_ANCHORS,
  TOP_ANCHORS,
  HORIZONTAL_ANCHORS,
  VERTICAL_ANCHORS,
  DIAGONAL_ANCHORS,
  Anchors,
  TOP_DIAGONAL_ANCHORS,
  BOTTOM_DIAGONAL_ANCHORS,
  useStage,
} from '_features/canvas'
import { Group } from 'konva/lib/Group'
import { getDoc } from 'shared/shareDb'

export const useTransformer = () => {
  const _stage = useStage()
  const transformerRef: React.RefObject<Transformer> = useRef(null)

  const TRANSFORMER_KEY = 'Transformer'
  const WHITEBOARD_BLOCK_TOOLBAR_ID = 'whiteboard-block-toolbar'

  const getTransformer = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (stage) {
      return findTransformerOnStage(stage)
    }
  }

  const getTransformerSize = (pageId: string) => {
    const transformer = getTransformer(pageId)
    if (transformer) {
      return {
        width: transformer.width(),
        height: transformer.height(),
      }
    } else
      return {
        width: 0,
        height: 0,
      }
  }

  const getScaledTransformerSize = (pageId: string) => {
    const transformer = getTransformer(pageId)
    const stage = transformer?.getStage()
    if (transformer && stage) {
      return {
        width: transformer.width() / stage.scaleX(),
        height: transformer.height() / stage.scaleX(),
      }
    } else
      return {
        width: 0,
        height: 0,
      }
  }

  const getTransformerPosition = (pageId: string) => {
    const transformer = getTransformer(pageId)
    if (transformer) {
      return {
        x: transformer.x(),
        y: transformer.y(),
      }
    } else
      return {
        x: 0,
        y: 0,
      }
  }

  const getActiveAnchor = (pageId: string) => {
    const transformer = getTransformer(pageId)
    return transformer?.getActiveAnchor()
  }

  const checkIfHorizontalAnchor = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return false
    return HORIZONTAL_ANCHORS.includes(activeAnchor)
  }

  const checkIfVerticalAnchor = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return false
    return VERTICAL_ANCHORS.includes(activeAnchor)
  }

  const checkIfDiagonalAnchor = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return false
    return DIAGONAL_ANCHORS.includes(activeAnchor)
  }

  const checkIfTopDiagonalAnchor = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return false
    return TOP_DIAGONAL_ANCHORS.includes(activeAnchor)
  }

  const checkIfBottomDiagonalAnchor = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return false
    return BOTTOM_DIAGONAL_ANCHORS.includes(activeAnchor)
  }

  const checkIfTopLeftAnchor = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return false
    return activeAnchor === Anchors.TOP_LEFT
  }

  const checkIfTopRightAnchor = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return false
    return activeAnchor === Anchors.TOP_RIGHT
  }

  const checkIfBottomLeftAnchor = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return false
    return activeAnchor === Anchors.BOTTOM_LEFT
  }

  const isHorizontalResize = (pageId: string) =>
    !!(checkIfHorizontalAnchor(pageId) && getSingleNodeFromTransformer(pageId))

  const isVerticalResize = (pageId: string) =>
    !!(checkIfVerticalAnchor(pageId) && getSingleNodeFromTransformer(pageId))

  const isDiagonalResize = (pageId: string) =>
    !!(checkIfDiagonalAnchor(pageId) && getSingleNodeFromTransformer(pageId))

  const isTopDiagonalResize = (pageId: string) =>
    !!(checkIfTopDiagonalAnchor(pageId) && getSingleNodeFromTransformer(pageId))

  const isBottomDiagonalResize = (pageId: string) =>
    !!(checkIfBottomDiagonalAnchor(pageId) && getSingleNodeFromTransformer(pageId))

  const getHorizontalResizeDirection = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return 1
    return LEFT_ANCHORS.includes(activeAnchor) ? -1 : 1
  }

  const getVerticalResizeDirection = (pageId: string) => {
    const activeAnchor = getActiveAnchor(pageId)
    if (!activeAnchor) return 1
    return TOP_ANCHORS.includes(activeAnchor) ? -1 : 1
  }
  const getTransformerNodes = (pageId: string) => getTransformer(pageId)?.nodes() as Group[]

  const getSingleNodeFromTransformer = (pageId: string) => {
    const nodes = getTransformerNodes(pageId)
    if (nodes && nodes.length === 1) {
      return nodes[0] as Group
    }
  }

  const getSingleBlockFromTransformer = (pageId: string): block | undefined => {
    const node = getSingleNodeFromTransformer(pageId)
    if (!node) return
    return _stage.getBlockFromNode(pageId, node)
  }

  const setNodeFromBlockToTransformer = (pageId: string, block: block) => {
    const blockNode = _stage.getNodeFromBlock(pageId, block._id)
    if (blockNode) {
      getTransformer(pageId)?.nodes([blockNode])
    }
  }

  const setAllNodesToTransformer = (pageId: string) => {
    const nodesOnStage = _stage.getAllNodes(pageId)
    if (!nodesOnStage) return
    getTransformer(pageId)?.nodes(nodesOnStage).getLayer()?.batchDraw()
  }

  const removeNodesFromTransformer = (pageId: string) => {
    getTransformer(pageId)?.nodes([]).getLayer()?.batchDraw()
  }
  const getBlocksFromTransformer = (pageId: string): block[] | undefined => {
    const doc = getDoc(pageId)
    return getTransformerNodes(pageId)?.map((node) => {
      return doc?.data?.blocks?.find((block: block) => block._id === node.attrs.id)
    })
  }

  const findTransformerOnStage = (stage: Stage): Transformer => {
    const [transformer] = stage.find((node: Node) => {
      return node.className === TRANSFORMER_KEY
    })
    return transformer as Transformer
  }

  const findSingleFrameInTransformer = (pageId: string): Node | undefined => {
    const transformer = getTransformer(pageId)
    const nodes = transformer?.nodes()
    const filtered = nodes?.filter((node) => {
      return node.hasName('frame')
    })
    if (filtered && filtered.length === 1) {
      return filtered[0]
    }
  }

  const findSingleAnchorNode = (pageId: string, anchorName: string) => {
    return getTransformer(pageId)?.findOne(`.${anchorName}`)
  }

  const forceUpdateTransformer = (pageId: string) => {
    const transformer = getTransformer(pageId)
    if (transformer) {
      transformer.forceUpdate()
    }
  }

  return {
    TRANSFORMER_KEY,
    WHITEBOARD_BLOCK_TOOLBAR_ID,
    transformerRef,
    getTransformer,
    getTransformerSize,
    getScaledTransformerSize,
    getTransformerPosition,
    getActiveAnchor,
    checkIfHorizontalAnchor,
    checkIfTopLeftAnchor,
    checkIfTopRightAnchor,
    checkIfBottomLeftAnchor,
    isHorizontalResize,
    isVerticalResize,
    isDiagonalResize,
    isTopDiagonalResize,
    isBottomDiagonalResize,
    getHorizontalResizeDirection,
    getVerticalResizeDirection,
    getTransformerNodes,
    setNodeFromBlockToTransformer,
    getSingleNodeFromTransformer,
    getSingleBlockFromTransformer,
    setAllNodesToTransformer,
    removeNodesFromTransformer,
    findTransformerOnStage,
    getBlocksFromTransformer,
    findSingleFrameInTransformer,
    findSingleAnchorNode,
    forceUpdateTransformer,
  }
}
