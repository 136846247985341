import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { SummaryPageTitle } from '_entities/page'
import { SummarizationCategory } from 'services/ai/useAi'
import { useSummarization } from '_features/ai'
import { AxiosService } from 'services/axiosService/axiosService'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { setIsProjectFileUploadLoading } from 'redux/reducers/projectFileReducer'
import { PageTypesEnum } from 'interfaces/projectFile'

export const SummaryProjectTitle = 'Project analysis'

export const useProjectSummarization = () => {
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const _summarization = useSummarization()
  const axiosInstance = new AxiosService()
  const _getProjectFile = useGetProjectFile()
  const dispatch = useAppDispatch()

  const getPagesForSummarization = () => {
    if (!pages) return []
    return pages?.filter(
      (page) =>
        page.text !== SummaryPageTitle &&
        page.text !== SummaryProjectTitle &&
        (page.type === PageTypesEnum.DOCUMENT || PageTypesEnum.WHITE_BOARD),
    )
  }

  const summarize = async (category: SummarizationCategory) => {
    dispatch(setIsProjectFileUploadLoading(true))
    if (!project) {
      dispatch(setIsProjectFileUploadLoading(false))
      return
    }
    const pagesToSummarize = getPagesForSummarization()
    const text = await _summarization.getFullText(
      pagesToSummarize.map((page) => page.id.toString()),
    )
    if (!text) {
      dispatch(setIsProjectFileUploadLoading(false))
      return
    }
    const response = await axiosInstance.createProjectSummary(project.id, category, text)
    if (response) {
      _getProjectFile.getAndDispatchProjectFilePages(project.id)
      dispatch(setIsProjectFileUploadLoading(false))
    }
  }
  return {
    getPagesForSummarization,
    summarize,
  }
}
