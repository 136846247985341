import { type block, IBlockTypes, BlockMetaKeys, BLOCKS_COPY_KEY } from '_entities/block'
import { usePosition, useStage, useTransformer } from '_features/canvas'
import { FULL_SIZE } from '_entities/whiteboard'
import { useAppSelector } from 'redux/hooks'
import copy from 'copy-to-clipboard'
import { isJsonString } from 'utils/isJson'
import { useTextBlock } from '_entities/text'
import { objectId } from 'utils/editor'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useCreateBlock } from '_features/block'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { EmbedType, useEmbed } from '_entities/embed'
import { useEmbedCreation } from '_features/embed'
import { checkIfLinkInputPaste } from 'components/molecules/Link/Link'
import { useEffect, useRef } from 'react'
import { useInternalEmbed } from 'whiteboard/Embed/InternalEmbed/useInternalEmbed'
import { useUpload } from '_features/upload/model/useUpload'
import { getBlocksLength } from 'shared/shareDb'

export const useCopyPaste = () => {
  const _position = usePosition()
  const _transformer = useTransformer()
  const _textBlock = useTextBlock()
  const _json1 = useJson1()
  const _createBlock = useCreateBlock()
  const _submit = useSubmit()
  const _stage = useStage()
  const _embedCreation = useEmbedCreation()
  const _embed = useEmbed()
  const _internalEmbed = useInternalEmbed()
  const _upload = useUpload()
  const user = useAppSelector((state) => state.global.user)
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const selectedBlockRef = useRef<block | null>(null)

  const QUILL_LINK_EDIT_PLACEHOLDER = 'https://quilljs.com'

  interface Opts {
    shouldShift?: boolean
  }

  useEffect(() => {
    if (selectedBlock) {
      selectedBlockRef.current = selectedBlock
    } else {
      selectedBlockRef.current = null
    }
  }, [selectedBlock])

  const getNewPosition = (pageId: string, block: block, opts?: Opts) => {
    const x = _position.getBlockX(block)
    const y = _position.getBlockY(block)
    if (x && y)
      return { x: opts?.shouldShift ? x + FULL_SIZE : x, y: opts?.shouldShift ? y + FULL_SIZE : y }
    else return _position.getNewBlockPosition(pageId)
  }
  const generateNewPastedBlock = (pageId: string, block: block, newId: string, opts?: Opts) => {
    return {
      ...block,
      _id: newId,
      data: {
        ...block.data,
        ...getNewPosition(pageId, block, opts),
      },
      meta: {
        pageId,
        relationshipType: null,
        originalObject: null,
        relatedObjects: [],
        properties: [],
        chatMessageIds: [],
        createdBy: user?.uuid || block.meta[BlockMetaKeys.CREATED_BY],
        tags: [],
        assignees: [],
        dueDate: new Date(),
        createdAt: new Date().toLocaleDateString('en-US'),
        name: `${block.data.tag}_${getBlocksLength(pageId) + 1}`,
      },
    }
  }

  const copyBlocks = (pageId: string) => {
    const copiedBlocks = _transformer.getBlocksFromTransformer(pageId)
    copy(JSON.stringify({ [BLOCKS_COPY_KEY]: copiedBlocks }))
  }

  const getClipboardContent = async () => {
    return await navigator.clipboard.readText()
  }

  const getCopiedBlocks = async () => {
    const clipboardContent = await getClipboardContent()
    const isJson = isJsonString(clipboardContent)
    if (clipboardContent && isJson) return JSON.parse(clipboardContent)[BLOCKS_COPY_KEY]
  }

  const handlePastedBlocks = (pageId: string, copiedBlocks: block[]) => {
    const idsOfNewBlocks: string[] = []
    const textBlocks: block[] = []
    const ops = copiedBlocks.map((block: block) => {
      if (_textBlock.isTextBlock(block)) textBlocks.push(block)
      const id = objectId()
      idsOfNewBlocks.push(id)
      const newBlock = generateNewPastedBlock(pageId, block, id, { shouldShift: true })
      return _json1.addBlock(newBlock, getBlocksLength(pageId))
    })
    // If all the copied blocks are text blocks we create a doc with those
    if (textBlocks.length === copiedBlocks.length)
      return _createBlock.createDocumentPageAndGenerateBlock(pageId, textBlocks, true)
    const pasteOps = _json1.combineOperations(ops)
    _submit.submit(pageId, pasteOps, SourceKeys.UPDATE_BLOCK)
    setTimeout(() => {
      setPastedNodesToTransformer(pageId, idsOfNewBlocks)
    })
  }

  const getPastedNodes = (pageId: string, ids: string[]) => {
    const blockNodes = _stage.getAllNodes(pageId)
    return blockNodes?.filter((node) => ids.includes(node.attrs.id))
  }

  const setPastedNodesToTransformer = (pageId: string, ids: string[]) => {
    const pastedNodes = getPastedNodes(pageId, ids)
    if (pastedNodes) {
      setTimeout(() => {
        _transformer.transformerRef.current?.nodes(pastedNodes)
      })
    }
  }

  const handlePaste = (
    copiedBlocks: block[],
    clipboardContent: string,
    pageId: string,
    embedType?: EmbedType,
  ) => {
    if (copiedBlocks) {
      handlePastedBlocks(pageId, copiedBlocks)
    } else if (embedType) {
      _embedCreation.handleWbExternalLink(clipboardContent, embedType as EmbedType, pageId)
    } else {
      _createBlock.createWhiteboardBlock({
        type: IBlockTypes.TEXT,
        initialText: clipboardContent,
        pageId,
      })
    }
  }

  const pasteBlocks = async (pageId: string, e: ClipboardEvent) => {
    const clipboardContent = await getClipboardContent()
    const copiedBlocks: block[] = await getCopiedBlocks()
    const embedType = _embed.getEmbedTypeFromLink(clipboardContent, true)

    if (checkIfLinkInputPaste(e)) return

    handlePaste(copiedBlocks, clipboardContent, pageId, embedType)
  }

  const pasteHandler = async (pageId: string, e: ClipboardEvent, isEmbed?: boolean) => {
    if (
      (document.activeElement as HTMLElement).isContentEditable ||
      (document.activeElement as HTMLInputElement).placeholder === QUILL_LINK_EDIT_PLACEHOLDER
    )
      return
    if (isEmbed) {
      if (
        _embed.checkIfSelectedBlockIsEmbed(selectedBlockRef.current) &&
        _internalEmbed.getEmbedBlockPage(selectedBlockRef.current)?.id === pageId
      ) {
        handlePasteAndUpload(pageId, e)
      }
    } else {
      if (!_embed.checkIfSelectedBlockIsEmbed(selectedBlockRef.current)) {
        handlePasteAndUpload(pageId, e)
      }
    }
  }

  const handlePasteAndUpload = (pageId: string, e: ClipboardEvent) => {
    const data = e.clipboardData
    const file = data && data.files && data.files[0]
    if (file) {
      _upload.handleWbFileUpload(file, pageId)
    } else {
      pasteBlocks(pageId, e)
    }
  }

  return {
    copyBlocks,
    pasteHandler,
    generateNewPastedBlock,
  }
}
