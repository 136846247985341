import { Value } from 'react-quill'
import { IArrowItem, IFrameItem, ILineItem, IShapeItem } from 'interfaces/whiteboard'
import { IPage } from 'interfaces/page'
import { RelationshipType, type OriginalObject, type RelatedObject } from '_features/relationship'
import { IExternalEmbed, IInternalEmbed } from '_entities/embed'
import { Emoji } from '_features/emojis'

export interface block {
  _id: string
  data: blockData
  meta: blockMeta
  style?: blockStyle
  typeOfObject?: typeof TypeOfObject
  index?: number
}

export enum BlockMetaKeys {
  PAGE_ID = 'pageId',
  RELATIONSHIP_TYPE = 'relationshipType',
  ORIGINAL_OBJECT = 'originalObject',
  RELATED_OBJECTS = 'relatedObjects',
  CREATED_BY = 'createdBy',
  PROJECT_FILE_ID = 'projectFileId',
  SPACE_ID = 'spaceId',
  CHAT_MESSAGE_IDS = 'chatMessageIds',
  ASSIGNEES = 'assignees',
  DUE_DATE = 'dueDate',
  CREATED_AT = 'createdAt',
  TAGS = 'tags',
  NAME = 'name',
}

export interface blockMeta {
  [BlockMetaKeys.PAGE_ID]: string
  [BlockMetaKeys.RELATIONSHIP_TYPE]?: RelationshipType | null
  [BlockMetaKeys.ORIGINAL_OBJECT]?: OriginalObject | null
  [BlockMetaKeys.RELATED_OBJECTS]?: RelatedObject[]
  [BlockMetaKeys.CREATED_BY]: string
  [BlockMetaKeys.PROJECT_FILE_ID]?: string
  [BlockMetaKeys.SPACE_ID]?: string
  [BlockMetaKeys.CHAT_MESSAGE_IDS]?: string[]
  [BlockMetaKeys.ASSIGNEES]: string[]
  [BlockMetaKeys.DUE_DATE]: Date | null
  [BlockMetaKeys.CREATED_AT]: string
  [BlockMetaKeys.TAGS]: string[]
  [BlockMetaKeys.NAME]: string
}

export interface blockData {
  pageId?: string
  richText?: Value
  lineHeight?: number
  position?: number
  referenceType?: string
  blocksInGroup?: block[]
  tag: IBlockTypes
  imageUrl?: string
  imageId?: string
  tableData?: ITableReducer
  isChecked?: boolean
  typeOfObject?: any
  originalObject?: {
    _id?: string
    pageOfOriginalBlock: string
  }
  error?: boolean
  relationshipType?: string
  ref?: any
  isSelected?: boolean
  index?: number
  createdBy?: string
  showCreatedBy?: boolean
  lastUserId?: string
  width?: number
  height?: number
  calloutWidth?: number
  calloutHeight?: number
  x?: number
  y?: number
  line?: ILineItem
  arrow?: IArrowItem
  shape?: IShapeItem
  frame?: IFrameItem
  documentPage?: IPage | null
  groupId?: string
  scaleX?: number
  scaleY?: number
  connectionFrom?: string[]
  connectionTo?: string[]
  whiteboardPage?: IPage
  whiteboardBlocks?: block[]
  whiteboardWidth?: number
  indent?: number
  imagePosition?: string
  sizeValue?: number
  imageBorderWrapperWidth?: number
  positionIndex?: number
  updateInstance?: boolean
  internalEmbed?: IInternalEmbed
  embed?: IExternalEmbed
  numberInList?: number | null | string
  isEmbedExpanded?: boolean
  isAiGenerating?: boolean
  isAiGenerated?: boolean
  emoji?: Emoji
  gridWidth?: GridBlockWidth
  sizes?: GridSize[]
  cta?: {
    id?: number
    align?: string
  }
}

export interface blockStyle {
  blockType?: BlockStyleType
  styling: blockStyling[]
}

export interface blockStyling {
  type: StyleTypes
  value: string
  _id?: string
}

export enum StyleTypes {
  BACKGROUND_COLOR = 'backgroundColor',
  BOLD = 'bold',
  COLOR = 'color',
  FONT_WEIGHT = 'fontWeight',
  UNDERLINE = 'underline',
  STRIKE_THROUGH = 'strikethrough',
  ITALIC = 'italic',
  FONT_SIZE = 'fontSize',
  FONT_FAMILY = 'fontFamily',
}

export enum BlockStyleType {
  TITLE = 'Title',
  HEADING_1 = 'Heading-1',
  HEADING_2 = 'Heading-2',
  HEADING_3 = 'Heading-3',
  TEXT = 'Text',
  SMALL_TEXT = 'Small-text',
  NUMBERED_LIST = 'Numbered-list',
  BULLETED_LIST = 'Bulleted-list',
  CALLOUT_TEXT = 'Callout-text',
  QUOTE = 'Quote',
  TO_DO = 'To-Do',
  TOGGLE = 'Toggle',
  NESTED_SUBPAGE = 'Nested-subpage',
  LINK_TO_ANOTHER_PAGE = 'Link-to-another-page',
  REGULAR_LINK_TEXT = 'Regular-link-text',
  CUSTOM_TEXT = 'Custom-text',
}

export const TypeOfObject = {
  ...BlockStyleType,
  GROUP: 'Group',
  FRAME: 'Frame',
}

export enum IBlockTypes {
  DOCUMENT = 'document',
  WHITEBOARD = 'whiteboard',
  TITLE = 'h1',
  XLARGE = 'h2',
  SUBHEADER = 'h3',
  LARGE = 'h4',
  MEDIUM = 'h5',
  SMALL = 'h6',
  TEXT = 'p',
  STICKY_NOTE = 'sticky_note',
  LINE = 'line',
  ARROW = 'arrow',
  SHAPE = 'shape',
  IMAGE = 'img',
  EMOJI = 'emoji',
  TABLE = 'table',
  LIST = 'list',
  NUMBERED_LIST = 'numbered_list',
  CHECKLIST = 'checklist',
  FRAME = 'frame',
  IMAGE_TEXT = 'image_text',
  EMBED = 'embed',
  EXTERNAL_EMBED = 'external_embed',
  SLIDESHOW = 'slideshow',
  GENERIC_LINK = 'generic_link',
  CTA = 'cta',
}

export const TextBlockTypes = {
  TITLE: IBlockTypes.TITLE,
  XLARGE: IBlockTypes.XLARGE,
  SUBHEADER: IBlockTypes.SUBHEADER,
  LARGE: IBlockTypes.LARGE,
  MEDIUM: IBlockTypes.MEDIUM,
  SMALL: IBlockTypes.SMALL,
  TEXT: IBlockTypes.TEXT,
  LIST: IBlockTypes.LIST,
  CHECKLIST: IBlockTypes.CHECKLIST,
  NUMBER_LIST: IBlockTypes.NUMBERED_LIST,
}

export const CtaBlockTypes = {
  CTA: IBlockTypes.CTA,
}

export const ShapeBlockTypes = {
  LINE: IBlockTypes.LINE,
  ARROW: IBlockTypes.ARROW,
  SHAPE: IBlockTypes.SHAPE,
}

export const SizableTextTypes = {
  TITLE: IBlockTypes.TITLE,
  XLARGE: IBlockTypes.XLARGE,
  SUBHEADER: IBlockTypes.SUBHEADER,
  LARGE: IBlockTypes.LARGE,
  MEDIUM: IBlockTypes.MEDIUM,
  SMALL: IBlockTypes.SMALL,
  TEXT: IBlockTypes.TEXT,
}

export const StickyNoteTypes = {
  STICKY_NOTE: IBlockTypes.STICKY_NOTE,
}

export const ImageTypes = {
  IMAGE: IBlockTypes.IMAGE,
}

export interface ITableReducer {
  columns: ITableColumn[]
  data: ITableData[]
  skipReset: boolean
  isOpSendingAllowed: boolean
}

export interface ITableColumn {
  id: string | number
  label: string
  accessor?: string
  minWidth?: number
  dataType: string
  options?: any[]
  width?: number
  disableResizing?: boolean
  created?: boolean
}

export interface ITableData {
  [key: string]: string | number
}

export interface ITableAction {
  type: string
  columnId?: string
  rowIndex?: number
  value?: string
  option?: string
  backgroundColor?: string
  dataType?: string
  label?: string
  focus?: boolean
  data?: ITableData[]
  columns?: ITableColumn[]
}

export interface WhiteboardBlockProps {
  isPreview?: boolean
  block: block
  isSlideshow?: boolean
  isSelected?: boolean
  isShadow?: boolean
}

export enum GridBlockWidth {
  FULL_BLEED = 'full-bleed',
  OUT_OF_GRID = 'out-of-grid',
  IN_GRID = 'in-grid',
  SMALL = 'small',
}

export interface GridSize {
  width: GridBlockWidth
  height: number
}
