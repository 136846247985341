import { envVars } from 'config/env'
import { removeFileExtension } from 'shared/lib'
import { useToast } from 'services/helpers/useToast'
import { useUpload } from '_features/upload/model/useUpload'
import useFileUpload from '_entities/resources/model/useFileUpload'
import { generateNewExternalPageBlock } from 'utils/editor/generateBlock'
import { IBlockTypes } from './types'
import { SourceKeys } from 'interfaces/editor'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useEmbed } from '_entities/embed'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setIsProjectFileUploadLoading } from 'redux/reducers/projectFileReducer'
import { getBlocks } from 'shared/shareDb'

const useBlockUpload = () => {
  const _fileUpload = useFileUpload()
  const toast = useToast()
  const _upload = useUpload()
  const _embed = useEmbed()
  const _submit = useSubmit()
  const _json1 = useJson1()
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector((state) => state.global.user)
  const page = useAppSelector((state) => state.page.selectedPage)

  const handleBlockFileUpload = async (file: File) => {
    if (file && file.size > 5000000000) {
      toast.error('The maximum size per file is 5GB')
      return
    }

    if (!file) return

    let response = await _fileUpload.uploadUrl(file.name)

    const url = response?.url
    const path = response?.path

    if (!response || !file) return

    response = await _fileUpload.uploadFile(url, file)

    if (!response) return

    await _fileUpload.createResource(path)

    const link = `${envVars.api.s3Butcket}${path}`

    const name = removeFileExtension(file.name)

    await _upload.handleCreateEmbedPage({
      name,
      url: link,
      pageId: page?.id,
    })

    return link
  }

  const handleFileOnDrop = (pageId: string, acceptedFiles: File[]) => {
    dispatch(setIsProjectFileUploadLoading(true))

    acceptedFiles.map(async (file: File) => {
      const link = await handleBlockFileUpload(file)

      if (link) {
        const type = _embed.getEmbedTypeFromLink(link)

        if (type && currentUser && currentUser.uuid && link) {
          const newBlock = generateNewExternalPageBlock({
            tag: IBlockTypes.EXTERNAL_EMBED,
            userUuid: currentUser.uuid,
            embed: {
              type,
              name: file.name,
              url: link,
            },
          })
          const blocks = getBlocks(pageId)
          const index = blocks?.length || 0
          _submit.submit(pageId, _json1.addBlock(newBlock, index), SourceKeys.UPDATE_BLOCK)
          dispatch(setIsProjectFileUploadLoading(false))
        }
      }
    })
  }

  return {
    handleBlockFileUpload,
    handleFileOnDrop,
  }
}

export default useBlockUpload
