import { IPalette } from 'interfaces/settings'

interface IProps {
  palette: IPalette
  handleDuplicatePalette: (palette: IPalette) => void
  deletePalette: (palette: IPalette) => void
  handleEditPalette: (palette: IPalette) => void
  handleResetColorToDefault: (palette: IPalette) => void
}

const useAdminDropdownOptions = ({
  palette,
  handleDuplicatePalette,
  deletePalette,
  handleEditPalette,
  handleResetColorToDefault,
}: IProps) => {
  const defaultDropdownOptions = [
    {
      id: 1,
      label: 'Rename',
      onClick: () => handleEditPalette(palette),
    },
    {
      id: 2,
      label: 'Duplicate',
      onClick: () => handleDuplicatePalette(palette),
    },
    {
      id: 3,
      label: 'Reset to default',
      onClick: () => handleResetColorToDefault(palette),
    },
    {
      id: 4,
      label: 'Delete palette',
      onClick: () => deletePalette(palette),
    },
  ]

  return {
    defaultDropdownOptions,
  }
}

export default useAdminDropdownOptions
