import { ITools } from 'interfaces/whiteboard'
import React from 'react'
import { Circle, Rect, RegularPolygon } from 'react-konva'
import { getColor } from 'shared/colors'
import { useAppSelector } from 'redux/hooks'
import { constants } from '_entities/whiteboard'
import * as whiteboardConstants from 'whiteboard/constants'

export const PreviewShapes = () => {
  const tool = useAppSelector((state) => state.whiteboard.tool)
  return (
    <React.Fragment>
      <Rect
        visible={tool === ITools.RECTANGLE}
        width={constants.SHAPE_DEFAULT_WIDTH}
        height={constants.SHAPE_DEFAULT_HEIGHT}
        fill={getColor('--primary-color2')}
        shadowBlur={1}
        shadowOffsetY={1}
        shadowOffsetX={0.3}
        shadowColor='#383838'
        shadowOpacity={0.2}
        opacity={0.4}
        name={`block-preview-${ITools.RECTANGLE}`}
      />

      <Rect
        visible={tool === ITools.CURVED_RECTANGLE}
        width={constants.SHAPE_DEFAULT_WIDTH}
        height={constants.SHAPE_DEFAULT_HEIGHT}
        cornerRadius={whiteboardConstants.default.DEFALT_CURVED_RECTANGLE_RADIUS}
        fill={getColor('--primary-color2')}
        shadowBlur={1}
        shadowOffsetY={1}
        shadowOffsetX={0.3}
        shadowColor='#383838'
        shadowOpacity={0.2}
        opacity={0.4}
        name={`block-preview-${ITools.CURVED_RECTANGLE}`}
      />

      <Circle
        visible={tool === ITools.CIRCLE}
        fill={getColor('--primary-color2')}
        radius={constants.SHAPE_DEFAULT_WIDTH / 2}
        perfectDrawEnabled={false}
        shadowBlur={1}
        shadowOffsetY={1}
        shadowOffsetX={0.3}
        shadowColor='#383838'
        shadowOpacity={0.2}
        opacity={0.4}
        name={`block-preview-${ITools.CIRCLE}`}
      />

      <RegularPolygon
        visible={tool === ITools.TRIANGLE}
        sides={3}
        radius={constants.SHAPE_DEFAULT_WIDTH / 2}
        fill={getColor('--primary-color2')}
        shadowBlur={1}
        shadowOffsetY={1}
        shadowOffsetX={0.3}
        shadowColor='#383838'
        shadowOpacity={0.2}
        opacity={0.4}
        name={`block-preview-${ITools.TRIANGLE}`}
      />

      <RegularPolygon
        visible={tool === ITools.ROMB}
        sides={4}
        radius={constants.SHAPE_DEFAULT_WIDTH / 2}
        fill={getColor('--primary-color2')}
        shadowBlur={1}
        shadowOffsetY={1}
        shadowOffsetX={0.3}
        shadowColor='#383838'
        shadowOpacity={0.2}
        opacity={0.4}
        name={`block-preview-${ITools.ROMB}`}
      />

      <RegularPolygon
        visible={tool === ITools.PENTAGON}
        sides={5}
        radius={constants.SHAPE_DEFAULT_WIDTH / 2}
        fill={getColor('--primary-color2')}
        shadowBlur={1}
        shadowOffsetY={1}
        shadowOffsetX={0.3}
        shadowColor='#383838'
        shadowOpacity={0.2}
        opacity={0.4}
        name={`block-preview-${ITools.PENTAGON}`}
      />

      <RegularPolygon
        visible={tool === ITools.HEXAGON}
        sides={6}
        radius={constants.SHAPE_DEFAULT_WIDTH / 2}
        fill={getColor('--primary-color2')}
        shadowBlur={1}
        shadowOffsetY={1}
        shadowOffsetX={0.3}
        shadowColor='#383838'
        shadowOpacity={0.2}
        opacity={0.4}
        name={`block-preview-${ITools.HEXAGON}`}
      />
    </React.Fragment>
  )
}
