import styled, { CSSProperties } from 'styled-components'

export const VerticalLine = styled.div<{
  margin?: CSSProperties['margin']
  padding?: CSSProperties['padding']
}>`
  width: 1px;
  min-height: 100%;
  opacity: 0.3;
  background-color: var(--gray3);
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
`
