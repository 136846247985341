import styled from 'styled-components'
import { usePresentation } from '_features/canvas'

export const OverflowContainer = styled.div`
  width: 100%;
  height: fit-content;
  overflow-x: auto;
  margin-bottom: 8px;
`

export const StyledContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`

export const StyledImage = styled.img<{ selected?: boolean }>`
  height: auto;
  width: 22%;
  border-radius: 6px;
  margin-left: 8px;
  object-fit: cover;
  opacity: ${(props) => (props.selected ? 0.4 : 1)};
  cursor: pointer;
  transition: opacity 0.1s ease-out;
  &:hover {
    opacity: 0.4;
  }
`

interface Props {
  pageId: string
  currentIndex?: number
  frameSnapshots: string[]
}

export const FrameNavigation = (props: Props) => {
  const _presentation = usePresentation()

  const onClick = (index: number) => {
    const sortedByY = _presentation.sortFramesByY(props.pageId)
    if (!sortedByY) return
    const frame = sortedByY[index]
    if (!frame) return
    _presentation.focusFrame({ node: frame })
  }

  return (
    <OverflowContainer>
      <StyledContainer>
        {props.frameSnapshots.map((snapshot, index) => (
          <StyledImage
            selected={index === props.currentIndex}
            onClick={() => onClick(index)}
            key={index}
            src={snapshot}
            alt={`frame-${index}`}
          />
        ))}
      </StyledContainer>
    </OverflowContainer>
  )
}
