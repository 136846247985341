import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { useParams, useSearchParams } from 'react-router-dom'
import { getSelectedOption } from 'redux/reducers/taskManagerReducer'

export const useTaskManagerView = () => {
  const params = useParams()
  const [searchParams] = useSearchParams()
  const dispatch = useAppDispatch()
  const projectId = params.id
  const pageId = searchParams.get('pageId')

  const options = useAppSelector((state) => state.taskManager.options)
  const selectedOption = useAppSelector(getSelectedOption)

  return {
    pageId,
    dispatch,
    projectId,
    options,
    selectedOption,
  }
}
