import { ReactNode } from 'react'
import { icons } from 'shared/icons'
import { OptionWrapper } from './styles'

interface Props {
  data: {
    id?: string | number | null | undefined
    value: string
    label: ReactNode
  }
  selectedOption: string | undefined
  shownModal: string | null
  icon?: ReactNode
}

const WorkspaceOption = (props: Props) => {
  return (
    <OptionWrapper>
      {props.selectedOption === props.data.id ||
      props.selectedOption?.toString() === props.data.id ? (
        <icons.check />
      ) : (
        props.icon
      )}

      {props.data.label}
    </OptionWrapper>
  )
}

export default WorkspaceOption
