import { TreeView } from 'components/organisms/TreeView/TreeView'
import { DropOptions } from '@minoru/react-dnd-treeview'
import { type block } from '_entities/block'
import { ExtendedNodeModel } from 'interfaces/editor'
import { useAppSelector } from 'redux/hooks'
import TasksDrawer from './TasksDrawer'
import { useCreateTask } from '_features/task'

export const ROOT_ID = 'tasks-tree'

export const TasksTree = () => {
  const selectedBlock = useAppSelector((state) => state.page.selectedBlock)
  const _createTask = useCreateTask()

  return (
    <TreeView
      canDrag={() => false}
      rootId={ROOT_ID}
      tree={[
        {
          id: 'tasks-component',
          parent: 'tasks-tree',
          text: '',
          droppable: true,
          type: 'tasks',
        } as ExtendedNodeModel,
      ]}
      onDrop={(e, props: DropOptions<block>) => {
        const { dragSource } = props as DropOptions<block>
        if (dragSource) {
          const block =
            selectedBlock?._id === (dragSource as ExtendedNodeModel).id
              ? selectedBlock
              : dragSource.data
          block && _createTask.createReferencedTask(block)
        }
      }}
      classes={{
        container: 'tasks-tree-container',
      }}
      render={() => {
        return <TasksDrawer />
      }}
    />
  )
}
