import React from 'react'

import { AnimatePresence } from 'framer-motion'
import {
  ChatDrawerContainer,
  ProfileDrawerHeader,
  ChatDrawerContent,
  ProfileInfo,
  StyledUserName,
  StyledJobName,
  StyledArrowBarToRight,
  StyledMaximizeIcon,
  StyledSettingsIcon,
  StyledLogoutIcon,
  LogoutWrapper,
  StyledToggleWrapper,
} from './styles'

import { IconButton } from 'components/atoms/Button/Button'

import useProfileDrawer from './useProfileDrawer'
import Avatar from 'components/atoms/Avatar/Avatar'
import AdminColorSettings from 'components/organisms/AdminColorSettings/AdminColorSettings'
import ColorSettings from 'components/organisms/ColorSettings/ColorSettings'
import { IRoles } from 'interfaces/invite'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import ProfileSettings from 'components/organisms/ProfileSettings/ProfileSettings'
import Defaults from '_entities/profile/ui/Defaults/Defaults'
import LLMDropdown from '_features/ai/ui/LLMDropdown'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import Switch from 'components/atoms/Switch/Switch'
import { isMobile } from 'react-device-detect'
import { ProfileCtaSection } from '_features/cta'
import { useAppSelector } from 'redux/hooks'
import {
  SpaceColorSettings,
  SpaceImageBackgroundSetting,
  SpaceColorAdminSettings,
} from '_entities/space'
import { IPermissions } from '_entities/user'
import { ImageAdminEdit } from '_entities/project'

const ProfileDrawer = () => {
  const _profileDrawer = useProfileDrawer()
  const myRole = useAppSelector((state) => state.projectFile.myProjectFileRole)

  return (
    <AnimatePresence>
      {_profileDrawer.open && (
        <ChatDrawerContainer
          id={'profile-drawer'}
          initial={{
            transform: 'translateX(100%)',
          }}
          animate={{
            transform: 'translateX(0%)',
          }}
          exit={{
            transform: 'translateX(100%)',
          }}
          transition={{ ease: 'easeInOut', duration: 0.4 }}
        >
          <ChatDrawerContent
            initial={{
              width: isMobile ? '100vw' : '400px',
            }}
            animate={{
              width: _profileDrawer.fullScreen ? '100vw' : isMobile ? '100vw' : '400px',
            }}
            exit={{
              width: isMobile ? '100vw' : '400px',
            }}
            transition={{ ease: 'easeInOut', duration: 0.4 }}
          >
            <ProfileDrawerHeader>
              {_profileDrawer.user && _profileDrawer.userImage ? (
                <Avatar
                  src={_profileDrawer.user && _profileDrawer.userImage}
                  width='60px'
                  height='60px'
                  userName={`${_profileDrawer.user?.firstName} ${_profileDrawer.user?.lastName}`}
                />
              ) : (
                <Avatar
                  src=''
                  width='60px'
                  height='60px'
                  userName={`${_profileDrawer.user?.firstName} ${_profileDrawer.user?.lastName}`}
                />
              )}

              <ProfileInfo>
                <StyledUserName>
                  {_profileDrawer.user?.firstName} {_profileDrawer.user?.lastName}
                </StyledUserName>
                <StyledJobName>{_profileDrawer.user?.job}</StyledJobName>
              </ProfileInfo>
              <IconButton
                variant='transparent'
                size='small'
                onClick={_profileDrawer.toggleSettings}
                border={
                  _profileDrawer.isSettingsVisible
                    ? '1px solid var(--primary-color)'
                    : '1px solid transparent'
                }
              >
                <StyledSettingsIcon />
              </IconButton>
              <IconButton
                variant='transparent'
                size='small'
                onClick={_profileDrawer.handleToggleFullScreen}
              >
                <StyledMaximizeIcon />
              </IconButton>
              <IconButton
                variant='transparent'
                size='small'
                onClick={_profileDrawer.handleProfileDrawerClose}
              >
                <StyledArrowBarToRight />
              </IconButton>
            </ProfileDrawerHeader>

            {_profileDrawer.isSettingsVisible ? (
              <ProfileSettings />
            ) : (
              <>
                {_profileDrawer.user?.role?.name === IRoles.SUPER_ADMIN ? (
                  <>
                    <LLMDropdown />
                    <AdminColorSettings />
                    {(myRole === IRoles.OWNER || myRole === IPermissions.CAN_EDIT) && (
                      <ProfileCtaSection />
                    )}

                    <ImageAdminEdit />
                    <SpaceColorAdminSettings />
                    <Defaults />
                    <StyledToggleWrapper>
                      <CustomP fontWeight={'600'}>Show error messages</CustomP>
                      <Switch
                        checked={_profileDrawer.isErrorMessageToggleSelected}
                        onClick={_profileDrawer.handleShowErrorMessagesToggle}
                      />
                    </StyledToggleWrapper>
                  </>
                ) : (
                  <div>
                    <ColorSettings />
                    {(myRole === IRoles.OWNER || myRole === IPermissions.CAN_EDIT) && (
                      <ProfileCtaSection />
                    )}
                    <SpaceImageBackgroundSetting />
                    <SpaceColorSettings />
                  </div>
                )}
              </>
            )}
          </ChatDrawerContent>
          <LogoutWrapper>
            <Wrapper onClick={_profileDrawer.handleLogout} display={'flex'} alignItems={'center'}>
              <IconButton variant='transparent' size='small'>
                <StyledLogoutIcon />
              </IconButton>
              <CustomP color={getColor('--everyday-red')} fontWeight={'600'}>
                Log out
              </CustomP>
            </Wrapper>
          </LogoutWrapper>
        </ChatDrawerContainer>
      )}
    </AnimatePresence>
  )
}

export default ProfileDrawer
