import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { GetStreamContextProvider } from 'services/getstream/GetStreamContext'
import 'react-toastify/dist/ReactToastify.css'
import 'stream-chat-react/dist/css/v2/index.css'
import './index.css'
import App from './App'
import store from './redux/store'
import reportWebVitals from './reportWebVitals'
import { Connection } from 'sharedb/lib/client'
import { Socket } from 'sharedb/lib/sharedb'

// Initialize javascript-time-ago
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en.json'
import { envVars } from 'config/env'
import { GoogleOAuthProvider } from '@react-oauth/google'
import ReconnectingWebSocket from 'reconnecting-websocket'
TimeAgo.addDefaultLocale(en)

const container = document.getElementById('app') as HTMLElement

const root = createRoot(container!)
/* if (envVars.nodeEnv === 'production') {
  console.log = () => void 0
} */

declare global {
  interface Window {
    __MANAGER_SOCKET__?: ReconnectingWebSocket
    __SOCKET__?: Socket
    __CONNECTION__?: Connection
    __ELECTRON_FETCH__?: (url: string) => any
    __FILE__?: File
  }
}

root.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={`${envVars.googleApi.clientId}`}>
      <GetStreamContextProvider>
        <App />
      </GetStreamContextProvider>
    </GoogleOAuthProvider>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
