import React from 'react'
import { StyledTableRow } from './styles'

interface Props {
  children: React.ReactNode
  id?: string
  padding?: string
  margin?: string
}

const TableRow = ({ children, id, padding, margin }: Props) => {
  return (
    <StyledTableRow id={id} padding={padding} margin={margin}>
      {children}
    </StyledTableRow>
  )
}

export default TableRow
