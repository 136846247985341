import { IPalette, IPaletteColor } from 'interfaces/settings'
import ColorDetails from '../ColorDetails/ColorDetails'
import { DetailedColorsWrapper } from 'components/organisms/AdminPalette/styles'
import React from 'react'
import { RGBColor } from 'react-color'

interface IProps {
  userPalette: IPalette
  colorToChange: IPaletteColor | null
  isOpen: boolean
  palette: IPalette
  handleSelectColorToChange: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    paletteColor: IPaletteColor,
  ) => void
  expanded: string
  handleChange: (
    color: any,
    event: React.ChangeEvent<HTMLInputElement>,
    paletteColor: IPaletteColor,
  ) => void
  pickedColor?: RGBColor
  closeColorPicker: () => void
  convertToTitleCase: (str: string) => string
}

const DetailedColors = ({
  userPalette,
  colorToChange,
  isOpen,
  palette,
  handleSelectColorToChange,
  expanded,
  handleChange,
  pickedColor,
  closeColorPicker,
  convertToTitleCase,
}: IProps) => {
  return (
    <DetailedColorsWrapper>
      {userPalette.attributes.colors.map((paletteColor: IPaletteColor) => {
        return (
          <ColorDetails
            key={paletteColor.type}
            paletteColor={paletteColor}
            colorToChange={colorToChange}
            isOpen={isOpen}
            palette={palette}
            handleSelectColorToChange={handleSelectColorToChange}
            expanded={expanded}
            userPalette={userPalette}
            handleChange={handleChange}
            colorType={convertToTitleCase(paletteColor.type)}
            pickedColor={pickedColor}
            closeColorPicker={closeColorPicker}
            isAdmin
          />
        )
      })}
    </DetailedColorsWrapper>
  )
}

export default DetailedColors
