import React from 'react'
import { ListItem } from '../CustomSelectOptions/styles'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import Avatar from 'components/atoms/Avatar/Avatar'

interface Props {
  onClick?: (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void
  padding?: string
  option?: string
  color?: string
  paddingFirstChild?: string
  paddingLastChild?: string
  icon?: JSX.Element
  src?: string
  alt?: string
  isProfile?: boolean
  userColor?: string
  userName?: string
  alignItems?: string
}

const IconOption = ({
  onClick,
  padding,
  option,
  color,
  paddingFirstChild,
  paddingLastChild,
  icon,
  alignItems,
  src,
  alt,
  isProfile,
  userName,
  userColor,
}: Props) => {
  return (
    <ListItem
      onClick={onClick}
      color={color}
      padding={padding}
      paddingFirstChild={paddingFirstChild}
      paddingLastChild={paddingLastChild}
      alignItems={alignItems}
    >
      {isProfile ? (
        <Avatar src={src} alt={alt} userColor={userColor} userName={userName} />
      ) : (
        <CustomIcon src={src} alt={alt} />
      )}
      {option}
    </ListItem>
  )
}

export default IconOption
