import React from 'react'
import CustomP from 'components/atoms/CustomP/CustomP'
import InviteModal from 'components/molecules/InviteModal/InviteModal'
import ProjectFileMembers from 'components/organisms/ProjectFileMembers/ProjectFileMembers'
import InviteModalDivider from 'components/molecules/InviteModalDivider/InviteModalDivider'
import InviteByLink from './InviteByLink/InviteByLink'
import InviteByEmail from './InviteByEmail/InviteByEmail'
import { getColor } from 'shared/colors'

type Props = {
  modalShow: boolean
  setModalShow: React.Dispatch<React.SetStateAction<boolean>>
  handleSpaceModalClose?: () => void
}

const ProjectFileInviteModal = ({ modalShow, setModalShow }: Props) => {
  return (
    <InviteModal
      key={'projectFileInv'}
      show={modalShow}
      setShow={setModalShow}
      width={'580px'}
      title={
        <CustomP fontSize='16px' fontWeight='700' color={getColor('--primary-text')}>
          Share this project file
        </CustomP>
      }
      footer={<ProjectFileMembers />}
    >
      {/* =====================  Invite by link ===================== */}
      <InviteByLink />
      {/* ===================== Divider  ============================ */}
      <InviteModalDivider />

      {/* ===================== Invite by email ===================== */}

      <InviteByEmail />
    </InviteModal>
  )
}

export default ProjectFileInviteModal
