import { css } from 'styled-components'

export const StyledCss = css<{
  height?: string
  minWidth?: string
  width?: string
  padding?: string
  margin?: string
  objectFit?: string
  objectPosition?: string
  borderRadius?: string
  imageTop?: string
  imageLeft?: string
  position?: string
  inset?: string
  zIndex?: string
  cursor?: string
}>`
  z-index: ${(props) => props.zIndex};
  position: ${(props) => props.position};
  inset: ${(props) => props.inset};
  height: ${(props) => props.height};
  min-width: ${(props) => props.minWidth};
  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  object-fit: ${(props) => props.objectFit};
  object-position: ${(props) => props.objectPosition};
  border-radius: ${(props) => props.borderRadius};
  cursor: ${(props) => props.cursor || 'pointer'};
  top: ${(props) => props.imageTop || ''};
  top: ${(props) => props.imageLeft || ''};
`
export const StyledArrowCss = css`
  color: var(--primary-color);
  font-size: 12px;
  cursor: pointer;
`
