import React, { BaseSyntheticEvent } from 'react'
import Form from 'react-bootstrap/Form'
import {
  StyledModal,
  StyledModalHeader,
  StyledModalTitle,
  StyledModalBody,
  StyledTitleInputWithLabel,
  StyledLabel,
  StyledInput,
  StyledSelectInputWithLabel,
  StyledSelectInputContainer,
  StyledButton,
} from './styles'
import { useCreateSpaceModal } from './useCreateSpaceModal'

interface Props {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
}

export const CreateSpaceModal = ({ show, setShow }: Props) => {
  const { newSpaceName, setNewSpaceName, description, setDescription, createNewSpace } =
    useCreateSpaceModal({ setShow })
  return (
    <StyledModal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='inviteModalBackground'
    >
      <StyledModalHeader closeButton>
        <StyledModalTitle>Create a new space</StyledModalTitle>
      </StyledModalHeader>
      <StyledModalBody>
        <Form>
          <StyledTitleInputWithLabel controlId='exampleForm.ControlInput1'>
            <StyledLabel className='mb-0'>Space name</StyledLabel>
            <StyledInput
              value={newSpaceName}
              type='text'
              name='title'
              placeholder='Enter space name'
              onChange={(e: BaseSyntheticEvent) => setNewSpaceName(e.target.value)}
            />
          </StyledTitleInputWithLabel>
          <StyledTitleInputWithLabel controlId='exampleForm.ControlInput1'>
            <StyledLabel className='mb-0'>Description</StyledLabel>
            <StyledInput
              value={description}
              type='text'
              name='title'
              placeholder='Enter space description'
              onChange={(e: BaseSyntheticEvent) => setDescription(e.target.value)}
            />
          </StyledTitleInputWithLabel>
          <StyledSelectInputWithLabel>
            <StyledSelectInputContainer>
              <StyledButton
                variant='primary'
                onClick={() => createNewSpace(newSpaceName, description)}
              >
                Create&nbsp;new&nbsp;space
              </StyledButton>
            </StyledSelectInputContainer>
          </StyledSelectInputWithLabel>
        </Form>
      </StyledModalBody>
    </StyledModal>
  )
}
