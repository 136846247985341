import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import { EmojiPicker, EmojiPickerContainer, useEmojiBlock } from '_features/emojis'
import { IconButtonSize } from '../Toolbar'
import { usePopperMenu } from 'shared/lib/usePopper'
import { useRef } from 'react'
import { useAppDispatch } from 'redux/hooks'
import { setTool } from 'redux/reducers/whiteboardReducer'
import { ITools } from 'interfaces/whiteboard'

interface Props {
  isDisabled: boolean
}

export const EmojiSelector = (props: Props) => {
  const _popperMenu = usePopperMenu()
  const _emojiBlock = useEmojiBlock()
  const dispatch = useAppDispatch()
  const ref = useRef<HTMLButtonElement>(null)
  return (
    <>
      <IconButton
        disabled={props.isDisabled}
        ref={ref}
        onClick={() => {
          if (props.isDisabled) return
          _popperMenu.setElement(() => {
            if (_popperMenu.element) return undefined
            else if (ref.current !== null) return ref.current
          })
        }}
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
      >
        <icons.smiley />
      </IconButton>
      <EmojiPickerContainer
        ref={(instance) => {
          if (instance) {
            _popperMenu.emojiPopperElementRef.current = instance
          }
        }}
        style={_popperMenu.emojiPopper.styles.popper}
        {..._popperMenu.emojiPopper.attributes.popper}
        shouldHide={!_popperMenu.element}
      >
        <EmojiPicker
          onEmojiSelect={(emoji) => {
            _emojiBlock.setSelectedCanvasEmoji(emoji)
            dispatch(setTool(ITools.EMOJI))
            // Close menu
            _popperMenu.setElement(undefined)
          }}
        />
      </EmojiPickerContainer>
    </>
  )
}
