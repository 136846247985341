import { Circle, SwitchContainer } from './styles'

interface Props {
  checked: boolean
  onClick?: (e: React.MouseEvent) => void
  transform?: string
}

const Switch = (props: Props) => {
  return (
    <SwitchContainer
      checked={props.checked}
      onClick={(e) => props.onClick && props.onClick(e)}
      transform={props.transform}
    >
      <Circle></Circle>
    </SwitchContainer>
  )
}

export default Switch
