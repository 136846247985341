import React, { useState } from 'react'

export const useReferralsTabUserModal = () => {
  const CURRENT_INVITED_USERS = 0
  const INVITED_USERS_NEDDED_FOR_PRIZE = 5
  const PERCENTAGE_INDEX = 100

  const percentageForProgressBar =
    (CURRENT_INVITED_USERS / INVITED_USERS_NEDDED_FOR_PRIZE) * PERCENTAGE_INDEX
  const [referralLink, setReferralLink] = useState<string>('')
  const [referralEmails, setReferralEmails] = useState<Array<string>>([])
  return {
    referralLink,
    referralEmails,
    percentageForProgressBar,
  }
}
