import { AxiosService } from 'services/axiosService/axiosService'

const useProject = () => {
  const axiosService = new AxiosService()

  const setDefaultProject = async (projectId: string, isDefault?: boolean) => {
    return await axiosService.setDefaultProject(projectId, isDefault)
  }

  const getDefaultProject = async () => {
    return await axiosService.getDefaultProject()
  }

  const getProjectFilesBySpaceId = async (spaceId: string) => {
    return await axiosService.getProjectFilesBySpaceId(spaceId)
  }

  const deleteProjectFile = async (projectFileId: number, spaceId?: string) => {
    if (spaceId) {
      return await axiosService.deleteProjectFileRequest(spaceId, projectFileId)
    }
  }

  return {
    setDefaultProject,
    getDefaultProject,
    getProjectFilesBySpaceId,
    deleteProjectFile,
  }
}

export default useProject
