import styled from 'styled-components'

export const StyledSpaceTabBodyHeaderText = styled.h6`
  position: relative;
  font-size: 11pt;
  user-select: none;
  display: flex;
  gap: 0.5rem;
  top: 1px;
  text-align: start;
  width: 100%;
  display: flex;
  justify-content: flex-end;
`
