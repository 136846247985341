import { block, getHtmlPortalId } from '_entities/block'
import { useStage } from '_features/canvas'
import { getById } from 'shared/lib'

export const useHtml = () => {
  const _stage = useStage()

  const getBoundingRect = (
    pageId: string,
    el: HTMLDivElement,
    scaleX?: number,
    scaleY?: number,
  ) => {
    const stage = _stage.getStage(pageId)
    if (stage) {
      const rescaledSize = _stage.unScaleSize(pageId, el.getBoundingClientRect())
      if (!rescaledSize) return
      return {
        width: rescaledSize.width / (scaleX ?? 1),
        height: rescaledSize.height / (scaleY ?? 1),
      }
    }
  }

  const getNonScaledRect = (el: HTMLDivElement) => {
    return {
      width: el.getBoundingClientRect().width,
      height: el.getBoundingClientRect().height,
    }
  }

  const getHtmlElement = (block: block) => {
    return getById(getHtmlPortalId(block))
  }

  return {
    getBoundingRect,
    getNonScaledRect,
    getHtmlElement,
  }
}
