import { AxiosService } from 'services/axiosService/axiosService'
import { useAppDispatch } from 'redux/hooks'
import { setProjectFiles } from 'redux/reducers/spaceReducer'

export const useGetProjectFiles = () => {
  const dispatch = useAppDispatch()
  const AxiosInstance = new AxiosService()
  const getAndDispatchSpaceProjectFiles = async (spaceId: string) => {
    const getProjectFilesResponse = await AxiosInstance.getSpaceProjectFiles(spaceId)
    if (getProjectFilesResponse) dispatch(setProjectFiles(getProjectFilesResponse))
  }

  return {
    getAndDispatchSpaceProjectFiles,
  }
}
