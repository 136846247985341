import { emojiRegExpString, unifiedRegExpString } from '_features/emojis/lib/regex'
import { getCustomEmojiById } from './getCustomEmoji'

export const isSupportedEmoji = (code: string) => {
  if (getCustomEmojiById(code)) return true

  const isNativeEmoji = RegExp(emojiRegExpString).test(code)
  if (isNativeEmoji) return true

  const isUnifiedEmoji = RegExp(unifiedRegExpString).test(code)
  if (isUnifiedEmoji) return true

  return false
}
