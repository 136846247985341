import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setTasks, setOptions, setPropertyDefinitions } from 'redux/reducers/taskManagerReducer'
import { useMessages } from './useMessages'
import { tasksApi } from '_features/task/api/task'
import { manager } from '_features/tasks/api/manager'
import { getTaskWithValues } from '_features/tasks'
import { getTaskManager } from '../lib'
import { useMessageListener } from '_features/task'

export const useWebsockets = () => {
  // ** Redux state
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const propertyDefinitions = useAppSelector((state) => state.taskManager.propertyDefinitions)
  const tasks = useAppSelector((state) => state.taskManager.tasks)

  // ** Hooks
  const dispatch = useAppDispatch()
  const _messages = useMessages()
  const _messageListener = useMessageListener()

  // ** Network
  const [handleGetTasks] = tasksApi.endpoints.getTasks.useLazyQuery()
  const [handleGetOptions] = manager.endpoints.getManagerOptions.useLazyQuery()
  const [handleGetPropertyDefinitions] =
    manager.endpoints.getManagerPropertyDefinitions.useLazyQuery()

  const joinManager = async (managerId: number) => {
    if (window.__MANAGER_SOCKET__) {
      _messages?.joinTaskManager(managerId)
    }
  }

  const getManagerTasks = async (managerId: number) => {
    const tasks = (await handleGetTasks({ managerId }).unwrap()).data
    if (!tasks) return

    const tasksWithProperties = getTaskWithValues(tasks, propertyDefinitions)

    dispatch(setTasks([...tasksWithProperties]))
  }

  const getManagerOptions = async (managerId: number) => {
    const options = (await handleGetOptions({ managerId }).unwrap()).data
    if (!options) return
    dispatch(setOptions(options))
  }

  const getManagerPropertyDefinitions = async (managerId: number) => {
    const propertyDefinitions = (await handleGetPropertyDefinitions({ managerId }).unwrap()).data
    if (!propertyDefinitions) return
    dispatch(setPropertyDefinitions(propertyDefinitions))
  }

  useEffect(() => {
    const manager = getTaskManager(projectFile)

    const managerId = manager?.id

    if (!managerId) return

    joinManager(managerId)
    getManagerOptions(managerId)
    getManagerPropertyDefinitions(managerId)
  }, [projectFile])

  useEffect(() => {
    const manager = getTaskManager(projectFile)

    const managerId = manager?.id

    if (!managerId) return

    getManagerTasks(managerId)
  }, [propertyDefinitions])

  useEffect(() => {
    window.__MANAGER_SOCKET__?.addEventListener('message', _messageListener.messageListener)

    return () => {
      window.__MANAGER_SOCKET__?.removeEventListener('message', _messageListener.messageListener)
    }
  }, [tasks])

  useEffect(() => {
    const pingPongInterval = window.setInterval(() => {
      _messages?.pingPong()
    }, 1000 * 30)

    return () => {
      window.clearInterval(pingPongInterval)
    }
  }, [])
}
