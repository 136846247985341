import { type block } from '_entities/block'

export const DEBOUNCE_UPLOAD_BLOCK_MIDDLE = 500
export const TIMEOUT_SHORT_DELAY = 250

export const IMAGE_OPTIONS_SCALE_VALUE = 0.6

export const IMAGE_DEFAULT_WIDTH = 300
export const IMAGE_MINIMUM_ZOOM_SIZE = 25
export const IMAGE_DEFAULT_ZOOM_SIZE = 100
export const IMAGE_MAXIMUM_ZOOM_SIZE = 500

export const IMAGE_WITH_TEXT_LABEL = 'image with text'

export const REPLACE_IMAGE_IN_PROGRESS = 'Image replacement started...'
export const NO_TEXT_CONTENT = 'No textual content yet...'
export const IMAGE_DEFAULT_URL = 'undefined'

export const IMAGE_BORDER_WRAPPER_SELECTOR = 'imageBorderWrapperSelector'

export const IMAGE_OPTIONS_BORDER_RADIUS = '7px'
export const INPUT_SCREEN_REMOVER_PIXELS = '-9999px'

export const IMAGE_TOGGLE_WIDTH_MAXIMUM = 75
export const IMAGE_TOGGLE_WIDTH_MIDDLE = 50
export const IMAGE_TOGGLE_WIDTH_MINIMUM = 35
export const MINIMUM_RECTANGLE_SIZE = 200

export const orderingDataForImage = {
  NORTH_WEST: {
    IMAGE: 1,
    TEXT: 2,
    IS_IMAGE_UP: true,
  },
  NORTH_EAST: {
    IMAGE: 2,
    TEXT: 1,
    IS_IMAGE_UP: true,
  },
  SOUTH_WEST: {
    IMAGE: 1,
    TEXT: 2,
    IS_IMAGE_UP: false,
  },
  SOUTH_EAST: {
    IMAGE: 2,
    TEXT: 1,
    IS_IMAGE_UP: false,
  },
}

export const FLOAT_LEFT_OPTION_MARGIN = '0 1rem 0.15rem 0'
export const FLOAT_RIGHT_OPTION_MARGIN = '0 0 0.15rem 1rem'

export const RESIZE_LEFT_SELECTOR = 'resizeLeftSelector'
export const RESIZE_RIGHT_SELECTOR = 'resizeRightSelector'
export const RESIZE_BOTTOM_SELECTOR = 'resizeLeftIconSelector'
export const DIAGONAL_ICON_SELECTOR = 'diagonalIconSelector'
export const CROP_ICON_SELECTOR = 'cropIconSelector'
export const DOWNLOAD_ICON_SELECTOR = 'downloadIconSelector'
export const REPLACE_IMAGE_ICON_SELECTOR = 'replaceImageIconSelector'

export const ALIGN_LEFT_ICON_SELECTOR = 'alignLeftIconSelector'
export const ALIGN_CENTRE_ICON_SELECTOR = 'alignCentreIconSelector'
export const ALIGN_RIGHT_ICON_SELECTOR = 'alignRightIconSelector'
export const FLOAT_LEFT_ICON_SELECTOR = 'floatLeftIconSelector'
export const FLOAT_RIGHT_ICON_SELECTOR = 'floatRightIconSelector'

export const IMAGE_BLOCK_NO_TEXT_WARNING =
  'There is no text currently inside this image block or the content is default.'
export const IMAGE_BLOCK_TEXT_IS_DEFAULT_WARNING = 'The text is default. You can change it.'
export const IMAGE_BLOCK_HOW_TO_OPEN_TEXT_MODE = 'To open the text mode, use the align icon.'
export const DOUBLE_CLICK_TO_EDIT_TEXT = 'Double click onto the text to edit the text content.'
export const USUAL_DOWNLOAD_SUCCESS_TEXT = 'Image downloading started...'
export const SAFARI_DOWNLOAD_INFO =
  'Check the settings to allow downloads or see download folder...'

export const croppingNoPossibleWarningText = (minimumSize: number) =>
  `Image height and width should both be at least ${minimumSize} pixels to crop.`

export const imageDownloadFilename = (differentiator: number | Date) =>
  `everyday_image_${differentiator}_download`

export const INFORMATION_BLOCK_CONTAINS_TEXT =
  'This block can contain text, too. You can change the text content that is placed around an image by editing the paragraph. When you are done, confirm changes just by clicking outside the content or anywhere else. Changes are applied automatically, in real-time. To access the text around the image, click the wrapping option icon.'

export const IMAGE_DEFAULT_ASIDE_TEXT =
  'Everyday is not just an ordinary company. We are driven by our passion for creating exceptional experiences for our users. Our focus is on providing you with innovative applications that enhance your productivity, inspire your creativity, and simplify your daily tasks. Just like Singapore, a vibrant city-state that captivates with its impressive skyline and rich cultural diversity, We are a hub of possibilities. We believe in the power of good content and the impact it can have on your life. Our applications empower you to express yourself, manage your tasks efficiently, and share your ideas with the world. Just as Singapore seamlessly blends modernity and tradition, We also combine cutting-edge technology with user-friendly interfaces. We strive to create intuitive tools that make your digital experience seamless and enjoyable. Whether you are a professional writer, a busy entrepreneur, or a creative thinker, our applications cater to your unique needs and help you achieve your goals. As the diverse festivals celebrated in Singapore, We celebrate our individuality. We understand that every user is unique, with different preferences and styles. That is why our applications offer customizable features, allowing you to personalize your experience and make it truly yours. Similar to Singapore s reputation for cleanliness and orderliness, Our team is committed to providing you with a polished and flawless user experience. We meticulously design and refine our applications to ensure they meet the highest standards.'

export const IMAGE_REPOSITIONING_OPTIONS = new Map([
  ['1 1', orderingDataForImage.SOUTH_EAST],
  ['0 1', orderingDataForImage.SOUTH_WEST],
  ['1 0', orderingDataForImage.NORTH_EAST],
  ['0 0', orderingDataForImage.NORTH_WEST],
])

export const NEW_IMAGE_MARGIN = new Map([
  ['1 1', '0 0 0 1rem'],
  ['0 1', '0 1rem 0 0'],
  ['1 0', '0 0 0 1rem'],
  ['0 0', '0 1rem 0 0'],
])

export const NEW_INITIAL_IMAGE_MARGIN = new Map([
  [orderingDataForImage.SOUTH_EAST, '0 0 0 1rem'],
  [orderingDataForImage.SOUTH_WEST, '0 0 0 1rem'],
  [orderingDataForImage.NORTH_EAST, '0 0 0 1rem'],
  [orderingDataForImage.NORTH_WEST, '0 0 0 1rem'],
])

export const IFRAME_UPLOAD_INITIAL_WIDTH = '100%'
export const IFRAME_UPLOAD_INITIAL_HEIGHT = '400px'
export const BROWSER_NOT_SUPPORT_VIDEO_TEXT = 'Your browser does not support the videos.'
export const VIDEO_UPLOAD_DEFAULT_HEIGHT = '250'

export const defaultTextForUploadingFiles = (tag: string) => {
  const fileTypeDeterminer = tag === 'iframe' ? 'file' : tag === 'img' ? 'image' : tag
  return `No ${fileTypeDeterminer} selected. Click to select.`
}

export const setDefaultImageAlt = (block: block, hasAdditionalMark = false) =>
  /* Be cautious here, adding the easily changable marker on the alt or source can affect the feature unexpectedly and causing the constant changes and loadings, like image flickering. Use only if you have covered all the needed situations and after you convince yourself by testing that everything should work fine. */
  hasAdditionalMark ? `Image in editor: ${block.data.imageUrl}` : 'Image in editor'

export const widthResizeImageOptions = [
  {
    label: '35 % of the block width',
    value: 35,
  },
  {
    label: '50 % of the block width',
    value: 50,
  },
  {
    label: '75 % of the block width',
    value: 75,
  },
]
