import styled from 'styled-components'
import { motion } from 'framer-motion'

export const Placeholder = styled(motion.div)<{ width?: string }>`
  margin: 0 auto;
  height: 5px;
  width: ${(props) => (props.width ? props.width : '100%')};
  border-radius: 2px;
  background-color: var(--primary-color2);
`
