import { getSocket } from 'shared/shareDb'

export const options = {
  // Temporary fix for manager:get long wait for response
  connectionTimeout: 10_000,
  maxRetries: 3,
}

export const setSocket = () => {
  window.__MANAGER_SOCKET__ = getSocket('/manager', [], options)
}

export const handleDisconnect = () => {
  window.__MANAGER_SOCKET__?.close()
  delete window.__MANAGER_SOCKET__
}
