import React from 'react'
import { ListItem } from './styles'

interface Props {
  onClick?: () => void
  src?: string
  alt?: string
  padding?: string
  option?: string
  i?: React.Key | null | undefined
  isSelected?: boolean
  optionsInSelectPadding?: string
  fontWeightForOptions?: string
}

const CustomSelectOption = ({
  onClick,
  src,
  alt,
  padding,
  option,
  i,
  isSelected,
  optionsInSelectPadding,
  fontWeightForOptions,
}: Props) => {
  return (
    <ListItem
      onClick={onClick}
      key={i}
      padding={optionsInSelectPadding || '8px'}
      fontWeight={fontWeightForOptions}
    >
      {option}
    </ListItem>
  )
}

export default CustomSelectOption
