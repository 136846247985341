import React from 'react'
import { SideBarWidthButtonStyled } from './styles'
import { ReactComponent as VectorLeft } from 'assets/images/Vector-2.svg'
import { ReactComponent as VectorRight } from 'assets/images/Vector-5.svg'
import { Position } from 'interfaces/projectFile'

type Props = {
  onClick: () => void
  className: string
  position: Position
  isMinimized: boolean
}

const SidebarCollapseButton = (props: Props) => {
  return (
    <SideBarWidthButtonStyled
      isMinimized={props.isMinimized}
      position={props.position}
      className={props.className}
      onClick={props.onClick}
    >
      {props.position == Position.RIGHT ? (
        <>{props.isMinimized ? <VectorLeft /> : <VectorRight />}</>
      ) : (
        <>{props.isMinimized ? <VectorRight /> : <VectorLeft />}</>
      )}
    </SideBarWidthButtonStyled>
  )
}

export default SidebarCollapseButton
