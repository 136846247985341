import axios from 'axios'
import { envVars } from 'config/env'
import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Deserializer } from 'jsonapi-serializer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setCurrentSpace } from 'redux/reducers/spaceReducer'

export const useChangeSpaceLogoModal = (handleClose: () => void) => {
  const [imageScale, setImageScale] = useState(1)
  const [spaceLogo, setSpaceLogo] = useState<File>()
  // eslint-disable-next-line
  const editor: any = useRef()
  const dispatch = useAppDispatch()
  const space = useAppSelector((state) => state.space.currentSpace)

  const onDrop = useCallback((files: File[]) => {
    const reader = new FileReader()
    files.forEach((file) => {
      reader.onload = () => {
        setSpaceLogo(file)
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const data = await setCroppedImage()

    const config = {
      method: 'post',
      url: `${envVars.api.https}api/spaces/${space ? space.id : null}/logo`,
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    }

    axios(config).then(async (res) => {
      handleClose()
      setSpaceLogo(undefined)
      try {
        const data = new Deserializer({
          keyForAttribute: 'camelCase',
          pluralizeType: false,
        })
          .deserialize(res.data)
          .then((data) => {
            dispatch(setCurrentSpace(data))
          })
        return data
      } catch (e: any) {
        return null
      }
    })
  }
  const setCroppedImage = async (): Promise<FormData | undefined> => {
    if (editor) {
      const dataUrl = editor.current.getImage().toDataURL()
      const result = await fetch(dataUrl)
      const blob = await result.blob()

      const file = new File([blob], 'ProfilePhoto.png', {
        type: 'image/png',
        lastModified: new Date().getTime(),
      })
      if (file) {
        setSpaceLogo(file)
      }
      const data = new FormData()
      data.append('logoFile', file as Blob, 'logoFile.png')

      return data
    }
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  return {
    imageScale,
    setImageScale,
    onSubmit,
    getRootProps,
    getInputProps,
    setSpaceLogo,
    spaceLogo,
    editor,
  }
}
