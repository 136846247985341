import React from 'react'

import styled from 'styled-components'
import { StyledHeaderComponentCss } from './styles'

const HeaderComponent = styled.div`
  ${StyledHeaderComponentCss}
`

type Props = {
  children?: React.ReactNode
}

const Header: React.FC<Props> = ({ children }) => {
  return <HeaderComponent>{children}</HeaderComponent>
}

export default Header
