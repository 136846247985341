import { useAppSelector } from 'redux/hooks'

export const useMember = () => {
  const spaceMembers = useAppSelector((state) => state.space.currentSpace)?.spaceMembers || []

  return spaceMembers.length
    ? spaceMembers.map((member) => {
        return {
          id: member.user.uuid,
          label: `${member.user.firstName} ${member.user.lastName}`,
          value: member.user.uuid,
          photo: member.user.photo,
        }
      })
    : []
}
