import React, { MouseEventHandler } from 'react'
import { StyledButton } from './styles'

type Props = {
  borderradius?: string
  className?: string
  type?: 'button' | 'submit' | 'reset'
  isDisabled?: boolean
  onClickFunction?: (e?: any) => void
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>
  background?: string
  border?: string
  position?: string
  left?: string
  right?: string
  textAling?: string
  width?: string
  height?: string
  margin?: string
  padding?: string
  children?: React.ReactNode
  fontSize?: string
  fontWeight?: string
  color?: string
  opacity?: string
}

const CustomButton: React.FC<Props> = ({
  borderradius,
  className,
  type,
  onClickFunction,
  onMouseEnter,
  onMouseLeave,
  isDisabled,
  background,
  border,
  position,
  left,
  right,
  textAling,
  width,
  height,
  margin,
  padding,
  children,
  fontSize,
  fontWeight,
  color,
  opacity,
}) => {
  return (
    <>
      <StyledButton
        borderradius={borderradius}
        className={className}
        type={type}
        onClick={onClickFunction}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        disabled={isDisabled}
        background={background}
        border={border}
        position={position}
        left={left}
        right={right}
        width={width}
        textAling={textAling}
        margin={margin}
        padding={padding}
        height={height}
        fontSize={fontSize}
        fontWeight={fontWeight}
        color={color}
        opacity={opacity}
      >
        {children}
      </StyledButton>
    </>
  )
}

CustomButton.defaultProps = {
  borderradius: '4px',
  border: 'red',
  position: '',
  textAling: 'center',
  width: '100%',
  margin: '0',
  height: '25px',
}

export default CustomButton
