import { ITask } from 'interfaces/taskManager'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { type block } from '_entities/block'
import { useEffect, useState } from 'react'
import {
  getSelectedTask,
  setIsSliderOpen,
  setIsSliderTaskOpen,
  setSelectedTaskDocument,
  setSelectedTaskId,
} from 'redux/reducers/taskManagerReducer'
import { AxiosService } from 'services/axiosService/axiosService'
import { useParams } from 'react-router-dom'
import { useEditorFocus } from '_features/editor'
import { useCreateTask } from '_features/task'

interface Props {
  block: block
}

const useBlockTasks = (props: Props) => {
  const { block } = props
  const [blockTasks, setBlockTasks] = useState<ITask[]>([])
  const _createTask = useCreateTask()
  const _editorFocus = useEditorFocus()
  const dispatch = useAppDispatch()
  const tasks = useAppSelector((state) => state.taskManager.tasks)
  const selectedTask = useAppSelector(getSelectedTask)
  const selectedSpace = useAppSelector((state) => state.space.currentSpace)
  const spaceId = selectedSpace?.id
  const AxiosInstance = new AxiosService()
  const params = useParams()
  const projectId = params.id

  useEffect(() => {
    fetchBlockTasks()
  }, [tasks, block])

  const fetchBlockTasks = async () => {
    const filteredTask: ITask[] = []

    tasks?.map((task) => {
      const taskReferences = task?.references

      taskReferences?.map((reference) => {
        if (reference?.blockId === block?._id) {
          filteredTask.push(task)
        }
      })
    })

    setBlockTasks(filteredTask || [])
  }

  const handleCreateTask = (block: block) => {
    _createTask.createReferencedTask(block)
  }

  const onTaskSelect = async (task: ITask) => {
    if (spaceId && projectId) {
      const response = await AxiosInstance.getPage(projectId, task?.id)
      if (response) {
        dispatch(setIsSliderOpen(true))
        dispatch(setIsSliderTaskOpen(true))
        dispatch(setSelectedTaskId(task.id))
        dispatch(setSelectedTaskDocument(task))
        setTimeout(() => {
          _editorFocus.focusFirstEmptyParagraph(task.id)
          const taskElement = document.getElementById(`task-${task.id}`) as HTMLDivElement | null

          if (taskElement) {
            const taskElementOffsetTop = taskElement.offsetTop

            const taskManagerDrawerElement = document.querySelector('.task-drawer')

            if (taskManagerDrawerElement) {
              taskManagerDrawerElement.scrollTo({
                top: taskElementOffsetTop + 100,
              })
            }
          }
        }, 500)
      }
    }
  }

  return {
    blockTasks,
    handleCreateTask,
    selectedTask,
    onTaskSelect,
  }
}

export default useBlockTasks
