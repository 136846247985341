import { AxiosService } from 'services/axiosService/axiosService'

const useTags = () => {
  const AxiosInstance = new AxiosService()

  const getTags = (projectId: string) => {
    return AxiosInstance.getTags(projectId)
  }

  const getTag = (projectId: string, tagId: string) => {
    return AxiosInstance.getTag(projectId, tagId)
  }

  const createTag = (projectId: string, name: string) => {
    return AxiosInstance.createTag(name, projectId)
  }

  const updateTag = (projectId: string, tagId: string, name: string) => {
    return AxiosInstance.updateTag(projectId, tagId, name)
  }

  const deleteTag = (projectId: string, tagId: string) => {
    return AxiosInstance.deleteTag(projectId, tagId)
  }

  return {
    getTags,
    getTag,
    createTag,
    updateTag,
    deleteTag,
  }
}

export default useTags
