import { useAppSelector } from 'redux/hooks'
import { useSearchParams } from 'react-router-dom'
import { useState } from 'react'

export const usePageInviteModal = () => {
  const [searchParams] = useSearchParams()

  const selectedPageId = searchParams.get('pageId')
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const pageMembers = useAppSelector((state) => state.page.members)
  const [userInvited, setUserInvited] = useState(false)

  const currentProjectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const handleUserInvited = () => {
    setUserInvited(true)
  }

  const handleUserNotInvited = () => {
    setUserInvited(false)
  }

  return {
    selectedPage,
    selectedPageId,
    currentProjectFile,
    pageMembers,
    userInvited,
    handleUserInvited,
    handleUserNotInvited,
  }
}
