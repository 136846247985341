import { MouseEventHandler } from 'react'

export interface UseDoubleClickProps {
  onSingleClick?: MouseEventHandler // Event handler for single click
  onDoubleClick?: MouseEventHandler // Event handler for double click
  delta?: number // Time interval between clicks
}

const useDoubleClick = (props: UseDoubleClickProps) => {
  const { onSingleClick, onDoubleClick, delta = 300 } = props

  let timeoutInstance: number | undefined

  const onClick: MouseEventHandler = (event) => {
    if (timeoutInstance === undefined) {
      // First click
      timeoutInstance = window.setTimeout(() => {
        timeoutInstance = undefined
        onSingleClick?.(event) // Trigger the single click event handler
      }, delta)
    } else {
      // Second click
      clearInterval(timeoutInstance) // Clear the timeout for the first click
      timeoutInstance = undefined
      onDoubleClick?.(event) // Trigger the double click event handler
    }
  }

  return onClick
}

export default useDoubleClick
