import styled from 'styled-components'
import { QuillStyles } from 'editor/EditableBlock/EditorTextBlock/styles'
import { ReactComponent as Mirror } from 'assets/images/mirror-icon.svg'
import { ReactComponent as Instance } from 'assets/images/instance.svg'
import { DEFAULT_LINE_HEIGHT, DEFAULT_SIZE } from '_entities/text'

export const StyledMirrorIcon = styled(Mirror)`
  cursor: pointer;

  path {
    stroke: var(--secondary-text);
  }
`

export const StyledInstanceIcon = styled(Instance)`
  cursor: pointer;

  path {
    stroke: var(--secondary-text);
  }
`

export const MainWrapper = styled.div<{ background?: string; alignItems?: string }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: ${(props) => props.alignItems || 'center'};
  justify-content: center;
  gap: 4px;
  outline: none;
  position: relative;
  background: ${(props) => props.background};
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
`

export const TextPositioningWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const QuillWrapper = styled.div<{
  width?: string
  height?: string
  maxWidth?: string
  qlEditorWidth?: number
  qlEditorHeight?: number
}>`
  width: ${(props) => (props.width ? `${props.width}` : '100%')};
  height: ${(props) => (props.height ? `${props.height}` : '100%')};
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}` : '100%')};
  display: flex;
  justify-content: center;
  align-items: center;
  ${QuillStyles};

  & .ql-editor {
    vertical-align: top;
    font-size: ${DEFAULT_SIZE}px;
    width: ${(props) => (props.qlEditorWidth ? `${props.qlEditorWidth}px` : '100%')};
    height: ${(props) => (props.qlEditorHeight ? `${props.qlEditorHeight}px` : '100%')};
    display: flex;
    justify-content: center;
    flex-direction: column;
    line-height: ${DEFAULT_LINE_HEIGHT};
    overflow: visible;
  }

  & .ql-blank:focus::before {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`

export const StyledMirrorIndicator = styled.div`
  @media screen and (max-width: 900px) {
    display: none !important;
  }
`

export const StyledInstanceIndicator = styled.div`
  @media screen and (max-width: 900px) {
    display: none !important;
  }

  z-index: 100;
`
