import { useEffect, useRef, useState } from 'react'
import { useOnClickOutside } from 'usehooks-ts'
import { SlashMenuOptions, ExtendedIPages } from './types'

interface Props {
  isCentered?: boolean
  open?: boolean
  options: (SlashMenuOptions | ExtendedIPages)[]
  onChange: ((newValue: unknown) => void) | undefined
  onClickOutside?: () => void
  isFocused?: boolean
  setFocused?: (value: React.SetStateAction<boolean>) => void
}

export const useMenuComponent = (props: Props) => {
  const ref = useRef<HTMLDivElement>(null)
  const focusedRef = useRef<number>(-1)
  const [focused, setFocused] = useState<number>(-1)

  useOnClickOutside(ref, () => onClickOutside())

  const onClickOutside = () => {
    props.onClickOutside && props.onClickOutside()
    ref.current?.blur()
    setFocused(-1)
    props.setFocused && props.setFocused(false)
  }

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        e.preventDefault()
        e.stopPropagation()
        props.onChange && props.onChange(props.options[focusedRef.current])
      } else if (e.key === 'ArrowDown') {
        e.preventDefault()
        setFocused((prevState) => {
          if (prevState === props.options.length - 1) return prevState
          return prevState + 1
        })
      } else if (e.key === 'ArrowUp') {
        e.preventDefault()
        setFocused((prevState) => {
          if (prevState === 0) return prevState
          return prevState - 1
        })
      }
    }

    if (props.isFocused) {
      setFocused(0)
      ref.current?.focus()
      ref.current?.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      ref.current?.removeEventListener('keydown', handleKeyDown)
    }
  }, [props.isFocused, props.options])

  useEffect(() => {
    focusedRef.current = focused
  }, [focused])

  return {
    ref,
    focused,
  }
}
