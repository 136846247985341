import styled from 'styled-components'

export const StyledTreeWrapper = styled.ul<{ flexContainer?: boolean }>`
  height: 100%;
  position: relative;
  display: block;
  justify-content: center;
  flex-direction: column;
`

export const ItemWrapper = styled.div``

export const DropTargetWrapper = styled.li<{ isShown?: boolean; flexContainer?: boolean }>`
  position: relative;
  display: ${(props) => (props.isShown ? 'block' : 'none')};
  align-self: center;
`

export const PlaceholderWrapper = styled.div<{ visible?: boolean; isLast?: boolean }>`
  position: ${(props) => (!props.isLast ? 'absolute' : 'relative')};
  opacity: ${(props) => (props.visible ? 1 : 0)};
  height: 4px;
  width: 100%;
  background: var(--everyday-blue);
  border-radius: 10px;
  cursor: pointer;
  z-index: 1;
  top: -2px;
`

export const DragPreview = styled.div`
  position: absolute;
  top: -1000px;
  opacity: 1;
  z-index: -99;
  cursor: grabbing;
  background-color: var(--white);
  padding: 8px 8px 8px 24px;
`
