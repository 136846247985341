import Dropdown from 'components/molecules/Dropdown/StyledDropdown'
import styled from 'styled-components'

export { ReactComponent as CaretDownIcon } from 'assets/images/CaretDown.svg'

export const ClassicDropdown = styled(Dropdown)`
  .dropdown-toggle {
    background: none;
    border: none;
    padding: 0;
    color: var(--everyday-blue);
  }

  .dropdown-menu {
    padding: 0;
    border: none;
    min-width: fit-content;
    // TODO: Why was this visible?
    overflow: hidden !important;
    position: absolute !important;
    background: var(--overlay-modal);

    & .dropdown-item {
      color: var(--primary-text) !important;

      &:hover {
        * {
          background-color: var(--hover);
        }
      }
    }
  }
`
