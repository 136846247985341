import { useEffect, useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  setColorPalettes,
  setErrorShown,
  setSelectedColorPalette,
  setSettings,
} from 'redux/reducers/globalReducer'
import ForgotPassword from './views/Authentication/ForgotPassword'
import {
  Route,
  Routes,
  BrowserRouter as ReactRouter,
  MemoryRouter as ElectronRouter,
} from 'react-router-dom'
import InitialPhotoUpload from './views/InitialSetup/InitialPhotoUpload'
import Signup from './views/Authentication/Signup'
import VerifyEmail from './views/Authentication/VerifyEmail'
import EmailVerified from './views/Authentication/EmailVerified'
import PageNotFound from './components/templates/Errors/PageNotFound'
import BadGateway from './components/templates/Errors/BadGateway'
import InternalServerError from './components/templates/Errors/InternalServerError'
import ServiceUnavailable from './components/templates/Errors/ServiceUnavailable'
import Forbidden from './components/templates/Errors/Forbidden'
import ResetPassword from './views/Authentication/ResetPassword'
import SignIn from './views/Authentication/SignIn'
import SetupWork from './views/InitialSetup/SetupWork'
import ComingSoon from './components/templates/Errors/ComingSoon'
import Maintenance from './components/templates/Errors/Maintenance'
import PasswordCreated from './views/Authentication/PasswordCreated'
import ProjectFile from './views/ProjectFile/ProjectFile'
import Space from './views/Space/Space'
import ProtectedRoute from './components/atoms/ProtectedRoute/ProtectedRoute'
import InvitationScreen from 'components/templates/Invitation/Invitation'
import EmailVerificationScreen from 'components/templates/EmailVerificationScreen/EmailVerificationScreen'
import { AxiosService } from 'services/axiosService/axiosService'
import { envVars } from 'config/env'
import ReactGA4 from 'react-ga4'
import { hotjar } from 'react-hotjar'
import ChangesConfirmedInformationScreen from 'views/Authentication/ChangesConfirmedInformationScreen'
import InvitationCheck from 'views/InvitationCheck/InvitationCheck'
import useColorPalette from 'utils/useColorPalette'
import { IRoles } from 'interfaces/invite'
import { SettingsKey } from 'interfaces/settings'
import useSettings from '_entities/profile/model/useSettings'
import { toast, ToastContainer } from 'react-toastify'
import { isElectron } from 'shared/lib'
import { useViewer } from '_entities/viewer'
import FullWidthLogo from 'components/templates/FullScreenLoader/FullScreenLoader'
import { LandingPage, isLandingPage } from '_pages/LandingPage'
import { PrivacyPolicy } from 'views/PrivacyPolicy/PrivacyPolicy'

const googleAnalyticsId = envVars.googleAnalytics.id
const hotjarId = envVars.hotjar.id
const hotjarSv = envVars.hotjar.sv
if (googleAnalyticsId) ReactGA4.initialize(googleAnalyticsId)
if (hotjarId && hotjarSv) hotjar.initialize(parseInt(hotjarId), parseInt(hotjarSv))

function App() {
  const [isUserCheckDone, setIsUserCheckDone] = useState<boolean>(false)
  const AxiosInstance = new AxiosService()
  const dispatch = useAppDispatch()
  const _settings = useSettings()
  const _colorPalette = useColorPalette()
  const _viewer = useViewer()

  const user = useAppSelector((state) => state.global.user)
  const colorPalette = useAppSelector((state) => state.global.colorPalette)
  const selectedColorPalette = useAppSelector((state) => state.global.selectedColorPalette)
  const areErrorsShown = useAppSelector((state) => state.global.areErrorsShown)

  useEffect(() => {
    if (user && user.palette) {
      dispatch(setSelectedColorPalette(user.palette))
      _colorPalette.handleSetPaletteAttributes(user.palette)
    } else {
      const fetchDefaultPalettes = async () => {
        const defaultPalettes = await AxiosInstance.getDefaultColorPalettes()
        if (defaultPalettes) {
          _colorPalette.handleSetPaletteAttributes(defaultPalettes[0])
        }
      }
      fetchDefaultPalettes()
    }
  }, [user])

  useEffect(() => {
    if (areErrorsShown) {
      // Clear any queued toasts if errors are now shown
      toast.dismiss()
    }
  }, [areErrorsShown])

  useEffect(() => {
    handleFetchErrorDisplay()
  }, [user, areErrorsShown])

  useEffect(() => {
    if (
      colorPalette &&
      selectedColorPalette &&
      parseInt(selectedColorPalette?.id) === parseInt(colorPalette?.id)
    ) {
      _colorPalette.handleSetPalette(colorPalette)
    }
  }, [colorPalette, selectedColorPalette])

  const handleFetchErrorDisplay = async () => {
    const response = await AxiosInstance.getAllSettings()
    dispatch(setSettings(response))
    if (response) {
      const errorReportingSetting = _settings.getSettingByKey(response, SettingsKey.ERROR_REPORTING)

      if (errorReportingSetting?.value === 'all') {
        dispatch(setErrorShown(true))
      } else {
        dispatch(setErrorShown(false))
      }
    }
  }

  const fetchDefaultPalettes = async () => {
    const userPalettes = (await AxiosInstance.getDefaultColorPalettes()).data

    if (userPalettes) {
      dispatch(setColorPalettes([...userPalettes]))

      return userPalettes
    }

    return []
  }

  const fetchPalettes = async () => {
    const userPalettes = user && (await AxiosInstance.getUserColorPalette(user.id)).data

    if (userPalettes) {
      dispatch(setColorPalettes([...userPalettes]))

      return userPalettes
    }

    return []
  }

  const performUserCheck = async () => {
    await _viewer.getAndSaveViewer()
    setIsUserCheckDone(true)
  }

  useEffect(() => {
    const proxyUrl = envVars.api.proxy
    if (proxyUrl) fetch(proxyUrl)
    ReactGA4.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: window.location.pathname,
    })
    performUserCheck()
  }, [])

  useEffect(() => {
    if (user) {
      if (user?.role?.name === IRoles.SUPER_ADMIN) {
        fetchDefaultPalettes()
      } else {
        fetchPalettes()
      }
    }
  }, [user])

  const getProperRouter = (children: React.ReactNode) => {
    if (isElectron()) {
      return <ElectronRouter>{children}</ElectronRouter>
    } else return <ReactRouter>{children}</ReactRouter>
  }

  const getRootPage = () => {
    if (isLandingPage()) {
      return <LandingPage />
    } else return <Signup />
  }
  const properRouter = getProperRouter(
    <Routes>
      <Route index element={getRootPage()} />
      <Route path='/signin' element={<SignIn />} />
      <Route path='/verifyemail' element={<VerifyEmail />} />
      <Route path='/emailverified' element={<EmailVerified />} />
      <Route path='*' element={<PageNotFound />} />
      <Route path='/b' element={<BadGateway />} />
      <Route path='/i' element={<InternalServerError />} />
      <Route path='/s' element={<ServiceUnavailable />} />
      <Route path='/f' element={<Forbidden />} />
      <Route path='/comingsoon' element={<ComingSoon />} />
      <Route path='/maintenance' element={<Maintenance />} />
      <Route path='/forgotpassword' element={<ForgotPassword />} />
      <Route path='/resetpassword' element={<ResetPassword />} />
      <Route path='/setupwork' element={<SetupWork />} />
      <Route path='/createpassword' element={<ResetPassword />} />
      <Route path='/passwordcreated' element={<PasswordCreated />} />
      <Route path='/all-done' element={<ChangesConfirmedInformationScreen />} />
      <Route
        path='/initialphotoupload'
        element={
          <ProtectedRoute>
            <InitialPhotoUpload />
          </ProtectedRoute>
        }
      />
      <Route
        path='/spaces/:spaceId/project-file/:id'
        element={
          <ProtectedRoute>
            <ProjectFile />
          </ProtectedRoute>
        }
      />
      <Route
        path='/space/:id'
        element={
          <ProtectedRoute>
            <Space />
          </ProtectedRoute>
        }
      />
      <Route path='/invitation' element={<InvitationScreen />} />
      <Route path='/email-verification' element={<EmailVerificationScreen />} />
      <Route path='/invitation-check' element={<InvitationCheck />} />
      <Route path='/privacy-policy' element={<PrivacyPolicy />} />
    </Routes>,
  )

  return (
    <div>
      {!isUserCheckDone && <FullWidthLogo />}
      {isUserCheckDone && properRouter}
      {areErrorsShown && (
        <ToastContainer
          newestOnTop
          position='bottom-center'
          autoClose={1000}
          hideProgressBar
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          closeButton={false}
          theme='light'
        />
      )}
    </div>
  )
}

export default App
