import Avatar from 'components/atoms/Avatar/Avatar'
import React from 'react'
import { Form } from 'react-bootstrap'
import ChangeUserProfileModal from '../ChangeUserProfileModal/ChangeUserProfileModal'
import SelectOption from '../SelectOption/SelectOption'
import {
  AvatarActionsContainer,
  AvatarContainer,
  FlexColumnContainer,
  FlexRowContainerForInfo,
  InfoText,
  InputFlexRowContainer,
  ModalEditButton,
  ModalInfoLabel,
  ModalSaveButton,
  MyProfileTabUserModalStyled,
  RemovePhotoButton,
  Styles,
  UploadPhotoButton,
  UserNameText,
  InputControl,
  SelectControl,
  StyledFormGroup,
} from './styles'
import { useMyProfileTabUserModal } from './useMyProfileTabUserModal'
import AvatarPlaceholder from 'assets/images/avatar-placeholder.png'
import { envVars } from 'config/env'
import { useAppSelector } from 'redux/hooks'

type Props = {
  userImage: string | undefined
  setUserImage: React.Dispatch<React.SetStateAction<string | undefined>>
}

interface IAgeArrayItem {
  label: string
  value: string
  id: number
}

const MyProfileTabUserModal: React.FC<Props> = ({ userImage, setUserImage }) => {
  const {
    uploadPhotoModalOpen,
    setUploadPhotoModalOpen,
    editMenuOpen,
    setEditMenuOpen,
    genders,
    updatedUser,
    setGenderId,
    age,
    setAge,
    setLocation,
    aboutMe,
    setAboutMe,
    ageArray,
    updateUserInfoAsync,
    onShowChangeModal,
    onCloseChangePasswordModal,
    updatedUserName,
    onChangeAboutMeArea,
    onSaveUpdatedUser,
    removeUserImage,
    onImageError,
  } = useMyProfileTabUserModal()
  const user = useAppSelector((state) => state.global.user)
  return (
    <MyProfileTabUserModalStyled>
      <UserNameText>{updatedUserName}</UserNameText>
      <AvatarContainer>
        <Avatar
          src={
            updatedUser.photo ? `${envVars.api.s3Butcket}${updatedUser.photo}` : AvatarPlaceholder
          }
          userName={updatedUserName}
          fontSize='35px'
          userColor={user?.userColor}
          width='100px'
          height='100px'
          onError={onImageError}
        />
      </AvatarContainer>
      <ChangeUserProfileModal
        setUserImage={setUserImage}
        show={uploadPhotoModalOpen}
        handleClose={onCloseChangePasswordModal}
        onShow={onShowChangeModal}
      />
      <AvatarActionsContainer>
        <UploadPhotoButton onClick={() => setUploadPhotoModalOpen(true)}>
          Upload photo
        </UploadPhotoButton>

        {userImage && <RemovePhotoButton onClick={removeUserImage}>Remove</RemovePhotoButton>}
      </AvatarActionsContainer>
      {!editMenuOpen && (
        <>
          {updatedUser.aboutMe && (
            <FlexColumnContainer className='my-2'>
              <ModalInfoLabel>About me</ModalInfoLabel>
              <InfoText>{updatedUser.aboutMe}</InfoText>
            </FlexColumnContainer>
          )}
          <FlexRowContainerForInfo>
            {updatedUser.gender && (
              <FlexColumnContainer>
                <ModalInfoLabel>Gender</ModalInfoLabel>
                <InfoText>{updatedUser.gender.name}</InfoText>
              </FlexColumnContainer>
            )}
            {updatedUser.age !== undefined && updatedUser.age !== null && updatedUser.age !== 0 && (
              <FlexColumnContainer>
                <ModalInfoLabel>Age</ModalInfoLabel>
                <InfoText>{updatedUser.age}</InfoText>
              </FlexColumnContainer>
            )}
            {updatedUser.location && (
              <FlexColumnContainer>
                <ModalInfoLabel>Location</ModalInfoLabel>
                <InfoText>{updatedUser.location}</InfoText>
              </FlexColumnContainer>
            )}
          </FlexRowContainerForInfo>
          <ModalEditButton onClick={() => setEditMenuOpen(true)}>Edit Profile</ModalEditButton>
        </>
      )}
      {editMenuOpen && (
        <Form>
          <Form.Group className='my-3'>
            <ModalInfoLabel>About me</ModalInfoLabel>

            <InputControl
              as='textarea'
              onChange={(e) => onChangeAboutMeArea(e)}
              rows={4}
              maxLength={450}
              placeholder='About me (max 450 characters)'
              defaultValue={updatedUser.aboutMe ? updatedUser.aboutMe : undefined}
            />
          </Form.Group>
          <InputFlexRowContainer>
            <StyledFormGroup className='mb-3'>
              <ModalInfoLabel>Gender</ModalInfoLabel>
              <SelectControl
                placeholder='Gender'
                defaultValue={updatedUser.gender ? updatedUser.gender.id : -1}
                onChange={(e: React.ChangeEvent) =>
                  setGenderId(Number((e.currentTarget as HTMLSelectElement).value))
                }
              >
                <SelectOption option={'Gender'} optionValue={-1} disabled={true} />
                {genders &&
                  genders.map((item: any) => {
                    return (
                      <SelectOption
                        option={item.name}
                        key={item.id}
                        optionValue={item.id}
                        disabled={false}
                      />
                    )
                  })}
              </SelectControl>
            </StyledFormGroup>
            <StyledFormGroup className='mb-3'>
              <ModalInfoLabel>Age</ModalInfoLabel>

              <SelectControl
                styles={Styles()}
                defaultValue={
                  updatedUser
                    ? {
                        label: String(updatedUser.age),
                        value: String(updatedUser.age),
                      }
                    : -1
                }
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                  setAge(Number(e.currentTarget.value))
                }
                placeholder={'Age'}
              >
                <SelectOption option={'Age'} optionValue={-1} disabled={true} />
                {ageArray &&
                  ageArray
                    .map((item: number) => {
                      return { label: String(item), value: String(item), id: item }
                    })
                    .map((item: IAgeArrayItem) => {
                      return (
                        <SelectOption
                          option={item.label}
                          key={item.id}
                          optionValue={item.id}
                          disabled={false}
                        />
                      )
                    })}
              </SelectControl>
            </StyledFormGroup>
            <StyledFormGroup className='mb-3'>
              <ModalInfoLabel>Location</ModalInfoLabel>
              <InputControl
                type='text'
                placeholder='Location'
                onChange={(e: React.ChangeEvent) =>
                  setLocation((e.currentTarget as HTMLInputElement).value)
                }
                defaultValue={updatedUser.location ? updatedUser.location : undefined}
              />
            </StyledFormGroup>
          </InputFlexRowContainer>
          <ModalSaveButton onClick={async (e) => await onSaveUpdatedUser(e)}>Save</ModalSaveButton>
        </Form>
      )}
    </MyProfileTabUserModalStyled>
  )
}

export default MyProfileTabUserModal
