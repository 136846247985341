import { DetachIcon, StyledDropdown, StyledInstanceIndicator, StyledMirrorIcon } from '../styles'
import RenderBlockMirrors from '../RenderBlockMirrors'
import { IPages } from 'interfaces/page'
import { type block } from '_entities/block'
import OriginalMirror from './OriginalMirror'
import { DropdownMenuItem } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import Divider from 'components/atoms/Divider/Divider'
import { useRelationships, RelationshipType } from '_features/relationship'

interface IProps {
  hasOriginalObject: boolean
  block: block
  onPageClick: (pageId: string, blockId: string) => void
  pages: IPages[] | undefined
}

const MirrorIndicator = ({ hasOriginalObject, block, onPageClick, pages }: IProps) => {
  const {
    handleDetachAllFromOriginal,
    handleDetachFromOriginal,
    handleDetachFromRelationship,
    handleSwitchRelationshipType,
  } = useRelationships({ block })

  return (
    <>
      {hasOriginalObject ? (
        <OriginalMirror
          block={block}
          onPageClick={onPageClick}
          pages={pages}
          handleDetachFromRelationship={handleDetachFromRelationship}
          handleSwitchRelationshipType={handleSwitchRelationshipType}
        />
      ) : (
        <StyledDropdown
          trigger={
            <StyledInstanceIndicator>
              <StyledMirrorIcon isScaled />
            </StyledInstanceIndicator>
          }
        >
          <RenderBlockMirrors
            currentBlock={block}
            pages={pages}
            handleDetachFromOriginal={handleDetachFromOriginal}
          />
          <Divider margin={'0px'} width={'100%'} />
          <DropdownMenuItem
            onClick={(e) => handleDetachAllFromOriginal(e, block, RelationshipType.MIRROR)}
          >
            <DetachIcon />
            Detach all
          </DropdownMenuItem>
        </StyledDropdown>
      )}
    </>
  )
}

export default MirrorIndicator
