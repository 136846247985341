import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledDropdown = styled(Dropdown)<{
  position: string
  width: string
  top: string
  right: string
  cursor: string
  transform: string
  minwidth: string
  margin: string
  padding: string
  transition: string
  menuTransform: string
  menuMinwidth: string
  menuMargin: string
  menuPadding: string
  menuPosition: string
  menuTransition: string
  flexShrink?: string
  iconWidth?: string
  isMenuHidden?: boolean
  backgroundColor?: string
  color?: string
}>`
  ${(props) => props.styles};
  position: ${(props) => props.position && props.position};
  top: ${(props) => props.top && props.top};
  right: ${(props) => props.right && props.right};
  transform: ${(props) => props.transform};
  padding: ${(props) => props.padding};
  cursor: ${(props) => props.cursor};
  flex-shrink: ${(props) => props.flexShrink};

  & > .dropdown-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  & .dropdown-menu {
    min-width: ${(props) => props.menuMinwidth && props.menuMinwidth} !important;
    position: ${(props) => props.menuPosition};
    transition: ${(props) => props.menuTransition};
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    transform: ${(props) => props.menuTransform} !important;
    margin: ${(props) => props.menuMargin};
    padding: ${(props) => props.menuPadding};
    border: 0;
    border-radius: 10px;
    opacity: ${(props) => (props.isMenuHidden ? '0' : '1')};
    pointer-events: ${(props) => (props.isMenuHidden ? 'none' : 'auto')};
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : 'var(--overlay-modal)'};

    input {
      color: var(--primary-text) !important;
    }

    * {
      color: ${(props) => (props.color ? props.color : 'var(--primary-text)')} !important;
    }

    & img {
      width: ${(props) => props.iconWidth || ''};
    }
  }
`
