import React from 'react'
import AuthInput from 'components/atoms/AuthInput/AuthInput'
import Image from 'components/atoms/Image/Image'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import { Link } from 'react-router-dom'
import loadingSpinner from 'assets/images/loadingSpinner.gif'
import CustomP from 'components/atoms/CustomP/CustomP'
import { Form } from './styles'

import emailIcon from 'assets/images/emailIcon.svg'
import emailRed from 'assets/images/emailRed.svg'

type Props = {
  onSubmit: React.FormEventHandler<HTMLFormElement>
  validateEmail: () => boolean
  setEmail: React.Dispatch<React.SetStateAction<string>>
  error: string
  successMessage: string
  loading: boolean
}

const index: React.FC<Props> = ({
  onSubmit,
  validateEmail,
  setEmail,
  error,
  successMessage,
  loading,
}) => {
  return (
    <Form onSubmit={onSubmit} id='forgotPasswordForm'>
      {!successMessage && (
        <div className='mb-4 d-flex justify-content-center align-items-start flex-column'>
          <AuthInput
            type='email'
            placeholder='Email'
            id='email'
            isRequired={true}
            className={
              !validateEmail()
                ? 'form-control form-control-md is-invalid placeholderDarker w-100 forgotPasswordStyles'
                : 'form-control form-control-md placeholderDarker w-100 forgotPasswordStyles'
            }
            onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value)
            }}
            iconLeft={emailIcon}
            iconLeftError={emailRed}
            paddingLeft='2.5rem'
            inputInnerWidth='100%'
          />

          {!validateEmail() && (
            <CustomP className='text-left mb-0' color='var(--everyday-red) !important'>
              Please enter a valid email address.
            </CustomP>
          )}
        </div>
      )}
      {loading && (
        <span className='d-flex justify-content-center align-items-center'>
          <Image
            src={loadingSpinner}
            alt='loading spinner'
            className='loading-spinner mb-4'
            width='10%'
          />
        </span>
      )}
      {!successMessage && (
        <div className='mb-1 d-flex align-items-center justify-content-center'>
          <CustomButton
            className='btn w-75'
            type={validateEmail() ? 'submit' : 'button'}
            height='40px'
            borderradius='10px'
            background='var(--primary-color)'
            color='var(--white) !important'
            fontWeight='600'
          >
            <CustomP className='mb-0' fontSize='12pt' fontWeight='600'>
              Continue
            </CustomP>
          </CustomButton>
        </div>
      )}
      {error && (
        <span>
          <CustomP color='var(--everyday-red) !important'>{error}</CustomP>
        </span>
      )}
      {successMessage && (
        <span>
          <CustomP className='text-success '>{successMessage}</CustomP>
        </span>
      )}
      <p className='textBolder'>
        <Link to='/signin' className='text-primary'>
          Back to login
        </Link>
      </p>
    </Form>
  )
}

export default index
