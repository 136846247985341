import { AxiosService } from 'services/axiosService/axiosService'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ChangeEvent, useEffect, useState } from 'react'
import { useToast } from 'services/helpers/useToast'
import { setSelectedProjectFile } from 'redux/reducers/projectFileReducer'
import { useUpload } from '_features/upload'
import { extractNameFromLink, getById } from 'shared/lib'

export const useBackgroundImage = () => {
  // ** State
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [backgroundImage, setBackgroundImage] = useState('')
  const [backgroundColor, setBackgroundColor] = useState<string | undefined>()
  const [backgroundOpacity, setBackgroundOpacity] = useState<number | undefined>()

  // ** Hooks
  const axiosService = new AxiosService()
  const toast = useToast()
  const dispatch = useAppDispatch()
  const _upload = useUpload()

  // ** Redux state
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const space = useAppSelector((state) => state.space.currentSpace)
  const page = useAppSelector((state) => state.page.selectedPage)

  useEffect(() => {
    const backgroundImageSetting = projectFile?.backgroundImage

    if (!backgroundImageSetting) return

    const background = JSON.parse(backgroundImageSetting)

    const backgroundImage = background.backgroundImage

    if (backgroundImage) {
      setBackgroundImage(backgroundImage)
      setName(extractNameFromLink(backgroundImage))
      setBackgroundColor(undefined)
    }

    const backgroundColor = background.backgroundColor

    if (backgroundColor) {
      setBackgroundColor(backgroundColor)
      setBackgroundImage('')
      setName('')
    }
  }, [projectFile])

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    const files = e.target.files
    if (files && files[0].size > 5000000000) {
      toast.error('The maximum size per file is 5GB')
      return
    }
    const file = files?.[0]

    if (!file) return

    await _upload.handleFileUpload(file, async (name, _, link) => {
      const data = JSON.stringify({
        backgroundImage: link,
        backgroundColor: undefined,
      })

      const documentBackground = getById('document-background')

      const backgroundImage = JSON.parse(data).backgroundImage

      if (documentBackground) {
        documentBackground.style.background = `url(${backgroundImage})`
      }

      await updateProjectFile(data)
    })
    setLoading(false)
  }

  const updateProjectFile = async (data: string) => {
    if (!space || !projectFile) return
    const response = await axiosService.updateProjectFile({
      spaceId: space.id,
      projectFileId: projectFile.id.toString(),
      backgroundImage: data,
    })
    if (!response) return
    dispatch(setSelectedProjectFile({ ...projectFile, backgroundImage: data }))
  }

  const removeImage = async () => {
    const response =
      space &&
      projectFile &&
      (await axiosService.updateProjectFile({
        spaceId: space.id,
        projectFileId: projectFile.id.toString(),
        backgroundImage: '{}',
      }))
    if (!response) return
    dispatch(setSelectedProjectFile({ ...projectFile, backgroundImage: '{}' }))
    setName('')
    setBackgroundImage('')
  }

  return {
    handleFileUpload,
    loading,
    name,
    removeImage,
    projectFile,
    page,
    backgroundImage,
    updateProjectFile,
    backgroundColor,
    setBackgroundColor,
    backgroundOpacity,
    setBackgroundOpacity,
  }
}
