export const getFigmaFileNameFromUrl = (url: string) => {
  const numberOfCharsAfterDetectedPieceOfString = 28
  // 28 is the number of characters after the position of the string 'file/' in the URL (fixed value).

  const findStartIndex = url.indexOf('file/') + numberOfCharsAfterDetectedPieceOfString
  const findEndIndex = url.indexOf('?')

  /* =================================================================================================
      URL example as a reference: 
      https://www.figma.com/file/HhP3tc3FMcEMxHO9fJXLFT/Everyday---UI?type=design&node-id=33%3A55&mode=dev   
      ================================================================================================= */

  const unformattedFileName = url.substring(findStartIndex, findEndIndex)
  let finalWord = unformattedFileName.replace('---', ' ')

  const removeAllDashesCharacters = () => {
    finalWord = finalWord.replace('-', ' ')
    if (finalWord.includes('-')) removeAllDashesCharacters()
  }

  removeAllDashesCharacters()

  return finalWord
}
