import { type block } from '_entities/block'
import {
  ConversionButtonContainer,
  ConversionDropdownMenuBody,
  ConversionDropdownMenuContainer,
} from './styles'
import React from 'react'
import { useConversionMenu } from '_features/block'

interface Props {
  isWhiteboard?: boolean
  block: block
  setActiveToolbarOption: React.Dispatch<React.SetStateAction<string | null>>
}

export const ConversionMenu = (props: Props) => {
  const { setActiveToolbarOption } = props
  const _conversionMenu = useConversionMenu()
  return (
    <ConversionDropdownMenuContainer>
      <ConversionDropdownMenuBody bottomLine>
        {_conversionMenu.textOptions.map((item) => (
          <ConversionButtonContainer
            onClick={(e: Event) => {
              _conversionMenu.textConverter(props.block, item.value, item.tag, e)
              setActiveToolbarOption(null)
            }}
            key={`toolbar-conversion-${props.block._id}`}
          >
            {item.icon}
            {item.label}
          </ConversionButtonContainer>
        ))}
      </ConversionDropdownMenuBody>
      <ConversionDropdownMenuBody>
        {_conversionMenu.nonTextOptions.map((item) => (
          <ConversionButtonContainer
            onClick={(e: Event) => {
              _conversionMenu.blockTypeSetter(props.block, item.tag, e)
              setActiveToolbarOption(null)
            }}
            key={`toolbar-conversion-${props.block._id}`}
          >
            {item.icon}
            {item.label}
          </ConversionButtonContainer>
        ))}
      </ConversionDropdownMenuBody>
    </ConversionDropdownMenuContainer>
  )
}
