import styled from 'styled-components'

type RadioButtonStyledProps = {
  fontSize?: string
  fontWeight?: string
  marginBlock?: string
}

export const RadioButtonStyled = styled.div<RadioButtonStyledProps>`
  font-size: ${(props) => props.fontSize || ''};
  font-weight: ${(props) => props.fontWeight || ''};
  margin-block: ${(props) => props.marginBlock || ''};

  & .form-check-input {
    min-height: 16px !important;
    width: 16px !important;
    position: relative;
    top: -0.1rem;
  }

  label {
    color: var(--secondary-text) !important;
  }
`
