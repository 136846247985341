import React, { KeyboardEvent } from 'react'
import Form from 'react-bootstrap/Form'
import { StyledFormGroup, StyledLabel, StyledInput } from './styles'

type Props = {
  value?: string
  setValue?: (value: string) => void
  onChange?: React.ChangeEventHandler
  textColour?: string
  textFontWeight?: string
  label?: string
  labelFontSize?: string
  labelColor?: string
  labelMargin?: string
  labelweight?: string
  placeHolder?: string
  fontSize?: string
  type?: string
  borderradius?: string
  bordercolor?: string
  width?: string
  formheight?: string
  formmaxwidth?: string
  inputheight?: string
  className?: string
  inputClassName?: string
  inputmargin?: string
  onKeyDown?: (e: KeyboardEvent) => void
  formgroupwidth?: string
  placeholdercolor?: string
  fontweight?: string
  color?: string
  backgroundColor?: string
  disabled?: boolean
  maxwidth?: string
  linkexist?: boolean
  inputPadding?: string
}

const Input: React.FC<Props> = ({
  value,
  setValue,
  label,
  labelFontSize,
  labelColor,
  labelMargin,
  labelweight,
  placeHolder,
  fontSize,
  type,
  borderradius,
  bordercolor,
  width,
  formheight,
  formmaxwidth,
  inputheight,
  className,
  inputClassName,
  inputmargin,
  onKeyDown,
  formgroupwidth,
  placeholdercolor,
  fontweight,
  color,
  backgroundColor,
  disabled,
  maxwidth,
  linkexist,
  inputPadding,
  onChange,
}) => {
  return (
    <StyledFormGroup
      className={className}
      width={formgroupwidth}
      height={formheight}
      maxwidth={formmaxwidth}
    >
      {label !== undefined && (
        <StyledLabel
          fontSize={labelFontSize}
          labelColor={labelColor}
          labelMargin={labelMargin}
          className='mb-0'
          labelweight={labelweight}
        >
          {label}
        </StyledLabel>
      )}
      <StyledInput
        onKeyDown={onKeyDown}
        width={width}
        type={type}
        value={value}
        placeholder={placeHolder}
        borderradius={borderradius}
        bordercolor={bordercolor}
        fontSize={fontSize}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) return onChange(e)
          if (setValue) setValue(e.target.value)
        }}
        className={inputClassName}
        inputmargin={inputmargin}
        placeholdercolor={placeholdercolor}
        fontweight={fontweight}
        color={color}
        backgroundColor={backgroundColor}
        disabled={disabled}
        height={inputheight}
        maxwidth={maxwidth}
        linkexist={linkexist}
        inputPadding={inputPadding}
        onClick={(e: any) => e.preventDefault()}
      />
    </StyledFormGroup>
  )
}

Input.defaultProps = {
  type: 'Email',
  placeHolder: 'Placeholder',
  borderradius: '8px',
  width: 'auto',
  labelColor: '#61777C',
  labelFontSize: '13px',
  bordercolor: '#DDDDDD',
  fontSize: '14px',
}

export default Input
