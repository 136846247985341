import CustomP from 'components/atoms/CustomP/CustomP'
import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import styled from 'styled-components'

export const TagWrapper = styled.div`
  padding: 6px;
  border-radius: 10px;
  border: solid 1px #d6d6d6;
  font-size: 12px;
  height: 24px;
  display: flex;
  align-items: center;
  color: var(--primary-text);
  gap: 2px;
`

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const StyledButton = styled.button<{ width?: string }>`
  background: transparent;
  border: none;
  display: flex;
  align-items: flex-start;

  &:focus {
    outline: none;
  }
`

export const TagInput = styled.input`
  font-size: 14px;
  font-weight: 600;
  padding: 6px;
  border-radius: 10px;
  outline: none;
  border: 1px solid transparent;
  width: 100%;
  background-color: var(--sidebars);

  &:focus {
    border: 1px solid var(--everyday-blue);
    background-color: var(--white);
  }
`

export const StyledHashIcon = styled(icons.hash)`
  opacity: 1;
  pointer-events: all;
`

export const StyledCloseIcon = styled(icons.close)`
  opacity: 0;
  position: absolute;
  pointer-events: none;
`

export const StyledTrashIcon = styled(icons.trash)`
  path {
    stroke: var(--everyday-red) !important;
  }
`
export const StyledToggle = styled(IconButton)`
  position: absolute;
  right: 0;
  top: 0;
  opacity: 0;
`

export const StyledCustomP = styled(CustomP)<{ isBlockTag: boolean }>`
  position: relative;
  &:hover {
    ${StyledCloseIcon} {
      opacity: 1;
      pointer-events: all;
    }

    ${StyledHashIcon} {
      opacity: ${({ isBlockTag }) => (isBlockTag ? 0 : 1)};
      pointer-events: none;
    }

    ${StyledToggle} {
      opacity: 1;
      pointer-events: all;
    }
  }
`
