import { Channel as ChannelType } from 'stream-chat'

import { useGetStreamContext } from 'services/getstream/GetStreamContext'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useEffect, useState } from 'react'
import { useDefaultChannel } from './useDefaultChannel'
import { setSelectedChannel } from 'redux/reducers/chatReducer'

const useChat = () => {
  const { chatClient } = useGetStreamContext()
  const { DEFAULT_CHANNEL } = useDefaultChannel()
  const dispatch = useAppDispatch()

  const [channel, setChannel] = useState<ChannelType | undefined>()

  const projectFIle = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const user = useAppSelector((state) => state.global.user)

  useEffect(() => {
    // This function gets a channel
    const getChannel = async () => {
      if (chatClient && user) {
        const channel = (
          await chatClient.queryChannels({
            cid: DEFAULT_CHANNEL.cid,
          })
        )[0]

        setChannel(channel)
        dispatch(setSelectedChannel(DEFAULT_CHANNEL.cid))
      }
    }
    getChannel()
  }, [chatClient, projectFIle, user])

  return {
    chatClient,
    channel,
  }
}

export default useChat
