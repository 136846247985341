import { type block } from '_entities/block'
import { IMAGE_MAXIMUM_ZOOM_SIZE, IMAGE_MINIMUM_ZOOM_SIZE } from '_features/editor'

type disableCheckerOptionsType = {
  isZoomScaleDown: boolean
  block?: block
}

export const determineIsDisabledAndReplacement = (
  disableCheckerOptions: disableCheckerOptionsType,
) => {
  const { isZoomScaleDown, block } = disableCheckerOptions

  const placeholderEdgeValue = isZoomScaleDown ? IMAGE_MINIMUM_ZOOM_SIZE : IMAGE_MAXIMUM_ZOOM_SIZE

  return block?.data.sizeValue ? block?.data.sizeValue === placeholderEdgeValue : false
}
