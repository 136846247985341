import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
  position: relative;
`

export const StyledPdfToolbar = styled.div`
  border-bottom: var(--gray-thin) 1px solid;
  padding: 4px 12px;
  position: sticky;
  left: 0;
  bottom: 0;
  z-index: 1;

  input {
    width: 50px;
  }
`

export const StyledPdfToolbarWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: 12px;
  justify-content: right;
  max-height: 40px;
  height: 34px;
`
