import { DragSourceTypes, ExtendedNodeModel, SourceKeys } from 'interfaces/editor'
import { type block, getBlockPageId, IBlockTypes } from '_entities/block'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useUpdatePage } from '_features/page'
import Quill, { DeltaStatic } from 'quill'
import useNumberedList from 'editor/EditableBlock/EditorTextBlock/helpers/useNumberedList'
import { usePresence } from 'shared/shareDb/usePresence'
import { TreeItem } from '_entities/tree'
import { useUpdateBlock } from './useUpdateBlock'
import { getPage } from '_entities/page'
import { useAppSelector } from 'redux/hooks'
import { useCreateBlock } from './useCreateBlock'
import { getBlocks } from 'shared/shareDb'

export const useBlockDrag = () => {
  // ** Redux
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  // ** Hooks
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _presence = usePresence()
  const _updatePage = useUpdatePage()
  const _numberedList = useNumberedList()
  const _updateBlock = useUpdateBlock()
  const _createBlock = useCreateBlock()

  const onDropHandler = (sourceIndex: number, destinationIndex: number, block: block) => {
    // If we don't have a destination (due to dropping outside the droppable)
    // or the destination hasn't changed, we change nothing
    const blocks = getBlocks(getBlockPageId(block))
    if (blocks) {
      if (destinationIndex > blocks.length) return
      _submit.submit(
        getBlockPageId(block),
        _json1.moveBlock(sourceIndex, destinationIndex),
        SourceKeys.UPDATE_BLOCK,
      )
    }

    setTimeout(() => {
      const firstTitleInDocument = blocks?.find(
        (block: block) =>
          block.data.tag === IBlockTypes.TITLE ||
          (block.data.richText as DeltaStatic)?.ops?.some((op) => op.attributes?.header === 1),
      )

      if (firstTitleInDocument) {
        const quillElement = document.querySelector(`#quill-editor-${firstTitleInDocument._id}`)
        if (!quillElement) return
        const editor: Quill = Quill.find(quillElement)
        const title = editor?.getText()
        _updatePage.updateTitle(title, firstTitleInDocument.meta.pageId)
      }
    })

    if (destinationIndex === sourceIndex) {
      return
    }

    if (blocks) {
      const block = blocks?.[sourceIndex]
      if (block) {
        _numberedList.updateList(block)
      }
    }
  }

  const onDragStartHandler = (block: block) => {
    _presence.updatePresence(block)
  }

  const onDocumentDrop = async (args: {
    pageId: string
    droppedData: TreeItem
    destinationIndex: number
    relativeIndex: number
  }) => {
    const block = args.droppedData?.data
    const sourceIndex = (args.droppedData as ExtendedNodeModel).positionIndex - 1

    const isAltKeyPressed = (window.event as KeyboardEvent)?.altKey

    if (isAltKeyPressed && block && args.destinationIndex !== undefined) {
      _updateBlock.copyBlockHandler(block as block, args.destinationIndex)
      return
    }

    if (args.droppedData) {
      const pageDragSource = args.droppedData

      if (
        pageDragSource.type === DragSourceTypes.WHITEBOARD ||
        pageDragSource.type === DragSourceTypes.DOCUMENT
      ) {
        const page = projectFile && (await getPage(projectFile.id, args.droppedData.id))
        page &&
          args.destinationIndex > -1 &&
          _createBlock.addDocumentBlockHandler(
            pageDragSource.type as unknown as IBlockTypes,
            page,
            args.destinationIndex,
            args.pageId,
          )

        return
      }

      if (pageDragSource.type === DragSourceTypes.EMBED) {
        const page = projectFile && (await getPage(projectFile.id, args.droppedData.id))

        page &&
          args.destinationIndex > -1 &&
          _createBlock.addDocumentExternalBlockHandler(page, args.destinationIndex)
        return
      }
    }

    if (
      args.droppedData &&
      typeof sourceIndex === 'number' &&
      typeof args.relativeIndex === 'number'
    ) {
      const blocks = getBlocks(args.pageId)
      const destination =
        args.relativeIndex === blocks?.length ? args.relativeIndex - 1 : args.relativeIndex
      onDropHandler(sourceIndex, destination, block as block)
    }
  }

  return {
    onDragStartHandler,
    onDropHandler,
    onDocumentDrop,
  }
}
