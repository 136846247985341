import styled from 'styled-components'

export const StyledWrapper = styled.div<{
  border?: string
  height?: string
  width?: string
  position?: string
  right?: string
  left?: string
  top?: string
  bottom?: string
  borderradius?: string
  padding?: string
  color?: string
  zindex?: string
  bordertop?: string
  display?: string
  justifyContent?: string
  alignItems?: string
  stylesWrapper?: string
  focusColor?: string
  hoverColor?: string
  transform?: string
  margin?: string
  marginTop?: string
  boxshadow?: string
  background?: string
  cursor?: string
  visibility?: string
  transition?: string
  opacity?: string
  gap?: string
  flexdirection?: string
  flexgrow?: string
  outline?: string
  maxWidth?: string
  overflow?: string
}>`
  border: ${(props) => props.border};
  height: ${(props) => props.height};
  width: ${(props) => props.width} !important;
  position: ${(props) => props.position};
  right: ${(props) => props.right};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  bottom: ${(props) => props.bottom};
  border-radius: ${(props) => props.borderradius} !important;
  padding: ${(props) => props.padding};
  color: ${(props) => props.color};
  z-index: ${(props) => props.zindex};
  border-top: ${(props) => props.bordertop};
  display: ${(props) => props.display};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  transform: ${(props) => props.transform};
  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  box-shadow: ${(props) => props.boxshadow};
  background: ${(props) => props.background};
  cursor: ${(props) => props.cursor};
  visibility: ${(props) => props.visibility};
  transition: ${(props) => props.transition};
  gap: ${(props) => props.gap};
  opacity: ${(props) => props.opacity};
  flex-direction: ${(props) => props.flexdirection};
  flex-grow: ${(props) => props.flexgrow};
  outline: ${(props) => props.outline};
  max-width: ${(props) => props.maxWidth};
  overflow: ${(props) => props.overflow};

  ${(props) => props.stylesWrapper};

  .dark-style-hover:hover {
    background: var(--primary-color2);
  }

  & .dark-style-hover {
    &:hover {
      color: var(--black) !important;
    }
  }

  &:focus-within {
    background: ${(props) => props.focusColor};
  }

  &:hover {
    background: ${(props) => props.hoverColor};
  }

  .row.no-gutters {
    margin-right: 0;
    margin-left: 0;
  }

  .row.no-gutters > [class^='col-'],
  .row.no-gutters > [class*=' col-'] {
    padding-right: 0;
    padding-left: 0;
  }

  #row-borders {
    padding: 0 !important;
    margin: 0 !important;
    border: none;

    > * {
      border: 1px solid var(--gray4) !important;
    }
  }
`
