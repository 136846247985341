import { Whiteboard } from '_entities/whiteboard'
import { ChatTree, TasksTree } from '_widgets'
import PageInviteModal from '../PageInviteModal/PageInviteModal'
import { usePage } from './usePage'
import { PageTypesEnum } from 'interfaces/projectFile'
import {
  PageContainer,
  FullPageImage,
  StyledVideo,
  StyledVimeo,
  VideoWrapper,
  StyledYoutube,
} from './styles'
import { useAppSelector } from 'redux/hooks'
import ProfileDrawer from './ProfileDrawer/ProfileDrawer'
import {
  checkIsFigma,
  checkIsImageRegex,
  checkIsMiro,
  checkIsOfficeLink,
  checkIsPdfLink,
  checkIsVideoLink,
  checkIsVimeo,
  checkIsYoutube,
  checkIsGoogleDocLink,
  checkIsGoogleSlidesLink,
  checkIsGoogleSheetsLink,
  extractVimeoVideoId,
  extractVideoId,
} from '_entities/embed'
import useImage from '_entities/image/model/useImage'
import ShowImageModal from 'components/organisms/ShowImageModal/ShowImageModal'
import { GoogleEmbed } from '_features/google-embed'
import EmptyPage from 'views/EmptyPage/EmptyPage'
import { WebsiteEmbed } from '_entities/embed/ExternalEmbed/ui/WebsiteEmbed/WebsiteEmbed'
import { checkIsGoogleCalendarLink } from '_entities/embed/ExternalEmbed/model/EmbedRegex'
import { Document } from '_widgets/Document'
import { FigmaFullPageEmbed } from '_pages/FigmaFullPageEmbed'
import { FullPagePdfEmbed } from '_pages/FullPagePdfEmbed'
import { FullPageEmbed } from '_pages/FullPageEmbed'
import { Toolbar } from '_features/toolbar'
import { useEffect, useState } from 'react'
import { TaskManager } from '_widgets/TaskManager'
import { IPermissions } from '_entities/user'
import { checkPageRole } from 'shared/shareDb'

export const getPageContainerId = (pageId: string) => {
  return `page-container-${pageId}`
}

export const getPageContainer = (pageId: string) => {
  return document.getElementById(getPageContainerId(pageId))
}

interface Props {
  isPreview?: boolean
}

const Page = (props?: Props) => {
  const [role, setRole] = useState<IPermissions>()
  const _page = usePage()
  const _image = useImage()
  const isProjectFileEmpty = useAppSelector((state) => state.projectFile.isProjectFileEmpty)
  const isPresentationMode = useAppSelector((state) => state.projectFile.isPresentationMode)
  const isGenericLink = (url: string) => {
    return (
      !checkIsGoogleDocLink(url) &&
      !checkIsGoogleSlidesLink(url) &&
      !checkIsGoogleSheetsLink(url) &&
      !checkIsGoogleCalendarLink(url) &&
      !checkIsOfficeLink(url) &&
      !checkIsMiro(url) &&
      !checkIsVimeo(url) &&
      !checkIsYoutube(url) &&
      !checkIsPdfLink(url) &&
      !checkIsImageRegex(url) &&
      !checkIsVideoLink(url) &&
      !checkIsFigma(url)
    )
  }

  useEffect(() => {
    if (!_page.selectedPage) return
    checkPageRole(_page.selectedPage.id, setRole)
  }, [_page.selectedPage])

  return (
    <PageContainer
      backgroundColor={
        _page.selectedPage && (_page.pageBackgroundConfig[_page.selectedPage.type] as string)
      }
      ref={_page.ref}
      id={_page.selectedPage && getPageContainerId(_page.selectedPage?.id)}
      className='scrollbar'
    >
      {_page.selectedPage && _page.selectedPage.type === PageTypesEnum.DOCUMENT && (
        <>
          <Document pageId={_page.selectedPage.id} isPreview={props?.isPreview} />
          <Toolbar />
        </>
      )}

      {isProjectFileEmpty && !_page.selectedPage && <EmptyPage />}

      {_page.selectedPage && _page.selectedPage.type === PageTypesEnum.WHITE_BOARD && (
        <>
          <Whiteboard pageId={_page.selectedPage.id} isPreview={props?.isPreview} />
          {!isPresentationMode &&
            !(role === IPermissions.CAN_COMMENT || role === IPermissions.CAN_VIEW) && <Toolbar />}
        </>
      )}

      {_page.selectedPage && _page.selectedPage.type === PageTypesEnum.TASK_MANAGER && (
        <TaskManager isSlider={false} isSliderFullScreen={false} />
      )}

      {_page.selectedPage &&
        _page.selectedPage.type === PageTypesEnum.EMBED &&
        (checkIsGoogleDocLink(_page.selectedPage.embedUrl as string) ||
          checkIsGoogleSlidesLink(_page.selectedPage.embedUrl as string) ||
          checkIsGoogleSheetsLink(_page.selectedPage.embedUrl as string)) && (
          <GoogleEmbed embedUrl={`${_page.selectedPage.embedUrl as string}?usp=sharing`} />
        )}

      {_page.selectedPage &&
        _page.selectedPage.type === PageTypesEnum.EMBED &&
        (checkIsOfficeLink(_page.selectedPage.embedUrl as string) ||
          checkIsMiro(_page.selectedPage.embedUrl as string) ||
          checkIsGoogleCalendarLink(_page.selectedPage.embedUrl as string)) && (
          <FullPageEmbed
            embedUrl={
              checkIsOfficeLink(_page.selectedPage.embedUrl as string)
                ? `https://view.officeapps.live.com/op/embed.aspx?src=${
                    _page.selectedPage.embedUrl as string
                  }`
                : `${_page.selectedPage.embedUrl as string}`
            }
          />
        )}

      {_page.selectedPage &&
        _page.selectedPage.type === PageTypesEnum.EMBED &&
        checkIsVimeo(_page.selectedPage.embedUrl as string) && (
          <VideoWrapper>
            <StyledVimeo
              src={`https://player.vimeo.com/video/${extractVimeoVideoId(
                _page.selectedPage.embedUrl as string,
              )}`}
              allow='autoplay; fullscreen; picture-in-picture'
              allowFullScreen
            />
          </VideoWrapper>
        )}

      {_page.selectedPage &&
        _page.selectedPage.type === PageTypesEnum.EMBED &&
        checkIsYoutube(_page.selectedPage.embedUrl as string) && (
          <VideoWrapper>
            <StyledYoutube
              src={`https://www.youtube.com/embed/${extractVideoId(
                _page.selectedPage.embedUrl as string,
              )}`}
            />
          </VideoWrapper>
        )}

      {_page.selectedPage &&
        _page.selectedPage.type === PageTypesEnum.EMBED &&
        checkIsPdfLink(_page.selectedPage.embedUrl as string) && (
          <FullPagePdfEmbed
            embedUrl={_page.selectedPage.embedUrl as string}
            name={_page.selectedPage.title as string}
          />
        )}

      {_page.selectedPage &&
        _page.selectedPage.type === PageTypesEnum.EMBED &&
        typeof _page.selectedPage.embedUrl === 'string' &&
        checkIsImageRegex(_page.selectedPage.embedUrl as string) && (
          <FullPageImage src={_page.selectedPage.embedUrl} />
        )}

      {_page.selectedPage &&
        _page.selectedPage.type === PageTypesEnum.EMBED &&
        checkIsVideoLink(_page.selectedPage.embedUrl as string) && (
          <VideoWrapper>
            <StyledVideo width='320' height='240' controls>
              <source src={_page.selectedPage.embedUrl} type='video/mp4' />
              Your browser does not support the video tag.
            </StyledVideo>
          </VideoWrapper>
        )}

      {_page.selectedPage &&
        _page.selectedPage.type === PageTypesEnum.EMBED &&
        checkIsFigma(_page.selectedPage.embedUrl as string) && (
          <FigmaFullPageEmbed embedUrl={_page.selectedPage.embedUrl} />
        )}

      {_page.selectedPage &&
        _page.selectedPage.type === PageTypesEnum.EMBED &&
        isGenericLink(_page.selectedPage.embedUrl as string) && (
          <WebsiteEmbed
            url={_page.selectedPage.embedUrl as string}
            title={_page.selectedPage.title}
          />
        )}

      <ChatTree />
      <TasksTree />
      <ProfileDrawer />
      {_page.selectedPage?.id && (
        <PageInviteModal show={_page.modalShow} setShow={_page.setModalShow} />
      )}

      <ShowImageModal
        block={_image.selectedBlock}
        onShow={_image.onShow}
        isModalShown={_image.isImageModalShown || false}
        handleClose={_image.onCloseModal}
        onSizeMinusClick={_image.onSizeMinusClick}
        onSizePlusClick={_image.onSizePlusClick}
        scale={_image.scale}
      />
    </PageContainer>
  )
}

export default Page
