import { ISpace } from 'interfaces/space'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { AxiosService } from 'services/axiosService/axiosService'
import { setNewProjectFileValue } from 'redux/reducers/spaceReducer'
import { IPage } from 'interfaces/page'
import { getPages } from '_entities/project'
import { PageTypesEnum } from 'interfaces/projectFile'
import { useState } from 'react'

interface IUseNewProjectFileModalProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  space: ISpace | undefined
  newProjectFileTitle: string
}

export const useNewProjectFileModal = (props: IUseNewProjectFileModalProps) => {
  const { setShow, space, newProjectFileTitle } = props
  const user = useAppSelector((state) => state.global.user)

  const projectFileOptions = () => {
    if (user) {
      const options = [
        {
          name: `Everyone from ${user.firstName}’s
          Space can edit`,
          assignMember: 'Everyone Can Edit',
          private: false,
        },
        {
          name: `Everyone from ${user.firstName}’s
          Space can view`,
          assignMember: 'Everyone Can View',
          private: false,
        },
        {
          name: `Everyone from ${user.firstName}’s
          Space can comment`,
          assignMember: 'Everyone Can Comment',
          private: false,
        },
        {
          name: 'Private - can be changed later',
          assignMember: 'Just Me',
          private: true,
        },
      ]
      return options
    } else return []
  }

  const getPermissionSelectionText = () => {
    if (user)
      return `Everyone from ${user.firstName}’s 
    Space can edit`
    else return 'Everyone from my space can edit'
  }
  const [selectedAssignedMember, setSelectedAssignedMember] = useState<string>('')

  const handleClose = () => setShow(false)
  const AxiosInstance = new AxiosService()
  const dispatch = useAppDispatch()

  dispatch(setNewProjectFileValue(projectFileOptions()[0]))

  const newProjectFilePrivateValue = useAppSelector(
    (state) => state.space.newProjectDropdownValue?.private,
  )
  const navigate = useNavigate()

  const createProjectFile = async (e: any) => {
    e.preventDefault()

    if (space) {
      const createProjectFileResponse = await AxiosInstance.createProjectFileRequest(
        newProjectFileTitle,
        space.id,
        newProjectFilePrivateValue,
        selectedAssignedMember || 'Everyone Can View' /* Default selection. */,
      )
      if (createProjectFileResponse) {
        const pages: IPage[] = await getPages(createProjectFileResponse.data.data.id)
        const documentPage = pages.find((page) => page.type === PageTypesEnum.DOCUMENT)

        setTimeout(() => {
          navigate(
            `/spaces/${space.id}/project-file/${createProjectFileResponse.data.data.id}?pageId=${documentPage?.id}`,
          )
        }, 10)
      }
    }
  }

  return {
    getPermissionSelectionText,
    handleClose,
    newProjectFilePrivateValue,
    createProjectFile,
    dispatch,
    setSelectedAssignedMember,
    projectFileOptions,
  }
}
