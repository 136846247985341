import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import {
  Button,
  ForgotPasswordLink,
  ModalInfoLabel,
  ModalInnerContainer,
  ModalSubtitle,
  ModalTitle,
  InputControl,
} from './styles'

type Props = {
  show: boolean
  handleClose: () => void
  saveChanges: () => void
  setPassword: React.Dispatch<React.SetStateAction<string>>
  onShow: () => void
  setNewEmail: React.Dispatch<React.SetStateAction<string>>
  email: string
  user?: any
  placeholder?: string
  additionalPlaceholder?: string
}

const ChangeEmailModal: React.FC<Props> = ({
  show,
  handleClose,
  saveChanges,
  setPassword,
  setNewEmail,
  onShow,
  placeholder,
  additionalPlaceholder,
}) => {
  return (
    <Modal
      show={show}
      onShow={onShow}
      onHide={handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='changeEmailModal'
    >
      <ModalInnerContainer>
        <Modal.Header closeButton className='modalHeader border-0'>
          <ModalTitle>Change email</ModalTitle>
        </Modal.Header>
        <ModalSubtitle className='modal-header border-0 m-0'>
          We’ll send instructions to your new email address how to complete the change
        </ModalSubtitle>
        <Modal.Body className='border-0'>
          <Form>
            <Form.Group className='mb-3'>
              <ModalInfoLabel>New email address</ModalInfoLabel>
              <InputControl
                type='email'
                placeholder={placeholder || 'name@example.com'}
                onChange={(e: React.ChangeEvent) =>
                  setNewEmail((e.currentTarget as HTMLInputElement).value)
                }
              />
            </Form.Group>
            <Form.Group className='mb-0'>
              <ModalInfoLabel>Confirm password</ModalInfoLabel>
              <InputControl
                type='password'
                placeholder={additionalPlaceholder || '*******'}
                onChange={(e: React.ChangeEvent) =>
                  setPassword((e.currentTarget as HTMLInputElement).value)
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-between'>
          <ForgotPasswordLink onClick={handleClose}>Forgot password?</ForgotPasswordLink>
          <Button onClick={saveChanges}>Send email</Button>
        </Modal.Footer>
      </ModalInnerContainer>
    </Modal>
  )
}

export default ChangeEmailModal
