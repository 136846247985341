import styled from 'styled-components'

export const SettingsWrapper = styled.div`
  padding: 16px;
  overflow: scroll;
  height: 100%;
  padding-bottom: 40px;
`

export const SectionHeader = styled.div`
  height: 30px;
  cursor: pointer;
  margin-bottom: 12px;
`

export const SectionContent = styled.div``
