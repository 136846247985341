import React, { useEffect, useState } from 'react'
import { passwordStrength } from 'check-password-strength'
import { Row, Col } from 'react-bootstrap'
import DisplayCoverImage from 'components/templates/DisplayCoverImage/DisplayCoverImage'
import VerifyEmail from './VerifyEmail'
import AuthWrapper from 'components/templates/AuthWrapper/AuthWrapper'
import SignUpForm from 'components/organisms/SignUpForm/SignUpForm'
import SocialAuth from 'components/organisms/SocialAuth/SocialAuth'
import Card from 'components/organisms/Card/Card'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'services/auth/useAuth'
import { useUser } from 'services/user/useUser'
import { MainWrapper } from 'views/styles'

const Signup: React.FC = () => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')
  const [passwordMissmatchMessage, setPasswordMissmatchMessage] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [id, setId] = useState('')
  const [checkDone, setCheckDone] = useState<boolean>(false)
  const [agreeTerms, setAgreeTerms] = useState<boolean>(false)

  const { signUp } = useAuth()
  const { getSpaces } = useUser()
  const navigate = useNavigate()

  const checkIfPasswordsMatch = () => {
    if (password === confirmPassword) {
      setPasswordMissmatchMessage('')
      return true
    }
    setPasswordMissmatchMessage('Passwords do not match.')
    return false
  }

  const validateEmail = () => {
    if (email.length !== 0) {
      const re = // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase()) ? true : false
    } else {
      return true
    }
  }

  const checkIfPasswordSafe = () => {
    if (password.length === 0) return true
    if (
      passwordStrength(password).value === 'Medium' ||
      passwordStrength(password).value === 'Strong'
    )
      return true
    else {
      return false
    }
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!checkIfPasswordSafe()) return
    /* !checkIfPasswordsMatch() removed from the new design. */
    /* Possibility is that will be returned after redesign. */
    setError('')
    setLoading(true)
    const response = await signUp(firstName, lastName, email, password)
    if (response) setId(response.data.data.id)
    else setError('Something went wrong')
    setLoading(false)
    setAgreeTerms(false)
  }

  const checkLoginAndRedirectToSpace = async () => {
    const { mySpaces } = await getSpaces()
    if (mySpaces.length) {
      const myFirstSpace = mySpaces[0]
      navigate(`/space/${myFirstSpace.id}`)
    }
    setCheckDone(true)
  }

  useEffect(() => {
    checkLoginAndRedirectToSpace()
  }, [])

  if (!id && checkDone) {
    return (
      <MainWrapper>
        <DisplayCoverImage />

        <AuthWrapper
          text={'Log In'}
          question={'Already have an account?'}
          elements={
            <Card
              shadow
              textBlack={false}
              radius
              fontSize
              titleText='Sign Up'
              subtext=' '
              cardElements={
                <Row className='d-flex justify-content-center align-items-center'>
                  <Col>
                    <SignUpForm
                      onSubmit={onSubmit}
                      setFirstName={setFirstName}
                      setLastName={setLastName}
                      validateEmail={validateEmail}
                      setEmail={setEmail}
                      setPassword={setPassword}
                      setConfirmPassword={setConfirmPassword}
                      confirmPassword={confirmPassword}
                      passwordMissmatchMessage={passwordMissmatchMessage}
                      checkIfPasswordSafe={checkIfPasswordSafe}
                      error={error}
                      loading={loading}
                      isChecked={agreeTerms}
                      setAgreeTerms={setAgreeTerms}
                      firstName={firstName}
                      lastName={lastName}
                      email={email}
                      password={password}
                    />

                    <SocialAuth text={'Sign Up'} />
                  </Col>
                </Row>
              }
            />
          }
        />
      </MainWrapper>
    )
  }
  if (checkDone) return <VerifyEmail id={id} email={email} />
  return null
}

export default Signup
