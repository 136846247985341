import PageMembersItem from 'components/molecules/PageMembersItem/PageMembersItem'
import { PageMembersWrapper, ScrollContainer } from './styles'
import { useEffect, useRef, useState } from 'react'
import { useGetPage } from '_entities/page'
import { IPage, IPageMember, IPendingPageMember } from 'interfaces/page'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import Loader from 'components/atoms/Loader/Loader'
import PendingMembersItem from 'components/molecules/PendingMembersItem/PendingMemberItem'
interface Props {
  page: IPage
  userInvited: boolean
  handleUserNotInvited: () => void
}

const PageMembers = (props: Props) => {
  const { page } = props
  const [members, setMembers] = useState<IPageMember[]>()
  const [pendingUsers, setPendingUsers] = useState<IPendingPageMember[]>()

  const { getPageMembers, getPendingUsers } = useGetPage()
  const ref = useRef<HTMLDivElement | null>(null)

  const setPageMembers = async () => {
    const response = await getPageMembers(props.page.id)
    const pendingUsers = await getPendingUsers(props.page.id)
    if (response) {
      setMembers(response)
    }

    if (pendingUsers) {
      setPendingUsers(pendingUsers)
    }

    props.handleUserNotInvited()
  }

  useEffect(() => {
    setPageMembers()
  }, [props.userInvited])

  if (members)
    return (
      <PageMembersWrapper ref={ref} className='scrollbar'>
        <ScrollContainer>
          {members.map((member) => {
            return (
              <PageMembersItem
                {...{ member, page }}
                key={`page-${props.page.id}-member-${member.user.id}`}
              />
            )
          })}
          {pendingUsers?.map((member) => {
            return (
              <PendingMembersItem
                {...{ member, page, setPageMembers }}
                key={`page-${props.page.id}-member-${member.id}`}
              />
            )
          })}
        </ScrollContainer>
      </PageMembersWrapper>
    )
  else
    return (
      <Wrapper
        height='100px'
        width='100%'
        display='flex'
        justifyContent='center'
        alignItems='center'
      >
        <Loader />
      </Wrapper>
    )
}

export default PageMembers
