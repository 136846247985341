import RadixDropdown, {
  IconButton,
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { type block } from '_entities/block'
import { useConversionMenu } from '_features/block'
import { IconButtonSize } from '_features/toolbar'

interface Props {
  block: block
}

const WhiteboardTextConversionDropdown = (props: Props) => {
  const _conversionMenu = useConversionMenu()
  return (
    <RadixDropdown
      trigger={
        <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
          {_conversionMenu.textOptions.find((obj) => obj.tag === props.block?.data.tag)?.icon}
        </IconButton>
      }
    >
      {_conversionMenu.textOptions.map((option) => (
        <DropdownMenuItem
          onSelect={(e) => {
            _conversionMenu.textConverter(props.block, option.value)
          }}
          key={`text-conversion-option-${option.label}-block-id-${props.block._id}`}
        >
          {option.icon} {option.label}
        </DropdownMenuItem>
      ))}
    </RadixDropdown>
  )
}

export default WhiteboardTextConversionDropdown
