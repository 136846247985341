import React from 'react'
import {
  DragPreview,
  DropTargetWrapper,
  ItemWrapper,
  PlaceholderWrapper,
  StyledTreeWrapper,
} from './styles'
import useDragging, { DRAG_PREVIEW_ID } from '../model/useDragging'
import { DraggingTreeProps } from '../model/types'
import { TreeItem } from '_entities/tree/model/types'

export const TREE_CLASS_NAME = 'tree-container'

export const LAST_PLACEHOLDER = 'last'

export const RENDER_ONLY = 'render-only'

const LAST_PLACEHOLDER_ITEM: TreeItem = {
  id: LAST_PLACEHOLDER,
  isBetweenItems: true,
  text: LAST_PLACEHOLDER,
  depth: 0,
  parent: 0,
  type: LAST_PLACEHOLDER,
}

export const RENDER_ONLY_ITEM: TreeItem = {
  id: RENDER_ONLY,
  text: RENDER_ONLY,
  depth: 0,
  parent: 0,
  type: RENDER_ONLY,
  isShown: true,
}

export const getPlaceholderId = (id: string) => {
  return `placeholder-${id}`
}

const DraggableTree = (props: DraggingTreeProps) => {
  const _dragging = useDragging(props)
  return (
    <StyledTreeWrapper
      className={props.className || TREE_CLASS_NAME}
      role='list'
      flexContainer={props.flexContainer}
    >
      <DragPreview id={DRAG_PREVIEW_ID}></DragPreview>
      {_dragging.items.map((item, index) => {
        const hasChild = _dragging.hasChild(_dragging.items, item)
        const handleRef = _dragging.handleRefs.current[item.id]

        if (handleRef && handleRef.current) {
          const draggableAttr = document.createAttribute('draggable')
          draggableAttr.value = 'true'
          handleRef.current.attributes.setNamedItem(draggableAttr)

          handleRef.current.addEventListener('dragstart', (event) =>
            _dragging.onHandlerDragStart(event, item),
          )

          handleRef.current.addEventListener('drag', (event) =>
            _dragging.onHandlerDrag(event, item),
          )

          handleRef.current.addEventListener('dragend', (event) =>
            _dragging.onHandlerDragEnd(event, item),
          )
        }

        return (
          <DropTargetWrapper
            role='listitem'
            key={`${item.id}-${index}`}
            isShown={item.isShown}
            onDrop={(event) => {
              _dragging.onDrop(event, _dragging.items)
            }}
            flexContainer={props.flexContainer}
          >
            <PlaceholderWrapper
              id={getPlaceholderId(item.id)}
              visible={
                _dragging.itemHovered?.id === item.id && _dragging.itemHovered.isBetweenItems
              }
              onDragOver={() => {
                _dragging.handleSetItemHovered({ item, isBetweenItems: true })
              }}
              onDragEnd={(event) => {
                event.dataTransfer.clearData()
                _dragging.removeItemHovered()
              }}
              onDragLeave={() => {
                _dragging.removeItemHovered()
              }}
            >
              {props.placeholderRender ? props.placeholderRender() : <></>}
            </PlaceholderWrapper>
            <ItemWrapper
              onDragOver={() => {
                _dragging.handleSetItemHovered({ item, isBetweenItems: false })
              }}
              onDragEnd={(event) => _dragging.onDragEndItem(event)}
              onDragLeave={() => {
                _dragging.removeItemHovered()
              }}
              draggable={!props.preventDrag && !(handleRef && handleRef.current)}
              onDragStart={(event) => _dragging.onDragStartItem(event, item)}
              onDrop={(event) => {
                _dragging.onDrop(event, _dragging.items)
              }}
              id={`drag-item-${item.id}`}
            >
              {props.render ? (
                props.render(item, {
                  depth: item?.depth || 0,
                  isOpen: _dragging.checkAreChildrenVisible(item.id, _dragging.items) || false,
                  onToggle: () => _dragging.handleOnOpen(item.id, _dragging.items),
                  isDropTarget:
                    _dragging.itemHovered?.id === item.id && !_dragging.itemHovered.isBetweenItems,
                  hasChild: hasChild,
                  isDragging: _dragging.isDragging,
                  handleRef: !props.preventDrag ? handleRef : undefined,
                })
              ) : (
                <></>
              )}
            </ItemWrapper>
          </DropTargetWrapper>
        )
      })}
      <PlaceholderWrapper
        isLast
        visible={
          _dragging.itemHovered?.type === LAST_PLACEHOLDER && _dragging.itemHovered.isBetweenItems
        }
        onDragOver={() => {
          _dragging.handleSetItemHovered({
            item: LAST_PLACEHOLDER_ITEM,
            isBetweenItems: true,
          })
        }}
        onDragEnd={(event) => {
          event.dataTransfer.clearData()
          _dragging.removeItemHovered()
        }}
        onDragLeave={() => {
          _dragging.removeItemHovered()
        }}
        onDrop={(event) => _dragging.onDrop(event, _dragging.items)}
      >
        {props.placeholderRender ? props.placeholderRender() : null}
      </PlaceholderWrapper>
    </StyledTreeWrapper>
  )
}

export default DraggableTree
