import { useAppSelector } from 'redux/hooks'
import { useEffect, useState } from 'react'
import { SlashMenuProps } from '../ui/SlashMenu/SlashMenu'
import { SlashMenuOptions, ExtendedIPages } from './types'
import { useSlashMenuOptions } from './useSlashMenuOptions'
import { PageTypesEnum } from 'interfaces/projectFile'

export const SELECTOR_ICON_SIZE = '1.15rem'
export const SELECTOR_ICON_SIZE_SMALL = '1rem'

export const useSlashMenu = (props: SlashMenuProps) => {
  const [isPageSearch, setIsPageSearch] = useState<boolean>(false)
  const [sortedItems, setSortedItems] = useState<(SlashMenuOptions | ExtendedIPages)[]>([])
  const _slashMenuOptions = useSlashMenuOptions()
  const pages = useAppSelector((state) => state.projectFile.pages)
  let pagesWithoutTaskManager = pages?.filter((page) => page.type !== PageTypesEnum.TASK_MANAGER)
  pagesWithoutTaskManager = pagesWithoutTaskManager?.map((page) => {
    return {
      ...page,
      label: page.text,
    }
  })

  let options: (SlashMenuOptions | ExtendedIPages)[] = props.isWhiteboard
    ? [..._slashMenuOptions.whiteboardOptions]
    : [..._slashMenuOptions.editorOptions]

  if (isPageSearch) {
    options = pagesWithoutTaskManager
      ? [...options, ...(pagesWithoutTaskManager as ExtendedIPages[])]
      : options
  }

  function filterDropdownItems(items: (SlashMenuOptions | ExtendedIPages)[], query: string) {
    const searchQuery = query.toLowerCase().trim()

    const startsWithQuery = items.filter((item) =>
      item.label.toLowerCase().trim().startsWith(searchQuery),
    )

    const containsQuery = items.filter(
      (item) =>
        !item.label.toLowerCase().startsWith(searchQuery) &&
        item.label.toLowerCase().includes(searchQuery),
    )

    const sortedItems = startsWithQuery.concat(containsQuery)

    const tagSelectionItems = sortedItems.filter((item) => item.type)
    const pageSelectionItems = sortedItems.filter((item) => !item.type)

    return [...tagSelectionItems, ...pageSelectionItems]
  }

  useEffect(() => {
    if (props.searchText && props.searchText?.length > 0) {
      setSortedItems(filterDropdownItems(options, props.searchText))
      if (props.searchText.length > 2) setIsPageSearch(true)
    } else {
      setSortedItems([])
    }
  }, [props.searchText])

  return {
    open,
    options,
    sortedItems,
    isPageSearch,
  }
}
