import React from 'react'
import {
  CTABtn,
  getCtaBorderColor,
  getCtaBorderRadius,
  getCtaButtonColor,
  getCtaButtonHeight,
  getCtaFont,
  getCtaFontSize,
  getCtaTextColor,
  getCtaTitle,
  StyledCtaButtonWrapper,
  useCtaButton,
  DocumentBlockAttributes,
  getCtaButtonId,
  getCtaButtonAlignment,
  getCtaFontWeight,
  getCtaFontWeightValue,
} from '_entities/cta'
import { CTAButtonSettings, useCta, useOnCtaClick } from '_features/cta'
import { block } from '_entities/block'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import { useObservationOnDocumentBlock } from '_features/block'

interface IProps {
  block: block
}

export const CTAButton = (props: IProps) => {
  // ** Hooks
  const _cta = useCta()
  const _onClick = useOnCtaClick()
  const _ctaButton = useCtaButton(props)
  const _observeDocBlock = useObservationOnDocumentBlock()

  if (!_ctaButton.button && _ctaButton.error) {
    return (
      <CustomP fontSize='14px' color={getColor('--everyday-red')}>
        {_ctaButton.error}
      </CustomP>
    )
  }

  return (
    <StyledCtaButtonWrapper
      key={getCtaButtonId(props.block._id)}
      isEditing={_ctaButton.editing}
      align={getCtaButtonAlignment(props.block)}
      id={getCtaButtonId(props.block._id)}
      ref={(node) => {
        if (!node) return

        _observeDocBlock.observeOnDocumentBlock(
          node.id,
          DocumentBlockAttributes.BUTTON_IS_EDITING,
          () => {
            const isEditing =
              node.getAttribute(DocumentBlockAttributes.BUTTON_IS_EDITING) === 'true'

            if (!isEditing) {
              _ctaButton.fetchButton()
            }

            _ctaButton.handleSetIsEditing(isEditing)
          },
        )
      }}
    >
      {_ctaButton.button && (
        <>
          {_ctaButton.editing ? (
            <CTAButtonSettings isDocument button={_ctaButton.button} block={props.block} />
          ) : (
            <CTABtn
              key={getCtaButtonId(_ctaButton.button.id)}
              fontFamily={getCtaFont(_ctaButton.button)}
              fontSize={getCtaFontSize(_ctaButton.button)}
              textColor={getCtaTextColor(_ctaButton.button)}
              buttonBackgroundColor={getCtaButtonColor(_ctaButton.button)}
              borderRadius={getCtaBorderRadius(_ctaButton.button)}
              buttonSize={getCtaButtonHeight(_ctaButton.button)}
              borderColor={getCtaBorderColor(_ctaButton.button)}
              fontWeight={getCtaFontWeightValue(_ctaButton.button)}
              onInput={(e) => _ctaButton.button && _cta.onTitleChange(e, _ctaButton.button)}
              id={getCtaButtonId(_ctaButton.button.id)}
              onClick={() => {
                _ctaButton.button &&
                  _onClick.handleClick(_ctaButton.button.onClickAction, _ctaButton.button?.link)
              }}
              ref={(node) => {
                if (!node) return

                _observeDocBlock.observeOnDocumentBlock(
                  node.id,
                  DocumentBlockAttributes.BUTTON_JUST_UPDATED,
                  async () => {
                    const isJustUpdated =
                      node.getAttribute(DocumentBlockAttributes.BUTTON_JUST_UPDATED) === 'true'
                    if (isJustUpdated) {
                      node.removeAttribute(DocumentBlockAttributes.BUTTON_JUST_UPDATED)
                      await _ctaButton.fetchButton()
                    }
                  },
                )
              }}
            >
              {getCtaTitle(_ctaButton.button)}
            </CTABtn>
          )}
        </>
      )}
    </StyledCtaButtonWrapper>
  )
}
