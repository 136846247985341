// https://ihateregex.io/expr/emoji/
export const emojiRegExpString =
  '(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff])'

export const emojiToUnified = (emoji: string) =>
  [...emoji].map((e) => e.codePointAt(0)?.toString(16)).join('-')

export const unifiedRegExpString = '\b([da-fA-F]{4,5}|[da-fA-F]{6}|[da-fA-F]{8})\b'

export const unifiedToEmoji = (unified: string) =>
  String.fromCodePoint(...unified.split('-').map((c) => parseInt(c, 16)))
