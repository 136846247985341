import { Layer, Stage } from 'react-konva'
import { ITools } from 'interfaces/whiteboard'
import { WhiteboardComponent } from '_entities/whiteboard/ui/styles'
import { getLayerId, getGridLayerId, useGrid } from '_entities/whiteboard'
import { useCallback, useEffect, useRef, useState } from 'react'
import { PreviewShapes } from '_entities/shape'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getColor } from 'shared/colors'
import Connector from 'whiteboard/Connector/Connector'
import { setSelectedBlock, setTool, setSlashMenuOpen } from 'redux/reducers/whiteboardReducer'
import { type block, IBlockTypes, WhiteboardBlock } from '_entities/block'
import { SlashMenu, SlashMenuOptions, useCreateBlock, useDeleteBlock } from '_features/block'
import { SingleValue } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import {
  Transformer,
  ZoomControls,
  useTransformer,
  useStage,
  useCopyPaste,
  useWheel,
  useZoom,
  useWhiteboard,
  useCursor,
  usePresentation,
  WhiteboardAttrs,
  useCanvas,
} from '_features/canvas'
import { useSlideShow } from '_entities/slideshow'
import {
  EmbedType,
  useEmbed,
  NoFrames,
  AllBlockEmbedTypes,
  useWhiteboardEmbed,
} from '_entities/embed'
import { useUpload } from '_features/upload/model/useUpload'
import { useLocation } from 'react-router-dom'
import { observeResize, setGrab, setGrabbing, useHash } from 'shared/lib'
import { useEmbedCreation } from '_features/embed'
import { PageTypesEnum } from 'interfaces/projectFile'
import { TreeItem } from '_entities/tree/model/types'
import { useShareDbSession, usePage } from '_entities/page'
import Loader from 'components/atoms/Loader/Loader'
import { PresentationToolbar } from '_features/toolbar'
import { getWhiteboardContainer, getWhiteboardContainerId } from '../lib/getters'
import { IPermissions } from '_entities/user'
import { getBlockById, getLastBlock, getRole } from 'shared/shareDb'
import { Stage as StageType } from 'konva/lib/Stage'

interface Props {
  pageId: string
  isEmbed?: boolean
  isSlideShow?: boolean
  parentPageId?: string
  parentBlock?: block
  background?: string
  isPreview?: boolean
  onRefLoad?: (node: HTMLDivElement | null) => void
}

export const Whiteboard = (props: Props) => {
  const [isPresentation, setIsPresentation] = useState(false)
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const slashMenuOpen = useAppSelector((state) => state.whiteboard.slashMenuOpen)
  const isPresentationMode = useAppSelector((state) => state.projectFile.isPresentationMode)
  const dispatch = useAppDispatch()
  const _page = usePage()
  const _slideshow = useSlideShow()
  const _transformer = useTransformer()
  const _upload = useUpload()
  const _wheel = useWheel()
  const _zoom = useZoom()
  const _delete = useDeleteBlock()
  const location = useLocation()
  const _hash = useHash()
  const _createBlock = useCreateBlock()
  const _copyPaste = useCopyPaste()
  const _embed = useEmbed()
  const _embedCreation = useEmbedCreation()
  const _stage = useStage()
  const _grid = useGrid()
  const _canvas = useCanvas()
  const _presentation = usePresentation()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const isPresentationObserver = useRef<MutationObserver>()
  const resizeObserver = useRef<ResizeObserver>()

  useCursor({ pageId: props.pageId })

  const role = getRole(props.pageId)

  const isPreview =
    role === IPermissions.CAN_COMMENT || role === IPermissions.CAN_VIEW || props.isPreview

  if (isPreview) {
    dispatch(setTool(ITools.HAND))
  }

  const { blocks } = useShareDbSession({
    pageId: props.pageId,
    isEmbed: props.isEmbed,
    isPreview,
  })
  const whiteboard = useWhiteboard({
    pageId: props.pageId,
    blocks,
    isEmbed: props.isEmbed,
    isSlideShow: props.isSlideShow,
    parentPageId: props.parentPageId,
  })

  const onCanvasComponentRefLoad = useCallback((node: HTMLDivElement | null) => {
    if (!node) {
      isPresentationObserver.current?.disconnect()
      resizeObserver.current?.disconnect()
      return
    }

    props.onRefLoad && props.onRefLoad(node)
    isPresentationObserver.current = _canvas.observeMutationOnCanvasElement(
      props.pageId,
      WhiteboardAttrs.IS_PRESENTATION,
      () => {
        const presentationAttr = _presentation.getIsPresentation(props.pageId)
        if (presentationAttr) {
          setIsPresentation(true)
        } else {
          setIsPresentation(false)
        }
      },
    )
    resizeObserver.current = observeResize(node, () => {
      const stage = _stage.getStage(props.pageId)
      if (stage) {
        stage.width(node.clientWidth)
        stage.height(node.clientHeight)
      }
      if (_presentation.getIsPresentation(props.pageId)) {
        _presentation.debounceRefocus(props.pageId)
      }
    })
  }, [])

  const onStageRefLoad = useCallback((node: StageType | null) => {
    if (!node) return

    _grid.drawLines(props.pageId)
    whiteboard.setIsStageLoaded(true)
  }, [])

  useEffect(() => {
    const wheelHandler = (e: WheelEvent) => {
      if (e.ctrlKey) e.preventDefault()
    }

    const root = getWhiteboardContainer(props.pageId)

    const pasteListenerCallback = (e: ClipboardEvent) => {
      _copyPaste.pasteHandler(props.pageId, e, props.isEmbed)
    }

    root?.addEventListener('wheel', wheelHandler, true)
    document.addEventListener('paste', pasteListenerCallback, true)
    return () => {
      root?.removeEventListener('wheel', wheelHandler, true)
      document.removeEventListener('paste', pasteListenerCallback, true)
      if (_presentation.getIsPresentation(props.pageId)) {
        _presentation.stopPresentation(props.pageId)
      }
    }
  }, [])

  useEffect(() => {
    const blockIdFromHash = _hash.getBlockIdFromHash()
    if (!blockIdFromHash) return
    const block = getBlockById(props.pageId, blockIdFromHash)
    if (!block) return
    _zoom.handleFocus(block)
  }, [location])

  const isWhiteboardContainerFocused = () => {
    return getWhiteboardContainer(props.pageId) === document.activeElement
  }

  const isEmbeddedInDoc = () => {
    if (props.isEmbed && props.parentPageId) {
      if (_page.isDocument(props.parentPageId)) return true
      else return false
    } else return undefined
  }

  const isStageDraggable = () => {
    if (isPreview) return false
    const isInDoc = isEmbeddedInDoc()
    if (isInDoc !== undefined) return !isInDoc
    else return tool === ITools.HAND && !props.isSlideShow
  }

  const shouldMoveAndZoom = () => {
    const isInDoc = isEmbeddedInDoc()
    if (isInDoc !== undefined) return !isInDoc
    else return !isPreview
  }

  if (props.isSlideShow && blocks && !_slideshow.isFrameInDoc(props.pageId)) {
    return <NoFrames />
  }

  if (!blocks) return <Loader />

  return (
    <WhiteboardComponent
      data-blocks={JSON.stringify(blocks)}
      background={
        isPresentationMode
          ? getColor('--presentation-background')
          : props.isEmbed
          ? props.background || 'var(--whiteboard-embeds)'
          : 'transparent'
      }
      ref={onCanvasComponentRefLoad}
      id={getWhiteboardContainerId(props.pageId)}
      onKeyDown={(e) => {
        if (isPreview) return
        if ((e.ctrlKey || e.metaKey) && e.key === 'c' && isWhiteboardContainerFocused()) {
          e.preventDefault()
          _copyPaste.copyBlocks(props.pageId)
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 'd') {
          e.preventDefault()
          _createBlock.createDocumentPageAndGenerateBlock(whiteboard.pageId)
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 'w') {
          e.preventDefault()
          _createBlock.createWhiteboardPageAndGenerateBlock(whiteboard.pageId)
        }
        if (e.key === ' ' && !(document.activeElement as HTMLElement).isContentEditable) {
          dispatch(setTool(ITools.HAND))
        }
        if (
          (e.key === 'Backspace' || e.key === 'Delete') &&
          !(document.activeElement as HTMLElement).isContentEditable
        ) {
          e.stopPropagation()
          _delete.deleteMultipleBlocks(props.pageId)
        }
        if (e.key === '/' && !(document.activeElement as HTMLElement).isContentEditable) {
          _createBlock.createWhiteboardBlock({
            type: IBlockTypes.TEXT,
            initialText: '',
            pageId: props.pageId,
          })
          dispatch(setSlashMenuOpen(true))
        }
        if (e.key === 'Escape') {
          dispatch(setSlashMenuOpen(false))
        }
        if (
          e.key === 'Alt' &&
          !(document.activeElement as HTMLElement).isContentEditable &&
          _transformer.getTransformer(whiteboard.pageId)?.isDragging
        ) {
          whiteboard._canvasDrag.handleDragAltKeyDown(whiteboard.pageId)
        }
      }}
      onKeyUp={(e) => {
        if (isPreview) return
        if (e.key === ' ') {
          dispatch(setTool(ITools.CURSOR))
        }
        if (e.key === 'Alt' && _transformer.getTransformer(whiteboard.pageId)?.isDragging) {
          whiteboard._canvasDrag.handleDragAltKeyUp()
        }
      }}
      tabIndex={0}
      onDrop={(e) => {
        if (isPreview) return
        const files = e.dataTransfer.files
        const file = files[0]

        if (file) {
          _upload.handleWbFileUpload(file, props.pageId)
          return
        }

        const position = {
          x: e.clientX,
          y: e.clientY,
        }

        const stage = _stage.getStage(props.pageId)

        if (!stage) return

        const scaledPosition = _stage.unScalePosition(props.pageId, position)

        const droppedData: TreeItem = JSON.parse(e.dataTransfer.getData('text/plain'))

        if (droppedData.type === PageTypesEnum.EMBED) {
          if (!droppedData.embedUrl) return

          if (droppedData.embedUrl?.startsWith('pageId')) {
            const pageId = droppedData.embedUrl.split('pageId-')[1]

            _createBlock.handleSelectLinkPage(props.pageId, pageId, scaledPosition)
          } else {
            const embedType = _embed.getEmbedTypeFromLink(droppedData.embedUrl, true)

            if (embedType) {
              _embedCreation.handleWbExternalLink(
                droppedData.embedUrl,
                embedType as EmbedType,
                props.pageId,
                undefined,
                scaledPosition,
                false,
              )
            }
          }
        } else {
          _createBlock.handleSelectLinkPage(props.pageId, droppedData.id, scaledPosition)
        }
      }}
      onScroll={(e) => {
        // Following is to prevent initial scroll
        // to portaled html elements if they are outside of viewport
        ;(e.target as HTMLDivElement).scrollTop = 0
        ;(e.target as HTMLDivElement).scrollLeft = 0
      }}
    >
      {isPresentation && !_whiteboardEmbed.isDocEmbed(props.pageId) && (
        <PresentationToolbar
          pageId={props.pageId}
          isSlideShow={props.isSlideShow}
          isPreview={isPreview}
        />
      )}

      {getLastBlock(props.pageId) && (!isPreview || (isPreview && props.isSlideShow)) && (
        <SlashMenu
          isWhiteboard
          isCentered
          open={slashMenuOpen}
          onClickOutside={() => dispatch(setSlashMenuOpen(false))}
          onSelect={(value: SingleValue<Option> | SlashMenuOptions) => {
            dispatch(setSlashMenuOpen(false))
            if (AllBlockEmbedTypes.includes(value?.type as IBlockTypes)) {
              _transformer.removeNodesFromTransformer(props.pageId)
              dispatch(setSelectedBlock(undefined))
            }
          }}
          block={getLastBlock(props.pageId)}
        />
      )}

      {!isPreview && !props.isEmbed && (
        <ZoomControls
          {...{
            pageId: props.pageId,
            zoomToPercentage: _zoom.percentageToScale,
            zoomPercentage: _zoom.zoomPercentage,
            setZoomPercentage: _zoom.setZoomPercentage,
          }}
        />
      )}

      {whiteboard.isWhiteboardLoaded && (
        <Stage
          id={`whiteboard-stage-${props.pageId}`}
          isEmbed={props.isEmbed}
          isSlideShow={props.isSlideShow}
          parentPageId={props.parentPageId}
          parentBlock={props.parentBlock}
          draggable={isStageDraggable()}
          onDragMove={whiteboard.handleStageDragMove}
          ref={onStageRefLoad}
          width={window.innerWidth}
          height={window.innerHeight}
          onWheel={shouldMoveAndZoom() ? _wheel.handleWheel : undefined}
          onTap={!isPreview ? whiteboard.handleClick : undefined}
          onClick={whiteboard.handleClick}
          onTouchStart={
            !isPreview
              ? whiteboard.handleMouseDown
              : () => {
                  setGrabbing()
                }
          }
          onMouseDown={
            !isPreview
              ? whiteboard.handleMouseDown
              : () => {
                  setGrabbing()
                }
          }
          onTouchMove={!isPreview ? whiteboard.handleMouseMove : undefined}
          onMousemove={!isPreview ? whiteboard.handleMouseMove : undefined}
          onTouchEnd={
            !isPreview
              ? whiteboard.handleMouseUp
              : () => {
                  setGrab()
                }
          }
          onMouseup={
            !isPreview
              ? whiteboard.handleMouseUp
              : () => {
                  setGrab()
                }
          }
          tool={ITools.CURSOR}
        >
          {!_whiteboardEmbed.isDocEmbed(props.pageId) && !props.isPreview && (
            <Layer id={getGridLayerId(props.pageId)} />
          )}

          <Layer id={getLayerId(props.pageId)}>
            {whiteboard.connectionPreviewArrow && (
              <Connector
                points={[
                  {
                    x: whiteboard.connectionPreviewArrow.xStart,
                    y: whiteboard.connectionPreviewArrow.yStart,
                  },
                  {
                    x: whiteboard.connectionPreviewArrow.xEnd,
                    y: whiteboard.connectionPreviewArrow.yEnd,
                  },
                ]}
              />
            )}
            {blocks &&
              blocks.map((block) => {
                if (block.data) {
                  return (
                    <WhiteboardBlock
                      key={`whiteboard-block-${block._id}`}
                      isPreview={isPreview}
                      block={block}
                    />
                  )
                } else return null
              })}
            <Transformer
              {...{
                pageId: props.pageId,
                whiteboard,
                isEmbed: props.isEmbed,
                parentPageId: props.parentPageId,
                onTransformerDragStart: whiteboard._canvasDrag.onTransformerDragStart,
                onTransformerDragEnd: whiteboard._canvasDrag.onTransformerDragEnd,
                onTransformerDrag: whiteboard._canvasDrag.onTransformerDrag,
              }}
            />
            {/* Ghost that follows mouse after shape tool selection */}
            <PreviewShapes />
            {/* Ghost block */}
            {whiteboard._canvasDrag.ghosts &&
              whiteboard._canvasDrag.ghosts.map((ghost) => {
                return (
                  <WhiteboardBlock
                    key={`whiteboard-shadow-block-${ghost._id}`}
                    isPreview={isPreview}
                    block={ghost}
                    isShadow
                  />
                )
              })}
          </Layer>
        </Stage>
      )}
    </WhiteboardComponent>
  )
}
