import Avatar from 'components/atoms/Avatar/Avatar'
import {
  DangerButton,
  DeleteContainer,
  SeparatorContainer,
  SpaceInfoSection,
  SpaceRowContainer,
  SpaceSettingsLabelStyled,
  SpaceSettingsName,
  SpaceSettingsStyled,
  ChangeButton,
} from './styles'
import Separator from 'components/molecules/Separator/Separator'
import Line from 'shared/ui/HorizontalLine'
import ChangeNameModal from 'components/molecules/ChangeNameModal/ChangeNameModal'
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal'
import { useSpaceSettings } from './useSpaceSettings'
import ChangeSpaceLogoModal from '../ChangeSpaceLogoModal/ChangeSpaceLogoModal'
import AvatarPlaceholder from 'assets/images/avatar-placeholder.png'
import { envVars } from 'config/env'
import { useAppSelector } from 'redux/hooks'

const SpaceSettings = () => {
  const {
    showChangeNameModal,
    setShowChangeNameModal,
    showChangeProfileModal,
    setShowChangeProfileModal,
    deleteSpaceModalShow,
    setDeleteSpaceModalShow,
    inputValue,
    setInputValue,
    showTooltip,
    setShowTooltip,
    deleteSpace,
    onMouseEnter,
    spaceName,
    setSpaceName,
    changeSpaceName,
  } = useSpaceSettings()
  const space = useAppSelector((state) => state.space.currentSpace)

  return (
    <SpaceSettingsStyled>
      <ChangeNameModal
        show={showChangeNameModal}
        handleClose={() => setShowChangeNameModal(false)}
        saveChanges={() => changeSpaceName()}
        onShow={() => void 0}
        setName={setSpaceName}
        name={spaceName ?? ''}
        placeholder={space?.name}
        maxLenght={27}
        isLastnameShown={false}
        label='Workspace name'
      />
      <ChangeSpaceLogoModal
        show={showChangeProfileModal}
        handleClose={() => setShowChangeProfileModal(false)}
      />
      <DeleteModal
        show={deleteSpaceModalShow}
        handleClose={() => setDeleteSpaceModalShow(false)}
        onShow={() => void 0}
        onChangeText={(e) => setInputValue(e.target.value)}
        deleteItem={space?.name || ''}
        onMouseEnter={() => onMouseEnter()}
        showTooltip={showTooltip}
        deleteButtonDisabled={inputValue !== 'Delete'}
        onMouseLeave={() => setShowTooltip(false)}
        saveChanges={() => space?.id && deleteSpace(space?.id as unknown as number)}
      />
      <SpaceInfoSection>
        <SpaceSettingsLabelStyled>Space name</SpaceSettingsLabelStyled>
        <SpaceRowContainer>
          <SpaceSettingsName>{space?.name}</SpaceSettingsName>
          <ChangeButton onClick={() => setShowChangeNameModal(true)}>Change name</ChangeButton>
        </SpaceRowContainer>
      </SpaceInfoSection>

      <SpaceInfoSection marginTop='1rem'>
        <SpaceSettingsLabelStyled>Space icon</SpaceSettingsLabelStyled>
        <Avatar
          src={space?.logo ? `${envVars.api.s3Butcket}${space?.logo}` : AvatarPlaceholder}
          userName={space?.name}
          userColor='var(--primary-color)'
          borderRadius='8px'
          height='32px'
          width='32px'
        />
        <ChangeButton onClick={() => setShowChangeProfileModal(true)} marginTop='0.35rem'>
          Upload photo
        </ChangeButton>
      </SpaceInfoSection>
      <SeparatorContainer>
        <Separator>
          <Line />
        </Separator>
      </SeparatorContainer>

      <DeleteContainer marginTop='2rem'>
        <SpaceSettingsLabelStyled>Delete this space</SpaceSettingsLabelStyled>
        <DangerButton className='btn' onClick={() => setDeleteSpaceModalShow(true)}>
          Delete space
        </DangerButton>
      </DeleteContainer>
    </SpaceSettingsStyled>
  )
}

export default SpaceSettings
