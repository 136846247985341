import styled from 'styled-components'

export const StyledThreadHeader = styled.button`
  background-color: transparent;
  border: none;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  svg path {
    fill: var(--primary-text);
  }
`

export const StyledThreadHeaderText = styled.span`
  font-weight: 600;
  font-size: 16px;
  color: var(--primary-text);
`
