import CustomP from 'components/atoms/CustomP/CustomP'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { icons } from 'shared/icons'
import { getColor } from 'shared/colors'

interface Props {
  onUndo: () => void
  onRedirect: () => void
}

const ProjectDuplicatedToast = (props: Props) => {
  return (
    <Wrapper
      display={'flex'}
      alignItems={'center'}
      width={'100%'}
      gap={'6px'}
      padding={'0 12px 0 6px'}
    >
      {' '}
      <icons.toastCheck />
      <CustomP fontSize={'16px'} whiteSpace={'nowrap'}>
        Project duplicated
      </CustomP>
      <CustomP
        fontSize={'14px'}
        cursorPointer
        color={getColor('--everyday-blue')}
        hoverbgcolor={getColor('--hover')}
        borderradius={'10px'}
        paddingRight={'10px'}
        paddingLeft={'10px'}
        paddingTop={'5px'}
        paddingBottom={'5px'}
        onClick={props.onUndo}
      >
        Undo
      </CustomP>
      <icons.arrowRightUp onClick={props.onRedirect} />
    </Wrapper>
  )
}

export default ProjectDuplicatedToast
