import styled from 'styled-components'

import { ReactComponent as ChevronRightIcon } from 'assets/images/chevron-right-black.svg'
import { ReactComponent as TextAUnderline } from 'assets/images/TextAUnderline.svg'
import { ReactComponent as TextColor } from 'assets/images/text-color.svg'
import { ReactComponent as ColorPicker } from 'assets/images/color-picker.svg'

import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { StyledWrapper as StyledWrapperBase } from 'components/molecules/Wrapper/styles'

export const TextAUnderlineIcon = styled(TextAUnderline)<{ fillColor?: string }>`
  path {
    fill: ${(props) => props.fillColor};
    stroke: var(--primary-text);
  }
`

export const TextColorIcon = styled(TextColor)<{ fillColor?: string }>`
  path {
    fill: ${(props) => props.fillColor};
  }
`

export const ColorPickerIcon = styled(ColorPicker)<{ fillColor?: string }>`
  path {
    fill: ${(props) => props.fillColor};
  }
`

export const ColorCircle = styled.div<{ fillColor?: string }>`
  width: 18px;
  height: 18px;
  border-radius: 999px;
  transition: none;

  background: ${(props) => props.fillColor};
`

export const EmptyDiv = styled.div``

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const RowDropdownTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-text);
  margin-right: 4px;
`

export const IconButtonRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`

export const CollapseRow = styled.div`
  display: flex;
  flex-direction: column;
`

export const Divider = styled.div`
  margin: 8px;
  height: 1px;
  background: var(--secondary-text);
  opacity: 0.1;
`

export const SidebarDivider = styled.div`
  margin: 0;
  height: 1px;
  background: var(--secondary-text);
  opacity: 0.1;
`

export const DropdownHeader = styled.div`
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
`
export const DropdownContent = styled.div`
  .tree-container {
    padding-left: 0;
    margin-bottom: 0;
  }
`

export const StyledTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-left: 6px;
  color: var(--secondary-text);
`

type EmbedContainerPropsType = {
  top?: number
  display?: string
}

export const EmbedContainer = styled.div<EmbedContainerPropsType>`
  top: ${(props) => props.top || ''};
  display: ${(props) => props.display || ''};
`

export const SidebarDropdown = styled.div`
  padding: 1px 8px;
`

export const PageBackgroundImageSettings = styled.div`
  padding: 8px;
`

export type ChevronRightIconRotatingProps = {
  turn?: boolean
}

export const ChevronRightIconRotating = styled(ChevronRightIcon)<ChevronRightIconRotatingProps>`
  transform: ${(props) => (props.turn ? 'rotate(-90deg)' : 'rotate(90deg)')};
`

export const BlockTitle = styled.input`
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
  padding: 6px;
  border-radius: 10px;
  outline: none;
  border: 1px solid transparent;
  width: 100%;
  background-color: var(--sidebars);
  color: var(--primary-text);

  &:focus {
    border: 1px solid var(--everyday-blue);
    background-color: var(--white);
  }
`

export const ActionWrapper = styled.div`
    margin-top: 4px;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px;
    font-size: 12px;
    margin-left: -4px;
    border-radius: 10px;
    cursor: pointer;

    svg {
        width: 16px;
        height: 16px;
`

export const PropertyWrapper = styled.div<{ width?: string }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: ${(props) => props.width || '50%'};

  &:hover {
    ${ActionWrapper} {
      background: var(--hover);
    }
  }
`

export const PropertyTitle = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: var(--secondary-text);
`

export const EmptyState = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #a3a3a3;
`

export const StyledWrapper = styled(Wrapper)`
  padding: 6px;
  margin-top: 8px;
`

export const AssigneesWrapper = styled.div`
  display: flex;

  ${StyledWrapperBase}:not(:first-child) {
    margin-left: -4px;
  }
`
