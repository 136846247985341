import { ProfileDrawerImageWrapper } from './styles'
import { StyledTitle } from 'components/organisms/ColorSettings/styles'
import {
  StyledIcon,
  StyledItemNewPageHeader,
} from 'components/organisms/Dropdowns/EmbedDropdown/Option/styles'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import React from 'react'
import EditorUploadInput from '_features/upload/ui/EditorUploadInput'
import { Spinner } from 'react-bootstrap'
import { useBackgroundImage } from '../hooks'
import { icons } from 'shared/icons'

export const SpaceImageBackgroundSetting = () => {
  const _useBackgroundImage = useBackgroundImage()

  return (
    <ProfileDrawerImageWrapper>
      <Wrapper display='flex' flexdirection='column'>
        <StyledTitle>Image background</StyledTitle>
        {_useBackgroundImage.loading ? (
          <Spinner size='sm' animation='border' />
        ) : (
          <>
            {_useBackgroundImage.backgroundImage ? (
              <>
                <Wrapper display='flex' flexdirection='column'>
                  <Wrapper display={'flex'} alignItems={'center'} gap={'6px'} cursor={'pointer'}>
                    <StyledIcon>
                      <icons.close onClick={_useBackgroundImage.removeImage} />
                    </StyledIcon>
                    {_useBackgroundImage?.name.length > 30
                      ? `${_useBackgroundImage?.name.slice(0, 30)}...`
                      : _useBackgroundImage?.name}
                  </Wrapper>
                </Wrapper>
              </>
            ) : (
              <EditorUploadInput
                type='file'
                id='space-image'
                onChange={(e) => {
                  _useBackgroundImage.handleFileUpload(e)
                }}
                label={
                  <Wrapper display={'flex'} alignItems={'center'} gap={'6px'} cursor={'pointer'}>
                    <StyledIcon>
                      <icons.upload />
                    </StyledIcon>
                    <StyledItemNewPageHeader>Upload</StyledItemNewPageHeader>
                  </Wrapper>
                }
              />
            )}
          </>
        )}
      </Wrapper>
    </ProfileDrawerImageWrapper>
  )
}
