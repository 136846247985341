import { type block, WhiteboardBlockProps } from '_entities/block'
import { InternalEmbedBlock } from '_entities/embed'
import { useAppSelector } from 'redux/hooks'
import Resizable from 'whiteboard/Resizable/Resizable'
import { useInternalEmbed } from './useInternalEmbed'
import { useEffect, useRef, useState } from 'react'
import { useTransformer } from '_features/canvas'
import { useOnClickOutside } from 'usehooks-ts'
import { getColor } from 'shared/colors'
import { getById } from 'shared/lib'
import { getDoc } from 'shared/shareDb'

export const getInternalEmbedRectId = (block: block) => {
  return `wb-internal-embed-rect-${block._id}`
}

const InternalEmbed = (props: WhiteboardBlockProps) => {
  const [enabled, setEnabled] = useState(false)
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const doc = getDoc(props.block.meta.pageId)
  const _internalEmbed = useInternalEmbed()
  const ref = useRef<HTMLDivElement | null>(null)
  const _transformer = useTransformer()

  const handleDblClick = () => {
    setEnabled(true)
  }

  const removeTransformerNodesFromEmbed = () => {
    const embedPage = _internalEmbed.getEmbedBlockPage(props.block)
    if (!embedPage) return
    _transformer.removeNodesFromTransformer(embedPage.id)
  }

  const handleClickOutside = () => {
    const toolbar = getById(_transformer.WHITEBOARD_BLOCK_TOOLBAR_ID)
    if (toolbar) return
    setEnabled(false)
    removeTransformerNodesFromEmbed()
  }

  useOnClickOutside(ref, handleClickOutside)

  useEffect(() => {
    if (_internalEmbed.EnablingTools.includes(tool)) {
      setEnabled(true)
    }
  }, [tool])

  const shouldEnable = () => !props.isPreview && selectedBlock?._id === props.block._id

  return (
    <Resizable
      {...props}
      onDblClick={handleDblClick}
      shouldEnable={shouldEnable()}
      strokeEnabled={enabled}
      stroke={getColor('--everyday-green')}
      strokeWidth={4}
      borderRadius={4}
    >
      {doc && (
        <InternalEmbedBlock innerRef={ref} block={props.block} isWhiteboard isEnabled={enabled} />
      )}
    </Resizable>
  )
}

export default InternalEmbed
