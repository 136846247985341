import { useEffect, useRef, useState } from 'react'
import {
  type block,
  checkIsFirstBlockFullBleed,
  getBlockGridWidth,
  getBlockIndent,
  getBlockLineHeight,
  getBlockPageId,
  GridBlockWidth,
  hasOriginalObject,
  IBlockTypes,
  isInstance,
  isMirror,
} from '_entities/block'
import { useComments } from '_features/comments'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ReactComponent as CommentIcon } from 'assets/images/block-toolbar/comment.svg'
import { useOnClickOutside } from 'usehooks-ts'
import { setIsEditing, setSelectedBlock } from 'redux/reducers/pageReducer'
import { useKeyHandler } from '../lib/useKeyHandler'
import { useActionMenu } from '../lib/useActionMenu'
import { IPermissions, Presence } from '_entities/user'
import { IconButton } from 'components/atoms/Button/Button'
import { usePaste } from '../lib/usePaste'
import EditorBlockHandle from 'editor/EditableBlock/EditorBlockHandle/EditorBlockHandle'
import BlockRenderer from 'editor/EditableBlock/BlockRenderer/BlockRenderer'
import BlockToolbar from 'editor/EditableBlock/BlockToolbar/BlockToolbar'
import {
  StyledBlock,
  StyledChatIcon,
  StyledChatIconActive,
  StyledChatIconReference,
  StyledChatIconTyping,
  StyledChatNumber,
  StyledIconWrapper,
} from 'editor/EditableBlock/styles'
import useOnPageClick from 'utils/useOnPageClick'
import MirrorIndicator from 'editor/EditableBlock/MirrorIndicator/MirrorIndicator'
import InstanceIndicator from 'editor/EditableBlock/InstanceIndicator/InstanceIndicator'
import ImageBlockToolbar from 'editor/EditableBlock/ImageBlockToolbar/ImageBlockToolbar'
import { useMessageThread } from '_features/chat'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { useBlockWidth } from '../lib/useBlockWidth'
import { isMobile } from 'react-device-detect'
import { EDITOR_BLOCK_NAME, EDITOR_BLOCK_TITLE } from '../lib/constants'
import { IUserAttributes } from 'interfaces/auth/userData'
import { EditorBlockAttrs, observeMutationOnBlockElement } from './attributes'
import { getUserPresence } from '../model/user-presence'
import { CtaToolbar } from '_entities/cta'

export type Props = {
  block: block
  userRole?: IPermissions
  isDropTarget?: boolean
  isDragging?: boolean
  dragPreview?: {
    width: number
  }
  handleRef?: React.RefObject<HTMLDivElement>
  isPreview?: boolean
}

export const DocumentBlock = (props: Props) => {
  // ** State
  const [userPresence, setUserPresence] = useState<IUserAttributes | undefined>()
  const [activeToolbarOption, setActiveToolbarOption] = useState<string | null>(null)

  // ** Redux state
  const selectedBlock = useAppSelector((state) => state.page.selectedBlock)
  const selectedObject = useAppSelector((state) => state.chat.selectedObject)
  const objectChatToView = useAppSelector((state) => state.chat.objectChatToView)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const chatToView = useAppSelector((state) => state.chat.objectChatToView)

  // ** Refs
  const toolbarRef = useRef<HTMLDivElement>(null)
  const imageToolbarRef = useRef<HTMLDivElement>(null)
  const ctaToolbarRef = useRef<HTMLDivElement>(null)

  // ** Hooks
  const _messageThread = useMessageThread()
  const _actionMenu = useActionMenu()
  const _paste = usePaste()
  const _keyHandler = useKeyHandler({
    actionMenuOpen: _actionMenu.actionMenuOpen,
    openActionMenu: _actionMenu.openActionMenu,
  })
  const _comments = useComments()
  const _blockWidth = useBlockWidth(props.block)
  const dispatch = useAppDispatch()
  const onPageClick = useOnPageClick()

  const handleClickOutside = () => {
    setActiveToolbarOption(null)
    _actionMenu.closeActionMenu()
    dispatch(setIsEditing(false))
  }

  useOnClickOutside(toolbarRef, handleClickOutside)
  useOnClickOutside(imageToolbarRef, handleClickOutside)
  useOnClickOutside(ctaToolbarRef, handleClickOutside)

  useEffect(() => {
    if (selectedBlock?._id === props.block._id) {
      dispatch(setSelectedBlock(props.block))
    }
  }, [props.block])

  useEffect(() => {
    observeMutationOnBlockElement(props.block._id, EditorBlockAttrs.USER_PRESENCE, () => {
      setUserPresence(getUserPresence(props.block))
    })
  }, [])

  return (
    <Wrapper
      width={_blockWidth.wrapperWidth}
      display='flex'
      alignItems='start'
      margin='auto'
      transition='all 0.3s ease-in-out'
      maxWidth={_blockWidth.maxWidth}
      marginTop={checkIsFirstBlockFullBleed(props.block) ? '0px' : '8px'}
      justifyContent={isMobile ? 'center' : 'flex-start'}
    >
      <StyledBlock
        lineHeight={getBlockLineHeight(props.block)}
        id={props.block._id}
        data-page-id={getBlockPageId(props.block)}
        data-block-name={EDITOR_BLOCK_NAME}
        title={EDITOR_BLOCK_TITLE}
        isPreview={props.isPreview}
        onClick={(e) => {
          e.stopPropagation()
          if (
            getBlockGridWidth(props.block) === GridBlockWidth.FULL_BLEED ||
            getBlockGridWidth(props.block) === GridBlockWidth.OUT_OF_GRID
          ) {
            _actionMenu.setActionMenuOpen(true)
          }
          dispatch(setSelectedBlock(props.block))
        }}
        onKeyDown={_keyHandler.handleKeyDown}
        indent={getBlockIndent(props.block)}
        onPaste={_paste.onPaste}
        isDropTarget={props.isDropTarget || chatToView?._id === props.block._id}
        isDragging={props.isDragging}
        dragPreview={props.dragPreview}
        position={'relative'}
        width={_blockWidth.blockWidth}
        maxWidth={_blockWidth.maxWidth}
      >
        {props.userRole !== IPermissions.CAN_VIEW &&
          props.userRole !== IPermissions.CAN_COMMENT &&
          !props.isPreview && (
            <EditorBlockHandle
              handleRef={props.handleRef}
              block={props.block}
              setActionMenuOpen={_actionMenu.setActionMenuOpen}
            />
          )}

        {props.userRole === IPermissions.CAN_COMMENT && (
          <StyledIconWrapper className='comment-icon'>
            <IconButton variant='transparent' size='small' onClick={_comments.handleOnCommentClick}>
              <CommentIcon />
            </IconButton>
          </StyledIconWrapper>
        )}

        <BlockRenderer
          block={props.block}
          userRole={props.userRole}
          actionMenuOpen={_actionMenu.actionMenuOpen}
          closeActionMenu={_actionMenu.closeActionMenu}
          openActionMenu={_actionMenu.openActionMenu}
          handleKeyDown={_keyHandler.handleKeyDown}
          activeToolbarOption={activeToolbarOption}
          setActionMenuOpen={_actionMenu.setActionMenuOpen}
          isPreview={props.isPreview}
        />

        {isMirror(props.block) && (
          <MirrorIndicator
            hasOriginalObject={hasOriginalObject(props.block)}
            block={props.block}
            onPageClick={onPageClick}
            pages={pages}
          />
        )}

        {isInstance(props.block) && (
          <InstanceIndicator
            hasOriginalObject={hasOriginalObject(props.block)}
            block={props.block}
            onPageClick={onPageClick}
            pages={pages}
            isMirror={isMirror(props.block)}
          />
        )}

        {_actionMenu.actionMenuOpen &&
          props.block.meta.relationshipType !== 'mirror' &&
          props.userRole !== IPermissions.CAN_VIEW &&
          !props.isPreview && (
            <>
              {(props.block.data.tag === IBlockTypes.IMAGE ||
                props.block.data.tag === IBlockTypes.IMAGE_TEXT) && (
                <ImageBlockToolbar
                  block={props.block}
                  closeActionMenu={_actionMenu.closeActionMenu}
                  innerRef={imageToolbarRef}
                  isEditor
                />
              )}

              {props.block.data.tag === IBlockTypes.CTA && (
                <CtaToolbar block={props.block} innerRef={ctaToolbarRef} />
              )}

              {props.block.data.tag !== IBlockTypes.IMAGE &&
                props.block.data.tag !== IBlockTypes.IMAGE_TEXT &&
                props.block.data.tag !== IBlockTypes.CTA && (
                  <BlockToolbar
                    block={props.block}
                    handleOnCommentClick={_comments.handleOnCommentClick}
                    closeActionMenu={_actionMenu.closeActionMenu}
                    innerRef={toolbarRef}
                    isEditor
                    activeToolbarOption={activeToolbarOption}
                    setActiveToolbarOption={setActiveToolbarOption}
                  />
                )}
            </>
          )}

        {objectChatToView?._id === props.block._id && props.block.meta.chatMessageIds ? (
          <StyledChatIconReference
            onClick={() => _messageThread.handleOpenMessageThread(props.block)}
            isMirror={isMirror(props.block)}
            isInstance={isInstance(props.block)}
          >
            <StyledChatIconActive />
            <StyledChatNumber className='active'>
              {props.block.meta.chatMessageIds.length}
            </StyledChatNumber>
          </StyledChatIconReference>
        ) : (
          <>
            {selectedObject?._id === props.block._id ? (
              <StyledChatIconReference
                onClick={() => _messageThread.handleOpenMessageThread(props.block)}
                isMirror={isMirror(props.block)}
                isInstance={isInstance(props.block)}
              >
                <StyledChatIconTyping />
                <StyledChatNumber></StyledChatNumber>
              </StyledChatIconReference>
            ) : (
              <>
                {props.block.meta.chatMessageIds && props.block.meta.chatMessageIds.length > 0 && (
                  <StyledChatIconReference
                    onClick={() => _messageThread.handleOpenMessageThread(props.block)}
                    isMirror={isMirror(props.block)}
                    isInstance={isInstance(props.block)}
                  >
                    <StyledChatIcon />
                    <StyledChatNumber>{props.block.meta.chatMessageIds.length}</StyledChatNumber>
                  </StyledChatIconReference>
                )}
              </>
            )}
          </>
        )}
        {userPresence && <Presence user={userPresence} />}
      </StyledBlock>
    </Wrapper>
  )
}
