import { useNavigate } from 'react-router-dom'
import { AxiosService } from 'services/axiosService/axiosService'
import { useAppSelector } from 'redux/hooks'
import { useInvites } from 'services/invites/useInvites'
import { ISpace } from 'interfaces/space'
import { IRoles } from 'interfaces/invite'
import { useViewer } from '_entities/viewer'

export const useUser = () => {
  const AxiosInstance = new AxiosService()
  const navigate = useNavigate()
  const _viewer = useViewer()
  const { removeInviteLink } = useInvites()
  const currentUser = useAppSelector((state) => state.global.user)

  const checkUser = async (
    shouldRedirectToSignupQuestions: boolean,
    setAreQuestionsAnswered?: React.Dispatch<React.SetStateAction<boolean>>,
    setIsCheckQuestions?: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    let isAnswered = ''
    let isProfilePhoto = 'null'
    const response = await _viewer.getAndSaveViewer()
    if (response) {
      removeInviteLink()
      isAnswered = JSON.stringify(response.industry)
      isProfilePhoto = JSON.stringify(response.photo)
      if (isProfilePhoto !== 'null') {
        const url = `/space/${response.spaces[0].id}`
        navigate(url, { state: { isFromLogin: true } })
      } else if (isAnswered) {
        navigate('/initialphotoupload', { replace: false })
      } else {
        setAreQuestionsAnswered && setAreQuestionsAnswered(false)
        shouldRedirectToSignupQuestions &&
          navigate &&
          navigate('/signupquestions', { replace: false })
      }
      if (setIsCheckQuestions) setIsCheckQuestions(true)
      _viewer.getProfileImage(response.id)
    } else {
      navigate('/signin')
    }
  }

  const getSpaces = async () => {
    const spaces: ISpace[] | undefined = await AxiosInstance.getMySpaces()
    let mySpaces: ISpace[] = []
    let sharedSpaces: ISpace[] = []
    if (spaces) {
      const mine = spaces.filter(
        (space: ISpace) =>
          space.spaceMembers.find((member) => member.user.id === currentUser?.id)?.role.type ===
          IRoles.OWNER,
      )
      mySpaces = mine
      const shared = spaces.filter(
        (space: ISpace) =>
          space.spaceMembers.find((member) => member.user.id === currentUser?.id)?.role.type !==
          IRoles.OWNER,
      )
      sharedSpaces = shared
    }
    return {
      allSpaces: spaces,
      mySpaces,
      sharedSpaces,
    }
  }

  const updateUserInfo = async (data: {
    genderId?: number
    age?: number
    location?: string
    aboutMe?: string
    firstName?: string
    lastName?: string
    email?: string
    password?: string
    newPassword?: string
    colorPaletteId?: number
  }) => {
    return await AxiosInstance.updateUserInfo(data)
  }

  const getUser = async (userId: string) => {
    return await AxiosInstance.getUser(userId)
  }

  const getWorkspaces = async () => {
    const { mySpaces, sharedSpaces } = await getSpaces()

    const spaces = mySpaces.map((space) => {
      return {
        ...space,
        label: space.name,
        value: space.id,
      }
    })

    const shared = sharedSpaces.map((space) => {
      return {
        ...space,
        label: space.name,
        value: space.id,
      }
    })

    return [...spaces, ...shared]
  }

  return {
    checkUser,
    getSpaces,
    updateUserInfo,
    getUser,
    getWorkspaces,
  }
}
