import React from 'react'
import styled from 'styled-components'
import { StyledFullScreenComponent } from './styles'

const FullScreenComponent = styled.div`
  ${StyledFullScreenComponent}
`
type Props = {
  onMouseDown?: (e: any) => void
  onKeyDown?: (e: React.KeyboardEvent) => void
  onKeyUp?: (e: React.KeyboardEvent) => void
  onClick?: () => void
  children: React.ReactNode
}
const index: React.FC<Props> = ({ onMouseDown, onKeyDown, onKeyUp, onClick, children }) => {
  return (
    <FullScreenComponent
      tabIndex={0}
      onMouseDown={onMouseDown}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onClick={onClick}
    >
      {children}
    </FullScreenComponent>
  )
}

export default index
