import { useEffect, useRef } from 'react'
import { Location, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setIsProjectFileEmpty, setUserRoles } from 'redux/reducers/projectFileReducer'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { useGetProjectFiles } from 'services/space/useGetProjectFiles'
import { IPage } from 'interfaces/page'
import { IProjectRole, PageTypesEnum, roleIdData } from 'interfaces/projectFile'
import { getMeFromMembers } from 'services/projectFile/getMembers'
import { getPages } from '_entities/project'
import { AxiosService } from 'services/axiosService/axiosService'
import { setSelectedPage as setSelectedPageState } from 'redux/reducers/pageReducer'
import { EMPTY_PAGE_ID } from '_entities/page'

export const useProjectFile = () => {
  /* Hook activations */
  const params = useParams()
  const AxiosInstance = new AxiosService()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { getAndDispatchProjectFile, getAndDispatchProjectFileMembers, cleanupProjectFile } =
    useGetProjectFile()

  const { getAndDispatchSpaceProjectFiles } = useGetProjectFiles()

  /* State fetches */

  const currentUser = useAppSelector((state) => state.global.user)

  const currentProjectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const isPresentationMode = useAppSelector((state) => state.projectFile.isPresentationMode)
  const myRole = useAppSelector((state) => state.projectFile.myProjectFileRole)
  const sidebarTabKey = useAppSelector((state) => state.projectFile.sidebarTabKey)
  const pages = useAppSelector((state) => state.projectFile.pages)

  /* Functions */

  const getLastVisitedPage = async (projectId: string): Promise<string | undefined> => {
    if (currentUser) {
      const meFromMembers = await getMeFromMembers(parseInt(projectId), currentUser.id)
      return meFromMembers?.lastVisitedPage
    }
  }

  const findDocumentPage = (pages: IPage[]) => {
    return pages.find((page) => page.type === PageTypesEnum.DOCUMENT)
  }

  const checkIfPageEmpty = () => {
    const pageId = searchParams.get('pageId')
    return pageId === EMPTY_PAGE_ID || pages?.length === 1
  }

  const handleNoPage = async (spaceId: string, projectId: string) => {
    const lastVisitedPage = await getLastVisitedPage(projectId)
    const pages: IPage[] = await getPages(projectId)
    const documentPage = findDocumentPage(pages)
    if (lastVisitedPage) {
      navigate(`/spaces/${spaceId}/project-file/${projectId}?pageId=${lastVisitedPage}`)
    } else if (documentPage) {
      navigate(`/spaces/${spaceId}/project-file/${projectId}?pageId=${documentPage?.id}`)
    } else {
      dispatch(setSelectedPageState(null))
      dispatch(setIsProjectFileEmpty(true))
      navigate(`/spaces/${spaceId}/project-file/${projectId}?pageId=${EMPTY_PAGE_ID}`)
    }
  }

  const fetchProjectFileRoles = async () => {
    const response = await AxiosInstance.getProjectFileRoles()
    if (response) {
      const rolesToDisplayWithoutGuestAndOwnerOptions = response.data.data.filter(
        (role: IProjectRole) =>
          role.id !== roleIdData.OWNER_ROLE_ID && role.id !== roleIdData.GUEST_ROLE_ID,
      )

      dispatch(
        setUserRoles(
          rolesToDisplayWithoutGuestAndOwnerOptions.map((option: IProjectRole) => {
            return {
              name: option.attributes.type,
              value: parseInt(option.id),
            }
          }),
        ),
      )
    }
  }

  const projectId = params.id
  const spaceId = params.spaceId
  const pageId = searchParams.get('pageId')
  const isSpaceIdAndProjectId = projectId && spaceId
  const isPageInSearchParams = isSpaceIdAndProjectId && pageId
  const isPageNotInSearchParams = isSpaceIdAndProjectId && !pageId
  const prevLocation = useRef<Location | null>(null)

  useEffect(() => {
    const numberPattern = /\d+/g
    const prevNumbers = prevLocation.current?.pathname.match(numberPattern)
    const prevProjectId = prevNumbers && prevNumbers[prevNumbers.length - 1]

    const isProjectFileEmpty = checkIfPageEmpty()
    if (isProjectFileEmpty) {
      dispatch(setIsProjectFileEmpty(true))
    } else {
      dispatch(setIsProjectFileEmpty(false))
    }

    if (prevProjectId && projectId !== prevProjectId) {
      setSelectedPage()
      return () => {
        cleanupProjectFile()
      }
    } else {
      setSelectedPage()
    }
  }, [location])

  const setSelectedPage = () => {
    if (isPageInSearchParams) {
      getAndDispatchProjectFile(parseInt(projectId), spaceId)
      getAndDispatchProjectFileMembers(parseInt(projectId))
      getAndDispatchSpaceProjectFiles(spaceId)
      fetchProjectFileRoles()
    } else if (isPageNotInSearchParams) {
      handleNoPage(spaceId, projectId)
    } else navigate(`/space/${spaceId}`)
  }

  return {
    setSelectedPage,
    currentProjectFile,
    myRole,
    isPresentationMode,
    sidebarTabKey,
  }
}
