import React from 'react'
import { CustomPopup } from './styles'
import CustomP from '../CustomP/CustomP'

type Props = {
  message?: string
  fontSize?: string
  color?: string
  className?: string
  width?: string
  height?: string
  left?: string
  top?: string
  right?: string
  bottom?: string
  active?: boolean
  justify?: string
  alignitems?: string
  flexdirection?: string
  zindex?: string
  aligntext?: string
  padding?: string
  borderradius?: string
}

const Tooltip: React.FC<Props> = ({
  message,
  fontSize,
  color,
  className,
  width,
  height,
  left,
  top,
  right,
  bottom,
  active,
  justify,
  alignitems,
  flexdirection,
  zindex,
  aligntext,
  padding,
  borderradius,
}) => {
  return (
    <CustomPopup
      width={width}
      height={height}
      left={left}
      top={top}
      right={right}
      bottom={bottom}
      active={active}
      justify={justify}
      alignitems={alignitems}
      zindex={zindex}
      aligntext={aligntext}
      padding={padding}
      borderradius={borderradius}
    >
      <CustomP fontSize={fontSize} color={color} className={className}>
        {message}
      </CustomP>
    </CustomPopup>
  )
}

Tooltip.defaultProps = {
  width: '111px',
  height: '33px',
  top: '43px',
  color: 'white',
  zindex: '100',
}

export default Tooltip
