import DropdownItem from '../DropdownItem/DropdownItem'
import { StyledDropdown } from './styles'
import { INewDropdownOption } from 'interfaces/dropdownOptions'
import { StyledComponent } from 'styled-components'
import { Dropdown } from 'react-bootstrap'
import { Styles } from 'components/organisms/ChoosePages/styles'
interface Props {
  dropdownOptions: INewDropdownOption[]
  onSelect?: () => void
  styles?: string
  toggleButton?: JSX.Element
  menuStylesOverrides?: string
  paddingDropdown?: string
  positionDropdown?: string
  widthDropdown?: string
  transformMenu?: string
  topDropdown?: string
  rightDropdown?: string
  menuMargin?: string
  menuPadding?: string
  cursor?: string
  onToggle?: () => void
  transform?: string
  positionDropdownMenu?: string
  transitionDropdownMenu?: string
  dropdownItemDisplay?: string
  dropdownItemWidth?: string
  dropdownItemPadding?: string
  dropdownMenuMinWidth?: string
  customMenuWrapper?: StyledComponent<'div', any>
  onClick?: () => void
  onTogglerClick?: () => void
  flexShrink?: string
  className?: string
  iconWidth?: string
  dropdownSelector?: string
  autoClose?: true | 'outside' | 'inside' | false
  innerRef?: React.MutableRefObject<typeof Dropdown | null>
  isMenuHidden?: boolean
  open?: boolean
  backgroundColor?: string
  color?: string
}
export const CustomDropdown = (props: Props) => {
  return (
    <StyledDropdown
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        props.onTogglerClick && props.onTogglerClick()
      }}
      open={props.open}
      flexShrink={props.flexShrink}
      onToggle={props.onToggle}
      position={props.positionDropdown}
      width={props.widthDropdown}
      top={props.topDropdown}
      right={props.rightDropdown}
      transform={props.transform}
      padding={props.paddingDropdown}
      cursor={props.cursor}
      menuMargin={props.menuMargin}
      menuPadding={props.menuPadding}
      menuTransform={props.transformMenu}
      menuPosition={props.positionDropdownMenu}
      menuTransition={props.transitionDropdownMenu}
      menuMinwidth={props.dropdownMenuMinWidth}
      iconWidth={props.iconWidth}
      autoClose={props.autoClose}
      ref={props.innerRef}
      isMenuHidden={props.isMenuHidden}
      styles={Styles()}
      backgroundColor={props.backgroundColor}
      color={props.color}
    >
      <Dropdown.Toggle as={'div'} className={props.className}>
        {props.toggleButton}
      </Dropdown.Toggle>
      <StyledDropdown.Menu id={props.dropdownSelector}>
        {props.customMenuWrapper && (
          <props.customMenuWrapper>
            {props.dropdownOptions.map((option, i) => (
              <DropdownItem
                dropdownItemDisplay={props.dropdownItemDisplay}
                dropdownItemWidth={props.dropdownItemWidth}
                dropdownItemPadding={props.dropdownItemPadding}
                key={`${option.id + i.toString()}`}
                eventKey={option.eventKey}
                onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  e.stopPropagation()
                  option.onClick && option.onClick(e)
                  props.onSelect && props.onSelect()
                }}
                element={option.element}
              />
            ))}
          </props.customMenuWrapper>
        )}

        {!props.customMenuWrapper &&
          props.dropdownOptions.map((option, i) => (
            <DropdownItem
              dropdownItemDisplay={props.dropdownItemDisplay}
              dropdownItemWidth={props.dropdownItemWidth}
              key={`${option.id + i.toString()}`}
              eventKey={option.eventKey}
              onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
                option.onClick && option.onClick(e)
                props.onSelect && props.onSelect()
              }}
              element={option.element}
            />
          ))}
      </StyledDropdown.Menu>
    </StyledDropdown>
  )
}
