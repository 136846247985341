import { AxiosService } from 'services/axiosService/axiosService'
import { useAppSelector } from 'redux/hooks'
import { useToast } from 'services/helpers/useToast'
import { useState } from 'react'

export const usePageMembers = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const toast = useToast()

  const customDropdownWidth = '141px'
  const customDropdownRight = '118px'
  const customDropdownBottom = '-10px'
  const pendingDropdownOptionPadding = '8px 12px'
  const AxiosInstance = new AxiosService()
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const pageRoles = useAppSelector((state) => state.page.pageRoles)
  const pageRolesOwnerBackend = useAppSelector((state) => state.page.pageRolesOwner)
  const extendedPageRolesOwner = pageRolesOwnerBackend?.map((obj) => ({
    ...obj,
    label: obj.name,
  }))

  const pageRolesOwner = extendedPageRolesOwner
    ?.slice(0, -1)
    .map((obj: any) => ({ ...obj, isCheckmark: true }))

  if (extendedPageRolesOwner) {
    pageRolesOwner?.push(extendedPageRolesOwner[extendedPageRolesOwner.length - 1])
  }

  const updateMember = async (memberId: string, pageId: string, roleId: number) => {
    const response = await AxiosInstance.updatePageMember(memberId, pageId, roleId)
    if (response) toast.success('Successfully updated page member role!')
  }

  const resendInvite = async (pageId: string, inviteToken: string) => {
    const response = await AxiosInstance.resendPageInvite(pageId, inviteToken)
    if (response) toast.success('Invite email resended!')
  }

  const removeMemberFromPage = async (pageId: string, memberId: string) => {
    const response = await AxiosInstance.removeFromPage(pageId, memberId)
    if (response) return response
  }

  const removePendingUserFromPage = async (pageId: string, inviteId: string) => {
    const response = await AxiosInstance.removePendingUserFromPage(pageId, inviteId)
    if (response) return response
  }

  const approveOrRejectRequestAccess = async (inviteToken: string, accept: boolean) => {
    if (selectedPage) {
      setLoading(true)
      const response = await AxiosInstance.confirmAccessToPage(
        selectedPage?.id,
        inviteToken,
        accept,
      )
      if (response) {
        setLoading(false)
        return response
      }
      setLoading(false)
    }
  }

  return {
    loading,
    updateMember,
    pageRoles,
    resendInvite,
    removeMemberFromPage,
    pageRolesOwner,
    removePendingUserFromPage,
    selectedPage,
    customDropdownWidth,
    customDropdownRight,
    customDropdownBottom,
    pendingDropdownOptionPadding,
    extendedPageRolesOwner,
    approveOrRejectRequestAccess,
  }
}
