import Konva from 'konva'
import { KonvaEventObject } from 'konva/lib/Node'
import { useStage, useTransformer, IConnector, useIntersection } from '_features/canvas'
import { useEvent } from '_features/canvas/event'
import { IArrowItem } from 'interfaces/whiteboard'
import { getIndex, type block } from '_entities/block'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { JSONOp } from 'ot-json1'
import { useLayer } from '_entities/whiteboard'
import { getColor } from 'shared/colors'
import { Shape, ShapeConfig } from 'konva/lib/Shape'
import { sceneFunc } from 'whiteboard/Connector/sceneFunc'
import { getBlockById, getBlocks } from 'shared/shareDb'

interface Props {
  connectionPreviewArrow: IArrowItem | null
  setConnectionPreviewArrow: (arrow: IArrowItem | null) => void
  connectors: IConnector[]
  setConnectors: (connectors: IConnector[]) => void
}

export const useConnectors = (props: Props) => {
  const _stage = useStage()
  const _event = useEvent()
  const _intersection = useIntersection()
  const _transformer = useTransformer()
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _layer = useLayer()

  const addConnector = (e: KonvaEventObject<DragEvent>) => {
    const pageId = _event.getPageIdFromEvent(e)
    if (props.connectionPreviewArrow) {
      props.setConnectionPreviewArrow(null)
      const pointerPosition = _stage.getRealPointerPosition(pageId)
      if (pointerPosition) {
        const nodes = _stage.getAllNodes(pageId)
        nodes?.forEach((node) => {
          const rect = node.getClientRect()
          const isIntersection = _intersection.hasPointIntersection(pointerPosition, rect)
          if (isIntersection) {
            const fromNode = _transformer.getSingleNodeFromTransformer(pageId)
            const fromBlock = getBlockById(pageId, fromNode?.attrs.id)
            const toBlock = getBlockById(pageId, node.attrs.id)
            if (!fromBlock || !toBlock) return
            const fromOp = addFromConnectionOp(pageId, toBlock, fromBlock)
            const toOp = addToConnectionOp(pageId, fromBlock, toBlock)
            if (fromOp && toOp) {
              const combinedOp = _json1.combineOperations([fromOp, toOp])
              _submit.submit(pageId, combinedOp, SourceKeys.UPDATE_BLOCK)
            }
          }
        })
      }
    }
  }

  const addFromConnectionOp = (
    pageId: string,
    block: block,
    fromBlock: block,
  ): JSONOp | undefined => {
    let op: JSONOp | undefined
    const index = getIndex(block)
    const connectionFromValue = block.data.connectionFrom
    if (!connectionFromValue) {
      op = _json1.getInsertKeyInDataKeyOp(index, ['connectionFrom'], [fromBlock._id])
    } else if (!connectionFromValue.includes(fromBlock._id)) {
      op = _json1.getReplaceBlockDataKeyOp(
        index,
        ['connectionFrom'],
        [...connectionFromValue, fromBlock._id],
        connectionFromValue,
      )
    }
    return op
  }

  const addToConnectionOp = (pageId: string, block: block, toBlock: block): JSONOp | undefined => {
    let op: JSONOp | undefined
    const index = getIndex(block)
    const connectionToValue = block.data.connectionTo
    if (!connectionToValue) {
      op = _json1.getInsertKeyInDataKeyOp(index, ['connectionTo'], [toBlock._id])
    } else if (!connectionToValue.includes(toBlock._id)) {
      op = _json1.getReplaceBlockDataKeyOp(
        index,
        ['connectionTo'],
        [...connectionToValue, toBlock._id],
      )
    }
    return op
  }

  function generateConnectors(pageId: string) {
    const result: IConnector[] = []
    const blocks = getBlocks(pageId)
    if (blocks && blocks.length) {
      blocks.forEach((block) => {
        if (block && block.data && block.data.connectionTo && block.data.connectionTo.length) {
          block.data.connectionTo.forEach((id) => {
            const from = block._id
            const to = id
            const connectorId = `connector-${block._id}-${id}`
            result.push({
              id: connectorId,
              from: from,
              to: to,
            })
          })
        }
      })
      props.setConnectors(result)
      deleteAllConnectorNodes(pageId)
      const layer = _layer.getLayer(pageId)
      result.forEach((connectorData) => {
        const existingLine = layer?.findOne('#' + connectorData.id)
        if (!existingLine) {
          const line = new Konva.Shape({
            stroke: getColor('--gray2'),
            id: connectorData.id,
            strokeWidth: 2,
            name: 'connector',
          })
          layer?.add(line)
        }
      })

      return result
    }
  }

  const findAllConnectorNodes = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (stage) return stage.find('.connector')
  }

  const deleteAllConnectorNodes = (pageId: string) => {
    const connectorNodes = findAllConnectorNodes(pageId)
    if (connectorNodes) {
      connectorNodes.forEach((node) => {
        node.remove()
      })
    }
  }

  function updateConnectors(pageId: string) {
    const layer = _layer.getLayer(pageId)
    props.connectors.forEach((connect) => {
      if (!layer) return
      const stage = _stage.getStage(pageId)
      const line: Shape<ShapeConfig> = layer?.findOne('#' + connect.id)
      const fromNode = layer?.findOne('#' + connect.from)
      const toNode = layer?.findOne('#' + connect.to)
      if (stage && line && fromNode && toNode) {
        const fromRect = fromNode.getClientRect()
        const toRect = toNode.getClientRect()
        const scaledFromCoordinates = _stage.unScalePosition(pageId, fromRect)
        const scaledToCoordinates = _stage.unScalePosition(pageId, toRect)
        const scaledFromRect = _stage.unScaleSize(pageId, fromRect)
        const scaledToRect = _stage.unScaleSize(pageId, toRect)
        if (scaledFromCoordinates && scaledToCoordinates && scaledFromRect && scaledToRect) {
          const from = {
            x: scaledFromCoordinates.x + scaledFromRect.width / 2,
            y: scaledFromCoordinates.y + scaledFromRect.height / 2,
          }
          const to = {
            x: scaledToCoordinates.x + scaledToRect.width / 2,
            y: scaledToCoordinates.y + scaledToRect.height / 2,
          }
          line.moveToBottom()
          line.sceneFunc(sceneFunc([from, to]))
        }
      }
    })
  }

  const drawConnector = (pageId: string) => {
    if (props.connectionPreviewArrow) {
      const position = _stage.getScaledPointerPosition(pageId)
      if (position) {
        props.setConnectionPreviewArrow({
          ...props.connectionPreviewArrow,
          xEnd: position?.x ?? 0,
          yEnd: position?.y ?? 0,
        })
      }
    }
  }
  return {
    addConnector,
    generateConnectors,
    updateConnectors,
    drawConnector,
  }
}
