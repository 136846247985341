import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ChatDrawerContainer = styled(motion.div)`
  height: 100vh;

  background: var(--sidebars);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  overflow: hidden;
`

export const ChatDrawerContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
`

export const ChatDrawerHead = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px 20px 54px 20px;
  background-color: var(--tasks-background);

  svg path {
    fill: var(--primary-text);
    stroke: var(--primary-text);
  }
`

export const ChatDrawerDropdownContainer = styled.div`
  padding: 10px 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`

export const ChatDrawerDropdown = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ChatDropdownTitle = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: var(--primary-text);
  padding: 8px;
`

export const ChatDrawerBody = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  & .str-chat__channel-list {
    width: 30%;
  }

  & .str-chat__channel {
    width: 100%;
  }

  & > .str-chat__attachment-list-scroll-container {
    z-index: 10 !important;
    background-color: var(--sidebars);
  }

  & .str-chat__message-list-scroll {
    padding: 0 !important;
    background: var(--sidebars);
  }

  .str-chat__list {
    background: var(--sidebars);
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .str-chat__list::-webkit-scrollbar {
    display: none;
  }

  .str-chat__empty-channel {
    background: var(--sidebars);
  }

  .str-chat__message-action-icon path {
    fill: var(--primary-text);
  }

  .str-chat__message-simple__actions__action:nth-child(2) .str-chat__message-action-icon path {
    fill: none;
    stroke: var(--primary-text);
  }

  & .str-chat__thread {
    position: absolute;
    background: var(--sidebars);
    border-inline-start: none;

    & .str-chat__reverse-infinite-scroll {
      bottom: 0;
      width: 100%;
    }

    & .thread-indicator {
      display: none;
    }

    & .str-chat__textarea {
      background: var(--sidebars);
    }

    & .str-chat__parent-message-li {
      padding: 24px 20px 0 20px;
      margin-bottom: 0;
      margin-inline: -19px;
    }

    & .str-chat__message-replies-count-button,
    .str-chat__thread-start {
      display: none;
    }

    & .str-chat__list .str-chat__parent-message-li {
      border-block-end: none;
    }

    & .str-chat__li {
      padding: 10px 0 !important;
      position: relative !important;
    }

    & .str-chat__li:empty {
      display: none;
    }

    & .selected-object-wrapper {
      margin-left: 20px !important;
      width: 85%;
    }

    & .message-content {
      border-left: 1px solid #d6d6d6;
      margin-left: 11px;
      color: var(--primary-text);
    }

    & .str-chat__message-text-inner {
      p {
        color: var(--primary-text);
        padding-left: 20px;
      }
    }

    & .str-chat__list--thread {
      padding: 0 12px 122px 12px !important;
    }

    & .str-chat__input-flat--textarea-wrapper {
      position: fixed !important;
      width: 100%;
      bottom: 0;
      background-color: var(--sidebars);
    }

    & .str-chat__message-list-scroll {
      margin-bottom: 120px !important;
      padding: 0 !important;
    }

    & .rfu-file-upload-button {
      z-index: 10 !important;
    }

    & .str-chat__attachment-preview-list {
      margin-bottom: 110px;
    }

    & .str-chat__message-actions-list-item:last-child {
      display: none;
    }
  }

  & .str-chat__message-actions-list-item {
    &:hover {
      background: var(--hover) !important;
    }
  }

  .str-chat__message-actions-list {
    background: var(--overlay-modal);
    .str-chat__message-actions-list-item {
      color: var(--primary-text);
    }

    & .str-chat__message-actions-list-item:last-child,
    .str-chat__message-actions-list-item:nth-last-child(2) {
      color: red;
    }
  }

  .str-chat__message-actions-box {
    box-shadow: none;
  }

  & .str-chat__message-simple__actions {
    margin-top: 0 !important;
    display: none !important;
  }

  & .str-chat__li {
    margin: 0 !important;
    padding: 10px 22px;
    position: relative !important;

    &:hover {
      background: rgba(0, 0, 0, 0.03) !important;

      & .str-chat__message-text-inner {
        background: rgba(0, 0, 0, 0) !important;
      }

      & .str-chat__message-simple__actions {
        position: absolute !important;
        right: 10px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: space-between !important;
        height: 24px !important;
        z-index: 1 !important;
        padding: 0 4px 0 !important;
      }

      & .str-chat__message-simple__actions__action--reactions {
        display: flex !important;
      }

      & str-chat__message-simple__actions__action--options {
        display: flex !important;
      }

      & .str-chat__message-simple__actions__action--thread {
        display: flex !important;
      }
    }
  }

  & .str-chat__message-actions-box {
    top: initial !important;
    left: -155px !important;
    border-radius: 16px !important;
    inset-block-end: initial !important;
  }

  & .str-chat__li:last-child {
    & .str-chat__message-actions-box {
      inset-block-end: 100% !important;
    }
  }

  & .str-chat__message-text {
    display: flex !important;
    width: 100% !important;
  }

  & .str-chat__message-text-inner {
    & p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      padding-left: 30px;
      color: var(--primary-text);
      margin-bottom: 12px;
    }
  }

  & .str-chat__simple-reactions-list-item {
    border: solid 1px var(--gray-inactive);
    border-radius: 8px;
    padding: 2px 8px;
  }

  & .str-chat__message-reaction-own {
    border: solid 1px var(--primary-color);
  }

  & .str-chat__message-replies-count-button {
    font-family: Helvetica Neue, sans-serif !important;
    font-weight: 500 !important;
    font-size: 13px !important;
    text-align: right !important;
    border: none !important;
    background-color: transparent !important;
    color: var(--primary-color);
  }

  & .str-chat__message-reactions-container {
    margin-left: 30px;
  }

  & .str-chat__simple-reactions-list {
    border-radius: 10px !important;
  }

  & .str-chat__reaction-selector {
    max-height: 45px !important;
    top: -58px !important;
    right: 20px !important;
    border-radius: 20px !important;
    background: var(--sidebars) !important;
  }

  .str-chat__message-reactions-list,
  .str-chat__message-reactions-list-item {
    background: var(--overlay-modal) !important;
  }

  .str-chat__message-reactions-list-item {
    &:hover {
      background: var(--hover) !important;
    }
  }

  .str-chat__emoji-picker-container * {
    background: var(--overlay-modal) !important;
    color: var(--primary-text) !important;

    svg path {
      fill: var(--primary-text) !important;
    }
  }

  & .str-chat__message-reactions-list {
    padding: 0 !important;
  }

  & .str-chat__reaction-selector .str-chat__message-reactions-option {
    max-height: 40px !important;
  }

  & .str-chat__message-reactions-list-item__count {
    color: #000 !important;
  }

  & .str-chat__message-simple-status {
    right: 22px !important;
  }

  & .quoted-message-inner {
    margin-top: 10px !important;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15) !important;
  }

  & .str-chat__li .quoted-message.mine .quoted-message-inner {
    background: #ebebeb !important;
  }

  & .str-chat__message-actions-box-button,
  & .str-chat__message-simple__actions__action {
    border: none;
    padding: 0px 4px;
    background-color: transparent;
  }

  & .str-chat__date-separator {
    text-align: center;
    display: inline;
  }

  & .str-chat__date-separator .str-chat__date-separator-line {
    display: none !important;
  }

  & .str-chat__date-separator .str-chat__date-separator-date {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: var(--secondary-text);
  }

  & .str-chat__input-flat--textarea-wrapper {
    position: relative !important;
    border-top: solid 1px var(--secondary-text) !important;
  }

  & .str-chat__input-flat--textarea-wrapper textarea {
    width: 95% !important;
    border-top: 1px solid var(--secondary-text);
    border-left: 1px solid var(--secondary-text);
    border-right: 1px solid var(--secondary-text);
    border-radius: 8px 8px 0 0 !important;
    padding: 12px 12px !important;
    margin: 12px !important;
    resize: none !important;
    background: var(--sidebars);
  }

  & .str-chat__input-flat--textarea-wrapper textarea::placeholder {
    color: var(--secondary-text);
    font-size: 14px;
  }

  & .str-chat__input-flat--textarea-wrapper textarea:focus-visible {
    outline: none !important;
  }

  & .rfu-file-upload-button {
    position: absolute !important;
    width: 24px !important;
    height: 24px !important;
    opacity: 0 !important;
    z-index: 10;
    pointer-events: all;
  }

  & .rfu-file-upload-button input {
    width: 24px;
    height: 24px;
  }

  & .rfu-file-upload-button svg {
    display: none;
  }

  & .str-chat__list-notifications {
    display: none !important;
  }
`

export const ChatDrawerGrow = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;
`
