import React from 'react'
import { StyledSpaceGroups } from './styles'

const SpaceGroups = () => {
  return (
    <StyledSpaceGroups className='ps-5 ms-1 fw-bold text-muted opacity-50 mb-3'>
      Everyday's space groups. Coming soon!
    </StyledSpaceGroups>
  )
}

export default SpaceGroups
