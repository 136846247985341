import { type block } from '_entities/block'

// SVG ids
export const getCircleId = (block: block) => {
  return `wb-resizable-circle-${block._id}`
}
export const getSquareId = (block: block) => {
  return `wb-resizable-square-${block._id}`
}
export const getSquareCId = (block: block) => {
  return `wb-resizable-squareC-${block._id}`
}
export const getTriangleId = (block: block) => {
  return `wb-resizable-triangle-${block._id}`
}
export const getHexagonId = (block: block) => {
  return `wb-resizable-hexagon-${block._id}`
}
export const getPentagonId = (block: block) => {
  return `wb-resizable-pentagon-${block._id}`
}
export const getRombId = (block: block) => {
  return `wb-resizable-romb-${block._id}`
}

// Path ids

export const getCirclePathId = (block: block) => {
  return `wb-resizable-circle-path-${block._id}`
}

export const getSquarePathId = (block: block) => {
  return `wb-resizable-square-path-${block._id}`
}

export const getSquareCPathId = (block: block) => {
  return `wb-resizable-squareC-path-${block._id}`
}

export const getTrianglePathId = (block: block) => {
  return `wb-resizable-triangle-path-${block._id}`
}

export const getHexagonPathId = (block: block) => {
  return `wb-resizable-hexagon-path-${block._id}`
}

export const getPentagonPathId = (block: block) => {
  return `wb-resizable-pentagon-path-${block._id}`
}

export const getRombPathId = (block: block) => {
  return `wb-resizable-romb-path-${block._id}`
}

export interface ShapeArgs {
  block: block
  fill: string
  width?: number
  height?: number
  stroke?: string
  strokeWidth?: number
  dash?: number[]
}

export const CircleShape = (args: ShapeArgs) => {
  return (
    <svg
      id={getCircleId(args.block)}
      width={args.width}
      height={args.height}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <circle
        id={getCirclePathId(args.block)}
        cx='50'
        cy='50'
        r='49.3'
        fill={args.fill}
        stroke={args.stroke}
        strokeWidth={args.strokeWidth}
        strokeDasharray={args.dash?.join(',')}
      />
    </svg>
  )
}

export const SquareShape = (args: ShapeArgs) => {
  return (
    <svg
      id={getSquareId(args.block)}
      width={args.width}
      height={args.height}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <path
        id={getSquarePathId(args.block)}
        d='M0.700005 96L0.700005 4C0.700006 2.17746 2.17747 0.699996 4.00001 0.699996L96 0.699997C97.8225 0.699997 99.3 2.17746 99.3 4L99.3 96C99.3 97.8225 97.8225 99.3 96 99.3L4.00001 99.3C2.17746 99.3 0.700005 97.8225 0.700005 96Z'
        fill={args.fill}
        stroke={args.stroke}
        strokeWidth={args.strokeWidth}
        strokeDasharray={args.dash?.join(',')}
      />
    </svg>
  )
}

export const SquareCShape = (args: ShapeArgs) => {
  return (
    <svg
      id={getSquareCId(args.block)}
      width={args.width}
      height={args.height}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <path
        id={getSquareCPathId(args.block)}
        d='M0.700006 80L0.700004 20C0.700005 9.3409 9.34091 0.699997 20 0.699998L80 0.699995C90.6591 0.699996 99.3 9.3409 99.3 20L99.3 80C99.3 90.6591 90.6591 99.3 80 99.3L20 99.3C9.34091 99.3 0.700005 90.6591 0.700006 80Z'
        fill={args.fill}
        stroke={args.stroke}
        strokeWidth={args.strokeWidth}
        strokeDasharray={args.dash?.join(',')}
      />
    </svg>
  )
}

export const TriangleShape = (args: ShapeArgs) => {
  return (
    <svg
      id={getTriangleId(args.block)}
      width={args.width}
      height={args.height}
      viewBox='0 0 96 96'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <path
        id={getTrianglePathId(args.block)}
        d='M50.9516 3.46846L94.4795 90.5242C95.5766 92.7184 93.981 95.3 91.5279 95.3L4.47214 95.3C2.01898 95.3 0.423439 92.7184 1.52052 90.5242L45.0484 3.46846C46.2645 1.0362 49.7355 1.0362 50.9516 3.46846Z'
        fill={args.fill}
        stroke={args.stroke}
        strokeWidth={args.strokeWidth}
        strokeDasharray={args.dash?.join(',')}
      />
    </svg>
  )
}

export const HexagonShape = (args: ShapeArgs) => {
  return (
    <svg
      id={getHexagonId(args.block)}
      width={args.width}
      height={args.height}
      viewBox='0 0 100 100'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <path
        id={getHexagonPathId(args.block)}
        d='M0.700015 72.5279L0.700019 27.4874C0.700019 26.2309 1.41355 25.0834 2.54045 24.5277L49.1886 1.52312C50.1302 1.05874 51.2367 1.07018 52.1686 1.55392L97.5204 25.0967C98.6138 25.6643 99.3 26.7937 99.3 28.0256L99.3 72.4412C99.3 73.6894 98.5957 74.8308 97.4801 75.3907L51.4768 98.4757C50.547 98.9423 49.4515 98.9431 48.521 98.4778L2.52421 75.4795C1.40623 74.9205 0.700014 73.7778 0.700015 72.5279Z'
        fill={args.fill}
        stroke={args.stroke}
        strokeWidth={args.strokeWidth}
        strokeDasharray={args.dash?.join(',')}
      />
    </svg>
  )
}

export const PentagonShape = (args: ShapeArgs) => {
  return (
    <svg
      id={getPentagonId(args.block)}
      width={args.width}
      height={args.height}
      viewBox='0 0 99 99'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <path
        id={getPentagonPathId(args.block)}
        d='M18.7248 96.01L1.01804 40.9347C0.595708 39.6211 1.03528 38.1833 2.12002 37.3305L47.6874 1.50344C48.8889 0.558803 50.5818 0.562747 51.7788 1.51294L96.9128 37.34C97.9824 38.1891 98.4185 39.6092 98.0099 40.9122L80.7382 95.9875C80.3067 97.3635 79.0316 98.3 77.5894 98.3L21.8664 98.3C20.433 98.3 19.1635 97.3746 18.7248 96.01Z'
        fill={args.fill}
        stroke={args.stroke}
        strokeWidth={args.strokeWidth}
        strokeDasharray={args.dash?.join(',')}
      />
    </svg>
  )
}

export const RombShape = (args: ShapeArgs) => {
  return (
    <svg
      id={getRombId(args.block)}
      width={args.width}
      height={args.height}
      viewBox='0 0 98 98'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      preserveAspectRatio='none'
    >
      <path
        id={getRombPathId(args.block)}
        d='M95.7404 50.5098L51.8955 95.5961C50.6099 96.9181 48.4903 96.93 47.1899 95.6225L2.34912 50.5368C1.05362 49.2342 1.0713 47.1244 2.38845 45.8437L47.2298 2.24377C48.521 0.98835 50.5802 0.999915 51.8572 2.26976L95.7015 45.8692C96.9837 47.1442 97.001 49.2135 95.7404 50.5098Z'
        fill={args.fill}
        stroke={args.stroke}
        strokeWidth={args.strokeWidth}
        strokeDasharray={args.dash?.join(',')}
      />
    </svg>
  )
}
