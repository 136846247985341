import { useRelationships, RelationshipType } from '_features/relationship'
import { type block } from '_entities/block'
import React from 'react'
import RadixDropdown, {
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import Select from 'react-select'
import { multiSelectStyles } from './multiSelectStyles'
import { PageOption, pageOptions } from 'components/organisms/ProjectFileRightSideBar/constants'
import { CreateButton } from 'components/atoms/CreateButton/CreateButton'
import Spinner from 'react-bootstrap/Spinner'
import {
  CreateButtonContainer,
  InstanceActiveIcon,
  InstanceInactiveIcon,
  MirrorActiveIcon,
  MirrorInactiveIcon,
  SelectPagesWrapper,
} from './style'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import { IconButtonSize } from '_features/toolbar'

interface Props {
  block: block
  closeActionMenu?: () => void
  onClick?: () => void
  relationshipType: RelationshipType
  icon: React.ReactNode
  background?: string
  onIconClick?: () => void
}

const Relationship = (props: Props) => {
  const { block } = props
  const { selectedPages, selectPages, createRelationship, isLoading, orderedPages } =
    useRelationships({
      onSuccess: props.closeActionMenu,
      block,
    })

  return (
    <RadixDropdown
      onMouseDown={(e: React.MouseEvent) => {
        props.onClick && props.onClick()
        e.preventDefault()
        e.stopPropagation()
      }}
      onKeydown={(e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === 'Tab') {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      trigger={
        <IconButton
          /* Issues with the versioning, cannot pass the prop from the Radix. "style" is the only thing currently available. */
          onClick={props.onIconClick}
          style={{
            backgroundColor: props.background,
          }}
          width={IconButtonSize + 'px'}
          height={IconButtonSize + 'px'}
        >
          {props.icon}
        </IconButton>
      }
    >
      <CustomP
        color={getColor('--primary-text')}
        paddingBottom='6px'
        paddingTop='6px'
        paddingLeft='12px'
        paddingRight='12px'
      >
        Create a related object in
      </CustomP>
      <SelectPagesWrapper>
        <Select
          styles={multiSelectStyles()}
          isMulti
          value={selectedPages}
          options={pageOptions(orderedPages)}
          onChange={selectPages}
          placeholder='Search pages'
          isClearable={false}
          menuIsOpen
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            Option: PageOption,
          }}
        />
      </SelectPagesWrapper>
      <CreateButtonContainer>
        <CreateButton
          onClick={() => createRelationship(RelationshipType.INSTANCE, block)}
          className='pt-1'
          disabled={selectedPages.length === 0}
        >
          {selectedPages.length === 0 ? <InstanceInactiveIcon /> : <InstanceActiveIcon />}
          {isLoading === RelationshipType.INSTANCE && <Spinner size='sm' />}
          {isLoading !== RelationshipType.INSTANCE && `New ${RelationshipType.INSTANCE}`}
        </CreateButton>
        <CreateButton
          onClick={() => createRelationship(RelationshipType.MIRROR, block)}
          className='pt-1'
          disabled={selectedPages.length === 0}
        >
          {selectedPages.length === 0 ? <MirrorInactiveIcon /> : <MirrorActiveIcon />}
          {isLoading === RelationshipType.MIRROR && <Spinner size='sm' />}
          {isLoading !== RelationshipType.MIRROR && `New ${RelationshipType.MIRROR}`}
        </CreateButton>
      </CreateButtonContainer>
    </RadixDropdown>
  )
}

export default Relationship
