import { IClientRect, Position } from 'interfaces/whiteboard'

export const useIntersection = () => {
  const haveRectsIntersection = (r1: IClientRect, r2: IClientRect) => {
    return !(
      r2.x > r1.x + r1.width ||
      r2.x + r2.width < r1.x ||
      r2.y > r1.y + r1.height ||
      r2.y + r2.height < r1.y
    )
  }

  const hasPointIntersection = (position: Position, rect: IClientRect) => {
    return !(
      rect.x > position.x ||
      rect.x + rect.width < position.x ||
      rect.y > position.y ||
      rect.y + rect.height < position.y
    )
  }

  const isPointOutsideRect = (position: Position, rect: IClientRect) => {
    return (
      position.x < rect.x ||
      position.x > rect.x + rect.width ||
      position.y < rect.y ||
      position.y > rect.y + rect.height
    )
  }

  return {
    haveRectsIntersection,
    hasPointIntersection,
    isPointOutsideRect,
  }
}
