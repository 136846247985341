import { ColorSettingsWrapper, StyledSubtitle, StyledTitle, CreateNewPalletWrapper } from './styles'
import Palette from '../Palette/Palette'
import useColorSettings from './useColorSettings'
import { IPalette } from 'interfaces/settings'
import { ReactComponent as PlusIcon } from 'assets/images/Plus.svg'
import CustomP from 'components/atoms/CustomP/CustomP'
import { Spinner } from 'react-bootstrap'

const ColorSettings = () => {
  const {
    selected,
    expanded,
    palettes,
    handleSelectPalette,
    handleExpandOnClick,
    handleCreateNewPalette,
    loading,
    fetchPalettes,
    setDefaultPalette,
    handleResetColorToDefault,
  } = useColorSettings()

  const defaultPalettes = palettes?.filter(
    (palette: IPalette) => palette?.attributes?.createdBy === null,
  )

  const palettesWithoutDefault = palettes?.filter(
    (palette: IPalette) => palette?.attributes?.createdBy !== null,
  )

  return (
    <ColorSettingsWrapper>
      <StyledTitle>Color Settings</StyledTitle>
      <StyledSubtitle>Space Color Palettes</StyledSubtitle>
      {defaultPalettes &&
        defaultPalettes.map((palette: IPalette) => (
          <Palette
            key={palette.id}
            palette={palette}
            selected={selected}
            handleSelectPalette={handleSelectPalette}
            handleExpandOnClick={handleExpandOnClick}
            expanded={expanded}
            fetchPalettes={fetchPalettes}
            setDefaultPalette={setDefaultPalette}
            handleResetColorToDefault={handleResetColorToDefault}
          />
        ))}
      {palettesWithoutDefault &&
        palettesWithoutDefault.map((palette: IPalette) => (
          <Palette
            key={palette.id}
            palette={palette}
            selected={selected}
            handleSelectPalette={handleSelectPalette}
            handleExpandOnClick={handleExpandOnClick}
            expanded={expanded}
            fetchPalettes={fetchPalettes}
            setDefaultPalette={setDefaultPalette}
            handleResetColorToDefault={handleResetColorToDefault}
          />
        ))}
      {loading === 'New' ? (
        <Spinner size='sm' />
      ) : (
        <CreateNewPalletWrapper onClick={handleCreateNewPalette}>
          <PlusIcon />
          <CustomP color='var(--secondary-text)' fontSize='14px' fontWeight='500'>
            Create New Palette
          </CustomP>
        </CreateNewPalletWrapper>
      )}
    </ColorSettingsWrapper>
  )
}

export default ColorSettings
