import styled from 'styled-components'

export const PageMembersWrapper = styled.div`
  height: 145px;
  padding: 0;
  width: 100%;
  position: relative;
`

export const ScrollContainer = styled.div`
  overflow: auto;
  max-height: 100%;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`
