import CustomP from 'components/atoms/CustomP/CustomP'
import React, { useRef } from 'react'
import { ColorPickerHeader, ColorPickerWrapper, StyledCloseIcon, StyledColorPicker } from './styles'
import { IPaletteColor } from 'interfaces/settings'
import convertHexToRGBA from 'utils/convertToRgba'
import { useOnClickOutside } from 'usehooks-ts'
import { ColorResult, RGBColor } from 'react-color'
import { ColorPickerTypes, getPaletteColorElementId } from 'components/organisms/Palette/Palette'
import { getById } from 'shared/lib'

interface IProps {
  handleChange: (
    color: ColorResult,
    event: React.ChangeEvent<HTMLInputElement>,
    paletteColor: any,
  ) => void
  onChangeComplete?: (color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) => void
  closeColorPicker: () => void
  paletteColor?: IPaletteColor
  pickedColor?: RGBColor
  placeholder: string
  transform?: string
  id?: string
  type: ColorPickerTypes
}

export const getColorPickerWrapperId = (id?: string) => {
  return `color-picker-wrapper-${id ? id : 'generic'}`
}

const ColorPicker = (props: IProps) => {
  const ref = useRef(null)
  useOnClickOutside(ref, (event) => {
    const colorPickerTriggerElement = getById(getPaletteColorElementId(props.type))
    if (!colorPickerTriggerElement?.contains(event.target as Node)) props.closeColorPicker()
  })

  return (
    <ColorPickerWrapper
      id={getColorPickerWrapperId(props.id)}
      onClick={(e) => e.stopPropagation()}
      transform={props.transform}
      ref={ref}
    >
      <ColorPickerHeader>
        <CustomP fontSize='12px' fontWeight='500' color='var(--primary-text)'>
          {props.placeholder}
        </CustomP>
        <StyledCloseIcon onClick={props.closeColorPicker} />
      </ColorPickerHeader>
      <StyledColorPicker
        onChange={(color: ColorResult, event: React.ChangeEvent<HTMLInputElement>) =>
          props.handleChange(color, event, props.paletteColor)
        }
        onChangeComplete={props.onChangeComplete}
        color={
          props.pickedColor || (props.paletteColor && convertHexToRGBA(props.paletteColor.color))
        }
        styles={
          {
            default: {
              picker: {
                cursor: 'pointer',
                boxShadow: 'none',
                border: 'none',
              },
              saturation: {
                boxShadow: 'none',
              },
              hue: {
                boxShadow: 'none',
              },
            },
          } as any
        }
      />
    </ColorPickerWrapper>
  )
}

export default ColorPicker
