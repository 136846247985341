import React from 'react'
import { StyledLinkSettingsPopup, StyledContainer, Arrow } from './styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { expirationOptions } from 'services/invites/constants'
import CustomSelectDropdown from '../CustomSelectDropdown/CustomSelectDropdown'

type Props = {
  onClick?: (value: any) => void
  onChange?: (value: any) => void
  colour?: string
  fontWeight?: string
  margin?: string
  id?: string
  idForModal?: string
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function LinkOptions({
  onClick,
  onChange,
  colour,
  fontWeight,
  margin,
  id,
  idForModal,
  isOpen,
  setIsOpen,
}: Props) {
  return (
    <>
      <StyledContainer
        id={id}
        role='button'
        className='pt-2 ps-0 pe-0'
        onClick={(e: MouseEvent) => {
          e.stopPropagation()
          setIsOpen(!isOpen)
        }}
        margin={margin}
      >
        <Arrow translatez={`${isOpen ? '180deg' : '90deg'}`} />
        <CustomP
          className='ps-1 d-inline'
          color={colour || 'var(--primary-color)'}
          fontWeight={fontWeight}
        >
          Link options
        </CustomP>
      </StyledContainer>

      <StyledLinkSettingsPopup
        className='d-flex'
        open={isOpen}
        padding={'0 0 0 0'}
        id={idForModal}
        onClick={(e: React.MouseEvent<HTMLDivElement>) => {
          e.stopPropagation()
        }}
      >
        <CustomP color='var(--gray2)' fontSize='12px' fontWeight='600'>
          Link expires in:
        </CustomP>
        <CustomSelectDropdown
          options={expirationOptions}
          defaultSelect={'Never'}
          fontWeight='500'
          margin={'0 1rem 0 10px'}
          onChange={onChange}
        />
        <CustomP
          fontSize='14px'
          fontWeight='500'
          onClick={onClick}
          cursorPointer
          color={'var(--primary-color)'}
          paddingLeft={'12px'}
        >
          Generate new link
        </CustomP>
      </StyledLinkSettingsPopup>
    </>
  )
}

export default LinkOptions
