import { getById, setInitial, setNSResize } from 'shared/lib'
import { type block } from '_entities/block'
import { useEffect } from 'react'
import { getExpandedPageBlockElement } from '_entities/embed'
import { useHeightEdit } from './useHeightEdit'
import { getEmbedHeightInputId } from '_entities/embed/InternalEmbed/ui/EmbedHeightEdit'

interface IProps {
  block: block
}

export const useInternalCanvasResize = (props: IProps) => {
  // ** Hooks
  const _embedHeight = useHeightEdit(props)

  useEffect(() => {
    const resizableElement = getExpandedPageBlockElement(props.block)

    if (resizableElement === null) return

    let isResizing = false

    const borderWidth = parseInt(window.getComputedStyle(resizableElement).borderBottomWidth)

    const onMouseMove = (e: MouseEvent) => {
      if (resizableElement) {
        const newHeight = (
          e.clientY -
          resizableElement.getBoundingClientRect().top -
          borderWidth
        ).toFixed(0)

        if (parseInt(newHeight) < 200 || parseInt(newHeight) > 999) return

        resizableElement.style.height = `${newHeight}px`
        setNSResize()
        const input = getById(`${getEmbedHeightInputId(props.block._id)}`) as HTMLInputElement

        if (input) {
          input.value = newHeight.toString()
        }
      }
    }

    const onMouseDown = (e: MouseEvent) => {
      const offsetY = e.clientY - resizableElement.getBoundingClientRect().top

      if (resizableElement && offsetY > resizableElement.clientHeight - 5) {
        document.addEventListener('mousemove', onMouseMove)
        isResizing = true
      }
    }

    const onMouseUp = () => {
      if (isResizing) {
        _embedHeight.onHeightChange(props.block, resizableElement.offsetHeight)
        setInitial()
        isResizing = false
      }
      document.removeEventListener('mousemove', onMouseMove)
    }

    resizableElement.addEventListener('mousedown', onMouseDown)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      resizableElement.removeEventListener('mousedown', onMouseDown)
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
      isResizing = false
    }
  }, [props.block])
}
