import React from 'react'
import { StyledTabBodyHeader } from './styles'

interface Props {
  children: React.ReactNode
  position?: string
  top?: string
  left?: string
}

const SpaceTabHeaderWrapper = ({ children, position, top, left }: Props) => {
  return (
    <StyledTabBodyHeader position={position} top={top} left={left}>
      {children}
    </StyledTabBodyHeader>
  )
}

export default SpaceTabHeaderWrapper
