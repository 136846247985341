import React, { useEffect, useRef, useState } from 'react'
import { type block, getBlockPageId, getBlockTag, getIndex, IBlockTypes } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'
import { downloadImageOnClickRequest } from 'utils/downloadImageOnClickRequest'
import { IMAGE_WITH_TEXT_LABEL } from '../lib/uploadConstants'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { isSafari } from 'shared/lib'

export const useImageBlock = (block: block) => {
  const options = {
    step: 10,
    axis: 'horizontal',
  }

  const { step = 1, axis = 'both' } = options || {}
  // ** State
  const [resizeDirection, setResizeDirection] = useState<string | null>(null)
  const [coords, setCoords] = useState({ x: Infinity, y: Infinity })
  const [dims, setDims] = useState({ width: Infinity, height: Infinity })

  // ** Refs
  const innerRef = React.useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)

  // ** Hooks
  const _submit = useSubmit()
  const _json1 = useJson1()

  useEffect(() => {
    if (imageRef.current && block.data.imageBorderWrapperWidth !== undefined) {
      imageRef.current.style.width = block.data.imageBorderWrapperWidth + 'px'
    }

    if (
      imageRef.current &&
      block.data.imageBorderWrapperWidth !== undefined &&
      block.data.imageBorderWrapperWidth < 250
    ) {
      imageRef.current.style.width = '250px'
    }
  }, [block])

  useEffect(() => {
    document.addEventListener('mousemove', doDrag, false)
    document.addEventListener('mouseup', stopDrag, false)
  }, [dims, imageRef, coords, axis])

  const handleChangeWidth = (newWidth: number) => {
    if (newWidth === Infinity) return
    const index = getIndex(block)

    if (index === -1) return

    const op = _json1.getReplaceBlockDataKeyOp(
      index,
      ['imageBorderWrapperWidth'],
      newWidth,
      block.data.imageBorderWrapperWidth,
    )
    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  const getValue = (input: number) => Math.ceil(input / step) * step

  const doDrag = (event: MouseEvent) => {
    if (!imageRef.current) return

    const deltaX = event.clientX - coords.x
    const widthDelta = resizeDirection === 'right' ? deltaX : -deltaX
    const heightDelta = event.clientY - coords.y

    const newWidth = getValue(dims.width + widthDelta)
    const newHeight = getValue(dims.height + heightDelta)

    if (newWidth >= 250) {
      if (axis === 'both' || axis === 'horizontal') {
        imageRef.current.style.width = newWidth + 'px'
      }
      if (axis === 'both' || axis === 'vertical') {
        imageRef.current.style.height = newHeight + 'px'
      }
    }
  }

  const stopDrag = (event: any) => {
    document.removeEventListener('mouseup', stopDrag, false)
    document.removeEventListener('mousemove', doDrag, false)
    const deltaX = event.clientX - coords.x
    const widthDelta = resizeDirection === 'right' ? deltaX : -deltaX

    const newWidth = getValue(dims.width + widthDelta)

    handleChangeWidth(newWidth)
  }

  const onDownloadIconClick = (
    e: React.MouseEvent<SVGSVGElement, MouseEvent>,
    imageUrl?: string,
  ) => {
    e.stopPropagation()
    downloadImageOnClickRequest(imageUrl, isSafari())
  }

  const onRightResizeMouseDown = (event: React.MouseEvent) => {
    handleOnMouseDown(event)
    setResizeDirection('right')
  }

  const onRightResizeTouchStart = (event: React.TouchEvent) => {
    handleOnTouchStart(event)
    setResizeDirection('right')
  }

  const onLeftResizerMouseDown = (event: React.MouseEvent) => {
    handleOnMouseDown(event)
    setResizeDirection('left')
  }

  const onLeftResizerTouchStart = (event: React.TouchEvent) => {
    handleOnTouchStart(event)
    setResizeDirection('left')
  }

  const handleOnTouchStart = (event: React.TouchEvent) => {
    if (!imageRef.current) return
    setCoords({ x: event.touches[0].clientX, y: event.touches[0].clientY })
    const { width, height } = window.getComputedStyle(imageRef.current)
    setDims({ width: parseInt(width, 10), height: parseInt(height, 10) })
  }

  const handleOnMouseDown = (event: React.MouseEvent) => {
    if (!imageRef.current) return
    setCoords({ x: event.clientX, y: event.clientY })
    const { width, height } = window.getComputedStyle(imageRef.current)
    setDims({ width: parseInt(width, 10), height: parseInt(height, 10) })
  }

  const nameInLabelIfNoFileSelected =
    getBlockTag(block) === IBlockTypes.IMAGE_TEXT ? IMAGE_WITH_TEXT_LABEL : getBlockTag(block)

  return {
    nameInLabelIfNoFileSelected,
    onDownloadIconClick,
    innerRef,
    imageRef,
    onRightResizeMouseDown,
    onRightResizeTouchStart,
    onLeftResizerTouchStart,
    onLeftResizerMouseDown,
    resizeDirection,
  }
}
