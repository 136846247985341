import React from 'react'
import { Modal } from 'react-bootstrap'
import { downloadImageOnClickRequest } from 'utils/downloadImageOnClickRequest'
import { IMAGE_DEFAULT_ZOOM_SIZE, ImagePreviewModalType } from '_features/editor'
import { determineIsDisabledAndReplacement } from '_features/editor/editor-image-block/lib/determineIsDisabledAndReplacement'
import {
  ImageModal,
  ImageOptions,
  ModalInnerContainer,
  DiagonalCloseIcon,
  DownloadIcon,
  PlusIcon,
  MinusIcon,
  SizeText,
  ImageWrapper,
} from './styles'

const ShowImageModal: React.FC<ImagePreviewModalType> = ({
  block,
  isModalShown,
  handleClose,
  onShow,
  onSizePlusClick,
  onSizeMinusClick,
  innerRef,
  scale,
}) => {
  return (
    <ImageModal
      show={isModalShown}
      onShow={onShow}
      onHide={handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='showImageInEditorModal'
    >
      <ModalInnerContainer>
        <Modal.Body className='border-0 p-0 m-0 showImageModalBody' ref={innerRef}>
          <ImageWrapper scale={(scale || IMAGE_DEFAULT_ZOOM_SIZE) / 100}>
            <img src={block?.data.imageUrl} alt={`Image in editor: ${block?.data.imageUrl}`} />
          </ImageWrapper>
          <ImageOptions>
            <DiagonalCloseIcon onClick={handleClose} />
            <DownloadIcon onClick={() => downloadImageOnClickRequest(block?.data.imageUrl)} />
            <MinusIcon
              onClick={onSizeMinusClick}
              isDisabled={determineIsDisabledAndReplacement({ isZoomScaleDown: true, block })}
            />
            <SizeText>{`${scale || IMAGE_DEFAULT_ZOOM_SIZE} %`}</SizeText>
            <PlusIcon
              onClick={onSizePlusClick}
              isDisabled={determineIsDisabledAndReplacement({ isZoomScaleDown: false, block })}
            />
          </ImageOptions>
        </Modal.Body>
      </ModalInnerContainer>
    </ImageModal>
  )
}

export default ShowImageModal
