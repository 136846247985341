import styled from 'styled-components'

type StyledSpaceMembersPropsType = {
  position?: string
  top?: string
  left?: string
}

export const StyledTabBodyHeader = styled.div<StyledSpaceMembersPropsType>`
  padding: 12px 52px;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  border-top: 3px solid var(--gray4);
  margin-top: -13px;
  margin-bottom: 0.25rem;
  padding-top: 32px;
  position: relative;
  width: 100%;

  position: ${(props) => props.position || ''};
  top: ${(props) => props.top || ''};
  left: ${(props) => props.left || ''};
`
