import RadixDropdown, {
  IconButton,
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import { type block } from '_entities/block'
import { IconButtonSize } from '_features/toolbar/ui/Toolbar'
import { useBlockAi } from 'editor/EditableBlock/BlockToolbar/useBlockAi'
import ExpandIcon from 'editor/EditableBlock/BlockToolbar/ExpandIcon'
import GenerateIcon from 'editor/EditableBlock/BlockToolbar/GenerateIcon'
import SummarizeIcon from 'editor/EditableBlock/BlockToolbar/SummarizeIcon'

interface Props {
  block: block
}

const AIDropdown = (props: Props) => {
  const { expandText, summarizeText, generateText } = useBlockAi({
    block: props.block,
  })
  return (
    <RadixDropdown
      trigger={
        <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
          <icons.ai />
          <icons.caretDown />
        </IconButton>
      }
    >
      <DropdownMenuItem onSelect={summarizeText}>
        <SummarizeIcon id={`summarize-${props.block._id}`} /> Summarize
      </DropdownMenuItem>
      <DropdownMenuItem onSelect={expandText}>
        <ExpandIcon id={`expand-${props.block._id}`} /> Complete&nbsp;sentence
      </DropdownMenuItem>
      <DropdownMenuItem onSelect={generateText}>
        <GenerateIcon id={`generate-${props.block._id}`} /> Expand upon
      </DropdownMenuItem>
    </RadixDropdown>
  )
}

export default AIDropdown
