import styled from 'styled-components'
import { useCallback } from 'react'
import { envVars } from 'config/env'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
`
export const Content = styled.div`
  width: 60%;
  margin: 0 auto;
  overflow-y: auto;
`
export const PrivacyPolicy = () => {
  const ref = useCallback((node: HTMLDivElement | null) => {
    if (node) {
      node.setAttribute('name', 'termly-embed')
      const script = document.createElement('script')
      script.src = 'https://app.termly.io/embed-policy.min.js'
      script.async = true
      document.body.appendChild(script)
    }
  }, [])
  return (
    <Container>
      <Content ref={ref} data-id={envVars.termly.id} data-type='iframe'></Content>
    </Container>
  )
}
