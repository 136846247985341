import { DropdownOptionContainer } from 'components/atoms/PropertyContainer/PropertyContainer'
import { CustomDropdown } from 'components/molecules/CustomDropdown/CustomDropdown'
import { ITools } from 'interfaces/whiteboard'
import { IconWrapper } from '_entities/whiteboard/ui/styles'
import { BasicInput } from 'components/atoms/BasicInput/BasicInput'
import { ReactComponent as CaretDown } from 'assets/images/CaretDown.svg'
import { zoomSteps } from '../../lib/constants'
import { getColor } from 'shared/colors'
import { useZoom } from '_features/canvas'
import { useAppSelector } from 'redux/hooks'

interface Props {
  pageId: string
  zoomToPercentage: (pageId: string, percent: number, animate?: boolean) => void
  zoomPercentage: number
  setZoomPercentage: React.Dispatch<React.SetStateAction<number>>
}

export const ZoomSelector = (props: Props) => {
  const WIDTH = '145px'
  const zoom = useAppSelector((state) => state.whiteboard.zoom)
  const _zoom = useZoom()
  return (
    <CustomDropdown
      dropdownMenuMinWidth={WIDTH}
      menuMargin='0'
      menuPadding='0'
      dropdownOptions={[
        {
          element: (
            <DropdownOptionContainer padding='12px' isHover width={WIDTH}>
              Zoom to fit
            </DropdownOptionContainer>
          ),
          id: 'zoom-to-fit',
          onClick() {
            _zoom.zoomToFit({ pageId: props.pageId, betweenSidebars: true, shouldAnimate: true })
          },
          eventKey: 111000,
        },
        {
          element: (
            <DropdownOptionContainer padding='12px' isHover width={WIDTH}>
              Zoom to 50%
            </DropdownOptionContainer>
          ),
          id: 'zoom-to-50',
          onClick() {
            props.zoomToPercentage(props.pageId, 50, true)
          },
          eventKey: 5050,
        },
        {
          element: (
            <DropdownOptionContainer padding='12px' isHover width={WIDTH}>
              Zoom to 100%
            </DropdownOptionContainer>
          ),
          id: 'zoom-to-100',
          onClick() {
            props.zoomToPercentage(props.pageId, 100, true)
          },
          eventKey: 100,
        },
        {
          element: (
            <DropdownOptionContainer padding='12px' isHover width={WIDTH}>
              Zoom to 200%
            </DropdownOptionContainer>
          ),
          id: 'zoom-to-200',
          onClick() {
            props.zoomToPercentage(props.pageId, 200, true)
          },
          eventKey: 100,
        },
        {
          element: (
            <DropdownOptionContainer padding='0 12px 12px 12px' isHover width={WIDTH}>
              <BasicInput
                background={getColor('--overlay-modal')}
                value={props.zoomPercentage}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const number = parseInt(e.target.value)
                  const min = zoomSteps[0]
                  const max = zoomSteps[zoomSteps.length - 1]
                  if (Number.isNaN(number) || (number >= min && number <= max))
                    props.setZoomPercentage(number)
                  if (number >= min && number <= max) {
                    props.zoomToPercentage(props.pageId, number)
                  }
                }}
                min='1'
                max='200'
                type='number'
              />
            </DropdownOptionContainer>
          ),
          id: ITools.FRAME_ONE_ONE,
          onClick() {
            void 0
          },
          eventKey: 109239572305,
        },
      ]}
      toggleButton={
        <IconWrapper width='64px'>
          {zoom ? `${zoom}%` : '100%'}&nbsp;
          <CaretDown />
        </IconWrapper>
      }
    />
  )
}
