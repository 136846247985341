import React from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { styled, keyframes } from '@stitches/react'
import { violet, blackA, mauve } from '@radix-ui/colors'
import { Cross2Icon } from '@radix-ui/react-icons'

interface Props {
  children: React.ReactNode
  open?: boolean
  setOpen: (value: boolean) => void
  title?: string
}

const Modal = (props: Props) => {
  const closeModal = () => props.setOpen(false)

  return (
    <Dialog.Root open={props.open}>
      <Dialog.Portal>
        <DialogOverlay onClick={closeModal} />
        <DialogContent>
          <DialogTitle>{props.title}</DialogTitle>
          {props.children}
          <Dialog.Close asChild>
            <IconButton onClick={closeModal} aria-label='Close'>
              <Cross2Icon />
            </IconButton>
          </Dialog.Close>
        </DialogContent>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
})

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
})

const DialogOverlay = styled(Dialog.Overlay, {
  backgroundColor: blackA.blackA9,
  position: 'fixed',
  inset: 0,
  animation: `${overlayShow} 400ms cubic-bezier(0.16, 1, 0.3, 1)`,
  zIndex: 6,
})

const DialogContent = styled(Dialog.Content, {
  backgroundColor: 'var(--overlay-modal)',
  borderRadius: 6,
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '580px',
  maxHeight: '85vh',
  padding: 25,
  animation: `${contentShow} 400ms cubic-bezier(0.16, 1, 0.3, 1)`,
  zIndex: 7,
  '&:focus': { outline: 'none' },
})

const DialogTitle = styled(Dialog.Title, {
  marginBottom: '10px',
  fontWeight: 500,
  color: 'var(--primary-text)',
  fontSize: 17,
})

const IconButton = styled('button', {
  all: 'unset',
  fontFamily: 'inherit',
  borderRadius: '100%',
  height: 25,
  width: 25,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: violet.violet11,
  position: 'absolute',
  top: 10,
  right: 10,

  '&:hover': { backgroundColor: violet.violet4 },
  '&:focus': { boxShadow: `0 0 0 2px ${violet.violet7}` },
})

export default Modal
