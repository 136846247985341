import React from 'react'
import { SelectedDefaultWrapper, SelectedName, StyledEditIcon, StyledTrashIcon } from './styles'
import { DefaultsType } from 'interfaces/settings'

interface Props {
  item: any
  handleShowIcon: (item: DefaultsType | null) => JSX.Element
  handleShownModal: (defaultModal: DefaultsType) => void
  handleDeleteDefault: (item: DefaultsType, id: string) => void
}

const SelectedDefault = (props: Props) => {
  return (
    <SelectedDefaultWrapper>
      {props.handleShowIcon(props.item.label)}
      <SelectedName>{props.item.value?.name}</SelectedName>
      <StyledEditIcon onClick={() => props.handleShownModal(props.item.label)} />
      <StyledTrashIcon
        onClick={() =>
          props.item.value.isDefault &&
          props.handleDeleteDefault(props.item.label, props.item.value.id)
        }
      />
    </SelectedDefaultWrapper>
  )
}

export default SelectedDefault
