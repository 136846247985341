import { IPages } from 'interfaces/page'
import { PageIconWrapper } from './styles'
import { getColor } from 'shared/colors'
import { Option } from 'interfaces/selectOptions'
import { PageTypesEnum } from 'interfaces/projectFile'
import { GroupBase, OptionProps, components } from 'react-select'
import { useIcons } from 'shared/icons'

export const textColorArray = () => [
  getColor('--color-picker-black'),
  getColor('--color-picker-blue'),
  getColor('--color-picker-green'),
  getColor('--color-picker-red'),
  getColor('--color-picker-white'),
  getColor('--color-picker-yellow'),
  getColor('--color-picker-orange'),
  getColor('--color-picker-purple'),
  getColor('--color-picker-gray'),
  getColor('--color-picker-gray2'),
]

export const pageOptions = (pages?: IPages[]): Option[] => {
  if (pages) {
    const result = pages.map((page) => {
      return {
        value: page.id as string,
        label: page.text,
        type: page.type,
      }
    })

    return result
  } else return []
}

export const PageOption = (props: OptionProps<Option, true, GroupBase<Option>>) => {
  const _icons = useIcons()
  const Icon = _icons.PageTypeIcon[props.data.type as PageTypesEnum]
  return (
    <components.Option {...props}>
      <PageIconWrapper>
        <Icon />
      </PageIconWrapper>
      <span className='px-1' style={{ color: 'var(--primary-text)' }}>
        {props.data.label}
      </span>
    </components.Option>
  )
}
