import { type block } from '_entities/block'
import { Emoji } from './types'
import { useToolbar, ToolbarAttrs, ToolbarAttrsData } from '_features/toolbar'

export const useEmojiBlock = () => {
  const _toolbar = useToolbar()
  const getEmoji = (block: block) => {
    return block.data.emoji
  }
  const getEmojiCode = (block: block) => {
    return block.data.emoji?.native || block.data.emoji?.id
  }

  const getSelectedCanvasEmoji = () => {
    const emoji = _toolbar.getAttr(ToolbarAttrs.EMOJI)
    if (!emoji) return
    return JSON.parse(emoji) as ToolbarAttrsData[ToolbarAttrs.EMOJI]
  }

  const setSelectedCanvasEmoji = (emoji: Emoji) => {
    _toolbar.setAttr(ToolbarAttrs.EMOJI, emoji)
  }

  const removeCanvasEmoji = () => {
    _toolbar.removeAttr(ToolbarAttrs.EMOJI)
  }
  return {
    getEmoji,
    getEmojiCode,
    getSelectedCanvasEmoji,
    setSelectedCanvasEmoji,
    removeCanvasEmoji,
  }
}
