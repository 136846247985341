import DropdownMenu from 'components/organisms/DropdownMenu/DropdownMenu'
import styled, { css } from 'styled-components'

export const ConversionDropdownMenuContainer = styled(DropdownMenu)`
  min-width: fit-content;
  padding: 0 !important;
  height: 304px;
  overflow-y: scroll;
  & > li > a {
    white-space: nowrap;
  }

  .dropdown-item {
    color: var(--primary-text) !important;
  }

  .dropdown-item:hover {
    background-color: var(--hover) !important;
  }
`

export const ConversionDropdownMenuBody = styled.div<{ bottomLine?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 186px;
  padding: 12px 8px;
  border-bottom: ${(props) => (props.bottomLine ? '1px solid var(--gray4)' : 'none')};
`

export const ConversionButtonContainer = styled(DropdownMenu.Item)<{ active?: boolean }>`
  padding: 4px;
  display: flex;
  cursor: pointer;
  border-radius: 10px;
  gap: 12px;
  align-items: center;
`
