import { DropPlaceholder, StyledPageWrapper } from './styles'
import DraggableTree from 'shared/dragging/ui/DraggableTree'
import MoveFileModal from './MoveFileModal'
import { TreeItem } from '_entities/tree/model/types'
import PageReference from '_entities/page/ui/PageReference/PageReference'
import { usePagesNavigation } from '_widgets/LeftSidebar/model/usePagesNavigation'
import { useIcons } from 'shared/icons'
import { LeftSidebarEmojiPicker } from '_features/emojis'

const PagesNavigation = () => {
  const _draggablePagesNavigation = usePagesNavigation()
  const _icons = useIcons()

  return (
    <>
      <DraggableTree
        sort={true}
        items={
          (_draggablePagesNavigation.allPages
            ? _draggablePagesNavigation.pagesWithoutTaskManager
            : []) as TreeItem[]
        }
        initialTreeStructure={_draggablePagesNavigation.pageStructure}
        onDrop={_draggablePagesNavigation.onDrop}
        render={(node, { depth, isOpen, onToggle, isDropTarget, hasChild }) => {
          return (
            <PageReference
              isDropTarget={isDropTarget}
              depth={depth}
              node={node}
              hasChild={hasChild}
              onToggle={onToggle}
              isOpen={isOpen}
              setEmojiNodeElement={_draggablePagesNavigation._emojiDropdown.setEmojiNodeElement}
              handleMoveModalOpen={_draggablePagesNavigation.handleMoveModalOpen}
            />
          )
        }}
        placeholderRender={() => {
          return <DropPlaceholder />
        }}
        dragPreviewRender={(item: TreeItem) => {
          const Icon = _icons.getIcon({
            pageNode: item,
          })

          return (
            <StyledPageWrapper isDragging>
              {Icon && <Icon />}
              {item.text}
            </StyledPageWrapper>
          )
        }}
      />
      <LeftSidebarEmojiPicker
        refHandler={(instance) => {
          if (instance) {
            _draggablePagesNavigation._emojiDropdown.emojiPopperElementRef.current = instance
          }
        }}
        style={_draggablePagesNavigation._emojiDropdown.emojiPopper.styles.popper}
        {..._draggablePagesNavigation._emojiDropdown.emojiPopper.attributes.popper}
        shouldHide={!_draggablePagesNavigation._emojiDropdown.emojiNodeElement}
        onEmojiSelect={_draggablePagesNavigation.handleAddEmojiOnClick}
        onButtonClick={_draggablePagesNavigation.handleRemoveEmojiOnClick}
      />
      <MoveFileModal
        moveModalOpen={_draggablePagesNavigation.moveModalOpen}
        handleMoveModalClose={_draggablePagesNavigation.handleMoveModalClose}
        selectedPage={_draggablePagesNavigation.pageToMove}
        selectedProject={_draggablePagesNavigation.selectedProject}
        handleSelectProject={_draggablePagesNavigation.handleSelectProject}
        options={_draggablePagesNavigation.options}
        handleCopyFileToAnotherProject={_draggablePagesNavigation.handleCopy}
      />
    </>
  )
}

export default PagesNavigation
