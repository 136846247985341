import { useState } from 'react'
import { AxiosService } from 'services/axiosService/axiosService'
import { UserTypingActions } from 'services/user/UserTypingEnums/UserTypingActions'
import { useToast } from 'services/helpers/useToast'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setCurrentSpace } from 'redux/reducers/spaceReducer'
import { useUser } from 'services/user/useUser'
import { useNavigate } from 'react-router-dom'

export const useSpaceSettings = () => {
  const toast = useToast()
  const dispatch = useAppDispatch()
  const AxiosInstance = new AxiosService()
  const _user = useUser()
  const navigate = useNavigate()
  const space = useAppSelector((state) => state.space.currentSpace)
  /* Want to prevent user to name the space like 0 or '' */
  const [spaceName, setSpaceName] = useState<string>(space?.name || '')
  const [showChangeNameModal, setShowChangeNameModal] = useState<boolean>(false)
  const [deleteSpaceModalShow, setDeleteSpaceModalShow] = useState<boolean>(false)
  const [showChangeProfileModal, setShowChangeProfileModal] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>('')
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const deleteSpace = async (spaceId: number) => {
    if (inputValue === 'Delete') {
      const response = await AxiosInstance.deleteSpace(spaceId)
      if (response) {
        const spaces = await _user.getSpaces()
        if (spaces.allSpaces) {
          navigate(`/space/${spaces.allSpaces[0].id}`)
        } else {
          toast.error('No spaces found')
        }
      } else toast.error('Account should have at least one space')
    }
  }

  const changeSpaceName = async () => {
    if (space) {
      const response = await AxiosInstance.updateSpace(space.id, spaceName)
      if (response) {
        const updatedSpace = await AxiosInstance.getSpace(space.id)
        if (updatedSpace) {
          dispatch(setCurrentSpace(updatedSpace))
        }
      } else console.error(response)
    }
    setShowChangeNameModal(false)
  }

  const onMouseEnter = () => {
    inputValue !== UserTypingActions.DELETE ? setShowTooltip(true) : setShowTooltip(false)
  }

  return {
    showChangeNameModal,
    setShowChangeNameModal,
    showChangeProfileModal,
    setShowChangeProfileModal,
    deleteSpaceModalShow,
    setDeleteSpaceModalShow,
    inputValue,
    setInputValue,
    showTooltip,
    setShowTooltip,
    deleteSpace,
    onMouseEnter,
    spaceName,
    setSpaceName,
    changeSpaceName,
  }
}
