import styled from 'styled-components'

export const FontEditWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  justify-content: space-between;
`

export const StyledInput = styled.input<{ showArrows?: boolean }>`
  outline: none;
  border: none;
  width: 24px;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  background-color: transparent;
  color: var(--secondary-text);

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    display: ${(props) => (props.showArrows ? 'block' : 'none')};
  }

  z-index: 2;
`

export const StyledButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.2s;

  &:hover {
    background-color: var(--sidebars-hover);
  }
`
