import React from 'react'
import CustomP from '../CustomP/CustomP'

type Props = {
  text: string
}

const TextSuccess: React.FC<Props> = ({ text }) => {
  return <CustomP className='text-success' text={text} />
}

export default TextSuccess
