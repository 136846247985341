import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import RadixDropdown, {
  DropdownMenuItem,
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import { getBlockGridWidth, GridBlockWidth } from '_entities/block'
import { EditWrapper } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/BlockEdit/styles'
import { useBlockWidthEdit } from '_features/block'
import { SidebarEditProps } from './EmbedEdit'
import { isMobile } from 'react-device-detect'

export const EmbedWidthEdit = (props: SidebarEditProps) => {
  const _blockWidthEdit = useBlockWidthEdit()
  return (
    <EditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Size
      </CustomP>
      <RadixDropdown
        trigger={
          <IconButton padding='0' width='18px' height='18px' transform='translateX(1.5px)'>
            <icons.width />
          </IconButton>
        }
      >
        <DropdownMenuItem
          onSelect={() =>
            _blockWidthEdit.handleBlockWidthChange(props.block, GridBlockWidth.FULL_BLEED)
          }
          selected={getBlockGridWidth(props.block) === GridBlockWidth.FULL_BLEED}
        >
          Full Bleed
        </DropdownMenuItem>

        <DropdownMenuItem
          onSelect={() =>
            _blockWidthEdit.handleBlockWidthChange(props.block, GridBlockWidth.OUT_OF_GRID)
          }
          selected={getBlockGridWidth(props.block) === GridBlockWidth.OUT_OF_GRID}
          disabled={isMobile}
        >
          Out Of Grid
        </DropdownMenuItem>

        <DropdownMenuItem
          onSelect={() =>
            _blockWidthEdit.handleBlockWidthChange(props.block, GridBlockWidth.IN_GRID)
          }
          selected={getBlockGridWidth(props.block) === GridBlockWidth.IN_GRID}
        >
          In Grid
        </DropdownMenuItem>

        <DropdownMenuItem
          onSelect={() => _blockWidthEdit.handleBlockWidthChange(props.block, GridBlockWidth.SMALL)}
          selected={getBlockGridWidth(props.block) === GridBlockWidth.SMALL}
          disabled={isMobile}
        >
          Small
        </DropdownMenuItem>
      </RadixDropdown>
    </EditWrapper>
  )
}
