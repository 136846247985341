import { AssignValueToPropertyRequestType, UnassignValueToPropertyRequestType } from '../model'
import { property } from '../api'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setTasks } from 'redux/reducers/taskManagerReducer'
import { getPropertyValue } from 'utils/taskManager/getPropertyValue'
import { ITaskProperty } from 'interfaces/taskManager'

export const useAssignToProperty = () => {
  // ** Redux
  const [handleAssignValueToProperty] = property.endpoints.assignValueToProperty.useMutation()
  const [handleUnassignValueFromProperty] =
    property.endpoints.unassignValueFromProperty.useMutation()

  // ** Redux state
  const propertyDefinitions = useAppSelector((state) => state.taskManager.propertyDefinitions)
  const tasks = useAppSelector((state) => state.taskManager.tasks)

  // ** Hooks
  const dispatch = useAppDispatch()

  const updateProperty = async (
    request: AssignValueToPropertyRequestType | UnassignValueToPropertyRequestType,
    handleMutation: any,
  ) => {
    const response = await handleMutation(request).unwrap()

    if (response && tasks) {
      const taskId = response.page.id
      const propertyDefinitionId = response.propertyDefinitionId

      const newTasks = tasks.map((task) => {
        if (task.id !== taskId) return task

        const properties = task.properties?.map((property) => {
          if (property.propertyDefinitionId !== propertyDefinitionId) return property

          const propertyDefinition = propertyDefinitions?.find(
            (propDef) => propDef.id === property.propertyDefinitionId,
          )

          const newProperty = {
            ...response,
            propertyDefinition,
          }

          return {
            ...newProperty,
            value: getPropertyValue(newProperty as ITaskProperty),
          }
        })

        return {
          ...task,
          properties,
        }
      })

      dispatch(setTasks(newTasks))
      return response
    }
  }

  const assignValueToProperty = (request: AssignValueToPropertyRequestType) =>
    updateProperty(request, handleAssignValueToProperty)

  const unassignValueFromProperty = (request: UnassignValueToPropertyRequestType) =>
    updateProperty(request, handleUnassignValueFromProperty)

  return {
    assignValueToProperty,
    unassignValueFromProperty,
  }
}
