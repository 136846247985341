import { IProjectFileMember } from 'interfaces/projectFile'
import { AxiosService } from 'services/axiosService/axiosService'
const AxiosInstance = new AxiosService()

export const getMembers = async (projectId: number) => {
  return await AxiosInstance.getProjectFileMembers(projectId)
}

export const getPendingMembers = async (projectId: number) => {
  return await AxiosInstance.getProjectPendingMembers(projectId)
}

export const getMeFromMembers = async (projectId: number, myId: number) => {
  const members: IProjectFileMember[] | undefined = await getMembers(projectId)
  if (members) {
    return members.find((user) => user.user.id === myId)
  }
}
