import React from 'react'
import EmbedOption from '../Option/Option'
import { icons } from 'shared/icons'
interface Props {
  onSelectDoc: () => void
  onSelectWb: () => void
  setPageLinkSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const EverydayEmbeds = (props: Props) => {
  return (
    <>
      <EmbedOption
        icon={icons.document}
        onClick={() => props.onSelectDoc()}
        onSelect={() => void 0}
      >
        Document
      </EmbedOption>
      <EmbedOption icon={icons.whiteboard} onClick={props.onSelectWb} onSelect={() => void 0}>
        Canvas
      </EmbedOption>
      <EmbedOption
        icon={icons.embedFiles}
        onSelect={() => props.setPageLinkSelectorOpen(true)}
        onClick={() => void 0}
      >
        Link to page
      </EmbedOption>
    </>
  )
}

export default EverydayEmbeds
