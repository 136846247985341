import faker from 'faker'
import {} from 'interfaces/editor'
import { ITableReducer, ITableColumn } from '_entities/block'

export function shortId() {
  return '_' + Math.random().toString(36).substr(2, 9)
}

export function randomColor() {
  return `hsl(${Math.floor(Math.random() * 360)}, 95%, 90%)`
}

export function makeData(count: number): ITableReducer {
  const data = []
  let options = []
  for (let i = 0; i < count; i++) {
    const row = {
      ID: faker.mersenne.rand(),
      firstName: faker.name.firstName(),
      lastName: faker.name.lastName(),
      email: faker.internet.email(),
      age: Math.floor(20 + Math.random() * 20),
      music: faker.music.genre(),
    }
    options.push({ label: row.music, backgroundColor: randomColor() })

    data.push(row)
  }

  options = options.filter((a, i, self) => self.findIndex((b) => b.label === a.label) === i)

  const columns: ITableColumn[] = [
    {
      id: 'firstColumn',
      label: 'Column 1',
      accessor: 'firstColumn',
      minWidth: 100,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: 'secondColumn',
      label: 'Column 2',
      accessor: 'secondColumn',
      minWidth: 100,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: 'thirdColumn',
      label: 'Column 3',
      accessor: 'thirdColumn',
      minWidth: 100,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: 'fourthColumn',
      label: 'Column 4',
      accessor: 'fourthColumn',
      minWidth: 100,
      dataType: DataTypes.TEXT,
      options: [],
    },
    /*  {
      id: 'age',
      label: 'Age',
      accessor: 'age',
      width: 80,
      dataType: DataTypes.NUMBER,
      options: [],
    },
    {
      id: 'email',
      label: 'E-Mail',
      accessor: 'email',
      width: 300,
      dataType: DataTypes.TEXT,
      options: [],
    },
    {
      id: 'music',
      label: 'Music Preference',
      accessor: 'music',
      dataType: DataTypes.SELECT,
      width: 200,
      options: options,
    }, */
    {
      id: Constants.ADD_COLUMN_ID,
      width: 20,
      label: '+',
      disableResizing: true,
      dataType: 'null',
    },
  ]
  return { columns: columns, data: data, skipReset: false, isOpSendingAllowed: true }
}

export const ActionTypes = Object.freeze({
  UPDATE_EVERYTHING: 'update_everything',
  ADD_OPTION_TO_COLUMN: 'add_option_to_column',
  ADD_ROW: 'add_row',
  UPDATE_COLUMN_TYPE: 'update_column_type',
  UPDATE_COLUMN_HEADER: 'update_column_header',
  UPDATE_CELL: 'update_cell',
  ADD_COLUMN_TO_LEFT: 'add_column_to_left',
  ADD_COLUMN_TO_RIGHT: 'add_column_to_right',
  DELETE_COLUMN: 'delete_column',
  ENABLE_RESET: 'enable_reset',
})

export const DataTypes = Object.freeze({
  NUMBER: 'number',
  TEXT: 'text',
  SELECT: 'select',
})

export const Constants = Object.freeze({
  ADD_COLUMN_ID: 999999,
})
