export enum EditorImageBlockOptions {
  DIAGONAL = 'diagonal',
  CROP = 'crop',
  ALIGN = 'align',
  DOWNLOAD = 'download',
  CHANGE_WIDTH = 'changeWidth',
  CHANGE_TEXT = 'changeText',
  ALIGN_LEFT = 'alignLeft',
  ALIGN_CENTRE = 'alignCentre',
  ALIGN_RIGHT = 'alignRight',
  FLOAT_LEFT = 'floatLeft',
  FLOAT_RIGHT = 'floatRight',
  REPLACE_IMAGE = 'replaceImage',
  POSITION_START = 'start',
  POSITION_CENTER = 'center',
  POSITION_END = 'end',
  AFFECTED_MOUSE_POSITION_LEFT = 'left',
  AFFECTED_MOUSE_POSITION_RIGHT = 'right',
}
