import styled, { CSSProperties, css } from 'styled-components'
import { ReactComponent as Magnifier } from 'assets/images/MagnifierIcon.svg'
import { ReactComponent as Copy } from 'assets/images/file_copy_black.svg'
import { ReactComponent as Trash } from 'assets/images/trashIcon.svg'
import { ReactComponent as ArrowDown } from 'assets/images/keyboard_arrow_down.svg'
import { ReactComponent as Plus } from 'assets/images/PlusIcon.svg'
import { ReactComponent as Funnel } from 'assets/images/funnel.svg'
import { ReactComponent as EditAddPlusIcon } from 'assets/images/task-manager/EditAddPlus.svg'

import CustomP from 'components/atoms/CustomP/CustomP'
import Accordion from 'react-bootstrap/Accordion'
import { ILayoutsEnum } from 'interfaces/taskManager'
import { StyledChip } from 'components/atoms/DateChip/styles'
import { StyledChip as PropertyChip } from 'components/atoms/PropertyChip/styles'
import { TaskPropertiesWrapper } from '../TaskManagerBoard/styles'

export const StyledFunnelIcon = styled(Funnel)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledSearchAndOptionsBar = styled.div`
  display: flex;
  //align-items: center;
  justify-content: space-between;
  padding: 0 16px;
`

export const StyledTaskSliderFiltersContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
  gap: 8px;
`

export const StyledSearchContainer = styled.div`
  display: flex;
  align-items: center;
`
export const StyledOptionsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
`

export const StyledFilteredByDropdownHeader = styled.div`
  display: flex;
  align-items: center;
`

export const StyledOptionsElement = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 16px 0;
  width: 100%;
  color: var(--primary-text) !important;
`

export const StyledOptionWrapperWithBorder = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--gray4);
  padding: 0 0 16px 0;

  & > p {
    margin: 0;
  }
`

export const StyledDropdownItemName = styled(CustomP)`
  font-weight: 700;
  background-color: transparent !important;
  color: var(--primary-text);
`

export const StyledDropdownNameContainer = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: 500;
  gap: 10px;
  width: 100%;
`

export const NameInputContainer = styled.label`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 6px 8px;
  gap: 8px;
  border: 1px solid var(--secondary-text);
  border-radius: 10px;

  svg path {
    stroke: var(--primary-text);
  }
`

export const NameInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  font-size: inherit;
  color: inherit;
  padding: 0;
  margin: 0;

  flex-grow: 1;
  min-width: 0;
  line-height: 0;
`

export const StyledAccordion = styled(Accordion)<{
  isSubgroup: boolean
  type: string
  isSlider?: boolean
}>`
  & .accordion-item {
    border: none;
    margin-bottom: 10px;
    background: var(--tasks-background);
    position: relative !important;

    * {
      color: var(--primary-text);
    }
  }

  .accordion-header {
    padding: 0px;
  }

  .accordion-header:not(.inner) {
    ${(props) => (!props.isSubgroup ? 'display: none;' : '')}
  }

  & .accordion-button {
    background: var(--gray4);
    color: black;
    padding: 7.5px 16px;
    box-shadow: none;
    border-radius: 4px;

    &::after {
      background-image: none !important;
    }
  }

  & .accordion-button:not(.collapsed) {
    background: ${(props) => (props.isSlider ? 'var(--sidebars)' : 'var(--tasks-background)')};

    &::after {
      background-image: none !important;
    }
  }

  .accordion-body {
    background: ${(props) => (props.isSlider ? 'var(--sidebars)' : 'var(--tasks-background)')};
    padding: 6px;
    position: relative !important;
    ${(props) =>
      props.type === ILayoutsEnum.BOARD &&
      css`
        display: flex;
        gap: 16px;
      `}
  }

  .outside {
    padding: 0;
  }
`

export const DroppableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const DroppableWrapper = styled.div``

export const ColumnTitleContainer = styled.div<{
  marginBottom?: CSSProperties['marginBottom']
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
`

export const ColumnTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const ColumnTitleText = styled.div`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  color: var(--main-black);
`

export const ColumnTitleNumber = styled.div`
  padding: 2px 4px;
  background: var(--hover);
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-text);
`

export const ColumnTitleIcon = styled(EditAddPlusIcon)`
  * {
    stroke: var(--gray2);
  }
`

export const StyledDroppable = styled.div`
  display: flex;
  flex-direction: column;

  & > *:not(:last-of-type) {
    margin-bottom: 12px;
  }

  // margin-top: 20px;

  transition: 0.2s;

  width: 100%;
  height: 100%;
  border-radius: 10px;
`

export const StyledDraggableFloat = styled.div`
  position: absolute;
  right: 5px;
  top: 6px;
  background: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  z-index: 1001;
  padding: 2px;

  transition: 0.2s;

  visibility: hidden;
  opacity: 0;
`

export const StyledDraggable = styled.div<{
  isDragging?: boolean
  selected?: boolean
  type?: 'horizontal' | 'vertical'
  isSlider?: boolean
  isSimpleView?: boolean
}>`
    user-select: none;
    position: relative;
    left: ${(props) => (props.isSlider && props.isDragging ? '14px !important' : '')};
    cursor: pointer !important;
    padding: ${(props) => (props.isSimpleView ? '8px 14px' : '2px 12px 12px 12px')};
    margin-bottom: 6px;
    margin-top: 6px;
    background-color: ${(props) => (props.isDragging ? 'var(--hover)' : 'var(--tasks-background)')};

    border-style: solid;
    border-width: 1px;
    border-color: ${(props) => (props.selected ? 'var(--primary-color)' : '#D6D6D6')};
    border-radius: 7px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;

    &:hover {
        background: var(--hover);

        ${StyledDraggableFloat} {
            visibility: visible;
            opacity: 1;
        }
    }

    ${(props) =>
      props.isSimpleView &&
      css`
        ${StyledChip} {
          background-color: transparent;
          color: var(--primary-text);
          padding: 4px;
          font-size: 12px;
          align-items: flex-start;

          svg {
            display: none;
          }
        }

        ${TaskPropertiesWrapper} {
          gap: 6px;
        }

        & .dropdown-toggle ${PropertyChip} {
          font-size: 12px;
          width: 60px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      `}
}
`

export const MagnifierIcon = styled(Magnifier)`
  path {
    stroke: var(--secondary-text);
  }
`

export const PlusIcon = styled(Plus)`
  transform: scale(0.7);
  stroke: var(--primary-color);
`

export const CopyIcon = styled(Copy)`
  transform: scale(1.1);

  path {
    fill: var(--primary-text);
  }
`

export const TrashIcon = styled(Trash)`
  transform: scale(1.1);

  path {
    fill: var(--everyday-red);
  }
`
export const ArrowDownIcon = styled(ArrowDown)<{ shouldFlip?: boolean }>`
  transition: transform 0.2s;
  ${(props) => (props.shouldFlip ? 'transform: rotate(-180deg)' : '')};
`

export const StyledSelectItem = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
`

export const InputBoxInput = styled.input`
  border: none;
  outline: none;
  background: transparent;
  margin: 0;
  padding: 4px;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--primary-text);

  &::placeholder {
    color: var(--secondary-text);
  }

  width: 200px !important;
`

export const InputBoxContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  cursor: text;

  border-radius: 10px;

  transition: 0.2s;

  border-width: 1px;
  border-style: solid;
  border-color: transparent;

  ${InputBoxInput} {
    transition: 0.4s;

    width: 0;
    min-width: 50px;

    &:focus,
    &:not([value='']) {
      min-width: 150px;
    }
  }
`

export const GrowBox = styled.div`
  flex-grow: 1;
`
