import { IShapeTypes, ITools, Size } from 'interfaces/whiteboard'
import { useCreateBlock } from './useCreateBlock'
import { IBlockTypes } from '_entities/block'
import { useGrid, constants, useFrame } from '_entities/whiteboard'
import { useArrow, useDrawing } from '_features/canvas'
import { useAppSelector } from 'redux/hooks'
import { useEmojiBlock } from '_features/emojis'
import { useEmbedSelection, useEmbedCreation, useInternalEmbedSelection } from '_features/embed'
import { useUpload } from '_features/upload/model/useUpload'

export const useWhiteboardBlock = () => {
  const _createBlock = useCreateBlock()
  const _arrow = useArrow()
  const _grid = useGrid()
  const _drawing = useDrawing()
  const _emojiBlock = useEmojiBlock()
  const _embedSelection = useEmbedSelection()
  const _internalEmbedSelection = useInternalEmbedSelection()
  const _embedCreation = useEmbedCreation()
  const _frame = useFrame()
  const _upload = useUpload()
  const currentUser = useAppSelector((state) => state.global.user)

  const newShapeConfig = (pageId: string, size?: Size, isShapeDrawn?: boolean) => {
    return {
      [ITools.PENTAGON]: () =>
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.SHAPE,
          shapeType: IShapeTypes.PENTAGON,
          size,
          isShapeDrawn,
          pageId,
        }),
      [ITools.HEXAGON]: () =>
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.SHAPE,
          shapeType: IShapeTypes.HEXAGON,
          size,
          isShapeDrawn,
          pageId,
        }),
      [ITools.ROMB]: () =>
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.SHAPE,
          shapeType: IShapeTypes.ROMB,
          size,
          isShapeDrawn,
          pageId,
        }),
      [ITools.CIRCLE]: () =>
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.SHAPE,
          shapeType: IShapeTypes.CIRCLE,
          size,
          isShapeDrawn,
          pageId,
        }),
      [ITools.RECTANGLE]: () =>
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.SHAPE,
          shapeType: IShapeTypes.RECTANGLE,
          size,
          isShapeDrawn,
          pageId,
        }),
      [ITools.CURVED_RECTANGLE]: () =>
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.SHAPE,
          shapeType: IShapeTypes.CURVED_RECTANGLE,
          size,
          isShapeDrawn,
          pageId,
        }),
      [ITools.TRIANGLE]: () =>
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.SHAPE,
          shapeType: IShapeTypes.TRIANGLE,
          size,
          isShapeDrawn,
          pageId,
        }),
    }
  }

  const newBlockTypeConfig = (pageId: string): { [key in ITools]: () => void } => {
    return {
      ...newShapeConfig(pageId),
      ..._frame.newFrameConfig(pageId),
      [ITools.CURSOR]: () => void 0,
      [ITools.HAND]: () => void 0,
      [ITools.ERASER]: () => void 0,
      [ITools.TEXT]: () =>
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.TEXT,
          initialText: '',
          pageId,
        }),

      [ITools.ARROW]: () => {
        _createBlock.createWhiteboardBlock({ type: IBlockTypes.ARROW, pageId })
        _arrow.setArrowCoordinates(null)
      },
      [ITools.PEN]: () => {
        _createBlock.createWhiteboardBlock({ type: IBlockTypes.LINE, pageId })
        _drawing.setLine(null)
      },
      [ITools.STICKY]: () =>
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.STICKY_NOTE,
          initialText: '',
          createdBy: `${currentUser?.firstName} ${currentUser?.lastName}`,
          scale: {
            scaleX: _grid.getSizeFromGridLength(1) / constants.STICKY_NOTE_DEFAULT_WIDTH,
            scaleY: _grid.getSizeFromGridLength(1) / constants.STICKY_NOTE_DEFAULT_HEIGHT,
          },
          pageId,
        }),
      [ITools.EMOJI]: () => {
        const selectedEmoji = _emojiBlock.getSelectedCanvasEmoji()
        if (!selectedEmoji) return
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.EMOJI,
          emoji: selectedEmoji,
          pageId,
          name: selectedEmoji.name,
        })
        _emojiBlock.removeCanvasEmoji()
      },
      [ITools.INTERNAL_EMBED]: () => {
        const selectedEmbed = _internalEmbedSelection.getSelectedEmbed()
        if (!selectedEmbed) return
        if (selectedEmbed.type === IBlockTypes.DOCUMENT)
          _createBlock.createDocumentPageAndGenerateBlock(pageId)
        else if (selectedEmbed.type === IBlockTypes.WHITEBOARD)
          _createBlock.createWhiteboardPageAndGenerateBlock(pageId)
        else if (selectedEmbed.pageId)
          _createBlock.handleSelectLinkPage(pageId, selectedEmbed.pageId)
        _internalEmbedSelection.removeSelectedEmbed()
      },
      [ITools.EXTERNAL_EMBED]: () => {
        const selectedEmbed = _embedSelection.getSelectedEmbed()
        if (!selectedEmbed) return

        _embedCreation.handleWbExternalLink(selectedEmbed.url, selectedEmbed.type, pageId)
      },
      [ITools.FILE]: () => {
        const file = window.__FILE__
        if (!file) return
        _upload.handleWbFileUpload(file, pageId)
        delete window.__FILE__
      },
      [ITools.CTA]: () => {
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.CTA,
          pageId,
        })
      },
    }
  }

  return {
    newBlockTypeConfig,
    newShapeConfig,
  }
}
