import { StyleTypes, type block, BlockStyleType, IBlockTypes } from '_entities/block'
import { IExternalEmbed } from '_entities/embed'
import { RelationshipType } from '_features/relationship'
import { IArrowItem, IFrameItem, ILineItem, IShapeItem, Scale } from 'interfaces/whiteboard'
import objectId from './objectId'
import { generateBlockDefaultStyle } from './generateBlockDefaultStyle'
import { getColor } from 'shared/colors'
import { IPage } from 'interfaces/page'
import { DeltaStatic } from 'quill'
import { Doc } from 'sharedb'
import { Emoji } from '_features/emojis'

export const generateTextBlock = (
  tag: IBlockTypes,
  userUuid: string,
  initialText?: string | DeltaStatic,
  name?: string,
  pageId?: string,
): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: pageId || '',
      createdBy: userUuid || '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: name || `${tag}_1`,
    },
    data: {
      tag,
      richText: initialText || '',
      imageUrl: '',
      positionIndex: 1,
    },
    style: {
      styling: generateBlockDefaultStyle[tag],
    },
  }
}

export const generateStickyNoteBlock = (
  tag: IBlockTypes,
  userUuid: string,
  initialText?: string | DeltaStatic,
  createdBy?: string,
  scale?: Scale,
  name?: string,
): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: '',
      createdBy: userUuid || '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: name || `${tag}_1`,
    },
    data: {
      tag,
      richText: initialText,
      shape: {
        type: null,
        fill: getColor('--everyday-yellow'),
      },
      createdBy,
      positionIndex: 1,
      scaleX: scale?.scaleX || 1,
      scaleY: scale?.scaleY || 1,
    },
    style: {
      styling: generateBlockDefaultStyle[tag],
    },
  }
}

export const generateNewDocumentBlock = (
  tag: IBlockTypes,
  documentPage: IPage,
  userUuid: string,
  isExpanded?: boolean,
  name?: string,
): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: '',
      createdBy: userUuid || '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: name || `${tag}_1`,
    },
    data: {
      tag,
      documentPage,
      imageUrl: '',
      positionIndex: 1,
      scaleX: 1,
      scaleY: 1,
      isEmbedExpanded: isExpanded || true,
    },
    style: {
      blockType: BlockStyleType.TEXT,
      styling: [
        { type: StyleTypes.COLOR, value: '' },
        { type: StyleTypes.BACKGROUND_COLOR, value: '' },
        { type: StyleTypes.BOLD, value: '' },
        { type: StyleTypes.UNDERLINE, value: '' },
        { type: StyleTypes.STRIKE_THROUGH, value: '' },
        { type: StyleTypes.ITALIC, value: '' },
        { type: StyleTypes.FONT_FAMILY, value: '' },
        { type: StyleTypes.FONT_SIZE, value: '' },
        { type: StyleTypes.FONT_WEIGHT, value: '' },
      ],
    },
  }
}

interface IExternalBlock {
  tag: IBlockTypes
  userUuid: string
  embed?: IExternalEmbed
  name?: string
  isExpanded?: boolean
}

export const generateNewExternalPageBlock = (args: IExternalBlock): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: '',
      createdBy: args.userUuid || '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: args.name || `${args.tag}_1`,
    },
    data: {
      tag: args.tag,
      embed: args.embed,
      imageUrl: '',
      positionIndex: 1,
      scaleX: 1,
      scaleY: 1,
      isEmbedExpanded: args.isExpanded || true,
    },
  }
}

export const generateLineBlock = (
  tag: IBlockTypes,
  line: ILineItem,
  userUuid: string,
  name?: string,
): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: '',
      createdBy: userUuid || '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: name || `${tag}_1`,
    },
    data: {
      tag,
      line,
      positionIndex: 1,
      scaleX: 1,
      scaleY: 1,
    },
  }
}

export const generateArrowBlock = (
  tag: IBlockTypes,
  userUuid: string,
  arrow: IArrowItem,
  name?: string,
): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: '',
      createdBy: userUuid || '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: name || `${tag}_1`,
    },
    data: {
      tag,
      arrow,
      positionIndex: 1,
      scaleX: 1,
      scaleY: 1,
    },
  }
}

export const generateShapeBlock = (
  tag: IBlockTypes,
  userUuid: string,
  shape: IShapeItem,
  scale?: Scale,
  name?: string,
): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: '',
      createdBy: userUuid,
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: name || `${tag}_1`,
    },
    data: {
      tag,
      richText: '',
      connectionFrom: [],
      connectionTo: [],
      scaleX: scale?.scaleX,
      scaleY: scale?.scaleY,
      shape,
      positionIndex: 1,
    },
    style: {
      blockType: BlockStyleType.TEXT,
      styling: [
        { type: StyleTypes.COLOR, value: getColor('--gray4') },
        { type: StyleTypes.BACKGROUND_COLOR, value: '' },
        { type: StyleTypes.BOLD, value: '' },
        { type: StyleTypes.UNDERLINE, value: '' },
        { type: StyleTypes.STRIKE_THROUGH, value: '' },
        { type: StyleTypes.ITALIC, value: '' },
        { type: StyleTypes.FONT_FAMILY, value: 'Inter' },
        { type: StyleTypes.FONT_SIZE, value: '18px' },
        { type: StyleTypes.FONT_WEIGHT, value: '400' },
      ],
    },
  }
}

export const generateFrameBlock = (
  tag: IBlockTypes,
  userUuid: string,
  frame: IFrameItem,
  name?: string,
): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: '',
      createdBy: userUuid || '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: name || `${tag}_1`,
    },
    data: {
      tag,
      frame,
      positionIndex: 0,
      scaleX: 1,
      scaleY: 1,
    },
    style: {
      blockType: BlockStyleType.TEXT,
      styling: [
        { type: StyleTypes.COLOR, value: getColor('--gray4') },
        { type: StyleTypes.BACKGROUND_COLOR, value: getColor('--white') },
        { type: StyleTypes.BOLD, value: '' },
        { type: StyleTypes.UNDERLINE, value: '' },
        { type: StyleTypes.STRIKE_THROUGH, value: '' },
        { type: StyleTypes.ITALIC, value: '' },
        { type: StyleTypes.FONT_FAMILY, value: '' },
        { type: StyleTypes.FONT_SIZE, value: '' },
        { type: StyleTypes.FONT_WEIGHT, value: '' },
      ],
    },
  }
}

export const generateImageBlock = (
  tag: IBlockTypes,
  userUuid: string,
  imageUrl?: string,
  name?: string,
): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: '',
      createdBy: userUuid || '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: name || `${tag}_1`,
    },
    data: {
      tag,
      imageUrl,
      positionIndex: 1,
      scaleX: 1,
      scaleY: 1,
    },
  }
}

export const generateEmojiBlock = (
  tag: IBlockTypes,
  userUuid: string,
  emoji: Emoji,
  name?: string,
): block => {
  return {
    _id: objectId(),
    meta: {
      pageId: '',
      createdBy: userUuid || '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: new Date().toLocaleDateString('en-US'),
      name: name || `${tag}_1`,
    },
    data: {
      tag,
      emoji,
      positionIndex: 1,
      scaleX: 1,
      scaleY: 1,
    },
  }
}

export const constructBlock = (
  doc: Doc,
  relationshipType: RelationshipType,
  block: block,
): block => {
  return {
    ...block,
    _id: objectId(),
    meta: {
      createdBy: block.meta.createdBy,
      pageId: doc.id,
      relationshipType,
      originalObject: {
        _id: block._id,
        pageOfOriginalBlock: block.meta.pageId ?? '',
      },
      tags: block.meta.tags,
      assignees: block.meta.assignees,
      dueDate: block.meta.dueDate,
      createdAt: block.meta.createdAt,
      name: block.meta.name,
    },
  }
}
