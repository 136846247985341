import styled from 'styled-components'

export const TaskBlockWrapper = styled.div<{ margin?: string; isDisabled?: boolean }>`
  width: 100%;
  margin: ${(props) => props.margin};
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
`

export const TaskBlockContentWrapper = styled.div`
  background-color: var(--tasks-background);
`

export const SelectedObjectImage = styled.div<{ backgroundImage?: string }>`
  width: 100%;
  height: 200px;
  pointer-events: none;
  background-size: cover !important;
  background-position: center !important;
  background: ${(props) => `url('${props.backgroundImage}')`};
`

export const PageBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px;
`

export const ErrorWrapper = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  position: relative;
  pointer-events: none;
  margin-bottom: 4px;
  color: var(--gray-inactive) !important;
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
`
