import styled from 'styled-components'

export const StyledAvatar = styled.div<{
  userColor?: string
  isNotSamePage?: boolean
  width?: string
  heigth?: string
  fontSize?: string
  borderRadius?: string
}>`
  width: ${(props) => props.width};
  min-width: ${(props) => props.width};
  height: ${(props) => props.heigth};
  min-height: ${(props) => props.heigth};
  font-size: ${(props) => props.fontSize};
  border-radius: ${(props) => props.borderRadius};
  background-color: ${(props) => props.userColor};
  opacity: ${(props) => (props.isNotSamePage ? 0.3 : 1)};
  display: flex;
  min-width: ${(props) => props.width};
  min-height: ${(props) => props.heigth};
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: normal;
  overflow: hidden;
`
