import styled from 'styled-components'
import { IUserAttributes } from 'interfaces/auth/userData'

export const PresenceContainer = styled.div<{ userPresence: IUserAttributes }>`
  display: flex;
  align-items: center;
  background-color: var(--white);
  padding: 0.2rem 0.4rem;
  border-radius: 4px;
  border: ${(props) => `1px solid ${props.userPresence.userColor}`};
  gap: 4px;
  position: absolute;
  right: 12px;
  color: ${(props) => (props.userPresence.userColor ? props.userPresence.userColor : 'grey')};
`
export const PresenceIndicator = styled.div<{ userPresence: IUserAttributes }>`
  display: inline-block;
  position: relative;
  width: var(--presence-width);
  height: var(--presence-width);
  border-radius: 100%;
  background-color: ${(props) =>
    props.userPresence.userColor ? props.userPresence.userColor : 'grey'};
`

interface Props {
  user: IUserAttributes
}
export const Presence = (props: Props) => {
  return (
    <PresenceContainer userPresence={props.user}>
      <PresenceIndicator userPresence={props.user} />
      {props.user.firstName}
    </PresenceContainer>
  )
}
