import { SourceKeys } from 'interfaces/editor'
import { type block } from '_entities/block'
import { RelationshipType } from './types'
import React, { useState } from 'react'
import { MultiValue } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useShareDb } from '_entities/page'
import { useToast } from 'services/helpers/useToast'
import { useAppSelector } from 'redux/hooks'
import { useSubmit, error } from 'utils/shareDB/useSubmit'
import { Doc as JsonDoc } from 'ot-json1'
import sharedb from 'sharedb'
import { IPages } from 'interfaces/page'
import { constructBlock } from 'utils/editor/generateBlock'
import { BlockMetaKeys } from '_entities/block/model/types'
import { PageTypesEnum } from 'interfaces/projectFile'
import { getDoc } from 'shared/shareDb'

interface Props {
  onSuccess?: () => void
  block?: block
}

export const useRelationships = (props: Props) => {
  const currentUser = useAppSelector((state) => state.global.user)
  const [selectedPages, setSelectedPages] = useState<MultiValue<Option>>([])
  const [isLoading, setIsLoading] = useState<string>('')
  const pages = useAppSelector((state) => state.projectFile.pages)
  const pagesWithoutTaskManager = pages?.filter((page) => page.type !== PageTypesEnum.TASK_MANAGER)
  const { addBlock } = useJson1()
  const _shareDb = useShareDb()
  let doc: sharedb.Doc | undefined
  if (props.block) {
    doc = getDoc(props.block?.meta.pageId)
  }
  const { submit } = useSubmit()
  const { getReplaceBlockDataKeyOp, getReplaceBlockMetaKeyOp } = useJson1()

  const index = (doc?.data?.blocks as block[])?.findIndex((item) => item._id === props.block?._id)

  const toast = useToast()
  const selectPages = async (newValue: MultiValue<Option>) => {
    setSelectedPages(newValue)
  }

  const createRelationship = (
    relationshipType: RelationshipType,
    block: block,
    pages?: MultiValue<Option>,
  ) => {
    const pagesToCreateRelationship = pages ?? selectedPages
    setIsLoading(relationshipType)
    pagesToCreateRelationship.forEach(async (page: Option, i: number) => {
      _shareDb.handleUnsubscribedPage(page.value, (doc) => {
        const constructedBlock = constructBlock(doc, relationshipType, block)
        doc.submitOp(
          addBlock(constructedBlock, doc.data.blocks.length),
          { source: currentUser },
          error,
        )
      })
      i === selectedPages.length - 1 && setIsLoading('')
    })
    toast.success(
      `Created ${relationshipType} relationship on ${pagesToCreateRelationship.length} ${
        pagesToCreateRelationship.length > 1 ? 'pages' : 'page'
      }`,
    )
  }

  const handlePushUpdates = (e: React.MouseEvent, block: block) => {
    e.preventDefault()
    e.stopPropagation()
    const op = getReplaceBlockDataKeyOp(
      index,
      ['updateInstance'],
      !block.data.updateInstance as unknown as JsonDoc,
    )
    if (!doc) return
    submit(doc.id, op, SourceKeys.UPDATE_BLOCK)
    toast.success('Pushed updates to all instances')
  }

  const handlePullUpdates = (e: React.MouseEvent, block: block) => {
    e.preventDefault()
    e.stopPropagation()
    const op = getReplaceBlockDataKeyOp(
      index,
      ['updateInstance'],
      !block.data.updateInstance as unknown as JsonDoc,
    )
    if (!doc) return
    submit(doc.id, op, SourceKeys.UPDATE_BLOCK)
    toast.success('Pulled updates from original')
  }

  const handleDetachAllFromOriginal = (
    e: React.MouseEvent,
    block: block,
    relationshipType: RelationshipType,
  ) => {
    let relatedObjects = block.meta.relatedObjects
    if (relationshipType === RelationshipType.INSTANCE) {
      relatedObjects = block.meta.relatedObjects?.filter(
        (relatedObject) => relatedObject.typeOfRelation !== RelationshipType.INSTANCE,
      )
    } else if (relationshipType === RelationshipType.MIRROR) {
      relatedObjects = block.meta.relatedObjects?.filter(
        (relatedObject) => relatedObject.typeOfRelation !== RelationshipType.MIRROR,
      )
    }

    const op = getReplaceBlockMetaKeyOp(
      index,
      [BlockMetaKeys.RELATED_OBJECTS],
      relatedObjects as unknown as JsonDoc,
    )
    if (!doc) return
    submit(doc.id, op, SourceKeys.UPDATE_BLOCK)
    toast.success(`Detached all ${relationshipType}s`)
  }

  const handleDetachFromOriginal = (relationshipId: string, block: block, e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()

    const relatedObjects = block.meta.relatedObjects?.filter(
      (relatedObject) => relatedObject.relatedObject !== relationshipId,
    )

    const op = getReplaceBlockMetaKeyOp(
      index,
      [BlockMetaKeys.RELATED_OBJECTS],
      relatedObjects as unknown as JsonDoc,
    )
    if (!doc) return
    submit(doc.id, op, SourceKeys.UPDATE_BLOCK)
  }

  const handleSwitchRelationshipType = (type: RelationshipType, e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    const op = getReplaceBlockMetaKeyOp(
      index,
      ['relationshipType'],
      type,
      props.block?.meta.relationshipType,
    )
    if (!doc) return

    submit(doc.id, op, SourceKeys.UPDATE_BLOCK)
  }

  const handleDetachFromRelationship = () => {
    const op = getReplaceBlockMetaKeyOp(index, ['originalObject'], null)
    if (!doc) return

    submit(doc.id, op, SourceKeys.UPDATE_BLOCK)
  }

  const filterPages = (pages: IPages[]): IPages[] => {
    const filteredArray: IPages[] = []
    const pagesWithParents: IPages[] = []

    for (const page of pages) {
      if (page.parent) {
        pagesWithParents.push(page)
      } else {
        filteredArray.push(page)
      }
    }

    for (const page of pagesWithParents) {
      const parentIndex = filteredArray.findIndex((p) => p.id === page.parent)

      if (parentIndex !== -1) {
        filteredArray.splice(parentIndex + 1, 0, page)
      }
    }

    return filteredArray
  }

  const generateOriginalObject = (block: block) => {
    return {
      _id: block._id,
      pageOfOriginalBlock: block.meta.pageId,
    }
  }

  const generateRelatedObject = (
    block: block,
    pageId: string,
    typeOfRelation: RelationshipType,
  ) => {
    return {
      relatedObject: block._id,
      typeOfRelation,
      changed: false,
      pageId,
    }
  }

  const constructRelationshipPage = (page: IPages) => {
    return {
      ...page,
      value: page?.id as string,
      label: page?.text,
    }
  }

  return {
    isLoading,
    selectPages,
    createRelationship,
    selectedPages,
    handlePushUpdates,
    handlePullUpdates,
    handleDetachAllFromOriginal,
    handleDetachFromOriginal,
    handleDetachFromRelationship,
    handleSwitchRelationshipType,
    orderedPages: filterPages(pagesWithoutTaskManager ?? []),
    generateOriginalObject,
    generateRelatedObject,
    constructRelationshipPage,
  }
}
