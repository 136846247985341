import { ITask, ITaskProperty, PropertyCategories } from 'interfaces/taskManager'
import { getAllProperties } from '_entities/task'

export const createCategoriesByPropertyName = (
  tasks: ITask[],
  propertyName: string,
): PropertyCategories => {
  const allProperties = getAllProperties(tasks)

  const propertiesByName: ITaskProperty[] = allProperties.filter(
    (prop) => prop.propertyDefinition?.name === propertyName,
  )

  const filteredCategories = propertiesByName.filter((prop) => prop.value)

  const categoriesMap = new Map(
    filteredCategories.map((prop) => {
      return [prop.value, prop]
    }),
  )

  return Array.from(categoriesMap)
}
