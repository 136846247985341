import React from 'react'
import AuthInput from 'components/atoms/AuthInput/AuthInput'
import LoadingAnim from 'components/atoms/LoadingAnim/LoadingAnim'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import TextDanger from 'components/atoms/TextDanger/TextDanger'
import CustomP from 'components/atoms/CustomP/CustomP'

import { useSignupForm } from './useSignupForm'
import { Form, HorizontalDivider, InputWrapperPassword, InputWrapperEmail } from './styles'

import emailIcon from 'assets/images/emailIcon.svg'
import emailRed from 'assets/images/emailRed.svg'
import lockProtect from 'assets/images/lockProtect.svg'
import lockProtectRed from 'assets/images/lockRed.svg'

type Props = {
  onSubmit: React.FormEventHandler<HTMLFormElement>
  setFirstName: React.Dispatch<React.SetStateAction<string>>
  setLastName: React.Dispatch<React.SetStateAction<string>>
  validateEmail: () => boolean
  setEmail: React.Dispatch<React.SetStateAction<string>>
  setPassword: React.Dispatch<React.SetStateAction<string>>
  setConfirmPassword: React.Dispatch<React.SetStateAction<string>>
  confirmPassword: string
  passwordMissmatchMessage: string
  checkIfPasswordSafe: () => boolean
  error: string
  loading: boolean
  setAgreeTerms: React.Dispatch<React.SetStateAction<boolean>>
  isChecked: boolean
  firstName: string
  lastName: string
  email: string
  password: string
}

const index: React.FC<Props> = ({
  onSubmit,
  setFirstName,
  setLastName,
  validateEmail,
  setEmail,
  setPassword,
  firstName,
  lastName,
  password,
  email,
  passwordMissmatchMessage,
  checkIfPasswordSafe,
  error,
  loading,
  setAgreeTerms,
  isChecked,
}) => {
  const {
    emailValidityChecker,
    passwordValidityChecker,
    passwordPositiveWeaknessDetector,
    passwordNegativeWeaknessDetector,
  } = useSignupForm(email, validateEmail, password, checkIfPasswordSafe)

  return (
    <Form onSubmit={onSubmit}>
      <div className='mb-md-1 mt-md-1 pb-1'>
        <HorizontalDivider>
          <AuthInput
            type='text'
            id='firstName'
            htmlFor='floatingInput'
            placeholder='First name'
            margin='0 0 1.25rem'
            isRequired
            label={firstName || lastName ? 'First name' : ''}
            onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFirstName(e.target.value)
            }}
            className='placeholderDarker'
            width='95%'
            inputInnerWidth='100%'
          />

          <AuthInput
            type='text'
            id='lastName'
            htmlFor='floatingInput'
            placeholder='Last name'
            margin='0 0 1.25rem'
            isRequired
            label={firstName || lastName ? 'Last name' : ''}
            onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
              setLastName(e.target.value)
            }}
            className='placeholderDarker'
            width='95%'
            inputInnerWidth='100%'
          />
        </HorizontalDivider>

        <InputWrapperEmail>
          <AuthInput
            type='email'
            placeholder='Email address'
            id='email'
            className={emailValidityChecker}
            isRequired
            label={email ? 'Email address' : ''}
            onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value)
            }}
            iconLeft={emailIcon}
            iconLeftError={emailRed}
            moveIcon='mt-5'
            paddingLeft='2.5rem'
            valueEntered={email}
            focusBorder={email ? '' : ''}
            inputInnerWidth='100%'
          />
        </InputWrapperEmail>
        <CustomP className='mb-4 px-0 pt-1 text-start' color='var(--everyday-red) !important'>
          {!validateEmail() && 'Please enter a valid email address.'}
        </CustomP>
        <InputWrapperPassword>
          <AuthInput
            type='password'
            id='password'
            placeholder='Create password'
            className={passwordValidityChecker}
            isRequired
            label={password ? 'Create password' : ''}
            onChangeFunction={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword(e.target.value)
            }}
            iconLeft={lockProtect}
            iconLeftError={lockProtectRed}
            moveIcon='mt-5'
            paddingLeft='2.5rem'
            eyeIconTop='45px'
            width='600px !important'
            inputInnerWidth='100%'
          />
        </InputWrapperPassword>

        {passwordPositiveWeaknessDetector && (
          <CustomP
            className='text-start'
            color='var(--everyday-red) !important'
            marginBottom='1rem'
            marginTop='0rem'
            fontWeight='500'
          >
            Too weak. Your password should contain numbers and letters.
          </CustomP>
        )}

        {passwordNegativeWeaknessDetector && (
          <CustomP
            className='text-start'
            color='var(--everyday-green-dark) !important'
            marginTop='-1rem'
            marginBottom='1rem'
            fontWeight='500'
          >
            Strong enough :)
          </CustomP>
        )}

        {error && <TextDanger text={error} />}
        {loading && (
          <span className='d-flex justify-content-center align-items-center'>
            <LoadingAnim className='loading-spinner mb-3' />
          </span>
        )}
        <div className='mt-4 mb-4'>
          <AuthInput
            className='form-check-input'
            type='checkbox'
            label='I agree to the <a href="https://www.google.com" style="color: var(--everyday-blue) !important; text-decoration: none !important;" target="blank" rel="noopener noreferrer">Terms and Conditions</a>'
            id='flexCheckDefault'
            isRequired
            labelClassName='form-check-label text-secondary'
            htmlFor='flexCheckDefault'
            textFontSize='16pt !important'
            onChangeFunction={(e) => {
              setAgreeTerms(!isChecked)
            }}
            isChecked={isChecked}
            onInvalidFunction={(e) =>
              !isChecked &&
              (e.target as HTMLInputElement).setCustomValidity('Passwords has to match.')
            }
            labelFontSize='14px'
            padding='0.65rem'
          />
        </div>
        <div className='d-grid gap-2'>
          <CustomButton
            height='40px'
            className='btn pb-1 my-2'
            type='submit'
            borderradius='10px'
            background='var(--primary-color)'
          >
            <CustomP className='mb-0 whiten' fontSize='16px' fontWeight='600'>
              Create account
            </CustomP>
          </CustomButton>
        </div>
      </div>
    </Form>
  )
}

export default index
