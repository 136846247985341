import { StyledChip } from './styles'

interface Props {
  text: string
  status?: string
}

const PropertyChip = (props: Props) => {
  return <StyledChip status={props.status}>{props.text}</StyledChip>
}

export default PropertyChip
