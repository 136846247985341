import React, { useEffect, useRef, useState } from 'react'
import { renderToString } from 'react-dom/server'
import { DraggingTreeProps } from './types'
import { useTree } from '_entities/tree/model/useTree'
import { TreeItem } from '_entities/tree/model/types'
import { LAST_PLACEHOLDER } from '../ui/DraggableTree'
import { block } from '_entities/block'
import { useAppSelector } from 'redux/hooks'
import { usePagesTreeStructure } from '_features/page'
import { getById } from 'shared/lib'
import { EDITOR_BLOCK_NAME } from '_widgets/DocumentBlock'

export const DRAG_PREVIEW_ID = 'drag-preview'

export const ROOT_ID = 0

const useDragging = (props: DraggingTreeProps) => {
  const [itemHovered, setItemHovered] = useState<TreeItem | null>(null)
  const [isDragging, setIsDragging] = useState<boolean>(false)
  const [items, setItems] = useState<TreeItem[]>([])
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const _tree = useTree()
  const _pagesTreeStructure = usePagesTreeStructure()

  const handleRefs = useRef<{ [key: string]: React.RefObject<HTMLDivElement> }>({})

  useEffect(() => {
    const newRefs: { [key: string]: React.RefObject<HTMLDivElement> } = {}
    items.forEach((item) => {
      newRefs[item.id] = React.createRef()
    })
    handleRefs.current = newRefs

    return () => {
      items.forEach((item) => {
        const handleRef = handleRefs.current[item.id]
        if (handleRef && handleRef.current) {
          handleRef.current.removeEventListener('dragstart', (event) =>
            onHandlerDragStart(event, item),
          )
          handleRef.current.removeEventListener('drag', (event) => onHandlerDrag(event, item))
          handleRef.current.removeEventListener('dragend', (event) => onHandlerDragEnd(event, item))
        }
      })
    }
  }, [items])

  useEffect(() => {
    const ordered: TreeItem[] = []
    const orderItems = () => {
      const items = props.items.sort((a, b) =>
        a.positionIndex && b.positionIndex ? a.positionIndex - b.positionIndex : 0,
      )

      const newItems: TreeItem[] = []

      props.initialTreeStructure &&
        props.initialTreeStructure.map((item) => {
          const index = items.findIndex((i) => i.id === item.id)
          if (index > -1) {
            newItems.push({
              ...items[index],
              isShown: item.isShown,
            })
          }
        })

      const rootItems = (props.initialTreeStructure ? newItems : items).filter(
        (item) => !item.parent,
      )
      rootItems.forEach((rootItem) =>
        assignDepth(props.initialTreeStructure ? newItems : items, rootItem, 0),
      )

      setItems(ordered)
    }

    const assignDepth = (items: TreeItem[], item: TreeItem, depth: number) => {
      ordered.push({
        ...item,
        depth,
        isShown: item.isShown !== undefined ? item.isShown : true,
        text: item.text,
        type: item.type,
      })
      const children = items.filter((child) => child.parent === item.id)
      children.forEach((child) => assignDepth(items, child, depth + 1))
    }

    // ** If the sort prop is true items will be ordered and depth will be assigned
    if (props.sort) {
      setTimeout(() => {
        orderItems()
      }, 10)
    } else {
      setItems(props.items)
    }
  }, [props.items, props.initialTreeStructure])

  const hasChild = (items: TreeItem[], item: TreeItem) => {
    return items.map((item) => item.parent).includes(item.id)
  }

  const getIndex = (items: TreeItem[], item: TreeItem) => {
    return items.findIndex((sibling) => sibling.id === item.id)
  }

  const onDragEndItem = (event: React.DragEvent<HTMLDivElement>) => {
    // ** Clear the data from the event
    event.dataTransfer.clearData()
    setItemHovered(null)
    setIsDragging(false)
    const dragPreview = getById(DRAG_PREVIEW_ID)
    if (dragPreview) {
      dragPreview.innerHTML = ''
      event.dataTransfer.setDragImage(dragPreview, 0, 0)
    }
  }

  const onDragStartItem = (event: React.DragEvent<HTMLDivElement>, item: TreeItem) => {
    setIsDragging(true)
    event.dataTransfer.effectAllowed = 'copyMove'

    props.onDragStart && item.data && props.onDragStart(item.data as block)

    const itemElement = getById(`drag-item-${item.id}`)

    if (props.dragPreviewRender) {
      const dragPreview = getById(DRAG_PREVIEW_ID)
      if (dragPreview) {
        ;(event.target as HTMLElement).style.cursor = 'move'
        const DragPreviewRender = props.dragPreviewRender(item)

        if (DragPreviewRender) {
          dragPreview.innerHTML = renderToString(DragPreviewRender)
        }

        event.dataTransfer.setDragImage(dragPreview, 0, 0)
      }
    } else {
      if (itemElement) {
        const dragPreview = getById(DRAG_PREVIEW_ID)
        if (dragPreview) {
          const editorBlockElement = itemElement.querySelector(
            `div[data-block-name=${EDITOR_BLOCK_NAME}]`,
          ) as HTMLElement

          dragPreview.innerHTML = editorBlockElement?.innerHTML || itemElement.innerHTML
          event.dataTransfer.setDragImage(dragPreview, 0, 0)
        }
      }
    }

    event.dataTransfer.setData(
      'text/plain',
      JSON.stringify({
        ...item,
        id: item.id,
        text: item.text,
        type: item.type,
      }),
    )
  }

  const onDrop = async (
    event: React.DragEvent<HTMLLIElement> | React.DragEvent<HTMLDivElement>,
    items: TreeItem[],
  ) => {
    setIsDragging(false)
    setItemHovered(null)
    if (!itemHovered || !projectFile) return

    const droppedData = JSON.parse(event.dataTransfer.getData('text/plain'))

    droppedData.parent = itemHovered
      ? itemHovered.id === LAST_PLACEHOLDER
        ? 0
        : itemHovered.id
      : droppedData.parent

    droppedData.parent = props.rootId === 0 ? 0 : droppedData.parent

    const currentIndex = getIndex(items, droppedData)
    const siblings = _tree.getSiblings(items, itemHovered)
    let destinationIndex = getIndex(items, itemHovered)
    const filteredItems = items.filter((item) => droppedData.id !== item.id)

    let destination = destinationIndex > currentIndex ? destinationIndex - 1 : destinationIndex

    if (currentIndex === -1) {
      destination = destinationIndex
    }

    const newTree = [
      ...filteredItems.slice(0, destination),
      droppedData,
      ...filteredItems.slice(destination),
    ]

    const newItems: TreeItem[] = []

    if (itemHovered.id) {
      newTree.map((item) => {
        if (item.parent === itemHovered.parent) {
          newItems.push(item)
        }
      })
    } else {
      newTree.map((item) => {
        // If the page is a root page
        if (item.parent === 0) {
          newItems.push(item)
        }
      })
    }

    if (newItems.length > 0) {
      destination = newItems.findIndex((node) => node.id === droppedData.id)
    }

    if (itemHovered.type === LAST_PLACEHOLDER) {
      destination = siblings.length
      destinationIndex = siblings.length
    }

    if (droppedData.id === itemHovered.id) return

    props.onDrop &&
      (await props.onDrop({
        event,
        newTree,
        dropTarget: itemHovered,
        relativeIndex: destination,
        droppedData,
        destinationIndex,
      }))

    if (props.sort) {
      _pagesTreeStructure.setNewTreeStructure(
        projectFile.id,
        newTree.map((item) => {
          return {
            id: item.id,
            isShown: item.isShown,
          }
        }),
      )
      setItems(newTree)
    }
  }

  const handleOnOpen = (id: string, items: TreeItem[]) => {
    const children = _tree.getSubItems(items, id)

    const filteredItems: TreeItem[] = []

    if (children.length === 0 || !projectFile) return

    items.map((orderedItem) => {
      const index = children.findIndex((child) => child.id === orderedItem.id)
      if (index > -1) {
        filteredItems.push({
          ...orderedItem,
          isShown: !children[0].isShown,
        })
        return
      }

      filteredItems.push(orderedItem)
    })

    if (props.sort) {
      setItems(filteredItems)
      _pagesTreeStructure.setNewTreeStructure(
        projectFile.id,
        filteredItems.map((item) => {
          return {
            id: item.id,
            isShown: !!item.isShown,
          }
        }),
      )
    }
  }

  const removeItemHovered = () => {
    setItemHovered(null)
  }

  const handleSetItemHovered = ({
    item,
    isBetweenItems,
  }: {
    item: TreeItem
    isBetweenItems: boolean
  }) => {
    setItemHovered({
      ...item,
      isBetweenItems,
    })
  }

  const checkAreChildrenVisible = (id: string, items: TreeItem[]) => {
    const children = _tree.getFirstChildren(items, id)

    if (children.length === 0) return

    return children.every((child) => child.isShown)
  }

  const onHandlerDragStart = (event: DragEvent, item: TreeItem) => {
    onDragStartItem(event as unknown as React.DragEvent<HTMLDivElement>, item)
  }

  const onHandlerDragEnd = (event: DragEvent, item: TreeItem) => {
    const itemElement = getById(`drag-item-${item.id}`)

    onDragEndItem(event as unknown as React.DragEvent<HTMLDivElement>)
    itemElement?.setAttribute('draggable', 'false')
  }

  const onHandlerDrag = (event: DragEvent, item: TreeItem) => {
    const itemElement = getById(`drag-item-${item.id}`)

    if (itemElement) {
      itemElement.style.left = `${event.clientX}px`
      itemElement.style.top = `${event.clientY}px`
    }
  }

  return {
    hasChild,
    getIndex,
    onDragEndItem,
    onDragStartItem,
    onDrop,
    handleOnOpen,
    isDragging,
    removeItemHovered,
    handleSetItemHovered,
    itemHovered,
    checkAreChildrenVisible,
    items,
    handleRefs,
    onHandlerDragStart,
    onHandlerDragEnd,
    onHandlerDrag,
  }
}

export default useDragging
