import {
  PinnedMessagesDropwdownMenu,
  PinnedMessagesToggleButton,
  PinnedMessagesContainer,
} from './styles'

import useChatHeader from './useChatHeader'
import { Dropdown } from 'react-bootstrap'
import PinnedMessage from 'components/organisms/Chat/PinnedMessage/PinnedMessage'

import { ReactComponent as MessagePinnedBlackIcon } from 'assets/images/pinned-black.svg'
import { DefaultGenerics, MessageResponse } from 'stream-chat'
import { IPage } from 'interfaces/page'
import { type block } from '_entities/block'

interface ExtendedPinnedMessageResponse extends MessageResponse<DefaultGenerics> {
  referencedBlock: block | null
  referencedPage: IPage | null
}

const ChatHeader = () => {
  const { pinnedMessages, handleOnPinnedMessageClick } = useChatHeader()

  if (pinnedMessages?.length === 0) return <></>

  return (
    <PinnedMessagesContainer>
      <Dropdown>
        <PinnedMessagesToggleButton variant='' id='dropdown-basic'>
          <MessagePinnedBlackIcon /> {pinnedMessages?.length}
        </PinnedMessagesToggleButton>

        <PinnedMessagesDropwdownMenu style={{ height: '400px', overflow: 'scroll' }}>
          {pinnedMessages?.map((message: ExtendedPinnedMessageResponse) => (
            <Dropdown.Item key={message.id} onClick={() => handleOnPinnedMessageClick(message)}>
              <PinnedMessage
                messageObject={message}
                referencedPage={message.referencedPage}
                referencedBlock={message.referencedBlock}
              />
            </Dropdown.Item>
          ))}
        </PinnedMessagesDropwdownMenu>
      </Dropdown>
    </PinnedMessagesContainer>
  )
}

export default ChatHeader
