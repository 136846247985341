import styled from 'styled-components'

// Icons
import { ReactComponent as EditAddPlus } from 'assets/images/task-manager/EditAddPlus.svg'
import { changeOpacity, getColor } from 'shared/colors'

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 20px 0 20px 20px;
  backdrop-filter: blur(20px);
  background-color: ${() => changeOpacity(getColor('--sidebars'), 0.5)};

  height: calc(100vh - 40px);
  width: calc(100% - 20px);
  border-radius: 4px 0 0 4px;

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;

  :hover .minimizeButton {
    opacity: 1 !important;

    & * {
      opacity: 1 !important;
    }
  }
`

export const ItemGap = styled.div`
  flex-grow: 1;
`

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const Sidebar = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`

export const SidebarHead = styled.div``

export const SidebarBody = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  /* This somehow fixes the overflow */
  overflow-y: auto;
`

export const PagesBarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 6px;
  padding-bottom: 10px;
`

export const EditAddPlusIcon = styled(EditAddPlus)`
  path {
    stroke: var(--secondary-text);
  }
`
