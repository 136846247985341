import React, { useState } from 'react'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { StyledBootstrapRow, StyledBootstrapColumn, EmptyDiv, InfoSvgStyled } from './styles'
import usePlanOverviewModal from './usePlanOverviewModal'
import { ReactComponent as CheckSvg } from 'assets/images/check.svg'

const MapPlanInfo = () => {
  const { plansArray, tooltipText } = usePlanOverviewModal()
  return (
    <>
      {plansArray.map((option, index) => {
        const [showTooltip, setShowTooltip] = useState<boolean>(false)
        return (
          <StyledBootstrapRow key={index}>
            <StyledBootstrapColumn>
              {option.option}
              <EmptyDiv className='position-relative'>
                <InfoSvgStyled
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                />
                <Tooltip
                  className='mb-0 p-1'
                  message={tooltipText[index]}
                  active={showTooltip}
                  justify={'center'}
                  alignitems={'center'}
                  width={'200px'}
                  padding={'5px'}
                  height={'60px'}
                  left={'150%'}
                  top={'0'}
                  borderradius={'8px'}
                />
              </EmptyDiv>
            </StyledBootstrapColumn>
            {option.data.map((data, idx) => {
              if (data === '+')
                return (
                  <StyledBootstrapColumn>
                    <CheckSvg />
                  </StyledBootstrapColumn>
                )
              return <StyledBootstrapColumn key={idx}>{data}</StyledBootstrapColumn>
            })}
          </StyledBootstrapRow>
        )
      })}
    </>
  )
}

export default MapPlanInfo
