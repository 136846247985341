import React from 'react'
import { SpinnerContainer } from './styles'

interface Props {
  isDone?: boolean
  isFail?: boolean
}
const Spinner = (props: Props) => {
  return (
    <SpinnerContainer
      className={`circle-loader ${props.isDone ? 'load-complete' : ''} ${
        props.isFail ? 'load-complete_fail' : ''
      }`}
    >
      <div
        className={`checkmark draw ${props.isDone ? 'done checkmark_success' : ''} ${
          props.isFail ? 'done checkmark_fail' : ''
        }`}
      ></div>
    </SpinnerContainer>
  )
}

export default Spinner
