import { IBlockTypes, type block, getBlockTag } from '_entities/block'
import { Scale, Size } from 'interfaces/whiteboard'

export const useScale = () => {
  const scalableBlockTypes = [IBlockTypes.FRAME, IBlockTypes.STICKY_NOTE]

  const isBlockScalable = (block: block) => {
    return scalableBlockTypes.includes(getBlockTag(block))
  }

  const scaleToSize = (originalSize: Size, scale: Scale) => {
    return {
      width: originalSize.width * scale.scaleX,
      height: originalSize.height * scale.scaleY,
    }
  }

  const sizeToScale = (size: Size, originalSize: Size) => {
    return {
      scaleX: size.width / originalSize.width,
      scaleY: size.height / originalSize.height,
    }
  }

  return {
    scalableBlockTypes,
    isBlockScalable,
    scaleToSize,
    sizeToScale,
  }
}
