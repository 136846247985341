import { usePage } from '_entities/page'
import { useToast } from 'services/helpers/useToast'

export const useSummarization = () => {
  const toast = useToast()
  const _page = usePage()

  const getFullText = async (pageIds: string[]) => {
    if (pageIds.length === 0) {
      toast.error('There is nothing to summarize')
      return
    }
    const allStrings = Promise.all(
      pageIds.map(async (pageId) => {
        return await _page.getText(pageId.toString())
      }),
    )

    let finalText = ''
    const stringsArray = await allStrings
    stringsArray.forEach((pageText) => {
      finalText = finalText + pageText + ' '
    })

    return finalText
  }

  return {
    getFullText,
  }
}
