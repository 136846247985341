import React, { useState } from 'react'
import CustomIcon from '../CustomIcon/CustomIcon'
import { CustomChip, HoverEffect } from './styles'
import DeleteIcon from 'assets/images/close.svg'

type Props = {
  value?: string
  itemId: string
  margin?: string
  onClick?: (itemId: string) => void
  needClose?: boolean
}

const Chip: React.FC<Props> = ({ value, itemId, margin, onClick, needClose }) => {
  const [hover, setHover] = useState(false)

  return (
    <CustomChip className='d-flex align-items-center' margin={margin}>
      {value}
      <HoverEffect
        hover={hover}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className='d-inline justify-content-center align-items-center'
        paddingBottom={'1px'}
        paddingLeft={'2px'}
        paddingRight={'2px'}
        backgroundOnHover={'#D7D7D7'}
        onClick={() => {
          if (onClick) onClick(itemId)
        }}
      >
        {needClose && <CustomIcon src={DeleteIcon} width='12px' height='12px' />}
      </HoverEffect>
    </CustomChip>
  )
}

export default Chip

Chip.defaultProps = {
  needClose: true,
}
