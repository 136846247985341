import { icons } from 'shared/icons'
import { TagWrapper } from './styles'

interface Props {
  label: string
}

const Tag = (props: Props) => {
  return (
    <TagWrapper>
      <icons.hash />
      {props.label}
    </TagWrapper>
  )
}

export default Tag
