import { IPropertyTypes, ITaskProperty } from 'interfaces/taskManager'

export const getPropertyValue = (prop: ITaskProperty) => {
  if (prop.propertyDefinition?.type === IPropertyTypes.STATUS) {
    const currentValue = prop.status?.find((status) => status.current)
    const statusValues = prop.propertyDefinition.statusValues
    const statusValue = statusValues.find(
      (statusValue) => statusValue.id === currentValue?.statusValueId,
    )
    if (statusValue) return statusValue.value
    else return ''
  } else if (prop.propertyDefinition?.type === IPropertyTypes.PERSON) {
    const currentValue = prop.person?.find((person) => person.current)
    if (currentValue) return `${currentValue.user?.firstName} ${currentValue.user?.lastName}`
    else return ''
  } else if (prop.propertyDefinition?.type === IPropertyTypes.DATE) {
    const currentValue = prop.date?.find((status) => status.current)
    if (currentValue) return new Date(Date.parse(currentValue.value)).toLocaleDateString('en-US')
    else return ''
  } else if (prop.propertyDefinition?.type === IPropertyTypes.TEXT) {
    const currentValue = prop.text?.find((text) => text.current)
    if (currentValue) return currentValue.value
    else return ''
  } else if (prop.propertyDefinition?.type === IPropertyTypes.NUMBER) {
    const currentValue = prop.number?.find((number) => number.current)
    if (currentValue) return currentValue.value.toString()
  } else if (prop.propertyDefinition?.type === IPropertyTypes.LABEL) {
    const currentValue = prop.multiSelect?.find((val) => val.current)
    if (currentValue) return currentValue.selectedValue.value.toString()
  } else if (prop.propertyDefinition?.type === IPropertyTypes.FILES_AND_MEDIA) {
    const currentValue = prop.text?.find((text) => text.current)
    if (currentValue) return currentValue.value
    else return ''
  } else if (prop.propertyDefinition?.type === IPropertyTypes.URL) {
    const currentValue = prop.text?.find((text) => text.current)
    if (currentValue) return currentValue.value
    else return ''
  } else return 'need to define'
  return ''
}
