import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import Relationship from 'editor/EditableBlock/BlockToolbar/Relationships/Relationship'
import { type block } from '_entities/block'
import { useComments } from '_features/comments'
import { RelationshipType } from '_features/relationship'
import { ReactComponent as Mirror } from 'assets/images/block-toolbar/mirror.svg'
import { ReactComponent as CheckBox } from 'assets/images/checktask.svg'
import { ReactComponent as Instance } from 'assets/images/block-toolbar/instance.svg'
import { ReactComponent as CommentIcon } from 'assets/images/block-toolbar/comment.svg'
import { IconButtonSize } from '_features/toolbar'
import ThreeDotsDropdown from '../Dropdowns/ThreeDotsDropdown/ThreeDotsDropdown'
import { useCreateTask } from '_features/task'

interface IProps {
  block: block
  isWhiteboard?: boolean
  closeActionMenu?: () => void
}

const SharedToolbar = (props: IProps) => {
  const { handleOnCommentClick } = useComments()
  const _createTask = useCreateTask()

  return (
    <>
      {/* Comment */}
      <IconButton
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
        onClick={() => {
          handleOnCommentClick(props.block)
        }}
      >
        <CommentIcon />
      </IconButton>

      {/* Create task */}
      <IconButton
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
        onMouseUp={() => {
          _createTask.createReferencedTask(props.block)
        }}
      >
        <CheckBox />
      </IconButton>

      {/* Instance */}
      <Relationship
        block={props.block}
        closeActionMenu={props.closeActionMenu}
        relationshipType={RelationshipType.INSTANCE}
        icon={<Instance />}
      />

      {/* Mirror */}
      <Relationship
        block={props.block}
        closeActionMenu={props.closeActionMenu}
        relationshipType={RelationshipType.MIRROR}
        icon={<Mirror />}
      />

      {/* Dots */}
      <ThreeDotsDropdown block={props.block} isWhiteboard={props.isWhiteboard} />
    </>
  )
}

export default SharedToolbar
