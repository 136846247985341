import styled, { css } from 'styled-components'
import { Nav, Tab } from 'react-bootstrap'

// Use this for docs: https://react-bootstrap.github.io/components/tabs/#custom-tab-layout

export const SidebarTabsContainer = styled.div<{
  fullWidth?: boolean
  fullHeight?: boolean
  grow?: boolean
}>`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;

  ${(props) => props.fullWidth && 'width: 100%;'}
  ${(props) => props.fullHeight && 'height: 100%;'}
  ${(props) => props.grow && 'flex-grow: 1;'}
`

export const SidebarTabsBody = styled(Tab.Content)`
  height: 100%;
  display: flex;

  ul {
    margin-bottom: 0 !important;
  }
`

export const SidebarTabsBodyItem = styled(Tab.Pane)<{
  fullWidth?: boolean
  fullHeight?: boolean
  grow?: boolean
}>`
  ${(props) => props.fullWidth && 'width: 100%;'}
  ${(props) => props.fullHeight && 'height: 100%;'}
  ${(props) => props.grow && 'flex-grow: 1;'}
  ${(props) =>
    props.verticalList &&
    css`
      display: flex;
      flex-direction: column;
    `}
`
