import styled from 'styled-components'

export const SizeToggleButton = styled.button`
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: var(--padding-8) var(--padding-8);
  border-radius: 1000px;
  justify-content: center;
  background-color: var(--overlay-modal);
  color: var(--primary-text);
  outline: none;
  border: none;

  &:hover {
    background-color: var(--hover);
  }
`
export const DeleteButton = styled.button`
  border: none;
  outline: none;
  background: none;
  color: var(--everyday-red);
`
