import styled from 'styled-components'
import { CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import { Form } from 'react-bootstrap'

export const MyProfileTabUserModalStyled = styled.div`
  padding: 8px;
  font-family: 'DM Sans';
  font-style: normal;
  width: 100%;

  & *::placeholder {
    color: #d8d8d8;
  }

  input {
    border-radius: 10px;
  }

  textarea {
    resize: none;
    border-radius: 10px;
  }
`
export const UserNameText = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  padding-bottom: 8px;
  color: var(--primary-text);
`
export const UploadPhotoButton = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: var(--primary-color);
  border-radius: 10px;

  margin-block: 18px 12px;
  margin-right: 0.5rem;
  padding: 0.5rem;
  cursor: pointer;

  transition: all 0.25s ease-in-out;

  :hover {
    background-color: var(--hover);
  }
`
export const RemovePhotoButton = styled.div`
  position: relative;
  font-size: 14px;
  margin-bottom: 20px;
  cursor: pointer;
  font-weight: 600;
  top: 16px;
  padding: 0.5rem 0.5rem;
  border-radius: 10px;
  color: var(--secondary-text);

  :hover {
    color: var(--everyday-red);
    background: var(--hover);
  }
`
export const AvatarContainer = styled.div`
  padding: 8px 0px 8px 0px;
  & img {
    height: 100px;
    width: 100px;
    object-fit: cover;
    object-position: 50% 50%;
  }
`
export const AvatarActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const ModalInfoLabel = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  color: var(--gray2);
  margin-bottom: 12px;
`

export const InfoText = styled.div`
  padding-bottom: 25px;
  word-break: break-all;
  font-size: 16px;
  color: var(--primary-text);
`
export const StyledFormGroup = styled(Form.Group)`
  flex: 1;
`

export const InputFlexRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`

export const FlexRowContainerForInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 80px;
`
export const FlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0px;
`
export const ModalSaveButton = styled.button`
  background: var(--primary-color);
  color: white;
  border-radius: 10px;
  border: none;
  padding: 5px 16px;
  font-weight: 600;
  margin-bottom: 12px;
  transition: all 0.2s ease-in-out;

  :active {
    filter: none;
  }

  :hover {
    background: var(--hover);
    color: var(--primary-text);
  }
`
export const ModalEditButton = styled.button`
  border: none;
  color: var(--white);
  font-weight: 500;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
  padding: 3px 14px 3px 14px;
  background: var(--everyday-blue);
  transition: all 0.2s ease-in-out;

  :active {
    filter: none;
  }

  :hover {
    background-color: var(--hover);
    color: var(--primary-text);
  }
`

export const Styles = (): StylesConfig<Option, false, GroupBase<Option>> | undefined => {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      background: 'none',
      borderColor: '#dedede',
      border: '2px solid #dedede',
      paddingLeft: '0em',
      paddingRight: '1.65rem !important',
      borderRadius: '10px',
      boxShadow: 'none',
      fontSize: '14px',
      maxHeight: '34px !important',
      minHeight: '34px !important',
      height: '34px !important',
      width: '4.5rem',
      overflow: 'hidden',
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '.9em',
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: OptionProps<Option, false, GroupBase<Option>>,
    ) => ({
      ...baseStyles,
      color: state.isSelected ? 'var(--primary-color)' : 'initial',
      backgroundColor: state.isFocused ? 'var(--primary-color2)' : 'none',
      borderRadius: '0px',
      borderBottom: '1pt solid var(--gray3)',
      cursor: 'pointer',
      borderRight: 'none !important',
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      margin: 0,
      padding: 0,
      borderColor: 'transparent',
      border: '1px solid var(--gray-thin)',
      boxShadow: 'none',
      position: 'relative',
      left: 0,
      width: '100%',
      fontSize: '14px',
      maxHeight: '150px !important',
      overflowY: 'hidden',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex:
        '9999 !important' /* Everything like 99 or 999 will not work, index should be as big as possible and it affects only this React Select, nothing else inside this SPA. */,
    }),
    dropdownIndicator: (styles) => ({
      ...styles,
      width: '2rem',
      maxWidth: '2rem',
      position: 'absolute',
      right: '0px',
      top: '0px',
      paddingRight: '0',
      borderRight: 'none !important',
      border: 'none !important',
    }),
  }
}

export const InputControl = styled(Form.Control)`
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  font-size: 14px;

  :focus {
    box-shadow: none;
    outline: none;
    border: 1px solid var(--everyday-blue);
  }
`

export const SelectControl = styled(Form.Select)`
  border-radius: 10px;
  padding: 6px 12px;
  border: 1px solid #e1e1e1;
  color: var(--gray-inactive);

  :focus {
    box-shadow: none;
    border: 1px solid var(--everyday-blue);
  }
`
