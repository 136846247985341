import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { useParams, useNavigate } from 'react-router-dom'
import { AxiosService } from 'services/axiosService/axiosService'
import { ITask } from 'interfaces/taskManager'
import {
  setSelectedTaskId,
  getSelectedOption,
  setSelectedTaskDocument,
} from 'redux/reducers/taskManagerReducer'
import { useState } from 'react'

export const useTaskManagerBoard = () => {
  const dispatch = useAppDispatch()
  const activeKeys = useAppSelector((state) => state.taskManager.activeKeysBoard)
  const selectedOption = useAppSelector(getSelectedOption)
  const subgroup = selectedOption?.subGroupBy

  const selectedShowProperties = selectedOption?.showProperties
  const params = useParams()
  const navigate = useNavigate()
  const spaceId = params.spaceId
  const projectId = params.id
  const AxiosInstance = new AxiosService()

  const onTaskSelect = async (task: ITask) => {
    if (spaceId && projectId) {
      const response = await AxiosInstance.getPage(projectId, task?.id)
      if (response) {
        dispatch(setSelectedTaskId(task.id))
        dispatch(setSelectedTaskDocument(task))
        setTimeout(() => {
          const openedDocument = document.querySelector(
            `[id="${task.id}"] ul p:empty`,
          ) as HTMLElement
          if (openedDocument) {
            openedDocument.focus()
          }
        }, 500)
      }
    }
  }

  return {
    dispatch,
    activeKeys,
    subgroup,
    selectedShowProperties,
    navigate,
    spaceId,
    projectId,
    AxiosInstance,
    onTaskSelect,
  }
}
