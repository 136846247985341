import { Modal } from 'react-bootstrap'
import {
  ModalInnerContainer,
  ModalTitle,
  StyledBootstrapRow,
  StyledBootstrapColumnHead,
  PlanNameStyled,
  CommingSoonLabel,
  CurrentPlanLabel,
  UpgradeButton,
  ContainerStyled,
  InfoSpan,
  EmptyDiv,
} from './styles'
import { ReactComponent as CheckSvg } from 'assets/images/check.svg'
import MapPlanInfo from './MapPlanInfo'
import MapPeriodButtons from './MapPeriodButtons'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { RootState } from 'redux/store'
import { setPlanModalOpened } from 'redux/reducers/spaceReducer'

const PlanOverviewModal = () => {
  const dispatch = useAppDispatch()
  const planModalOpened = useAppSelector((state) => state.space.planModalOpened)
  const billingInterval = useAppSelector((state: RootState) => state.subscription.billingInterval)
  return (
    <Modal
      show={planModalOpened}
      onHide={() => dispatch(setPlanModalOpened(false))}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      fullscreen
      dialogClassName='planOverviewModal'
    >
      <ModalInnerContainer>
        <Modal.Header closeButton className='modalHeader border-0 '>
          <ModalTitle>Ready to plan?</ModalTitle>
        </Modal.Header>
        <MapPeriodButtons selectedPeriod={billingInterval} />
        <Modal.Body className='border-0'>
          <ContainerStyled>
            <StyledBootstrapRow>
              <StyledBootstrapColumnHead></StyledBootstrapColumnHead>
              <StyledBootstrapColumnHead>
                <PlanNameStyled>Free</PlanNameStyled>
                <PlanNameStyled>$0</PlanNameStyled>
                <EmptyDiv>Explore Everyday</EmptyDiv>
                <CurrentPlanLabel className='mt-4'>
                  <CheckSvg />
                  Your current plan
                </CurrentPlanLabel>
              </StyledBootstrapColumnHead>
              <StyledBootstrapColumnHead>
                <PlanNameStyled>Standard</PlanNameStyled>
                <PlanNameStyled>{billingInterval === 'Yearly' ? '$234' : '$19.50'}</PlanNameStyled>
                <EmptyDiv className='text-center'>
                  <EmptyDiv>Up your work</EmptyDiv>
                  <InfoSpan>
                    per member per {billingInterval === 'Yearly' ? 'year' : 'month'}
                  </InfoSpan>
                </EmptyDiv>

                <UpgradeButton>Upgrade to Standard</UpgradeButton>
              </StyledBootstrapColumnHead>
              <StyledBootstrapColumnHead>
                <PlanNameStyled>Pro</PlanNameStyled>
                <CommingSoonLabel>Coming soon</CommingSoonLabel>
              </StyledBootstrapColumnHead>
              <StyledBootstrapColumnHead>
                <PlanNameStyled>Enterprise</PlanNameStyled>
                <CommingSoonLabel>Coming soon</CommingSoonLabel>
              </StyledBootstrapColumnHead>
            </StyledBootstrapRow>
            <MapPlanInfo />
          </ContainerStyled>
        </Modal.Body>
      </ModalInnerContainer>
    </Modal>
  )
}

export default PlanOverviewModal
