import styled from 'styled-components'

export const HoverEffect = styled.div<{
  paddingTop?: string
  paddingBottom?: string
  paddingLeft?: string
  paddingRight?: string
  hover?: boolean
  backgroundOnHover?: string
  colorOnHover?: string
}>`
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: ${(props) => props.paddingBottom};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  border-radius: 5px;
  transition: all 0.15s ease-in-out;
  cursor: pointer;
  margin-left: 3px;

  &:hover {
    background: ${(props) => props.hover && `${props.backgroundOnHover}`};
    color: ${(props) => props.hover && `${props.colorOnHover}`};
  }
`

export const CustomChip = styled.span<{ margin?: string }>`
  margin: ${(props) => props.margin};
  padding: 5px 5px;
  font-size: small;
  background: #e7eeff;
  border-radius: 8px;
  display: inline-flex;
`
