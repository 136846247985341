import { type block } from '_entities/block'

export const getLeftArrowId = (block: block) => {
  return `presentation-left-arrow-button-${block._id}`
}

export const getLeftControlElement = (block: block) => {
  return document.getElementById(getLeftArrowId(block))
}
export const getRightArrowId = (block: block) => {
  return `presentation-right-arrow-button-${block._id}`
}

export const getRightControlElement = (block: block) => {
  return document.getElementById(getRightArrowId(block))
}

export const getDeleteButtonId = (block: block) => {
  return `presentation-delete-button-${block._id}`
}

export const getDeleteControlElement = (block: block) => {
  return document.getElementById(getDeleteButtonId(block))
}
