import { type block } from '_entities/block'
import { DropdownMenuItem } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { useAppDispatch } from 'redux/hooks'
import { setFontSize } from 'redux/reducers/pageReducer'
import { getFontSizeDropdownElement, useFontSize } from '_features/text'
import CustomP from 'components/atoms/CustomP/CustomP'
import { fontSizeArrayForDropdown } from '_entities/text'

interface FontSizeDropdownProps {
  block: block
}

const FontSizeDropdown = (props: FontSizeDropdownProps) => {
  const dispatch = useAppDispatch()
  const _fontSize = useFontSize()

  const fontSizeOptions = (block: block) => {
    return [
      ...fontSizeArrayForDropdown().map((fontSize: number) => {
        return {
          name: (
            <CustomP fontSize='12px' paddingLeft='6px' paddingRight='6px' fontWeight='500'>
              {fontSize}
            </CustomP>
          ),
          label: fontSize,
          onClick: () => {
            setTimeout(() => {
              const inputElement = getFontSizeDropdownElement(block)
              if (inputElement) (inputElement as HTMLInputElement).value = fontSize.toString()
              _fontSize.setFontSizeOnFocusedEditor(block, fontSize)
            }, 100)
          },
          isCheckmark: true,
        }
      }),
    ]
  }

  return (
    <>
      {fontSizeOptions(props.block).map((item, n) => (
        <DropdownMenuItem
          key={n}
          onClick={(e) => {
            e.stopPropagation()
            item.onClick()
            dispatch(setFontSize(item.label))
          }}
        >
          {item.label.toString()}
        </DropdownMenuItem>
      ))}
    </>
  )
}

export default FontSizeDropdown
