import { useEffect, useReducer } from 'react'
import { reducer } from './reducer'
import { getBlockPageId, getIndex, type block, type ITableReducer } from '_entities/block'
import { useJson1 } from 'shared/shareDb'
import { useSubmit } from 'utils/shareDB/useSubmit'

import { ActionTypes, makeData } from './utils'
import { SourceKeys } from 'interfaces/editor'

interface Props {
  tableState?: ITableReducer
  block: block
}

export const useTable = ({ tableState, block }: Props) => {
  const _json1 = useJson1()
  const _submit = useSubmit()
  const [state, dispatch] = useReducer(reducer, tableState ? tableState : makeData(0))

  useEffect(() => {
    if (tableState) {
      dispatch({
        type: ActionTypes.UPDATE_EVERYTHING,
        columns: tableState.columns,
        data: tableState.data,
      })
    }
  }, [tableState?.data, tableState?.columns])

  useEffect(() => {
    dispatch({ type: ActionTypes.ENABLE_RESET })
    if (state.isOpSendingAllowed) {
      const updatedBlock = { ...block, data: { ...block.data, tableData: state } }
      const index = getIndex(block)
      if (index < 0) return
      const op = _json1.replaceBlock(updatedBlock, index, block)
      _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
    }
  }, [state.data, state.columns])

  return {
    state,
    dispatch,
  }
}
