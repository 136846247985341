import React, { useEffect, useState } from 'react'
import ProgressIndicator from 'components/atoms/ProgressIndicator/ProgressIndicator'
import { block, getBlockTag, getIndex } from '_entities/block'
import { useImageBlock } from '../model/useImageBlock'
import 'cropperjs/dist/cropper.css'
import {
  Resizable,
  ImageEditorWrapper,
  ImageBorderWrapper,
  Image,
  EditorUploadDivider,
  ResizeLine,
} from './styles'
import {
  IMAGE_BORDER_WRAPPER_SELECTOR,
  setDefaultImageAlt,
  defaultTextForUploadingFiles,
  RESIZE_LEFT_SELECTOR,
  RESIZE_RIGHT_SELECTOR,
} from '../lib/uploadConstants'
import useImage from '_entities/image/model/useImage'
import EditorUploadInput from '_features/upload/ui/EditorUploadInput'
import {
  EditorImageBlockAttrs,
  getFileInputId,
  observeMutationOnImageBlockElement,
} from '_features/editor'
import { useFilesHandler } from '_widgets/DocumentBlock'
import { IUserAttributes } from 'interfaces/auth/userData'
import { IPermissions } from '_entities/user'
import { ImageCropper, getIsCropMode } from '_features/crop-image'
import { getImageBlockId } from '../lib/getters'

export interface Props {
  block: block
  actionMenuOpen: boolean
  userPresence?: IUserAttributes
  isSelected?: boolean
  setActionMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  userRole?: IPermissions
  openActionMenu: () => void
}

export const EditorImageBlock = (props: Props) => {
  const [isCroppingModeOn, setIsCroppingModeOn] = useState<boolean>(false)
  const _filesHandler = useFilesHandler()
  const {
    nameInLabelIfNoFileSelected,
    imageRef,
    onRightResizeMouseDown,
    onRightResizeTouchStart,
    onLeftResizerTouchStart,
    onLeftResizerMouseDown,
  } = useImageBlock(props.block)

  const _image = useImage()

  useEffect(() => {
    observeMutationOnImageBlockElement(props.block, EditorImageBlockAttrs.CROP, () => {
      setIsCroppingModeOn(getIsCropMode(props.block))
    })
  }, [])

  return (
    <EditorUploadDivider
      id={getImageBlockId(props.block)}
      isSelected={props.actionMenuOpen}
      data-position={getIndex(props.block)}
      data-tag={getBlockTag(props.block)}
      tabIndex={0}
    >
      {!props.block.data.imageUrl &&
        !_filesHandler.isLoading &&
        !props.block.data.isAiGenerating && (
          <EditorUploadInput
            id={getFileInputId(props.block._id)}
            name={getBlockTag(props.block)}
            type='file'
            onChange={() => _filesHandler.handleImageUpload(props.block, getBlockTag(props.block))}
            label={defaultTextForUploadingFiles(nameInLabelIfNoFileSelected)}
          />
        )}

      {!props.block.data.imageUrl && _filesHandler.isLoading && (
        <ProgressIndicator percentage={_filesHandler.uploadProgress} />
      )}

      <ImageEditorWrapper
        alignPosition={props.block.data.imagePosition}
        isSelected={props.isSelected}
        onClick={() => props.setActionMenuOpen(true)}
      >
        <ImageBorderWrapper id={IMAGE_BORDER_WRAPPER_SELECTOR}>
          {!isCroppingModeOn && props.block.data.imageUrl && (
            <Resizable>
              <Image
                key={`${props.block.data.imageUrl}`}
                src={`${props.block.data.imageUrl}?key=${Date.now()}`}
                alt={setDefaultImageAlt(props.block)}
                draggable={false}
                onDoubleClick={() => {
                  _image.onDiagonalIconClick()
                }}
                ref={imageRef}
              />
            </Resizable>
          )}

          <React.Fragment>
            <ResizeLine
              id={RESIZE_LEFT_SELECTOR}
              left='-0.1rem'
              onMouseDown={onLeftResizerMouseDown}
              onTouchStart={onLeftResizerTouchStart}
            />
            <ResizeLine
              id={RESIZE_RIGHT_SELECTOR}
              right='-0.1rem'
              onMouseDown={onRightResizeMouseDown}
              onTouchStart={onRightResizeTouchStart}
            />
          </React.Fragment>
          {isCroppingModeOn && (
            <ImageCropper block={props.block} onCropFinish={() => setIsCroppingModeOn(false)} />
          )}
        </ImageBorderWrapper>
      </ImageEditorWrapper>
    </EditorUploadDivider>
  )
}
