import { ITools } from 'interfaces/whiteboard'
import { useShape } from '_entities/shape'
import { type block } from '_entities/block'
export const useInternalEmbed = () => {
  const _shape = useShape()

  const getEmbedBlockPage = (selectedBlock: block | null) => {
    if (selectedBlock && selectedBlock.data.documentPage) {
      return selectedBlock.data.documentPage
    }
  }

  const EnablingTools = [
    ITools.TEXT,
    ..._shape.ShapeOptions,
    ITools.STICKY,
    ITools.ARROW,
    ITools.PEN,
    ITools.FRAME,
  ]

  return {
    EnablingTools,
    getEmbedBlockPage,
  }
}
