import React from 'react'
import Image from '../Image/Image'
import loadingSpinner from 'assets/images/loadingSpinner.gif'

type Props = {
  className?: string
}
const LoadingAnim: React.FC<Props> = ({ className }) => {
  return (
    <>
      <Image src={loadingSpinner} alt='loading spinner' className={className} />
    </>
  )
}

export default LoadingAnim
