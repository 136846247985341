export const checkIfTheCharacterIsNumber = (character: string) => {
  if (character.includes('.')) {
    const numberCharacter = character.replace('.', '')
    if (/^\d+$/.test(numberCharacter)) {
      return parseInt(numberCharacter, 10)
    }
  }

  return null
}
