import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { IPalette } from 'interfaces/settings'
import { AxiosService } from 'services/axiosService/axiosService'
import { setColorPalettes, setSelectedColorPalette } from 'redux/reducers/globalReducer'
import { useUser } from 'services/user/useUser'
import { useViewer } from '_entities/viewer'

const useColorSettings = () => {
  const [selected, setSelected] = useState('')
  const [expanded, setExpanded] = useState('')
  const user = useAppSelector((state) => state.global.user)
  const [loading, setIsLoading] = useState<string | null>(null)
  const palettes = useAppSelector((state) => state.global.colorPalettes)

  const dispatch = useAppDispatch()
  const _viewer = useViewer()
  const { updateUserInfo } = useUser()
  const AxiosInstance = new AxiosService()

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !user.palette) return

      const userPalette = palettes?.find(
        (palette: IPalette) => parseInt(palette.id) === user.palette?.id,
      )

      if (userPalette) {
        setSelected(userPalette.id)
      } else if (palettes && palettes.length > 0) {
        setSelected(palettes[0].id)
      }
    }

    fetchData()
  }, [])

  const fetchPalettes = async () => {
    const userPalettes = user && (await AxiosInstance.getUserColorPalette(user?.id)).data

    if (userPalettes) {
      dispatch(setColorPalettes([...userPalettes]))

      return userPalettes
    }

    return []
  }

  const handleSelectPalette = async (palette: IPalette) => {
    setSelected(palette.id)
    dispatch(setSelectedColorPalette(palette))
    await updateUserInfo({
      colorPaletteId: parseInt(palette.id),
    })

    await _viewer.getAndSaveViewer()
  }

  const setDefaultPalette = async () => {
    const defaultPalette =
      palettes && palettes.filter((palette: IPalette) => palette.attributes.createdBy === null)
    handleSelectPalette(defaultPalette![0])
    setSelected(defaultPalette![0].id)
    dispatch(setSelectedColorPalette(defaultPalette![0]))
  }

  const handleCreateNewPalette = async () => {
    setIsLoading('New')
    const response =
      palettes &&
      user &&
      (await AxiosInstance.createColorPalette(user.id, 'My New Palette', parseInt(palettes[0].id)))
    fetchPalettes()
    handleSelectPalette(response.data)
    setIsLoading(null)
  }

  const handleExpandOnClick = (palette: IPalette) => {
    if (expanded === palette.id) {
      palettes && setExpanded('')
    } else {
      setExpanded(palette.id)
    }
  }

  const handleResetColorToDefault = async (palette: IPalette) => {
    const defaultPalette =
      palettes && palettes.filter((palette: IPalette) => palette.attributes.createdBy === null)[0]
    const defaultColors = defaultPalette?.attributes.colors

    await Promise.all(
      palette.attributes.colors.map(async (color) => {
        const matchingDefaultColors = defaultColors?.filter(
          (defaultColor) => color.type === defaultColor.type,
        )
        const promises = matchingDefaultColors?.map((defaultColor) => {
          return (
            user &&
            AxiosInstance.updateColorPaletteColor(
              color.id.toString(),
              defaultColor.color,
              defaultColor.opacity,
              user?.id,
            )
          )
        })
        promises && (await Promise.all(promises))
      }),
    )

    fetchPalettes()
  }

  return {
    selected,
    expanded,
    palettes,
    handleSelectPalette,
    handleCreateNewPalette,
    handleExpandOnClick,
    loading,
    fetchPalettes,
    setDefaultPalette,
    handleResetColorToDefault,
  }
}

export default useColorSettings
