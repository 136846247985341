import React from 'react'
import Avatar from 'components/atoms/Avatar/Avatar'
import Image from 'components/atoms/Image/Image'
import CustomP from 'components/atoms/CustomP/CustomP'
import AvatarPlaceholder from 'assets/images/avatar-placeholder.png'
import ArrowDown from 'assets/images/chevron-down.svg'
import { Container, ReferralTrack, HorizontalContainer } from './styles'
import { useToast } from 'services/helpers/useToast'
import { Referral } from './data'

type ReferralsListPropsType = {
  referralsList: Referral[]
}

const ReferralsList = ({ referralsList }: ReferralsListPropsType) => {
  const toast = useToast()

  return (
    <Container>
      {referralsList.length > 0 ? (
        referralsList.map((referral) => {
          return (
            <ReferralTrack key={referral.id}>
              <Avatar src={referral.src || AvatarPlaceholder} width='22px' height='22px' />
              <CustomP marginRight='auto'>{referral.fullName}</CustomP>

              {referral.status === 'pending' ? (
                <HorizontalContainer>
                  <CustomP color='var(--gray2)'>Pending</CustomP>
                  <Image
                    src={referral.src || ArrowDown}
                    width='16px'
                    height='16px'
                    alt={'Arrow down'}
                    onClick={() => toast.info('Referrals coming soon!')}
                  />
                </HorizontalContainer>
              ) : (
                <CustomP color='var(--gray2)'>{referral.date}</CustomP>
              )}
            </ReferralTrack>
          )
        })
      ) : (
        <CustomP color='var(--gray2)'>No referrals yet</CustomP>
      )}
    </Container>
  )
}

export default ReferralsList
