import { ChevronRightIconRotating } from 'components/organisms/AdminPalette/styles'
import { Spinner } from 'react-bootstrap'
import { SidebarListItemInput } from '../SidebarList/styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import React from 'react'
import { PaletteNameWrapper } from './styles'
import { IPalette } from 'interfaces/settings'

interface IProps {
  loading: string | null
  userPalette: IPalette
  expanded: string
  handleExpandOnClick: (palette: any) => void
  isEdit: string | null
  renameInputPosition: number
  setRenameInputPosition: (position: number) => void
  handleRenameColorPalette: (e: React.KeyboardEvent<HTMLInputElement>) => void
}

const PaletteName = ({
  loading,
  userPalette,
  expanded,
  handleExpandOnClick,
  isEdit,
  renameInputPosition,
  setRenameInputPosition,
  handleRenameColorPalette,
}: IProps) => {
  return (
    <PaletteNameWrapper>
      {loading !== userPalette.id ? (
        <ChevronRightIconRotating
          turn={expanded === userPalette.id}
          onClick={(e) => {
            e.stopPropagation()
            handleExpandOnClick(userPalette)
          }}
        />
      ) : (
        <Spinner size='sm' />
      )}

      {isEdit === userPalette.id && renameInputPosition > 0 ? (
        <SidebarListItemInput
          width='100%'
          type='text'
          autoFocus
          className='rename-input'
          defaultValue={userPalette.attributes.name}
          onKeyDown={handleRenameColorPalette}
          onBlur={() => {
            setRenameInputPosition(0)
          }}
        />
      ) : (
        <CustomP color='var(--primary-text)' fontWeight='500'>
          {userPalette.attributes.name.length > 15
            ? `${userPalette.attributes.name.substring(0, 15)}...`
            : userPalette.attributes.name}
        </CustomP>
      )}
    </PaletteNameWrapper>
  )
}

export default PaletteName
