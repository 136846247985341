import React from 'react'
import { Form, Modal } from 'react-bootstrap'
import { Button, ModalInnerContainer, ModalSubtitle, ModalTitle, Span, Input } from './styles'
import Tooltip from 'components/atoms/Tooltip/Tooltip'

type Props = {
  show: boolean
  handleClose: () => void
  saveChanges: () => void
  onShow: () => void
  deleteItem: string
  onChangeText: (e: React.ChangeEvent<any>) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  showTooltip?: boolean
  deleteButtonDisabled?: boolean
}

const DeleteModal: React.FC<Props> = ({
  show,
  onShow,
  handleClose,
  deleteItem,
  onChangeText,
  saveChanges,
  onMouseEnter,
  onMouseLeave,
  showTooltip,
  deleteButtonDisabled,
}) => {
  return (
    <Modal
      show={show}
      onShow={onShow}
      onHide={handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='changeEmailModal'
    >
      <ModalInnerContainer>
        <Modal.Header closeButton className='modalHeader border-0'>
          <ModalTitle>Delete {deleteItem}?</ModalTitle>
        </Modal.Header>
        <ModalSubtitle>
          Are you sure you want to delete {deleteItem}? All projects in this space will be deleted.
          This action can not be reverted.
        </ModalSubtitle>
        <Modal.Body className='border-0'>
          <Form>
            <Form.Group className='mb-3'>
              <Form.Label>
                To confim, type <Span>Delete</Span> into the field below
              </Form.Label>
              <Input type='text' onChange={onChangeText} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className='border-0 d-flex justify-content-end'>
          <Button
            className='btn'
            onClick={saveChanges}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            opacity={deleteButtonDisabled ? 0.5 : 1}
          >
            Delete space
          </Button>
          <Tooltip
            className='mb-0 p-1'
            message={
              'You must type Delete in input field in order for this button to become active'
            }
            active={showTooltip ?? false}
            justify={'center'}
            alignitems={'center'}
            width={'350px'}
            padding={'8px'}
            height={'60px'}
            right={'-100px'}
            top={'206px'}
            borderradius={'8px'}
          />
        </Modal.Footer>
      </ModalInnerContainer>
    </Modal>
  )
}

export default DeleteModal
