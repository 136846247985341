import React, { ChangeEvent, useState } from 'react'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import NewPageTypePicker from './NewPageTypePicker'
import UploadOrLinkSelector from 'components/organisms/UploadOrLinkSelector/UploadOrLinkSelector'
import { useToast } from 'services/helpers/useToast'
import {
  checkIsPdf,
  checkIsNotion,
  checkIsAsana,
  checkIsMiro,
  checkIsFigma,
  checkIsYoutube,
  checkIsVimeo,
  checkIsGoogleDocLink,
  checkIsGoogleSheetsLink,
  checkIsGoogleSlidesLink,
  checkIsEmail,
} from '_entities/embed/ExternalEmbed/model/EmbedRegex'
import { EmbedType, embedTypeDisplayName, getFigmaFileNameFromUrl } from '_entities/embed'
import { useCreatePage } from '_features/page'
import { PageTypesEnum } from 'interfaces/projectFile'
import Link from 'components/molecules/Link/Link'
import useFileUpload from '_entities/resources/model/useFileUpload'
import { envVars } from 'config/env'
import { removeFileExtension } from 'shared/lib'
import { setIsProjectFileUploadLoading } from 'redux/reducers/projectFileReducer'
import { AxiosService } from 'services/axiosService/axiosService'

interface Props {
  pageId?: string
  toggleButton: React.ReactNode
  onSelectDoc: () => void
  onSelectWb: () => void
  parentPageId?: string
  isSubpageMenu?: boolean
  isScrollable?: boolean
}

export const tabs = [
  {
    text: 'Upload',
  },
  {
    text: 'Link',
  },
]

const NewPageDropdown = (props: Props) => {
  const [open, setOpen] = useState<boolean | undefined>(false)
  const [embedType, setEmbedType] = useState<EmbedType | ''>('')
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [link, setLink] = useState('')
  const [linkButtonDisabled, setLinkButtonDisabled] = useState(true)
  const [loading, setLoading] = useState(false)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const space = useAppSelector((state) => state.space.currentSpace)
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const toast = useToast()
  const _fileUpload = useFileUpload()
  const { createEmbedPage } = useCreatePage()
  const dispatch = useAppDispatch()
  const axiosService = new AxiosService()

  const isOnlyLinkTypes = () =>
    embedType === EmbedType.VIMEO ||
    embedType === EmbedType.YOUTUBE ||
    embedType === EmbedType.NOTION ||
    embedType === EmbedType.ASANA ||
    embedType === EmbedType.GOOGLE_DOC ||
    embedType === EmbedType.GOOGLE_SHEETS ||
    embedType === EmbedType.GOOGLE_SLIDES ||
    embedType === EmbedType.GOOGLE_CALENDAR ||
    embedType === EmbedType.MIRO ||
    embedType === EmbedType.FIGMA ||
    embedType === EmbedType.GENERIC_LINK

  const isUploadAndLinkTypes = () => embedType === EmbedType.PDF || embedType === EmbedType.VIDEO

  const onOpenChange = (open: boolean) => {
    if (!open) {
      setSelectedTab(tabs[0])
      setEmbedType('')
      setLink('')
      setLinkButtonDisabled(true)
    }
    setOpen(open)
  }

  const linkConfig = {
    [EmbedType.FILE]: () => void 0,
    [EmbedType.IMAGE]: () => void 0,
    [EmbedType.PDF]: async (pasted: string) => {
      const isPdf = await checkIsPdf(pasted)
      if (pasted && isPdf) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.NOTION]: (pasted: string) => {
      const isNotion = checkIsNotion(pasted)
      if (pasted && isNotion) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.ASANA]: (pasted: string) => {
      const isAsana = checkIsAsana(pasted)
      if (pasted && isAsana) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.VIDEO]: () => void 0,
    [EmbedType.VIMEO]: (pasted: string) => {
      const isVimeo = checkIsVimeo(pasted)
      if (pasted && isVimeo) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.YOUTUBE]: (pasted: string) => {
      const isYoutube = checkIsYoutube(pasted)
      if (pasted && isYoutube) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.GOOGLE_DOC]: (pasted: string) => {
      const isGoogleDoc = checkIsGoogleDocLink(pasted)
      if (pasted && isGoogleDoc) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.GOOGLE_SHEETS]: (pasted: string) => {
      const isGoogleSlides = checkIsGoogleSheetsLink(pasted)
      if (pasted && isGoogleSlides) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.GOOGLE_SLIDES]: (pasted: string) => {
      const isGoogleSheets = checkIsGoogleSlidesLink(pasted)
      if (pasted && isGoogleSheets) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.GOOGLE_CALENDAR]: (pasted: string) => {
      const isGoogleCalendar = checkIsEmail(pasted)
      if (pasted && isGoogleCalendar) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.MIRO]: (pasted: string) => {
      const isMiro = checkIsMiro(pasted)
      if (pasted && isMiro) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.FIGMA]: (pasted: string) => {
      const isFigma = checkIsFigma(pasted)
      if (pasted && isFigma) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
    [EmbedType.OFFICE]: () => void 0,
    [EmbedType.OFFICE_DOCUMENT]: () => void 0,
    [EmbedType.OFFICE_EXCEL]: () => void 0,
    [EmbedType.OFFICE_POWERPOINT]: () => void 0,
    [EmbedType.GENERIC_LINK]: (pasted: string) => {
      if (pasted) {
        setLinkButtonDisabled(false)
      } else {
        setLinkButtonDisabled(true)
      }
    },
  }

  const handleCreateEmbedPage = async (url: string, name?: string) => {
    if (projectFile && pages && space) {
      await createEmbedPage(
        PageTypesEnum.EMBED,
        pages,
        projectFile.id,
        space.id,
        undefined,
        url,
        name || 'Untitled',
      )
    }
  }

  const handleFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true)
    dispatch(setIsProjectFileUploadLoading(true))
    const files = e.target.files
    if (files && files[0].size > 5000000000) {
      toast.error('The maximum size per file is 5GB')
      dispatch(setIsProjectFileUploadLoading(false))
      return
    }
    const file = files?.[0]

    if (!file) return

    let response = await _fileUpload.uploadUrl(file.name)

    const url = response?.url
    const path = response?.path

    if (!response || !file) return

    response = await _fileUpload.uploadFile(url, file)

    if (!response) return

    await _fileUpload.createResource(path)

    const link = `${envVars.api.s3Butcket}${path}`

    const name = removeFileExtension(file.name)

    await handleCreateEmbedPage(link, name)

    setLoading(false)
    onOpenChange(false)
    dispatch(setIsProjectFileUploadLoading(false))
  }

  const handleExternalLink = async (embedType: EmbedType | '', parentId?: string) => {
    dispatch(setIsProjectFileUploadLoading(true))
    if (embedType === EmbedType.GOOGLE_DOC) {
      if (pages && space && projectFile) {
        const file = await axiosService.getCalloutMetadata(link)

        await createEmbedPage(
          PageTypesEnum.EMBED,
          pages,
          projectFile.id,
          space.id,
          parentId,
          link,
          file.title || 'Google doc',
        )
      }
    } else if (embedType === EmbedType.GOOGLE_SLIDES) {
      if (pages && space && projectFile) {
        const file = await axiosService.getCalloutMetadata(link)

        await createEmbedPage(
          PageTypesEnum.EMBED,
          pages,
          projectFile.id,
          space.id,
          parentId,
          link,
          file.title || 'Google slides',
        )
      }
    } else if (embedType === EmbedType.GOOGLE_SHEETS) {
      if (pages && space && projectFile) {
        const file = await axiosService.getCalloutMetadata(link)

        await createEmbedPage(
          PageTypesEnum.EMBED,
          pages,
          projectFile.id,
          space.id,
          parentId,
          link,
          file.title || 'Google sheet',
        )
      }
    } else if (embedType === EmbedType.GOOGLE_CALENDAR) {
      if (pages && space && projectFile) {
        const file = await axiosService.getCalloutMetadata(link)
        const fullLink = `https://calendar.google.com/calendar/embed?src=${link}`
        await createEmbedPage(
          PageTypesEnum.EMBED,
          pages,
          projectFile.id,
          space.id,
          parentId,
          fullLink,
          file?.title || 'Google calendar',
        )
      }
    } else if (embedType === EmbedType.MIRO) {
      const file = await axiosService.getCalloutMetadata(link)

      if (pages && space && projectFile) {
        await createEmbedPage(
          PageTypesEnum.EMBED,
          pages,
          projectFile.id,
          space.id,
          parentId,
          link,
          file.title || 'Miro board',
        )
      }
    } else if (embedType === EmbedType.FIGMA) {
      if (pages && space && projectFile) {
        const file = await axiosService.getCalloutMetadata(link)

        await createEmbedPage(
          PageTypesEnum.EMBED,
          pages,
          projectFile.id,
          space.id,
          parentId,
          link,
          getFigmaFileNameFromUrl(link) || file.title,
        )
      }
    } else if (embedType === EmbedType.YOUTUBE) {
      if (pages && space && projectFile) {
        const file = await axiosService.getCalloutMetadata(link)

        await createEmbedPage(
          PageTypesEnum.EMBED,
          pages,
          projectFile.id,
          space.id,
          parentId,
          link,
          file.title || 'YouTube video',
        )
      }
    } else if (embedType === EmbedType.VIMEO) {
      if (pages && space && projectFile) {
        const file = await axiosService.getCalloutMetadata(link)

        await createEmbedPage(
          PageTypesEnum.EMBED,
          pages,
          projectFile.id,
          space.id,
          parentId,
          link,
          file.title || 'Vimeo video',
        )
      }
    } else if (embedType === EmbedType.GENERIC_LINK) {
      const file = await axiosService.getCalloutMetadata(link)
      const icon = file.image
      if (pages && space && projectFile) {
        await createEmbedPage(
          PageTypesEnum.EMBED,
          pages,
          projectFile.id,
          space.id,
          parentId,
          link,
          file.title || 'Generic link',
          icon,
        )
      }
    }
    dispatch(setIsProjectFileUploadLoading(false))
  }

  const onPaste = async (e: React.ClipboardEvent<HTMLDivElement>) => {
    const pasted = e.clipboardData.getData('Text')
    linkConfig[embedType as EmbedType](pasted)
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value)
    linkConfig[embedType as EmbedType](e.target.value)
  }

  const handleSetEmbedType = (type: EmbedType) => {
    setEmbedType(type)
  }

  return (
    <RadixDropdown
      open={open}
      onOpenChange={onOpenChange}
      trigger={props.toggleButton}
      height={props.isScrollable ? '300px' : 'auto'}
      overflow={props.isScrollable ? 'scroll' : 'hidden'}
    >
      {!embedType && (
        <NewPageTypePicker
          onSelectDoc={props.onSelectDoc}
          onSelectWb={props.onSelectWb}
          setEmbedType={handleSetEmbedType}
          handleFileUpload={handleFileUpload}
        />
      )}
      {isOnlyLinkTypes() && (
        <Link
          placeholder={
            embedType === EmbedType.GOOGLE_CALENDAR ? 'Paste email' : 'Paste link to embed here'
          }
          loading={loading}
          link={link}
          onInputChange={onInputChange}
          addLink={() => handleExternalLink(embedType, props.parentPageId)}
          disabled={link === '' || linkButtonDisabled}
          onPaste={onPaste}
          title={`Embed ${embedTypeDisplayName[embedType as EmbedType]}`}
          buttonText={`Add ${embedTypeDisplayName[embedType as EmbedType]}`}
        />
      )}
      {isUploadAndLinkTypes() && (
        <UploadOrLinkSelector
          tabs={tabs}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          handleFileUpload={handleFileUpload}
          loading={loading}
          link={link}
          uploadButtonText={'Upload file'}
          embedButtonDisabled={link === '' || linkButtonDisabled}
          onPaste={onPaste}
          onInputChange={onInputChange}
          embedFileTitle={`Embed ${embedTypeDisplayName[embedType as EmbedType]}`}
          embedFileButtonText={`Add ${embedTypeDisplayName[embedType as EmbedType]}`}
        />
      )}
    </RadixDropdown>
  )
}

export default NewPageDropdown
