import styled, { css } from 'styled-components'

import { ReactComponent as ChevronRightIcon } from 'assets/images/chevron-right.svg'

type EmbedContainerPropsType = {
  top?: number
  display?: string
}

export type ChevronRightIconRotatingProps = {
  turn?: boolean
  hide?: boolean
}

export const ChevronRightIconRotating = styled(ChevronRightIcon)<ChevronRightIconRotatingProps>`
  transition: 0.1s;
  transform: ${(props) => (props.turn ? 'rotate(90deg)' : 'rotate(0deg)')};
  opacity: ${(props) => (props.hide ? 0 : 1)};
`

export const SidebarListItemIcon = styled.div<{
  hoverGray?: boolean
}>`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 20px;
  min-height: 20px;
  border-radius: 7px;
  transition: 0.2s;

  svg path {
    stroke: var(--primary-text) !important;
  }

  ${(props) =>
    props.hoverGray &&
    css`
      &:hover {
        background: var(--hover);
      }
    `}
`

const SidebarListItemTextHoverActiveCSS = css`
  font-weight: 600;
  color: white;
`
export const SidebarListItemText = styled.div`
  width: 0;
  flex-grow: 1;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--secondary-text);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const SidebarListItemInput = styled.input<{ width?: string; active?: boolean }>`
  min-width: 0;
  width: ${(props) => props.width || '0'};

  border: none;
  outline: none;
  font-style: italic;

  flex-grow: 1;

  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.active ? 'var(--white)' : 'var(--secondary-text)')};
  background: transparent;
`

export const SidebarListItemContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
`

const SidebarListItemHoverActiveCSS = css`
  background: var(--primary-color);
`
export const SidebarListItem = styled.div<{
  active?: boolean
  isDropTarget?: boolean
  isDragging?: boolean
  depth: number
}>`
  width: ${(props) => props.isDragging && 'fit-content'};
  background: transparent;
  padding: 8px 4px 8px 0;
  cursor: pointer;
  color: var(--secondary-text);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 2px;
  padding-left: ${({ depth }) => `calc(12px * ${depth + 1})`};
  transition: background-color 0.2s, box-shadow 0.2s;

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        background: var(--hover);
      }
    `}

  ${(props) =>
    props.active &&
    css`
      i {
        color: var(--white);
      }

      svg path {
        stroke: var(--white);
      }

      ${SidebarListItemHoverActiveCSS}
      ${SidebarListItemText} {
        ${SidebarListItemTextHoverActiveCSS}
      }
    `}
    ${(props) => props.isDropTarget && 'outline: 2px solid var(--primary-color);'}
`

export const SidebarListDivider = styled.div<{ margin?: string }>`
  margin: ${(props) => props.margin || '8px'};
  width: auto;
  height: 1px;
  background: var(--secondary-text);
  opacity: 0.2;
`

export const SidebarListGrow = styled.div`
  flex-grow: 1;
`

export const Divider = styled.div`
  display: flex;
`

export const EmbedContainer = styled.div<EmbedContainerPropsType>`
  top: ${(props) => props.top || ''};
  display: ${(props) => props.display || ''};
`

export const TasksButtonSidebarWrapper = styled.div<{ selected?: boolean }>`
  display: flex;
  cursor: pointer;
  padding: 6px 12px;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props) => (props.selected ? 'var(--white)' : 'var(--secondary-text)')};
  background: ${(props) => (props.selected ? 'var(--primary-color)' : 'transparent')};
  align-items: center;

  svg path {
    stroke: ${(props) => (props.selected ? 'var(--white)' : 'var(--secondary-text)')};
  }
`
