// ** Type Imports
import { propertyApi } from 'shared/api/propertyApi'
import {
  AssignValueToPropertyRequestType,
  CreatePropertyRequestType,
  UnassignValueToPropertyRequestType,
} from '../model'
import { ITaskProperty } from 'interfaces/taskManager'

export const property = propertyApi.injectEndpoints({
  endpoints: (build) => ({
    assignValueToProperty: build.mutation<ITaskProperty, AssignValueToPropertyRequestType>({
      query: ({
        propertyId,
        statusValueId,
        positionIndex,
        multiSelectValueId,
        userId,
        number,
        text,
        date,
      }) => ({
        url: `${propertyId}/assign`,
        method: 'POST',
        body: {
          statusValueId,
          positionIndex,
          multiSelectValueId,
          userId,
          number,
          text,
          date,
        },
      }),
    }),

    createProperty: build.mutation<ITaskProperty, CreatePropertyRequestType>({
      query: ({ taskId, propertyId }) => ({
        url: '/properties',
        method: 'POST',
        body: {
          taskId,
          propertyId,
        },
      }),
    }),

    unassignValueFromProperty: build.mutation<ITaskProperty, UnassignValueToPropertyRequestType>({
      query: ({ propertyId, valueId }) => ({
        url: `${propertyId}/unassign`,
        method: 'POST',
        body: {
          valueId,
        },
      }),
    }),
  }),
})
