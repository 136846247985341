import Konva from 'konva'
import {
  useStage,
  useTransformer,
  StageChangeEvent,
  ZOOM_DURATION,
  getFrameFit,
} from '_features/canvas'
import { useGrid, getWhiteboardContainer, useKonvaNode } from '_entities/whiteboard'
import { useState } from 'react'
import { KonvaEventObject, Node } from 'konva/lib/Node'
import useLocalStorage, { LocalStorageKeys } from 'shared/lib/useLocalStorage'
import { useEvent } from '_features/canvas/event'
import { IFrameScalePosition } from 'interfaces/whiteboard'
import { block, getBlockPageId } from '_entities/block'
import { Stage } from 'konva/lib/Stage'
import { useAppDispatch } from 'redux/hooks'
import { setZoom } from 'redux/reducers/whiteboardReducer'
import { INodeZoomCalcConfig, IZoomToFitArgs } from './types'

export const useZoom = () => {
  const [zoomPercentage, setZoomPercentage] = useState<number>(100)
  const _stage = useStage()
  const _transformer = useTransformer()
  const _grid = useGrid()
  const _localStorage = useLocalStorage()
  const _event = useEvent()
  const _konvaNode = useKonvaNode()
  const dispatch = useAppDispatch()

  const percentageToScale = (pageId: string, percent: number, animate?: boolean) => {
    if (percent < 10 || percent > 200) return
    const scaleX = 0.000001 * Math.pow(percent, 3) + 0.1
    const stage = _stage.getStage(pageId)
    const whiteboardContainer = getWhiteboardContainer(pageId)
    if (stage && whiteboardContainer?.offsetWidth && whiteboardContainer.offsetHeight) {
      const middleX = whiteboardContainer.offsetWidth / 2
      const middleY = whiteboardContainer.offsetHeight / 2
      const mousePointTo = _stage.unScalePosition(pageId, { x: middleX, y: middleY })
      if (mousePointTo) {
        if (!animate) {
          stage.scale({ x: scaleX, y: scaleX })
          const newPos = {
            x: middleX - mousePointTo.x * scaleX,
            y: middleY - mousePointTo.y * scaleX,
          }
          stage.position(newPos)
          _grid.drawLines(pageId)
        } else {
          const duration = 1
          stage.to({
            x: middleX - mousePointTo.x * scaleX,
            y: middleY - mousePointTo.y * scaleX,
            scaleX: scaleX,
            scaleY: scaleX,
            duration,
            easing: Konva.Easings.EaseInOut,
          })
          setTimeout(() => {
            _grid.drawLines(pageId)
          }, duration * 1000)
        }
      }
    }

    dispatch(setZoom(percent))
    setZoomPercentage(percent)
  }

  const scaleToPercentage = (scale: number) => {
    const percentage = Math.pow(scale - 0.1, 1 / 3) * 100

    if (Math.round(percentage) > 200 || Math.round(percentage) < 10) return

    dispatch(setZoom(Math.round(percentage)))
    setZoomPercentage(Math.round(percentage))

    return Math.round(percentage)
  }

  const zoomHandler = (e: KonvaEventObject<WheelEvent>) => {
    const pageId = _event.getPageIdFromEvent(e)
    const maxZoomIn = 10
    const maxZoomOut = 0.1
    const stage = e.target.getStage()
    const pointerPosition = stage?.getPointerPosition()
    const deltaY = e.evt.deltaY

    const deltaDivider = 200

    const deltaDescaler = deltaY / deltaDivider
    if (stage && pointerPosition) {
      const oldScale = stage.scaleX()
      const mousePointTo = _stage.unScalePosition(pageId, pointerPosition)
      const scroll = () => {
        if (oldScale - deltaDescaler < maxZoomIn && oldScale - deltaDescaler > maxZoomOut)
          return oldScale - deltaDescaler
        else return oldScale
      }

      if (mousePointTo && mousePointTo.x && mousePointTo.y) {
        const percentage = scaleToPercentage(scroll())

        if (percentage && percentage <= 200 && percentage >= 10) {
          stage.scale({ x: scroll(), y: scroll() })
        }

        let newPos = {
          x: pointerPosition.x - mousePointTo.x * scroll(),
          y: pointerPosition.y - mousePointTo.y * scroll(),
        }

        if (!percentage || percentage >= 200 || percentage <= 10) {
          newPos = {
            x: stage.x(),
            y: stage.y(),
          }
        }

        stage.position(newPos)
        _grid.drawLines(pageId)
      }
    }
  }

  const zoomToFit = (args: IZoomToFitArgs) => {
    const { pageId, betweenSidebars, shouldAnimate } = args
    _transformer.setAllNodesToTransformer(pageId)
    const transformer = _transformer.getTransformer(pageId)
    if (!transformer) return
    focusNode({ node: transformer, betweenSidebars, shouldAnimate })
    transformer.opacity(0)
    setTimeout(() => {
      _transformer.removeNodesFromTransformer(pageId)
      transformer.opacity(1)
    }, ZOOM_DURATION)
  }

  const getStageScaleX = (pageId: string): number | null => {
    const stages = _stage.getStagesFromLocalStorage()
    if (!stages) return null
    const stage = stages[pageId]
    return stage?.scaleX
  }

  const getStageScaleY = (pageId: string): number | null => {
    const stages = _stage.getStagesFromLocalStorage()
    if (!stages) return null
    const stage = stages[pageId]
    return stage?.scaleY
  }

  const saveStageScaleX = (pageId: string, scaleX: number) => {
    let stages = _stage.getStagesFromLocalStorage()
    if (!stages) {
      stages = _stage.constructNewLocalStorageStages(pageId)
    }
    stages[pageId] = {
      ...stages[pageId],
      scaleX,
    }
    _localStorage.setItem(LocalStorageKeys.STAGES, stages)
  }

  const saveStageScaleY = (pageId: string, scaleY: number) => {
    let stages = _stage.getStagesFromLocalStorage()
    if (!stages) {
      stages = _stage.constructNewLocalStorageStages(pageId)
    }
    stages[pageId] = {
      ...stages[pageId],
      scaleY,
    }
    _localStorage.setItem(LocalStorageKeys.STAGES, stages)
  }

  const handleStageScaleListener = (pageId: string, e: KonvaEventObject<any>) => {
    if (e.type === 'scaleXChange') saveStageScaleX(pageId, (e as StageChangeEvent).newVal)
    if (e.type === 'scaleYChange') saveStageScaleY(pageId, (e as StageChangeEvent).newVal)
  }

  const setInitialStageScale = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (!stage) return
    stage.scaleX(getStageScaleX(pageId) || 1)
    stage.scaleY(getStageScaleY(pageId) || 1)
  }

  const getNodeZoomScale = (pageId: string, node: Node) => {
    const screenSize = _stage.getStageSize(pageId)
    const unScaledSize = _stage.unScaleSize(pageId, node.getClientRect())
    if (!screenSize || !unScaledSize) return 1
    const scaleX = screenSize.width / unScaledSize.width
    const scaleY = screenSize.height / unScaledSize.height
    const frameFit = getFrameFit(pageId)
    if (frameFit === 'cover') return Math.max(scaleX, scaleY)
    return Math.min(scaleX, scaleY)
  }

  const getCenteringOffset = (pageId: string, node: Node) => {
    const screenSize = _stage.getStageSize(pageId)
    const unScaledSize = _stage.unScaleSize(pageId, node.getClientRect())
    const scale = getNodeZoomScale(pageId, node)
    if (!screenSize || !unScaledSize || !scale) return
    let offsetX = 0
    let offsetY = 0
    // If the frame is smaller in width than the screen, we want to center it horizontally
    if (unScaledSize.width * scale < screenSize.width) {
      offsetX = screenSize.width - unScaledSize.width * scale
    }
    // If the frame is smaller in height than the screen, we want to center it vertically
    if (unScaledSize.height * scale < screenSize.height) {
      offsetY = screenSize.height - unScaledSize.height * scale
    }

    return {
      x: offsetX,
      y: offsetY,
    }
  }

  const getZoomCoords = (pageId: string, node: Node) => {
    const scale = getNodeZoomScale(pageId, node)
    const frameRect = node.getClientRect()
    const unScaledPosition = _stage.unScalePosition(pageId, { x: frameRect.x, y: frameRect.y })
    if (!unScaledPosition) return
    return {
      x: -unScaledPosition.x * scale,
      y: -unScaledPosition.y * scale,
    }
  }

  const getNodeFullScreenZoom = (config: INodeZoomCalcConfig): IFrameScalePosition | undefined => {
    const stageFromNode = _konvaNode.getStageFromNode(config.node)
    const stage = config.stageRef?.current || stageFromNode
    let scale = 1
    let x = 0
    let y = 0
    if (!stage) return
    scale = getNodeZoomScale(_stage.getPageIdFromStage(stage), config.node)
    const offset = getCenteringOffset(_stage.getPageIdFromStage(stage), config.node)
    const zoomCoords = getZoomCoords(_stage.getPageIdFromStage(stage), config.node)
    if (!offset || !zoomCoords) return
    x = zoomCoords?.x + offset.x / 2
    y = zoomCoords?.y + offset.y / 2
    return {
      position: { x, y },
      scale,
    }
  }

  const handleFocus = (block: block, stageRef?: React.MutableRefObject<Stage | undefined>) => {
    // We need stage ref because we need to scope the focus on a specific stage when multiple
    // stages exists, which is the case in tasks and chat that renders multiple whiteboards
    const pageId = getBlockPageId(block)
    // If ref hasnt loaded yet, the object will exist but the current won't
    // This means the we sent the ref to be checked, because we want the focus
    // to be scoped to the ref, but the ref hasn't loaded yet, so we do nothing in that case

    if (stageRef && !stageRef.current) return
    const stage = stageRef?.current || _stage.getStage(pageId)
    const node = _stage.getNodeFromBlock(pageId, block._id, stageRef)
    if (stage && node) {
      focusNode({ node, stageRef, shouldAnimate: true })
    }
  }

  const focusNode = (config: INodeZoomCalcConfig) => {
    const params = getNodeFullScreenZoom(config)
    if (!params) return
    const stageFromNode = _konvaNode.getStageFromNode(config.node)
    if (!config.stageRef && !stageFromNode) return
    const stage = config.stageRef?.current || stageFromNode
    const { position, scale } = params
    if (stage) {
      stage.to({
        x: position.x,
        y: position.y,
        scaleX: scale,
        scaleY: scale,
        duration: config.shouldAnimate
          ? config.animationLength
            ? config.animationLength
            : ZOOM_DURATION
          : 0,
        easing: Konva.Easings.EaseInOut,
      })
    }
  }

  return {
    zoomPercentage,
    setZoomPercentage,
    percentageToScale,
    scaleToPercentage,
    zoomHandler,
    zoomToFit,
    getStageScaleX,
    getStageScaleY,
    saveStageScaleX,
    saveStageScaleY,
    handleStageScaleListener,
    setInitialStageScale,
    getNodeFullScreenZoom,
    handleFocus,
    focusNode,
  }
}
