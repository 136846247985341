import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { IGeneralDropdownOption } from 'interfaces/invite'
import { IPage, IPages } from 'interfaces/page'
import { IProjectFileMember } from 'interfaces/projectFile'
import { RootState } from 'redux/store'
import { ProjectFileResponse } from '_entities/project'

interface ProjectFileState {
  selectedProjectFile?: ProjectFileResponse
  pages?: IPages[]
  freshlyCreatedPageId?: IPages['id']
  freshlyCreatedBlockPageId?: IPages['id']
  members: IProjectFileMember[]
  userRoles: IGeneralDropdownOption[]
  alignment: string
  layout: string
  pendingMembers: IProjectFileMember[]
  myProjectFileRole: string
  allMembers?: IProjectFileMember[]
  isPresentationMode: boolean
  sidebarTabKey: string
  rightSidebarTabKey?: string
  isProjectFileUploadLoading: boolean
  isProjectFileEmpty: boolean
  leftMinimize?: boolean
  rightMinimize?: boolean
}

const initialState = {
  userRoles: [],
  members: [],
  alignment: '',
  layout: 'vertical',
  expireLinkValue: { name: 'Never', value: 'Never' },
  inviteLink: '',
  pendingMembers: [],
  myProjectFileRole: '',
  isPresentationMode: false,
  sidebarTabKey: 'Files',
  isProjectFileUploadLoading: false,
  isProjectFileEmpty: false,
  leftMinimize: true,
  rightMinimize: false,
} as ProjectFileState

const projectFileSlice = createSlice({
  name: 'projectFile',
  initialState,
  reducers: {
    setSelectedProjectFile: (state, action) => {
      state.selectedProjectFile = action.payload
    },
    setPages: (state, action) => {
      if (action.payload) {
        const droppablePages: IPages[] = action.payload.map((page: IPage) => {
          return {
            id: page.id,
            text: page.title,
            parent: page.parent === null ? 0 : page.parent?.id,
            droppable: true,
            positionIndex: page.positionIndex,
            type: page.type,
            icon: page.icon || null,
            embedUrl: page.embedUrl || null,
            managers: page.managers,
          }
        })
        droppablePages.sort((a: IPages, b: IPages) => a.positionIndex - b.positionIndex)
        state.pages = droppablePages
      } else state.pages = action.payload
    },
    setPageIconById(state, action: PayloadAction<{ pageId: string; icon: string }>) {
      const { pageId, icon } = action.payload

      const page = state.pages?.find((page) => page.id === pageId)

      if (page) page.icon = icon
    },
    setPagesWithUpdatedTitle: (state, action) => {
      state.pages = action.payload
    },
    setFreshlyCreatedPageId: (state, action: PayloadAction<IPages['id'] | undefined>) => {
      state.freshlyCreatedPageId = action.payload
    },
    setFreshlyCreatedBlockPageId: (state, action: PayloadAction<IPages['id'] | undefined>) => {
      state.freshlyCreatedBlockPageId = action.payload
    },
    setMembers: (state, action) => {
      state.members = action.payload
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload
    },
    setAlignment: (state, action) => {
      state.alignment = action.payload
    },
    setLayout: (state, action) => {
      state.layout = action.payload
    },
    setPendingProjectMembers: (state, action) => {
      state.pendingMembers = action.payload
    },
    setMyProjectFileRole: (state, action) => {
      state.myProjectFileRole = action.payload
    },
    setProjectAllMembers: (state, action) => {
      state.allMembers = action.payload
    },
    setIsPresentationMode: (state, action) => {
      state.isPresentationMode = action.payload
    },
    setRightSidebarTabKey: (state, action) => {
      state.rightSidebarTabKey = action.payload
    },
    setIsProjectFileUploadLoading: (state, action) => {
      state.isProjectFileUploadLoading = action.payload
    },
    setIsProjectFileEmpty: (state, action) => {
      state.isProjectFileEmpty = action.payload
    },
    setLeftMinimize: (state, action) => {
      state.leftMinimize = action.payload
    },
    setRightMinimize: (state, action) => {
      state.rightMinimize = action.payload
    },
  },
})

export const {
  setSelectedProjectFile,
  setPages,
  setPageIconById,
  setPagesWithUpdatedTitle,
  setFreshlyCreatedPageId,
  setFreshlyCreatedBlockPageId,
  setMembers,
  setUserRoles,
  setAlignment,
  setLayout,
  setPendingProjectMembers,
  setMyProjectFileRole,
  setProjectAllMembers,
  setIsPresentationMode,
  setRightSidebarTabKey,
  setIsProjectFileUploadLoading,
  setIsProjectFileEmpty,
  setLeftMinimize,
  setRightMinimize,
} = projectFileSlice.actions

export default projectFileSlice.reducer

export const getPageById = (id: string) => (state: RootState) =>
  state.projectFile.pages?.find((page) => page.id === id)
