import { IBlockTypes, type block } from '_entities/block'
import { useText } from './useText'
import { DeltaOperation } from 'quill'
import { getEditor } from 'shared/lib'

export const useTextBlock = () => {
  const _text = useText()

  const doesBlockContainText = (block: block) => {
    const conditions = [
      IBlockTypes.TITLE,
      IBlockTypes.XLARGE,
      IBlockTypes.LARGE,
      IBlockTypes.MEDIUM,
      IBlockTypes.SMALL,
      IBlockTypes.SUBHEADER,
      IBlockTypes.TEXT,
      IBlockTypes.IMAGE_TEXT,
      IBlockTypes.LIST,
      IBlockTypes.CHECKLIST,
      IBlockTypes.NUMBERED_LIST,
      IBlockTypes.STICKY_NOTE,
      IBlockTypes.SHAPE,
    ]

    return conditions.some((el) => block.data.tag === el)
  }

  const isTextBlock = (block: block) => {
    const conditions = [
      IBlockTypes.TITLE,
      IBlockTypes.XLARGE,
      IBlockTypes.LARGE,
      IBlockTypes.MEDIUM,
      IBlockTypes.SMALL,
      IBlockTypes.SUBHEADER,
      IBlockTypes.TEXT,
      IBlockTypes.LIST,
      IBlockTypes.CHECKLIST,
      IBlockTypes.NUMBERED_LIST,
    ]

    return conditions.some((el) => block.data.tag === el)
  }

  const getBlocksContainingTextFromBlocks = (blocks: block[]) => {
    return blocks.filter((block) => doesBlockContainText(block))
  }
  const getTextBlocksFromBlocks = (blocks: block[]) => {
    return blocks.filter((block) => isTextBlock(block))
  }

  const getTextFromBlocks = (blocks: block[]) => {
    let string = ''
    blocks.forEach((block) => {
      const html = convertToHtml(block)
      const stringFromHtml = getStringFromHtml(html)
      string = string + stringFromHtml + ' '
    })
    return string
  }

  const getIsChecked = (block: block) => {
    return block.data.isChecked
  }

  const getRichTextOps = (block: block): DeltaOperation[] | undefined => {
    if (typeof block.data.richText !== 'string') return block.data.richText?.ops
  }

  const getIsCheckedKey = (block: block) => {
    return 'isChecked' in block.data
  }

  const convertToHtml = (block: block) => {
    const richText = block?.data?.richText
    if (!richText) return ''
    if (typeof richText !== 'string') {
      const ops = richText?.ops
      if (!ops) return ''
      const html = _text.convertDeltaToHtml(ops)
      return (
        html
          // eslint-disable-next-line
          .replaceAll(/&#x27;/g, "'")
          .replaceAll(/&quot;/g, '"')
          .replaceAll(/&#x2F;/g, '/')
      )
    } else return richText
  }

  const focusTextBlock = (block: block) => {
    const editor = getEditor(block._id)
    if (editor) {
      editor.focus()
    }
  }

  const getStringFromHtml = (html: string) => {
    const span = document.createElement('span')
    span.innerHTML = html
    return span.textContent || span.innerText
  }
  return {
    isTextBlock,
    doesBlockContainText,
    getBlocksContainingTextFromBlocks,
    getTextBlocksFromBlocks,
    getTextFromBlocks,
    getIsChecked,
    getRichTextOps,
    getIsCheckedKey,
    convertToHtml,
    focusTextBlock,
    getStringFromHtml,
  }
}
