import Avatar from 'components/atoms/Avatar/Avatar'
import { envVars } from 'config/env'
import CustomP from 'components/atoms/CustomP/CustomP'
import { UnassignedIcon } from './styles'

interface IProps {
  label: string
  photo: string
}

const PersonOption = (props: IProps) => {
  const { label, photo } = props
  return (
    <CustomP cursorPointer display={'flex'} gap={'8px'} alignItems={'center'} fontSize={'14px'}>
      {label === 'Unassigned' ? (
        <UnassignedIcon />
      ) : (
        <>
          {' '}
          {photo ? (
            <Avatar src={`${envVars.api.s3Butcket}${photo}`} />
          ) : (
            <Avatar userName={label} />
          )}
        </>
      )}

      {label.length > 13 ? `${label.slice(0, 13)}...` : label}
    </CustomP>
  )
}

export default PersonOption
