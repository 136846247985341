import React from 'react'
import 'reactjs-popup/dist/index.css'
import { StyledSidebarReference } from './styles'

type Props = {
  isDraggedOver?: boolean
  levelOfNesting?: number
  isSelected?: boolean
  onClick?: () => void
  children: React.ReactNode
  justifyContent?: string
  color?: string
  onMouseEnter?: () => void
  onMouseLeave?: () => void
  borderRadius?: string
  hoveredBorderRadius?: string
  padding?: string
  innerRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined
}

const SidebarReference = (props: Props) => (
  <StyledSidebarReference
    {...props}
    ref={props.innerRef}
    isSelected={props.isSelected}
    justifyContent={props.justifyContent}
    color={props.color}
    borderRadius={props.borderRadius}
    hoveredBorderRadius={props.hoveredBorderRadius}
    onClick={() => {
      if (props.onClick) props.onClick()
    }}
    onMouseEnter={props.onMouseEnter}
    onMouseLeave={props.onMouseLeave}
    padding={props.padding}
  >
    {props.children}
  </StyledSidebarReference>
)

export default SidebarReference
