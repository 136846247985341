import { ActionWrapper, PropertyTitle, PropertyWrapper, StyledWrapper } from '../styles'
import { icons } from 'shared/icons'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import React from 'react'
import { type block } from '_entities/block'
import useBlockTasks from './useBlockTasks'
import { Task } from '_entities/task'

interface IProps {
  block: block
}

const Tasks = (props: IProps) => {
  const { block } = props
  const _tasks = useBlockTasks({ block })

  return (
    <StyledWrapper>
      <PropertyWrapper width='100%'>
        <PropertyTitle>Tasks</PropertyTitle>
        {_tasks.blockTasks?.length === 0 ? (
          <ActionWrapper onClick={() => _tasks.handleCreateTask(block)}>
            <icons.checkBoxBold />
            Create task{' '}
          </ActionWrapper>
        ) : (
          <Wrapper>
            {_tasks.blockTasks?.map((task: any) => {
              if (!task) return <></>

              return (
                <Task
                  key={task.id}
                  type='horizontal'
                  task={task}
                  onTaskSelect={() => _tasks.onTaskSelect(task)}
                  index={task.positionIndex}
                  selected={task.id === _tasks.selectedTask?.id}
                  isSlider={true}
                  isSimpleView
                />
              )
            })}
          </Wrapper>
        )}
      </PropertyWrapper>
    </StyledWrapper>
  )
}

export default Tasks
