import { useEffect, useRef } from 'react'
import { Location, useLocation, useParams, useSearchParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { PageTypesEnum } from 'interfaces/projectFile'
import { useGetPage } from '_entities/page'
import { setPageMembers, setSelectedPage, setShareModalOpened } from 'redux/reducers/pageReducer'
import { setRightSidebarTabKey } from 'redux/reducers/projectFileReducer'
import useFileUpload from '_entities/resources/model/useFileUpload'
import { useSpace } from '_entities/space'

export const usePage = () => {
  const params = useParams()
  const spaceId = params.spaceId
  const projectFileId = params.id
  const location = useLocation()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const { getAndDispatchPage, getAndDispatchPageRoles } = useGetPage()
  const _space = useSpace()
  const { downloadFile } = useFileUpload()
  const pageId = searchParams.get('pageId')
  const space = useAppSelector((state) => state.space.currentSpace)
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const modalShow = useAppSelector((state) => state.page.shareModalOpened)
  const ref = useRef<HTMLDivElement>(null)
  const profileOpen = useAppSelector((state) => state.profile.profileOpen)
  const chatOpen = useAppSelector((state) => state.chat.open)
  const taskManagerOpen = useAppSelector((state) => state.taskManager.isSliderOpen)
  const selectedBlock = useAppSelector((state) => state.page.selectedBlock)

  const pageBackgroundConfig: { [key in PageTypesEnum]: string } = {
    document: 'var(--document-background)',
    slides: 'white',
    whiteboard: 'var(--whiteboard-background)',
    'task-manager': 'var(--tasks-background)',
    embed: 'white',
  }

  const setModalShow = (value: boolean) => {
    dispatch(setShareModalOpened(value))
  }

  const prevLocation = useRef<Location | null>(null)

  useEffect(() => {
    if (spaceId && !space) _space.getSpace(spaceId)
  }, [])

  useEffect(() => {
    if (prevLocation.current?.search !== location.search) {
      dispatch(setSelectedPage(undefined))
      dispatch(setPageMembers(undefined))
      dispatch(setRightSidebarTabKey(undefined))
      if (pageId && projectFileId) {
        getAndDispatchPage(projectFileId, pageId)
      }
      getAndDispatchPageRoles()
    }
    prevLocation.current = location
  }, [location])

  const handlePageModalOpen = () => {
    dispatch(setShareModalOpened(true))
  }

  const handleDownloadFile = () => {
    if (selectedPage && selectedPage.embedUrl) {
      downloadFile(selectedPage.embedUrl, selectedPage.title)
    }
  }

  return {
    spaceId,
    ref,
    modalShow,
    setModalShow,
    selectedPage,
    pageBackgroundConfig,
    handlePageModalOpen,
    profileOpen,
    chatOpen,
    taskManagerOpen,
    handleDownloadFile,
    selectedBlock,
  }
}
