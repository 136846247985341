import { ctaEndpoints } from '../api'
import { useAppSelector } from 'redux/hooks'

export const useDeleteCtaButton = () => {
  // ** Redux state
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  // ** Mutation methods
  const [handleDeleteCtaButton] = ctaEndpoints.endpoints.deleteCtaButton.useMutation()

  const deleteCtaButton = async (id: number) => {
    const projectFileId = projectFile?.id

    if (!projectFileId) return

    return await handleDeleteCtaButton({
      projectFileId,
      id,
    }).unwrap()
  }

  return {
    deleteCtaButton,
  }
}
