import Quill from 'quill'
import { type block, getPreviousBlock, isSomeOfLists } from '_entities/block'
import useNumberedList from 'editor/EditableBlock/EditorTextBlock/helpers/useNumberedList'
import { useUpdateBlock } from '_features/block'
import { useCallback } from 'react'
import { checkIsEditorEmpty, getEditor } from 'shared/lib'

export const useEditorEdit = () => {
  const _numberedList = useNumberedList()
  const _updateBlock = useUpdateBlock()
  const deleteText = useCallback((block: block, length?: number) => {
    const quillElement = document.querySelector(`#quill-editor-${block._id}`)
    if (quillElement) {
      const editor: Quill = Quill.find(quillElement)
      editor.deleteText(0, length || editor.getLength(), 'user')
    }
  }, [])

  const handleEmptyList = useCallback((block: block) => {
    const isEmpty = checkIsEditorEmpty(block)
    if (isEmpty && isSomeOfLists(block)) {
      _updateBlock.changeToTextBlock(block)
      _numberedList.splitNumberedList(block)
    }
  }, [])

  const concatContentsWithBlockBefore = useCallback((block: block, currentBlock: block) => {
    const editor = getEditor(block._id)
    const previousBlock = getPreviousBlock(currentBlock)
    const previousEditor = previousBlock && getEditor(previousBlock._id)
    if (previousEditor && editor) {
      const contents = editor.getContents()
      const contentsBefore = previousEditor?.getContents()
      if (contentsBefore && contentsBefore.ops) {
        const lastOpIndex = contentsBefore.ops.length - 1
        if (lastOpIndex >= 0) {
          const lastInsert = contentsBefore.ops[lastOpIndex].insert
          const removedLineBreakString = lastInsert.slice(0, -1)
          contentsBefore.ops[lastOpIndex].insert = removedLineBreakString + ' '
          const updatedContents = contentsBefore.concat(contents)
          const oldLength = contentsBefore.length()
          previousEditor.setContents(updatedContents, 'user')
          previousEditor.setSelection(oldLength, 0, 'user')
        }
      }
    }
  }, [])

  const checkCaretAtBeginningAndList = useCallback((block: block) => {
    const editor = getEditor(block._id)
    const selection = editor?.getSelection()
    if (selection && selection.index === 0 && isSomeOfLists(block)) {
      _updateBlock.changeToTextBlock(block)
    }
  }, [])

  return {
    deleteText,
    handleEmptyList,
    concatContentsWithBlockBefore,
    checkCaretAtBeginningAndList,
  }
}
