import React, { useEffect, useState } from 'react'
import FullScreen from 'components/templates/FullScreen/FullScreen'
import Page from 'components/templates/Page/Page'
import { LeftSidebar, useWebsockets } from '_widgets'
import { useProjectFile } from './useProjectFile'
import FullScreenLoader from 'components/templates/FullScreenLoader/FullScreenLoader'
import ProjectFileRightSidebar from 'components/organisms/ProjectFileRightSideBar/ProjectFileRightSideBar'
import {
  LeftSidebarContainer,
  MainContainer,
  MainContentContainer,
  RightSidebarContainer,
} from './styles'
import { useSidebarSizes } from './useSidebarSizes'
import { Position } from 'interfaces/projectFile'
import SidebarCollapseButton from 'components/molecules/SidebarCollapseButton/SideBarCollapseButton'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { DndProvider } from 'react-dnd'
import { getBackendOptions, MultiBackend } from '@minoru/react-dnd-treeview'
import { setRightSidebarOpen } from 'redux/reducers/pageReducer'
import { IPermissions } from '_entities/user'
import {
  checkPageRole,
  handleDisconnect as handleShareDbDisconnect,
  setSocket as setShareDbSocket,
} from 'shared/shareDb'
import { useBackground } from '_entities/page'
import {
  handleDisconnect as handleWebsocketDisconnect,
  setSocket as setWebsocket,
} from '_features/tasks'

const index: React.FC = () => {
  useWebsockets()
  const [pageRole, setPageRole] = useState<IPermissions>()
  const { myRole, currentProjectFile, isPresentationMode } = useProjectFile()

  const { leftMinimize, rightMinimize, setLeftMinimize, setRightMinimize } = useSidebarSizes()

  const chatIsOpen = useAppSelector((state) => state.chat.open)
  const taskSliderIsOpen = useAppSelector((state) => state.taskManager.isSliderOpen)
  const uploadLoading = useAppSelector((state) => state.projectFile.isProjectFileUploadLoading)
  const page = useAppSelector((state) => state.page.selectedPage)
  const currentUser = useAppSelector((state) => state.global.user)
  const dispatch = useAppDispatch()
  const _background = useBackground({ page })

  useEffect(() => {
    if (window.innerWidth < 1240) {
      if (chatIsOpen || isPresentationMode || taskSliderIsOpen) {
        dispatch(setLeftMinimize(true))
      }
    } else {
      if (chatIsOpen || isPresentationMode || taskSliderIsOpen) {
        dispatch(setLeftMinimize(true))
      } else {
        dispatch(setLeftMinimize(false))
      }
    }
  }, [chatIsOpen, taskSliderIsOpen])

  useEffect(() => {
    if (isPresentationMode) {
      dispatch(setLeftMinimize(true))
      dispatch(setRightMinimize(true))
      dispatch(setRightSidebarOpen(true))
    } else {
      dispatch(setLeftMinimize(false))
      dispatch(setRightMinimize(false))
      dispatch(setRightSidebarOpen(false))
    }
  }, [isPresentationMode])

  useEffect(() => {
    if (!page) return
    checkPageRole(page.id, setPageRole)
  }, [page])

  useEffect(() => {
    if (window.innerWidth < 1240) {
      dispatch(setLeftMinimize(true))
      dispatch(setRightMinimize(true))
      dispatch(setRightSidebarOpen(true))
    }

    setShareDbSocket()
    setWebsocket()

    return () => {
      handleShareDbDisconnect()
      handleWebsocketDisconnect()
    }
  }, [])

  return (
    <FullScreen>
      {!currentProjectFile && <FullScreenLoader />}
      {currentProjectFile && (
        <DndProvider backend={MultiBackend} options={getBackendOptions()}>
          <MainContainer>
            {_background.getBackgroundNode()}
            {currentUser && currentUser.email && (
              <LeftSidebarContainer leftMinimize={leftMinimize}>
                <LeftSidebar myRole={myRole ?? ''} uploadLoading={uploadLoading} />
                <SidebarCollapseButton
                  position={Position.LEFT}
                  className='minimizeButton'
                  onClick={() => dispatch(setLeftMinimize(!leftMinimize))}
                  isMinimized={!!leftMinimize}
                />
              </LeftSidebarContainer>
            )}

            <MainContentContainer
              {...{
                leftMinimize,
                rightMinimize,
                chatIsOpen,
                taskSliderIsOpen,
              }}
            >
              {_background.getOverlayNode()}
              <Page />
            </MainContentContainer>
            {page &&
              pageRole &&
              (pageRole === IPermissions.OWNER || pageRole === IPermissions.CAN_EDIT) && (
                <RightSidebarContainer id='right-sidebar' rightMinimize={rightMinimize}>
                  <ProjectFileRightSidebar selectedPage={page} />
                  <SidebarCollapseButton
                    position={Position.RIGHT}
                    className='minimizeButton'
                    onClick={() => dispatch(setRightMinimize(!rightMinimize))}
                    isMinimized={!!rightMinimize}
                  />
                </RightSidebarContainer>
              )}
          </MainContainer>
        </DndProvider>
      )}
    </FullScreen>
  )
}

export default index
