import styled from 'styled-components'
import { Col } from 'react-bootstrap'

type SpaceButtonsStyleType = {
  textColour?: string
  backgroundColour?: string
  hoverTextColour?: string
  buttonWidth?: string
  border?: string
  hoverOpacity?: string
  hoverBackgroundColour?: string
}

export const TitleAndUpgradeContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

export const UpgradeButton = styled.button`
  border-radius: 10px;
  border: transparent;
  margin: 20px 12px 24px;
  padding: 4px 16px;
  color: var(--everyday-blue);
  background: white;
  font-style: normal;
  font-size: 14px;
  letter-spacing: 0.02em;
  font-weight: 500;
  transition: all 0.3s ease-in-out;

  &:hover {
    background: var(--light-hover-gray);
  }
`

/* Center */

export const StyledCenter = styled(Col)`
  border-top: 1px solid #cccccc;
  overflow-x: hidden;
  height: 100%;
`

export const StyledSpaceTitleContainer = styled.div`
  padding: 0px 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -0.75rem;
`

export const StyledSpaceTitle = styled.h3`
  font-weight: bold;
  font-size: 21px;
`

export const StyledSpaceTopButtonContainer = styled.div`
  display: flex;
  gap: 12px;
  font-weight: 600 !important;
  font-size: 16px;
`
export const NoAccessText = styled.div`
  display: flex;
  margin-top: 20px;
  color: #fa5a32;
  font-weight: 500;
  font-size: 19px;
  text-align: center;
  justify-content: center;
`

export const StyledSpaceTopButton = styled.button<SpaceButtonsStyleType>`
  border: ${(props) => props.border || 'none'};
  padding: 8px 16px;
  border-radius: 10px;
  font-weight: 600;
  font-size: 14px;
  background: ${(props) =>
    props.backgroundColour ? props.backgroundColour : 'var(--gray5) ! important'};
  color: ${(props) => (props.textColour ? props.textColour : 'var(--everyday-blue) !important')};
  margin-top: -8px;
  width: ${(props) => props.buttonWidth || ''};

  &:hover {
    opacity: ${(props) => props.hoverOpacity || '1'};

    background: ${(props) => props.hoverBackgroundColour || ''};

    color: ${(props) =>
      props.hoverTextColour ? props.hoverTextColour : 'var(--black) !important'};
  }
`
