import styled from 'styled-components'

export const Circle = styled.div`
  border-radius: 50%;
  background: var(--white);
  height: 10px;
  width: 10px;
  margin: 2px;
  transition: all 0.2s ease-in-out;
`

export const SwitchContainer = styled.div<{ checked: boolean; transform?: string }>`
  background: ${(props) => (props.checked ? 'var(--everyday-blue)' : '#f0f0f0')};
  height: 14px;
  width: 24px;
  border-radius: 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: ${(props) => (props.transform ? props.transform : 'none')};

  ${Circle} {
    margin-left: ${(props) => (props.checked ? '12px' : '2px')};
  }
`
