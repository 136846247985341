import React from 'react'
import { OptionWrapper } from '_entities/profile/ui/Defaults/styles'
import { useFileSearch } from '_widgets/LeftSidebar/model/useFileSearch'
import SingleSelect from 'components/organisms/SingleSelect/SingleSelect'
import { Modal } from 'react-bootstrap'
import { ModalTitle } from 'components/molecules/ChangePasswordModal/styles'
import { Option } from 'interfaces/selectOptions'

interface Props {
  show: boolean
  handleCloseModal: () => void
}

export const FileSearch = (props: Props) => {
  const _fileSearch = useFileSearch({
    handleCloseModal: props.handleCloseModal,
  })

  return (
    <Modal show={props.show} onHide={props.handleCloseModal}>
      <Modal.Header closeButton className='modalHeader border-0'>
        <ModalTitle>Search project file</ModalTitle>
      </Modal.Header>
      <Modal.Body className='border-0'>
        <SingleSelect
          options={_fileSearch.pages as Option[]}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => _fileSearch.handleOnClick(e)}
          menuIsOpen
          currentValue={null}
          formatOptionLabel={(data) => {
            return (
              <OptionWrapper>
                {data.icon}
                {data.label}
              </OptionWrapper>
            )
          }}
          isSearchable
          placeholder={'Search project files'}
        />
      </Modal.Body>
    </Modal>
  )
}
