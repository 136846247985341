import { useState } from 'react'
import { SingleValue } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import { type block, getBlockPageId, getIndex, IBlockTypes } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'
import { getPage } from '_entities/page'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useAppSelector } from 'redux/hooks'
import { docSubscribe, getDoc } from 'shared/shareDb'

export const useSelectPageToEmbed = () => {
  const [selectedPage, setSelectedPage] = useState<SingleValue<Option>>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const _submit = useSubmit()
  const _json1 = useJson1()

  const handleSelectPage = async (newValue: SingleValue<Option>, block: block) => {
    setIsLoading(true)
    const index = getIndex(block)
    if (index < 0) return
    const page = newValue && project?.id && (await getPage(project.id, newValue?.value))
    if (page) {
      const subscribedDoc = await docSubscribe(page.id)
      if (!subscribedDoc || subscribedDoc instanceof Error) return
      const updatedBlock = {
        ...block,
        data: {
          ...block.data,
          tag: page.type as IBlockTypes,
          documentPage: page,
        },
        meta: {
          ...block.meta,
          name: page.title,
        },
      }
      const replaceOp = _json1.replaceBlock(updatedBlock, index, block)
      _submit.submit(getBlockPageId(block), replaceOp, SourceKeys.UPDATE_BLOCK)
    }

    setIsLoading(false)
  }

  return {
    selectedPage,
    setSelectedPage,
    handleSelectPage,
    isLoading,
  }
}
