import React from 'react'
import { StyledToggle, StyledTrashIcon } from './styles'
import Dots from 'components/atoms/Dots/Dots'
import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { icons } from 'shared/icons'
import { getColor } from 'shared/colors'

interface Props {
  data: {
    id?: string | number | null | undefined
    label: React.ReactNode
    value: string
  }
  _tags: any
}

const TagOptionDropdown = (props: Props) => {
  return (
    <RadixDropdown
      trigger={
        <StyledToggle onClick={(e) => e.stopPropagation()}>
          <Dots />
        </StyledToggle>
      }
    >
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation()
          props._tags.handleOnTagRenameStart(props.data.id as string)
        }}
      >
        <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
          {' '}
          <icons.edit /> Rename
        </Wrapper>
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation()
          props._tags.deleteTag(props.data.id as string)
        }}
      >
        <Wrapper
          color={getColor('--everyday-red')}
          display={'flex'}
          alignItems={'center'}
          gap={'4px'}
        >
          {' '}
          <StyledTrashIcon />
          Delete
        </Wrapper>
      </DropdownMenuItem>
    </RadixDropdown>
  )
}

export default TagOptionDropdown
