import { Col } from 'react-bootstrap'
import styled from 'styled-components'

import { ReactComponent as DotsIconStyled } from 'assets/images/DotsThreeOutline.svg'

export const StyledSpaceMembers = styled.div``

export const StyledCol = styled(Col)`
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: 2px;

  &.allowFullText {
    position: relative;
  }

  &::-webkit-scrollbar {
    background: none;
    height: 2px;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cccccc;
  }

  & .full-text-on-hover {
    position: fixed;
    margin-top: 50px;
    margin-left: 0px;
    background-color: var(--secondary-text);
    color: var(--white);
    padding: 0.25rem 0.5rem;
    border-radius: 5px;
    opacity: 0.9;
    width: max-content;
    z-index: 999 !important;
    font-size: 13px;
    font-weight: 500;
  }
`

export const StyledAvatar = styled.div<{ src: any }>`
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  min-width: 32px;
  width: 32px;
  height: 32px;
  flex-basis: 32px;
  border-radius: 100%;
  background-color: grey;
  background-image: url(${(props) => props.src});
  background-size: cover;
  margin-right: 4px;
`

export const Dots = styled(DotsIconStyled)``
