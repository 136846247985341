import React from 'react'
import { Col, Nav, Row, Tab } from 'react-bootstrap'
import { SidebarItems, SidebarLinks } from 'services/user/UserSettings/constants'
import { ActiveElementToggle, ModalContentContainer, NavItem } from './styles'

type Props = {
  userImage: string | undefined
  setUserImage: React.Dispatch<React.SetStateAction<string | undefined>>
  onHide?: () => void
}

const ModalSidebarAndContent: React.FC<Props> = ({ userImage, setUserImage, onHide }) => {
  return (
    <Tab.Container defaultActiveKey='first'>
      <Row className='h-100'>
        <Col sm={3} lg={3} md={3} className='vartical-border'>
          <ActiveElementToggle>
            <Nav variant='pills' className='flex-column'>
              {SidebarLinks.map((item, index) => {
                return (
                  <Nav.Link eventKey={item.eventKey} key={index}>
                    <NavItem>{item.name}</NavItem>
                  </Nav.Link>
                )
              })}
            </Nav>
          </ActiveElementToggle>
        </Col>
        <Col className='overflow-auto h-100'>
          <ModalContentContainer>
            <Tab.Content>
              {SidebarItems(userImage, setUserImage, onHide).map((item, index) => {
                return (
                  <Tab.Pane eventKey={item.eventKey} key={index}>
                    {item.item}
                  </Tab.Pane>
                )
              })}
            </Tab.Content>
          </ModalContentContainer>
        </Col>
      </Row>
    </Tab.Container>
  )
}

export default ModalSidebarAndContent
