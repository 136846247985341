import { useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import { useUser } from 'services/user/useUser'
import { useSpace } from '_entities/space'

export const useSpaceInitializer = () => {
  const _user = useUser()
  const _space = useSpace()
  const params = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const checkSpaceOwnership = async () => {
    const { allSpaces } = await _user.getSpaces()

    if (allSpaces) {
      const thisSpaceInMySpaces = allSpaces.find((space) => space.id === params.id)
      if (params && params.id && !thisSpaceInMySpaces) {
        navigate('/f')
      }
      if (params.id) {
        _space.getSpace(params.id)
        _space.getProjects(params.id)
        _space.getMembers(params.id)
      }
    }
  }

  useEffect(() => {
    checkSpaceOwnership()
  }, [location])
}
