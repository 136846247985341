import React from 'react'
import { type block, IBlockTypes } from '../../block'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { StyledImage } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/Source/styles'

interface IProps {
  block: block
}

const SourceImage = (props: IProps) => {
  return (
    <>
      {props.block?.data.tag && props.block.data.tag === IBlockTypes.IMAGE && (
        <Wrapper margin={'0 0 12px 12px'}>
          <StyledImage src={props.block.data.imageUrl} />
        </Wrapper>
      )}
    </>
  )
}

export default SourceImage
