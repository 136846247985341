import axios from 'axios'

import { AxiosService } from 'services/axiosService/axiosService'

import { envVars } from 'config/env'
import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useAppSelector } from 'redux/hooks'

export const useChangeUserProfileModal = (
  setUserImage: React.Dispatch<React.SetStateAction<string | undefined>>,
  handleClose: () => void,
) => {
  const axiosClass = new AxiosService()
  const user = useAppSelector((state) => state.global.user)
  const [imageScale, setImageScale] = useState(1)

  const [profileImage, setProfileImage] = useState<File>()
  // eslint-disable-next-line
  const editor: any = useRef()

  const onDrop = useCallback((files: File[]) => {
    const reader = new FileReader()
    files.forEach((file) => {
      reader.onload = () => {
        setProfileImage(file)
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])
  const getProfileImage = async () => {
    if (!user) return
    await axiosClass
      .getUserProfileImage(user.id)
      .then((res) => res && res.data !== undefined && setUserImage(res.data))
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const data = await setCroppedImage()

    const config = {
      method: 'post',
      url: `${envVars.api.https}api/users/${user && user.id ? user.id : null}/photo`,
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    }

    axios(config).then(() =>
      getProfileImage().then(() => {
        handleClose()
        setProfileImage(undefined)
        history.go()
      }),
    )
  }

  const setCroppedImage = async (): Promise<FormData | undefined> => {
    if (editor) {
      const dataUrl = editor.current.getImage().toDataURL()
      const result = await fetch(dataUrl)
      const blob = await result.blob()

      const file = new File([blob], 'ProfilePhoto.png', {
        type: 'image/png',
        lastModified: new Date().getTime(),
      })
      if (file) {
        setProfileImage(file)
      }
      const data = new FormData()
      data.append('profile-file', file as Blob, 'profileImage.png')

      return data
    }
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  return {
    imageScale,
    setImageScale,
    onSubmit,
    getRootProps,
    getInputProps,
    setProfileImage,
    profileImage,
    editor,
  }
}
