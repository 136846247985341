import { useJson1 } from 'shared/shareDb/useJson1'
import { type block, getIndex, IBlockTypes } from '_entities/block'
import { useAppSelector } from 'redux/hooks'

export const useBlockOps = () => {
  const currentUser = useAppSelector((state) => state.global.user)
  const _json1 = useJson1()

  const getReplaceTypeOp = (block: block, type: IBlockTypes) => {
    return _json1.getReplaceBlockDataKeyOp(getIndex(block), ['tag'], type, block.data.tag)
  }

  const getCreatedAtOp = () => {
    return _json1.getReplaceBlockMetaKeyOp(0, ['createdAt'], new Date().toLocaleDateString('en-US'))
  }

  const getCreatedByOp = () => {
    if (!currentUser?.uuid) return
    return _json1.getReplaceBlockMetaKeyOp(0, ['createdBy'], currentUser.uuid)
  }

  const getListNumberOp = (block: block, numberInTheList: number | string | null) => {
    if ('numberInList' in block.data) {
      return _json1.getReplaceBlockDataKeyOp(
        getIndex(block),
        ['numberInList'],
        numberInTheList,
        block.data.numberInList,
      )
    } else return _json1.getInsertKeyInDataKeyOp(getIndex(block), ['numberInList'], numberInTheList)
  }

  const getIndentOp = (block: block, indent: number | null) => {
    if ('indent' in block.data) {
      return _json1.getReplaceBlockDataKeyOp(getIndex(block), ['indent'], indent, block.data.indent)
    } else {
      return _json1.getInsertKeyInDataKeyOp(getIndex(block), ['indent'], indent)
    }
  }

  return {
    getReplaceTypeOp,
    getCreatedAtOp,
    getCreatedByOp,
    getListNumberOp,
    getIndentOp,
  }
}
