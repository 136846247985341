import * as json1 from 'ot-json1'
import * as richText from 'rich-text'
import { DeltaOperation } from 'quill'

export interface RichTextOp {
  insert?: string
  retain?: number
  delete?: number
  attributes?: Attributes
}

export interface Attributes {
  bold?: boolean
  italic?: boolean
  underline?: boolean
  strikethrough?: boolean
  color?: string
  backgroundColor?: string
  width?: string
  height?: string
}

export const useJson1Text = () => {
  json1.type.registerSubtype(richText)
  const richTextSubtype = 'rich-text'
  const getBasePath = (index: number) => {
    return ['blocks', index, 'data', 'domTree']
  }

  const getRichTextTargetKey = (index: number) => {
    return ['blocks', index, 'data', 'richText']
  }

  const getEditTextOperation = (index: number, ops: DeltaOperation[]) => {
    return json1.editOp(getRichTextTargetKey(index), richTextSubtype, ops)
  }

  const getInsertTextOp = (string: string, attributes?: Attributes) => {
    return attributes ? { insert: string, attributes } : { insert: string }
  }

  const getRemoveTextOp = (numOfDeletedChars: number) => {
    return { delete: numOfDeletedChars }
  }

  const getRetainTextOp = (numOfCharacters: number, attributes?: Attributes) => {
    return attributes
      ? {
          retain: numOfCharacters,
          attributes,
        }
      : { retain: numOfCharacters }
  }

  const insertAtPath = (index: number, path: json1.Path, value: any) => {
    const finalPath = getBasePath(index).concat(path)
    return json1.insertOp(finalPath, value)
  }

  const deleteAtPath = (index: number, path: json1.Path, value: any) => {
    const finalPath = getBasePath(index).concat(path)
    return json1.removeOp(finalPath, value)
  }

  const replaceAtPath = (index: number, path: json1.Path, oldValue: any, newValue: any) => {
    const finalPath = getBasePath(index).concat(path)
    return json1.replaceOp(finalPath, oldValue, newValue)
  }

  return {
    getEditTextOperation,
    getInsertTextOp,
    getRemoveTextOp,
    getRetainTextOp,
    insertAtPath,
    deleteAtPath,
    replaceAtPath,
  }
}
