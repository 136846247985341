import React, { ChangeEvent } from 'react'
import styled from 'styled-components'

interface Props {
  id: string
  type: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name?: string
  label: string | React.ReactNode
}

const StyledLabel = styled.label`
  display: block;
  padding: 0.5rem 0;
  color: #888888;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
`

const EditorUploadInput = (props: Props) => {
  return (
    <>
      <input id={props.id} onChange={props.onChange} name={props.name} type={props.type} hidden />
      <StyledLabel htmlFor={props.id}>{props.label || 'Upload'}</StyledLabel>
    </>
  )
}

export default EditorUploadInput
