import CustomP from 'components/atoms/CustomP/CustomP'
import IconWithDropdown from '../IconWithDropdown/IconWithDropdown'
import { ReactComponent as Info } from 'assets/images/info.svg'
import { useAppSelector } from 'redux/hooks'
import { ISharePageWith } from 'interfaces/invite'
import { useParams } from 'react-router-dom'
import { useUpdatePage } from '_features/page'
import Wrapper from '../Wrapper/Wrapper'
import { icons } from 'shared/icons'
import { getColor } from 'shared/colors'

const SharingAccess = () => {
  const params = useParams()
  const { updatePage } = useUpdatePage()
  const projectId = params.id
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const currentProjectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const onChange = (value: ISharePageWith) => {
    projectId &&
      selectedPage &&
      updatePage(selectedPage.id, parseInt(projectId), undefined, value.access)
  }

  const SharedWith = [
    { name: `Everyone from ${currentProjectFile?.title}`, value: 1, access: 'project-members' },
    { name: 'Only members on this page', value: 2, access: 'private' },
  ]

  const defaultSelect = currentProjectFile?.private
    ? 'Only members on this page'
    : `Everyone from ${currentProjectFile?.title}`

  return (
    <Wrapper display='flex' alignItems='center' margin='10px 0'>
      <CustomP className='mb-0 pb-1' fontSize='13px' fontWeight='700' color='var(--secondary-text)'>
        Shared with
      </CustomP>
      <IconWithDropdown
        padding={'0 0 0 12px'}
        iconLeft={
          defaultSelect === `Everyone from ${currentProjectFile?.title}` ? (
            <icons.people color={getColor('--primary-color')} />
          ) : (
            <icons.lockSmall color={getColor('--gray2')} />
          )
        }
        iconRight={<Info color={getColor('--gray2')} />}
        options={SharedWith}
        defaultSelect={defaultSelect}
        onChange={onChange}
        marginIconRight={'-4px 0 0 0'}
      />
    </Wrapper>
  )
}

export default SharingAccess
