import styled from 'styled-components'
import { ReactComponent as ResizeLineStyled } from 'assets/images/resizeLine.svg'

type ScreenWidthPropsType = {
  isSelected?: boolean
  width?: number
  height?: number | string
  isDraggingOver?: string | null
}

type ResizeLinePropsType = {
  left?: string
  right?: string
  bottom?: string
}

export const Container = styled.div<ScreenWidthPropsType>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  top: 0;
  padding: 0.5rem;
  border-radius: 8px;
  border: 2px solid var(--hover);
  width: ${({ width }) => (width && width !== Infinity ? `${width}px` : '100%')};
  height: ${({ height }) =>
    height && height !== Infinity
      ? `${typeof height === 'number' ? `${height}px` : height}`
      : '500px'};
  min-width: 350px;
  min-height: 350px;

  & iframe {
    border-right: 2px solid var(--hover);
    box-shadow: 0 0 0 0.15rem rgba(0, 0, 0, 0.05);
  }

  background-color: ${(props) =>
    props.isSelected ? 'var(--selection-gray)' : 'var(--document-background)'};

  :focus {
    background-color: var(--selection-gray);
  }

  :hover {
    & .page-preview-options {
      opacity: 1;
      height: 40px;
      padding: 8px 14px;
    }
  }
`

export const ResizeLine = styled(ResizeLineStyled)<ResizeLinePropsType>`
  position: absolute;
  left: ${({ left, bottom }) => (bottom ? '50%' : left ? left : '')};
  right: ${({ right }) => right || ''};
  bottom: ${({ bottom }) => bottom || ''};
  top: ${({ bottom }) => (bottom ? '' : '50%')};
  transform: translateY(-50%) scale(0.6);
  transform: ${({ bottom }) => (bottom ? 'rotate(-90deg)' : '')};
  cursor: ${({ bottom }) => (bottom ? 'ns-resize' : 'ew-resize')};
  filter: drop-shadow(0rem 0rem 2rem rgba(0, 0, 0, 0.15));
  display: block;

  line {
    stroke: var(--secondary-text);
  }
`
