import styled from 'styled-components'
import CustomP from 'components/atoms/CustomP/CustomP'
export const StyledItemNewPageHeader = styled(CustomP)<{ fontSize?: string }>`
  font-size: ${(props) => props.fontSize ?? '12px'};
  font-weight: 500;
  letter-spacing: -0.006em;
  color: var(--primary-text);
`

export const StyledOption = styled.div`
  margin: 0px 0;
`

export const StyledIcon = styled.div`
  transform: scale(0.8);

  path {
    stroke: var(--primary-text);
  }
`
