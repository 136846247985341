import React from 'react'
import {
  ActionWrapper,
  PropertyTitle,
  PropertyWrapper,
  StyledWrapper,
} from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/styles'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import MultiSelect from 'components/organisms/MultiSelect/MultiSelect'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import useTag from '../model/useTag'
import { block } from '../../block'
import TagOption from './TagOption'
import Tag from './Tag'
import { StyledButton, TagsWrapper } from './styles'

interface Props {
  block: block
}

const Tags = (props: Props) => {
  const _tags = useTag({
    block: props.block,
  })

  return (
    <StyledWrapper>
      <PropertyWrapper width={'100%'}>
        <PropertyTitle>Tags</PropertyTitle>
        <RadixDropdown
          trigger={
            <ActionWrapper>
              {_tags.blockTags?.length === 0 ? (
                <>
                  <icons.plus /> Add{' '}
                </>
              ) : (
                <StyledButton>
                  <TagsWrapper>
                    {_tags.blockTags?.map((tag) => {
                      return <Tag label={tag?.label} key={tag.id} />
                    })}
                  </TagsWrapper>
                </StyledButton>
              )}
            </ActionWrapper>
          }
          onOpenChange={(isOpen) => {
            if (!isOpen && _tags.isTagCreation) {
              _tags.handleTagCreationClose()
            }
          }}
        >
          <Wrapper padding={'8px 0 4px 0'}>
            <MultiSelect
              options={_tags.projectTags}
              onChange={(newValue) => _tags.updateBlockTags(newValue)}
              formatOptionLabel={(data, formatOptionLabelMeta) =>
                TagOption({
                  data: data,
                  _tags: _tags,
                })
              }
              placeholder={'Search'}
              value={_tags.blockTags}
              isSearchable={true}
              removePaddingOnOption={
                (_tags.isTagCreation ? (_tags.projectTags?.length as number) - 1 : null) ||
                (_tags.isTagRename
                  ? (_tags.projectTags?.findIndex((t) => t.id === _tags.isTagRename) as number) + 1
                  : null)
              }
              controlShouldRenderValue={false}
              hideSelectedOptions={false}
            />
          </Wrapper>
        </RadixDropdown>
      </PropertyWrapper>
    </StyledWrapper>
  )
}

export default Tags
