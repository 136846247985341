import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

export const LARGE_BUTTON_HEIGHT = 28

export const StyledButton = styled(motion.button)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--overlay-modal);
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);
  border-radius: 6px;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: var(--hover);
        cursor: pointer;
      }
    `}
`

export const SmallButton = styled(StyledButton)`
  width: 24px;
  height: 24px;
`

export const LargeButton = styled(StyledButton)`
  width: 36px;
  height: ${LARGE_BUTTON_HEIGHT}px;
`
