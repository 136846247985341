export const FIGMA_INVALID_EMBED_NAME = 'Invalid Figma embed'

export const GOOGLE_DOCS_INVALID_EMBED_URL = 'Invalid Google Docs embed link'
export const GOOGLE_SLIDES_INVALID_EMBED_URL = 'Invalid Google Slides embed link'
export const GOOGLE_SHEET_INVALID_EMBED_URL = 'Invalid Google Sheets embed link'
export const OFFICE_INVALID_EMBED_URL =
  'Invalid Office 365 embed code. Please provide a valid embed code.'
export const MIRO_INVALID_EMBED_URL = 'Invalid Miro embed link'

export const EVERYDAY_IDENTIFIER = 'everyday'
export const FIGMA_EMBED_REDIRECTER = 'https://www.figma.com/embed'
export const FIGMA_EMBED_IDENTIFIER = `?embed_host=${EVERYDAY_IDENTIFIER}&url=`
