import styled from 'styled-components'

interface StyledPagePreviewPropsType {
  isSelected?: boolean
  isChatEmbed?: boolean
}

export const StyledPagePreview = styled.div<StyledPagePreviewPropsType>`
  display: flex;
  align-items: center;
  padding: 10px;
  gap: 12px;
  font-size: ${(props) => (props.isChatEmbed ? '16px' : '18px')};
  font-weight: 600;
  text-decoration: underline;
  text-decoration-color: var(--gray3);
  text-underline-offset: 3px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  color: var(--text);
  background-color: ${(props) => (props.isSelected ? 'var(--selection-gray)' : '')};

  :focus {
    background-color: var(--selection-gray);
  }
`
