import React from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 3;
`

const StyledMessage = styled.div`
  width: fit-content;
  padding: 12px 16px;
  border-radius: 8px;
  color: white;
  background-color: var(--main-black);
`

export const DblClickToEnable = () => {
  return (
    <StyledWrapper>
      <StyledMessage>Double click to enable editing</StyledMessage>
    </StyledWrapper>
  )
}
