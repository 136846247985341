import React from 'react'
import styled from 'styled-components'

interface Props {
  id?: string
  width?: string | number
  height?: string | number
  src?: string
  srcDoc?: string
  iframeRef?: React.RefObject<HTMLIFrameElement>
  onLoad?: (e: React.SyntheticEvent) => void
  zIndex?: number
  position?: string
}

const StyledIFrame = styled.iframe<{ zIndex?: number; position?: string }>`
  z-index: ${(props) => props.zIndex || 0};
  position: ${(props) => props.position || undefined};
`

const IFrameEmbed = (props: Props) => {
  const { width, height, src, iframeRef } = props
  return (
    <StyledIFrame
      zIndex={props.zIndex}
      position={props.position}
      onLoad={props.onLoad}
      id={props.id}
      width={width || '100%'}
      height={height || '100%'}
      src={src}
      srcDoc={props.srcDoc}
      allowFullScreen
      ref={iframeRef}
    />
  )
}

export default IFrameEmbed
