import Quill, { DeltaOperation, DeltaStatic, StringMap } from 'quill'
import { useRef } from 'react'

export const useTextEdit = () => {
  const formatBeforeRef = useRef<StringMap | null>(null)

  const removeHighlight = (node: any, delta: DeltaStatic, editor: Quill): DeltaStatic => {
    if (delta.ops) {
      const contents = editor.getContents()

      if (!contents?.ops?.[contents.ops?.length - 2]?.attributes?.background) {
        delete delta.ops?.[0]?.attributes
        return delta
      }

      // Iterate over each op
      delta.ops.forEach((op: DeltaOperation) => {
        op.attributes = contents?.ops?.[contents.ops?.length - 2]?.attributes
      })
    }

    // Return the modified delta
    return delta
  }

  // Prevent Quill from deleting format when deleting text from the entire line
  const handlePreventFormatDelete = (editor: Quill) => {
    const selection = editor.getSelection()

    if (!selection) return

    const [_, offset] = editor.getLine(selection?.index)

    const indexOfLine = selection.index - offset

    // If there is still one character in the line left, save its format for use on deletion
    if (offset === 1) {
      formatBeforeRef.current = editor.getFormat(indexOfLine, 1)
    }

    // If the line is empty, apply the saved format to the line
    if (offset === 0) {
      formatBeforeRef.current &&
        Object.entries(formatBeforeRef.current).forEach(([key, value]) => {
          editor.format(key, value)
        })
    }
  }

  return {
    removeHighlight,
    handlePreventFormatDelete,
  }
}
