import React from 'react'
import styled from 'styled-components'
import {
  StyledTitleCss,
  CloseButtonCss,
  ModalCss,
  HeaderCss,
  BodyCss,
  StyledFooter,
} from './styles'
import Modal from 'react-bootstrap/Modal'
import { ReactComponent as Close } from 'assets/images/close.svg'

const StyledModal = styled(Modal)`
  ${ModalCss}
`

const StyledHeader = styled(Modal.Header)`
  ${HeaderCss}
`

const StyledBody = styled(Modal.Body)`
  ${BodyCss}
`

const StyledModalTitle = styled(Modal.Title)`
  ${StyledTitleCss}
`

const StyledCloseButton = styled(Close)`
  ${CloseButtonCss}
`

type Props = {
  show?: boolean
  setShow: (value: boolean) => void
  title?: JSX.Element
  children?: React.ReactNode
  width?: string
  paddingLeftTitle?: string
  footer?: JSX.Element
  bodyPaddingTop?: string
  padding?: string
  onModalClick?: (e: MouseEvent) => void
}

const InviteModal: React.FC<Props> = ({
  show,
  setShow,
  title,
  children,
  width,
  paddingLeftTitle,
  footer,
  bodyPaddingTop,
  padding,
  onModalClick,
}) => {
  return (
    <StyledModal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={show}
      onHide={() => setShow(false)}
      className='border-0'
      width={width}
      padding={padding}
      onClick={(e: MouseEvent) => {
        e.stopPropagation()
        e.preventDefault()
        onModalClick && onModalClick(e)
      }}
    >
      <StyledHeader>
        <StyledModalTitle paddingleft={paddingLeftTitle} id='contained-modal-title-center'>
          {title}
        </StyledModalTitle>
        <StyledCloseButton onClick={() => setShow(false)} />
      </StyledHeader>
      <StyledBody paddingtop={bodyPaddingTop}>{children}</StyledBody>
      <StyledFooter>{footer}</StyledFooter>
    </StyledModal>
  )
}

export default InviteModal
