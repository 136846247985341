import styled from 'styled-components'

export const StyledTabs = styled.div<{ padding?: string; height?: string; borderBottom?: string }>`
  padding: ${({ padding }) => (padding ? padding : '12px 48px 5px')};
  display: flex;
  gap: 16px;
  user-select: none;
  height: ${({ height }) => (height ? height : 'auto')};
  border-bottom: ${({ borderBottom }) => (borderBottom ? borderBottom : 'none')};
`
