import { ReactComponent as ChatIcon } from 'assets/images/chat_indicator.svg'
import { ReactComponent as ChatIconTyping } from 'assets/images/chat_indicator_typing.svg'
import { ReactComponent as ChatIconActive } from 'assets/images/chat_indicator_active.svg'
import { ReactComponent as Mirror } from 'assets/images/mirror-active.svg'
import { ReactComponent as Instance } from 'assets/images/instance-active.svg'
import { ReactComponent as IsInstanceIcon } from 'assets/images/other-instance.svg'
import { ReactComponent as IsMirrorIcon } from 'assets/images/other-mirror.svg'
import styled from 'styled-components'
import { CSSProperties } from 'react'

import { ReactComponent as GoTo } from 'assets/images/arrow-forward.svg'
import { ReactComponent as Detach } from 'assets/images/unlink.svg'
import { ReactComponent as Push } from 'assets/images/reload.svg'
import { ReactComponent as Pull } from 'assets/images/arrow-move-right.svg'
import { ReactComponent as DropdownMirror } from 'assets/images/dropdown-mirror.svg'
import { ReactComponent as Dots } from 'assets/images/three_dots.svg'
import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

export const StyledMirrorIcon = styled(Mirror)<{ isScaled?: boolean }>`
  cursor: pointer;
  transform: ${(props) => (props.isScaled ? 'scale(1.3)' : 'scale(1)')};

  path {
    stroke: var(--primary-text);
  }
`

export const StyledInstanceIcon = styled(Instance)<{ isScaled?: boolean }>`
  cursor: pointer;
  transform: ${(props) => (props.isScaled ? 'scale(1.3)' : 'scale(1)')};

  path {
    stroke: var(--primary-text);
  }
`

export const StyledMirrorWhiteIcon = styled(Mirror)`
  path {
    stroke: var(--primary-text);
  }
`

type styledBlockProps = {
  position?: string
  isSelected?: boolean
  /* Reference selection preview */
  isPreview?: boolean
  /* Drag preview */
  dragPreview?: {
    width: number
  }
  indent?: number
  /* Dragging target */
  isDropTarget?: boolean
  /* Static/original block when dragging */
  isDragging?: boolean
  width?: CSSProperties['width']
  maxWidth?: CSSProperties['maxWidth']
  lineHeight?: number
}

export const StyledBlock = styled.div<styledBlockProps>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-left: ${(props) => props.indent + 'px'};
  position: ${(props) => props.position};
  border: ${(props) => props.isDropTarget && '1px solid var(--primary-color)'};
  width: ${(props) => props.width || '100%'};
  flex: 0 0 auto;
  align-content: center;
  align-self: center;
  transition: all 0.35s ease-in-out;

  &:not(:hover) > #editor-handle-dropdown {
    opacity: 0;
  }

  &:not(:hover) > .comment-icon {
    opacity: 0;
  }

  opacity: ${(props) => props.isDragging && '0.3'};

  h1 {
    font-size: 3rem !important;
    font-weight: 900 !important;
    line-height: ${(props) => props.lineHeight || 64}px !important;
    letter-spacing: -1.056px !important; /* Equivalent to -2.2% of 48px (3rem x 16px) */
  }

  h2 {
    font-size: 2.375rem !important;
    font-weight: 200 !important;
    line-height: ${(props) => props.lineHeight || 52}px !important;
    letter-spacing: -0.8355px !important; /* Equivalent to -2.1% of 38px (2.375rem x 16px) */
  }

  h3 {
    font-size: 2.25rem !important;
    font-weight: 800 !important;
    line-height: ${(props) => props.lineHeight || 40}px !important;
    letter-spacing: -0.756px !important; /* Equivalent to -2.1% of 36px (2.25rem x 16px) */
  }

  h4 {
    font-size: 1.75rem !important;
    font-weight: 700 !important;
    line-height: ${(props) => props.lineHeight || 36}px !important;
    letter-spacing: -0.35px !important; /* Equivalent to -2.0% of 28px (1.75rem x 16px) */
  }

  h5 {
    font-size: 1.313rem !important;
    font-weight: 600 !important;
    line-height: ${(props) => props.lineHeight || 28}px !important;
    letter-spacing: -0.22341px !important; /* Equivalent to -1.7% of 21.008px (1.313rem x 16px) */
  }

  h6 {
    font-size: 1.0625rem !important;
    font-weight: 600 !important;
    line-height: ${(props) => props.lineHeight || 24}px !important;
    letter-spacing: -0.138125px !important; /* Equivalent to -1.3% of 17px (1.0625rem x 16px) */
  }

  p {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: ${(props) => props.lineHeight || 24}px !important;
    letter-spacing: 0.1575px; /* Equivalent to 1.4% of 18px (1.125rem x 16px) */
  }

  @media screen and (min-width: 924px) {
    max-width: ${(props) => props.maxWidth || '100%'};
  }
`

export const StyledMirrorIndicator = styled.div`
  @media screen and (max-width: 900px) {
    display: none !important;
  }
  position: absolute;
  right: -5%;
`

export const StyledInstanceIndicator = styled.div<{ isMirror?: boolean }>`
  @media screen and (max-width: 900px) {
    display: none !important;
  }
  position: absolute;
  right: ${(props) => (props.isMirror ? '-9%' : '-5%')};
`

export const EditorBlockInput = styled.input`
  min-width: 0;

  border: none;
  outline: none;
  font-style: italic;

  flex-grow: 1;

  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: var(--secondary-text);
  background: transparent;
`

export const StyledChatIcon = styled(ChatIcon)`
  width: 24px;
  height: 24px;

  path {
    stroke: var(--secondary-text);
  }
`

export const StyledChatIconTyping = styled(ChatIconTyping)`
  width: 24px;
  height: 24px;

  path {
    stroke: var(--secondary-text);
    fill: transparent;
  }
`

export const StyledChatIconActive = styled(ChatIconActive)`
  width: 24px;
  height: 24px;

  path {
    stroke: var(--secondary-text);
    fill: var(--secondary-text);
  }
`

export const StyledChatIconReference = styled.div<{ isMirror?: boolean; isInstance?: boolean }>`
  cursor: pointer;
  position: absolute;
  right: ${(props) =>
    props.isMirror && props.isInstance
      ? '-0.5%'
      : props.isMirror || props.isInstance
      ? '-0.5%'
      : '-5%'};
`

export const StyledChatNumber = styled.span`
  color: var(--secondary-text);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  font-weight: 600;

  &.active {
    color: var(--document-background);
  }
`

export const StyledIconWrapper = styled.div`
  position: absolute;
  left: 10%;
`

export const GoToIcon = styled(GoTo)``

export const DetachIcon = styled(Detach)``

export const PushIcon = styled(Push)``

export const MirrorIcon = styled(DropdownMirror)``

export const DotsIcon = styled(Dots)``

export const PullIcon = styled(Pull)``

export const StyledIsMirrorIcon = styled(IsMirrorIcon)<{ isScaled?: boolean }>`
  transform: ${(props) => (props.isScaled ? 'scale(1.3)' : 'scale(1)')};

  path {
    stroke: var(--primary-text);
  }
`

export const StyledIsInstanceIcon = styled(IsInstanceIcon)<{ isScaled?: boolean }>`
  transform: ${(props) => (props.isScaled ? 'scale(1.3)' : 'scale(1)')};

  path {
    stroke: var(--primary-text);
  }
`

export const DotsIconWrapper = styled.div`
  svg {
    path {
      fill: var(--primary-text);
    }
  }
`

export const StyledDropdown = styled(RadixDropdown)<{ top?: string }>`
  display: flex !important;
  flex-direction: column !important;
  transform: translateY(20%) !important;

  & .radix-dropdown-menu-content {
    margin-top: ${(props) => props.top || '0'} !important;
  }

  .dropdown-menu {
    color: var(--primary-text) !important;
  }
`

export const RelationshipContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const RelationshipDropdownMenu = styled.div``

export const RelationshipContainer = styled.div`
  display: flex;
  font-size: 14px;
  gap: 12px;

  justify-content: space-between;
  align-items: center;

  :hover {
    color: black;
    opacity: 1;
    cursor: pointer;
  }
`

export const PageDropdownMenuItem = styled(DropdownMenuItem)`
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding-bottom: 12px;
  padding-top: 12px;
  min-width: 182px;
  max-width: 182px;
`

export const PageDropdownMenuItemText = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  overflow: scroll;
  color: var(--primary-text);

  ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`
