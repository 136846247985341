import React from 'react'
import { IconButton } from '../Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import { getBlockPageId, getIndex, type block } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { JSONOp } from 'ot-json1'
import { IconButtonSize } from '_features/toolbar/ui/Toolbar'

interface Props {
  block: block
}

const CreatedByTool = (props: Props) => {
  const { getInsertKeyInDataKeyOp, getReplaceBlockDataKeyOp } = useJson1()
  const { submit } = useSubmit()

  const getIsShowCreatedBy = () => {
    return props.block.data.showCreatedBy
  }

  const getShowCreatedByOp = () => {
    let op: JSONOp | undefined = undefined
    const index = getIndex(props.block)

    if (typeof index === 'number') {
      const isShowCreatedBy = getIsShowCreatedBy()
      if (isShowCreatedBy === undefined) {
        op = getInsertKeyInDataKeyOp(index, ['showCreatedBy'], true)
      } else if (isShowCreatedBy === false) {
        op = getReplaceBlockDataKeyOp(index, ['showCreatedBy'], true, isShowCreatedBy)
      } else {
        op = getReplaceBlockDataKeyOp(index, ['showCreatedBy'], false, isShowCreatedBy)
      }
    }
    return op
  }

  const handleSwitchShowCreatedBy = () => {
    const op = getShowCreatedByOp()
    if (op) submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
  }

  const onClick = () => {
    handleSwitchShowCreatedBy()
  }
  return (
    <IconButton
      isSelected={getIsShowCreatedBy()}
      width={IconButtonSize + 'px'}
      height={IconButtonSize + 'px'}
      onClick={onClick}
    >
      <icons.user />
    </IconButton>
  )
}

export default CreatedByTool
