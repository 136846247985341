import React from 'react'
import ConnectionItemUserModal from '../ConnectionItemUserModal/ConnectionItemUserModal'
import { ConnectionTabUserModalStyled, StyledFacebookLogo, StyledGoogleLogo } from './styles'

const ConnectionTabUserModal = () => {
  return (
    <ConnectionTabUserModalStyled>
      <ConnectionItemUserModal
        isUserConnected={false}
        connectionLogo={<StyledFacebookLogo />}
        connectionName={'Facebook'}
      />
      <hr />
      <ConnectionItemUserModal
        isUserConnected={false}
        connectionLogo={<StyledGoogleLogo />}
        connectionName={'Google'}
      />
    </ConnectionTabUserModalStyled>
  )
}

export default ConnectionTabUserModal
