import { getBlocks as getBlocksFromDoc, getDoc } from 'shared/shareDb'
import { getBlocks as getBlocksFromDocumentElement } from '_widgets/Document'
import { getBlocks as getBlocksFromCanvasElement } from '_entities/whiteboard'
import { type block, BlockMetaKeys, StyleTypes } from './types'

export const getBlocks = (pageId: string) => {
  return (
    getBlocksFromDoc(pageId) ||
    getBlocksFromDocumentElement(pageId) ||
    getBlocksFromCanvasElement(pageId)
  )
}

export const getBlock = (pageId: string, blockId: string) => {
  const blocks = getBlocks(pageId)
  if (!blocks) return
  return blocks.find((block) => block._id === blockId)
}

export const getBlockId = (block: block) => block._id

export const getBlockStyling = (block: block) => block.style?.styling

export const getBlockExpandedState = (block: block) => {
  return block.data?.isEmbedExpanded
}

export const getBlockPageId = (block: block) => {
  return block?.meta[BlockMetaKeys.PAGE_ID]
}

export const getBlockWidth = (block: block) => {
  return block.data.width
}

export const getBlockHeight = (block: block) => {
  return block.data.height
}

export const getCalloutWidth = (block: block) => {
  return block.data.calloutWidth
}

export const getCalloutHeight = (block: block) => {
  return block.data.calloutHeight
}

export const getScaleX = (block: block) => {
  return block.data.scaleX || 1
}

export const getScaleY = (block: block) => {
  return block.data.scaleY || 1
}

export const getEmbed = (block: block) => {
  return block.data.embed
}

export const getImageUrl = (block: block) => {
  return block.data.imageUrl
}

export const getImagePosition = (block: block) => {
  return block.data.imagePosition
}

export const getBlockTag = (block: block) => block.data.tag

export const getBlockGridWidth = (block: block) => block.data.gridWidth

export const getBlockIndent = (block: block) => block.data.indent

export const getBlockSizes = (block: block) => block.data.sizes

export const getIndex = (block: block) => {
  const blocks = getBlocks(getBlockPageId(block))
  if (!blocks) return -1
  return blocks.findIndex((b: block) => b._id === block._id)
}

export const getBlockFontSize = (block: block) =>
  getBlockStyling(block)?.find((style) => style.type === StyleTypes.FONT_SIZE)

export const getBlockFontFamily = (block: block) =>
  getBlockStyling(block)?.find((style) => style.type === StyleTypes.FONT_FAMILY)

export const getPreviousBlock = (block: block) => {
  const currentIndex = getIndex(block)

  if (currentIndex) {
    const previousIndex = currentIndex - 1
    if (previousIndex >= 0) {
      const doc = getDoc(block.meta.pageId)
      if (doc) return (doc.data.blocks as block[])[previousIndex]
    }
  }
}

export const getNextBlock = (block: block) => {
  const currentIndex = getIndex(block)

  if (currentIndex) {
    const nextIndex = currentIndex + 1
    const blocks = getBlocks(block.meta.pageId)
    return blocks?.[nextIndex]
  }
}

export const getBlockLineHeight = (block: block) => block.data.lineHeight
