import { pageApi } from 'shared/api'
import { GetSnapshotRequestType, GetSnapshotResponseType } from '../model/types'

export const snapshots = pageApi.injectEndpoints({
  endpoints: (build) => ({
    getSnapshot: build.query<GetSnapshotResponseType, GetSnapshotRequestType>({
      query: ({ pageId }) => `/${pageId}/history-snapshots/latest`,
    }),
  }),
})

export const { useGetSnapshotQuery } = snapshots
