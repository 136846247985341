import { AxiosService } from 'services/axiosService/axiosService'

const useFileUpload = () => {
  const axiosService = new AxiosService()

  const uploadUrl = async (filename: string) => {
    return await axiosService.uploadUrl(filename)
  }

  const uploadFile = async (url: string, file: any) => {
    return await axiosService.uploadFileToUrl(url, file)
  }

  const createResource = async (filePath: string, pageId?: string) => {
    return axiosService.createResource(filePath, pageId)
  }

  const downloadFile = async (url: string, fileName: string) => {
    try {
      const response = await fetch(url)
      const blob = await response.blob()

      const link = Object.assign(document.createElement('a'), {
        href: URL.createObjectURL(blob),
        download: fileName,
        style: 'display: none',
      })

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return true
    } catch (error) {
      console.error('Error downloading the file:', error)
      return false
    }
  }

  return {
    uploadUrl,
    uploadFile,
    createResource,
    downloadFile,
  }
}

export default useFileUpload
