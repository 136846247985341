import { type block } from '_entities/block'
import { getById } from 'shared/lib'

export const getPageBlockId = (block: block) => {
  return `page-block-${block._id}`
}

export const getPageBlockElement = (block: block) => {
  return getById(getPageBlockId(block))
}

export const getExpandedPageBlockId = (block: block) => {
  return `expanded-page-block-${block._id}`
}

export const getExpandedPageBlockElement = (block: block) => {
  return getById(getExpandedPageBlockId(block))
}
