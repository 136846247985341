import {
  ColorDetailsWrapper,
  PaletteColor,
  PaletteNameWrapper,
} from 'components/organisms/Palette/styles'
import convertHexToRGBA from 'utils/convertToRgba'
import ColorPicker from '../ColorPicker/ColorPicker'
import React from 'react'
import CustomP from 'components/atoms/CustomP/CustomP'
import { IPalette, IPaletteColor } from 'interfaces/settings'
import { RGBColor } from 'react-color'
import { ColorPickerTypes } from 'components/organisms/Palette/Palette'

interface IProps {
  paletteColor: IPaletteColor
  colorToChange: IPaletteColor | null
  isOpen: boolean
  palette: IPalette
  handleSelectColorToChange: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    paletteColor: IPaletteColor,
  ) => void
  expanded: string
  userPalette: IPalette
  handleChange: (
    color: any,
    event: React.ChangeEvent<HTMLInputElement>,
    paletteColor: IPaletteColor,
  ) => void
  colorType: string
  pickedColor?: RGBColor
  closeColorPicker: () => void
  isAdmin?: boolean
  handleShowError?: (error: string, e: React.MouseEvent<HTMLElement>) => void
}

const ColorDetails = ({
  paletteColor,
  colorToChange,
  isOpen,
  palette,
  handleSelectColorToChange,
  expanded,
  userPalette,
  handleChange,
  colorType,
  pickedColor,
  closeColorPicker,
  isAdmin,
  handleShowError,
}: IProps) => {
  return (
    <ColorDetailsWrapper key={paletteColor.id}>
      <PaletteNameWrapper
        onClick={
          palette.attributes.createdBy || isAdmin
            ? (e) => handleSelectColorToChange(e, paletteColor)
            : (e) => handleShowError && handleShowError('You cannot edit default palettes', e)
        }
      >
        <PaletteColor
          color={convertHexToRGBA(`#${paletteColor.color}`, paletteColor.opacity)}
          size='20px'
        />
        {colorToChange &&
          colorToChange.id === paletteColor.id &&
          isOpen &&
          expanded === userPalette.id && (
            <ColorPicker
              transform='translateY(-40%)'
              placeholder={colorType}
              handleChange={handleChange}
              closeColorPicker={closeColorPicker}
              paletteColor={paletteColor}
              pickedColor={pickedColor}
              type={ColorPickerTypes.DETAILS}
            />
          )}
        <CustomP>#{paletteColor.color}</CustomP>
      </PaletteNameWrapper>
      <CustomP>{colorType}</CustomP>
      <CustomP>{Math.floor(paletteColor.opacity * 100)}%</CustomP>
    </ColorDetailsWrapper>
  )
}

export default ColorDetails
