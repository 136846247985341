import { IBlockTypes } from '_entities/block'

export type FileType = IBlockTypes.EXTERNAL_EMBED | IBlockTypes.IMAGE

export const getFileType = (fileName: string): FileType => {
  if (/\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(fileName)) return IBlockTypes.IMAGE
  else return IBlockTypes.EXTERNAL_EMBED
}

export function isCorrectFileType(tag: string, url: string) {
  if (tag === IBlockTypes.IMAGE || tag === IBlockTypes.TEXT || tag === IBlockTypes.IMAGE_TEXT) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url)
  }
  return false
}

export function isGenerallyCorrectFileType(url: string) {
  if (
    /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url) ||
    /\.(pdf)$/.test(url) ||
    /\.(mp4|mov|wmv)$/.test(url)
  ) {
    return true
  } else return false
}
