import { ITask, ITaskProperty } from 'interfaces/taskManager'
import { Draggable } from 'react-beautiful-dnd'
import { StyledDraggable } from 'components/organisms/TaskManagerView/styles'
import { usePropertyValueElement } from '_features/task'
import { TaskContent, TaskDocumentWrapper } from 'components/organisms/TaskManagerBoard/styles'
import {
  setIsSliderOpen,
  setIsSliderTaskOpen,
  setSelectedTaskId,
} from 'redux/reducers/taskManagerReducer'
import { Document } from '_widgets/Document'
import TaskBlock from 'components/organisms/TaskBlock/TaskBlock'
import TaskTitle from 'components/molecules/TaskTitle/TaskTitle'
import TaskProperties from 'components/molecules/TaskProperties/TaskProperties'
import { useTask } from '../hooks'

interface Props {
  task: ITask
  onTaskSelect: (task: ITask) => Promise<void>
  index: number
  selected?: boolean
  type?: 'horizontal' | 'vertical'
  isSlider: boolean
  isSimpleView?: boolean
}

export const Task = (props: Props) => {
  const { task, onTaskSelect, index, selected, type, isSlider, isSimpleView } = props
  const { renderPropertyValueElement } = usePropertyValueElement()

  const {
    statusProperty,
    assigneeProperty,
    dueDateProperty,
    handleRenameTask,
    setIsTaskTitleRename,
    selectedTaskDocument,
    selectedTaskId,
    block,
    dispatch,
    page,
    inputRef,
    minimizeTask,
    handleRedirectToBlock,
  } = useTask({ task, isSlider: true, isSimpleView })

  if (isSimpleView)
    return (
      <StyledDraggable
        type={type}
        isSlider={isSlider}
        onClick={() => {
          isSlider
            ? !selected
              ? onTaskSelect(task)
              : dispatch(setSelectedTaskId(undefined))
            : onTaskSelect(task)
          !isSlider && dispatch(setIsSliderOpen(true))
          !isSlider && dispatch(setIsSliderTaskOpen(true))
        }}
        {...{ selected: selected }}
        id={`task-${task.id}`}
        isSimpleView={isSimpleView}
      >
        <TaskContent type={type} isSimpleView={isSimpleView}>
          <TaskTitle
            task={task}
            isSlider={isSlider}
            handleRenameTask={handleRenameTask}
            inputRef={inputRef}
            setIsTaskTitleRename={setIsTaskTitleRename}
            selected={selected}
            onTaskSelect={onTaskSelect}
            minimizeTask={minimizeTask}
            isSimpleView={isSimpleView}
          />
          <TaskProperties
            assigneeProperty={assigneeProperty as ITaskProperty}
            statusProperty={statusProperty as ITaskProperty}
            dueDateProperty={dueDateProperty as ITaskProperty}
            renderPropertyValueElement={renderPropertyValueElement}
          />
        </TaskContent>
      </StyledDraggable>
    )

  return (
    <Draggable key={task.id} draggableId={task.id} index={index}>
      {(provided, snapshot) => {
        return (
          <StyledDraggable
            ref={provided.innerRef}
            type={type}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            isDragging={snapshot.isDragging}
            style={{
              ...provided.draggableProps.style,
            }}
            isSlider={isSlider}
            onClick={() => {
              isSlider
                ? !selected
                  ? onTaskSelect(task)
                  : dispatch(setSelectedTaskId(undefined))
                : onTaskSelect(task)
              !isSlider && dispatch(setIsSliderOpen(true))
              !isSlider && dispatch(setIsSliderTaskOpen(true))
            }}
            {...{ selected: selected }}
            id={`task-${task.id}`}
          >
            <TaskContent type={type}>
              <TaskTitle
                task={task}
                isSlider={isSlider}
                handleRenameTask={handleRenameTask}
                inputRef={inputRef}
                setIsTaskTitleRename={setIsTaskTitleRename}
                selected={selected}
                onTaskSelect={onTaskSelect}
                minimizeTask={minimizeTask}
              />
              <TaskProperties
                assigneeProperty={assigneeProperty as ITaskProperty}
                statusProperty={statusProperty as ITaskProperty}
                dueDateProperty={dueDateProperty as ITaskProperty}
                renderPropertyValueElement={renderPropertyValueElement}
              />
            </TaskContent>
            {block && (
              <TaskBlock
                block={block}
                page={page}
                handleRedirectToBlock={handleRedirectToBlock}
                isDisabled={!isSlider}
              />
            )}
            {selectedTaskDocument && isSlider && selectedTaskId === task?.id && (
              <TaskDocumentWrapper isSlider={isSlider}>
                <Document pageId={selectedTaskDocument.id} />
              </TaskDocumentWrapper>
            )}
          </StyledDraggable>
        )
      }}
    </Draggable>
  )
}
