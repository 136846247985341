import { IBlockTypes, block, getBlockPageId } from '_entities/block'
import { useCallback } from 'react'
import { Node, NodeConfig } from 'konva/lib/Node'
import { useStage, useIntersection } from '_features/canvas'
import { ITools, Position } from 'interfaces/whiteboard'
import { useWhiteboardEmbed } from '_entities/embed'
import { useAppDispatch } from 'redux/hooks'
import { setTool } from 'redux/reducers/whiteboardReducer'
import { useCreateBlock } from '_features/block'
import { frames, getFrameHtmlId, getFrameKonvaRectId } from './getters'
import { sizes } from './types'
import { GroupNames, useKonvaNode } from '_entities/whiteboard'
import { getById } from 'shared/lib'
import { getBlocks } from 'shared/shareDb'

export const useFrame = () => {
  const NO_FILL = 'white'
  const _stage = useStage()
  const _intersection = useIntersection()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _createBlock = useCreateBlock()
  const _konvaNode = useKonvaNode()
  const dispatch = useAppDispatch()

  const getFrameHtmlElement = (block: block) => {
    return getById(getFrameHtmlId(block._id))
  }
  const getFrameKonvaElement = (block: block) => {
    return _konvaNode.findById(getBlockPageId(block), getFrameKonvaRectId(block._id))
  }

  const isFrameBlock = useCallback((block: block) => {
    return block?.data?.tag === IBlockTypes.FRAME
  }, [])

  const getBlocksIntersectingWithFrame = (pageId: string, frame: Node<NodeConfig>) => {
    const nodes: Node<NodeConfig>[] = []
    const frameRect = frame.getClientRect()
    const blockNodes = _stage.getAllBlockNodes(pageId)
    blockNodes?.forEach((node: Node) => {
      if (_intersection.haveRectsIntersection(node.getClientRect(), frameRect)) {
        nodes.push(node)
      }
    })
    return nodes
  }

  const getFrameBlocks = (pageId: string) => {
    const blocks = getBlocks(pageId)
    if (!blocks) return
    return blocks.filter((block: block) => isFrameBlock(block))
  }

  const isNodeFrameInsideCanvasInsideDoc = (node: Node<NodeConfig>) => {
    const pageId = _stage.getDocIdFromStageId(node.getStage()?.attrs.id)
    if (!pageId) return
    const isFrame = node.hasName(GroupNames.FRAME)
    return isFrame && _whiteboardEmbed.isDocEmbed(pageId)
  }

  const newFrameConfig = (pageId: string) => {
    return {
      [ITools.FRAME]: () => void 0,
      [ITools.FRAME_SIXTEEN_NINE]: () => {
        const frameNodes = _stage.getAllFrameNodes(pageId)
        let position: Position | undefined
        if (_whiteboardEmbed.isDocEmbed(pageId)) {
          if (frameNodes && frameNodes.length > 0) {
            dispatch(setTool(ITools.CURSOR))
            return
          } else {
            position = {
              x: 0,
              y: 0,
            }
          }
        }
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.FRAME,
          frameSize: frames[sizes.FRAME_SIXTEEN_NINE],
          pageId,
          position,
        })
      },
      [ITools.FRAME_FOUR_THREE]: () => {
        const frameNodes = _stage.getAllFrameNodes(pageId)
        let position: Position | undefined
        if (_whiteboardEmbed.isDocEmbed(pageId)) {
          if (frameNodes && frameNodes.length > 0) {
            dispatch(setTool(ITools.CURSOR))
            return
          } else {
            position = {
              x: 0,
              y: 0,
            }
          }
        }
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.FRAME,
          frameSize: frames[sizes.FRAME_FOUR_THREE],
          pageId,
          position,
        })
      },
      [ITools.FRAME_ONE_ONE]: () => {
        const frameNodes = _stage.getAllFrameNodes(pageId)
        let position: Position | undefined
        if (_whiteboardEmbed.isDocEmbed(pageId)) {
          if (frameNodes && frameNodes.length > 0) {
            dispatch(setTool(ITools.CURSOR))
            return
          } else {
            position = {
              x: 0,
              y: 0,
            }
          }
        }
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.FRAME,
          frameSize: frames[sizes.FRAME_ONE_ONE],
          pageId,
          position,
        })
      },
      [ITools.FRAME_CUS]: () => {
        const frameNodes = _stage.getAllFrameNodes(pageId)
        let position: Position | undefined
        if (_whiteboardEmbed.isDocEmbed(pageId)) {
          if (frameNodes && frameNodes.length > 0) {
            dispatch(setTool(ITools.CURSOR))
            return
          } else {
            position = {
              x: 0,
              y: 0,
            }
          }
        }
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.FRAME,
          frameSize: frames[sizes.FRAME_CUSTOM],
          pageId,
          position,
        })
      },
    }
  }

  return {
    getFrameHtmlElement,
    getFrameKonvaElement,
    NO_FILL,
    isFrameBlock,
    frames,
    getBlocksIntersectingWithFrame,
    getFrameBlocks,
    newFrameConfig,
    isNodeFrameInsideCanvasInsideDoc,
  }
}
