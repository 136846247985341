import styled from 'styled-components'
import CheckWhite from 'assets/images/checkWhite.svg'
import Form from 'react-bootstrap/Form'

type CheckboxStyledProps = {
  fontSize?: string
  fontWeight?: string
  marginBlock?: string
  dimensions?: string
  borderRadius?: string
  borderColour?: string
  borderWidth?: string
  marginRight?: string
  top?: string
  left?: string
  checked?: boolean
}

export const CheckboxStyled = styled.div<CheckboxStyledProps>`
  font-size: ${(props) => props.fontSize || ''};
  font-weight: ${(props) => props.fontWeight || ''};
  margin-block: ${(props) => props.marginBlock || ''};
  display: flex;
  align-items: last baseline;

  label {
    color: ${(props) =>
      props.checked ? 'var(--primary-text)' : 'var(--secondary-text)'} !important;
  }
`

export const StyledFormCheck = styled(Form.Check)`
  /* Stylings below should be globally applied. Unique ones are passed through props. */
  & .form-check-input {
    cursor: pointer;
    width: ${(props) => props.dimensions || '16px !important'};
    min-height: ${(props) => props.dimensions || '16px !important'};
    background-image: ${(props) => (props.checked ? `url(${CheckWhite}) !important` : 'none')};
    background-color: ${(props) => (props.checked ? 'var(--primary-color)' : 'none')};
    background-size: 70%;
    border-width: ${(props) => props.borderWidth || '1px'};
    border-style: solid;
    border-color: ${(props) =>
      props.checked ? 'transparent' : props.borderColour || 'var(--gray3)'};
    box-shadow: none;
    margin-right: ${(props) => props.marginRight || '12px'};
    position: relative;
    top: ${(props) => props.top || '0'};
    left: ${(props) => props.left || '0'};
    border-radius: ${(props) => props.borderRadius || ''};
  }
`
