import CustomP from 'components/atoms/CustomP/CustomP'
import { BasicInput } from 'components/atoms/BasicInput/BasicInput'
import React from 'react'
import Button from 'components/atoms/Button/Button'
import { Spinner } from 'react-bootstrap'
import { getColor } from 'shared/colors'
import { StyledLink } from './styles'

interface IProps {
  loading?: boolean
  title: string
  placeholder: string
  buttonText: string
  link: string
  disabled: boolean
  addLink: () => void
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onInputMouseDown?: () => void
  onPaste?: (e: React.ClipboardEvent<HTMLInputElement>) => Promise<void>
}

export const LINK_INPUT_ID = 'link-input'

export const checkIfLinkInputPaste = (e: ClipboardEvent) => {
  return (e.target as HTMLInputElement)?.id === LINK_INPUT_ID
}

const Link = (props: IProps) => {
  const buttonRef = React.useRef<HTMLButtonElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleEnterKeyDown = () => {
    if (buttonRef.current) {
      buttonRef.current?.focus()
      props.addLink()
    }
  }

  return (
    <StyledLink onMouseDown={(e: React.MouseEvent<HTMLInputElement>) => e.stopPropagation()}>
      <CustomP
        color={getColor('--primary-text')}
        fontWeight='500'
        fontSize='12px'
        marginBottom='12px'
      >
        {props.title}
      </CustomP>
      <BasicInput
        onMouseDown={props.onInputMouseDown}
        onPaste={(e: React.ClipboardEvent<HTMLInputElement>) => {
          e.stopPropagation()
          if (props.onPaste) props.onPaste(e)
        }}
        type='text'
        ref={inputRef}
        placeholder={props.placeholder}
        borderRadius='12px'
        placeholderFontSize='13px'
        value={props.link}
        onChange={props.onInputChange}
        background={getColor('--overlay-modal')}
        color={getColor('--primary-text')}
        padding='4px 12px'
        margin={'0 0 12px 0'}
        id={LINK_INPUT_ID}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleEnterKeyDown()
          }
        }}
      />

      <Button
        variant={'primary'}
        size={'small'}
        onClick={props.addLink}
        disabled={props.disabled}
        grow
        width={'100%'}
        ref={buttonRef}
      >
        {props.loading ? <Spinner size='sm' /> : `${props.buttonText}`}
      </Button>
    </StyledLink>
  )
}

export default Link
