import IFrameEmbed from 'components/atoms/IFrameEmbed/IFrameEmbed'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { useState } from 'react'
import { DEFAULT_GENERIC_LINK_NAME, WebView } from '_features/embed'
import { isElectron } from 'shared/lib'
import { envVars } from 'config/env'

interface Props {
  id?: string
  url: string
  title?: string
}

const MainWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 84px;
  background: rgba(173, 173, 173, 0.03);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.2px);
  -webkit-backdrop-filter: blur(5.2px);
  border: 1px solid rgba(173, 173, 173, 0.24);
`

const Text = styled.h1`
  font-size: 28px;
  text-align: center;
  color: rgb(173, 173, 173);
`

export const DEFAULT_FALLBACK_TEXT = 'Website'

export const WebsiteEmbed = (props: Props) => {
  const [loading, setLoading] = useState(true)

  const getFallbackText = () => {
    if (props.title === DEFAULT_GENERIC_LINK_NAME) {
      return DEFAULT_FALLBACK_TEXT
    } else return props.title
  }
  return (
    <MainWrapper>
      {!loading && <Text>{getFallbackText()}</Text>}
      {loading && <Spinner animation='border' />}
      {isElectron() && <WebView id={props.id} url={props.url} />}
      {!isElectron() && (
        <IFrameEmbed
          position='absolute'
          id={props.id}
          onLoad={() => setLoading(false)}
          src={`${envVars.api.proxy}${props.url}`}
        />
      )}
    </MainWrapper>
  )
}
