import { toast } from 'react-toastify'
import { ReactComponent as Exclamation } from 'assets/images/exclamationWhite.svg'
import { ReactComponent as CheckWhite } from 'assets/images/checkWhite.svg'
import { ReactComponent as WarningWhite } from 'assets/images/warningWhite.svg'
import { ReactComponent as Info } from 'assets/images/info.svg'
import styled from 'styled-components'

export const IconWrapper = styled.div<{ background: string }>`
  background-color: ${(props) => props.background};
  height: 100%;
  max-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const useToast = () => {
  const COVER_CASE_MESSAGE = 'Unknown message'

  const success = (message: string, delay?: number) => {
    toast(message || COVER_CASE_MESSAGE, {
      autoClose: delay,
      icon: (
        <IconWrapper background='var(--everyday-green)'>
          <CheckWhite />
        </IconWrapper>
      ),
    })
  }
  const successBlack = (message: string, delay?: number) => {
    toast(message || COVER_CASE_MESSAGE, {
      autoClose: delay,
      icon: (
        <IconWrapper background='var(--main-black)'>
          <CheckWhite />
        </IconWrapper>
      ),
    })
  }

  const warning = (message: string, delay?: number) => {
    toast(message || COVER_CASE_MESSAGE, {
      autoClose: delay,
      icon: (
        <IconWrapper background='var(--everyday-orange)'>
          <WarningWhite />
        </IconWrapper>
      ),
    })
  }

  const error = (message: string, delay?: number) => {
    toast(message || COVER_CASE_MESSAGE, {
      autoClose: delay,
      icon: (
        <IconWrapper background='var(--everyday-red)'>
          <Exclamation />
        </IconWrapper>
      ),
    })
  }

  const info = (message: string, delay?: number) => {
    toast(message || COVER_CASE_MESSAGE, {
      autoClose: delay,
      icon: (
        <IconWrapper background='var(--primary-color)'>
          <Info />
        </IconWrapper>
      ),
    })
  }

  const customToast = (node: () => JSX.Element, delay?: number) => {
    toast(node, {
      autoClose: delay,
    })
  }

  return {
    success,
    successBlack,
    warning,
    error,
    info,
    customToast,
  }
}
