import { EditWrapper } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/BlockEdit/styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor, getStringFromRGBColor } from 'shared/colors'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { getBlockDocumentPage, getInternalEmbedBackgroundHistory } from '../../lib/getters'
import { PaletteColor } from 'components/organisms/Palette/styles'
import ColorPicker from 'components/molecules/ColorPicker/ColorPicker'
import { useEmbedBackgroundEdit } from '_features/embed'
import { SidebarEditProps } from './EmbedEdit'
import { useWhiteboardEmbed } from '../InternalCanvas/model/useWhiteboardEmbed'
import { usePresentation } from '_features/canvas'
import { ColorPickerTypes, getPaletteColorElementId } from 'components/organisms/Palette/Palette'

export const BackgroundEdit = (props: SidebarEditProps) => {
  const _embedBackgroundEdit = useEmbedBackgroundEdit(props)
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _presentation = usePresentation()
  return (
    <>
      {/* Internal canvas embed background color setting */}
      <EditWrapper>
        <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
          Background color
        </CustomP>

        <Wrapper display='flex' gap='2px'>
          {getInternalEmbedBackgroundHistory(props.block)?.map((color, i) => (
            <PaletteColor
              key={`${props.block._id}-background-color-${i}`}
              color={color}
              size='18px'
              onClick={() =>
                _embedBackgroundEdit.handleSelectEmbedBackgroundColor(props.block, color)
              }
            />
          ))}

          <PaletteColor
            color=''
            id={getPaletteColorElementId(ColorPickerTypes.BACKGROUND)}
            size='18px'
            onClick={() => {
              _embedBackgroundEdit.setIsBackgroundSelectionOpen(
                !_embedBackgroundEdit.isBackgroundSelectionOpen,
              )
            }}
            isColorWheel
          />
        </Wrapper>
        {_embedBackgroundEdit.isBackgroundSelectionOpen && (
          <ColorPicker
            transform='translateY(-90%) translateX(75%)'
            placeholder={'Background color'}
            handleChange={(color) =>
              _embedBackgroundEdit.onBackgroundChange(props.block, color.rgb)
            }
            onChangeComplete={(color) => {
              const blockPage = getBlockDocumentPage(props.block)
              if (!blockPage) return
              if (_whiteboardEmbed.isDocEmbed(blockPage.id)) {
                _presentation.updateFrameBackgroundColor(
                  blockPage.id,
                  getStringFromRGBColor(color.rgb),
                )
              }
              _embedBackgroundEdit.updateBackgroundColorPreset(props.block, color.rgb)
            }}
            closeColorPicker={() => {
              _embedBackgroundEdit.setIsBackgroundSelectionOpen(false)
            }}
            pickedColor={_embedBackgroundEdit.selectedBackgroundColor}
            type={ColorPickerTypes.BACKGROUND}
          />
        )}
      </EditWrapper>
    </>
  )
}
