import { useTransformer, useStage, StageChangeEvent } from '_features/canvas'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useGrid, useKonvaNode } from '_entities/whiteboard'
import { KonvaEventObject } from 'konva/lib/Node'
import useLocalStorage, { LocalStorageKeys } from 'shared/lib/useLocalStorage'
import { useEvent } from '_features/canvas/event'
import { getIndex } from '_entities/block'

export const useMove = () => {
  const _transformer = useTransformer()
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _grid = useGrid()
  const _stage = useStage()
  const _konvaNode = useKonvaNode()
  const _localStorage = useLocalStorage()
  const _event = useEvent()

  const moveBlocksInTransformer = (pageId: string) => {
    const nodes = _transformer.getTransformerNodes(pageId)
    if (nodes) {
      nodes.forEach((node) => {
        const block = _stage.getBlockFromNode(pageId, node)
        if (!block) return
        const index = getIndex(block)
        if (index > -1) {
          const snapPosition = _grid.calculateGridCoordinates({
            x: node.attrs.x,
            y: node.attrs.y,
          })
          const group = _konvaNode.getGroupNode(block)
          group?.x(snapPosition.x)
          group?.y(snapPosition.y)

          const xOp = _json1.getReplaceBlockDataKeyOp(index, ['x'], snapPosition.x)
          const yOp = _json1.getReplaceBlockDataKeyOp(index, ['y'], snapPosition.y)
          const finalOp = _json1.combineOperations([xOp, yOp])
          _submit.submit(pageId, finalOp, SourceKeys.UPDATE_BLOCK)
        }
      })
    }
  }

  const scrollAndTouchpadMoveHandler = (e: KonvaEventObject<WheelEvent>) => {
    const stage = e.target.getStage()
    if (stage) {
      const dx = -e.evt.deltaX
      const dy = -e.evt.deltaY
      // We're setting both x and y on this event because it serves as universal positioning
      // handler so both mouse scroll and laptop touchpad scroll can be handled
      stage.x(stage.x() + dx)
      stage.y(stage.y() + dy)
      const pageId = _event.getPageIdFromEvent(e)
      _grid.drawLines(pageId)
    }
  }

  const getStageX = (pageId: string): number | null => {
    const stages = _stage.getStagesFromLocalStorage()
    if (!stages) return null
    const stage = stages[pageId]
    return stage?.x
  }

  const getStageY = (pageId: string): number | null => {
    const stages = _stage.getStagesFromLocalStorage()
    if (!stages) return null
    const stage = stages[pageId]
    return stage?.y
  }

  const saveStageX = (pageId: string, x: number) => {
    let stages = _stage.getStagesFromLocalStorage()
    if (!stages) {
      stages = _stage.constructNewLocalStorageStages(pageId)
    }
    stages[pageId] = {
      ...stages[pageId],
      x,
    }
    _localStorage.setItem(LocalStorageKeys.STAGES, stages)
  }

  const saveStageY = (pageId: string, y: number) => {
    let stages = _stage.getStagesFromLocalStorage()
    if (!stages) {
      stages = _stage.constructNewLocalStorageStages(pageId)
    }
    stages[pageId] = {
      ...stages[pageId],
      y,
    }
    _localStorage.setItem(LocalStorageKeys.STAGES, stages)
  }

  const handleStageMoveListener = (pageId: string, e: KonvaEventObject<any>) => {
    if (e.type === 'xChange') saveStageX(pageId, (e as StageChangeEvent).newVal)
    if (e.type === 'yChange') saveStageY(pageId, (e as StageChangeEvent).newVal)
  }

  const setInitialStagePosition = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (!stage) return
    stage.x(getStageX(pageId) || 0)
    stage.y(getStageY(pageId) || 0)
  }

  return {
    moveBlocksInTransformer,
    scrollAndTouchpadMoveHandler,
    handleStageMoveListener,
    getStageX,
    getStageY,
    saveStageX,
    saveStageY,
    setInitialStagePosition,
  }
}
