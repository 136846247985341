import React from 'react'
import { StyledOption, StyledIcon, StyledItemNewPageHeader } from './styles'
import { DropdownMenuItem } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

interface Props {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
  children: React.ReactNode
  onClick: () => void
  onSelect: (e: Event) => void
  fontSize?: string
}

const Option = (props: Props) => {
  return (
    <StyledOption onClick={props.onClick}>
      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.onSelect(e)
        }}
        padding={'6px 0'}
      >
        <StyledIcon>
          <props.icon width={24} height={24} />
        </StyledIcon>

        <StyledItemNewPageHeader fontSize={props.fontSize}>
          {props.children}
        </StyledItemNewPageHeader>
      </DropdownMenuItem>
    </StyledOption>
  )
}

export default Option
