import { PageNameWrapper, PagePreviewOptionsWrapper } from './styles'
import React from 'react'
import { Icon } from 'shared/icons'
import { PagePreviewAction } from './PagePreviewAction'

interface IProps {
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null | string
  isEmbeddedDocumentExpanded: boolean
  handleEmbeddedMaximize: () => void
  handleEmbeddedMinimize: () => void
  title?: string
  isWhiteboardEmbed?: boolean
  onTitleClick?: () => void
}

export const PagePreviewOptions = (props: IProps) => {
  return (
    <PagePreviewOptionsWrapper className='page-preview-options'>
      <PageNameWrapper>
        {props.Icon && <Icon Icon={props.Icon} />}
        {<span onClick={props.onTitleClick}>{props.title}</span>}
      </PageNameWrapper>
      <PagePreviewAction
        isEmbeddedDocumentExpanded={props.isEmbeddedDocumentExpanded}
        handleEmbeddedMaximize={props.handleEmbeddedMaximize}
        handleEmbeddedMinimize={props.handleEmbeddedMinimize}
      />
    </PagePreviewOptionsWrapper>
  )
}
