import React from 'react'
import CustomP from 'components/atoms/CustomP/CustomP'
import Divider from 'components/atoms/Divider/Divider'
import { DividerWrapper } from './styles'

type InviteModalDividerPropsType = {
  margin?: string
}

function InviteModalDivider(props: InviteModalDividerPropsType) {
  return (
    <DividerWrapper className='d-inline-flex' margin={props.margin}>
      <Divider height='1px' color='var(--gray2) !important' />
      <CustomP
        fontSize='13px'
        color='var(--gray3)'
        marginLeft='12px'
        marginRight='12px'
        className='my-auto'
      >
        or
      </CustomP>
      <Divider height='1px' color='var(--gray2) !important' />
    </DividerWrapper>
  )
}

export default InviteModalDivider
