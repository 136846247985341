import React from 'react'
import { StyledAvatar } from './styles'
import Image from '../Image/Image'
import { Props } from './sharedProps'

const Avatar: React.FC<Props> = ({
  userName,
  userColor,
  src,
  alt,
  width,
  height,
  fontSize,
  borderRadius,
  onError,
  cursor,
}) => {
  return (
    <StyledAvatar
      heigth={height}
      width={width}
      fontSize={fontSize}
      userColor={userColor}
      borderRadius={borderRadius}
    >
      {src && (
        <Image
          src={src}
          alt={`profile-image-${userName}`}
          width={width}
          height={height}
          objectFit='cover'
          objectPosition='50% 50%'
          onError={onError}
          cursor={cursor}
        />
      )}
      {!src && userName && userName[0].toUpperCase()}
    </StyledAvatar>
  )
}

export default Avatar

Avatar.defaultProps = {
  height: '20px !important',
  width: '20px !important',
  fontSize: '16px',
  userColor: 'grey',
  borderRadius: '50%',
}
