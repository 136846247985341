import styled, { css } from 'styled-components'

export const StyledImage = styled.div`
  height: 16rem;
  width: 16rem;
`

export const StyledUploadIcon = css`
  font-family: FontAwesome !important;
  font-size: x-large;
`

export const UploadText = styled.p`
  margin-bottom: 0px;
  font-size: 16px;
`
