import { useAppDispatch } from 'redux/hooks'
import { setOpen, setSelectedObject } from 'redux/reducers/chatReducer'
import { type block } from '_entities/block'

export const useComments = () => {
  // Hooks
  const dispatch = useAppDispatch()

  const handleOnCommentClick = (block: block) => {
    dispatch(setSelectedObject(block))
    dispatch(setOpen(true))

    setTimeout(() => {
      const element = document.querySelector('.str-chat__list')
      if (element) {
        element.scrollTo(0, element.scrollHeight)
      }
    }, 1000)
  }

  return {
    handleOnCommentClick,
  }
}
