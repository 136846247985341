import { IGeneralDropdownOption } from 'interfaces/invite'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { AxiosService } from 'services/axiosService/axiosService'
import { expirationOptions } from 'services/invites/constants'
import { useToast } from 'services/helpers/useToast'

export const useProjectInviteLink = () => {
  const [expiration, setLinkExpiration] = useState<string>(expirationOptions[0].value)
  const [permission, setLinkPermission] = useState<number>(2)
  const [inviteLink, setInviteLink] = useState<string>('')
  const toast = useToast()
  const params = useParams()
  const AxiosInstance = new AxiosService()
  const projectId = params.id

  const updateLinkDuration = (option: IGeneralDropdownOption) => {
    setLinkExpiration(option.value as string)
  }

  const updateLinkPermission = (option: IGeneralDropdownOption) => {
    setLinkPermission(option.value as number)
  }

  const getInviteLink = async () => {
    if (projectId) {
      const response = await AxiosInstance.getProjectFilePublicLink(parseInt(projectId))
      if (response) setInviteLink(response.data)
    }
  }

  const generateNewLink = async () => {
    if (projectId && permission) {
      const response = await AxiosInstance.generateProjectFileInviteLink(
        parseInt(projectId),
        permission,
        expiration,
      )
      if (response) {
        setInviteLink(response.data)
        toast.success('New link generated!')
      }
    }
  }

  useEffect(() => {
    getInviteLink()
  }, [])

  return {
    updateLinkDuration,
    updateLinkPermission,
    inviteLink,
    getInviteLink,
    generateNewLink,
  }
}
