export enum blockToolbar {
  TEXT_RESIZER = 'text-resizer',
  TEXT_STYLES = 'text-styles',
  TEXT_COLOURS = 'text-colours',
  TEXT_LINK = 'text-link',
  TEXT_AI = 'text-ai',
  TEXT_COMMENT = 'text-comment',
  TEXT_TASK = 'text-task',
  TEXT_INSTANCE = 'text-instance',
  TEXT_MIRROR = 'text-mirror',
  TEXT_DOTS = 'text-dots',
}

export enum shapeToolbar {
  SHAPE_COLOR = 'shape-color',
  SHAPE_BORDER = 'shape-border',
  SHAPE_REPLACE = 'shape-replace',
}

export enum StickyNoteToolbar {
  STICKY_NOTE_COLOR = 'sticky-note-color',
  STICKY_NOTE_REPLACE = 'sticky-note-replace',
  STICKY_NOTE_USER = 'sticky-note-user',
}
