import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setProfileOpen } from 'redux/reducers/profileReducer'
import { toggleFullScreen } from 'redux/reducers/chatReducer'
import { setErrorShown, setUserImage } from 'redux/reducers/globalReducer'
import { useEffect, useState } from 'react'
import { AxiosService } from 'services/axiosService/axiosService'
import useLocalStorage from 'shared/lib/useLocalStorage'
import { useNavigate } from 'react-router-dom'
import { SettingsKey } from 'interfaces/settings'
import useSettings from '_entities/profile/model/useSettings'
import { useViewer } from '_entities/viewer'

const useProfileDrawer = () => {
  const [userImage, setLocalUserImage] = useState<string>()
  const [isSettingsVisible, setIsSettingsVisible] = useState<boolean>(false)
  const [isErrorMessageToggleSelected, setIsErrorMessageToggleSelected] = useState<boolean>(true)

  const open = useAppSelector((state) => state.profile.profileOpen)
  const fullScreen = useAppSelector((state) => state.chat.fullScreen)
  const user = useAppSelector((state) => state.global.user)
  const settings = useAppSelector((state) => state.global.settings)

  const navigate = useNavigate()
  const AxiosInstance = new AxiosService()
  const dispatch = useAppDispatch()

  const _settings = useSettings()
  const _localStorage = useLocalStorage()
  const _viewer = useViewer()

  useEffect(() => {
    if (user) {
      getProfileImage()
    }
  }, [user])

  useEffect(() => {
    if (!open) {
      setIsSettingsVisible(false)
    }
  }, [open])

  useEffect(() => {
    handleFetchSettings()
  }, [])

  const handleFetchSettings = async () => {
    if (settings) {
      const errorReportingSetting = _settings.getSettingByKey(settings, SettingsKey.ERROR_REPORTING)

      if (errorReportingSetting?.value === 'all') {
        setIsErrorMessageToggleSelected(true)
        dispatch(setErrorShown(true))
      } else {
        setIsErrorMessageToggleSelected(false)
        dispatch(setErrorShown(false))
      }
    }
  }

  const handleProfileDrawerClose = () => {
    dispatch(setProfileOpen(false))
  }

  const handleToggleFullScreen = () => {
    dispatch(toggleFullScreen())
  }

  const getProfileImage = async () => {
    const response = user && (await AxiosInstance.getUserProfileImage(user.id ?? user.uuid))

    if (response && response.data !== undefined) {
      setLocalUserImage(`${response.data}`)
      dispatch(setUserImage(`${response.data}`))
    }
  }

  const toggleSettings = () => {
    setIsSettingsVisible(!isSettingsVisible)
  }

  const logoutEndpoint = async () => {
    const response = await AxiosInstance.logout()
    if (response) navigate('/')
  }

  const handleLogout = () => {
    _localStorage.clearLocalStorage()
    logoutEndpoint()
    _viewer.deleteViewer()
  }

  const handleShowErrorMessagesToggle = async () => {
    setIsErrorMessageToggleSelected(!isErrorMessageToggleSelected)
    if (settings) {
      const errorReportingSetting = _settings.getSettingByKey(settings, SettingsKey.ERROR_REPORTING)
      if (!errorReportingSetting) return

      if (errorReportingSetting?.value === 'none') {
        await AxiosInstance.updateSetting(errorReportingSetting.id.toString(), 'all')
        dispatch(setErrorShown(true))
      } else {
        await AxiosInstance.updateSetting(errorReportingSetting.id.toString(), 'none')
        dispatch(setErrorShown(false))
      }
    }
  }

  return {
    open,
    fullScreen,
    handleProfileDrawerClose,
    handleToggleFullScreen,
    user,
    userImage,
    isSettingsVisible,
    toggleSettings,
    handleLogout,
    isErrorMessageToggleSelected,
    handleShowErrorMessagesToggle,
  }
}

export default useProfileDrawer
