import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import React, { useState } from 'react'
import { ListItem, Visible } from './styles'

interface Props {
  onClick?: () => void
  src?: string
  alt?: string
  padding?: string
  option?: string
  i?: React.Key | null | undefined
  isSelected?: boolean
  optionValue?: string | number | readonly string[]
  disabled?: boolean
}

const SelectOption = ({
  onClick,
  src,
  alt,
  padding,
  option,
  i,
  isSelected,
  optionValue,
  disabled,
}: Props) => {
  return (
    <ListItem onClick={onClick} key={i} padding={'8px'} value={optionValue} disabled={disabled}>
      <Visible isSelected={isSelected}>
        <CustomIcon src={src} alt={alt} padding={padding} />
      </Visible>
      {option}
    </ListItem>
  )
}

export default SelectOption
