import styled from 'styled-components'

export const Form = styled.form`
  &#forgotPasswordForm {
    & * {
      & input,
      & button {
        width: 100% !important;
      }
    }

    .textBolder {
      font-weight: 600;
      margin-block: 2.5rem 0.5rem;
    }
  }
`
