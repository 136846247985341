import { Group, Transformer as KonvaTransformer } from 'react-konva'
import { getColor } from 'shared/colors'
import { KonvaEventObject } from 'konva/lib/Node'
import TextToolbar from 'components/organisms/TextToolbar/TextToolbar'
import ShapeToolbar from 'components/organisms/ShapeToolbar/ShapeToolbar'
import NotesToolbar from 'components/molecules/NotesToolbar/NotesToolbar'
import ImageToolbar from 'components/organisms/ImageToolbar/ImageToolbar'
import FrameToolbar from 'whiteboard/Frame/FrameToolbar/FrameToolbar'
import {
  IBlockTypes,
  ImageTypes,
  ShapeBlockTypes,
  StickyNoteTypes,
  TextBlockTypes,
} from '_entities/block'
import { Html } from 'react-konva-utils'
import { Provider, ReactReduxContext } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { useTransformer } from '../model/useTransformer'
import { useTransform } from '../model/useTransform'
import { Box } from 'konva/lib/shapes/Transformer'
import { STEP_SIZE } from '_entities/whiteboard'
import { useStage, useAnchors, UseWhiteboardType } from '_features/canvas'
import { StyledBlockToolbar } from 'editor/EditableBlock/BlockToolbar/styles'
import { WhiteboardToolbarFramer } from 'utils/framerConfig'
import SharedToolbar from 'components/organisms/SharedToolbar/SharedToolbar'
import { useWhiteboardEmbed } from '_entities/embed'

interface Props {
  pageId: string
  isEmbed?: boolean
  parentPageId?: string
  whiteboard: UseWhiteboardType
  onTransformerDragStart: (pageId: string) => void
  onTransformerDragEnd: (e: KonvaEventObject<DragEvent>, isEmbed?: boolean) => void
  onTransformerDrag: (
    evt: KonvaEventObject<MouseEvent | TouchEvent>,
    isEmbed?: boolean,
    parentPageId?: string,
  ) => void
}

export const Transformer = (props: Props) => {
  const _anchors = useAnchors()
  const _stage = useStage()
  const _transformer = useTransformer()
  const _transform = useTransform()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)

  const trBoundBoxFunc = (oldBox: Box, newBox: Box) => {
    const stage = _stage.getStage(props.pageId)
    if (!stage) return newBox
    const maxSize = STEP_SIZE * stage.scaleX()
    // limit resize
    if (newBox.width < maxSize || newBox.height < maxSize) {
      if (newBox.width < maxSize) {
        // Calculate the new width and x position if the width is less than the minimum size
        newBox.width = maxSize
        newBox.x = oldBox.x
      }
      if (newBox.height < maxSize) {
        // Calculate the new height and y position if the height is less than the minimum size
        newBox.height = maxSize
        newBox.y = oldBox.y
      }
    }
    return newBox
  }

  return (
    <KonvaTransformer
      draggable
      id={`transformer-${props.pageId}`}
      opacity={1}
      rotateEnabled={false}
      borderStroke={getColor('--primary-color')}
      anchorStroke={getColor('--primary-color')}
      anchorCornerRadius={2}
      anchorSize={7}
      ref={props.whiteboard.transformerRef}
      boundBoxFunc={trBoundBoxFunc}
      onDragStart={() => {
        props.onTransformerDragStart(props.pageId)
      }}
      onDragMove={(e) => props.onTransformerDrag(e, props.isEmbed, props.parentPageId)}
      onDragEnd={(e) => props.onTransformerDragEnd(e, props.isEmbed)}
      onTransform={(e: KonvaEventObject<MouseEvent | TouchEvent>) =>
        props.onTransformerDrag(e, props.isEmbed, props.parentPageId)
      }
      onTransformStart={() => _transform.onTransformStart(props.pageId)}
      onTransformEnd={_transform.onTransformEnd}
    >
      {_anchors.renderAnchors(props.pageId)}
      {selectedBlock && (
        <Group x={_transformer.getTransformerSize(props.pageId).width / 2}>
          {(!props.isEmbed || _whiteboardEmbed.isDocEmbed(props.pageId)) &&
            selectedBlock &&
            selectedBlock.data &&
            selectedBlock.data.tag && (
              <ReactReduxContext.Consumer>
                {({ store }) => (
                  <Html
                    divProps={{
                      id: _transformer.WHITEBOARD_BLOCK_TOOLBAR_ID,
                    }}
                  >
                    <Provider store={store}>
                      <Router>
                        <StyledBlockToolbar
                          {...WhiteboardToolbarFramer()}
                          onMouseDown={(e: React.MouseEvent) => {
                            e.preventDefault()
                          }}
                        >
                          {Object.values(TextBlockTypes).includes(selectedBlock.data.tag) && (
                            <TextToolbar />
                          )}
                          {Object.values(ShapeBlockTypes).includes(selectedBlock.data.tag) && (
                            <ShapeToolbar />
                          )}
                          {Object.values(ImageTypes).includes(selectedBlock.data.tag) && (
                            <ImageToolbar />
                          )}
                          {Object.values(StickyNoteTypes).includes(selectedBlock.data.tag) && (
                            <NotesToolbar />
                          )}
                          {selectedBlock.data.tag === IBlockTypes.FRAME && <FrameToolbar />}

                          <SharedToolbar block={selectedBlock} isWhiteboard={true} />
                        </StyledBlockToolbar>
                      </Router>
                    </Provider>
                  </Html>
                )}
              </ReactReduxContext.Consumer>
            )}
        </Group>
      )}
    </KonvaTransformer>
  )
}
