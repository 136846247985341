import { StyledComponent } from 'styled-components'
import { ReactNode } from 'react'
import { IManager, IPage } from './page'
import { IUserAttributes } from './auth/userData'

export type IGroups = Record<string, IGroup>

export interface ISubgroup {
  name: string
  property: ITaskProperty | null
  columns: IGroups
}

export interface IGroup {
  name: string
  property: ITaskProperty | null
  tasks: ITask[]
}

export interface ITask extends IPage {
  properties?: ITaskProperty[]
  manager?: IManager
}

export enum IPropertyTypes {
  STATUS = 'status',
  PERSON = 'person',
  DATE = 'date',
  TEXT = 'text',
  NUMBER = 'number',
  LABEL = 'label',
  FILES_AND_MEDIA = 'files-and-media',
  URL = 'url',
  CREATED_BY = 'created-by',
  LAST_EDITED_BY = 'last-edited-by',
  CREATED_TIME = 'created-time',
  LAST_EDITED_TIME = 'last-edited-time',
  WORKSTREAM = 'workstream',
}

export interface ITaskProperty {
  id: number
  createdAt: Date
  updatedAt: Date
  propertyDefinition: IPropertyDefinitionBase
  status?: IStatus[]
  text?: IText[]
  date?: IDate[]
  multiSelect?: IMultiSelect[]
  number?: INumber[]
  person?: IPerson[]
  propertyDefinitionId: number
  value: string
}

export type Options = IOption[]

export interface IOption {
  id: number
  name: string
  layout: ILayoutsEnum

  groupBy?: IPropertyDefinition
  filters: IPropertyDefinition[]
  showProperties: IPropertyDefinition[]
  subGroupBy?: IPropertyDefinition
}

/* ============ BEGIN PROPERTY DEFINITIONS =========== */

export interface IPropertyUI {
  id: string
  name: string
  type: IPropertyTypes
  icon?:
    | StyledComponent<
        React.FunctionComponent<
          React.SVGProps<SVGSVGElement> & {
            title?: string | undefined
          }
        >,
        any,
        object,
        never
      >
    | ReactNode
  hasOptions?: boolean
}

export interface IPropertyDefinitionBase {
  id: number
  name: string
  type: IPropertyTypes
  createdAt: string
  updatedAt: Date
  statusValues: IStatusValue[]
}

export interface IPropertyDefinition extends IPropertyDefinitionBase {
  dateOptions: IDateOptionDefinition
  multiSelectValues: IMultiSelectValue[]
  numberOptions: INumberOptions
  statusValues: IStatusValue[]
}

/* MULTI SELECT */

export interface IMultiSelect {
  id: number
  current: boolean
  createdAt: Date
  updatedAt: Date
  selectedValue: IMultiSelectValue
}

export interface IMultiSelectValue {
  id: number
  value: string
  createdAt: Date
  updatedAt: Date
}

/* STATUS */

export interface IStatus {
  id: number
  current: boolean
  createdAt: Date
  updatedAt: Date
  statusValue: IStatusValue
  statusValueId: number
}

export interface IStatusValue {
  id: number
  value: string
  category: string
  createdAt: Date
  updatedAt: Date
  color?: string
}

export enum IStatusCategoriesEnum {
  TODO = 'to-do',
  IN_PROGRESS = 'in-progress',
  COMPLETE = 'complete',
}

/* Number */
export interface INumber {
  id: number
  value: number
  current: boolean
  createdAt: Date
  updatedAt: Date
}

export interface INumberOptions {
  createdAt: Date
  format: string
  id: number
  updatedAt: Date
}

/* Date */

export interface IDate {
  id: number
  value: string
  current: boolean
  createdAt: Date
  updatedAt: Date
}

export interface IDateOptionDefinition {
  createdAt: Date
  dateFormat: string
  id: number
  timeFormat: string
  updatedAt: Date
}

/* Text */

export interface IText {
  id: number
  value: string
  current: boolean
  createdAt: Date
  updatedAt: Date
}

/* Person */

export interface IPerson {
  createdAt: Date
  current: boolean
  id: number
  updatedAt: Date
  user: IUserAttributes
  firstName: string
  lastName: string
  photo?: string
  userId: number
}

/* ============== END PROPERTY DEFINITIONS ============= */

export type PropertyCategory = [string, ITaskProperty]
export type PropertyCategories = PropertyCategory[]

export enum ILayoutsEnum {
  BOARD = 'board',
  LIST = 'list',
}
