import { useEffect, useState } from 'react'
import { JSONOp } from 'ot-json1'
import { SourceKeys } from 'interfaces/editor'
import { getBlockExpandedState, getBlockPageId, getIndex, type block } from '_entities/block'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'

interface Props {
  block: block
  isChatEmbed?: boolean
}

export const useEmbedExpansion = (props: Props) => {
  const [isEmbeddedDocumentExpanded, setIsEmbeddedDocumentExpanded] = useState<
    boolean | undefined
  >()
  const _json1 = useJson1()
  const _submit = useSubmit()

  const handleExpandedStateToggle = () => {
    const currentState = getBlockExpandedState(props.block)
    let op: JSONOp
    const index = getIndex(props.block)

    if (index === -1) return

    if (currentState !== undefined) {
      op = _json1.getReplaceBlockDataKeyOp(index, ['isEmbedExpanded'], !currentState, currentState)
    } else {
      op = _json1.getInsertKeyInDataKeyOp(index, ['isEmbedExpanded'], true)
    }

    _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
  }

  const handleEmbeddedDocumentMinimize = () => {
    setIsEmbeddedDocumentExpanded(false)
    if (props.isChatEmbed) return
    handleExpandedStateToggle()
  }

  const handleEmbeddedDocumentMaximize = () => {
    setIsEmbeddedDocumentExpanded(true)
    if (props.isChatEmbed) return
    handleExpandedStateToggle()
  }

  useEffect(() => {
    setIsEmbeddedDocumentExpanded(getBlockExpandedState(props.block))
  }, [props.block])

  return {
    isEmbeddedDocumentExpanded,
    handleEmbeddedDocumentMinimize,
    handleEmbeddedDocumentMaximize,
  }
}
