import { type block, IBlockTypes } from '_entities/block'
import { ReactComponent as TextBoldIcon } from 'assets/images/TextBold.svg'
import { ReactComponent as TextItalicIcon } from 'assets/images/TextItalic.svg'
import { ReactComponent as TextUnderlineIcon } from 'assets/images/TextUnderline.svg'
import { ReactComponent as TextStrikethroughIcon } from 'assets/images/TextStrikethrough.svg'
import { ReactComponent as AlignLeft } from 'assets/images/align-left.svg'
import { ReactComponent as AlignCenter } from 'assets/images/align-center.svg'
import { ReactComponent as AlignRight } from 'assets/images/align-right.svg'
import Quill from 'quill'

export const getNoBoldTags = (block?: block) => {
  return (
    block?.data.tag === IBlockTypes.TITLE ||
    block?.data.tag === IBlockTypes.XLARGE ||
    block?.data.tag === IBlockTypes.SUBHEADER ||
    block?.data.tag === IBlockTypes.LARGE ||
    block?.data.tag === IBlockTypes.MEDIUM ||
    block?.data.tag === IBlockTypes.SMALL
  )
}

export const textBlockOptions = (block: block) => {
  const quillElement = document.querySelector(`#quill-editor-${block._id}`)
  if (!quillElement) return []
  const editor: Quill = Quill.find(quillElement)
  return [
    {
      element: <TextBoldIcon opacity={getNoBoldTags(block) ? 0.5 : 1} />,
      onClick: () => {
        /* if (getNoBoldTags(block)) return
        surroundContents(FormatTypes.BOLD) */
        editor.format('bold', !editor.getFormat().bold, 'user')
      },
    },
    {
      element: <TextItalicIcon />,
      onClick: () => {
        editor.format('italic', !editor.getFormat().italic, 'user')
        /* surroundContents(FormatTypes.ITALIC) */
      },
    },

    {
      element: <TextUnderlineIcon />,
      onClick: () => {
        /* surroundContents(FormatTypes.UNDERLINE) */
        editor.format('underline', !editor.getFormat().underline, 'user')
      },
    },
    {
      element: <TextStrikethroughIcon />,
      onClick: () => {
        /* surroundContents(FormatTypes.STRIKETHROUGH) */
        editor.format('strike', !editor.getFormat().strike, 'user')
      },
    },
    {
      element: <AlignLeft />,
      onClick: () => {
        editor.format('align', '', 'user')
      },
    },
    {
      element: <AlignCenter />,
      onClick: () => {
        editor.format('align', 'center', 'user')
      },
    },
    {
      element: <AlignRight />,
      onClick: () => {
        editor.format('align', 'right', 'user')
      },
    },
  ]
}
