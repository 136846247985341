import { EmojiPartPickerStyles, StyledEmojiPicker } from './styles'
import { customEmojis } from './customEmojis'
import { Emoji } from '../model/types'

export interface EmojiPickerProps {
  onEmojiSelect: (emoji: Emoji) => void
}
export const EmojiPicker = (props: EmojiPickerProps) => {
  return (
    <EmojiPartPickerStyles>
      <StyledEmojiPicker
        onEmojiSelect={props.onEmojiSelect}
        autoFocus={true}
        set='apple'
        skinTonePosition='none'
        theme='light'
        navPosition='none'
        previewPosition='none'
        custom={customEmojis}
      />
    </EmojiPartPickerStyles>
  )
}
