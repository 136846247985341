import React, { useState } from 'react'
import { icons } from 'shared/icons'
import { block } from '_entities/block'
import { EmbedType } from '_entities/embed'
import { StyledEmbedIcon } from '../ui/styles'

export const useEmbedAddButton = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [link, setLink] = useState<string>('')

  const handleIsOpen = () => {
    setIsOpen(!isOpen)
  }

  const embedIcon = (block?: block) => {
    switch (block?.data.embed?.type) {
      case EmbedType.FIGMA:
        return <icons.figma />

      case EmbedType.GOOGLE_DOC:
        return <icons.googleDoc />

      case EmbedType.GOOGLE_SLIDES:
        return <icons.googleSlides />

      case EmbedType.GOOGLE_SHEETS:
        return <icons.googleSheets />
      case EmbedType.MIRO: {
        return <icons.miro width={'1em'} height={'1em'} />
      }
      case EmbedType.GENERIC_LINK: {
        return <StyledEmbedIcon width={'1em'} height={'1em'} />
      }
    }
  }

  const embedTitle = (block?: block) => {
    switch (block?.data.embed?.type) {
      case EmbedType.FIGMA:
        return 'Embed a Figma file'

      case EmbedType.GOOGLE_DOC:
        return 'Embed a Google Doc'

      case EmbedType.GOOGLE_SLIDES:
        return 'Embed a Google Slides'

      case EmbedType.GOOGLE_SHEETS:
        return 'Embed a Google Sheet'
      case EmbedType.MIRO: {
        return 'Embed a Miro board'
      }
      case EmbedType.GENERIC_LINK: {
        return 'Click to add embed'
      }
    }
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value)
  }

  return {
    isOpen,
    handleIsOpen,
    embedIcon,
    embedTitle,
    handleOnChange,
    link,
  }
}
