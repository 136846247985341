import React from 'react'
import { Link } from 'react-router-dom'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import Card from 'components/organisms/Card/Card'
import AuthWrapper from 'components/templates/AuthWrapper/AuthWrapper'
import CustomP from 'components/atoms/CustomP/CustomP'

const EmailVerified: React.FC = () => {
  return (
    <AuthWrapper
      elements={
        <Card
          titleText='Thanks, your email has been verified'
          secondaryText='You can proceed to setup'
          shadow
          radius
          fontSize
          cardElements={
            <Link className='text-primary' to='/signin'>
              <CustomButton
                className='btn w-50 d-inline-flex justify-content-center align-items-center'
                height='40px'
                background='var(--primary-color)'
                fontWeight='600'
              >
                <CustomP className='mb-0'>OK</CustomP>
              </CustomButton>
            </Link>
          }
        />
      }
    />
  )
}

export default EmailVerified
