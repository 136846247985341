import styled from 'styled-components'
import { ReactComponent as SendMessage } from 'assets/images/send-message.svg'
import { ReactComponent as EmojiPickerIcon } from 'assets/images/emoji-picker.svg'
import { ReactComponent as ClearMention } from 'assets/images/close.svg'

export const ClearMentionButton = styled(ClearMention)``

export const ClearMentionButtonWrapper = styled.div`
  cursor: pointer !important;
  position: absolute;
  border-radius: 7px;
  background-color: #fff;
  right: 4px;
  top: 4px;
  padding: 4px;
`

export const SendMessageButton = styled(SendMessage)`
  cursor: pointer;
  z-index: 1;
`

export const SelectedObjectWrapper = styled.div`
  border: solid 1px var(--secondary-text);
  border-radius: 8px;
  margin: 4px 4px -2px 2px;
  padding: 7px;
  display: inline-block;
  position: relative;
  top: 10px;
  left: 10px;
  font-size: 14px;
  font-weight: 500;
  width: 95%;
`

export const MentionContainer = styled.p<{ backgroundColor?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  padding: 5px;
  border-radius: 8px;
`

export const MentionName = styled.p<{ backgroundColor?: string }>`
  font-weight: 400;
  font-size: 14px;
  color: #333333;
  margin-bottom: 0;
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  & > span {
    font-weight: 400;
    font-size: 14px;
    color: #000;
    margin-bottom: 0;
  }
`

export const PageNameWrapper = styled.div<{ padding?: string; justifyContent?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent ?? 'flex-end'};
  gap: 8px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: ${({ padding }) => padding || '0'};
  color: var(--primary-text) !important;

  svg path {
    stroke: var(--primary-text);
    cursor: pointer;
  }
`

export const UploadFilesButtonWrapper = styled.button`
  border: none;
  background-color: transparent;
  z-index: 1;
  pointer-events: none;
  transform: scale(1.2);
`

export const EmojiPickerButton = styled(EmojiPickerIcon)`
  transform: scale(1.2);
`

export const InputFlatWrapper = styled.div`
  background: var(--sidebars);
`

export const InputFlat = styled.div`
  background: var(--sidebars);
`

export const InputTextAreaWrapper = styled.div`
  & .str-chat__suggestion-list-container {
    margin-bottom: 80px;
    width: 90%;
    margin-left: 10px;
    background: var(--sidebars);
  }
  position: relative;
  background: var(--sidebars);

  * {
    color: var(--secondary-text);
  }
`

export const EmojiSelectorWrapper = styled.div``

export const EmojiSelector = styled.span``

export const SelectedObjectImage = styled.img`
  width: 100%;
`

export const InputActionsWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: 0;
  width: 95%;
  height: 34px;
  background: var(--sidebars);
  bottom: 20px;
  left: 12px;
  border-radius: 0 0 8px 8px;
  border-bottom: solid 1px var(--secondary-text);
  border-left: solid 1px var(--secondary-text);
  border-right: solid 1px var(--secondary-text);
  justify-content: space-between;
  align-items: center;
  padding: 0 8px;
`

export const InputActionsIcons = styled.div`
  display: flex;
  gap: 12px;
`
