import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { setFilteredSpaceMembers } from 'redux/reducers/spaceReducer'
import { useState } from 'react'
import { IRoles } from 'interfaces/invite'

export const useSpaceMembers = () => {
  const dispatch = useAppDispatch()
  const spaceMembers = useAppSelector((state) => state.space.spaceMembers)
  const filteredSpaceMembers = useAppSelector((state) => state.space.filteredSpaceMembers)
  const sortedMembers = [...spaceMembers].sort((a) => (a.role === IRoles.OWNER ? -1 : 1))

  const [indexOfFullTextElement, setIndexOfFullTextElement] = useState<number>(-1)
  const [isFullEmailTextShown, setIsFullEmailTextShown] = useState<boolean>(false)
  const [isFullNameTextShown, setIsFullNameTextShown] = useState<boolean>(false)
  const [isUserTypingInInput, setIsUserTypingInInput] = useState<boolean>(false)

  const handleFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsUserTypingInInput(!!e.target.value.length)

    dispatch(
      setFilteredSpaceMembers(
        spaceMembers.filter(
          (spaceMember) =>
            spaceMember.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
            spaceMember.email.toLowerCase().includes(e.target.value.toLowerCase()),
        ),
      ),
    )
  }

  return {
    spaceMembers,
    filteredSpaceMembers,
    handleFilter,
    sortedMembers,
    isFullEmailTextShown,
    isFullNameTextShown,
    setIsFullEmailTextShown,
    setIsFullNameTextShown,
    isUserTypingInInput,
    indexOfFullTextElement,
    setIndexOfFullTextElement,
  }
}
