import { StyledChip } from './styles'

interface Props {
  children?: React.ReactNode
}

const DateChip = (props: Props) => {
  return <StyledChip>{props.children}</StyledChip>
}

export default DateChip
