import { SourceKeys } from 'interfaces/editor'
import { type block, BLOCKS_COPY_KEY } from '_entities/block'
import { useJson1 } from 'shared/shareDb/useJson1'
import copy from 'copy-to-clipboard'
import { useSubmit } from 'utils/shareDB/useSubmit'
import {
  removeSelectedNodesAttrs,
  getSelectedBlockNodes,
  getSelectedBlocks,
  getPageIdFromKeyboardEvent,
} from '_widgets/Document'
import { getBlocks } from 'shared/shareDb'

export const useMultipleSelection = () => {
  // ** Hooks
  const _submit = useSubmit()
  const _json1 = useJson1()

  const deleteMultipleBlocks = (pageId: string) => {
    const selectedBlockNodes = getSelectedBlockNodes(pageId)
    const blocks = getBlocks(pageId)
    if (selectedBlockNodes.length && blocks) {
      const firstBlockId = selectedBlockNodes[0].getAttribute('id')
      if (!firstBlockId) return
      const firstBlockIndex = blocks.findIndex((block) => block?._id === firstBlockId)
      firstBlockIndex &&
        selectedBlockNodes.forEach(() => {
          _submit.submit(pageId, _json1.deleteBlock(firstBlockIndex), SourceKeys.UPDATE_BLOCK)
        })
    }
  }

  const removeSelection = (pageId: string) => {
    removeSelectedNodesAttrs(pageId)
  }

  const clipboardConstructor = (selectedBlocks?: block[]) => {
    return JSON.stringify({
      [BLOCKS_COPY_KEY]: selectedBlocks,
    })
  }

  const pageMultipleSelectionHandler = (e: React.KeyboardEvent) => {
    const pageId = getPageIdFromKeyboardEvent(e)
    if (!pageId) return
    const selectedBlocks = getSelectedBlocks(pageId)
    if (!selectedBlocks) return
    if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'c') {
      copy(clipboardConstructor(selectedBlocks))
    }
    if ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === 'x') {
      copy(clipboardConstructor(selectedBlocks))
      deleteMultipleBlocks(pageId)
    }
    if (e.key === 'Backspace' || e.key === 'Delete') {
      deleteMultipleBlocks(pageId)
    }
  }

  return {
    pageMultipleSelectionHandler,
    removeSelection,
  }
}
