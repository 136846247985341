import styled from 'styled-components'

export const Form = styled.form`
  & > * {
    width: 100% !important;
  }

  &#forgotPasswordForm {
    & * {
      width: 100% !important;
    }

    .textBolder {
      font-weight: 600;
      margin-block: 2.5rem 0.5rem;
    }
  }

  &#resetPasswordForm {
    input {
      position: relative;
      top: 0.75rem;
    }

    img {
      z-index: 10;
    }

    button {
      border-radius: 10px;
      margin-top: 1rem;
      background-color: var(--primary-color);

      p {
        font-size: 16px !important;
        font-weight: 500;
      }
    }
  }
`
