import styled from 'styled-components'

export const ColorSettingsWrapper = styled.div`
  padding: 24px;
  height: auto;
  border-top: none;
`

export const StyledSubtitle = styled.p`
  color: var(--secondary-text);
  font-size: 14px;
`

export const StyledTitle = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  display: inline-flex;
  margin-bottom: 12px;
  color: var(--primary-text);
`

export const CreateNewPalletWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
`
