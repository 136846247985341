import { DefaultsType } from 'interfaces/settings'
import React from 'react'
import { ActionTitle, ActionWrapper, AddButtonWrapper } from './styles'

interface Props {
  item: any
  generateDefaultHeader: (item: string) => string
  handleShownModal: (defaultModal: DefaultsType) => void
}

const Default = (props: Props) => {
  return (
    <ActionWrapper>
      <ActionTitle>Default {props.generateDefaultHeader(props.item.label)}</ActionTitle>
      {!props.item.value?.name && (
        <AddButtonWrapper onClick={() => props.handleShownModal(props.item.label)}>
          Add
        </AddButtonWrapper>
      )}
    </ActionWrapper>
  )
}

export default Default
