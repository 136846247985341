import React, { ReactNode } from 'react'

import { Dropdown } from 'react-bootstrap'
import {
  DropdownItem,
  IconTextContainer,
  ItemSubtitle,
  ItemTitle,
  TitleSubtitleContainer,
  IconContainer,
} from './styles'

export type DropdownMenuProps = React.ComponentProps<typeof Dropdown.Menu> &
  Pick<React.CSSProperties, 'width'> &
  Pick<React.CSSProperties, 'maxHeight'>

// This is heavily based on Bootstrap's Dropdown.Menu component
// https://react-bootstrap.netlify.app/components/dropdowns/

const DropdownMenu = (props: DropdownMenuProps) => {
  const { children, width, maxHeight, ...restProps } = props

  return (
    <Dropdown.Menu
      style={{
        transition: 'none',
        borderRadius: 8 + 'px',
        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)',
        border: 'none',
        overflow: 'hidden',
        padding: '8px 0px',
        background: 'var(--overlay-modal)',
        width,
        maxHeight,
        overflowY: 'auto',
      }}
      {...restProps}
    >
      {children}
    </Dropdown.Menu>
  )
}

export type DropdownMenuItemProps = React.ComponentProps<typeof Dropdown.Item> & {
  children?: ReactNode

  title: string
  subtitle?: string
  icon?: ReactNode
  paddingBlock?: string
}

export const DropdownMenuItem = (props: DropdownMenuItemProps) => {
  const { children, title, subtitle, icon, paddingBlock, ...restProps } = props

  if (children)
    return (
      <Dropdown.Item {...restProps} as='div' style={{ cursor: 'pointer' }}>
        {children}
      </Dropdown.Item>
    )

  return (
    <DropdownItem title={title} {...restProps}>
      <IconTextContainer>
        <IconContainer>{icon}</IconContainer>
        <TitleSubtitleContainer>
          {title && <ItemTitle paddingBlock={paddingBlock}>{title}</ItemTitle>}
          {subtitle && <ItemSubtitle>{subtitle}</ItemSubtitle>}
        </TitleSubtitleContainer>
      </IconTextContainer>
    </DropdownItem>
  )
}

DropdownMenu.Item = DropdownMenuItem

export default DropdownMenu
