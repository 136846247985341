import CustomP from 'components/atoms/CustomP/CustomP'
import React, { useRef, useState } from 'react'
import { SelectWithDropdownAndButtonWrapper, SelectWithDropdownWrapper } from '../styles'
import SelectWithChips from 'components/organisms/SelectWithChipsInvites/SelectWithChipsInvites'
import CustomSelectDropdown from 'components/molecules/CustomSelectDropdown/CustomSelectDropdown'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import { getColor } from 'shared/colors'
import { MultiValue } from 'react-select'
import {
  IDropdownOption,
  IGeneralDropdownOption,
  IMultiValueOption,
  SuggestedSelectUser,
} from 'interfaces/invite'
import { useParams, useSearchParams } from 'react-router-dom'
import { AxiosService } from 'services/axiosService/axiosService'
import { useAppSelector } from 'redux/hooks'
import { useToast } from 'services/helpers/useToast'
import { Option } from 'interfaces/selectOptions'
import { Role } from 'interfaces/page'
import Spinner from 'react-bootstrap/Spinner'

const InviteByEmail = ({ handleUserInvited }: { handleUserInvited: () => void }) => {
  const pageRoles = useAppSelector((state) => state.page.pageRoles)?.filter(
    (role) => role.value !== Role.OWNER,
  )

  const [inviteEmails, setInviteEmails] = useState<MultiValue<IMultiValueOption>>([])
  const [inviteLoading, setInviteLoading] = useState<boolean>(false)
  const [permission, setEmailPermission] = useState<number | undefined>(
    pageRoles ? parseInt(pageRoles[0].value) : undefined,
  )

  const toast = useToast()
  const AxiosInstance = new AxiosService()
  const selectRef = useRef(null)
  const params = useParams()
  const [searchParams] = useSearchParams()
  const projectId = params.id
  const pageId = searchParams.get('pageId')

  const setInviteEmailsState = (value: MultiValue<IMultiValueOption>) => {
    setInviteEmails(value)
  }

  const getSuggestedUsers = async (value: string) => {
    if (projectId && pageId)
      return await AxiosInstance.getProjectFileMembers(parseInt(projectId), pageId, value)
  }

  const suggestedUsersPromise = async (value: string) => {
    const response = await getSuggestedUsers(value)
    if (response) {
      const suggestedOptions = response.map((elem: any) => {
        return {
          label: `${elem.user.firstName} ${elem.user.lastName}`,
          value: elem.user.email,
          id: elem.user.id,
        }
      })
      return new Promise<SuggestedSelectUser[]>((resolve) => {
        setTimeout(() => {
          resolve(suggestedOptions)
        }, 1000)
      })
    }
  }

  const updateEmailPermission = (option: Option) => {
    setEmailPermission(parseInt(option.value))
  }

  const onSubmit = async () => {
    if (pageId && permission) {
      setInviteLoading(true)
      const onlyEmails = inviteEmails.map((elem) => elem.value)
      const response = await AxiosInstance.inviteUserToPage(pageId, onlyEmails, permission, 'DSWK')
      if (response) {
        selectRef && selectRef.current && (selectRef.current as any).clearValue()
        toast.success('Invites sent successfully')
        setInviteLoading(false)
        handleUserInvited()
      } else {
        setInviteLoading(false)
      }
    }
  }

  return (
    <>
      <CustomP className='mb-0' color={getColor('--primary-text')}>
        Invite to page
      </CustomP>
      <SelectWithDropdownAndButtonWrapper>
        <SelectWithDropdownWrapper>
          <SelectWithChips
            selectRef={selectRef}
            setInviteEmailsState={setInviteEmailsState}
            suggestedUsersPromise={suggestedUsersPromise}
            width={'100%'}
          />
          {pageRoles && (
            <CustomSelectDropdown
              margin='0 1rem'
              options={pageRoles}
              defaultSelect={pageRoles[0].name}
              dropdownWidth={'138px'}
              onChange={updateEmailPermission}
            />
          )}
        </SelectWithDropdownWrapper>

        <CustomButton
          margin={'5px'}
          padding={'8px 20px'}
          width={'fit-content'}
          height={'36px'}
          className='d-flex justify-content-center align-items-center gap-2 border-0 position-relative'
          borderradius='8px'
          background={getColor('--primary-color')}
          onClickFunction={onSubmit}
        >
          {inviteLoading ? (
            <Spinner size='sm' variant='light' />
          ) : (
            <CustomP className='mb-0 text-white'>Send&nbsp;invite</CustomP>
          )}
        </CustomButton>
      </SelectWithDropdownAndButtonWrapper>
    </>
  )
}

export default InviteByEmail
