import { useState } from 'react'

export const useActionMenu = () => {
  const [actionMenuOpen, setActionMenuOpen] = useState<boolean>(false)
  const closeActionMenu = () => {
    setActionMenuOpen(false)
  }

  const openActionMenu = () => {
    setActionMenuOpen(true)
  }

  return {
    actionMenuOpen,
    setActionMenuOpen,
    openActionMenu,
    closeActionMenu,
  }
}
