import DropdownMenu from 'components/organisms/DropdownMenu/DropdownMenu'
import styled from 'styled-components'

export const TextFormattingDropdownMenuContainer = styled(DropdownMenu)`
  min-width: fit-content;
  padding: 0 !important;
  & > li > a {
    white-space: nowrap;
  }

  .dropdown-item:hover {
    background-color: var(--hover) !important;
  }
`

export const TextFormattingDropdownMenuBody = styled.div<{ bottomLine?: boolean }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 128px;
  width: auto;
  padding: 12px 8px;
`

export const TextFormattingButtonContainer = styled(DropdownMenu.Item)<{ active?: boolean }>`
  width: 28px;
  height: 28px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
`
