import { useAppSelector } from 'redux/hooks'
import { getById } from 'shared/lib'
import { DocumentBlockAttributes, getCtaButtonId } from '_entities/cta'
import { type block } from '_entities/block'

export const useCtaToolbar = () => {
  // ** Redux State
  const myRole = useAppSelector((state) => state.projectFile.myProjectFileRole)

  const setIsCtaEditing = (block: block) => {
    const button = getById(getCtaButtonId(block._id))

    if (button) {
      const isEditing = button.getAttribute(DocumentBlockAttributes.BUTTON_IS_EDITING) === 'true'

      if (isEditing) {
        button.removeAttribute(DocumentBlockAttributes.BUTTON_IS_EDITING)
      } else {
        button.setAttribute(DocumentBlockAttributes.BUTTON_IS_EDITING, 'true')
      }
    }
  }

  return {
    setIsCtaEditing,
    myRole,
  }
}
