import { IPropertyTypes, ITask } from 'interfaces/taskManager'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getShowProperties, setSelectedTaskId } from 'redux/reducers/taskManagerReducer'
import { useEffect, useMemo, useRef, useState } from 'react'
import { IPage } from 'interfaces/page'
import { getPage } from '_entities/page'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import useOnPageClick from 'utils/useOnPageClick'
import { type block } from '_entities/block'
import { useUpdateTask } from '_features/task'

export interface UseTaskProps {
  task: ITask
  isSlider?: boolean
  isSimpleView?: boolean
}

export const useTask = (props: UseTaskProps) => {
  const [page, setPage] = useState<IPage | null>(null)
  const { task } = props
  const _updateTask = useUpdateTask()

  const showProperties = useAppSelector(getShowProperties) || []
  const selectedTaskDocument = useAppSelector((state) => state.taskManager.selectedTaskDocument)
  const selectedTaskId = useAppSelector((state) => state.taskManager.selectedTaskId)
  const isSliderTaskOpen = useAppSelector((state) => state.taskManager.isSliderTaskOpen)
  const isSlider = useAppSelector((state) => state.taskManager.isSliderOpen)
  const showPropertiesIds = showProperties.map((property) => property.id)
  let filteredProperties =
    task?.properties?.filter((property) =>
      showPropertiesIds?.includes(property.propertyDefinition.id),
    ) || []

  if (props.isSimpleView) {
    filteredProperties = task?.properties || []
  }

  const block = task?.references?.map((reference) => reference?.block)[0]
  const dispatch = useAppDispatch()
  const params = useParams()
  const onPageClick = useOnPageClick()
  const projectFileId = params.id
  const inputRef = useRef<HTMLInputElement>(null)

  const statusProperty = filteredProperties?.find(
    (property) => property.propertyDefinition.type === IPropertyTypes.STATUS,
  )

  const assigneeProperty = filteredProperties?.find(
    (property) => property.propertyDefinition.type === IPropertyTypes.PERSON,
  )

  const dueDateProperty = filteredProperties?.find(
    (property) => property.propertyDefinition.type === IPropertyTypes.DATE,
  )

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('keydown', (event) => {
        if (event.key === 'Enter') {
          inputRef.current?.blur()
        }
      })
    }
  }, [inputRef.current])

  useEffect(() => {
    if (block) {
      projectFileId &&
        getPage(projectFileId, block.meta.pageId).then((page) => {
          setPage(page)
        })
    }
  }, [])

  const handleRenameTask = (event: React.ChangeEvent<HTMLInputElement>) => {
    debounceChange({ event })
  }

  const renameTask = (event: React.ChangeEvent<HTMLInputElement>) => {
    const valueBeforeCurrentKey = (event.target as HTMLInputElement).value
    _updateTask.updateTask({ taskId: task.id, title: valueBeforeCurrentKey })
  }

  const debounceChange = useMemo(
    () =>
      debounce((args: { event: React.ChangeEvent<HTMLInputElement> }) => {
        renameTask(args.event)
      }, 500),
    [],
  )

  const setIsTaskTitleRename = (event: React.MouseEvent) => {
    event.stopPropagation()
    inputRef.current?.select()
  }

  const minimizeTask = () => {
    dispatch(setSelectedTaskId(undefined))
  }

  const handleRedirectToBlock = (block: block) => {
    onPageClick(block.meta.pageId, block._id, block.meta?.spaceId, block.meta?.projectFileId)
  }

  return {
    statusProperty,
    assigneeProperty,
    dueDateProperty,
    handleRenameTask,
    setIsTaskTitleRename,
    selectedTaskDocument,
    selectedTaskId,
    isSliderTaskOpen,
    block,
    dispatch,
    page,
    inputRef,
    minimizeTask,
    handleRedirectToBlock,
    isSlider,
  }
}
