import CustomP from 'components/atoms/CustomP/CustomP'
import EditorUploadInput from '_features/upload/ui/EditorUploadInput'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import {
  StyledIcon,
  StyledItemNewPageHeader,
} from 'components/organisms/Dropdowns/EmbedDropdown/Option/styles'
import { icons } from 'shared/icons'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { PaletteColor } from 'components/molecules/PaletteColors/styles'
import { StyledColorPicker } from 'components/molecules/ColorPicker/styles'
import { getById } from 'shared/lib'
import { StyledInput } from '_entities/embed/InternalEmbed/ui/styles'
import {
  PageBackgroundImageSettings,
  SidebarDropdown,
  StyledTitle,
} from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/styles'
import { useUpdatePageBackground } from '../hooks'
import { Spinner } from 'react-bootstrap'

export const PageBackgroundEdit = () => {
  const _usePageBackgroundImageUpdate = useUpdatePageBackground()

  return (
    <PageBackgroundImageSettings>
      <SidebarDropdown>
        <StyledTitle>Page background image</StyledTitle>

        {_usePageBackgroundImageUpdate.loading ? (
          <Spinner size='sm' />
        ) : (
          <>
            {!_usePageBackgroundImageUpdate.backgroundImage ? (
              <EditorUploadInput
                type='file'
                id='page-image'
                onChange={(e) => {
                  _usePageBackgroundImageUpdate.handleFileUpload(e)
                }}
                label={
                  <Wrapper display={'flex'} alignItems={'center'} gap={'6px'} cursor={'pointer'}>
                    <StyledIcon>
                      <icons.upload />
                    </StyledIcon>
                    <StyledItemNewPageHeader>Upload</StyledItemNewPageHeader>
                  </Wrapper>
                }
              />
            ) : (
              <Wrapper
                display={'flex'}
                alignItems={'center'}
                gap={'6px'}
                cursor={'pointer'}
                padding='12px 0'
              >
                <StyledIcon>
                  <icons.close onClick={_usePageBackgroundImageUpdate.removeBackgroundImage} />
                </StyledIcon>
                {_usePageBackgroundImageUpdate.name.length > 15
                  ? `${_usePageBackgroundImageUpdate.name?.slice(0, 15)}...`
                  : `${_usePageBackgroundImageUpdate.name}`}
              </Wrapper>
            )}
          </>
        )}

        <StyledTitle>Page overlay settings</StyledTitle>

        <Wrapper
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          padding='8px 0px 6px 6px'
        >
          <CustomP fontWeight='500' fontSize='14px'>
            Overlay color
          </CustomP>
          <Wrapper display='flex' alignItems='center' gap='6px' margin='0px 0px 12px 0px'>
            <RadixDropdown
              trigger={
                <PaletteColor
                  size='20px'
                  color={
                    _usePageBackgroundImageUpdate.color
                      ? _usePageBackgroundImageUpdate.color
                      : '#ffffff'
                  }
                />
              }
            >
              <StyledColorPicker
                color={_usePageBackgroundImageUpdate.color}
                onChange={(e) => {
                  const documentBackground = getById(
                    `document-container-${_usePageBackgroundImageUpdate.page?.id}}`,
                  )

                  if (documentBackground) {
                    documentBackground.style.backgroundColor = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`
                  }
                  _usePageBackgroundImageUpdate.setColor(e.hex)
                }}
                onChangeComplete={(e) => {
                  _usePageBackgroundImageUpdate.updatePageBackground(
                    JSON.stringify({
                      backgroundImage: JSON.parse(
                        _usePageBackgroundImageUpdate.page?.backgroundImage || '{}',
                      ).backgroundImage,
                      overlayColor: e.hex,
                      overlayOpacity: `${e.rgb.a}`,
                    }),
                  )
                  _usePageBackgroundImageUpdate.setOpacity(e.rgb.a)
                }}
              />
            </RadixDropdown>
            <CustomP fontWeight='500' fontSize='14px'>
              {_usePageBackgroundImageUpdate.color
                ? _usePageBackgroundImageUpdate.color
                : '#ffffff'}
              , {_usePageBackgroundImageUpdate.opacity}
            </CustomP>
          </Wrapper>
        </Wrapper>

        <Wrapper
          display='flex'
          alignItems='center'
          justifyContent='space-between'
          padding='0px 0px 6px 6px'
        >
          <CustomP fontWeight='500' fontSize='14px'>
            Overlay blur
          </CustomP>
          <StyledInput
            value={_usePageBackgroundImageUpdate.blur}
            type='number'
            onChange={(e) => {
              _usePageBackgroundImageUpdate.onBlurChange(e)
            }}
          />
        </Wrapper>
      </SidebarDropdown>
    </PageBackgroundImageSettings>
  )
}
