import React from 'react'
import { StyledDropDownListContainer } from '../DropdownListContainer/styles'
import IconOption from '../IconOption/IconOption'
import { HrDivider } from 'components/atoms/HrDivider/HrDivider'
import { IDropdownOption } from 'interfaces/invite'
import { IconOptionContainer, StyledDropDownList } from './styles'
import { envVars } from 'config/env'
interface Props {
  options?: any[]
  fontsize?: string
  isOpen?: boolean
  onClickFunction?: (
    memberId: number,
    value: any,
    e: React.MouseEvent<HTMLLIElement, MouseEvent>,
  ) => void
  selectedOption?: IDropdownOption
  dropdownPadding?: string
  dropdownOptionPadding?: string
  paddingFirstChild?: string
  paddingLastChild?: string
  width?: string
  dropdownBottom?: string
  dropdownLeft?: string
  margindropdown?: string
  dropdownTop?: string
  dropdownRight?: string
  translateY?: string
  memberId?: number
}

export const DropdownActionList = ({
  options,
  fontsize,
  isOpen,
  onClickFunction,
  selectedOption,
  dropdownPadding,
  dropdownOptionPadding,
  paddingFirstChild,
  paddingLastChild,
  width,
  dropdownBottom,
  dropdownLeft,
  margindropdown,
  dropdownTop,
  dropdownRight,
  translateY,
  memberId,
}: Props) => {
  return (
    <StyledDropDownListContainer
      isOpen={isOpen}
      fontsize={fontsize}
      padding={dropdownPadding}
      width={width}
      bottom={dropdownBottom}
      left={dropdownLeft}
      top={dropdownTop}
      right={dropdownRight}
      margin={margindropdown}
      translatey={translateY}
      className={'dropdown'}
    >
      <StyledDropDownList>
        {options?.map((option, i) => (
          <IconOptionContainer key={i + option.name} isProfile={option.isProfile}>
            {option.borderTop && <HrDivider bordertop={'1px solid #F2F2F2'} />}
            <IconOption
              key={i}
              onClick={(e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                onClickFunction && onClickFunction(memberId as number, option, e)
              }}
              alignItems={option.alignItems}
              isProfile={option.isProfile}
              option={option.name}
              icon={option.icon && <option.icon />}
              color={option.color}
              padding={dropdownOptionPadding}
              paddingFirstChild={paddingFirstChild}
              paddingLastChild={paddingLastChild}
              src={option.src}
              alt={option.alt}
              userName={option.userName}
              userColor={option.userColor}
            />
          </IconOptionContainer>
        ))}
      </StyledDropDownList>
    </StyledDropDownListContainer>
  )
}
