import styled from 'styled-components'

export const CustomPopup = styled.div<{
  width?: string
  height?: string
  maxheight?: string
  left?: string
  top?: string
  right?: string
  bottom?: string
  active?: boolean
  justify?: string
  alignitems?: string
  flexdirection?: string
  padding?: string
  margin?: string
  zindex?: string
  aligntext?: string
  borderradius?: string
}>`
  display: flex;
  background: #403d3d;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  position: absolute;
  filter: drop-shadow(0px 0px 6px rgba(0, 0, 0, 0.25));
  transition: all 0.4s ease-in-out;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  max-height: ${(props) => (props.active ? `${props.maxheight}` : '')};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  opacity: ${(props) => (props.active ? '1' : '0')};
  visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
  justify-content: ${(props) => props.justify};
  align-items: ${(props) => props.alignitems};
  flex-direction: ${(props) => props.flexdirection};
  padding: ${(props) => props.padding};
  z-index: ${(props) => props.zindex};
  text-align: ${(props) => props.aligntext};
  border-radius: ${(props) => props.borderradius};
`
