import styled from 'styled-components'

import { ReactComponent as ExpandIcon } from 'assets/images/expand.svg'
import { ReactComponent as MinimizeIcon } from 'assets/images/minimize.svg'

export const StyledExpandIcon = styled(ExpandIcon)<{ opacity?: string }>`
  cursor: pointer;
  opacity: ${({ opacity }) => opacity || '1'};
  path {
    stroke: var(--primary-text);
  }
`

export const StyledMinimizeIcon = styled(MinimizeIcon)`
  cursor: pointer;
  path {
    stroke: var(--primary-text);
  }
`
