import React from 'react'
import { StyledDropdownItem } from './styles'

interface Props {
  element: React.ReactNode
  eventKey: number | string
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void
  dropdownItemDisplay?: string
  dropdownItemWidth?: string
  dropdownItemPadding?: string
  preventDefault?: boolean
  stopPropagation?: boolean
}

const DropdownItem = (props: Props) => {
  const { preventDefault = true, stopPropagation = true } = props
  return (
    <StyledDropdownItem
      onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        preventDefault && e.preventDefault()
        stopPropagation && e.stopPropagation()
        props.onClick?.(e)
      }}
      eventKey={props.eventKey}
      display={props.dropdownItemDisplay}
      width={props.dropdownItemWidth}
      padding={props.dropdownItemPadding}
    >
      {props.element}
    </StyledDropdownItem>
  )
}

export default DropdownItem
