import styled from 'styled-components'
import { ChromePicker } from 'react-color'
import { ReactComponent as CloseIcon } from 'assets/images/close.svg'

export const ColorPickerWrapper = styled.div<{ transform?: string }>`
  position: absolute;
  bottom: 0;
  transform: ${(props) => (props.transform ? props.transform : 'translateY(98%)')};
  left: -50%;
  background-color: var(--overlay-modal);
  box-shadow: 0 4px 4px 0 #94949440;
  padding: 8px;
  border: 1px solid var(--secondary-text);
  border-radius: 7px;
  z-index: 10;
`

export const StyledColorPicker = styled(ChromePicker)`
  border: none !important;
  box-shadow: revert !important;
`

export const ColorPickerHeader = styled.div`
  padding: 0 0 16px 0;
  display: flex;
  justify-content: space-between;
`

export const StyledCloseIcon = styled(CloseIcon)`
  height: 16px;
  width: 16px;
  cursor: pointer;
`
