import React, { CSSProperties, MouseEventHandler } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import styled, { css } from 'styled-components'
import { RadixDropdownFramer } from 'utils/framerConfig'
import { getColor } from 'shared/colors'

interface Props {
  open?: boolean
  trigger: React.ReactNode
  children: React.ReactNode
  onKeydown?: (e: React.KeyboardEvent) => void
  onMouseDown?: (e: React.MouseEvent) => void
  onOpenChange?: (e: boolean) => void
  triggerRef?: React.RefObject<HTMLButtonElement>
  menuRef?: React.RefObject<HTMLDivElement>
  disabled?: boolean
  overflow?: CSSProperties['overflow']
  flexDirection?: CSSProperties['flexDirection']
  height?: CSSProperties['height']
  isCentered?: boolean
}

export const IconButton = styled.button<{
  background?: string
  hoverBackground?: string
  isSelected?: boolean
  width?: string
  height?: string
  display?: string
  padding?: string
  transform?: string
}>`
  all: unset;
  font-size: 14px;
  font-family: inherit;
  display: ${(props) => props.display || 'flex'};
  align-items: center;
  gap: 4px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  border: 1.4px solid ${(props) => (props.isSelected ? 'var(--primary-color2)' : 'transparent')};
  padding: ${(props) => props.padding || 'var(--padding-4) var(--padding-4)'};
  border-radius: 1000px;
  justify-content: center;
  color: var(--primary-text);
  background-color: ${(props) => props.background || 'transparent'};
  transform: ${(props) => props.transform || 'none'};

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${() => props.hoverBackground || 'var(--hover)'};
        cursor: pointer;
      }
    `}
  &[data-state='open'] {
    background-color: var(--hover);
    border: 1.4px solid var(--primary-color2);
  }
`

export const DropdownMenuItem = styled(DropdownMenu.Item)<{
  align?: string
  noHover?: boolean
  padding?: CSSProperties['padding']
  selected?: boolean
  disabled?: boolean
}>`
  user-select: none;
  background-color: var(--overlay-modal);
  color: var(--primary-text);
  cursor: pointer;
  padding: ${(props) =>
    props.padding
      ? props.padding
      : css`
                    var(--padding-8) var(--padding-12)
                    `};
  display: flex;
  align-items: ${(props) => props.align || 'center'};
  gap: 6px;
  font-size: 14px;
  border: ${(props) => (props.selected ? '1px solid var(--everyday-blue)' : 'none')};
  border-radius: 10px;

  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};

  svg path {
    stroke: var(--primary-text);
  }

  ${(props) =>
    !props.noHover &&
    css`
      &:hover {
        background-color: ${getColor('--hover')} !important;
        opacity: 1;
        outline: none;
      }
    `}
`

export const BaseMenuStyles = css`
  background-color: var(--overlay-modal);
  border-radius: var(--border-radius-7);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
`

const DropdownMenuContent = styled(DropdownMenu.Content)<{
  overflow?: CSSProperties['overflow']
  flexDirection?: CSSProperties['flexDirection']
  height?: CSSProperties['height']
  isCentered?: boolean
}>`
  min-width: fit-content;
  ${BaseMenuStyles}
  z-index: 1000;
  overflow: ${(props) => props.overflow || 'hidden'};
  max-height: 90vh;
  display: flex;
  flex-direction: ${(props) => props.flexDirection || 'column'};
  max-height: ${(props) => props.height || 'fit-content'} !important;

  ${DropdownMenuItem}[data-highlighted]:focus-visible {
    outline: none !important;
    background: var(--everyday-blue);
  }
`

export const MenuTopText = styled.p`
  padding: var(--padding-8) var(--padding-12) 0 var(--padding-12);
  color: var(--gray-inactive);
  margin-bottom: 0;
  font-weight: 400;
`

export const RowMenuWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 144px;
  padding: var(--padding-8) var(--padding-8);
`

export const RowMenuItem = styled(DropdownMenu.Item)`
  width: 25%;
`

const RadixDropdown = (props: Props) => {
  return (
    <DropdownMenu.Root open={props.open} onOpenChange={props.onOpenChange}>
      <DropdownMenu.Trigger disabled={props.disabled} ref={props.triggerRef} asChild>
        {props.trigger}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenuContent
          ref={props.menuRef}
          {...RadixDropdownFramer}
          sideOffset={5}
          onKeyDown={props.onKeydown}
          onMouseDown={props.onMouseDown}
          overflow={props.overflow}
          flexDirection={props.flexDirection}
          height={props.height}
          isCentered={props.isCentered}
        >
          {props.children}
        </DropdownMenuContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default RadixDropdown
