import { getById, observeMutation } from 'shared/lib'
import { DocumentBlockAttributes } from '_entities/cta'

export const useObservationOnDocumentBlock = () => {
  const observeOnDocumentBlock = (
    id: string,
    attr: DocumentBlockAttributes,
    callback: () => void,
  ) => {
    const element = getById(id)
    if (!element) return
    return observeMutation(element, (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes' && mutation.attributeName === attr) {
          callback()
        }
      }
    })
  }

  return {
    observeOnDocumentBlock,
  }
}
