import { type block, getBlockTag, IBlockTypes } from '_entities/block'
import { getDoc } from 'shared/shareDb'

export const useSlideShow = () => {
  const isSlideShow = (block: block) => {
    return getBlockTag(block) === IBlockTypes.SLIDESHOW
  }

  const isFrame = (block: block) => {
    return getBlockTag(block) === IBlockTypes.FRAME
  }

  const isFrameInDoc = (pageId: string) => {
    const doc = getDoc(pageId)
    return doc?.data?.blocks?.find((block: block) => isFrame(block))
  }

  return {
    isSlideShow,
    isFrameInDoc,
  }
}
