import { ResizeArrows, ResizeArrow } from './styles'
import { type block, IBlockTypes, getBlockPageId, getIndex } from '_entities/block'
import { ReactComponent as ArrowUpSvg } from 'assets/images/block-toolbar/arrow-up.svg'
import { ReactComponent as ArrowDownSvg } from 'assets/images/block-toolbar/arrow-down.svg'
import Quill from 'quill'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'

interface Props {
  block: block
  onClick?: () => void
}

const TextResizer = (props: Props) => {
  const _json1 = useJson1()
  const _submit = useSubmit()
  const SizableTextTypes: { tag: IBlockTypes; value: number | false }[] = [
    { tag: IBlockTypes.TEXT, value: false },
    { tag: IBlockTypes.SMALL, value: 6 },
    { tag: IBlockTypes.MEDIUM, value: 5 },
    { tag: IBlockTypes.LARGE, value: 4 },
    { tag: IBlockTypes.XLARGE, value: 3 },
    { tag: IBlockTypes.SUBHEADER, value: 2 },
    { tag: IBlockTypes.TITLE, value: 1 },
  ]

  const getMinIndex = () => {
    return 0
  }

  const getMaxIndex = () => {
    return SizableTextTypes.length - 1
  }
  const getCurrentTextSizeIndex = () => {
    const type = props.block.data.tag
    return SizableTextTypes.findIndex((el) => el.tag === type)
  }

  const getMinCondition = () => {
    const currentIndex = getCurrentTextSizeIndex()
    return currentIndex !== getMinIndex()
  }

  const getMaxCondition = () => {
    const currentIndex = getCurrentTextSizeIndex()
    return currentIndex !== getMaxIndex()
  }

  const getSmallerText = () => {
    const currentIndex = getCurrentTextSizeIndex()
    if (getMinCondition()) {
      return SizableTextTypes[currentIndex - 1]
    }
  }

  const getLargerText = () => {
    const currentIndex = getCurrentTextSizeIndex()
    if (getMaxCondition()) {
      return SizableTextTypes[currentIndex + 1]
    }
  }

  const setTextUp = () => {
    const largerText = getLargerText()
    if (largerText) {
      headerFormatter(largerText.value)
      blockSetter(largerText.tag)
    }
  }

  const setTextDown = () => {
    const smallerText = getSmallerText()
    if (smallerText) {
      headerFormatter(smallerText.value)
      blockSetter(smallerText.tag)
    }
  }

  const headerFormatter = (value: number | false) => {
    const quillElement = document.querySelector(`#quill-editor-${props.block._id}`)
    if (quillElement) {
      const editor: Quill = Quill.find(quillElement)
      editor.format('header', value, 'user')
    }
  }

  const blockSetter = (tag: IBlockTypes) => {
    const index = getIndex(props.block)
    const op = _json1.getReplaceBlockDataKeyOp(index, ['tag'], tag, props.block.data.tag)
    _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
  }
  return (
    <ResizeArrows onClick={props.onClick}>
      <ResizeArrow onMouseDown={setTextUp} isDisabled={!getMaxCondition()}>
        <ArrowUpSvg />
      </ResizeArrow>
      <ResizeArrow onMouseDown={setTextDown} isDisabled={!getMinCondition()}>
        <ArrowDownSvg />
      </ResizeArrow>
    </ResizeArrows>
  )
}

export default TextResizer
