import React, { useEffect, useRef, useState } from 'react'
import { StyledPagePreview } from './styles'
import { EditorBlockInput } from 'editor/EditableBlock/styles'
import { type block } from '_entities/block'
import { Icon } from 'shared/icons'

export interface BasicCalloutProps {
  block: block
  title?: string
  isSelected?: boolean
  Icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null | string
  onClick?: () => void
  freshlyCreatedBlockPageId?: string | number
  inputOnKeydown?: (event: React.KeyboardEvent<HTMLInputElement>) => void
  inputOnBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
  isChatEmbed?: boolean
  shouldSliceText?: boolean
}

export const getCalloutId = (block: block) => {
  return `link-callout-${block._id}`
}

export const BasicCallout = (props: BasicCalloutProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  const [blockPageTitle, setBlockPageTitle] = useState(props.title)

  useEffect(() => {
    inputRef.current?.select()
  }, [props.freshlyCreatedBlockPageId])

  useEffect(() => {
    setBlockPageTitle(props.title)
  }, [props.title])

  const getTitle = () => {
    return blockPageTitle || props.title
  }

  const sliceTitle = (title?: string) => {
    if (!title) return
    if (title.length > 50) {
      return `${title.slice(0, 50)}...`
    } else {
      return title
    }
  }

  return (
    <StyledPagePreview
      id={getCalloutId(props.block)}
      isSelected={props.isSelected}
      onClick={props.onClick}
      isChatEmbed={props.isChatEmbed}
    >
      <Icon Icon={props.Icon} />

      {props.freshlyCreatedBlockPageId === props.block._id ? (
        <EditorBlockInput
          type='text'
          defaultValue={props.title}
          autoFocus
          onKeyDown={props.inputOnKeydown}
          onBlur={props.inputOnBlur}
          ref={inputRef}
          onChange={(e) => setBlockPageTitle(e.target.value)}
        />
      ) : props.shouldSliceText ? (
        sliceTitle(getTitle())
      ) : (
        getTitle()
      )}
    </StyledPagePreview>
  )
}
