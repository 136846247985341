import { IMultiValueOption } from 'interfaces/invite'
import { CSSObjectWithLabel, GroupBase, StylesConfig } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import styled from 'styled-components'

export const SelectWithInvitesStyles = (
  width?: string,
  border?: string,
  borderradius?: string,
  flexgrow?: string,
  minheight?: string,
  zIndex?: string,
): StylesConfig<IMultiValueOption, true, GroupBase<IMultiValueOption>> | undefined => {
  return {
    container: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      width: width,
      border: border,
      borderRadius: borderradius,
      fontSize: '14px',
      flexGrow: flexgrow,
    }),
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      border: 0,
      borderRadius: 16,
      boxShadow: 'none',
      minHeight: minheight,
    }),
    multiValue: (base: CSSObjectWithLabel) => ({
      ...base,
      backgroundColor: '#F4F6FF',
      borderRadius: '8px',
      maxWidth: '300px',
      overflowX: 'hidden',
      color: 'var(--white) !important',
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: 'var(--secondary-text) !important',
        zIndex: zIndex ? zIndex : '0',
      }
    },
  }
}

export const StyledAsyncCreatableSelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
  width: 100%;

  * {
    border-radius: 10px !important;
    background: var(--overlay-modal) !important;
    color: var(--secondary-text) !important;
  }
`
