import { useStage, useTransformer } from '_features/canvas'
import { useFullEmbed } from '_entities/embed'
import constants from 'whiteboard/constants'
import { AnchorPositions } from 'interfaces/whiteboard'
import { useAppSelector } from 'redux/hooks'
import { Circle } from 'react-konva'
import { getColor } from 'shared/colors'

export enum Anchors {
  TOP_LEFT = 'top-left',
  TOP_CENTER = 'top-center',
  TOP_RIGHT = 'top-right',
  MIDDLE_RIGHT = 'middle-right',
  MIDDLE_LEFT = 'middle-left',
  BOTTOM_LEFT = 'bottom-left',
  BOTTOM_RIGHT = 'bottom-right',
  BOTTOM_CENTER = 'bottom-center',
}

export const ALL_ANCHORS = [
  Anchors.TOP_LEFT,
  Anchors.TOP_CENTER,
  Anchors.TOP_RIGHT,
  Anchors.MIDDLE_RIGHT,
  Anchors.MIDDLE_LEFT,
  Anchors.BOTTOM_LEFT,
  Anchors.BOTTOM_CENTER,
  Anchors.BOTTOM_RIGHT,
]

export const HORIZONTAL_ANCHORS = ['middle-left', 'middle-right']
export const VERTICAL_ANCHORS = ['top-center', 'bottom-center']
export const DIAGONAL_ANCHORS = ['top-left', 'top-right', 'bottom-left', 'bottom-right']
export const TOP_DIAGONAL_ANCHORS = ['top-left', 'top-right']
export const BOTTOM_DIAGONAL_ANCHORS = ['bottom-left', 'bottom-right']
export const MIDDLE_ANCHORS = ['middle-left', 'middle-right', 'top-center', 'bottom-center']
export const TOP_ANCHORS = ['top-left', 'top-right', 'top-center']
export const BOTTOM_ANCHORS = ['bottom-left', 'bottom-right', 'bottom-center']
export const LEFT_ANCHORS = ['top-left', 'bottom-left', 'middle-left']
export const RIGHT_ANCHORS = ['top-right', 'bottom-right', 'middle-right']
export const TEXT_ANCHORS = [
  'top-left',
  'top-right',
  'bottom-left',
  'bottom-right',
  'middle-right',
  'middle-left',
]

export const TOP_ANCHOR_ID = 'connection-anchor-top'
export const RIGHT_ANCHOR_ID = 'connection-anchor-right'
export const BOTTOM_ANCHOR_ID = 'connection-anchor-bottom'
export const LEFT_ANCHOR_ID = 'connection-anchor-left'

export const ANCHOR_NAME = 'connection-anchor'
export const useAnchors = () => {
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const _stage = useStage()
  const _transformer = useTransformer()
  const _fullEmbed = useFullEmbed()
  const getAllowedAnchors = (pageId: string) => {
    const isOnlyFullEmbed = _fullEmbed.findIfOnlyFullEmbedInTransformer(pageId)
    if (isOnlyFullEmbed) {
      return ALL_ANCHORS
    } else return DIAGONAL_ANCHORS
  }

  const isAnchorVisible = (pageId: string) =>
    !!_transformer.getTransformerSize(pageId).width && !!selectedBlock

  const setAnchors = (pageId: string) => {
    const transformer = _transformer.getTransformer(pageId)
    if (transformer) {
      transformer.enabledAnchors(getAllowedAnchors(pageId))
    }
  }

  const getTopAnchor = (pageId: string) => {
    return {
      x: _transformer.getTransformerSize(pageId).width / 2,
      y: -(constants.ANCHOR_RADIUS + constants.ANCHOR_MARGIN),
    }
  }

  const getRightAnchor = (pageId: string) => {
    return {
      x:
        _transformer.getTransformerSize(pageId).width +
        constants.ANCHOR_RADIUS +
        constants.ANCHOR_MARGIN,
      y: _transformer.getTransformerSize(pageId).height / 2,
    }
  }

  const getBottomAnchor = (pageId: string) => {
    return {
      x: _transformer.getTransformerSize(pageId).width / 2,
      y:
        _transformer.getTransformerSize(pageId).height +
        constants.ANCHOR_RADIUS +
        constants.ANCHOR_MARGIN,
    }
  }

  const getLeftAnchor = (pageId: string) => {
    return {
      x: -(constants.ANCHOR_RADIUS + constants.ANCHOR_MARGIN),
      y: _transformer.getTransformerSize(pageId).height / 2,
    }
  }

  const getAnchors = (pageId: string): AnchorPositions => {
    return {
      top: getTopAnchor(pageId),
      right: getRightAnchor(pageId),
      bottom: getBottomAnchor(pageId),
      left: getLeftAnchor(pageId),
    }
  }

  const refreshAnchors = (pageId: string) => {
    const anchorPositions = getAnchors(pageId)
    const anchorNodes = getAnchorNodes(pageId)
    if (!anchorPositions || !anchorNodes) return
    anchorNodes.top.forEach((anchor) => {
      anchor.x(anchorPositions.top.x)
      anchor.y(anchorPositions.top.y)
    })
    anchorNodes.right.forEach((anchor) => {
      anchor.x(anchorPositions.right.x)
      anchor.y(anchorPositions.right.y)
    })
    anchorNodes.bottom.forEach((anchor) => {
      anchor.x(anchorPositions.bottom.x)
      anchor.y(anchorPositions.bottom.y)
    })
    anchorNodes.left.forEach((anchor) => {
      anchor.x(anchorPositions.left.x)
      anchor.y(anchorPositions.left.y)
    })
  }

  const getAnchorNodes = (pageId: string) => {
    const stage = _stage.getStage(pageId)
    if (stage)
      return {
        top: stage.find(`#${TOP_ANCHOR_ID}`),
        right: stage.find(`#${RIGHT_ANCHOR_ID}`),
        bottom: stage.find(`#${BOTTOM_ANCHOR_ID}`),
        left: stage.find(`#${LEFT_ANCHOR_ID}`),
      }
  }

  const getTopAnchorCircle = (pageId: string) => {
    return (
      <Circle
        visible={isAnchorVisible(pageId)}
        name={ANCHOR_NAME}
        id={TOP_ANCHOR_ID}
        x={getAnchors(pageId).top.x}
        y={getAnchors(pageId).top.y}
        fill={getColor('--hover')}
        stroke={getColor('--primary-color')}
        strokeWidth={1}
        radius={constants.ANCHOR_RADIUS}
      />
    )
  }

  const getRightAnchorCircle = (pageId: string) => {
    return (
      <Circle
        visible={isAnchorVisible(pageId)}
        name={ANCHOR_NAME}
        id={RIGHT_ANCHOR_ID}
        x={getAnchors(pageId).right.x}
        y={getAnchors(pageId).right.y}
        fill={getColor('--hover')}
        stroke={getColor('--primary-color')}
        strokeWidth={1}
        radius={constants.ANCHOR_RADIUS}
      />
    )
  }

  const getBottomAnchorCircle = (pageId: string) => {
    return (
      <Circle
        visible={isAnchorVisible(pageId)}
        name={ANCHOR_NAME}
        id={BOTTOM_ANCHOR_ID}
        x={getAnchors(pageId).bottom.x}
        y={getAnchors(pageId).bottom.y}
        fill={getColor('--hover')}
        stroke={getColor('--primary-color')}
        strokeWidth={1}
        radius={constants.ANCHOR_RADIUS}
      />
    )
  }

  const getLeftAnchorCircle = (pageId: string) => {
    return (
      <Circle
        visible={isAnchorVisible(pageId)}
        name={ANCHOR_NAME}
        id={LEFT_ANCHOR_ID}
        x={getAnchors(pageId).left.x}
        y={getAnchors(pageId).left.y}
        fill={getColor('--hover')}
        stroke={getColor('--primary-color')}
        strokeWidth={1}
        radius={constants.ANCHOR_RADIUS}
      />
    )
  }

  const renderAnchors = (pageId: string) => {
    return (
      <>
        {getTopAnchorCircle(pageId)}
        {getRightAnchorCircle(pageId)}
        {getBottomAnchorCircle(pageId)}
        {getLeftAnchorCircle(pageId)}
      </>
    )
  }

  return {
    isAnchorVisible,
    getAllowedAnchors,
    setAnchors,
    getAnchors,
    renderAnchors,
    refreshAnchors,
  }
}
