import React, { useState } from 'react'
import { passwordStrength } from 'check-password-strength'
import 'bootstrap/dist/css/bootstrap.css'
import Card from 'components/organisms/Card/Card'
import AuthWrapper from 'components/templates/AuthWrapper/AuthWrapper'
import ResetPasswordForm from 'components/organisms/ResetPasswordForm/ResetPasswordForm'
import { AxiosService } from 'services/axiosService/axiosService'
import { useNavigate } from 'react-router-dom'

const ResetPassword: React.FC = () => {
  const navigate = useNavigate()
  const REDIRECT_TIMEOUT = 3000

  const [passwordShown, setPasswordShown] = useState(false)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [passwordMismatchMessage, setPasswordMismatchMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [successMessage, setSuccessMessage] = useState('')
  const [AxiosInstance, setAxiosInstance] = useState(new AxiosService())

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  const checkIfPasswordsMatch = () => {
    if (password === confirmPassword) {
      setPasswordMismatchMessage('')
      return true
    }
    setPasswordMismatchMessage('Passwords do not match.')
    return false
  }

  const validateEmail = () => {
    if (email.length !== 0) {
      const re = // eslint-disable-next-line
        /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase()) ? true : false
    } else {
      return true
    }
  }

  const checkIfPasswordSafe = () => {
    if (password.length === 0) return true
    if (
      passwordStrength(password).value === 'Medium' ||
      passwordStrength(password).value === 'Strong'
    )
      return true
    else {
      return false
    }
  }

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    if (!checkIfPasswordSafe() || !checkIfPasswordsMatch()) return
    setError('')
    setSuccessMessage('')
    setLoading(true)
    const response = await AxiosInstance.resetPassword(email, password)
    if (response) {
      setSuccessMessage('Your password has been changed.')

      setTimeout(() => {
        navigate('/all-done', { replace: true })
      }, REDIRECT_TIMEOUT)
    } else setError('Password could not be changed. Check your credentials and try again.')
    setLoading(false)
  }

  return (
    <AuthWrapper
      elements={
        <Card
          titleText='Create new password'
          secondaryText='Type your email and new password below'
          subtext=' '
          alignLeft
          textBlack
          shadow
          radius
          fontSize
          isFontSmaller
          isTitleLeft
          cardElements={
            <ResetPasswordForm
              onSubmit={onSubmit}
              validateEmail={validateEmail}
              setEmail={setEmail}
              password={password}
              setPassword={setPassword}
              passwordShown={passwordShown}
              checkIfPasswordSafe={checkIfPasswordSafe}
              togglePassword={togglePassword}
              setConfirmPassword={setConfirmPassword}
              passwordMismatchMessage={passwordMismatchMessage}
              error={error}
              successMessage={successMessage}
              loading={loading}
            />
          }
        />
      }
    />
  )
}
export default ResetPassword
