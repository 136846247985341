import { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setSelectedObject } from 'redux/reducers/chatReducer'
import { useMessageInputContext } from 'stream-chat-react'
import convertToPlain from 'utils/editor/convertToPlain'
import { useTextBlock } from '_entities/text'
const useCustomMessageInput = () => {
  const selectedObject = useAppSelector((state) => state.chat.selectedObject)
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const _textBlock = useTextBlock()
  let selectedBlockText = selectedObject && convertToPlain(_textBlock.convertToHtml(selectedObject))

  // if the text is longer than 150 characters, truncate it
  if (selectedObject && convertToPlain(_textBlock.convertToHtml(selectedObject)).length > 150) {
    selectedBlockText = `${convertToPlain(_textBlock.convertToHtml(selectedObject)).substring(
      0,
      150,
    )}...`
  }

  // Hooks
  const dispatch = useAppDispatch()
  const {
    closeEmojiPicker,
    emojiPickerIsOpen,
    handleEmojiKeyDown,
    handleSubmit,
    openEmojiPicker,
    uploadNewFiles,
    numberOfUploads,
  } = useMessageInputContext()

  useEffect(() => {
    const textArea = document.querySelector(
      'textarea.str-chat__message-textarea',
    ) as HTMLTextAreaElement
    if (textArea) {
      textArea.setAttribute('placeholder', `Message #${selectedPage?.title}`)
    }
  }, [])

  const handleClearMention = () => {
    dispatch(setSelectedObject(null))
  }

  return {
    selectedObject,
    handleClearMention,
    closeEmojiPicker,
    emojiPickerIsOpen,
    handleEmojiKeyDown,
    handleSubmit,
    openEmojiPicker,
    uploadNewFiles,
    numberOfUploads,
    selectedPage,
    selectedBlockText,
  }
}

export default useCustomMessageInput
