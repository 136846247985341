import styled from 'styled-components'

export const ListItem = styled.li<{
  color?: string
  padding?: string
  paddingFirstChild?: string
  paddingLastChild?: string
  alignItems?: string
  fontWeight?: string
}>`
  display: flex;
  list-style: none;
  align-items: ${(props) => props.alignItems};
  cursor: pointer;
  padding: ${(props) => props.padding};
  border-radius: 8px;
  gap: 8px;
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight || ''};

  :first-child {
    padding: ${(props) => props.paddingFirstChild};
  }

  :last-child {
    padding: ${(props) => props.paddingLastChild};
  }

  &:hover {
    background: #f2f6ff;
  }
`

export const Visible = styled.div<{ isSelected?: boolean }>`
  opacity: ${(props) => (props.isSelected ? '1' : '0')};
  visibility: ${(props) => (props.isSelected ? 'visible' : 'hidden')};
  display: ${(props) => (props.isSelected ? 'flex' : 'none')};
  transition: visibility 0.4s ease-in-out, opacity 0.4s ease-in-out;
`
