import facebookIcon from './icons/facebook.svg'
import instagramIcon from './icons/instagram.svg'
import linkedInIcon from './icons/linkedin.svg'
import discordIcon from './icons/discord.svg'
import twitterIcon from './icons/twitter.svg'
import youtubeIcon from './icons/youtube.svg'
import wordpressIcon from './icons/wordpress.svg'
import figmaIcon from './icons/figma.svg'
import googleMeetIcon from './icons/google-meet.svg'

export const customEmojis = [
  {
    id: 'social-media',
    name: 'Social Media',
    emojis: [
      {
        id: 'facebook',
        name: 'Facebook',
        keywords: ['facebook', 'book'],
        skins: [{ src: facebookIcon }],
      },
      {
        id: 'instagram',
        name: 'Instagram',
        keywords: ['instagram', 'gram'],
        skins: [{ src: instagramIcon }],
      },
      {
        id: 'linkedin',
        name: 'LinkedIn',
        keywords: ['linkedin', 'in'],
        skins: [{ src: linkedInIcon }],
      },
      {
        id: 'twitter',
        name: 'Twitter',
        keywords: ['twitter', 'elon', 'musk'],
        skins: [{ src: twitterIcon }],
      },
      {
        id: 'youtube',
        name: 'YouTube',
        keywords: ['youtube', 'tube', 'google'],
        skins: [{ src: youtubeIcon }],
      },
    ],
  },
  {
    id: 'apps',
    name: 'Apps',
    emojis: [
      {
        id: 'figma',
        name: 'Figma',
        keywords: ['figma'],
        skins: [{ src: figmaIcon }],
      },
      {
        id: 'google-meet',
        name: 'Google Meet',
        keywords: ['google meet', 'meet'],
        skins: [{ src: googleMeetIcon }],
      },
      {
        id: 'discord',
        name: 'Discord',
        keywords: ['discord', 'chat'],
        skins: [{ src: discordIcon }],
      },
      {
        id: 'wordpress',
        name: 'WordPress',
        keywords: ['wordpress', 'press', 'cms'],
        skins: [{ src: wordpressIcon }],
      },
    ],
  },
]
