import { StreamChatGenerics } from 'interfaces/getstream/streamChatGenerics'
import { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  setIsEditing,
  setIsMessageThreadOpen,
  setObjectChatToView,
} from 'redux/reducers/chatReducer'
import { useGetStreamContext } from 'services/getstream/GetStreamContext'
import { UpdatedMessage } from 'stream-chat'
import {
  messageHasReactions,
  useChannelActionContext,
  useEditHandler,
  useMessageContext,
} from 'stream-chat-react'
import useOnPageClick from 'utils/useOnPageClick'
import { useChatDrawer } from '_widgets'
import { MessageResponseWithBlock } from 'interfaces/chat'
import { IPage } from 'interfaces/page'
import { useParams } from 'react-router-dom'
import { useGetPage, getPage } from '_entities/page'
import { type block, IBlockTypes } from '_entities/block'
import { generateNewDocumentBlock } from 'utils/editor/generateBlock'
import { getDocumentContainerId } from '_widgets/Document'
import { getById } from 'shared/lib'

const useCustomMessage = () => {
  // Hooks
  const dispatch = useAppDispatch()
  const { setEdit, clearEdit } = useEditHandler()
  const { chatClient } = useGetStreamContext()
  const { openThread, closeThread } = useChannelActionContext()
  const {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
    isMyMessage,
    actionsEnabled,
  } = useMessageContext()
  const { channelOptions } = useChatDrawer()

  // State
  const [editValue, setIsEditValue] = useState(message?.text)
  const [referencedBlock, setReferencedBlock] = useState<block | null>(null)
  const [referencedPage, setReferencedPage] = useState<IPage | null>(null)
  const [messageObject, setMessageObject] = useState<any>(message)
  const editing = useAppSelector((state) => state.chat.isEditing)
  const selectedChannel = useAppSelector((state) => state.chat.selectedChannel)
  const messagesWithBlock = useAppSelector((state) => state.chat.messagesWithBlock)
  const currentUser = useAppSelector((state) => state.global.user)
  const selectedPage = useAppSelector((state) => state.page.selectedPage)

  // Refs
  const messageWrapperRef = useRef(null)

  // Variables
  const onPageClick = useOnPageClick()
  const hasReactions = messageHasReactions(message)
  const hasAttachments = message?.attachments && message?.attachments.length > 0
  const url = window.location.href
  const regex = /\/spaces\/\d+\/project-file\/\d+\?pageId=[\w-]+/
  const extractedPart = url.match(regex)
  const params = useParams()
  const { getPageObjects } = useGetPage()
  const spaceId = params.spaceId

  useEffect(() => {
    setMessageObject(message)

    if (
      (message?.referencedBlock as any)?.blockId &&
      (message?.referencedBlock as any)?.tag !== IBlockTypes.EMBED &&
      (message?.referencedBlock as any)?.tag !== IBlockTypes.DOCUMENT &&
      (message?.referencedBlock as any)?.tag !== IBlockTypes.WHITEBOARD
    ) {
      fetchBlock(message as MessageResponseWithBlock)
    } else {
      if (
        (message?.referencedBlock as any)?.projectFileId &&
        (message?.referencedBlock as any)?.pageId
      ) {
        fetchPage(message as MessageResponseWithBlock)
      }
    }

    const pinActionElement = document.querySelector(
      `.str-chat__li[data-message-id="${message?.id}"] .str-chat__message-actions-list-item:nth-child(2)`,
    ) as HTMLElement

    const deleteThreadActionElement = document.querySelector(
      `.str-chat__li[data-message-id="${message?.id}"] .str-chat__message-actions-list-item:nth-child(4)`,
    ) as HTMLElement
    if (deleteThreadActionElement) {
      if (message?.reply_count === 0) {
        deleteThreadActionElement.style.display = 'none'
      } else {
        deleteThreadActionElement.style.display = 'block'
      }
    }

    if (pinActionElement) {
      if (message.pinned) {
        pinActionElement.innerHTML = 'Unpin'
      } else {
        pinActionElement.innerHTML = 'Pin'
      }
    }
  }, [message])

  useEffect(() => {
    if (messagesWithBlock && messagesWithBlock.length > 0) {
      messagesWithBlock && openThread(messagesWithBlock?.[0])
    }
  }, [messagesWithBlock])

  useEffect(() => {
    const messageUserMention = document.querySelectorAll(
      '.str-chat__message-mention',
    ) as NodeListOf<Element>

    const replyButton = document.querySelectorAll(
      '.str-chat__message-reply-in-thread-button',
    ) as NodeListOf<Element>

    replyButton.forEach((item: any) => {
      item.addEventListener('click', () => {
        if ((message as MessageResponseWithBlock).referencedBlock) {
          handleSetBlockToView(message)
        }
        dispatch(setIsMessageThreadOpen(true))
      })
    })

    messageUserMention.forEach((item: any) => {
      const dataUserId = item.getAttribute('data-user-id')

      if (dataUserId === currentUser?.uuid) {
        item.style.background = '#FFFF99'
      }
    })
  }, [])

  const fetchPage = async (message: MessageResponseWithBlock) => {
    const page = await getPage(
      `${message?.referencedBlock.projectFileId}`,
      `${message?.referencedBlock.pageId}`,
    )

    if (page && currentUser && currentUser.uuid) {
      const block = generateNewDocumentBlock(IBlockTypes.DOCUMENT, page, currentUser.uuid)

      setReferencedBlock(block)
      setReferencedPage(page)
    }
  }

  const fetchBlock = async (message: MessageResponseWithBlock) => {
    if (message?.referencedBlock && message.referencedBlock.projectFileId) {
      const block = await getPageObjects(
        `${message.referencedBlock.pageId}`,
        `${message.referencedBlock.blockId}`,
      )

      const page = await getPage(
        `${message.referencedBlock.projectFileId}`,
        `${message.referencedBlock.pageId}`,
      )

      if (page) {
        setReferencedPage(page)
      }

      if (block) {
        setReferencedBlock(block)
      }
    }
  }

  const handleSetBlockToView = (message: any) => {
    const block = document.getElementById(
      `quill-editor-${(message as MessageResponseWithBlock).referencedBlock?.blockId}`,
    ) as HTMLElement

    const documentContainer =
      selectedPage && (getById(getDocumentContainerId(selectedPage.id)) as HTMLElement)

    if (block && documentContainer) {
      documentContainer.scrollTo(0, block.offsetTop)
    }
    dispatch(setObjectChatToView(referencedBlock))
  }

  const handleOnEditSave = async () => {
    await chatClient?.updateMessage({
      ...(message as UpdatedMessage<StreamChatGenerics>),
      id: message.id,
      text: editValue,
    })
    dispatch(setIsEditing(null))
  }

  const handleEditClose = () => {
    dispatch(setIsEditing(null))
  }

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEditValue(e.target.value)
  }

  const handleOnClickSubject = async () => {
    onPageClick(
      messageObject?.referencedBlock.pageId,
      messageObject?.referencedBlock?.blockId,
      spaceId,
      messageObject?.referencedBlock?.projectFileId,
    )
  }

  const handleOpenThread = () => {
    openThread(message)
    if ((message as MessageResponseWithBlock).referencedBlock) {
      handleSetBlockToView(message)
    }
    dispatch(setIsMessageThreadOpen(true))
  }

  const handleCloseThread = () => {
    closeThread()
    dispatch(setIsMessageThreadOpen(false))
    dispatch(setObjectChatToView(null))
  }

  return {
    isReactionEnabled,
    message,
    reactionSelectorRef,
    showDetailedReactions,
    isMyMessage,
    actionsEnabled,
    handleOnChange,
    handleOnEditSave,
    messageWrapperRef,
    hasReactions,
    hasAttachments,
    editing,
    editValue,
    clearEdit,
    setEdit,
    handleEditClose,
    extractedPart,
    handleOnClickSubject,
    handleOpenThread,
    messageObject,
    selectedChannel,
    channelOptions,
    handleCloseThread,
    messagesWithBlock,
    referencedBlock,
    referencedPage,
  }
}

export default useCustomMessage
