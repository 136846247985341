import { type block } from '_entities/block'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { OverlayToggle } from './OverlayToggle'
import { BackgroundEdit } from './BackgroundEdit'
import { BorderColorEdit } from './BorderColorEdit'
import { BorderWeightEdit } from './BorderWeightEdit'
import { EmbedWidthEdit } from './EmbedWidthEdit'
import { EmbedHeightEdit } from './EmbedHeightEdit'
import { BorderRadiusEdit } from './BorderRadiusEdit'
import { EmbedBackgroundImageEdit } from './EmbedBackgroundImageEdit'
import { EmbedThumbnailImageEdit } from './EmbedThumbnnailImageEdit'
import { SlidesNavigationToggle } from './SlidesNavigationToggle'

export interface SidebarEditProps {
  block: block
}

export const EmbedEdit = (props: SidebarEditProps) => {
  return (
    <Wrapper>
      {/* Overlay setting */}
      <OverlayToggle {...props} />

      {/* Internal canvas embed border color setting */}
      <BorderColorEdit {...props} />

      {/* Internal canvas embed border weight setting */}
      <BorderWeightEdit {...props} />

      {/* Internal canvas embed border radius setting */}
      <BorderRadiusEdit {...props} />

      {/* Internal canvas embed background color setting */}
      <BackgroundEdit {...props} />

      {/* Internal canvas embed width setting */}
      <EmbedWidthEdit {...props} />

      {/* Internal canvas embed height setting */}
      <EmbedHeightEdit {...props} />

      {/* Internal canvas embed background image setting */}
      <EmbedBackgroundImageEdit {...props} />

      {/* Internal canvas embed thumbnail image setting */}
      <EmbedThumbnailImageEdit {...props} />

      {/* Slides navigation toggle */}
      <SlidesNavigationToggle {...props} />
    </Wrapper>
  )
}
