/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react'
import Card from 'components/organisms/Card/Card'
import { Row, Col } from 'react-bootstrap'
import AuthWrapper from 'components/templates/AuthWrapper/AuthWrapper'
import SignInForm from 'components/organisms/SignInForm/SignInForm'
import SocialAuth from 'components/organisms/SocialAuth/SocialAuth'
import DisplayCoverImage from 'components/templates/DisplayCoverImage/DisplayCoverImage'
import { useAuth } from 'services/auth/useAuth'
import { validateEmail } from 'utils/auth/validateEmail'
import { validatePassword } from 'utils/auth/validatePassword'
import { useNavigate } from 'react-router-dom'
import { MainWrapper } from 'views/styles'

const SignIn: React.FC = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const { signIn, isInvalidResponse } = useAuth()

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const response = await signIn(email, password)
    if (response) {
      navigate('/invitation-check')
      setIsLoading(false)
    }
    setIsLoading(false)
  }

  return (
    <MainWrapper>
      <DisplayCoverImage />

      <AuthWrapper
        text={'Create new'}
        linkPassed={'/forgotpassword'}
        // eslint-disable-next-line
        question={'Forgot password?'}
        elements={
          <Row className='d-flex justify-content-center align-items-center'>
            <Col>
              <Card
                textBlack
                alignLeft
                fontSize
                shadow
                radius
                titleText={'Log In'}
                subtext=' '
                cardElements={
                  <>
                    <SignInForm
                      onSubmit={onSubmit}
                      validateEmail={validateEmail}
                      validatePassword={validatePassword}
                      email={email}
                      password={password}
                      setEmail={setEmail}
                      setPassword={setPassword}
                      isLoading={isLoading}
                      isInvalidResponse={isInvalidResponse}
                    />
                    <SocialAuth text={'Sign In'}></SocialAuth>
                  </>
                }
              />
            </Col>
          </Row>
        }
      />
    </MainWrapper>
  )
}

export default SignIn
