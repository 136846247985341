import React, { MutableRefObject } from 'react'
import styled from 'styled-components'
import { StyledPCss } from './styles'

const StyledP = styled.p<Props>`
  ${StyledPCss}
`

type Props = {
  className?: string
  text?: string
  fontSize?: string
  children?: React.ReactNode
  letterSpacing?: string
  fontFamilySFPro?: boolean
  fontWeight?: string
  color?: string
  backgroundColor?: string
  translatey?: string
  paddingLeft?: string
  paddingRight?: string
  paddingTop?: string
  paddingBottom?: string
  marginBottom?: string
  marginLeft?: string
  marginRight?: string
  marginTop?: string
  cursorPointer?: boolean
  customCss?: string
  onClick?: (value: any) => void
  maxwidth?: string
  overflow?: string
  textoverflow?: string
  whiteSpace?: string
  borderradius?: string
  hoverbgcolor?: string
  position?: string
  top?: string
  left?: string
  onMouseDownCapture?: (value: any) => void
  userSelect?: string
  textTrunc?: number
  wordBreak?: string
  onMouseEnter?: React.MouseEventHandler
  onMouseLeave?: React.MouseEventHandler
  boxShadow?: string
  width?: string
  display?: string
  justifyContent?: string
  alignItems?: string
  gap?: string
  opacity?: string
  pointerEvents?: string
}

const CustomP: React.FC<Props> = ({
  text,
  className,
  fontSize,
  children,
  letterSpacing,
  fontFamilySFPro,
  fontWeight,
  color,
  backgroundColor,
  translatey,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  cursorPointer,
  customCss,
  onClick,
  maxwidth,
  overflow,
  textoverflow,
  whiteSpace,
  borderradius,
  hoverbgcolor,
  position,
  onMouseDownCapture,
  top,
  left,
  userSelect,
  textTrunc,
  wordBreak,
  onMouseEnter,
  onMouseLeave,
  boxShadow,
  width,
  display,
  justifyContent,
  alignItems,
  gap,
  opacity,
  pointerEvents,
}) => {
  return (
    <StyledP
      fontSize={fontSize}
      className={className}
      letterSpacing={letterSpacing}
      paddingLeft={paddingLeft}
      fontFamilySFPro={fontFamilySFPro}
      fontWeight={fontWeight}
      color={color}
      backgroundColor={backgroundColor}
      translatey={translatey}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      marginBottom={marginBottom}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginTop={marginTop}
      cursorPointer={cursorPointer}
      paddingRight={paddingRight}
      customCss={customCss}
      onClick={onClick}
      onMouseDownCapture={onMouseDownCapture}
      maxwidth={maxwidth}
      overflow={overflow}
      textoverflow={textoverflow}
      whiteSpace={whiteSpace}
      borderradius={borderradius}
      hoverbgcolor={hoverbgcolor}
      position={position}
      top={top}
      left={left}
      userSelect={userSelect}
      wordBreak={wordBreak}
      textTrunc={textTrunc}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      boxShadow={boxShadow}
      width={width}
      display={display}
      justifyContent={justifyContent}
      alignItems={alignItems}
      gap={gap}
      opacity={opacity}
      pointerEvents={pointerEvents}
    >
      {text}
      {children}
    </StyledP>
  )
}

export default CustomP
