import {
  getBlockPageId,
  type block,
  getBlockGridWidth,
  GridBlockWidth,
  getIndex,
} from '_entities/block'
import { JSONOp } from 'ot-json1'
import { SourceKeys } from 'interfaces/editor'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'

export const useBlockWidthEdit = () => {
  // ** Hooks
  const _submit = useSubmit()
  const _json1 = useJson1()

  const handleBlockWidthChange = (block: block, width: GridBlockWidth) => {
    const index = getIndex(block)
    let op: JSONOp

    if (index === -1) return

    if (getBlockGridWidth(block) !== undefined) {
      op = _json1.getReplaceBlockDataKeyOp(index, ['gridWidth'], width)
    } else {
      op = _json1.getInsertKeyInDataKeyOp(index, ['gridWidth'], width)
    }

    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  return {
    handleBlockWidthChange,
  }
}
