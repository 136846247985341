import { ITools } from 'interfaces/whiteboard'
import {
  useStage,
  StageAttrs,
  useTransformer,
  useZoom,
  useIntersection,
  ZOOM_DURATION,
  useCanvas,
  REFOCUS_DELAY,
  ZOOM_DURATION_OFFSET,
  useFrameColor,
  INodeZoomCalcConfig,
  useFrameBackgroundImage,
} from '_features/canvas'
import { block, getBlockPageId, IBlockTypes } from '_entities/block'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setTool } from 'redux/reducers/whiteboardReducer'
import { setIsPresentationMode } from 'redux/reducers/projectFileReducer'
import { usePage } from '_entities/page'
import { useInternalEmbedBlock, useWhiteboardEmbed } from '_entities/embed'
import { SIDEBAR_TOGGLE_DURATION } from '_entities/project'
import { useMemo } from 'react'
import { debounce } from 'lodash'
import { BeginPresentationProps } from './types'
import {
  getFrameSize,
  frames,
  gridSizes,
  GAP_SIZE,
  useFrame,
  useKonvaNode,
  getBorderColor,
  getBorderWidth,
  getFrame,
} from '_entities/whiteboard'
import { useCreateBlock, useDeleteBlock } from '_features/block'
import { Group } from 'konva/lib/Group'
import { Node, NodeConfig } from 'konva/lib/Node'
import {
  getIsPresentation,
  removeIsPresentation,
  setCurrentFrame,
  setIsPresentation,
} from './dom-attributes'
import { useInternalCanvasBorderColor, useInternalCanvasBorderWidth } from '_features/embed'

export const usePresentation = () => {
  const _stage = useStage()
  const _zoom = useZoom()
  const _transformer = useTransformer()
  const _page = usePage()
  const _internalEmbedBlock = useInternalEmbedBlock()
  const _intersection = useIntersection()
  const _canvas = useCanvas()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _createBlock = useCreateBlock()
  const _frame = useFrame()
  const _deleteBlock = useDeleteBlock()
  const dispatch = useAppDispatch()
  const _konvaNode = useKonvaNode()
  const _frameColor = useFrameColor()
  const _frameBackgroundImage = useFrameBackgroundImage()
  const _internalCanvasBorderColor = useInternalCanvasBorderColor()
  const _internalCanvasBorderWidth = useInternalCanvasBorderWidth()

  const page = useAppSelector((state) => state.page.selectedPage)
  const selectedDocBlock = useAppSelector((state) => state.page.selectedBlock)
  const selectedCanvasBlock = useAppSelector((state) => state.whiteboard.selectedBlock)

  // ======= FUNCTIONS =======

  const sortFramesByY = (pageId: string) => {
    const frameNodes = _stage.getAllFrameNodes(pageId)
    return frameNodes?.sort((first, second) => first.y() - second.y())
  }

  const getLastFrame = (pageId: string) => {
    const sortedByY = sortFramesByY(pageId)
    if (!sortedByY || sortedByY.length === 0) return
    return sortedByY?.[sortedByY.length - 1]
  }

  const getCurrentFrame = (pageId: string) => {
    const centerCoordinates = _canvas.getCenterCoordinates(pageId)
    if (!centerCoordinates) return
    const sortedFrames = sortFramesByY(pageId)
    return sortedFrames?.find((node) => {
      const frameRect = node.getClientRect()
      return _intersection.hasPointIntersection(centerCoordinates, frameRect)
    })
  }

  const getCurrentFrameIndex = (pageId: string) => {
    const centerCoordinates = _canvas.getCenterCoordinates(pageId)
    if (!centerCoordinates) return
    const sortedFrames = sortFramesByY(pageId)
    return sortedFrames?.findIndex((node) => {
      const frameRect = node.getClientRect()
      return _intersection.hasPointIntersection(centerCoordinates, frameRect)
    })
  }

  const focusFrame = (config: INodeZoomCalcConfig) => {
    const pageId = _konvaNode.getPageIdFromNode(config.node)
    if (!pageId) return
    _zoom.focusNode(config)

    const block = _stage.getBlockFromNode(pageId, config.node)
    if (block) {
      const borderColor = getBorderColor(block)
      const borderWidth = getBorderWidth(block)
      if (borderColor) {
        _internalCanvasBorderColor.updateInternalCanvasElementBorderColorInRealTime(
          pageId,
          borderColor,
        )
      } else {
        _internalCanvasBorderColor.updateInternalCanvasElementBorderColorInRealTime(pageId, '')
      }
      if (borderWidth) {
        _internalCanvasBorderWidth.updateInternalCanvasElementBorderWidthInRealTime(
          pageId,
          `${borderWidth}px`,
        )
      } else {
        _internalCanvasBorderWidth.updateInternalCanvasElementBorderWidthInRealTime(pageId, '0px')
      }
      const frame = getFrame(block)
      if (frame) {
        setCurrentFrame(pageId, frame)
      }
    }
  }

  const refocusCurrentFrame = (pageId: string) => {
    if (!getIsPresentation(pageId)) return
    const sortedByY = sortFramesByY(pageId)
    const currentFrameIndex = getCurrentFrameIndex(pageId)
    if (!sortedByY || currentFrameIndex === undefined) return
    let node: Node<NodeConfig> | undefined
    if (currentFrameIndex === -1) node = sortedByY[0]
    else node = sortedByY[currentFrameIndex]
    focusFrame({ node, shouldAnimate: true, animationLength: 0.2 })
  }

  const debounceRefocus = useMemo(() => debounce(refocusCurrentFrame, REFOCUS_DELAY), [])

  const previousSlide = (pageId: string) => {
    const sortedByY = sortFramesByY(pageId)
    const currentFrameIndex = getCurrentFrameIndex(pageId)
    if (!sortedByY || currentFrameIndex === undefined) return
    if (currentFrameIndex === 0) {
      focusFrame({ node: sortedByY[sortedByY.length - 1] })
    } else {
      focusFrame({ node: sortedByY[currentFrameIndex - 1] })
    }
  }

  const nextSlide = (pageId: string) => {
    const sortedByY = sortFramesByY(pageId)
    const currentFrameIndex = getCurrentFrameIndex(pageId)
    if (!sortedByY || currentFrameIndex === undefined) return
    if (currentFrameIndex === sortedByY.length - 1) {
      focusFrame({ node: sortedByY[0] })
    } else {
      focusFrame({ node: sortedByY[currentFrameIndex + 1] })
    }
  }

  // ======= ADDING NEW FRAME BELOW ========

  const addFrame = (pageId: string) => {
    const lastFrame = getLastFrame(pageId)
    if (!lastFrame) return
    const size = getFrameSize(lastFrame)
    _createBlock.createWhiteboardBlock({
      type: IBlockTypes.FRAME,
      frameSize: frames[size],
      pageId,
      position: {
        x: lastFrame.x(),
        y: lastFrame.y() + gridSizes[size].height + GAP_SIZE,
      },
    })
  }

  // ======= DELETING FRAME ========

  const deleteFrame = (pageId: string) => {
    const currentFrame = getCurrentFrame(pageId)
    if (!currentFrame) return
    const intersectingNodes = _frame.getBlocksIntersectingWithFrame(pageId, currentFrame)
    _deleteBlock.deleteNodes(pageId, [currentFrame as Group, ...(intersectingNodes as Group[])])
    nextSlide(pageId)
  }

  // ====== UPDATING FRAME COLOR ========

  const getCurrentFrameBlock = (pageId: string) => {
    const currentFrame = getCurrentFrame(pageId)
    if (!currentFrame) return
    return _stage.getBlockFromNode(pageId, currentFrame)
  }

  const updateFrameBackgroundColor = (pageId: string, rgba: string) => {
    const block = getCurrentFrameBlock(pageId)
    if (!block) return
    _frameColor.handleSelectColor(block, rgba)
  }

  const updateFrameBorderColor = (pageId: string, rgba: string) => {
    const block = getCurrentFrameBlock(pageId)
    if (!block) return
    _frameColor.handleSelectBorderColor(block, rgba)
  }

  const updateFrameBorderWidth = (pageId: string, width: number) => {
    const block = getCurrentFrameBlock(pageId)
    if (!block) return
    _frameColor.handleSelectBorderWidth(block, width)
  }

  const updateFrameBackgroundImage = (pageId: string, image: string) => {
    const block = getCurrentFrameBlock(pageId)
    if (!block) return
    _frameBackgroundImage.handleSetBackgroundImage(block, image)
  }

  const updateFrameThumbnailImage = (pageId: string, image: string) => {
    const block = getCurrentFrameBlock(pageId)
    if (!block) return
    _frameBackgroundImage.handleSetThumbnailImage(block, image)
  }

  // ======= PRESENTATION STOPPER LOGIC =======

  const stopPresentation = (pageId: string, isEmbed?: typeof StageAttrs.IS_EMBED) => {
    dispatch(setTool(ITools.CURSOR))
    removeIsPresentation(pageId)
    _zoom.zoomToFit({ pageId, shouldAnimate: true })
    setTimeout(() => {
      if (!isEmbed) dispatch(setIsPresentationMode(false))
    }, ZOOM_DURATION * 1000)
  }

  // ======= PRESENTATION STARTER LOGIC =======

  const beginPresentationFromBlock = (block: block, shouldAnimate?: boolean) => {
    const pageId = getBlockPageId(block)
    const sortedByY = sortFramesByY(pageId)
    if (!sortedByY || sortedByY.length === 0) return
    const blockIndexInArray = _stage.getIndexFromNodeArrayForBlock(sortedByY, block)
    checkTypeAndStart({ node: sortedByY[blockIndexInArray], shouldAnimate })
  }

  const beginPresentationFromHighestFrame = (pageId: string, shouldAnimate?: boolean) => {
    const sortedByY = sortFramesByY(pageId)
    if (!sortedByY || sortedByY.length === 0) return
    checkTypeAndStart({ node: sortedByY[0], shouldAnimate })
  }

  const checkTypeAndStart = (config: INodeZoomCalcConfig) => {
    const pageId = _konvaNode.getPageIdFromNode(config.node)
    if (!pageId) return
    if (!_whiteboardEmbed.getIsEmbed(pageId)) {
      dispatch(setIsPresentationMode(true))
      setTimeout(() => {
        focusFrame(config)
      }, SIDEBAR_TOGGLE_DURATION)
    } else focusFrame(config)
  }

  const beginPresentation = (props: BeginPresentationProps) => {
    const sortedByY = sortFramesByY(props.pageId)
    if (!sortedByY || sortedByY.length === 0) return
    _transformer.removeNodesFromTransformer(props.pageId)
    if (props.block) beginPresentationFromBlock(props.block, props.shouldAnimate)
    else beginPresentationFromHighestFrame(props.pageId, props.shouldAnimate)
    setTimeout(() => {
      setIsPresentation(props.pageId)
    }, ZOOM_DURATION * 1000 + ZOOM_DURATION_OFFSET)
  }

  const startEmbeddedPresentation = (block: block) => {
    if (!selectedDocBlock) return
    const blockPageId = _internalEmbedBlock.getBlockPageId(block)
    if (!blockPageId) return
    beginPresentation({ pageId: blockPageId })
  }

  const checkSelectedBlockAndStartPresentation = () => {
    if (!page) return
    if (
      _page.isSelectedPageDoc() &&
      selectedDocBlock &&
      _internalEmbedBlock.isInternalDocument(selectedDocBlock)
    ) {
      startEmbeddedPresentation(selectedDocBlock)
    } else {
      if (selectedCanvasBlock && _internalEmbedBlock.isInternalCanvas(selectedCanvasBlock)) {
        startEmbeddedPresentation(selectedCanvasBlock)
      } else beginPresentation({ pageId: page.id, shouldAnimate: true })
    }
  }

  return {
    getIsPresentation,
    setIsPresentation,
    removeIsPresentation,
    beginPresentation,
    getCurrentFrame,
    getCurrentFrameIndex,
    focusFrame,
    refocusCurrentFrame,
    debounceRefocus,
    previousSlide,
    nextSlide,
    addFrame,
    deleteFrame,
    getCurrentFrameBlock,
    updateFrameBackgroundColor,
    updateFrameBorderColor,
    updateFrameBorderWidth,
    updateFrameBackgroundImage,
    updateFrameThumbnailImage,
    stopPresentation,
    sortFramesByY,
    getLastFrame,
    checkSelectedBlockAndStartPresentation,
  }
}
