import { getById } from 'shared/lib'
import { TASKS_DRAWER_ID } from '../ui/TasksDrawer'

export const useTasksDrawerElement = () => {
  const getTasksElement = () => {
    return getById(TASKS_DRAWER_ID)
  }
  const getTasksRect = () => {
    return getTasksElement()?.getBoundingClientRect()
  }
  return {
    getTasksElement,
    getTasksRect,
  }
}
