import styled from 'styled-components'

export const StyledDropDownListContainer = styled.div<{
  isOpen?: boolean
  fontsize?: string
  padding?: string
  width?: string
  bottom?: string
  left?: string
  top?: string
  right?: string
  margin?: string
  translatey?: string
}>`
  display: flex;
  position: absolute;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  font-size: ${(props) => props.fontsize};
  color: #3a3940;
  background: #ffffff;
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  max-height: ${(props) => (props.isOpen ? '533px' : '0px')};
  width: ${(props) => props.width};
  transition: all 0.4s ease-in-out;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
  z-index: 9999999 !important;
  border-radius: 8px;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  transform: ${(props) => props.translatey && `translateY(${props.translatey})`};
`

export const StyledDropDownList = styled.ul`
  padding: 0;
  margin: 0;
  opacity: 1;
  background: var(--white);
  color: var(--main-black);
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
  word-break: break-all;
`
