import { Setting, SettingsKey } from 'interfaces/settings'

const useSettings = () => {
  const getSettingByKey = (settings: Setting[], key: SettingsKey) => {
    return settings.find((setting) => setting.key === key)
  }

  return {
    getSettingByKey,
  }
}

export default useSettings
