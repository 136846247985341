import { ITools } from 'interfaces/whiteboard'
import { OptionWrapper } from './option'
import { icons } from 'shared/icons'
import { IFrameOption, sizes } from '../model/types'
import { frames } from '../model/getters'

export const FrameOptions = (): IFrameOption[] => {
  return [
    {
      tool: ITools.FRAME_SIXTEEN_NINE,
      child: (
        <OptionWrapper>
          <icons.sixteenNine /> 16:9
        </OptionWrapper>
      ),
      ...frames[sizes.FRAME_SIXTEEN_NINE],
    },
    {
      tool: ITools.FRAME_FOUR_THREE,
      child: (
        <OptionWrapper>
          <icons.squareFrame /> 4:3
        </OptionWrapper>
      ),
      ...frames[sizes.FRAME_FOUR_THREE],
    },
    {
      tool: ITools.FRAME_ONE_ONE,
      child: (
        <OptionWrapper>
          <icons.squareFrame /> 1:1
        </OptionWrapper>
      ),
      ...frames[sizes.FRAME_ONE_ONE],
    },
    {
      tool: ITools.FRAME_CUS,
      child: (
        <OptionWrapper>
          <icons.crop /> Custom
        </OptionWrapper>
      ),
      ...frames[sizes.FRAME_CUSTOM],
    },
  ]
}
