import CustomP from 'components/atoms/CustomP/CustomP'
import {
  SelectWithDropdownAndButtonWrapper,
  SelectWithDropdownWrapper,
} from 'components/templates/PageInviteModal/styles'
import { InputLink } from '../styles'
import CustomSelectDropdown from 'components/molecules/CustomSelectDropdown/CustomSelectDropdown'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import LinkOptions from 'components/molecules/LinkOptions/LinkOptions'
import copy from 'copy-to-clipboard'
import { useProjectInviteLink } from '../useProjectInviteLink'
import { useAppSelector } from 'redux/hooks'
import { getColor } from 'shared/colors'
import CopyIcon from 'assets/images/file_copy.svg'
import { useState } from 'react'

const InviteByLink = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { updateLinkDuration, updateLinkPermission, inviteLink, generateNewLink } =
    useProjectInviteLink()
  const userRoles = useAppSelector((state) => state.projectFile.userRoles)

  return (
    <>
      <CustomP className='mb-0' color={getColor('--secondary-text')}>
        Invite to project by link
      </CustomP>
      <SelectWithDropdownAndButtonWrapper>
        <SelectWithDropdownWrapper>
          <InputLink readOnly value={inviteLink} />
          <CustomSelectDropdown
            margin='0 1rem'
            options={userRoles}
            defaultSelect={'Can Edit'}
            dropdownWidth={'138px'}
            onChange={updateLinkPermission}
            backgroundColour='var(--overlay-modal)'
            color={getColor('--primary-text')}
          />
        </SelectWithDropdownWrapper>

        <CustomButton
          margin={'5px'}
          padding={'8px 20px'}
          width={'107px'}
          height={'36px'}
          className='d-flex justify-content-center align-items-center gap-2 border-0 position-relative'
          borderradius='8px'
          background={getColor('--primary-color')}
          onClickFunction={() => {
            copy(inviteLink)
          }}
        >
          <CustomIcon src={CopyIcon} alt='Copy' />
          <CustomP className='mb-0 text-white d-flex'>Copy&nbsp;link</CustomP>
        </CustomButton>
      </SelectWithDropdownAndButtonWrapper>
      <LinkOptions
        onChange={updateLinkDuration}
        onClick={generateNewLink}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )
}

export default InviteByLink
