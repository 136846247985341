import { PageNameWrapper } from 'components/organisms/ChatCustomMessageInput/styles'
import { MessagePage } from 'interfaces/chat'
import { ReactComponent as DocumentIcon } from 'assets/images/file-description.svg'
import { ReactComponent as WhiteboardIcon } from 'assets/images/chalkboard.svg'
import { IPage } from 'interfaces/page'
import { StyledExpandIcon, StyledMinimizeIcon } from 'components/molecules/TaskTitle/styles'

interface IProps {
  page: IPage | MessagePage | null
  padding?: string
  shouldShowExpand?: boolean
  isExpanded?: boolean
  toggleExpand?: () => void
  justifyContent?: string
}

export const PageName = (props: IProps) => {
  return (
    <PageNameWrapper padding={props.padding} justifyContent={props.justifyContent}>
      {props.shouldShowExpand && (
        <>
          {props.isExpanded ? (
            <StyledMinimizeIcon
              onClick={(e) => {
                e.stopPropagation()
                props.toggleExpand && props.toggleExpand()
              }}
            />
          ) : (
            <StyledExpandIcon
              onClick={(e) => {
                e.stopPropagation()
                props.toggleExpand && props.toggleExpand()
              }}
            />
          )}
        </>
      )}

      <div>
        {props.page?.icon ? (
          props.page?.icon
        ) : props.page?.type === 'document' ? (
          <DocumentIcon />
        ) : (
          <WhiteboardIcon />
        )}{' '}
        {props.page?.title}
      </div>
    </PageNameWrapper>
  )
}
