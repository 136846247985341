import { useTransformer } from '_features/canvas'
import { Node } from 'konva/lib/Node'
import { IS_FULL_EMBED } from '../lib/constants'

export const useFullEmbed = () => {
  const _transformer = useTransformer()

  const findIfOnlyFullEmbedInTransformer = (pageId: string): Node | undefined => {
    const nodes = _transformer.getTransformerNodes(pageId)
    const filtered = nodes?.filter((node) => {
      return node.getAttr(IS_FULL_EMBED)
    })
    if (filtered && filtered.length === 1) {
      return filtered[0]
    }
  }

  const findIfFullEmbedInTransformer = (pageId: string): Node | undefined => {
    const nodes = _transformer.getTransformerNodes(pageId)
    return nodes?.find((node) => {
      return node.getAttr(IS_FULL_EMBED)
    })
  }

  const findIfFullEmbedAmongstMutlipleNodes = (pageId: string) => {
    const nodes = _transformer.getTransformerNodes(pageId)
    const fullEmbed = nodes?.find((node) => {
      return node.getAttr(IS_FULL_EMBED)
    })
    return nodes && nodes.length && nodes.length > 1 && fullEmbed
  }

  return {
    findIfOnlyFullEmbedInTransformer,
    findIfFullEmbedInTransformer,
    findIfFullEmbedAmongstMutlipleNodes,
  }
}
