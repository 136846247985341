import styled from 'styled-components'

export const InputLink = styled.input`
  border: none;
  width: 100%;
  padding: 3.5px 8px;
  background: transparent;
  font-size: 14px;
  height: 34px;
  color: var(--primary-text);

  &:focus {
    border: none;
    outline: none;
  }
`
