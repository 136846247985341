import { css } from 'styled-components'

export const StyledMainDiv = css<{ shadow?: boolean; radius?: boolean }>`
  box-shadow: ${(props) => (props.shadow ? '0px 1px 4px 0px rgba(0, 0, 0, 0.20)' : 'none')};
  border-radius: ${(props) => (props.radius ? '8px' : 'none')};
  background-color: var(--white);
  width: min(100%, 550px);
  padding-inline: 2.5rem;

  @media (max-width: 768px) {
    padding-inline: 1rem;
    margin-inline: 0 !important;
  }
`

export const StyledCustomeH2 = css`
  font-size: 38px;
  text-align: left;
  line-height: 32px;
  margin-block: 0.5rem 2rem;

  &.smallerFont {
    font-size: 21px;
    margin-top: 0.5rem;
    position: relative;
    top: 0.5rem;
  }
`

export const StyledSubText = css<{ textBlack?: boolean; alignLeft?: boolean; fontSize?: boolean }>`
  font-size: ${(props) => (props.fontSize ? '16px' : 'none')};
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  margin-bottom: mb-3;
  color: ${(props) => (props.textBlack ? 'text-dark' : 'gray')};
`

export const StyledSpanCursor = css`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    text-underline-offset: 3px;
  }
`
