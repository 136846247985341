import React, { useEffect, useState } from 'react'
import { AxiosService } from 'services/axiosService/axiosService'
import useLocalStorage from 'shared/lib/useLocalStorage'
import { useToast } from 'services/helpers/useToast'
import { useAppSelector } from 'redux/hooks'

export const useMyProfileTabUserModal = () => {
  const toast = useToast()
  const user = useAppSelector((state) => state.global.user)
  const [uploadPhotoModalOpen, setUploadPhotoModalOpen] = useState<boolean>(false)
  const [editMenuOpen, setEditMenuOpen] = useState<boolean>(false)
  const [genders, setGenders] = useState<[]>([])
  const [updatedUser, setUpdatedUser] = useState<any>(user)
  const [updatedUserName, setUpdatedUserName] = useState<string>(
    updatedUser ? updatedUser.firstName + ' ' + updatedUser.lastName : '',
  )
  const [genderId, setGenderId] = useState<number>(0)
  const [age, setAge] = useState<number>(user?.age ? user.age : 0)
  const [location, setLocation] = useState<string>(user?.location ? user.location : '')
  const [aboutMe, setAboutMe] = useState<string>(user?.aboutMe ? user.aboutMe : '')
  const axiosService = new AxiosService()
  const ageArray = Array.from(Array(100).keys())
  const { setItem } = useLocalStorage()
  const MAX_ABOUT_ME_LENGHT = 450

  const mainModal = document.getElementsByClassName('userModalMaxWidth')

  const onShowChangeModal = () => {
    mainModal[0]?.classList.add('userModalHideForOtherModals')
  }

  const onCloseChangePasswordModal = () => {
    setUploadPhotoModalOpen(false)
    mainModal[0]?.classList.remove('userModalHideForOtherModals')
  }

  const updateUserInfoAsync = async () => {
    await axiosService.updateUserInfo({
      genderId: genderId,
      age: age,
      location: location,
      aboutMe: aboutMe,
      firstName: updatedUser.firstName,
      lastName: updatedUser.lastName,
    })
    const result = await axiosService.getMe()
    if (result) {
      setUpdatedUser(result)
    }
  }

  const getAllGendersForSelect = async () => {
    const result = await axiosService.getAllGenders()

    if (result) {
      setGenders(result)
    }
  }

  const getUpdatedUsers = async () => {
    const result = await axiosService.getMe()
    if (result) {
      setUpdatedUser(result)
      setUpdatedUserName(updatedUser ? updatedUser.firstName + ' ' + updatedUser.lastName : '')
    }
  }

  const removeUserImage = () => {
    axiosService
      .deleteImage(updatedUser.id)
      .then((response) => {
        response?.data && setUpdatedUser((prev: any) => ({ ...prev, photo: null }))
        toast.success('Your profile image has been removed.')
      })
      .catch((e) => {
        toast.error(e.response.data.errors[0].message)
      })
  }

  const onChangeAboutMeArea = (e: React.ChangeEvent<any>) => {
    if (e.currentTarget.value.length <= MAX_ABOUT_ME_LENGHT) {
      setAboutMe(e.currentTarget.value)
    } else {
      setAboutMe(aboutMe)
    }
  }

  const onSaveUpdatedUser = async (e: React.ChangeEvent<any>) => {
    e.preventDefault()
    setEditMenuOpen(false)
    await updateUserInfoAsync()
  }

  const onImageError = () => {
    setUpdatedUser((prev: any) => ({
      ...prev,
      photo: null,
    }))
  }

  useEffect(() => {
    getUpdatedUsers()
    getAllGendersForSelect()
  }, [])

  return {
    onSaveUpdatedUser,
    uploadPhotoModalOpen,
    setUploadPhotoModalOpen,
    editMenuOpen,
    setEditMenuOpen,
    genders,
    updatedUser,
    setGenderId,
    age,
    setAge,
    setLocation,
    aboutMe,
    setAboutMe,
    ageArray,
    updateUserInfoAsync,
    onShowChangeModal,
    onCloseChangePasswordModal,
    updatedUserName,
    onChangeAboutMeArea,
    removeUserImage,
    onImageError,
  }
}
