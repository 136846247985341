import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { AxiosService } from 'services/axiosService/axiosService'
import copy from 'copy-to-clipboard'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import CustomP from 'components/atoms/CustomP/CustomP'
import Share from 'assets/images/person_add.svg'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import Pencil from 'assets/images/pencilLeftSidebar.svg'
import Duplicate from 'assets/images/content_copy.svg'
import CopyLink from 'assets/images/link.svg'
import DeleteRedIcon from 'assets/images/deleteRed.svg'
import PlusIcon from 'assets/images/Plus.svg'
import useOnPageClick from 'utils/useOnPageClick'
import { StyledWrapper, IconTextWrapper } from '../ui/PageReference/styles'
import { useUpdatePage } from '_features/page'
import { icons } from 'shared/icons'
import { checkIfFile, checkIsPdfLink } from '_entities/embed/ExternalEmbed/model/EmbedRegex'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import useFileUpload from '_entities/resources/model/useFileUpload'
import { useEmbed, useGoogle } from '_entities/embed'
import { usePageSummarization, UsePageReferenceProps } from '_entities/page'
import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { useOnClickOutside } from 'usehooks-ts'
import useDoubleClick from 'utils/useDoubleClick'
import { SidebarItemNewPage } from '_features/page/addPage'
import { useToast } from 'services/helpers/useToast'
import { SummarizationCategory } from 'services/ai/useAi'

export const getPageReferenceId = (id: string) => {
  return `page-reference-${id}`
}

export const usePageReference = (props: UsePageReferenceProps) => {
  const [deletePageLoading, setDeletePageLoading] = useState<boolean>(false)
  const [copyLink, setCopyLink] = useState<string>(window.location.href)
  const [hovered, setIsHovered] = useState<boolean>(false)
  const [renameInputPosition, setRenameInputPosition] = useState<number>(0)
  const [subpageModalPosition, setSubpageModalPosition] = useState<number>(0)
  const [isSubpageModeOn, setIsSubpageModeOn] = useState<boolean>(false)
  const [isShown, setIsShown] = useState<boolean>(false)
  const [pageName, setPageName] = useState<string>('')
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const freshlyCreatedPageId = useAppSelector((state) => state.projectFile.freshlyCreatedPageId)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const onPageClick = useOnPageClick()
  const params = useParams()
  const _updatePage = useUpdatePage()
  const AxiosInstance = new AxiosService()
  const _getProjectFile = useGetProjectFile()
  const _fileUpload = useFileUpload()
  const _embed = useEmbed()
  const _pageSummarization = usePageSummarization()
  const _google = useGoogle()
  const toast = useToast()

  const offsetToTop = -20

  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const changeGoogleName = async () => {
    if (
      (props.node.text?.toLowerCase().includes('google') ||
        props.node.text?.toLowerCase().includes('untitled')) &&
      props.node.embedUrl
    ) {
      const fileName = await _google.fetchFileName(props.node.embedUrl)
      _updatePage.updateTitleAndDoc(fileName || props.node.text, props.node.id.toString())
    }
  }
  useEffect(() => {
    changeGoogleName()
  }, [])

  const deletePage = async (pageId: string) => {
    if (params.spaceId && params.id) {
      setDeletePageLoading(true)
      const deletePageResponse = await AxiosInstance.deleteProjectFilePage(
        params.spaceId,
        parseInt(params.id),
        pageId,
      )

      if (deletePageResponse) {
        _getProjectFile.getAndDispatchProjectFilePages(params.id)
        navigate(`/spaces/${params.spaceId}/project-file/${params.id}`)
      }
      setDeletePageLoading(false)
    }
  }

  const handlePageNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPageName(event.target.value)
  }

  const duplicatePage = async (pageId: string) => {
    if (!projectFile) return
    const response =
      projectFile &&
      (await AxiosInstance.copyPageToProjectFile(
        pageId,
        parseInt(projectFile.id),
        parseInt(projectFile.id),
      ))

    if (response) {
      await _getProjectFile.getAndDispatchProjectFilePages(projectFile.id.toString())
    }
  }

  const calculateRenameInputPosition = () => {
    const pageReferenceRect = ref.current?.getBoundingClientRect()
    setTimeout(() => {
      inputRef.current?.select()
    }, 100)
    if (pageReferenceRect) {
      setRenameInputPosition(pageReferenceRect?.top + pageReferenceRect.height * 0.7)
    } else setRenameInputPosition(0)
  }

  const handleDownloadFileFromUrl = async () => {
    await _fileUpload.downloadFile(props.node.embedUrl as string, props.node.text)
  }

  const dropdownOptions = [
    {
      element: (
        <SidebarItemNewPage
          parentPageId={props.node.id}
          isScrollable
          toggleButton={
            <StyledWrapper>
              <CustomIcon src={PlusIcon} alt='Share' />
              <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px'>
                New subpage
              </CustomP>
            </StyledWrapper>
          }
        />
      ),
      id: 1,
      onClick: () => void 0,
      eventKey: 1,
    },
    {
      element: (
        <Wrapper
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          styles={'&:hover {background: var(--hover)}'}
          borderradius='8px'
          padding='0 8px'
        >
          <CustomIcon src={Share} alt='Share' />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px'>
            Share
          </CustomP>
        </Wrapper>
      ),
      id: 2,
      onClick: () => void 0,
      eventKey: 2,
    },
    {
      element: (
        <Wrapper
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          styles={'&:hover {background: var(--hover)}'}
          borderradius='8px'
          padding='0 8px'
          position='relative'
        >
          <CustomIcon src={Pencil} alt='Pencil' />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px'>
            Rename
          </CustomP>
        </Wrapper>
      ),
      id: 3,
      onClick: () => calculateRenameInputPosition(),
      eventKey: 3,
    },
    {
      element: (
        <Wrapper
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          styles={'&:hover {background: var(--hover)}'}
          borderradius='8px'
          padding='0 8px'
        >
          <CustomIcon src={Duplicate} alt='Duplicate' />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px'>
            Duplicate
          </CustomP>
        </Wrapper>
      ),
      id: 4,
      onClick: () => {
        if (params.id) {
          duplicatePage(props.node.id as string)
        }
      },
      eventKey: 4,
    },
    {
      element: (
        <Wrapper
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          styles={'&:hover {background: var(--hover)}'}
          borderradius='8px'
          padding='0 8px'
        >
          <CustomIcon src={CopyLink} alt='Copy link' />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px'>
            Copy Link
          </CustomP>
        </Wrapper>
      ),
      id: 5,
      onClick: () => {
        const splitLink = copyLink.split('=')
        const partOfLinkWeNeed = splitLink[0]
        const wantedCopyLink = partOfLinkWeNeed + `=${props.node.id}`
        setCopyLink(wantedCopyLink)
        toast.success('Link copied to clipboard')
        copy(wantedCopyLink)
      },
      eventKey: 5,
    },
    {
      element: (
        <Wrapper
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          styles={'&:hover {background: var(--hover)}'}
          borderradius='8px'
          padding='0 8px'
        >
          <icons.arrowDoubleForward />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px'>
            Make a copy in...
          </CustomP>
        </Wrapper>
      ),
      id: 6,
      onClick: () => {
        const page = pages?.find((page) => page.id === props.node.id)
        if (page) {
          props.handleMoveModalOpen && props.handleMoveModalOpen(page)
        }
      },
      eventKey: 6,
    },
    {
      element: (
        <>
          <RadixDropdown
            trigger={
              <IconTextWrapper>
                <icons.summarize width={20} height={20} />
                <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px'>
                  Summarize
                </CustomP>
              </IconTextWrapper>
            }
          >
            <DropdownMenuItem
              onClick={() =>
                _pageSummarization.summarize(props.node.id.toString(), SummarizationCategory.LEGAL)
              }
            >
              <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
                {' '}
                <icons.legal width={20} height={20} /> Legal
              </Wrapper>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                _pageSummarization.summarize(
                  props.node.id.toString(),
                  SummarizationCategory.PRODUCT,
                )
              }
            >
              <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
                {' '}
                <icons.product width={20} height={20} /> Product
              </Wrapper>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                _pageSummarization.summarize(
                  props.node.id.toString(),
                  SummarizationCategory.COPYWRITING,
                )
              }
            >
              <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
                {' '}
                <icons.copywriting width={20} height={20} /> Copywriting
              </Wrapper>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                _pageSummarization.summarize(
                  props.node.id.toString(),
                  SummarizationCategory.FINANCE,
                )
              }
            >
              <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
                {' '}
                <icons.finance width={20} height={20} /> Finance
              </Wrapper>
            </DropdownMenuItem>
            <DropdownMenuItem
              onClick={() =>
                _pageSummarization.summarize(
                  props.node.id.toString(),
                  SummarizationCategory.DEFAULT,
                )
              }
            >
              <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
                {' '}
                <icons.summarize width={20} height={20} /> Staffing
              </Wrapper>
            </DropdownMenuItem>
          </RadixDropdown>
        </>
      ),
      id: 7,
      onClick: () => void 0,
      eventKey: 7,
    },
    {
      element: (
        <Wrapper
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          styles={'&:hover {background: var(--hover)}'}
          borderradius='8px'
          padding='0 8px'
        >
          <CustomIcon src={DeleteRedIcon} alt='Delete page' />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px'>
            Delete Page
          </CustomP>
        </Wrapper>
      ),
      id: 8,
      onClick: () => deletePage(props.node.id.toString()),
      eventKey: 8,
    },
  ]

  if (
    props.node.embedUrl &&
    checkIfFile(props.node.embedUrl as string) &&
    !checkIsPdfLink(props.node.embedUrl as string)
  ) {
    dropdownOptions.splice(2, 0, {
      element: (
        <Wrapper
          display='flex'
          justifyContent='flex-start'
          alignItems='center'
          styles={'&:hover {background: var(--hover)}'}
          borderradius='8px'
          padding='0 8px'
        >
          <icons.download />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px'>
            Download file
          </CustomP>
        </Wrapper>
      ),
      id: 6,
      onClick: () => handleDownloadFileFromUrl(),
      eventKey: 6,
    })
  }

  const handleRenamePage = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      setRenameInputPosition(0)
      const value = (event.target as HTMLInputElement).value
      if (value && projectFile && projectFile.id) {
        _updatePage.updateTitleAndDoc(value, props.node.id.toString())
      }
    } else if (event.key === 'Escape') {
      setRenameInputPosition(0)
    }
  }

  const handleOnOutsideClick = () => {
    if (projectFile && projectFile.id) {
      _updatePage.updateTitleAndDoc(pageName || props.node.text, props.node.id.toString())
    }
  }

  useOnClickOutside(inputRef, () => handleOnOutsideClick())

  const handleOnSingleClick = async () => {
    if (props.isViewOnly) {
      onPageClick(props.node.id as string, '', '', props.node?.projectFileId)
    } else if (props.node.embedUrl && _embed.isDownload(props.node.embedUrl)) {
      const response = await _fileUpload.downloadFile(props.node.embedUrl, props.node.text)

      if (!response) {
        onPageClick(props.node.id as string, '', '', props.node?.projectFileId)
        /* window.open(props.node.embedUrl, '_blank') */
      }
    } else {
      if (props.node.embedUrl?.startsWith('pageId')) {
        const pageId = props.node.embedUrl

        onPageClick(pageId, '', '', props.node?.projectFileId)
        return
      }
      onPageClick(props.node.id as string)
    }
  }

  const handlePageClick = useDoubleClick({
    onSingleClick: () => handleOnSingleClick(),
    onDoubleClick: () => calculateRenameInputPosition(),
  })

  const handleOnIconClick = () => {
    props.setEmojiNodeElement((emojiNodeElement) => {
      if (ref.current && !emojiNodeElement)
        return {
          node: props.node,
          element: ref.current,
        }
      return undefined
    })
  }

  return {
    ref,
    onPageClick,
    selectedPage,
    deletePageLoading,
    deletePage,
    setIsHovered,
    hovered,
    dispatch,
    dropdownOptions,
    renameInputPosition,
    setRenameInputPosition,
    calculateRenameInputPosition,
    // Embed
    subpageModalPosition,
    setSubpageModalPosition,
    isSubpageModeOn,
    setIsSubpageModeOn,
    isShown,
    setIsShown,
    offsetToTop,
    handleRenamePage,
    inputRef,
    handlePageNameChange,
    pageName,
    downloadFile: _fileUpload.downloadFile,
    isSummaryLoading: _pageSummarization.isLoading,
    freshlyCreatedPageId,
    handlePageClick,
    handleOnSingleClick,
    handleOnIconClick,
  }
}
