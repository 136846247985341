import React from 'react'
import { type block, IBlockTypes, TextBlockTypes, INDENTATION } from '_entities/block'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { NumberInList } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/Source/styles'
import { displayBulletSign } from 'editor/EditableBlock/EditorTextBlock/helpers/displayBulletSign'
import convertToPlain from 'utils/editor/convertToPlain'
import { useTextBlock } from '_entities/text'

interface IProps {
  block: block
}

const SourceText = (props: IProps) => {
  const _textBlock = useTextBlock()
  return (
    <>
      {props.block?.data.tag && Object.values(TextBlockTypes).includes(props.block.data.tag) && (
        <Wrapper margin={'0 12px 12px 12px'} display='flex' gap='4px'>
          {props.block.data.tag === IBlockTypes.NUMBERED_LIST && (
            <NumberInList>{props.block.data.numberInList}.</NumberInList>
          )}

          {props.block.data.tag === IBlockTypes.CHECKLIST &&
            displayBulletSign(props.block.data.indent ? props.block.data.indent / INDENTATION : 0)}

          {convertToPlain(_textBlock.convertToHtml(props.block)).length > 75
            ? `${convertToPlain(_textBlock.convertToHtml(props.block)).slice(0, 75)}...`
            : `${convertToPlain(_textBlock.convertToHtml(props.block))}`}
        </Wrapper>
      )}
    </>
  )
}

export default SourceText
