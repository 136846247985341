import React, { ReactNode } from 'react'
import { ArrowDownIcon, DropDownContainer, DropDownHeader, DropDownHeaderText } from './styles'
import { useCustomSelectDropdown } from './useCustomSelectDropdown'
import { DropdownListContainer } from '../DropdownListContainer/DropdownListContainer'

type Props = {
  width?: string
  headerTextMaxWidth?: string
  fontsize?: string
  textFontsize?: string
  fontWeight?: string
  options?: any[]
  margin?: string
  padding?: string
  height?: string
  borderHeader?: string
  focusedBorder?: string
  headerPadding?: string
  defaultSelect?: string
  dropdownWidth?: string
  onChange?: (value: any) => void
  dropdownBottom?: string
  dropdownLeft?: string
  dropdownIconOptionPadding?: string
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>
  dropdownTop?: string
  overflowxHeaderText?: string
  dropdownRight?: string
  optionsInSelectPadding?: string
  fontWeightForOptions?: string
  icon?: ReactNode
  backgroundColour?: string
  color?: string
}

const CustomSelectDropdown: React.FC<Props> = ({
  width,
  headerTextMaxWidth,
  fontsize,
  textFontsize,
  fontWeight,
  options,
  margin,
  padding,
  height,
  borderHeader,
  focusedBorder,
  headerPadding,
  defaultSelect,
  dropdownWidth,
  onChange,
  dropdownBottom,
  dropdownLeft,
  dropdownIconOptionPadding,
  onMouseEnter,
  onMouseLeave,
  dropdownTop,
  overflowxHeaderText,
  dropdownRight,
  optionsInSelectPadding,
  fontWeightForOptions,
  icon,
  backgroundColour,
  color,
}) => {
  const { isOpen, selectedOption, toggling, onOptionClicked } = useCustomSelectDropdown({
    onChange,
  })

  return (
    <DropDownContainer
      width={width}
      margin={margin}
      padding={padding}
      maxheight={height}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      backgroundColour={backgroundColour}
      color={color}
    >
      <DropDownHeader
        fontWeight={fontWeight}
        onClick={toggling}
        focusedBorder={focusedBorder}
        isOpen={isOpen}
        headerPadding={headerPadding}
        pending={defaultSelect === 'Pending' ? true : false}
      >
        <DropDownHeaderText maxWidth={headerTextMaxWidth} fontSize={textFontsize}>
          {selectedOption ? selectedOption.name : defaultSelect}
        </DropDownHeaderText>

        {icon ? (
          <div style={{ marginLeft: 8 + 'px' }}>{icon}</div>
        ) : (
          <ArrowDownIcon isOpen={isOpen} />
        )}
      </DropDownHeader>
      <DropdownListContainer
        fontsize={fontsize}
        options={options}
        defaultSelect={defaultSelect}
        isOpen={isOpen}
        onClickFunction={onOptionClicked}
        selectedOption={selectedOption}
        dropdownWidth={dropdownWidth}
        dropdownBottom={dropdownBottom}
        dropdownIconOptionPadding={dropdownIconOptionPadding}
        dropdownRight={dropdownRight}
        optionsInSelectPadding={optionsInSelectPadding}
        fontWeightForOptions={fontWeightForOptions}
      />
    </DropDownContainer>
  )
}
export default CustomSelectDropdown
