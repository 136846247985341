import { useEffect, useState } from 'react'
import { AxiosService } from 'services/axiosService/axiosService'
import { useAppSelector } from 'redux/hooks'
import useLocalStorage, { LocalStorageKeys } from 'shared/lib/useLocalStorage'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { GetGoogleResponseType } from '_features/embed'
import { AccessType } from './types'
import { useGoogle } from './useGoogle'

interface IProps {
  embedUrl: string
}

export const useGoogleEmbed = (props: IProps) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [hasAccess, setHasAccess] = useState<AccessType | null>(null)
  const [user, setUser] = useState<GetGoogleResponseType | null>(null)

  const _localStorage = useLocalStorage()
  const _google = useGoogle()
  const _projectFile = useGetProjectFile()
  const AxiosInstance = new AxiosService()

  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const page = useAppSelector((state) => state.page.selectedPage)

  const token = _localStorage.getParsedItem(LocalStorageKeys.GOOGLE_TOKEN)

  useEffect(() => {
    handleCheckIfHasAccess()
  }, [])

  const fetchUser = async () => {
    const token = _localStorage.getParsedItem(LocalStorageKeys.GOOGLE_TOKEN)

    if (token) {
      const response = await _google.fetchUserInfo(token)
      setUser(response)
    }
  }

  const handleCheckIfHasAccess = async () => {
    setLoading(true)
    const token = _localStorage.getParsedItem(LocalStorageKeys.GOOGLE_TOKEN)
    const filePublicAndHasAccess = await _google.checkAccess(
      props.embedUrl.includes('?usp=sharing') ? props.embedUrl : `${props.embedUrl}?usp=sharing`,
      token,
    )

    if (token) {
      fetchUser()
    }

    if (
      filePublicAndHasAccess === AccessType.PUBLIC ||
      filePublicAndHasAccess === AccessType.USER ||
      filePublicAndHasAccess === AccessType.LINK_OWNER
    ) {
      setHasAccess(filePublicAndHasAccess)

      if (filePublicAndHasAccess) {
        const fileName = await _google.fetchFileName(props.embedUrl)
        if (project && page && page.title.toLowerCase().includes('google') && fileName) {
          await AxiosInstance.updatePageTitle(fileName, parseInt(project.id), page.id)
          _projectFile.getAndDispatchProjectFilePages(project.id)
        }
      }
    } else {
      setHasAccess(null)
    }

    setLoading(false)
  }

  return {
    hasAccess,
    token,
    loading,
    user,
  }
}
