import styled from 'styled-components'
import { ReactComponent as Paperclip } from 'assets/images/Paperclip.svg'

export const PaperClipIcon = styled(Paperclip)`
  cursor: pointer;
`

export const PropertiesContainer = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  gap: 12px;

  & > button {
    color: var(--gray);
    font-size: 14px;
  }

  & svg {
    margin: 0;
  }
`

export const StyledInputContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid var(--gray3);
  transition: border 0.2s;
  cursor: pointer;

  &:hover {
    border: 1px solid var(--primary-color);
  }

  & label {
    margin: 0;
    cursor: pointer;
  }
`

export const StyledUploadedFile = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 14px;
  font-weight: 500;
  color: var(--secondary-text);
  margin-top: 1rem;

  & > a {
    &:hover {
      color: var(--primary-color) !important;
    }
  }
`

export const ColumnActionRow = styled.div`
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  border-radius: 10px;

  transition: 0.2s;

  &:hover {
    background: var(--hover);
  }
`

export const ColumnActionRowText = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--gray2);
`

export const TaskTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
  padding: 0;
  gap: 12px;
`

export const StyledChipWrapper = styled.div`
  cursor: pointer;
`

export const StyledDatePickerWrapper = styled.div`
  & .react-datepicker__header {
    background: var(--tasks-background) !important;
    border-bottom: none;
  }

  & .react-datepicker-popper {
    z-index: 10 !important;
  }

  & .react-datepicker__close-icon {
    transform: translateX(-2px);
    padding-top: 2px;
    opacity: 0;

    &:after {
      background: transparent;
      color: var(--primary-text);
      transform: scale(1.2);
    }
  }

  & .react-datepicker__today-button {
    color: var(--primary-text) !important;
    border-top: none;
    background: transparent;
    text-align: right;
    padding: 0 8px 8px 0;
    font-weight: 500;
  }

  & .date-picker {
    &:hover {
      .react-datepicker__close-icon {
        opacity: 1;
      }
    }
  }

  svg {
    vertical-align: top;
  }

  & .react-datepicker__month-container {
    background: var(--tasks-background) !important;
  }

  & .react-datepicker {
    background-color: white !important;
    box-shadow: 0px 1px 4px 0px #00000033;
    border: none;
  }

  & .react-datepicker__triangle {
    display: none;
  }

  & .react-datepicker__day,
  .react-datepicker__time-name,
  .react-datepicker__day,
  .react-datepicker__day-name,
  .react-datepicker__current-month {
    color: var(--primary-text) !important;
  }

  & .react-datepicker__day--outside-month {
    color: var(--gray-inactive);
  }

  & .react-datepicker__day--selected {
    background: var(--tasks-background) !important;
    border: solid 1px var(--primary-color);
    border-radius: 10px;
    font-weight: 500;
    outline: none;
  }

  & .react-datepicker__day--today {
    font-weight: 500;
  }

  & .react-datepicker__current-month {
    font-weight: 500;
    font-size: 14px;
  }

  & .react-datepicker__navigation {
    top: 10px;
  }

  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    background: var(--hover) !important;
  }
`
