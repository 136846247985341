import { SlashMenuOptions } from './types'
import {
  CheckSquare,
  ImageSquare,
  ListBullets,
  H1Icon,
  H2Icon,
  LIcon,
  MIcon,
  SIcon,
  TIcon,
  XLIcon,
  LinkToPage,
  FigmaEmbedIcon,
  ListNumbers,
  GoogleDoc,
  GoogleSlides,
  GoogleSheets,
  SlideShow,
  EmbedLink,
} from '../ui/SlashMenu/styles'
import { IBlockTypes } from '_entities/block'
import { ITools } from 'interfaces/whiteboard'
import { SELECTOR_ICON_SIZE, SELECTOR_ICON_SIZE_SMALL } from './useSlashMenu'
import { icons, DocumentIcon, WhiteboardIcon } from 'shared/icons'
import { EmbedType } from '_entities/embed'

export const useSlashMenuOptions = () => {
  const textOptions: SlashMenuOptions[] = [
    {
      label: 'Headline',
      icon: <H1Icon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.TITLE,
      isText: true,
      value: 1,
      tool: ITools.TEXT,
    },
    {
      label: 'Subheader',
      icon: <H2Icon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.SUBHEADER,
      isText: true,
      value: 2,
      tool: ITools.TEXT,
    },
    {
      label: 'XLarge',
      icon: <XLIcon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.XLARGE,
      isText: true,
      value: 3,
      tool: ITools.TEXT,
    },
    {
      label: 'Large',
      icon: <LIcon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.LARGE,
      isText: true,
      value: 4,
      tool: ITools.TEXT,
    },
    {
      label: 'Medium',
      icon: <MIcon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.MEDIUM,
      isText: true,
      value: 5,
      tool: ITools.TEXT,
    },
    {
      label: 'Small',
      icon: <SIcon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.SMALL,
      isText: true,
      value: 6,
      tool: ITools.TEXT,
    },
    {
      label: 'Text',
      icon: <TIcon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.TEXT,
      isText: true,
      value: false,
      tool: ITools.TEXT,
    },
    {
      label: 'Bulleted list',
      icon: <ListBullets width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.LIST,
      isText: false,
      tool: ITools.TEXT,
    },
    {
      label: 'Numbered list',
      icon: <ListNumbers width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.NUMBERED_LIST,
      isText: false,
      tool: ITools.TEXT,
    },
    {
      label: 'To-Do list',
      icon: <CheckSquare width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.CHECKLIST,
      isText: false,
      tool: ITools.TEXT,
    },
  ]

  const imageOptions: SlashMenuOptions[] = [
    {
      label: 'Image',
      icon: <ImageSquare width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.IMAGE,
      isText: false,
    },
    {
      label: 'Image and text',
      icon: <icons.floatTextLeft width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.IMAGE_TEXT,
      isText: false,
    },
  ]

  const externalEmbedOptions: SlashMenuOptions[] = [
    {
      label: 'Figma embed',
      icon: <FigmaEmbedIcon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.EXTERNAL_EMBED,
      embedType: EmbedType.FIGMA,
      isText: false,
    },
    {
      label: 'Google Docs',
      icon: <GoogleDoc width={SELECTOR_ICON_SIZE_SMALL} />,
      type: IBlockTypes.EXTERNAL_EMBED,
      embedType: EmbedType.GOOGLE_DOC,
      isText: false,
    },
    {
      label: 'Google Slides',
      icon: <GoogleSlides width={SELECTOR_ICON_SIZE_SMALL} />,
      type: IBlockTypes.EXTERNAL_EMBED,
      embedType: EmbedType.GOOGLE_SLIDES,
      isText: false,
    },
    {
      label: 'Google Sheets',
      icon: <GoogleSheets width={SELECTOR_ICON_SIZE_SMALL} />,
      type: IBlockTypes.EXTERNAL_EMBED,
      embedType: EmbedType.GOOGLE_SHEETS,
      isText: false,
    },
    {
      label: 'Miro board',
      icon: <icons.miro width={SELECTOR_ICON_SIZE_SMALL} height={SELECTOR_ICON_SIZE_SMALL} />,
      type: IBlockTypes.EXTERNAL_EMBED,
      isText: false,
    },
    {
      label: 'Embed link',
      icon: <EmbedLink width={SELECTOR_ICON_SIZE_SMALL} />,
      type: IBlockTypes.GENERIC_LINK,
      embedType: EmbedType.GENERIC_LINK,
      isText: false,
    },
  ]

  const internalEmbedOptions: SlashMenuOptions[] = [
    {
      label: 'New Document',
      icon: <DocumentIcon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.DOCUMENT,
      isText: false,
    },
    {
      label: 'New Canvas',
      icon: <WhiteboardIcon width={SELECTOR_ICON_SIZE} />,
      type: IBlockTypes.WHITEBOARD,
      isText: false,
    },
    {
      label: 'Link to page',
      icon: <LinkToPage width={SELECTOR_ICON_SIZE_SMALL} />,
      type: IBlockTypes.EMBED,
      isText: false,
    },
    {
      label: 'Slide Show',
      icon: <SlideShow width={SELECTOR_ICON_SIZE_SMALL} />,
      type: IBlockTypes.SLIDESHOW,
      isText: false,
    },
  ]

  const editorOptions: SlashMenuOptions[] = [
    ...textOptions,
    ...imageOptions,
    ...externalEmbedOptions,
    ...internalEmbedOptions,
  ]

  const whiteboardOptions: SlashMenuOptions[] = [
    ...textOptions.filter((option) => option.label !== 'Numbered list'),
    ...externalEmbedOptions,
    ...internalEmbedOptions,
  ]

  return {
    textOptions,
    imageOptions,
    externalEmbedOptions,
    internalEmbedOptions,
    editorOptions,
    whiteboardOptions,
  }
}
