import Wrapper from 'components/molecules/Wrapper/Wrapper'
import CustomP from 'components/atoms/CustomP/CustomP'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import Settings from 'assets/images/settings.svg'
import ThemeSwitcher from 'assets/images/themeSwitchIcon.svg'
import Logout from 'assets/images/logout.svg'
import Avatar from 'components/atoms/Avatar/Avatar'
import { AxiosService } from 'services/axiosService/axiosService'
import { useNavigate } from 'react-router-dom'
import useLocalStorage from 'shared/lib/useLocalStorage'
import { IUserAttributes } from 'interfaces/auth/userData'
import AvatarPlaceholder from 'assets/images/avatar-placeholder.png'
import { useAppDispatch } from 'redux/hooks'
import { setProfileModalOpened } from 'redux/reducers/spaceReducer'
import { useViewer } from '_entities/viewer'

export const useProfileDropdownOptions = () => {
  const { clearLocalStorage } = useLocalStorage()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const _viewer = useViewer()
  const AxiosInstance = new AxiosService()
  const logoutEndpoint = async () => {
    const response = await AxiosInstance.logout()
    if (response) navigate('/')
  }
  const handleLogout = () => {
    clearLocalStorage()
    logoutEndpoint()
    _viewer.deleteViewer()
  }

  const dropdownOptions = (user?: IUserAttributes, userImage?: string) => [
    {
      element: (
        <Wrapper
          display='flex'
          padding='8px 12px 4px 10px'
          styles={'&:hover {background: var(--hover); & p {color: black}};'}
          alignItems='center'
          className='dark-style-hover'
        >
          <Avatar src={userImage || AvatarPlaceholder} width='24px' height='24px' />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px' color='var(--gray2)'>
            {user?.email}
          </CustomP>
        </Wrapper>
      ),
      id: 9,
      onClick: () => {
        console.error('not ready yet')
      },
      eventKey: 1,
    },
    {
      element: (
        <Wrapper
          display='flex'
          padding='0px 0px 0px 12px'
          styles={'&:hover {background: var(--hover); & p {color: black}}'}
          className='dark-style-hover'
        >
          <CustomIcon src={Settings} />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px' fontWeight='500'>
            Settings
          </CustomP>
        </Wrapper>
      ),
      id: 9,
      onClick: () => {
        dispatch(setProfileModalOpened(true))
      },
      eventKey: 2,
    },
    {
      element: (
        <Wrapper
          display='flex'
          padding='0px 0px 0px 12px'
          styles={
            '&:hover {background: var(--hover); & p {color: black}}; border-bottom: 3px solid var(--gray4); margin-block: 4px 8px;'
          }
          className='dark-style-hover'
        >
          <CustomIcon src={ThemeSwitcher} />
          <CustomP className='ps-2 py-2' marginBottom='0' borderradius='5.5px' fontWeight='500'>
            Theme
          </CustomP>
        </Wrapper>
      ),
      id: 9,
      onClick: () => {
        console.error('not ready yet')
      },
      eventKey: 3,
    },
    {
      element: (
        <Wrapper
          display='flex'
          padding='0px 0px 0px 12px'
          styles={'&:hover {background: var(--hover); & p {color: black}}; padding-block: 0px;'}
          className='dark-style-hover'
          margin='0.5rem 0 0 0'
        >
          <CustomIcon src={Logout} />
          <CustomP
            hoverbgcolor='&:hover {background:var(--hover)}'
            className='ps-2 py-2'
            marginBottom='0'
            borderradius='5.5px'
            fontWeight='500'
          >
            Log out
          </CustomP>
        </Wrapper>
      ),
      id: 9,
      onClick: () => {
        handleLogout()
      },
      eventKey: 4,
    },
  ]

  return { dropdownOptions }
}
