import styled from 'styled-components'

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`
export const ModalBodyContainer = styled.div`
  margin: 16px;
  height: 600px;

  & .vartical-border {
    padding-right: 20px;
    margin-right: 10px;
    overflow-y: auto;
    min-height: 600px;
    border-right: 2px solid var(--gray5);
  }
`
