import { css } from 'styled-components'

export const commonGradient = css`
  background-image: -webkit-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: -moz-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
  background-image: -o-linear-gradient(left, #ececec 0px, #f4f4f4 40px, #ececec 80px);
`

export const shineAnimation = css`
  @-webkit-keyframes shine-loading-image {
    0% {
      background-position: -12px;
    }
    40%,
    100% {
      background-position: 258px;
    }
  }

  @keyframes shine-loading-image {
    0% {
      background-position: -12px;
    }
    40%,
    100% {
      background-position: 258px;
    }
  }

  -webkit-animation: shine-loading-image 1.25s infinite ease-in-out;
  animation: shine-loading-image 1.25s infinite ease-in-out;
`

export const shineAnimationWide = css`
  @-webkit-keyframes shine-loading-image-wide {
    0% {
      background-position: -12px;
    }
    40%,
    100% {
      background-position: 592px;
    }
  }

  @keyframes shine-loading-image-wide {
    0% {
      background-position: -12px;
    }
    40%,
    100% {
      background-position: 592px;
    }
  }

  -webkit-animation: shine-loading-image-wide 1.25s infinite ease-in-out;
  animation: shine-loading-image-wide 1.25s infinite ease-in-out;
`
