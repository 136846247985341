import React from 'react'
import { Modal } from 'react-bootstrap'
import { CloseIconContainer, ModalBodyContainer } from './styles'
import { ReactComponent as CloseIcon } from 'assets/images/close.svg'
import ModalSidebarAndContent from '../ModalWithSidebarAndContent/ModalSidebarAndContent'

type Props = {
  show: boolean
  onHide: () => void
  userImage: string | undefined
  setUserImage: React.Dispatch<React.SetStateAction<string | undefined>>
}

const ModalWithSidebar: React.FC<Props> = ({ show, onHide, userImage, setUserImage }) => {
  return (
    <Modal
      size='xl'
      aria-labelledby='contained-modal-title-vcenter'
      onHide={onHide}
      show={show}
      centered
      dialogClassName='userModalMaxWidth'
    >
      <CloseIconContainer onClick={onHide}>
        <CloseIcon height={27} width={27} />
      </CloseIconContainer>
      <ModalBodyContainer>
        <ModalSidebarAndContent userImage={userImage} setUserImage={setUserImage} onHide={onHide} />
      </ModalBodyContainer>
    </Modal>
  )
}

export default ModalWithSidebar
