import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { StyledSidebarNavigationCss, IconAndTitleWrapper, StyledArrowIcon } from './styles'
import SidebarReference from 'components/molecules/SidebarReference/SidebarReference'
import {
  DraggableProvidedDraggableProps,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd'

type Props = {
  levelOfNesting?: number
  draggableProps?: DraggableProvidedDraggableProps
  dragHandleProps?: DraggableProvidedDragHandleProps
  onMouseEnterReference?: () => void
  onMouseLeaveReference?: () => void
  titleColor?: string
  innerRef?:
    | ((instance: HTMLDivElement | null) => void)
    | React.RefObject<HTMLDivElement>
    | null
    | undefined
  children?: React.ReactNode
  title: string
  isDropdownOpened?: boolean
}

const StyledSidebarNavigation = styled.div`
  ${StyledSidebarNavigationCss}
`

const index: React.FC<Props> = ({
  levelOfNesting,
  children,
  title,
  draggableProps,
  dragHandleProps,
  onMouseEnterReference,
  onMouseLeaveReference,
  titleColor,
  innerRef,
  isDropdownOpened,
}) => {
  const [isOpened, setIsOpened] = useState<boolean>(true)

  useEffect(() => {
    isDropdownOpened && setIsOpened(true)
  }, [isDropdownOpened])

  return (
    <StyledSidebarNavigation {...draggableProps} {...dragHandleProps} ref={innerRef}>
      <SidebarReference
        levelOfNesting={levelOfNesting}
        isSelected={false}
        onClick={() => setIsOpened(!isOpened)}
        color={titleColor}
        onMouseEnter={onMouseEnterReference}
        onMouseLeave={onMouseLeaveReference}
      >
        <IconAndTitleWrapper>
          <StyledArrowIcon isopened={isOpened ? 1 : 0} />
          {title}
        </IconAndTitleWrapper>
      </SidebarReference>
      {isOpened && children}
    </StyledSidebarNavigation>
  )
}

export default index
