import { Position } from 'interfaces/whiteboard'
import { Context } from 'konva/lib/Context'
import { Shape, ShapeConfig } from 'konva/lib/Shape'

const RADIUS = 20
export const sceneFunc =
  (points: [Position, Position]) => (context: Context, shape: Shape<ShapeConfig>) => {
    const width = points[1].x - points[0].x
    const height = points[1].y - points[0].y
    const verticalDir = Math.sign(height)
    const horizontalDir = Math.sign(width)

    const radius = RADIUS
    const getBeginningX = () => {
      return points[0].x
    }

    const getLastLineToX = () => {
      return points[1].x
    }

    const getLastLineToY = () => {
      return points[1].y
    }

    const drawArrow = () => {
      const arrowLineX = getLastLineToX() - horizontalDir * 10
      const leftArrowLineY = getLastLineToY() - 10
      const rightArrowLineY = getLastLineToY() + 10
      context.lineTo(arrowLineX, leftArrowLineY)
      context.lineTo(getLastLineToX(), getLastLineToY())
      context.lineTo(arrowLineX, rightArrowLineY)
    }

    const getMiddlelineX = () => {
      return points[0].x + width / 2
    }

    const getBeginningOfFirstCurveX = () => {
      return points[0].x + width / 2 - horizontalDir * radius
    }

    context.beginPath()
    context.moveTo(getBeginningX(), points[0].y)
    context.lineTo(getBeginningOfFirstCurveX(), points[0].y)
    context.quadraticCurveTo(
      points[0].x + width / 2,
      points[0].y,
      points[0].x + width / 2,
      points[0].y + verticalDir * radius,
    )
    context.lineTo(getMiddlelineX(), points[1].y - verticalDir * radius)
    context.quadraticCurveTo(
      points[0].x + width / 2,
      points[1].y,
      points[0].x + width / 2 + horizontalDir * radius,
      points[1].y,
    )

    context.lineTo(getLastLineToX(), getLastLineToY())

    context.fillStrokeShape(shape)
  }
