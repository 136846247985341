import React from 'react'
import { icons } from 'shared/icons'
import CustomP from 'components/atoms/CustomP/CustomP'
import IconList from './IconList'
import { StyledIcon, StyledItemNewPageHeader } from '../Option/styles'
import EditorUploadInput from '_features/upload/ui/EditorUploadInput'
import Wrapper from 'components/molecules/Wrapper/Wrapper'

interface Props {
  setUploadSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Upload = (props: Props) => {
  return (
    <>
      <EditorUploadInput
        type='file'
        id='fileInput'
        onChange={props.handleFileUpload}
        label={
          <Wrapper display={'flex'} alignItems={'center'} gap={'6px'} cursor={'pointer'}>
            <StyledIcon>
              <icons.upload />
            </StyledIcon>
            <StyledItemNewPageHeader>Upload</StyledItemNewPageHeader>
          </Wrapper>
        }
      />
      <CustomP color='var(--gray-inactive)' fontWeight='300' marginBottom='8px'>
        Supported
      </CustomP>
      <IconList
        icons={[
          <icons.pdfFull width={18} height={18} key={'upload-icon-pdf'} />,
          <icons.word width={18} height={18} key={'upload-icon-word'} />,
          <icons.image width={18} height={18} key={'upload-icon-image'} />,
          <icons.video width={18} height={18} key={'upload-icon-video'} />,
          <icons.fileDots width={18} height={18} key={'upload-icon-fileDots'} />,
        ]}
        title='Upload-icons'
      />
    </>
  )
}

export default Upload
