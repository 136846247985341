import { block } from '_entities/block'
import { getDocumentContainerId } from '../lib/getters'

export enum DocumentAttrs {
  BLOCKS = 'data-blocks',
}

export interface DocumentAttrsData {
  [DocumentAttrs.BLOCKS]: block[]
}

export const getBlocks = (pageId: string) => {
  const element = document.getElementById(getDocumentContainerId(pageId))
  if (element) return JSON.parse(element.getAttribute(DocumentAttrs.BLOCKS) || '[]') as block[]
}
