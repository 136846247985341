import { useEffect, useState } from 'react'
import { useToast } from 'services/helpers/useToast'
import { icons } from 'shared/icons'
import { DefaultsType } from 'interfaces/settings'
import { IProjectFile, ISpace } from 'interfaces/space'
import { IPage } from 'interfaces/page'
import useProject from '_entities/project/model/useProject'
import useDefaultPage from '_entities/page/model/useDefaultPage'
import { useSpace } from '_entities/space'
import { Option } from 'interfaces/selectOptions'
import { AxiosService } from 'services/axiosService/axiosService'

const useDefaults = () => {
  const [shownModal, setShownModal] = useState<DefaultsType | null>(null)
  const [options, setOptions] = useState<ISpace[] | IProjectFile[] | IPage[]>([])
  const [selectedOption, setSelectedOption] = useState<ISpace | IProjectFile | IPage | null>(null)
  const [selectedDefault, setSelectedDefault] = useState<ISpace | IProjectFile | IPage | null>(null)
  const [defaults, setDefaults] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { setDefaultSpace, getDefaultSpace } = useSpace()
  const { setDefaultProject, getDefaultProject, getProjectFilesBySpaceId } = useProject()
  const { setDefaultPage, getDefaultPage, getAllPages } = useDefaultPage()
  const toast = useToast()
  const axiosService = new AxiosService()

  useEffect(() => {
    handleSetDefaults()
  }, [])

  const handleSetDefaults = async () => {
    setLoading(true)
    const defaultSpace = await getDefaultSpace()
    const defaultProject = await getDefaultProject()
    const defaultCanvas = await getDefaultPage('whiteboard')
    const defaultDocument = await getDefaultPage('document')

    const defaults = [
      {
        label: 'workspace',
        value: defaultSpace,
      },
      {
        label: 'project',
        value: defaultProject?.data
          ? {
              ...defaultProject?.data.attributes,
              name: defaultProject?.data.attributes.title,
              isDefault: true,
              id: defaultProject?.data.id,
            }
          : null,
      },
      {
        label: 'document',
        value: defaultDocument?.data
          ? {
              ...defaultDocument?.data.attributes,
              name: defaultDocument?.data.attributes.title,
              isDefault: true,
              id: defaultDocument?.data.id,
            }
          : null,
      },
      {
        label: 'canvas',
        value: defaultCanvas?.data
          ? {
              ...defaultCanvas?.data.attributes,
              name: defaultCanvas?.data.attributes.title,
              isDefault: true,
              id: defaultCanvas?.data.id,
            }
          : null,
      },
    ]

    setDefaults(defaults)
    setLoading(false)
  }

  const getProjects = async () => {
    const response = (
      await axiosService.searchProjects({
        limit: 10,
        page: 1,
        search: '',
      })
    ).data as IProjectFile[]

    const projects = response.map((project) => {
      return {
        ...project,
        label: project.title,
        value: project.id,
      }
    })

    setOptions([...projects])
  }

  const getCanvases = async () => {
    const pages = await getAllPages({
      type: 'whiteboard',
    })

    const allPages = [...pages.data]

    setOptions(
      allPages.map((canvas) => {
        return {
          ...canvas,
          label: canvas.title,
          value: canvas.id,
        }
      }),
    )
  }

  const getDocuments = async () => {
    const pages = await getAllPages({
      type: 'document',
    })

    const allPages = [...pages.data]

    setOptions(
      allPages.map((document) => {
        return {
          ...document,
          label: document.title,
          value: document.id,
        }
      }),
    )
  }

  const handleShownModal = (defaultModal: DefaultsType) => {
    setShownModal(defaultModal)
    handleSetOptions(defaultModal)
    setDefaultOption(defaultModal)
  }

  const handleSetOptions = async (item: DefaultsType) => {
    switch (item) {
      case 'workspace': {
        const spaces = (
          await axiosService.searchSpaces({
            limit: 10,
            page: 1,
            search: '',
          })
        ).data as ISpace[]

        if (!spaces) return

        const allSpaces = spaces.map((space) => {
          return {
            ...space,
            label: space.name,
            value: space.id,
          }
        })

        setOptions([...allSpaces])
        break
      }
      case 'project':
        getProjects()
        break
      case 'canvas':
        getCanvases()
        break
      case 'document':
        getDocuments()
        break
      default:
        return
    }
  }

  const handleCloseModal = () => {
    setShownModal(null)
    setSelectedOption(null)
    setOptions([])
  }

  const generateModalHeader = () => `${shownModal?.charAt(0).toUpperCase()}${shownModal?.slice(1)}`
  const generateDefaultHeader = (item: string) =>
    `${item?.charAt(0).toUpperCase()}${item?.slice(1)}`

  const handleSelectedOption = (option: any) => {
    setSelectedOption(option)
  }

  const handleSubmitDefaultSpace = async () => {
    if (selectedOption) {
      const response = await setDefaultSpace(selectedOption.id as string)

      if (response) {
        handleCloseModal()
        toast.success('Workspace set as default')
        handleSetDefaults()
      }
    }
  }

  const handleSubmitDefaultDocument = async () => {
    if (selectedOption) {
      const response = await setDefaultPage(selectedOption.id as string)

      if (response) {
        handleCloseModal()
        toast.success('Document set as default')
        handleSetDefaults()
      }
    }
  }

  const handleSubmitDefaultProject = async () => {
    if (selectedOption) {
      const response = await setDefaultProject(selectedOption.id as string)

      if (response) {
        handleCloseModal()
        toast.success('Project set as default')
        handleSetDefaults()
      }
    }
  }

  const handleSubmitDefaultCanvas = async () => {
    if (selectedOption) {
      const response = await setDefaultPage(selectedOption.id as string)

      if (response) {
        handleCloseModal()
        toast.success('Canvas set as default')
        handleSetDefaults()
      }
    }
  }

  const handleSubmitDefault = async (item: DefaultsType | null) => {
    switch (item) {
      case 'workspace':
        handleSubmitDefaultSpace()
        break
      case 'project':
        handleSubmitDefaultProject()
        break
      case 'canvas':
        handleSubmitDefaultCanvas()
        break
      case 'document':
        handleSubmitDefaultDocument()
        break
      default:
        return
    }
  }

  const handleDeleteDefault = async (item: DefaultsType, id: string) => {
    let response
    switch (item) {
      case 'workspace':
        response = await setDefaultSpace(id, false)

        if (response) {
          handleSetDefaults()
          toast.warning('Default workspace removed')
        }
        break

      case 'project':
        response = await setDefaultProject(id, false)

        if (response) {
          handleSetDefaults()
          toast.warning('Default project removed')
        }
        break
      case 'canvas':
        response = await setDefaultPage(id, false)

        if (response) {
          handleSetDefaults()
          toast.warning('Default whiteboard removed')
        }
        break
      case 'document':
        response = await setDefaultPage(id, false)

        if (response) {
          handleSetDefaults()
          toast.warning('Default document removed')
        }
        break
      default:
        return
    }
  }

  const handleShowIcon = (item: DefaultsType | null) => {
    switch (item) {
      case 'workspace':
        return <icons.workspace />
      case 'project':
        return <icons.folders />
      case 'canvas':
        return <icons.whiteboard />
      case 'document':
        return <icons.document />
      default:
        return <icons.workspace />
    }
  }

  const setDefaultOption = (item: DefaultsType) => {
    const selectedDefaultOption = defaults.find((defaultItem) => {
      if (defaultItem.label === item) {
        return defaultItem
      }
    })

    setSelectedDefault(selectedDefaultOption?.value)
  }

  const loadOptions = async (
    search: string,
    loadedOptions: Option[],
    { page }: { page: number },
  ): Promise<any> => {
    const fetchedOptions = await getAllPages({
      type: shownModal === 'canvas' ? 'whiteboard' : 'document',
      limit: 10,
      page: page,
      search: search,
    })

    const formattedOptions = fetchedOptions.data.map((option: any) => ({
      value: option.id,
      label: option.title,
      id: option.id,
    }))

    return {
      options: formattedOptions,
      hasMore: fetchedOptions.meta.hasNextPage,
      additional: {
        page: page + 1,
      },
    }
  }

  const loadSpaceOptions = async (
    search: string,
    loadedOptions: Option[],
    { page }: { page: number },
  ): Promise<any> => {
    const fetchedOptions = await axiosService.searchSpaces({
      limit: 10,
      page: page,
      search: search,
    })

    const formattedOptions = fetchedOptions.data.map((option: any) => ({
      value: option.id,
      label: option.name,
      id: option.id,
    }))

    return {
      options: formattedOptions,
      hasMore: fetchedOptions.meta.hasNextPage,
      additional: {
        page: page + 1,
      },
    }
  }

  const loadProjectOptions = async (
    search: string,
    loadedOptions: Option[],
    { page }: { page: number },
  ): Promise<any> => {
    const fetchedOptions = await axiosService.searchProjects({
      limit: 10,
      page: page,
      search: search,
    })

    const formattedOptions = fetchedOptions.data.map((option: any) => ({
      value: option.id,
      label: option.title,
      id: option.id,
    }))

    return {
      options: formattedOptions,
      hasMore: fetchedOptions.meta.hasNextPage,
      additional: {
        page: page + 1,
      },
    }
  }

  return {
    shownModal,
    handleShownModal,
    generateModalHeader,
    options,
    handleCloseModal,
    handleSelectedOption,
    selectedOption,
    handleDeleteDefault,
    generateDefaultHeader,
    defaults,
    handleShowIcon,
    handleSubmitDefault,
    setDefaultOption,
    selectedDefault,
    loadOptions,
    setLoading,
    loading,
    loadSpaceOptions,
    loadProjectOptions,
  }
}

export default useDefaults
