import { IUserAttributes } from 'interfaces/auth/userData'
import { type block } from '_entities/block'
import { CSSProperties } from 'react'

export const getBlockStyling = (block: block, userPresence?: IUserAttributes): CSSProperties => {
  return userPresence
    ? {
        border: `${`1px solid ${userPresence.userColor}`}`,
        maxWidth: '100%',
        overflowWrap: 'break-word',
        color: block.style?.styling.find((b) => b.type === 'color')
          ? `${block.style?.styling.find((b) => b.type === 'color')?.value} `
          : undefined,
        backgroundColor: block.style?.styling.find((b) => b.type === 'backgroundColor')?.value
          ? `${block.style?.styling.find((b) => b.type === 'backgroundColor')?.value}`
          : undefined,
        fontWeight: block.style?.styling.find((b) => b.type === 'bold')
          ? `${
              block.style?.styling.find((b) => b.type === 'bold')?.value === 'true'
                ? 'bolder'
                : block.style?.styling.find((b) => b.type === 'fontWeight')?.value
            }`
          : undefined,
        textDecoration: `${
          block.style?.styling.find((b) => b.type === 'underline')?.value === 'true'
            ? 'underline '
            : ''
        }${
          block.style?.styling.find((b) => b.type === 'strikethrough')?.value === 'true'
            ? ' line-through '
            : ''
        }`,
        fontStyle: `${
          block.style?.styling.find((b) => b.type === 'italic')?.value === 'true' ? ' italic ' : ''
        }`,
        fontSize: block.style?.styling.find((b) => b.type === 'fontSize')
          ? `${
              block.style?.styling.find((b) => b.type === 'fontSize')?.value !== '' &&
              block.style?.styling.find((b) => b.type === 'fontSize')?.value + 'px'
            }`
          : undefined,
      }
    : {
        border: `${'none'}`,
        color: block.style?.styling.find((b) => b.type === 'color')
          ? `${block.style?.styling.find((b) => b.type === 'color')?.value} `
          : undefined,
        backgroundColor: block.style?.styling.find((b) => b.type === 'backgroundColor')?.value
          ? `${block.style?.styling.find((b) => b.type === 'backgroundColor')?.value}`
          : undefined,
        fontWeight: block.style?.styling.find((b) => b.type === 'bold')
          ? `${
              block.style?.styling.find((b) => b.type === 'bold')?.value === 'true'
                ? 'bolder'
                : block.style?.styling.find((b) => b.type === 'fontWeight')?.value
            }`
          : undefined,
        textDecoration: `${
          block.style?.styling.find((b) => b.type === 'underline')?.value === 'true'
            ? 'underline '
            : ''
        }${
          block.style?.styling.find((b) => b.type === 'strikethrough')?.value === 'true'
            ? ' line-through '
            : ''
        }`,
        fontStyle: `${
          block.style?.styling.find((b) => b.type === 'italic')?.value === 'true' ? ' italic ' : ''
        }`,
        fontSize: block.style?.styling.find((b) => b.type === 'fontSize')
          ? `${
              block.style?.styling.find((b) => b.type === 'fontSize')?.value !== '' &&
              block.style?.styling.find((b) => b.type === 'fontSize')?.value + 'px'
            }`
          : undefined,
      }
}
