import React from 'react'
import styled from 'styled-components'

import { StyledTabCss } from './styles'
const StyledTab = styled.h6`
  ${StyledTabCss}
`
import { ITab } from 'interfaces/tabs'

interface Props {
  onClick: () => void
  text: string
  selectedTab: ITab
  className: string
  isMembersTags?: boolean
  numberOfMembers?: number
  tabFontSize?: string
}

const Tab = ({ onClick, text, selectedTab, className, tabFontSize }: Props) => {
  return (
    <StyledTab
      onClick={onClick}
      text={text}
      selectedTab={selectedTab}
      className={className}
      tabFontSize={tabFontSize}
    >
      {text}
    </StyledTab>
  )
}

export default Tab
