export enum RelationshipType {
  MIRROR = 'mirror',
  INSTANCE = 'instance',
  REFERENCE = 'reference',
}

export interface OriginalObject {
  _id?: string | undefined
  pageOfOriginalBlock: string
}

export type RelatedObject = {
  relatedObject?: string
  changed: boolean
  typeOfRelation: RelationshipType
  referenceFlag?: ReferenceType
  pageId?: string
  _id?: string
}

export enum ReferenceType {
  DEPENDING = 'depending',
  BLOCKING = 'blocking',
  RELATING = 'relating',
  DUPLICATING = 'duplicating',
}
