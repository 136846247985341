import { Shape } from 'react-konva'
import { Position } from 'interfaces/whiteboard'
import { sceneFunc } from './sceneFunc'
import { getColor } from 'shared/colors'

interface Props {
  points: [Position, Position]
}

const Connector = (props: Props) => {
  const { points } = props
  return <Shape sceneFunc={sceneFunc(points)} stroke={getColor('--gray3')} strokeWidth={1} />
}

export default Connector
