import { useStage, useTransformer } from '_features/canvas'
import { KonvaMouseTouchEvent } from './types'

export const useEvent = () => {
  const _stage = useStage()
  const _transformer = useTransformer()
  const getPageIdFromEvent = (e: KonvaMouseTouchEvent) => {
    const stageId = e.target.getStage()?.attrs.id
    return _stage.getDocIdFromStageId(stageId)
  }

  const getCurrentScaledTransformerPosition = (evt: KonvaMouseTouchEvent) => {
    const pageId = getPageIdFromEvent(evt)
    const stage = _stage.getStage(pageId)
    const transformer = _transformer.getTransformer(pageId)
    const position = transformer?.position()
    if (!stage || !position) return
    return _stage.unScalePosition(pageId, position)
  }

  return {
    getPageIdFromEvent,
    getCurrentScaledTransformerPosition,
  }
}
