import { DeltaOperation } from 'quill'
import { DEFAULT_SIZE, MIN_SIZE } from '../lib/constants'

export const fontSizeArray = () => {
  const array = []
  for (let i = MIN_SIZE; i <= 999; i++) {
    array.push(i)
  }
  return array
}

export const fontSizeArrayForDropdown = () => {
  return [10, 12, 14, 18, 24, 36, 48, 64, 80, 144, 288]
}

export const getFontSizeCss = () => {
  let string = ''
  fontSizeArray().forEach((fontSize) => {
    string += `& .ql-size-${fontSize}px {font-size: ${fontSize}px;}`
  })
  return string
}

export const getFontSizes = () => {
  return fontSizeArray().map((size) => `${size}px`)
}

export const getSize = (ops: DeltaOperation[]) => {
  let sizeFormat = `${DEFAULT_SIZE}px`
  ops?.forEach((op: DeltaOperation) => {
    if (!op.attributes?.size) return
    sizeFormat = op.attributes?.size
  })
  return parseInt(sizeFormat)
}
