import styled from 'styled-components'
import { motion } from 'framer-motion'

export const StyledBlockToolbar = styled(motion.div)<{
  isEditor?: boolean
  top?: string
  bottom?: string
  left?: string
  transform?: string
}>`
  position: absolute;
  gap: ${(props) => (props.isEditor ? '4px' : '8px')};
  height: fit-content;

  display: flex;
  align-items: center;
  background-color: var(--overlay-modal);
  border-radius: 1000px;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
  padding: 3px 4px;
  z-index: 100;
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  transform: ${(props) => props.transform};
  bottom: ${(props) => props.bottom};

  & * {
    z-index: 100;
    isolation: isolate;
  }

  & > *:active {
    background-color: var(--hover);
  }

  svg path {
    stroke: var(--primary-text);
  }
`

export const ElementContainer = styled.div<{ isBorderBottom?: boolean }>`
  padding: 4px;
  border-radius: 0 !important;
  font-size: 14px;
  border-bottom: ${(props) => (props.isBorderBottom ? '1px solid var(--gray4)' : '')};
  font-weight: 500;

  &:hover {
    background-color: var(--hover);

    * {
      background-color: var(--hover);
    }
  }

  p {
    font-size: 14px !important;
  }
`

export const IconAndText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
`

export const ToolbarDivider = styled.div`
  width: 1px;
  height: 24px;
  background-color: var(--gray4);
  margin: 0 8px;
`

export const StyledOptionIcon = styled.div`
  svg {
    width: 24px !important;
    height: 24px !important;
    padding: 3px;
  }
`
