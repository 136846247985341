import { getBlockPageId, getIndex, type block } from '_entities/block'
import {
  getBlockDocumentPage,
  getBlockInternalEmbed,
  getInternalEmbedBackgroundHistory,
  getInternalEmbedBackgroundColor,
  useWhiteboardEmbed,
  useInternalEmbedBlock,
} from '_entities/embed'
import { SourceKeys } from 'interfaces/editor'
import { useEffect, useState } from 'react'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { JSONOp } from 'ot-json1'
import {
  usePresentation,
  useCanvas,
  useFrameColor,
  WhiteboardAttrs,
  getCurrentFrame,
} from '_features/canvas'
import { SidebarEditProps } from '_entities/embed/InternalEmbed/ui/EmbedEdit'
import { RGBColor } from 'react-color'
import { getRGBColorFromString, getStringFromRGBColor } from 'shared/colors'

export const useEmbedBackgroundEdit = (props: SidebarEditProps) => {
  // ** State
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState<RGBColor | undefined>()
  const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] = useState(false)

  // ** Hooks
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _presentation = usePresentation()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _canvas = useCanvas()
  const _internalEmbedBlock = useInternalEmbedBlock()
  const _frameColor = useFrameColor()

  useEffect(() => {
    const pageId = _internalEmbedBlock.getBlockPageId(props.block)
    if (!pageId) return
    const observer = _canvas.observeMutationOnCanvasElement(
      pageId,
      WhiteboardAttrs.CURRENT_FRAME,
      () => {
        const currentFrame = getCurrentFrame(pageId)
        if (currentFrame) {
          const rgba = currentFrame.fill
          if (rgba) {
            setSelectedBackgroundColor(getRGBColorFromString(rgba))
          } else {
            setSelectedBackgroundColor(undefined)
          }
        } else {
          const rgba = getInternalEmbedBackgroundColor(props.block)
          if (!rgba) return
          setSelectedBackgroundColor(getRGBColorFromString(rgba))
        }
      },
    )
    return () => {
      observer?.disconnect()
    }
  }, [props.block])

  const onBackgroundChange = (block: block, color: RGBColor) => {
    const rgba = `rgba(${color?.r}, ${color?.g}, ${color?.b}, ${color?.a})`
    setSelectedBackgroundColor(color)
    const blockPage = getBlockDocumentPage(block)
    if (!blockPage) return
    if (_whiteboardEmbed.isDocEmbed(blockPage.id)) {
      const frameBlock = _presentation.getCurrentFrameBlock(blockPage.id)
      if (!frameBlock) return
      _frameColor.updateFrameRealTime(frameBlock, rgba)
    }
  }

  const updateBackgroundColorPreset = (block: block, rgba: RGBColor) => {
    const index = getIndex(block)
    const rgbString = getStringFromRGBColor(rgba)
    if (index === -1) return

    const backgroundColorHistory = getInternalEmbedBackgroundHistory(block)
      ? [...(getInternalEmbedBackgroundHistory(block) as string[]), rgbString]
      : [rgbString]

    if (backgroundColorHistory.length > 5) backgroundColorHistory.shift()

    const updatedBlock = {
      ...block,
      data: {
        ...block.data,
        internalEmbed: {
          ...getBlockInternalEmbed(block),
          backgroundColorHistory,
          backgroundColor: rgbString,
        },
      },
    }

    const op = _json1.replaceBlock(updatedBlock, index, block)

    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  const updateWhiteboardBackgroundColor = (block: block, rgba: string) => {
    const index = getIndex(block)

    if (index === -1) return

    let op: JSONOp

    if (getInternalEmbedBackgroundColor(block) !== undefined) {
      op = _json1.getReplaceBlockDataKeyOp(index, ['internalEmbed', 'backgroundColor'], rgba)
    } else {
      if (!getBlockInternalEmbed(block)) {
        op = _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed'], {
          backgroundColor: rgba,
        })
      } else {
        op = _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed', 'backgroundColor'], rgba)
      }
    }

    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
    _canvas.updateWhiteboardElementBackgroundColorInRealTime(
      block.data.documentPage?.id as string,
      rgba,
    )
  }

  const handleSelectEmbedBackgroundColor = (block: block, rbga: string) => {
    const pageId = _internalEmbedBlock.getBlockPageId(block)
    if (!pageId) return
    if (_whiteboardEmbed.isDocEmbed(pageId)) {
      _presentation.updateFrameBackgroundColor(pageId, rbga)
    } else {
      updateWhiteboardBackgroundColor(block, rbga)
    }
  }

  return {
    onBackgroundChange,
    handleSelectEmbedBackgroundColor,
    selectedBackgroundColor,
    isBackgroundSelectionOpen,
    setIsBackgroundSelectionOpen,
    updateBackgroundColorPreset,
  }
}
