import React from 'react'
import { StyledTitle } from 'components/organisms/ColorSettings/styles'
import { ActionsWrapper, StyledDefaultsWrapper } from './styles'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import SelectDefaultModal from './SelectDefaultModal'
import { Option } from 'interfaces/selectOptions'
import useDefaults from '_entities/profile/model/useDefaults'
import SelectedDefault from './SelectedDefault'
import Default from './Default'
import { Spinner } from 'react-bootstrap'

const Defaults = () => {
  const _defaultItems = useDefaults()

  return (
    <StyledDefaultsWrapper>
      <StyledTitle>Defaults</StyledTitle>

      <ActionsWrapper>
        {_defaultItems.loading ? (
          <Spinner />
        ) : (
          <>
            {_defaultItems.defaults.map((item) => {
              return (
                <Wrapper key={item}>
                  {' '}
                  <Default
                    item={item}
                    generateDefaultHeader={_defaultItems.generateDefaultHeader}
                    handleShownModal={_defaultItems.handleShownModal}
                  />
                  {item.value && item.value.isDefault && (
                    <SelectedDefault
                      item={item}
                      handleShowIcon={_defaultItems.handleShowIcon}
                      handleShownModal={_defaultItems.handleShownModal}
                      handleDeleteDefault={_defaultItems.handleDeleteDefault}
                    />
                  )}
                </Wrapper>
              )
            })}
          </>
        )}
      </ActionsWrapper>

      <SelectDefaultModal
        shownModal={_defaultItems.shownModal}
        handleCloseModal={_defaultItems.handleCloseModal}
        generateModalHeader={_defaultItems.generateDefaultHeader}
        options={_defaultItems.options}
        handleSelectedOption={_defaultItems.handleSelectedOption}
        selectedDefault={_defaultItems.selectedDefault as Option | null}
        selectedOption={_defaultItems.selectedOption as Option | null}
        handleShowIcon={_defaultItems.handleShowIcon}
        handleSubmitDefault={_defaultItems.handleSubmitDefault}
        loadOptions={_defaultItems.loadOptions}
        loadSpaceOptions={_defaultItems.loadSpaceOptions}
        loadProjectOptions={_defaultItems.loadProjectOptions}
      />
    </StyledDefaultsWrapper>
  )
}

export default Defaults
