import Button from 'components/atoms/Button/Button'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { StyledGoogleIcon } from './styles'
import useGoogleEmbedLoginButton from '../model/useGoogleEmbedLoginButton'

interface Props {
  label: string
}

const GoogleEmbedLoginButton = (props: Props) => {
  const _googleEmbedLoginButton = useGoogleEmbedLoginButton()

  return (
    <Button variant={'secondary'} size={'medium'} onClick={() => _googleEmbedLoginButton.login()}>
      <Wrapper gap={'12px'} display={'flex'} alignItems={'center'}>
        <StyledGoogleIcon />
        {props.label}
      </Wrapper>
    </Button>
  )
}

export default GoogleEmbedLoginButton
