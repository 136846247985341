import { IPropertyTypes, IPropertyUI } from 'interfaces/taskManager'
import {
  StatusIcon,
  PersonIcon,
  DateIcon,
  TextIcon,
  NumberIcon,
  SingleSelectIcon,
  FilesIcon,
  UrlIcon,
} from 'components/organisms/ProjectFileRightSideBar/TaskManagerSidebar/styles'
import { StyledComponent } from 'styled-components'

import {
  StyledCalendarIcon,
  StyledOneIcon,
  StyledOpenInNewIcon,
  StyledSystemWindowCheckIcon,
  StyledTextAaIcon,
  StyledUserIcon,
} from './styles'

type PropIconConfig = {
  [key in IPropertyTypes]: StyledComponent<
    React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined
      }
    >,
    any,
    object,
    never
  >
}

export const generatePropertyIcon: PropIconConfig = {
  status: StatusIcon,
  person: PersonIcon,
  date: DateIcon,
  text: TextIcon,
  number: NumberIcon,
  label: SingleSelectIcon,
  'files-and-media': FilesIcon,
  url: UrlIcon,
  'created-by': PersonIcon,
  'last-edited-by': PersonIcon,
  'created-time': DateIcon,
  'last-edited-time': DateIcon,
  workstream: PersonIcon,
}

export const generateNewPropertyIcon: PropIconConfig = {
  status: StatusIcon,
  person: PersonIcon,
  date: DateIcon,
  text: TextIcon,
  number: NumberIcon,
  label: SingleSelectIcon,
  'files-and-media': FilesIcon,
  url: UrlIcon,
  'created-by': PersonIcon,
  'last-edited-by': PersonIcon,
  'created-time': DateIcon,
  'last-edited-time': DateIcon,
  workstream: PersonIcon,
}

export const generateProperties = (): IPropertyUI[] => [
  {
    id: 'status_ui',
    type: IPropertyTypes.STATUS,
    name: 'Status',
    // icon: generatePropertyIcon[IPropertyTypes.STATUS],
    icon: <StyledSystemWindowCheckIcon />,
    hasOptions: true,
  },
  {
    id: 'person_ui',
    type: IPropertyTypes.PERSON,
    name: 'Person',
    // icon: generatePropertyIcon[IPropertyTypes.PERSON],
    icon: <StyledUserIcon />,
  },
  {
    id: 'date_ui',
    type: IPropertyTypes.DATE,
    name: 'Date',
    // icon: generatePropertyIcon[IPropertyTypes.DATE],
    icon: <StyledCalendarIcon />,
  },
  {
    id: 'text_ui',
    type: IPropertyTypes.TEXT,
    name: 'Text',
    // icon: generatePropertyIcon[IPropertyTypes.TEXT],
    icon: <StyledTextAaIcon />,
  },
  {
    id: 'number_ui',
    type: IPropertyTypes.NUMBER,
    name: 'Number',
    // icon: generatePropertyIcon[IPropertyTypes.NUMBER],
    icon: <StyledOneIcon />,
  },
  // {
  //   id: 'label_ui',
  //   type: IPropertyTypes.LABEL,
  //   name: 'Label',
  //   // icon: generatePropertyIcon[IPropertyTypes.LABEL],
  //   icon: <LabelIcon />,
  //   hasOptions: true,
  // },
  // {
  //   id: 'files_and_media_ui',
  //   type: IPropertyTypes.FILES_AND_MEDIA,
  //   name: 'Files and media',
  //   // icon: generatePropertyIcon[IPropertyTypes.FILES_AND_MEDIA],
  //   icon: <AttachFileIcon />,
  // },
  {
    id: 'url_ui',
    type: IPropertyTypes.URL,
    name: 'URL',
    // icon: generatePropertyIcon[IPropertyTypes.URL],
    icon: <StyledOpenInNewIcon />,
  },
]
