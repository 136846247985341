import { getBoundingBoxId, StageAttrs, StageAttrsData, useStage } from '_features/canvas'
import { useLayer } from '_entities/whiteboard'
import Konva from 'konva'
import { getColor } from 'shared/colors'
import { Layer } from 'konva/lib/Layer'
import { Node } from 'konva/lib/Node'

export const boundingBoxStyles = {
  stroke: getColor('--primary-color'),
  strokeWidth: 1,
  fill: getColor('--hover'),
  cornerRadius: 4,
  opacity: 0.5,
}

export const useBoundingBox = () => {
  const _stage = useStage()
  const _layer = useLayer()

  const getBoundingBox = (pageId: string) => {
    return _stage.getStage(pageId)?.findOne(`#${getBoundingBoxId(pageId)}`)
  }

  // Below function is to find all bounding boxes
  // We need this for when we do a mouse up outside the stage
  // On subsequent mouse down, new identical bounding box is created
  const getAllBoundingBoxes = (pageId: string) => {
    return _stage.getStage(pageId)?.find(`#${getBoundingBoxId(pageId)}`)
  }

  const setInitialDrawingPosition = (pageId: string) => {
    const realPointerPosition = _stage.getScaledPointerPosition(pageId)
    if (realPointerPosition) {
      const position = {
        x: realPointerPosition?.x,
        y: realPointerPosition?.y,
      }
      _stage.setStageAttr(pageId, StageAttrs.INITIAL_DRAWING_POSITION, { ...position })
    }
  }

  const createBoundingBox = (pageId: string) => {
    const position = getInitialDrawingPosition(pageId)
    const rect = new Konva.Rect({
      id: getBoundingBoxId(pageId),
      width: 0,
      height: 0,
      ...boundingBoxStyles,
      ...position,
    })
    const layer = _layer.getLayer(pageId)
    ;(layer as Layer)?.add(rect)
  }

  const getInitialDrawingPosition = (pageId: string) => {
    return _stage.getStageAttr(
      pageId,
      StageAttrs.INITIAL_DRAWING_POSITION,
    ) as StageAttrsData[StageAttrs.INITIAL_DRAWING_POSITION]
  }

  const getUpdatedBoundingBox = (pageId: string) => {
    const initialDrawingPosition = getInitialDrawingPosition(pageId)
    const realPointerPosition = _stage.getScaledPointerPosition(pageId)
    if (
      initialDrawingPosition &&
      initialDrawingPosition.x &&
      initialDrawingPosition.y &&
      realPointerPosition &&
      realPointerPosition.x &&
      realPointerPosition.y
    ) {
      return {
        x: Math.min(initialDrawingPosition.x, realPointerPosition.x),
        y: Math.min(initialDrawingPosition.y, realPointerPosition.y),
        width: Math.abs(realPointerPosition.x - initialDrawingPosition.x),
        height: Math.abs(realPointerPosition.y - initialDrawingPosition.y),
      }
    }
  }

  const updateBoundingBox = (pageId: string) => {
    const boundingBox = getBoundingBox(pageId)
    const updatedBoundingBox = getUpdatedBoundingBox(pageId)
    if (boundingBox && updatedBoundingBox) {
      boundingBox.setAttrs({ ...boundingBox.attrs, ...updatedBoundingBox })
    }
  }

  const removeBoundingBoxes = (pageId: string) => {
    const boundingBoxes = getAllBoundingBoxes(pageId)
    boundingBoxes?.forEach((box: Node) => box.destroy())
  }

  return {
    getBoundingBox,
    createBoundingBox,
    updateBoundingBox,
    removeBoundingBoxes,
    setInitialDrawingPosition,
  }
}
