import { FilterDropdownItem } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/useSidebar'
import { SourceKeys } from 'interfaces/editor'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { type block } from './types'
import { useJson1 } from 'shared/shareDb/useJson1'
import { getBlockPageId, getIndex } from './getters'

const useAssignee = () => {
  const { submit } = useSubmit()
  const { getReplaceBlockMetaKeyOp } = useJson1()

  const onAssigneesChange = (items: FilterDropdownItem[], block: block) => {
    const assigneesIds: string[] = []

    items.map((item) => {
      assigneesIds.push(item.value)
    })

    const index = getIndex(block)

    if (index === -1) return

    const op = getReplaceBlockMetaKeyOp(index, ['assignees'], [...assigneesIds])

    submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  return {
    onAssigneesChange,
  }
}

export default useAssignee
