import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { IPalette } from 'interfaces/settings'
import { AxiosService } from 'services/axiosService/axiosService'
import { setColorPalettes, setSelectedColorPalette } from 'redux/reducers/globalReducer'
import { useViewer } from '_entities/viewer'

const useAdminColorSettings = () => {
  const [selected, setSelected] = useState('')
  const [expanded, setExpanded] = useState('')
  const user = useAppSelector((state) => state.global.user)
  const [loading, setIsLoading] = useState<string | null>(null)
  const palettes = useAppSelector((state) => state.global.colorPalettes)
  const sortedPalettes =
    palettes &&
    [...palettes].sort((a: IPalette, b: IPalette) =>
      a.id.localeCompare(b.id, undefined, { numeric: true }),
    )

  const dispatch = useAppDispatch()
  const AxiosInstance = new AxiosService()
  const _viewer = useViewer()

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !user.palette) return

      const userPalette = palettes?.find(
        (palette: IPalette) => parseInt(palette.id) === user.palette?.id,
      )

      if (userPalette) {
        setSelected(userPalette.id)
      } else if (palettes && palettes.length > 0) {
        setSelected(palettes[0].id)
      }
    }

    fetchData()
  }, [])

  const fetchPalettes = async () => {
    const userPalettes = user && (await AxiosInstance.getDefaultColorPalettes()).data

    if (userPalettes) {
      dispatch(setColorPalettes([...userPalettes]))

      return userPalettes
    }

    return []
  }

  const handleSelectPalette = async (palette: IPalette) => {
    setSelected(palette.id)
    dispatch(setSelectedColorPalette(palette))
    await AxiosInstance.updateUserInfo({
      colorPaletteId: parseInt(palette.id),
    })

    _viewer.getAndSaveViewer()
  }

  const setDefaultPalette = async () => {
    const defaultPalette =
      sortedPalettes &&
      sortedPalettes.filter((palette: IPalette) => palette.attributes.createdBy === null)
    await handleSelectPalette(defaultPalette![0])
    setSelected(defaultPalette![0].id)
    dispatch(setSelectedColorPalette(defaultPalette![0]))
  }

  const handleCreateNewPalette = async () => {
    setIsLoading('New')
    const response =
      sortedPalettes &&
      user &&
      (await AxiosInstance.createDefaultPalette(
        'My New Default Palette',
        parseInt(sortedPalettes[0].id),
      ))
    await fetchPalettes()
    handleSelectPalette(response.data)
    setIsLoading(null)
  }

  const handleExpandOnClick = (palette: IPalette) => {
    if (expanded === palette.id) {
      sortedPalettes && setExpanded('')
    } else {
      setExpanded(palette.id)
    }
  }

  const handleResetColorToDefault = async (paletteToReset: IPalette) => {
    const response = await AxiosInstance.resetDefaultColorPalette(paletteToReset.id)
    if (response) fetchPalettes()
  }

  return {
    selected,
    expanded,
    palettes,
    handleSelectPalette,
    handleCreateNewPalette,
    handleExpandOnClick,
    loading,
    fetchPalettes,
    setDefaultPalette,
    handleResetColorToDefault,
    sortedPalettes,
  }
}

export default useAdminColorSettings
