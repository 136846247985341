import React from 'react'
import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { ReactComponent as ResizeIcon } from 'assets/images/block-toolbar/resize.svg'
import { ReactComponent as DownloadIcon } from 'assets/images/block-toolbar/download.svg'
import { ReactComponent as ReplaceIcon } from 'assets/images/block-toolbar/replace.svg'
import { IconButtonSize } from '_features/toolbar'
import { BasicInput } from 'components/atoms/BasicInput/BasicInput'
import useImage from '_entities/image/model/useImage'
import { useAppSelector } from 'redux/hooks'
import { ToolbarDivider } from 'editor/EditableBlock/BlockToolbar/styles'
import { INPUT_SCREEN_REMOVER_PIXELS } from '_features/editor'

const ImageToolbar = () => {
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const _image = useImage()

  if (!selectedBlock) return null

  return (
    <>
      {/* Resize */}
      <IconButton
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
        onClick={() => _image.onDiagonalIconClick()}
      >
        <ResizeIcon />
      </IconButton>

      {/* Crop */}
      {/*
          <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
            <CropIcon />
          </IconButton>
            * */}

      <BasicInput
        type='file'
        ref={_image.replaceAndUploadImageInputRef}
        accept='image/*'
        onChange={_image.handleImageFileReplacement}
        position='fixed'
        left={INPUT_SCREEN_REMOVER_PIXELS}
      />

      {/* Download */}
      <IconButton
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
        onClick={(e) => _image.handleDownloadStart(e, _image.selectedBlock)}
      >
        <DownloadIcon />
      </IconButton>

      {/* Replace */}
      <IconButton
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
        onClick={() => _image.handleReplaceIconStart()}
      >
        <ReplaceIcon />
      </IconButton>
      <ToolbarDivider />
    </>
  )
}

export default ImageToolbar
