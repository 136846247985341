import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'

export const StyledDropdownItem = styled(Dropdown.Item)<{
  display: string
  width: string
  padding?: string
}>`
  padding: ${(props) => (props.padding ? props.padding : '0 !important')};
  display: ${(props) => props.display} !important;
  width: ${(props) => props.width} !important;
  border-radius: inherit !important;
`
