import { LocalPresence, Presence } from 'sharedb/lib/client'
import { useAppSelector } from 'redux/hooks'
import { block, getBlockPageId } from '_entities/block'
import { IUserAttributes } from 'interfaces/auth/userData'
import { getBlockById } from './doc'
import { EditorBlockAttrs, setUserPresence } from '_widgets/DocumentBlock'
import { getBlockNodesWithPresence } from '_widgets/Document/model/presence-blocks'

export const usePresence = () => {
  const currentUser = useAppSelector((state) => state.global.user)

  const getPresence = (pageId: string): Presence | undefined => {
    return window.__CONNECTION__?.getPresence(`pageId:${pageId}`)
  }

  const getLocalPresence = (pageId: string): LocalPresence | undefined => {
    const presence = getPresence(pageId)
    if (!presence || !currentUser || !currentUser.uuid) return
    return presence?.localPresences[currentUser.uuid]
  }

  const destroyLocalPresence = (pageId: string) => {
    getLocalPresence(pageId)?.destroy()
  }

  const createLocalPresence = (pageId: string): LocalPresence | undefined => {
    const presence = getPresence(pageId)
    if (!presence) return
    return presence?.create(currentUser?.uuid)
  }

  const updatePresence = (block: block) => {
    const pageId = getBlockPageId(block)
    const localPresence = getLocalPresence(pageId)
    localPresence?.submit({
      user: { ...currentUser, selectedPageId: pageId, blockId: block._id },
    })
  }

  const removePresence = (pageId: string) => {
    const localPresence = getLocalPresence(pageId)
    localPresence?.submit({
      user: { ...currentUser, selectedPageId: pageId },
    })
  }

  const initializePresenceListener = (pageId: string) => {
    const presence = getPresence(pageId)
    if (!presence) return
    presence.on('receive', () => {
      const usersInLiveCollab: IUserAttributes[] = []
      for (const key in presence.localPresences) {
        const localUser = presence.localPresences[key].value?.user
        delete localUser?.blockId
        usersInLiveCollab.push(localUser)
      }
      for (const key in presence.remotePresences) {
        Array.from(getBlockNodesWithPresence(pageId)).forEach((blockElem) => {
          blockElem.removeAttribute(EditorBlockAttrs.USER_PRESENCE)
        })
        const user = presence.remotePresences[key].user
        const block = getBlockById(pageId, user.blockId)
        if (block) {
          setUserPresence(block, user)
        }
        usersInLiveCollab.push(user)
      }
    })
  }

  return {
    getPresence,
    getLocalPresence,
    destroyLocalPresence,
    createLocalPresence,
    updatePresence,
    removePresence,
    initializePresenceListener,
  }
}
