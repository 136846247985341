import React from 'react'
import { ChevronRightIconRotating, EmbedContainer } from 'components/molecules/SidebarList/styles'
import AccountTabUserModal from 'components/molecules/AccountTabUserModal/AccountTabUserModal'
import { SectionContent, SectionHeader, SettingsWrapper } from './styles'
import { ProfileTab } from 'interfaces/settings'
import MyProfileTabUserModal from 'components/molecules/MyProfileTabUserModal/MyProfileTabUserModal'
import { setUserImage } from 'redux/reducers/globalReducer'
import ReferralsTabUserModal from 'components/molecules/ReferralsTabUserModal/ReferralsTabUserModal'
import NotificationTabUserModal from 'components/molecules/NotificationsTabUserModal/NotificationTabUserModal'
import ConnectionTabUserModal from 'components/molecules/ConnectionTabUserModal/ConnectionTabUserModal'
import useProfileSettings from './useProfileSettings'
import { StyledTitle } from '../ProjectFileRightSideBar/ReusableSidebar/styles'

export const ProfileSettings = () => {
  const { userImage, isShown, setIsShown } = useProfileSettings()

  return (
    <SettingsWrapper>
      {/* Account */}
      <SectionHeader
        onClick={() =>
          isShown !== ProfileTab.ACCOUNT ? setIsShown(ProfileTab.ACCOUNT) : setIsShown(null)
        }
      >
        {isShown !== ProfileTab.ACCOUNT && <ChevronRightIconRotating />}
        {isShown == ProfileTab.ACCOUNT && <ChevronRightIconRotating turn />}

        <StyledTitle>Account</StyledTitle>
      </SectionHeader>
      <SectionContent>
        <EmbedContainer display={isShown !== ProfileTab.ACCOUNT ? 'none' : 'initial'} top={0}>
          <AccountTabUserModal />
        </EmbedContainer>
      </SectionContent>

      {/* My profile */}
      <SectionHeader
        onClick={() =>
          isShown !== ProfileTab.MY_PROFILE ? setIsShown(ProfileTab.MY_PROFILE) : setIsShown(null)
        }
      >
        {isShown !== ProfileTab.MY_PROFILE && <ChevronRightIconRotating />}
        {isShown === ProfileTab.MY_PROFILE && <ChevronRightIconRotating turn />}

        <StyledTitle>My profile</StyledTitle>
      </SectionHeader>
      <SectionContent>
        <EmbedContainer display={isShown !== ProfileTab.MY_PROFILE ? 'none' : 'initial'} top={0}>
          <MyProfileTabUserModal setUserImage={setUserImage} userImage={userImage} />
        </EmbedContainer>
      </SectionContent>

      {/* Referrals */}
      <SectionHeader
        onClick={() =>
          isShown !== ProfileTab.REFERRALS ? setIsShown(ProfileTab.REFERRALS) : setIsShown(null)
        }
      >
        {isShown !== ProfileTab.REFERRALS && <ChevronRightIconRotating />}
        {isShown === ProfileTab.REFERRALS && <ChevronRightIconRotating turn />}

        <StyledTitle>Referrals</StyledTitle>
      </SectionHeader>
      <SectionContent>
        <EmbedContainer display={isShown !== ProfileTab.REFERRALS ? 'none' : 'initial'} top={0}>
          <ReferralsTabUserModal />
        </EmbedContainer>
      </SectionContent>

      {/* Notifications */}
      <SectionHeader
        onClick={() =>
          isShown !== ProfileTab.NOTIFICATIONS
            ? setIsShown(ProfileTab.NOTIFICATIONS)
            : setIsShown(null)
        }
      >
        {isShown !== ProfileTab.NOTIFICATIONS && <ChevronRightIconRotating />}
        {isShown === ProfileTab.NOTIFICATIONS && <ChevronRightIconRotating turn />}

        <StyledTitle>Notifications</StyledTitle>
      </SectionHeader>
      <SectionContent>
        <EmbedContainer display={isShown !== ProfileTab.NOTIFICATIONS ? 'none' : 'initial'} top={0}>
          <NotificationTabUserModal />
        </EmbedContainer>
      </SectionContent>

      {/* Connections */}
      <SectionHeader
        onClick={() =>
          isShown !== ProfileTab.CONNECTIONS ? setIsShown(ProfileTab.CONNECTIONS) : setIsShown(null)
        }
      >
        {isShown !== ProfileTab.CONNECTIONS && <ChevronRightIconRotating />}
        {isShown === ProfileTab.CONNECTIONS && <ChevronRightIconRotating turn />}

        <StyledTitle>Connections</StyledTitle>
      </SectionHeader>
      <SectionContent>
        <EmbedContainer display={isShown !== ProfileTab.CONNECTIONS ? 'none' : 'initial'} top={0}>
          <ConnectionTabUserModal />
        </EmbedContainer>
      </SectionContent>
    </SettingsWrapper>
  )
}

export default ProfileSettings
