import styled from 'styled-components'

export const OverlayContainer = styled.div<{
  isDocument?: boolean
  blur?: string
  background?: string
}>`
  position: ${(props) => (!props.isDocument ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  margin: 20px;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  backdrop-filter: ${(props) => (props.blur ? `blur(${props.blur}px)` : 'blur(100px)')};
  background: ${(props) => props.background};
  border-radius: 4px;
  z-index: 0;
`
