import styled from 'styled-components'

export const ProfileDrawerImageWrapper = styled.div`
  padding: 12px 24px;
  height: auto;
  border-top: none;
  display: flex;
  gap: 12px;
  flex-direction: column;
`

export const StyledTransparencyInput = styled.input`
  border: 1px solid var(--gray-disabled-stroke);
  border-radius: 10px;
  width: 84px;
  height: 32px;
  padding: 12px;
  outline: none;
`
