import React from 'react'
import { StyledWrapper } from './styles'

type Props = {
  id?: string
  innerRef?: React.MutableRefObject<HTMLDivElement | null>
  className?: string
  children?: React.ReactNode
  border?: string
  height?: string
  position?: string
  right?: string
  left?: string
  top?: string
  bottom?: string
  width?: string
  borderradius?: string
  padding?: string
  color?: string
  zindex?: string
  bordertop?: string
  display?: string
  justifyContent?: string
  alignItems?: string
  styles?: string
  onClick?: (e: any) => void
  focusColor?: string
  hoverColor?: string
  transform?: string
  margin?: string
  marginTop?: string
  boxshadow?: string
  background?: string
  cursor?: string
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement> | undefined
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement> | undefined
  visibility?: string
  transition?: string
  opacity?: string
  gap?: string
  flexdirection?: string
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement> | undefined
  flexgrow?: string
  tabIndex?: number
  outline?: string
  onResize?: React.ReactEventHandler<HTMLDivElement>
  maxWidth?: string
  overflow?: string
}

const Wrapper: React.FC<Props> = ({
  id,
  innerRef,
  className,
  children,
  border,
  height,
  position,
  right,
  left,
  top,
  bottom,
  width,
  borderradius,
  padding,
  color,
  zindex,
  bordertop,
  display,
  justifyContent,
  alignItems,
  styles,
  onClick,
  focusColor,
  hoverColor,
  transform,
  margin,
  marginTop,
  boxshadow,
  background,
  cursor,
  onMouseEnter,
  onMouseLeave,
  visibility,
  transition,
  opacity,
  gap,
  flexdirection,
  onKeyDown,
  flexgrow,
  tabIndex,
  outline,
  onResize,
  maxWidth,
  overflow,
}) => {
  return (
    <StyledWrapper
      onResize={onResize}
      id={id}
      ref={innerRef}
      onKeyDown={onKeyDown}
      className={className}
      border={border}
      height={height}
      width={width}
      position={position}
      right={right}
      left={left}
      top={top}
      bottom={bottom}
      borderradius={borderradius}
      padding={padding}
      color={color}
      zindex={zindex}
      bordertop={bordertop}
      display={display}
      justifyContent={justifyContent}
      alignItems={alignItems}
      stylesWrapper={styles}
      onClick={onClick}
      focusColor={focusColor}
      hoverColor={hoverColor}
      transform={transform}
      margin={margin}
      marginTop={marginTop}
      boxshadow={boxshadow}
      background={background}
      cursor={cursor}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      visibility={visibility}
      transition={transition}
      opacity={opacity}
      gap={gap}
      flexdirection={flexdirection}
      flexgrow={flexgrow}
      tabIndex={tabIndex}
      outline={outline}
      maxWidth={maxWidth}
      overflow={overflow}
    >
      {children}
    </StyledWrapper>
  )
}

export default Wrapper
