import 'react-datepicker/dist/react-datepicker.css'
import DatePicker, { ReactDatePicker } from 'react-datepicker'
import * as Popper from '@popperjs/core'
import { StyledDatePickerWrapper } from 'components/organisms/TaskManagerModal/styles'

interface Props {
  selected: Date | null
  onChange: (date: Date | null) => void
  customInput?: React.ReactNode
  customInputRef?: React.RefObject<ReactDatePicker<never, undefined>> | null
  isClearable?: boolean
  open?: boolean
  onClickOutside?: () => void
  onInputClick?: () => void
  placement?: Popper.Placement
}

const CustomDatePicker = (props: Props) => {
  return (
    <StyledDatePickerWrapper>
      <DatePicker
        selected={props.selected}
        ref={props.customInputRef}
        wrapperClassName='date-picker'
        onChange={props.onChange}
        customInput={props.customInput}
        todayButton='Today'
        isClearable={props.isClearable}
        open={props.open}
        popperPlacement={props.placement || 'bottom-start'}
        onClickOutside={props.onClickOutside}
        onInputClick={props.onInputClick}
      />
    </StyledDatePickerWrapper>
  )
}

export default CustomDatePicker
