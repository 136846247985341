import {
  setIsSliderTaskOpen,
  setModalOpen,
  setSelectedTaskDocument,
  setSelectedTaskId,
} from 'redux/reducers/taskManagerReducer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { ITask } from 'interfaces/taskManager'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeleteTask } from '_features/task'

const useTaskTitle = () => {
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const dispatch = useAppDispatch()
  const _deleteTask = useDeleteTask()
  const navigate = useNavigate()
  const params = useParams()
  const spaceId = params.spaceId
  const projectId = params.id

  const closeModalHandler = () => {
    dispatch(setModalOpen(false))
    if (spaceId && projectId && selectedPage) {
      navigate(`/spaces/${spaceId}/project-file/${projectId}?pageId=${selectedPage.id}`)
    }
    setTimeout(() => {
      dispatch(setSelectedTaskId(undefined))
      dispatch(setSelectedTaskDocument(undefined))
    }, 500)
  }

  const handleDeleteTask = (task: ITask) => {
    if (task) {
      _deleteTask.deleteTask(task)
      closeModalHandler()
      dispatch(setIsSliderTaskOpen(false))
    }
  }

  return {
    handleDeleteTask,
  }
}

export default useTaskTitle
