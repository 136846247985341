import { block } from '_entities/block'
import { sizes } from './types'
import { getSizeFromGridLength } from '_entities/whiteboard'

export const getFrameHtmlId = (blockId: string) => {
  return `frame-${blockId}`
}

export const getFrameKonvaRectId = (blockId: string) => {
  return `frame-rect-${blockId}`
}

export const getFrame = (block: block) => {
  return block.data.frame
}

export const getFill = (block: block) => {
  return block.data.frame?.fill
}

export const getBackgroundImage = (block: block) => {
  return block.data.frame?.backgroundImage
}

export const getThumbnail = (block: block) => {
  return block.data.frame?.thumbnail
}

export const getBorderColor = (block: block) => {
  return block.data.frame?.borderColor
}

export const getBorderWidth = (block: block) => {
  return block.data.frame?.borderWeight
}

export const getName = (block: block) => {
  return block.data.frame?.name
}

export const gridSizes = {
  [sizes.FRAME_SIXTEEN_NINE]: {
    width: getSizeFromGridLength(5),
    height: getSizeFromGridLength(3),
  },
  [sizes.FRAME_FOUR_THREE]: {
    width: getSizeFromGridLength(4),
    height: getSizeFromGridLength(3),
  },
  [sizes.FRAME_ONE_ONE]: {
    width: getSizeFromGridLength(3),
    height: getSizeFromGridLength(3),
  },
  [sizes.FRAME_CUSTOM]: {
    width: getSizeFromGridLength(14),
    height: getSizeFromGridLength(10),
  },
}

export const frames = {
  [sizes.FRAME_SIXTEEN_NINE]: {
    name: sizes.FRAME_SIXTEEN_NINE,
  },
  [sizes.FRAME_FOUR_THREE]: {
    name: sizes.FRAME_FOUR_THREE,
  },
  [sizes.FRAME_ONE_ONE]: {
    name: sizes.FRAME_ONE_ONE,
  },
  [sizes.FRAME_CUSTOM]: {
    name: sizes.FRAME_CUSTOM,
  },
}
