import FullWidthLogo from 'components/templates/FullScreenLoader/FullScreenLoader'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { getPage } from '_entities/page'
import { useViewer } from '_entities/viewer'
import { useAppSelector } from 'redux/hooks'
type Props = {
  children: React.ReactNode
}

const ProtectedRoute = (props: Props) => {
  const [isCheckFinished, setIsCheckFinished] = useState<boolean>(false)
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const projectId = params.id
  const pageId = searchParams.get('pageId')
  const currentUser = useAppSelector((state) => state.global.user)
  const _viewer = useViewer()

  const checkPublicPage = async (projectId: string, pageId: string) => {
    const page = await getPage(projectId, pageId)
    if (page) {
      await _viewer.getAndSaveViewer()
      setIsCheckFinished(true)
    } else navigate('/signin')
  }

  const performChecks = async () => {
    if (!currentUser) {
      const user = await _viewer.getAndSaveViewer()
      if (user) {
        setIsCheckFinished(true)
      } else {
        if (projectId && pageId) {
          checkPublicPage(projectId, pageId)
        } else navigate('/signin')
      }
    } else {
      setIsCheckFinished(true)
    }
  }

  useEffect(() => {
    performChecks()
  }, [])

  return (
    <>
      {!isCheckFinished && <FullWidthLogo />}
      {isCheckFinished && props.children}
    </>
  )
}
export default ProtectedRoute
