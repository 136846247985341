import { useAppSelector } from 'redux/hooks'
import { Icon, useIcons } from 'shared/icons'
import useOnPageClick from 'utils/useOnPageClick'
import { SingleValue } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import { TreeItem } from '_entities/tree'
import { PageTypesEnum } from 'interfaces/projectFile'

interface Props {
  handleCloseModal: () => void
}

export const useFileSearch = (props: Props) => {
  const _icons = useIcons()
  const _onPageClick = useOnPageClick()
  const pages = useAppSelector((state) => state.projectFile.pages)

  const handleOnClick = (e: SingleValue<Option>) => {
    if (!e) return
    props.handleCloseModal()
    _onPageClick(e.data.id, '', '', e.data.projectFileId)
  }

  return {
    handleOnClick,
    pages: pages
      ?.filter((page) => page.type !== PageTypesEnum.TASK_MANAGER)
      .map((option) => ({
        value: option.text.toLowerCase(),
        label: option.text,
        id: option.id,
        icon: <Icon Icon={_icons.getIcon({ pageNode: option as TreeItem })} />,
        data: option,
      })),
  }
}
