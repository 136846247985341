import {
  ChevronRightIconRotating,
  Divider,
  DropdownContent,
  DropdownHeader,
  EmbedContainer,
  SidebarDropdown,
  StyledTitle,
} from '../styles'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import BlockEdit from '../BlockEdit/BlockEdit'
import BoldItalicStrikethrough from './BoldItalicStrikethrough'
import TextAndBackgroundColor from './TextAndBackgroundColor'
import { useEffect } from 'react'
import { PageTypesEnum } from 'interfaces/projectFile'
import { type block } from '_entities/block'
import { useAppSelector } from 'redux/hooks'
import { useEmbed, EmbedEdit } from '_entities/embed'

interface Props {
  shownTab: string | null
  handleShownTab: (tab: 'edit' | 'info' | null) => void
  textFormat: string
  block: block
  BlockColor: string
  BlockBackground: string
  pageType: PageTypesEnum
}

const Editing = (props: Props) => {
  const { shownTab, handleShownTab, block, textFormat, BlockBackground, BlockColor, pageType } =
    props

  const isEditing = useAppSelector((state) => state.page.isEditing)

  const _embed = useEmbed()

  useEffect(() => {
    if (isEditing) {
      handleShownTab('edit')
    }
  }, [isEditing])

  return (
    <SidebarDropdown>
      <DropdownHeader
        onClick={shownTab !== 'edit' ? () => handleShownTab('edit') : () => handleShownTab(null)}
      >
        <StyledTitle>Editing</StyledTitle>
        {shownTab === 'edit' ? <ChevronRightIconRotating turn /> : <ChevronRightIconRotating />}
      </DropdownHeader>
      <DropdownContent>
        <EmbedContainer display={shownTab !== 'edit' ? 'none' : 'initial'} top={0}>
          {_embed.checkIfSelectedBlockIsEmbed(block) ? (
            <Wrapper marginTop='8px'>
              <EmbedEdit block={block} />
            </Wrapper>
          ) : (
            <>
              <Wrapper marginTop='8px'>
                <BlockEdit
                  {...{
                    block,
                    textFormat,
                  }}
                />
              </Wrapper>

              <Divider />

              <BoldItalicStrikethrough block={block} />

              <Divider />

              <TextAndBackgroundColor
                {...{
                  block,
                  BlockBackground,
                  BlockColor,
                  pageType,
                }}
              />
            </>
          )}
        </EmbedContainer>
      </DropdownContent>
    </SidebarDropdown>
  )
}

export default Editing
