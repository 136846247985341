import { type block, IBlockTypes } from '_entities/block'

export const isCapableToHaveSizeChangeArrowsDisplayedInMenu = (block: block) => {
  /* Some textual types like checkboxes or lists with text should not pass the validation. */
  const allowedTypes = [
    IBlockTypes.LARGE,
    IBlockTypes.MEDIUM,
    IBlockTypes.SMALL,
    IBlockTypes.SUBHEADER,
    IBlockTypes.TEXT,
    IBlockTypes.TITLE,
    IBlockTypes.XLARGE,
  ]

  const condiitonToBeFit = allowedTypes.includes(block?.data?.tag as IBlockTypes)

  return condiitonToBeFit
}
