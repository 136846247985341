import { MultiValue } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import { StyledSelectItem } from '../styles'
import { createCategoriesByPropertyName } from 'utils/taskManager/createCategoriesByPropertyName'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { IPropertyDefinition } from 'interfaces/taskManager'
import { setSelectedFilterValues } from 'redux/reducers/taskManagerReducer'

interface UseFilterByDropdownProps {
  propertyDefinition: IPropertyDefinition
}

export const useFilterByDropdown = (props: UseFilterByDropdownProps) => {
  const {
    propertyDefinition: { name },
  } = props
  const dispatch = useAppDispatch()
  const tasks = useAppSelector((state) => state.taskManager.tasks)
  const selectedFilterValues = useAppSelector((state) => state.taskManager.selectedFilterValues)

  const onChange = (items: MultiValue<Option>) => {
    const updatedSelectedFilterValues = { ...selectedFilterValues }
    if (items.length) updatedSelectedFilterValues[name] = items
    else delete updatedSelectedFilterValues[name]

    dispatch(setSelectedFilterValues(updatedSelectedFilterValues))
  }

  const propertyOptions = tasks ? createCategoriesByPropertyName(tasks, name) : []

  const options: Option[] = propertyOptions.map((option) => {
    return {
      value: option[0],
      label: (
        <StyledSelectItem>
          <span className='px-1'>{option[0]}</span>
        </StyledSelectItem>
      ),
    }
  })

  return {
    onChange,
    options,
  }
}
