import { FileUploadButton } from 'react-file-utils'
import { AttachmentPreviewList, ChatAutoComplete, EmojiPicker } from 'stream-chat-react'
// Icon imports
import { ReactComponent as UploadFilesButton } from 'assets/images/upload-files-button.svg'
import { ReactComponent as DocumentIcon } from 'assets/images/file-description.svg'
import { ReactComponent as WhiteboardIcon } from 'assets/images/chalkboard.svg'

import useCustomMessageInput from './useCustomMessageInput'
import {
  ClearMentionButton,
  EmojiPickerButton,
  EmojiSelector,
  EmojiSelectorWrapper,
  InputFlat,
  InputFlatWrapper,
  InputTextAreaWrapper,
  MentionContainer,
  MentionName,
  SelectedObjectImage,
  SelectedObjectWrapper,
  SendMessageButton,
  UploadFilesButtonWrapper,
  PageNameWrapper,
  ClearMentionButtonWrapper,
  InputActionsWrapper,
  InputActionsIcons,
} from './styles'
import { IBlockTypes } from '_entities/block'
import { useEmbed } from '_entities/embed'
import { PageName, usePage } from '_entities/page'
import { useShape } from '_entities/shape'
import { useFrame } from '_entities/whiteboard'
import { BasicCallout } from 'shared/ui'
import { useIcons } from 'shared/icons'

export const CustomMessageInput = () => {
  const _messageInput = useCustomMessageInput()
  const _embed = useEmbed()
  const _shape = useShape()
  const _frame = useFrame()
  const _page = usePage()
  const _icons = useIcons()

  return (
    <InputFlat className='str-chat__input-flat str-chat__input-flat--send-button-active'>
      <InputFlatWrapper className='str-chat__input-flat-wrapper'>
        <EmojiPicker />
        {_messageInput.numberOfUploads > 0 && <AttachmentPreviewList />}
        <InputTextAreaWrapper className='str-chat__input-flat--textarea-wrapper'>
          {_messageInput.selectedObject && (
            <SelectedObjectWrapper>
              {
                <ClearMentionButtonWrapper>
                  <ClearMentionButton onClick={_messageInput.handleClearMention} />
                </ClearMentionButtonWrapper>
              }

              <MentionContainer backgroundColor={_shape.getShapeFill(_messageInput.selectedObject)}>
                {_messageInput.selectedObject?.data.tag === IBlockTypes.IMAGE ? (
                  <SelectedObjectImage src={`${_messageInput.selectedObject?.data.imageUrl}`} />
                ) : _embed.isEmbedBlock(_messageInput.selectedObject) &&
                  _messageInput.selectedPage ? (
                  <BasicCallout
                    onClick={() =>
                      _messageInput.selectedObject &&
                      window.open(_embed.getUrl(_messageInput.selectedObject), '_blank')
                    }
                    block={_messageInput.selectedObject}
                    title={_embed.getExternalEmbedTitle(_messageInput.selectedObject)}
                    Icon={_icons.getIcon({ block: _messageInput.selectedObject })}
                  />
                ) : _messageInput.selectedObject?.data.tag === IBlockTypes.EMBED ||
                  _messageInput.selectedObject?.data.tag === IBlockTypes.WHITEBOARD ||
                  _messageInput.selectedObject?.data.tag === IBlockTypes.DOCUMENT ? (
                  <PageNameWrapper>
                    {_messageInput.selectedObject?.data?.documentPage?.icon ? (
                      _messageInput.selectedObject?.data?.documentPage?.icon
                    ) : _messageInput.selectedObject?.data?.documentPage?.type === 'document' ? (
                      <DocumentIcon />
                    ) : (
                      <WhiteboardIcon />
                    )}{' '}
                    {_page.getPageTitle(_messageInput.selectedObject?.data?.documentPage?.id)}
                  </PageNameWrapper>
                ) : (
                  <MentionName>
                    {_frame.isFrameBlock(_messageInput.selectedObject)
                      ? 'Frame'
                      : _messageInput.selectedBlockText}
                  </MentionName>
                )}
              </MentionContainer>
              {_messageInput.selectedPage && <PageName page={_messageInput.selectedPage} />}
            </SelectedObjectWrapper>
          )}

          <ChatAutoComplete />
          <InputActionsWrapper>
            <InputActionsIcons>
              <FileUploadButton handleFiles={_messageInput.uploadNewFiles} />
              <UploadFilesButtonWrapper>
                <UploadFilesButton />
              </UploadFilesButtonWrapper>
              <EmojiSelectorWrapper>
                <EmojiSelector
                  onClick={
                    _messageInput.emojiPickerIsOpen
                      ? _messageInput.closeEmojiPicker
                      : _messageInput.openEmojiPicker
                  }
                  onKeyDown={_messageInput.handleEmojiKeyDown}
                  role='button'
                  tabIndex={0}
                >
                  <EmojiPickerButton />
                </EmojiSelector>
              </EmojiSelectorWrapper>
            </InputActionsIcons>
            <SendMessageButton onClick={_messageInput.handleSubmit} />
          </InputActionsWrapper>
        </InputTextAreaWrapper>
      </InputFlatWrapper>
    </InputFlat>
  )
}
