import RadixDropdown, {
  DropdownMenuItem,
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import CustomP from 'components/atoms/CustomP/CustomP'
import React from 'react'
import { IProjectFile } from 'interfaces/space'
import { icons } from 'shared/icons'
import { getColor } from 'shared/colors'
import Line from 'shared/ui/HorizontalLine'
import { StyledDeleteIcon, StyledDots } from 'components/organisms/SpaceProjects/styles'

interface Props {
  projectFile: IProjectFile
  handleModalShow: (e: React.MouseEvent, project: IProjectFile) => void
  handleRenameModalOpen: (projectId: IProjectFile) => void
  handleDuplicateProjectFile: (projectFileId: number) => void
  handleDeleteProjectFile: (projectFileId: number) => void
  handleCopyLink: (projectId: string) => void
}

const ProjectMenuDropdown = (props: Props) => {
  return (
    <RadixDropdown
      trigger={
        <IconButton>
          <StyledDots />
        </IconButton>
      }
    >
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation()
          props.handleRenameModalOpen(props.projectFile)
        }}
      >
        <icons.edit />
        <CustomP marginBottom='0' borderradius='5.5px' fontWeight='500' fontSize='14px'>
          Rename project
        </CustomP>
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation()
          props.handleDuplicateProjectFile(props.projectFile.id)
        }}
      >
        <icons.duplicate />
        <CustomP marginBottom='0' borderradius='5.5px' fontWeight='500' fontSize='14px'>
          Duplicate project
        </CustomP>
      </DropdownMenuItem>
      <Line height={'1px'} />
      <DropdownMenuItem onClick={(e) => props.handleModalShow(e, props.projectFile)}>
        <icons.arrowDoubleForward />
        <CustomP marginBottom='0' borderradius='5.5px' fontWeight='500' fontSize='14px'>
          Make a copy in...
        </CustomP>
      </DropdownMenuItem>
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation()
          props.handleCopyLink(props.projectFile.id.toString())
        }}
      >
        <icons.link />
        <CustomP marginBottom='0' borderradius='5.5px' fontWeight='500' fontSize='14px'>
          Copy link
        </CustomP>
      </DropdownMenuItem>
      <Line height={'1px'} />
      <DropdownMenuItem
        onClick={(e) => {
          e.stopPropagation()
          props.handleDeleteProjectFile(props.projectFile.id)
        }}
      >
        <StyledDeleteIcon />
        <CustomP
          marginBottom='0'
          borderradius='5.5px'
          fontWeight='500'
          fontSize='14px'
          color={getColor('--everyday-red')}
        >
          Delete project
        </CustomP>
      </DropdownMenuItem>
    </RadixDropdown>
  )
}

export default ProjectMenuDropdown
