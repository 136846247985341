import { useState } from 'react'

export const useAuthInput = () => {
  const [passwordShown, setPasswordShown] = useState<boolean>(false)
  const [isPasswordFocused, setIsPasswordFocused] = useState<boolean>(false)

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  return {
    passwordShown,
    setPasswordShown,
    isPasswordFocused,
    setIsPasswordFocused,
    togglePassword,
  }
}
