import { DefaultsType } from 'interfaces/settings'
import { IProjectFile, ISpace } from 'interfaces/space'
import { IPage } from 'interfaces/page'
import { Option } from 'interfaces/selectOptions'
import { PropsValue } from 'react-select'
import WorkspaceOption from './WorkspaceOption'
import { SelectStyles, StyledWrapper } from 'components/organisms/SingleSelect/styles'
import { icons } from 'shared/icons'
import { AsyncPaginate } from 'react-select-async-paginate'

interface Props {
  shownModal: DefaultsType | null
  options: ISpace[] | IProjectFile[] | IPage[]
  handleSelectedOption: (e: Option) => void
  selectedDefault: Option | null
  selectedOption: Option | null
  handleShowIcon: (item: DefaultsType | null) => JSX.Element
  loadSpaceOptions: (
    search: string,
    loadedOptions: Option[],
    { page }: { page: number },
  ) => Promise<any>
  loadProjectOptions: (
    search: string,
    loadedOptions: Option[],
    { page }: { page: number },
  ) => Promise<any>
}

const DefaultProjectWorkspace = (props: Props) => {
  return (
    <AsyncPaginate
      value={props.selectedDefault?.id as unknown as PropsValue<Option>}
      loadOptions={
        props.shownModal === 'workspace'
          ? (props.loadSpaceOptions as any)
          : (props.loadProjectOptions as any)
      }
      placeholder={`Search ${props.shownModal}s`}
      isSearchable
      formatOptionLabel={(data) =>
        WorkspaceOption({
          data: {
            ...data,
            id: data.value,
          },
          selectedOption:
            props.selectedOption?.id?.toString() || props.selectedDefault?.id?.toString(),
          shownModal: props.shownModal,
          icon: props.handleShowIcon(props.shownModal),
        })
      }
      additional={{
        page: 1,
      }}
      menuIsOpen
      onChange={(e: any) => props.handleSelectedOption(e)}
      styles={SelectStyles(false)}
      controlShouldRenderValue={false}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Control: (props) => {
          return (
            <StyledWrapper hideControl={false}>
              <icons.magnifyingGlass />
              {props.children}
            </StyledWrapper>
          )
        },
      }}
    />
  )
}

export default DefaultProjectWorkspace
