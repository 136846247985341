import React, { useRef, useState } from 'react'
import { downloadImageOnClickRequest } from 'utils/downloadImageOnClickRequest'
import { SourceKeys } from 'interfaces/editor'
import { useToast } from 'services/helpers/useToast'
import { useUpload } from '_features/upload/model/useUpload'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { block, getBlockPageId, getIndex } from '_entities/block'
import {
  setIsImageModalShown,
  setSelectedBlock as setSelectedDocumentBlock,
} from 'redux/reducers/pageReducer'
import { useTransformer } from '_features/canvas'
import { setSelectedBlock } from 'redux/reducers/whiteboardReducer'
import { REPLACE_IMAGE_IN_PROGRESS, TIMEOUT_SHORT_DELAY } from '_features/editor'
import { isSafari } from 'shared/lib'

const useImage = () => {
  const [scale, setScale] = React.useState(100)
  const [isModalShown, setIsModalShown] = useState<boolean>(false)

  const toast = useToast()
  const _upload = useUpload()
  const dispatch = useAppDispatch()
  const _submit = useSubmit()
  const _json = useJson1()
  const _transformer = useTransformer()

  const selectedBlockWhiteboard = useAppSelector((state) => state.whiteboard.selectedBlock)
  const selectedBlockDocument = useAppSelector((state) => state.page.selectedBlock)
  const isImageModalShown = useAppSelector((state) => state.page.isImageModalShown)

  const replaceAndUploadImageInputRef = useRef<HTMLInputElement>(null)
  const handleDownloadStart = (e: React.MouseEvent, block?: block) =>
    onDownloadIconClick(e, block?.data.imageUrl)

  const onDownloadIconClick = (e: React.MouseEvent, imageUrl?: string) => {
    e.stopPropagation()
    downloadImageOnClickRequest(imageUrl, isSafari())
  }

  const handleReplaceIconStart = () => {
    replaceAndUploadImageInputRef.current?.click()
  }

  const handleImageFileReplacement = async () => {
    setTimeout(() => toast.successBlack(REPLACE_IMAGE_IN_PROGRESS), TIMEOUT_SHORT_DELAY)
    handleReplaceImage(selectedBlockDocument || selectedBlockWhiteboard)
  }

  const handleReplaceImage = (selectedBlock?: block) => {
    if (!selectedBlock) return
    const pageId = getBlockPageId(selectedBlock)

    replaceAndUploadImageInputRef.current?.files &&
      _upload.handleFileUpload(
        replaceAndUploadImageInputRef.current?.files[0],
        (fileName, fileType, link) => {
          const newBlock = {
            ...selectedBlock,
            data: {
              ...selectedBlock.data,
              imageUrl: link,
            },
          }

          if (!selectedBlock?.meta?.pageId) return

          const index = getIndex(selectedBlock)

          const op = _json.replaceBlock(newBlock, index)

          _submit.submit(pageId, op, SourceKeys.UPDATE_BLOCK)

          _transformer.removeNodesFromTransformer(newBlock.meta.pageId)
          dispatch(setSelectedBlock(undefined))
          dispatch(setSelectedDocumentBlock(undefined))
        },
      )
  }

  const onDiagonalIconClick = () => {
    setIsModalShown(true)
    dispatch(setIsImageModalShown(true))
  }

  const onShow = () => setIsModalShown(true)

  const onCloseModal = () => {
    dispatch(setIsImageModalShown(false))
    setIsModalShown(false)
  }

  const onSizePlusClick = () => {
    if (scale === 200) return
    setScale(scale + 10)
  }

  const onSizeMinusClick = () => {
    if (scale === 30) return
    setScale(scale - 10)
  }

  return {
    replaceAndUploadImageInputRef,
    handleReplaceIconStart,
    handleImageFileReplacement,
    handleDownloadStart,
    onDiagonalIconClick,
    onShow,
    onCloseModal,
    isModalShown,
    onSizePlusClick,
    onSizeMinusClick,
    scale,
    handleReplaceImage,
    isImageModalShown,
    selectedBlock: selectedBlockDocument || selectedBlockWhiteboard,
  }
}

export default useImage
