import {
  DetachIcon,
  DotsIcon,
  DotsIconWrapper,
  GoToIcon,
  PageDropdownMenuItemText,
  StyledDropdown,
  StyledInstanceIcon,
  StyledInstanceIndicator,
  StyledIsMirrorIcon,
  StyledMirrorWhiteIcon,
} from '../styles'
import { RelationshipContainer } from 'components/organisms/ProjectFileRightSideBar/styles'
import getNameFromPageId from 'utils/editor/getNameFromPageId'
import React from 'react'
import { type block } from '_entities/block'
import { RelationshipType } from '_features/relationship'
import { IPages } from 'interfaces/page'
import { DropdownMenuItem } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

interface IProps {
  block: block
  onPageClick: (pageId: string, blockId: string) => void
  pages: IPages[] | undefined
  handleDetachFromRelationship: (block: block) => void
  handleSwitchRelationshipType: (type: RelationshipType, e: React.MouseEvent, id: string) => void
}

const OriginalMirror = ({
  block,
  onPageClick,
  pages,
  handleDetachFromRelationship,
  handleSwitchRelationshipType,
}: IProps) => {
  return (
    <StyledDropdown
      trigger={
        <StyledInstanceIndicator>
          <StyledIsMirrorIcon isScaled />
        </StyledInstanceIndicator>
      }
    >
      <RelationshipContainer>
        <PageDropdownMenuItemText
          key={block._id}
          onClick={() => {
            onPageClick(
              block.meta.originalObject?.pageOfOriginalBlock as string,
              block.meta.originalObject?._id as string,
            )
          }}
        >
          <StyledMirrorWhiteIcon />
          {getNameFromPageId(block.meta.originalObject?.pageOfOriginalBlock ?? '', pages)}
        </PageDropdownMenuItemText>
        <StyledDropdown
          trigger={
            <DotsIconWrapper>
              <DotsIcon />
            </DotsIconWrapper>
          }
          top='120px'
        >
          <DropdownMenuItem
            onClick={() => {
              onPageClick(
                block.meta.originalObject?.pageOfOriginalBlock as string,
                block.meta.originalObject?._id as string,
              )
            }}
          >
            <GoToIcon />
            Go to
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleDetachFromRelationship(block)}>
            <DetachIcon />
            Detach
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(e) => handleSwitchRelationshipType(RelationshipType.INSTANCE, e, block._id)}
          >
            <StyledInstanceIcon />
            Switch to instance
          </DropdownMenuItem>
        </StyledDropdown>
      </RelationshipContainer>
    </StyledDropdown>
  )
}

export default OriginalMirror
