import React, { Fragment } from 'react'
import Table from 'editor/EditorTable'
import EditorTextBlock from '../EditorTextBlock/EditorTextBlock'
import { InternalEmbedBlock, useEmbed } from '_entities/embed'
import { type block, IBlockTypes, isBlockGenericLink } from '_entities/block'
import { EditorEmbedFrame } from '_features/embed/ui/EditorEmbedFrame'
import { getBlockStyling } from 'utils/editor/getBlockStyling'
import { EmbedAddButton } from '_features/external-embed-mutation'
import { BasicCallout } from 'shared/ui'
import { useTextBlock } from '_entities/text'
import { EditorImageBlock } from '_features/editor'
import { IPermissions } from '_entities/user'
import { IUserAttributes } from 'interfaces/auth/userData'
import { CTAButton, getBlockCtaId } from '_entities/cta'

interface Props {
  block: block
  userRole?: IPermissions
  userPresence?: IUserAttributes
  isPreview?: boolean
  actionMenuOpen: boolean
  closeActionMenu: () => void
  openActionMenu: () => void
  handleKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void
  activeToolbarOption: string | null
  setActionMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  searchText?: string
  isPageSearch?: boolean
}

const BlockRenderer = (props: Props) => {
  const _textBlock = useTextBlock()
  const _embed = useEmbed()

  return (
    <Fragment>
      {_textBlock.doesBlockContainText(props.block) && (
        <EditorTextBlock
          block={props.block}
          userRole={props.userRole}
          openActionMenu={props.openActionMenu}
          searchText={props.searchText}
          isPreview={props.isPreview}
        />
      )}

      {props.block.data.tag === IBlockTypes.IMAGE && (
        <EditorImageBlock
          isSelected={false}
          block={props.block}
          actionMenuOpen={props.actionMenuOpen}
          userRole={props.userRole}
          userPresence={props.userPresence}
          setActionMenuOpen={props.setActionMenuOpen}
          openActionMenu={props.openActionMenu}
        />
      )}

      {props.block.data.tag === IBlockTypes.TABLE && (
        <Table
          actionMenuOpen={props.actionMenuOpen}
          blockId={props.block._id}
          tableState={props.block.data.tableData}
          style={getBlockStyling(props.block)}
          block={props.block}
        />
      )}

      {_embed.isEmbedBlock(props.block) && !_embed.getEmbedUrl(props.block) && (
        <EmbedAddButton block={props.block} />
      )}

      {isBlockGenericLink(props.block) && !_embed.getEmbedUrl(props.block) && (
        <EmbedAddButton block={props.block} />
      )}

      {_embed.isEmbedBlock(props.block) && _embed.getEmbedUrl(props.block) && (
        <EditorEmbedFrame block={props.block} />
      )}

      {isBlockGenericLink(props.block) && _embed.getEmbedUrl(props.block) && (
        <BasicCallout
          block={props.block}
          title={props.block.data.embed?.title}
          Icon={props.block.data.embed?.image}
          freshlyCreatedBlockPageId={''}
          onClick={() => {
            _embed.getEmbedUrl(props.block) &&
              window.open(_embed.getEmbedUrl(props.block) as string, '_blank')
          }}
          shouldSliceText
        />
      )}

      {getBlockCtaId(props.block) && <CTAButton block={props.block} />}

      {(props.block.data.tag === IBlockTypes.DOCUMENT ||
        props.block.data.tag === IBlockTypes.WHITEBOARD ||
        props.block.data.tag === IBlockTypes.EMBED ||
        props.block.data.tag === IBlockTypes.SLIDESHOW) && (
        <InternalEmbedBlock block={props.block} isDocument isPreview={props.isPreview} />
      )}
    </Fragment>
  )
}

export default BlockRenderer
