import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  margin-block: 2rem 1.5rem;
`

export const ReferralTrack = styled.div`
  display: flex;
  align-items: center;
  gap: 0.65rem;
  user-select: none;

  * {
    font-size: 14px !important;
  }
`

export const HorizontalContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
`
