import styled from 'styled-components'
import { ReactComponent as OneIcon } from 'assets/images/property/looks_one.svg'
import { ReactComponent as OpenInNewIcon } from 'assets/images/property/open_in_new.svg'
import { ReactComponent as SystemWindowCheckIcon } from 'assets/images/property/SystemWindowCheck.svg'
import { ReactComponent as UserIcon } from 'assets/images/property/User.svg'
import { ReactComponent as CalendarIcon } from 'assets/images/property/CalendarBlank.svg'
import { ReactComponent as TextAaIcon } from 'assets/images/property/TextAa.svg'

export const StyledUserIcon = styled(UserIcon)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledCalendarIcon = styled(CalendarIcon)`
  path {
    fill: var(--primary-text);
  }
`

export const StyledTextAaIcon = styled(TextAaIcon)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledOneIcon = styled(OneIcon)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledSystemWindowCheckIcon = styled(SystemWindowCheckIcon)`
  path {
    stroke: var(--primary-text);
  }
`
