import React from 'react'
import { type block } from '_entities/block'
import { RelationshipType } from '_features/relationship'
import { IPages } from 'interfaces/page'
import getNameFromPageId from 'utils/editor/getNameFromPageId'
import useOnPageClick from 'utils/useOnPageClick'
import {
  DetachIcon,
  DotsIcon,
  DotsIconWrapper,
  GoToIcon,
  RelationshipContainer,
  RelationshipContainerWrapper,
  StyledDropdown,
  PageDropdownMenuItem,
  PageDropdownMenuItemText,
  StyledInstanceIcon,
} from './styles'
import { DropdownMenuItem } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
type Props = {
  currentBlock?: block
  pages: IPages[] | undefined
  handleDetachFromOriginal: (relationshipId: string, block: block, e: React.MouseEvent) => void
}
const RenderBlockInstances = ({ currentBlock, pages, handleDetachFromOriginal }: Props) => {
  const onPageClick = useOnPageClick()
  return (
    <RelationshipContainerWrapper>
      {currentBlock &&
      currentBlock.meta &&
      currentBlock.meta.relatedObjects &&
      currentBlock.meta.relatedObjects.length
        ? currentBlock.meta.relatedObjects.map((item, index) => {
            if (item)
              return (
                item.typeOfRelation !== RelationshipType.REFERENCE &&
                item.typeOfRelation === RelationshipType.INSTANCE && (
                  <RelationshipContainer>
                    <PageDropdownMenuItem
                      onClick={() => {
                        onPageClick(item.pageId as string, item._id)
                      }}
                      key={index}
                    >
                      <PageDropdownMenuItemText>
                        <StyledInstanceIcon />
                        {getNameFromPageId(item.pageId ?? '', pages)}
                      </PageDropdownMenuItemText>

                      <StyledDropdown
                        trigger={
                          <DotsIconWrapper>
                            <DotsIcon />
                          </DotsIconWrapper>
                        }
                        top='120px'
                      >
                        <DropdownMenuItem
                          onClick={() => {
                            onPageClick(item.pageId as string, item._id)
                          }}
                        >
                          <GoToIcon />
                          Go to
                        </DropdownMenuItem>
                        <DropdownMenuItem
                          onClick={(e) =>
                            handleDetachFromOriginal(item.relatedObject as string, currentBlock, e)
                          }
                        >
                          <DetachIcon />
                          Detach
                        </DropdownMenuItem>
                      </StyledDropdown>
                    </PageDropdownMenuItem>
                  </RelationshipContainer>
                )
              )
          })
        : null}
    </RelationshipContainerWrapper>
  )
}

export default RenderBlockInstances
