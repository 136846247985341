import { createSlice } from '@reduxjs/toolkit'
import { IPagePermission } from 'interfaces/invite'
import { IPage, IPageMember } from 'interfaces/page'
import { type block, IBlockTypes } from '_entities/block'
import { Option } from 'interfaces/selectOptions'

interface PageState {
  selectedPage?: IPage
  shareModalOpened?: boolean
  selectedBlock?: block
  members?: IPageMember[]
  pendingMembers?: IPageMember[]
  pageRolesOwner?: IPagePermission[]
  pageRoles?: Option[]
  rightSidebarOpen: boolean
  isImageModalShown?: boolean
  isEditing?: boolean
  fontSize?: number
  fontFamily?: string
}

const initialState = {
  shareModalOpened: false,
  isBlockSelectEnabled: true,
  selectedBlock: {
    _id: '',
    meta: {
      pageId: '',
      createdBy: '',
      tags: [],
      assignees: [],
      dueDate: null,
      createdAt: '',
      name: '',
    },
    data: {
      tag: IBlockTypes.TEXT,
      style: {
        color: '',
        strikeThrough: false,
        backgroundColor: '',
        bold: false,
        italic: false,
        underline: false,
        fontWeight: '',
        fontSize: '',
        fontFamily: '',
        link: '',
      },
    },
  },
  textColor: 'black',
  background: 'white',
  iconDropdownOpen: false,
  activityBlockTag: null,
  chatBlockTag: null,
  replyToId: null,
  isReplying: false,
  showCommentInput: false,
  rightSidebarOpen: false,
  isImageModalShown: false,
  isEditing: false,
} as PageState

const pageSlice = createSlice({
  name: 'pageReducer',
  initialState,
  reducers: {
    setSelectedBlock: (state, action) => {
      state.selectedBlock = action.payload
    },
    setSelectedPage: (state, action) => {
      state.selectedPage = action.payload
    },
    setShareModalOpened: (state, action) => {
      state.shareModalOpened = action.payload
    },
    setPageMembers: (state, action) => {
      state.members = action.payload
    },
    setPageRoles: (state, action) => {
      const rolesToSet = action.payload.map((role: any) => {
        return { name: role.type, value: role.id }
      })
      state.pageRolesOwner = [...rolesToSet.concat({ name: 'Remove access', value: 0 })]
      state.pageRoles = rolesToSet
    },
    setRightSidebarOpen: (state, action) => {
      state.rightSidebarOpen = action.payload
    },
    setIsImageModalShown: (state, action) => {
      state.isImageModalShown = action.payload
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload
    },
    setFontSize: (state, action) => {
      state.fontSize = action.payload
    },
    setFontFamily: (state, action) => {
      state.fontFamily = action.payload
    },
  },
})

export const {
  setSelectedBlock,
  setSelectedPage,
  setShareModalOpened,
  setPageMembers,
  setPageRoles,
  setRightSidebarOpen,
  setIsImageModalShown,
  setIsEditing,
  setFontFamily,
  setFontSize,
} = pageSlice.actions

export default pageSlice.reducer
