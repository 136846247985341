import { useEffect, useState } from 'react'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { AxiosService } from 'services/axiosService/axiosService'
import { setUserImage } from 'redux/reducers/globalReducer'

export const useSpaceNavigation = () => {
  const user = useAppSelector((state) => state.global.user)
  const [userImage, setLocalUserImage] = useState<string>()
  const dispatch = useAppDispatch()

  const axios = new AxiosService()

  const getProfileImage = async () => {
    if (!user) return
    const response = await axios.getUserProfileImage(user.id)

    if (response && response.data !== undefined) {
      setLocalUserImage(response.data)
      dispatch(setUserImage(response.data))
    }
  }

  useEffect(() => {
    getProfileImage()
  }, [])

  return {
    userImage,
    setUserImage,
  }
}
