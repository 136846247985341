import { GroupBase, OptionProps, components } from 'react-select'
import Wrapper from '../Wrapper/Wrapper'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import CheckMark from 'assets/images/check.svg'
import { Visible } from '../CustomSelectOptions/styles'
import { OptionWrapper } from './styles'

interface CustomOptionProps
  extends OptionProps<
    {
      name: JSX.Element
      label: string
      icon: JSX.Element
      value: number
      color: string
      topborder: boolean
      onClick: () => Promise<void>
      bordertop: string
      borderRadius: string
      isCheckmark: boolean
      isBorder: boolean
    },
    boolean,
    GroupBase<{
      name: JSX.Element
      label: string
      icon: JSX.Element
      value: number
      color: string
      topborder: boolean
      onClick: () => Promise<void>
      bordertop: string
      borderRadius: string
      isCheckmark: boolean
      isBorder: boolean
    }>
  > {
  styles: string
  data: {
    name: JSX.Element
    label: string
    icon: JSX.Element
    value: number
    color: string
    topborder: boolean
    onClick: () => Promise<void>
    bordertop: string
    borderRadius: string
    isCheckmark: boolean
    isBorder: boolean
  }
}

const { Option } = components
const NewSelectOption = (props: CustomOptionProps) => {
  return (
    <OptionWrapper
      onClick={() => props.data.onClick && props.data.onClick()}
      borderTop={props.data.bordertop}
      borderRadius={props.data.borderRadius}
    >
      <Option {...props}>
        <Wrapper
          display='flex'
          justifyContent='left'
          width='100%'
          padding={props.data.isCheckmark ? '0 0 0 10px' : '0'}
          alignItems='center'
        >
          {props.data.icon}
          {props.data.isCheckmark && (
            <Visible isSelected={props.selectProps.placeholder === props.data.label}>
              <CustomIcon src={CheckMark} />
            </Visible>
          )}
          {props.data.name}
        </Wrapper>
      </Option>
    </OptionWrapper>
  )
}

export default NewSelectOption
