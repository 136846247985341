import { useAppSelector } from 'redux/hooks'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useUser } from 'services/user/useUser'

export const useSpaceSidebar = () => {
  const [show, setShow] = useState<boolean>(false)
  const [mySpaces, setMySpaces] = useState<any[]>([])
  const [sharedSpaces, setSharedSpaces] = useState<any>([])
  const [createNewSpaceIsHovered, setCreateNewSpaceIsHovered] = useState<boolean>(false)
  const [sharedSpacesIsHovered, setsharedSpacesIsHovered] = useState<boolean>(false)
  const navigate = useNavigate()
  const params = useParams()
  const { getSpaces } = useUser()
  const projectFiles = useAppSelector((state) => state.space.projectFiles)
  const filteredProjectFiles = useAppSelector((state) => state.space.filteredProjectFiles)

  const getAndSetSpaces = async () => {
    const { mySpaces, sharedSpaces } = await getSpaces()
    setMySpaces(mySpaces)
    setSharedSpaces(sharedSpaces)
  }

  useEffect(() => {
    getAndSetSpaces()
  }, [])

  return {
    navigate,
    params,
    projectFiles,
    sharedSpaces,
    filteredProjectFiles,
    createNewSpaceIsHovered,
    setCreateNewSpaceIsHovered,
    sharedSpacesIsHovered,
    setsharedSpacesIsHovered,
    show,
    setShow,
    mySpaces,
  }
}
