import React from 'react'
import Image from 'components/atoms/Image/Image'
import { StyledSpaceTabBodyHeaderText } from './styles'
import ArrowDown from 'assets/images/chevron-down.svg'

interface Props {
  text: string
}

const SpaceTabBodyHeaderText = ({ text }: Props) => {
  return (
    <StyledSpaceTabBodyHeaderText>
      {text}
      <Image src={ArrowDown} alt='Arrow down' width='16px' height='16px' />
    </StyledSpaceTabBodyHeaderText>
  )
}

export default SpaceTabBodyHeaderText
