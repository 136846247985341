// ======= CANVAS ELEMENT STATE =======

import {
  removeCanvasElemAttr,
  setCanvasElemAttr,
  WhiteboardAttrs,
  WhiteboardAttrsData,
  getCanvasElemAttr,
  NodeFit,
} from '_features/canvas'
import { IFrameItem } from 'interfaces/whiteboard'

// ======= IS PRESENTATION =======

export const getIsPresentation = (pageId: string) => {
  const isPresentation = getCanvasElemAttr(pageId, WhiteboardAttrs.IS_PRESENTATION)
  if (!isPresentation) return
  return JSON.parse(isPresentation) as WhiteboardAttrsData[WhiteboardAttrs.IS_PRESENTATION]
}

export const setIsPresentation = (pageId: string) => {
  setCanvasElemAttr(pageId, WhiteboardAttrs.IS_PRESENTATION, true)
}

export const removeIsPresentation = (pageId: string) => {
  removeCanvasElemAttr(pageId, WhiteboardAttrs.IS_PRESENTATION)
}

// ======= SHOULD SHOW CONTROLS =======

export const getShouldShowControls = (pageId: string) => {
  const shouldShowControls = getCanvasElemAttr(
    pageId,
    WhiteboardAttrs.SHOULD_SHOW_PRESENTATION_CONTROLS,
  )
  if (!shouldShowControls) return
  return JSON.parse(
    shouldShowControls,
  ) as WhiteboardAttrsData[WhiteboardAttrs.SHOULD_SHOW_PRESENTATION_CONTROLS]
}

export const setShouldShowControls = (pageId: string) => {
  setCanvasElemAttr(pageId, WhiteboardAttrs.SHOULD_SHOW_PRESENTATION_CONTROLS, true)
}

export const removeShouldShowControls = (pageId: string) => {
  removeCanvasElemAttr(pageId, WhiteboardAttrs.SHOULD_SHOW_PRESENTATION_CONTROLS)
}

// ======= CONTROLS ENABLED =======

export const getControlsEnabled = (pageId: string) => {
  const controlsEnabled = getCanvasElemAttr(pageId, WhiteboardAttrs.PRESENTATION_CONTROLS_ENABLED)
  if (!controlsEnabled) return
  return JSON.parse(
    controlsEnabled,
  ) as WhiteboardAttrsData[WhiteboardAttrs.PRESENTATION_CONTROLS_ENABLED]
}

export const setControlsEnabled = (pageId: string) => {
  setCanvasElemAttr(pageId, WhiteboardAttrs.PRESENTATION_CONTROLS_ENABLED, true)
}

export const removeControlsEnabled = (pageId: string) => {
  removeCanvasElemAttr(pageId, WhiteboardAttrs.PRESENTATION_CONTROLS_ENABLED)
}

// ======= FRAME FIT =======

export const getFrameFit = (pageId: string) => {
  const frameFit = getCanvasElemAttr(pageId, WhiteboardAttrs.PRESENTATION_FRAME_FIT)
  if (!frameFit) return
  return JSON.parse(frameFit) as WhiteboardAttrsData[WhiteboardAttrs.PRESENTATION_FRAME_FIT]
}

export const setFrameFit = (pageId: string, fit: NodeFit) => {
  setCanvasElemAttr(pageId, WhiteboardAttrs.PRESENTATION_FRAME_FIT, fit)
}

export const removeFrameFit = (pageId: string) => {
  removeCanvasElemAttr(pageId, WhiteboardAttrs.PRESENTATION_FRAME_FIT)
}

// ======= CURRENT FRAME =======

export const getCurrentFrame = (pageId: string) => {
  const currentFrame = getCanvasElemAttr(pageId, WhiteboardAttrs.CURRENT_FRAME)
  if (!currentFrame) return
  return JSON.parse(currentFrame) as WhiteboardAttrsData[WhiteboardAttrs.CURRENT_FRAME]
}

export const setCurrentFrame = (pageId: string, currentFrame: IFrameItem) => {
  setCanvasElemAttr(pageId, WhiteboardAttrs.CURRENT_FRAME, currentFrame)
}

export const removeCurrentFrame = (pageId: string) => {
  removeCanvasElemAttr(pageId, WhiteboardAttrs.CURRENT_FRAME)
}
