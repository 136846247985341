import React, { useState } from 'react'

const usePlanOverviewModal = () => {
  const [selectedPeriod, setSelectedPeriod] = useState<string>('Yearly')
  const spaceOptionsAray = ['1', '1', '-', '-']
  const teamsOptionsAray = ['1', '1', '-', '-']
  const filesOptionsArray = ['5 project files', 'Unlimited', '-', '-']
  const dataLimitOptionsArray = ['5 gb', 'Unlimited', '-', '-']
  const versionHistoryOptionsArray = ['30days', 'Unlimited', '-', '-']
  const clientRolesHistoryOptionsArray = ['-', '1', '-', '-']
  const guestsHistoryOptionsArray = ['10', 'Unlimited', '-', '-']
  const nestedComponentsOptionsArray = ['+', '+', '-', '-']
  const periodButtonArray = ['Yearly', 'Monthly']

  const plansArray = [
    { option: 'Space', data: spaceOptionsAray },
    { option: 'Teams', data: teamsOptionsAray },
    { option: 'Files', data: filesOptionsArray },
    { option: 'Data limit', data: dataLimitOptionsArray },
    { option: 'Version history', data: versionHistoryOptionsArray },
    { option: 'Client roles', data: clientRolesHistoryOptionsArray },
    { option: 'Guests', data: guestsHistoryOptionsArray },
    { option: 'Nested components', data: nestedComponentsOptionsArray },
  ]

  const tooltipText = [
    'The place where you can find your project files',
    'How many teams you can have in your space/s',
    'The number of project files you are allowd to create',
    'The data limit cap',
    'How long will your version history be stored on our servers',
    'The number of client roles you have',
    'The number of guests you are allowed to invite',
    'Are nested components allowed',
  ]
  return { selectedPeriod, setSelectedPeriod, periodButtonArray, plansArray, tooltipText }
}

export default usePlanOverviewModal
