import { SourceKeys } from 'interfaces/editor'
import { type block, IBlockTypes, getBlockPageId, getIndex } from '_entities/block'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { convertFromArabicToRoman, convertRomanToArabic } from 'utils/convertNumerics'
import { Doc as JsonDoc } from 'ot-json1/dist/types'
import { JSONOp } from 'ot-json1'
import { getBlocks } from 'shared/shareDb'

const useNumberedList = () => {
  const _json1 = useJson1()
  const _submit = useSubmit()

  const getBlockIndent = (block: block) => block.data.indent

  const addNumber = (number: number | string, firstNumber?: number | string | null) => {
    const isRoman = !Number.isNaN(convertRomanToArabic(firstNumber as string))

    if (typeof firstNumber === 'number') {
      return (number as number) + 1
    } else if (!isRoman && typeof firstNumber === 'string') {
      return String.fromCharCode(97 + (number as number))
    } else if (isRoman && typeof firstNumber === 'string') {
      return convertFromArabicToRoman((number as number) + 1)
    }
  }

  const splitNumberedList = (block: block) => {
    const endOfTheList = getListAfter(block)

    if (!endOfTheList) return

    endOfTheList.map((b, i) => {
      const index = getIndex(b)
      if (b.data.tag !== IBlockTypes.NUMBERED_LIST) return

      const newNumber = addNumber(i, endOfTheList[0]?.data.numberInList)

      if (index === -1 || newNumber === undefined) return

      const op = _json1.getReplaceBlockDataKeyOp(
        index,
        ['numberInList'],
        newNumber,
        b.data.numberInList,
      )
      _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
    })
  }

  const findPreviousNumberBlockInDocument = (block: block) => {
    const previousNumberBlocks = getPreviousNumberBlocks(block)

    if (previousNumberBlocks?.length === 0) return

    return previousNumberBlocks?.[previousNumberBlocks?.length - 1]
  }

  const findPreviousRomanBlockInDocument = (block: block) => {
    const previousRomanBlocks = getPreviousRomanBlocks(block)

    if (previousRomanBlocks?.length === 0) return

    return previousRomanBlocks?.[previousRomanBlocks?.length - 1]
  }

  const findPreviousCharacterBlockInDocument = (block: block) => {
    const previousCharacterBlocks = getPreviousCharacterBlocks(block)

    if (previousCharacterBlocks?.length === 0) return

    return previousCharacterBlocks?.[previousCharacterBlocks?.length - 1]
  }

  const getPreviousNumberBlocks = (block: block) => {
    const index = getIndex(block)
    if (index === -1) return
    const blocks = getBlocks(getBlockPageId(block))
    return blocks
      ?.slice(0, index)
      .map((block) =>
        block.data.tag === IBlockTypes.NUMBERED_LIST && typeof block.data.numberInList === 'number'
          ? block
          : undefined,
      )
      .filter((block) => block !== undefined)
  }

  const getPreviousRomanBlocks = (block: block) => {
    const index = getIndex(block)
    if (index === -1) return
    const blocks = getBlocks(getBlockPageId(block))
    return blocks
      ?.slice(0, index)
      .map((block) =>
        block.data.tag === IBlockTypes.NUMBERED_LIST &&
        typeof block.data.numberInList === 'string' &&
        !Number.isNaN(convertRomanToArabic(block.data.numberInList as string))
          ? block
          : undefined,
      )
      .filter((block) => block !== undefined)
  }

  const getPreviousCharacterBlocks = (block: block) => {
    const index = getIndex(block)
    if (index === -1) return
    const blocks = getBlocks(getBlockPageId(block))
    return blocks
      ?.slice(0, index)
      .map((block) =>
        block.data.tag === IBlockTypes.NUMBERED_LIST &&
        typeof block.data.numberInList === 'string' &&
        Number.isNaN(convertRomanToArabic(block.data.numberInList as string))
          ? block
          : undefined,
      )
      .filter((block) => block !== undefined)
  }

  const updateListItem = (block: block, newValue: JsonDoc | undefined) => {
    const index = getIndex(block)
    if (!newValue || index === -1) return
    const op = _json1.getReplaceBlockDataKeyOp(
      index,
      ['numberInList'],
      newValue,
      block.data.numberInList,
    )
    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  const getListBefore = (block: block) => {
    const blocks = getBlocks(block.meta.pageId)
    const index = getIndex(block)

    if (!blocks) return

    const listBefore: block[] = []

    for (let i = blocks.slice(0, index).length - 1; i >= 0; i--) {
      const b = blocks[i]

      if (b.data.tag !== IBlockTypes.NUMBERED_LIST) {
        break
      }

      if (block.data.indent && block.data.indent > 0 && getBlockIndent(b) === 0) {
        break
      }

      if (getBlockIndent(block) === getBlockIndent(b)) {
        listBefore.push(b)
      }
    }

    return listBefore.reverse()
  }

  const getListAfter = (block: block) => {
    const blocks = getBlocks(block.meta.pageId)
    const index = getIndex(block)

    if (!blocks) return

    const listAfter = []

    for (let i = index + 1; i < blocks.length; i++) {
      const b = blocks[i]

      if (b.data.tag !== IBlockTypes.NUMBERED_LIST) {
        break
      }

      if (block.data.indent && block.data.indent > 0 && getBlockIndent(b) === 0) {
        break
      }

      if (getBlockIndent(block) === getBlockIndent(b)) {
        listAfter.push(b)
      }
    }

    return listAfter
  }

  const updateList = (block: block) => {
    const beginningOfTheList = getListBefore(block)
    const endOfTheList = getListAfter(block)

    if (!beginningOfTheList || !endOfTheList) return

    const numberedList = [...beginningOfTheList, block, ...endOfTheList]

    submitUpdatedList(numberedList)
  }

  const submitUpdatedList = (list: block[]) => {
    list.map((b, i) => {
      const index = getIndex(b)
      const newNumber = addNumber(i, list[0]?.data.numberInList)
      if (index === -1 || newNumber === undefined) return
      const op = _json1.getReplaceBlockDataKeyOp(
        index,
        ['numberInList'],
        newNumber,
        b.data.numberInList,
      )
      _submit.submit(getBlockPageId(b), op, SourceKeys.UPDATE_BLOCK)
    })
  }

  const updateIndent = (block: block, indent: number) => {
    const index = getIndex(block)
    if (index === -1) return

    let op: JSONOp | undefined

    if (block.data.indent === undefined) {
      op = _json1.getInsertKeyInDataKeyOp(index, ['indent'], indent)
    } else {
      op = _json1.getReplaceBlockDataKeyOp(index, ['indent'], indent, block.data.indent)
    }
    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  const findBlockWithSameIndentInPreviousBlocks = (block: block, indent: number) => {
    const blocks = getBlocks(block.meta.pageId)
    const index = getIndex(block)

    if (!blocks) return

    for (let i = blocks.slice(0, index).length - 1; i >= 0; i--) {
      const b = blocks[i]

      if (getBlockIndent(b) === indent) {
        return b
      }
    }
  }

  const findListWithTheSameIndent = (block: block) => {
    const listBefore = getListBefore(block)
    const listAfter = getListAfter(block)

    if (!listBefore || !listAfter) return

    return [...listBefore, ...listAfter]
  }

  return {
    splitNumberedList,
    findPreviousNumberBlockInDocument,
    findPreviousRomanBlockInDocument,
    findPreviousCharacterBlockInDocument,
    updateListItem,
    updateList,
    addNumber,
    updateIndent,
    findBlockWithSameIndentInPreviousBlocks,
    getListBefore,
    getListAfter,
    submitUpdatedList,
    findListWithTheSameIndent,
  }
}

export default useNumberedList
