import { type block } from '_entities/block'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor, getRGBColorFromString, getStringFromRGBColor } from 'shared/colors'
import { PaletteColor } from 'components/organisms/Palette/styles'
import { getBlockDocumentPage, getInternalEmbedBorderColor } from '../../lib/getters'
import ColorPicker from 'components/molecules/ColorPicker/ColorPicker'
import { EditWrapper } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/BlockEdit/styles'
import { useInternalCanvasBorderColor } from '_features/embed'
import { SidebarEditProps } from './EmbedEdit'
import { useEffect, useMemo, useState } from 'react'
import { getCurrentFrame, usePresentation, WhiteboardAttrs, useCanvas } from '_features/canvas'
import { useWhiteboardEmbed } from '../InternalCanvas/model/useWhiteboardEmbed'
import { debounce } from 'lodash'
import { RGBColor } from 'react-color'
import { useInternalEmbedBlock } from '../model/useInternalEmbedBlock'
import { ColorPickerTypes, getPaletteColorElementId } from 'components/organisms/Palette/Palette'

export const BorderColorEdit = (props: SidebarEditProps) => {
  // ** State
  const [selectedBorderColor, setSelectedBorderColor] = useState<RGBColor | undefined>()
  const [isBorderSelectionOpen, setIsBorderSelectionOpen] = useState(false)
  const _presentation = usePresentation()
  const _internalCanvasBorderColor = useInternalCanvasBorderColor()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _internalEmbedBlock = useInternalEmbedBlock()
  const _canvas = useCanvas()

  const onBorderColorChange = (block: block, color: RGBColor) => {
    const rgba = getStringFromRGBColor(color)
    _internalCanvasBorderColor.updateInternalCanvasBorderColorInRealTime(block, rgba)
    debounceBorderColorChange({ block, rgba })
    setSelectedBorderColor(color)
  }

  const debounceBorderColorChange = useMemo(
    () =>
      debounce((args: { block: block; rgba: string }) => {
        const blockPage = getBlockDocumentPage(args.block)
        if (blockPage && _whiteboardEmbed.isDocEmbed(blockPage.id)) {
          _presentation.updateFrameBorderColor(blockPage.id, args.rgba)
        }
        _internalCanvasBorderColor.updateInternalCanvasBorderColor(args.block, args.rgba)
      }, 1000),
    [],
  )

  useEffect(() => {
    const pageId = _internalEmbedBlock.getBlockPageId(props.block)
    if (!pageId) return
    const observer = _canvas.observeMutationOnCanvasElement(
      pageId,
      WhiteboardAttrs.CURRENT_FRAME,
      () => {
        const currentFrame = getCurrentFrame(pageId)
        if (currentFrame) {
          const rgba = currentFrame.borderColor
          if (rgba) {
            setSelectedBorderColor(getRGBColorFromString(rgba))
          } else {
            setSelectedBorderColor(undefined)
          }
        } else {
          const rgba = getInternalEmbedBorderColor(props.block)
          if (!rgba) return
          setSelectedBorderColor(getRGBColorFromString(rgba))
        }
      },
    )
    return () => {
      observer?.disconnect()
    }
  }, [props.block])

  return (
    <EditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Border color
      </CustomP>
      <PaletteColor
        id={getPaletteColorElementId(ColorPickerTypes.BORDER)}
        color={`${
          selectedBorderColor
            ? getStringFromRGBColor(selectedBorderColor)
            : getInternalEmbedBorderColor(props.block)
        }`}
        size='18px'
        onClick={() => {
          setIsBorderSelectionOpen(!isBorderSelectionOpen)
        }}
      />
      {isBorderSelectionOpen && (
        <ColorPicker
          transform='translateY(-115%) translateX(75%)'
          placeholder={'Border color'}
          handleChange={(color) => onBorderColorChange(props.block, color.rgb)}
          closeColorPicker={() => {
            setIsBorderSelectionOpen(false)
          }}
          pickedColor={selectedBorderColor}
          type={ColorPickerTypes.BORDER}
        />
      )}
    </EditWrapper>
  )
}
