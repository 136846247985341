import React, { FC } from 'react'
import Card from 'components/organisms/Card/Card'
import { Link } from 'react-router-dom'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import background from 'assets/images/errors/404.svg'
import Image from 'components/atoms/Image/Image'
import HeaderWrapper from 'components/templates/HeaderWrapper/HeaderWrapper'
import CustomP from 'components/atoms/CustomP/CustomP'

const PageNotFound = () => {
  return (
    <HeaderWrapper>
      <Card
        cardElements={
          <>
            <Image
              isDraggable={false}
              className='img-fluid'
              src={background}
              alt='everyday company logo R'
            ></Image>
            <h1 className='fs-2 mb-3 text-black'>404</h1>
            <h1 className='fs-2 text-black mb-5'>Oops, Page not found!</h1>
            <div>
              <Link className='text-primary' to='/'>
                <CustomButton
                  className='btn btn-primary w-50 d-inline-flex justify-content-center align-items-center'
                  height='40px'
                >
                  <CustomP className='mb-0'>Back to home</CustomP>
                </CustomButton>
              </Link>
            </div>
          </>
        }
      />
    </HeaderWrapper>
  )
}

export default PageNotFound
