import Select, { GroupBase, OptionsOrGroups } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import { SelectStyles, StyledWrapper } from './styles'
import { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select'
import { ReactNode } from 'react'
import { icons } from 'shared/icons'

interface Props {
  options: OptionsOrGroups<Option, GroupBase<Option>> | undefined | any
  onChange: (items: any) => void
  autoFocus?: boolean
  hideControl?: boolean
  defaultValue?: any
  value?: any
  formatOptionLabel?: (
    data: Option,
    formatOptionLabelMeta: FormatOptionLabelMeta<Option>,
  ) => ReactNode
  isSearchable?: boolean
  placeholder?: string
  removePaddingOnOption?: number | null
  controlShouldRenderValue?: boolean
  hideSelectedOptions?: boolean
}

const MultiSelect = (props: Props) => {
  const { controlShouldRenderValue } = props
  return (
    <Select
      menuIsOpen
      autoFocus={props.autoFocus}
      isClearable={false}
      isMulti
      options={props.options}
      onChange={props.onChange}
      components={{
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        Control: (props) => {
          return (
            <StyledWrapper
              hasValue={
                controlShouldRenderValue !== undefined ? controlShouldRenderValue : props.hasValue
              }
            >
              {(!props.hasValue || !controlShouldRenderValue) && <icons.magnifyingGlass />}
              {props.children}
            </StyledWrapper>
          )
        },
      }}
      value={props.value}
      defaultValue={props.defaultValue}
      styles={SelectStyles(props.hideControl, props.removePaddingOnOption)}
      formatOptionLabel={props.formatOptionLabel}
      isSearchable={props.isSearchable}
      placeholder={props.placeholder}
      controlShouldRenderValue={props.controlShouldRenderValue}
      hideSelectedOptions={props.hideSelectedOptions}
    />
  )
}

export default MultiSelect
