import React, { useState } from 'react'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import Input from 'components/atoms/Input/Input'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import CustomP from 'components/atoms/CustomP/CustomP'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import copy from 'copy-to-clipboard'

type Props = {
  value?: string
  setValue?: React.Dispatch<React.SetStateAction<string>>
  inviteLink?: string
  buttonText?: string
  textColour?: string
  textFontWeight?: string
  buttonIconSrc?: string
  buttonIconAlt?: string
  buttonIconHeight?: string
  buttonIconWidth?: string
  buttonMargin?: string
  buttonWidth?: string
  labelText?: string
  labelweight?: string
  labelColor?: string
  labelMargin?: string
  labelFontSize?: string
  labelHeight?: string
  inputHeight?: string
  placeHolderText?: string
  toolTipMessage?: string
  onChange?: React.ChangeEventHandler
}

const InputWithButton: React.FC<Props> = ({
  value,
  setValue,
  inviteLink,
  buttonText,
  textColour,
  textFontWeight,
  buttonIconSrc,
  buttonIconAlt,
  buttonIconHeight,
  buttonIconWidth,
  buttonMargin,
  buttonWidth,
  labelText,
  labelweight,
  labelColor,
  labelMargin,
  placeHolderText,
  toolTipMessage,
}) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const handleClick = () => {
    if (inviteLink) copy(inviteLink)
    setShowTooltip(true)
    setTimeout(() => {
      setShowTooltip(false)
    }, 2000)
  }

  return (
    <Wrapper className='d-flex w-100 align-items-end'>
      <Input
        value={inviteLink}
        className='flex-grow-1'
        label={labelText}
        labelweight={labelweight}
        labelColor={labelColor}
        labelMargin={labelMargin}
        placeHolder={placeHolderText}
        width={'100% !important'}
        fontweight={textFontWeight}
        color={textColour}
      />
      <CustomButton
        margin={buttonMargin}
        width={buttonWidth}
        height={'36px'}
        className='d-flex justify-content-center align-items-center gap-2 border-0 position-relative'
        borderradius='8px'
        background={'var(--primary-color)'}
        onClickFunction={handleClick}
      >
        {buttonIconSrc && (
          <CustomIcon
            src={buttonIconSrc}
            alt={buttonIconAlt}
            height={buttonIconHeight}
            width={buttonIconWidth}
          />
        )}
        <CustomP className='mb-0 text-white' fontWeight='600'>
          {buttonText}
        </CustomP>
        <Tooltip
          className='mb-0 p-1'
          message={toolTipMessage}
          active={showTooltip}
          justify={'center'}
          alignitems={'center'}
        />
      </CustomButton>
    </Wrapper>
  )
}

export default InputWithButton
