import styled from 'styled-components'

export const ChipContainerStyled = styled.div`
  width: fit-content;
  margin-bottom: 16px;
  margin-top: 12px;

  & > * {
    padding: 4px 8px !important;
    background: #d8d8d8;
    color: var(--main-black);
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    letter-spacing: 0.02em;
    border-radius: 12px;
  }
  & > span > div {
    display: none !important;
  }
`
