import {
  LLMWrapper,
  SelectModelWrapper,
  StyledTitle,
} from 'components/templates/Page/ProfileDrawer/styles'
import LLMOption from './LLMOption'
import { Option } from 'interfaces/selectOptions'
import { SingleValue } from 'react-select'
import SingleSelect from 'components/organisms/SingleSelect/SingleSelect'
import useLLMDropdown from '../model/useLLMDropdown'

const LLMDropdown = () => {
  const _dropdown = useLLMDropdown()

  return (
    <LLMWrapper>
      <SelectModelWrapper>
        <StyledTitle>Large Language Model</StyledTitle>
        <SingleSelect
          options={_dropdown.options}
          onClick={(e) => {
            e.stopPropagation()
            _dropdown.setIsShown(!_dropdown.isShown)
          }}
          onChange={(e: SingleValue<Option>) => _dropdown.handleSelect(e)}
          autoFocus
          menuIsOpen={_dropdown.isShown}
          isDropdownIndicatorShown
          isSearchable={false}
          currentValue={_dropdown.selected}
          formatOptionLabel={(data) =>
            LLMOption({
              data: {
                ...data,
                id: data.value,
              },
              selectedOption: _dropdown.selected?.value?.toString(),
            })
          }
        />
      </SelectModelWrapper>
    </LLMWrapper>
  )
}

export default LLMDropdown
