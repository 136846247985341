import {
  type block,
  getBlockPageId,
  getIndex,
  GridBlockWidth,
  IBlockTypes,
  useBlockOps,
} from '_entities/block'
import { SlashMenuOptions } from './types'
import { EmbedType } from '_entities/embed'
import { SourceKeys } from 'interfaces/editor'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useCreatePage } from '_features/page'
import { getPage } from '_entities/page'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import Quill from 'quill'
import { JSONOp } from 'ot-json1'
import { SingleValue } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import { setFontFamily, setFontSize } from 'redux/reducers/pageReducer'
import { formattedTextFontSize } from '_entities/text/model/useText'
import { useEditorEdit } from '_features/editor'
import { docSubscribe, getBlocks, getDoc } from 'shared/shareDb'
import { useFilesHandler } from '_widgets/DocumentBlock'

export const useSlashMenuSelection = () => {
  const space = useAppSelector((state) => state.space.currentSpace)
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _createPage = useCreatePage()
  const _blockOps = useBlockOps()
  const _editorEdit = useEditorEdit()
  const dispatch = useAppDispatch()
  const _filesHandler = useFilesHandler()

  const createListItem = (block: block, type: IBlockTypes, numberInTheList?: number | null) => {
    const updatedBlock: block = {
      ...block,
      data: {
        ...block.data,
        tag: type,
        numberInList: numberInTheList,
      },
    }
    const replaceOp = _json1.replaceBlock(updatedBlock, getIndex(block), block)
    _submit.submit(getBlockPageId(block), replaceOp, SourceKeys.UPDATE_BLOCK)
    setTimeout(() => {
      _editorEdit.deleteText(block)
    }, 10)
  }

  /* =========================================================================== */
  /* =========================================================================== */

  const checkIfTheNameIsDefault = (name: string) => {
    const tag = name?.split('_')[0] as IBlockTypes
    return Object.values(IBlockTypes).includes(tag)
  }

  const updateBlockName = (block: block, option: SlashMenuOptions) => {
    if (!block.meta.name) return
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    const blocks = getBlocks(pageId)
    const newTag = `${option.type}_${blocks ? blocks.length + 1 : 1}`
    if (index === -1) return
    if (!checkIfTheNameIsDefault(block.meta.name)) return
    const nameChangeOp = _json1.getReplaceBlockMetaKeyOp(index, ['name'], newTag)
    _submit.submit(pageId, nameChangeOp, SourceKeys.UPDATE_BLOCK)
  }

  const tagMapper = {
    [IBlockTypes.TITLE]: 1,
    [IBlockTypes.SUBHEADER]: 2,
    [IBlockTypes.XLARGE]: 3,
    [IBlockTypes.LARGE]: 4,
    [IBlockTypes.MEDIUM]: 5,
    [IBlockTypes.SMALL]: 6,
    [IBlockTypes.TEXT]: false,
  }

  const formatNewBlockPerTextType = (block: block, type: IBlockTypes, value?: number | false) => {
    const quillElement = document.querySelector(`#quill-editor-${block._id}`)
    if (quillElement) {
      const editor: Quill = Quill.find(quillElement)
      editor.format(
        'header',
        tagMapper[
          type as
            | IBlockTypes.TITLE
            | IBlockTypes.SUBHEADER
            | IBlockTypes.XLARGE
            | IBlockTypes.LARGE
            | IBlockTypes.MEDIUM
            | IBlockTypes.SMALL
            | IBlockTypes.TEXT
        ],
        'user',
      )

      setTimeout(() => {
        const selectedFontSize = value ? formattedTextFontSize[value] : formattedTextFontSize['p']
        editor.format('size', `${selectedFontSize}px`, 'user')
        editor.format('font', 'arial', 'user')
        dispatch(setFontSize(selectedFontSize))
        dispatch(setFontFamily('arial'))
      }, 100)
    }
  }

  const isNewValueEmbed = (newValue: SingleValue<Option>) => {
    if (newValue && newValue.id)
      return newValue.type === IBlockTypes.WHITEBOARD || newValue.type === IBlockTypes.DOCUMENT
  }

  const handlePageLink = async (block: block, newValue: SingleValue<Option>) => {
    if (!newValue?.id) return

    const index = getIndex(block)

    const page = newValue && project?.id && (await getPage(project.id, newValue?.id as string))
    if (page) {
      const subscribedDoc = await docSubscribe(page.id)
      if (!subscribedDoc || subscribedDoc instanceof Error) return
      const tagOp = _json1.getReplaceBlockDataKeyOp(index, ['tag'], page.type, block.data.tag)
      const documentPageOp = _json1.getInsertKeyInDataKeyOp(index, ['documentPage'], page)
      const finalOp = _json1.combineOperations([tagOp, documentPageOp])
      _submit.submit(getBlockPageId(block), finalOp, SourceKeys.UPDATE_BLOCK)
    }
  }

  const handleNonPageLink = async (block: block, option: SlashMenuOptions) => {
    switch (option.type) {
      case IBlockTypes.IMAGE:
      case IBlockTypes.IMAGE_TEXT: {
        const op = _json1.getReplaceBlockDataKeyOp(
          getIndex(block),
          ['tag'],
          option.type,
          block.data.tag,
        )
        _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
        const inputEl = document.createElement('input')
        inputEl.type = 'file'
        inputEl.accept = 'image/*'
        inputEl.onchange = () => {
          _filesHandler.handleImageUpload(block, option.type, inputEl)
        }
        inputEl.click()

        setTimeout(() => {
          updateBlockName(block, option)
        }, 100)
        break
      }

      case IBlockTypes.TITLE: // <h1>
      case IBlockTypes.XLARGE: // <h2>
      case IBlockTypes.LARGE: // <h3>
      case IBlockTypes.MEDIUM: // <h4>
      case IBlockTypes.SMALL: // <h5>
      case IBlockTypes.SUBHEADER: // <h6>
      case IBlockTypes.TEXT: {
        const op = _json1.getReplaceBlockDataKeyOp(
          getIndex(block),
          ['tag'],
          option.type,
          block.data.tag,
        )
        _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)

        setTimeout(() => {
          _editorEdit.deleteText(block)
          formatNewBlockPerTextType(block, option.type, option.value)
        }, 10)
        setTimeout(() => {
          updateBlockName(block, option)
        }, 100)
        break
      }

      case IBlockTypes.LIST: {
        const blocks = getBlocks(getBlockPageId(block))
        if (!blocks) return
        createListItem(block, option.type)
        setTimeout(() => {
          updateBlockName(block, option)
        }, 100)
        break
      }

      case IBlockTypes.NUMBERED_LIST: {
        const blocks = getBlocks(getBlockPageId(block))
        if (!blocks) return
        createListItem(block, option.type, 1)
        setTimeout(() => {
          updateBlockName(block, option)
        }, 100)
        break
      }

      case IBlockTypes.CHECKLIST: {
        const blocks = getBlocks(getBlockPageId(block))
        if (!blocks) return
        createListItem(block, option.type)
        setTimeout(() => {
          updateBlockName(block, option)
        }, 100)
        break
      }

      case IBlockTypes.TABLE: {
        const replaceOp = _blockOps.getReplaceTypeOp(block, option.type)
        _submit.submit(getBlockPageId(block), replaceOp, SourceKeys.UPDATE_BLOCK)
        setTimeout(() => {
          updateBlockName(block, option)
        }, 100)
        break
      }
      case IBlockTypes.DOCUMENT: {
        if (pages && project && space) {
          const createdPage = await _createPage.createDocumentPage(
            pages,
            project.id,
            space.id,
            getBlockPageId(block),
            'New document',
          )

          if (createdPage) {
            const updatedBlock = {
              ...block,
              data: {
                ...block.data,
                tag: option.type,
                documentPage: createdPage,
              },
            }
            const subscribedDoc = await docSubscribe(createdPage.id)
            if (!subscribedDoc || subscribedDoc instanceof Error) return
            const replaceOp = _json1.replaceBlock(updatedBlock, getIndex(block), block)
            _submit.submit(getBlockPageId(block), replaceOp, SourceKeys.UPDATE_BLOCK)

            setTimeout(() => {
              updateBlockName(block, option)
            }, 100)
          }
        }
        break
      }
      case IBlockTypes.WHITEBOARD: {
        if (pages && project && space) {
          const createdPage = await _createPage.createWhiteboardPage(
            pages,
            project.id,
            space.id,
            getBlockPageId(block),
            'New Canvas',
            true,
          )

          if (createdPage) {
            const updatedBlock = {
              ...block,
              data: {
                ...block.data,
                tag: option.type,
                documentPage: createdPage,
                isEmbedExpanded: true,
                gridWidth: GridBlockWidth.IN_GRID,
                internalEmbed: {
                  borderRadius: 10,
                },
              },
            }
            const subscribedDoc = await docSubscribe(createdPage.id)
            if (!subscribedDoc || subscribedDoc instanceof Error) return
            const replaceOp = _json1.replaceBlock(updatedBlock, getIndex(block), block)
            _submit.submit(getBlockPageId(block), replaceOp, SourceKeys.UPDATE_BLOCK)
            setTimeout(() => {
              updateBlockName(block, option)
            }, 100)
          }
        }

        break
      }

      case IBlockTypes.EMBED: {
        const index = getIndex(block)

        let documentPageOp: JSONOp

        if (block.data.documentPage) {
          documentPageOp = _json1.getReplaceBlockDataKeyOp(index, ['documentPage'], null)
        } else {
          documentPageOp = _json1.getInsertKeyInDataKeyOp(index, ['documentPage'], null)
        }

        const tagOp = _json1.getReplaceBlockDataKeyOp(index, ['tag'], option.type, block.data.tag)
        const finalOp = _json1.combineOperations([documentPageOp, tagOp])
        _submit.submit(getBlockPageId(block), finalOp, SourceKeys.UPDATE_BLOCK)
        setTimeout(() => {
          updateBlockName(block, option)
        }, 100)
        break
      }

      case IBlockTypes.EXTERNAL_EMBED: {
        if (option.embedType) {
          const index = getIndex(block)
          const tagOp = _json1.getReplaceBlockDataKeyOp(index, ['tag'], option.type)
          const updatedBlock = {
            ...block,
            data: {
              ...block.data,
              tag: option.type,
              embed: {
                ...block.data.embed,
                type: option.embedType as EmbedType,
                name: option.label,
                url: '',
              },
            },
          }
          const replaceOp = _json1.replaceBlock(updatedBlock, getIndex(block), block)

          _submit.submit(getBlockPageId(block), replaceOp, SourceKeys.UPDATE_BLOCK)

          setTimeout(() => {
            _submit.submit(getBlockPageId(block), tagOp, SourceKeys.UPDATE_BLOCK)
            setTimeout(() => {
              updateBlockName(block, option)
            }, 100)
          }, 100)
        }

        break
      }

      case IBlockTypes.GENERIC_LINK: {
        if (option.embedType) {
          let embedOp: JSONOp
          const index = getIndex(block)
          const tagOp = _json1.getReplaceBlockDataKeyOp(index, ['tag'], option.type)
          if (block.data.embed) {
            embedOp = _json1.getReplaceBlockDataKeyOp(index, ['embed'], {
              type: option.embedType as EmbedType,
              name: option.label,
              url: '',
            })
          } else {
            embedOp = _json1.getInsertKeyInDataKeyOp(index, ['embed'], {
              type: option.embedType as EmbedType,
              name: option.label,
              url: '',
            })
          }

          _submit.submit(getBlockPageId(block), embedOp, SourceKeys.UPDATE_BLOCK)

          setTimeout(() => {
            _submit.submit(getBlockPageId(block), tagOp, SourceKeys.UPDATE_BLOCK)
            setTimeout(() => {
              updateBlockName(block, option)
            }, 100)
          }, 100)
        }

        break
      }

      case IBlockTypes.SLIDESHOW: {
        const index = getIndex(block)

        let documentPageOp: JSONOp

        if (block.data.documentPage) {
          documentPageOp = _json1.getReplaceBlockDataKeyOp(index, ['documentPage'], null)
        } else {
          documentPageOp = _json1.getInsertKeyInDataKeyOp(index, ['documentPage'], null)
        }

        const tagOp = _json1.getReplaceBlockDataKeyOp(index, ['tag'], option.type, block.data.tag)
        const finalOp = _json1.combineOperations([documentPageOp, tagOp])
        _submit.submit(getBlockPageId(block), finalOp, SourceKeys.UPDATE_BLOCK)
        break
      }

      case IBlockTypes.CTA: {
        const index = getIndex(block)

        const newBlock = {
          ...block,
          data: {
            tag: IBlockTypes.CTA,
            cta: {
              id: option.id,
            },
          },
        }

        const op = _json1.replaceBlock(newBlock, index, block)

        _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
        break
      }
    }
  }

  const handleSelection = (block: block, newValue: SingleValue<Option> | SlashMenuOptions) => {
    if (isNewValueEmbed(newValue as SingleValue<Option>)) {
      handlePageLink(block, newValue as SingleValue<Option>)
    } else {
      handleNonPageLink(block, newValue as SlashMenuOptions)
    }
  }

  return {
    handleSelection,
    handleNonPageLink,
  }
}
