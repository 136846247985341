import React, { Fragment } from 'react'
import { EmbedHeader, IconWrapper, EditorEmbedWrapper, Title } from './styles'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { useEmbedAddButton } from '../model/useEmbedAddButton'
import { type block } from '_entities/block'
import Link from 'components/molecules/Link/Link'
import { useEmbedCreation } from '_features/embed'
interface Props {
  block: block
}

export const getEditorEmbedId = (block: block) => {
  return `editor-embed-${block._id}`
}

export const EmbedAddButton = (props: Props) => {
  const _embedCreation = useEmbedCreation()
  const { isOpen, handleIsOpen, embedIcon, embedTitle, handleOnChange, link } = useEmbedAddButton()

  return (
    <EditorEmbedWrapper id={getEditorEmbedId(props.block)}>
      <RadixDropdown
        open={isOpen}
        onOpenChange={handleIsOpen}
        trigger={
          <EmbedHeader>
            <Fragment>
              <IconWrapper>{embedIcon(props.block)}</IconWrapper>
              <Title>{embedTitle(props.block)}</Title>
            </Fragment>
          </EmbedHeader>
        }
      >
        <Link
          placeholder='Paste link here'
          loading={_embedCreation.loading}
          title={'Link URL'}
          buttonText={'Link'}
          link={link}
          onInputChange={(e) => handleOnChange(e)}
          addLink={() => _embedCreation.handleDocExternalLink(link, props.block)}
          disabled={link.length === 0}
        />
      </RadixDropdown>
    </EditorEmbedWrapper>
  )
}
