import { type block } from '_entities/block'
import { useSlashMenu, SlashMenuOptions, useSlashMenuSelection } from '_features/block'
import { SingleValue } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import { MenuComponent } from './MenuComponent'

export interface SlashMenuProps {
  open?: boolean
  searchText?: string
  block: block
  isWhiteboard?: boolean
  onClickOutside?: () => void
  onSelect?: (value: SingleValue<Option> | SlashMenuOptions) => void
  isCentered?: boolean
  isFocused?: boolean
  setFocused?: (value: React.SetStateAction<boolean>) => void
}

export const getSlashMenuId = (block?: block) => {
  return `slash-menu-${block ? block._id : 'general'}`
}

export const SlashMenu = (props: SlashMenuProps) => {
  const _slashMenu = useSlashMenu(props)
  const _slashMenuSelection = useSlashMenuSelection()
  return (
    <MenuComponent
      isCentered={props.isCentered}
      open={props.open}
      options={_slashMenu.sortedItems.length > 0 ? _slashMenu.sortedItems : _slashMenu.options}
      onChange={(value) => {
        _slashMenuSelection.handleSelection(
          props.block,
          value as SingleValue<Option> | SlashMenuOptions,
        )
        props.onSelect && props.onSelect(value as SingleValue<Option> | SlashMenuOptions)
      }}
      onClickOutside={props.onClickOutside}
      isFocused={props.isFocused}
      setFocused={props.setFocused}
      isEmpty={_slashMenu.sortedItems.length === 0}
    />
  )
}
