import React from 'react'
import { useAppSelector } from 'redux/hooks'
import { getBlockPageId, getIndex, type block } from '_entities/block'
import { Doc as JsonDoc } from 'ot-json1/dist/types'
import { SourceKeys } from 'interfaces/editor'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { BlockMetaKeys } from '_entities/block/model/types'
import { type RelatedObject } from '_features/relationship'

const useBacklinks = () => {
  const pages = useAppSelector((state) => state.projectFile.pages)
  const { submit } = useSubmit()
  const { getReplaceBlockMetaKeyOp } = useJson1()

  const handleDetachAllFromOriginal = (e: React.MouseEvent, block: block, item: RelatedObject) => {
    e.stopPropagation()

    let relatedObjects = block.meta.relatedObjects

    relatedObjects = relatedObjects?.filter(
      (relatedObject) => relatedObject.relatedObject !== item.relatedObject,
    )

    const index = getIndex(block)

    if (index === -1) return

    const op = getReplaceBlockMetaKeyOp(
      index,
      [BlockMetaKeys.RELATED_OBJECTS],
      relatedObjects as unknown as JsonDoc,
    )
    submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  return {
    pages,
    handleDetachAllFromOriginal,
  }
}

export default useBacklinks
