import { css } from 'styled-components'

export const StyledHeaderComponentCss = css`
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & img {
    user-select: none;
  }
`
