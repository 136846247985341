import DropdownMenu from 'components/organisms/DropdownMenu/DropdownMenu'
import styled, { css } from 'styled-components'

export const ColorDropdownMenuContainer = styled(DropdownMenu)`
  padding: 12px 8px !important;
  min-width: fit-content;
  & > li > a {
    white-space: nowrap;
  }

  .dropdown-item:hover {
    background-color: var(--hover) !important;
  }
`

export const ColorDropdownMenuTitle = styled.div`
  padding: 4px;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: var(--gray2);
`

export const ColorDropdownMenuBody = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 132px;
`

export const ColorDropdownSection = styled.div`
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 8px 0;
`

export const ColorButtonContainer = styled(DropdownMenu.Item)<{ active?: boolean }>`
  width: fit-content;
  padding: 4px;
  cursor: pointer;
  border-radius: 10px;

  ${(props) =>
    props.active
      ? css`
          ${ColorButton} {
            border: 1px solid #8aadff;
            background: #fff;
          }
        `
      : css`
          &:hover {
            background: #efefef;
          }
        `}
`

export const ColorButtonColor = styled.div<{ backgroundColor: string; border?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
  ${(props) =>
    props.border &&
    css`
      border: ${props.border};
    `}
`

export const ColorButton = styled.div<{ isSelected?: boolean }>`
  padding: 4px;
  border-radius: 5px;
  border: ${(props) => (props.isSelected ? '1px solid #1986E5' : 'none')};
  display: flex;
  align-items: center;
  justify-content: center;
`
