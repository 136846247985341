import React from 'react'
import { AI } from 'interfaces/ai/ai'
import { type block, getBlockPageId, getIndex, IBlockTypes } from '_entities/block'
import { ImageSizeEnum, useAi } from 'services/ai/useAi'
import { useToast } from 'services/helpers/useToast'
import './spinner.css'
import Quill from 'quill'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { useTransformer } from '_features/canvas'
import { useAppDispatch } from 'redux/hooks'
import { setSelectedBlock } from 'redux/reducers/whiteboardReducer'
import { getEditor } from 'shared/lib'
interface Props {
  block: block
  setActiveToolbarOption?: React.Dispatch<React.SetStateAction<string | null>>
}

export const useBlockAi = (props: Props) => {
  const _ai = useAi()
  const dispatch = useAppDispatch()
  const _json1 = useJson1()
  const _submit = useSubmit()
  const toast = useToast()
  const _transformer = useTransformer()

  const TEXT_NOT_GENERATED = 'Text could not be generated. Try again.'

  const expandText = async () => {
    props.setActiveToolbarOption && props.setActiveToolbarOption(null)
    const editor: Quill | undefined = getEditor(props.block._id)
    if (editor) {
      const text = editor.getText()
      const contentLength = editor.getLength()
      editor.insertText(contentLength, '...', 'user')
      const response: AI | undefined = await _ai.expand(text)
      let aiResponse = response?.choices[0].message.content
      if (!aiResponse) {
        toast.error(TEXT_NOT_GENERATED)
        return
      }
      const index = getIndex(props.block)
      if (index === -1) return
      aiResponse = aiResponse.trim()
      editor.deleteText(contentLength, 3)
      editor.insertText(editor.getLength(), aiResponse, 'user')
    }
  }

  const summarizeText = async () => {
    props.setActiveToolbarOption && props.setActiveToolbarOption(null)

    const editor: Quill | undefined = getEditor(props.block._id)
    if (editor) {
      const text = editor.getText()
      const contentLength = editor.getLength()
      editor.insertText(contentLength, '...', 'user')
      const response: AI | undefined = await _ai.summarize(text)
      let aiResponse = response?.choices[0].message.content
      if (!aiResponse) {
        toast.error(TEXT_NOT_GENERATED)
        return
      }
      const index = getIndex(props.block)
      if (index === -1) return
      aiResponse = aiResponse.trim()
      editor.setText(aiResponse, 'user')
    }
  }

  const generateText = async () => {
    props.setActiveToolbarOption && props.setActiveToolbarOption(null)

    const editor: Quill | undefined = getEditor(props.block._id)
    if (editor) {
      const text = editor.getText()
      const contentLength = editor.getLength()
      editor.insertText(contentLength, '...', 'user')
      const response: AI | undefined = await _ai.generate(text)
      let aiResponse = response?.choices[0].message.content
      if (aiResponse == null) {
        toast.error(TEXT_NOT_GENERATED)
        return
      }
      const index = getIndex(props.block)
      if (index === -1) return
      aiResponse = aiResponse.trim()
      editor.setText(aiResponse, 'user')
    }
  }

  const convertToImageAndText = async (e: React.MouseEvent, isWhiteboard?: boolean) => {
    e.stopPropagation()
    const editor = getEditor(props.block._id)
    const text = editor?.getText()
    if (!text) return
    if (text.length > 1000) {
      toast.error(
        `Text is ${text.length} characters long. It cannot be longer than 1000 characters.`,
      )
      return
    }
    const index = getIndex(props.block)

    const newBlock = {
      ...props.block,
      data: {
        ...props.block.data,
        tag: isWhiteboard ? IBlockTypes.IMAGE : IBlockTypes.IMAGE_TEXT,
        isImageAndTextBlock: !isWhiteboard,
        isAiGenerating: true,
        imageUrl: '',
        scaleX: 1,
        scaleY: 1,
      },
    }

    const op = _json1.replaceBlock(newBlock, index, props.block)

    _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
    dispatch(setSelectedBlock(undefined))
    _transformer.removeNodesFromTransformer(props.block.meta.pageId)

    if (text) {
      await handleSubmitImageUrlOp(text, newBlock, index, isWhiteboard)
    }
  }

  const handleSubmitImageUrlOp = async (
    text: string,
    block: block,
    index: number,
    isWhiteboard?: boolean,
  ) => {
    const url = await _ai.generateImage(text, isWhiteboard ? ImageSizeEnum.LANSCAPE : undefined)
    if (!url) {
      const newBlock = {
        ...block,
        data: {
          ...block.data,
          tag: IBlockTypes.TEXT,
          isImageAndTextBlock: false,
          isAiGenerating: false,
          imageUrl: '',
        },
      }
      const op = _json1.replaceBlock(newBlock, index, block)
      _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
      _transformer.removeNodesFromTransformer(newBlock.meta.pageId)
    } else {
      const newBlock = {
        ...block,
        data: {
          ...block.data,
          isAiGenerating: false,
          imageUrl: url,
        },
      }
      const op = _json1.replaceBlock(newBlock, index, block)
      _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
      _transformer.removeNodesFromTransformer(newBlock.meta.pageId)
    }
  }

  return {
    expandText,
    summarizeText,
    generateText,
    convertToImageAndText,
  }
}
