import CustomP from 'components/atoms/CustomP/CustomP'
import CopyIcon from 'assets/images/CopySimple.svg'
import InviteModal from 'components/molecules/InviteModal/InviteModal'
import InviteModalDivider from 'components/molecules/InviteModalDivider/InviteModalDivider'
import LinkOptions from 'components/molecules/LinkOptions/LinkOptions'
import { useSpaceInviteModal } from './useSpaceInviteModal'
import SelectWithChips from 'components/organisms/SelectWithChipsInvites/SelectWithChipsInvites'
import FlexContainer from 'components/molecules/FlexContainer/FlexContainer'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import InputWithButton from 'components/molecules/InputWithButton/InputWithButton'
import { LabelText } from './styles'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setInviteModalOpened } from 'redux/reducers/spaceReducer'

const SpaceInviteModal = () => {
  const {
    inviteLink,
    generateInviteLink,
    sendInviteEmail,
    expiresValue,
    setInviteEmailsState,
    onChangeLinkExpiration,
    selectRef,
    onInvitationModalClick,
    isOpen,
    setIsOpen,
  } = useSpaceInviteModal()
  const space = useAppSelector((state) => state.space.currentSpace)
  const opened = useAppSelector((state) => state.space.inviteModalOpened)
  const dispatch = useAppDispatch()
  const setModalShow = (bool: boolean) => {
    dispatch(setInviteModalOpened(bool))
  }
  return (
    <InviteModal
      key={'spaceInv'}
      show={opened}
      setShow={setModalShow}
      width={'580px'}
      padding='2.5rem 2rem'
      title={
        <CustomP
          fontSize='16px'
          fontWeight='600'
          marginBottom='4rem !important'
          paddingBottom='4rem !important'
          className='mb-5'
        >
          Invite members to workspace
        </CustomP>
      }
      onModalClick={onInvitationModalClick}
    >
      <InputWithButton
        inviteLink={inviteLink}
        buttonText='Copy link'
        buttonWidth='132px'
        buttonMargin='0 0 0 8px'
        buttonIconSrc={CopyIcon}
        buttonIconAlt='Copy'
        labelText={'Invite by link'}
        labelFontSize='12px'
        labelColor='var(--gray2) !important'
        labelweight={'600'}
        toolTipMessage={'Link copied!'}
        placeHolderText={'Invite link'}
        labelMargin={'1.5rem 0 0.15rem 0 !important'}
        textColour='var(--main-black) !important'
        textFontWeight='500'
      />
      <LinkOptions
        id='linkOptionsForInviteSelector'
        idForModal='linkOptionsForInviteModalOnlySelector'
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onClick={() => {
          if (!space) return
          generateInviteLink(space.id, expiresValue.value)
        }}
        onChange={onChangeLinkExpiration}
        colour='var(--main-black)'
        fontWeight='500'
        margin='0 0 0 0.5rem'
      />

      <InviteModalDivider margin='0.75rem 0rem' />

      <LabelText>Invite by email or name</LabelText>
      <FlexContainer className='w-100' justifyContent='flex-start'>
        <SelectWithChips
          setInviteEmailsState={setInviteEmailsState}
          border={'1px solid var(--slightly-muted-white)'}
          borderradius={'10px'}
          flexgrow={'1 !important'}
          minheight={'36px'}
          selectRef={selectRef}
          width='100%'
        />
        <CustomButton
          margin={'0 0 0 8px'}
          padding={'8px 20px'}
          width={'fit-content'}
          height={'36px'}
          className='d-flex justify-content-center align-items-center gap-2 border-0 position-relative'
          borderradius='10px'
          background='var(--everyday-blue)'
          onClickFunction={sendInviteEmail}
        >
          <CustomP className='mb-0 text-white' fontWeight='600'>
            Send&nbsp;invite
          </CustomP>
        </CustomButton>
      </FlexContainer>
    </InviteModal>
  )
}

export default SpaceInviteModal
