import { DropdownIndicatorProps, GroupBase, components } from 'react-select'
import { ReactComponent as UnfoldMore } from 'assets/images/unfold_more.svg'

interface CustomDropdownIndicatorProps {
  icon?: JSX.Element
}

interface CustomDropdownIndicatorProps
  extends DropdownIndicatorProps<
    {
      icon?: JSX.Element
    },
    boolean,
    GroupBase<{
      icon?: JSX.Element
    }>
  > {
  styles: string
  data: {
    icon?: JSX.Element
  }
}

const DropdownIndicator = (props: CustomDropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <UnfoldMore />
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator
