import { JSONOp } from 'ot-json1'
import { useAppSelector } from 'redux/hooks'
import { SourceKeys } from 'interfaces/editor'
import { toast } from 'react-toastify'
import { docSubscribe, getDoc } from 'shared/shareDb'

export const error = (error: unknown) => {
  if (error) {
    console.error('%cWebsocket error: ', 'color: red')
    console.error(error)
    toast.error(`Unable to submit op! Error: ${error}`)
  }
}

export const useSubmit = () => {
  const user = useAppSelector((state) => state.global.user)
  const submit = async (pageId: string, op: JSONOp, sourceKey?: SourceKeys) => {
    const doc = getDoc(pageId)
    if (!doc) return
    if (doc.data) {
      doc.submitOp(op, { source: sourceKey ? { ...user, sourceKey } : user }, error)
    } else {
      await docSubscribe(doc.id)
      doc.submitOp(op, { source: sourceKey ? { ...user, sourceKey } : user }, error)
    }
  }

  return {
    submit,
  }
}
