import { checkIsUrl } from '_entities/embed/ExternalEmbed/model/EmbedRegex'
import { StyledIcon, StyledIconWrapper, StyledImageIcon } from './styles'
import { isSupportedEmoji, EmojiRenderer } from '_features/emojis'
import { icons } from 'shared/icons'

interface PageIconProps {
  Icon?: string | React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null
  size?: number
}
export const Icon = (props: PageIconProps) => {
  const { Icon } = props
  const renderIconFromUrl = () => {
    if (typeof Icon === 'string' && checkIsUrl(Icon)) {
      return <StyledImageIcon width={props.size} height={props.size} src={Icon} />
    }
  }

  const renderIconFromEmoji = () => {
    if (typeof Icon === 'string' && isSupportedEmoji(Icon)) {
      return <EmojiRenderer code={Icon} />
    }
  }

  const renderInternalIcon = () => {
    if (typeof Icon !== 'string') return <StyledIcon>{Icon && <Icon />}</StyledIcon>
  }

  const renderDefaultIcon = () => {
    return <StyledIcon>{<icons.link />}</StyledIcon>
  }
  return (
    <StyledIconWrapper>
      {renderIconFromUrl() || renderIconFromEmoji() || renderInternalIcon() || renderDefaultIcon()}
    </StyledIconWrapper>
  )
}
