import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

type StyledButtonPropsType = {
  backgroundColour?: string
  textColour?: string
}

type StyledLabelProps = {
  textColour?: string
}

type StyledInputProps = {
  margin?: string
}

export const StyledModal = styled(Modal)`
  & .modal-dialog {
    max-width: 580px;
  }

  & .modal-content {
    min-height: 224px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px;
    padding-block: 2rem;
  }

  & .btn-close {
    background-size: 16px 16px;
    background-position: center;
    position: relative;
    top: -0.75rem;
  }
`

export const StyledModalHeader = styled(Modal.Header)`
  border-bottom: none;
  padding: 0 !important;
`
export const StyledModalTitle = styled(Modal.Title)`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 1.5rem;
`

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
`

export const StyledTitleInputWithLabel = styled(Form.Group)`
  margin-block: 0.5rem;
`

export const StyledInput = styled(Form.Control)<StyledInputProps>`
  font-size: 14px;
  border-radius: 8px !important;
  margin: ${(props) => props.margin || ''};

  :focus {
    border: 2px solid var(--everyday-blue) !important;
    box-shadow: none !important;
  }

  ::placeholder {
    color: var(--gray3);
  }
`

export const StyledLabel = styled(Form.Label)<StyledLabelProps>`
  font-weight: 600;
  font-size: 12px;
  color: var(--gray2);
  padding-bottom: 0.25rem;
`

export const StyledSelectInputWithLabel = styled(Form.Group)`
  width: 100%;
  margin-top: 10px;
`

export const StyledSelectInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:focus {
    border: '1px solid var(--everyday-red)';
  }

  @media (max-width: 750px) {
    display: grid;
    gap: 2rem;
  }
`

export const StyledSelectInput = styled(Form.Select)`
  border-radius: 8px;
  font-size: 14px;
  width: fit-content;
  border: none;
`

export const StyledOption = styled.option`
  border: none !important;
  border-radius: 8px;
`

export const StyledButton = styled(Button)<StyledButtonPropsType>`
  height: 36px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  background-color: ${(props) => props.backgroundColour || ''};
  color: ${(props) => props.textColour || ''};

  &:hover {
    background-color: ${(props) => props.backgroundColour || ''};
    opacity: 0.8;
  }
`
