import { getById } from 'shared/lib'
import { CHAT_DRAWER_ID } from '../ui/ChatDrawer'
export const useChatDrawerElement = () => {
  const getChatElement = () => {
    return getById(CHAT_DRAWER_ID)
  }
  const getChatRect = () => {
    return getChatElement()?.getBoundingClientRect()
  }

  return {
    getChatElement,
    getChatRect,
  }
}
