import React from 'react'
import clsx from 'clsx'
import PlusIcon from './img/Plus'
import { ActionTypes } from './utils'
import { type ITableAction, type ITableColumn, type ITableData } from '_entities/block'
import { useTableComponent } from './useTableComponent'
import { TableStyled } from './tableStyles'

interface Props {
  columns: ITableColumn[]
  data: ITableData[]
  dispatch: React.Dispatch<ITableAction>
  skipReset: boolean
  style: React.CSSProperties
  blockId: string
  actionMenuOpen?: boolean
}

export default function TableComponent({
  columns,
  data,
  dispatch: dataDispatch,
  skipReset,
  style,
  blockId,
  actionMenuOpen,
}: Props) {
  const {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    totalColumnsWidth,
    isTableResizing,
  } = useTableComponent({ columns, data, dataDispatch, skipReset })
  return (
    <TableStyled
      isSelected={actionMenuOpen}
      color={style?.color}
      backgroundColor={style?.backgroundColor}
      {...getTableProps()}
      className={clsx('table', isTableResizing() && 'noselect')}
      id={blockId}
    >
      {headerGroups.map((headerGroup) => (
        // eslint-disable-next-line react/jsx-key
        <div {...headerGroup.getHeaderGroupProps()} className='tr'>
          {headerGroup.headers.map((column) => column.render('Header'))}
        </div>
      ))}

      <div {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row)
          return (
            // eslint-disable-next-line react/jsx-key
            <div {...row.getRowProps()} className='tr'>
              {row.cells.map((cell) => (
                // eslint-disable-next-line react/jsx-key
                <div {...cell.getCellProps()} className='td'>
                  {cell.render('Cell')}
                </div>
              ))}
            </div>
          )
        })}

        <div
          style={{ width: `${totalColumnsWidth}px` }}
          className='tr add-row'
          onClick={() => dataDispatch({ type: ActionTypes.ADD_ROW })}
        >
          <span className='svg-icon svg-gray icon-margin'>
            <PlusIcon />
          </span>
          New
        </div>
      </div>
    </TableStyled>
  )
}
