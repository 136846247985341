import { type block, getBlockId } from '_entities/block'
import { IUserAttributes } from 'interfaces/auth/userData'
import { getElemAttr, removeElemAttr, setElemAttr, observeMutation } from 'shared/lib'

export enum EditorBlockAttrs {
  BLOCK_NAME = 'data-block-name',
  PAGE_ID = 'data-page-id',
  IS_SELECTED = 'data-is-selected',
  USER_PRESENCE = 'data-user-presence',
}

export interface EditorBlockAttrsData {
  [EditorBlockAttrs.BLOCK_NAME]: string
  [EditorBlockAttrs.PAGE_ID]: string
  [EditorBlockAttrs.IS_SELECTED]: boolean
  [EditorBlockAttrs.USER_PRESENCE]: IUserAttributes
}

export const getBlockElemAttr = (block: block, name: EditorBlockAttrs) => {
  return getElemAttr(getBlockId(block), name)
}

export const setBlockElemAttr = (
  block: block,
  name: EditorBlockAttrs,
  value: EditorBlockAttrsData[EditorBlockAttrs] | undefined,
) => {
  setElemAttr(getBlockId(block), name, JSON.stringify(value))
}

export const removeBlockElemAttr = (block: block, name: EditorBlockAttrs) => {
  removeElemAttr(getBlockId(block), name)
}

export const observeMutationOnBlockElement = (
  blockId: string,
  attr: EditorBlockAttrs,
  callback: () => void,
) => {
  const block = document.getElementById(blockId)
  if (!block) return
  return observeMutation(block, (mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'attributes' && mutation.attributeName === attr) {
        callback()
      }
    }
  })
}
