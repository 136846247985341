import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { NO_RESULTS_MESSAGE } from '_features/block/ui/SlashMenu/constants'

type ItemTitlePropsType = {
  paddingBlock?: string
}

export const ItemTitle = styled.div<ItemTitlePropsType>`
  color: #383838;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  width: fit-content;
  padding-block: ${(props) => props.paddingBlock || ''};
`
export const ItemSubtitle = styled.div`
  color: var(--gray2);
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  width: fit-content;
`

export const DropdownItem = styled(Dropdown.Item).attrs({
  className: 'skip-unset',
})`
  cursor: pointer;
  padding-block: 0.35rem !important;
  ${(props) => {
    if (props.title !== NO_RESULTS_MESSAGE) {
      return `
      cursor: pointer;
      &:hover {
        background: var(--object-menu-select-grey) !important;
      }
      &:hover,
      &.active {
        background: var(--object-menu-select-grey);
      }`
    } else {
      return `
      &:hover {
        background: none !important;
      }
      &:hover,
      &.active {
        background: none;
      }
      `
    }
  }}
`
export const IconTextContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 8px;
  width: 100%;
  padding-left: 0.35rem;
`

export const TitleSubtitleContainer = styled.div`
  width: fit-content;
  gap: 8px;
`

export const IconContainer = styled.div`
  width: 16px;
`
