import { ReactComponent as DragHandleIcon } from 'assets/images/draggable.svg'
import { HandleContainer } from './styles'
import { type block } from '_entities/block'
import React from 'react'
import { setIsBlockSelected } from '_widgets/DocumentBlock'
import { selectAllTextInBlock } from 'shared/lib'

interface Props {
  block: block
  setActionMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleRef?: React.RefObject<HTMLDivElement>
  top?: string | number
  left?: string | number
}
const EditorBlockHandle = (props: Props) => {
  return (
    <HandleContainer
      ref={props.handleRef}
      id={'editor-handle-dropdown'}
      onClick={() => {
        setIsBlockSelected(props.block)
        props.setActionMenuOpen(true)
        selectAllTextInBlock(props.block)
      }}
    >
      <DragHandleIcon />
    </HandleContainer>
  )
}

export default EditorBlockHandle
