import React from 'react'
import RadixDropdown, {
  IconButton,
  RowMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { ReactComponent as ShapeStrokeIcon } from 'assets/images/shape-stroke.svg'
import { getBlockPageId, getIndex, type block } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'
import ColorDropdownMenu from '../ColorDropdown/ColorDropdownMenu'
import { ShapeStrokeDropdownSection } from './styles'
import { IconWrapper } from '_entities/whiteboard/ui/styles'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { ShapeConfig } from 'konva/lib/Shape'
import { IconButtonSize } from '_features/toolbar'
import { icons } from 'shared/icons'

interface Props {
  block: block
}

export const strokeWidthOptions = () => [
  {
    width: 1,
    icon: <icons.strokeMin key='min-stroke-icon' />,
  },
  {
    width: 2,
    icon: <icons.strokeMid key='mid-stroke-icon' />,
  },
  {
    width: 3,
    icon: <icons.strokeMax key='max-stroke-icon' />,
  },
]

export const strokeDashOptions = () => [
  {
    dash: [],
    icon: <icons.lineStraight key='stroke-straight-icon' />,
  },
  {
    dash: [5, 5],
    icon: <icons.lineDashed key='stroke-dashed-icon' />,
  },
  {
    dash: [2, 2],
    icon: <icons.lineDotted key='stroke-dotted-icon' />,
  },
]

const ShapeStrokeDropdown = (props: Props) => {
  const { getInsertKeyInDataKeyOp, getReplaceBlockDataKeyOp, getDeleteKeyInDataKeyOp } = useJson1()
  const { submit } = useSubmit()

  const handleSelectColor = (color: string) => {
    const currentStroke = props.block.data.shape?.stroke
    const pageId = getBlockPageId(props.block)
    if (currentStroke) {
      submit(
        pageId,
        getReplaceBlockDataKeyOp(
          getIndex(props.block),
          ['shape', 'stroke'],
          color,
          currentStroke as string,
        ),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      submit(
        pageId,
        getInsertKeyInDataKeyOp(getIndex(props.block), ['shape', 'stroke'], color),
        SourceKeys.UPDATE_BLOCK,
      )
  }

  const handleSelectStrokeWidth = (strokeWidth: ShapeConfig['strokeWidth']) => {
    const currentStrokeWidth = props.block.data.shape?.strokeWidth
    const pageId = getBlockPageId(props.block)
    if (currentStrokeWidth) {
      submit(
        pageId,
        getReplaceBlockDataKeyOp(
          getIndex(props.block),
          ['shape', 'strokeWidth'],
          strokeWidth || 0,
          currentStrokeWidth as number,
        ),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      submit(
        pageId,
        getInsertKeyInDataKeyOp(getIndex(props.block), ['shape', 'strokeWidth'], strokeWidth || 0),
        SourceKeys.UPDATE_BLOCK,
      )
  }

  const handleSelectStrokeDash = (strokeDash: ShapeConfig['dash']) => {
    const currentStrokeDash = props.block.data.shape?.dash
    const pageId = getBlockPageId(props.block)

    if (currentStrokeDash) {
      submit(
        pageId,
        getReplaceBlockDataKeyOp(
          getIndex(props.block),
          ['shape', 'dash'],
          strokeDash || [],
          currentStrokeDash as number[],
        ),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      submit(
        pageId,
        getInsertKeyInDataKeyOp(getIndex(props.block), ['shape', 'dash'], strokeDash || []),
        SourceKeys.UPDATE_BLOCK,
      )
  }

  const handleDisableStroke = () => {
    const currentStroke = props.block.data.shape?.stroke
    if (currentStroke) {
      submit(
        getBlockPageId(props.block),
        getDeleteKeyInDataKeyOp(getIndex(props.block), ['shape', 'stroke']),
        SourceKeys.UPDATE_BLOCK,
      )
    }
  }

  const areWidthAndDashDisabled = !props.block.data.shape?.stroke
  return (
    <RadixDropdown
      trigger={
        <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
          <ShapeStrokeIcon />
        </IconButton>
      }
    >
      <ColorDropdownMenu
        selectedColor={props.block.data.shape?.stroke as string}
        onSelect={handleSelectColor}
        onSelectDisabled={handleDisableStroke}
      />
      <ShapeStrokeDropdownSection disabled={areWidthAndDashDisabled}>
        {strokeWidthOptions().map(
          (option: { width: ShapeConfig['strokeWidth']; icon: React.ReactNode }, i) => (
            <RowMenuItem
              disabled={areWidthAndDashDisabled}
              onSelect={() => handleSelectStrokeWidth(option.width)}
              key={`${props.block._id}-stroke-width-option-${i}`}
            >
              <IconWrapper
                disabled={areWidthAndDashDisabled}
                height='36px'
                selected={props.block.data.shape?.strokeWidth === option.width}
              >
                {option.icon}
              </IconWrapper>
            </RowMenuItem>
          ),
        )}
      </ShapeStrokeDropdownSection>
      <ShapeStrokeDropdownSection disabled={areWidthAndDashDisabled}>
        {strokeDashOptions().map(
          (option: { dash: ShapeConfig['dash']; icon: React.ReactNode }, i) => (
            <RowMenuItem
              disabled={areWidthAndDashDisabled}
              onSelect={() => handleSelectStrokeDash(option.dash)}
              key={`${props.block._id}-stroke-dash-option-${i}`}
            >
              <IconWrapper
                disabled={areWidthAndDashDisabled}
                height='36px'
                selected={
                  JSON.stringify(props.block.data.shape?.dash) === JSON.stringify(option.dash)
                }
              >
                {option.icon}
              </IconWrapper>
            </RowMenuItem>
          ),
        )}
      </ShapeStrokeDropdownSection>
    </RadixDropdown>
  )
}

export default ShapeStrokeDropdown
