import { IGroups, IPropertyDefinition } from 'interfaces/taskManager'
import { v4 as uuidv4 } from 'uuid'

export const addStatusCategory = (
  categoriesForUI: IGroups,
  properties: IPropertyDefinition[],
  categoryName: string,
  categoryType: string,
) => {
  const statusProperty = properties.find((p) => p.type === 'status')
  const statusValue = statusProperty?.statusValues.find((v) => v.category === categoryType)

  return {
    ...categoriesForUI,
    [uuidv4()]: {
      name: categoryName,
      property: {
        status: [{ current: true, statusValue }],
        propertyDefinition: { type: 'status', id: statusProperty?.id },
      },
      tasks: [],
    },
  }
}
