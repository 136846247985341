import { Doc } from 'sharedb'
import { type block } from '_entities/block'
import { IPermissions } from '_entities/user'
import * as json1 from 'ot-json1'
import { SourceKeys, SourceType } from 'interfaces/editor'
import { DeltaStatic } from 'quill'
import { setEditorContents } from 'shared/lib'

export const NOT_IN_REDIS_ERROR = 'Document not loaded'

export const getDoc = (pageId: string): Doc | undefined => {
  const connection = window.__CONNECTION__
  if (connection) {
    const doc: Doc = connection.get('pages', pageId)
    doc.submitSource = true
    return doc
  }
}

export const destroyDoc = (pageId: string) => {
  getDoc(pageId)?.destroy()
}

export const getBlocks = (pageId: string): block[] | undefined => {
  return getDoc(pageId)?.data?.blocks
}

export const getRole = (pageId: string): IPermissions | undefined => {
  return getDoc(pageId)?.data?.roleType
}

export const getBlocksLength = (pageId: string): number => {
  return getDoc(pageId)?.data?.blocks?.length || 0
}

export const docSubscribe = async (pageId: string) => {
  try {
    return await subscribeToDoc(pageId)
  } catch (e: unknown) {
    console.log('Subscribe to doc error', e)
    if ((e as Error).message === NOT_IN_REDIS_ERROR) {
      return await subscribeToDoc(pageId)
    }
  }
}

export const subscribeToDoc = (pageId: string): Promise<Doc | Error> | undefined => {
  const doc = getDoc(pageId)
  if (!doc) return
  return new Promise((resolve, reject) => {
    doc.subscribe((error) => {
      if (error) {
        reject(error)
      }
      resolve(doc)
    })
  })
}

export const getLastBlock = (pageId: string) => {
  const doc = getDoc(pageId)
  if (doc?.data && doc.data.blocks) {
    return doc.data.blocks[doc.data.blocks.length - 1]
  } else {
    return null
  }
}

export const getBlockById = (pageId: string, blockId: string): block | undefined => {
  const doc = getDoc(pageId)
  if (!doc?.data?.blocks) return
  return doc.data.blocks.find((b: block) => b._id === blockId)
}

const getUpdatedBlock = (doc: Doc, op: json1.JSONOp): block | undefined => {
  if (op) {
    const index = op[1]
    const updatedBlock = doc.data.blocks[index as number]
    if (updatedBlock && updatedBlock._id) {
      return updatedBlock
    }
  }
}

export const initializeDocListener = (pageId: string, callback: () => void) => {
  const doc = getDoc(pageId)
  if (!doc) return
  // Op listener
  doc.on('op', (op: json1.JSONOp, source: SourceType | false) => {
    if (doc.data && doc.data.blocks && Array.isArray(doc.data.blocks)) {
      if (!source) {
        callback()
        const updatedBlock = getUpdatedBlock(doc, op)
        if (updatedBlock) {
          const updatedRichText = updatedBlock.data.richText
          if (updatedRichText) {
            setEditorContents(updatedBlock._id, updatedRichText as DeltaStatic)
          }
        }
      } else if (
        source &&
        source.sourceKey &&
        Object.values(SourceKeys).includes(source.sourceKey)
      ) {
        callback()
      }
    }
  })
}

export const checkPageRole = async (pageId: string, callback: (role: IPermissions) => void) => {
  const role = getRole(pageId)
  if (role) callback(role)
  else {
    await docSubscribe(pageId)
    const role = getRole(pageId)
    if (role) callback(role)
  }
}
