import React from 'react'
import { StyledDivider } from './styles'

type Props = {
  width?: string
  height?: string
  color?: string
  margin?: string
}

const Divider: React.FC<Props> = ({ width, height, color, margin }) => {
  return <StyledDivider width={width} height={height} color={color} margin={margin} />
}

Divider.defaultProps = {
  width: '248px',
  height: '1px',
  color: '#cccccc',
  margin: '',
}

export default Divider
