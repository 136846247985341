import { IShapeTypes, ITools } from 'interfaces/whiteboard'
import {
  StyledPentagonIcon,
  StyledHexagonIcon,
  StyledRombIcon,
  StyledCircleIcon,
  StyledRectangleIcon,
  StyledCurvedRectangleIcon,
  StyledTriangleIcon,
  StyledStickyNoteIcon,
} from '../ui/icons'
import {
  IBlockTypes,
  type block,
  getBlockWidth,
  getBlockHeight,
  getBlockPageId,
} from '_entities/block'
import {
  CircleShape,
  SquareShape,
  SquareCShape,
  TriangleShape,
  RombShape,
  HexagonShape,
  PentagonShape,
} from 'shared/ui'
import {
  getCircleId,
  getHexagonId,
  getPentagonId,
  getRombId,
  getSquareCId,
  getSquareId,
  getTriangleId,
  getCirclePathId,
  getHexagonPathId,
  getPentagonPathId,
  getRombPathId,
  getSquareCPathId,
  getSquarePathId,
  getTrianglePathId,
} from 'shared/ui/Shapes'
import { useTransformer, useStage } from '_features/canvas'
import { useEmbed } from '_entities/embed'
import { constants, useKonvaNode } from '_entities/whiteboard'
import { getTextPositioningWrapperId } from 'whiteboard/Text/ResizableTextBlock'
import { KonvaEventObject, Node, NodeConfig } from 'konva/lib/Node'
import { useAppSelector } from 'redux/hooks'
import { useEvent } from '_features/canvas/event'
import { getById, getEditorElement } from 'shared/lib'
import { getDoc } from 'shared/shareDb'

export const useShape = () => {
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const _transformer = useTransformer()
  const _konvaNode = useKonvaNode()
  const _embed = useEmbed()
  const _stage = useStage()
  const _event = useEvent()

  const toolToTag = {
    [ITools.PENTAGON]: IBlockTypes.SHAPE,
    [ITools.HEXAGON]: IBlockTypes.SHAPE,
    [ITools.ROMB]: IBlockTypes.SHAPE,
    [ITools.CIRCLE]: IBlockTypes.SHAPE,
    [ITools.RECTANGLE]: IBlockTypes.SHAPE,
    [ITools.CURVED_RECTANGLE]: IBlockTypes.SHAPE,
    [ITools.TRIANGLE]: IBlockTypes.SHAPE,
    [ITools.CURSOR]: undefined,
    [ITools.HAND]: undefined,
    [ITools.PEN]: undefined,
    [ITools.ERASER]: undefined,
    [ITools.ARROW]: undefined,
    [ITools.EMOJI]: undefined,
    [ITools.INTERNAL_EMBED]: undefined,
    [ITools.EXTERNAL_EMBED]: undefined,
    [ITools.FILE]: undefined,
    [ITools.FRAME_SIXTEEN_NINE]: undefined,
    [ITools.FRAME_FOUR_THREE]: undefined,
    [ITools.FRAME_ONE_ONE]: undefined,
    [ITools.FRAME_CUS]: undefined,
    [ITools.STICKY]: IBlockTypes.STICKY_NOTE,
    [ITools.TEXT]: undefined,
    [ITools.FRAME]: undefined,
    [ITools.CTA]: undefined,
  }

  const toolToShapeType = {
    [ITools.PENTAGON]: IShapeTypes.PENTAGON,
    [ITools.HEXAGON]: IShapeTypes.HEXAGON,
    [ITools.ROMB]: IShapeTypes.ROMB,
    [ITools.CIRCLE]: IShapeTypes.CIRCLE,
    [ITools.RECTANGLE]: IShapeTypes.RECTANGLE,
    [ITools.CURVED_RECTANGLE]: IShapeTypes.CURVED_RECTANGLE,
    [ITools.TRIANGLE]: IShapeTypes.TRIANGLE,
    [ITools.CURSOR]: null,
    [ITools.HAND]: null,
    [ITools.PEN]: null,
    [ITools.ERASER]: null,
    [ITools.ARROW]: null,
    [ITools.EMOJI]: null,
    [ITools.INTERNAL_EMBED]: null,
    [ITools.EXTERNAL_EMBED]: null,
    [ITools.FILE]: null,
    [ITools.FRAME_SIXTEEN_NINE]: null,
    [ITools.FRAME_FOUR_THREE]: null,
    [ITools.FRAME_ONE_ONE]: null,
    [ITools.FRAME_CUS]: null,
    [ITools.STICKY]: null,
    [ITools.TEXT]: null,
    [ITools.FRAME]: null,
    [ITools.CTA]: null,
  }

  const shapes = {
    [IShapeTypes.RECTANGLE]: SquareShape,
    [IShapeTypes.CURVED_RECTANGLE]: SquareCShape,
    [IShapeTypes.CIRCLE]: CircleShape,
    [IShapeTypes.TRIANGLE]: TriangleShape,
    [IShapeTypes.ROMB]: RombShape,
    [IShapeTypes.HEXAGON]: HexagonShape,
    [IShapeTypes.PENTAGON]: PentagonShape,
  }

  const shapeIds = {
    [IShapeTypes.RECTANGLE]: getSquareId,
    [IShapeTypes.CURVED_RECTANGLE]: getSquareCId,
    [IShapeTypes.CIRCLE]: getCircleId,
    [IShapeTypes.TRIANGLE]: getTriangleId,
    [IShapeTypes.ROMB]: getRombId,
    [IShapeTypes.HEXAGON]: getHexagonId,
    [IShapeTypes.PENTAGON]: getPentagonId,
  }

  const pathIds = {
    [IShapeTypes.RECTANGLE]: getSquarePathId,
    [IShapeTypes.CURVED_RECTANGLE]: getSquareCPathId,
    [IShapeTypes.CIRCLE]: getCirclePathId,
    [IShapeTypes.TRIANGLE]: getTrianglePathId,
    [IShapeTypes.ROMB]: getRombPathId,
    [IShapeTypes.HEXAGON]: getHexagonPathId,
    [IShapeTypes.PENTAGON]: getPentagonPathId,
  }

  // Percentages of the original size to be reduced by
  // so it fits the inner shape rectangle

  const textSizeReducer = {
    [IShapeTypes.RECTANGLE]: {
      width: 0.8,
      height: 0.8,
    },
    [IShapeTypes.CURVED_RECTANGLE]: {
      width: 0.8,
      height: 0.8,
    },
    [IShapeTypes.CIRCLE]: {
      width: 0.7,
      height: 0.7,
    },
    [IShapeTypes.TRIANGLE]: {
      width: 0.52,
      height: 0.52,
    },
    [IShapeTypes.ROMB]: {
      width: 0.5,
      height: 0.5,
    },
    [IShapeTypes.HEXAGON]: {
      width: 0.65,
      height: 0.65,
    },
    [IShapeTypes.PENTAGON]: {
      width: 0.6,
      height: 0.75,
    },
  }

  const shapesWithTextOnBottom = [IShapeTypes.TRIANGLE, IShapeTypes.PENTAGON]

  const ShapeOptions = [
    ITools.PENTAGON,
    ITools.HEXAGON,
    ITools.ROMB,
    ITools.CIRCLE,
    ITools.RECTANGLE,
    ITools.CURVED_RECTANGLE,
    ITools.TRIANGLE,
  ]

  const iconRenderConfig = {
    [ITools.PENTAGON]: StyledPentagonIcon,
    [ITools.HEXAGON]: StyledHexagonIcon,
    [ITools.ROMB]: StyledRombIcon,
    [ITools.CIRCLE]: StyledCircleIcon,
    [ITools.RECTANGLE]: StyledRectangleIcon,
    [ITools.CURVED_RECTANGLE]: StyledCurvedRectangleIcon,
    [ITools.TRIANGLE]: StyledTriangleIcon,
    [ITools.CURSOR]: undefined,
    [ITools.HAND]: undefined,
    [ITools.PEN]: undefined,
    [ITools.ERASER]: undefined,
    [ITools.ARROW]: undefined,
    [ITools.EMOJI]: undefined,
    [ITools.INTERNAL_EMBED]: undefined,
    [ITools.EXTERNAL_EMBED]: undefined,
    [ITools.FILE]: undefined,
    [ITools.FRAME_SIXTEEN_NINE]: undefined,
    [ITools.FRAME_FOUR_THREE]: undefined,
    [ITools.FRAME_ONE_ONE]: undefined,
    [ITools.FRAME_CUS]: undefined,
    [ITools.STICKY]: StyledStickyNoteIcon,
    [ITools.TEXT]: undefined,
    [ITools.FRAME]: undefined,
    [ITools.CTA]: undefined,
  }

  const shapeIconMap = {
    [IShapeTypes.TRIANGLE]: {
      tool: ITools.TRIANGLE,
      icon: StyledTriangleIcon,
    },
    [IShapeTypes.ROMB]: {
      tool: ITools.ROMB,
      icon: StyledRombIcon,
    },
    [IShapeTypes.PENTAGON]: {
      tool: ITools.PENTAGON,
      icon: StyledPentagonIcon,
    },
    [IShapeTypes.HEXAGON]: {
      tool: ITools.HEXAGON,
      icon: StyledHexagonIcon,
    },
    [IShapeTypes.RECTANGLE]: {
      tool: ITools.RECTANGLE,
      icon: StyledRectangleIcon,
    },
    [IShapeTypes.CURVED_RECTANGLE]: {
      tool: ITools.CURVED_RECTANGLE,
      icon: StyledCurvedRectangleIcon,
    },
    [IShapeTypes.CIRCLE]: {
      tool: ITools.CIRCLE,
      icon: StyledCircleIcon,
    },
  }

  const iconFromBlockTag = (tag: IBlockTypes, shapeType: IShapeTypes) => {
    if (tag === IBlockTypes.SHAPE) return shapeIconMap[shapeType]
    if (tag === IBlockTypes.STICKY_NOTE)
      return { tool: ITools.STICKY, icon: iconRenderConfig[ITools.STICKY] }
  }

  const isBlockShape = (block: block) => {
    return block.data.tag === IBlockTypes.SHAPE
  }

  const getShapeType = (block: block) => {
    return block.data.shape?.type
  }

  const getShapeFill = (block: block) => {
    return block.data.shape?.fill
  }

  const getShapeStoke = (block: block) => {
    return block.data.shape?.stroke
  }

  const getShapeStrokeWidth = (block: block) => {
    return block.data.shape?.strokeWidth
  }

  const getShapeStrokeDashArray = (block: block) => {
    return block.data.shape?.dash
  }

  const getShapeElement = (block: block) => {
    const shapeType = getShapeType(block)
    if (!shapeType) return null
    const shapeId = shapeIds[shapeType]
    return getById(shapeId(block))
  }

  const getPathElement = (block: block): SVGGraphicsElement | null => {
    const shapeType = getShapeType(block)
    if (!shapeType) return null
    const pathId = pathIds[shapeType]
    return getById(pathId(block)) as unknown as SVGGraphicsElement
  }

  const findIfShapeBlockInTransformer = (pageId: string): boolean => {
    const doc = getDoc(pageId)
    const nodeInTransformer = _transformer.getSingleNodeFromTransformer(pageId)
    if (nodeInTransformer && doc?.data?.blocks) {
      const nodeId = nodeInTransformer.attrs.id
      const block = (doc.data.blocks as block[]).find((block) => block._id === nodeId)
      if (block && isBlockShape(block)) return true
      else return false
    } else return false
  }

  const setSVGSize = (block: block) => {
    const group = _konvaNode.getGroupNode(block)
    if (!group) return
    const element = getShapeElement(block)
    if (!element) return
    const defaultSizes = _embed.getEmbedDefaultSizes(block)
    const currentWidth = getBlockWidth(block)
    const currentHeight = getBlockHeight(block)
    // Assign SVG element width and height based on the main wrapper width and height
    if (!currentWidth || !currentHeight) {
      element.style.width = `${defaultSizes.width * group.scaleX()}px`
      element.style.height = `${defaultSizes.height * group.scaleY()}px`
    } else {
      element.style.width = `${currentWidth * group.scaleX()}px`
      element.style.height = `${currentHeight * group.scaleY()}px`
    }
  }

  const setQuillPositioningWrapperSize = (block: block) => {
    const pathElement = getPathElement(block)
    const rect = pathElement?.getBoundingClientRect()
    const shapeType = getShapeType(block)
    const quillPositioningWrapper = getById(getTextPositioningWrapperId(block))
    if (!quillPositioningWrapper || !rect || !shapeType) return
    if (shapesWithTextOnBottom.includes(shapeType))
      quillPositioningWrapper.style.alignItems = 'flex-end'
    else quillPositioningWrapper.style.alignItems = 'center'
    // Assign quill wrapper width and height based on the path width and height
    const unScaledPathSize = _stage.unScaleSize(getBlockPageId(block), rect)
    if (!unScaledPathSize) return
    quillPositioningWrapper.style.width = rect?.width ? `${unScaledPathSize.width}px` : '100%'
    quillPositioningWrapper.style.height = rect?.height ? `${unScaledPathSize.height}px` : '100%'
  }

  const setQuillElementSize = (block: block) => {
    const shapeType = getShapeType(block)
    const quillElement = getEditorElement(block._id)
    const pathElement = getPathElement(block)
    const rect = pathElement?.getBoundingClientRect()
    if (!rect || !quillElement || !shapeType) return
    // Assign the quill editor width and height based on the reduced sized to fit inner rect
    // of path element
    const unScaledPathSize = _stage.unScaleSize(getBlockPageId(block), {
      width: rect.width * textSizeReducer[shapeType].width,
      height: rect.height * textSizeReducer[shapeType].height,
    })
    if (!unScaledPathSize) return
    quillElement.style.width = rect?.width ? `${unScaledPathSize.width}px` : '100%'
    quillElement.style.height = rect?.height ? `${unScaledPathSize.height}px` : '100%'
  }

  const updateShapeSize = (block: block) => {
    setSVGSize(block)
    setQuillPositioningWrapperSize(block)
    setQuillElementSize(block)
  }

  // This function is for moving the shape preview ghost hovering when shape tool is selected
  const beforeMouseDownShapeMouseMove = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    if (ShapeOptions.includes(tool)) {
      const stage = e.target.getStage()
      if (!stage) return
      const [previewShape]: Node<NodeConfig>[] = stage.find(`.block-preview-${tool}`)
      const pageId = _event.getPageIdFromEvent(e)
      const scaledPosition = _stage.getScaledPointerPosition(pageId)
      if (scaledPosition) {
        if (tool === ITools.CURVED_RECTANGLE) {
          previewShape.x(scaledPosition.x)
          previewShape.y(scaledPosition.y)
        } else if (tool === ITools.RECTANGLE) {
          /* Move by one pixel if rectangle to enable stage mousedown */
          previewShape.x(scaledPosition.x + 1)
          previewShape.y(scaledPosition.y + 1)
        } else {
          previewShape.x(scaledPosition.x + constants.SHAPE_DEFAULT_WIDTH / 2)
          previewShape.y(scaledPosition.y + constants.SHAPE_DEFAULT_HEIGHT / 2)
        }
      }
    }
  }

  return {
    isBlockShape,
    getShapeType,
    getShapeFill,
    getShapeStoke,
    getShapeStrokeWidth,
    getShapeStrokeDashArray,
    getShapeElement,
    getPathElement,
    findIfShapeBlockInTransformer,
    ShapeOptions,
    iconRenderConfig,
    iconFromBlockTag,
    toolToTag,
    toolToShapeType,
    shapes,
    shapeIds,
    pathIds,
    textSizeReducer,
    shapesWithTextOnBottom,
    updateShapeSize,
    beforeMouseDownShapeMouseMove,
  }
}
