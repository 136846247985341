import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  setMembers,
  setPendingProjectMembers,
  setPages,
  setSelectedProjectFile,
} from 'redux/reducers/projectFileReducer'
import { setSelectedPage, setPageMembers } from 'redux/reducers/pageReducer'
import useLocalStorage from 'shared/lib/useLocalStorage'
import { useViewer } from '_entities/viewer'

export const useProjectFileSidebarTopElement = () => {
  const { clearLocalStorage } = useLocalStorage()
  const currentUser = useAppSelector((state) => state.global.user)
  const [modalShow, setModalShow] = useState<boolean>(false)
  const [showTooltipProjectName, setShowTooltipProjectName] = useState<boolean>(false)
  const handleSpaceModalClose = () => setModalShow(false)
  const currentProjectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const allProjectFiles = useAppSelector((state) => state.space.projectFilesForSelect)
  const navigate = useNavigate()
  const params = useParams()
  const dispatch = useAppDispatch()
  const _viewer = useViewer()

  const onLogoClick = () => {
    if (params && params.spaceId) {
      dispatch(setPages(undefined))
      dispatch(setSelectedProjectFile(undefined))
      dispatch(setSelectedPage(undefined))
      dispatch(setMembers([]))
      dispatch(setPendingProjectMembers([]))
      dispatch(setPageMembers(undefined))
      const isAnonymous = !currentUser?.email
      if (isAnonymous) {
        _viewer.deleteViewer()
        clearLocalStorage()
        navigate('/signin')
      } else navigate(`/space/${currentUser?.spaces[0].id}`)
    }
  }

  return {
    modalShow,
    setModalShow,
    handleSpaceModalClose,
    currentProjectFile,
    allProjectFiles,
    navigate,
    params,
    onLogoClick,
    setShowTooltipProjectName,
    showTooltipProjectName,
  }
}
