import styled from 'styled-components'
import { envVars } from 'config/env'

export const StyledSidebarReference = styled.div<{
  isDraggedOver?: boolean
  isSelected?: boolean
  levelOfNesting?: number
  justifyContent?: string
  color?: string
  borderRadius?: string
  hoveredBorderRadius?: string
  padding?: string
}>`
  max-width: 100%;
  height: fit-content;
  margin: 0 8px 0 ${(props) => (props.levelOfNesting ? props.levelOfNesting * 16 : 0)}px;
  padding: ${(props) => (props.padding ? props.padding : '6px 16px')};
  background: ${(props) => (props.isSelected ? 'white' : 'none')};
  border: ${(props) => (props.isDraggedOver ? '1px solid red' : 'none')};
  border-radius: 10px;
  color: ${(props) => (props.color ? props.color : 'black')};
  font-size: 14px !important;
  cursor: pointer;
  transition: background 0.4s, color 0.4s;
  display: flex;
  justify-content: ${(props) => (props.justifyContent ? props.justifyContent : 'space-between')};
  align-items: center;
  border-radius: ${(props) => props.borderRadius || '10px'};
  margin-left: 0.5rem;
  font-weight: ${(props) => (props.isSelected ? '600' : '500')};
  box-shadow: ${(props) => (props.isSelected ? '0px 1px 1px 0px rgba(56, 56, 56, 0.20)' : 'none')};

  &:hover {
    background: var(--secondary);
    color: black;
    border-radius: ${(props) => (props.hoveredBorderRadius ? props.hoveredBorderRadius : '')};
    & > div {
      transition: 0.4s;
      opacity: 1;
      border-radius: ${(props) => (props.hoveredBorderRadius ? props.hoveredBorderRadius : '')};
    }
  }
`
