import { DetailedColors, PaletteOverview, PaletteWrapper } from './styles'
import { IPalette, IPaletteColor } from 'interfaces/settings'
import React from 'react'
import useDropdownOptions from '../ColorSettings/useDropdownOptions'
import usePalette from './usePalette'
import ColorDetails from 'components/molecules/ColorDetails/ColorDetails'
import ColorPaletteDropdown from 'components/molecules/ColorPaletteDropdown/ColorPaletteDropdown'
import PaletteName from 'components/molecules/PaletteName/PaletteName'
import PaletteColors from 'components/molecules/PaletteColors/PaletteColors'

interface IProps {
  palette: IPalette
  selected: string
  handleSelectPalette: (palette: IPalette) => void
  handleExpandOnClick: (palette: IPalette) => void
  expanded: string
  fetchPalettes: () => void
  setDefaultPalette: () => void
  handleResetColorToDefault: (palette: IPalette) => void
}

export enum ColorPickerTypes {
  BACKGROUND = 'background',
  BORDER = 'border',
  PALETTE = 'palette',
  DETAILS = 'details',
}

export const getPaletteColorElementId = (type: ColorPickerTypes) => {
  return `palette-color-${type}`
}

const Palette = ({
  palette,
  selected,
  handleSelectPalette,
  handleExpandOnClick,
  expanded,
  fetchPalettes,
  setDefaultPalette,
  handleResetColorToDefault,
}: IProps) => {
  const {
    colorToChange,
    pickedColor,
    handleSelectColorToChange,
    handleChange,
    closeColorPicker,
    handleDuplicatePalette,
    deletePalette,
    handleEditPalette,
    isEdit,
    renameInputPosition,
    setRenameInputPosition,
    loading,
    isOpen,
    convertToTitleCase,
    userPalette,
    handleRenameColorPalette,
    handleShowError,
  } = usePalette({ palette, fetchPalettes, setDefaultPalette })

  const { dropdownOptions, defaultDropdownOptions } = useDropdownOptions({
    palette,
    handleDuplicatePalette,
    deletePalette,
    handleEditPalette,
    handleResetColorToDefault,
  })

  const NUM_OF_VISIBLE_PALETTES = 4

  if (!userPalette) {
    return <></>
  }

  return (
    <PaletteWrapper
      isSelected={selected === userPalette.id}
      onClick={() => handleSelectPalette(userPalette)}
    >
      <PaletteOverview>
        <PaletteName
          loading={loading}
          userPalette={userPalette}
          expanded={expanded}
          handleExpandOnClick={handleExpandOnClick}
          isEdit={isEdit}
          renameInputPosition={renameInputPosition}
          setRenameInputPosition={setRenameInputPosition}
          handleRenameColorPalette={handleRenameColorPalette}
        />
        <PaletteColors
          userPalette={userPalette}
          numberOfVisiblePalettes={NUM_OF_VISIBLE_PALETTES}
          handleSelectColorToChange={handleSelectColorToChange}
          handleChange={handleChange}
          closeColorPicker={closeColorPicker}
          colorToChange={colorToChange}
          pickedColor={pickedColor}
          handleExpandOnClick={handleExpandOnClick}
          expanded={expanded}
          isOpen={isOpen}
          colorType={colorToChange ? convertToTitleCase(colorToChange?.type) : ''}
          palette={palette}
          handleShowError={handleShowError}
        />
      </PaletteOverview>
      {expanded === userPalette.id && (
        <>
          <DetailedColors>
            {userPalette.attributes.colors.map((paletteColor: IPaletteColor) => {
              return (
                <ColorDetails
                  key={paletteColor.type}
                  paletteColor={paletteColor}
                  colorToChange={colorToChange}
                  isOpen={isOpen}
                  palette={palette}
                  handleSelectColorToChange={handleSelectColorToChange}
                  expanded={expanded}
                  userPalette={userPalette}
                  handleChange={handleChange}
                  colorType={convertToTitleCase(paletteColor.type)}
                  pickedColor={pickedColor}
                  closeColorPicker={closeColorPicker}
                  handleShowError={handleShowError}
                />
              )
            })}
          </DetailedColors>
          <ColorPaletteDropdown
            palette={palette}
            defaultDropdownOptions={defaultDropdownOptions}
            dropdownOptions={dropdownOptions}
          />
        </>
      )}
    </PaletteWrapper>
  )
}

export default Palette
