import { type block, getBlockTag, IBlockTypes } from '_entities/block'
import { usePosition } from '_features/canvas'

export const useImage = () => {
  const _position = usePosition()
  const isCanvasImage = (block: block) => {
    return (
      getBlockTag(block) === IBlockTypes.IMAGE &&
      _position.getBlockX(block) &&
      _position.getBlockY(block)
    )
  }

  return {
    isCanvasImage,
  }
}
