import styled from 'styled-components'

type StyleProps = {
  color?: string
  backgroundColor?: string
  isSelected?: boolean
}

export const TableStyled = styled.div<StyleProps>`
  border: ${(props) => (props.isSelected ? '1px solid var(--primary-color) ' : 'none')};

  background-color: ${(props) => (props.isSelected ? 'var(--selection-gray)' : 'var(--white)')};

  :focus {
    background-color: var(--selection-gray);
  }

  & > * {
    & svg {
      background: none;
      stroke: ${(props) => props.color} !important;
    }
    & .data-input {
      color: ${(props) => props.color} !important;
      background: ${(props) => props.backgroundColor};
    }
    & .th-content {
      color: ${(props) => props.color} !important;
      background: ${(props) => props.backgroundColor};
    }
    background: ${(props) => props.backgroundColor};
  }
`
