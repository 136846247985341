import { AxiosService } from 'services/axiosService/axiosService'

const useDefaultPage = () => {
  const axiosService = new AxiosService()

  const getDefaultPage = async (type: string) => {
    return await axiosService.getDefaultPage(type)
  }

  const setDefaultPage = async (pageId: string, isDefault?: boolean) => {
    return await axiosService.setDefaultPage(pageId, isDefault)
  }

  const getAllPages = async ({
    type,
    limit,
    page,
    search,
  }: {
    type: string
    limit?: number
    page?: number
    search?: string
  }) => {
    return await axiosService.getAllPages({
      type,
      limit,
      page,
      search,
    })
  }

  return {
    getDefaultPage,
    setDefaultPage,
    getAllPages,
  }
}

export default useDefaultPage
