import React from 'react'
import TableRow from 'components/molecules/TableRow/TableRow'
import CustomP from 'components/atoms/CustomP/CustomP'
import { Col } from 'react-bootstrap'

type SpaceTableHeaderPropsType = {
  titleList: string[]
}

const SpaceTableHeader = ({ titleList }: SpaceTableHeaderPropsType) => {
  return (
    <TableRow id='no-colour-on-hover'>
      {titleList.map((title, index) => {
        if (title === '') return <Col></Col>

        return (
          <Col className='d-flex align-items-center' key={`${title}${index}`}>
            <CustomP color={'var(--gray2)'} text={title} className='mb-0 text-bolded-600' />
          </Col>
        )
      })}
    </TableRow>
  )
}

export default SpaceTableHeader
