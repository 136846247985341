import { useEffect, useState } from 'react'
import { block } from '_entities/block'
import { ctaEndpoints } from '_features/cta'
import { useAppSelector } from 'redux/hooks'
import { CtaButton, getBlockCtaId } from '_entities/cta'

interface IProps {
  block: block
}

export const useCtaButton = (props: IProps) => {
  // ** State
  const [button, setButton] = useState<CtaButton | undefined>(undefined)
  const [editing, setEditing] = useState(false)
  const [error, setError] = useState<string | null>(null)

  // ** Query methods
  const [handleGetCtaButtonById] = ctaEndpoints.endpoints.getCtaButtonById.useLazyQuery()

  const fetchButton = async () => {
    try {
      const button = await handleGetCtaButtonById({
        projectFileId: '0',
        id: getBlockCtaId(props.block) || 0,
      }).unwrap()
      setButton(button)
    } catch (error) {
      setError('Error fetching button, it may be deleted.')
    }
  }

  useEffect(() => {
    fetchButton()
  }, [])

  const handleSetIsEditing = (isEditing: boolean) => {
    setEditing(isEditing)
  }

  return {
    button,
    setButton,
    editing,
    setEditing,
    fetchButton,
    handleSetIsEditing,
    error,
  }
}
