import { useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { useAppSelector } from 'redux/hooks'
import {
  CTA_BUTTONS_LIMIT,
  ClickAction,
  CtaButton,
  getCtaButtonId,
  DocumentBlockAttributes,
  getCtaBlocks,
} from '_entities/cta'
import { getElements } from 'shared/lib'
import {
  useDeleteCtaButton,
  ctaEndpoints,
  CTAButtonSettingsProps,
  useCreateCtaButton,
  useUpdateCtaButton,
  updateButtonsStyling,
} from '_features/cta'

export const useCta = (props?: CTAButtonSettingsProps) => {
  // ** State
  const [isButtonsDropdownOpen, setIsButtonsDropdownOpen] = useState(false)
  const [selectedButton, setSelectedButton] = useState<CtaButton | null>(null)

  // NEW STATES
  const [loadedData, setLoadedData] = useState<CtaButton[]>([])
  const [page, setPage] = useState(1)
  const [hasMore, setHasMore] = useState(true)
  const [loading, setLoading] = useState(false)

  // ** Redux state
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  // ** Query methods
  const [handleGetCtaButtons] = ctaEndpoints.endpoints.getCtaButtons.useLazyQuery()

  // ** Hooks
  const _updateCta = useUpdateCtaButton()
  const _createCta = useCreateCtaButton()
  const _deleteCta = useDeleteCtaButton()

  // ** Refs
  const wrapperRef = useRef<HTMLDivElement>(null)

  const loadData = async () => {
    if (!hasMore || loading || !projectFile) return // Don't load more if already loading or no more data available

    try {
      setLoading(true)

      const buttonsData = await handleGetCtaButtons({
        projectFileId: projectFile.id,
        limit: CTA_BUTTONS_LIMIT,
        page: page,
      }).unwrap()

      const newData = buttonsData.data

      // Append new data to the loadedData state
      setLoadedData((prevData) => [...prevData, ...newData])
      setSelectedButton(newData[0])
      setPage((prevPage) => prevPage + 1)
      setHasMore(buttonsData.meta.hasNextPage)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleScroll = () => {
    if (!wrapperRef.current || loading || !hasMore) return

    // If the user has scrolled to the bottom of the wrapper element load more data
    if (
      wrapperRef.current.scrollTop + wrapperRef.current.clientHeight + 5 >=
      wrapperRef.current.scrollHeight
    ) {
      loadData()
    }
  }

  useEffect(() => {
    const wrapper = wrapperRef.current

    if (wrapper) {
      wrapper.addEventListener('scroll', handleScroll)
      return () => {
        wrapper.removeEventListener('scroll', handleScroll)
      }
    }
  }, [hasMore, loading])

  useEffect(() => {
    loadData()
  }, [projectFile])

  useEffect(() => {
    props?.button && setButtonForEdit(props?.button)
  }, [props?.button])

  const updateLocalButtons = (updatedButton: CtaButton) => {
    setLoadedData((prevButtons) => {
      return prevButtons.map((button) => {
        if (button.id === updatedButton.id) {
          return updatedButton
        }

        return button
      })
    })
  }

  const debounceTitleChange = useMemo(
    () =>
      debounce((args: { id: number; title: string }) => {
        updateCtaButtonTittle(args)
      }, 1000),
    [],
  )

  const debounceLinkChange = useMemo(
    () =>
      debounce((args: { id: number; link: string }) => {
        updateCtaButtonLink(args)
      }, 1000),
    [],
  )

  const updateAllButtons = (ctaButton: CtaButton) => {
    setTimeout(() => {
      const buttons = getCtaBlocks(ctaButton)

      if (buttons) {
        buttons.forEach((button) => {
          button.setAttribute(DocumentBlockAttributes.BUTTON_JUST_UPDATED, 'true')
        })
      }
    }, 1000)
  }

  const updateCtaButtonLink = async ({ id, link }: { id: number; link?: string }) => {
    await updateCtaButton({ id, link })
  }

  const updateCtaButtonTittle = async ({ id, title }: { id: number; title?: string }) => {
    await updateCtaButton({ id, title })
  }

  const onTitleChange = (e: React.FormEvent<HTMLDivElement>, button: CtaButton) => {
    const buttons = Array.from(getElements(`#${getCtaButtonId(button.id)}`))

    if (buttons) {
      buttons.forEach((button) => {
        button.textContent = e.currentTarget.textContent || ''
      })
    }

    debounceTitleChange({ id: button.id, title: e.currentTarget.textContent || '' })
  }

  const onChangeLink = (e: React.ChangeEvent<HTMLInputElement>, button: CtaButton) => {
    debounceLinkChange({ id: button.id, link: e.target.value || '' })
  }

  const toggleIsButtonsContainerOpen = () => {
    setIsButtonsDropdownOpen(!isButtonsDropdownOpen)
  }

  const setButtonForEdit = (button: CtaButton) => {
    const updatedButton = loadedData.find((b) => b.id === button.id)
    setSelectedButton(updatedButton || button)
  }

  const updateCtaButton = async ({
    id,
    title,
    onClickAction,
    link,
    styling,
  }: {
    id: number
    title?: string
    onClickAction?: ClickAction
    link?: string
    styling?: {
      font?: string
      fontSize?: number
      textColor?: string
      buttonColor?: string
      buttonSize?: number
      borderRadius?: number
      borderColor?: string
    }
  }) => {
    styling && updateButtonsStyling(id, styling, loadedData)

    const response = await _updateCta.updateCtaButton({
      id,
      title,
      onClickAction,
      link,
      styling,
    })

    if (response) {
      setSelectedButton(response)
      updateLocalButtons(response)
      updateAllButtons(response)

      return response
    }
  }

  const createCtaButton = async () => {
    const response = await _createCta.createCtaButton()

    if (response) {
      setLoadedData((prevButtons) => [response, ...prevButtons])
      setSelectedButton(response)
    }
  }

  const deleteButton = async (id: number) => {
    await _deleteCta.deleteCtaButton(id)
    setLoadedData((prevButtons) => {
      if (prevButtons.length === 1) return [...prevButtons]
      setSelectedButton(prevButtons.filter((button) => button.id !== id)[0])
      return prevButtons.filter((button) => button.id !== id)
    })
  }

  return {
    onTitleChange,
    toggleIsButtonsContainerOpen,
    isButtonsDropdownOpen,
    setButtonForEdit,
    selectedButton,
    updateCtaButton,
    createCtaButton,
    onChangeLink,
    setSelectedButton,
    deleteButton,
    wrapperRef,
    loadedData,
  }
}
