import styled from 'styled-components'
import { Col } from 'react-bootstrap'

type StyledLeftSidebarStyledProps = {
  padding?: string
  paddingTop?: string
}

/* Left sidebar */

import { ReactComponent as CounterClockwiseIconStyled } from 'assets/images/clockCounterClockwise.svg'

export const StyledLeftSidebar = styled(Col)<StyledLeftSidebarStyledProps>`
  border-top: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
  padding: ${(props) => (props.padding ? props.padding : '')};
  padding-top: ${(props) => (props.paddingTop ? props.paddingTop : '')};
  user-select: none;

  & * {
    font-size: 14px !important;
  }
`

export const Separator = styled.div`
  width: 100%;
  padding: 16px;
`

export const Line = styled.div`
  height: 3px;
  background-color: var(--gray4);
`
