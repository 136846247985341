import SpaceTabHeaderWrapper from 'components/molecules/SpaceTabHeaderWrapper/SpaceTabHeaderWrapper'
import AuthInput from 'components/atoms/AuthInput/AuthInput'
import TableRow from 'components/molecules/TableRow/TableRow'
import CustomP from 'components/atoms/CustomP/CustomP'
import AvatarPlaceholder from 'assets/images/avatar-placeholder.png'
import { StyledSpaceMembers, StyledCol, StyledAvatar, Dots } from './styles'
import { IRoles } from 'interfaces/invite'
import { useSpaceMembers } from './useSpaceMembers'
import { CustomDropdown } from 'components/molecules/CustomDropdown/CustomDropdown'
import { useSpaceMembersOptions } from './useSpaceMemberOptions'
import { NewSelect } from 'components/molecules/NewSelect/NewSelect'
import magnifierIcon from 'assets/images/MagnifierIcon.svg'
import { envVars } from 'config/env'
import SpaceTabBodyHeaderText from 'components/molecules/SpaceTabBodyHeaderText/SpaceTabBodyHeaderText'
import SpaceTableHeader from '../SpaceTableHeader/SpaceTableHeader'
import { spaceMembersHeaderTitles } from './data'
import { useSpace } from '_entities/space'

const SpaceMembers = () => {
  const {
    filteredSpaceMembers,
    handleFilter,
    sortedMembers,
    isFullEmailTextShown,
    setIsFullEmailTextShown,
    setIsFullNameTextShown,
    indexOfFullTextElement,
    setIndexOfFullTextElement,
    isUserTypingInInput,
  } = useSpaceMembers()

  const { rolesConfig, SpaceMemberDropdownOptions, isMenuHidden, setIsMenuHidden } =
    useSpaceMembersOptions()

  const _space = useSpace()

  return (
    <StyledSpaceMembers>
      <SpaceTabHeaderWrapper position='relative' left='-0.75rem' top='-0.05rem'>
        <AuthInput
          type='text'
          placeholder='Search members'
          className='pt-2 pb-1 remove-borders me-auto'
          onChangeFunction={(e) => handleFilter(e)}
          iconLeft={magnifierIcon}
          paddingLeft='2.75rem'
          width='200px'
        />

        <SpaceTabBodyHeaderText text={'All members'} />
      </SpaceTabHeaderWrapper>

      <SpaceTableHeader titleList={spaceMembersHeaderTitles} />

      {(filteredSpaceMembers.length
        ? filteredSpaceMembers
        : !isUserTypingInInput
        ? sortedMembers
        : []
      ).map((member, i) => {
        return (
          <TableRow key={i} padding='0.25rem 1rem'>
            <StyledCol className='d-flex align-items-center allowFullText overflow-hidden'>
              <StyledAvatar
                src={member.photo ? `${envVars.api.s3Butcket}${member.photo}` : AvatarPlaceholder}
              />
              <CustomP
                fontSize='13px'
                className='mb-0'
                onMouseEnter={() => {
                  setIsFullNameTextShown(true)
                  setIndexOfFullTextElement(i)
                }}
                onMouseLeave={() => {
                  setIsFullNameTextShown(false)
                  setIndexOfFullTextElement(-1)
                }}
              >
                {member.name}
              </CustomP>
            </StyledCol>
            <StyledCol className='d-flex align-items-center allowFullText'>
              <CustomP
                text={member.email}
                className='mb-0 overflow-hidden'
                onMouseEnter={() => {
                  setIsFullEmailTextShown(true)
                  setIndexOfFullTextElement(i)
                }}
                onMouseLeave={() => {
                  setIsFullEmailTextShown(false)
                  setIndexOfFullTextElement(-1)
                }}
              />
              {isFullEmailTextShown && indexOfFullTextElement === i && (
                /* Email tooltip */
                <CustomP text={member.email} className='mb-0 full-text-on-hover' />
              )}
            </StyledCol>

            <StyledCol className='d-flex align-items-center'>
              {member.role === IRoles.OWNER ? (
                <CustomP text={member.role} className='mb-0' />
              ) : (
                <NewSelect
                  options={rolesConfig(member.memberId, member.role)[`${_space.getSpaceRole()}`]}
                  optionWidth='156px'
                  defaultValue={member.role}
                  optionDisplay='flex'
                  optionPadding='8px 0 8px 10px'
                  optionJustifyContent='left'
                  optionAlignContent='center'
                  optionBorder
                  lastOptionTopBorder='1px solid var(--gray2)'
                />
              )}
            </StyledCol>

            <StyledCol className='d-flex align-items-center'>
              <CustomP
                text={member.lastActive}
                className='mb-0'
                color='var(--gray2)'
                fontWeight='600'
                fontSize='13px'
              />
            </StyledCol>

            <StyledCol className='d-flex align-items-center justify-content-end'>
              <CustomDropdown
                dropdownOptions={SpaceMemberDropdownOptions(
                  member.memberId,
                  member.roleId,
                  member.email,
                )}
                styles={'position: fixed'}
                toggleButton={<Dots />}
                positionDropdown='absolute'
                positionDropdownMenu='absolute'
                className='toggleRepositionInZDirection'
                transformMenu='translateX(-105%)'
                autoClose={'outside'}
                dropdownSelector='spaceMembersDropdownModal'
                onTogglerClick={() => setIsMenuHidden(false)}
                isMenuHidden={isMenuHidden}
              />
            </StyledCol>
          </TableRow>
        )
      })}
    </StyledSpaceMembers>
  )
}

export default SpaceMembers
