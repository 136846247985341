import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { AxiosService } from 'services/axiosService/axiosService'
import { setPageIconById, setPagesWithUpdatedTitle } from 'redux/reducers/projectFileReducer'
import { PageLinkSharingEnum } from 'interfaces/invite'
import { IPage } from 'interfaces/page'
import { Doc as json1Doc } from 'ot-json1'
import { IBlockTypes } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useShareDb } from '_entities/page'
import { DeltaStatic } from 'quill'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { getBlocks } from 'shared/shareDb'
import { getEditor } from 'shared/lib'

export const useUpdatePage = () => {
  const AxiosInstance = new AxiosService()
  const dispatch = useAppDispatch()
  const _shareDb = useShareDb()
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _projectFile = useGetProjectFile()
  const pages = useAppSelector((state) => state.projectFile.pages)
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const updatePage = async (
    pageId?: string,
    projectId?: number,
    linkSharing?: PageLinkSharingEnum,
    access?: string,
    backgroundImage?: string,
  ) => {
    return await AxiosInstance.updatePage({
      pageId,
      projectId,
      linkSharing,
      access,
      backgroundImage,
    })
  }

  const spreadPages = (pageId: string, title: string) => {
    const spreadPages = pages && [...pages]
    const updatedPages =
      spreadPages &&
      spreadPages.map((page) => {
        const spreadPage = { ...page }
        if (page.id === pageId) {
          spreadPage.text = title
        }

        return spreadPage
      })
    dispatch(setPagesWithUpdatedTitle(updatedPages))
  }

  const getDocAndUpdateTitle = (pageId: string, title: string) => {
    _shareDb.handleUnsubscribedPage(pageId, (doc) => {
      if (doc.data) {
        updateTitleInDoc(pageId, title)
      }
    })
  }

  const updateTitleInDoc = (pageId: string, title: string) => {
    const blocks = getBlocks(pageId)
    if (!blocks) return
    const index = blocks.findIndex(
      (block) =>
        block.data.tag === IBlockTypes.TITLE ||
        (block.data.richText as DeltaStatic)?.ops?.some((op) => op.attributes?.header === 1),
    )

    if (index === -1) return

    const delta = {
      ops: [
        {
          insert: title,
        },
        {
          insert: '\n',
          attributes: {
            header: 1,
          },
        },
      ],
    }

    const op = _json1.getReplaceBlockDataKeyOp(
      index,
      ['richText'],
      delta as json1Doc,
      blocks[index]?.data?.richText as json1Doc,
    )

    _submit.submit(pageId, op, SourceKeys.UPDATE_BLOCK)

    const editor = getEditor(blocks[index]?._id)

    if (editor) {
      editor?.setContents(delta as DeltaStatic)
      setTimeout(() => {
        editor.setSelection(editor.getLength() - 1, 0, 'api')
      }, 500)
    }
  }

  const updateTitleAndDoc = async (title: string, pageId: string) => {
    const updateTitleResponse =
      project && (await AxiosInstance.updatePageTitle(title, parseInt(project.id), pageId))
    if (updateTitleResponse) {
      spreadPages(pageId, title)
      getDocAndUpdateTitle(pageId, title)
    }
  }

  const updateTitle = async (title: string, pageId: string) => {
    const updateTitleResponse =
      project?.id && (await AxiosInstance.updatePageTitle(title, parseInt(project.id), pageId))

    if (updateTitleResponse) {
      // spreadPages(pageId, title)
      project && _projectFile.getAndDispatchProjectFilePages(project?.id)
    }
  }

  const updateIcon = async (icon: string, projectFileId: string, pageId: string) => {
    const response = await AxiosInstance.updatePageIcon(icon, parseInt(projectFileId), pageId)
    if (response) {
      const icon = response.data.data?.find((page: IPage) => page?.id === pageId)?.attributes?.icon
      if (pageId)
        dispatch(
          setPageIconById({
            pageId: pageId,
            icon: icon || null,
          }),
        )
    }
  }

  return {
    updatePage,
    updateTitle,
    updateIcon,
    updateTitleAndDoc,
  }
}
