import { ITools } from 'interfaces/whiteboard'

export enum sizes {
  FRAME_SIXTEEN_NINE = '16:9',
  FRAME_FOUR_THREE = '4:3',
  FRAME_ONE_ONE = '1:1',
  FRAME_CUSTOM = 'Custom',
}

export interface IFrameOption {
  tool: ITools
  child: JSX.Element
  name: sizes
}
