import DeleteRed, { ReactComponent as DeleteRedIcon } from 'assets/images/deleteRed.svg'
import EmailSvg, { ReactComponent as Email } from 'assets/images/email.svg'

export const useProjectFileMembers = () => {
  const pendingOptions = [
    {
      name: 'Remove',
      value: 5,
      color: '#FA5A32',
      icon: DeleteRedIcon,
      src: DeleteRed,
      alt: 'Delete',
    },
    { name: 'Resend invite', value: 6, borderTop: true, icon: Email, src: EmailSvg, alt: 'Email' },
  ]

  const projectRolesOwner = [
    { name: 'Can Edit', value: '2' },
    { name: 'Can Comment', value: '3' },
    { name: 'Can View', value: '4' },
    { name: 'Remove access', value: '0', src: DeleteRed, alt: 'Remove' },
  ]

  const projectRoles = [
    { name: 'Can Edit', value: '2' },
    { name: 'Can Comment', value: '3' },
    { name: 'Can View', value: '4' },
  ]

  return {
    pendingOptions,
    projectRolesOwner,
    projectRoles,
  }
}
