import { type block, getBlockPageId, getIndex } from '_entities/block'
import { useShape } from '_entities/shape'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { SourceKeys } from 'interfaces/editor'
import { useCallback } from 'react'

export const useShapeColor = () => {
  const _shape = useShape()
  const _submit = useSubmit()
  const _json1 = useJson1()

  const handleSelectColor = useCallback((block: block, color: string) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    if (index < 0) return
    const currentFill = _shape.getShapeFill(block)
    if (currentFill) {
      _submit.submit(
        pageId,
        _json1.getReplaceBlockDataKeyOp(index, ['shape', 'fill'], color, currentFill),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      _submit.submit(
        pageId,
        _json1.getInsertKeyInDataKeyOp(index, ['shape', 'fill'], color),
        SourceKeys.UPDATE_BLOCK,
      )
  }, [])

  const handleDisableColor = useCallback((block: block) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    if (index < 0) return
    const currentFill = _shape.getShapeFill(block)
    if (currentFill) {
      _submit.submit(
        pageId,
        _json1.getDeleteKeyInDataKeyOp(index, ['shape', 'fill']),
        SourceKeys.UPDATE_BLOCK,
      )
    }
  }, [])

  return {
    handleSelectColor,
    handleDisableColor,
  }
}
