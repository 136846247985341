import styled from 'styled-components'

type TitleTextProps = {
  margin?: string
}

export const ReferralTabUserModalStyled = styled.div`
  padding: 8px;
  font-style: normal;
  width: fit-content;

  & *::placeholder {
    color: #d8d8d8;
  }
`
export const TitleText = styled.div<TitleTextProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  padding-bottom: 32px;
  margin: ${(props) => (props.margin ? props.margin : '')};
  color: var(--primary-text);
`
export const Hr = styled.hr`
  color: #aeb0b4 !important;
  border-color: #aeb0b4 !important;
  margin-top: 25px;
  margin-bottom: 25px;
`

export const HrAndOrContainer = styled.div`
  & span {
    display: flex;
    flex-direction: row;
    padding-block: 1rem;
  }
  & span:before,
  span:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #aeb0b4 !important;
    margin: auto;
    opacity: 0.25;
  }
  & span:before {
    margin-right: 10px;
  }
  & span:after {
    margin-left: 10px;
  }
`

export const InfoSpanText = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: #aeb0b4;
`

export const ModalInfoLabel = styled.div`
  font-weight: 600;
  font-size: 13px;
  line-height: 120%;
  color: #aeb0b4;
  margin-bottom: 4px;
`
export const ProgressBar = styled.div`
  height: 8px;
  min-height: 8px;
`
type ProgressProps = {
  value: number
}
export const ProgressLine = styled.div<ProgressProps>`
  width: ${(props) => props.value}%;
  min-width: ${(props) => props.value}%;
  min-height: 8px;
`

export const InfoText = styled.div`
  padding-bottom: 25px;
  word-break: break-all;
  font-size: 13px;
  color: var(--primary-text);
`
export const InfoTextSmall = styled.div`
  width: 100%;
  padding-bottom: 12px;
  text-align: center;
  font-size: 14px;
  word-break: break-all;
  color: var(--secondary-text);
`

export const FlexRowContainerForInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  height: 36px;
`

export const ModalCopyLinkButton = styled.button`
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: white;
  font-weight: 600;
  font-size: 14px;
  height: 100%;
  width: 100%;
  text-align: center !important;
  /* margin-top: 8px; */
  padding: 5px 14px 5px 14px;
  background: var(--everyday-blue) !important;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  :disabled {
    background: var(--gray3) !important;
    opacity: 0.7;
  }

  :active {
    filter: none;
  }

  :hover {
    opacity: 0.8;
  }
`
