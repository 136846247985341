import { EditWrapper } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/BlockEdit/styles'
import { getColor } from 'shared/colors'
import CustomP from 'components/atoms/CustomP/CustomP'
import React from 'react'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { useBorderRadiusEdit } from '_features/embed'
import { StyledInput } from './styles'
import { SidebarEditProps } from './EmbedEdit'

export const BorderRadiusEdit = (props: SidebarEditProps) => {
  const _radiusEdit = useBorderRadiusEdit({ block: props.block })

  return (
    <EditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Border radius
      </CustomP>
      <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
        <StyledInput
          type='number'
          value={_radiusEdit.borderRadius}
          onChange={(e) => _radiusEdit.onRadiusChange(props.block, parseInt(e.target.value))}
        />
        px
      </Wrapper>
    </EditWrapper>
  )
}
