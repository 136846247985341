import { ReactNode } from 'react'
import { useAppSelector } from 'redux/hooks'
import { ChannelOption } from 'redux/reducers/chatReducer'
import { ReactComponent as AtIcon } from 'assets/images/at.svg'
import { useDefaultChannel } from 'components/organisms/Chat/useDefaultChannel'
import { useIcons } from 'shared/icons'
import { PageTypesEnum } from 'interfaces/projectFile'

export type DropdownChannelOption = ChannelOption & {
  icon: ReactNode
}

export const useChatDrawer = () => {
  const selectedChannel = useAppSelector((state) => state.chat.selectedChannel)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const pagesWithoutTaskManager = pages?.filter((page) => page.type !== PageTypesEnum.TASK_MANAGER)
  const { DEFAULT_CHANNEL } = useDefaultChannel()
  const _icons = useIcons()

  const defaultOption: DropdownChannelOption = {
    ...DEFAULT_CHANNEL,
    icon: <AtIcon />,
  }

  const pageOptions: DropdownChannelOption[] =
    pagesWithoutTaskManager?.map((page) => {
      const Icon = _icons.PageTypeIcon[page.type as keyof typeof _icons.PageTypeIcon]

      return {
        label: page.text,
        icon: <Icon />,
        cid: `messaging:${page.id}`,
      }
    }) || []

  const channelOptions: DropdownChannelOption[] = [defaultOption, ...pageOptions]

  const selectedChannelOption = channelOptions.find((option) => option.cid === selectedChannel)

  return { channelOptions, selectedChannelOption, DEFAULT_CHANNEL }
}
