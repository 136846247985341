import { StyledBlockToolbar } from 'editor/EditableBlock/BlockToolbar/styles'
import { block } from '_entities/block'
import SharedToolbar from 'components/organisms/SharedToolbar/SharedToolbar'
import React from 'react'
import { EditorToolbarFramer } from 'utils/framerConfig'
import { icons } from 'shared/icons'
import { IconButtonSize } from '_features/toolbar'
import RadixDropdown, {
  DropdownMenuItem,
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { IRoles } from 'interfaces/invite'
import { useCtaToolbar } from '../hooks'
import { useActionMenu } from '_widgets/DocumentBlock'
import {
  ALIGN_CENTRE_ICON_SELECTOR,
  ALIGN_LEFT_ICON_SELECTOR,
  ALIGN_RIGHT_ICON_SELECTOR,
  AlignCentreIcon,
  AlignLeftIcon,
  AlignRightIcon,
} from '_features/editor'
import { useUpdateCtaButton } from '_features/cta'

interface CtaToolbarProps {
  block: block
  innerRef: React.RefObject<HTMLDivElement>
}

export const CtaToolbar = (props: CtaToolbarProps) => {
  // ** Hooks
  const _toolbar = useCtaToolbar()
  const _actionMenu = useActionMenu()
  const _updateCtaButton = useUpdateCtaButton()

  return (
    <StyledBlockToolbar ref={props.innerRef} {...EditorToolbarFramer}>
      {/* Edit CTA button*/}
      {_toolbar.myRole === IRoles.OWNER && (
        <IconButton
          width={IconButtonSize + 'px'}
          height={IconButtonSize + 'px'}
          onMouseUp={() => {
            _toolbar.setIsCtaEditing(props.block)
            _actionMenu.closeActionMenu()
          }}
        >
          <icons.edit />
        </IconButton>
      )}

      {/* Align CTA button */}
      <RadixDropdown
        trigger={
          <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
            <icons.align />
          </IconButton>
        }
        flexDirection={'row'}
        menuRef={props.innerRef}
      >
        <DropdownMenuItem onSelect={() => _updateCtaButton.handleAlign(props.block, 'left')}>
          <AlignLeftIcon id={ALIGN_LEFT_ICON_SELECTOR} />
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={() => _updateCtaButton.handleAlign(props.block, 'center')}>
          <AlignCentreIcon id={ALIGN_CENTRE_ICON_SELECTOR} />
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={() => _updateCtaButton.handleAlign(props.block, 'right')}>
          <AlignRightIcon id={ALIGN_RIGHT_ICON_SELECTOR} />
        </DropdownMenuItem>
      </RadixDropdown>

      <SharedToolbar block={props.block} closeActionMenu={_actionMenu.closeActionMenu} />
    </StyledBlockToolbar>
  )
}
