import { useAppDispatch } from 'redux/hooks'
import { setSelectedPage, setPageRoles } from 'redux/reducers/pageReducer'
import { getPage } from './getPage'
import { AxiosService } from 'services/axiosService/axiosService'
import { IPageMember } from 'interfaces/page'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'

export const useGetPage = () => {
  const AxiosInstance = new AxiosService()
  const dispatch = useAppDispatch()
  const _getProjectFile = useGetProjectFile()
  const getAndDispatchPage = async (projectFileId: string, selectedPageId: string) => {
    const page = await getPage(projectFileId, selectedPageId)
    if (page) {
      dispatch(setSelectedPage(page))
      _getProjectFile.getAndDispatchProjectFilePages(page.projectFileId)
    }
  }

  const getPageMembers = async (pageId: string): Promise<IPageMember[] | undefined> => {
    return await AxiosInstance.getPageMembers(pageId)
  }

  const getPendingUsers = async (pageId: string) => {
    return await AxiosInstance.getPendingMembers(pageId)
  }

  const getPageRoles = async () => {
    return await AxiosInstance.getPageRoles()
  }

  const getAndDispatchPageRoles = async () => {
    const response = await getPageRoles()
    if (response) {
      dispatch(setPageRoles(response))
    }
  }

  const getPageObjects = async (pageId: string, objectId: string) => {
    return await AxiosInstance.getPageObjects(pageId, objectId)
  }

  return {
    getAndDispatchPage,
    getPageMembers,
    getPendingUsers,
    getPageRoles,
    getAndDispatchPageRoles,
    getPageObjects,
  }
}
