import React from 'react'
import {
  AssigneesWrapper,
  PropertyTitle,
  PropertyWrapper,
} from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/styles'
import { icons } from 'shared/icons'
import Avatar from 'components/atoms/Avatar/Avatar'
import { envVars } from 'config/env'
import MultiSelect from 'components/organisms/MultiSelect/MultiSelect'
import personOption from 'components/molecules/PersonOption/PersonOption'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import useAssignees from '../model/useAsignees'
import { type block } from '_entities/block'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { StyledButton } from './styles'

interface IProps {
  block: block
}

const Assignee = (props: IProps) => {
  const _assignees = useAssignees({ block: props.block })

  return (
    <PropertyWrapper>
      <PropertyTitle>Assignee</PropertyTitle>

      <RadixDropdown
        trigger={
          <StyledButton>
            {_assignees.assignees?.length === 0 ? (
              <icons.userPlus />
            ) : (
              <AssigneesWrapper>
                {_assignees.assignees?.slice(0, 3).map((assignee, index) => (
                  <Wrapper zindex={`-${index}`} key={assignee?.value}>
                    <Avatar
                      src={assignee?.photo ? `${envVars.api.s3Butcket}${assignee?.photo}` : ''}
                      userName={`${assignee?.label}`}
                    />
                  </Wrapper>
                ))}
                {_assignees.assignees && _assignees.assignees.length > 3 && (
                  <Avatar src={''} userName={`${_assignees.assignees.length - 3}`} key={''} />
                )}
              </AssigneesWrapper>
            )}
          </StyledButton>
        }
      >
        <Wrapper padding={'8px 0 4px 0'}>
          <MultiSelect
            options={_assignees.options}
            onChange={(newValue: any) => _assignees.onAssigneesChange(newValue, props.block)}
            formatOptionLabel={personOption as any}
            placeholder={'Search'}
            value={_assignees.assignees}
            isSearchable={true}
          />
        </Wrapper>
      </RadixDropdown>
    </PropertyWrapper>
  )
}

export default Assignee
