import { ClickAction } from '_entities/cta'
import { useAppDispatch } from 'redux/hooks'
import { setLeftMinimize, setRightMinimize } from 'redux/reducers/projectFileReducer'
import { useToast } from 'services/helpers/useToast'

export const useOnCtaClick = () => {
  const dispatch = useAppDispatch()
  const toast = useToast()

  const handleClick = (onClickAction: ClickAction, url?: string) => {
    if (onClickAction === ClickAction.OPEN_URL) {
      try {
        window.open(url, '_blank')
      } catch (e) {
        toast.error('Please enter a valid URL in CTA settings.')
      }
    } else if (onClickAction === ClickAction.OPEN_PAGE) {
      try {
        window.location.href = url || ''
      } catch (e) {
        toast.error('Please enter a valid URL in CTA settings.')
      }
    } else if (onClickAction === ClickAction.OPEN_LEFT_RAIL) {
      dispatch(setLeftMinimize(false))
    } else if (onClickAction === ClickAction.OPEN_RIGHT_RAIL) {
      dispatch(setRightMinimize(false))
    } else if (onClickAction === ClickAction.OPEN_ACCOUNT_WALL) {
      // TODO: 'Implement account wall'
    } else if (onClickAction === ClickAction.OPEN_PAY_WALL) {
      // TODO: 'Implement pay wall'
    } else if (onClickAction === ClickAction.CLOSE_LEFT_RAIL) {
      dispatch(setLeftMinimize(true))
    } else if (onClickAction === ClickAction.CLOSE_RIGHT_RAIL) {
      dispatch(setRightMinimize(true))
    }
  }

  return {
    handleClick,
  }
}
