import styled, { css } from 'styled-components'
import Modal from 'react-bootstrap/Modal'

export const ModalCss = css<{ width?: string; padding?: string }>`
  align-items: start;

  .modal-dialog {
    width: ${(props) => props.width};
  }

  .modal-content {
    background: var(--overlay-modal);
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
    padding: ${(props) => (props.padding ? props.padding : '24px')};
  }
`

export const HeaderCss = css`
  border: none;
  display: flex;
  padding: 0;
`

export const BodyCss = css`
  border: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  background: var(--overlay-modal);
`

export const StyledTitleCss = css<{ paddingleft?: number }>`
  font-size: 16px;
  font-weight: 700;
  height: 36px;
  padding-top: 3px;
  padding-left: ${(props) => props.paddingleft && props.paddingleft};
`

export const CloseButtonCss = css`
  cursor: pointer;
`
export const StyledFooter = styled(Modal.Footer)`
  padding: 0;
  border: none;
`
