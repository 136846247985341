import {
  EmbedType,
  checkIsImageUrl,
  checkIsOfficeDoc,
  checkIsOfficeExcel,
  checkIsOfficePowerPoint,
  checkIsVideoLink,
  checkIsYoutube,
  useEmbed,
  PageBlockTypes,
  checkIsAsana,
} from '_entities/embed'
import { icons } from 'shared/icons'
import { IBlockTypes, type block, getBlockTag } from '_entities/block'
import { PageTypesEnum } from 'interfaces/projectFile'
import styled from 'styled-components'
import { KanbanIcon, SlidesIcon, DocumentIcon, WhiteboardIcon, FigmaIcon } from '../ui/styles'
import { TreeItem } from '_entities/tree/model/types'

export const useIcons = () => {
  const _embed = useEmbed()

  const PageTypeIcon = {
    [PageTypesEnum.DOCUMENT]: DocumentIcon,
    [PageTypesEnum.SLIDES]: SlidesIcon,
    [PageTypesEnum.WHITE_BOARD]: WhiteboardIcon,
    [PageTypesEnum.TASK_MANAGER]: KanbanIcon,
    [PageTypesEnum.EMBED]: FigmaIcon,
  }

  const PageTypeIconFromBlockType = {
    [IBlockTypes.DOCUMENT]: DocumentIcon,
    [IBlockTypes.WHITEBOARD]: WhiteboardIcon,
  }

  const embedIcons = {
    [EmbedType.NOTION]: icons.notion,
    [EmbedType.ASANA]: icons.asana,
    [EmbedType.GOOGLE_DOC]: icons.googleDoc,
    [EmbedType.GOOGLE_SLIDES]: icons.googleSlides,
    [EmbedType.GOOGLE_SHEETS]: icons.googleSheets,
    [EmbedType.GOOGLE_CALENDAR]: icons.googleCalendar,
    [EmbedType.OFFICE]: icons.word,
    [EmbedType.MIRO]: icons.miro,
    [EmbedType.FIGMA]: icons.figma,
    [EmbedType.YOUTUBE]: icons.youtube,
    [EmbedType.VIMEO]: icons.vimeo,
    [EmbedType.GENERIC_LINK]: icons.link,
    [EmbedType.FILE]: icons.download,
    [EmbedType.PDF]: icons.pdfFull,
  }

  const getIcon = (args: { pageNode?: TreeItem; block?: block; url?: string }) => {
    if (args.pageNode) {
      return getPageIcon(args.pageNode)
    } else if (args.block) {
      return getBlockIcon(args.block)
    } else if (args.url) {
      return getIconFromUrl(args.url)
    }
  }

  const getPageIcon = (pageNode: TreeItem) => {
    const pageIcon = pageNode?.icon
    if (pageIcon) return pageIcon
    if (!pageNode.embedUrl) {
      return getPageTypeIcon(pageNode)
    } else {
      const url = pageNode.embedUrl
      const iconFromUrl = getIconFromUrl(url)
      if (iconFromUrl) {
        return iconFromUrl
      } else if (_embed.isInternalPageLink(url)) {
        return icons.linkToPage
      } else {
        return icons.download
      }
    }
  }

  const getPageTypeIcon = (node: TreeItem) => {
    return PageTypeIcon[node.type as keyof typeof PageTypeIcon]
  }

  const getBlockIcon = (block: block) => {
    const image = block.data.embed?.image
    const embed = block.data.embed

    if (checkIsImageUrl(embed?.url as string)) {
      return styled(icons.image)`
        width: 1rem;
        height: 1rem;
      `
    } else if (checkIsVideoLink(embed?.url as string)) {
      return styled(icons.video)`
        width: 1rem;
        height: 1rem;
      `
    } else if (checkIsOfficeDoc(embed?.url as string)) {
      return styled(icons.word)`
        width: 1rem;
        height: 1rem;
      `
    } else if (checkIsOfficeExcel(embed?.url as string)) {
      return styled(icons.excel)`
        width: 1rem;
        height: 1rem;
      `
    } else if (checkIsOfficePowerPoint(embed?.url as string)) {
      return styled(icons.powerpoint)`
        width: 1rem;
        height: 1rem;
      `
    } else if (checkIsYoutube(embed?.url as string)) {
      return styled(icons.youtube)`
        width: 1rem;
        height: 1rem;
      `
    } else if (checkIsAsana(embed?.url as string)) {
      return styled(icons.asana)`
        width: 1rem;
        height: 1rem;
      `
    }
    if (image) return image
    else if (embed) {
      return embedIcons[embed.type as keyof typeof embedIcons]
    } else return PageTypeIconFromBlockType[getBlockTag(block) as PageBlockTypes]
  }

  const getIconFromUrl = (link: string) => {
    const type = _embed.getEmbedTypeFromLink(link)
    if (!type) return null
    return embedIcons[type as keyof typeof embedIcons]
  }

  const getOfficeIcon = (url: string) => {
    if (checkIsOfficeDoc(url || '')) {
      return icons.word
    }

    if (checkIsOfficeExcel(url || '')) {
      return icons.excel
    }

    if (checkIsOfficePowerPoint(url || '')) {
      return icons.powerpoint
    }
  }

  return {
    PageTypeIcon,
    getIcon,
    getPageIcon,
    getBlockIcon,
    getIconFromUrl,
    getOfficeIcon,
  }
}
