import {
  type ITableAction,
  type ITableColumn,
  type ITableData,
  type ITableReducer,
} from '_entities/block'
import { randomColor, shortId, makeData, ActionTypes, DataTypes } from './utils'
import update from 'immutability-helper'

export function reducer(state: ITableReducer, action: ITableAction) {
  switch (action.type) {
    case ActionTypes.UPDATE_EVERYTHING: {
      return update(state, {
        isOpSendingAllowed: { $set: false },
        skipReset: { $set: true },
        columns: {
          $set: action.columns || [],
        },
        data: {
          $set: action.data || [],
        },
      })
    }

    case ActionTypes.ADD_OPTION_TO_COLUMN: {
      const optionIndex = state.columns.findIndex((column) => column.id === action.columnId)
      return update(state, {
        isOpSendingAllowed: { $set: true },
        skipReset: { $set: true },
        columns: {
          [optionIndex]: {
            options: {
              $push: [
                {
                  label: action.option,
                  backgroundColor: action.backgroundColor,
                },
              ],
            },
          },
        },
      })
    }

    case ActionTypes.ADD_ROW: {
      return update(state, {
        isOpSendingAllowed: { $set: true },
        skipReset: { $set: true },
        data: { $push: [{}] },
      })
    }

    case ActionTypes.UPDATE_COLUMN_TYPE: {
      const typeIndex = state.columns.findIndex((column) => column.id === action.columnId)
      switch (action.dataType) {
        case DataTypes.NUMBER:
          if (state.columns[typeIndex].dataType === DataTypes.NUMBER) {
            return state
          } else {
            return update(state, {
              isOpSendingAllowed: { $set: true },
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data: ITableData[]): ITableData[] =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId as string]:
                      typeof action.columnId === 'string' &&
                      isNaN(parseFloat(row[action.columnId] as string))
                        ? ''
                        : Number.parseInt(row[action.columnId as string] as string),
                  })),
              },
            })
          }
        case DataTypes.SELECT:
          if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
            return state
          } else {
            const options: any[] = []
            state.data.forEach((row) => {
              if (row[action.columnId as string]) {
                options.push({
                  label: row[action.columnId as string],
                  backgroundColor: randomColor(),
                })
              }
            })
            return update(state, {
              isOpSendingAllowed: { $set: true },
              skipReset: { $set: true },
              columns: {
                [typeIndex]: {
                  dataType: { $set: action.dataType },
                  options: { $push: options },
                },
              },
            })
          }
        case DataTypes.TEXT:
          if (state.columns[typeIndex].dataType === DataTypes.TEXT) {
            return state
          } else if (state.columns[typeIndex].dataType === DataTypes.SELECT) {
            return update(state, {
              isOpSendingAllowed: { $set: true },
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
            })
          } else {
            return update(state, {
              isOpSendingAllowed: { $set: true },
              skipReset: { $set: true },
              columns: { [typeIndex]: { dataType: { $set: action.dataType } } },
              data: {
                $apply: (data: ITableData[]): ITableData[] =>
                  data.map((row) => ({
                    ...row,
                    [action.columnId as string]: row[action.columnId as string] + '',
                  })),
              },
            })
          }
        default:
          return state
      }
    }

    case ActionTypes.UPDATE_COLUMN_HEADER: {
      const index = state.columns.findIndex((column) => column.id === action.columnId)
      return update(state, {
        isOpSendingAllowed: { $set: true },
        skipReset: { $set: true },
        columns: { [index]: { label: { $set: action.label as string } } },
      })
    }

    case ActionTypes.UPDATE_CELL: {
      return update(state, {
        isOpSendingAllowed: { $set: true },
        skipReset: { $set: true },
        data: {
          [action.rowIndex as number]: {
            [action.columnId as string]: { $set: action.value as string },
          },
        },
      })
    }

    case ActionTypes.ADD_COLUMN_TO_LEFT: {
      const leftIndex = state.columns.findIndex((column) => column.id === action.columnId)
      const leftId = shortId()
      return update(state, {
        isOpSendingAllowed: { $set: true },
        skipReset: { $set: true },
        columns: {
          $splice: [
            [
              leftIndex,
              0,
              {
                id: leftId,
                label: 'Column',
                accessor: leftId,
                dataType: DataTypes.TEXT,
                created: action.focus && true,
                options: [],
              },
            ],
          ],
        },
      })
    }

    case ActionTypes.ADD_COLUMN_TO_RIGHT: {
      const rightIndex = state.columns.findIndex((column) => column.id === action.columnId)
      const rightId = shortId()
      return update(state, {
        isOpSendingAllowed: { $set: true },
        skipReset: { $set: true },
        columns: {
          $splice: [
            [
              rightIndex + 1,
              0,
              {
                id: rightId,
                label: 'Column',
                accessor: rightId,
                dataType: DataTypes.TEXT,
                created: action.focus && true,
                options: [],
              },
            ],
          ],
        },
      })
    }

    case ActionTypes.DELETE_COLUMN: {
      const deleteIndex = state.columns.findIndex((column) => column.id === action.columnId)
      return update(state, {
        isOpSendingAllowed: { $set: true },
        skipReset: { $set: true },
        columns: { $splice: [[deleteIndex, 1]] },
      })
    }

    case ActionTypes.ENABLE_RESET:
      return update(state, { skipReset: { $set: true } })
    default:
      return state
  }
}
