import { useTransformer, useStage } from '_features/canvas'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { useJson1 } from 'shared/shareDb/useJson1'
import {
  type block,
  getBlockPageId,
  getBlockWidth,
  getBlockHeight,
  getCalloutWidth,
  getCalloutHeight,
  getIndex,
} from '_entities/block'
import { useGrid, useKonvaNode } from '_entities/whiteboard'
import { useScale } from './useScale'
import { Group } from 'konva/lib/Group'
import { useEmbed } from '_entities/embed'

export const useResize = () => {
  const _transformer = useTransformer()
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _grid = useGrid()
  const _konvaNode = useKonvaNode()
  const _stage = useStage()
  const _scale = useScale()
  const _embed = useEmbed()

  const getScale = (block: block, node: Group) => {
    const scale = _konvaNode.getScale(node)
    const imageNode = _konvaNode.getImageNodeFromGroup(node) || _konvaNode.getRectFromGroup(node)
    if (!scale) return { x: 1, y: 1 }
    // Get the new size of the node background image
    const newSize = {
      width: imageNode.width() * scale.x,
      height: imageNode.height() * scale.y,
    }
    // Get the snap size from the new size
    const snapSize = _grid.getSnapSize(newSize)
    if (!snapSize) return { x: 1, y: 1 }
    // Get the scale from the snap size by dividing new size with default size
    const snapScale = {
      x: snapSize.width / imageNode.width(),
      y: snapSize.height / imageNode.height(),
    }
    if (_scale.isBlockScalable(block)) return snapScale
    else return { x: 1, y: 1 }
  }

  const getSize = (block: block) => {
    const shadowSize = _grid.getShadowSize(getBlockPageId(block))
    const currentSize = {
      width: getBlockWidth(block),
      height: getBlockHeight(block),
    }
    const isCallout = _embed.isCallout(block)
    if (!shadowSize) return { width: 0, height: 0 }
    if (isCallout) return currentSize
    return { width: shadowSize.width, height: shadowSize.height }
  }

  const getCalloutSize = (block: block) => {
    const shadowSize = _grid.getShadowSize(getBlockPageId(block))
    const currentCalloutSize = {
      calloutWidth: getCalloutWidth(block),
      calloutHeight: getCalloutHeight(block),
    }
    const isCallout = _embed.isCallout(block)
    if (!shadowSize) return { calloutWidth: 0, calloutHeight: 0 }
    if (!isCallout) return currentCalloutSize
    return { calloutWidth: shadowSize.width, calloutHeight: shadowSize.height }
  }

  const resizeBlocksInTransformer = (pageId: string) => {
    const nodes = _transformer.getTransformerNodes(pageId)
    if (nodes) {
      nodes.forEach((node) => {
        const { x, y } = node.attrs
        const block = _stage.getBlockFromNode(pageId, node)
        if (!block) return
        const index = getIndex(block)
        if (index > -1) {
          const snapPosition = _grid.calculateGridCoordinates({ x, y })
          const scale = getScale(block, node)
          if (!scale) return
          const group = _konvaNode.getGroupNode(block)
          const size = getSize(block)
          const calloutSize = getCalloutSize(block)
          group?.position(snapPosition)
          group?.scale(scale)
          const updatedBlock = {
            ...block,
            data: {
              ...block.data,
              ...snapPosition,
              ...size,
              ...calloutSize,
              scaleX: scale.x,
              scaleY: scale.y,
            },
          }
          const op = _json1.replaceBlock(updatedBlock, index, block)
          _submit.submit(pageId, op, SourceKeys.UPDATE_BLOCK)
        }
      })
    }
  }

  return {
    getScale,
    getSize,
    resizeBlocksInTransformer,
  }
}
