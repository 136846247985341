import React, { useEffect, useRef, useState } from 'react'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { type block, getBlockPageId, getIndex } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'

interface Props {
  block: block
}

const useEditorEmbedFrame = (props: Props) => {
  const options = {
    step: 10,
    axis: 'horizontal',
  }
  const { step = 1, axis = 'both' } = options || {}
  const [coords, setCoords] = useState({ x: Infinity, y: Infinity })
  const [dims, setDims] = useState({ width: Infinity, height: Infinity })
  const [resizeDirection, setResizeDirection] = useState<string | null>(null)
  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const { block } = props

  const { getInsertKeyInDataKeyOp, getReplaceBlockDataKeyOp, combineOperations } = useJson1()
  const { submit } = useSubmit()

  useEffect(() => {
    window.addEventListener('mousemove', doDrag, false)
    window.addEventListener('mouseup', stopDrag, false)
  }, [dims, coords, axis])

  const getValue = (input: number) => Math.ceil(input / step) * step

  const doDrag = (event: any) => {
    const deltaX = event.clientX - coords.x
    const widthDelta = resizeDirection === 'right' ? deltaX * 2 : -(deltaX * 2)
    const heightDelta = event.clientY - coords.y

    const newWidth = getValue(dims.width + widthDelta)
    const newHeight = getValue(dims.height + heightDelta)

    if (!resizeDirection) return

    if (resizeDirection === 'bottom') {
      setHeight(newHeight)
    } else {
      setWidth(newWidth)
    }
  }

  const handleChangeWidth = (newWidth: number) => {
    if (newWidth === Infinity || isNaN(newWidth)) return null
    const index = getIndex(props.block)
    if (index < 0) return null
    let widthOp
    if (block.data) {
      if (!('width' in block.data)) widthOp = getInsertKeyInDataKeyOp(index, ['width'], newWidth)
      else widthOp = getReplaceBlockDataKeyOp(index, ['width'], newWidth, block.data.width)
    }
    return widthOp
  }

  const handleChangeHeight = (newHeight: number) => {
    if (newHeight === Infinity || isNaN(newHeight)) return null
    const index = getIndex(props.block)
    if (index < 0) return null

    let heightOp

    if (block.data) {
      if (!('height' in block.data))
        heightOp = getInsertKeyInDataKeyOp(index, ['height'], newHeight)
      else heightOp = getReplaceBlockDataKeyOp(index, ['height'], newHeight)
    }

    return heightOp
  }

  const handleChangeSize = (newWidth: number, newHeight: number) => {
    const widthOp = handleChangeWidth(newWidth)
    const heightOp = handleChangeHeight(newHeight)
    if (!heightOp || !widthOp) return
    const finalOp = combineOperations([widthOp, heightOp])
    submit(getBlockPageId(props.block), finalOp, SourceKeys.UPDATE_BLOCK)
  }

  const stopDrag = (event: any) => {
    window.removeEventListener('mouseup', stopDrag, false)
    window.removeEventListener('mousemove', doDrag, false)
    setResizeDirection(null)
    const deltaX = event.clientX - coords.x
    const widthDelta = resizeDirection === 'right' ? deltaX : -deltaX
    const heightDelta = event.clientY - coords.y

    const newWidth = getValue(dims.width + widthDelta)
    const newHeight = getValue(dims.height + heightDelta)
    handleChangeSize(newWidth, newHeight)
  }
  const onRightResizeMouseDown = (event: React.MouseEvent) => {
    handleOnMouseDown(event)
    setResizeDirection('right')
  }

  const onBottomResizeMouseDown = (event: React.MouseEvent) => {
    handleOnMouseDown(event)
    setResizeDirection('bottom')
  }

  const onLeftResizerMouseDown = (event: React.MouseEvent) => {
    handleOnMouseDown(event)
    setResizeDirection('left')
  }

  const handleOnMouseDown = (event: React.MouseEvent) => {
    if (!iframeRef.current) return
    setCoords({ x: event.clientX, y: event.clientY })
    const { width, height } = window.getComputedStyle(iframeRef.current)
    setDims({ width: parseInt(width, 10), height: parseInt(height, 10) })
  }

  return {
    onRightResizeMouseDown,
    onLeftResizerMouseDown,
    handleOnMouseDown,
    iframeRef,
    resizeDirection,
    width,
    height,
    onBottomResizeMouseDown,
  }
}

export default useEditorEmbedFrame
