import { StyledProgressIndicator } from './styles'
import { Spinner } from 'react-bootstrap'
import CustomP from '../CustomP/CustomP'

interface Props {
  percentage: string
}

const ProgressIndicator = ({ percentage }: Props) => {
  return (
    <StyledProgressIndicator>
      <Spinner as='span' animation='border' size='sm' role='status' aria-hidden='true' />
      <CustomP marginBottom='0' marginLeft='5px'>
        {percentage && `${percentage}%`}
      </CustomP>
    </StyledProgressIndicator>
  )
}

export default ProgressIndicator
