import React from 'react'
import { EditorBlockAttrs } from '../ui/attributes'
import { getBlock } from '_entities/block'

export const getBlockIdFromEvent = (e: React.ClipboardEvent | React.KeyboardEvent) => {
  const blockElement = e.target as HTMLDivElement
  return blockElement.getAttribute('id')
}

export const getPageIdFromEvent = (e: React.ClipboardEvent | React.KeyboardEvent) => {
  const blockElement = e.target as HTMLDivElement
  return blockElement.getAttribute(EditorBlockAttrs.PAGE_ID)
}

export const getBlockFromEvent = (e: React.ClipboardEvent | React.KeyboardEvent) => {
  const pageId = getPageIdFromEvent(e)
  const blockId = getBlockIdFromEvent(e)
  if (!blockId || !pageId) return
  return getBlock(pageId, blockId)
}
