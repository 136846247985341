import { SourceKeys } from 'interfaces/editor'
import { type block, getBlockPageId, getIndex, IBlockTypes } from '_entities/block'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import {
  CheckSquare,
  FigmaEmbedIcon,
  GoogleDoc,
  GoogleSheets,
  GoogleSlides,
  H1Icon,
  H2Icon,
  ImageAndText,
  ImageSquare,
  LIcon,
  ListBullets,
  ListNumbers,
  MIcon,
  SIcon,
  TIcon,
  XLIcon,
} from '_features/block/ui/SlashMenu/styles'
import { EmbedType } from '_entities/embed'
import { IConversionMenuOption } from '_features/block'
import { SELECTOR_ICON_SIZE } from './useSlashMenu'
import { QuillHeadings, useText } from '_entities/text'
import { textSizeIcons } from 'components/organisms/Dropdowns/WhiteboardShapeTextConversionDropdown/WhiteboardShapeTextConversionDropdown'
import { useAppDispatch } from 'redux/hooks'
import { DocumentIcon, WhiteboardIcon } from 'shared/icons'
import { formattedTextFontSize } from '_entities/text/model/useText'
import { setFontFamily, setFontSize } from 'redux/reducers/pageReducer'
import { getEditor } from 'shared/lib'

export const useConversionMenu = () => {
  const _json1 = useJson1()
  const _submit = useSubmit()
  const dispatch = useAppDispatch()
  const _text = useText()

  const options: IConversionMenuOption[] = [
    {
      label: 'Headline',
      icon: <H1Icon />,
      tag: IBlockTypes.TITLE,
      value: 1,
      isText: true,
    },
    {
      label: 'Subheading',
      icon: <H2Icon />,
      tag: IBlockTypes.SUBHEADER,
      value: 2,
      isText: true,
    },
    {
      label: 'XLarge',
      icon: <XLIcon />,
      tag: IBlockTypes.XLARGE,
      value: 3,
      isText: true,
    },
    {
      label: 'Large',
      icon: <LIcon />,
      tag: IBlockTypes.LARGE,
      value: 4,
      isText: true,
    },
    {
      label: 'Medium',
      icon: <MIcon />,
      tag: IBlockTypes.MEDIUM,
      value: 5,
      isText: true,
    },
    {
      label: 'Small',
      icon: <SIcon />,
      tag: IBlockTypes.SMALL,
      value: 6,
      isText: true,
    },

    {
      label: 'Text',
      icon: <TIcon />,
      tag: IBlockTypes.TEXT,
      value: false,
      isText: true,
    },
    {
      label: 'Bulleted list',
      icon: <ListBullets />,
      tag: IBlockTypes.LIST,
      isText: false,
    },
    {
      label: 'Numbered list',
      icon: <ListNumbers />,
      tag: IBlockTypes.NUMBERED_LIST,
      isText: false,
    },
    {
      label: 'To-Do list',
      icon: <CheckSquare />,
      tag: IBlockTypes.CHECKLIST,
      isText: false,
    },
    {
      label: 'Image',
      icon: <ImageSquare />,
      tag: IBlockTypes.IMAGE,
      isText: false,
    },
    {
      label: 'Image and text',
      icon: <ImageAndText />,
      tag: IBlockTypes.IMAGE_TEXT,
      isText: false,
    },
    {
      label: 'Figma embed',
      icon: <FigmaEmbedIcon />,
      tag: IBlockTypes.EXTERNAL_EMBED,
      embedType: EmbedType.FIGMA,
      isText: false,
    },
    {
      label: 'Google Docs',
      icon: <GoogleDoc width={SELECTOR_ICON_SIZE} />,
      tag: IBlockTypes.EXTERNAL_EMBED,
      embedType: EmbedType.GOOGLE_DOC,
      isText: false,
    },
    {
      label: 'Google Slides',
      icon: <GoogleSlides width={SELECTOR_ICON_SIZE} />,
      tag: IBlockTypes.EXTERNAL_EMBED,
      embedType: EmbedType.GOOGLE_SLIDES,
      isText: false,
    },
    {
      label: 'Google Sheets',
      icon: <GoogleSheets width={SELECTOR_ICON_SIZE} />,
      tag: IBlockTypes.EXTERNAL_EMBED,
      embedType: EmbedType.GOOGLE_SHEETS,
      isText: false,
    },
    {
      label: 'Document',
      icon: <DocumentIcon width={SELECTOR_ICON_SIZE} />,
      tag: IBlockTypes.DOCUMENT,
      isText: false,
    },
    {
      label: 'Whiteboard',
      icon: <WhiteboardIcon width={SELECTOR_ICON_SIZE} />,
      tag: IBlockTypes.WHITEBOARD,
      isText: false,
    },
  ]

  const textOptions = [
    ...options.filter((item) => {
      if (item.isText)
        return {
          label: item.label,
          icon: item.icon,
          value: item.value,
          tag: item.tag,
        }
    }),
  ]
  const nonTextOptions = [
    ...options.filter((item) => {
      if (!item.isText)
        return {
          label: item.label,
          icon: item.icon,
        }
    }),
  ]

  const textConverter = (
    block: block,
    value: number | false | undefined,
    tag?: IBlockTypes,
    e?: Event,
  ) => {
    const editor = getEditor(block._id)
    if (editor) {
      setTimeout(() => {
        const selection = editor.getSelection()
        const selectedFontSize = value ? formattedTextFontSize[value] : formattedTextFontSize['p']
        if (selection && selection.length > 0) {
          editor.formatText(selection.index, selection.length, 'header', value, 'user')
          editor?.formatText(
            selection.index,
            selection.length,
            'size',
            `${selectedFontSize}px`,
            'user',
          )
          editor?.format('font', 'arial', 'user')
        } else {
          editor.format('header', value, 'user')
          editor?.format('size', `${selectedFontSize}px`, 'user')
          editor?.format('font', 'arial', 'user')
        }
        dispatch(setFontSize(selectedFontSize))
        dispatch(setFontFamily('arial'))
      }, 100)
    }
    setTimeout(() => {
      if (tag) {
        blockTypeSetter(block, tag, e)
      }
    })
  }

  const blockTypeSetter = (block: block, tag: IBlockTypes, e?: Event) => {
    e?.stopPropagation()

    const index = getIndex(block)
    if (index === -1) return
    const updatedBlock = {
      ...block,
      data: {
        ...block.data,
        tag,
      },
    }

    if (tag === IBlockTypes.NUMBERED_LIST) {
      updatedBlock.data.numberInList = 1
    }

    const op = _json1.replaceBlock(updatedBlock, index, block)
    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  const getCurrentIcon = (block: block) => {
    const icon = <TIcon />
    const header = _text.getHeaderFormat(block)
    return textSizeIcons[header as QuillHeadings] || icon
  }

  const getCurrentLabel = (block: block) => {
    const header = _text.getHeaderFormat(block)
    return textOptions.find((option) => option.value === header)?.label
  }

  return {
    options,
    textOptions,
    nonTextOptions,
    textConverter,
    blockTypeSetter,
    getCurrentIcon,
    getCurrentLabel,
  }
}
