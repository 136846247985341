import styled from 'styled-components'
import { Dropdown } from 'react-bootstrap'

export const PinnedMessagesDropwdownMenu = styled(Dropdown.Menu)`
  height: 400px;
  width: 440px;
  overflow-y: scroll;
  box-shadow: 0 1px 8px var(--primary-text);
  border: none;
  border-radius: 7px;
  margin-top: 10px;
  transform: translate3d(-310px, 38px, 0px) !important;
  background: var(--sidebars);
`

export const PinnedMessagesToggleButton = styled(Dropdown.Toggle)`
  position: absolute;
  top: 0;
  border: none;
  color: var(--primary-text);

  &:hover {
    cursor: pointer;
    color: var(--primary-text);
  }
`

export const PinnedMessagesContainer = styled.div`
  position: fixed;
  z-index: 1000;
  top: 18px;
  right: 156px;
  color: var(--primary-text);

  svg path {
    stroke: var(--primary-text);
  }
`
