import CustomSelectDropdown from 'components/molecules/CustomSelectDropdown/CustomSelectDropdown'
import React, { BaseSyntheticEvent } from 'react'
import Form from 'react-bootstrap/Form'
import { ISpace } from 'interfaces/space'
import { IDropdownOption } from 'interfaces/invite'
import { useNewProjectFileModal } from './useNewProjectFileModal'
import {
  StyledModal,
  StyledModalHeader,
  StyledModalTitle,
  StyledModalBody,
  StyledTitleInputWithLabel,
  StyledLabel,
  StyledInput,
  StyledSelectInputWithLabel,
  StyledSelectInputContainer,
  StyledButton,
} from './styles'

type Props = {
  show: boolean
  setShow: React.Dispatch<React.SetStateAction<boolean>>
  newProjectFileTitle: string
  setNewProjectFileTitle: React.Dispatch<React.SetStateAction<string>>
  space?: ISpace
}

const NewProjectFileModal: React.FC<Props> = ({
  show,
  setShow,
  newProjectFileTitle,
  setNewProjectFileTitle,
  space,
}) => {
  const _newProjectFile = useNewProjectFileModal({
    setShow,
    space,
    newProjectFileTitle,
  })

  return (
    <StyledModal
      show={show}
      onHide={_newProjectFile.handleClose}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className='inviteModalBackground'
    >
      <StyledModalHeader closeButton>
        <StyledModalTitle>Create a new project</StyledModalTitle>
      </StyledModalHeader>
      <StyledModalBody>
        <Form onSubmit={_newProjectFile.createProjectFile}>
          <StyledTitleInputWithLabel>
            <StyledLabel className='mb-0'>Project name</StyledLabel>
            <StyledInput
              type='text'
              name='title'
              placeholder='Project name goes here'
              onChange={(e: BaseSyntheticEvent) => setNewProjectFileTitle(e.target.value)}
              margin='0 0 1rem 0'
            />
          </StyledTitleInputWithLabel>
          <StyledSelectInputWithLabel>
            <StyledLabel className='mb-0 mt-3'>Share project with members</StyledLabel>
            <StyledSelectInputContainer>
              <CustomSelectDropdown
                options={_newProjectFile.projectFileOptions()}
                defaultSelect={_newProjectFile.getPermissionSelectionText()}
                borderHeader={'1px solid white'}
                focusedBorder={'1px solid var(--primary-color)'}
                headerPadding={'6px 8px'}
                onChange={(value: IDropdownOption) => {
                  _newProjectFile.setSelectedAssignedMember(value.assignMember as string)
                }}
                optionsInSelectPadding='8px 60px 8px 16px'
                fontWeightForOptions='500'
                fontWeight='500'
                backgroundColour='var(--white)'
                color='var(--main-black)'
              />
              <StyledButton
                textColour='var(--white) !important'
                backgroundColour='var(--everyday-blue)'
                onClick={_newProjectFile.createProjectFile}
              >
                Create project
              </StyledButton>
            </StyledSelectInputContainer>
          </StyledSelectInputWithLabel>
        </Form>
      </StyledModalBody>
    </StyledModal>
  )
}

export default NewProjectFileModal
