import styled from 'styled-components'

export const EditorContainer = styled.div<{
  shadow: string
  isEmbed?: boolean
  paddingTop: string
}>`
  height: calc(100% - 40px);
  width: 100%;
  box-shadow: ${(props) => props.shadow};
  overflow-y: auto;
  outline: none;
  transition: all 0.3s ease-in-out;
  padding-top: ${(props) => props.paddingTop};
  padding-bottom: 84px;
  margin-top: 20px;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  color: var(--text);
`
