import { useGetLandingPageQuery, useBackground } from '_entities/page'
import FullScreenLoader from 'components/templates/FullScreenLoader/FullScreenLoader'
import styled from 'styled-components'
import { Document } from '_widgets/Document'

export const StyledWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
`

export const LandingPage = () => {
  const { data, isFetching } = useGetLandingPageQuery({
    slug: '1',
  })
  const _background = useBackground({ page: data })

  if (isFetching) return <FullScreenLoader />
  if (!data || !data.id) return null

  return (
    <StyledWrapper>
      {_background.getBackgroundNode()}
      {_background.getOverlayNode()}
      <Document pageId={data.id} isPreview />
    </StyledWrapper>
  )
}
