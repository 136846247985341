import { icons } from 'shared/icons'
import styled from 'styled-components'

export const CtaWrapper = styled.div`
  padding: 24px;
  padding-bottom: 10px;
`

export const CtaSettingsWrapper = styled.div<{
  isDocument?: boolean
}>`
  border: solid 1px #e3e3e3;
  border-radius: ${({ isDocument }) => (!isDocument ? '10px' : '10px 10px 0 0')};
  overflow: ${({ isDocument }) => (!isDocument ? 'hidden' : 'visible')};
  position: relative;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 12px 24px 12px;
`

export const SettingsOptionsWrapper = styled.div<{
  isButtonsDropdownOpen: boolean
  isDocument?: boolean
}>`
  position: ${({ isDocument }) => (isDocument ? 'absolute' : 'relative')};
  transition: all 0.3s ease-in-out;
  filter: ${({ isButtonsDropdownOpen }) =>
    isButtonsDropdownOpen ? 'brightness(80%)' : 'brightness(100%)'};
  backdrop-filter: brightness(100%);
  padding: 12px;
  z-index: ${({ isDocument }) => (isDocument ? 10 : 0)};
  background: ${({ isDocument }) => (!isDocument ? 'var(--sidebars)' : 'var(--overlay-modal)')};
  border: ${({ isDocument }) => (isDocument ? 'solid 1px #e3e3e3' : 'none')};
  border-radius: ${({ isDocument }) => (!isDocument ? '10px' : '0 0 10px 10px')};
  border-top: none;
  width: 100.5%;
  top: 100%;
  left: -1px;
`

export const SettingsOptionWrapper = styled.div`
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
`

export const SettingsOptionText = styled.h6`
  font-size: 15.75px;
  font-weight: 500;
  letter-spacing: -0.02em;
  margin-bottom: 0;
  color: var(--primary-text);
`

export const StyledDirectionIcon = styled(icons.direction)`
  cursor: pointer;
`

export const StyledDirectionOutIcon = styled(icons.directionOut)`
  cursor: pointer;
`

export const StyledCloseIcon = styled(icons.close)`
  cursor: pointer;
`

export const CtaButtonsWrapper = styled.div`
  width: 100%;
  z-index: 2;
`

export const PlusIcon = styled(icons.plus)`
  cursor: pointer;
`

export const PlusIconWrapper = styled.div<{ isButtonsDropdownOpen?: boolean }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
  background: var(--sidebars);
  padding: 4px 12px;
  z-index: 3;
`

export const DropdownWrapper = styled.div<{
  isButtonsDropdownOpen?: boolean
}>`
    background-color: var(--sidebars) !important;
    transition: all 0.25s ease-in-out;
    height: ${({ isButtonsDropdownOpen }) => (isButtonsDropdownOpen ? '250px' : '0px')};
    pointer-events: ${({ isButtonsDropdownOpen }) => (isButtonsDropdownOpen ? 'all' : 'none')};
    width: 100%;
    position: absolute;
    top: 101%;
    left: 0;
    z-index: 2;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
}
`

export const StyledDropdownTrigger = styled.button`
  border: none;
  outline: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  color: var(--primary-text);
`

export const SlashMenuCtaButtonsListWrapper = styled.div<{
  open?: boolean
}>`
  display: ${(props) => (props.open ? 'block' : 'none')};
  max-height: 200px;
  width: 200px;
  background-color: var(--overlay-modal);
  border-radius: 8px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border: none;
  position: absolute;
  overflow: scroll;
  z-index: 3;
`

export const CtaButtonsListWrapper = styled.div<{
  open?: boolean
}>`
  max-height: 250px;
  overflow-y: scroll;
  z-index: 3;
  position: relative;
  height: 100%;
  padding-bottom: 40px;
`
