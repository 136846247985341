import { manager } from '../api'
import { useAppSelector } from 'redux/hooks'
import { ILayoutsEnum } from 'interfaces/taskManager'

export const useManagerOptionMutation = () => {
  // ** Network
  const [handleDeleteOption] = manager.endpoints.deleteManagerOption.useMutation()
  const [handleCreateTask] = manager.endpoints.createManagerOption.useMutation()

  // ** Redux State
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const createManagerOptions = async (name: string, layout: ILayoutsEnum) => {
    if (!projectFile) return

    return await handleCreateTask({
      managerId: projectFile.managers[0].id,
      name,
      layout,
    }).unwrap()
  }

  const deleteManagerOption = async (managerId: number, optionId: number) => {
    return await handleDeleteOption({
      managerId,
      optionId,
    }).unwrap()
  }

  return {
    createManagerOptions,
    deleteManagerOption,
  }
}
