interface IGlobalThis {
  __IN_DESKTOP_ENV__: boolean
  fetch: (url: string) => any
}

export const isElectron = () => {
  return (globalThis as unknown as IGlobalThis)?.__IN_DESKTOP_ENV__
}

export const getElectronFetch = () => {
  return window.__ELECTRON_FETCH__
}
