import { getWhiteboardContainer } from '_entities/whiteboard'
import { observeMutation } from 'shared/lib'
import { WhiteboardAttrs } from './types'

export const useCanvas = () => {
  const getCenterCoordinates = (pageId: string) => {
    const canvas = getWhiteboardContainer(pageId)
    if (!canvas) return
    return {
      x: canvas.clientWidth / 2,
      y: canvas.clientHeight / 2,
    }
  }

  const updateWhiteboardElementBackgroundColorInRealTime = (pageId: string, rgba: string) => {
    const element = getWhiteboardContainer(pageId)
    if (!element) return
    element.style.backgroundColor = rgba
  }

  const observeMutationOnCanvasElement = (
    pageId: string,
    attr: WhiteboardAttrs,
    callback: () => void,
  ) => {
    const canvas = getWhiteboardContainer(pageId)
    if (!canvas) return
    return observeMutation(canvas, (mutationList) => {
      for (const mutation of mutationList) {
        if (mutation.type === 'attributes' && mutation.attributeName === attr) {
          callback()
        }
      }
    })
  }

  return {
    getCenterCoordinates,
    updateWhiteboardElementBackgroundColorInRealTime,
    observeMutationOnCanvasElement,
  }
}
