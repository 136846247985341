import { useEmbed } from '_entities/embed/ExternalEmbed/model/useEmbed'
import { AccessType } from './types'
import { Permission } from '_features/embed/model/types'
import { getGoogleInfo } from '_features/embed'

export const useGoogle = () => {
  const _embed = useEmbed()
  const [handleFetchUserInfo] = getGoogleInfo.endpoints.getUserInfo.useLazyQuery()
  const [handleFetchFileById] = getGoogleInfo.endpoints.getFileById.useLazyQuery()
  const [handleCheckAccess] = getGoogleInfo.endpoints.checkAccess.useLazyQuery()
  const [handleCheckIframeLink] = getGoogleInfo.endpoints.checkIframeSrc.useLazyQuery()

  const fetchFileName = async (url: string) => {
    try {
      const fileId = _embed.extractFileId(url)
      const file = await handleFetchFileById({ fileId }).unwrap()
      return file?.name
    } catch (error) {
      return null
    }
  }

  const fetchUserInfo = async (accessToken: string) => {
    const user = await handleFetchUserInfo({ accessToken }).unwrap()
    if (user) return user
    else return null
  }

  const checkAccess = async (url: string, accessToken?: string) => {
    try {
      if (!accessToken) {
        const iframeLink = await handleCheckIframeLink({ url }).unwrap()

        if (iframeLink) return AccessType.PUBLIC
      }

      const fileId = _embed.extractFileId(url)

      const permissions = (await handleCheckAccess({ fileId }).unwrap())?.permissions

      if (!permissions || permissions.length === 0) {
        return AccessType.PUBLIC
      } else if (
        accessToken &&
        permissions.some((permission: Permission) => permission.id === 'me')
      ) {
        return AccessType.USER
      } else {
        return AccessType.LINK_OWNER
      }
    } catch (error) {
      console.error('Error checking file access:', error)
      return false
    }
  }

  return {
    fetchUserInfo,
    checkAccess,
    fetchFileName,
  }
}
