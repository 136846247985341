import styled from 'styled-components'
import { ReactComponent as ShareProject } from 'assets/images/share_project.svg'

// Icon exports
export { ReactComponent as UserPlusIcon } from 'assets/images/UserPlus.svg'
export { ReactComponent as EverydayLogoIcon } from 'assets/images/everyday logo.svg'
export { ReactComponent as CaretUpDownIcon } from 'assets/images/CaretUpDown.svg'

export const Container = styled.div`
  width: 100%;
  height: fit-content;
  padding: 20px;
  border-bottom: 1px solid #f2f2f2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`

export const ShareProjectIcon = styled(ShareProject)<{ opacity?: string }>`
  width: 24px;
  height: 24px;
  object-fit: cover;
  object-position: 50% 50%;
  cursor: pointer;
  opacity: ${(props) => props.opacity};
`

export const ShareProjectButton = styled.div<{
  opacity?: string
}>`
  width: 32px;
  height: 32px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  opacity: ${(props) => props.opacity};

  border-radius: 10px;

  &:hover {
    background: var(--hover);
  }
`

export const MainContainer = styled.div`
  padding: 4px 8px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`

export const LogoDropdownContainer = styled.div`
  display: flex;
  align-items: center;

  & + * + {
    margin-left: 8px;
  }
`

export const LogoContainer = styled.div`
  padding: 16px 12px;
  cursor: pointer;
`

export const ProjectsDropdownContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
