import styled from 'styled-components'

export const CreateButton = styled.button`
  background: none;
  font-size: 13px;
  text-align: end;
  width: fit-content;
  border: none;
  color: var(--primary-text) !important;
  padding-top: 0 !important;
  display: flex;
  align-items: center;
  gap: 6px;
  min-width: 100px;
  &:disabled {
    cursor: default;
    color: var(--gray-disabled-stroke);
  }
`
