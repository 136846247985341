import React from 'react'
import { type block } from '_entities/block'
import { EmptyState, PropertyTitle, PropertyWrapper, StyledWrapper } from '../styles'
import RenderSidebarRelations from '../../EditorSidebar/RenderSidebarRelations'
import useBacklinks from './useBacklinks'

interface Props {
  block: block
}

const Backlinks = (props: Props) => {
  const _backlinks = useBacklinks()

  return (
    <StyledWrapper>
      <PropertyWrapper width={'100%'}>
        <PropertyTitle>Back links</PropertyTitle>

        {props.block.meta.relatedObjects?.length === 0 || !props.block.meta.relatedObjects ? (
          <EmptyState>None</EmptyState>
        ) : (
          <RenderSidebarRelations
            currentBlock={props.block}
            pages={_backlinks.pages}
            handleDetachAllFromOriginal={_backlinks.handleDetachAllFromOriginal}
          />
        )}
      </PropertyWrapper>
    </StyledWrapper>
  )
}

export default Backlinks
