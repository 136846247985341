import { StyledImageUpload } from '../UploadImageDropdown/styles'
import Button from 'components/atoms/Button/Button'
import { Spinner } from 'react-bootstrap'
import CustomP from 'components/atoms/CustomP/CustomP'
import React from 'react'
import EditorUploadInput from '_features/upload/ui/EditorUploadInput'

interface IProps {
  loading: boolean
  buttonText: string
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const FileUpload = (props: IProps) => {
  return (
    <StyledImageUpload>
      <EditorUploadInput
        type='file'
        id='fileInput'
        onChange={props.handleFileUpload}
        label={
          <Button variant={'primary'} size={'small'}>
            {props.loading ? <Spinner size='sm' /> : props.buttonText}
          </Button>
        }
      />
      <CustomP
        color='var(--gray3)'
        fontWeight='400'
        paddingTop='6px'
        fontSize='12px'
        marginLeft='auto'
        marginRight='auto'
      >
        The maximum size per file is 5GB
      </CustomP>
    </StyledImageUpload>
  )
}

export default FileUpload
