import { GroupBase, MultiValue } from 'react-select'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { IMultiValueOption } from 'interfaces/invite'
import { useSelectWithChipsInvites } from './useSelectWithChipsInvites'
import Select from 'react-select/dist/declarations/src/Select'
import { SelectWithInvitesStyles, StyledAsyncCreatableSelectWrapper } from './styles'
import { useMemo } from 'react'
import debounce from 'lodash.debounce'

interface Props {
  suggestedUsersPromise?: (value: string) => Promise<any> | undefined
  setInviteEmailsState: (value: MultiValue<IMultiValueOption>) => void
  width?: string
  minheight?: string
  border?: string
  borderradius?: string
  flexgrow?: string
  selectRef?: React.Ref<Select<IMultiValueOption, true, GroupBase<IMultiValueOption>>>
  zIndex?: string
}

const SelectWithChipsInvites = ({
  suggestedUsersPromise,
  setInviteEmailsState,
  width,
  minheight,
  border,
  borderradius,
  flexgrow,
  selectRef,
  zIndex,
}: Props) => {
  const { value, setValue, handleCreate, isLoading, animatedComponents } =
    useSelectWithChipsInvites({
      setInviteEmailsState,
    })
  const debouncedSuggestedUsers = useMemo(
    () => suggestedUsersPromise && debounce(suggestedUsersPromise, 300),
    [],
  )
  return (
    <StyledAsyncCreatableSelectWrapper>
      <AsyncCreatableSelect
        ref={selectRef}
        styles={SelectWithInvitesStyles(width, border, borderradius, flexgrow, minheight, zIndex)}
        onChange={(newValue) => {
          setValue(newValue)
          setInviteEmailsState(newValue)
        }}
        placeholder={'@email or name'}
        cacheOptions
        isMulti
        value={value}
        onCreateOption={handleCreate}
        isLoading={isLoading}
        isClearable={false}
        loadOptions={debouncedSuggestedUsers}
        components={{
          ...animatedComponents,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            neutral50: 'var(--primary-text) !important', // Colour for placeholder
          },
        })}
      />
    </StyledAsyncCreatableSelectWrapper>
  )
}

export default SelectWithChipsInvites
