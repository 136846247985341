import React, { ReactElement } from 'react'
import {
  ConnectionInfo,
  ConnectionInfoWithPicture,
  ConnectionItemUserModalStyled,
  ConnectionLink,
  ConnectionName,
  ConnectionStatus,
} from './styles'

type Props = {
  isUserConnected: boolean
  connectionLogo: ReactElement
  connectionName: string
}

const ConnectionItemUserModal: React.FC<Props> = ({
  isUserConnected,
  connectionLogo,
  connectionName,
}) => {
  return (
    <ConnectionItemUserModalStyled>
      <ConnectionInfoWithPicture>
        <>{connectionLogo}</>
        <ConnectionInfo>
          <ConnectionName>{connectionName}</ConnectionName>
          <ConnectionStatus>
            {/* TODO Handle user connection here*/}
            {isUserConnected ? 'Your are connected' : `Log in with ${connectionName}`}
          </ConnectionStatus>
        </ConnectionInfo>
      </ConnectionInfoWithPicture>
      <ConnectionLink>Connect</ConnectionLink>
    </ConnectionItemUserModalStyled>
  )
}

export default ConnectionItemUserModal
