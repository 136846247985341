import { IMultiValueOption } from 'interfaces/invite'
import { useState } from 'react'
import { MultiValue } from 'react-select'
import makeAnimated from 'react-select/animated'
import { validateEmail } from 'utils/auth/validateEmail'
import { useToast } from 'services/helpers/useToast'

interface Option {
  readonly label: string
  readonly value: string
}

interface Props {
  setInviteEmailsState: (value: MultiValue<IMultiValueOption>) => void
}

const createOption = (label: string): Option => ({
  label,
  value: label,
})

export const useSelectWithChipsInvites = ({ setInviteEmailsState }: Props) => {
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [value, setValue] = useState<MultiValue<IMultiValueOption>>([])

  const handleCreate = (inputValue: string) => {
    if (validateEmail(inputValue)) {
      const newOption = createOption(inputValue)
      setValue((prev) => [...prev, newOption])
      setInviteEmailsState([...value, newOption])
    } else {
      toast.error('Email not valid')
    }
  }

  const animatedComponents = makeAnimated()

  return {
    value,
    setValue,
    handleCreate,
    isLoading,
    animatedComponents,
  }
}
