import styled from 'styled-components'

import { ReactComponent as MagnifierIconStyled } from 'assets/images/clock.svg'
import { ReactComponent as NotificationBellStyled } from 'assets/images/notificationBell.svg'

export const StyledAvatarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 24px;
  padding: 4px;
  transition: all 0.3s ease-in-out;
  border-radius: 8px;
  user-select: none;
  overflow-x: hidden;

  & img {
    object-fit: cover;
    object-position: 50% 50%;
  }
`

export const StyledArrow = styled.img`
  cursor: pointer;
  color: #aeb0b4;
  transform: scale(1.22) translateX(-0.15rem);
`

export const ImageWrapper = styled.div`
  position: relative;
`

export const MagnifierIcon = styled(MagnifierIconStyled)`
  width: 20px;
  height: 20px;
`

export const NotificationBell = styled(NotificationBellStyled)`
  margin-right: 0.65rem;
  cursor: pointer;

  :hover {
    background: #f4f7ff;
  }
`
