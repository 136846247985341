import { AxiosService } from 'services/axiosService/axiosService'

export enum ImageSizeEnum {
  SQUARE = '1024x1024',
  LANSCAPE = '1792x1024',
  PORTRAIT = '1024x1792',
}

export enum SummarizationCategory {
  LEGAL = 'legal',
  PRODUCT = 'product',
  COPYWRITING = 'copywriting',
  FINANCE = 'finance',
  DEFAULT = 'default',
}

export const useAi = () => {
  const AxiosInstance = new AxiosService()

  const summarize = async (text: string) => {
    return await AxiosInstance.aiSummarize(text)
  }

  const expand = async (text: string) => {
    return await AxiosInstance.aiExpand(text)
  }

  const generate = async (text: string) => {
    return await AxiosInstance.aiGenerate(text)
  }

  const generateImage = async (text: string, imageSize?: ImageSizeEnum) => {
    return await AxiosInstance.generateAiImage(text, imageSize)
  }

  return {
    summarize,
    expand,
    generate,
    generateImage,
  }
}
