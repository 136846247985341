import ProfilePhotoEditor from 'components/organisms/ProfilePhotoEditor/ProfilePhotoEditor'
import { Modal } from 'react-bootstrap'
import { ChangeSpaceLogoModalStyled, Button, ModalTitle, SubtitleText } from './styles'
import { useChangeSpaceLogoModal } from './useChangeSpaceLogoModal'

type Props = {
  show: boolean
  handleClose: () => void
}

const ChangeSpaceLogoModal = ({ show, handleClose }: Props) => {
  const {
    imageScale,
    setImageScale,
    onSubmit,
    getRootProps,
    getInputProps,
    setSpaceLogo,
    spaceLogo,
    editor,
  } = useChangeSpaceLogoModal(handleClose)
  return (
    <ChangeSpaceLogoModalStyled
      show={show}
      onHide={() => {
        handleClose()
        setSpaceLogo(undefined)
      }}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      dialogClassName='changePhotoModal'
    >
      <Modal.Header closeButton>
        <ModalTitle>Adjust photo</ModalTitle>
      </Modal.Header>
      <SubtitleText>Drag to reposition photo and use the slider to zoom in or out</SubtitleText>
      <Modal.Body>
        <ProfilePhotoEditor
          file={spaceLogo}
          editor={editor}
          getInputProps={getInputProps}
          getRootProps={getRootProps}
          imageScale={imageScale}
          setImageScale={setImageScale}
          isProfileModal={true}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onSubmit}>Save photo</Button>
      </Modal.Footer>
    </ChangeSpaceLogoModalStyled>
  )
}

export default ChangeSpaceLogoModal
