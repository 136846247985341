import { AxiosService } from 'services/axiosService/axiosService'
import { IPages } from 'interfaces/page'
import { useEmbed } from '_entities/embed'
import { PageTypesEnum } from 'interfaces/projectFile'
import { useTextBlock } from '_entities/text'
import { useAppSelector } from 'redux/hooks'
import { useShareDb } from './useShareDb'
import { usePosition } from '_features/canvas'

export const usePage = () => {
  const AxiosInstance = new AxiosService()
  const _embed = useEmbed()
  const _textBlock = useTextBlock()
  const _shareDb = useShareDb()
  const _position = usePosition()
  const selectedPage = useAppSelector((state) => state.page.selectedPage)
  const pages = useAppSelector((state) => state.projectFile.pages)

  const getPageById = (id: string) => {
    return pages?.find((page) => page.id === id)
  }

  const deletePage = async (pageId: string, spaceId: string, projectId: string) => {
    if (spaceId && projectId) {
      return await AxiosInstance.deleteProjectFilePage(spaceId, parseInt(projectId), pageId)
    }

    return null
  }

  const getSubpagesAndParentPage = (pages: IPages[], pageId: string | number) => {
    const parentPage = pages.find((page) => page.id === pageId)
    const subpages = getSubpages(pages, pageId)
    if (!parentPage) return subpages
    return [parentPage, ...subpages]
  }

  const getSubpages = (pages: IPages[], pageId: string | number): IPages[] => {
    const children: IPages[] = []
    if (!pages) return children
    for (const page of pages) {
      if (page.parent === pageId) {
        const nestedChildren = getSubpages(pages, page.id)
        children.push(page, ...nestedChildren)
      }
    }
    return children
  }

  const getTextBlocks = async (pageId: string) => {
    const blocks = await _shareDb.getBlocksFromUnsubscribedDoc(pageId)
    if (!blocks) return
    return _textBlock.getTextBlocksFromBlocks(blocks)
  }

  const getEmbedBlocks = async (pageId: string) => {
    const blocks = await _shareDb.getBlocksFromUnsubscribedDoc(pageId)
    if (!blocks) return
    return blocks.filter((block) => _embed.isEmbedBlock(block))
  }

  const getTextualContentBlocks = async (pageId: string) => {
    const blocks = await _shareDb.getBlocksFromUnsubscribedDoc(pageId)
    if (!blocks) return
    return _textBlock.getBlocksContainingTextFromBlocks(blocks)
  }

  const getText = async (pageId: string) => {
    let blocks = await getTextualContentBlocks(pageId)
    if (!blocks) return
    blocks = _position.sortBlocksByY(blocks)
    return _textBlock.getTextFromBlocks(blocks)
  }

  const isDocument = (pageId: string) => {
    const page = getPageById(pageId)
    if (!page) return
    return page.type === PageTypesEnum.DOCUMENT
  }

  const isCanvas = (pageId: string) => {
    const page = getPageById(pageId)
    if (!page) return
    return page.type === PageTypesEnum.WHITE_BOARD
  }

  const isExternal = (page: IPages) => {
    return page.type === PageTypesEnum.EMBED
  }

  const getPageTitle = (id?: string) => {
    const page = pages?.find((page) => page.id === id)
    return page?.text
  }

  // ======= CHECK SELECTED PAGE =======
  const isSelectedPageDoc = () => {
    if (!selectedPage) return
    return isDocument(selectedPage?.id)
  }

  const isSelectedPageCanvas = () => {
    if (!selectedPage) return
    return isCanvas(selectedPage?.id)
  }

  return {
    getPageById,
    deletePage,
    getSubpagesAndParentPage,
    getSubpages,
    getTextBlocks,
    getEmbedBlocks,
    getTextualContentBlocks,
    getText,
    isDocument,
    isCanvas,
    isExternal,
    getPageTitle,
    isSelectedPageDoc,
    isSelectedPageCanvas,
  }
}
