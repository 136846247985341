import React from 'react'
import {
  CTABtn,
  CtaButton,
  getCtaBorderColor,
  getCtaBorderRadius,
  getCtaButtonColor,
  getCtaButtonHeight,
  getCtaFont,
  getCtaFontSize,
  getCtaTextColor,
  getCtaTitle,
} from '_entities/cta'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { CtaButtonsListWrapper } from '../styles'

interface IProps {
  isDocument?: boolean
  setButtonForEdit: (button: CtaButton) => void
  deleteCtaButton: (id: number) => void
  createCtaButton: () => void
  wrapperRef: React.RefObject<HTMLDivElement>
  loadedData: CtaButton[] | null
}

export const ButtonsList = (props: IProps) => {
  return (
    <CtaButtonsListWrapper ref={props.wrapperRef}>
      {props.loadedData?.map((item) => (
        <Wrapper
          display='flex'
          justifyContent='space-between'
          alignItems='center'
          key={item.id}
          padding='6px 12px'
        >
          <CTABtn
            fontFamily={getCtaFont(item)}
            fontSize={getCtaFontSize(item)}
            textColor={getCtaTextColor(item)}
            buttonBackgroundColor={getCtaButtonColor(item)}
            borderRadius={getCtaBorderRadius(item)}
            borderColor={getCtaBorderColor(item)}
            buttonSize={getCtaButtonHeight(item)}
            onClick={() => {
              props.setButtonForEdit(item)
            }}
          >
            {getCtaTitle(item)}
          </CTABtn>
        </Wrapper>
      ))}
    </CtaButtonsListWrapper>
  )
}
