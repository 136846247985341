import { ProfileDrawerImageWrapper } from './styles'
import { StyledTitle } from 'components/organisms/ColorSettings/styles'
import React from 'react'
import { StyledColorPicker } from 'components/molecules/ColorPicker/styles'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { PaletteColor } from 'components/molecules/PaletteColors/styles'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import CustomP from 'components/atoms/CustomP/CustomP'
import { useBackgroundImage } from '../hooks'
import { getById } from 'shared/lib'

export const SpaceColorSettings = () => {
  const _useBackgroundImage = useBackgroundImage()

  return (
    <ProfileDrawerImageWrapper>
      <StyledTitle>Space background color</StyledTitle>
      <Wrapper display='flex' alignItems='center' gap='16px'>
        <CustomP fontWeight='500' fontSize='14px' width='90px'>
          {_useBackgroundImage.backgroundColor || '#ffffff'},{' '}
          {_useBackgroundImage.backgroundOpacity}
        </CustomP>
        <RadixDropdown
          trigger={
            <PaletteColor size='20px' color={_useBackgroundImage.backgroundColor || '#ffffff'} />
          }
        >
          <StyledColorPicker
            color={_useBackgroundImage.backgroundColor}
            onChange={(e) => {
              const documentBackground = getById('document-background')

              if (documentBackground) {
                documentBackground.style.background = `rgba(${e.rgb.r}, ${e.rgb.g}, ${e.rgb.b}, ${e.rgb.a})`
              }
              _useBackgroundImage.setBackgroundColor(e.hex)
              _useBackgroundImage.setBackgroundOpacity(e.rgb.a)
            }}
            onChangeComplete={(e) => {
              _useBackgroundImage.updateProjectFile(
                JSON.stringify({
                  backgroundImage: undefined,
                  backgroundColor: e.hex,
                  backgroundOpacity: e.rgb.a,
                }),
              )
            }}
          />
        </RadixDropdown>
      </Wrapper>
    </ProfileDrawerImageWrapper>
  )
}
