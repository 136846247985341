import React, { useEffect, useState } from 'react'
import { EditWrapper } from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/BlockEdit/styles'
import { getColor } from 'shared/colors'
import CustomP from 'components/atoms/CustomP/CustomP'
import Switch from 'components/atoms/Switch/Switch'
import { SidebarEditProps } from './EmbedEdit'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb'
import { getBlockInternalEmbed, getIsSlidesNavOn } from '_entities/embed'
import { getBlockPageId, getIndex } from '_entities/block'
import { JSONOp } from 'ot-json1'
import { SourceKeys } from 'interfaces/editor'

export const SlidesNavigationToggle = (props: SidebarEditProps) => {
  // ** State
  const [isNav, setIsNav] = useState(false)

  // ** Hooks
  const _submit = useSubmit()
  const _json1 = useJson1()

  useEffect(() => {
    setIsNav(!!getIsSlidesNavOn(props.block))
  }, [props.block])

  const handleEditOverlayToggle = () => {
    setIsNav(!isNav)
    const index = getIndex(props.block)

    if (index === -1) return

    let op: JSONOp

    if (getIsSlidesNavOn(props.block) !== undefined) {
      op = _json1.getReplaceBlockDataKeyOp(index, ['internalEmbed', 'isSlidesNavOn'], !isNav)
    } else {
      if (!getBlockInternalEmbed(props.block)) {
        op = _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed'], {
          isSlidesNavOn: !isNav,
        })
      } else {
        op = _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed', 'isSlidesNavOn'], !isNav)
      }
    }

    _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
  }

  return (
    <EditWrapper>
      <CustomP fontSize={'14px'} fontWeight={'500'} color={getColor('--primary-text')}>
        Frames navigation
      </CustomP>
      <Switch checked={isNav} onClick={handleEditOverlayToggle} transform='translateX(3px)' />
    </EditWrapper>
  )
}
