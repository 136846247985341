import { IUserAttributes } from './auth/userData'
import { IRoles } from './invite'

export interface IProjectFile {
  id: number
  createdAt: Date
  title: string
  updatedAt: Date
  spaceId: string
  attributes: IProjectFile
}

export enum SpaceTabNames {
  SETTINGS = 'Settings',
  GROUPS = 'Groups',
  PLANSANDBILLING = 'Plans and billing',
  MEMBERS = 'Members',
  PROJECTS = 'Projects',
}

export interface ISpace {
  projectFiles: IProjectFile[]
  spaceMembers: ISpaceMember[]
  description: string
  id: string
  name: string
  logo?: string
  chatId: string
  isDefault?: boolean
}

export interface IResult {
  name: string
  email: string
}

export interface ILinkExpiration {
  name: string
  value: string
  assignMember?: string
}

export interface IShareProjectWithMembers {
  name: string
  assignMember: string
  private: boolean
}

export interface ISpaceMember {
  id: string
  role: ISpaceRole
  user: IUserAttributes
}

export interface ISpaceRole {
  createdAt: Date
  updatedAt: Date
  id: number
  type: IRoles
  spacePermissions: ISpacePermission[]
}

export enum ISpacePermissionType {
  All = 'All',
  TransferOwnership = 'Transfer Ownership',
  Delete = 'Delete',
  AddMember = 'Add Member',
  RemoveMember = 'Remove Member',
  Edit = 'Edit',
  Comment = 'Comment',
  Approve = 'Approve',
  View = 'View',
}

export interface ISpacePermission {
  createdAt: Date
  updatedAt: Date
  id: number
  type: ISpacePermissionType
}

export interface ISpaces {
  name: string
  id: number
}

// TODO add all the other possible subscription types
export enum SubscriptionTypes {
  FREE = 'Free',
}
