import { type block } from '_entities/block'
import { useEffect, useRef } from 'react'
import useDate from '_entities/block/model/useDate'

interface Props {
  block: block
}

const useDueDate = (props: Props) => {
  const { block } = props
  const blockRef = useRef(block)
  const { onDateChange } = useDate()

  useEffect(() => {
    blockRef.current = block
  }, [block])

  return {
    onDateChange,
  }
}

export default useDueDate
