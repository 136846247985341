import { block } from '_entities/block'
import { useAppDispatch } from 'redux/hooks'
import { setMessagesToOpen, setObjectChatToView, setOpen } from 'redux/reducers/chatReducer'

export const useMessageThread = () => {
  const dispatch = useAppDispatch()
  const handleOpenMessageThread = (block: block) => {
    dispatch(setOpen(true))
    block.meta?.chatMessageIds && dispatch(setMessagesToOpen(block.meta?.chatMessageIds))
    dispatch(setObjectChatToView(block))
  }

  return {
    handleOpenMessageThread,
  }
}
