export enum Position {
  LEFT = 'left',
  RIGHT = 'right',
}

export enum PageTypesEnum {
  DOCUMENT = 'document',
  SLIDES = 'slides',
  WHITE_BOARD = 'whiteboard',
  TASK_MANAGER = 'task-manager',
  EMBED = 'embed',
}

export enum ProjectFileOrganisation {
  LIST = 'list',
  BOXES = 'boxes',
}

export interface IPageBreadcrum {
  name: string
  backgroundColor: string
}

export interface IProjectFileMember {
  lastVisitedPage: string
  id: string
  email: string
  status: string
  inviteToken: {
    id: string
  }
  projectFile: {
    id: number
  }
  role: {
    id: number
    type: string
  }
  projectFileRole: {
    id: number
    type: string
  }
  user: {
    firstName: string
    id: number
    lastName: string
    email: string
  }
}

export interface IProjectFilePendingMember {
  email: string
  status: string
  id: string
  projectFileRole: {
    type: string
    id: number
  }
}
export interface ISuggestedUser {
  id: number
  name: string
  email: string
  role: string
  lastActive: string
}

export interface IProjectRole {
  attributes: {
    type: string
  }
  id: string
  type: string
}

export enum roleIdData {
  OWNER_ROLE_ID = '1',
  GUEST_ROLE_ID = '5',
}
