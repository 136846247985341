import { type block } from '_entities/block'
import { MessageResponseWithBlock } from 'interfaces/chat'
import { useEffect, useState } from 'react'
import { useGetStreamContext } from 'services/getstream/GetStreamContext'
import { useComments } from '_features/comments'

interface Props {
  block: block
}

const useBlockMessages = (props: Props) => {
  const { block } = props
  const { chatClient } = useGetStreamContext()
  const { handleOnCommentClick } = useComments()

  const [messages, setMessages] = useState<(MessageResponseWithBlock | null)[] | null>(null)

  useEffect(() => {
    fetchMessages()
  }, [block])

  const fetchMessages = async () => {
    const filteredMessages = await Promise.all(
      (block?.meta?.chatMessageIds || []).map(async (messageId) => {
        try {
          const message = await chatClient?.getMessage(messageId)
          return message?.message || null
        } catch (error) {
          return null
        }
      }),
    )

    setMessages(filteredMessages as (MessageResponseWithBlock | null)[])
  }

  return {
    messages,
    handleOnCommentClick,
  }
}

export default useBlockMessages
