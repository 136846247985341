import React from 'react'
import styled from 'styled-components'

interface Props {
  icons: React.ReactNode[]
  title: string
}

const StyledIconList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 10px;
`

const IconList = (props: Props) => {
  return <StyledIconList>{props.icons.map((icon) => icon)}</StyledIconList>
}

export default IconList
