import { useFontSize } from '../model/useFontSize'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import FontSizeDropdown from './FontSizeDropdown'
import { StyledInput, StyledButton } from './styles'
import { block } from '_entities/block'
import { useEffect, useState } from 'react'
import { getSize } from '_entities/text'
import { getFontSizeInputId } from '../lib/getters'
import { getEditor } from 'shared/lib'

interface Props {
  block: block
  inputDisabled?: boolean
}

export const FontSizeChanger = (props: Props) => {
  const [fontSize, setFontSize] = useState<number | undefined>()
  const _fontSize = useFontSize()

  useEffect(() => {
    const editor = getEditor(props.block._id)
    if (!editor) return
    const ops = editor.getContents().ops
    if (!ops) return
    const currentSize = getSize(ops)
    setFontSize(currentSize)
  }, [props.block])

  return (
    <Wrapper display='flex' gap='6px'>
      <StyledInput
        id={getFontSizeInputId(props.block)}
        disabled={props.inputDisabled}
        value={fontSize}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const newFontValue = parseInt(e.currentTarget.value)
          setFontSize(newFontValue)
          _fontSize.updateSelectionTextSize(props.block, newFontValue)
        }}
        type='number'
      />

      <RadixDropdown
        trigger={
          <StyledButton>
            <icons.caretDown />
          </StyledButton>
        }
      >
        <FontSizeDropdown {...{ block: props.block }} />
      </RadixDropdown>
    </Wrapper>
  )
}
