import styled from 'styled-components'
import { Container } from 'react-bootstrap'

export const StyledContainer = styled(Container)<{ isPrivate?: string }>`
  height: 52px;
  border-radius: 8px;
  display: flex;
  justify-content: start;
  align-items: center;
  margin: 0;
  width: auto;
  padding: 0 0 0 5px;
`
