import { createSlice } from '@reduxjs/toolkit'

interface SubscriptionState {
  billingInterval: 'Monthly' | 'Yearly'
}

const initialState: SubscriptionState = {
  billingInterval: 'Yearly',
}

const subscriptionSlice = createSlice({
  name: 'subscriptionReducer',
  initialState,
  reducers: {
    setBillingInterval: (state, action) => {
      state.billingInterval = action.payload
    },
  },
})

export const { setBillingInterval } = subscriptionSlice.actions

export default subscriptionSlice.reducer
