import useLocalStorage from 'shared/lib/useLocalStorage'

export const usePreviewForReferences = () => {
  const { getParsedItem, setItem } = useLocalStorage()
  const currentPreviewSelection = getParsedItem('previewSelection')

  const updateSelectedBlocksForReference = (id: string) => {
    const index = currentPreviewSelection.findIndex(
      (block: { blockId: string; html: string }) => block.blockId === id,
    )
    if (index !== -1) {
      currentPreviewSelection.splice(index, 1)
    } else {
      currentPreviewSelection.push({
        blockId: id,
      })
    }
    setItem('previewSelection', currentPreviewSelection)
    window.dispatchEvent(new Event('storage'))
  }

  const resetSelectedBlocksForReference = () => {
    setItem('previewSelection', [])
  }
  return {
    updateSelectedBlocksForReference,
    resetSelectedBlocksForReference,
  }
}
