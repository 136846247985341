import styled from 'styled-components'
import { LargeButton, SmallButton, LARGE_BUTTON_HEIGHT } from './Button'

const POSITIVE_SIGN = 1
const NEGATIVE_SIGN = -1
const HORIZONTAL_SPACING = 36
const VERTICAL_SPACING = 6

type Position = 'left' | 'right'

const getSignedHorizontalMargin = (isInside?: boolean) => {
  return isInside ? HORIZONTAL_SPACING * POSITIVE_SIGN : HORIZONTAL_SPACING * NEGATIVE_SIGN
}

const getSignedVerticalMargin = (isInside?: boolean) => {
  return isInside
    ? (VERTICAL_SPACING + LARGE_BUTTON_HEIGHT) * NEGATIVE_SIGN
    : VERTICAL_SPACING * POSITIVE_SIGN
}

const getSignedVerticalCss = (isInside?: boolean) => {
  return `top: calc(100% + ${getSignedVerticalMargin(isInside)}px)`
}

const getSignedValue = (isInside?: boolean) => {
  return `${getSignedHorizontalMargin(isInside)}px`
}

const getSignedLeftCss = (isInside?: boolean) => {
  return `left: ${getSignedValue(isInside)}`
}

const getSignedRightCss = (isInside?: boolean) => {
  return `right: ${getSignedValue(isInside)}`
}

export const StyledArrow = styled(SmallButton)<{ position: Position; isInside?: boolean }>`
  top: 50%;
  ${(props) =>
    props.position === 'left'
      ? getSignedLeftCss(props.isInside)
      : getSignedRightCss(props.isInside)};
  transform: translateY(-50%);
`
export const StyledPlus = styled(SmallButton)<{ isInside?: boolean }>`
  top: calc(50% - 36px);
  ${(props) => getSignedRightCss(props.isInside)};
  transform: translateY(-50%);
`
export const StyledTrash = styled(LargeButton)<{ isInside?: boolean }>`
  ${(props) => getSignedVerticalCss(props.isInside)};
  left: 50%;
  transform: translateX(-50%);
`
