import { getBlockPageId, getIndex, type block } from '_entities/block'
import {
  getBlockDocumentPage,
  getExpandedPageBlockElement,
  getInternalEmbedBorderWidth,
  useWhiteboardEmbed,
} from '_entities/embed'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { SourceKeys } from 'interfaces/editor'

export const useInternalCanvasBorderWidth = () => {
  const _json1 = useJson1()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _submit = useSubmit()

  // ===== REAL TIME UPDATES =====

  const updateInternalCanvasBorderWidthInRealTime = (block: block, width: string) => {
    const blockPage = getBlockDocumentPage(block)
    if (!blockPage) return
    updateInternalCanvasElementBorderWidthInRealTime(blockPage.id, width)
  }
  const updateInternalCanvasElementBorderWidthInRealTime = (pageId: string, width: string) => {
    const parentBlock = _whiteboardEmbed.getParentBlock(pageId)
    if (!parentBlock) return
    const element = getExpandedPageBlockElement(parentBlock)
    if (!element) return
    element.style.borderWidth = width
  }

  // ===== SHAREDB UPDATES =====

  const updateInternalCanvasBorderWidth = (block: block, width: number) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    if (index < 0) return
    const currentWidth = getInternalEmbedBorderWidth(block)
    if (currentWidth !== undefined) {
      _submit.submit(
        pageId,
        _json1.getReplaceBlockDataKeyOp(
          index,
          ['internalEmbed', 'borderWidth'],
          width,
          currentWidth,
        ),
        SourceKeys.UPDATE_BLOCK,
      )
    } else
      _submit.submit(
        pageId,
        _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed', 'borderWidth'], width),
        SourceKeys.UPDATE_BLOCK,
      )
  }
  return {
    updateInternalCanvasBorderWidthInRealTime,
    updateInternalCanvasElementBorderWidthInRealTime,
    updateInternalCanvasBorderWidth,
  }
}
