import Resizable from 'whiteboard/Resizable/Resizable'
import { useAppSelector, useAppDispatch } from 'redux/hooks'
import { useEmojiBlock, getNative, getCustomEmojiSrc } from '_features/emojis'
import { WhiteboardBlockProps, getBlockPageId } from '_entities/block'
import { Twemoji } from 'react-emoji-render'
import styled from 'styled-components'
import { Image } from '_entities/whiteboard'
import { useCreateBlock } from '_features/block'
import { useTransformer } from '_features/canvas'
import { useState } from 'react'
import { setSelectedBlock } from 'redux/reducers/whiteboardReducer'

export const StyledTwemoji = styled(Twemoji)`
  & > img {
    width: 100% !important;
    height: 100% !important;
  }
`

export const EmojiBlock = (props: WhiteboardBlockProps) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const _emojiBlock = useEmojiBlock()
  const _createBlock = useCreateBlock()
  const _transformer = useTransformer()
  const dispatch = useAppDispatch()
  const shouldEnable = () => {
    return !props.isPreview && selectedBlock?._id === props.block._id
  }
  const code = _emojiBlock.getEmojiCode(props.block)

  if (!code) return null
  const native = getNative(code)
  const customIconSrc = getCustomEmojiSrc(code)

  return (
    <Resizable htmlStyle={{ overflow: 'hidden' }} {...props} shouldEnable={shouldEnable()}>
      <div
        ref={(node) => {
          if (!node || loaded) return
          if (_createBlock.getIsJustCreatedOnCanvas(props.block)) {
            _transformer.setNodeFromBlockToTransformer(getBlockPageId(props.block), props.block)
            dispatch(setSelectedBlock(props.block))
            _createBlock.removeIsJustCreatedOnCanvas(props.block)
          }
          setLoaded(true)
        }}
      >
        {(native && <StyledTwemoji svg text={native || 'Emoji'} />) ||
          (customIconSrc && <Image src={customIconSrc} />)}
      </div>
    </Resizable>
  )
}
