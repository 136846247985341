import { createSlice } from '@reduxjs/toolkit'
import {
  ILinkExpiration,
  IProjectFile,
  IShareProjectWithMembers,
  ISpace,
  ISpaces,
} from 'interfaces/space'
import { ITab } from 'interfaces/tabs'
import { IGeneralDropdownOption } from 'interfaces/invite'

interface SpaceState {
  currentSpace?: ISpace
  selectedTab: ITab
  inviteEmailsInputValue: string
  spaceMembers: any[]
  projectFiles: IProjectFile[]
  /* Project files for select are for dropdown select options including only name and value */
  projectFilesForSelect: IGeneralDropdownOption[]
  filteredProjectFiles: IProjectFile[]
  expireLinkValue: ILinkExpiration
  newProjectDropdownValue?: IShareProjectWithMembers
  allSpaces: ISpaces[]
  filteredSpaceMembers: any[]
  planModalOpened: boolean
  inviteModalOpened: boolean
  profileModalOpened: boolean
}

const initialState = {
  inviteEmailsInputValue: '',
  spaceMembers: [],
  selectedTab: {
    text: 'Projects',
  },
  projectFiles: [],
  projectFilesForSelect: [],
  filteredProjectFiles: [],
  expireLinkValue: { name: 'Never', value: 'Never' },
  allSpaces: [],
  filteredSpaceMembers: [],
  planModalOpened: false,
  inviteModalOpened: false,
  profileModalOpened: false,
} as SpaceState

const spaceSlice = createSlice({
  name: 'spaceReducer',
  initialState,
  reducers: {
    setCurrentSpace: (state, action) => {
      state.currentSpace = action.payload
    },
    setInviteEmailsInputValue: (state, action) => {
      state.inviteEmailsInputValue = action.payload
    },
    setSpaceMembers: (state, action) => {
      state.spaceMembers = action.payload
    },
    setSelectedTab: (state, action) => {
      state.selectedTab.text = action.payload
    },
    setProjectFiles: (state, action) => {
      const projectFilesForSelect = action.payload.map((projectFile: IProjectFile) => {
        return {
          name: projectFile.title,
          value: projectFile.id,
        }
      })
      state.projectFiles = action.payload
      state.projectFilesForSelect = projectFilesForSelect
    },
    setFilteredProjectFiles: (state, action) => {
      state.filteredProjectFiles = action.payload
    },
    setExpireLinkValue: (state, action) => {
      state.expireLinkValue = action.payload
    },
    setNewProjectFileValue: (state, action) => {
      state.newProjectDropdownValue = action.payload
    },
    setSpaces: (state, action) => {
      state.allSpaces = action.payload
    },
    setFilteredSpaceMembers: (state, action) => {
      state.filteredSpaceMembers = action.payload
    },
    setPlanModalOpened: (state, action) => {
      state.planModalOpened = action.payload
    },
    setInviteModalOpened: (state, action) => {
      state.inviteModalOpened = action.payload
    },
    setProfileModalOpened: (state, action) => {
      state.profileModalOpened = action.payload
    },
  },
})

export const {
  setCurrentSpace,
  setInviteEmailsInputValue,
  setSpaceMembers,
  setSelectedTab,
  setProjectFiles,
  setFilteredProjectFiles,
  setExpireLinkValue,
  setNewProjectFileValue,
  setFilteredSpaceMembers,
  setPlanModalOpened,
  setInviteModalOpened,
  setProfileModalOpened,
} = spaceSlice.actions

export default spaceSlice.reducer
