import { IBlockTypes } from '_entities/block'
import SharedTextToolbar from '../SharedTextToolbar/SharedTextToolbar'
import WhiteboardTextConversionDropdown from '../Dropdowns/WhiteboardTextConversionDropdown/WhiteboardTextConversionDropdown'
import { isCapableToHaveSizeChangeArrowsDisplayedInMenu } from 'editor/EditableBlock/BlockToolbar/isCapableToHaveSizeChangeArrowsDisplayedInMenu'
import TextResizer from 'editor/EditableBlock/BlockToolbar/ResizeArrows/TextResizer'
import { useAppSelector } from 'redux/hooks'
import { ToolbarDivider } from 'editor/EditableBlock/BlockToolbar/styles'

const TextToolbar = () => {
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)

  const isBlockImage = selectedBlock?.data.tag === IBlockTypes.IMAGE
  if (!selectedBlock) return null
  return (
    <>
      {/* Resize arrows */}
      {isCapableToHaveSizeChangeArrowsDisplayedInMenu(selectedBlock) && !isBlockImage && (
        <TextResizer block={selectedBlock} />
      )}
      <WhiteboardTextConversionDropdown block={selectedBlock} />
      <SharedTextToolbar isWhiteboard block={selectedBlock} />
      <ToolbarDivider />
    </>
  )
}

export default TextToolbar
