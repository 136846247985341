import { PayloadAction, createSlice } from '@reduxjs/toolkit'

interface ProfileState {
  profileOpen: boolean
}

const initialState = {
  profileOpen: false,
} as ProfileState

const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    setProfileOpen: (state, action: PayloadAction<boolean>) => {
      state.profileOpen = action.payload
    },
  },
})

export const { setProfileOpen } = profileSlice.actions

export default profileSlice.reducer
