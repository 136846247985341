import { ToolbarDivider } from 'editor/EditableBlock/BlockToolbar/styles'
import SharedTextToolbar from '../SharedTextToolbar/SharedTextToolbar'
import ShapeStrokeDropdown from '../Dropdowns/ShapeStrokeDropdown/ShapeStrokeDropdown'
import ColorTool from './ColorTool/ColorTool'
import ShapeSelectorTool from './ShapeSelectorTool/ShapeSelectorTool'
import WhiteboardShapeTextConversionDropdown from '../Dropdowns/WhiteboardShapeTextConversionDropdown/WhiteboardShapeTextConversionDropdown'
import { useAppSelector } from 'redux/hooks'
import { useShape } from '_entities/shape'
import { useShapeColor } from '_features/canvas'

const ShapeToolbar = () => {
  const _shape = useShape()
  const _shapeColor = useShapeColor()

  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)

  if (!selectedBlock) return null

  return (
    <>
      <ColorTool
        color={_shape.getShapeFill(selectedBlock) || ''}
        onSelect={(color) => _shapeColor.handleSelectColor(selectedBlock, color)}
        onSelectDisabled={() => _shapeColor.handleDisableColor(selectedBlock)}
      />
      <ShapeStrokeDropdown block={selectedBlock} />
      <ShapeSelectorTool block={selectedBlock} showSticky />

      <ToolbarDivider />
      <WhiteboardShapeTextConversionDropdown block={selectedBlock} />
      <SharedTextToolbar isWhiteboard block={selectedBlock} />

      <ToolbarDivider />
    </>
  )
}

export default ShapeToolbar
