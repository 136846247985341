import React from 'react'
import { PropertyTitle, PropertyWrapper } from '../styles'
import Avatar from 'components/atoms/Avatar/Avatar'
import { envVars } from 'config/env'
import useCreatedBy from './useCreatedBy'
import { type block } from '_entities/block'

interface IProps {
  block: block
}

const CreatedBy = (props: IProps) => {
  const _createdBy = useCreatedBy({ block: props.block })

  return (
    <PropertyWrapper>
      <PropertyTitle>Created by</PropertyTitle>
      <Avatar
        src={
          _createdBy.createdBy?.photo
            ? `${envVars.api.s3Butcket}${_createdBy.createdBy?.photo}`
            : ''
        }
        userName={`${_createdBy.createdBy?.firstName} ${_createdBy.createdBy?.lastName}`}
        cursor={'initial'}
      />
    </PropertyWrapper>
  )
}

export default CreatedBy
