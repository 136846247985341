import styled from 'styled-components'

export const FullEmbedWrapper = styled.div<{ height?: number }>`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '100%')};
`

export const ExpandedFullEmbed = styled.div`
  height: 100%;

  :hover {
    & .page-preview-options {
      opacity: 1;
      height: 40px;
      padding: 8px 14px;
    }
  }
`
