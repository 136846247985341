import React from 'react'
import { type block } from '_entities/block'
import { IPages } from 'interfaces/page'
import { RelationshipContainer, RelationshipTextContainer } from '../styles'
import useOnPageClick from 'utils/useOnPageClick'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import RadixDropdown, {
  DropdownMenuItem,
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import Dots from 'components/atoms/Dots/Dots'
import { DetachIcon } from 'editor/EditableBlock/styles'
import getPageFromPages from 'utils/editor/getPageFromPages'
import { type RelatedObject } from '_features/relationship'
import { useIcons } from 'shared/icons'

type Props = {
  currentBlock?: block
  pages: IPages[] | undefined
  handleDetachAllFromOriginal?: (e: React.MouseEvent, block: block, item: RelatedObject) => void
}
const RenderSidebarRelations = (props: Props) => {
  const { currentBlock, pages, handleDetachAllFromOriginal } = props
  const onPageClick = useOnPageClick()
  const _icons = useIcons()
  return (
    <>
      {currentBlock &&
      currentBlock.meta &&
      currentBlock.meta.relatedObjects &&
      currentBlock.meta.relatedObjects.length
        ? currentBlock.meta.relatedObjects.map((item, index) => {
            const page = getPageFromPages(item.pageId ?? '', pages)

            const Icon =
              page?.icon || _icons.PageTypeIcon[page?.type as keyof typeof _icons.PageTypeIcon]

            if (item && page) {
              return (
                item.typeOfRelation !== 'reference' && (
                  <RelationshipContainer
                    key={index}
                    onClick={() => {
                      onPageClick(item.pageId as string, item._id)
                    }}
                  >
                    <RelationshipTextContainer>
                      {page?.icon ? <Wrapper>{Icon}</Wrapper> : <Icon />}
                      {page?.text}
                    </RelationshipTextContainer>
                    <RadixDropdown
                      trigger={
                        <IconButton>
                          <Dots />
                        </IconButton>
                      }
                    >
                      <DropdownMenuItem
                        onClick={(e) =>
                          handleDetachAllFromOriginal &&
                          handleDetachAllFromOriginal(e, currentBlock, item)
                        }
                      >
                        <DetachIcon />
                        Detach
                      </DropdownMenuItem>
                    </RadixDropdown>
                  </RelationshipContainer>
                )
              )
            }
          })
        : null}
    </>
  )
}

export default RenderSidebarRelations
