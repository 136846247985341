import { IBlockTypes } from '_entities/block'
import { SlashMenuProps, useSlashMenuSelection } from '_features/block'
import { getCtaTitle } from '_entities/cta'
import React from 'react'
import { SlashMenuCtaButtonsListWrapper, useCta } from '_features/cta'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { CTAIcon } from './styles'

export const SlashCtaMenu = (props: SlashMenuProps) => {
  const _cta = useCta()
  const _slashMenuSelection = useSlashMenuSelection()

  return (
    <SlashMenuCtaButtonsListWrapper ref={_cta.wrapperRef} open={props.open}>
      {_cta.loadedData?.map((item) => (
        <Wrapper
          key={item.id}
          display='flex'
          justifyContent='left'
          width='100%'
          alignItems='center'
          padding='8px 12px'
          gap='12px'
          onClick={() => {
            _slashMenuSelection.handleSelection(props.block, {
              icon: '',
              isText: false,
              label: getCtaTitle(item),
              type: IBlockTypes.CTA,
              id: item.id,
            })
          }}
        >
          <Wrapper width={'1rem'}>
            <CTAIcon />
          </Wrapper>
          {getCtaTitle(item).length > 15
            ? `${getCtaTitle(item).slice(0, 15)}...`
            : getCtaTitle(item)}
        </Wrapper>
      ))}
    </SlashMenuCtaButtonsListWrapper>
  )
}
