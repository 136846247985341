import { useAppSelector } from 'redux/hooks'
import { ChannelOption } from 'redux/reducers/chatReducer'

export const useDefaultChannel = () => {
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const DEFAULT_CHANNEL: ChannelOption = {
    cid: `messaging:${projectFile?.chatId}`,
    label: projectFile?.title ?? '',
  }
  return {
    DEFAULT_CHANNEL,
  }
}
