import { passwordStrength } from 'check-password-strength'

export const useSignupForm = (
  email: string,
  validateEmail: () => boolean,
  password: string,
  checkIfPasswordSafe: () => boolean,
) => {
  const emailValidityChecker =
    email.length !== 0
      ? !validateEmail() && email.length > 0
        ? 'is-invalid placeholderDarker'
        : 'placeholderConfirm'
      : 'placeholderDarker'

  const passwordValidityChecker =
    password.length !== 0
      ? !checkIfPasswordSafe() && password.length > 0
        ? 'is-invalid mb-1 placeholderDarker'
        : 'mb-4 placeholderConfirm'
      : 'placeholderDarker'

  const passwordPositiveWeaknessDetector =
    (passwordStrength(password).value === 'Too weak' ||
      passwordStrength(password).value === 'Weak') &&
    password.length > 0

  const passwordNegativeWeaknessDetector =
    passwordStrength(password).value !== 'Too weak' &&
    passwordStrength(password).value !== 'Weak' &&
    password.length > 0

  return {
    emailValidityChecker,
    passwordValidityChecker,
    passwordPositiveWeaknessDetector,
    passwordNegativeWeaknessDetector,
  }
}
