import {
  DetachIcon,
  DotsIcon,
  DotsIconWrapper,
  GoToIcon,
  StyledDropdown,
  StyledInstanceIndicator,
  StyledIsInstanceIcon,
  PullIcon,
  PageDropdownMenuItemText,
  StyledInstanceIcon,
  StyledMirrorIcon,
} from '../styles'
import { RelationshipContainer } from 'components/organisms/ProjectFileRightSideBar/styles'
import getNameFromPageId from 'utils/editor/getNameFromPageId'
import React from 'react'
import { type block } from '_entities/block'
import { RelationshipType } from '_features/relationship'
import { IPages } from 'interfaces/page'
import { DropdownMenuItem } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

interface IProps {
  block: block
  onPageClick: (pageId: string, blockId: string) => void
  pages: IPages[] | undefined
  isMirror: boolean
  handlePullUpdates: (e: React.MouseEvent, block: block) => void
  handleDetachFromRelationship: (block: block) => void
  handleSwitchRelationshipType: (type: RelationshipType, e: React.MouseEvent, id: string) => void
}

const OriginalInstance = ({
  isMirror,
  block,
  onPageClick,
  pages,
  handlePullUpdates,
  handleDetachFromRelationship,
  handleSwitchRelationshipType,
}: IProps) => {
  return (
    <StyledDropdown
      trigger={
        <StyledInstanceIndicator isMirror={isMirror}>
          <StyledIsInstanceIcon isScaled />
        </StyledInstanceIndicator>
      }
    >
      <RelationshipContainer>
        <PageDropdownMenuItemText
          key={block._id}
          onClick={() => {
            onPageClick(
              block.meta.originalObject?.pageOfOriginalBlock as string,
              block.meta.originalObject?._id as string,
            )
          }}
        >
          <DropdownMenuItem>
            <StyledInstanceIcon />
            {getNameFromPageId(block.meta.originalObject?.pageOfOriginalBlock ?? '', pages)}
          </DropdownMenuItem>
        </PageDropdownMenuItemText>
        <StyledDropdown
          trigger={
            <DotsIconWrapper>
              <DotsIcon />
            </DotsIconWrapper>
          }
          top='120px'
        >
          <DropdownMenuItem
            onClick={() => {
              onPageClick(
                block.meta.originalObject?.pageOfOriginalBlock as string,
                block.meta.originalObject?._id as string,
              )
            }}
          >
            <GoToIcon />
            Go to
          </DropdownMenuItem>
          <DropdownMenuItem onClick={(e) => handlePullUpdates(e, block)}>
            <PullIcon />
            Pull updates to instance
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => handleDetachFromRelationship(block)}>
            <DetachIcon />
            Detach
          </DropdownMenuItem>
          <DropdownMenuItem
            onClick={(e) => handleSwitchRelationshipType(RelationshipType.MIRROR, e, block._id)}
          >
            <StyledMirrorIcon />
            Switch to mirror
          </DropdownMenuItem>
        </StyledDropdown>
      </RelationshipContainer>
    </StyledDropdown>
  )
}

export default OriginalInstance
