import styled from 'styled-components'

export const PaletteColorsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 8px;
  gap: 6px;
  position: relative;
`

export const PaletteColor = styled.div<{ color: string; size?: string }>`
  height: ${(props) => (props.size ? props.size : '30px')};
  width: ${(props) => (props.size ? props.size : '30px')};
  border-radius: 4px;
  background-color: ${(props) => `${props.color}`};
  color: var(--primary-text);
  border: solid 0.5px var(--gray3);
`

export const PaletteNumber = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 0.5px var(--gray3);
`
