import { useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { useOnClickOutside } from 'usehooks-ts'

export const usePopperMenu = () => {
  const [element, setElement] = useState<HTMLElement | undefined>()

  const closeEmojiDropdown = () => setElement(undefined)

  const emojiPopperElementRef = useRef<HTMLElement | null>(null)
  const emojiPopper = usePopper(element, emojiPopperElementRef.current, {
    strategy: 'fixed',
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5],
        },
      },
    ],
  })

  useOnClickOutside(emojiPopperElementRef, (event) => {
    if (element && !element.contains(event.target as Node)) {
      event.preventDefault()
      event.stopPropagation()

      closeEmojiDropdown()
    }
  })

  return {
    element,
    setElement,
    emojiPopperElementRef,
    emojiPopper,
    closeEmojiDropdown,
  }
}
