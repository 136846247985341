import styled from 'styled-components'

export const ConnectionItemUserModalStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
`
export const ConnectionInfoWithPicture = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;

  & > svg {
    height: 48px;
    width: 48px;
  }
`

export const ConnectionName = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  color: var(--secondary-text);
`
export const ConnectionStatus = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 120%;
  color: var(--primary-text);
`
export const ConnectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`
export const ConnectionLink = styled.p`
  font-weight: 500;
  font-size: 14px !important;
  color: var(--primary-color) !important;
  cursor: pointer !important;
  padding: 0.25rem 0.75rem;
  border-radius: 10px;

  :hover {
    background-color: var(--gray4) !important;
  }
`
