import React from 'react'
import { StyledImage, StyledVideo } from './styles'
import { FileAttachment } from 'stream-chat-react'
import { DefaultGenerics, Attachment as StreamAttachment } from 'stream-chat'
import Wrapper from 'components/molecules/Wrapper/Wrapper'

interface IProps {
  attachment: StreamAttachment<DefaultGenerics>
}

const Attachment = (props: IProps) => {
  const { attachment } = props
  return (
    <Wrapper>
      {attachment.type === 'image' && <StyledImage src={attachment.image_url} />}
      {attachment.type === 'video' && (
        <StyledVideo controls width='300' height='200'>
          <source src={attachment.video_url as string} type='video/mp4' />
          Your browser does not support the video tag.
        </StyledVideo>
      )}
      {attachment.type === 'file' && <FileAttachment attachment={attachment} />}
    </Wrapper>
  )
}

export default Attachment
