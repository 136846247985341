import { SidebarListItemText } from 'components/molecules/SidebarList/styles'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  overflow-y: auto;
  height: 100%;

  .tree-container {
    height: 100%;
    overflow: auto;
    margin-bottom: 0;
  }
`

export const SidebarListItemIcon = styled.div`
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  margin-right: 8px;
`

const SidebarListItemHoverActiveCSS = css`
  background: var(--primary-color);
`

const SidebarListItemTextHoverActiveCSS = css`
  font-weight: 600;
  color: white;
`

export const StyledPageWrapper = styled.div<{
  isDropTarget?: boolean
  depth?: number
  active?: boolean
  isDragging?: boolean
}>`
  width: ${(props) => props.isDragging && 'fit-content'};
  padding: ${({ isDragging }) => (isDragging ? '0 !important' : '8px 4px 8px 8px')};
  cursor: pointer;
  color: var(--secondary-text);
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 2px;
  ${(props) => props.isDropTarget && 'outline: 2px solid var(--primary-color);'}
  padding-left: ${({ depth, isDragging }) =>
    depth && !isDragging ? `calc(12px * ${depth + 1})` : '8px !important'};
  position: relative;

  ${(props) =>
    !props.active &&
    css`
      &:hover {
        background: var(--hover);
      }
    `}

  ${(props) =>
    props.active &&
    css`
      i {
        color: var(--white);
      }
      svg path {
        stroke: var(--white) !important;
      }
      ${SidebarListItemHoverActiveCSS}
      ${SidebarListItemText} {
        ${SidebarListItemTextHoverActiveCSS}
      }
    `}
`

export const DropPlaceholder = styled.div``
