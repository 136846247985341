import styled from 'styled-components'

export const StyledEmptyChatText = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.011em;
  text-align: center;
  color: var(--gray-inactive);
`

export const StyledEmptyChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
`
