import React from 'react'
import styled from 'styled-components'
import { icons } from 'shared/icons'
import CustomP from 'components/atoms/CustomP/CustomP'

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

export const NoFrames = () => {
  return (
    <StyledWrapper>
      <icons.frameOff />
      <CustomP fontSize='1.5em' color={'var(--text)'}>
        This page has no frames to display in a slide show{' '}
      </CustomP>
    </StyledWrapper>
  )
}
