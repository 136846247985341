import { icons } from 'shared/icons'
import styled from 'styled-components'

export const StyledCircleIcon = styled(icons.circle)`
  path {
    fill: var(--primary-text);
  }
`

export const StyledRectangleIcon = styled(icons.rectangle)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledTriangleIcon = styled(icons.triangle)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledPentagonIcon = styled(icons.pentagon)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledHexagonIcon = styled(icons.hexagon)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledRombIcon = styled(icons.romb)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledCurvedRectangleIcon = styled(icons.curvedRectangle)`
  rect {
    stroke: var(--primary-text);
  }
`

export const StyledStickyNoteIcon = styled(icons.stickyNote)`
  rect {
    stroke: var(--primary-text);
  }
`
