import { block } from '_entities/block'
import {
  getResizableQuillWrapperId,
  getTextPositioningWrapperId,
} from 'whiteboard/Text/ResizableTextBlock'
import { getSize, MIN_SIZE } from '_entities/text'
import { useFontSize } from './useFontSize'
import { getById, getEditor } from 'shared/lib'

export const useOverflow = () => {
  const _fontSize = useFontSize()

  const isOverflowing = (block: block) => {
    const quillEl = getById(getResizableQuillWrapperId(block))
    const textPositioningEl = getById(getTextPositioningWrapperId(block))
    if (!quillEl || !textPositioningEl) return
    const quillHeight = quillEl.clientHeight
    const textPositioningHeight = textPositioningEl.clientHeight
    if (textPositioningHeight >= quillHeight) return true
    return false
  }

  const preventWritingOnOverflowingText = (e: KeyboardEvent) => {
    if (
      e.key === 'Backspace' ||
      e.key === 'Delete' ||
      e.key === 'ArrowUp' ||
      e.key === 'ArrowDown' ||
      e.key === 'ArrowLeft' ||
      e.key === 'ArrowRight'
    )
      return
    e?.preventDefault()
    e?.stopPropagation()
  }

  const reduceOverflowingTextSizeOnChange = (block: block) => {
    const editor = getEditor(block._id)
    if (!editor) return
    if (!isOverflowing(block)) {
      editor.root.removeEventListener('keydown', preventWritingOnOverflowingText)
      return
    }
    const ops = editor?.getContents().ops
    if (!ops) return
    const currentSize = getSize(ops)
    if (currentSize && currentSize === MIN_SIZE) {
      editor.root.addEventListener('keydown', preventWritingOnOverflowingText)
      editor.root.addEventListener('blur', () => {
        editor.root.removeEventListener('keydown', preventWritingOnOverflowingText)
      })
    } else {
      editor.root.removeEventListener('keydown', preventWritingOnOverflowingText)
      _fontSize.decreaseFontSize(block, currentSize)
    }
  }

  return { isOverflowing, reduceOverflowingTextSizeOnChange }
}
