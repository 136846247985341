import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { type block, getBlockPageId, getIndex } from '_entities/block'
import { useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash'
import { JSONOp } from 'ot-json1'
import { SourceKeys } from 'interfaces/editor'
import { getBlockInternalEmbed, getInternalEmbedBorderRadius } from '_entities/embed'
import { getById } from 'shared/lib'

interface IProps {
  block: block
}

export const useBorderRadiusEdit = (props: IProps) => {
  // ** State
  const [borderRadius, setBorderRadius] = useState<number | undefined>()

  // ** Hooks
  const _submit = useSubmit()
  const _json1 = useJson1()

  useEffect(() => {
    setBorderRadius(getInternalEmbedBorderRadius(props.block) || 0)
  }, [props.block])

  const onRadiusChange = (block: block, radius: number) => {
    setBorderRadius(radius)
    const element = getById(getBlockPageId(block))
    if (!element) return
    element.style.borderRadius = `${radius}px`
    debounceBackgroundColorChange({ block, radius })
  }

  const updateBorderRadius = (block: block, radius: number) => {
    const index = getIndex(block)
    if (index === -1) return
    let op: JSONOp
    if (getInternalEmbedBorderRadius(block) !== undefined) {
      op = _json1.getReplaceBlockDataKeyOp(index, ['internalEmbed', 'borderRadius'], radius)
    } else {
      if (!getBlockInternalEmbed(block)) {
        op = _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed'], {
          borderRadius: radius,
        })
      } else {
        op = _json1.getInsertKeyInDataKeyOp(index, ['internalEmbed', 'borderRadius'], radius)
      }
    }
    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  const debounceBackgroundColorChange = useMemo(
    () =>
      debounce((args: { block: block; radius: number }) => {
        updateBorderRadius(args.block, args.radius)
      }, 1000),
    [],
  )

  return {
    onRadiusChange,
    borderRadius,
  }
}
