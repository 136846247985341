import { StyledContainer } from './styles'
import Share from 'assets/images/share.svg'
import LinkBlack from 'assets/images/linkBlack.svg'
import FlexContainer from 'components/molecules/FlexContainer/FlexContainer'
import { useInviteByLink } from './useInviteByLink'
import CustomP from 'components/atoms/CustomP/CustomP'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { NewSelect } from 'components/molecules/NewSelect/NewSelect'
import CopyLinkButton from './CopyLinkButton/CopyLinkButton'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'

const InviteByLink = () => {
  const { defaultValue, linkSharingOptions } = useInviteByLink()

  return (
    <Wrapper className={'w-100'}>
      <CustomP
        marginRight='auto'
        color={'var(--primary-text)'}
        className={'mb-0'}
        paddingTop={'16px'}
      >
        Link sharing
      </CustomP>
      <FlexContainer className='w-100 m-0' alignItems='baseline' justifyContent='space-between'>
        <StyledContainer>
          <CustomIcon src={LinkBlack} alt='Link' padding={'0 8px 0 0'} />
          <CustomP className='mt-0' color='var(--primary-text)'>
            Anyone with the link
          </CustomP>
          <CustomIcon src={Share} alt='Share' padding={'0 8px 0 18px'} />
          {defaultValue && (
            <NewSelect
              options={linkSharingOptions}
              containerPositionInitial
              menuTransform={'translateY(20px)'}
              defaultValue={defaultValue}
            />
          )}
        </StyledContainer>
        <CopyLinkButton value={defaultValue} />
      </FlexContainer>
    </Wrapper>
  )
}

export default InviteByLink
