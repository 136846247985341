import CustomSelectDropdown from '../CustomSelectDropdown/CustomSelectDropdown'
import FlexContainer from '../FlexContainer/FlexContainer'
import { ListDiv } from '../PageMembersItem/styles'
import { ReactComponent as Avatar } from 'assets/images/avatar.svg'
import CustomP from 'components/atoms/CustomP/CustomP'
import { ReactComponent as PendingAvatar } from 'assets/images/pendingAvatar.svg'
import PendingDropdown from '../PendingDropdown/PendingDropdown'
import { useProjectMembersList } from './useProjectMembersList'
import { IRoles, IStatus } from 'interfaces/invite'
import { useAppSelector } from 'redux/hooks'
import { getColor } from 'shared/colors'

interface PageMembersList {
  firstName?: string
  lastName?: string
  ownerType?: string
  memberRoleType?: string
  email?: string
  status?: string
  optionsPending?: any[]
  memberId?: number
  pendingMemberUserId?: string
  inviteToken?: string
  projectRolesOwner?: any[]
  projectRoles?: any[]
}

export const ProjectMembersList = ({
  firstName,
  lastName,
  ownerType,
  memberRoleType,
  email,
  status,
  optionsPending,
  memberId,
  pendingMemberUserId,
  inviteToken,
  projectRolesOwner,
  projectRoles,
}: PageMembersList) => {
  const {
    updateMemberRole,
    deletePageMember,
    resendInvite,
    customDropdownWidth,
    customDropdownRight,
    customDropdownBottom,
    pendingDropdownWidth,
    pendingDropdownRight,
    pendingDropdownBottom,
    pendingDropdownOptionPadding,
    pendingDropdownRotateZ,
    myProjectFileRole,
  } = useProjectMembersList()
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const userRoles = useAppSelector((state) => state.projectFile.userRoles)
  return (
    <ListDiv>
      <FlexContainer>
        {status === 'pending' ? <PendingAvatar /> : <Avatar />}
        <CustomP className='my-auto' paddingLeft={'8px'} color={getColor('--primary-text')}>
          {status === 'pending' ? email : `${firstName} ${lastName}`}
        </CustomP>
      </FlexContainer>

      {memberRoleType === IRoles.OWNER && status !== 'pending' && (
        <CustomP color='#cccccc' className='my-auto'>
          Owner
        </CustomP>
      )}
      {memberRoleType !== IRoles.OWNER && status !== 'pending' && (
        <CustomSelectDropdown
          options={myProjectFileRole === IRoles.OWNER ? projectRolesOwner : userRoles}
          defaultSelect={memberRoleType}
          dropdownWidth={customDropdownWidth}
          dropdownRight={customDropdownRight}
          dropdownBottom={customDropdownBottom}
          onChange={(value: any) => {
            if (!project) return
            if (value == 'Remove access' && pendingMemberUserId && memberId) {
              deletePageMember(parseInt(project.id), memberId.toString())
            } else if (memberId)
              updateMemberRole(project.id.toString(), memberId.toString(), parseInt(value.value))
          }}
          dropdownIconOptionPadding={'8px'}
        />
      )}
      {status === IStatus.PENDING && (
        <PendingDropdown
          options={optionsPending}
          defaultSelect={'Pending'}
          dropdownOptionPadding={pendingDropdownOptionPadding}
          dropdownWidth={pendingDropdownWidth}
          dropdownRight={pendingDropdownRight}
          dropdownBottom={pendingDropdownBottom}
          rotatez={pendingDropdownRotateZ}
          onChange={(value: any) => {
            if (!project) return
            if (value == 'Remove access') console.warn('ENDPOINT not ready')
            else if (inviteToken) resendInvite(parseInt(project.id), inviteToken)
          }}
          memberId={memberId}
        />
      )}
    </ListDiv>
  )
}
