import styled, { css } from 'styled-components'
import { ReactComponent as EyeIconStyled } from 'assets/images/eyeProtect.svg'
import { ReactComponent as EyeClosedIconStyled } from 'assets/images/eye-wink.svg'
import { ReactComponent as EyeIconRedStyled } from 'assets/images/eyeRed.svg'
import { ReactComponent as checkGreenStyled } from 'assets/images/check-green.svg'

type EyeIconPropsType = {
  top?: string
  right?: string
}

type StyledInputContainerPropsType = {
  margin?: string
  isFlexWrap?: boolean
}

type InputWrapperProps = {
  inputInnerWidth?: string
}

export const StyledInputContainerCss = css<StyledInputContainerPropsType>`
  display: flex;
  align-items: ${(props) => (props.isFlexWrap ? 'flex-start' : 'center')};
  position: relative;
  margin: ${(props) => props.margin || ''};
  flex-direction: ${(props) => (props.isFlexWrap ? 'column' : 'row')};
  flex-wrap: ${(props) => (props.isFlexWrap ? 'wrap' : 'nowrap')};
  gap: 0.25rem;
  color: black;
  width: 100%;

  form :invalid {
    background: green !important;
  }

  & .placeholderDarker {
    border: 2px solid var(--gray3);
  }

  & .placeholderConfirm {
    border: 2px solid var(--everyday-green-dark);

    :focus {
      border: 2px solid var(--everyday-green-dark);
    }
  }

  & .remove-borders {
    border: none !important;
    border-radius: 10px !important;
    box-shadow: none !important;
  }

  & img {
    margin-left: 0.8rem !important;
    cursor: auto !important;
  }
`
export const StyledInputCss = css<{
  paddingLeft?: string
  borderRadius?: string
  borderColour?: string
  placeholderFontSize?: string
  textFontSize?: string
  width?: string
  padding?: string
  focusBorder?: string
}>`
  padding: ${(props) => props.padding || '8px'};
  padding-left: ${(props) => props.paddingLeft || ''};
  border-radius: ${(props) => props.borderRadius || '10px'};

  border-color: ${(props) => props.borderColour || ''};
  font-size: ${(props) => props.textFontSize};
  width: ${(props) => props.width || '100%'};

  :focus {
    box-shadow: none;
    border: ${(props) => props.focusBorder || '2px solid var(--everyday-blue)'};
  }

  &::placeholder {
    font-family: Helvetica, 'FontAwesome', sans-serif !important;
    color: var(--gray3) !important;
    font-size: ${(props) => props.placeholderFontSize};
  }

  &.placeholderDarker::placeholder {
    color: var(--gray2) !important;
  }

  & .placeholderConfirm::placeholder {
    color: var(--everyday-green-dark);
  }

  &.is-invalid {
    border-color: var(--everyday-red);
    box-shadow: none !important;
    background-color: var(--white);
    color: var(--gray2) !important;

    :focus {
      border: 2px solid var(--everyday-red);
    }
  }

  &#flexCheckDefault {
    border-color: var(--gray3);
  }
`

export const StyledLabelCss = css<{ inputType?: string; labelFontSize?: string }>`
  ${(props) => props.inputType === 'checkbox' && 'margin-left: 8px'};
  font-weight: 500;
  font-size: ${(props) => props.labelFontSize || ''};
  text-align: start;

  position: relative;
  top: 1px;

  & a {
    text-decoration: underline !important;
  }

  &#flexCheckDefault {
    color: var(--black);
  }
`

export const EyeIcon = styled(EyeIconStyled)<EyeIconPropsType>`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: ${(props) => props.top || '50%'};
  right: ${(props) => props.right || ''};
  transform: translateY(-50%);
`

export const EyeClosedIcon = styled(EyeClosedIconStyled)<EyeIconPropsType>`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: ${(props) => props.top || '50%'};
  right: ${(props) => props.right || ''};
  transform: translateY(-50%);
`

export const EyeIconRed = styled(EyeIconRedStyled)<EyeIconPropsType>`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: ${(props) => props.top || '50%'};
  right: ${(props) => props.right || ''};
  transform: translateY(-50%);
`

export const CheckGreenIcon = styled(checkGreenStyled)<EyeIconPropsType>`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 20px;
  top: ${(props) => props.top || '50%'};
  right: ${(props) => props.right || ''};
  transform: translateY(-50%);
`

export const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  width: ${(props) => props.inputInnerWidth || ''};
`
