import styled from 'styled-components'
import { Col } from 'react-bootstrap'

type SidebarSubtitleProps = {
  paddingBottom?: string
}

export const StyledRightSidebarCss = styled(Col)`
  border-top: 1px solid #cccccc;
  border-left: 1px solid #cccccc;
  overflow-x: auto;
`

export const SectionContainer = styled.div`
  margin-bottom: 28px;
  max-height: 30%;
  overflow-y: auto;

  &.bottom-light-border {
    padding-bottom: 16px;
  }

  &.position-section {
    margin-top: -16px !important;
  }
`
export const SidebarSubtitle = styled.div<SidebarSubtitleProps>`
  padding-bottom: ${(props) => props.paddingBottom || '1rem'};

  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 110%;
  color: var(--gray2);
  margin-top: 0.12rem;
`
export const PlanInfoContaier = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  display: flex;
  align-items: center;
  color: #403d3d;
  margin-bottom: 22px;

  &.position-plan-container {
    margin-bottom: 16px;
  }
`
export const UpgradeSpaceButton = styled.button`
  background: none;
  border: none;
  padding: 0.25rem 0rem;
  border-radius: 10px;
  text-align: start !important;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  text-align: center;
  color: #4a80ff;
  transition: all 0.2s ease-in-out;

  :hover {
    font-weight: 500;
    font-size: 14.2px;
    background-color: var(--gray4);
    padding: 0.25rem 0.5rem;
  }
`

export const SidebarTitle = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 120%;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  position: relative;
  top: -0.5rem;

  /* Main black */
  color: #403d3d;
`

export const MutedDescriptionText = styled.p`
  font-size: 13px;
  color: var(--gray2) !important;
  border-bottom: 3px solid var(--gray4);
  padding-bottom: 1.35rem;
  cursor: pointer;
`

export const StyledRightSidebarTitleContainerCss = styled.div`
  margin-top: 1.35rem;
  padding: 24px 24px 24px 8px;
`
export const StyledRightSidebarTitleCss = styled.h6`
  font-size: 14px;
  font-weight: bold;
`
