import CustomP from 'components/atoms/CustomP/CustomP'
import React from 'react'
import { icons } from 'shared/icons'
import { StyledCloseIcon, StyledCustomP, StyledHashIcon, TagInput } from './styles'
import { getColor } from 'shared/colors'
import { UseTagType } from '../model/useTag'
import { TagTypes } from 'interfaces/editor'
import TagOptionDropdown from './TagOptionDropdown'

interface IProps {
  data: {
    id?: string | number | null | undefined
    label: React.ReactNode
    value: string
  }
  _tags: UseTagType
}

const TagOption = (props: IProps) => {
  const isBlockTag = props._tags.projectTags?.find((option) => option.id === props.data.id)

  if (props.data.value === TagTypes.CREATE_INPUT) {
    return (
      <TagInput
        key={props.data.value}
        ref={props._tags.inputRef}
        onClick={(e) => {
          e.stopPropagation()
          props._tags.inputRef?.current?.focus()
        }}
        onKeyDown={(e) => {
          props._tags.createTag(props._tags.inputRef?.current?.value || '', e)
        }}
      />
    )
  }

  if (props._tags.isTagRename === props.data.id) {
    return (
      <TagInput
        key={props.data.value}
        ref={props._tags.inputRef}
        onClick={(e) => {
          e.stopPropagation()
          props._tags.inputRef?.current?.focus()
        }}
        onKeyDown={(e) => {
          props._tags.updateTagName(
            props.data.id as string,
            props._tags.inputRef?.current?.value || '',
            e,
          )
        }}
      />
    )
  }

  if (props.data.value === TagTypes.CREATE)
    return (
      <CustomP
        cursorPointer
        display={'flex'}
        alignItems={'center'}
        fontSize={'14px'}
        onClick={() => props._tags.handleOnTagCreation()}
        gap={'2px'}
        pointerEvents={props._tags.isTagCreation || props._tags.isTagRename ? 'none' : 'auto'}
        color={
          props._tags.isTagCreation || props._tags.isTagRename
            ? getColor('--gray-inactive')
            : getColor('--primary-text')
        }
      >
        <icons.plus />
        New tag
      </CustomP>
    )

  return (
    <StyledCustomP
      cursorPointer
      display={'flex'}
      alignItems={'center'}
      fontSize={'14px'}
      gap={'4px'}
      position={'relative'}
      isBlockTag={!!isBlockTag}
    >
      <StyledHashIcon />
      {isBlockTag && (
        <StyledCloseIcon
          onClick={(e) => {
            e.stopPropagation()
            props._tags.removeTag(props.data.id as string)
          }}
        />
      )}
      {props.data.label}
      <TagOptionDropdown data={props.data} _tags={props._tags} />
    </StyledCustomP>
  )
}

export default TagOption
