import FullScreenLoader from 'components/templates/FullScreenLoader/FullScreenLoader'
import { useUser } from 'services/user/useUser'
import { useInvites } from 'services/invites/useInvites'
const InvitationCheck = () => {
  const { checkUser } = useUser()
  const { invitationLink } = useInvites()

  if (invitationLink) window.location.href = invitationLink
  else checkUser(true)
  return <FullScreenLoader />
}

export default InvitationCheck
