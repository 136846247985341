import { IProjectFile, ISpace } from 'interfaces/space'
import { AxiosService } from 'services/axiosService/axiosService'
import { useToast } from 'services/helpers/useToast'
import MoveProjectToast from '_entities/space/ui/MoveProjectToast'

const useTransferProject = () => {
  const axiosService = new AxiosService()
  const toast = useToast()

  const handleCopyProjectToAnotherSpace = async (
    spaceId: string,
    projectId: string,
    selectedProject: IProjectFile,
    selectedSpace: ISpace,
    handleOnUndo: (projectId: number) => void,
    handleRedirect: (spaceId: string, projectId: string) => void,
  ) => {
    const response =
      spaceId &&
      projectId &&
      selectedProject &&
      (await axiosService.copyProjectToProjectSpace(
        spaceId,
        selectedProject.id.toString(),
        parseInt(selectedSpace.id),
      ))

    if (response) {
      const createdProjectId = response.data?.id

      toast.customToast(
        () =>
          MoveProjectToast({
            selectedProject: selectedProject,
            selectedSpace: selectedSpace,
            onUndo: () => handleOnUndo(createdProjectId),
            onRedirect: () => handleRedirect(selectedSpace?.id.toString(), createdProjectId),
          }),
        5000,
      )
    }
  }

  return {
    handleCopyProjectToAnotherSpace,
  }
}

export default useTransferProject
