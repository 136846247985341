import useLocalStorage, { LocalStorageKeys } from 'shared/lib/useLocalStorage'

export const useInvites = () => {
  const { getParsedItem, removeItem, setItem } = useLocalStorage()
  const url = window.location.href

  const invitationLink = getParsedItem(LocalStorageKeys.INVITATION_LINK)
  const dispatchInviteLink = () => {
    setItem(LocalStorageKeys.INVITATION_LINK, url)
  }

  const removeInviteLink = () => {
    removeItem(LocalStorageKeys.INVITATION_LINK)
  }

  return {
    invitationLink,
    dispatchInviteLink,
    removeInviteLink,
  }
}
