import { type block, getBlockPageId, getIndex } from '_entities/block'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { SourceKeys } from 'interfaces/editor'
import { getBlocksLength } from 'shared/shareDb'

const useBlockToolbar = () => {
  const _json1 = useJson1()
  const _submit = useSubmit()

  const sendToFront = (block: block) => {
    const pageId = getBlockPageId(block)
    const length = getBlocksLength(pageId)
    const index = getIndex(block)
    const op = _json1.moveBlock(index, length > 0 ? length - 1 : 0)
    _submit.submit(pageId, op, SourceKeys.UPDATE_BLOCK)
  }

  const sendToBack = (block: block) => {
    const pageId = getBlockPageId(block)
    const index = getIndex(block)
    const op = _json1.moveBlock(index, 0)
    _submit.submit(pageId, op, SourceKeys.UPDATE_BLOCK)
  }

  return {
    sendToFront,
    sendToBack,
  }
}

export default useBlockToolbar
