import { Placeholder } from './styles'
import { type block } from '_entities/block'
import { AnimatePresence } from 'framer-motion'
import { ITask } from 'interfaces/taskManager'

interface Props {
  width?: string
}

const BlocksDragPlaceholder = (props: Props) => {
  const { width } = props
  return (
    <AnimatePresence>
      <Placeholder
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        exit={{
          opacity: 1,
        }}
        transition={{ type: 'spring', stiffness: 100 }}
        width={width}
      />
    </AnimatePresence>
  )
}

export default BlocksDragPlaceholder
