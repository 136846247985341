import styled from 'styled-components'
import Button from 'components/atoms/Button/Button'

export const ListDiv = styled.div`
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const RequestButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const StyledActionText = styled.div``

export const StyledButton = styled(Button)<{ type: 'approve' | 'reject' }>`
  background-color: ${(props) =>
    props.type === 'approve' ? 'var(--primary-color)' : 'var(--everyday-red)'};
  color: var(--white);
  border: none;

  &:hover {
    background: var(--hover);
  }
`
