import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import {
  DocumentIcon,
  StyledItemNewPageIcon,
  StyledItemNewPageItem,
  WhiteboardIcon,
} from './styles'
import { EmbedType } from '_entities/embed'
import { icons } from 'shared/icons'
import React from 'react'
import { StyledLabel } from 'components/molecules/UploadImageDropdown/styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { SummarizationCategory } from 'services/ai/useAi'
import { useProjectSummarization } from '_entities/project'

interface Props {
  onSelectDoc: () => void
  onSelectWb: () => void
  setEmbedType: (type: EmbedType) => void
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const NewPageTypePicker = (props: Props) => {
  const _projectSummarization = useProjectSummarization()
  return (
    <>
      <StyledItemNewPageItem onClick={props.onSelectDoc}>
        <DropdownMenuItem align={'flex-start'}>
          <StyledItemNewPageIcon>
            <DocumentIcon />
          </StyledItemNewPageIcon>
          New Document
        </DropdownMenuItem>
      </StyledItemNewPageItem>

      <StyledItemNewPageItem onClick={props.onSelectWb}>
        <DropdownMenuItem align={'flex-start'}>
          <StyledItemNewPageIcon>
            <WhiteboardIcon />
          </StyledItemNewPageIcon>
          New Canvas
        </DropdownMenuItem>
      </StyledItemNewPageItem>
      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.setEmbedType(EmbedType.GOOGLE_DOC)
        }}
      >
        {<icons.googleDoc width={24} height={24} />} Google Docs
      </DropdownMenuItem>
      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.setEmbedType(EmbedType.GOOGLE_SLIDES)
        }}
      >
        {<icons.googleSlides width={24} height={24} />} Google Slides
      </DropdownMenuItem>
      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.setEmbedType(EmbedType.GOOGLE_SHEETS)
        }}
      >
        {<icons.googleSheets width={24} height={24} />} Google Sheets
      </DropdownMenuItem>
      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.setEmbedType(EmbedType.GOOGLE_CALENDAR)
        }}
      >
        {<icons.googleCalendar width={24} height={24} />} Google Calendar
      </DropdownMenuItem>
      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.setEmbedType(EmbedType.MIRO)
        }}
      >
        {<icons.miro width={24} height={24} />} Miro
      </DropdownMenuItem>
      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.setEmbedType(EmbedType.FIGMA)
        }}
      >
        {<icons.figma width={24} height={24} />} Figma
      </DropdownMenuItem>

      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.setEmbedType(EmbedType.YOUTUBE)
        }}
      >
        {<icons.youtube />} YouTube
      </DropdownMenuItem>

      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.setEmbedType(EmbedType.VIMEO)
        }}
      >
        {<icons.vimeo />} Vimeo
      </DropdownMenuItem>

      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.setEmbedType(EmbedType.GENERIC_LINK)
        }}
      >
        <icons.link />
        Generic link
      </DropdownMenuItem>

      <RadixDropdown
        trigger={
          <DropdownMenuItem>
            <icons.summarize width={20} height={20} />
            <CustomP marginBottom='0'>Summarize project</CustomP>
          </DropdownMenuItem>
        }
      >
        <DropdownMenuItem
          onClick={() => _projectSummarization.summarize(SummarizationCategory.LEGAL)}
        >
          <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
            {' '}
            <icons.legal width={20} height={20} /> Legal
          </Wrapper>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => _projectSummarization.summarize(SummarizationCategory.PRODUCT)}
        >
          <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
            {' '}
            <icons.product width={20} height={20} /> Product
          </Wrapper>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => _projectSummarization.summarize(SummarizationCategory.COPYWRITING)}
        >
          <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
            {' '}
            <icons.copywriting width={20} height={20} /> Copywriting
          </Wrapper>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => _projectSummarization.summarize(SummarizationCategory.FINANCE)}
        >
          <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
            {' '}
            <icons.finance width={20} height={20} /> Finance
          </Wrapper>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => _projectSummarization.summarize(SummarizationCategory.DEFAULT)}
        >
          <Wrapper display={'flex'} alignItems={'center'} gap={'4px'}>
            {' '}
            <icons.summarize width={20} height={20} /> Staffing
          </Wrapper>
        </DropdownMenuItem>
      </RadixDropdown>

      <DropdownMenuItem
        onSelect={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <StyledLabel htmlFor='fileInput'>
          {<icons.upload width={24} height={24} />} Upload
        </StyledLabel>
        <input type='file' id='fileInput' onChange={props.handleFileUpload} hidden />
      </DropdownMenuItem>
    </>
  )
}

export default NewPageTypePicker
