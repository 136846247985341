import { SelectedObjectImage, SelectedObjectWrapper } from '../styles'
import { PageName } from '_entities/page'
import { type block } from '_entities/block'
import { IPage } from 'interfaces/page'

interface IProps {
  handleOnClickSubject?: () => void
  referencedBlock: block | null
  referencedPage: IPage | null
}

export const SelectedImageBlock = ({
  handleOnClickSubject,
  referencedBlock,
  referencedPage,
}: IProps) => {
  return (
    <SelectedObjectWrapper onClick={handleOnClickSubject} className='selected-object-wrapper'>
      <SelectedObjectImage src={referencedBlock?.data.imageUrl} />
      <PageName page={referencedPage} />
    </SelectedObjectWrapper>
  )
}

export default SelectedImageBlock
