import FilterByDropdown from './FilterByDropdown/FilterByDropdown'
import {
  StyledSearchAndOptionsBar,
  StyledTaskSliderFiltersContainer,
  MagnifierIcon,
  InputBoxInput,
  InputBoxContainer,
} from './styles'
import TaskManagerBoard from 'components/organisms/TaskManagerBoard/TaskManagerBoard'
import TaskManagerList from '../TaskManagerList/TaskManagerList'
import { useTaskManagerView } from './useTaskManagerView'
import { ILayoutsEnum } from 'interfaces/taskManager'
import { useState } from 'react'
import { CommonProps } from '_features/tasks'
import Wrapper from 'components/molecules/Wrapper/Wrapper'

const TaskManagerView = (props: CommonProps) => {
  const { selectedOption } = useTaskManagerView()

  const [search, setSearch] = useState('')
  const [showSliderFilters] = useState<boolean>(false)

  return (
    <>
      <StyledSearchAndOptionsBar>
        {/* Search Input */}
        <InputBoxContainer>
          <MagnifierIcon />
          <InputBoxInput
            type='text'
            placeholder='Search'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </InputBoxContainer>

        <Wrapper display='flex'>
          {/* Selected filters */}
          {!props.isSlider &&
            selectedOption?.filters.map((filter) => {
              return <FilterByDropdown key={filter.id} propertyDefinition={filter} />
            })}
        </Wrapper>
      </StyledSearchAndOptionsBar>

      <StyledTaskSliderFiltersContainer>
        {props.isSlider &&
          showSliderFilters &&
          selectedOption?.filters.map((filter) => {
            return <FilterByDropdown key={filter.id} propertyDefinition={filter} />
          })}
      </StyledTaskSliderFiltersContainer>

      {props.type === ILayoutsEnum.BOARD && <TaskManagerBoard {...props} {...{ search }} />}
      {props.type === ILayoutsEnum.LIST && <TaskManagerList {...props} {...{ search }} />}
    </>
  )
}

export default TaskManagerView
