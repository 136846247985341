import styled from 'styled-components'
import { useEmbed } from '_entities/embed'

export const StyledEditorPageBlockWrapper = styled.div<{
  isChatEmbed?: boolean
  isFullScreen?: boolean
  isEmbeddedDocumentExpanded?: boolean
}>`
  width: ${(props) => (!props.isChatEmbed ? '100%' : 'calc(100% - 26px)')};
  height: 100%;
  background-color: 'transparent';
  margin: ${(props) =>
    !props.isEmbeddedDocumentExpanded && props.isChatEmbed
      ? '0 0 0 24px'
      : !props.isChatEmbed
      ? '0'
      : '24px 0 24px 26px'};
  border-radius: 10px;
`

export const StyledPageWrapper = styled.div<{
  width?: number
  height?: number
  isDocumentEmbed?: boolean
  isWhiteboardEmbed?: boolean
  borderColor?: string
  borderWeight?: number
  marginLeft?: string
  borderRadius?: number
}>`
  transition: width, margin-left, border-radius 0.2s ease-in-out;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  height: ${(props) => (props.height ? `${props.height}px` : '100%')};
  border-width: ${(props) => (props.borderWeight ? `${props.borderWeight}px` : '0px')};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : '0')};
  border-style: solid;
  border-color: ${(props) => (props.borderColor ? props.borderColor : 'transparent')};
  border-radius: ${(props) =>
    props.borderRadius !== undefined ? `${props.borderRadius}px` : '10px'};
  position: relative;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  aspect-ratio: ${(props) =>
    props.isDocumentEmbed
      ? `${useEmbed().getDocumentEmbedDefaultSize().width}/${
          useEmbed().getDocumentEmbedDefaultSize().height
        }`
      : `${useEmbed().getWhiteboardEmbedDefaultSize().width}/${
          useEmbed().getWhiteboardEmbedDefaultSize().height
        }`};

  svg path {
    color: var(--text);
    stroke: var(--text);
  }

  :hover {
    & .page-preview-options {
      opacity: 1;
      height: 40px;
      padding: 8px 14px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 5px;
    cursor: ns-resize;
    background: transparent;
  }
`

export const PageNameWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 16px;
  font-weight: 600;
  color: var(--primary-text);
  cursor: pointer;

  svg path {
    stroke: var(--primary-text);
  }
`

export const PagePreviewOptionsWrapper = styled.div<{ isWhiteboardEmbed?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 14px;
  opacity: 0;
  height: 0;
  transition: all 0.2s ease-in-out;
  background-color: var(--white);
  z-index: 1;
`

export const PagePreviewActions = styled.div<{
  isFigma?: boolean
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  position: ${(props) => (props.isFigma ? 'absolute' : 'relative')};
  right: ${(props) => (props.isFigma ? '36px' : 'auto')};
  bottom: ${(props) => (props.isFigma ? '24px' : 'auto')};
`

export const SelectPageDropdown = styled.div`
  width: 100%;
  text-align: left;
  align-items: flex-start;
  display: flex;
`

export const StyledInput = styled.input`
  width: 52px;
  height: 24px;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid var(--gray-inactive);

  &:focus {
    border: 1px solid var(--everyday-blue);
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`
