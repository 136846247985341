import {
  IconWrapper,
  IconLine,
  StyledToolbar,
  StyledHandIcon,
} from '_entities/whiteboard/ui/styles'

import { ReactComponent as Cursor } from 'assets/images/whiteboard/cursor.svg'
import { ReactComponent as TextIcon } from 'assets/images/whiteboard/text.svg'
import { ShapeSelector } from './ShapeSelector/ShapeSelector'
import { FrameSelector } from './FrameSelector/FrameSelector'
import { ITools } from 'interfaces/whiteboard'
import EmbedDropdown from 'components/organisms/Dropdowns/EmbedDropdown/EmbedDropdown'
import { icons } from 'shared/icons'
import { WhiteboardToolbarFramer } from 'utils/framerConfig'
import { usePresentation } from '_features/canvas'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { setTool } from 'redux/reducers/whiteboardReducer'
import { EmojiSelector } from './EmojiSelector/EmojiSelector'
import { getToolbarId } from '../model/useToolbar'
import { useEffect, useState } from 'react'
import { usePage } from '_entities/page'
import { useInternalEmbedBlock } from '_entities/embed'
import { docSubscribe, getBlocks, initializeDocListener, checkPageRole } from 'shared/shareDb'
import { IPermissions } from '_entities/user'

export const IconButtonSize = 26

export const Toolbar = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const dispatch = useAppDispatch()
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const page = useAppSelector((state) => state.page.selectedPage)
  const _page = usePage()
  const _presentation = usePresentation()
  const _internalEmbedBlock = useInternalEmbedBlock()

  const disableIfNoExpandedCanvasInDoc = async () => {
    if (!_page.isSelectedPageDoc()) {
      setIsDisabled(false)
      return
    }
    if (!page) return
    let blocks = getBlocks(page.id)
    if (!blocks) await docSubscribe(page.id)
    blocks = getBlocks(page.id)
    if (!blocks) return
    _internalEmbedBlock.findIfSomeInternalCanvasIsExpanded(blocks)
      ? setIsDisabled(false)
      : setIsDisabled(true)
  }

  useEffect(() => {
    if (!page) return
    checkPageRole(page.id, (role) => {
      if (role === IPermissions.CAN_COMMENT || role === IPermissions.CAN_VIEW) {
        setIsDisabled(true)
      } else {
        disableIfNoExpandedCanvasInDoc()
        initializeDocListener(page.id, () => {
          disableIfNoExpandedCanvasInDoc()
        })
      }
    })
  }, [page])

  return (
    <StyledToolbar {...WhiteboardToolbarFramer(isDisabled ? 0.3 : 1)} id={getToolbarId()}>
      <IconWrapper
        disabled={isDisabled}
        selected={tool === ITools.CURSOR}
        onClick={() => {
          if (isDisabled) return
          dispatch(setTool(ITools.CURSOR))
        }}
      >
        <Cursor />
      </IconWrapper>
      <IconWrapper
        disabled={isDisabled}
        selected={tool === ITools.HAND}
        onClick={() => {
          if (isDisabled) return
          dispatch(setTool(ITools.HAND))
        }}
      >
        <StyledHandIcon />
      </IconWrapper>
      <IconLine />
      <IconWrapper
        disabled={isDisabled}
        selected={tool === ITools.TEXT}
        onClick={() => {
          if (isDisabled) return
          dispatch(setTool(ITools.TEXT))
        }}
      >
        <TextIcon />
      </IconWrapper>
      <ShapeSelector isDisabled={isDisabled} />
      <IconWrapper
        disabled={isDisabled}
        selected={tool === ITools.STICKY}
        onClick={() => {
          if (isDisabled) return
          dispatch(setTool(ITools.STICKY))
        }}
      >
        <icons.stickyNote />
      </IconWrapper>

      <EmojiSelector isDisabled={isDisabled} />

      <EmbedDropdown isDisabled={isDisabled} />

      <FrameSelector isDisabled={isDisabled} />
      <IconLine />
      <IconWrapper
        disabled={isDisabled}
        selected={false}
        onClick={() => {
          if (isDisabled) return
          _presentation.checkSelectedBlockAndStartPresentation()
        }}
      >
        <icons.play />
      </IconWrapper>
    </StyledToolbar>
  )
}
