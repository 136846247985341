import { IDropdownOption } from 'interfaces/invite'
import React from 'react'
import { DropDownContainer, DropDownHeader } from '../CustomSelectDropdown/styles'
import { DropdownActionList } from '../DropdownActionList/DropdownActionList'
import { usePendingDropdown } from './usePendingDropdown'
import ArrowDown from 'assets/images/keyboard_arrow_down.svg'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'

interface Props {
  width?: string
  fontsize?: string
  options?: any[]
  margin?: string
  padding?: string
  height?: string
  dropdownPadding?: string
  dropdownOptionPadding?: string
  paddingFirstChild?: string
  paddingLastChild?: string
  onChange?: (value: IDropdownOption) => void
  defaultSelect?: string
  dropdownWidth?: string
  dropdownLeft?: string
  dropdownTop?: string
  dropdownRight?: string
  dropdownBottom?: string
  translateY?: string
  rotatez?: string
  memberId?: number
}

const PendingDropdown = ({
  width,
  fontsize,
  options,
  margin,
  padding,
  height,
  dropdownPadding,
  dropdownOptionPadding,
  paddingFirstChild,
  paddingLastChild,
  onChange,
  defaultSelect,
  dropdownWidth,
  dropdownLeft,
  dropdownTop,
  dropdownRight,
  dropdownBottom,
  translateY,
  rotatez,
  memberId,
}: Props) => {
  const { isOpen, selectedOption, toggle, onOptionClicked } = usePendingDropdown({ onChange })
  return (
    <DropDownContainer
      width={width}
      margin={margin}
      padding={padding}
      height={height}
      onScroll={toggle}
    >
      <DropDownHeader onClick={toggle} isOpen={isOpen} pending={defaultSelect === 'Pending'}>
        {defaultSelect}
        <CustomIcon
          src={ArrowDown}
          rotatez={rotatez ? rotatez : '180deg'}
          isOpen={isOpen}
          margin={'0 0 0 8px'}
        />
      </DropDownHeader>
      <DropdownActionList
        fontsize={fontsize}
        options={options}
        isOpen={isOpen}
        onClickFunction={onOptionClicked}
        selectedOption={selectedOption}
        dropdownPadding={dropdownPadding}
        dropdownOptionPadding={dropdownOptionPadding}
        paddingFirstChild={paddingFirstChild}
        paddingLastChild={paddingLastChild}
        dropdownTop={dropdownTop}
        dropdownLeft={dropdownLeft}
        width={dropdownWidth}
        dropdownRight={dropdownRight}
        dropdownBottom={dropdownBottom}
        translateY={translateY}
        memberId={memberId}
      />
    </DropDownContainer>
  )
}

export default PendingDropdown
