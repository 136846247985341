import { useEffect, useMemo, useState } from 'react'
import { IPalette, IPaletteColor, PaletteAttributes } from 'interfaces/settings'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { AxiosService } from 'services/axiosService/axiosService'
import { setColorPalette } from 'redux/reducers/globalReducer'
import { debounce } from 'lodash'
import { useToast } from 'services/helpers/useToast'

interface IUsePalette {
  palette: IPalette | null
  fetchPalettes: () => void
  setDefaultPalette: () => void
}

const usePalette = ({ palette, fetchPalettes, setDefaultPalette }: IUsePalette) => {
  const [colorToChange, setColorToChange] = useState<IPaletteColor | null>(null)
  const [pickedColor, setPickedColor] = useState<any | null>(null)
  const [isEdit, setIsEdit] = useState<string | null>(null)
  const [renameInputPosition, setRenameInputPosition] = useState<number>(0)
  const [loading, setIsLoading] = useState<string | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [userPalette, setUserPalette] = useState<IPalette | null>(palette ? palette : null)
  const palettes = useAppSelector((state) => state.global.colorPalettes)
  const colorPalette = useAppSelector((state) => state.global.colorPalette)
  const user = useAppSelector((state) => state.global.user)

  const dispatch = useAppDispatch()
  const AxiosInstance = new AxiosService()
  const toast = useToast()

  useEffect(() => {
    if (palette) {
      setUserPalette(palette)
    }
  }, [palette])

  const handleDuplicatePalette = async (palette: IPalette) => {
    setIsLoading(palette.id)
    const response =
      palettes &&
      user &&
      (await AxiosInstance.createColorPalette(
        user.id,
        palette.attributes.name,
        parseInt(palette.id),
      ))
    if (response) {
      fetchPalettes()
    }
    setIsLoading(null)
  }

  const handleRenamePalette = async (name: string, palette: IPalette | null) => {
    if (palette === null) return
    setIsLoading(palette.id)
    const response = user && (await AxiosInstance.updateColorPalette(palette.id, name, user.id))
    if (response) {
      fetchPalettes()
    }
    setIsLoading(null)
  }

  const deletePalette = async (palette: IPalette) => {
    setIsLoading(palette.id)
    const response = user && (await AxiosInstance.deleteColorPalette(palette.id, user.id))
    if (response) {
      fetchPalettes()
      setDefaultPalette()
    }
    setIsLoading(null)
  }

  const handleEditPalette = (palette: IPalette) => {
    setIsEdit(palette.id)
    setRenameInputPosition(1)
    setTimeout(() => {
      ;(document.querySelector('.rename-input') as HTMLInputElement)?.select()
    }, 100)
  }

  const handleCreateNewPalette = async () => {
    setIsLoading('new')
    const response =
      palettes &&
      user &&
      (await AxiosInstance.createColorPalette(user.id, 'My New Palette', parseInt(palettes[0].id)))
    if (response) {
      fetchPalettes()
    }
    setIsLoading(null)
  }

  const handleSelectColorToChange = (e: any, color: IPaletteColor) => {
    e.stopPropagation()
    e.preventDefault()
    setIsOpen(true)
    setPickedColor(null)
    setColorToChange(color)
  }

  const handleChange = (
    color: any,
    event: React.ChangeEvent<HTMLInputElement>,
    colorToChange: IPaletteColor,
  ) => {
    setPickedColor(color)

    const indexOfColorToUpdate = userPalette?.attributes.colors?.findIndex(
      (paletteColor) => paletteColor.id === colorToChange.id,
    )

    const updatedColor = {
      id: colorToChange?.id as number,
      color: color.hex.replace('#', '').toUpperCase() as string,
      opacity: color.rgb.a as number,
      type: colorToChange?.type as string,
    }

    const updatedColors = userPalette ? [...userPalette.attributes.colors] : []
    updatedColors[indexOfColorToUpdate as number] = updatedColor

    const colorPaletteChanged = userPalette && {
      id: userPalette?.id,
      type: 'colorPalette',
      attributes: {
        name: userPalette?.attributes.name,
        createdBy: {
          id: userPalette?.attributes.createdBy?.id as number,
        },
        colors: updatedColors as PaletteAttributes['colors'],
      },
    }

    setUserPalette(colorPaletteChanged)

    dispatch(setColorPalette(colorPaletteChanged))

    debounceChange({ color, colorToChange, opacity: color.rgb.a })
  }

  const updateColorPreset = async (color: any, colorToChange: IPaletteColor, opacity: number) => {
    user &&
      (await AxiosInstance.updateColorPaletteColor(
        colorToChange.id.toString(),
        color.hex,
        opacity,
        user?.id,
      ))
  }

  const debounceChange = useMemo(
    () =>
      debounce((args: { color: any; colorToChange: IPaletteColor; opacity: number }) => {
        updateColorPreset(args.color, args.colorToChange, args.opacity)
      }, 1000),
    [],
  )
  const closeColorPicker = () => {
    setIsOpen(false)
  }

  const convertToTitleCase = (inputString: string) => {
    const words = inputString.split('-')
    const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    return capitalizedWords.join(' ')
  }

  const handleRenameColorPalette = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const valueBeforeCurrentKey = (event.target as HTMLInputElement).value
    if (event.key === 'Enter') {
      setRenameInputPosition(0)
      handleRenamePalette(valueBeforeCurrentKey, userPalette)
    } else if (event.key === 'Escape') {
      setRenameInputPosition(0)
    }
  }

  const handleShowError = (error: string, e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    e.stopPropagation()
    toast.error(`${error}`)
  }

  return {
    colorToChange,
    palette,
    pickedColor,
    palettes,
    handleSelectColorToChange,
    handleChange,
    closeColorPicker,
    handleCreateNewPalette,
    handleDuplicatePalette,
    handleRenamePalette,
    deletePalette,
    handleEditPalette,
    isEdit,
    renameInputPosition,
    setRenameInputPosition,
    loading,
    isOpen,
    setIsOpen,
    debounceChange,
    colorPalette,
    convertToTitleCase,
    userPalette,
    handleRenameColorPalette,
    handleShowError,
  }
}

export default usePalette
