import { ILineItem, ITools } from 'interfaces/whiteboard'
import { KonvaEventObject } from 'konva/lib/Node'
import { useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import { useStage } from '_features/canvas/stage'
import { useEvent } from '_features/canvas/event'

export const useDrawing = () => {
  const [line, setLine] = useState<ILineItem | null>(null)
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const _stage = useStage()
  const _event = useEvent()

  const penMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    if (tool === ITools.PEN) {
      const pageId = _event.getPageIdFromEvent(e)
      const scaledPointerPosition = _stage.getScaledPointerPosition(pageId)
      if (!scaledPointerPosition) return
      const points = [
        Math.trunc(scaledPointerPosition.x) ?? 0,
        Math.trunc(scaledPointerPosition.y) ?? 0,
      ]
      setLine({
        points,
        x: 0,
        y: 0,
      })
    }
  }

  const penMouseMove = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const pageId = _event.getPageIdFromEvent(e)
    const scaledPoints = _stage.getScaledPointerPosition(pageId)
    if (!line || !scaledPoints) return
    const oneLine = {
      ...line,
    }
    oneLine.points = oneLine.points?.concat([
      Math.trunc(scaledPoints?.x) ?? 0,
      Math.trunc(scaledPoints?.y) ?? 0,
    ])
    setLine(oneLine)
  }
  return {
    line,
    setLine,
    penMouseDown,
    penMouseMove,
  }
}
