import FlexContainer from '../FlexContainer/FlexContainer'
import { ListDiv } from './styles'
import { ReactComponent as Avatar } from 'assets/images/avatar.svg'
import { ReactComponent as CheckMark } from 'assets/images/check.svg'
import CustomP from 'components/atoms/CustomP/CustomP'
import { IPage, IPageMember } from 'interfaces/page'
import { useAppSelector } from 'redux/hooks'
import { useToast } from 'services/helpers/useToast'
import { AxiosService } from 'services/axiosService/axiosService'
import RadixDropdown, {
  IconButton,
  DropdownMenuItem,
} from '../Dropdown/RadixDropdown/RadixDropdown'
import { ReactComponent as CaretDownIcon } from 'assets/images/CaretDown.svg'

interface Props {
  member: IPageMember
  page: IPage
}
const PageMembersItem = (props: Props) => {
  const toast = useToast()
  const AxiosInstance = new AxiosService()
  const pageRoles = useAppSelector((state) => state.page.pageRoles)
  const updateMember = async (memberId: string, pageId: string, roleId: number) => {
    const response = await AxiosInstance.updatePageMember(memberId, pageId, roleId)
    if (response) toast.success('Successfully updated page member role!')
  }

  return (
    <ListDiv>
      <FlexContainer>
        <Avatar />
        <CustomP className='my-auto' paddingLeft={'8px'} color='var(--primary-text)'>
          {`${props.member.user.firstName} ${props.member.user.lastName}`}
        </CustomP>
      </FlexContainer>
      <RadixDropdown
        trigger={
          <IconButton>
            {props.member.role.type} <CaretDownIcon />
          </IconButton>
        }
      >
        {pageRoles?.map((role) => (
          <DropdownMenuItem
            onSelect={() => {
              updateMember(props.member.id, props.page.id, parseInt(role.value as string))
            }}
            key={`page-member-${props.member.id}-role-setting-${role.name}`}
          >
            {role.name === props.member.role.type && <CheckMark />} {role.name}
          </DropdownMenuItem>
        ))}
      </RadixDropdown>
    </ListDiv>
  )
}

export default PageMembersItem
