import styled, { css } from 'styled-components'
import { ReactComponent as PageTypeIcon } from 'assets/images/arrowDownTopbarViews.svg'

export const StyledSidebarNavigationCss = css`
  width: 100%;
  height: fit-content;
  display: grid;
  gap: 4px;
  overflow-y: auto;
`

export const IconAndTitleWrapper = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  gap: 8px;
  color: ${(props) => (props.color ? props.color : '')};

  &:hover {
    color: black;
  }
`

export const StyledArrowIcon = styled(PageTypeIcon)<{ isopened: number }>`
  ${(props) => (!props.isopened ? 'transform: rotate(-90deg)' : '')};
`
