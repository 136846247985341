import styled from 'styled-components'

export const PageContainer = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100vh !important;
`

export const StyledShareText = styled.p`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  display: inline-block;
  margin-bottom: 0;
  color: var(--white);
`

export const FullPageImage = styled.img`
  width: auto;
  height: 75%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 64px;
`

export const StyledVideo = styled.video`
  width: auto;
  height: 75%;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 64px;
`

export const StyledVimeo = styled.iframe`
  width: 100%;
  height: 100%;
  margin-top: 64px;
`

export const VideoWrapper = styled.div`
  width: 624px;
  aspect-ratio: 16 / 9;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 64px;
`

export const StyledYoutube = styled.iframe`
  width: 100%;
  height: 100%;
  margin-top: 64px;
`

export const EmptyPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledDropdownButton = styled.button`
  outline: none;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`
