import { css } from 'styled-components'
import { ITab } from 'interfaces/tabs'

export const StyledTabCss = css<{ selectedTab: ITab; text: string; tabFontSize?: string }>`
  font-size: ${(props) => props.tabFontSize || '11.5pt'};

  color: ${(props) => {
    return (!props.selectedTab.text.includes('Members') ? props.selectedTab.text : 'Members') ===
      (!props.text.includes('Members') ? props.text : 'Members')
      ? '3px solid var(--primary-color) !important'
      : 'var(--gray2) !important'
  }};
  font-weight: ${(props) => {
    return (!props.selectedTab.text.includes('Members') ? props.selectedTab.text : 'Members') ===
      (!props.text.includes('Members') ? props.text : 'Members')
      ? '600'
      : '500'
  }};
  padding: 12px 4px;
  border-bottom: ${(props) => {
    return (!props.selectedTab.text.includes('Members') ? props.selectedTab.text : 'Members') ===
      (!props.text.includes('Members') ? props.text : 'Members')
      ? '3px solid var(--primary-color)'
      : 'none'
  }};
  cursor: pointer;

  &:first-child {
    position: relative;
    top: 0.05rem;
    border-bottom: ${(props) => {
      return (!props.selectedTab.text.includes('Members') ? props.selectedTab.text : 'Members') ===
        (!props.text.includes('Members') ? props.text : 'Members')
        ? '3px solid var(--primary-color)'
        : 'none'
    }};
  }

  &:hover {
    color: gray;
  }
`
