import { css } from 'styled-components'

export const StyledAuthWrapperComponent = css`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: scale(0.9);
  margin-block: auto;

  .addEverydayBlue {
    color: var(--primary-color) !important;
    font-weight: 500 !important;
  }
`

export const StyledRow = css`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const StyledP = css`
  font-size: 14px;
  font-weight: 400;
  color: #000000;
  padding-left: 17px;
`
