import styled, { css } from 'styled-components'
import { icons } from './icons'
export const StyledImageIcon = styled.img`
  width: 16px;
  height: 16px;
`

export const StyledIcon = styled.div<{ size?: number }>`
  ${(props) => css`
    width: ${props.size || 20}px;
    height: ${props.size || 20}px;
  `}
  svg {
    width: 100%;
    height: 100%;
  }
`
export const DocumentIcon = styled(icons.document)`
  width: 12px;
  transform: scale(1.2);
  z-index: 1 !important;
`

export const SlidesIcon = styled(icons.slideshow)`
  width: 12px;
  transform: scale(1.2);
`

export const WhiteboardIcon = styled(icons.whiteboard)`
  width: 12px;
  transform: scale(1.2);
`

export const KanbanIcon = styled(icons.taskManager)`
  width: 12px;
  transform: scale(1.2);
`

export const FigmaIcon = styled(icons.figma)`
  width: 12px;
  transform: scale(1.2);
`

export const StyledIconWrapper = styled.div``
