import { landingPageApi, TAGS } from 'shared/api'
import { GetLandingPageRequestType, GetLandingPageResponseType } from '../model/types'

export const landingPage = landingPageApi.injectEndpoints({
  endpoints: (build) => ({
    getLandingPage: build.query<GetLandingPageResponseType, GetLandingPageRequestType>({
      query: ({ slug }) => `/${slug}`,
      providesTags: [TAGS.LANDING_PAGE],
    }),
  }),
})

export const { useGetLandingPageQuery } = landingPage
