import { useState } from 'react'
import { SharingButton } from './styles'
import CustomIcon from 'components/atoms/CustomIcon/CustomIcon'
import LinkBlue from 'assets/images/link.svg'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import copy from 'copy-to-clipboard'
import { PageLinkSharingEnum, publicLinkNameMapper } from 'interfaces/invite'

interface Props {
  value?: string
}
const toolTipMessage = 'Link copied!'

const CopyLinkButton = (props: Props) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false)

  const copyLink = () => {
    copy(window.location.href), handleTooltip()
  }

  const handleTooltip = () => {
    setShowTooltip(true)
    setTimeout(() => {
      setShowTooltip(false)
    }, 2000)
  }
  return (
    <SharingButton
      onClick={copyLink}
      className='border-0 align-self-end mt-auto position-relative'
      show={props.value === publicLinkNameMapper[PageLinkSharingEnum.NO_ACCESS] ? false : true}
    >
      <CustomIcon src={LinkBlue} alt={'Link'} margin={'0 9px 0 0'} />
      Copy Link
      <Tooltip
        className='mb-0 p-1'
        message={toolTipMessage}
        active={showTooltip}
        justify={'center'}
        alignitems={'center'}
      />
    </SharingButton>
  )
}

export default CopyLinkButton
