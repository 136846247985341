// ** Type Imports
import { TAGS } from 'shared/api'
import {
  GetManagerOptionsRequestType,
  GetManagerOptionsResponseType,
  UpdateViewRequestData,
} from '../model'
import { managerApi } from 'shared/api/managerApi'
import { IOption, IPropertyDefinition } from 'interfaces/taskManager'

export const manager = managerApi.injectEndpoints({
  endpoints: (build) => ({
    getManagerOptions: build.query<GetManagerOptionsResponseType, GetManagerOptionsRequestType>({
      query: ({ managerId }) => `${managerId}/options`,
      providesTags: [TAGS.TASK],
    }),
    getManagerPropertyDefinitions: build.query<
      { data: IPropertyDefinition[] },
      { managerId: number }
    >({
      query: ({ managerId }) => `${managerId}/property-definitions`,
      providesTags: [TAGS.TASK],
    }),
    createManagerOption: build.mutation<
      IOption[],
      {
        managerId: number
        name: string
        layout: string
      }
    >({
      query: ({ managerId, name, layout }) => ({
        url: `${managerId}/options`,
        method: 'POST',
        body: {
          name: name,
          layout: layout,
        },
      }),
      invalidatesTags: [TAGS.TASK],
    }),
    deleteManagerOption: build.mutation<
      IOption,
      {
        managerId: number
        optionId: number
      }
    >({
      query: ({ managerId, optionId }) => ({
        url: `${managerId}/options/${optionId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS.TASK],
    }),
    updateManagerOption: build.mutation<IOption, UpdateViewRequestData>({
      query: ({ managerId, data }) => ({
        url: `${managerId}/options/${data.id}`,
        method: 'PATCH',
        body: {
          data,
        },
      }),
      invalidatesTags: [TAGS.TASK],
    }),
  }),
})
