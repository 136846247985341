import { ChatFilterContainer } from './styles'
import useChatFilter from './useChatFilter'
import { ChannelOption } from 'redux/reducers/chatReducer'
import CustomThreadHeader from '../Chat/ThreadHeader/ThreadHeader'
import Checkbox from 'components/atoms/Checkbox/Checkbox'
import CustomP from 'components/atoms/CustomP/CustomP'

const ChatFilter = () => {
  const {
    selectedFilter,
    handleFilterSelection,
    selectedPage,
    isMessageThreadOpen,
    handleCloseBlockChat,
    messagesWithBlock,
    selectedMessages,
  } = useChatFilter()

  if (isMessageThreadOpen || messagesWithBlock !== null) {
    return (
      <ChatFilterContainer
        backgroundColor={'var(--tasks-background)'}
        border={'solid 1px var(--secondary-text)'}
      >
        <CustomThreadHeader closeThread={handleCloseBlockChat} />
      </ChatFilterContainer>
    )
  }

  return (
    <ChatFilterContainer backgroundColor={'var(--tasks-background)'}>
      <Checkbox
        onChange={() => handleFilterSelection(selectedMessages as ChannelOption)}
        checked={selectedFilter?.replace('messaging:', '') === selectedPage?.id}
      />{' '}
      <CustomP
        marginBottom='2px'
        onClick={() => handleFilterSelection(selectedMessages as ChannelOption)}
        customCss='cursor: pointer'
        color='var(--primary-text)'
      >
        Show only comments from&nbsp;<b>{selectedPage?.title}</b>
      </CustomP>
    </ChatFilterContainer>
  )
}

export default ChatFilter
