import { useAppSelector } from 'redux/hooks'
import { ctaEndpoints } from '../api'
import { ClickAction } from '_entities/cta'
import { getBlockPageId, getIndex, type block } from '_entities/block'
import { JSONOp } from 'ot-json1'
import { SourceKeys } from 'interfaces/editor'
import { useJson1 } from 'shared/shareDb'
import { useSubmit } from 'utils/shareDB/useSubmit'

export const useUpdateCtaButton = () => {
  // ** Redux state
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  // ** Mutations methods
  const [handleUpdateCtaButton] = ctaEndpoints.endpoints.updateCta.useMutation()

  // ** Hooks
  const _json1 = useJson1()
  const _submit = useSubmit()

  const updateCtaButton = async ({
    id,
    title,
    onClickAction,
    link,
    styling,
  }: {
    id: number
    title?: string
    onClickAction?: ClickAction
    link?: string
    styling?: {
      font?: string
      fontSize?: number
      textColor?: string
      buttonColor?: string
      buttonHeight?: number
      borderRadius?: number
      borderColor?: string
    }
  }) => {
    if (!projectFile) return

    return await handleUpdateCtaButton({
      projectFileId: projectFile.id,
      id,
      title,
      onClickAction,
      link,
      styling,
    }).unwrap()
  }

  const handleAlign = (block: block, align: string) => {
    const index = getIndex(block)

    let op: JSONOp

    if (block.data.cta?.align) {
      op = _json1.getReplaceBlockDataKeyOp(index, ['cta', 'align'], align)
    } else {
      op = _json1.getInsertKeyInDataKeyOp(index, ['cta', 'align'], align)
    }

    _submit.submit(getBlockPageId(block), op, SourceKeys.UPDATE_BLOCK)
  }

  return {
    updateCtaButton,
    handleAlign,
  }
}
