import styled from 'styled-components'
import GoogleLogo from 'assets/images/googleLogo.svg'
import MicrosoftLogo from 'assets/images/msLogo.svg'
import { StyledSeparator, StyledServicesSignIn } from './styles'
import { envVars } from 'config/env'
import useLocalStorage from 'shared/lib/useLocalStorage'
import { useSearchParams } from 'react-router-dom'

const ServicesSignIn = styled.div`
  ${StyledServicesSignIn}
`

type Props = {
  text: string
}

const index: React.FC<Props> = ({ text }) => {
  const [searchParams] = useSearchParams()
  const { setItem } = useLocalStorage()
  const saveInviteTokenToLocalStorage = () => {
    const inviteToken = searchParams.get('inviteToken')
    if (inviteToken) {
      setItem('inviteToken', inviteToken)
    }
  }
  return (
    <div>
      <StyledSeparator>
        <span>or</span>
      </StyledSeparator>
      <div className='d-flex gap-3 flex-column align-items-center justify-content-center items-center mt-4'>
        <a
          onMouseDown={saveInviteTokenToLocalStorage}
          href={`${envVars.api.https}api/auth/google`}
          target='_blank'
          rel='noopener noreferrer'
        >
          <ServicesSignIn>
            <img src={GoogleLogo} alt='Google sign in' className='m-2' />
            <p className='m-2'>{text} with Google</p>
          </ServicesSignIn>
        </a>
        <a onMouseDown={saveInviteTokenToLocalStorage} href={`${envVars.api.https}api/auth/azure`}>
          <ServicesSignIn>
            <img src={MicrosoftLogo} alt='Microsoft sign in' className='m-2' />
            <p className='m-2'>{text} with Microsoft</p>
          </ServicesSignIn>
        </a>
      </div>
    </div>
  )
}

export default index
