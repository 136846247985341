import { type block, getBlockPageId, getIndex, IBlockTypes, isSomeOfLists } from '_entities/block'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  generateArrowBlock,
  generateEmojiBlock,
  generateFrameBlock,
  generateImageBlock,
  generateLineBlock,
  generateNewDocumentBlock,
  generateNewExternalPageBlock,
  generateShapeBlock,
  generateStickyNoteBlock,
  generateTextBlock,
} from 'utils/editor/generateBlock'
import { SourceKeys } from 'interfaces/editor'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useJson1 } from 'shared/shareDb/useJson1'
import { ITools, Position } from 'interfaces/whiteboard'
import {
  StageAttrs,
  StageAttrsData,
  useAnchors,
  useArrow,
  useDrawing,
  usePosition,
  useStage,
  useTransformer,
} from '_features/canvas'
import { getColor } from 'shared/colors'
import { useGrid } from '_entities/whiteboard'
import { setTool } from 'redux/reducers/whiteboardReducer'
import { useShapeCreation } from '_entities/shape'
import { NewBlockConfig } from './types'
import { useCreatePage } from '_features/page'
import { getPage } from '_entities/page'
import { setFontFamily } from 'redux/reducers/pageReducer'
import { getDocumentContainerId } from '_widgets/Document'
import Quill from 'quill'
import { IPage } from 'interfaces/page'
import { useEmbed } from '_entities/embed'
import { getById, getEditor } from 'shared/lib'
import { docSubscribe, getBlocks } from 'shared/shareDb'

export const LAST_CREATED_BLOCK_ID = 'data-last-block-created-id'

export const useCreateBlock = () => {
  // ** Redux
  const currentUser = useAppSelector((state) => state.global.user)
  const space = useAppSelector((state) => state.space.currentSpace)
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const pages = useAppSelector((state) => state.projectFile.pages)

  // ** Hooks
  const dispatch = useAppDispatch()
  const _submit = useSubmit()
  const _json1 = useJson1()
  const _transformer = useTransformer()
  const _anchors = useAnchors()
  const _drawing = useDrawing()
  const _arrow = useArrow()
  const _grid = useGrid()
  const _position = usePosition()
  const _stage = useStage()
  const _shapeCreation = useShapeCreation()
  const _createPage = useCreatePage()
  const _embed = useEmbed()

  const createDocumentBlock = (
    currentBlock: block,
    tag: IBlockTypes,
    numberInList?: number | null | string,
    indent?: number,
  ) => {
    const editor = getEditor(currentBlock._id)
    if (!editor) return
    const selection = editor.getSelection()
    if (selection) {
      const remainingContents = editor.getContents(selection.index)
      const index = getIndex(currentBlock)

      if (currentUser?.uuid === undefined) return

      // ** Create a new block
      // ** If the selection is not empty, create a new block with the remaining contents
      const newBlock: block = generateTextBlock(
        tag,
        currentUser.uuid,
        editor.getText(selection.index, editor.getLength()).trim().length > 0
          ? remainingContents
          : undefined,
      )
      // ** Set the last block created in the page element
      const pageElement = getById(getDocumentContainerId(getBlockPageId(currentBlock)))
      ;(pageElement as HTMLElement)?.setAttribute(LAST_CREATED_BLOCK_ID, newBlock._id)

      newBlock.data.lastUserId = currentUser.uuid
      newBlock.data.isChecked = false
      newBlock.data.positionIndex = 1
      newBlock.data.indent = isSomeOfLists(currentBlock)
        ? indent !== undefined
          ? indent
          : currentBlock.data.indent
        : 0
      newBlock.meta.pageId = getBlockPageId(currentBlock)
      newBlock.data.numberInList = numberInList || 1
      newBlock.meta.spaceId = space?.id
      newBlock.meta.projectFileId = project?.id
      newBlock.meta.chatMessageIds = []
      newBlock.meta.relatedObjects = []
      newBlock.data.updateInstance = true
      newBlock.meta.assignees = []
      newBlock.meta.createdBy = currentUser.uuid ? currentUser.uuid : ''
      newBlock.meta.createdAt = new Date().toLocaleDateString('en-US')
      newBlock.meta.dueDate = null
      const blocks = getBlocks(getBlockPageId(currentBlock))
      newBlock.meta.name = `${tag}_${blocks ? blocks.length + 1 : 1}`
      newBlock.meta.tags = []
      // ** Delete the remaining contents from the current block
      if (editor.getText(selection.index, editor.getLength()).trim().length > 0) {
        editor.deleteText(selection.index, editor.getLength(), 'user')
      }
      setTimeout(() => {
        _submit.submit(
          getBlockPageId(currentBlock),
          _json1.addBlock(newBlock, index + 1),
          SourceKeys.UPDATE_BLOCK,
        )
      })
    }
  }

  const generateNewBlock = (config: NewBlockConfig): block | null => {
    let block: block | null = null

    const blocks = getBlocks(config.pageId)

    if (!currentUser?.uuid || !blocks) return null

    if (
      (config.type === IBlockTypes.DOCUMENT || config.type === IBlockTypes.WHITEBOARD) &&
      config.createdPage
    ) {
      block = generateNewDocumentBlock(
        config.type,
        config.createdPage,
        currentUser.uuid,
        config.isExpanded,
        config.name,
      )
    }
    if (config.type === IBlockTypes.EXTERNAL_EMBED) {
      block = generateNewExternalPageBlock({
        tag: config.type,
        userUuid: currentUser.uuid,
        embed: config.externalEmbed,
        name: config.name,
        isExpanded: config.isExpanded,
      })
    }
    if (config.type === IBlockTypes.TEXT) {
      const textBlocks = blocks.filter((b) => b.data.tag === IBlockTypes.TEXT)
      const name = `${config.type}_${textBlocks.length + 1}`
      block = generateTextBlock(config.type, currentUser.uuid, config.initialText, name)
    }
    if (config.type === IBlockTypes.STICKY_NOTE) {
      const stickyBlocks = blocks.filter((b) => b.data.tag === IBlockTypes.STICKY_NOTE)
      const name = `${config.type}_${stickyBlocks.length + 1}`
      block = generateStickyNoteBlock(
        config.type,
        currentUser.uuid,
        config.initialText,
        config.createdBy,
        config.scale,
        name,
      )
    }
    if (config.type === IBlockTypes.LINE && _drawing.line) {
      const lineBlocks = blocks.filter((b) => b.data.tag === IBlockTypes.LINE)
      const name = `${config.type}_${lineBlocks.length + 1}`
      block = generateLineBlock(config.type, _drawing.line, currentUser.uuid, name)
    }
    if (config.type === IBlockTypes.ARROW && _arrow.arrowCoordinates) {
      const arrowBlocks = blocks.filter((b) => b.data.tag === IBlockTypes.ARROW)
      const name = `${config.type}_${arrowBlocks.length + 1}`
      block = generateArrowBlock(config.type, currentUser.uuid, _arrow.arrowCoordinates, name)
    }
    if (config.type === IBlockTypes.IMAGE) {
      block = generateImageBlock(config.type, currentUser.uuid, config.imageUrl, config.name)
    }
    if (config.type === IBlockTypes.EMOJI && config.emoji) {
      block = generateEmojiBlock(config.type, currentUser.uuid, config.emoji, config.name)
    }
    if (config.type === IBlockTypes.FRAME && config.frameSize) {
      const frameBlocks = blocks.filter((b) => b.data.tag === IBlockTypes.FRAME)
      const name = `${config.type}_${frameBlocks.length + 1}`
      block = generateFrameBlock(config.type, currentUser.uuid, config.frameSize, name)
    }
    if (config.type === IBlockTypes.SHAPE) {
      const shapeBlocks = blocks.filter((b) => b.data.tag === IBlockTypes.SHAPE)
      const name = `${config.shapeType}_${shapeBlocks.length + 1}`
      block = generateShapeBlock(
        config.type,
        currentUser.uuid,
        {
          type: config.shapeType || null,
          strokeWidth: 2,
          dash: [],
          fill: getColor('--whiteboard-shapes'),
        },
        config.scale,
        name,
      )
    }

    if (block) {
      const snapPosition = _grid.calculateGridCoordinates(
        config.position || _position.getNewBlockPosition(config.pageId),
      )
      block.meta.pageId = config.pageId
      block.meta.chatMessageIds = []
      block.meta.relatedObjects = []
      block.data.x = snapPosition.x
      block.data.y = snapPosition.y

      if (config.type === IBlockTypes.ARROW || config.type === IBlockTypes.LINE) {
        block.data.x = undefined
        block.data.y = undefined
      }
    }

    return block
  }

  const createWhiteboardBlock = (config: NewBlockConfig) => {
    const newBlock = generateNewBlock(config)
    const blocks = getBlocks(config.pageId)
    if (blocks && newBlock) {
      _submit.submit(
        config.pageId,
        _json1.addBlock(newBlock, config.type === IBlockTypes.FRAME ? 0 : blocks.length),
        SourceKeys.UPDATE_BLOCK,
      )
      _stage.setStageAttr(config.pageId, StageAttrs.LAST_CREATED_BLOCK, newBlock)
      if (config.type !== IBlockTypes.LINE) {
        dispatch(setTool(ITools.CURSOR))
        if (config.isShapeDrawn) {
          _shapeCreation.setShapeCreationDragBlock(config.pageId, newBlock)
        }
      }
    }
  }

  const handleSelectLinkPage = async (
    parentPageId: string,
    childPageId: string,
    position?: Position,
  ) => {
    const page = project && (await getPage(project.id, childPageId))
    if (page) {
      const subscribedDoc = await docSubscribe(page.id)
      if (!subscribedDoc || subscribedDoc instanceof Error) return
      createWhiteboardBlock({
        type: page.type as IBlockTypes,
        createdPage: page,
        pageId: parentPageId,
        position,
        name: page.title,
      })
    }
  }

  const addNewBlockInTransformer = (pageId: string, block: block) => {
    _transformer.setNodeFromBlockToTransformer(pageId, block)
    _anchors.setAnchors(pageId)
  }

  const createDocumentPageAndGenerateBlock = async (
    pageId: string,
    blocks?: block[],
    isExpanded?: boolean,
  ) => {
    if (pages && space && project) {
      const createdPage = await _createPage.createDocumentPage(
        pages,
        project.id,
        space.id,
        pageId,
        '',
        blocks,
      )
      if (createdPage) {
        const subscribedDoc = await docSubscribe(createdPage.id)
        if (!subscribedDoc || subscribedDoc instanceof Error) return
        createWhiteboardBlock({
          type: IBlockTypes.DOCUMENT,
          createdPage,
          isExpanded,
          pageId,
        })
      }
    }
  }

  const createWhiteboardPageAndGenerateBlock = async (pageId: string) => {
    if (pages && space && project) {
      const createdPage = await _createPage.createWhiteboardPage(
        pages,
        project.id,
        space.id,
        pageId,
      )
      if (createdPage) {
        const subscribedDoc = await docSubscribe(createdPage.id)
        if (!subscribedDoc || subscribedDoc instanceof Error) return
        createWhiteboardBlock({ type: IBlockTypes.WHITEBOARD, createdPage, pageId })
      }
    }
  }

  const getIsJustCreatedOnCanvas = (block: block) => {
    return (
      (
        _stage.getStageAttr(
          getBlockPageId(block),
          StageAttrs.LAST_CREATED_BLOCK,
        ) as StageAttrsData[StageAttrs.LAST_CREATED_BLOCK]
      )?._id === block._id
    )
  }

  const removeIsJustCreatedOnCanvas = (block: block) => {
    const pageId = getBlockPageId(block)
    _stage.removeStageAttr(pageId, StageAttrs.LAST_CREATED_BLOCK)
  }

  const isJustCreatedInDoc = (block: block) => {
    const pageElement = getById(getDocumentContainerId(getBlockPageId(block)))

    return pageElement?.getAttribute(LAST_CREATED_BLOCK_ID) === block._id
  }

  const setFontOnCreation = (block: block) => {
    const editor = getEditor(block._id)
    if (!editor) return
    editor.format('size', '18px', 'user')
    setTimeout(() => {
      editor.format('font', 'arial', 'user')
      dispatch(setFontFamily('arial'))
    }, 100)
  }

  const createEmptyBlockOnClick = (pageId: string) => {
    const blocks = getBlocks(pageId)

    if (!blocks) return

    const lastBlock = blocks[blocks.length - 1]

    if (blocks.length === 0) {
      addEmptyBlockToPositionHandler(pageId, 0)
      return
    }

    if (lastBlock) {
      const editor = getEditor(lastBlock._id)
      if (editor) {
        const length = editor.getLength()
        const isParagraph = lastBlock.data.tag === IBlockTypes.TEXT
        if (length > 1 || !isParagraph) {
          addEmptyBlockToPositionHandler(pageId, blocks.length)
        } /* else {
          editor.focus()
        } */
      } else {
        addEmptyBlockToPositionHandler(pageId, blocks.length)
      }
    }
  }

  const addEmptyBlockToPositionHandler = (pageId: string, destinationIndex?: number) => {
    if (currentUser?.uuid === undefined) return

    const newBlock: block = generateTextBlock(IBlockTypes.TEXT, currentUser.uuid, '', '', pageId)

    setTimeout(() => {
      _submit.submit(
        pageId,
        _json1.addBlock(newBlock, destinationIndex as number),
        SourceKeys.UPDATE_BLOCK,
      )
      setTimeout(() => {
        const quillElement = document.querySelector(`#quill-editor-${newBlock._id}`)
        if (quillElement) {
          const editor: Quill = Quill.find(quillElement)
          editor.focus()
        }
      }, 100)
    })
  }

  const addDocumentBlockHandler = (
    tag: IBlockTypes,
    documentPage: IPage,
    positionIndex = 0,
    pageId: string,
  ) => {
    if (!currentUser?.uuid) return

    const newBlock = generateNewDocumentBlock(tag, documentPage, currentUser.uuid)

    handleSubmitNewBlock(newBlock, positionIndex, pageId)
  }

  const addDocumentExternalBlockHandler = (page: IPage, positionIndex = 0) => {
    const type = _embed.getEmbedTypeFromLink(page.embedUrl || '')

    if (!type || !currentUser?.uuid) return

    const newBlock = generateNewExternalPageBlock({
      tag: IBlockTypes.EXTERNAL_EMBED,
      userUuid: currentUser.uuid,
      embed: {
        type,
        name: page.title,
        url: page.embedUrl || '',
      },
    })

    handleSubmitNewBlock(newBlock, positionIndex, page.id)
  }

  const handleSubmitNewBlock = (newBlock: block, positionIndex: number, pageId: string) => {
    newBlock.data.lastUserId = currentUser?.uuid
    newBlock.data.isChecked = false
    newBlock.data.positionIndex = positionIndex
    newBlock.meta.pageId = pageId
    newBlock.meta.spaceId = space?.id || ''
    newBlock.meta.projectFileId = project?.id || ''
    newBlock.meta.chatMessageIds = []
    newBlock.meta.relatedObjects = []
    newBlock.data.updateInstance = true
    _submit.submit(pageId, _json1.addBlock(newBlock, positionIndex), SourceKeys.UPDATE_BLOCK)
  }

  return {
    createDocumentBlock,
    createWhiteboardBlock,
    handleSelectLinkPage,
    addNewBlockInTransformer,
    createDocumentPageAndGenerateBlock,
    createWhiteboardPageAndGenerateBlock,
    getIsJustCreatedOnCanvas,
    removeIsJustCreatedOnCanvas,
    isJustCreatedInDoc,
    setFontOnCreation,
    createEmptyBlockOnClick,
    addDocumentBlockHandler,
    addDocumentExternalBlockHandler,
  }
}
