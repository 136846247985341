import styled, { css } from 'styled-components'

export const StyledServicesSignIn = css`
  width: 240px;
  display: flex;
  justify-content: center;

  & p {
    font-weight: 500;
  }
`
export const StyledSeparator = styled.div`
  padding-top: 0.5em;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid var(--gray4);
  line-height: 0.1em;
  margin: 10px 0 20px;

  & span {
    font-size: 0.7em;
    background: #fff;
    padding: 0 10px;
  }
`
