// ** Type Imports
import type {
  GetGoogleResponseType,
  GetGoogleRequestType,
  GetGoogleUserInfoRequestType,
  CheckAccessResponseType,
  GetGoogleIframeSrcRequestType,
} from '_features/embed'
import { googleApi, TAGS } from 'shared/api'

export const getGoogleInfo = googleApi.injectEndpoints({
  endpoints: (build) => ({
    getFileById: build.query<GetGoogleResponseType, GetGoogleRequestType>({
      query: ({ fileId }) => `https://www.googleapis.com/drive/v3/files/${fileId}`,
      providesTags: [TAGS.GOOGLE],
    }),
    getUserInfo: build.query<GetGoogleResponseType, GetGoogleUserInfoRequestType>({
      query: ({ accessToken }) =>
        `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${accessToken}`,
      providesTags: [TAGS.GOOGLE],
    }),
    checkAccess: build.query<CheckAccessResponseType, GetGoogleRequestType>({
      query: ({ fileId }) =>
        `https://www.googleapis.com/drive/v3/files/${fileId}?fields=permissions`,
      providesTags: [TAGS.GOOGLE],
    }),
    checkIframeSrc: build.query<CheckAccessResponseType, GetGoogleIframeSrcRequestType>({
      query: ({ url }) => ({
        url: `${url}`,
        responseHandler: (response) => response.text(),
      }),
      providesTags: [TAGS.GOOGLE],
    }),
  }),
})
