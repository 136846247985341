import { SettingsOptionsWrapper } from '../styles'
import { OnClickEdit } from './OnClickEdit'
import { ClickAction, CtaButton, getCtaOnClickAction } from '_entities/cta'
import { EditUrl } from './EditUrl'
import { EditPage } from './EditPage'
import { FontEdit } from './FontEdit'
import { FontSizeEdit } from './FontSizeEdit'
import { TextColourEdit } from './TextColourEdit'
import { ButtonSizeEdit } from './ButtonSizeEdit'
import { ButtonColour } from './ButtonColour'
import { BorderRadiusEdit } from './BorderRadiusEdit'
import { BorderColourEdit } from './BorderColourEdit'
import { FontWeightEdit } from './FontWeightEdit'

interface IProps {
  isDocument?: boolean
  button?: CtaButton | null
  updateCtaButton: (args: {
    id: number
    title?: string
    onClickAction?: ClickAction
    link?: string
    styling?: {
      font?: string
      fontSize?: number
      textColor?: string
      buttonColor?: string
      buttonHeight?: number
      borderRadius?: number
      borderColor?: string
    }
  }) => void
  isButtonsDropdownOpen: boolean
  onChangeLink: (e: React.ChangeEvent<HTMLInputElement>, button: CtaButton) => void
}

export const Settings = (props: IProps) => {
  if (!props.button) return null

  return (
    <SettingsOptionsWrapper
      isButtonsDropdownOpen={props.isButtonsDropdownOpen}
      isDocument={props.isDocument}
      key={props.isDocument ? 'document' : 'block'}
    >
      {/* Edit button on click action  */}
      <OnClickEdit button={props.button} updateCtaButton={props.updateCtaButton} />

      {/* Edit URL */}
      {getCtaOnClickAction(props.button) === ClickAction.OPEN_URL && (
        <EditUrl button={props.button} onChangeLink={props.onChangeLink} />
      )}

      {/* Edit navigate to page */}
      {getCtaOnClickAction(props.button) === ClickAction.OPEN_PAGE && (
        <EditPage button={props.button} updateCtaButton={props.updateCtaButton} />
      )}

      {/* Edit button font family */}
      <FontEdit button={props.button} updateCtaButton={props.updateCtaButton} />

      {/* Edit button font size */}
      <FontSizeEdit button={props.button} updateCtaButton={props.updateCtaButton} />

      {/* Edit button text colour */}
      <TextColourEdit button={props.button} updateCtaButton={props.updateCtaButton} />

      {/* Edit button size */}
      <ButtonSizeEdit button={props.button} updateCtaButton={props.updateCtaButton} />

      {/* Edit button colour */}
      <ButtonColour button={props.button} updateCtaButton={props.updateCtaButton} />

      {/* Edit button border radius */}
      <BorderRadiusEdit button={props.button} updateCtaButton={props.updateCtaButton} />

      {/* Edit button border colour */}
      <BorderColourEdit button={props.button} updateCtaButton={props.updateCtaButton} />

      {/* Font weight edit */}
      <FontWeightEdit button={props.button} updateCtaButton={props.updateCtaButton} />
    </SettingsOptionsWrapper>
  )
}
