import { IDropdownOption } from 'interfaces/invite'
import React, { useState } from 'react'

interface Props {
  onChange?: (value: IDropdownOption) => void
}

export const useCustomSelectDropdown = ({ onChange }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<IDropdownOption>()

  const toggling = () => {
    setIsOpen(!isOpen)
  }

  const onOptionClicked = (value: React.SetStateAction<any>) => () => {
    setSelectedOption(value)
    setIsOpen(false)
    onChange && onChange(value)
  }

  return {
    isOpen,
    selectedOption,
    toggling,
    onOptionClicked,
  }
}
