import React from 'react'
import { PropertyTitle, PropertyWrapper } from '../styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { type block } from '_entities/block'

interface IProps {
  block: block
}

const CreatedAt = (props: IProps) => {
  const { block } = props

  return (
    <PropertyWrapper>
      <PropertyTitle>Date created</PropertyTitle>
      <CustomP color='var(--primary-text)'>
        {new Date(block.meta.createdAt).toLocaleDateString('en-US')}
      </CustomP>
    </PropertyWrapper>
  )
}

export default CreatedAt
