import { icons } from 'shared/icons'
import styled from 'styled-components'

export const Title = styled.div`
  width: max-content;
  position: relative;
  font-weight: 600;
  font-size: 16px;
  margin-right: auto;
  cursor: pointer;
  color: #a3a3a3;
`

export const StyledEmbedIcon = styled(icons.embedLink)`
  path {
    stroke: #a3a3a3;
  }
`
export const EmbedHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  left: -0.25rem;
  width: min(100%, 350px);
  overflow-x: hidden;
  cursor: pointer;
`

export const IconWrapper = styled.div`
  max-width: 1.5rem !important;

  svg {
    max-width: 1.5rem !important;
  }
`

export const EditorEmbedWrapper = styled.div`
  padding: 12px;
`
