import { WhiteboardBlockProps } from '_entities/block'
import IFrameEmbed from 'components/atoms/IFrameEmbed/IFrameEmbed'
import {
  useEmbed,
  PagePreviewOptions,
  useEmbedExpansion,
  checkIsGoogleDocLink,
  checkIsGoogleSlidesLink,
  checkIsGoogleSheetsLink,
  checkIsFigma,
  PagePreviewAction,
} from '_entities/embed'
import { useAppSelector } from 'redux/hooks'
import Resizable from 'whiteboard/Resizable/Resizable'
import { FullEmbedWrapper } from './styles'
import { ExpandableCallout } from 'shared/ui'
import { GoogleEmbed } from '_features/google-embed'
import { envVars } from 'config/env'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { Icon, useIcons } from 'shared/icons'
import { WebsiteEmbed } from '../WebsiteEmbed/WebsiteEmbed'
import { Container } from '_features/embed/ui/styles'
import {
  StyledExpandIcon,
  StyledMinimizeIcon,
} from '../../../../../components/molecules/TaskTitle/styles'
import { PageNameWrapper, PagePreviewActions } from '../../../InternalEmbed/ui/styles'
import React from 'react'

const FullEmbed = (props: WhiteboardBlockProps) => {
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const _embed = useEmbed()
  const _icons = useIcons()
  const _embedExpansion = useEmbedExpansion({ block: props.block })

  const shouldEnable = () => props.isPreview || selectedBlock?._id === props.block._id

  const openExternalEmbed = () => {
    if (!_embed.isOnlyLink(props.block)) return
    window.open(_embed.getUrl(props.block), '_blank')
  }

  return (
    <Resizable {...props} isCallout={_embed.isCallout(props.block)} shouldEnable={shouldEnable()}>
      {!_embedExpansion.isEmbeddedDocumentExpanded && (
        <ExpandableCallout
          width={'100%'}
          height={'100%'}
          block={props.block}
          onClick={() => {
            openExternalEmbed()
          }}
          title={_embed.getExternalEmbedTitle(props.block)}
          Icon={_icons.getIcon({ block: props.block })}
          isExpanded={_embedExpansion.isEmbeddedDocumentExpanded}
          maximize={_embedExpansion.handleEmbeddedDocumentMaximize}
          minimize={_embedExpansion.handleEmbeddedDocumentMinimize}
        />
      )}

      {_embedExpansion.isEmbeddedDocumentExpanded && (
        <Container height='100%'>
          <FullEmbedWrapper>
            {_embed.getUrl(props.block) && (
              <>
                {checkIsGoogleDocLink(_embed.getUrl(props.block)) ||
                checkIsGoogleSlidesLink(_embed.getUrl(props.block)) ||
                checkIsGoogleSheetsLink(_embed.getUrl(props.block)) ? (
                  <GoogleOAuthProvider clientId={`${envVars.googleApi.clientId}`}>
                    <GoogleEmbed embedUrl={`${_embed.getUrl(props.block)}?embed=true`} />
                  </GoogleOAuthProvider>
                ) : _embed.isOnlyLink(props.block) ? (
                  <WebsiteEmbed
                    title={_embed.getExternalEmbedTitle(props.block)}
                    url={_embed.getUrl(props.block)}
                  />
                ) : (
                  <IFrameEmbed src={`${_embed.getUrl(props.block)}?embed=true`} />
                )}
              </>
            )}
          </FullEmbedWrapper>

          {checkIsFigma(_embed.getUrl(props.block)) && (
            <PagePreviewAction
              isEmbeddedDocumentExpanded={_embedExpansion.isEmbeddedDocumentExpanded}
              handleEmbeddedMaximize={_embedExpansion.handleEmbeddedDocumentMaximize}
              handleEmbeddedMinimize={_embedExpansion.handleEmbeddedDocumentMinimize}
              isFigma
            />
          )}

          {!checkIsFigma(_embed.getUrl(props.block)) && (
            <PagePreviewOptions
              Icon={_icons.getIcon({ block: props.block })}
              isEmbeddedDocumentExpanded={_embedExpansion.isEmbeddedDocumentExpanded || false}
              handleEmbeddedMaximize={_embedExpansion.handleEmbeddedDocumentMaximize}
              handleEmbeddedMinimize={_embedExpansion.handleEmbeddedDocumentMinimize}
              title={_embed.getExternalEmbedTitle(props.block)}
              isWhiteboardEmbed
              onTitleClick={() => {
                openExternalEmbed()
              }}
            />
          )}
        </Container>
      )}
    </Resizable>
  )
}

export default FullEmbed
