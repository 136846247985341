import { SettingsOptionText, SettingsOptionWrapper, StyledDropdownTrigger } from '../styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import { getCtaFontSize, CtaButtonProps, FONT_SIZES } from '_entities/cta'

export const FontSizeEdit = (props: CtaButtonProps) => {
  return (
    <SettingsOptionWrapper>
      <SettingsOptionText>Font Size</SettingsOptionText>

      <RadixDropdown
        trigger={
          <StyledDropdownTrigger>
            <CustomP fontWeight='500'>{getCtaFontSize(props.button)}px</CustomP>
            <icons.arrowDown />
          </StyledDropdownTrigger>
        }
      >
        {[...FONT_SIZES].map((option) => (
          <DropdownMenuItem
            key={option}
            onSelect={() =>
              props.updateCtaButton({
                id: props.button.id,
                styling: {
                  ...props.button.styling,
                  fontSize: option,
                },
              })
            }
          >
            {option}
          </DropdownMenuItem>
        ))}
      </RadixDropdown>
    </SettingsOptionWrapper>
  )
}
