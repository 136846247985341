import { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { IForgotPassword } from 'interfaces/auth/types'
import TextSuccess from 'components/atoms/TextSuccess/TextSuccess'
import TextDanger from 'components/atoms/TextDanger/TextDanger'
import LoadingAnim from 'components/atoms/LoadingAnim/LoadingAnim'
import { StyledMainDiv, StyledCustomeH2, StyledSubText, StyledSpanCursor } from './styles'
import { envVars } from 'config/env'

const MainDiv = styled.div`
  ${StyledMainDiv}
`

const CustomeH2 = styled.h2`
  ${StyledCustomeH2}
`

const SubText = styled.p`
  ${StyledSubText}
`

const SpanCursor = styled.span`
  ${StyledSpanCursor}
`

const index: React.FC<{
  cardElements: React.ReactNode
  titleText?: string
  secondaryText?: string
  textBlack?: boolean
  shadow?: boolean
  radius?: boolean
  alignLeft?: boolean
  fontSize?: boolean
  successMessage?: string
  email?: string
  subtext?: string
  isTitleLeft?: boolean
  isFontSmaller?: boolean
}> = ({
  cardElements,
  titleText,
  textBlack,
  shadow,
  radius,
  alignLeft,
  fontSize,
  successMessage,
  secondaryText,
  email,
  subtext,
  isTitleLeft,
  isFontSmaller,
}) => {
  const [statusMessage, setStatusMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const resendMail = (e: React.SyntheticEvent) => {
    e.preventDefault()
    setLoading(true)
    axios
      .post<IForgotPassword>(`${envVars.api.https}api/auth/forgot-pass`, {
        data: {
          attributes: {
            email,
          },
          type: 'auths',
        },
      })
      .then(() => setStatusMessage('Email resent. Check your inbox.'))
      .catch((err) => {
        err?.response?.data?.errors[0]?.message
          ? setError(err?.response?.data?.errors[0]?.message)
          : setError('Something went wrong')
      })
      .finally(() => setLoading(false))
  }

  return (
    <MainDiv shadow={shadow} radius={radius}>
      <div className='card-body text-center py-4'>
        {titleText && (
          <CustomeH2
            className={`card-title fw-bold ${isTitleLeft ? '' : 'text-center'} ${
              isFontSmaller ? 'smallerFont' : ''
            }`}
          >
            {titleText}
          </CustomeH2>
        )}
        <SubText className='mt-3 mb-4 smaller-size'>
          {subtext ? subtext : 'Log in to your account'}
        </SubText>
        <SubText textBlack={textBlack} alignLeft={alignLeft} fontSize={fontSize}>
          {secondaryText}
          {successMessage && (
            <SpanCursor className='text-primary' onClick={resendMail}>
              resend
            </SpanCursor>
          )}
        </SubText>
        {cardElements}
        {statusMessage === 'Email sent, check your inbox.' ? (
          <TextDanger text={error} />
        ) : (
          <TextSuccess text={statusMessage} />
        )}
        {loading && (
          <span className='d-flex justify-content-center align-items-center'>
            <LoadingAnim className='loading-spinner mb-3' />
          </span>
        )}
      </div>
    </MainDiv>
  )
}

export default index

index.defaultProps = {
  isTitleLeft: false,
  isFontSmaller: false,
}
