import React from 'react'
import SingleSelect from 'components/organisms/SingleSelect/SingleSelect'
import { PageOption, pageOptions } from 'components/organisms/ProjectFileRightSideBar/constants'
import { useAppSelector } from 'redux/hooks'
import { PageTypesEnum } from 'interfaces/projectFile'
import { CtaButtonProps } from '_entities/cta'

export const EditPage = (props: CtaButtonProps) => {
  // ** Redux state
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const space = useAppSelector((state) => state.space.currentSpace)
  const pages = useAppSelector((state) => state.projectFile.pages)
  const pagesWithoutTaskManager = pages?.filter((page) => page.type !== PageTypesEnum.TASK_MANAGER)

  const link = props.button.link
  const pageId = link?.split('pageId=')[1]

  const currentValue = pages?.find((page) => page.id === pageId)

  return (
    <SingleSelect
      currentValue={{
        label: currentValue?.text,
        value: currentValue?.id as string,
      }}
      options={pageOptions(pagesWithoutTaskManager)}
      onChange={(e) => {
        const link = `${window.location.origin}/spaces/${space?.id}/project-file/${projectFile?.id}?pageId=${e?.value}`

        props.updateCtaButton({
          id: props.button.id,
          link: link,
        })
      }}
      placeholder='Search pages'
      isClearable={false}
      isSearchable
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: () => null,
        Option: PageOption as any,
      }}
    />
  )
}
