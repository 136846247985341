import { AxiosService } from 'services/axiosService/axiosService'
import MoveFileToast from '_widgets/LeftSidebar/ui/PagesNavigation/MoveFileToast'
import { useToast } from 'services/helpers/useToast'
import { IPage, IPages } from 'interfaces/page'
import { IProjectFile } from 'interfaces/space'

export const usePageTransfer = () => {
  const axiosService = new AxiosService()
  const toast = useToast()

  const copyPageToProject = async (
    pageId: string,
    projectFileId: number,
    projectToCopyInto: number,
  ) => {
    return await axiosService.copyPageToProjectFile(pageId, projectFileId, projectToCopyInto)
  }

  const handleCopyFileToAnotherProject = async (
    pageToMove: IPages,
    projectId: string,
    selectedProject: IProjectFile | null,
    handleRedirect: (pageId: string, page: IPage, spaceId: string) => void,
    handleOnUndo: (pageId: string) => void,
  ) => {
    if (!selectedProject) return
    const response =
      projectId &&
      pageToMove &&
      (await copyPageToProject(
        pageToMove.id.toString(),
        parseInt(projectId),
        parseInt(selectedProject.id.toString()),
      ))
    if (response) {
      const createdPageId = response.data[0]?.id
      toast.customToast(
        () =>
          MoveFileToast({
            selectedPage: pageToMove,
            selectedProject: selectedProject.attributes,
            onUndo: () => handleOnUndo(createdPageId),
            onRedirect: () =>
              handleRedirect(createdPageId, response.data[0].attributes, selectedProject?.spaceId),
          }),
        5000,
      )
    }
  }

  return {
    copyPageToProject,
    handleCopyFileToAnotherProject,
  }
}
