import styled from 'styled-components'

import Popup from 'reactjs-popup'

export const IconTextWrapper = styled.div<{
  opacity?: number
}>`
  display: flex;
  align-items: center;

  padding-left: 8px;
`

export const PageReference = styled.div<{
  isDropTarget?: boolean
  depth?: number
  isSelected?: boolean
  width?: string
  noRightMargin?: boolean
}>`
  height: fit-content;
  min-width: fit-content;
  width: ${(props) => (props.width ? props.width : '')};
  margin: 0 ${(props) => (props.noRightMargin ? 0 : '8px')} 0
    ${(props) => (props.depth ? props.depth * 8 : 0)}px;
  padding: 8px 8px 8px 0;
  background: ${(props) =>
    props.isDropTarget ? 'var(--primary-color)' : props.isSelected ? 'var(--hover-blue)' : 'none'};
  border-radius: 8px;
  color: #383838;
  font-weight: 500;
  font-size: small;
  cursor: pointer;
  transition: background 0.4s, color 0.4s;

  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  &:hover {
    ${IconTextWrapper} {
      background: var(--secondary);
    }
    // background: var(--hover);
    background: var(--hover);
    color: black;
  }
`

export const Dropdown = styled(Popup)``

export const DropdownItem = styled.div<{ name: string }>`
  padding: 0.2rem;
  height: 100%;
  text-align: center;
  transition: background 0.4s;
  border-radius: 4px;
  cursor: pointer;
  color: ${(props) => (props.name === 'Delete' ? 'red' : 'initial')};
  &:hover {
    background: lightgray;
  }
`

export const StyledWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  padding: 0 8px;

  &:hover {
    background: var(--hover);
  }
`
