import { icons } from 'shared/icons'
import CustomP from 'components/atoms/CustomP/CustomP'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
`

const StyledIcon = styled(icons.eyeOff)`
  scale: 2.5;
`

export const NoViewableContent = () => {
  return (
    <StyledWrapper>
      <StyledIcon />
      <CustomP fontSize='1.5em' color={'var(--text)'}>
        This content is not visible in this view
      </CustomP>
    </StyledWrapper>
  )
}
