import { Dropdown } from 'react-bootstrap'
import styled, { css } from 'styled-components'

import { ReactComponent as ListIconStyled } from 'assets/images/threeVectors.svg'
import { ReactComponent as BoxesIconStyled } from 'assets/images/FourRectanglesIcon.svg'
import { icons } from 'shared/icons'
import Dots from 'components/atoms/Dots/Dots'
import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

type IconsPropsType = {
  isSelected: boolean
}

type ProjectFilePropsType = {
  layout: boolean
  marginBottom?: string
}

type SearchAndLayoutContainerCssPropsType = {
  top?: string
  left?: string
  position?: string
}

export const ListIcon = styled(ListIconStyled)<IconsPropsType>`
  background-color: ${(props) => (props.isSelected ? 'var(--gray4)' : '')};
  border-radius: 6px;
  width: 30px;
  height: 30px;
  padding: 0.35rem 0.5rem;
`
export const BoxesIcon = styled(BoxesIconStyled)<IconsPropsType>`
  background-color: ${(props) => (props.isSelected ? 'var(--gray4)' : '')};
  border-radius: 6px;
  width: 30px;
  height: 30px;
  padding: 0.35rem 0.5rem;
`

export const StyledDropdown = styled(Dropdown)`
  position: absolute;
  top: 2px;
  right: 7px;
`

export const SearchAndLayoutContainerCss = css<SearchAndLayoutContainerCssPropsType>`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 16px;

  position: ${(props) => props.position || ''};
  top: ${(props) => props.top || ''};
  left: ${(props) => props.left || ''};
`

export const LayoutIconsContainerCss = css`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;
  margin-top: -0.5rem;
  padding: 0.5rem;

  & > * {
    cursor: pointer;
  }
`

export const WelcomeMessageCss = css`
  padding: 20px 0 0 0;
  text-align: center;
  font-size: 15px;
  color: var(--gray);
  user-select: none;
  margin-block: 2rem 1.5rem;
`

export const WelcomeMessageSpanCss = css`
  color: var(--primary-color);
  cursor: pointer;
`

export const ProjectFilesContainerCss = css<ProjectFilePropsType>`
  padding: 16px 48px;
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  gap: 12px;
  position: relative;
  margin-bottom: ${(props) => props.marginBottom || ''};

  & .moveTop {
    position: relative;
    top: -19px !important;
  }

  & .dropdownAbsolute {
    position: absolute;
    padding-inline: 0.25rem;
    top: -80px;
    right: -80px;
  }

  & .listOrganiser {
    position: absolute;
    left: -200px;
    top: -200px;
  }

  &.organisedAsBoxes {
    display: grid;
    position: relative;
    gap: 0.25rem;

    & > * {
      display: grid;
      gap: 0.25rem;
      width: 100%;
      height: 50px;
      overflow-y: hidden;
      align-items: start;
      align-content: bottom;
      justify-content: end;
      border: none;
      border-top: 2px solid var(--gray4);
      box-shadow: none !important;
      border-radius: 0;

      img {
        width: 38px;
        height: 38px;
        align-self: end;
        position: relative;
        top: 0.25rem;
        margin-right: 0.5rem;
      }

      & .threeDots,
      & .emptyStar {
        position: absolute;
        right: 65px;
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
      }

      & .threeDots {
        margin-left: 40px;
      }

      & .emptyStar {
        margin-right: 40px;
        margin-top: 1px;
      }

      & .buttonForDeleteInBoxes {
        height: 100%;
        width: 120px;
      }

      & > *:not(img) {
        display: none;
        padding: 0 1rem;

        &:first-child {
          display: none;
        }
      }
    }
  }
`

export const FileCardOptions = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.25rem;
`

export const ProjectFileCss = css`
  position: relative;
  width: 168px;
  height: 168px;
  border: 1px solid var(--gray4);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 1px 1px 0px rgba(56, 56, 56, 0.2);

  ${IconButton} {
    opacity: 0;

    &[data-state='open'] {
      opacity: 1;
    }
  }

  &:hover {
    ${IconButton} {
      opacity: 1;
    }
  }
`
export const ProjectFileLogoCss = css<{ isNew?: boolean; marginRight?: string }>`
  ${(props) => props.isNew && 'transform: scale(0.65)'};
  width: 67px;
  height: 67px;
  margin-bottom: 1.5em;
  margin-right: ${(props) => props.marginRight || ''};
  user-select: none;
`

export const ProjectFileTitleCss = css<{ isNew?: boolean }>`
  ${(props) => props.isNew && 'opacity: 0.5'};
  position: absolute;
  bottom: 5px;
  text-align: center;
  font-size: 14px;
`

export const ProjectFileStarCss = css`
  width: 20px;
  height: 20px;
  opacity: 0.75;
`

export const StyledDots = styled(Dots)``

export const StyledDeleteIcon = styled(icons.trash)`
  path {
    stroke: var(--everyday-red) !important;
  }
`
