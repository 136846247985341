import { Tabs } from 'react-bootstrap'
import { useManagerOptionMutation, useTaskManager } from '_features/tasks'
import {
  StyledBoardIcon,
  StyledContainer,
  StyledCustomH1,
  StyledListIcon,
  StyledTab,
  StyledTabTogglerContent,
} from './styles'
import { useAppDispatch } from 'redux/hooks'
import { ILayoutsEnum } from 'interfaces/taskManager'
import TaskManagerView from 'components/organisms/TaskManagerView/TaskManagerView'
import { setSelectedOptionId } from 'redux/reducers/taskManagerReducer'
import { useWebsockets } from '../model/useWebsockets'

interface Props {
  isSlider: boolean
  isSliderFullScreen: boolean
}

export const TaskManager = (props: Props) => {
  // ** Hooks
  useWebsockets()
  const dispatch = useAppDispatch()
  const _taskManager = useTaskManager()
  const _taskManagerMutation = useManagerOptionMutation()

  return (
    <StyledContainer className={props.isSlider ? 'task-drawer' : ''}>
      {!props.isSlider && <StyledCustomH1>Task Manager</StyledCustomH1>}
      {!props.isSlider && (
        <Tabs
          activeKey={_taskManager.selectedOption?.id}
          className='mb-4'
          onSelect={(eventKey) => {
            if (eventKey) {
              if (eventKey === 'Add')
                _taskManagerMutation?.createManagerOptions('My layout', ILayoutsEnum.BOARD)
              else if (parseInt(eventKey)) {
                if (_taskManager.selectedOption) dispatch(setSelectedOptionId(parseInt(eventKey)))
              }
            }
          }}
        >
          {_taskManager.options &&
            [..._taskManager.options]?.reverse()?.map((option) => (
              <StyledTab
                key={option.id}
                eventKey={option.id}
                title={
                  option.layout === ILayoutsEnum.BOARD ? (
                    <StyledTabTogglerContent>
                      <StyledBoardIcon />
                      {option.name || 'Board'}
                    </StyledTabTogglerContent>
                  ) : (
                    <StyledTabTogglerContent>
                      <StyledListIcon />
                      {option.name || 'List'}
                    </StyledTabTogglerContent>
                  )
                }
                tabClassName={`tabBackgroundColor tabTaskManager  ${
                  _taskManager.selectedOption?.id == option.id ? 'tab-bottom_border' : ''
                }`}
              >
                <TaskManagerView
                  {...{
                    type: option.layout,
                    onDragEnd: _taskManager.onDragEnd,
                    data: _taskManager.data,
                    isSlider: props.isSlider,
                    isSliderFullscreen: props.isSliderFullScreen,
                  }}
                />
              </StyledTab>
            ))}
        </Tabs>
      )}
      {props.isSlider && (
        <TaskManagerView
          {...{
            type: props.isSliderFullScreen ? ILayoutsEnum.BOARD : ILayoutsEnum.LIST,
            onDragEnd: _taskManager.onDragEnd,
            data: _taskManager.data,
            isSlider: props.isSlider,
            isSliderFullscreen: props.isSliderFullScreen,
          }}
        />
      )}
    </StyledContainer>
  )
}
