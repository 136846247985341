import styled from 'styled-components'

export const NotificationTabUserModalStyled = styled.div`
  display: flex;
  flex-direction: column;

  & .btn {
    width: fit-content;
  }

  & .colorForHr {
    background-color: var(--gray3) !important;
    margin-block: 2rem;
  }
`

export const ValueAndButtonContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 12px;
  margin-bottom: 20px;
`

export const EmptyDiv = styled.div``

export const ModalInfoLabel = styled.label`
  color: var(--secondary-text) !important;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 1.5rem;
`
export const IndentedParagraph = styled.label`
  margin-left: 24px;
  display: grid;
  gap: 0.5rem;
`
export const ModalInfoLabelSmall = styled.label`
  color: var(--gray2) !important;
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 0.15rem;
  margin-top: 1rem;
`
