import styled from 'styled-components'

export { ReactComponent as DotsThreeOutline } from 'assets/images/DotsThreeOutline.svg'
export { ReactComponent as CheckBox } from 'assets/images/checktask.svg'
import { ReactComponent as ProfileIcon } from 'assets/images/user-circle.svg'
import Button from 'components/atoms/Button/Button'

export const Container = styled.div`
  display: flex;
  padding: 16px 8px;
  width: 100%;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;
  margin-bottom: 8px;
`
export const StyledIconText = styled.div`
  font-weight: 500;
  font-size: 10px;
  margin-top: 0;
  padding: 0;
  line-height: 16px;
`

export const StyledProfileIcon = styled(ProfileIcon)`
  width: 22px;
  height: 22px;
`

export const StyledShareButton = styled(Button)<{ width?: string }>`
  border: solid 1px var(--primary-color);
  text-align: left;
  width: ${(props) => (props.width ? props.width : 'auto')};
`
