import { motion } from 'framer-motion'
import styled from 'styled-components'
import { ReactComponent as ArrowBarToRight } from 'assets/images/arrow-bar-to-right.svg'
import { ReactComponent as MaximizeIcon } from 'assets/images/maximize.svg'
import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg'
import { ReactComponent as SettingsIcon } from 'assets/images/profile/settings.svg'

export const ChatDrawerContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;

  background: var(--sidebars);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
  border-radius: 7px;
  z-index: 3;
  padding-left: 0;
  overflow: scroll;
`

export const ChatDrawerContent = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100vh;
  overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-bottom: 40px;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const ProfileDrawerHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 24px 20px 24px 20px;
  background-color: var(--tasks-background);
`

export const ProfileImage = styled.div`
  width: 90px;
  height: 90px;
`

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  justify-content: center;
`

export const StyledUserName = styled.h6`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 0;
  color: var(--primary-text);
`

export const StyledJobName = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0;
  padding-top: 4px;
  color: var(--secondary-text);
`

export const StyledArrowBarToRight = styled(ArrowBarToRight)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledMaximizeIcon = styled(MaximizeIcon)`
  path {
    stroke: var(--primary-text);
  }
`

export const StyledLogoutIcon = styled(LogoutIcon)`
  scale: 0.9;

  path {
    fill: var(--everyday-red);
  }
`

export const LogoutWrapper = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 4px 24px 4px 12px;
  background: var(--sidebars);
  cursor: pointer;

  &:hover {
    background: var(--hover);
  }
`

export const StyledSettingsIcon = styled(SettingsIcon)`
  path {
    stroke: var(--primary-text);
  }
`

export const LLMWrapper = styled.div`
  padding: 16px;
  padding-bottom: 10px;
`

export const SelectModelWrapper = styled.div`
  margin-left: 8px;
`

export const StyledTitle = styled.p`
  font-size: 16px;
  color: var(--primary-text);
  margin-bottom: 12px;
`

export const StyledToggleWrapper = styled.div`
  padding: 0 36px 64px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
`
