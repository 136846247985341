import styled, { css, CSSProperties } from 'styled-components'

export const ChatFilterContainer = styled.div<{
  backgroundColor?: CSSProperties['backgroundColor']
  border?: CSSProperties['border']
}>`
  position: fixed;
  height: 46px;
  top: 62px;
  z-index: 100;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 22px;
  width: 100%;
  ${(props) =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `};
  ${(props) =>
    props.border &&
    css`
      border: ${props.border};
    `}
`
