import {
  ArrowLeft,
  TasksDrawerContainer,
  TasksDrawerHead,
  TasksDrawerBody,
  TasksDrawerDropdownContainer,
  TasksDrawerContent,
  TasksDrawerDropdown,
  TasksDropdownTitle,
  TasksDrawerGrow,
  TasksDrawerDetailsWrapper,
  StyledCheckBoxIcon,
  StyledArrowBarToRight,
} from './styles'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { IconButton } from 'components/atoms/Button/Button'
import { Document } from '_widgets/Document'
import {
  toggleSliderOpen,
  getSelectedTask,
  setSelectedTaskId,
  setIsSliderTaskOpen,
} from 'redux/reducers/taskManagerReducer'
import Details from 'components/organisms/TaskManagerModal/Details'
import { AnimatePresence } from 'framer-motion'
import { isMobile } from 'react-device-detect'
import { TaskManager } from '_widgets/TaskManager'

export const TASKS_DRAWER_ID = 'tasks-drawer'

const TasksDrawer = () => {
  const dispatch = useAppDispatch()
  const fullScreen = useAppSelector((state) => state.taskManager.isFullscreen)
  const taskOpen = useAppSelector((state) => state.taskManager.isSliderTaskOpen)
  const selectedTask = useAppSelector(getSelectedTask)
  const properties = useAppSelector((state) => state.taskManager.propertyDefinitions)
  const selectedTaskDocument = useAppSelector((state) => state.taskManager.selectedTaskDocument)
  const isOpen = useAppSelector((state) => state.taskManager.isSliderOpen)

  return (
    <>
      {isOpen && (
        <AnimatePresence>
          <TasksDrawerContainer
            id={TASKS_DRAWER_ID}
            initial={{
              transform: 'translateX(100%)',
            }}
            animate={{
              transform: 'translateX(0%)',
            }}
            exit={{
              transform: 'translateX(100%)',
            }}
            transition={{ ease: 'easeInOut', duration: 0.4 }}
          >
            <TasksDrawerContent
              initial={{
                width: isMobile ? '100vw' : '535px',
              }}
              animate={{
                width: fullScreen ? '100vw' : isMobile ? '100vw' : '535px',
              }}
              exit={{
                width: isMobile ? '100vw' : '535px',
              }}
              transition={{ ease: 'easeInOut', duration: 0.4 }}
            >
              <TasksDrawerHead>
                <TasksDrawerGrow
                  onClick={
                    taskOpen
                      ? () => {
                          dispatch(setIsSliderTaskOpen(false))
                          dispatch(setSelectedTaskId(undefined))
                        }
                      : () => void 0
                  }
                >
                  {taskOpen && <ArrowLeft />}
                  {!taskOpen && <StyledCheckBoxIcon />}
                  <TasksDrawerDropdownContainer>
                    <TasksDrawerDropdown>
                      <TasksDropdownTitle>Tasks</TasksDropdownTitle>
                    </TasksDrawerDropdown>
                  </TasksDrawerDropdownContainer>
                </TasksDrawerGrow>

                <IconButton
                  variant='transparent'
                  size='small'
                  onClick={() => {
                    dispatch(toggleSliderOpen())
                    dispatch(setIsSliderTaskOpen(false))
                    dispatch(setSelectedTaskId(undefined))
                  }}
                >
                  <StyledArrowBarToRight />
                </IconButton>
              </TasksDrawerHead>
              <TasksDrawerBody>
                {!taskOpen && <TaskManager isSlider isSliderFullScreen={fullScreen} />}
                {taskOpen && selectedTask && properties && (
                  <TasksDrawerDetailsWrapper>
                    <Details {...{ selectedTask, properties }} />
                    {selectedTaskDocument &&
                      selectedTask &&
                      selectedTask.id === selectedTaskDocument.id && (
                        <Document pageId={selectedTaskDocument.id} />
                      )}
                  </TasksDrawerDetailsWrapper>
                )}
              </TasksDrawerBody>
            </TasksDrawerContent>
          </TasksDrawerContainer>
        </AnimatePresence>
      )}
    </>
  )
}

export default TasksDrawer
