import {
  PageNameWrapper,
  StyledUpdateIcon,
} from 'components/organisms/ProjectFileRightSideBar/ReusableSidebar/Source/styles'
import { type block } from '_entities/block'
import { RelationshipType } from '_features/relationship'
import { icons } from 'shared/icons'
import React from 'react'
import { IPage } from 'interfaces/page'

interface IProps {
  block: block
  page: IPage | undefined
  handlePullUpdates: (e: React.MouseEvent, block: block) => void
}

const SourcePageName = (props: IProps) => {
  return (
    <PageNameWrapper>
      {props.block.meta.relationshipType === RelationshipType.INSTANCE && (
        <StyledUpdateIcon onClick={(e) => props.handlePullUpdates(e, props.block)} />
      )}
      {props.block.meta.relationshipType === RelationshipType.MIRROR ? (
        <icons.mirror />
      ) : (
        <icons.instance />
      )}

      {props.page?.title && props.page?.title.length > 10
        ? `${props.page?.title.slice(0, 10)}...`
        : `${props.page?.title}`}
    </PageNameWrapper>
  )
}

export default SourcePageName
