import React, { useCallback, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { envVars } from 'config/env'
import { useAppSelector } from 'redux/hooks'

export const useInitialPhotoUpload = () => {
  const [profileImage, setProfileImage] = useState<File>()
  const [imageScale, setImageScale] = useState(1)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const user = useAppSelector((state) => state.global.user)

  // eslint-disable-next-line
  const editor: any = useRef()

  const [errorValidationMessage, setErrorValidationMessage] = useState('')

  const validateFile = (uploadedFileSize: number, uploadedFileType: string) => {
    if (uploadedFileSize > 3000000) {
      setErrorValidationMessage('Maximum file size is 3mb')
      return false
    } else if (
      uploadedFileType === 'image/png' ||
      uploadedFileType === 'image/jpg' ||
      uploadedFileType === 'image/jpeg'
    ) {
      setErrorValidationMessage('')
      return true
    } else {
      setErrorValidationMessage('Supported types are: png, jpg, jpeg')
      return false
    }
  }

  const onDrop = useCallback((files: File[]) => {
    const reader = new FileReader()
    files.forEach((file) => {
      reader.onabort = () => setErrorValidationMessage('Something went wrong :(')
      reader.onerror = () => setErrorValidationMessage('Something went wrong :(')
      reader.onload = () => {
        if (!validateFile(file.size, file.type)) {
          return
        }
        setProfileImage(file)
      }
      reader.readAsArrayBuffer(file)
    })
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const setCroppedImage = async (): Promise<FormData | undefined> => {
    if (editor) {
      const dataUrl = editor.current.getImage().toDataURL()
      const result = await fetch(dataUrl)
      const blob = await result.blob()

      const file = new File([blob], 'ProfilePhoto.png', {
        type: 'image/png',
        lastModified: new Date().getTime(),
      })
      if (file) {
        setProfileImage(file)
      }

      const data = new FormData()
      data.append('profile-file', file as Blob, 'profileImage.png')

      return data
    }
  }

  const resetValuesBeforeReq = () => {
    setError('')
  }

  const navigate = useNavigate()

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    const redirectUrl = `/space/${user?.spaces[0]?.id}`
    if (profileImage) {
      resetValuesBeforeReq()
      setLoading(true)

      const data = await setCroppedImage()

      const config = {
        method: 'post',
        url: `${envVars.api.https}api/users/${user && user.id ? user.id : null}/photo`,
        withCredentials: true,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: data,
      }

      axios(config)
        .then(() => navigate(redirectUrl, { replace: true }))
        .catch((err) => {
          err?.response?.data?.errors[0]?.message
            ? setError(err?.response?.data?.errors[0]?.message)
            : setError('Something went wrong')
        })
        .finally(() => setLoading(false))
    } else navigate(redirectUrl, { replace: false })
  }

  return {
    onSubmit,
    getInputProps,
    profileImage,
    editor,
    getRootProps,
    imageScale,
    setImageScale,
    errorValidationMessage,
    error,
    loading,
  }
}
