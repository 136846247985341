import { Attachment, Avatar } from 'stream-chat-react'

import moment from 'moment'
import {
  MessageContent,
  MessageHeader,
  MessageHeaderName,
  MessageHeaderTimestamp,
  MessageWrapperContent,
} from '../../ChatCustomMessage/styles'
import { MessageWrapper } from './styles'
import { ExtendedMessageResponse } from 'interfaces/chat'
import { type block } from '_entities/block'
import { IPage } from 'interfaces/page'
import SelectedImageBlock from 'components/organisms/ChatCustomMessage/CustomMessage/SelectedImageBlock'
import SelectedTextBlock from 'components/organisms/ChatCustomMessage/CustomMessage/SelectedTextBlock'
import MessageContainer from 'components/organisms/ChatCustomMessage/CustomMessage/MessageContainer'
import { ExtendedPinnedMessageResponse } from 'components/organisms/ChatHeader/useChatHeader'

interface IProps {
  messageObject: ExtendedMessageResponse | ExtendedPinnedMessageResponse
  referencedBlock: block | null
  referencedPage: IPage | null
}

const PinnedMessage = ({ messageObject, referencedPage, referencedBlock }: IProps) => {
  const message = messageObject as any

  return (
    <MessageWrapper className='message-wrapper'>
      <MessageWrapperContent>
        <MessageHeader>
          <Avatar image={message.user?.image} name={message?.user?.name} size={24} />
          <MessageHeaderName>{message.user?.name}</MessageHeaderName>
          <MessageHeaderTimestamp>
            <time>{moment(message?.created_at).format('h:mmA')}</time>
          </MessageHeaderTimestamp>
        </MessageHeader>

        <MessageContent className='message-content'>
          {referencedBlock && message?.type !== 'deleted' ? (
            <>
              {referencedBlock?.data?.tag === 'img' ? (
                <SelectedImageBlock
                  referencedBlock={referencedBlock}
                  referencedPage={referencedPage}
                />
              ) : (
                <SelectedTextBlock
                  referencedBlock={referencedBlock}
                  referencedPage={referencedPage}
                />
              )}

              <MessageContainer message={message} />
            </>
          ) : (
            <MessageContainer message={message} />
          )}
        </MessageContent>

        {message?.attachments?.length > 0 && <Attachment attachments={message.attachments || []} />}
      </MessageWrapperContent>
    </MessageWrapper>
  )
}

export default PinnedMessage
