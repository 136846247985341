import { emojiRegExpString, unifiedRegExpString, unifiedToEmoji } from './regex'

export const getNative = (code: string) => {
  // If native return native, if unified convert to native, if neither undefined
  return RegExp(emojiRegExpString).test(code)
    ? code
    : RegExp(unifiedRegExpString).test(code)
    ? unifiedToEmoji(code)
    : undefined
}
