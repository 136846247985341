import { type block } from '_entities/block'
import { IArrowItem, ITools, Transposition } from 'interfaces/whiteboard'
import { KonvaEventObject } from 'konva/lib/Node'
import React, { useEffect, useRef, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { Stage } from 'konva/lib/Stage'
import { setSelectedBlock } from 'redux/reducers/whiteboardReducer'
import { usePreviewForReferences } from 'utils/usePreviewForReferences'
import {
  useTransformer,
  useArrow,
  useDrawing,
  useStage,
  useMouse,
  useSelection,
  useZoom,
  IConnector,
  useConnectors,
  useMove,
  useBoundingBox,
} from '_features/canvas'
import { useWhiteboardBlock } from '_features/block'
import { ShapeOptionsType, useShape, useShapeCreation } from '_entities/shape'
import { useGrid, getGridLayerId, useFrame } from '_entities/whiteboard'
import { useEvent } from '_features/canvas/event'
import { useCanvasDrag } from '_features/drag'
import { getById, setGrab, setGrabbing, setInitial } from 'shared/lib'
import { LEFT_SIDEBAR_ID } from '_widgets/LeftSidebar'
import { useWhiteboardEmbed } from '_entities/embed'
import {
  removeControlsEnabled,
  removeShouldShowControls,
  setControlsEnabled,
  setShouldShowControls,
} from '../presentation/model/dom-attributes'
interface Props {
  pageId: string
  blocks: block[] | undefined
  isEmbed?: boolean
  isSlideShow?: boolean
  parentPageId?: string
}

export interface StageChangeEvent extends KonvaEventObject<any> {
  newVal: number
  oldVal: number
}

export const getBoundingBoxId = (pageId: string) => {
  return `bounding-box-${pageId}`
}

export const useWhiteboard = (props: Props) => {
  const { pageId, blocks } = props
  const { resetSelectedBlocksForReference } = usePreviewForReferences()
  const _transformer = useTransformer()
  const _mouse = useMouse()
  const _move = useMove()
  const _selection = useSelection()
  const _zoom = useZoom()
  const _event = useEvent()
  const dispatch = useAppDispatch()
  const _stage = useStage()
  const _grid = useGrid()
  const _shape = useShape()
  const _shapeCreation = useShapeCreation()
  const _arrow = useArrow()
  const _drawing = useDrawing()
  const _whiteboardBlock = useWhiteboardBlock()
  const _frame = useFrame()
  const _boundingBox = useBoundingBox()

  const [isDraggingStage, setIsDraggingStage] = useState<boolean>(false)

  const [isWhiteboardLoaded, setIsWhiteboardLoaded] = useState<boolean>(false)
  const [isStageLoaded, setIsStageLoaded] = useState<boolean>(false)

  const [connectionPreviewArrow, setConnectionPreviewArrow] = useState<IArrowItem | null>(null)
  const [connectors, setConnectors] = useState<IConnector[]>([])
  const _connectors = useConnectors({
    connectionPreviewArrow,
    setConnectionPreviewArrow,
    connectors,
    setConnectors,
  })
  const _canvasDrag = useCanvasDrag({
    connectionPreviewArrow,
    setConnectionPreviewArrow,
    connectors,
    setConnectors,
  })

  const colorPalette = useAppSelector((state) => state.global.colorPalette)
  const selectedColorPalette = useAppSelector((state) => state.global.selectedColorPalette)
  const tool = useAppSelector((state) => state.whiteboard.tool)

  const handleConnectionAnchor = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    if (e.target.hasName('connection-anchor')) {
      const pageId = _event.getPageIdFromEvent(e)
      const scaledPosition = _stage.getScaledPointerPosition(pageId)
      setConnectionPreviewArrow({
        xStart: scaledPosition?.x ?? 0,
        yStart: scaledPosition?.y ?? 0,
        xEnd: scaledPosition?.x ?? 0,
        yEnd: scaledPosition?.y ?? 0,
      })
    }
  }

  const handleClick = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    if (checkIfEmptyMouseDown(e)) {
      resetSelectedBlocksForReference()
    }
  }

  const handMouseDown = () => {
    if (tool === ITools.HAND) {
      setGrabbing()
    }
  }

  const shapeMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    if (_shape.ShapeOptions.includes(tool)) {
      const stageId = e.target.getStage()?.attrs.id
      const docId = _stage.getDocIdFromStageId(stageId)

      _shapeCreation.setIsShapeMouseDown(docId)
      _grid.showShadow(pageId)
    }
  }

  const stageMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    _mouse.cursorMouseDown(e)
    handMouseDown()
    _drawing.penMouseDown(e)
    _arrow.arrowMouseDown(e)
    shapeMouseDown(e)
  }

  const checkIfEmptyMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const pageId = _stage.getDocIdFromStageId(e.target.getStage()?.attrs.id)
    const gridLayerId = getGridLayerId(pageId)
    const selectedNode = e.target.parent
    const isMouseOnGridLayer = selectedNode?.getAttr('id') === gridLayerId
    // This is official Konva mouse on stage checker with their ids
    const isMouseOnStage = e.target._id === e.currentTarget._id
    const isFrameInEmbeddedDocCanvas =
      selectedNode && _frame.isNodeFrameInsideCanvasInsideDoc(selectedNode)
    return isMouseOnStage || isMouseOnGridLayer || isFrameInEmbeddedDocCanvas
  }

  const handleMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    _selection.removeSelectedBlock()
    if (checkIfEmptyMouseDown(e)) {
      stageMouseDown(e)
    } else {
      _selection.handleSelection(e)
      handleConnectionAnchor(e)
    }
  }

  const handleMouseUp = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const docId = _event.getPageIdFromEvent(e)
    _mouse.selectMultipleNodes(docId)
    _boundingBox.removeBoundingBoxes(docId)
    if (tool === ITools.HAND) {
      setGrab()
    } else setInitial()

    if (_shapeCreation.getShapeCreationDragBlock(docId)) {
      _shapeCreation.saveNewShapeBlockAfterDrag(docId)
    }
    _whiteboardBlock.newBlockTypeConfig(pageId)[tool]()
    dispatchSelectedBlock(pageId)
    _shapeCreation.removeIsShapeMouseDown(docId)
    _shapeCreation.removeShapeCreationDragBlock(docId)
  }

  const handleMouseMove = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const docId = _event.getPageIdFromEvent(e)
    _boundingBox.updateBoundingBox(docId)
    _shape.beforeMouseDownShapeMouseMove(e)
    const isShapeMouseDown = _shapeCreation.getIsShapeMouseDown(docId)
    if (isShapeMouseDown && _shape.ShapeOptions.includes(tool)) {
      // Size is 5px so that shape size starts small so that it can be resized on drag
      // when we want to create a shape by holding mouse down and moving
      _whiteboardBlock
        .newShapeConfig(pageId, { width: 5, height: 5 }, true)
        [tool as ShapeOptionsType]()
      _shapeCreation.removeIsShapeMouseDown(docId)
    }
    _shapeCreation.resizeShapeOnCreation(docId, e)
    _drawing.penMouseMove(e)
    _arrow.arrowMouseMove(e)
  }

  const calculateBlockToolbarPosition = (): Transposition => {
    const DEFAULT_Y = -10
    let transposeX = _transformer.getTransformerSize(props.pageId).width / 2
    let transposeY = DEFAULT_Y
    const leftSidebar = document.getElementById(LEFT_SIDEBAR_ID)
    const rightSidebar = document.getElementById('right-sidebar')
    const toolbarElement = getById(_transformer.WHITEBOARD_BLOCK_TOOLBAR_ID)
    const transformer = _transformer.getTransformer(pageId)
    if (!toolbarElement || !leftSidebar || !rightSidebar || !transformer) {
      return { transposeX, transposeY }
    }

    const toolbarRect = toolbarElement.getBoundingClientRect()
    const leftSidebarRect = leftSidebar.getBoundingClientRect()
    const rightSidebarRect = rightSidebar.getBoundingClientRect()
    const transformerRect = transformer.getClientRect()
    const toolbarWidth = toolbarRect.width
    const toolbarY = toolbarRect.y
    const leftSidebarWidth = leftSidebarRect.width
    const rightSidebarX = rightSidebarRect.x
    const transformerX = transformerRect.x
    const minimumX = leftSidebarWidth
    const maximumX = rightSidebarX

    if (toolbarY < 0) {
      transposeY = DEFAULT_Y - toolbarY
    }

    if (transformerX < minimumX) {
      transposeX = transformerX - minimumX
      const sign = Math.sign(transposeX)
      transposeX = transposeX * sign
    } else if (transformerX + toolbarWidth > maximumX) {
      transposeX = maximumX - (transformerX + toolbarWidth + 20)
    }

    return { transposeX, transposeY }
  }

  const dispatchSelectedBlock = (pageId: string) => {
    const selectedBlock = _transformer.getSingleBlockFromTransformer(pageId)
    if (selectedBlock) {
      dispatch(setSelectedBlock(selectedBlock))
    }
  }

  const handleStageDragMove = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const pageId = _event.getPageIdFromEvent(e)
    _grid.drawLines(pageId)
  }

  useEffect(() => {
    const colors = colorPalette?.attributes.colors

    const whiteboardDotsColor = colors?.find((color) => color.type === 'whiteboard-dots')

    if (whiteboardDotsColor) {
      _grid.drawLines(pageId, whiteboardDotsColor)
    }
  }, [colorPalette, selectedColorPalette])

  useEffect(() => {
    _connectors.generateConnectors(pageId)
    if (blocks === undefined) {
      setIsStageLoaded(false)
    } else {
      // Detect if frame blocks exist and enable or disable presentation controls
      const frameBlocks = _frame.getFrameBlocks(pageId)
      if (frameBlocks && frameBlocks.length > 0) {
        setShouldShowControls(pageId)
        if (frameBlocks.length === 1) {
          removeControlsEnabled(pageId)
        } else {
          setControlsEnabled(pageId)
        }
      } else removeShouldShowControls(pageId)
    }
  }, [blocks])

  useEffect(() => {
    _connectors.updateConnectors(pageId)
  }, [connectors])

  useEffect(() => {
    const stage = _stage.getStage(pageId)
    if (!stage) return

    _grid.createShadow(pageId)
    _move.setInitialStagePosition(pageId)
    _zoom.setInitialStageScale(pageId)

    stage.on('xChange yChange', handlePageStageMoveListener)
    stage.on('scaleXChange scaleYChange', handlePageStageScaleListener)

    return () => {
      stage.off('xChange yChange', handlePageStageMoveListener)
      stage.off('scaleXChange scaleYChange', handlePageStageScaleListener)
    }
  }, [isStageLoaded])

  useEffect(() => {
    setIsWhiteboardLoaded(true)
  }, [])

  const handlePageStageMoveListener = (e: KonvaEventObject<any>) => {
    _move.handleStageMoveListener(pageId, e)
  }
  const handlePageStageScaleListener = (e: KonvaEventObject<any>) => {
    _zoom.handleStageScaleListener(pageId, e)
  }

  return {
    isDraggingStage,
    setIsDraggingStage,
    isWhiteboardLoaded,
    isStageLoaded,
    setIsStageLoaded,
    transformerRef: _transformer.transformerRef,
    handleClick,
    handleMouseDown,
    handleMouseMove,
    handleMouseUp,
    handleStageDragMove,
    dispatch,
    pageId,
    connectionPreviewArrow,
    setConnectionPreviewArrow,
    connectors,
    setConnectors,
    calculateBlockToolbarPosition,
    _canvasDrag,
  }
}

export type UseWhiteboardType = ReturnType<typeof useWhiteboard>
