import React from 'react'
import { Link } from 'react-router-dom'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import Card from 'components/organisms/Card/Card'
import AuthWrapper from 'components/templates/AuthWrapper/AuthWrapper'
import CustomP from 'components/atoms/CustomP/CustomP'

const PasswordCreated: React.FC = () => {
  return (
    <AuthWrapper
      elements={
        <Card
          titleText='Thanks, your password has been changed'
          secondaryText='You can proceed to login'
          textBlack
          shadow
          radius
          fontSize
          cardElements={
            <Link className='text-primary' to='/signin'>
              <CustomButton className='btn btn-primary w-50' height='40px'>
                <CustomP className='mb-0'>OK</CustomP>
              </CustomButton>
            </Link>
          }
        />
      }
    />
  )
}

export default PasswordCreated
