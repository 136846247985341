import {
  MainContainer,
  EverydayLogoIcon,
  LogoContainer,
  ShareProjectButton,
  UserPlusIcon,
  LogoDropdownContainer,
  CaretUpDownIcon,
  ProjectsDropdownContainer,
} from './styles'
import { IRoles } from 'interfaces/invite'
import ProjectFileInviteModal from 'components/templates/ProjectFileInviteModal/ProjectFileInviteModal'
import { useProjectFileSidebarTopElement } from './useProjectFileSidebarTopElement'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import RadixDropdown, {
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { StyledActionText } from 'components/molecules/PendingMembersItem/styles'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'

interface ProjectFileSidebarTopElement {
  myRole: string
}

const ProjectFileSidebarTopElement = ({ myRole }: ProjectFileSidebarTopElement) => {
  const {
    modalShow,
    setModalShow,
    handleSpaceModalClose,
    currentProjectFile,
    allProjectFiles,
    navigate,
    params,
    onLogoClick,
    setShowTooltipProjectName,
    showTooltipProjectName,
  } = useProjectFileSidebarTopElement()

  return (
    <MainContainer>
      <LogoDropdownContainer>
        <LogoContainer onClick={onLogoClick}>
          <EverydayLogoIcon />
        </LogoContainer>
        <RadixDropdown
          overflow='scroll'
          trigger={
            <ProjectsDropdownContainer>
              <CustomP color={getColor('--primary-text')}>
                {currentProjectFile?.title.length && currentProjectFile?.title.length > 12
                  ? `${currentProjectFile?.title.substring(0, 12)}...`
                  : `${currentProjectFile?.title}`}
              </CustomP>
              <CaretUpDownIcon />
            </ProjectsDropdownContainer>
          }
        >
          {allProjectFiles.map((projectFile: any) => {
            return (
              <DropdownMenuItem
                key={`project-file-${projectFile.value}`}
                onClick={() => {
                  if (params && params.spaceId)
                    navigate(`/spaces/${params.spaceId}/project-file/${projectFile.value}`)
                }}
              >
                <StyledActionText>{projectFile.name}</StyledActionText>
              </DropdownMenuItem>
            )
          })}
        </RadixDropdown>
      </LogoDropdownContainer>

      <ShareProjectButton
        onClick={() => {
          if (myRole === IRoles.OWNER) {
            setModalShow(true)
          } else return
        }}
        opacity={myRole === IRoles.OWNER ? '1' : '0.2'}
      >
        <UserPlusIcon />
      </ShareProjectButton>
      {myRole === IRoles.OWNER && (
        <ProjectFileInviteModal
          modalShow={modalShow}
          setModalShow={setModalShow}
          handleSpaceModalClose={handleSpaceModalClose}
        />
      )}

      <Tooltip
        message={`${currentProjectFile?.title}`}
        active={showTooltipProjectName}
        width='fit-content'
        aligntext={'center'}
        padding={'8px'}
        top={'44px'}
        left={'74px'}
      />
    </MainContainer>
  )
}

export default ProjectFileSidebarTopElement
