import React from 'react'
import { StyledFlexContainer } from './styles'
type Props = {
  children: React.ReactNode
  flexDirection?: string
  justifyContent?: string
  alignItems?: string
  className?: string
  height?: string
  gap?: string
  margin?: string
  padding?: string
  position?: string
}
const FlexContainer = ({
  children,
  flexDirection,
  justifyContent,
  alignItems,
  className,
  height,
  gap,
  margin,
  padding,
  position,
}: Props) => {
  return (
    <StyledFlexContainer
      flexDirection={flexDirection}
      justifyContent={justifyContent}
      alignItems={alignItems}
      className={className}
      height={height}
      gap={gap}
      margin={margin}
      padding={padding}
      position={position}
    >
      {children}
    </StyledFlexContainer>
  )
}
export default FlexContainer
