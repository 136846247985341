import styled from 'styled-components'
import { ReactComponent as ChevronRightIcon } from 'assets/images/chevron-right-black.svg'

export type ChevronRightIconRotatingProps = {
  turn?: boolean
  hide?: boolean
}

export const PaletteWrapper = styled.div<{ isSelected?: boolean }>`
  height: auto;
  width: 100%;
  border-radius: 7px;
  border: ${(props) =>
    props.isSelected ? '1px solid var(--primary-color)' : '1px solid var(--secondary-text)'};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
`

export const PaletteOverview = styled.div`
  display: flex;
  justify-content: space-between;
  height: 46px;
  width: 100%;
`

export const ChevronRightIconRotating = styled(ChevronRightIcon)<ChevronRightIconRotatingProps>`
  transition: 0.1s;
  height: 16px;
  width: 16px;
  transform: ${(props) => (props.turn ? 'rotate(90deg)' : 'rotate(0deg)')};
  opacity: ${(props) => (props.hide ? 0 : 1)};

  path {
    stroke: var(--primary-text);
  }
`

export const DetailedColorsWrapper = styled.div``
