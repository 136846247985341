// ** Type Imports
import { TAGS } from 'shared/api'
import { managerTasksApi } from 'shared/api/managerTasksApi'
import {
  CreateTaskRequestType,
  DeleteTaskRequestType,
  GetManagerTasksRequestType,
  GetManagerTasksResponseType,
  UpdateTaskRequestType,
} from '../model'
import { ITask } from 'interfaces/taskManager'

export const tasksApi = managerTasksApi.injectEndpoints({
  endpoints: (build) => ({
    getTasks: build.query<GetManagerTasksResponseType, GetManagerTasksRequestType>({
      query: ({ managerId }) => `${managerId}/tasks`,
      providesTags: [TAGS.TASK],
    }),
    createTask: build.mutation<ITask, CreateTaskRequestType>({
      query: ({ data, managerId }) => ({
        url: `${managerId}/tasks`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
      invalidatesTags: [TAGS.TASK],
    }),
    deleteTask: build.mutation<ITask, DeleteTaskRequestType>({
      query: ({ taskId, managerId }) => ({
        url: `${managerId}/tasks/${taskId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [TAGS.TASK],
    }),
    updateTask: build.mutation<ITask, UpdateTaskRequestType>({
      query: ({
        taskId,
        title,
        type,
        statusValueId,
        assigneeId,
        workstreamId,
        pageId,
        positionIndex,
        blockId,
        managerId,
      }) => ({
        url: `${managerId}/tasks/${taskId}`,
        method: 'PATCH',
        body: {
          title,
          type: type || 'document',
          statusValueId,
          assigneeId,
          workstreamId,
          pageId,
          positionIndex,
          blockId,
        },
      }),
      invalidatesTags: [TAGS.TASK],
    }),
  }),
})
