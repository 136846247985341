import IFrameEmbed from 'components/atoms/IFrameEmbed/IFrameEmbed'
import { PageName } from '_entities/page'
import { type block } from '_entities/block'
import { IPage } from 'interfaces/page'
import { useEmbed } from '_entities/embed'
import { useState } from 'react'
import { BasicCallout } from 'shared/ui'
import { useIcons } from 'shared/icons'

interface IProps {
  referencedBlock: block
  referencedPage: IPage | null
}

export const IFrameBlockPreview = ({ referencedBlock, referencedPage }: IProps) => {
  const [expanded, setExpanded] = useState(false)
  const _embed = useEmbed()
  const _icons = useIcons()
  return (
    <>
      {_embed.isOnlyLink(referencedBlock) && (
        <BasicCallout
          onClick={() => window.open(_embed.getUrl(referencedBlock), '_blank')}
          block={referencedBlock}
          title={_embed.getExternalEmbedTitle(referencedBlock)}
          Icon={_icons.getIcon({ block: referencedBlock })}
        />
      )}
      {!_embed.isOnlyLink(referencedBlock) && expanded && (
        <IFrameEmbed
          width={'100%'}
          height={'300'}
          src={`${_embed.getUrl(referencedBlock)}?embed=true`}
        />
      )}
      {!_embed.isOnlyLink(referencedBlock) &&
        !expanded &&
        _embed.getExternalEmbedTitle(referencedBlock)}

      <PageName
        page={referencedPage}
        justifyContent='space-between'
        toggleExpand={() => setExpanded(!expanded)}
        isExpanded={expanded}
        shouldShowExpand={!_embed.isOnlyLink(referencedBlock)}
      />
    </>
  )
}
