import React from 'react'
import { IBlockTypes, type block } from '_entities/block'
import { DragSourceTypes } from 'interfaces/editor'
import { useAppSelector } from 'redux/hooks'
import { ChatDrawer } from '../ui/ChatDrawer'
import OnDropOverlay from 'components/templates/Page/DropOverlay/OnDropOverlay'
import { ReactComponent as CommentIcon } from 'assets/images/chat/message.svg'
import { generateNewDocumentBlock } from 'utils/editor/generateBlock'
import { getPage } from '_entities/page'
import { SidebarListItem } from 'components/molecules/SidebarList/styles'
import DraggableTree, { RENDER_ONLY_ITEM } from 'shared/dragging/ui/DraggableTree'
import { useComments } from '_features/comments'
import { useIcons } from 'shared/icons'

export const ChatTree = () => {
  const { handleOnCommentClick } = useComments()
  const project = useAppSelector((state) => state.projectFile.selectedProjectFile)
  const selectedBlock = useAppSelector((state) => state.page.selectedBlock)
  const currentUser = useAppSelector((state) => state.global.user)
  const _icons = useIcons()

  return (
    <DraggableTree
      items={[RENDER_ONLY_ITEM]}
      className='chat-tree-container'
      onDrop={async ({ droppedData }) => {
        if (droppedData) {
          if (
            droppedData.type === DragSourceTypes.DOCUMENT ||
            droppedData.type === DragSourceTypes.WHITEBOARD ||
            droppedData.type === DragSourceTypes.EMBED
          ) {
            if (!project) return
            const page = await getPage(project.id.toString(), droppedData.id)
            if (page && currentUser && currentUser.uuid) {
              const block = generateNewDocumentBlock(
                droppedData.type as unknown as IBlockTypes,
                page,
                currentUser.uuid,
              )
              if (block) handleOnCommentClick(block)
              return
            }
          }

          const block = selectedBlock?._id === droppedData.id ? selectedBlock : droppedData.data

          if (block) handleOnCommentClick(block as block)
        }
      }}
      render={(node, { isDropTarget }) => {
        return (
          <ChatDrawer
            dropOverlay={
              isDropTarget && (
                <OnDropOverlay text={'Drop here to write comment'} icon={<CommentIcon />} />
              )
            }
          />
        )
      }}
      dragPreviewRender={(item) => {
        if (
          item.type === IBlockTypes.DOCUMENT ||
          item.type === IBlockTypes.WHITEBOARD ||
          item.type === IBlockTypes.EMBED
        ) {
          const Icon = _icons.PageTypeIcon[item.type as unknown as keyof typeof _icons.PageTypeIcon]

          return (
            <SidebarListItem depth={1} isDragging active>
              <Icon /> {item.text}
            </SidebarListItem>
          )
        } else return <></>
      }}
    />
  )
}
