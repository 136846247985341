const TEXT_BLOCK_DEFAULT_WIDTH = 500
const TEXT_BLOCK_DEFAULT_HEIGHT = 50
const STICKY_NOTE_DEFAULT_WIDTH = 100
const STICKY_NOTE_DEFAULT_HEIGHT = 100
const SHAPE_DEFAULT_WIDTH = 100
const SHAPE_DEFAULT_HEIGHT = 100

export const constants = {
  TEXT_BLOCK_DEFAULT_WIDTH,
  TEXT_BLOCK_DEFAULT_HEIGHT,
  STICKY_NOTE_DEFAULT_WIDTH,
  STICKY_NOTE_DEFAULT_HEIGHT,
  SHAPE_DEFAULT_WIDTH,
  SHAPE_DEFAULT_HEIGHT,
}
export const MIME_TYPE_FOR_SHEET =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
export const MIME_TYPE_FOR_DOC =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
export const MIME_TYPE_FOR_SLIDE =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation'
