import styled from 'styled-components'
import { ReactComponent as Status } from 'assets/images/status.svg'
import { ReactComponent as Date } from 'assets/images/date.svg'
import { ReactComponent as Text } from 'assets/images/text.svg'
import { ReactComponent as Number } from 'assets/images/number.svg'
import { ReactComponent as SingleSelect } from 'assets/images/single_select.svg'
import { ReactComponent as Files } from 'assets/images/files.svg'
import { ReactComponent as Url } from 'assets/images/url.svg'
import { ReactComponent as Thumbsup } from 'assets/images/thumbsup.svg'

export const StatusIcon = styled(Status)``
export const PersonIcon = styled(Thumbsup)``
export const DateIcon = styled(Date)``
export const TextIcon = styled(Text)``
export const NumberIcon = styled(Number)``
export const SingleSelectIcon = styled(SingleSelect)``
export const FilesIcon = styled(Files)``
export const UrlIcon = styled(Url)``
