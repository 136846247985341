import { useEffect, useRef } from 'react'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { DropdownMenuItem } from './styles'
import { SlashMenuOptions, ExtendedIPages } from '../../model/types'

interface CustomOptionProps {
  option: SlashMenuOptions | ExtendedIPages
  onChange: ((newValue: unknown) => void) | undefined
  focused?: number
  index?: number
}

export const SlashOption = (props: CustomOptionProps) => {
  const optionRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (props.focused === props.index) {
      optionRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      })
    }
  }, [props.focused])

  return (
    <DropdownMenuItem
      key={`slash-option-${props.option.label}`}
      onClick={() => {
        props.onChange && props.onChange(props.option)
      }}
      // We added onKeyDown to override the default behavior of the dropdown menu on enter click
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          e.preventDefault()
          e.stopPropagation()
        }
      }}
      focused={props.focused === props.index}
      ref={optionRef}
    >
      <Wrapper display='flex' justifyContent='left' width='100%' alignItems='center' gap='8px'>
        <Wrapper width={'1.15rem'}>{props.option.icon}</Wrapper>
        {props.option.label}
      </Wrapper>
    </DropdownMenuItem>
  )
}
