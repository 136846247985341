import { UpdateTaskRequestType } from '../model'
import { tasksApi } from '../api'
import { getPropertyValue } from 'utils/taskManager/getPropertyValue'
import { ITaskProperty } from 'interfaces/taskManager'
import { useAppSelector } from 'redux/hooks'

export const useUpdateTask = () => {
  // ** Network
  const [handleUpdateTask] = tasksApi.endpoints.updateTask.useMutation()

  // * State
  const propertyDefinitions = useAppSelector((state) => state.taskManager.propertyDefinitions)
  const tasks = useAppSelector((state) => state.taskManager.tasks)

  const updateTask = async ({
    taskId,
    title,
    type,
    statusValueId,
    assigneeId,
    workstreamId,
    pageId,
    positionIndex,
    blockId,
    managerId,
  }: UpdateTaskRequestType) => {
    await handleUpdateTask({
      taskId,
      title,
      type,
      statusValueId,
      assigneeId,
      workstreamId,
      pageId,
      positionIndex,
      blockId,
      managerId,
    }).unwrap()
  }

  const updateTaskProperties = (
    taskId: string,
    propertyDefinitionId: number,
    propertyData: ITaskProperty,
  ) => {
    return tasks?.map((task) => {
      if (task.id !== taskId) return task

      const properties = task.properties?.map((property) => {
        if (property.propertyDefinitionId !== propertyDefinitionId) return property

        const propertyDefinition = propertyDefinitions?.find(
          (propDef) => propDef.id === property.propertyDefinitionId,
        )

        const newProperty = {
          ...propertyData,
          propertyDefinition,
        }

        return {
          ...newProperty,
          value: getPropertyValue(newProperty as ITaskProperty),
        }
      })

      return {
        ...task,
        properties,
      }
    })
  }

  return {
    updateTask,
    updateTaskProperties,
  }
}
