import styled from 'styled-components'
import { Modal } from 'react-bootstrap'

type ImageWrapperPropsType = {
  scale?: number
}

type SizeIconsPropsType = {
  isDisabled?: boolean
}

import { ReactComponent as DiagonalCloseStyled } from 'assets/images/closeDiagonal.svg'
import { ReactComponent as DownloadIconStyled } from 'assets/images/DownloadIcon.svg'
import { ReactComponent as PlusStyled } from 'assets/images/crossPlusIcon.svg'
import { ReactComponent as MinusStyled } from 'assets/images/hyphenIcon.svg'

export const ImageModal = styled(Modal)`
  /* wholeShowImageModal */
  background-color: var(--main-black-semitransparent);
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;

  & img {
    max-height: 80vh;
  }
`

export const ModalInnerContainer = styled.div`
  height: auto;
  overflow: hidden;
  padding: 0;
  margin: 0;
  padding-bottom: 2.65rem;
  position: relative;
  border: none;
`

export const ImageOptions = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%) scale(0.8);
  width: fit-content;
  bottom: -2.65rem;
  height: auto;
  background: var(--white);
  border-radius: 7px;
  padding: 0.35rem 0.5rem !important;
  display: flex;
  align-items: center;
  gap: 0.85rem;
  filter: drop-shadow(0rem 0rem 2rem rgba(0, 0, 0, 0.15));
  z-index: 100;
`

export const DiagonalCloseIcon = styled(DiagonalCloseStyled)`
  cursor: pointer;
  transform: scale(1.2);
  border-radius: 7px;

  :hover {
    background: var(--hover);
  }
`

export const DownloadIcon = styled(DownloadIconStyled)`
  cursor: pointer;
  transform: scale(1.2);
  border-radius: 7px;

  :hover {
    background: var(--hover);
  }
`

export const PlusIcon = styled(PlusStyled)<SizeIconsPropsType>`
  cursor: pointer;
  transform: scale(1.1);
  border-radius: 7px;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.35' : '1')};

  :hover {
    background: var(--hover);
  }
`

export const MinusIcon = styled(MinusStyled)<SizeIconsPropsType>`
  cursor: pointer;
  transform: scale(1.1);
  border-radius: 7px;
  opacity: ${({ isDisabled }) => (isDisabled ? '0.35' : '1')};

  :hover {
    background: var(--hover);
  }
`

export const SizeText = styled.p`
  color: var(--gray2);
  padding: 0;
  margin: 0;
  align-self: center;
  user-select: none;
`

export const ImageWrapper = styled.div<ImageWrapperPropsType>`
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;

  & img {
    transform: scale(${({ scale }) => (scale ? scale : 1)});
    user-select: none;
  }
`
