import { type block } from '_entities/block'
import { textBlockOptions } from 'components/organisms/ProjectFileRightSideBar/useTextBlockOptions'
import {
  TextFormattingButtonContainer,
  TextFormattingDropdownMenuBody,
  TextFormattingDropdownMenuContainer,
} from './styles'
import React from 'react'

interface Props {
  block: block
  setOpenedDropdown?: React.Dispatch<React.SetStateAction<string | null>>
}

const TextFormattingMenu = (props: Props) => {
  return (
    <TextFormattingDropdownMenuContainer>
      <TextFormattingDropdownMenuBody bottomLine>
        {textBlockOptions(props.block).map((item) => (
          <TextFormattingButtonContainer
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation()
              item.onClick()
              props.setOpenedDropdown && props.setOpenedDropdown(null)
            }}
            key={`toolbar-formatting-${props.block._id}`}
          >
            {item.element}
          </TextFormattingButtonContainer>
        ))}
      </TextFormattingDropdownMenuBody>
    </TextFormattingDropdownMenuContainer>
  )
}

export default TextFormattingMenu
