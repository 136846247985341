import { useState } from 'react'
import { MessageResponseWithBlock } from 'interfaces/chat'

interface IProps {
  message: MessageResponseWithBlock
}

const useProjectSidebarMessage = (props: IProps) => {
  const { message } = props
  const [isShowMore, setIsShowMore] = useState<boolean>(true)

  const user = message.user

  const handleShowMore = (e: React.MouseEvent) => {
    e.stopPropagation()
    setIsShowMore(!isShowMore)
  }

  return {
    isShowMore,
    user,
    handleShowMore,
  }
}

export default useProjectSidebarMessage
