import RadixDropdown, {
  IconButton,
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import { type block } from '_entities/block'
import CustomP from 'components/atoms/CustomP/CustomP'
import { IconButtonSize } from '_features/toolbar'
import { useZoom } from '_features/canvas'
import { useDeleteBlock } from '_features/block'
import useBlockToolbar from 'editor/EditableBlock/BlockToolbar/useBlockToolbar'

interface Props {
  block: block
  isWhiteboard?: boolean
}

const ThreeDotsDropdown = (props: Props) => {
  const _zoom = useZoom()
  const _deleteBlock = useDeleteBlock()
  const _blockToolbar = useBlockToolbar()

  return (
    <RadixDropdown
      trigger={
        <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
          <icons.dots />
        </IconButton>
      }
    >
      {props.isWhiteboard && (
        <>
          <DropdownMenuItem onSelect={() => _blockToolbar.sendToFront(props.block)}>
            Bring to front
          </DropdownMenuItem>
          <DropdownMenuItem onSelect={() => _blockToolbar.sendToBack(props.block)}>
            Send to back
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() => {
              _zoom.handleFocus(props.block)
            }}
          >
            Focus
          </DropdownMenuItem>
        </>
      )}

      <DropdownMenuItem
        onMouseDown={(e) => {
          e.preventDefault()
          _deleteBlock.removeBlock(props.block)
        }}
      >
        <CustomP color='var(--everyday-red)'>Delete</CustomP>
      </DropdownMenuItem>
    </RadixDropdown>
  )
}

export default ThreeDotsDropdown
