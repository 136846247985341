import React from 'react'
import Header from 'components/organisms/Header/Header'
import styled from 'styled-components'
import { Container } from 'react-bootstrap'
import { StyledHeaderWrapper } from './styles'

const HeaderWrapperComponent = styled.div`
  ${StyledHeaderWrapper}
`

type Props = {
  children: React.ReactNode
}

const HeaderWrapper: React.FC<Props> = ({ children }) => {
  return (
    <HeaderWrapperComponent>
      <Container className='d-flex align-items-center justify-content-center w-100 h-100'>
        {children}
      </Container>
    </HeaderWrapperComponent>
  )
}

export default HeaderWrapper
