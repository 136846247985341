import React from 'react'
import Checkbox from 'components/atoms/Checkbox/Checkbox'
import RadioButton from 'components/atoms/RadioButton/RadioButton'
import {
  IndentedParagraph,
  ModalInfoLabel,
  ModalInfoLabelSmall,
  NotificationTabUserModalStyled,
} from './styles'
import { useNotificationsTabUserModal } from './useNotificationsTabUserModal'

const NotificationTabUserModal = () => {
  const {
    inAppNotificationsOn,
    setInAppNotificationsOn,
    emailAppNotificationsOn,
    setEmailAppNotificationsOn,
  } = useNotificationsTabUserModal()
  return (
    <NotificationTabUserModalStyled>
      <ModalInfoLabel>In-app notifications</ModalInfoLabel>
      <Checkbox
        label={'Send me in-app notifications'}
        id={'in-app-notifications-main'}
        onChange={(e) => setInAppNotificationsOn(e.currentTarget.checked)}
        fontSize='14px'
        fontWeight='500'
        dimensions='18px'
        checked={inAppNotificationsOn}
      />
      <IndentedParagraph>
        <ModalInfoLabelSmall>Page comments and mentions</ModalInfoLabelSmall>
        <RadioButton
          label={'All comments and mentions on my pages'}
          name={'in-app-choice'}
          id={'in-app-choice-one'}
          disabled={!inAppNotificationsOn}
          fontSize='14px'
          fontWeight='500'
          marginBlock='0 -0.35rem'
        />
        <RadioButton
          label={'Only mentions of me and replies'}
          name={'in-app-choice'}
          id={'in-app-choice-two'}
          disabled={!inAppNotificationsOn}
          fontSize='14px'
          fontWeight='500'
          marginBlock='0 -0.35rem'
        />
        <RadioButton
          label={'None'}
          name={'in-app-choice'}
          id={'in-app-choice-three'}
          disabled={!inAppNotificationsOn}
          fontSize='14px'
          fontWeight='500'
          marginBlock='0 -1rem'
        />
      </IndentedParagraph>
      <hr className='colorForHr' />
      <ModalInfoLabel>Email Notifications</ModalInfoLabel>
      <Checkbox
        label={'Send me email notifications'}
        id={'email-app-notifications-main'}
        onChange={(e) => setEmailAppNotificationsOn(e.currentTarget.checked)}
        fontSize='14px'
        fontWeight='500'
        dimensions='18px'
        checked={emailAppNotificationsOn}
      />
      <IndentedParagraph>
        <ModalInfoLabelSmall>Page comments and mentions</ModalInfoLabelSmall>
        <RadioButton
          disabled={!emailAppNotificationsOn}
          label={'All comments and mentions on my pages'}
          name={'email-app-choice'}
          id={'email-app-choice-one'}
          fontSize='14px'
          fontWeight='500'
          marginBlock='0 -0.35rem'
        />
        <RadioButton
          disabled={!emailAppNotificationsOn}
          label={'Only mentions of me and replies'}
          name={'email-app-choice'}
          id={'email-app-choice-two'}
          fontSize='14px'
          fontWeight='500'
          marginBlock='0 -0.35rem'
        />
        <RadioButton
          label={'None'}
          name={'email-app-choice'}
          id={'email-app-choice-three'}
          disabled={!emailAppNotificationsOn}
          fontSize='14px'
          fontWeight='500'
          marginBlock='0 0.5rem'
        />
      </IndentedParagraph>
      <IndentedParagraph>
        <ModalInfoLabelSmall>Invites and requests</ModalInfoLabelSmall>
        <Checkbox
          disabled={!emailAppNotificationsOn}
          label={'Invites and requests that need my response'}
          id={'email-app-notifications-secound'}
          fontSize='13px'
          fontWeight='500'
          marginBlock='0 0.5rem'
          dimensions='18px'
        />
      </IndentedParagraph>
      <IndentedParagraph>
        <ModalInfoLabelSmall>Weekly summary</ModalInfoLabelSmall>
        <Checkbox
          disabled={!emailAppNotificationsOn}
          label={'Weekly summary of activities in my projects'}
          id={'email-app-notifications-third'}
          fontSize='13px'
          fontWeight='500'
          marginBlock='0 3rem'
          dimensions='18px'
        />
      </IndentedParagraph>
    </NotificationTabUserModalStyled>
  )
}

export default NotificationTabUserModal
