import { type ITableAction, type ITableColumn, type ITableData } from '_entities/block'

import React, { useMemo } from 'react'
import { useTable, useBlockLayout, useResizeColumns, useSortBy, HeaderGroup } from 'react-table'
import Cell from './cells/Cell'
import Header from './header/Header'

const defaultColumn = {
  minWidth: 50,
  width: 150,
  maxWidth: 400,
  Cell: Cell,
  Header: Header,
  sortType: 'alphanumericFalsyLast',
}

interface Props {
  columns: ITableColumn[]
  data: ITableData[]
  dataDispatch: React.Dispatch<ITableAction>
  skipReset: boolean
}

export const useTableComponent = ({ columns, data, dataDispatch, skipReset }: Props) => {
  const sortTypes = useMemo(
    () => ({
      alphanumericFalsyLast(rowA: any, rowB: any, columnId: any, desc: any) {
        if (!rowA.values[columnId] && !rowB.values[columnId]) {
          return 0
        }

        if (!rowA.values[columnId]) {
          return desc ? -1 : 1
        }

        if (!rowB.values[columnId]) {
          return desc ? 1 : -1
        }

        return isNaN(rowA.values[columnId])
          ? rowA.values[columnId].localeCompare(rowB.values[columnId])
          : rowA.values[columnId] - rowB.values[columnId]
      },
    }),
    [],
  )

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, totalColumnsWidth } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        dataDispatch,
        autoResetSortBy: !skipReset,
        autoResetFilters: !skipReset,
        autoResetRowState: !skipReset,
        sortTypes,
      } as any,
      useBlockLayout,
      useResizeColumns,
      useSortBy,
    )

  interface UpdatedHeaderGroup extends HeaderGroup<object> {
    isResizing: boolean
  }
  function isTableResizing() {
    for (const headerGroup of headerGroups) {
      for (const column of headerGroup.headers as UpdatedHeaderGroup[]) {
        if (column.isResizing) {
          return true
        }
      }
    }

    return false
  }

  return {
    getTableProps,
    headerGroups,
    getTableBodyProps,
    rows,
    prepareRow,
    totalColumnsWidth,
    isTableResizing,
  }
}
