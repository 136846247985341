export const isSafari = () => {
  /* This code for detecting Safari is reused from the Internet. In short terms, I have tested five or so solutions and this one seems to be the most perfect, although every detection can have some kind of edge cases for certain conditions, but rarely. */

  return (
    navigator.vendor.match(/apple/i) &&
    !navigator.userAgent.match(/crios/i) &&
    !navigator.userAgent.match(/fxios/i) &&
    !navigator.userAgent.match(/Opera|OPT\//)
  )
}
