import {
  ITaskProperty,
  IPropertyTypes,
  IPropertyDefinition,
  IStatusCategoriesEnum,
} from 'interfaces/taskManager'
import PropertyChip from 'components/atoms/PropertyChip/PropertyChip'
import { CustomDropdown } from 'components/molecules/CustomDropdown/CustomDropdown'
import { GroupBase, MultiValue, OptionsOrGroups, SingleValue } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import { useAppSelector } from 'redux/hooks'
import CustomP from 'components/atoms/CustomP/CustomP'
import { BasicInput, BasicLabel } from 'components/atoms/BasicInput/BasicInput'
import { useRef, useState } from 'react'
import {
  PaperClipIcon,
  StyledInputContainer,
  StyledUploadedFile,
  StyledChipWrapper,
} from 'components/organisms/TaskManagerModal/styles'
import { constructFormData } from 'utils/constructFormData'
import { AxiosService } from 'services/axiosService/axiosService'
import ProgressIndicator from 'components/atoms/ProgressIndicator/ProgressIndicator'
import { envVars } from 'config/env'
import { Anchor } from 'components/atoms/Anchor/Anchor'
import Avatar from 'components/atoms/Avatar/Avatar'
import SingleSelect from 'components/organisms/SingleSelect/SingleSelect'
import { ReactComponent as ClockIcon } from 'assets/images/clock-hour.svg'
import DateChip from 'components/atoms/DateChip/DateChip'
import 'react-datepicker/dist/react-datepicker.css'
import CustomDatePicker from 'components/molecules/CustomDatePicker/CustomDatePicker'
import MultiSelect from 'components/organisms/MultiSelect/MultiSelect'
import { ReactComponent as UnassignedIcon } from 'assets/images/user-profile-unassgined.svg'
import personOption from 'components/molecules/PersonOption/PersonOption'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { StyledButton } from 'components/organisms/TaskManagerBoard/styles'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { useAssignToProperty } from '_features/property'

export const usePropertyValueElement = () => {
  // ** State
  const [uploadProgress, setUploadProgress] = useState<string>('')
  const [calendarOpen, setCalendarOpen] = useState<boolean>(false)

  // ** Hooks
  const AxiosInstance = new AxiosService()
  const _propertyAssign = useAssignToProperty()

  // ** Redux state
  const selectedTaskId = useAppSelector((state) => state.taskManager.selectedTaskId)
  const propertyDefinitions = useAppSelector((state) => state.taskManager.propertyDefinitions)
  const spaceMembers = useAppSelector((state) => state.space.currentSpace)?.spaceMembers || []
  const fileInputRef: React.RefObject<HTMLInputElement> | null = useRef(null)
  const users = useAppSelector((state) => state.projectFile.allMembers)

  const statusPropertyDefinition = propertyDefinitions?.find(
    (property) => property.type === 'status',
  )?.statusValues

  const statusOption = ({ value, label }: { value: string; label: string }) => {
    return <PropertyChip text={label} status={value} />
  }

  const generateMultiSelectOptions = (
    properties: IPropertyDefinition[],
    propertyDefinitionId: number,
  ) => {
    const propertyDefinition = properties.find((prop) => prop.id === propertyDefinitionId)
    if (propertyDefinition) {
      return propertyDefinition.multiSelectValues.map((option) => {
        return {
          id: option.id,
          label: option.value,
          value: option.value,
        }
      })
    } else return undefined
  }

  const generatePersonOptions = () => {
    return spaceMembers.length
      ? spaceMembers.map((member) => {
          return {
            id: member.user.id,
            label: `${member.user.firstName} ${member.user.lastName}`,
            value: `${member.user.firstName} ${member.user.lastName}`,
            photo: member.user.photo,
          }
        })
      : []
  }

  const generateMultiSelectComponent = (
    id: string,
    options: OptionsOrGroups<Option, GroupBase<Option>> | undefined,
    onChange: (e: SingleValue<Option> | MultiValue<Option>) => void,
    currentValue?: any,
  ) => {
    return [
      {
        element: (
          <MultiSelect
            options={options}
            onChange={onChange}
            value={currentValue ? currentValue : ''}
          />
        ),
        id,
        onClick: () => void 0,
        eventKey: NaN,
      },
    ]
  }

  const renderPropertyValueElement = (
    prop: ITaskProperty,
    properties?: IPropertyDefinition[],
  ): React.ReactNode => {
    const todo = statusPropertyDefinition?.find(
      (value) => value.category === IStatusCategoriesEnum.TODO,
    )
    const inProgress = statusPropertyDefinition?.find(
      (value) => value.category === IStatusCategoriesEnum.IN_PROGRESS,
    )
    const complete = statusPropertyDefinition?.find(
      (value) => value.category === IStatusCategoriesEnum.COMPLETE,
    )

    if (prop.propertyDefinition.type === IPropertyTypes.STATUS) {
      const options = [
        {
          id: todo?.id || 1,
          label: 'To do',
          value: IStatusCategoriesEnum.TODO,
        },
        {
          id: inProgress?.id || 2,
          label: 'In progress',
          value: IStatusCategoriesEnum.IN_PROGRESS,
        },
        {
          id: complete?.id || 3,
          label: 'Complete',
          value: IStatusCategoriesEnum.COMPLETE,
        },
      ]

      const currentValue = prop.status?.find((status) => status.current)
      const statusValues = prop.propertyDefinition.statusValues
      const statusValue = statusValues.find(
        (statusValue) => statusValue.id === currentValue?.statusValueId,
      )

      const selectedOption = options.find((option) => option.value === statusValue?.category)

      return (
        <RadixDropdown
          trigger={
            <StyledButton>
              <PropertyChip text={statusValue?.value || ''} status={statusValue?.category} />
            </StyledButton>
          }
        >
          <SingleSelect
            options={options}
            onClick={(e) => e.stopPropagation()}
            onChange={(e: any) => {
              if (e) {
                _propertyAssign.assignValueToProperty({
                  propertyId: prop.id,
                  statusValueId: (e as Option).id as number,
                })
              }
            }}
            menuIsOpen
            currentValue={selectedOption || ('' as any)}
            formatOptionLabel={statusOption as any}
            hideControl
            isSearchable
            placeholder={'Search'}
          />
        </RadixDropdown>
      )
    } else if (prop.propertyDefinition.type === IPropertyTypes.PERSON) {
      const options = generatePersonOptions()
      const currentValue = prop.person?.find((person) => person.current)

      const user = currentValue && options?.find((user) => user.id === currentValue.userId)

      const unassignedOption = {
        id: null,
        label: 'Unassigned',
        value: 'Unassigned',
        photo: (<UnassignedIcon />) as unknown as string,
      }

      const userValue = user && {
        id: user.id,
        label: `${user.label}`,
        value: `${user.label}`,
        photo: user.photo,
      }

      return (
        <RadixDropdown
          trigger={
            <StyledButton>
              {currentValue === undefined ? (
                <UnassignedIcon />
              ) : (
                <Avatar
                  src={user?.photo && `${envVars.api.s3Butcket}${user.photo}`}
                  alt={`${user?.label}`}
                  userName={`${user?.label}`}
                />
              )}
            </StyledButton>
          }
        >
          <Wrapper padding={'8px 0 4px 0'}>
            <SingleSelect
              options={[unassignedOption, ...options]}
              onClick={(e) => e.stopPropagation()}
              onChange={(e: any) => {
                if (e) {
                  if (e.id !== null) {
                    prop.id &&
                      _propertyAssign.assignValueToProperty({
                        propertyId: prop.id,
                        userId: (e as Option).id as number,
                      })
                  } else {
                    currentValue &&
                      _propertyAssign.unassignValueFromProperty({
                        propertyId: prop.id,
                        valueId: currentValue?.id as number,
                      })
                  }
                }
              }}
              currentValue={userValue ? userValue : null}
              formatOptionLabel={personOption as any}
              hideControl={false}
              isSearchable
              menuIsOpen
              placeholder={'Search'}
            />
          </Wrapper>
        </RadixDropdown>
      )
    } else if (prop.propertyDefinition.type === IPropertyTypes.DATE) {
      const currentValue = prop.date?.find((date) => date.current)

      const onChange = (date: Date | null) => {
        if (date) {
          _propertyAssign.assignValueToProperty({
            propertyId: prop.id,
            date: date.toISOString(),
          })
        }
      }

      return (
        <CustomDatePicker
          selected={currentValue ? new Date(currentValue.value) : new Date()}
          onChange={(date: Date | null) => {
            onChange(date)
          }}
          onInputClick={() => {
            setCalendarOpen(!calendarOpen)
          }}
          onClickOutside={() => {
            setCalendarOpen(false)
          }}
          open={calendarOpen}
          customInput={
            <StyledChipWrapper>
              <DateChip>
                <ClockIcon />
                <CustomP color='var(--main-black) !important'>
                  {currentValue &&
                    new Date(Date.parse(currentValue.value)).toLocaleDateString('en-US')}
                </CustomP>
              </DateChip>
            </StyledChipWrapper>
          }
        />
      )
    } else if (prop.propertyDefinition.type === IPropertyTypes.TEXT) {
      const currentValue = prop.text?.find((text) => text.current)
      return (
        <BasicInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            _propertyAssign.assignValueToProperty({
              propertyId: prop.id,
              text: e.target.value,
            })
          }}
          placeholder={'Type something'}
          defaultValue={currentValue ? currentValue.value : undefined}
        />
      )
    } else if (prop.propertyDefinition.type === IPropertyTypes.NUMBER) {
      const currentValue = prop.number?.find((number) => number.current)
      return (
        <BasicInput
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            _propertyAssign.assignValueToProperty({
              propertyId: prop.id,
              number: parseInt(e.target.value),
            })
          }}
          type='number'
          placeholder={'Type a number'}
          defaultValue={currentValue ? currentValue.value : undefined}
        />
      )
    } else if (prop.propertyDefinition.type === IPropertyTypes.LABEL) {
      const options = properties
        ? generateMultiSelectOptions(properties, prop.propertyDefinition.id)
        : undefined
      const dropdownOptions = generateMultiSelectComponent(
        'multi_select_dropdown_options',
        options,
        (e: unknown) => {
          if (e)
            _propertyAssign.assignValueToProperty({
              propertyId: prop.id,
              number: (e as Option).id as number,
            })
        },
      )
      const currentValue = prop.multiSelect?.find((value) => value.current)
      return (
        <CustomDropdown
          toggleButton={
            <PropertyChip text={currentValue ? currentValue.selectedValue.value : ''} />
          }
          dropdownOptions={dropdownOptions}
        />
      )
    } else if (prop.propertyDefinition.type === IPropertyTypes.FILES_AND_MEDIA) {
      const uploadKey = 'files_and_media_upload_dropdown'
      const currentValue = prop.text?.find((text) => text.current)

      const links = currentValue ? currentValue.value.split(';') : undefined

      const handleFileUpload = async () => {
        if (
          fileInputRef &&
          fileInputRef.current &&
          fileInputRef.current.files &&
          fileInputRef.current.files[0]
        ) {
          const file: File = fileInputRef.current.files[0]
          const formData = constructFormData(file, selectedTaskId)
          const response = await AxiosInstance.postImage(
            formData,
            {
              'Content-Type': 'multipart/form-data',
            },
            (progressEvent) => {
              const progress = (progressEvent.loaded / progressEvent.total) * 100
              setUploadProgress(progress.toString())
            },
            () => void 0,
          )
          if (response) {
            setUploadProgress('')
            if (prop.text && prop.text.length === 0) {
              _propertyAssign.assignValueToProperty({
                propertyId: prop.id,
                text: response.filePath,
              })
            } else if (prop.text && prop.text.length !== 0) {
              if (currentValue) {
                const updatedValue = currentValue.value.concat(`;${response.filePath}`)
                _propertyAssign.assignValueToProperty({
                  propertyId: prop.id,
                  text: updatedValue,
                })
              }
            }
          }
        }
      }
      const dropdownOptions = [
        {
          element: (
            <StyledInputContainer>
              {!uploadProgress && (
                <>
                  <PaperClipIcon />
                  <BasicInput
                    ref={fileInputRef}
                    onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
                      e.stopPropagation()
                    }
                    onChange={handleFileUpload}
                    id={uploadKey}
                    type='file'
                    hidden
                  />
                  <BasicLabel
                    onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) =>
                      e.stopPropagation()
                    }
                    htmlFor={uploadKey}
                  >
                    Upload file
                  </BasicLabel>
                </>
              )}
              {uploadProgress && <ProgressIndicator percentage={uploadProgress} />}
            </StyledInputContainer>
          ),
          id: uploadKey,
          onClick: () => void 0,
          eventKey: NaN,
        },
      ]

      return (
        <CustomDropdown
          menuPadding='0'
          toggleButton={
            <>
              {links &&
                links.map((link) => {
                  return (
                    <StyledUploadedFile key={link}>
                      <PaperClipIcon />
                      <Anchor
                        onClick={(e) => e.stopPropagation()}
                        target='_blank'
                        rel='noopener noreferrer'
                        href={`${envVars.api.s3Butcket}${link}`}
                      >
                        {link.split('/').slice(2).join('/')}
                      </Anchor>
                    </StyledUploadedFile>
                  )
                })}
            </>
          }
          dropdownOptions={dropdownOptions}
        />
      )
    } else if (prop.propertyDefinition.type === IPropertyTypes.URL) {
      const currentValue = prop.text?.find((text) => text.current)
      return (
        <BasicInput
          type={'url'}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            _propertyAssign.assignValueToProperty({
              propertyId: prop.id,
              text: e.target.value,
            })
          }}
          placeholder={'Url'}
          defaultValue={currentValue ? currentValue.value : undefined}
        />
      )
    } else return null
  }

  return {
    renderPropertyValueElement,
  }
}
