import React from 'react'
import { IconWrapper, StyledPlusIcon, StyledToolbar } from '_entities/whiteboard/ui/styles'
import { ZoomSelector } from './ZoomSelector'
import { zoomSteps } from '../../lib/constants'
import { isMobile } from 'react-device-detect'

import { ReactComponent as MinusIcon } from 'assets/images/minus.svg'

interface Props {
  pageId: string
  zoomToPercentage: (pageId: string, percent: number, animate?: boolean) => void
  zoomPercentage: number
  setZoomPercentage: React.Dispatch<React.SetStateAction<number>>
}

export const ZoomControls = (props: Props) => {
  const min = zoomSteps[0]
  const max = zoomSteps[zoomSteps.length - 1]
  const findNearestDown = (zoomPercentage: number) => {
    let currentNum = zoomPercentage
    while (currentNum !== min) {
      currentNum -= 1
      if (zoomSteps.includes(currentNum)) break
    }
    return currentNum
  }

  const findNearestUp = (zoomPercentage: number) => {
    let currentNum = zoomPercentage
    while (currentNum !== max) {
      currentNum += 1
      if (zoomSteps.includes(currentNum)) break
    }
    return currentNum
  }

  return (
    <StyledToolbar
      right={'calc(15vw + var(--sidebar-button-width))'}
      className='zoom-toolbar'
      transform={isMobile ? 'translateY(-56px)' : ' translateX(-100px)'}
    >
      <IconWrapper
        onClick={() => {
          if (props.zoomPercentage > min)
            props.zoomToPercentage(props.pageId, findNearestDown(props.zoomPercentage))
        }}
      >
        <MinusIcon />
      </IconWrapper>

      <ZoomSelector
        pageId={props.pageId}
        zoomToPercentage={props.zoomToPercentage}
        zoomPercentage={props.zoomPercentage}
        setZoomPercentage={props.setZoomPercentage}
      />
      <IconWrapper
        onClick={() => {
          if (props.zoomPercentage < max)
            props.zoomToPercentage(props.pageId, findNearestUp(props.zoomPercentage))
        }}
      >
        <StyledPlusIcon />
      </IconWrapper>
    </StyledToolbar>
  )
}
