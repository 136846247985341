export interface IPalette {
  id: string
  type: string
  attributes: PaletteAttributes
}

export interface PaletteAttributes {
  name: string
  colors: IPaletteColor[]
  createdBy: {
    id: number
  } | null
}

export interface IPaletteColor {
  id: number
  color: string
  opacity: number
  type: string
}

export enum ProfileTab {
  ACCOUNT = 'account',
  MY_PROFILE = 'my-profile',
  REFERRALS = 'referrals',
  NOTIFICATIONS = 'notifications',
  CONNECTIONS = 'connections',
}

export type DefaultsType = 'workspace' | 'project' | 'document' | 'canvas'

export interface Setting {
  id: number
  key: string
  updatedAt: string
  createdAt: string
  value: string
}

export enum SettingsKey {
  ERROR_REPORTING = 'error_reporting',
}
