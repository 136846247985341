import { IPages } from 'interfaces/page'
import { AxiosService, ICreatePageRequestArgs } from 'services/axiosService/axiosService'
import { PageTypesEnum } from 'interfaces/projectFile'
import { usePagesTreeStructure } from '_features/page'

export interface ICreatePageArgs extends ICreatePageRequestArgs {
  pages: IPages[]
}

export const createPage = async (args: ICreatePageArgs) => {
  const AxiosInstance = new AxiosService()
  const _pagesTreeStructure = usePagesTreeStructure()
  const untitledPages = args.pages.length
    ? args.pages.filter((page: IPages) => page && page.text.includes('Untitled'))
    : []

  const untitledDocuments = args.pages.length
    ? args.pages.filter((page: IPages) => page && page.text.includes('New document'))
    : []

  const untitledWhiteboards = args.pages.length
    ? args.pages.filter((page: IPages) => page && page.text.includes('New canvas'))
    : []

  if (args.type === PageTypesEnum.DOCUMENT) {
    args.title = args.title || `New document ${untitledDocuments.length + 1}`
  } else if (args.type === PageTypesEnum.WHITE_BOARD) {
    args.title = args.title || `New canvas ${untitledWhiteboards.length + 1}`
  } else {
    if (!args.title) {
      args.title = `Untitled ${untitledPages.length + 1}`
    }
  }
  const requestArgs = {
    type: args.type,
    projectId: args.projectId,
    title: args.title,
    parentId: args.parentId,
    embedUrl: args.embedUrl,
    blocks: args.blocks,
    positionIndex: args.positionIndex,
    icon: args.icon,
    isHidden: args.isHidden,
  }

  const response = await AxiosInstance.addProjectFilePage(requestArgs)

  if (!response) return

  _pagesTreeStructure.setNewPageIntoTreeStructure(args.projectId, response.id)
  return response
}
