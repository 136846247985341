import {
  IconButton,
  RowMenuWrapper,
  RowMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { textColorArray } from 'components/organisms/ProjectFileRightSideBar/constants'
import styled from 'styled-components'
import { getColor } from 'shared/colors'
import { ReactComponent as DisabledColor } from 'assets/images/color-picker/disabled.svg'
import { ReactComponent as Plus } from 'assets/images/color-picker/plus.svg'

interface Props {
  selectedColor?: string
  onSelect: (color: string) => void
  onSelectDisabled: () => void
}

export const ColorButtonColor = styled.div<{ backgroundColor: string; borderColor?: string }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background-color: ${(props) => props.backgroundColor};
  border: 1px solid ${(props) => props.borderColor};
`

const ColorDropdownMenu = (props: Props) => {
  return (
    <RowMenuWrapper>
      {textColorArray().map((color, i) => (
        <RowMenuItem
          onSelect={() => props.onSelect(color)}
          key={`color-dropdown-item-${color}-${i}`}
        >
          <IconButton isSelected={color === props.selectedColor}>
            <ColorButtonColor
              backgroundColor={color}
              borderColor={
                color === getColor('--color-picker-white')
                  ? 'var(--gray-disabled-stroke)'
                  : 'transparent'
              }
            />
          </IconButton>
        </RowMenuItem>
      ))}
      <RowMenuItem onSelect={props.onSelectDisabled}>
        <IconButton isSelected={!props.selectedColor}>
          <DisabledColor />
        </IconButton>
      </RowMenuItem>
      <RowMenuItem>
        <IconButton>
          <Plus />
        </IconButton>
      </RowMenuItem>
    </RowMenuWrapper>
  )
}

export default ColorDropdownMenu
