import { type block } from '_entities/block'
import {
  EditorBlockAttrs,
  EditorBlockAttrsData,
  getBlockElemAttr,
  removeBlockElemAttr,
  setBlockElemAttr,
} from '../ui/attributes'
import { IUserAttributes } from 'interfaces/auth/userData'

export const getUserPresence = (block: block) => {
  const userPresence = getBlockElemAttr(block, EditorBlockAttrs.USER_PRESENCE)
  if (!userPresence) return
  return JSON.parse(userPresence) as EditorBlockAttrsData[EditorBlockAttrs.USER_PRESENCE]
}

export const setUserPresence = (block: block, user: IUserAttributes) => {
  setBlockElemAttr(block, EditorBlockAttrs.USER_PRESENCE, user)
}

export const removeUserPresence = (block: block) => {
  removeBlockElemAttr(block, EditorBlockAttrs.USER_PRESENCE)
}
