import React from 'react'
import styled from 'styled-components'
import { StyledCss } from './styles'
const StyledImageContainer = styled.div`
  display: flex;
  align-items: center;
`
const StyledImage = styled.img`
  ${StyledCss}
`
type Props = {
  src?: string
  alt: string
  onClick?: (e?: React.MouseEvent<HTMLImageElement>) => void
  className?: string
  isDraggable?: boolean
  height?: string
  minWidth?: string
  width?: string
  padding?: string
  margin?: string
  objectFit?: string
  objectPosition?: string
  borderRadius?: string
  imageTop?: string
  zIndex?: string
  position?: string
  inset?: string
  imageId?: string
  onError?: (e: React.ChangeEvent<HTMLImageElement>) => void
  cursor?: string
}

const Image: React.FC<Props> = ({
  src,
  alt,
  onClick,
  className,
  isDraggable,
  height,
  minWidth,
  width,
  padding,
  margin,
  objectFit,
  objectPosition,
  borderRadius,
  imageTop,
  zIndex,
  position,
  inset,
  onError,
  imageId,
  cursor,
}) => {
  return (
    <StyledImageContainer>
      <StyledImage
        onClick={onClick}
        draggable={isDraggable ? isDraggable : false}
        className={className}
        src={src}
        alt={alt}
        height={height}
        minWidth={minWidth}
        width={width}
        padding={padding}
        margin={margin}
        objectFit={objectFit}
        objectPosition={objectPosition}
        borderRadius={borderRadius}
        imageTop={imageTop}
        zIndex={zIndex}
        position={position}
        inset={inset}
        onError={onError}
        id={imageId}
        cursor={cursor}
      />
    </StyledImageContainer>
  )
}

export default Image
