import { IUserAttributes } from './auth/userData'
import { ExtendedNodeModel } from './editor'
import { PageLinkSharingEnum } from './invite'
import { PageTypesEnum } from './projectFile'
import { IPropertyDefinition } from './taskManager'
import { type block } from '_entities/block'
import { IPermissions } from '_entities/user'

export interface BaseReference {
  id: number
  blockId: string
  type: string
}

export interface ReferencedPage extends BaseReference {
  referencedPage: IPage
  block: block
}

export interface ReferencingPage extends BaseReference {
  referencingPage: IPage
}

export type IPage = {
  title: string
  type: PageTypesEnum
  projectFileId: number
  positionIndex: number
  createdAt: string
  access: string
  softDeleted?: boolean
  linkSharing: PageLinkSharingEnum
  managers?: IManager[]
  updatedAt: string
  id: string
  parent?: {
    name: string
    id: string | number | null
  }
  pageMembers: IPageResponsePageMembers | ITaskPageMembers[]
  references?: ReferencedPage[]
  referencedBy?: ReferencingPage[]
  icon?: string
  embedUrl?: string
  backgroundImage?: string
}

export interface IPages extends ExtendedNodeModel {
  depth?: number
  isOpen?: boolean
  type: PageTypesEnum
  icon?: string
  embedUrl?: string
  managers?: IManager[]
  attributes?: IPage
  isShown?: boolean
  relationships?: {
    parent: {
      data: {
        id: string | number | null
        type: string
      }
    }
  }
}

export interface IManager {
  id: number
  createdAt: Date
  updatedAt: Date
  propertyDefinitions: IPropertyDefinition[]
}

export interface IPageResponsePageMembers {
  user: IUserAttributes
  id: number
}

export interface ITaskPageMembers {
  id: number
  userId: number
  pageId: string
  createdAt: string
  updatedAt: string
}

export interface IBlock {
  id: string
  version: number
  softDeleted: boolean
  childs: []
  index: number
  role: string
  typeOfBlock: string
  dataObject: any
}

export enum ChangeType {
  BLOCK_CHANGED = 'block-changed',
  BLOCK_ADDED = 'block-added',
  BLOCK_REMOVED = 'block-removed',
  BLOCK_MOVED = 'block-moved',
}

export enum Role {
  OWNER = '1',
  CAN_EDIT = '2',
  CAN_VIEW = '3',
  CAN_COMMENT = '4',
  GUEST = '5',
}

export interface IChangedBlock {
  index: number
  type: string
  id?: string
  from?: number
  to?: number
  block: any // must be any because json1 is typed not to receive objects. If stringified and parsed its too slow and often doesn't register change
}

export interface IPageMember {
  id: string
  role: {
    id: number
    type: IPermissions
  }
  user: {
    firstName: string
    id: number
    lastName: string
  }
  page: IPage
}

export interface IPendingPageMember {
  email: string
  id: string
  inviteToken: {
    id: string
  }
  page: {
    title: string
    id: string
  }
  pageRole: {
    id: number
    type: string
  }
  status: string
}

export type relatedObject = {
  relatedObject: string
  typeOfRelation: string
}

export interface PageObject {
  _id?: string
  type?: string
  index?: number
  data?: object
  page?: string
  typeOfObject?: string
  relationshipType?: string
  originalObject?: string
  relatedObjects?: relatedObject[]
  createdBy?: string
  status?: string
  assignTo?: string
  date?: Date
  text?: string
  number?: number
  singleLabel?: string
  multiLabel?: string[]
  filesAndMedia?: string
  url?: string
  x?: number
  y?: number
}

export type ChangedBlock = IChangedBlock

export interface ISharePageWith {
  name: string
  value: number
}
