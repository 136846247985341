import React, { useEffect, useState } from 'react'
import { type block, WhiteboardBlockProps, getBlockPageId } from '_entities/block'
import { useAppSelector } from 'redux/hooks'
import { useQuill } from 'editor/EditableBlock/EditorTextBlock/helpers/useQuill'
import { MainWrapper, TextPositioningWrapper, QuillWrapper } from './style'
import 'react-quill/dist/quill.snow.css'
import Resizable from 'whiteboard/Resizable/Resizable'

import { useShape } from '_entities/shape'
import { renderToStaticMarkup } from 'react-dom/server'
import { useTransformer } from '_features/canvas'
import { useCreateBlock } from '_features/block'
import { useOverflow } from '_features/text'
import { FocusEdgeLocations, enableEditor, focusEditor, getById } from 'shared/lib'

interface Props extends WhiteboardBlockProps {
  children?: React.ReactNode
}

export const IS_TEXT = 'isText'

export const getHtmlWrapperId = (block: block) => {
  return `resizable-text-html-${block._id}`
}

export const getResizableQuillWrapperId = (block: block) => {
  return `quill-wrapper-${block._id}`
}

export const getTextPositioningWrapperId = (block: block) => {
  return `text-positioning-wrapper-${block._id}`
}

const TextBlock = (props: Props) => {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const selectedBlock = useAppSelector((state) => state.whiteboard.selectedBlock)
  const _shape = useShape()
  const _transformer = useTransformer()
  const _createBlock = useCreateBlock()
  const _overflow = useOverflow()

  const _quill = useQuill({
    block: props.block,
    isWhiteboard: true,
    shouldFocusOnRender: _createBlock.getIsJustCreatedOnCanvas(props.block),
    onTextChange: () => _overflow.reduceOverflowingTextSizeOnChange(props.block),
    isPreview: props.isPreview,
  })

  const startEditing = () => {
    setEditMode(true)
    focusEditor(props.block, FocusEdgeLocations.END)
  }

  const stopEditing = () => {
    setEditMode(false)
  }

  const onClick = () => {
    enableEditor(props.block)
    if (selectedBlock?._id !== props.block._id) {
      const wrapper = getById(getHtmlWrapperId(props.block))
      wrapper?.focus()
    } else startEditing()
  }

  const wrapperKeydown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') setTimeout(() => startEditing())
  }

  useEffect(() => {
    if (
      (!selectedBlock || selectedBlock._id !== props.block._id) &&
      !_createBlock.getIsJustCreatedOnCanvas(props.block)
    ) {
      stopEditing()
    }
  }, [selectedBlock])

  const shouldEnable = () => !props.isPreview && editMode

  const getShapeDataUri = () => {
    const shapeType = _shape.getShapeType(props.block)
    const shapeFill = _shape.getShapeFill(props.block)
    const shapeStroke = _shape.getShapeStoke(props.block)
    const shapeStrokeWidth = _shape.getShapeStrokeWidth(props.block)
    const shapeStrokeDashArray = _shape.getShapeStrokeDashArray(props.block)
    if (!shapeType || !shapeFill) return
    const shape = _shape.shapes[shapeType]({
      block: props.block,
      fill: shapeFill,
      stroke: shapeStroke as string,
      strokeWidth: shapeStrokeWidth as number,
      dash: shapeStrokeDashArray as number[],
    })
    const svgString = encodeURIComponent(renderToStaticMarkup(shape))
    return `url("data:image/svg+xml,${svgString}")`
  }

  const getTextBoxSize = () => {
    const shapeType = _shape.getShapeType(props.block)
    if (!shapeType) {
      return { width: 100, height: 100 }
    }
    const percentage = _shape.textSizeReducer[shapeType]
    return { width: percentage.width * 100, height: percentage.height * 100 }
  }

  const getAlignItems = () => {
    const shapeType = _shape.getShapeType(props.block)
    if (!shapeType) return 'center'
    if (_shape.shapesWithTextOnBottom.includes(shapeType)) return 'flex-end'
    else return 'center'
  }
  return (
    <Resizable
      {...props}
      shouldEnable={shouldEnable()}
      onClick={onClick}
      groupAttrs={{
        [IS_TEXT]: true,
      }}
    >
      <MainWrapper
        background={getShapeDataUri()}
        alignItems={getAlignItems()}
        tabIndex={0}
        id={getHtmlWrapperId(props.block)}
        onKeyDown={wrapperKeydown}
        onClick={() => enableEditor(props.block)}
      >
        {props.children}
        <QuillWrapper
          width={`${getTextBoxSize().width}%`}
          height={`${getTextBoxSize().height}%`}
          id={getResizableQuillWrapperId(props.block)}
        >
          <TextPositioningWrapper id={getTextPositioningWrapperId(props.block)}>
            <div
              ref={(node) => {
                if (!node || loaded) return
                _quill.initiateQuill()
                if (_createBlock.getIsJustCreatedOnCanvas(props.block)) {
                  _transformer.setNodeFromBlockToTransformer(
                    getBlockPageId(props.block),
                    props.block,
                  )
                  _createBlock.removeIsJustCreatedOnCanvas(props.block)
                }
                setLoaded(true)
              }}
              id={`quill-editor-${props.block._id}`}
            />
          </TextPositioningWrapper>
        </QuillWrapper>
      </MainWrapper>
    </Resizable>
  )
}

export default TextBlock
