import { getBlocks } from 'shared/shareDb'
import { EDITOR_BLOCK_NAME, EditorBlockAttrs } from '_widgets/DocumentBlock'

export const getSelectedBlockNodes = (pageId: string) => {
  return document.querySelectorAll(
    `[id="${pageId}"] [data-${EditorBlockAttrs.BLOCK_NAME}="${EDITOR_BLOCK_NAME}"][data-${EditorBlockAttrs.IS_SELECTED}="true"]`,
  )
}

export const getSelectedBlocks = (pageId: string) => {
  const selectedBlockNodes = getSelectedBlockNodes(pageId)
  const blocks = getBlocks(pageId)
  if (!blocks || !selectedBlockNodes.length) return
  return blocks?.filter((block) => {
    return Array.from(selectedBlockNodes).some((node) => {
      return node.id === block._id
    })
  })
}

export const removeSelectedNodesAttrs = (pageId: string) => {
  const selectedBlockNodes = getSelectedBlockNodes(pageId)
  selectedBlockNodes.forEach((node) => {
    node.removeAttribute(EditorBlockAttrs.IS_SELECTED)
  })
}
