import styled from 'styled-components'

export const MessageWrapper = styled.div`
  display: flex !important;
  padding: 20px;
  width: 100%;
  &:hover {
    background-color: #f5f5f5;
  }
`
