import React from 'react'

interface Props {
  id: string
}

const SummarizeIcon = (props: Props) => {
  return (
    <svg width='12' height='10' viewBox='0 0 12 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4.00016 8.33268H10.6668M4.00016 4.99935H10.6668M4.00016 1.66602H10.6668M1.3348 8.33268V8.33402L1.3335 8.33398V8.33268H1.3348ZM1.3348 4.99935V5.00068L1.3335 5.00065V4.99935H1.3348ZM1.3348 1.66602V1.66735L1.3335 1.66732V1.66602H1.3348Z'
        stroke={`url(#${props.id})`}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <linearGradient
          id={props.id}
          x1='6.00016'
          y1='1.66602'
          x2='6.00016'
          y2='8.33402'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#2C6BFF' />
          <stop offset='0.557292' stopColor='#9CACFF' />
          <stop offset='1' stopColor='#FC4AFF' />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SummarizeIcon
