import { AxiosResponse } from 'axios'
import { IUserAttributes } from 'interfaces/auth/userData'
import { useToast } from 'services/helpers/useToast'
const toast = useToast()

export const displayToastAndCloseModals = (
  changingKey: string,
  setChangingKeyModalShown: React.Dispatch<React.SetStateAction<boolean>>,
  user?: AxiosResponse<IUserAttributes>,
  onHide?: () => void,
) => {
  onHide && onHide()
  setChangingKeyModalShown(false)

  if (user) {
    toast.error(`${changingKey} changing failed, try again.`)
    return
  }

  toast.success(`${changingKey} changed successfully`)
}
