import { AnimatePresence } from 'framer-motion'
import {
  ChatDrawerContainer,
  ChatDrawerHead,
  ChatDrawerBody,
  ChatDrawerContent,
  ChatDropdownTitle,
  ChatDrawerGrow,
} from './styles'

import Chat from 'components/organisms/Chat/Chat'

import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  setIsMessageThreadOpen,
  setMessagesWithBlock,
  setObjectChatToView,
  setOpen,
  setSelectedChannel,
  setSelectedObject,
  toggleFullScreen,
} from 'redux/reducers/chatReducer'
import { IconButton } from 'components/atoms/Button/Button'

import { ReactComponent as MaximizeIcon } from 'assets/images/maximize.svg'
import { ReactComponent as ArrowBarToRight } from 'assets/images/arrow-bar-to-right.svg'
import { useChatDrawer } from '../model/useChatDrawer'
import { isMobile } from 'react-device-detect'

interface Props {
  dropOverlay?: React.ReactNode
}

export const CHAT_DRAWER_ID = 'chat-drawer'

export const ChatDrawer = (props: Props) => {
  const dispatch = useAppDispatch()

  const open = useAppSelector((state) => state.chat.open)
  const fullScreen = useAppSelector((state) => state.chat.fullScreen)
  const { DEFAULT_CHANNEL } = useChatDrawer()

  return (
    <>
      {open && (
        <AnimatePresence>
          <ChatDrawerContainer
            id={CHAT_DRAWER_ID}
            initial={{
              transform: 'translateX(100%)',
            }}
            animate={{
              transform: 'translateX(0%)',
            }}
            exit={{
              transform: 'translateX(100%)',
            }}
            transition={{ ease: 'easeInOut', duration: 0.4 }}
          >
            {props.dropOverlay}

            <ChatDrawerContent
              initial={{
                width: isMobile ? '100vw' : '475px',
              }}
              animate={{
                width: fullScreen ? '100vw' : isMobile ? '100vw' : '475px',
              }}
              exit={{
                width: isMobile ? '100vw' : '475px',
              }}
              transition={{ ease: 'easeInOut', duration: 0.4 }}
            >
              <ChatDrawerHead>
                <ChatDrawerGrow>
                  <ChatDropdownTitle># {DEFAULT_CHANNEL.label}</ChatDropdownTitle>
                </ChatDrawerGrow>

                <IconButton
                  variant='transparent'
                  size='small'
                  onClick={() => dispatch(toggleFullScreen())}
                >
                  <MaximizeIcon />
                </IconButton>
                <IconButton
                  variant='transparent'
                  size='small'
                  onClick={() => {
                    dispatch(setMessagesWithBlock(null))
                    dispatch(setOpen(false))
                    dispatch(setSelectedObject(null))
                    dispatch(setIsMessageThreadOpen(false))
                    dispatch(setSelectedChannel(null))
                    dispatch(setObjectChatToView(null))
                  }}
                >
                  <ArrowBarToRight />
                </IconButton>
              </ChatDrawerHead>
              <ChatDrawerBody>
                <Chat />
              </ChatDrawerBody>
            </ChatDrawerContent>
          </ChatDrawerContainer>
        </AnimatePresence>
      )}
    </>
  )
}
