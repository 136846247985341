import { block } from '_entities/block'
import { Position } from 'interfaces/whiteboard'
import { useStage, useBoundingBox } from '_features/canvas'

export const usePosition = () => {
  const _stage = useStage()
  const _boundingBox = useBoundingBox()

  const getBlockX = (block: block) => {
    return block.data.x
  }

  const getBlockY = (block: block) => {
    return block.data.y
  }

  const sortBlocksByY = (blocks: block[]) => {
    return blocks.sort((a, b) => {
      if (a.data.y && b.data.y) return a.data.y - b.data.y
      return 0
    })
  }

  const getNewBlockPosition = (pageId: string): Position => {
    const boundingBox = _boundingBox.getBoundingBox(pageId)
    const x = boundingBox?.getAttr('x')
    const y = boundingBox?.getAttr('Y')
    return {
      x: x ?? _stage.getScaledPointerPosition(pageId)?.x ?? 0,
      y: y ?? _stage.getScaledPointerPosition(pageId)?.y ?? 0,
    }
  }

  return {
    getBlockX,
    getBlockY,
    sortBlocksByY,
    getNewBlockPosition,
  }
}
