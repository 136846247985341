import { ReactComponent as ThreadIcon } from 'assets/images/thread-icon.svg'
import { ReactComponent as ThreadReplyIcon } from 'assets/images/reply-arrow.svg'

import {
  Attachment,
  Avatar,
  MessageOptions,
  MessageRepliesCountButton,
  MessageTimestamp,
  ReactionSelector,
  SimpleReactionsList,
} from 'stream-chat-react'

import { ReactComponent as MessagePinnedIcon } from 'assets/images/pinned.svg'

import {
  AvatarsWrapper,
  MessageContent,
  MessageHeader,
  MessageHeaderName,
  MessageHeaderTimestamp,
  MessageWrapper,
  MessageWrapperContent,
  StyledThreadIndicator,
  SelectedObjectWrapper,
} from './styles'
import useCustomMessage from './useCustomMessage'
import MessageContainer from './CustomMessage/MessageContainer'
import EditMessage from './CustomMessage/EditMessage'
import SelectedImageBlock from './CustomMessage/SelectedImageBlock'
import SelectedTextBlock from './CustomMessage/SelectedTextBlock'
import { IFrameBlockPreview, useEmbed, InternalEmbedBlock } from '_entities/embed'
import { User } from 'stream-chat'
import { IBlockTypes } from '_entities/block'

const CustomMessage = () => {
  const {
    reactionSelectorRef,
    showDetailedReactions,
    isMyMessage,
    handleOnChange,
    handleOnEditSave,
    messageWrapperRef,
    hasReactions,
    hasAttachments,
    editing,
    editValue,
    handleEditClose,
    handleOnClickSubject,
    handleOpenThread,
    messageObject,
    referencedBlock,
    referencedPage,
  } = useCustomMessage()
  const _embed = useEmbed()

  if (messageObject.type === 'deleted') {
    return null
  }

  return (
    <MessageWrapper>
      <MessageWrapperContent>
        <MessageOptions messageWrapperRef={messageWrapperRef} ThreadIcon={ThreadIcon} />
        <MessageHeader>
          <Avatar image={messageObject?.user?.image} size={24} name={messageObject?.user?.name} />
          <MessageHeaderName>{isMyMessage() ? 'You' : messageObject.user?.name}</MessageHeaderName>
          <MessageHeaderTimestamp>
            <MessageTimestamp />
          </MessageHeaderTimestamp>
          {messageObject?.pinned && <MessagePinnedIcon />}
        </MessageHeader>
        {showDetailedReactions && <ReactionSelector ref={reactionSelectorRef} />}

        <MessageContent className='message-content'>
          {referencedBlock ? (
            <>
              {referencedBlock?.data.tag === IBlockTypes.IMAGE ? (
                <SelectedImageBlock
                  handleOnClickSubject={handleOnClickSubject}
                  referencedBlock={referencedBlock}
                  referencedPage={referencedPage}
                />
              ) : _embed.isEmbedBlock(referencedBlock) ? (
                <SelectedObjectWrapper
                  onClick={handleOnClickSubject}
                  className='selected-object-wrapper'
                >
                  <IFrameBlockPreview
                    referencedBlock={referencedBlock}
                    referencedPage={referencedPage}
                  />
                </SelectedObjectWrapper>
              ) : (referencedBlock.data.tag === IBlockTypes.DOCUMENT ||
                  referencedBlock.data.tag === IBlockTypes.WHITEBOARD ||
                  referencedBlock.data.tag === IBlockTypes.EMBED) &&
                referencedBlock.data.documentPage ? (
                <InternalEmbedBlock block={referencedBlock} isChatEmbed />
              ) : (
                <SelectedTextBlock
                  handleOnClickSubject={handleOnClickSubject}
                  referencedBlock={referencedBlock}
                  referencedPage={referencedPage}
                />
              )}

              {editing?.id === messageObject?.id ? (
                <EditMessage
                  handleOnChange={handleOnChange}
                  handleEditClose={handleEditClose}
                  handleOnEditSave={handleOnEditSave}
                  editValue={editValue}
                />
              ) : (
                <MessageContainer message={messageObject} />
              )}
            </>
          ) : (
            <>
              {editing?.id === messageObject?.id ? (
                <EditMessage
                  handleOnChange={handleOnChange}
                  handleEditClose={handleEditClose}
                  handleOnEditSave={handleOnEditSave}
                  editValue={editValue}
                />
              ) : (
                <MessageContainer message={messageObject} />
              )}
            </>
          )}
        </MessageContent>

        {hasAttachments && <Attachment attachments={messageObject?.attachments || []} />}
        {hasReactions && <SimpleReactionsList />}
        {messageObject.reply_count > 0 &&
          messageObject?.reply_count > messageObject?.deleted_reply_count && (
            <StyledThreadIndicator className='thread-indicator'>
              <ThreadReplyIcon />
              <MessageRepliesCountButton
                reply_count={messageObject?.reply_count - messageObject?.deleted_reply_count}
                onClick={handleOpenThread}
              />
              <AvatarsWrapper>
                {messageObject?.thread_participants.slice(0, 5).map((participant: User) => (
                  <Avatar
                    image={participant?.image as string}
                    size={20}
                    key={participant.id}
                    name={messageObject?.user?.name}
                  />
                ))}
              </AvatarsWrapper>
            </StyledThreadIndicator>
          )}
      </MessageWrapperContent>
    </MessageWrapper>
  )
}

export default CustomMessage
