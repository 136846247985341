import { useState } from 'react'
import { useToast } from 'services/helpers/useToast'
import { IBlockTypes } from '_entities/block'
import { FileType, getFileType } from 'utils/isCorrectFileType'
import { AxiosResponse } from 'axios'
import { envVars } from 'config/env'
import useFileUpload from '_entities/resources/model/useFileUpload'
import { PageTypesEnum } from 'interfaces/projectFile'
import { useAppSelector } from 'redux/hooks'
import { useCreatePage } from '_features/page'
import { useCreateBlock } from '_features/block'
import { EmbedType } from '_entities/embed'
import { setInitial, setProgress, removeFileExtension } from 'shared/lib'
import { v4 as uuidv4 } from 'uuid'

export const useUpload = () => {
  const [imageUrl, setImageUrl] = useState('')
  const toast = useToast()

  const _fileUpload = useFileUpload()
  const { createEmbedPage } = useCreatePage()
  const _createBlock = useCreateBlock()
  const pages = useAppSelector((state) => state.projectFile.pages)
  const space = useAppSelector((state) => state.space.currentSpace)
  const projectFile = useAppSelector((state) => state.projectFile.selectedProjectFile)

  const triggerUpload = (callback: (file: File | null) => void) => {
    const inputEl = document.createElement('input')
    inputEl.type = 'file'
    inputEl.accept = 'image/*'
    inputEl.onchange = () => {
      callback(inputEl.files && inputEl.files[0])
    }
    inputEl.click()
  }

  const handleFileUpload = async (
    file: File,
    callback: (
      fileName: string,
      fileType: FileType,
      link: string,
      uploadResponse: void | AxiosResponse<any, any>,
    ) => void,
  ) => {
    if (file.size > 5000000000) {
      toast.error('The maximum size per file is 5GB')
      return
    }

    if (!file) return
    const uploadFileName = `${uuidv4()}-${file.name}`
    let response = await _fileUpload.uploadUrl(uploadFileName)

    const url = response?.url
    const path = response?.path

    if (!response || !file) return

    response = await _fileUpload.uploadFile(url, file)

    if (!response) return

    await _fileUpload.createResource(path)

    const link = `${envVars.api.s3Butcket}${path}`

    const name = removeFileExtension(file.name)

    const fileType = getFileType(file.name)

    return callback(name, fileType, link, response)
  }

  const handleWbFileUpload = (file: File, pageId: string) => {
    setProgress()
    handleFileUpload(file, (fileName, fileType, link) => {
      if (fileType === IBlockTypes.EXTERNAL_EMBED) {
        _createBlock.createWhiteboardBlock({
          type: IBlockTypes.EXTERNAL_EMBED,
          externalEmbed: {
            type: EmbedType.FILE,
            name: fileName,
            url: link,
          },
          pageId,
          name: fileName,
        })
        handleCreateEmbedPage({
          url: link,
          pageId,
          name: fileName,
        })
      } else {
        _createBlock.createWhiteboardBlock({
          type: fileType,
          imageUrl: link,
          pageId,
          name: fileName,
        })
      }
      setInitial()
    })
  }

  const handleCreateEmbedPage = async ({
    url,
    pageId,
    name,
  }: {
    url: string
    pageId?: string
    name?: string
  }) => {
    if (projectFile && pages && space) {
      await createEmbedPage(
        PageTypesEnum.EMBED,
        pages,
        projectFile.id,
        space.id,
        pageId,
        url,
        name || 'Untitled',
      )
    }
  }

  return {
    imageUrl,
    setImageUrl,
    triggerUpload,
    handleFileUpload,
    handleWbFileUpload,
    handleCreateEmbedPage,
  }
}
