import React from 'react'
import { type block } from '_entities/block'
import { StyledEditorPageBlockWrapper } from './styles'
import { PageTypesEnum } from 'interfaces/projectFile'
import { useInternalEmbedBlock } from '../model/useInternalEmbedBlock'
import { SelectPageToEmbed } from './SelectPageToEmbed'
import { ExpandableCallout } from 'shared/ui'
import { useStage, StageAttrs } from '_features/canvas'
import { useSlideShow } from '_entities/slideshow'
import { useAppSelector } from 'redux/hooks'
import { InternalDocument } from './InternalDocument'
import { InternalCanvas } from '../InternalCanvas/ui/InternalCanvas'
import { useEmbedExpansion } from '../model/useEmbedExpansion'
import { useIcons } from 'shared/icons'
import { getBlockDocumentPage } from '_entities/embed'
import { getPageBlockId } from '../model/getters'

interface Props {
  block: block
  isSelected?: boolean
  innerRef?: React.RefObject<HTMLDivElement>
  isDocument?: boolean
  isWhiteboard?: boolean
  isChatEmbed?: boolean
  width?: number
  height?: number
  isEnabled?: boolean
  isPreview?: boolean
}

export const InternalEmbedBlock = (props: Props) => {
  const { block, isSelected, innerRef, isChatEmbed } = props
  const isChatInFullScreen = useAppSelector((state) => state.chat.fullScreen)
  const _stage = useStage()
  const isEmbed = _stage.getStageAttr(block.meta.pageId, StageAttrs.IS_EMBED) as boolean
  const _editorPageBlock = useInternalEmbedBlock()
  const _slideshow = useSlideShow()
  const _embedExpansion = useEmbedExpansion({ block, isChatEmbed })
  const _icons = useIcons()
  const isEmbedPreview = () => {
    return (
      (props.isChatEmbed && !isChatInFullScreen) ||
      _slideshow.isSlideShow(block) ||
      isEmbed ||
      props.isPreview
    )
  }

  const openInternalEmbed = () => {
    const blockPageId = _editorPageBlock.getBlockPageId(props.block)
    if (!blockPageId) return
    _editorPageBlock.onPageClick(blockPageId)
  }

  const blockPage = getBlockDocumentPage(block)
  return (
    <StyledEditorPageBlockWrapper
      draggable={props.isWhiteboard}
      id={getPageBlockId(block)}
      ref={innerRef}
      isChatEmbed={isChatEmbed}
      isFullScreen={isChatInFullScreen}
      isEmbeddedDocumentExpanded={_embedExpansion.isEmbeddedDocumentExpanded}
    >
      {blockPage === null && <SelectPageToEmbed block={block} />}
      {blockPage && !_embedExpansion.isEmbeddedDocumentExpanded && (
        <ExpandableCallout
          width={props.isWhiteboard ? '100%' : undefined}
          height={props.isWhiteboard ? '100%' : undefined}
          shouldSliceText={props.isDocument}
          border={isChatEmbed ? 'none' : undefined}
          isSelected={isSelected}
          background={!isChatEmbed ? 'var(--document-background)' : 'transparent'}
          block={block}
          onClick={() => {
            const blockPageId = _editorPageBlock.getBlockPageId(block)
            if (!blockPageId) return
            _editorPageBlock.onPageClick(blockPageId)
          }}
          title={_editorPageBlock.getBlockPageTitle(block) || block.data.documentPage?.title}
          Icon={_icons.getIcon({ block })}
          freshlyCreatedBlockPageId={_editorPageBlock.freshlyCreatedBlockPageId}
          inputOnKeydown={(e) => _editorPageBlock.handleOnKeyDown(e, block)}
          inputOnBlur={_editorPageBlock.handleOnBlur}
          isChatEmbed={isChatEmbed}
          isExpanded={_embedExpansion.isEmbeddedDocumentExpanded}
          maximize={_embedExpansion.handleEmbeddedDocumentMaximize}
          minimize={_embedExpansion.handleEmbeddedDocumentMinimize}
        />
      )}
      {blockPage?.type === PageTypesEnum.DOCUMENT && _embedExpansion.isEmbeddedDocumentExpanded && (
        <InternalDocument
          block={block}
          width={props.width}
          height={props.height}
          isWhiteboard={props.isWhiteboard}
          onClick={isChatEmbed ? () => _editorPageBlock.handleOpenChatFullScreen() : () => void 0}
          isEmbed={isEmbed}
          isPreview={isEmbedPreview()}
          blockPage={blockPage}
          Icon={_icons.getIcon({ block })}
          isExpanded={_embedExpansion.isEmbeddedDocumentExpanded}
          maximize={_embedExpansion.handleEmbeddedDocumentMaximize}
          minimize={_embedExpansion.handleEmbeddedDocumentMinimize}
          title={_editorPageBlock.getBlockPageTitle(block) || block.data.documentPage?.title}
          onTitleClick={openInternalEmbed}
        />
      )}
      {blockPage?.type === PageTypesEnum.WHITE_BOARD &&
        _embedExpansion.isEmbeddedDocumentExpanded && (
          <InternalCanvas
            block={block}
            width={props.width}
            height={props.height}
            isWhiteboard={props.isWhiteboard}
            isSlideShow={_slideshow.isSlideShow(block)}
            isEnabled={props.isEnabled}
            onClick={isChatEmbed ? () => _editorPageBlock.handleOpenChatFullScreen() : () => void 0}
            isEmbed={isEmbed}
            isPreview={isEmbedPreview()}
            blockPage={blockPage}
            Icon={_icons.getIcon({ block })}
            isExpanded={_embedExpansion.isEmbeddedDocumentExpanded}
            maximize={_embedExpansion.handleEmbeddedDocumentMaximize}
            minimize={_embedExpansion.handleEmbeddedDocumentMinimize}
            title={_editorPageBlock.getBlockPageTitle(block) || block.data.documentPage?.title}
            onTitleClick={openInternalEmbed}
          />
        )}
    </StyledEditorPageBlockWrapper>
  )
}
