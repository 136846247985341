import { changeOpacity, getColor } from 'shared/colors'
import styled from 'styled-components'

export const MainContainer = styled.div`
  height: calc(100vh - 40px);
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
  z-index: 2;
  margin: 20px 20px 20px 0;
  backdrop-filter: blur(20px);
  background-color: ${() => changeOpacity(getColor('--sidebars'), 0.5)};
  border-radius: 0 4px 4px 0;
`
export const RelationshipContainer = styled.div`
  display: flex;
  font-size: 12px;
  gap: 12px;
  padding: 0 4px;
  margin-left: -4px;

  justify-content: space-between;
  align-items: center;
  border-radius: 10px;

  :hover {
    background: var(--hover);
    color: black;
    opacity: 1;
    cursor: pointer;
  }
`
export const RelationshipTextContainer = styled.div`
  display: flex;
  justify-content: left;
  gap: 6px;
  align-items: center;
  margin: 0 !important;
  width: 100%;
  color: var(--primary-text);
`

export const StyledButton = styled.button`
  border: none;
  background: none;
  color: var(--primary-color);

  :disabled {
    color: #dedede;
  }
`

export const IconWrapper = styled.span<{ clicked?: boolean }>`
  border-radius: 8px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: var(--hover);
  }

  &:active {
    background: var(--hover);
  }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const PageIconWrapper = styled.span`
  height: 100%;
  align-items: center;

  svg path {
    stroke: var(--primary-text);
  }
`
