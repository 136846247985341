import styled from 'styled-components'
import { Row } from 'react-bootstrap'

type StyledTableRowType = {
  padding?: string
  margin?: string
}

export const StyledTableRow = styled(Row)<StyledTableRowType>`
  margin: ${(props) => props.margin || '0 24px'};
  padding: ${(props) => props.padding || '24px'};
  border-bottom: 3px solid var(--gray4);

  &:last-child {
    border-bottom: none;
  }

  &:hover,
  &:hover .customDropdownHeader,
  &:hover .spaceDots {
    background-color: var(--gray4);
  }

  &#no-colour-on-hover:hover {
    background-color: transparent;
  }
`
