import styled from 'styled-components'

import { ReactComponent as InstanceActive } from 'assets/images/instance-active.svg'
import { ReactComponent as InstanceInactive } from 'assets/images/instance-disabled.svg'
import { ReactComponent as MirrorActive } from 'assets/images/mirror-active.svg'
import { ReactComponent as MirrorInactive } from 'assets/images/mirror-disabled.svg'

export const CreateButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 12px 16px;
  border-top: 1px solid #f1f1f1;
`

export const SelectPagesWrapper = styled.div`
  padding: 0 6px;
`

export const InstanceActiveIcon = styled(InstanceActive)`
  path {
    stroke: var(--primary-color);
  }
`

export const InstanceInactiveIcon = styled(InstanceInactive)``

export const MirrorActiveIcon = styled(MirrorActive)``

export const MirrorInactiveIcon = styled(MirrorInactive)``
