import React from 'react'
import ProfilePhotoEditor from 'components/organisms/ProfilePhotoEditor/ProfilePhotoEditor'
import { Modal } from 'react-bootstrap'
import { Button, ChangeUserProfileModalStyled, ModalTitle, SubtitleText } from './styles'
import { useChangeUserProfileModal } from './useChangeUserProfileModal'

type Props = {
  show: boolean
  handleClose: () => void
  onShow: () => void
  setUserImage: React.Dispatch<React.SetStateAction<string | undefined>>
}

const ChangeUserProfileModal: React.FC<Props> = ({ show, onShow, handleClose, setUserImage }) => {
  const {
    imageScale,
    setImageScale,
    onSubmit,
    getRootProps,
    getInputProps,
    setProfileImage,
    profileImage,
    editor,
  } = useChangeUserProfileModal(setUserImage, handleClose)

  return (
    <ChangeUserProfileModalStyled>
      <Modal
        show={show}
        onShow={onShow}
        onHide={() => {
          handleClose()
          setProfileImage(undefined)
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        dialogClassName='changePhotoModal'
      >
        <Modal.Header closeButton className='modalHeader mx-3 py-2 border-0'>
          <ModalTitle>Adjust photo</ModalTitle>
        </Modal.Header>
        <SubtitleText className='mx-3'>
          Drag to reposition photo and use the slider to zoom in or out
        </SubtitleText>
        <Modal.Body className='text-center d-flex justify-content-center'>
          <ProfilePhotoEditor
            file={profileImage}
            editor={editor}
            getInputProps={getInputProps}
            getRootProps={getRootProps}
            imageScale={imageScale}
            setImageScale={setImageScale}
            isProfileModal={true}
          />
        </Modal.Body>
        <Modal.Footer className='border-0 mx-3 d-flex justify-content-end'>
          <Button onClick={onSubmit}>Save photo</Button>
        </Modal.Footer>
      </Modal>
    </ChangeUserProfileModalStyled>
  )
}

export default ChangeUserProfileModal
