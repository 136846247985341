import styled from 'styled-components'

export const StyledChip = styled.div<{ status?: string }>`
  width: fit-content;
  color: var(--main-black) !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  border-radius: 8px;
  padding: 4px 8px;
  background: ${(props) =>
    props.status === 'to-do'
      ? '#FFCFD1'
      : props.status === 'in-progress'
      ? '#FFE296'
      : props.status === 'complete'
      ? '#C1E6C8'
      : 'var(--gray4)'};
`
