import styled from 'styled-components'
import CustomP from 'components/atoms/CustomP/CustomP'
export { ReactComponent as DocumentIcon } from 'assets/images/file-text-doc.svg'
export { ReactComponent as WhiteboardIcon } from 'assets/images/chalkboard-whiteboard.svg'
export { ReactComponent as EmbedFilesIcon } from 'assets/images/window-maximize-embed.svg'

export const StyledItemNewPageItem = styled.div``

export const StyledItemNewPageHeader = styled(CustomP)`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.006em;
  color: var(--primary-text);
`

export const StyledItemNewPageDescription = styled(CustomP)`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--secondary-text);
`

export const StyledItemNewPageIcon = styled.div`
  transform: scale(0.9);
`

export const StyledPageLinkSelectorWrapper = styled.div`
  padding: 10px 0;
  * {
    background: var(--overlay-modal);
  }
`
