import { ITools } from 'interfaces/whiteboard'
import { ReactComponent as FrameIcon } from 'assets/images/whiteboard/frame.svg'
import RadixDropdown, {
  IconButton,
  MenuTopText,
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { FrameSelectorMenu } from './styles'
import { IconButtonSize } from '../Toolbar'
import { setTool } from 'redux/reducers/whiteboardReducer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { FrameOptions } from '_entities/whiteboard'
import { usePage } from '_entities/page'

interface Props {
  isDisabled: boolean
}

export const FrameSelector = (props: Props) => {
  const dispatch = useAppDispatch()
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const _page = usePage()
  const isSelected =
    tool === ITools.FRAME_SIXTEEN_NINE ||
    tool === ITools.FRAME_FOUR_THREE ||
    tool === ITools.FRAME_ONE_ONE ||
    tool === ITools.FRAME_CUS

  if (_page.isSelectedPageDoc()) {
    return (
      <IconButton
        onClick={() => {
          dispatch(setTool(ITools.FRAME_CUS))
        }}
        isSelected={isSelected}
        width={IconButtonSize + 'px'}
        height={IconButtonSize + 'px'}
      >
        <FrameIcon />
      </IconButton>
    )
  }

  return (
    <RadixDropdown
      disabled={props.isDisabled}
      trigger={
        <IconButton
          isSelected={isSelected}
          width={IconButtonSize + 'px'}
          height={IconButtonSize + 'px'}
        >
          <FrameIcon />
        </IconButton>
      }
    >
      <FrameSelectorMenu>
        <MenuTopText>Add a frame</MenuTopText>
        {FrameOptions().map((option) => (
          <DropdownMenuItem
            onSelect={() => {
              dispatch(setTool(option.tool))
            }}
            key={'frame-selector-option'}
          >
            {option.child}
          </DropdownMenuItem>
        ))}
      </FrameSelectorMenu>
    </RadixDropdown>
  )
}
