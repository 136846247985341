import { IArrowItem, ITools } from 'interfaces/whiteboard'
import { KonvaEventObject } from 'konva/lib/Node'
import { useState } from 'react'
import { useAppSelector } from 'redux/hooks'
import { useEvent } from '_features/canvas/event'
import { useStage } from '_features/canvas/stage'

export const useArrow = () => {
  const [arrowCoordinates, setArrowCoordinates] = useState<IArrowItem | null>(null)
  const tool = useAppSelector((state) => state.whiteboard.tool)
  const _event = useEvent()
  const _stage = useStage()

  const arrowMouseDown = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    if (tool === ITools.ARROW) {
      const pageId = _event.getPageIdFromEvent(e)
      const scaledPosition = _stage.getScaledPointerPosition(pageId)
      setArrowCoordinates({
        xStart: scaledPosition?.x ?? 0,
        yStart: scaledPosition?.y ?? 0,
        xEnd: scaledPosition?.x ?? 0,
        yEnd: scaledPosition?.y ?? 0,
      })
    }
  }

  const arrowMouseMove = (e: KonvaEventObject<MouseEvent | TouchEvent>) => {
    const pageId = _event.getPageIdFromEvent(e)
    const scaledPoints = _stage.getScaledPointerPosition(pageId)
    if (!arrowCoordinates || !scaledPoints) return
    setArrowCoordinates({
      ...arrowCoordinates,
      xEnd: scaledPoints?.x ?? 0,
      yEnd: scaledPoints?.y ?? 0,
    })
  }
  return {
    arrowCoordinates,
    setArrowCoordinates,
    arrowMouseDown,
    arrowMouseMove,
  }
}
