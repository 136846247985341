import styled from 'styled-components'
import { CSSProperties } from 'react'

export type IconContainerProps = {
  flexDirection?: CSSProperties['flexDirection']
}

export const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  flex-direction: ${(props) => (props.flexDirection ? props.flexDirection : '')};
`
