import RadixDropdown, {
  IconButton,
  DropdownMenuItem,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { type block } from '_entities/block'
import { useConversionMenu } from '_features/block'
import {
  H1Icon,
  H2Icon,
  LIcon,
  MIcon,
  SIcon,
  XLIcon,
  TIcon,
} from '_features/block/ui/SlashMenu/styles'
import { QuillHeadings, useText } from '_entities/text'
import Wrapper from 'components/molecules/Wrapper/Wrapper'

interface Props {
  block: block
  showLabel?: boolean
}

export const textSizeIcons = {
  1: <H1Icon />,
  2: <H2Icon />,
  3: <XLIcon />,
  4: <LIcon />,
  5: <MIcon />,
  6: <SIcon />,
}

const WhiteboardShapeTextConversionDropdown = (props: Props) => {
  const _text = useText()
  const _conversionMenu = useConversionMenu()

  const getCurrentIcon = () => {
    const icon = <TIcon />
    const header = _text.getHeaderFormat(props.block)
    return textSizeIcons[header as QuillHeadings] || icon
  }

  const getCurrentLabel = () => {
    const header = _text.getHeaderFormat(props.block)
    return _conversionMenu.textOptions.find((option) => option.value === header)?.label
  }

  return (
    <RadixDropdown
      trigger={
        <IconButton>
          {props.showLabel ? (
            <Wrapper display='flex' alignItems='center' gap='8px'>
              {getCurrentIcon()} {getCurrentLabel()}
            </Wrapper>
          ) : (
            getCurrentIcon()
          )}
        </IconButton>
      }
    >
      {_conversionMenu.textOptions.map((option) => (
        <DropdownMenuItem
          onSelect={() => {
            _conversionMenu.textConverter(props.block, option.value)
          }}
          key={`text-conversion-option-${option.label}-block-id-${props.block._id}`}
        >
          {option.icon} {option.label}
        </DropdownMenuItem>
      ))}
    </RadixDropdown>
  )
}

export default WhiteboardShapeTextConversionDropdown
