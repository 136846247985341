import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { type block, getBlockGridWidth, GridBlockWidth } from '_entities/block'
import { useAppSelector } from 'redux/hooks'

// Constants for percentage values
const FULL_WIDTH = '100%'
const WRAPPER_DEFAULT = '80%'
const WRAPPER_REDUCED = '70%'
const WRAPPER_MINIMIZED = '60%'
const WRAPPER_MAXIMIZED = '100%'
const WRAPPER_FULL_BLEED = '100%'
const BLOCK_SMALL = '65%'
const BLOCK_MOBILE = '95%'

export const useBlockWidth = (block: block) => {
  // ** State
  const [blockWidth, setBlockWidth] = useState<string>(FULL_WIDTH)
  const [wrapperWidth, setWrapperWidth] = useState<string>(WRAPPER_DEFAULT)
  const [maxWidth, setMaxWidth] = useState<string>(FULL_WIDTH)

  // ** Redux state
  const leftMinimize = useAppSelector((state) => state.projectFile.leftMinimize)
  const rightMinimize = useAppSelector((state) => state.projectFile.rightMinimize)

  useEffect(() => {
    setBlockWidth(calculateBlockWidth())
    setWrapperWidth(calculateWrapperWidth())
    setMaxWidth(calculateMaxWidth())
  }, [leftMinimize, rightMinimize, block])

  const gridWidthMapping = {
    [GridBlockWidth.OUT_OF_GRID]: FULL_WIDTH,
    [GridBlockWidth.SMALL]: BLOCK_SMALL,
    [GridBlockWidth.FULL_BLEED]: FULL_WIDTH,
    [GridBlockWidth.IN_GRID]: FULL_WIDTH,
  }

  const calculateBlockWidth = () => {
    const gridWidth = getBlockGridWidth(block)
    return (isMobile || gridWidth === undefined) && gridWidth !== GridBlockWidth.FULL_BLEED
      ? BLOCK_MOBILE
      : gridWidthMapping[gridWidth] || FULL_WIDTH
  }

  const calculateWrapperWidth = () => {
    if (isMobile && getBlockGridWidth(block) !== GridBlockWidth.FULL_BLEED) {
      return BLOCK_MOBILE
    }

    const gridWidth = getBlockGridWidth(block)
    const isGridWidthUndefined = gridWidth === undefined
    const isInGridOrSmall =
      gridWidth === GridBlockWidth.IN_GRID || gridWidth === GridBlockWidth.SMALL

    if (isGridWidthUndefined || isInGridOrSmall) {
      if (leftMinimize && rightMinimize) {
        return WRAPPER_MINIMIZED
      } else if (leftMinimize || rightMinimize) {
        return WRAPPER_REDUCED
      } else {
        return WRAPPER_DEFAULT
      }
    }

    if (gridWidth === GridBlockWidth.FULL_BLEED) {
      return WRAPPER_FULL_BLEED
    }

    if (gridWidth === GridBlockWidth.OUT_OF_GRID) {
      if (leftMinimize && rightMinimize) {
        return '75%'
      } else if (leftMinimize || rightMinimize) {
        return WRAPPER_MAXIMIZED
      } else {
        return FULL_WIDTH
      }
    }

    return FULL_WIDTH
  }

  const calculateMaxWidth = () => {
    const gridWidth = getBlockGridWidth(block)
    const isFullBleed = gridWidth === GridBlockWidth.FULL_BLEED
    const isOutOfGridAndMinimized =
      gridWidth === GridBlockWidth.OUT_OF_GRID && (leftMinimize || rightMinimize)
    return isFullBleed || isOutOfGridAndMinimized ? FULL_WIDTH : '924px'
  }

  return {
    calculateBlockWidth,
    blockWidth,
    setBlockWidth,
    wrapperWidth,
    setWrapperWidth,
    maxWidth,
  }
}
