import { IPropertyDefinition, ITask, ITaskProperty } from 'interfaces/taskManager'
import { getPropertyValue } from 'utils/taskManager/getPropertyValue'

export const getTaskWithValues = (tasks: ITask[], propertyDefinitions?: IPropertyDefinition[]) => {
  return tasks.map((task: ITask) => {
    const properties = task.properties?.map((property) => {
      const propertyDefinition = propertyDefinitions?.find(
        (propDef) => propDef.id === property.propertyDefinitionId,
      )

      const newProperty = {
        ...property,
        propertyDefinition,
      }

      const value = getPropertyValue(newProperty as ITaskProperty)

      return {
        ...newProperty,
        value,
      }
    })

    return {
      ...task,
      properties,
    }
  })
}
