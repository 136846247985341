import { CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig } from 'react-select'
import { Option } from 'interfaces/selectOptions'

export const multiSelectStyles = (): StylesConfig<Option, true, GroupBase<Option>> => {
  return {
    control: (baseStyles) => ({
      ...baseStyles,
      background: 'none',
      zIndex: '1000',
      borderColor: '#dedede',
      border: '1px solid #dedede',
      paddingLeft: '.4em',
      paddingRight: '1em',
      borderRadius: '8px',
      boxShadow: 'none',
      fontSize: '14px',
    }),
    placeholder: (baseStyles) => ({
      ...baseStyles,
      fontSize: '.9em',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      margin: 0,
      padding: 0,
      background: 'transparent',
      borderColor: 'transparent',
      border: 0,
      boxShadow: 'none',
      position: 'relative',
      left: 0,
      width: '100%',
      fontSize: '14px',
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: OptionProps<Option, true, GroupBase<Option>>,
    ) => ({
      ...baseStyles,
      color: state.isSelected ? 'var(--primary-color)' : 'initial',
      backgroundColor: state.isFocused ? 'var(--hover)' : 'none',
      borderRadius: '8px',
    }),
    multiValue: (base: CSSObjectWithLabel) => ({
      ...base,
      backgroundColor: 'var(--hover)',
      borderRadius: '8px',
      maxWidth: '300px',
      overflowX: 'hidden',
    }),
  }
}
