import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useGetStreamContext } from 'services/getstream/GetStreamContext'
import { Channel as ChannelType, DefaultGenerics, MessageResponse } from 'stream-chat'
import { useChannelActionContext, StreamMessage } from 'stream-chat-react'
import { useDefaultChannel } from '../Chat/useDefaultChannel'
import { setIsMessageThreadOpen, setObjectChatToView } from 'redux/reducers/chatReducer'
import { MessageResponseWithBlock } from 'interfaces/chat'
import { type block } from '_entities/block'
import { IPage } from 'interfaces/page'
import { useGetPage, getPage } from '_entities/page'

export interface ExtendedPinnedMessageResponse extends MessageResponse<DefaultGenerics> {
  referencedBlock: block | null
  referencedPage: IPage | null
}

const useChatHeader = () => {
  // State
  const [channel, setChannel] = useState<ChannelType | undefined>()
  const [pinnedMessages, setPinnedMessages] = useState<ExtendedPinnedMessageResponse[] | null>([])
  const pinnedMessagesState = useAppSelector((state) => state.chat.pinnedMessages)
  const pages = useAppSelector((state) => state.projectFile.pages)

  // Hooks
  const { chatClient } = useGetStreamContext()
  const { jumpToMessage, openThread } = useChannelActionContext()
  const { DEFAULT_CHANNEL } = useDefaultChannel()
  const { getPageObjects } = useGetPage()
  const dispatch = useAppDispatch()

  useEffect(() => {
    // This function gets a channel
    const getChannel = async () => {
      if (chatClient) {
        const channel = (
          await chatClient.queryChannels({
            cid: DEFAULT_CHANNEL.cid,
          })
        )[0]

        setChannel(channel)
      }
    }
    getChannel()
  }, [chatClient, pages])

  const fetchPinnedMessages = async () => {
    if (channel) {
      const response = await channel.getPinnedMessages({})
      const constructedPinnedMessages = await Promise.all(
        response.messages.map(async (message) => {
          if (message?.referencedBlock) {
            const [referencedBlock, referencedPage] = await Promise.all([
              fetchBlock(message as MessageResponseWithBlock),
              fetchPage(message as MessageResponseWithBlock),
            ])

            return {
              ...message,
              referencedBlock,
              referencedPage,
            }
          }

          return message
        }),
      )
      setPinnedMessages((constructedPinnedMessages as any) || null)
      response?.messages?.forEach((message) => {
        const element = document.querySelector(
          `.str-chat__li[data-message-id="${message.id}"] .str-chat__message-actions-list-item:nth-child(2)`,
        ) as HTMLElement

        if (element) {
          if (message.pinned) {
            element.innerHTML = 'Unpin'
          } else {
            element.innerHTML = 'Pin'
          }
        }
      })
    }
  }

  const fetchBlock = async (message: MessageResponseWithBlock) => {
    if (message?.referencedBlock) {
      return await getPageObjects(
        `${message.referencedBlock.pageId}`,
        `${message.referencedBlock.blockId}`,
      )
    }
  }

  const fetchPage = async (message: MessageResponseWithBlock) => {
    if (message.referencedBlock) {
      const response = await getPage(
        `${message.referencedBlock.projectFileId}`,
        `${message.referencedBlock.pageId}`,
      )
      return response
    }
  }

  useEffect(() => {
    fetchPinnedMessages()
  }, [pinnedMessagesState, channel])

  const handleOpenThread = (message: StreamMessage) => {
    openThread(message)
  }

  const handleOnPinnedMessageClick = async (message: ExtendedPinnedMessageResponse) => {
    if (message.parent_id !== undefined) {
      const messageToOpen = await chatClient?.getMessage(message?.parent_id)
      handleOpenThread(messageToOpen?.message as StreamMessage)
      if (message.referencedBlock) {
        const referencedBlock = await fetchBlock(message as unknown as MessageResponseWithBlock)

        if (referencedBlock) {
          dispatch(setObjectChatToView(referencedBlock))
        }
      }
      dispatch(setIsMessageThreadOpen(true))
    } else {
      jumpToMessage(message.id)
    }
  }

  return {
    pinnedMessages,
    handleOnPinnedMessageClick,
  }
}

export default useChatHeader
