import { DropdownOptionContainer } from 'components/atoms/PropertyContainer/PropertyContainer'
import React from 'react'
import { TaskPropertiesWrapper } from 'components/organisms/TaskManagerBoard/styles'
import { ITaskProperty } from 'interfaces/taskManager'

interface IProps {
  assigneeProperty: ITaskProperty
  statusProperty: ITaskProperty
  dueDateProperty: ITaskProperty
  renderPropertyValueElement: any
}

const TaskProperties = ({
  assigneeProperty,
  statusProperty,
  dueDateProperty,
  renderPropertyValueElement,
}: IProps) => {
  return (
    <TaskPropertiesWrapper>
      {assigneeProperty && (
        <DropdownOptionContainer
          padding='0'
          margin='0'
          key={`board-properties_${assigneeProperty?.id}`}
          width='auto'
        >
          {assigneeProperty && renderPropertyValueElement(assigneeProperty)}
        </DropdownOptionContainer>
      )}
      {statusProperty && (
        <DropdownOptionContainer
          padding='0'
          margin='0'
          key={`board-properties_${statusProperty.id}`}
          width='auto'
        >
          {renderPropertyValueElement(statusProperty)}
        </DropdownOptionContainer>
      )}
      {dueDateProperty && (
        <DropdownOptionContainer
          padding='0'
          margin='0'
          key={`board-properties_${dueDateProperty.id}`}
          width='auto'
          onClick={(e) => e.stopPropagation()}
        >
          {renderPropertyValueElement(dueDateProperty)}
        </DropdownOptionContainer>
      )}
    </TaskPropertiesWrapper>
  )
}

export default TaskProperties
