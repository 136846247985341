import styled from 'styled-components'
import { CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig } from 'react-select'
import { Option } from 'interfaces/selectOptions'

export const StyledEditContainer = styled.div`
  border-radius: 8px;
  &:hover {
    background: var(--hover);
    cursor: pointer;
  }
  width: 100%;
`

export const Styles = (): StylesConfig<Option, false, GroupBase<Option>> | undefined => {
  return {
    control: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: 'var(--overlay-modal)',
      zIndex: '1000',
      borderColor: '#dedede',
      border: '1px solid #dedede',
      paddingLeft: '.4em',
      paddingRight: '1em',
      borderRadius: '8px',
      boxShadow: 'none',
      fontSize: '14px',
    }),
    placeholder: (baseStyles, state) => ({
      ...baseStyles,
      fontSize: '.9em',
      color: 'var(--primary-text)',
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: OptionProps<Option, false, GroupBase<Option>>,
    ) => ({
      ...baseStyles,
      color: state.isSelected ? 'var(--primary-color)' : 'initial',
      backgroundColor: state.isFocused ? 'var(--hover)' : 'none',
      borderRadius: '8px',
    }),
    menu: (baseStyles, state) => ({
      ...baseStyles,
      margin: 0,
      padding: 0,
      borderColor: 'transparent',
      border: 0,
      boxShadow: 'none',
      position: 'relative',
      left: 0,
      width: '100%',
      fontSize: '14px',
    }),
  }
}
