import { Dropdown } from 'react-bootstrap'
import styled from 'styled-components'
import { IconButton } from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'

export { ReactComponent as ArrowDownIcon } from 'assets/images/keyboard_arrow_down.svg'

export const StyledDropdown = styled(Dropdown)`
  .dropdown-menu {
    border-radius: 8px;
    background-color: var(--overlay-modal);
  }

  * {
    color: var(--primary-text) !important;
  }

  .dropdown-item {
    color: var(--primary-text) !important;
    background-color: var(--overlay-modal);
  }

  .dropdown-item:hover {
    background: var(--hover) !important;
  }
`

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  margin-left: 12px;
`

export const StyledDropdownMenu = styled.div`
  padding: 10px 4px;
  width: 150px;

  span {
    color: var(--primary-text);
  }
`
