import useLocalStorage, { LocalStorageKeys } from 'shared/lib/useLocalStorage'
import { useGoogleLogin } from '@react-oauth/google'

const useGoogleEmbedLoginButton = () => {
  const _localStorage = useLocalStorage()

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      _localStorage.setItem(LocalStorageKeys.GOOGLE_TOKEN, tokenResponse.access_token)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    },
    scope:
      'https://www.googleapis.com/auth/drive.readonly https://www.googleapis.com/auth/userinfo.profile',
  })

  return {
    login,
  }
}

export default useGoogleEmbedLoginButton
