import { CSSProperties } from 'react'
export { StyledEmojiPicker } from './styles'
import { EmojiContainer } from './styles'
import { getNative } from '../lib/getNative'
import { getCustomEmojiSrc } from '../lib/getCustomEmoji'

// Define em-emoji web component inside React
// https://github.com/missive/emoji-mart#-emoji-component
interface EmEmojiProps {
  id?: string
  shortcodes?: string
  native?: string
  size?: string
  fallback?: string
  set?: 'native' | 'apple' | 'facebook' | 'google' | 'twitter'
  skin?: 1 | 2 | 3 | 4 | 5 | 6
}
declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'em-emoji': EmEmojiProps
    }
  }
}

// This component can render emojis from native unicode, unified code or custom codes
export type EmojiRendererProps = {
  code: string
  size?: CSSProperties['fontSize']
}

export const EmojiRenderer = (props: EmojiRendererProps) => {
  const { code, size = '20px' } = props
  const native = getNative(code)
  const customIconSrc = getCustomEmojiSrc(code)
  return (
    <EmojiContainer size={size}>
      {(native && <em-emoji native={native} set='apple' />) ||
        (customIconSrc && <img src={customIconSrc} />)}
    </EmojiContainer>
  )
}
