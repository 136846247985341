import { useState } from 'react'
import RadixDropdown, {
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { icons } from 'shared/icons'
import Link from 'components/molecules/Link/Link'
import { type block } from '_entities/block'
import { IconButtonSize } from '_features/toolbar/ui/Toolbar'
import { getEditor } from 'shared/lib'

interface Props {
  block: block
}

const LinkDropdown = (props: Props) => {
  const [open, setOpen] = useState<boolean | undefined>(false)
  const [link, setLink] = useState<string>('')

  const onOpenChange = (open: boolean) => {
    setOpen(open)
    getCurrentLink()
  }

  const addLink = () => {
    const editor = getEditor(props.block._id)
    if (!link?.includes('https')) {
      editor?.format('link', `https://${link}`, 'user')
    } else {
      editor?.format('link', link, 'user')
    }
    setOpen(false)
  }

  const getCurrentLink = () => {
    const editor = getEditor(props.block._id)
    if (editor) {
      const range = editor.getSelection()
      if (range) {
        const format = editor.getFormat(range)
        if (format.link) setLink(format.link)
        else setLink('')
      }
    }
  }

  return (
    <RadixDropdown
      open={open}
      onOpenChange={onOpenChange}
      trigger={
        <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
          <icons.link />
        </IconButton>
      }
    >
      <Link
        title='Link URL'
        placeholder='Paste link here'
        buttonText='Add link'
        link={link}
        onInputChange={(e) => setLink(e.target.value)}
        addLink={addLink}
        disabled={link === ''}
      />
    </RadixDropdown>
  )
}

export default LinkDropdown
