import styled from 'styled-components'

export const NavItem = styled.div`
  padding: 9.5px;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: all 0.3s ease-in-out;
  letter-spacing: 0.02em;
  line-height: 120%;
  font-weight: 500 !important;
  font-size: 14px !important;

  :hover {
    background: var(--gray4);
    font-weight: 600;
  }
`

export const ActiveElementToggle = styled.div`
  font-weight: 500;
  font-size: 15px;
  letter-spacing: 0.02em;
  color: #aeb0b4;

  & .flex-column .active > * {
    background: var(--gray4) !important;
    color: #403d3d;
    font-weight: 600 !important;
  }
`
export const ModalContentContainer = styled.div`
  align-items: center;
  padding-top: 15px;
`
