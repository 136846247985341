import { IDropdownOption } from 'interfaces/invite'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'redux/hooks'

interface Props {
  onChange?: (value: IDropdownOption) => void
}

export const usePendingDropdown = ({ onChange }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedOption, setSelectedOption] = useState<IDropdownOption>()

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  const onOptionClicked = (memberId: number, value: IDropdownOption, e: any) => {
    setSelectedOption(value)
    setIsOpen(false)
    onChange && onChange(value)
  }

  return {
    isOpen,
    selectedOption,
    toggle,
    onOptionClicked,
  }
}
