import ReconnectingWebSocket, { Options } from 'reconnecting-websocket'
import { envVars } from 'config/env'

export const getSocket = (endpoint?: string, protocols?: string | string[], options?: Options) => {
  return new ReconnectingWebSocket(
    `${envVars.api.wss}${endpoint ? endpoint : ''}`,
    protocols,
    options,
  )
}
