import { Modal } from 'react-bootstrap'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import SingleSelect from 'components/organisms/SingleSelect/SingleSelect'
import WorkspaceOption from '_entities/profile/ui/Defaults/WorkspaceOption'
import { icons } from 'shared/icons'
import { Button as ModalButton } from 'components/molecules/ChangeNameModal/styles'
import React from 'react'
import { IPages } from 'interfaces/page'
import { IProjectFile } from 'interfaces/space'
import { Option } from 'interfaces/selectOptions'
import { GroupBase } from 'react-select'

interface Props {
  moveModalOpen: boolean
  handleMoveModalClose: () => void
  selectedPage: IPages | null
  selectedProject: IProjectFile | null
  handleSelectProject: (e: IProjectFile) => void
  options: (Option | GroupBase<Option>)[]
  handleCopyFileToAnotherProject: () => void
}

const MoveFileModal = (props: Props) => {
  return (
    <Modal show={props.moveModalOpen} onHide={() => props.handleMoveModalClose()}>
      <Modal.Header closeButton className='modalHeader border-0'>
        <Wrapper>Copy {props.selectedPage?.text} to</Wrapper>
      </Modal.Header>
      <Modal.Body className='border-0'>
        <SingleSelect
          options={props.options}
          onClick={(e) => e.stopPropagation()}
          onChange={(e: any) => props.handleSelectProject(e)}
          menuIsOpen
          currentValue={null}
          isSearchable
          placeholder={'Search'}
          formatOptionLabel={(data) =>
            WorkspaceOption({
              data: data,
              selectedOption: props.selectedProject?.id.toString(),
              shownModal: '',
              icon: <icons.folders />,
            })
          }
        />
      </Modal.Body>
      <Modal.Footer className='border-0 d-flex justify-content-end'>
        <ModalButton
          onClick={props.handleCopyFileToAnotherProject}
          disabled={!props.selectedProject}
        >
          Make a copy
        </ModalButton>
      </Modal.Footer>
    </Modal>
  )
}

export default MoveFileModal
