import { useRef, useState } from 'react'
import { usePopper } from 'react-popper'
import { useOnClickOutside } from 'usehooks-ts'
import { hide } from '@popperjs/core'
import { TreeItem } from '_entities/tree/model/types'

const useEmojiDropdown = () => {
  const [emojiNodeElement, setEmojiNodeElement] = useState<
    | {
        node: TreeItem
        element: HTMLElement
      }
    | undefined
  >()

  const closeEmojiDropdown = () => setEmojiNodeElement(undefined)

  const emojiPopperElementRef = useRef<HTMLElement | null>(null)
  const emojiPopper = usePopper(emojiNodeElement?.element, emojiPopperElementRef.current, {
    strategy: 'fixed',
    placement: 'bottom-start',
    modifiers: [hide],
  })

  useOnClickOutside(emojiPopperElementRef, (event) => {
    if (emojiNodeElement) {
      event.preventDefault()
      event.stopPropagation()

      closeEmojiDropdown()
    }
  })

  return {
    emojiNodeElement,
    setEmojiNodeElement,
    emojiPopperElementRef,
    emojiPopper,
    closeEmojiDropdown,
  }
}

export default useEmojiDropdown
