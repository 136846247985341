import { AxiosService } from 'services/axiosService/axiosService'
import {
  setSelectedProjectFile,
  setPages,
  setMembers,
  setPendingProjectMembers,
  setProjectAllMembers,
  setMyProjectFileRole,
} from 'redux/reducers/projectFileReducer'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { getMembers, getPendingMembers } from './getMembers'
import { getPages, ProjectFileResponse } from '_entities/project'
import { IRoles } from 'interfaces/invite'
import { IProjectFileMember } from 'interfaces/projectFile'
import { useNavigate } from 'react-router-dom'
import { useToast } from 'services/helpers/useToast'
import { setSelectedPage } from 'redux/reducers/pageReducer'

export const useGetProjectFile = () => {
  const toast = useToast()
  const AxiosInstance = new AxiosService()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentUser = useAppSelector((state) => state.global.user)

  const getAndDispatchProjectFile = async (projectFileId: number, spaceId: string) => {
    const projectFile: ProjectFileResponse = await AxiosInstance.getProjectFile(
      spaceId,
      projectFileId,
    )

    if (projectFile) {
      dispatch(setSelectedProjectFile(projectFile))
    } else {
      navigate(`/space/${spaceId}`)
      toast.error('Project file does not exist anymore.')
    }
  }

  const getAndDispatchProjectFilePages = async (projectFileId: string) => {
    const pages = await getPages(projectFileId)
    dispatch(setPages(pages))
    return pages
  }

  const getAndDispatchProjectFileMembers = async (projectFileId: number) => {
    const members: IProjectFileMember[] = await getMembers(projectFileId)

    if (members) {
      const me = members.find((user: IProjectFileMember) => user.user.email === currentUser?.email)
      const myRole = me?.role.type
      const pendingMembers: IProjectFileMember[] | undefined =
        myRole === IRoles.OWNER ? await getPendingMembers(projectFileId) : null
      const filteredPendingMembers = pendingMembers?.filter(
        (member: IProjectFileMember) => member.email !== null,
      )
      const allProjectMembers = filteredPendingMembers
        ? members.concat(filteredPendingMembers)
        : members
      dispatch(setProjectAllMembers(allProjectMembers))
      dispatch(setPendingProjectMembers(filteredPendingMembers))
      dispatch(setMyProjectFileRole(myRole))
      dispatch(setMembers(members))
    }
  }

  const cleanupProjectFile = () => {
    dispatch(setSelectedProjectFile(undefined))
    dispatch(setPages([]))
    dispatch(setMembers([]))
    dispatch(setPendingProjectMembers([]))
    dispatch(setProjectAllMembers([]))
    dispatch(setMyProjectFileRole(null))
    dispatch(setSelectedPage(undefined))
  }

  return {
    getAndDispatchProjectFile,
    getAndDispatchProjectFilePages,
    getAndDispatchProjectFileMembers,
    cleanupProjectFile,
  }
}
