import { ExpandIconProps, Expand } from './ExpandIcon'
import { StyledCallout, StyledCalloutProps } from './StyledCallout'

type ExpandableCalloutProps = StyledCalloutProps & ExpandIconProps

export const ExpandableCallout = (props: ExpandableCalloutProps) => {
  return (
    <StyledCallout {...props}>
      <Expand {...props} />
    </StyledCallout>
  )
}
