import FlexContainer from '../FlexContainer/FlexContainer'
import { ListDiv, StyledActionText, RequestButtonsWrapper, StyledButton } from './styles'
import { ReactComponent as Avatar } from 'assets/images/avatar.svg'
import CustomP from 'components/atoms/CustomP/CustomP'
import { IPage, IPendingPageMember } from 'interfaces/page'
import { IStatus } from 'interfaces/invite'
import RadixDropdown, {
  IconButton,
  DropdownMenuItem,
} from '../Dropdown/RadixDropdown/RadixDropdown'
import { ReactComponent as DeleteRedIcon } from 'assets/images/deleteRed.svg'
import { ReactComponent as Email } from 'assets/images/email.svg'
import { usePageMembers } from 'components/organisms/PageMembers/usePageMembers'
import { ReactComponent as CaretDownIcon } from 'assets/images/CaretDown.svg'
import { useToast } from 'services/helpers/useToast'
import Spinner from 'react-bootstrap/Spinner'

interface Props {
  member: IPendingPageMember
  page: IPage
  setPageMembers: () => void
}
const PendingMembersItem = (props: Props) => {
  const _pageMembers = usePageMembers()

  const toast = useToast()
  return (
    <ListDiv>
      <FlexContainer>
        <Avatar />
        <CustomP className='my-auto' paddingLeft={'8px'} color='var(--primary-text)'>
          {`${props.member.email}`}
        </CustomP>
      </FlexContainer>
      {props.member.status === IStatus.PENDING && (
        <RadixDropdown
          trigger={
            <IconButton>
              {props.member.status.charAt(0).toUpperCase() + props.member.status.slice(1)}{' '}
              <CaretDownIcon />
            </IconButton>
          }
        >
          <DropdownMenuItem
            onSelect={() => _pageMembers.removePendingUserFromPage(props.page.id, props.member.id)}
            key={`page-member-${props.member.id}-pending-setting-remove`}
          >
            <DeleteRedIcon />
            <StyledActionText>Remove</StyledActionText>
          </DropdownMenuItem>
          <DropdownMenuItem
            onSelect={() => _pageMembers.resendInvite(props.page.id, props.member.inviteToken.id)}
            key={`page-member-${props.member.id}-pending-setting-resend`}
          >
            <Email />
            <StyledActionText>Resend Invite</StyledActionText>
          </DropdownMenuItem>
        </RadixDropdown>
      )}
      {props.member.status === IStatus.REQUESTED && (
        <RequestButtonsWrapper>
          {_pageMembers.loading ? (
            <Spinner size='sm' variant='light' />
          ) : (
            <>
              <StyledButton
                onClick={async () => {
                  const response = await _pageMembers.approveOrRejectRequestAccess(
                    props.member.inviteToken.id,
                    true,
                  )
                  if (response) {
                    props.setPageMembers()
                    toast.success('Successfully approved page access request')
                  }
                }}
                size='small'
                type='approve'
              >
                <CustomP className='text-white'>Approve</CustomP>
              </StyledButton>
              <StyledButton
                onClick={async () => {
                  const response = await _pageMembers.approveOrRejectRequestAccess(
                    props.member.inviteToken.id,
                    false,
                  )
                  if (response) {
                    props.setPageMembers()
                    toast.success('Successfully denied page access request')
                  }
                }}
                type='reject'
                size='small'
              >
                <CustomP className='text-white'>Reject</CustomP>
              </StyledButton>
            </>
          )}
        </RequestButtonsWrapper>
      )}
    </ListDiv>
  )
}

export default PendingMembersItem
