import Quill, { DeltaStatic } from 'quill'
import { type block, getBlockPageId, getIndex } from '_entities/block'
import { getBlocks } from 'shared/shareDb'

export enum FocusEdgeLocations {
  START = 'start',
  END = 'end',
}

export type focusLocation = FocusEdgeLocations.START | FocusEdgeLocations.END | number

export const getEditorElement = (blockId: string): HTMLDivElement | null => {
  return document.querySelector(`#quill-editor-${blockId}`)
}

export const getEditor = (blockId: string): Quill | undefined => {
  const quillElement = getEditorElement(blockId)
  if (quillElement) {
    return Quill.find(quillElement)
  }
}

export const getEditorFromQlEditor = (editorEl: HTMLDivElement) => {
  return Quill.find(editorEl)
}

export const getLength = (block: block) => {
  const editor = getEditor(block._id)
  return editor?.getLength()
}

export const enableEditor = (block: block) => {
  const editor = getEditor(block._id)
  editor?.enable()
}

export const disableEditor = (block: block) => {
  const editor = getEditor(block._id)
  editor?.disable()
}

export const deleteText = (block: block, index: number, length: number) => {
  const editor = getEditor(block._id)
  editor?.deleteText(index, length, 'user')
}

export const findEditorInNextBlocks = (block: block) => {
  const blocks = getBlocks(getBlockPageId(block))
  const index = getIndex(block)
  if (!blocks || index === -1) return

  let nextIndex = index + 1
  let editor: Quill | undefined = blocks?.[nextIndex] && getEditor(blocks[nextIndex]._id)
  while (!editor && blocks && nextIndex < blocks?.length - 1) {
    nextIndex = nextIndex + 1
    editor = blocks?.[nextIndex] && getEditor(blocks[nextIndex]._id)
  }

  return editor
}

export const findEditorInPreviousBlocks = (block: block) => {
  const blocks = getBlocks(getBlockPageId(block))
  const index = getIndex(block)
  if (!blocks || index === -1) return

  let previousIndex = index - 1
  let editor: Quill | undefined = blocks?.[previousIndex] && getEditor(blocks[previousIndex]._id)
  while (!editor && previousIndex && previousIndex > 0) {
    previousIndex = previousIndex - 1
    editor = blocks?.[previousIndex] && getEditor(blocks[previousIndex]._id)
  }

  return editor
}

export const checkIsEditorEmpty = (block: block) => {
  const editor = getEditor(block._id)
  const length = editor?.getLength()
  if (length && length <= 1) return true
  else return false
}

export const focusEditor = (block: block, location: focusLocation) => {
  const editor = getEditor(block._id)
  if (!editor) return
  if (location === FocusEdgeLocations.START) {
    editor?.setSelection(0, 0)
  } else if (location === FocusEdgeLocations.END) {
    const length = editor.getLength()
    editor?.setSelection(length, 0)
  } else {
    editor?.setSelection(location, 0)
  }
}

export const blurEditorIfFocused = (block: block) => {
  const editor = getEditor(block._id)
  if (editor?.hasFocus()) {
    editor?.blur()
  }
}

export const findFirstEmptyParagraph = (pageId: string) => {
  return document.querySelector(`[id="${pageId}"] ul p:empty`) as HTMLElement
}

export const setEditorContents = (blockId: string, contents: DeltaStatic) => {
  const editor = getEditor(blockId)
  if (editor) editor.setContents(contents, 'api')
}

export const selectAllTextInBlock = (block: block) => {
  const editor = getEditor(block?._id)

  if (editor) {
    const text = editor.getText()
    editor.setSelection(0, text.length)
  }
}
