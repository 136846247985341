import styled from 'styled-components'
import Logo from 'assets/images/logo.png'
import CirclePlus from 'assets/images/circle-plus.svg'
import AuthInput from 'components/atoms/AuthInput/AuthInput'
import { ISpace } from 'interfaces/space'

import {
  SearchAndLayoutContainerCss,
  LayoutIconsContainerCss,
  ListIcon,
  BoxesIcon,
  WelcomeMessageCss,
  WelcomeMessageSpanCss,
  ProjectFilesContainerCss,
  ProjectFileCss,
  ProjectFileLogoCss,
  ProjectFileTitleCss,
  ProjectFileStarCss,
  FileCardOptions,
} from './styles'

import magnifierIcon from 'assets/images/MagnifierIcon.svg'
import SpaceTabHeaderWrapper from 'components/molecules/SpaceTabHeaderWrapper/SpaceTabHeaderWrapper'
import { useSpaceProjects } from './useSpaceProjects'
import { ProjectFileOrganisation } from 'interfaces/projectFile'
import SpaceTabBodyHeaderText from 'components/molecules/SpaceTabBodyHeaderText/SpaceTabBodyHeaderText'
import SpaceTableHeader from '../SpaceTableHeader/SpaceTableHeader'
import { spaceProjectsHeaderTitles } from './data'
import ProjectFileTrack from './ProjectFileTrack'
import { getStandardDateTimeFormat } from 'utils/getStandardDateTimeFormat'
import MoveProjectModal from '_entities/space/ui/MoveProjectModal'
import ChangeProjectNameModal from '_entities/project/ui/ChangeProjectNameModal'
import ProjectMenuDropdown from '_entities/project/ui/ProjectMenuDropdown'

const SearchAndLayoutContainer = styled.div`
  ${SearchAndLayoutContainerCss}
`

const LayoutIconsContainer = styled.div`
  ${LayoutIconsContainerCss}
`

const WelcomeMessage = styled.h6`
  ${WelcomeMessageCss}
`

const WelcomeMessageSpan = styled.span`
  ${WelcomeMessageSpanCss}
`

const ProjectFilesContainer = styled.div`
  ${ProjectFilesContainerCss}
`

const ProjectFile = styled.div`
  ${ProjectFileCss}
`

const ProjectFileLogo = styled.img`
  ${ProjectFileLogoCss}
`

const ProjectFileTitle = styled.h6`
  ${ProjectFileTitleCss}
`

interface Props {
  space?: ISpace
  handleNewProjectFileModalShow: () => void
}

const SpaceProjects = ({ space, handleNewProjectFileModalShow }: Props) => {
  const _spaceProjects = useSpaceProjects({ space })

  return (
    <>
      <SpaceTabHeaderWrapper>
        <SearchAndLayoutContainer position='absolute' top='2rem' left='2.5rem'>
          <AuthInput
            onChangeFunction={(e) => _spaceProjects.handleFilter(e)}
            type='text'
            placeholder='Search projects'
            className='pt-1 pb-1 remove-borders'
            iconLeft={magnifierIcon}
            paddingLeft='2.75rem'
            padding='0.55rem 0.5rem 0.5rem 2.75rem !important'
            width='180px'
          />

          <SpaceTabBodyHeaderText text={'All project files'} />
        </SearchAndLayoutContainer>

        <LayoutIconsContainer>
          <ListIcon
            className={`fa-solid fa-list-ul ${
              _spaceProjects.layout === 'boxes' ? 'selectedColouredState' : ''
            }`}
            isSelected={_spaceProjects.layout === 'boxes'}
            onClick={() => _spaceProjects.setLayout('boxes')}
          />
          <BoxesIcon
            className={`fa-solid fa-boxes-stacked ${
              _spaceProjects.layout === 'list' ? 'selectedColouredState' : ''
            }`}
            isSelected={_spaceProjects.layout === 'list'}
            onClick={() => _spaceProjects.setLayout('list')}
          />
        </LayoutIconsContainer>
      </SpaceTabHeaderWrapper>

      {_spaceProjects.projectFiles.length < 1 && (
        <WelcomeMessage>
          Welcome to your space! To start,{' '}
          <WelcomeMessageSpan onClick={handleNewProjectFileModalShow}>
            create a project
          </WelcomeMessageSpan>{' '}
          or explore the first project.
        </WelcomeMessage>
      )}

      {_spaceProjects.layout !== ProjectFileOrganisation.LIST && (
        <SpaceTableHeader titleList={spaceProjectsHeaderTitles} />
      )}

      <ProjectFilesContainer
        layout={_spaceProjects.layout === ProjectFileOrganisation.LIST}
        className={`${
          _spaceProjects.layout === ProjectFileOrganisation.LIST ? '' : 'organisedAsBoxes'
        }`}
        marginBottom={_spaceProjects.projectFiles.length > 8 ? '16rem' : '2rem'}
      >
        {(_spaceProjects.filteredProjectFiles.length
          ? _spaceProjects.filteredProjectFiles
          : !_spaceProjects.isUserTypingInInput
          ? _spaceProjects.projectFiles
          : []
        ).map((projectFile) => {
          if (_spaceProjects.layout === ProjectFileOrganisation.LIST)
            return (
              <ProjectFile
                onClick={() => {
                  _spaceProjects.openProjectFile(projectFile.id)
                }}
                key={projectFile.id}
                onMouseEnter={() => {
                  _spaceProjects.setHoveredProjectFileid(projectFile)
                  _spaceProjects.setAreIconsShown(true)
                }}
                onMouseLeave={() => {
                  _spaceProjects.setHoveredProjectFileid(undefined)
                  _spaceProjects.setAreIconsShown(false)
                  _spaceProjects.setIsDeleteButtonShown(false)
                }}
              >
                <ProjectFileLogo src={Logo} alt='Everyday file logo' />

                <ProjectFileTitle>{projectFile.title}</ProjectFileTitle>

                <FileCardOptions>
                  <ProjectMenuDropdown
                    projectFile={projectFile}
                    handleModalShow={_spaceProjects.handleModalShow}
                    handleRenameModalOpen={_spaceProjects.handleRenameModalOpen}
                    handleDuplicateProjectFile={_spaceProjects.handleDuplicateProject}
                    handleDeleteProjectFile={_spaceProjects.handleDeleteProject}
                    handleCopyLink={_spaceProjects.handleCopyLink}
                  />
                </FileCardOptions>
              </ProjectFile>
            )

          if (_spaceProjects.layout !== ProjectFileOrganisation.LIST)
            return (
              <ProjectFileTrack
                title={projectFile.title}
                imageSrc={Logo}
                creatorName={`${_spaceProjects.currentUser?.firstName} ${_spaceProjects.currentUser?.lastName}`}
                lastEdited={getStandardDateTimeFormat(projectFile.updatedAt, true)}
                createdAt={getStandardDateTimeFormat(projectFile.createdAt, false)}
                onClick={() => _spaceProjects.openProjectFile(projectFile.id)}
                projectFileId={projectFile.id}
                deleteProjectFile={_spaceProjects.handleDeleteProject}
              />
            )
        })}
        <ProjectFile
          onClick={handleNewProjectFileModalShow}
          className={_spaceProjects.layout !== ProjectFileOrganisation.LIST ? 'moveTop' : ''}
        >
          <ProjectFileLogo
            src={CirclePlus}
            isNew={true}
            marginRight={
              _spaceProjects.layout !== ProjectFileOrganisation.LIST ? '2rem !important' : ''
            }
          />
          <ProjectFileTitle isNew={true}>New project file</ProjectFileTitle>
        </ProjectFile>

        <MoveProjectModal
          moveModalOpen={_spaceProjects.moveModalOpen}
          handleMoveModalClose={_spaceProjects.handleModalClose}
          selectedSpace={_spaceProjects.selectedSpace}
          selectedProject={_spaceProjects.selectedProject}
          handleSelectSpace={_spaceProjects.handleSelectSpace}
          options={_spaceProjects.options}
          handleCopyProjectToAnotherSpace={_spaceProjects.handleCopy}
        />

        <ChangeProjectNameModal
          show={_spaceProjects.isRenameModalShown}
          handleClose={_spaceProjects.handleRenameModalClose}
          setName={_spaceProjects.setProjectName}
          onShow={() => void 0}
          label='Project name'
          placeholder='Enter your new project name'
          saveChanges={_spaceProjects.handleRenameProjectFile}
          name={_spaceProjects.projectName}
        />
      </ProjectFilesContainer>
    </>
  )
}
export default SpaceProjects
