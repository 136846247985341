import { Form } from 'react-bootstrap'
import styled from 'styled-components'

type ModalInnerContainerPropsType = {
  placeholderColour?: string
}

export const ModalTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 50%;
  color: var(--primary-text);
`
export const ModalInnerContainer = styled.div<ModalInnerContainerPropsType>`
  padding: 12px 12px;

  background: var(--overlay-modal);

  & form * {
    font-size: 14px;
  }

  * ::placeholder {
    color: ${(props) => props.placeholderColour || ''} !important;
  }

  .message-wrapper {
    background-color: transparent;
  }
`

export const Button = styled.button<{ disabled?: boolean }>`
  color: white;
  padding: 8px 16px 8px 16px;
  border-radius: 8px;
  border: none;
  font-weight: 600 !important;
  font-size: 14px;
  margin: 0 !important;

  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  background-color: ${(props) => (props.disabled ? 'var(--disabled)' : 'var(--primary-color)')};

  :hover {
    opacity: 0.8;
  }
`

export const Input = styled(Form.Control)`
  border-radius: 10px;
  outline: none;

  :focus {
    box-shadow: none;
    outline: 1px solid var(--everyday-blue);
  }
`
