import styled from 'styled-components'
export const StyledUploadOrLinkSelectorWrapper = styled.div`
  background-color: var(--overlay-modal);
  padding-top: 1rem;
  width: 297px;
  min-width: 200px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.15);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  z-index: 10;

  h6 {
    height: 26px;
    padding: 0;
    top: 0 !important;
    color: var(--primary-text) !important;
  }
`
