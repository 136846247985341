import { icons } from 'shared/icons'
import styled from 'styled-components'
import Button from 'components/atoms/Button/Button'
import { AxiosService } from 'services/axiosService/axiosService'
import { useToast } from 'services/helpers/useToast'
import { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner'
import CustomP from 'components/atoms/CustomP/CustomP'

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`

interface Props {
  pageId: string
}

export const NoAccess = (props: Props) => {
  const [loading, setLoading] = useState(false)
  const axiosInstance = new AxiosService()
  const toast = useToast()
  return (
    <StyledWrapper>
      <icons.lock />
      <CustomP fontSize='1.5em' color={'var(--text)'}>
        You don't have access to this page{' '}
      </CustomP>
      <Button
        onClick={async () => {
          setLoading(true)
          const response = await axiosInstance.requestAccessToPage(props.pageId)
          if (response) toast.success('Successfully sent request!')
          setLoading(false)
        }}
        variant='primary'
        size='medium'
      >
        {loading ? <Spinner size='sm' variant='light' /> : <CustomP>Ask for access</CustomP>}
      </Button>
    </StyledWrapper>
  )
}
