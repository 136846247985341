import React from 'react'
import { Spinner } from 'react-bootstrap'
import styled from 'styled-components'
import './style.css'

const StyledContainer = styled.div`
  width: 100%;
  height: calc(100% - 2 * var(--topbar-height));
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = () => {
  return (
    <StyledContainer>
      <div className='sk-chase'>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
        <div className='sk-chase-dot'></div>
      </div>
    </StyledContainer>
  )
}
export default Loader
