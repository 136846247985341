import { validateEmail } from 'utils/auth/validateEmail'

export const useSigninForm = (email: string) => {
  const emailValidityChecker =
    email.length !== 0
      ? !validateEmail(email) && email.length > 0
        ? 'is-invalid placeholderDarker'
        : 'placeholderConfirm'
      : 'placeholderDarker'

  return {
    emailValidityChecker,
  }
}
