import { useAppDispatch, useAppSelector } from 'redux/hooks'
import { useUpdatePage } from '_features/page'
import useOnPageClick from 'utils/useOnPageClick'
import { IBlockTypes, type block, getBlockExpandedState } from '_entities/block'
import { setFreshlyCreatedBlockPageId } from 'redux/reducers/projectFileReducer'
import { setFullScreen } from 'redux/reducers/chatReducer'

export const useInternalEmbedBlock = () => {
  const dispatch = useAppDispatch()
  const { updateTitle } = useUpdatePage()
  const onPageClick = useOnPageClick()
  const pages = useAppSelector((state) => state.projectFile.pages)
  const freshlyCreatedBlockPageId = useAppSelector(
    (state) => state.projectFile.freshlyCreatedBlockPageId,
  )

  const INTERNAL_EMBED_TYPES = [
    IBlockTypes.DOCUMENT,
    IBlockTypes.WHITEBOARD,
    IBlockTypes.EMBED,
    IBlockTypes.SLIDESHOW,
  ]

  const isInternalCanvas = (block: block) => {
    return block.data.tag === IBlockTypes.WHITEBOARD
  }

  const isInternalDocument = (block: block) => {
    return block.data.tag === IBlockTypes.DOCUMENT
  }

  const isInternalEmbed = (block: block) => INTERNAL_EMBED_TYPES.includes(block.data.tag)

  const getInternalEmbedBlocksFromBlocks = (blocks: block[]) => blocks.filter(isInternalEmbed)

  const getInternalCanvasBlocksFromBlocks = (blocks: block[]) =>
    blocks.filter((block) => isInternalCanvas(block))

  const findIfSomeInternalCanvasIsExpanded = (blocks: block[]) => {
    return getInternalCanvasBlocksFromBlocks(blocks).some((block) => getBlockExpandedState(block))
  }

  const getPageIdsFromInternalEmbedBlocks = (blocks: block[]) => {
    const ids = blocks.map((block) => getBlockPageId(block))
    return ids.filter((id) => id !== undefined)
  }

  const getBlockPageId = (block: block) => {
    return block.data.documentPage?.id || block.data.embed?.pageId
  }

  const getLeftSidebarBlockPage = (block: block) =>
    pages?.find((page) => page.id === getBlockPageId(block))

  const getBlockPageTitle = (block: block) => getLeftSidebarBlockPage(block)?.text

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, block: block) => {
    event.stopPropagation()
    const valueBeforeCurrentKey = (event.target as HTMLInputElement).value
    if (event.key === 'Enter') {
      event.preventDefault()
      const blockPageId = getBlockPageId(block)
      if (valueBeforeCurrentKey && blockPageId) {
        updateTitle(valueBeforeCurrentKey, blockPageId)
        dispatch(setFreshlyCreatedBlockPageId(undefined))
      }
    } else if (event.key === 'Escape') {
      event.preventDefault()
      dispatch(setFreshlyCreatedBlockPageId(undefined))
    }
  }

  const handleOnBlur = () => {
    dispatch(setFreshlyCreatedBlockPageId(undefined))
  }

  const handleOpenChatFullScreen = () => {
    dispatch(setFullScreen(true))
  }

  return {
    isInternalEmbed,
    isInternalCanvas,
    isInternalDocument,
    getInternalEmbedBlocksFromBlocks,
    getInternalCanvasBlocksFromBlocks,
    findIfSomeInternalCanvasIsExpanded,
    getPageIdsFromInternalEmbedBlocks,
    onPageClick,
    getBlockPageId,
    getLeftSidebarBlockPage,
    getBlockPageTitle,
    freshlyCreatedBlockPageId,
    handleOnKeyDown,
    handleOnBlur,
    handleOpenChatFullScreen,
  }
}
