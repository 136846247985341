import React from 'react'
import { Hr } from './styles'

interface Props {
  bordertop?: string
  margin?: string
}

export const HrDivider = ({ bordertop, margin }: Props) => {
  return <Hr bordertop={bordertop} margin={margin} />
}
