import styled, { css } from 'styled-components'

export { ReactComponent as PlusIcon } from 'assets/images/Plus.svg'

export const TaskContent = styled.div<{
  type?: 'vertical' | 'horizontal'
  isSimpleView?: boolean
}>`
  display: flex;
  gap: ${(props) => (props.isSimpleView ? '0' : '6px')};
  flex-direction: column;
  justify-content: space-between;
`

export const TaskPropertiesWrapper = styled.div<{ type?: 'vertical' | 'horizontal' }>`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const SidebarListItemInput = styled.input<{
  width?: string
  border?: string
  padding?: string
  isDisabled?: boolean
  isSimpleView?: boolean
}>`
  min-width: 0;
  width: ${(props) => props.width || '0'};

  border: ${(props) => props.border || 'none'};
  padding: ${(props) => (props.isSimpleView ? '4px 0' : '8px 0px')};
  padding-bottom: ${(props) => (props.isSimpleView ? '0px' : '8px')};
  border-radius: 7px;
  outline: none;
  flex-grow: 1;

  font-weight: ${(props) => (props.isSimpleView ? '600' : '500')};
  font-size: ${(props) => (props.isSimpleView ? '14px' : '16px')};
  line-height: 20px;
  color: var(--secondary-text);
  background: transparent;
  cursor: ${(props) => (props.isDisabled ? 'pointer' : 'text')};
  transition: all 0.1s ease-in-out;
  pointer-events: ${(props) => (props.isDisabled ? 'none' : 'auto')};
`

export const SelectedObjectWrapper = styled.button<{ minHeight?: number }>`
  background-color: #f1f1f1;
  border: solid 1px #d6d6d6;
  border-radius: 7px;
  position: relative;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  width: 100%;
  min-height: ${(props) => props.minHeight}px;
  text-align: left;
  overflow: hidden;
  z-index: 3;
`

export const TaskDocumentWrapper = styled.div<{ isSlider?: boolean }>`
  .PageBoundaryElementDetector {
    padding-left: 24px;
    padding-bottom: 120px;
    padding-top: 32px;
  }
`

export const StyledButton = styled.button<{ width?: string }>`
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  width: ${(props) => props.width || '100%'};

  &:hover {
    background: var(--hover);
  }
  &:focus {
    outline: none;
  }
`
