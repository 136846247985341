import styled from 'styled-components'

export const StyledLabel = styled.label<{ margin?: string }>`
  width: 100% !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;

  margin: ${({ margin }) => margin || '0'};

  button {
    width: 100%;
    pointer-events: none;
  }
`

export const StyledImageUpload = styled.div`
  padding: 1rem;
  button {
    width: 100%;
  }
`
