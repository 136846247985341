import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import Image from 'components/atoms/Image/Image'
import diagonalCoverToNorthEast from 'assets/images/auth/diagonal-background-v1.png'
import diagonalCoverHorizontal from 'assets/images/auth/diagonal-background-v2.svg'
import diagonalCoverToSouthEast from 'assets/images/auth/diagonal-background-v3.png'
import diagonalCoverSouth from 'assets/images/auth/diagonal-background-v4.png'

const DisplayCoverImage = () => {
  const [urlLocation, setUrlLocation] = useState<string | null>(null)
  const location = useLocation()

  useEffect(() => {
    setUrlLocation(location.pathname)
  }, [location])

  const backgroundChoiceMap = new Map([
    ['/', diagonalCoverToNorthEast],
    ['/forgotpassword', diagonalCoverHorizontal],
    ['/signin', diagonalCoverToSouthEast],
    ['/onboarding', diagonalCoverSouth],
  ])

  if (!urlLocation) return null
  const chosenBackground = backgroundChoiceMap.get(urlLocation)
  if (!chosenBackground) return null

  if (chosenBackground)
    return (
      <Image
        src={chosenBackground}
        alt='Everyday initial steps cover'
        width='100vw'
        height='100% !important'
        position='absolute !important'
        inset='0 !important'
        zIndex={'0 !important'}
        objectFit={chosenBackground === diagonalCoverHorizontal ? 'cover !important' : ''}
        imageId='cover-initial-image'
      />
    )

  return null
}

export default DisplayCoverImage
