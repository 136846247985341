import styled, { css } from 'styled-components'
import { ReactComponent as ArrowDown } from 'assets/images/keyboard_arrow_down.svg'

export const ArrowDownIcon = styled(ArrowDown)<{ isOpen?: boolean }>`
  transform: ${(props) => (props.isOpen ? 'rotate(0)' : 'rotate(90deg)')};
  transition: transform 0.2s ease-in-out;
  margin: 0 0 0 8px;
  path {
    fill: var(--primary-text);
  }
`

export const DropDownContainer = styled.div<{
  width?: string
  fontsize?: string
  margin?: string
  padding?: string
  maxheight?: string
  height?: string
  opacity?: string
  transition?: string
  position?: string
  pageReference?: boolean
  pageModalIsOpen?: boolean
  isDropdownOpen?: boolean
  backgroundColour?: string
  color?: string
}>`
  width: ${(props) => props.width};
  max-height: ${(props) => props.maxheight};
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  padding: ${(props) => props.padding};
  opacity: ${(props) =>
    props.opacity
      ? props.opacity
      : props.pageReference && props.isDropdownOpen
      ? '1'
      : props.pageReference && !props.isDropdownOpen
      ? '0'
      : '1'};
  transition: ${(props) => props.transition};
  &:hover {
    opacity: ${(props) =>
      props.pageReference && !props.pageModalIsOpen
        ? '1'
        : props.pageReference && props.pageModalIsOpen
        ? '0'
        : '1'};
  }
  position: ${(props) => props.position};
  p {
    color: ${(props) => (props.color ? props.color : 'var(--primary-text)')};
  }
  background: ${(props) =>
    props.backgroundColour ? props.backgroundColour : 'var(--overlay-modal)'};

  ul {
    color: ${(props) => (props.color ? props.color : 'var(--primary-text)')};
    background: ${(props) =>
      props.backgroundColour ? props.backgroundColour : 'var(--overlay-modal)'};

    li:hover {
      background-color: var(--hover);
    }
  }
`
export const DropDownHeader = styled.div<{
  isOpen?: boolean
  focusedBorder?: string
  headerPadding?: string
  borderHeader?: string
  fontWeight?: string
  pending?: boolean
}>`
  display: flex;
  font-size: 14px;
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => (props.pending ? '#a6a6a6' : 'var(--primary-text)')};
  cursor: pointer;
  white-space: nowrap;
  padding: ${(props) => props.headerPadding};
  border: ${(props) => (props.isOpen ? `${props.focusedBorder}` : `${props.borderHeader}`)};
  border-radius: 8px;
`

export const DropDownHeaderText = styled.p<{ maxWidth?: string; fontSize?: string }>`
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  max-width: ${(props) => props.maxWidth};
  color: var(--black);
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
`

export const BackgroundContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  z-index: 2;
`
