import styled from 'styled-components'

type SpaceInfoSectionStyledProps = {
  marginTop?: string
  marginBottom?: string
}

type DeleteContainerStyledProps = {
  marginTop?: string
}

type ChangeButtonProps = {
  marginTop?: string
}

export const SpaceSettingsStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-top: -12px;
  padding-top: 32px;
  border-top: 3px solid var(--gray4);

  & > * {
    font-style: normal;
  }

  font-style: normal;
  margin-left: 3.4rem;
`

export const SeparatorContainer = styled.div`
  width: 100%;
  margin-top: 1rem;

  & > * {
    align-items: center;
    padding: 0 24px 0 0 !important;
  }
`

export const SpaceInfoSection = styled.div<SpaceInfoSectionStyledProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : '')};

  & > div {
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.2));
  }
`
export const SpaceSettingsLabelStyled = styled.label`
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  color: #aeb0b4;
`
export const DeleteContainer = styled.label<DeleteContainerStyledProps>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
`

export const SpaceSettingsName = styled.label`
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;
  margin-block: 0.25rem 1rem;
`

export const SpaceRowContainer = styled.label`
  display: flex;
  align-items: first baseline;
  gap: 16px;
`

export const ChangeButton = styled.span<ChangeButtonProps>`
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  color: var(--everyday-blue);
  margin-top: ${(props) => (props.marginTop ? props.marginTop : '')};
  width: fit-content;
  border-radius: 10px;
  padding: 0.5rem 0rem;
  transition: all 0.5s ease-out;

  &:hover {
    background-color: var(--gray4);
    padding: 0.5rem 1rem;
  }

  cursor: pointer;
`
export const DangerButton = styled.div`
  width: fit-content;
  font-weight: 600;
  color: #fa5a32 !important;
  background: transparent !important;
  border-radius: 10px;
  padding-top: 5.5px !important;
  padding-bottom: 5.5px !important;
  padding-left: 0;
  transition: all 0.1s ease-out;
  margin-block: 0.5rem 1rem;

  :hover {
    background-color: var(--gray4) !important;
    padding-left: 1rem;
  }
  :active {
    border-color: transparent !important;
  }

  cursor: pointer;
`
