import styled from 'styled-components'

export const CTABtn = styled.div<{
  fontFamily?: string
  fontSize?: number
  textColor?: string
  buttonBackgroundColor?: string
  borderRadius?: number
  borderColor?: string
  buttonSize?: number
  fontWeight?: number
}>`
  font-family: ${({ fontFamily }) => `${fontFamily}`};
  font-size: ${({ fontSize }) => `${fontSize}px` || '14px'};
  color: ${({ textColor }) => textColor || 'var(--primary-text'};
  background-color: ${({ buttonBackgroundColor }) => buttonBackgroundColor || ''};
  border-radius: ${({ borderRadius }) => `${borderRadius}px` || '10px'};
  border: ${({ borderColor }) => `1px solid ${borderColor}` || 'none'};
  padding: 0px 24px;
  height: ${({ buttonSize }) => `${buttonSize}px` || '40px'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  cursor: pointer;
  text-align: center;
  outline: none;
  min-height: 40px;
  max-width: 260px;
  white-space: nowrap;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
`

export const StyledCtaButtonWrapper = styled.div<{
  isEditing: boolean
  align?: string
}>`
  padding: 4px;
  border-radius: 10px;
  min-width: 444px;
  width: ${({ isEditing }) => (isEditing ? '444px' : '100%')};
  margin-left: ${({ isEditing }) => (isEditing ? '-13px' : '0')};
  margin-top: ${({ isEditing }) => (isEditing ? '-13px' : '0')};
  display: ${({ isEditing }) => (!isEditing ? 'flex' : 'block')};
  justify-content: ${({ align }) => align};
`
