import { block } from '_entities/block'
import { getWhiteboardContainerId } from '../lib/getters'

export enum CanvasAttributes {
  BLOCKS = 'data-blocks',
}

export interface CanvasAttributesData {
  [CanvasAttributes.BLOCKS]: block[]
}

export const getBlocks = (pageId: string) => {
  const element = document.getElementById(getWhiteboardContainerId(pageId))
  if (element) {
    return JSON.parse(element.getAttribute(CanvasAttributes.BLOCKS) || '[]') as block[]
  }
}
