import React from 'react'
import CustomButton from 'components/atoms/CustomButton/CustomButton'
import ProfilePhotoEditor from 'components/organisms/ProfilePhotoEditor/ProfilePhotoEditor'
import LoadingAnim from 'components/atoms/LoadingAnim/LoadingAnim'
import HeaderWrapper from 'components/templates/HeaderWrapper/HeaderWrapper'
import CustomP from 'components/atoms/CustomP/CustomP'
import { useInitialPhotoUpload } from './useInitialPhotoUpload'

const InitialPhotoUpload: React.FC = () => {
  const {
    onSubmit,
    getInputProps,
    profileImage,
    editor,
    getRootProps,
    imageScale,
    setImageScale,
    errorValidationMessage,
    error,
    loading,
  } = useInitialPhotoUpload()
  return (
    <HeaderWrapper>
      <div className='row d-flex justify-content-center align-items-center text-center'>
        <div className='d-flex justify-content-center align-items-center flex-column'>
          <h2 className='fw-bold mb-4'>Upload profile photo</h2>
          <p className='mb-3  text-secondary'>
            Upload you profile photo now, or leave empty to skip. You can upload it later in profile
            settings
          </p>

          <div className='col-12 col-md-8 col-lg-6 col-xl-5 mt-2'>
            <div className='border-0'>
              <div className='p-2 text-center'>
                <form
                  onSubmit={onSubmit}
                  className='d-flex justify-content-center align-items-center flex-column'
                >
                  <input {...getInputProps()} />
                  <ProfilePhotoEditor
                    file={profileImage}
                    editor={editor}
                    getInputProps={getInputProps}
                    getRootProps={getRootProps}
                    imageScale={imageScale}
                    setImageScale={setImageScale}
                  />
                  <div className='d-flex align-items-center justify-content-center mt-5 w-100'>
                    <CustomButton className='btn btn-primary w-50' type={'submit'} height='40px'>
                      <CustomP className='mb-0'>{profileImage ? 'Upload' : 'Skip'}</CustomP>
                    </CustomButton>
                  </div>
                </form>
              </div>
              {errorValidationMessage && (
                <span>
                  <p className='text-danger mt-3'>{errorValidationMessage}</p>
                </span>
              )}
              {error && (
                <span>
                  <p className='text-danger mt-3'>{error}</p>
                </span>
              )}
              {loading && (
                <span className='d-flex justify-content-center align-items-center mt-3'>
                  <LoadingAnim className='loading-spinner mb-3' />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
    </HeaderWrapper>
  )
}

export default InitialPhotoUpload
