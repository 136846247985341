import React from 'react'
import CustomP from 'components/atoms/CustomP/CustomP'
import Image from 'components/atoms/Image/Image'
import emptyStar from 'assets/images/emptyStar.svg'
import { StyledDots } from './styles'
import { Row, Col } from 'react-bootstrap'
import { dropdownOptionsForListLayout } from './options'
import { CustomDropdown } from 'components/molecules/CustomDropdown/CustomDropdown'

type ProjectFileTrackPropsType = {
  title: string
  imageSrc: string
  creatorName: string
  lastEdited: string
  createdAt: string
  projectFileId: number
  deleteProjectFile: (projectFileId: number) => void
  onClick?: () => void
}

const ProjectFileTrack = ({
  title,
  imageSrc,
  creatorName,
  lastEdited,
  createdAt,
  onClick,
  projectFileId,
  deleteProjectFile,
}: ProjectFileTrackPropsType) => {
  return (
    <Row className='d-flex align-items-center position-relative moveTop' onClick={onClick}>
      <Col className='d-flex align-items-center gap-1'>
        <Image
          src={imageSrc}
          width='24px !important'
          height='24px !important'
          alt='Project file logo'
          margin='-0.2rem 0 0 0'
        />
        <CustomP text={title} fontSize='13px' />
      </Col>
      <Col className='d-flex align-items-center overflow-hidden'>
        <CustomP text={creatorName} fontSize='14px' />
      </Col>
      <Col className='d-flex align-items-center overflow-hidden'>
        <CustomP color={'var(--gray2)'} text={lastEdited} fontSize='12px' fontWeight='600' />
      </Col>
      <Col className='d-flex align-items-center overflow-hidden'>
        <CustomP text={createdAt} fontSize='13px' />
      </Col>
      <Col className='d-flex align-items-center justify-content-end '>
        <Image
          src={emptyStar}
          alt='Empty star'
          width='16px !important'
          height='16px !important'
          margin='-0.5rem 1rem 0 0 !important'
          onClick={(e) => e?.stopPropagation()}
        />
        <CustomDropdown
          dropdownOptions={dropdownOptionsForListLayout(projectFileId, deleteProjectFile)}
          positionDropdown='relative'
          toggleButton={<StyledDots />}
          menuStylesOverrides={'z-index: 100 !important; cursor: pointer;'}
          transformMenu='translate3d(-120%, -30%, 100px) scale(0.7) !important;'
          iconWidth='20px !important'
        />
      </Col>
    </Row>
  )
}

export default ProjectFileTrack
