import useLocalStorage from 'shared/lib/useLocalStorage'
export const usePagesTreeStructure = () => {
  const _localStorage = useLocalStorage()

  interface ITreeStructureItem {
    id: string
    isShown: boolean
  }

  type ITreeStructure = ITreeStructureItem[]

  const getPagesTreeStructure = (projectId: string) => {
    return _localStorage.getParsedItem(`treeStructure-${projectId}`)
  }

  const setNewPageIntoTreeStructure = (projectId: string, id: string) => {
    const treeStructure = _localStorage.getParsedItem(`treeStructure-${projectId}`)

    treeStructure &&
      _localStorage.setItem(`treeStructure-${projectId}`, [
        ...treeStructure,
        {
          id,
          isShown: true,
        },
      ])
  }

  const setNewTreeStructure = (projectId: string, tree: ITreeStructure) => {
    _localStorage.setItem(`treeStructure-${projectId}`, tree)
  }

  return {
    getPagesTreeStructure,
    setNewPageIntoTreeStructure,
    setNewTreeStructure,
  }
}
