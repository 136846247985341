import { ISuggestedUser } from './projectFile'
import { ILinkExpiration } from './space'

export enum PageLinkSharingEnum {
  CAN_EDIT = 'can-edit',
  CAN_COMMENT = 'can-comment',
  CAN_VIEW = 'can-view',
  NO_ACCESS = 'no-access',
}

export const publicLinkNameMapper: { [key in PageLinkSharingEnum]: string } = {
  [PageLinkSharingEnum.NO_ACCESS]: 'No Access via link',
  [PageLinkSharingEnum.CAN_EDIT]: 'Can Edit',
  [PageLinkSharingEnum.CAN_VIEW]: 'Can View',
  [PageLinkSharingEnum.CAN_COMMENT]: 'Can Comment',
}

export enum IPermissionsPrivacy {
  ANYONE_WTIH_LINK = 'Anyone with the link',
  ONLY_PROJECT_MEMBERS = 'Only project members can access',
}

export enum IStatus {
  PENDING = 'pending',
  FAILED = 'failed',
  REQUESTED = 'requested',
}

export enum IPermissionDuration {
  NEVER = 'Never',
  HOUR = '1 hour',
  ONEDAY = '24 hours',
  ONEWEEK = '7 days',
}

export enum IRoles {
  OWNER = 'Owner',
  ADMIN = 'Admin',
  MEMBER = 'Member',
  GUEST = 'Guest',
  REMOVE_MEMBER = 'Remove member',
  SUPER_ADMIN = 'super-admin',
}

export interface IFocusedSuggestedUser {
  user: ISuggestedUser
  index: number
}

export interface SuggestedSelectUser {
  label: string
  value: string
  id: number
}

export interface IGeneralDropdownOption {
  name: string
  value: string | number
}

export interface ILinkSharingOption {
  name?: JSX.Element
  label?: string
  id?: number
  onClick?: () => void
  value: number
  linkSharing: PageLinkSharingEnum
  isCheckmark?: boolean
}

export interface IMultiValueOption {
  label: string
  value: string
}

export interface IPagePermission {
  name: string
  value: number
}

export interface ISharePageWith {
  name: string
  value: number
  access: string
}

export type IDropdownOption = ILinkExpiration
