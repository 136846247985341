import styled from 'styled-components'

export const CustomIconCss = styled.img<{
  padding?: string
  margin?: string
  rotatez?: string
  isOpen?: boolean
  isSelected?: boolean
  opacity?: string
}>`
  cursor: pointer;

  align-self: center;
  padding: ${(props) => props.padding};
  margin: ${(props) => props.margin};
  transform: ${(props) => props.isOpen && `rotateZ(${props.rotatez})`};
  transition: transform 0.4s ease-in-out;
  opacity: ${(props) => props.opacity};
`
