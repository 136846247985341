import { createSlice } from '@reduxjs/toolkit'
import { type block } from '_entities/block'
import { ITools } from 'interfaces/whiteboard'

interface WhiteboardState {
  selectedBlock?: block
  tool: ITools
  slashMenuOpen: boolean
  zoom?: number
}

const initialState = { tool: ITools.CURSOR, slashMenuOpen: false } as WhiteboardState

const whiteboardSlice = createSlice({
  name: 'whiteboardReducer',
  initialState,
  reducers: {
    setSelectedBlock: (state, action) => {
      state.selectedBlock = action.payload
    },
    setTool: (state, action) => {
      state.tool = action.payload
    },
    setSlashMenuOpen: (state, action) => {
      state.slashMenuOpen = action.payload
    },
    setZoom: (state, action) => {
      state.zoom = action.payload
    },
  },
})

export const { setSelectedBlock, setTool, setSlashMenuOpen, setZoom } = whiteboardSlice.actions

export default whiteboardSlice.reducer
