import styled from 'styled-components'
import { Position } from 'interfaces/projectFile'
import { isMobile } from 'react-device-detect'

export const SideBarWidthButtonStyled = styled.div<{ position: Position; isMinimized: boolean }>`
  height: var(--sidebar-button-width);
  width: var(--sidebar-button-width);
  transition: all 0.2s ease-in-out;
  border-radius: 8px;
  background-color: var(--hover);
  box-shadow: 0 0 4px var(--secondary-text);
  align-items: center;
  text-align: center;
  position: absolute;
  left: ${(props) =>
    props.position === Position.RIGHT ? (props.isMinimized ? '-48px' : '-14px') : undefined};
  right: ${(props) =>
    props.position === Position.LEFT ? (props.isMinimized ? '-48px' : '-14px') : undefined};
  bottom: ${() => (isMobile ? '64px' : '24px')};
  z-index: 2;
  cursor: pointer;

  :hover {
    transform: scale(1.2);
  }

  svg path {
    fill: var(--primary-text);
  }
`
