import { StageAttrs, StageAttrsData, useStage } from '_features/canvas'
import { usePage } from '_entities/page'

export const useWhiteboardEmbed = () => {
  const _stage = useStage()
  const _page = usePage()

  // ======= STAGE ATTR STATE =======
  const getIsEmbed = (pageId: string) => {
    const isPresentation = _stage.getStageAttr(pageId, StageAttrs.IS_EMBED)
    if (!isPresentation) return
    return isPresentation as StageAttrsData[StageAttrs.IS_EMBED]
  }

  const getParentPageId = (pageId: string) => {
    const parentPageId = _stage.getStageAttr(pageId, StageAttrs.PARENT_PAGE_ID)
    if (!parentPageId) return
    return parentPageId as StageAttrsData[StageAttrs.PARENT_PAGE_ID]
  }

  const getParentBlock = (pageId: string) => {
    const parentBlock = _stage.getStageAttr(pageId, StageAttrs.PARENT_BLOCK)
    if (!parentBlock) return
    return parentBlock as StageAttrsData[StageAttrs.PARENT_BLOCK]
  }

  const isDocEmbed = (pageId: string) => {
    const isEmbed = getIsEmbed(pageId)
    const parentPageId = getParentPageId(pageId)
    if (isEmbed && parentPageId) {
      if (_page.isDocument(parentPageId)) return true
      else return false
    } else return undefined
  }

  return {
    getIsEmbed,
    getParentPageId,
    getParentBlock,
    isDocEmbed,
  }
}
