import { CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import styled from 'styled-components'
import { icons } from 'shared/icons'

export const SelectStyles = (
  hideControl?: boolean,
  isSearchable?: boolean,
): StylesConfig<Option, false, GroupBase<Option>> => {
  return {
    container: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      padding: '0',
      backgroundColor: 'var(--overlay-modal)',
    }),
    singleValue: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      position: 'absolute',
      backgroundColor: 'var(--overlay-modal)',
    }),
    control: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      border: '1px solid #E3E3E3 !important',
      borderRadius: '8px',
      boxShadow: 'none',
      fontSize: '14px',
      display: hideControl ? 'none' : 'block',
      backgroundColor: 'var(--overlay-modal)',
      width: '100%',
      'div:has(> input)': {
        display: 'flex',
      },
    }),
    placeholder: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      color: 'var(--primary-text)',
    }),
    valueContainer: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      width: '100%',
      padding: !isSearchable ? '8px 8px' : '8px',
    }),
    input: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      paddingBottom: '.4em',
      zIndex: 1,
      cursor: 'text',
      color: 'var(--primary-text)',
      input: {
        minWidth: '100% !important',
        width: 'initial !important',
      },
    }),
    menu: (baseStyles: CSSObjectWithLabel) => ({
      ...baseStyles,
      margin: 0,
      padding: 0,
      borderColor: 'transparent',
      border: 0,
      borderRadius: '8px',
      position: 'absolute',
      left: 0,
      width: '100%',
      fontSize: '14px',
      backgroundColor: 'var(--overlay-modal)',
      boxShadow: '0px 4px 16px 0px #00000026',
    }),
    option: (
      baseStyles: CSSObjectWithLabel,
      state: OptionProps<Option, false, GroupBase<Option>>,
    ) => ({
      ...baseStyles,
      padding: '6px !important',
      color: 'var(--primary-text)',
      backgroundColor: 'var(--overlay-modal)',
      borderRadius: '8px',
      ':hover': {
        backgroundColor: 'var(--hover)',
      },
    }),
    multiValue: (base: CSSObjectWithLabel) => ({
      ...base,
      backgroundColor: 'var(--hover)',
      borderRadius: '8px',
      maxWidth: '300px',
      overflowX: 'hidden',
    }),
    multiValueRemove: (base: CSSObjectWithLabel) => ({
      ...base,
      cursor: 'pointer',
      ':hover': {
        backgroundColor: 'var(--hover)',
      },
      svg: {
        stroke: 'var(--primary-text)',
        fill: 'var(--primary-text)',
      },
    }),
    indicatorsContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      padding: '0 8px',
    }),
  }
}

export const StyledWrapper = styled.div<{ hideControl?: boolean }>`
  display: ${(props) => (props.hideControl ? 'none' : 'flex')};
  height: 100%;
  cursor: pointer;
  font-size: 14px;
  border: 1px solid var(--secondary-text);
  border-radius: 8px;
  box-shadow: none;
  width: 100%;
  align-items: center;
  padding: 0 6px;
  gap: 4px;
`

export const StyledCaret = styled(icons.caretDown)`
  padding-right: 12px;
`
