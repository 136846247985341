import styled from 'styled-components'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

export const StyledModal = styled(Modal)`
  & .modal-dialog {
    max-width: 580px;
  }
  & .modal-content {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 24px 0;
  }

  & .btn-close {
    background-size: 12px 12px;
  }
`

export const StyledModalHeader = styled(Modal.Header)`
  border-bottom: none;
  padding: 0 !important;
`
export const StyledModalTitle = styled(Modal.Title)`
  font-size: 16px;
  font-weight: bold;
  padding: 0 0 10px 0;
`

export const StyledModalBody = styled(Modal.Body)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const StyledTitleInputWithLabel = styled(Form.Group)`
  margin-bottom: 0.5rem;
`

export const StyledInput = styled(Form.Control)`
  font-size: 14px;
  border-radius: 8px !important;

  &:focus {
    box-shadow: none;
    border: 2px solid var(--everyday-blue);
  }
`

export const StyledLabel = styled(Form.Label)`
  font-weight: bold;
  font-size: 13px;
  color: #61777c;
  padding: 10px 0 5px 0;
`

export const StyledSelectInputWithLabel = styled(Form.Group)`
  width: 100%;
  margin-top: 10px;
`

export const StyledSelectInputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &:focus {
    border: '1px solid red';
  }
`

export const StyledSelectInput = styled(Form.Select)`
  border-radius: 8px;
  font-size: 14px;
  width: fit-content;
  border: none;
`

export const StyledOption = styled.option`
  border: none !important;
  border-radius: 8px;
`

export const StyledButton = styled(Button)`
  height: 36px;
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 8px 20px;
  font-size: 14px;
  font-weight: 600;
  margin-block: 1rem 0.5rem;

  :hover {
    opacity: 0.8;
    background-color: var(--everyday-blue);
  }
`
