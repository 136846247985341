import styled from 'styled-components'

type Props = {
  isProfile: boolean
}

export const IconOptionContainer = styled.div<Props>`
  padding-top: ${(props) => (props.isProfile ? '0.5rem !important' : '0 !important')};
`

export const StyledDropDownList = styled.ul`
  padding: 0;
  margin: 0;
  opacity: 1;
  background: var(--overlay-modal);
  color: var(--primary-text);
  font-size: 14px;
  border-radius: 8px;
  width: 100%;
  word-break: break-all;
`
