import React from 'react'
import { StyledTabs } from './styles'
import Tab from 'components/atoms/Tab/Tab'
import { ITab } from 'interfaces/tabs'

interface Props {
  onClick: (text: string) => void
  tabs: ITab[]
  selectedTab: ITab
  numberOfMembers?: number
  tabFontSize?: string
  padding?: string
  height?: string
  borderBottom?: string
}

const Tabs = ({
  tabs,
  selectedTab,
  onClick,
  numberOfMembers,
  tabFontSize,
  padding,
  height,
  borderBottom,
}: Props) => {
  return (
    <StyledTabs padding={padding} height={height} borderBottom={borderBottom}>
      {tabs.map((tab, i) => {
        return (
          <Tab
            isMembersTags={selectedTab.text === 'Members'}
            numberOfMembers={numberOfMembers}
            onClick={() => onClick(tab.text)}
            key={`${tab.text} - ${i}`}
            text={
              i !== 1 ? tab.text : numberOfMembers ? `${tab.text} (${numberOfMembers})` : tab.text
            }
            selectedTab={selectedTab}
            className={selectedTab ? 'isSelected' : ''}
            tabFontSize={tabFontSize}
          />
        )
      })}
    </StyledTabs>
  )
}

export default Tabs
