import React from 'react'
import { block, IBlockTypes } from '../../block'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { StyledPagePreview } from 'shared/ui/Callout/styles'

interface Props {
  block: block
  Icon: React.FC | null
}

const SourceEmbed = (props: Props) => {
  return (
    <>
      {props.block?.data.tag &&
        (props.block.data.tag === IBlockTypes.EMBED ||
          props.block.data.tag === IBlockTypes.DOCUMENT ||
          props.block.data.tag === IBlockTypes.WHITEBOARD) && (
          <Wrapper margin={'0 0 12px 12px'}>
            <StyledPagePreview>
              {props.Icon && <props.Icon />}
              {props.block.data.documentPage?.title}
            </StyledPagePreview>
          </Wrapper>
        )}
    </>
  )
}

export default SourceEmbed
