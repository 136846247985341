import React from 'react'
import RadixDropdown from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import { PageOption, pageOptions } from 'components/organisms/ProjectFileRightSideBar/constants'
import { useAppSelector } from 'redux/hooks'
import { useSelectPageToEmbed } from '../model/useSelectPageToEmbed'
import { SelectPageDropdown } from './styles'
import { type block } from '_entities/block'
import SingleSelect from 'components/organisms/SingleSelect/SingleSelect'
import { PageTypesEnum } from 'interfaces/projectFile'
import { useSlideShow } from '_entities/slideshow'
import Button from 'components/atoms/Button/Button'
import { getColor } from 'shared/colors'
import Wrapper from 'components/molecules/Wrapper/Wrapper'
import { ReactComponent as FilePlus } from 'assets/images/file-plus-gray.svg'

interface IProps {
  block: block
  innerRef?: React.RefObject<HTMLDivElement>
}

export const SelectPageToEmbed = ({ block, innerRef }: IProps) => {
  const _slideshow = useSlideShow()
  const pages = useAppSelector((state) => state.projectFile.pages)
  const pagesWithoutTaskManager = pages?.filter((page) => page.type !== PageTypesEnum.TASK_MANAGER)
  const whiteboardPages = pages?.filter((page) => page.type === PageTypesEnum.WHITE_BOARD)
  const { selectedPage, handleSelectPage } = useSelectPageToEmbed()

  const isSlideshow = _slideshow.isSlideShow(block)
  return (
    <SelectPageDropdown ref={innerRef}>
      <RadixDropdown
        onMouseDown={(e: React.MouseEvent) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onKeydown={(e: React.KeyboardEvent) => {
          if (e.key === 'Enter' || e.key === 'Tab') {
            e.preventDefault()
            e.stopPropagation()
          }
        }}
        trigger={
          <Button size='medium' variant='transparent'>
            <Wrapper
              width='100%'
              display='flex'
              alignItems='center'
              gap='6px'
              color={getColor('--gray-inactive')}
            >
              <FilePlus />
              {_slideshow.isSlideShow(block) ? 'Click to add slide show' : 'Click to add page'}
            </Wrapper>
          </Button>
        }
      >
        <SingleSelect
          currentValue={selectedPage}
          options={pageOptions(isSlideshow ? whiteboardPages : pagesWithoutTaskManager)}
          onChange={(e) => {
            handleSelectPage(e, block)
          }}
          placeholder='Search pages'
          isClearable={false}
          menuIsOpen={true}
          components={{
            IndicatorSeparator: () => null,
            DropdownIndicator: () => null,
            Option: PageOption as any,
          }}
        />
      </RadixDropdown>
    </SelectPageDropdown>
  )
}
