import ShapeSelectorDropdown from 'components/organisms/Dropdowns/ShapeSelectorDropdown/ShapeSelectorDropdown'
import { type block, getBlockPageId, getBlockTag, getIndex, IBlockTypes } from '_entities/block'
import { SourceKeys } from 'interfaces/editor'
import { IShapeTypes, ITools, Size } from 'interfaces/whiteboard'
import { useJson1 } from 'shared/shareDb/useJson1'
import { useSubmit } from 'utils/shareDB/useSubmit'
import { useShape } from '_entities/shape'
import { useTransformer, useScale } from '_features/canvas'
import { constants, useKonvaNode } from '_entities/whiteboard'
import { Vector2d } from 'konva/lib/types'

interface Props {
  block: block
  showSticky?: boolean
}

const ShapeSelectorTool = (props: Props) => {
  const _json1 = useJson1()
  const _submit = useSubmit()
  const _shape = useShape()
  const _transformer = useTransformer()
  const _konvaNode = useKonvaNode()
  const _scale = useScale()

  const getScale = (size: Size) => {
    const scale = _scale.sizeToScale(size, {
      width: constants.SHAPE_DEFAULT_WIDTH,
      height: constants.SHAPE_DEFAULT_HEIGHT,
    })
    return {
      width: undefined,
      height: undefined,
      ...scale,
    }
  }

  const getSize = (scale: Vector2d) => {
    const size = _scale.scaleToSize(
      {
        width: constants.STICKY_NOTE_DEFAULT_WIDTH,
        height: constants.STICKY_NOTE_DEFAULT_HEIGHT,
      },
      { scaleX: scale.x, scaleY: scale.y },
    )
    return {
      ...size,
      scaleX: undefined,
      scaleY: undefined,
    }
  }

  const getSizeAndScale = (fromTag: IBlockTypes, toTag: IBlockTypes) => {
    if (toTag === IBlockTypes.STICKY_NOTE) {
      const size = _konvaNode.getImageNodeSizeFromBlock(props.block)
      if (fromTag === toTag) return size
      return getScale(size)
    }
    if (toTag === IBlockTypes.SHAPE) {
      const node = _konvaNode.getGroupNode(props.block)
      const scale = node.scale()
      if (!scale) return
      if (toTag === fromTag) return { scaleX: scale.x, scaleY: scale.y }
      return getSize(scale)
    }
  }

  const handleSelectReplaceShape = (tool: ITools) => {
    const index = getIndex(props.block)
    const toTag = _shape.toolToTag[tool] || IBlockTypes.SHAPE
    const toShapeType = _shape.toolToShapeType[tool]
    const scaleAndSize = getSizeAndScale(getBlockTag(props.block), toTag)
    const updatedBlock = {
      ...props.block,
      data: {
        ...props.block.data,
        ...scaleAndSize,
        tag: toTag,
        shape: {
          ...props.block.data.shape,
          type: toShapeType,
        },
      },
    }
    const op = _json1.replaceBlock(updatedBlock, index, props.block)
    _submit.submit(getBlockPageId(props.block), op, SourceKeys.UPDATE_BLOCK)
    setTimeout(() => {
      _transformer.setNodeFromBlockToTransformer(updatedBlock.meta.pageId, updatedBlock)
    }, 10)
  }

  const SelectedShape = _shape.iconFromBlockTag(
    getBlockTag(props.block) || IBlockTypes.SHAPE,
    _shape.getShapeType(props.block) || IShapeTypes.RECTANGLE,
  )

  const BackupShapeIcon = _shape.iconRenderConfig[ITools.CIRCLE]

  return (
    <ShapeSelectorDropdown
      selectedShape={SelectedShape?.tool ?? ITools.CIRCLE}
      toggleIcon={SelectedShape ? <SelectedShape.icon /> : <BackupShapeIcon />}
      onSelect={handleSelectReplaceShape}
      showSticky={props.showSticky}
    />
  )
}

export default ShapeSelectorTool
