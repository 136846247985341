import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import {
  StyledAccordion,
  DroppableContainer,
  DroppableWrapper,
  StyledDroppable,
  ArrowDownIcon,
  ColumnTitleContainer,
  ColumnTitle,
  ColumnTitleText,
  ColumnTitleNumber,
  ColumnTitleIcon,
} from '../TaskManagerView/styles'

import { AccordionEventKey } from 'react-bootstrap/esm/AccordionContext'
import { setActiveKeysBoard, getSelectedTask } from 'redux/reducers/taskManagerReducer'
import { useTaskManagerBoard } from './useTaskManagerBoard'
import { useAppSelector } from 'redux/hooks'
import { IconButton } from 'components/atoms/Button/Button'
import { ColumnActionRow, ColumnActionRowText } from '../TaskManagerModal/styles'
import { useCreateTask } from '_features/task'
import { CommonProps } from '_features/tasks'
import { Task } from '_entities/task'

const TaskManagerBoard = (props: CommonProps) => {
  const { dispatch, activeKeys, subgroup, selectedShowProperties, onTaskSelect } =
    useTaskManagerBoard()

  const _createTask = useCreateTask()

  const selectedTask = useAppSelector(getSelectedTask)

  return (
    <StyledAccordion
      onSelect={(e: AccordionEventKey) => {
        dispatch(setActiveKeysBoard(e))
      }}
      isSubgroup={!!subgroup}
      alwaysOpen
      type={props.type}
      activeKey={activeKeys}
    >
      <DragDropContext onDragEnd={props.onDragEnd}>
        {props.data.map((user, userIndex) => (
          <StyledAccordion.Item key={user.name} eventKey={userIndex.toString()}>
            <StyledAccordion.Header>
              <ArrowDownIcon
                shouldFlip={activeKeys?.includes(userIndex.toString())}
                stroke={'var(--gray2)'}
              />
              &nbsp; {user.name}
            </StyledAccordion.Header>
            <StyledAccordion.Body>
              {Object.entries(user.columns).map(([columnId, column]) => {
                return (
                  <DroppableContainer key={columnId}>
                    <ColumnTitleContainer marginBottom='20px'>
                      <ColumnTitle>
                        <ColumnTitleText>{column.name}</ColumnTitleText>
                        <ColumnTitleNumber>{column.tasks.length}</ColumnTitleNumber>
                      </ColumnTitle>
                      <IconButton
                        size='small'
                        variant='transparent'
                        onClick={() => _createTask.createTask(column)}
                      >
                        <ColumnTitleIcon />
                      </IconButton>
                    </ColumnTitleContainer>
                    <DroppableWrapper>
                      <Droppable droppableId={columnId} key={columnId}>
                        {(provided, snapshot) => {
                          return (
                            <StyledDroppable {...provided.droppableProps} ref={provided.innerRef}>
                              {selectedShowProperties &&
                                [...column.tasks].map((task, index) => {
                                  return (
                                    <Task
                                      key={task.id}
                                      task={task}
                                      onTaskSelect={onTaskSelect}
                                      index={index}
                                      selected={task.id === selectedTask?.id}
                                      isSlider={props.isSlider}
                                      isSimpleView={false}
                                    />
                                  )
                                })}
                              {provided.placeholder}
                              <ColumnActionRow onClick={() => _createTask.createTask(column)}>
                                <ColumnTitleIcon />
                                <ColumnActionRowText>New Task</ColumnActionRowText>
                              </ColumnActionRow>
                            </StyledDroppable>
                          )
                        }}
                      </Droppable>
                    </DroppableWrapper>
                  </DroppableContainer>
                )
              })}
            </StyledAccordion.Body>
          </StyledAccordion.Item>
        ))}
      </DragDropContext>
    </StyledAccordion>
  )
}

export default TaskManagerBoard
