import styled from 'styled-components'

export const SelectWithDropdownAndButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const SelectWithDropdownWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #b7b7b7;
  border-radius: 8px;

  div:first-child {
    width: 100%;
  }
`

export const PageMembersWrapper = styled.div`
  max-height: 145px;
  overflow-y: scroll;
  padding: 20px 15px 0px 8px;
  width: 100%;
`
