import { StyledPageWrapper } from '../../ui/styles'
import { ExpandedProps } from '../../model/types'
import { Whiteboard, getFrameThumbnail, useKonvaNode } from '_entities/whiteboard'
import { getBlockPageId, getBlockGridWidth, GridBlockWidth } from '_entities/block'
import {
  getBlockIsEmbedOverlayOn,
  getInternalEmbedBackgroundColor,
  PagePreviewOptions,
  getExpandedPageBlockId,
  useInternalEmbedBlock,
  useWhiteboardEmbed,
  getInternalEmbedBorderRadius,
  getInternalEmbedBorderColor,
  getInternalEmbedBorderWidth,
  getIsSlidesNavOn,
} from '_entities/embed'
import { StyledArrow, StyledPlus, StyledTrash } from './Arrow'
import { icons } from 'shared/icons'
import { usePage } from '_entities/page'
import {
  usePresentation,
  WhiteboardAttrs,
  useCanvas,
  getControlsEnabled,
  getShouldShowControls,
  useStage,
  getCurrentFrame,
  setFrameFit,
} from '_features/canvas'
import { Wrapper } from './Wrapper'
import { useInternalCanvasResize } from '_features/block'
import { getLeftArrowId, getRightArrowId, getDeleteButtonId } from '../model/getters'
import { useRef, useState } from 'react'
import { FrameNavigation } from './FrameNavigation'
import { Group } from 'konva/lib/Group'

interface Props extends ExpandedProps {
  isSlideShow?: boolean
  isEnabled?: boolean
}

export const InternalCanvas = (props: Props) => {
  const [currentIndex, setCurrentIndex] = useState<number | undefined>()
  const [frameSnapshots, setFrameSnapshots] = useState<string[]>([])
  const [showControls, setShowControls] = useState(false)
  const [controlsEnabled, setControlsEnabled] = useState(false)
  const _page = usePage()
  const _presentation = usePresentation()
  const _internalEmbedBlock = useInternalEmbedBlock()
  const _canvas = useCanvas()
  const _stage = useStage()
  const _whiteboardEmbed = useWhiteboardEmbed()
  const _konvaNode = useKonvaNode()

  const pageId = _internalEmbedBlock.getBlockPageId(props.block)
  if (!pageId) return null
  const shouldShowObserver = useRef<MutationObserver>()
  const shouldEnableObserver = useRef<MutationObserver>()

  const areControlsInside =
    getBlockGridWidth(props.block) === GridBlockWidth.FULL_BLEED ||
    getBlockGridWidth(props.block) === GridBlockWidth.OUT_OF_GRID
  useInternalCanvasResize(props)

  const handleAddSlide = () => {
    _presentation.addFrame(pageId)
  }

  const handleDeleteSlide = () => {
    _presentation.deleteFrame(pageId)
  }

  const getFrameSnapshots = async () => {
    const stage = _stage.getStage(pageId)
    const frameNodes = _presentation.sortFramesByY(pageId)
    if (!frameNodes || !stage) return
    const snapshots = frameNodes.map((node) => {
      const background = _konvaNode.getImageNodeFromGroup(node as Group)
      const thumbnail = getFrameThumbnail(background)
      if (thumbnail) return thumbnail
      if (!background.attrs.image || background.attrs.image === '') return background.toDataURL()
      else return (background.attrs.image as HTMLImageElement).src
    })
    setFrameSnapshots(snapshots)
  }

  return (
    <Wrapper>
      {currentIndex !== undefined && frameSnapshots.length > 0 && getIsSlidesNavOn(props.block) && (
        <FrameNavigation
          currentIndex={currentIndex}
          pageId={pageId}
          frameSnapshots={frameSnapshots}
        />
      )}

      <StyledPageWrapper
        width={props.width}
        height={props.height}
        id={getExpandedPageBlockId(props.block)}
        onClick={props.onClick}
        isWhiteboardEmbed
        borderColor={showControls ? undefined : getInternalEmbedBorderColor(props.block)}
        borderWeight={showControls ? undefined : getInternalEmbedBorderWidth(props.block)}
        borderRadius={getInternalEmbedBorderRadius(props.block)}
      >
        {props.blockPage && (
          <Whiteboard
            pageId={props.blockPage.id}
            isEmbed={true}
            isPreview={props.isPreview}
            isSlideShow={props.isSlideShow}
            parentPageId={getBlockPageId(props.block)}
            parentBlock={props.block}
            background={getInternalEmbedBackgroundColor(props.block)}
            onRefLoad={(node: HTMLDivElement | null) => {
              if (!node) {
                shouldShowObserver.current?.disconnect()
                shouldEnableObserver.current?.disconnect()
                return
              }

              setTimeout(() => {
                if (!props.blockPage) return
                if (!_presentation.getIsPresentation(props.blockPage.id)) {
                  setFrameFit(props.blockPage.id, 'cover')
                  _presentation.beginPresentation({ pageId: props.blockPage.id })
                }
              })

              getFrameSnapshots()
              setTimeout(() => {
                setCurrentIndex(_presentation.getCurrentFrameIndex(pageId))
              })
              shouldShowObserver.current = _canvas.observeMutationOnCanvasElement(
                pageId,
                WhiteboardAttrs.SHOULD_SHOW_PRESENTATION_CONTROLS,
                () => {
                  if (getShouldShowControls(pageId)) {
                    setShowControls(true)
                  } else {
                    setShowControls(false)
                  }
                },
              )
              shouldEnableObserver.current = _canvas.observeMutationOnCanvasElement(
                pageId,
                WhiteboardAttrs.PRESENTATION_CONTROLS_ENABLED,
                () => {
                  const areControlsEnabled = getControlsEnabled(pageId)
                  if (areControlsEnabled) {
                    setControlsEnabled(true)
                  } else {
                    setControlsEnabled(false)
                  }
                },
              )
              _canvas.observeMutationOnCanvasElement(pageId, WhiteboardAttrs.CURRENT_FRAME, () => {
                const currentFrame = getCurrentFrame(pageId)
                if (currentFrame) {
                  getFrameSnapshots()
                  setTimeout(() => {
                    setCurrentIndex(_presentation.getCurrentFrameIndex(pageId))
                  })
                }
              })
            }}
          />
        )}
        {/* {props.isEmbed && <NoViewableContent />} */}
        {/* {props.blockPage && !_permissions.canViewPage(props.blockPage.id) && props.blockPage && (
        <NoAccess pageId={props.blockPage.id} />
      )} */}
        {/* {!props.isEnabled && <DblClickToEnable />} */}

        {getBlockIsEmbedOverlayOn(props.block) &&
          !_page.isDocument(getBlockPageId(props.block)) && (
            <PagePreviewOptions
              Icon={props.Icon}
              isEmbeddedDocumentExpanded={props.isExpanded}
              handleEmbeddedMaximize={props.maximize}
              handleEmbeddedMinimize={props.minimize}
              title={props.title}
              isWhiteboardEmbed
              onTitleClick={props.onTitleClick}
            />
          )}
      </StyledPageWrapper>
      {showControls && _whiteboardEmbed.isDocEmbed(pageId) && (
        <>
          <StyledArrow
            disabled={!controlsEnabled}
            id={getLeftArrowId(props.block)}
            isInside={areControlsInside}
            onClick={() => {
              _presentation.previousSlide(pageId)
            }}
            position='left'
          >
            <icons.chevronLeft width={16} height={16} />
          </StyledArrow>
          <StyledPlus isInside={areControlsInside} onClick={handleAddSlide}>
            <icons.plus width={16} height={16} />
          </StyledPlus>
          <StyledArrow
            disabled={!controlsEnabled}
            id={getRightArrowId(props.block)}
            isInside={areControlsInside}
            onClick={() => {
              _presentation.nextSlide(pageId)
            }}
            position='right'
          >
            <icons.chevronRight width={16} height={16} />
          </StyledArrow>
          <StyledTrash
            disabled={!controlsEnabled}
            id={getDeleteButtonId(props.block)}
            isInside={areControlsInside}
            onClick={handleDeleteSlide}
          >
            <icons.trash width={24} height={18} />
          </StyledTrash>
        </>
      )}
    </Wrapper>
  )
}
