import {
  IBlockTypes,
  type block,
  getBlockExpandedState,
  getBlockWidth,
  getBlockHeight,
  getCalloutWidth,
  getCalloutHeight,
} from '_entities/block'
import { AxiosService } from 'services/axiosService/axiosService'
import {
  sizes,
  MIME_TYPE_FOR_DOC,
  MIME_TYPE_FOR_SHEET,
  MIME_TYPE_FOR_SLIDE,
  getSizeFromGridLength,
  gridSizes,
} from '_entities/whiteboard'
import {
  checkIsAsana,
  checkIsEmail,
  checkIsFigma,
  checkIsGoogleCalendarLink,
  checkIsGoogleDocLink,
  checkIsGoogleSheetsLink,
  checkIsGoogleSlidesLink,
  checkIsImageUrl,
  checkIsMiro,
  checkIsNotion,
  checkIsOfficeDoc,
  checkIsOfficeExcel,
  checkIsOfficeLink,
  checkIsOfficePowerPoint,
  checkIsPdfLink,
  checkIsUrl,
  checkIsVimeo,
  checkIsYoutube,
} from '_entities/embed/ExternalEmbed/model/EmbedRegex'
import { EmbedType } from './types'
import { FIGMA_EMBED_IDENTIFIER, FIGMA_EMBED_REDIRECTER } from '_entities/embed'

export const embedTypeDisplayName = {
  [EmbedType.FILE]: 'file',
  [EmbedType.GENERIC_LINK]: 'link',
  [EmbedType.IMAGE]: 'image',
  [EmbedType.PDF]: 'PDF',
  [EmbedType.VIDEO]: 'video',
  [EmbedType.VIMEO]: 'Vimeo',
  [EmbedType.YOUTUBE]: 'YouTube',
  [EmbedType.NOTION]: 'Notion',
  [EmbedType.ASANA]: 'Asana',
  [EmbedType.GOOGLE_DOC]: 'Google Doc',
  [EmbedType.GOOGLE_SHEETS]: 'Google Sheets',
  [EmbedType.GOOGLE_SLIDES]: 'Google Slides',
  [EmbedType.GOOGLE_CALENDAR]: 'Google Calendar',
  [EmbedType.MIRO]: 'Miro',
  [EmbedType.FIGMA]: 'Figma',
  [EmbedType.OFFICE]: 'Office',
  [EmbedType.OFFICE_DOCUMENT]: 'MS Office Document',
  [EmbedType.OFFICE_EXCEL]: 'MS Office Excel',
  [EmbedType.OFFICE_POWERPOINT]: 'MS Office Powerpoint',
}

export const AllBlockEmbedTypes = [
  IBlockTypes.DOCUMENT,
  IBlockTypes.WHITEBOARD,
  IBlockTypes.EMBED,
  IBlockTypes.SLIDESHOW,
  IBlockTypes.EXTERNAL_EMBED,
]

export const useEmbed = () => {
  const axiosService = new AxiosService()

  const getWhiteboardEmbedDefaultSize = () => {
    return {
      width: getSizeFromGridLength(9),
      height: getSizeFromGridLength(6),
    }
  }

  const getDocumentEmbedDefaultSize = () => {
    return {
      width: getSizeFromGridLength(6),
      height: getSizeFromGridLength(8),
    }
  }

  const getEmbedCalloutDefaultSize = () => {
    return {
      width: getSizeFromGridLength(5),
      height: getSizeFromGridLength(1),
    }
  }

  const getGoogleDocEmbedDefaultSize = () => {
    return {
      width: getSizeFromGridLength(9),
      height: getSizeFromGridLength(12),
    }
  }

  const fullEmbedDefaultSizes = {
    [EmbedType.FIGMA]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.MIRO]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.GOOGLE_DOC]: {
      width: getGoogleDocEmbedDefaultSize().width,
      height: getGoogleDocEmbedDefaultSize().height,
    },
    [EmbedType.GOOGLE_SHEETS]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.GOOGLE_SLIDES]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.GOOGLE_CALENDAR]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.OFFICE_DOCUMENT]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.OFFICE_EXCEL]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.OFFICE_POWERPOINT]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.YOUTUBE]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.VIMEO]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.ASANA]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.FILE]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.GENERIC_LINK]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.IMAGE]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
    [EmbedType.NOTION]: {
      width: getDocumentEmbedDefaultSize().width,
      height: getDocumentEmbedDefaultSize().height,
    },
    [EmbedType.OFFICE]: {
      width: getDocumentEmbedDefaultSize().width,
      height: getDocumentEmbedDefaultSize().height,
    },
    [EmbedType.PDF]: {
      width: getDocumentEmbedDefaultSize().width,
      height: getDocumentEmbedDefaultSize().height,
    },
    [EmbedType.VIDEO]: {
      width: getWhiteboardEmbedDefaultSize().width,
      height: getWhiteboardEmbedDefaultSize().height,
    },
  }

  const getEmbedDefaultSizes = (block: block) => {
    switch (block.data.tag) {
      case IBlockTypes.DOCUMENT:
        return {
          width: getDocumentEmbedDefaultSize().width,
          height: getDocumentEmbedDefaultSize().height,
        }
      case IBlockTypes.WHITEBOARD:
        return {
          width: getWhiteboardEmbedDefaultSize().width,
          height: getWhiteboardEmbedDefaultSize().height,
        }
      case IBlockTypes.EXTERNAL_EMBED: {
        const type = block.data.embed?.type
        if (!type)
          return {
            width: getWhiteboardEmbedDefaultSize().width,
            height: getWhiteboardEmbedDefaultSize().height,
          }
        return fullEmbedDefaultSizes[type]
      }
      case IBlockTypes.STICKY_NOTE:
        return {
          width: getSizeFromGridLength(1),
          height: getSizeFromGridLength(1),
        }
      case IBlockTypes.SHAPE:
        return {
          width: getSizeFromGridLength(1),
          height: getSizeFromGridLength(1),
        }
      case IBlockTypes.TITLE:
      case IBlockTypes.XLARGE:
      case IBlockTypes.LARGE:
      case IBlockTypes.MEDIUM:
      case IBlockTypes.SMALL:
      case IBlockTypes.SUBHEADER:
      case IBlockTypes.TEXT:
      case IBlockTypes.LIST:
      case IBlockTypes.CHECKLIST:
      case IBlockTypes.NUMBERED_LIST:
        return {
          width: getSizeFromGridLength(1),
          height: getSizeFromGridLength(1),
        }
      case IBlockTypes.IMAGE:
        return {
          width: getSizeFromGridLength(6),
          height: getSizeFromGridLength(3),
        }
      case IBlockTypes.FRAME: {
        const name = block.data.frame?.name
        return gridSizes[name || sizes.FRAME_ONE_ONE]
      }
      default:
        return {
          width: getWhiteboardEmbedDefaultSize().width,
          height: getWhiteboardEmbedDefaultSize().height,
        }
    }
  }

  const isCallout = (block: block) => {
    return AllBlockEmbedTypes.includes(block.data.tag) && !getBlockExpandedState(block)
  }

  const getSize = (block: block) => {
    const width =
      (isCallout(block)
        ? getCalloutWidth(block) || getEmbedCalloutDefaultSize().width
        : getBlockWidth(block)) || getEmbedDefaultSizes(block)?.width
    const height =
      (isCallout(block)
        ? getCalloutHeight(block) || getEmbedCalloutDefaultSize().height
        : getBlockHeight(block)) || getEmbedDefaultSizes(block)?.height

    return { width, height }
  }

  const getExternalEmbedTitle = (block: block) => {
    const title = block.data.embed?.name
    if (title) {
      return title
    } else return block.data.embed?.url
  }

  const isEmbedBlock = (block: block) => {
    return block.data.tag === IBlockTypes.EXTERNAL_EMBED
  }

  const checkIsBlockInternalEmbed = (block: block) => {
    return block.data.tag === IBlockTypes.DOCUMENT || block.data.tag === IBlockTypes.WHITEBOARD
  }

  const checkIfGoogleDoc = (block: block) => {
    return block.data.embed?.type === EmbedType.GOOGLE_DOC
  }

  const getEmbedTypeFromLink = (link: string, isWhiteboard?: boolean): EmbedType | undefined => {
    if (checkIsGoogleDocLink(link)) return EmbedType.GOOGLE_DOC
    if (checkIsGoogleSlidesLink(link)) return EmbedType.GOOGLE_SLIDES
    if (checkIsGoogleSheetsLink(link)) return EmbedType.GOOGLE_SHEETS
    if (checkIsGoogleCalendarLink(link)) return EmbedType.GOOGLE_CALENDAR
    if (checkIsNotion(link)) return EmbedType.NOTION
    if (checkIsAsana(link)) return EmbedType.ASANA
    if (checkIsMiro(link)) return EmbedType.MIRO
    if (checkIsFigma(link)) return EmbedType.FIGMA
    if (checkIsPdfLink(link)) return EmbedType.PDF
    if (checkIsOfficeDoc(link)) return EmbedType.OFFICE_DOCUMENT
    if (checkIsOfficePowerPoint(link)) return EmbedType.OFFICE_POWERPOINT
    if (checkIsOfficeExcel(link)) return EmbedType.OFFICE_EXCEL
    if (checkIsVimeo(link)) return EmbedType.VIMEO
    if (checkIsImageUrl(link)) return EmbedType.IMAGE
    if (checkIsYoutube(link)) return EmbedType.YOUTUBE
    if (checkIsUrl(link) && isWhiteboard) return EmbedType.GENERIC_LINK
  }

  const getUrl = (block: block) => {
    const embed = block.data.embed
    if (embed) {
      if (embed?.type === EmbedType.FIGMA) {
        return `${FIGMA_EMBED_REDIRECTER}${FIGMA_EMBED_IDENTIFIER}${embed.url}}`
      } else if (embed?.type === EmbedType.YOUTUBE) {
        const stringWithId = embed.url.split('v=')[1]
        if (stringWithId != undefined) {
          const onlyId = stringWithId.slice(0, 11)
          return `https://www.youtube.com/embed/${onlyId}`
        }
      } else if (embed?.type === EmbedType.VIMEO) {
        const stringWithId = embed.url.split('vimeo.com/')[1]
        if (stringWithId != undefined) {
          const onlyId = stringWithId.slice(0, 9)
          return `https://player.vimeo.com/video/${onlyId}`
        }
      } else if (checkIsOfficeLink(embed.url)) {
        return `https://view.officeapps.live.com/op/embed.aspx?src=${embed.url}`
      } else {
        return embed.url
      }
    }

    return ''
  }

  const fetchMetadata = async (url: string) => {
    return await axiosService.getCalloutMetadata(url)
  }

  const getNotionLinkName = (url: string) => {
    const stringsBetweenSlashes = url.split('/')
    const lastString = stringsBetweenSlashes[stringsBetweenSlashes.length - 1]
    const title = lastString.split('-')
    // We need to remove id from the end of title
    title.pop()
    // Separate words of title are in array, need to combine them
    return !title.length ? 'Notion' : title.join(' ')
  }

  const getHtmlFromEmbed = (link: string) => {
    const iframe = document.createElement('iframe')
    iframe.src = link
    document.body.appendChild(iframe)
    return iframe.contentWindow?.document.body.innerHTML
  }

  const checkIfSelectedBlockIsEmbed = (selectedBlock: block | null) => {
    return (
      selectedBlock &&
      selectedBlock.data.tag === IBlockTypes.WHITEBOARD &&
      selectedBlock.data.isEmbedExpanded !== undefined &&
      selectedBlock.data.documentPage?.id !== undefined
    )
  }

  const getExportMimeType = (fileType: string) => {
    switch (fileType) {
      case EmbedType.GOOGLE_SHEETS:
        return MIME_TYPE_FOR_SHEET
      case EmbedType.GOOGLE_DOC:
        return MIME_TYPE_FOR_DOC
      case EmbedType.GOOGLE_SLIDES:
        return MIME_TYPE_FOR_SLIDE
      default:
        throw new Error('Unsupported file type')
    }
  }

  const extractFileId = (url: string) => {
    const urlParts = url.split('/')
    return urlParts[urlParts.length - 2]
  }

  const constructExportUrl = (fileId: string, fileType: string) => {
    return `https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=${getExportMimeType(
      fileType,
    )}`
  }

  const isOnlyLink = (block: block) => {
    return block.data.embed?.type === EmbedType.GENERIC_LINK
  }

  const isVideo = (block: block) => {
    return block.data.embed?.type === EmbedType.VIDEO
  }

  const getGoogleEmbedType = (url: string) => {
    if (checkIsGoogleDocLink(url)) {
      return EmbedType.GOOGLE_DOC
    }
    if (checkIsGoogleSlidesLink(url)) {
      return EmbedType.GOOGLE_SLIDES
    }
    if (checkIsGoogleSheetsLink(url)) {
      return EmbedType.GOOGLE_SHEETS
    }
    if (checkIsEmail(url)) {
      return EmbedType.GOOGLE_CALENDAR
    }

    return EmbedType.GOOGLE_DOC
  }

  const getInternalEmbedTitle = (block: block) => block.data.documentPage?.title

  const getExternalEmbedUrl = (block: block) => block.data.embed?.url

  const isExternalEmbed = (block: block) => block.data.tag === IBlockTypes.EXTERNAL_EMBED

  const isInternalPageLink = (url: string) => {
    return url.includes('/page/')
  }

  const getHtml = (block: block) => {
    return block.data.embed?.html
  }

  const getEmbedUrl = (block: block) => {
    return block.data.embed?.url
  }

  const isDownload = (url: string) => {
    if (!getEmbedTypeFromLink(url) && !isInternalPageLink(url)) return true
  }

  return {
    getWhiteboardEmbedDefaultSize,
    getDocumentEmbedDefaultSize,
    getEmbedCalloutDefaultSize,
    getGoogleDocEmbedDefaultSize,
    fullEmbedDefaultSizes,
    getEmbedDefaultSizes,
    isEmbedBlock,
    checkIfGoogleDoc,
    getUrl,
    isCallout,
    getSize,
    getExternalEmbedTitle,
    getEmbedTypeFromLink,
    getNotionLinkName,
    fetchMetadata,
    getHtmlFromEmbed,
    checkIfSelectedBlockIsEmbed,
    getExportMimeType,
    extractFileId,
    constructExportUrl,
    isOnlyLink,
    isVideo,
    checkIsBlockInternalEmbed,
    getGoogleEmbedType,
    getInternalEmbedTitle,
    getExternalEmbedUrl,
    isExternalEmbed,
    isInternalPageLink,
    getHtml,
    getEmbedUrl,
    isDownload,
  }
}
