import { type block, IBlockTypes } from '_entities/block'
import React from 'react'
import { EditorToolbarFramer } from 'utils/framerConfig'
import { StyledBlockToolbar, ToolbarDivider } from '../BlockToolbar/styles'
import { BasicInput } from 'components/atoms/BasicInput/BasicInput'
import SharedToolbar from 'components/organisms/SharedToolbar/SharedToolbar'
import SharedTextToolbar from 'components/organisms/SharedTextToolbar/SharedTextToolbar'
import RadixDropdown, {
  DropdownMenuItem,
  IconButton,
} from 'components/molecules/Dropdown/RadixDropdown/RadixDropdown'
import useImage from '_entities/image/model/useImage'
import {
  ALIGN_CENTRE_ICON_SELECTOR,
  ALIGN_LEFT_ICON_SELECTOR,
  ALIGN_RIGHT_ICON_SELECTOR,
  CROP_ICON_SELECTOR,
  DIAGONAL_ICON_SELECTOR,
  DOWNLOAD_ICON_SELECTOR,
  FLOAT_LEFT_ICON_SELECTOR,
  FLOAT_RIGHT_ICON_SELECTOR,
  INPUT_SCREEN_REMOVER_PIXELS,
  REPLACE_IMAGE_ICON_SELECTOR,
  widthResizeImageOptions,
  AlignCentreIcon,
  AlignIcon,
  AlignLeftIcon,
  AlignRightIcon,
  ChangeWidthIcon,
  DiagonalIcon,
  DownloadIcon,
  FloatLeftIcon,
  FloatRightIcon,
  ReplaceImageIcon,
  MINIMUM_RECTANGLE_SIZE,
} from '_features/editor'
import { IconButtonSize } from '_features/toolbar'
import {
  checkIfImageIsBigEnoughForCropping,
  CropIcon,
  MINIMUM_SIZE_TO_CROP,
  setIsCropMode,
} from '_features/crop-image'
interface Props {
  block: block
  isEditor?: boolean
  isWhiteboard?: boolean
  innerRef: React.RefObject<HTMLDivElement>
  closeActionMenu?: () => void
}

const ImageBlockToolbar = (props: Props) => {
  const _image = useImage()

  const onCropIconClick = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    const selector = `[id="${props.block._id}"] img`
    const image = document.querySelector(selector) as HTMLImageElement

    if (!image) return

    const imageHeight = image?.height || 0
    const canProceedWithCropping = checkIfImageIsBigEnoughForCropping(
      props.block.data.imageBorderWrapperWidth || MINIMUM_RECTANGLE_SIZE,
      imageHeight,
      MINIMUM_SIZE_TO_CROP,
    )

    if (!canProceedWithCropping) return
    setIsCropMode(props.block)
  }

  return (
    <StyledBlockToolbar
      {...EditorToolbarFramer}
      ref={props.innerRef}
      onMouseDown={(e: React.MouseEvent) => {
        e.preventDefault()
      }}
      isEditor={props.isEditor}
    >
      <IconButton>
        <DiagonalIcon id={DIAGONAL_ICON_SELECTOR} onClick={() => _image.onDiagonalIconClick()} />
      </IconButton>

      <IconButton>
        <CropIcon id={CROP_ICON_SELECTOR} onClick={(e) => onCropIconClick(e)} />
      </IconButton>

      <RadixDropdown
        trigger={
          <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
            <AlignIcon />
          </IconButton>
        }
        flexDirection={'row'}
        menuRef={props.innerRef}
      >
        <DropdownMenuItem onSelect={(e) => void 0}>
          <AlignLeftIcon id={ALIGN_LEFT_ICON_SELECTOR} />
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={(e) => void 0}>
          <AlignCentreIcon id={ALIGN_CENTRE_ICON_SELECTOR} />
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={(e) => void 0}>
          <AlignRightIcon id={ALIGN_RIGHT_ICON_SELECTOR} />
        </DropdownMenuItem>

        <DropdownMenuItem onSelect={(e) => void 0}>
          <FloatLeftIcon id={FLOAT_LEFT_ICON_SELECTOR} />
        </DropdownMenuItem>
        <DropdownMenuItem onSelect={(e) => void 0}>
          <FloatRightIcon id={FLOAT_RIGHT_ICON_SELECTOR} />
        </DropdownMenuItem>
      </RadixDropdown>

      {props.block.data.tag === IBlockTypes.IMAGE_TEXT && (
        <RadixDropdown
          trigger={
            <IconButton width={IconButtonSize + 'px'} height={IconButtonSize + 'px'}>
              <ChangeWidthIcon />
            </IconButton>
          }
        >
          {widthResizeImageOptions.map((option) => {
            return (
              <DropdownMenuItem
                onSelect={() => {
                  return void 0
                }}
                key={option.label}
              >
                {option.label}
              </DropdownMenuItem>
            )
          })}
        </RadixDropdown>
      )}

      <IconButton>
        <DownloadIcon
          id={DOWNLOAD_ICON_SELECTOR}
          onClick={(e) => _image.handleDownloadStart(e, props.block)}
        />
      </IconButton>

      <IconButton>
        <ReplaceImageIcon
          id={REPLACE_IMAGE_ICON_SELECTOR}
          onClick={() => _image.handleReplaceIconStart()}
        />
      </IconButton>

      <BasicInput
        type='file'
        ref={_image.replaceAndUploadImageInputRef}
        accept='image/*'
        onChange={_image.handleImageFileReplacement}
        position='fixed'
        left={INPUT_SCREEN_REMOVER_PIXELS}
      />

      <ToolbarDivider />

      {props.block.data.tag === IBlockTypes.IMAGE_TEXT && (
        <SharedTextToolbar block={props.block} isWhiteboard={props.isWhiteboard} />
      )}
      <SharedToolbar
        block={props.block}
        closeActionMenu={props.closeActionMenu}
        isWhiteboard={props.isWhiteboard}
      />
    </StyledBlockToolbar>
  )
}

export default ImageBlockToolbar
