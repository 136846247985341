import React from 'react'
import Avatar from 'components/atoms/Avatar/Avatar'
import { MessageResponseWithBlock } from 'interfaces/chat'
import { type block } from '_entities/block'
import {
  MessageContentWrapper,
  ProjectSidebarMessageWrapper,
  ReplyCountText,
  ShowMoreContainer,
  StyledMessageTextContainer,
} from './styles'
import useProjectSidebarMessage from '../../model/useProjectSidebarMessage'
import Attachment from './Attachment'
import { useMessageThread } from '_features/chat'

interface IProps {
  message: MessageResponseWithBlock
  block: block
}

const ProjectSidebarMessage = (props: IProps) => {
  const { message } = props
  const _projectSidebarMessage = useProjectSidebarMessage({
    message,
  })
  const _messageThread = useMessageThread()

  return (
    <ProjectSidebarMessageWrapper
      onClick={() => _messageThread.handleOpenMessageThread(props.block)}
    >
      {_projectSidebarMessage.user ? (
        <Avatar
          userName={_projectSidebarMessage.user.name}
          src={_projectSidebarMessage.user.image as string}
        />
      ) : (
        <Avatar userName='' src='' />
      )}
      <MessageContentWrapper>
        <StyledMessageTextContainer>
          {_projectSidebarMessage.isShowMore && message.text && message.text?.length > 100
            ? `${message.text?.slice(0, 100)}...`
            : message.text}

          {message.text && message.text?.length > 100 && (
            <ShowMoreContainer
              onClick={(e) => {
                _projectSidebarMessage.handleShowMore(e)
              }}
            >
              {_projectSidebarMessage.isShowMore ? 'Show more' : 'Show less'}
            </ShowMoreContainer>
          )}
        </StyledMessageTextContainer>
        {message.attachments?.map((attachment, index) => (
          <Attachment attachment={attachment} key={index} />
        ))}
        <ReplyCountText>
          {message.reply_count === 0
            ? 'Add a reply'
            : message.reply_count === 1
            ? `${message.reply_count} reply`
            : `${message.reply_count} replies`}
        </ReplyCountText>
      </MessageContentWrapper>
    </ProjectSidebarMessageWrapper>
  )
}

export default ProjectSidebarMessage
