import { StyledEmbedMenu, StyledColumn } from './styles'
import { SingleValue } from 'react-select'
import { Option } from 'interfaces/selectOptions'
import CustomP from 'components/atoms/CustomP/CustomP'
import { VerticalLine } from 'shared/ui/VerticalLine'
import { EmbedType } from '_entities/embed'
import FullEmbedPicker from './FullEmbedPicker'
import EverydayEmbeds from './EverydayEmbeds'
import Upload from './Upload'
import GenericLink from './GenericLink'

interface Props {
  onSelectDoc: () => void
  onSelectWb: () => void
  setEmbedType: React.Dispatch<React.SetStateAction<EmbedType | ''>>
  setPageLinkSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>
  setUploadSelectorOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const EmbedMenu = (props: Props) => {
  return (
    <StyledEmbedMenu>
      <StyledColumn>
        <CustomP fontSize='12px' color='var(--gray-inactive)'>
          External embeds
        </CustomP>
        <FullEmbedPicker setEmbedType={props.setEmbedType} />
      </StyledColumn>
      <VerticalLine margin={'12px 0'} />
      <StyledColumn>
        <CustomP fontSize='12px' color='var(--gray-inactive)'>
          Everyday embeds
        </CustomP>
        <EverydayEmbeds {...props} />
      </StyledColumn>
      <VerticalLine margin={'12px 0'} />
      <StyledColumn>
        <CustomP fontSize='12px' color='var(--gray-inactive)'>
          Files
        </CustomP>
        <Upload {...props} />
      </StyledColumn>
      <VerticalLine margin={'12px 0'} />
      <StyledColumn>
        <CustomP fontSize='12px' color='var(--gray-inactive)'>
          Links
        </CustomP>
        <GenericLink setEmbedType={props.setEmbedType} />
      </StyledColumn>
    </StyledEmbedMenu>
  )
}

export default EmbedMenu
