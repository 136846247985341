import { css } from 'styled-components'

export const Style = css`
  display: flex;
  gap: 18px;
  font-size: 14px;
  padding-bottom: 12px;
  color: var(--white);
  margin-top: -1.25rem !important;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  & a {
    color: var(--white);
    text-decoration: inherit;
    font-weight: 500;
    &:hover {
      text-decoration: underline;
    }
  }
`
