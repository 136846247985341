import {
  IGroup,
  IGroups,
  IPropertyTypes,
  ISubgroup,
  ITask,
  ITaskProperty,
  PropertyCategories,
  PropertyCategory,
} from 'interfaces/taskManager'
import { useEffect, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { v4 as uuidv4 } from 'uuid'
import { useAppDispatch, useAppSelector } from 'redux/hooks'
import {
  getSelectedOption,
  setActiveKeysBoard,
  setActiveKeysList,
  setGroups,
  setPropertyForNoPropertyTask,
  setSelectedOptionId,
  setSubgroups,
} from 'redux/reducers/taskManagerReducer'

import { createCategoriesByPropertyName } from 'utils/taskManager/createCategoriesByPropertyName'
import { addStatusCategory } from '_widgets/TaskManager/lib/helpers'
import { useAssignToProperty, useCreateProperty } from '_features/property'

export const useTaskManager = () => {
  // ** State
  const [data, setData] = useState<ISubgroup[]>([])

  // ** Redux state
  const selectedOption = useAppSelector(getSelectedOption)
  const selectedSubgroup = selectedOption?.subGroupBy
  const properties = useAppSelector((state) => state.taskManager.propertyDefinitions)
  const options = useAppSelector((state) => state.taskManager.options)
  const tasks = useAppSelector((state) => state.taskManager.tasks)
  const selectedFilterValues = useAppSelector((state) => state.taskManager.selectedFilterValues)
  const propertyDefinitions = useAppSelector((state) => state.taskManager.propertyDefinitions)

  // ** Hooks
  const dispatch = useAppDispatch()
  const _assignProperty = useAssignToProperty()
  const _createProperty = useCreateProperty()

  const getTasksWithoutProperties = (): ITask[] | undefined => {
    return tasks?.filter((task) => {
      if (task.properties && task.properties.length === 0) return true
      else if (task.properties && task.properties.length !== 0) {
        const hasNoValues = task.properties.find(
          (prop) =>
            prop.status?.length === 0 &&
            prop.multiSelect?.length === 0 &&
            prop.person?.length === 0 &&
            prop.text?.length === 0 &&
            prop.date?.length === 0 &&
            prop.number?.length === 0,
        )
        if (hasNoValues) return true
        else return false
      }
    })
  }

  const isValueInTask = (value: string, task: ITask) => {
    return (
      task?.properties &&
      task.properties.find((prop: ITaskProperty) => {
        if (prop.propertyDefinition.type === IPropertyTypes.STATUS) {
          const currentValue = prop.status?.find((status) => status.current)
          const statusValues = prop.propertyDefinition.statusValues
          const statusValue = statusValues?.find(
            (statusValue) => statusValue.id === currentValue?.statusValueId,
          )
          if (statusValue) return statusValue.value === value
          else return ''
        } else if (prop.propertyDefinition.type === IPropertyTypes.PERSON) {
          const currentValue = prop.person?.find((status) => status.current)
          if (currentValue)
            return `${currentValue.user?.firstName} ${currentValue.user?.lastName}` === value
        } else if (prop.propertyDefinition.type === IPropertyTypes.DATE) {
          const currentValue = prop.date?.find((date) => date.current)
          if (currentValue)
            return new Date(Date.parse(currentValue.value)).toLocaleDateString('en-US') === value
        } else if (prop.propertyDefinition.type === IPropertyTypes.TEXT) {
          const currentValue = prop.text?.find((text) => text.current)
          if (currentValue) return currentValue.value === value
        } else if (prop.propertyDefinition.type === IPropertyTypes.NUMBER) {
          const currentNumber = prop.number?.find((num) => num.current)
          if (currentNumber) return currentNumber.value.toString() === value
        } else if (prop.propertyDefinition.type === IPropertyTypes.LABEL) {
          const currentValue = prop.multiSelect?.find((val) => val.current)
          if (currentValue) return currentValue.selectedValue.value.toString() === value
        } else if (prop.propertyDefinition.type === IPropertyTypes.FILES_AND_MEDIA) {
          const currentValue = prop.text?.find((text) => text.current)
          if (currentValue) return currentValue.value === value
        } else if (prop.propertyDefinition.type === IPropertyTypes.URL) {
          const currentValue = prop.text?.find((text) => text.current)
          if (currentValue) return currentValue.value === value
        }
      })
    )
  }

  const filterOutTasksByValue = (tasks: ITask[]) => {
    const arr = Object.entries(selectedFilterValues)
    const selectedFilterValuesArr =
      arr.length !== 0
        ? arr.map((val) =>
            val && val[1] && val[1].length && val[1][0] && val[1][0].value ? val[1][0].value : '',
          )
        : []
    return selectedFilterValuesArr.length
      ? tasks.filter((task) => {
          const filteredValues = selectedFilterValuesArr.filter((value) => {
            return isValueInTask(value, task)
          })
          if (filteredValues.length) return true
          else return false
        })
      : tasks
  }

  const constructGroups = (categories: PropertyCategories): IGroups => {
    let categoriesForUI: IGroups = categories.reduce(
      (accumulator: IGroups, value: PropertyCategory) => {
        const filteredTasks = filterOutTasksByValue(tasks ? tasks : [])?.filter((task) => {
          if (isValueInTask(value[0], task)) {
            return task
          }
        })
        return {
          ...accumulator,
          [uuidv4()]: {
            name: value[0],
            property: value[1],
            tasks: filteredTasks.sort((a, b) => a.positionIndex - b.positionIndex),
          },
        }
      },
      {},
    )
    const tasksWithoutProperties = getTasksWithoutProperties()
    if (tasksWithoutProperties && tasksWithoutProperties.length !== 0) {
      categoriesForUI = {
        ...categoriesForUI,
        [uuidv4()]: { name: 'No property', property: null, tasks: getTasksWithoutProperties() },
      }
    }
    if (selectedOption?.groupBy?.type === IPropertyTypes.STATUS) {
      const hasToDo = categories.some((c) => c[0] === 'To do')
      const hasInProgress = categories.some((c) => c[0] === 'In progress')
      const hasComplete = categories.some((c) => c[0] === 'Complete')

      if (!hasToDo && properties) {
        categoriesForUI = addStatusCategory(categoriesForUI, properties, 'To do', 'to-do')
      }

      if (!hasInProgress && properties) {
        categoriesForUI = addStatusCategory(
          categoriesForUI,
          properties,
          'In progress',
          'in-progress',
        )
      }

      if (!hasComplete && properties) {
        categoriesForUI = addStatusCategory(categoriesForUI, properties, 'Complete', 'complete')
      }

      const categoriesForUIArray = Object.entries(categoriesForUI)
      const toDo = categoriesForUIArray.find((group) => group[1].name === 'To do')
      const inProgress = categoriesForUIArray.find((group) => group[1].name === 'In progress')
      const complete = categoriesForUIArray.find((group) => group[1].name === 'Complete')

      if (toDo && inProgress && complete)
        categoriesForUI = {
          [toDo[0]]: toDo[1],
          [inProgress[0]]: inProgress[1],
          [complete[0]]: complete[1],
        }
    }

    dispatch(setGroups(categoriesForUI))
    return categoriesForUI
  }

  /* Very important! Subgroups are the whole data!! */
  const constructSubgroups = (categories: PropertyCategories, constructedGroups: IGroups) => {
    if (categories.length) {
      const constructedGroupsWithoutNoProperty = Object.entries(constructedGroups).filter(
        ([columnId, column]) => column.name !== 'No property',
      )
      let subgroupsForUI: ISubgroup[] = categories.map((category: PropertyCategory) => {
        const filteredGroups: IGroup[] = constructedGroupsWithoutNoProperty.map(
          ([columnId, column]) => {
            const filteredTasks = column.tasks.filter((task: ITask) => {
              if (isValueInTask(category[0], task)) {
                return task
              }
            })
            return {
              ...column,
              property: column.property,
              tasks: filteredTasks.sort((a, b) => a.positionIndex - b.positionIndex),
            }
          },
        )
        const reconstructedGroups: IGroups = filteredGroups.reduce(
          (accumulator: IGroups, value: IGroup) => {
            return {
              ...accumulator,
              [uuidv4()]: {
                name: value.name,
                property: value.property,
                tasks: value.tasks.sort((a, b) => a.positionIndex - b.positionIndex),
              },
            }
          },
          {},
        )
        return {
          name: category[0],
          property: category[1],
          columns: reconstructedGroups || {},
        }
      })
      const tasksWithoutProperties = getTasksWithoutProperties()
      if (tasksWithoutProperties && tasksWithoutProperties.length !== 0) {
        subgroupsForUI = [
          ...subgroupsForUI,
          {
            name: 'No property',
            property: null,
            columns: {
              [uuidv4()]: {
                name: 'No property',
                property: null,
                tasks: tasksWithoutProperties.sort((a, b) => a.positionIndex - b.positionIndex),
              },
            },
          },
        ]
      }

      dispatch(setSubgroups(subgroupsForUI))
      setData(subgroupsForUI)
    } else {
      const subgroupsForUI: ISubgroup[] = [
        {
          name: 'All',
          property: null,
          columns: constructedGroups || {},
        },
      ]

      dispatch(setSubgroups(subgroupsForUI))
      setData(subgroupsForUI)
    }
  }

  const updatePropertyValue = (task: ITask, property: ITaskProperty, destination: number) => {
    const propertyToUpdate =
      task.properties &&
      task.properties.find(
        (prop) => prop.propertyDefinition.type === property.propertyDefinition.type,
      )
    if (!propertyToUpdate) {
      dispatch(setPropertyForNoPropertyTask(property))
      _createProperty.createProperty(task.id, property.propertyDefinition.id)
      return
    }

    if (property.propertyDefinition.type === IPropertyTypes.STATUS) {
      const newPropertyCurrentValue = property.status?.find((status) => status.current)
      if (propertyToUpdate && newPropertyCurrentValue) {
        _assignProperty.assignValueToProperty({
          propertyId: propertyToUpdate.id,
          positionIndex: destination,
          statusValueId: newPropertyCurrentValue.statusValue.id,
        })
      }
    } else if (property.propertyDefinition.type === IPropertyTypes.PERSON) {
      const newPropertyCurrentValue = property.person?.find((person) => person.current)
      if (propertyToUpdate && newPropertyCurrentValue) {
        _assignProperty.assignValueToProperty({
          propertyId: propertyToUpdate.id,
          positionIndex: destination,
          userId: newPropertyCurrentValue.user.id,
        })
      }
    } else if (property.propertyDefinition.type === IPropertyTypes.DATE) {
      const newPropertyCurrentValue = property.date?.find((date) => date.current)
      if (propertyToUpdate && newPropertyCurrentValue) {
        _assignProperty.assignValueToProperty({
          propertyId: propertyToUpdate.id,
          positionIndex: destination,
          date: newPropertyCurrentValue.value,
        })
      }
    } else if (property.propertyDefinition.type === IPropertyTypes.TEXT) {
      const newPropertyCurrentValue = property.text?.find((status) => status.current)
      if (propertyToUpdate && newPropertyCurrentValue) {
        _assignProperty.assignValueToProperty({
          propertyId: propertyToUpdate.id,
          positionIndex: destination,
          text: newPropertyCurrentValue.value,
        })
      }
    } else if (property.propertyDefinition.type === IPropertyTypes.NUMBER) {
      const newPropertyCurrentValue = property.number?.find((status) => status.current)
      if (propertyToUpdate && newPropertyCurrentValue) {
        _assignProperty.assignValueToProperty({
          propertyId: propertyToUpdate.id,
          positionIndex: destination,
          number: newPropertyCurrentValue.value,
        })
      }
    } else if (property.propertyDefinition.type === IPropertyTypes.LABEL) {
      const newPropertyCurrentValue = property.multiSelect?.find((val) => val.current)
      if (propertyToUpdate && newPropertyCurrentValue) {
        _assignProperty.assignValueToProperty({
          propertyId: propertyToUpdate.id,
          positionIndex: destination,
          multiSelectValueId: newPropertyCurrentValue.selectedValue.id,
        })
      }
    } else if (property.propertyDefinition.type === IPropertyTypes.FILES_AND_MEDIA) {
      const newPropertyCurrentValue = property.text?.find((status) => status.current)
      if (propertyToUpdate && newPropertyCurrentValue) {
        _assignProperty.assignValueToProperty({
          propertyId: propertyToUpdate.id,
          positionIndex: destination,
          text: newPropertyCurrentValue.value,
        })
      }
    }
  }

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) return
    const { source, destination } = result
    if (tasks && source.droppableId !== destination.droppableId) {
      const taskIndex = tasks?.findIndex((task) => task.id === result.draggableId)
      const task = tasks[taskIndex] // task being dragged
      const fromSubgroupIndex = data.findIndex((subgroup) => subgroup.columns[source.droppableId])
      const toSubgroupIndex = data.findIndex(
        (subgroup) => subgroup.columns[destination.droppableId],
      )

      if (fromSubgroupIndex === toSubgroupIndex) {
        const subgroup = { ...data[toSubgroupIndex] }
        const subgroupColumns = { ...subgroup.columns }
        const sourceColumn = subgroupColumns[source.droppableId]
        const destColumn = subgroupColumns[destination.droppableId]
        const columns = data[0].columns
        const selectedColumn = columns[source.droppableId]

        const taskToUpdate = selectedColumn.tasks.find((task) => task.id === result.draggableId)
        const taskToUpdateProperty = taskToUpdate?.properties?.find((property) => {
          return property.propertyDefinition.type === IPropertyTypes.STATUS
        })
        const currentStatus = destColumn.property?.status?.find((status) => status.current)
        const updatedTasks = [...tasks]
        updatedTasks[taskIndex] = task
        const sourceItems = [...sourceColumn.tasks]
        const destItems = [...destColumn.tasks]
        sourceItems.splice(source.index, 1)
        destItems.splice(destination.index, 0, task)

        subgroupColumns[source.droppableId] = {
          ...subgroupColumns[source.droppableId],
          tasks: sourceItems,
        }

        subgroupColumns[destination.droppableId] = {
          ...subgroupColumns[destination.droppableId],
          tasks: destItems,
        }

        subgroup.columns = subgroupColumns
        const newData = [...data]
        newData[toSubgroupIndex] = subgroup
        setData(newData)

        taskToUpdate &&
          _assignProperty.assignValueToProperty({
            propertyId: taskToUpdateProperty?.id as number,
            positionIndex: result.destination.index,
            statusValueId: currentStatus?.statusValue.id,
          })
      } else {
        const fromSubgroup = { ...data[fromSubgroupIndex] }
        const toSubgroup = { ...data[toSubgroupIndex] }

        if (fromSubgroup && toSubgroup) {
          const destColumns = { ...toSubgroup.columns }
          const destColumn = destColumns[destination.droppableId]
          if (toSubgroup.property && destColumn.property) {
            updatePropertyValue(task, toSubgroup.property, result.destination.index)
            updatePropertyValue(task, destColumn.property, result.destination.index)
          }
        }
      }
    } else {
      const columns = data[0].columns
      const selectedColumn = columns[source.droppableId]
      const taskToUpdate = selectedColumn.tasks.find((task) => task.id === result.draggableId)

      const taskToUpdateProperty = taskToUpdate?.properties?.find((property) => {
        return property.propertyDefinition.type === IPropertyTypes.STATUS
      })

      const currentStatus = selectedColumn.property?.status?.find((status) => status.current)

      const subgroupIndex = data.findIndex((subgroup) => subgroup.columns[source.droppableId])
      const subgroup = data[subgroupIndex]
      const column = subgroup.columns[source.droppableId]
      const copiedItems = [...column.tasks]
      const [removed] = copiedItems.splice(source.index, 1)
      copiedItems.splice(destination.index, 0, removed)
      const newData = [...data]
      const newSubgroup = { ...newData[subgroupIndex] }
      const subgroupColumns = {
        ...newSubgroup.columns,
      }
      subgroupColumns[source.droppableId] = {
        ...subgroupColumns[source.droppableId],
        tasks: copiedItems,
      }

      newSubgroup.columns = subgroupColumns
      newData[subgroupIndex] = newSubgroup
      setData(newData)

      taskToUpdate &&
        _assignProperty.assignValueToProperty({
          propertyId: taskToUpdateProperty?.id as number,
          positionIndex: result.destination.index,
          statusValueId: currentStatus?.statusValue.id,
        })
    }
  }

  useEffect(() => {
    if (!selectedOption) {
      // Try to get a first board element
      const boardOption = options?.find((option) => option.name === 'Board')

      const option = options?.[1]

      if (boardOption)
        // Set first option for selectedLayout
        dispatch(setSelectedOptionId(boardOption.id || option?.id))
    }
  }, [selectedOption, options])

  useEffect(() => {
    if (tasks && selectedOption?.groupBy) {
      if (!propertyDefinitions) return
      const groupCategories = createCategoriesByPropertyName(tasks, selectedOption.groupBy.name)

      const constructedGroups: IGroups = constructGroups(groupCategories)

      const subgroupCategories = createCategoriesByPropertyName(
        tasks,
        selectedSubgroup?.name || 'none',
      )
      constructSubgroups(subgroupCategories, constructedGroups)
    }

    if (selectedSubgroup) {
      dispatch(setActiveKeysBoard(['0']))
      dispatch(setActiveKeysList(['0']))
    }
  }, [
    tasks,
    selectedFilterValues,
    selectedOption?.groupBy,
    selectedSubgroup,
    properties,
    propertyDefinitions,
  ])

  return {
    onDragEnd,
    data,
    selectedOption,
    options,
  }
}
