export enum ClickAction {
  OPEN_ACCOUNT_WALL = 'open-account-wall',
  OPEN_PAY_WALL = 'open-pay-wall',
  OPEN_LEFT_RAIL = 'open-left-rail',
  OPEN_RIGHT_RAIL = 'open-right-rail',
  OPEN_URL = 'open-url',
  OPEN_PAGE = 'open-page',
  CLOSE_LEFT_RAIL = 'close-left-rail',
  CLOSE_RIGHT_RAIL = 'close-right-rail',
}

export const CTA_OPTIONS = [
  {
    label: 'Open Account Wall',
    text: 'Open Account Wall',
    action: ClickAction.OPEN_ACCOUNT_WALL,
  },
  {
    label: 'Open Pay Wall',
    text: 'Open Pay Wall',
    action: ClickAction.OPEN_PAY_WALL,
  },
  {
    label: 'Open left rail',
    text: 'Open left rail',
    action: ClickAction.OPEN_LEFT_RAIL,
  },
  {
    label: 'Open right rail',
    text: 'Open right rail',
    action: ClickAction.OPEN_RIGHT_RAIL,
  },
  {
    label: 'Navigate to URL',
    text: 'Navigate to URL',
    action: ClickAction.OPEN_URL,
  },
  {
    label: 'Navigate to Page',
    text: 'Navigate to Page',
    action: ClickAction.OPEN_PAGE,
  },
  {
    label: 'Open URL',
    text: 'Open URL',
    action: ClickAction.OPEN_URL,
  },
  {
    label: 'Close left rail',
    text: 'Close left rail',
    action: ClickAction.CLOSE_LEFT_RAIL,
  },
  {
    label: 'Close right rail',
    text: 'Close right rail',
    action: ClickAction.CLOSE_RIGHT_RAIL,
  },
]

enum TextFontFamily {
  INTER = 'Inter',
  INCONSOLATA = 'Inconsolata',
  ROBOTO = 'Roboto',
  PACIFICO = 'Pacifico',
  PT_SANS = 'Ptsans',
  MONTSERRAT = 'Montserrat',
  ARIAL = 'Arial',
  MIRZA = 'Mirza',
}

export const FONT_FAMILY_ARRAY: string[] = [
  TextFontFamily.INTER,
  TextFontFamily.INCONSOLATA,
  TextFontFamily.ROBOTO,
  TextFontFamily.PACIFICO,
  TextFontFamily.PT_SANS,
  TextFontFamily.MONTSERRAT,
  TextFontFamily.ARIAL,
  TextFontFamily.MIRZA,
]

export const FONT_SIZES = [10, 12, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48, 64]

export const CTA_BUTTONS_LIMIT = 5

export enum DocumentBlockAttributes {
  BUTTON_JUST_UPDATED = 'data-cta-just-updated',
  BUTTON_IS_EDITING = 'data-cta-editing',
}

export const getCtaButtonId = (id?: string | number) => `cta-button-${id}`

export const FONT_WEIGHTS_MAP = [
  {
    label: 'Thin',
    value: 100,
  },
  {
    label: 'Extra Light',
    value: 200,
  },
  {
    label: 'Light',
    value: 300,
  },
  {
    label: 'Normal',
    value: 400,
  },
  {
    label: 'Medium',
    value: 500,
  },
  {
    label: 'Semi Bold',
    value: 600,
  },
  {
    label: 'Bold',
    value: 700,
  },
  {
    label: 'Extra Bold',
    value: 800,
  },
  {
    label: 'Black',
    value: 900,
  },
]
