import { IGeneralDropdownOption, IMultiValueOption, SuggestedSelectUser } from 'interfaces/invite'
import { useRef, useState } from 'react'
import { MultiValue } from 'react-select'
import { AxiosService } from 'services/axiosService/axiosService'
import { useToast } from 'services/helpers/useToast'
import { useParams } from 'react-router-dom'
import { useAppSelector } from 'redux/hooks'
import { useGetProjectFile } from 'services/projectFile/useGetProjectFile'
import { Role } from 'interfaces/page'

export const useProjectFileInviteByEmail = () => {
  const userRoles = useAppSelector((state) => state.projectFile.userRoles)?.filter(
    (role) => role.value !== parseInt(Role.OWNER),
  )

  const [emails, setInviteEmails] = useState<MultiValue<IMultiValueOption>>([])
  const [inviteLoading, setInviteLoading] = useState<boolean>(false)
  const [permission, setEmailPermission] = useState<number>(userRoles[0].value as number)
  const selectRef = useRef(null)
  const { getAndDispatchProjectFileMembers } = useGetProjectFile()
  const toast = useToast()
  const params = useParams()
  const projectId = params.id
  const AxiosInstance = new AxiosService()

  const updateEmailPermission = (option: IGeneralDropdownOption) => {
    setEmailPermission(option.value as number)
  }

  const setInviteEmailsState = (value: MultiValue<IMultiValueOption>) => {
    setInviteEmails(value)
  }

  const inviteToProject = async () => {
    if (projectId && permission) {
      setInviteLoading(true)
      const emailValues = emails.map((elem) => elem.value)
      const response = await AxiosInstance.inviteUserToProject(
        parseInt(projectId),
        emailValues,
        permission,
      )
      if (response) {
        selectRef && selectRef.current && (selectRef.current as any).clearValue()
        getAndDispatchProjectFileMembers(parseInt(projectId))
        toast.success('Invite sent successfully!')
        setInviteLoading(false)
      } else {
        setInviteLoading(false)
      }
    }
  }

  const getSuggestedUsers = async (value: string) => {
    if (projectId && params && params.spaceId)
      return await AxiosInstance.getSpaceMembers(params.spaceId, value, projectId.toString())
  }

  const suggestedUsersPromise = async (value: string) => {
    const response = await getSuggestedUsers(value)
    if (response) {
      const suggestedOptions = response.map((elem: any) => {
        return {
          label: `${elem.user.firstName} ${elem.user.lastName}`,
          value: elem.user.email,
          id: elem.user.id,
        }
      })
      return new Promise<SuggestedSelectUser[]>((resolve) => {
        resolve(suggestedOptions)
      })
    }
  }

  return {
    userRoles,
    selectRef,
    updateEmailPermission,
    setInviteEmailsState,
    inviteToProject,
    suggestedUsersPromise,
    inviteLoading,
  }
}
