import React from 'react'
import EmbedOption from '../Option/Option'
import { icons } from 'shared/icons'
import { EmbedType } from '_entities/embed'

interface Props {
  setEmbedType: React.Dispatch<React.SetStateAction<EmbedType | ''>>
}
const FullEmbedPicker = (props: Props) => {
  return (
    <>
      {' '}
      <EmbedOption
        icon={icons.googleDoc}
        onClick={() => void 0}
        onSelect={() => props.setEmbedType(EmbedType.GOOGLE_DOC)}
      >
        Google Doc
      </EmbedOption>
      <EmbedOption
        icon={icons.googleSheets}
        onClick={() => void 0}
        onSelect={() => props.setEmbedType(EmbedType.GOOGLE_SHEETS)}
      >
        Google Sheets
      </EmbedOption>
      <EmbedOption
        icon={icons.googleSlides}
        onClick={() => void 0}
        onSelect={() => props.setEmbedType(EmbedType.GOOGLE_SLIDES)}
      >
        Google Slides
      </EmbedOption>
      <EmbedOption
        icon={icons.googleCalendar}
        onClick={() => void 0}
        onSelect={() => props.setEmbedType(EmbedType.GOOGLE_CALENDAR)}
      >
        Google Calendar
      </EmbedOption>
      <EmbedOption
        icon={icons.figmaFull}
        onClick={() => void 0}
        onSelect={() => props.setEmbedType(EmbedType.FIGMA)}
      >
        Figma
      </EmbedOption>
      <EmbedOption
        icon={icons.miroFull}
        onClick={() => void 0}
        onSelect={() => props.setEmbedType(EmbedType.MIRO)}
      >
        Miro
      </EmbedOption>
    </>
  )
}

export default FullEmbedPicker
