import React from 'react'
import { ActionWrapper, PropertyTitle, PropertyWrapper, StyledWrapper } from '../styles'
import { icons } from 'shared/icons'
import { type block } from '_entities/block'
import useBlockMessages from './useBlockMessages'
import ProjectSidebarMessage from '_entities/message/ui/ProjectSidebarMessage/ProjectSidebarMessage'

interface IProps {
  block: block
}

const Messages = (props: IProps) => {
  const { block } = props
  const _blockMessages = useBlockMessages({ block })
  return (
    <StyledWrapper>
      <PropertyWrapper width={'100%'}>
        <PropertyTitle>Chat threads</PropertyTitle>
        {_blockMessages.messages?.length === 0 ? (
          <ActionWrapper onClick={() => _blockMessages.handleOnCommentClick(block)}>
            <icons.chatBold /> Chat{' '}
          </ActionWrapper>
        ) : (
          <>
            {_blockMessages.messages?.[0] && (
              <ProjectSidebarMessage
                key={_blockMessages.messages[0].id}
                message={_blockMessages.messages[0]}
                block={block}
              />
            )}
          </>
        )}
      </PropertyWrapper>
    </StyledWrapper>
  )
}

export default Messages
