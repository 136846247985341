import { useStage } from '_features/canvas'
import { type block } from '_entities/block'
import { Group } from 'konva/lib/Group'
import { Node } from 'konva/lib/Node'
import { Image } from 'konva/lib/shapes/Image'
import { Rect } from 'konva/lib/shapes/Rect'

export const useKonvaNode = () => {
  const _stage = useStage()

  const findById = (pageId: string, nodeId: string) => {
    const stage = _stage.getStage(pageId)
    if (!stage) return
    return stage.findOne(`#${nodeId}`)
  }

  const getStageFromNode = (node: Node) => {
    return node.getStage()
  }

  const getPageIdFromNode = (node: Node) => {
    const stage = getStageFromNode(node)
    if (!stage) return
    return _stage.getPageIdFromStage(stage)
  }

  const getGroupNode = (block: block) => {
    return findById(block.meta.pageId, block._id) as Group
  }

  const getGroupChildren = (node: Group) => {
    return node.getChildren()
  }

  const getImageNodeFromGroup = (node: Group) => {
    return node.findOne('Image') as Image
  }

  const getImageNodeFromBlock = (block: block) => {
    const group = getGroupNode(block)
    return getImageNodeFromGroup(group)
  }

  const getImageNodeSizeFromBlock = (block: block) => {
    const group = getGroupNode(block)
    const image = getImageNodeFromGroup(group)
    return {
      width: image.width(),
      height: image.height(),
    }
  }

  const getRectFromGroup = (node: Group) => {
    return node.findOne('Rect') as Rect
  }

  const getScale = (node: Node) => {
    return node.scale()
  }

  const getPosition = (node: Node) => {
    return node.position()
  }

  const setAttrsToNode = (node: Node, attrs: object) => {
    node.setAttrs(attrs)
  }

  const setGroupSize = (node: Node, attrs: { innerWidth: number; innerHeight: number }) => {
    setAttrsToNode(node, attrs)
  }

  const handleLoadedRef = (node: Node | null, callback: (node: Node) => void) => {
    if (!node) return
    callback(node)
  }

  return {
    findById,
    getStageFromNode,
    getPageIdFromNode,
    getGroupNode,
    getGroupChildren,
    getImageNodeFromGroup,
    getImageNodeFromBlock,
    getImageNodeSizeFromBlock,
    getRectFromGroup,
    getScale,
    getPosition,
    handleLoadedRef,
    setAttrsToNode,
    setGroupSize,
  }
}
