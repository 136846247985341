import { TaskTitleWrapper } from 'components/organisms/TaskManagerModal/styles'
import { SidebarListItemInput } from 'components/organisms/TaskManagerBoard/styles'
import Dots from 'components/atoms/Dots/Dots'
import React from 'react'
import { ITask } from 'interfaces/taskManager'
import { StyledExpandIcon, StyledMinimizeIcon } from './styles'
import Wrapper from '../Wrapper/Wrapper'
import RadixDropdown, {
  DropdownMenuItem,
  IconButton,
} from '../Dropdown/RadixDropdown/RadixDropdown'
import CustomP from 'components/atoms/CustomP/CustomP'
import { getColor } from 'shared/colors'
import useTaskTitle from './useTaskTitle'

interface IProps {
  task: ITask
  isSlider: boolean
  handleRenameTask: (event: React.ChangeEvent<HTMLInputElement>) => void
  inputRef: React.RefObject<HTMLInputElement>
  setIsTaskTitleRename: (event: React.MouseEvent) => void
  selected?: boolean
  onTaskSelect: (task: ITask) => Promise<void>
  minimizeTask: () => void
  isSimpleView?: boolean
}

const TaskTitle = (props: IProps) => {
  const {
    task,
    isSlider,
    handleRenameTask,
    inputRef,
    setIsTaskTitleRename,
    selected,
    onTaskSelect,
    minimizeTask,
    isSimpleView,
  } = props

  const _taskTitle = useTaskTitle()

  return (
    <TaskTitleWrapper>
      <SidebarListItemInput
        width='100%'
        type='text'
        defaultValue={task.title}
        key={task.title}
        onChange={handleRenameTask}
        onClick={setIsTaskTitleRename}
        placeholder='Task title'
        ref={inputRef}
        data-id={!isSlider ? `${task.id}` : `sidebar-${task.id}`}
        isSimpleView={isSimpleView}
      />

      {!isSimpleView && (
        <Wrapper display='flex' gap='6px'>
          {isSlider && (
            <>
              {!selected ? (
                <StyledExpandIcon onClick={() => onTaskSelect(task)} />
              ) : (
                <StyledMinimizeIcon onClick={() => minimizeTask()} />
              )}
            </>
          )}

          <RadixDropdown
            trigger={
              <IconButton>
                <Dots />
              </IconButton>
            }
          >
            <DropdownMenuItem
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                _taskTitle.handleDeleteTask(task)
              }}
            >
              <CustomP color={getColor('--everyday-red')}>Delete task</CustomP>
            </DropdownMenuItem>
          </RadixDropdown>
        </Wrapper>
      )}
    </TaskTitleWrapper>
  )
}

export default TaskTitle
